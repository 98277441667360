import { or } from 'ramda';
import { evolve } from 'ramda';

import { ImmigrationComponent } from './immigration.component';
import {isNilOrEmpty} from 'ramda-adjunct';

import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration, isOtherStatus, isNC, isUndocumentedImmigrant } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { controlWith } from '@app/helpers/form.helpers';
import { toDate, toISO } from '@app/helpers/date.helpers';
import { familyPlanning, handleMedicalPrograms } from '@app/helpers/programs.helpers';
import { nilControl } from '@app/helpers/form-control.helpers';
import { isNo, isPn } from '@app/helpers/tayn.helpers';
import {militaryAvailableAge} from '@app/helpers/person.helpers';

const formValidation = (builder, recordData, {programs, selectedIndividual }) => {
  programs = handleMedicalPrograms(programs);

  const citizenship = lookIn(recordData);

  const eligibleImmigration = citizenship(['eligibleImmigration']);
  const statusGainDate = toDate(citizenship(['immigration', 'statusGainDate']));
  const status = citizenship(['immigration', 'status']);
  const docType = citizenship(['immigration', 'document', 'docType']);

  const registrationNumber = citizenship(['immigration', 'document', 'registrationNumber']);
  const i94 = citizenship(['immigration', 'document', 'i94']);
  const visa = citizenship(['immigration', 'document', 'visa']);
  const passport = citizenship(['immigration', 'document', 'passport']);
  const countryIssuance = citizenship(['immigration', 'document', 'countryIssuance']);
  const cardNumber = citizenship(['immigration', 'document', 'cardNumber']);
  const expDate = toDate(citizenship(['immigration', 'document', 'expDate']));
  const documentDescription = citizenship(['immigration', 'document', 'documentDescription']);

  const naturalizationCertificateNumber = citizenship(['immigration', 'document', 'naturalizationCertificateNumber']);
  const certificateOfCitizenshipNumber = citizenship(['immigration', 'document', 'certificateOfCitizenshipNumber']);
  const sevisID = citizenship(['immigration', 'document', 'sevisID']);

  const isVeteranOrActiveDuty = citizenship(['militaryInfo', 'isVeteranOrActiveDuty']);
  const isSpouseVeteranOrActiveDuty = citizenship(['militaryInfo', 'isSpouseVeteranOrActiveDuty']);
  const isParentVeteranOrActiveDuty = citizenship(['militaryInfo', 'isParentVeteranOrActiveDuty'])

  const undocumentedImmigrant = isUndocumentedImmigrant(status);
  const hasMagi = familyPlanning(programs) && programs.length === 1;
  const notEligible = isNo(eligibleImmigration) || isPn(eligibleImmigration);
  const otherStatus = isOtherStatus(status);

  const isMilitaryEligible = militaryAvailableAge(selectedIndividual);

  const isNc = isNC(docType);

  const data = builder.group({
    eligibleImmigration: [eligibleImmigration, Vs.required],
    immigration: builder.group({
      statusGainDate: [controlWith(statusGainDate, (notEligible || !undocumentedImmigrant)), [Vs.notBefore120Years, Vs.validDate]],
      status: [controlWith(status, or(notEligible, hasMagi)), [Vs.required]],
      document: builder.group({
        docType: [
          {
            value: docType,
            disabled: isNo(eligibleImmigration) || isPn(eligibleImmigration) || isNilOrEmpty(status) || !undocumentedImmigrant || !otherStatus ? true : false
          },
        ],
        registrationNumber: [nilControl(registrationNumber), [Vs.alienNumber]],
        i94: [nilControl(i94), [Vs.i94]],
        visa: [nilControl(visa)],
        passport: [nilControl(passport), [Vs.passportNumber]],
        countryIssuance: [nilControl(countryIssuance)],
        cardNumber: [nilControl(cardNumber), [Vs.cardNumber]],
        expDate: [nilControl(expDate), [Vs.validDate]],
        documentDescription: [nilControl(documentDescription), [Vs.documentDescription]],
        naturalizationCertificateNumber: [controlWith(naturalizationCertificateNumber, !isNc), [Vs.naturalizationNumber]],
        certificateOfCitizenshipNumber: [nilControl(certificateOfCitizenshipNumber), [Vs.certificateNumber]],
        sevisID: [nilControl(sevisID), [Vs.sevisID]]
      }),
    }),
    militaryInfo: builder.group({
      isVeteranOrActiveDuty: [controlWith(isVeteranOrActiveDuty, !isMilitaryEligible)],
      isSpouseVeteranOrActiveDuty: [isSpouseVeteranOrActiveDuty],
      isParentVeteranOrActiveDuty: [isParentVeteranOrActiveDuty]
    }),
  });

  return { data };
};

const postObject = evolve({
  immigration: {
    statusGainDate: toISO,
    document: {
      expDate: toISO,
    },
  },
});

export default sectionConfiguration(ImmigrationComponent, formValidation, [], [], {}, { postObject });
