import { environment } from '@env/environment';
import { Component } from '@angular/core';
import { ModalService } from '@app/services/modal/modal.service';

@Component({
  selector: 'nh-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  gatewayURL = environment.gatewayURL;

  constructor(private modal: ModalService) {}

  openAnalyticsPolicy() {
    return this.modal.openAnalyticsPolicy();
  }
}
