<div class="d-none d-lg-block" [formGroup]="formData">
    <table class="table w-100 table-striped">
        <thead>
            <tr>
                <th scope="col">{{'CO-OWENERS_HEADER1' | translate }}</th>
                <th scope="col">{{'CO-OWENERS_HEADER2' | translate }}</th>
                <th scope="col">{{'CO-OWENERS_HEADER3' | translate }}</th>
                <th scope="col"></th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody formArrayName="jointOwnershipInformation">
            <tr *ngFor="let row of jointOwnershipInformation.controls; index as i; last as isLast" [formGroupName]="i">
                <td>
                    <nh-field *ngIf="i == 0" [isRow]="true" [mandatory]="true">
                        <!-- <select nh name="jointOwnerIndividual" aria-label="jointOwnerIndividual" (change)="emitCoOwnerValidate(row, i)"
                            [items]="coOwnerIndividuals" disabled formControlName="jointOwnerIndividual"
                            ></select>
                            <div *ngIf="coOwnerIndivErrorMessage && isLast" class="error-messages position-relative">
                                <span class="form-text invalid-tooltip d-block">
                                    <ng-container> {{coOwnerIndivErrorMessage | translate}}</ng-container>
                                </span>
                            </div> -->
                        <input type="text" name="jointOwnerIndividual"  aria-label ="jointOwnerIndividual"  class="form-control" [value]="row?.value?.jointOwnerIndividual?.value" disabled>
                    </nh-field> 
                    <nh-field *ngIf="i != 0" [isRow]="true" [mandatory]="true">
                        <select nh name="jointOwnerIndividual" (change)="emitCoOwnerValidate(row, i)"
                            [items]="coOwnerIndividuals" formControlName="jointOwnerIndividual"
                            aria-label="jointOwnerIndividual"></select>
                            <div *ngIf="coOwnerIndivErrorMessage && isLast" class="error-messages position-relative">
                                <span class="form-text invalid-tooltip d-block">
                                    <ng-container> {{coOwnerIndivErrorMessage | translate}}</ng-container>
                                </span>
                            </div>
                    </nh-field> 
                </td>
                <td>
                    <nh-field [isRow]="true" [mandatory]="true">
                        <input type="text" name="outOfHouseHoldname" aria-label ="outOfHouseHoldname" maxlength="35" class="form-control"
                            formControlName="outOfHouseHoldname" >
                    </nh-field>
                </td>
                <td>
                    <nh-field [isRow]="true" [mandatory]="true">
                        <nh-input-group prepend="%">
                            <input name="jointOwnerPercentage"  aria-label ="jointOwnerPercentage" percentage="true" type="text" maxLength="3"  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                class="form-control" (change)="emitErrorMessage(row)"
                                formControlName="jointOwnerPercentage" >
                        </nh-input-group>
                        <div *ngIf="formData.errors?.error && isLast && error.isError"
                            class="error-messages position-relative">
                            <span class="form-text invalid-tooltip d-block">
                                <ng-container> {{error.errorMessage }}</ng-container>
                            </span>
                        </div>
                    </nh-field>
                </td>
                <td>
                    <div *ngIf="i" class="pt-1 hover-red">
                        <button class="btn btn-link btn-lg delete-{{i}} hover-red p-1" (click)="removeIndex(i)">
                            <span class="pr-2">{{'REMOVE' | translate }}</span>
                            <nh-remove-svg></nh-remove-svg>
                        </button>
                    </div>
                </td>

            </tr>
        </tbody>
    </table>
    <div class="pt-1 hover-red add-another-left">
        <button class="btn btn-link btn-lg hover-red" [disabled]="addRowValid" (click)="emitAdd()">
            <span class="pr-2">{{'ADD_2' | translate }}</span>
            <nh-add-svg></nh-add-svg>
        </button>
    </div>
</div>
<div class="nh-table-mobile mt-3 pt-2 d-lg-none" [formGroup]="formData">
    <div formArrayName="jointOwnershipInformation">
        <div class="table-row mb-3 pb-3"
            *ngFor="let row of jointOwnershipInformation.controls; index as i; last as isLast" [formGroupName]="i">
            <!-- <div class="tab-section" *ngIf="row?.value.jointOwnerIndividual !== null">{{'CO-OWENERS_STATIC_TEXT4' | translate }} {{
                    row?.value?.jointOwnerIndividual?.value }}{{'CO-OWENERS_STATIC_TEXT5' | translate }}</div><hr> -->
            <div class="mobile-table-header">{{'CO-OWENERS_HEADER1' | translate }}</div>
            <hr>
            <div class="mobile-table-data">
                <nh-field [mandatory]="true">
                    <select nh name="jointOwnerIndividual" (change)="emitCoOwnerValidate(row,i)"
                        [items]="coOwnerIndividuals" formControlName="jointOwnerIndividual"
                        aria-label="jointOwnerIndividual"></select> 
                        <div *ngIf="coOwnerIndivErrorMessage && isLast" class="error-messages position-relative">
                            <span class="form-text invalid-tooltip d-block">
                                <ng-container> {{coOwnerIndivErrorMessage | translate}}</ng-container>
                            </span>
                        </div>                     
                </nh-field>
            </div><br>
            <div class="mobile-table-header">{{'CO-OWENERS_HEADER2' | translate }}</div>
            <hr>
            <div class="mobile-table-data">
                <nh-field [mandatory]="true">
                    <input type="text" name="outOfHouseHoldname" maxlength="35" class="form-control"
                        formControlName="outOfHouseHoldname" aria-label="outOfHouseHoldname">
                </nh-field>
            </div><br>
            <div class="mobile-table-header">{{'CO-OWENERS_HEADER3' | translate }}</div>
            <hr>
            <div class="mobile-table-data">
                <nh-field [mandatory]="true">
                    <nh-input-group prepend="%">
                        <input name="jointOwnerPercentage"  percentage="true" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            class="form-control" (change)="emitErrorMessage(row)" aria-label="jointOwnerPercentage" formControlName="jointOwnerPercentage"
                            maxlength="3">
                    </nh-input-group>
                    <div *ngIf="formData.errors?.error && isLast && error.isError"
                        class="error-messages position-relative">
                        <span class="form-text invalid-tooltip d-block">
                            <ng-container> {{error.errorMessage }}</ng-container>
                        </span>
                    </div>
                </nh-field>
            </div><br>
            <div *ngIf="i" class="pt-1 hover-red text-center">
                <button class="btn btn-link btn-lg delete-{{i}} hover-red p-1" (click)="removeIndex(i)">
                    <span class="pr-2">{{'REMOVE' | translate }}</span>
                    <nh-remove-svg></nh-remove-svg>
                </button>
            </div><br>
        </div>
    </div>
    <div class="pt-1 hover-red add-another-left">
        <button class="btn btn-link btn-lg hover-red" [disabled]="addRowValid" (click)="emitAdd()">
            <span class="pr-2">{{'ADD_2' | translate }}</span>
            <nh-add-svg></nh-add-svg>
        </button>
    </div>
</div>