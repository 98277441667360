<div class="p-2">
	<p class="static-text">{{'UPLOADED_DOCUMENTS_STATIC_TEXT1' | translate }}</p>
	<p *ngIf="ppViewTcc" class="static-text">{{'UPLOADED_DOCUMENTS_STATIC_TEXT3' | translate }}</p>
	<h5 class="section-header">{{'UPLOADED_DOCUMENTS_STATIC_TEXT2' | translate }}</h5><hr />

	<div *ngIf="rows && rows.length > 0" class="d-none d-lg-block">
		<ngx-datatable class='bootstrap striped' #table [rows]='rows' [columnMode]='"force"' [headerHeight]='"auto"'
			[footerHeight]='50' [rowHeight]='"auto"' [limit]="5">
			<ngx-datatable-column [name]="col.name | translate" [prop]="col.prop" *ngFor="let col of columns">
			</ngx-datatable-column>
			<ngx-datatable-column name="{{'View Document' | translate }}">
				<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
					<div class="pt-0 hover-red">
						<button class="pt-0 btn btn-link btn-lg hover-red" (click)="showPdf(row)">
							<span class="pr-2">{{'View' | translate }}</span>
							<i class="fa fa-search fa-lg"></i>
						</button>
					</div>
				</ng-template>
			</ngx-datatable-column>
		</ngx-datatable>
	</div>

	<div class="nh-table-mobile d-lg-none">
		<div *ngIf="rows.length">
			<div class="table-row mb-3 pb-3" *ngFor="let row of rows">
				<div class="mobile-table-header">{{columns[0].name | translate }}</div><hr>
				<div class="mobile-table-data">{{row.name}}</div><br>
				<div class="mobile-table-header">{{columns[1].name | translate }}</div><hr>
				<div class="mobile-table-data">{{row.proofType}}</div><br>
				<div class="mobile-table-header">{{columns[2].name | translate }}</div><hr>
				<div class="mobile-table-data">{{row.docType}}</div><br>
				<div class="mobile-table-header">{{columns[3].name | translate }}</div><hr>
				<div class="mobile-table-data">{{row.uploadDt}}</div><br>
				<div class="mobile-table-header">"{{'View Document' | translate }}"</div><hr>
				<button class="btn btn-secondary btn-sm w-100" (click)="showPdf(row)">{{'VIEW' | translate }} <i
					class="fa fa-search fa-lg"></i></button><br>
			</div>
		</div>
	</div>
	<p *ngIf="!rows.length" class="mb-5">{{'DO_NOT_HAVE_DOCUMENT' | translate }}</p>

	<div *ngIf="ppViewTcc && imeRows && imeRows.length > 0" class="d-none d-lg-block">
		<h5 class="section-header">My Uploaded Incurred Medical Expense Documents </h5><hr />
		<ngx-datatable class='bootstrap striped' #table [rows]='imeRows' [columnMode]='"force"' [headerHeight]='"auto"'
			[footerHeight]='50' [rowHeight]='"auto"' [limit]="5">
			<ngx-datatable-column [name]="col.name | translate" [prop]="col.prop" *ngFor="let col of imeColumns">
			</ngx-datatable-column>
			<ngx-datatable-column name="{{'View Document' | translate }}">
				<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
					<div class="pt-0 hover-red">
						<button class="pt-0 btn btn-link btn-lg hover-red" (click)="showPdf(row)">
							<span class="pr-2">{{'View' | translate }}</span>
							<i class="fa fa-search fa-lg"></i>
						</button>
					</div>
				</ng-template>
			</ngx-datatable-column>
		</ngx-datatable>
	</div>
	<div *ngIf="ppViewTcc && imeRows.length && imeRows.length > 0" class="nh-table-mobile d-lg-none">
			<h5 class="section-header">My Uploaded Incurred Medical Expense Documents </h5><hr />
			<div class="table-row mb-3 pb-3" *ngFor="let row of imeRows">
				<div class="mobile-table-header">{{imeColumns[0].name | translate }}</div><hr>
				<div class="mobile-table-data">{{row.name}}</div><br>
				<div class="mobile-table-header">{{imeColumns[1].name | translate }}</div><hr>
				<div class="mobile-table-data">{{row.proofType}}</div><br>
				<div class="mobile-table-header">{{imeColumns[2].name | translate }}</div><hr>
				<div class="mobile-table-data">{{row.docType}}</div><br>
				<div class="mobile-table-header">{{imeColumns[3].name | translate }}</div><hr>
				<div class="mobile-table-data">{{row.uploadDt}}</div><br>
				<div class="mobile-table-header">"View Document"</div><hr>
				<button class="btn btn-secondary btn-sm w-100" (click)="showPdf(row)">{{'VIEW' | translate }} <i
					class="fa fa-search fa-lg"></i></button><br>
			</div>
	</div>
</div>