<!-- Page Title -->
<div class="row">
  <div *ngIf="title" class="col-12">
    <h1 *ngIf="isSummary && isSubTitleEnabled && !isIMEdetailsSummary" class="page-title-h1">{{ 'SUMMARY_OF' | translate}}{{ title | translate }}</h1>
    <h1 *ngIf="isSummary && isSubTitleEnabled && isIMEdetailsSummary" class="page-title-h1">Incurred Medical Expenses Summary </h1>
    <h1 *ngIf="!isSummary" class="page-title-h1">{{ title | translate }}</h1>
  </div>
  <div class="col-4 d-flex justify-content-end align-items-center">
    <span class="fa-cta" *ngIf="canPrint()">
      <a (click)="print()" class="no-hover" title="PRINT" tabindex="0">
        <span class="d-none d-md-inline-block float-right print">&nbsp;PRINT</span>
        <i class="fa fa-fw fa-lg fa-print fa-border float-right"></i>
      </a>
    </span>
  </div>
</div>
