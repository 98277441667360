import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { findModule } from '@app/helpers/function.helpers';
import { getDataValue } from '@app/helpers/object.helpers';
import { or } from 'ramda';
@Component({
  templateUrl: './report-change.component.html',
  styleUrls: ['./report-change.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportChangeComponent extends SectionFormDirective implements AfterContentInit {
  form;
  modules;
  kb = false;
  tax = false;
  other = false;
  income = false;
  endCov;
  showKtTile = false;
  assets = false;
  expenses = false;
  contactInfo = false;
  endCoverage = false;
  houseHoldInfo = false;
  houseHoldMembers = false;
  initialSelection = new Array<string>();
  showKtMsg = false;
  formData: any;
  changeKt = false;
  referralKt = false;
  showPregDueDateNudge: string;
  closePregDueDateNudge: boolean = false;
  transfer = false;

  constructor(private router: Router, builder: FormBuilder, private titleService: Title) {
    super(builder);
    this.titleService.setTitle('Report My Changes');
  }

  ngAfterContentInit() {
    const data: any = this.getControl('data');
    this.showKtMsg = JSON.parse(sessionStorage.getItem('showKtMsg'));
    this.form = this.getForm();
    this.modules = getDataValue('record.data.modules', this);
    const { changeKBReferralSW, initiateKBReferralSW, showPastPregDueDateNudge } = data;
    this.changeKt = changeKBReferralSW === "Y";
    sessionStorage.setItem('changeKBReferralSW', changeKBReferralSW);
    this.referralKt = initiateKBReferralSW === "Y";
    this.showKtTile = or(this.changeKt, this.referralKt);
    this.showPregDueDateNudge = showPastPregDueDateNudge;

    if (this.showPregDueDateNudge === 'Y') {
      if (!sessionStorage.getItem('RMCPastPregDueDateNudgeStatus')) {
        sessionStorage.setItem('RMCPastPregDueDateNudgeStatus', 'true');
      }

      const getNudgeStatus = sessionStorage.getItem('closeNudge') ? sessionStorage.getItem('closeNudge') : sessionStorage.getItem('rmcClosePregDueDateNudge');
      if (getNudgeStatus === 'true') {
        this.closePregDueDateNudge = true;
      }
    }

    const HCPPG = findModule('HCPPG', this.modules);
    const HHPPG = findModule('HHPPG', this.modules);
    const SEPPG = findModule('SEPPG', this.modules);
    const XPPPG = findModule('XPPPG', this.modules);
    const ICPPG = findModule('ICPPG', this.modules);
    const ASPPG = findModule('ASPPG', this.modules);
    const INPPG = findModule('INPPG', this.modules);
    const TXPPG = findModule('TXPPG', this.modules);
    const KTPPG = findModule('KTPPG', this.modules);
    const HIPPG = findModule('HIPPG', this.modules);
    const TRPPG = findModule('TRPPG', this.modules);

    const disableInitial = (moduleName, tileValue) => {
      if (moduleName && moduleName.moduleId !== 'SUPPG') {
        const section = moduleName.sections.find(s => s.changeRequested === true ? s.sectionId : null);
        if (section) {
          this.initialSelection.push(tileValue);
        }
      }
      if (moduleName && moduleName.moduleId === 'SUPPG') {
        const section = moduleName.sections.find(s => s.sectionId === 'SUCOV');
        if (section.sectionId === 'SUCOV' && section.changeRequested === true) {
          this.initialSelection.push(tileValue);
        }
      }

      if (JSON.parse(sessionStorage.getItem('disableKb'))) {
        this.initialSelection.push('kb');
      }
    }

    disableInitial(KTPPG, 'kb');
    disableInitial(TXPPG, 'tax');
    disableInitial(INPPG, 'other');
    disableInitial(ASPPG, 'assets');
    disableInitial(ICPPG, 'income');
    disableInitial(TRPPG, 'transfer');
    disableInitial(XPPPG, 'expenses');
    disableInitial(SEPPG, 'endCoverage');
    disableInitial(HCPPG, 'contactInfo');
    disableInitial(HIPPG, 'houseHoldInfo');
    disableInitial(HHPPG, 'houseHoldMembers');
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  toTenncare() {
    return this.router.navigateByUrl("user-profile/benefit-dashboard");
  };

  toggleValue(module: string) {
    if(this.disable(module)) return;
    this[module] = !this[module];
    let control: any;
    let val: any;
    let controlName =
      module === 'contactInfo'
        ? 'HCPPG'
        : module === 'houseHoldMembers'
          ? 'HHPPG'
          : module === 'houseHoldInfo'
            ? 'HIPPG'
            : module === 'tax'
              ? 'TXPPG'
              : module === 'income'
                ? 'ICPPG'
                : module === 'other'
                  ? 'INPPG'
                  : module === 'transfer'
                    ? 'TRPPG'
                    : module === 'expenses'
                      ? 'XPPPG'
                      : module === 'assets'
                        ? 'ASPPG'
                        : module === 'endCoverage'
                          ? 'SEPPG'
                          : module === 'kb'
                            ? 'INPPG'
                            : '';

    this.endCov = this.modules.filter(m => m.moduleId === 'SEPPG')[0].sections;
    if (module === 'kb') {
      sessionStorage.setItem('disableKb', 'true');
    }
    if (controlName !== 'SEPPG') {
      controlName = 'data.' + controlName;
      control = this.getControl(controlName);
      if (control) {
        val = control.value.sections;
        if (val) {
          val = val.map(s => {
            s.changeRequested = !s.changeRequested;
            return s;
          });
          control.setValue(control.value);
        }
      }
    } else {
      const endCoverage = this.endCov.filter(item => item.sectionId === 'SUCOV');
      endCoverage.map(s => s.changeRequested = !s.changeRequested);
    }
  }

  disable(controlName: string) {    
    return this.initialSelection.some(v => v === controlName);
  }

  validate(event) {
    this.onValidate(event);
  }
}
