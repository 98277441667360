<div class="modal-content">
  <div class="modal-header">
    <h3 class="modal-title pull-left" id="my-modal-title">Supported Browsers</h3>

    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon" />
    </button>
  </div>
  <div class="modal-body">
    <div class="flex-container">
      <div class="row">
        <span class="flex-item"> <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank">
          <img class="icon_prop" alt="Safari_v11+" src="../../assets/images/icons8-microsoft-edge.svg" border="0">
            <p id="versionfont" style="font-weight: bold;">Version v40+</p>
          </a>
        </span>
      </div>
      <div class="row">
        <span class="flex-item"> <a href="https://support.apple.com/downloads/safari" target="_blank">
            <img class="icon_prop" alt="edge_v40+" src="../../assets/images/icons8-safari.svg" border="0">
            <p id="versionfont" style="font-weight: bold;">Version 11+</p>
          </a>
        </span>
      </div>
      <div class="row">
        <span class="flex-item"> <a href="https://www.google.com/chrome" target="_blank">
           <img class="icon_prop" alt="Chrome_v65+" src="../../assets/images/icons8-chrome.svg" style="" border="0">
            <p id="versionfont" style="font-weight: bold;">Version v65+</p>
          </a>
        </span>
      </div>
    </div>
  </div>
</div>
