<div>
	<h5 class="section-header"><strong>Search Results</strong></h5>
	<hr>
	<ngx-datatable class='bootstrap striped' #table [rows]='results' [columnMode]='"force"' [headerHeight]='"auto"'
		[footerHeight]='50' [rowHeight]='"auto"' [limit]="5">

		<ngx-datatable-column name="Person Name">
			<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
				<div *ngIf="row.details && row.details.length > 0">
					<div *ngFor="let ind of row.details" class="pb-3">
						<div>{{ ind.name }}</div>
						<div>{{ ind.dob | date: 'MM/dd/yyyy' }}</div>
					</div>
				</div>
			</ng-template>
		</ngx-datatable-column>

		<ngx-datatable-column name="Submission Type">
			<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
				<span *ngIf="submissionType && submissionType.code === 'AD'">Additional Document</span>
				<span *ngIf="!submissionType">{{ row?.submissionType?.value }}</span>
			</ng-template>
		</ngx-datatable-column>

		<ngx-datatable-column name="Submission Date">
			<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
				<span *ngIf="row?.subdate">{{ row.subdate | date : 'MM/dd/yyyy' }}</span>
				<span *ngIf="!row?.subdate">{{ 'Unavailable' | translate }}</span>
			</ng-template>
		</ngx-datatable-column>

		<ngx-datatable-column name="Submitted By">
			<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
				<span *ngIf="!row?.wpAppNum">N/A</span>
				<span *ngIf="row?.wpAppNum">{{ row.subBy }}</span>
			</ng-template>
		</ngx-datatable-column>

		<ngx-datatable-column [width]="200" name="Upload Documents*">
			<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
				<span *ngIf ="row?.memberSearchResponse && row.flagStatus">
					<div *ngFor="let ind of row.details">
						<div *ngIf="ind.form2350 === 'Y'"><button (click)="admitDischarge(ind)" class="btn btn-secondary upload-btn">Form 2350</button></div>
					</div>
				</span>
				<span *ngIf="row?.uploadDocs === 'F' && !row?.flagStatus">N/A</span>
				<span *ngIf="row?.uploadDocs === 'S'  && row?.submissionType.code != 'NBN'">
					<button (click)="uploadDocs(row.individual.individualId)" class="btn btn-secondary upload-btn">Upload
						<img src="../../../../assets/images/cloudupload.svg" alt="upload-button" width="20px" height="20px" />
					</button>
				</span>
				<span *ngIf="row?.uploadDocs === 'S'  && row?.submissionType.code === 'NBN'">N/A</span>
			</ng-template>
		</ngx-datatable-column>

		<ngx-datatable-column name="Submitted Documents**">
			<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
				<span *ngIf="!row?.subDocs && row?.submissionType.code != 'IME'">N/A</span>
				<span *ngIf="!row?.subDocs && row?.submissionType.code === 'IME'">Uploaded</span>
				<span *ngIf="row?.subDocs">
					<div class="hover-red p-0" (click)="onSubmitDocs(row?.wpAppNum)">
						<a class="btn btn-link p-0 hover-red">
							<span class="pr-2">View</span>
							<svg id="_icons_search-outline-{{rowIndex}}" data-name="⚛️ icons/search-outline"
								xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24"
								height="24" viewBox="0 0 24 24">
								<defs>
									<clipPath id="clip-path-{{rowIndex}}">
										<rect id="Background" width="24" height="24" fill="#174a7c" />
									</clipPath>
								</defs>
								<g id="Mask_Group_{{rowIndex}}" data-name="Mask Group 1" clip-path="url(#clip-path)">
									<path id="Combined_Shape" data-name="Combined Shape"
										d="M18.232,19.292l-.013-.012-4.927-4.927a8.122,8.122,0,1,1,1.061-1.061l4.927,4.927a.75.75,0,0,1-1.049,1.073ZM1.5,8.114A6.614,6.614,0,1,0,8.114,1.5,6.621,6.621,0,0,0,1.5,8.114Z"
										transform="translate(2.25 2.25)" fill="#174a7c" />
								</g>
							</svg>
						</a>
					</div>
				</span>
			</ng-template>
		</ngx-datatable-column>

		<ngx-datatable-column name="View Submission">
			<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
				<div *ngIf="submissionType && submissionType.code === 'AD'">N/A</div>
				<div *ngIf="!submissionType && !row?.viewSub && !row?.wpAppNum">
					<button (click)="continueApp(row)" class="btn btn-sm btn-secondary upload-btn">Continue</button>
					<div class="pt-1 hover-red">
						<button class="btn btn-link btn-lg hover-red p-1" (click)="onDeleteApp(row.appNum)">
							<span class="pr-2">Remove</span>
							<nh-remove-svg></nh-remove-svg>
						</button>
					</div>
				</div>
				<span *ngIf="!submissionType && row?.viewSub">
					<div class="hover-red p-0" (click)="onViewApp(row.viewSub)">
						<a class="btn btn-link hover-red" style="text-align: -webkit-left;padding-left: 0px">
							<span class="pr-2">View English</span>
							<svg id="search-outline-{{rowIndex}}" data-name="⚛️ icons/search-outline"
								xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24"
								height="24" viewBox="0 0 24 24">
								<defs>
									<clipPath id="search-path-{{rowIndex}}">
										<rect id="Background" width="24" height="24" fill="#174a7c" />
									</clipPath>
								</defs>
								<g id="search-Mask_Group_{{rowIndex}}" data-name="Mask Group 1" clip-path="url(#clip-path)">
									<path id="Combined_Shape" data-name="Combined Shape"
										d="M18.232,19.292l-.013-.012-4.927-4.927a8.122,8.122,0,1,1,1.061-1.061l4.927,4.927a.75.75,0,0,1-1.049,1.073ZM1.5,8.114A6.614,6.614,0,1,0,8.114,1.5,6.621,6.621,0,0,0,1.5,8.114Z"
										transform="translate(2.25 2.25)" fill="#174a7c" />
								</g>
							</svg>
						</a>
					</div>
				</span>
				<span *ngIf="!submissionType && row?.viewSubSP">
					<div class="hover-red p-0" (click)="onViewApp(row.viewSubSP)">
						<a class="btn btn-link hover-red" style="text-align: -webkit-left;padding-left: 0px">
							<span class="pr-2">View Spanish</span>
							<svg id="search-outline-{{rowIndex}}" data-name="⚛️ icons/search-outline" xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
								<defs>
									<clipPath id="search-path-{{rowIndex}}">
										<rect id="Background" width="24" height="24" fill="#174a7c" />
									</clipPath>
								</defs>
								<g id="search-Mask_Group_{{rowIndex}}" data-name="Mask Group 1" clip-path="url(#clip-path)">
									<path id="Combined_Shape" data-name="Combined Shape"
										d="M18.232,19.292l-.013-.012-4.927-4.927a8.122,8.122,0,1,1,1.061-1.061l4.927,4.927a.75.75,0,0,1-1.049,1.073ZM1.5,8.114A6.614,6.614,0,1,0,8.114,1.5,6.621,6.621,0,0,0,1.5,8.114Z"
										transform="translate(2.25 2.25)" fill="#174a7c" />
								</g>
							</svg>
						</a>
					</div>
				</span>
			</ng-template>
		</ngx-datatable-column>
	</ngx-datatable>
</div>