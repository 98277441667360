export default {
    type: 'RMB_SELF_EMPLOYMENT_STATIC_TEXT1',
    businessDescription: 'SELF_EMPLOYMENT_STATIC_TEXT7',
    businessName: 'SELF_EMPLOYMENT_STATIC_TEXT4',
    averageMonthlyProfit: 'RMB_SELF_EMPLOYMENT_STATIC_TEXT2',
    anyOneOutside: 'RMB_SELF_EMPLOYMENT_STATIC_TEXT3',
    coOwners: 'RMB_C_CO_OWNERS',
    removed: {
        selfempEndDate: "RMB_SELF_EMPLOYMENT_STATIC_TEXT4",
    }
}