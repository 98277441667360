export const expensePayment = {
    expenseFrequencyCode: 'RMB_UTILITIES_STICT_TEXT1',
    expenseAmount: 'UTILITIES_EXPENSES_STATIC_TEXT9'
}

export default {
    housingTypeExpense: 'RMB_UTILITIES_STICT_TEXT2',
    expensePaymentInformation: expensePayment,
    removed: {
        shelterEndDate: 'RMB_UTILITIES_STICT_TEXT3'
    }
}