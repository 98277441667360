import { propSatisfies } from 'ramda';
import { invoker } from 'ramda';
import { compose } from 'ramda';
import { evolve } from 'ramda';
import { reject } from 'ramda';
import { head } from 'ramda';
import { path } from 'ramda';
import { curry } from 'ramda';
import { addIndex } from 'ramda';
import { reduce } from 'ramda';

import { ifNun } from './null.helpers';
import { disabled } from './able.helpers';
import { aPath } from './function.helpers';
import { notContained } from './list.helper';
import { getDataValue } from '@app/helpers/object.helpers';

const focus = invoker(0, 'focus');
const print = invoker(0, 'print');

const elementChildren = aPath(['nativeElement', 'children']);
const abled = reject(disabled);
const control = propSatisfies(notContained(['INPUT', 'SELECT']), 'nodeName');
const inputs = reject(control);

export const focusFirst = compose(
  ifNun(focus),
  head,
  abled,
  inputs,
  head,
  elementChildren
);

export const setAttributes = setAttribute =>
  evolve({
    required: setAttribute('required'),
    minlength: setAttribute('minlength'),
    maxlength: setAttribute('maxlength'),
    min: setAttribute('min'),
    max: setAttribute('max'),
  });

export const nativeElement = path(['valueAccessor', '_elementRef', 'nativeElement']);

const reduceIndex = addIndex(reduce);

export const indexOrZero = curry((compare: Function, items: any[]) =>
  reduceIndex((zero: number, item: any, index: number) => (compare(item) ? index : zero), 0, items)
);

export const defaultView = path(['defaultView']);

export const printView = compose(
  print,
  defaultView
);

export const sessionStore = compose(
  path(['sessionStorage']),
  defaultView
);

export const removeDomCharactors = (obj: any, objPath: string) => {
  let str = getDataValue(objPath, obj);
  if (str) {
    const amp = '&amp;';
    while (str.indexOf(amp) > -1) {
      str = str.replace(amp, '&');
    }
    const p = objPath.split('.');
    let o = obj;
    while (p.length) {
      const pth = p.shift();
      if (pth) {
        if (p.length) {
          o = o[pth];
        } else {
          o[pth] = str;
        }
      }
    }
  }
};

export const customSort = ({ data, sortOrder, sortField }) => {
  const sortByObject = sortOrder.reduce(
    (obj, item, index) => ({
      ...obj,
      [item]: index,
    }),
    {}
  );
  return data.sort((a, b) => sortByObject[a[sortField]] - sortByObject[b[sortField]]);
};
