import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { applyTo, ifElse, compose, forEach, isEmpty, and, equals } from 'ramda';

import { SectionFormDirective } from '@app/classes/section-form.class';
import { isYes, isY } from '@app/helpers/tayn.helpers';
import { runCode } from '@app/helpers/code.helpers';
import { disable } from '@app/helpers/able.helpers';
import { toggles, swapControls } from '@app/helpers/forms.helpers';
import { toEach, lookIn } from '@app/helpers/flipped.helpers';
import { resetValue } from '@app/helpers/reset.helpers';
import { familyPlanning } from '@app/helpers/programs.helpers';
import { isOtherRelation, isUndocumentedImmigrant } from '@app/helpers/models.helpers';
import { assignAs } from '@app/helpers/function.helpers';
import { value } from '@app/helpers/value.helpers';
import { code } from '@app/helpers/code.helpers';
import { immigrationVerified } from '@app/helpers/types.helpers';
import Vs from '@app/services/validators/validators.service';
import { clearValidators } from '@app/helpers/form-control.helpers';
import { resetEnableControls, resetDisableControls, enableControls } from '@app/helpers/reset-able.helpers';
import { isApplyForBenefits, isPartner, isPresumptiveApp } from '@app/helpers/mode.helpers';
import {formatDate} from "@angular/common";

@Component({
  templateUrl: './newborn-immigration.component.html',
  styleUrls: ['./newborn-immigration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewbornImmigrationComponent extends SectionFormDirective implements AfterContentInit {
  showInfo = false;
  today = new Date();
  registrationNumber = false;
  cardNumber = false;
  expDate = false;
  i94 = false;
  visa = false;
  passport = false;
  countryIssuance = false;
  documentDescription = false;
  onReadFlag = false;
  isImmigrationDisabled: any = null;
  isEligible: any = null;
  hasMagi: any = false;
  isMilitaryEligible = false;
  sevisID = false;
  naturalizationCertificateNumber = false;
  certificateOfCitizenshipNumber = false;
  minDate;
  parentalEligibility;
  isFullMedicAidApp;
  isAFB;
  isPartner;
  isPresumptive;

  get babyName() {
    let resultRecord;
    if (sessionStorage.getItem('recordId') && sessionStorage.getItem('recordId') != "null" && sessionStorage.getItem('recordId') != '-1'){
      resultRecord = this['records'].filter((item => (item.identifier.recordId == sessionStorage.getItem('recordId'))))[0].data;
    } else if (sessionStorage.getItem('recordId') && sessionStorage.getItem('recordId') == '-1'){
      const recordId = Math.max.apply(Math, this['records'].map(function(o) { return o.identifier.recordId; }))
      resultRecord = this['records'].filter((item => (item.identifier.recordId == recordId)))[0].data;
    } else {
      const recordId = Math.max.apply(Math, this['records'].map(function(o) { return o.identifier.recordId; }))
      resultRecord = this['records'].filter((item => (item.identifier.recordId == recordId)))[0].data;
    }
    if (resultRecord) {
      return `${resultRecord.childFirstName} ${resultRecord.childLastName}`;
    }
    return '';
  }

  ngAfterContentInit() {
    this.minDate = new Date(this.record.data.childDob + ' 00:00');
    const userType = sessionStorage.getItem('userType');
    this.isAFB = isApplyForBenefits(this.applicationType);
    this.isPartner = isPartner(userType);
    this.isFullMedicAidApp = and(this.isPartner, this.isAFB);
    this.isPresumptive = isPresumptiveApp(this.applicationType);

    this.hasMagi = familyPlanning(this.programs) && this.programs.length === 1;
    const citizenship = lookIn(this.record);



    const since1996 = citizenship(['data', 'immigration', 'since1996']);
    const verification = citizenship(['data', 'immigration', 'vlpImmigrationInfo', 'verificationCode']);
    const legalStatus = citizenship(['data', 'immigration', 'vlpImmigrationInfo', 'legalStatusCode', 'code']);


    const eligibleImmigration = this.getControl('data.eligibleImmigration');
    const data = this.getControl('data.immigration');
    const immigration = data;
    const statusControl = data.get('status');
    const docTypeControl = data.get('document.docType');
    const gainDateControl = data.get('statusGainDate');

    const alienRegistrationControl = data.get('document.registrationNumber');
    const i94Control = data.get('document.i94');
    const visaControl = data.get('document.visa');
    const passportControl = data.get('document.passport');
    const countryIssuanceControl = data.get('document.countryIssuance');
    const cardNumberControl = data.get('document.cardNumber');
    const documentDescriptionControl = data.get('document.documentDescription');
    const expDateControl = data.get('document.expDate');
    const sevisIDControl = data.get('document.sevisID');
    const citizenshipCertificateControl = data.get('document.certificateOfCitizenshipNumber');
    const naturalizationCertificateNumberControl = data.get('document.naturalizationCertificateNumber');

    const reEntryControls = [alienRegistrationControl];
    const permanentControls = [alienRegistrationControl, cardNumberControl];
    const refugeeControls = [alienRegistrationControl];
    const authControls = [expDateControl, cardNumberControl];
    const otherAlienControls = [
      passportControl,
      alienRegistrationControl,
      documentDescriptionControl,
      expDateControl,
    ];
    const otherI94Controls = [
      i94Control,
      passportControl,
      expDateControl
    ];
    const arrivalControls = [i94Control];
    const passportControls = [i94Control, visaControl, passportControl];
    const machineControls = [
      alienRegistrationControl,
      passportControl
    ];
    const temporaryControls = [alienRegistrationControl];
    const unexpiredControls = [expDateControl, passportControl, countryIssuanceControl];
    const studentControls = [sevisIDControl];
    const JStudentControls = [sevisIDControl];
    const citizenshipControls = [alienRegistrationControl, citizenshipCertificateControl];
    const naturalizationControls = [alienRegistrationControl, naturalizationCertificateNumberControl];

    const reEntryCode = 'IRP';
    const permanentCode = 'PRC';
    const refugeeCode = 'RTD';
    const authCode = 'EAC';
    const otherAlienCode = 'AN';
    const otherI94Code = 'ADFP';
    const arrivalCode = 'AD';
    const passportCode = 'PI';
    const machineCode = 'MRIV';
    const temporaryCode = 'TIS';
    const unexpiredCode = 'UFP';
    const studentCode = 'CEF';
    const JStudentCode = 'DCEJ';
    const citizenshipCode = 'CC';
    const naturalizationCode = 'NC';

    const setMandatory = flag => forEach(field => assignAs(this, field)(flag));
    const setOptional = setMandatory(false);

    const hasData = controls => (this.onReadFlag ? enableControls(controls) : resetEnableControls(controls));

    const reEntry = () => [hasData(reEntryControls), alienRegistrationControl.setValidators([Vs.alienNumber]), alienRegistrationControl.updateValueAndValidity()];
    const permanentResident = () => [
      hasData(permanentControls),
      setOptional(['cardNumber']),
      alienRegistrationControl.setValidators([Vs.alienNumber]),
      alienRegistrationControl.updateValueAndValidity(),
      cardNumberControl.setValidators([Vs.cardNumber]),
      cardNumberControl.updateValueAndValidity()
    ];
    const refugeeTravel = () => [hasData(refugeeControls), alienRegistrationControl.setValidators([Vs.alienNumber]), alienRegistrationControl.updateValueAndValidity()];

    const authorizationCard = () => [
      hasData(authControls),
      cardNumberControl.setValidators([Vs.cardNumber]),
      cardNumberControl.updateValueAndValidity()
    ];

    const otherAlienNumber = () => [
      hasData(otherAlienControls),
      alienRegistrationControl.setValidators([Vs.alienNumber]),
      alienRegistrationControl.updateValueAndValidity()
    ];

    const otherI94Card = () => [
      hasData(otherI94Controls),
      passportControl.setValidators([Vs.passportNumber]),
    ];

    const arrivalDepartureCard = () => [hasData(arrivalControls), i94Control.setValidators([Vs.i94])];

    const i94Passport = () => [
      hasData(passportControls),
    ];

    const machineReadable = () => [
      hasData(machineControls),
      passportControl.setValidators([Vs.passportNumber]),
      passportControl.updateValueAndValidity(),
    ];

    const temporaryStamp = () => [hasData(temporaryControls),  alienRegistrationControl.setValidators([Vs.alienNumber])];

    const unExpiredPassport = () => [
      hasData(unexpiredControls),
      passportControl.setValidators([Vs.passportNumber]),
      passportControl.updateValueAndValidity(),
    ];

    const student = () => [
      hasData(studentControls),
    ]

    const JStudent = () => [
      hasData(JStudentControls),
    ]

    const citizenshipCertificate = () => [
      hasData(citizenshipControls),
      alienRegistrationControl.clearValidators(),
      alienRegistrationControl.setValidators([Vs.alienNumber]),
      alienRegistrationControl.updateValueAndValidity()
    ]

    const naturalization = () => [
      hasData(naturalizationControls),
      clearValidators(alienRegistrationControl),
      alienRegistrationControl.setValidators([Vs.alienNumber]),
      alienRegistrationControl.updateValueAndValidity()
    ]

    const validations = [
      runCode(reEntry, reEntryCode),
      runCode(permanentResident, permanentCode),
      runCode(refugeeTravel, refugeeCode),
      runCode(authorizationCard, authCode),
      runCode(otherAlienNumber, otherAlienCode),
      runCode(otherI94Card, otherI94Code),
      runCode(arrivalDepartureCard, arrivalCode),
      runCode(i94Passport, passportCode),
      runCode(machineReadable, machineCode),
      runCode(temporaryStamp, temporaryCode),
      runCode(unExpiredPassport, unexpiredCode),
      runCode(student, studentCode),
      runCode(JStudent, JStudentCode),
      runCode(citizenshipCertificate, citizenshipCode),
      runCode(naturalization, naturalizationCode)
    ];

    const resetDocument = () => [
      setOptional([
        'registrationNumber',
        'cardNumber',
        'expDate',
        'i94',
        'visa',
        'passport',
        'countryIssuance',
        'documentDescription',
        'sevisID',
        'certificateOfCitizenshipNumber',
        'naturalizationCertificateNumber'
      ]),
      resetDisableControls([
        alienRegistrationControl,
        i94Control,
        visaControl,
        passportControl,
        countryIssuanceControl,
        cardNumberControl,
        documentDescriptionControl,
        expDateControl,
        sevisIDControl,
        citizenshipCertificateControl,
        naturalizationCertificateNumberControl
      ]),
    ];

    const legalStatusVal = equals(legalStatus, 'P');
    const isVerified = and(isY(since1996), equals(verification, 'V'));

    if (this.record && this.record.data) {
      if (and(immigrationVerified(verification), legalStatusVal)) {
        this.isImmigrationDisabled = true;
      }

      if (isVerified) {
        this.isEligible = true;
      }

    }


    this.registerToggle(
        eligibleImmigration,
        toggles([
          ifElse(
              isYes,
              () => [
                resetValue(
                    {
                      statusGainDate: { value: null, disabled: this.hasMagi },
                      status: { value: null, disabled: this.hasMagi }
                    },
                    immigration
                ),
                disable(docTypeControl)
              ],
              () => [resetDocument(), disable(immigration)]
          ),
          () => this.updateForm(),
        ])
    );

    const applyValidations = compose(
        toEach(validations),
        applyTo
    );

    const setFlagsOnRead = () => {
      const docCodes = code(value(docTypeControl));
      this.onReadFlag = !isEmpty(docCodes);
      return applyValidations(value(docTypeControl));
    };

    setFlagsOnRead();
    this.registerToggle(statusControl, swapControls([docTypeControl, gainDateControl], isUndocumentedImmigrant));
    this.registerToggle(statusControl, swapControls([alienRegistrationControl, i94Control, cardNumberControl,
      documentDescriptionControl,
      sevisIDControl, passportControl, countryIssuanceControl, naturalizationCertificateNumberControl, citizenshipCertificateControl], isOtherRelation));

    this.registerToggle(docTypeControl, docType => [resetDocument(), applyValidations(docType)]);
  }
  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  otherStatus () {
    const val = this.getValue('data.immigration.status');
    const docTypeControl = this.getControl('data.immigration.document.docType');
    const statusGainDate = this.getControl('data.immigration.statusGainDate');
    const passportControl = this.getControl('data.immigration.document.passport');
    const documentDescriptionControl = this.getControl('data.immigration.document.documentDescription');
    const sevisIDControl = this.getControl('data.immigration.document.sevisID');
    const citizenshipCertificateControl = this.getControl('data.immigration.document.certificateOfCitizenshipNumber');
    const naturalizationCertificateNumberControl = this.getControl('data.immigration.document.naturalizationCertificateNumber');
    const alienRegistrationControl = this.getControl('data.immigration.document.registrationNumber');
    const countryIssuanceControl = this.getControl('data.immigration.document.countryIssuance');
    const cardNumberControl = this.getControl('data.immigration.document.cardNumber');
    const i94Control = this.getControl('data.immigration.document.i94');
    const passPortExpDt = this.getControl('data.immigration.document.expDate');
    if (val.code === 'OTR') {
      countryIssuanceControl.disable();
      passPortExpDt.disable();
      docTypeControl.disable();
      passportControl.setValidators([Vs.passportNumber]);
      sevisIDControl.setValidators([Vs.sevisID]);
      citizenshipCertificateControl.setValidators([Vs.certificateNumber]);
      naturalizationCertificateNumberControl.setValidators([Vs.naturalizationNumber]);
      naturalizationCertificateNumberControl.updateValueAndValidity();
      alienRegistrationControl.setValidators([Vs.alienNumber]);
      i94Control.setValidators([Vs.i94]);
      cardNumberControl.setValidators([Vs.cardNumber]);
      countryIssuanceControl.clearValidators();
      documentDescriptionControl.setValidators([Vs.required, Vs.documentDescription]);
      this.documentDescription = true;
    } else {
      if (val.code &&(val.code === 'UA' || val.code === 'NA')) {
        docTypeControl.disable();
        statusGainDate.disable();
      }
      this.documentDescription = false;
      passportControl.setValidators([Vs.passportNumber])
      sevisIDControl.setValidators([Vs.sevisID])
      citizenshipCertificateControl.setValidators([Vs.certificateNumber])
      naturalizationCertificateNumberControl.setValidators([Vs.naturalizationNumber])
      alienRegistrationControl.setValidators([Vs.alienNumber])
      cardNumberControl.setValidators([Vs.cardNumber])
      i94Control.setValidators([Vs.i94])
      documentDescriptionControl.clearValidators()
    }
  }

  isInvalidDate(event) {
    const enteredDate = event.target.value;
    if ( !enteredDate || enteredDate === '' || enteredDate === 'Invalid date' ) {
      event.target.value = enteredDate;
    } else if (event.target.name === 'statusGainDate') {
      event.target.value = formatDate (this.getControl('data.immigration.statusGainDate').value , 'MM/dd/yyyy' , 'en');
    } else if (event.target.name === 'expDate') {
      event.target.value = formatDate (this.getControl('data.immigration.document.expDate').value , 'MM/dd/yyyy' , 'en');
    }
  }
}
