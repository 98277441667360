import { NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { NgxsModule, Store } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { construct } from 'ramda';

import { ServicesModule } from '@app/services/services.module';

import { ApplicationState } from './app/app.state';
import { ApplicationFacade } from './app/app.facade';

import { SectionState } from './section/section.state';
import { SectionFacade } from './section/section.facade';

import { PeopleState } from './people/people.state';
import { PeopleFacade } from './people/people.facade';

import { NgxsResetPluginModule } from 'ngxs-reset-plugin';

@NgModule({
  imports: [
    ServicesModule,
    NgxsModule.forRoot([ApplicationState, SectionState, PeopleState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot()
  ],
  providers: [
    {
      provide: ApplicationFacade,
      useFactory: construct(ApplicationFacade),
      deps: [Store, Router],
    },
    {
      provide: SectionFacade,
      useFactory: construct(SectionFacade),
      deps: [Store],
    },
    {
      provide: PeopleFacade,
      useFactory: construct(PeopleFacade),
      deps: [Store],
    },
  ],
})
export class StateModule {}
