import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/gateway/services/api.service';
import Vs from '@app/services/validators/validators.service';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChangeSecurityQuestionsService } from './change-security-questions.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'nh-change-security-questions',
  templateUrl: './change-security-questions.component.html',
  styleUrls: ['./change-security-questions.component.scss']
})
export class ChangeSecurityQuestionsComponent implements OnInit {

  questionSet1: any;
  questionSet2: any;
  questionSet3: any;
  questionsArray = [];
  currentLang;

  updateSecurityQuestions = new FormGroup({
    question1: new FormControl('', Vs.required),
    answer1: new FormControl('', Vs.answer),
    question2: new FormControl('', Vs.required),
    answer2: new FormControl('', Vs.answer),
    question3: new FormControl('', Vs.required),
    answer3: new FormControl('', Vs.answer),
  })

  constructor(private apiService: ApiService, public translateService: TranslateService, private changeSecurityQuestionsService: ChangeSecurityQuestionsService, private titleService: Title) {
    translateService.onDefaultLangChange.subscribe((res) => {
      this.switchLanguage(res.lang);
    });
    this.titleService.setTitle('Change security Questions');
    this.changeSecurityQuestionsService.updateSecurityQuestions = this.updateSecurityQuestions;
  }

  get updateSecurityQuestionsForm() {
    return this.updateSecurityQuestions.controls;
  }
  get answer1() {
    return this.updateSecurityQuestionsForm.answer1;
  }
  get answer2() {
    return this.updateSecurityQuestionsForm.answer2;
  }
  get answer3() {
    return this.updateSecurityQuestionsForm.answer3;
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.defaultLang === 'sp' ? 'ES' : 'EN';
    this.changeSecurityQuestionsService.currentLang = this.currentLang;
    const url = 'publ/reference-tables/retrieve?table=SECURITYQUESTIONS';
    this.apiService.get(url).subscribe((res) => {
      res.columnDetails.forEach((item) => {
        const subset = (({ code, enDescription, spDescription }) => ({ code, enDescription, spDescription }))(item);
        subset['value'] = subset['enDescription'];
        this.questionsArray.push(subset);
      });
       this.questionSet1 = this.questionsArray.slice(0, 9).sort(function(a, b) {
          return a.value.localeCompare(b.value);
       });
       this.questionSet2 = this.questionsArray.slice(10,19).sort(function(a, b) {
          return a.value.localeCompare(b.value);
       });
       this.questionSet3 = this.questionsArray.slice(20, this.questionsArray.length-1).sort(function(a, b) {
          return a.value.localeCompare(b.value);
       });
    });
  };

  switchLanguage(lang) {
    this.currentLang = lang;
    this.questionSet1 = this.questionSet2 = this.questionSet3 = [];

    if (lang === 'sp' && this.questionsArray) {
      this.questionsArray.forEach(item => {
        item.value = item.spDescription;
      });
      this.questionSet1 = this.questionsArray.slice(0, 9).sort(function(a, b) {
         return a.value.localeCompare(b.value);
      });
      this.questionSet2 = this.questionsArray.slice(10, 19).sort(function(a, b) {
         return a.value.localeCompare(b.value);
      });
      this.questionSet3 = this.questionsArray.slice(20, this.questionsArray.length - 1).sort(function(a, b) {
         return a.value.localeCompare(b.value);
      });
    } else if (this.questionsArray) {
      this.questionsArray.forEach(item => {
        item.value = item.enDescription;
      });
      this.questionSet1 = this.questionsArray.slice(0, 9).sort(function(a, b) {
         return a.value.localeCompare(b.value);
      });
      this.questionSet2 = this.questionsArray.slice(10, 19).sort(function(a, b) {
         return a.value.localeCompare(b.value);
      });
      this.questionSet3 = this.questionsArray.slice(20, this.questionsArray.length - 1).sort(function(a, b) {
         return a.value.localeCompare(b.value);
      });
    }
  };

  answerUpdate(ans2, ans3, ans1) {
    if (ans1.value && [ans2.value, ans3.value].indexOf(ans1.value) >= 0) {
      ans1.setErrors({ ANSWER_DUPLICATE: true })
    } else {
      if ([ans2.value, ans3.value].indexOf(ans1.value) < 0 && [ans3.value, ans1.value].indexOf(ans2.value) < 0 && [ans1.value, ans2.value].indexOf(ans3.value) < 0
      && ans1.value.length >= 3 && ans2.value.length >= 3 && ans3.value.length >= 3) {
        [ans1, ans2, ans3].forEach(ans => ans.setErrors(null));
      }
      if ([[ans2.value, ans3.value].indexOf(ans1.value) < 0] && ans1.value.length >= 3) {
        ans1.setErrors(null);
      }
      if ([ans3.value, ans1.value].indexOf(ans2.value) < 0 && ans2.value.length >= 3) {
        ans2.setErrors(null);
      }
      if ([ans1.value, ans2.value].indexOf(ans3.value) < 0 && ans3.value.length >= 3) {
        ans3.setErrors(null);
      }
      !ans1.value ? ans1.setErrors({required: true}) :ans1.value.length < 3 ? ans1.setErrors({INVALID_ANSWER: true}) : null;
    }
  }
}
