import { isNil } from 'ramda';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Tables } from '@app/models/tables.model';
import { isMilitary } from '@app/helpers/models.helpers';
import { disableReset, disableResetControls, enableReset, enableResetControls, resetDisableControls, resetEnableControls } from '@app/helpers/reset-able.helpers';
import { isUndefined } from 'ramda-adjunct';
import { reset } from '@app/helpers/reset.helpers';
@Component({
  selector: 'nh-city-state',
  templateUrl: './city-state.component.html',
})
export class CityStateComponent implements OnInit {
  @Input() address: FormGroup;
  @Input() format: FormControl;
  @Input() military: boolean;
  @Input() addressFieldMandatory = true;
  @Input() tables: Tables | any;
  @Input() isDisabled = false;
  @Input() isAssistingPerson = false;
  @Input() showPopup = false;
  @Input() stateDefault = true;
  @Input() hideCounty: boolean = false;
  @Input() addressname: string;
  isMilitary;
  showCounty = true;
  index;
  stateData:any;
  appType;

  ngOnInit(): void {
    this.appType = sessionStorage.getItem('appType');

    const bottomList = ['AS', 'FM', 'GU', 'MH', 'NI', 'PR', 'PW', 'VI'];

    for (let i = 0; i < this.tables.STATE.length; i ++) {
      if (this.tables.STATE[i].code === "TN") {
       this.tables.STATE.splice(0 , 0 , this.tables.STATE[i]);
       this.tables.STATE.splice(i + 1 , 1);

      }
    }

    bottomList.forEach(element => {
      const index = this.tables.STATE.findIndex(i => i.code == element);
      if (index != -1) {
        const item = this.tables.STATE.splice(index, 1);
        this.tables.STATE.push(item[0]);
      }
    });

    if (this.format) {
      if (isUndefined(this.format.value) || isNil(this.format.value)) {
        this.format.patchValue({code: "US", description: "US", spDescription: "EEUU", value: "US"});
        const { addrApoFpo, addrAaAeAp, county } = this.address.controls;
        addrApoFpo.disable();
        addrAaAeAp.disable();
        this.setState();
        if (this.isAssistingPerson === true) {
          county.disable()
        }
      } else if (this.format.value) {
        if (isMilitary(this.format.value)) {
          const { city, state, county } = this.address.controls;
          this.isMilitary = true;
          city.disable();
          state.disable();
          county.disable();
        } else if (!isMilitary(this.format.value)) {
          const { addrApoFpo, addrAaAeAp, county } = this.address.controls;
          addrApoFpo.disable();
          addrAaAeAp.disable();
          if (this.isAssistingPerson === true) {
            county.disable();
          }
          if (this.address.value.state?.code === 'TN') {
            this.showCounty = true;
            this.address.controls.county.enable();
          } else {
            this.showCounty = false;
            this.address.controls.county.disable();
          }
        }
      }

      this.format.valueChanges.subscribe(value => {
        if (this.appType !== 'APL') {
        const addressControls = this.address.controls;
        const { city, state, county, zip, addrApoFpo, addrAaAeAp } = addressControls;
        const whenMilitary = [addrApoFpo, addrAaAeAp];
        const whenNotMilitary = [city, state, county];

        this.isMilitary = isMilitary(value);
        if (value) {
          if (this.address.status === 'DISABLED') {
            return null;
          } else {
            if (this.isMilitary) {
              resetEnableControls(whenMilitary);
              resetDisableControls(whenNotMilitary);
              zip.reset()
            } else {
              enableResetControls(whenNotMilitary);
              disableResetControls(whenMilitary);
              this.setState();
              zip.reset();
              this.address.controls?.county?.markAsPristine();
              this.address.controls?.county?.markAsUntouched();
              if (this.isAssistingPerson === true) {
                county.disable();
              }
            }
          }
        }
      } else {
        this.isMilitary = isMilitary(value);
      }
      });
    } else {
      const { addrApoFpo, addrAaAeAp, county } = this.address.controls;
      if (this.address.value.state?.code === 'TN') {
        this.showCounty = true;
        this.address.controls?.county?.enable();
      } else {
        this.showCounty = false;
        this.address.controls?.county?.disable();
        if(addrApoFpo || addrAaAeAp || county){
          resetDisableControls([addrApoFpo, addrAaAeAp, county]);
        }

      }
      if (this.isAssistingPerson === true) {
        this.address.controls?.county?.disable();
      }
    }
    this.setState();
  }
  setState () {
    if (this.stateDefault && this.address.value.state === null) {
      this.address.controls.state.patchValue({ code: 'TN', value: 'Tennessee'});
    } else if (!this.stateDefault && this.address.value.state === null) {
      reset(this.address.controls.state);
    }

    if (this.address.value.state !== null) {
      const selectedState = this.tables.STATE.filter(s => s.code == this.address?.value?.state?.code)[0];
      this.address.controls.state.patchValue(selectedState);
    }
  }

  onStateChange(val) {
    if (val.code !== 'TN') {
      this.showCounty = false;
      if(this.address.controls.county) {
        disableReset(this.address.controls.county);
      }
    } else if (val.code === 'TN' && this.isAssistingPerson) {
      this.showCounty = false;
      if(this.address.controls.county) {
        disableReset(this.address.controls.county);
      }
    } else if (val.code === 'TN' && !this.isAssistingPerson && !this.hideCounty) {
      this.showCounty = true;
      if(this.address.controls.county) {
        enableReset(this.address.controls.county);
      }
    }
  }
  ngOnChanges () {
    if (this.isAssistingPerson) {
      this.address.controls?.county?.clearValidators();
      this.address.controls?.county?.updateValueAndValidity();
    }
  }
}
