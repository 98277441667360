import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { FormArray } from '@angular/forms';
import Vs from '@app/services/validators/validators.service';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { toggleTaxIndiv } from '@app/helpers/models.helpers';
import { isEmpty } from 'ramda';
import { formatTableData } from '@app/helpers/tables.helpers';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { isRmbRmc } from '@app/helpers/mode.helpers';
import { Title } from '@angular/platform-browser';
@Component({
  templateUrl: './tax-filer-outside.component.html',
  styleUrls:['./tax-filer-outside.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TaxFilerOutsideComponent extends SectionFormDirective implements AfterContentInit {
  hasIndividuals = true;
  translatedLang;
  defaultLanguage;
  isRmbRmc = false;

  constructor (private titleService: Title, builder: FormBuilder, public translateService: TranslateService) {
    super(builder);
    this.titleService.setTitle('Tax Filer Outside Household');
  }

  toggle(individual) {
    toggleTaxIndiv(individual);
    this.checkAtleastOneSelection();
  }

  get taxDependents(): FormArray {
    return this.getControl('data.taxDependents') as FormArray;
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  tableCodeValue (tableName, tableControl){
    if (typeof tableControl !== 'string') {
      const idx = tableControl;
      const taxFilerOutsideList = this.getControl('data.taxDependents');
      const taxFilerCtrls = taxFilerOutsideList['controls'];
      const relationControl = taxFilerCtrls[idx];
      tableControl = relationControl.controls['relationToTaxFiler'];
      }
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }

  outsideHoh(val) {
    const taxDependents = this.getControl('data.taxDependents');

    if (val?.value === 'Yes' || val?.value === 'Si') {
      if (isEmpty(this.individuals)) {
        this.hasIndividuals = false;
        taxDependents.disable();
        taxDependents.updateValueAndValidity();
      }
      this.checkAtleastOneSelection()
    } else {
      taxDependents.disable();
      this.getControl('data.hiddenInput').clearValidators();
      this.getControl('data.hiddenInput').updateValueAndValidity()
    }
  }

  ngAfterContentInit() {
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);
    this.isRmbRmc = isRmbRmc(this.mode);
    
    if (this.individuals.length === 0) {
      this.hasIndividuals = false;
    }
    const filerOutsideHoh = this.getControl('data.filerOutsideHoh');
    if(filerOutsideHoh.value.code === 'Y' || filerOutsideHoh.value.code === 'Si'){
      this.checkAtleastOneSelection()
    }
    this.registerToggle(filerOutsideHoh, val => this.outsideHoh(val));
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.languageCheck(res.lang);
      this.registerToggle(filerOutsideHoh, val => this.outsideHoh(val));
      });
  }


  checkAtleastOneSelection() {
    const data = this.getControl('data');
    if (!isEmpty(this.individuals)) {
      if (!data.value.taxDependents) {
        this.getControl('data.hiddenInput').setValidators([Vs.required]);
        this.getControl('data.hiddenInput').updateValueAndValidity()
      } else {
        this.getControl('data.hiddenInput').clearValidators();
        this.getControl('data.hiddenInput').updateValueAndValidity()
      }
    }
  }
}
