import { Component, DoCheck, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { UserProfileService } from '../../user-profile.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'case-documents',
  templateUrl: './case-documents.component.html',
  styleUrls: ['./case-documents.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CaseDocumentsComponent implements OnInit, DoCheck {
  screenHeight: number;
  screenWidth: number;
  isMobileView = false;
  mobileWidth = 480;
  documentsLoading = true;
  rows = [];
  columns = [{
    name: 'Case or Appeal Numbers', prop: 'caseNum'
  },
  { name: 'Document Type', prop: 'docType' },
  { name: 'Who This Is For', prop: 'fullName' },
  { name: 'Date of Upload', prop: 'uploadDt' }, {
    name: 'Source', prop: 'source'
  }];
  defaultLanguage;
  translatedLang;
  constructor(private datePipe: DatePipe, private userProfileService: UserProfileService, public translateService: TranslateService, private titleService: Title
    ) { 
    this.getScreenSize();
    this.titleService.setTitle('My Documents | Case Documents');
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngDoCheck() {
    this.isMobileView = this.screenWidth <= this.mobileWidth;
  }
  
  getRows(rows) {
    if (rows && rows.length) {
      for (let i = 0; rows.length > i; i++) {

        if (rows[i].source === "Mail or Fax" || rows[i].source === "Correo o fax") {
          if (this.translatedLang === 'en') {
            rows[i].source = "Mail or Fax";
          } else {
            rows[i].source = "Correo o fax"
          }
        }
      }
    }
  }

  ngOnInit() {
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.getRows(this.rows);
    });
    const indivId = sessionStorage.getItem('individualId');
    this.documentsLoading = true;
    this.userProfileService.getCaseDocuments(indivId).subscribe((res: any) => {
      this.rows = res && res.length > 0 ? res.filter(a => a.appSrc?.code === 'DM').map(item => {
        const pickDate = item.uploadDt.slice(0, 10);
        const uploadDate = this.datePipe.transform(pickDate, "MM/dd/yyyy")
        return {
          caseNum: item.appealNum ? item.appealNum : item.caseNum,
          docType: item.docType,
          fullName: item.firstName + " " + item.lastName,
          uploadDt: uploadDate,
          source: item.appSrc?.value
        } 
      }) : [];
      this.documentsLoading = false;
      this.getRows(this.rows);
    })
  }
}