<div class="container my-3 p-4">
	<div class="row">
		<div class="col-12">
			<h1 class="page-title">{{'WELCOME_BACK' | translate}}</h1>
		</div>
	</div>

	<div class="row pl-1 pb-1 hover-red">
		<div class="btn btn-link hover-red" (click)="onPreviousClick()">
			<svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
				<g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
					<path id="Combined_Shape" data-name="Combined Shape"
						d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
						transform="translate(0)" fill="#174a7c" />
				</g>
			</svg>
			<span class="pl-2">{{'BACK_TO_PREVIOUS_PAGE' | translate}}</span>
		</div>
	</div>

	<div class="bg-white p-4">
		<p *ngIf="!isKepro" class="static-text">{{'FROM_HERE' | translate}}</p>
		<p *ngIf="isKepro" class="static-text">{{'AHS_FROM_HERE' | translate}}</p>
		<div>
			<h5 class="section-header">{{'WELCOME_BACK' | translate}}</h5><hr />
			<div class="card card-body shadow mb-2" [ngClass]="{'applyColor': appType === 'continue'}" (click)="select('continue')" role="button">
				<div class="row pl-2">
					<div class="row align-items-center spl-margin pl-3"><img class="col dot" aria-label="takeMeBack" src="../../../assets/images/Icons/Start_new_app.svg"></div>
					<div *ngIf="!isKepro" class="section-column">
						<strong class="tile_header">{{'TAKE_ME_BACK' | translate}}</strong>
						<div *ngIf="applicationNum && headOfHouseholdName" class="tile-text small">{{'HEAD_OF_HOUSEHOLD' | translate}}: <span
								class="mr-1 transform">{{headOfHouseholdName}}</span>
						</div>
						<div *ngIf="applicationNum && lastAccessed" class="tile-text small">{{'LAST_ACCESSED' | translate}}:
							<span class="mr-1">{{lastAccessed | date:'MM/dd/yyyy'}}</span>
						</div>
					</div>
					<div *ngIf="isKepro" class="section-column">
						<strong class="tile_header">Continue in progress application</strong>
						<div *ngIf="headOfHouseholdName" class="tile-text small mt-3">{{'HEAD_OF_HOUSEHOLD' | translate}}:
							<span
								class="mr-1 transform">{{headOfHouseholdName}}</span><span>{{hohDob}}</span><span>{{hohGender}}</span>
						</div>
						<div *ngIf="hohSSN" class="tile-text small">SSN: <span class="mr-1 transform">{{hohSSN}}</span>
						</div>
						<div *ngIf="appStartedBy" class="tile-text small">Started By: <span
								class="mr-1 transform">{{appStartedBy}}</span>
						</div>
						<div *ngIf="appStartOn" class="tile-text small">Started On: <span
								class="mr-1 transform">{{appStartOn | date:'MM/dd/yyyy'}}</span>
						</div>
						<div *ngIf="lastAccessedBy" class="tile-text small">Last Accessed By: <span
								class="mr-1 transform">{{lastAccessedBy}}</span>
						</div>
						<div *ngIf="lastAccessed" class="tile-text small">Last Accessed On:
							<span class="mr-1">{{lastAccessed | date:'MM/dd/yyyy'}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="card card-body shadow" [ngClass]="{'applyColor': appType === 'new'}" (click)="select('new')">
				<div class="row pl-2">
					<div class="row spl-margin pl-3"><img class="col dot" aria-label="startNew" src="../../../assets/images/Icons/Take_back.svg"></div>
					<div class="section-column spl-padding"><strong class="tile_header">{{'START_NEW_APPLICATION' | translate}}</strong></div>
				</div>
			</div>

			<div *ngIf="setMessage" class="alert alert-danger" role="alert">
				{{setMessage}}
			</div>
		</div>
	</div>
	<div class="row mt-3 m-1">
		<button id="continue" class="col-md-2 btn btn-block btn-secondary" (click)="next()" [disabled]="appType === ''">{{'NEXT' | translate}}</button>
	</div>

</div>