import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationService } from "@app/services/application/application.service";
import { UserProfileService } from "../user-profile.service";
import { contains } from 'ramda';
import { ApiService } from "../../services/api.service";
import { isPartner } from '@app/helpers/mode.helpers';
import { Title } from '@angular/platform-browser';
import { StateService } from '@app/services/state/state.service';

@Component({
  selector: "renew-benefits",
  templateUrl: "./renew-benefits.component.html",
  styleUrls: ["./renew-benefits.component.scss"],
})
export class RenewBenefitsComponent implements OnInit {
  rows;
  columns = [{ name: "Case Number", prop: "caseNum" }, { name: "Due Date", prop: "renewalDueDate" }];
  indivId;
  response;
  userName;
  indivEncId;
  isPartner;
  applicationNum;
  renewalNudgeAlert = false;

  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private app: ApplicationService,
    private apiService: ApiService,
    private titleService: Title,
    private stateService: StateService,
  ) {
    this.titleService.setTitle('Renew Benefits');
  }

  ngOnInit(): void {
    this.userName = sessionStorage.getItem("userName");
    this.indivEncId = sessionStorage.getItem("individualId");
    const userType = sessionStorage.getItem('userType');
    this.isPartner = isPartner(userType);
    this.app.getHeadOfHouseholdInfo(0,'APL').subscribe((res: any) => {
      this.applicationNum = res?.applicationNumber;
      if (this.applicationNum) {
          this.app.deleteApp(this.applicationNum).subscribe(() => {});
          this.stateService.resetStates();
        }
      })
    if (this.userName) {
      const url = 'publ/signin/validate/info?userId=' + this.userName;
      this.apiService.get(url).subscribe((res) => {
        if (res && !this.isPartner) {
          this.indivId = res.individualId.toString();
        } else {
          this.indivId = sessionStorage.getItem("individualId");
        }
      this.userProfileService
      .getBenefitDashboard(this.indivEncId)
      .subscribe((res: any) => {
        if (res) {
          this.response = { ...res };
          if (res.cmbSummaryResponse && res.cmbSummaryResponse.caseSummaryResponse.length > 0) {
            this.renewalNudgeAlert =  res.cmbSummaryResponse?.caseSummaryResponse[0]?.recentRenewalSW === 'Y' ? true: false;
            this.rows = [...res.cmbSummaryResponse.caseSummaryResponse];
            this.rows.forEach((i) => {
              const searchedIndv = i.indvSummaryResponse.filter(indv => indv.indvId === this.indivId);
              i['isKbA'] = searchedIndv?.filter(data => (( ((data.programStatus?.length && data.programStatus[0]?.coeCode === 'K01') && (this.getAge(data.dob)<=18)) || ((data.programStatus?.length && data.programStatus[0]?.coeCode === 'K03') && (this.getAge(data.dob)<=18)) )
                                                            && data.programStatus?.length && data.programStatus[0]?.edgStatus !== 'DN')).length > 0;
              if(i['isKbA']){
                i.hoHID = this.indivId;
              }
              const eligibleCodes = ["SSI", "SST"];
              searchedIndv.find(record => {
                return i['isRenewSsiEligble'] = contains(record.programStatus?.length && record.programStatus[0]?.coeCode, eligibleCodes);
              })
            })
          }
        }
      });
    });
  }
  }

  renewalWelcomePage(row) {
    sessionStorage.setItem("appType", "RB");
    sessionStorage.setItem("caseNum", row.caseNum);
    this.app
      .getHeadOfHouseholdInfo(row.caseNum, "REDETERMINATION")
      .subscribe((res: any) => {
        if (res && res?.applicationNumber) {
          sessionStorage.setItem('appNum', res?.applicationNumber)
          this.router.navigateByUrl("/renewMyBenefit?caseNumber=" + row.caseNum);
        } else {
          this.router.navigateByUrl("/application/" + "REDETERMINATION");
        }
      });
  }

  getAge = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
        age--;
    }    
    return age;
  }
}
