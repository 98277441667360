import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { sessionStore } from '@app/helpers/dom.helpers';

import { Nullable } from '@app/models/partials.model';
import { ApplicationNumber, ApplicationType, CaseNumber, IndividualId, Token } from '@app/models/app.model';
import { BehaviorSubject, Observable } from 'rxjs';
/**
 * Manage storage
 *
 */
@Injectable()
export class StorageService implements Storage {
  private _engine: Storage;
  private isAddPersonClicked$ = new BehaviorSubject<boolean>(false);

  public getAppNumber(): ApplicationNumber {
    return this.getItem('appNum');
  }

  public setAppNumber(appNum: ApplicationNumber) {
    this.setItem('appNum', appNum as string);
  }

  public getIndividualId(): IndividualId {
    return this.getItem('individualId');
  }

  public setIndividualId(individualId: IndividualId) {
    this.setItem('individualId', individualId as string);
  }

  public getCaseNumber(): CaseNumber {
    return this.getItem('caseNum');
  }

  public setCaseNumber(caseNum: CaseNumber) {
    this.setItem('caseNum', caseNum as string);
  }

  public getToken(): Token {
    return this.getItem('tokenId');
  }

  public setToken(token: string) {
    this.setItem('tokenId', token);
  }

  public getApplicationType(): ApplicationType {
    return this.getItem('appType');
  }

  public setApplicationType(applicationType: ApplicationType) {
    this.setItem('appType', applicationType as string);
  }

  /**
   * @constructor
   * @param {HTMLDocument} _document Access Web API
   *
   */
  constructor(@Inject(DOCUMENT) private _document: HTMLDocument) {
    this._engine = sessionStore(this._document);
  }

  public getItem(key: string): Nullable<string> {
    return this._engine.getItem(key);
  }

  public setItem(key: string, value: string): void {
    this._engine.setItem(key, value);
  }

  public removeItem(key: string): void {
    this._engine.removeItem(key);
  }

  public key(index: number): Nullable<string> {
    return this._engine.key(index);
  }

  public get length(): number {
    return this._engine.length;
  }

  public clear(): void {
    this._engine.clear();
  }

  public setAddPersonClicked(val: boolean): void {
    this.isAddPersonClicked$.next(val);
  }

  public getAddPersonClicked(): Observable<boolean> {
    return this.isAddPersonClicked$.asObservable();
  }
}
