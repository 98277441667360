import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SectionFormDirective } from '@app/classes/section-form.class';
import Vs from "@app/services/validators/validators.service";
import { minus120Years, after10Years } from '@app/helpers/date.helpers';
import { DatePipe } from "@angular/common";

@Component({
  templateUrl: './bccExtensionInformation.component.html',
  styleUrls: ["./bccExtensionInformation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class BccExtensionInformation extends SectionFormDirective implements AfterContentInit {

  name;
  ssn;
  formattedSSN;
  dob;
  gender;
  checkboxValue;
  enablefollowupApptDt = false;
  minimumDate = minus120Years();
  maximumDate = after10Years(new Date());

  constructor(public builder: FormBuilder, private titleService: Title, private datePipe: DatePipe,) {
    super(builder)
    this.titleService.setTitle('Apply For Coverage');
  };

  requestExtension(event, otherField) {
    console.log(event);
    otherField.checked = false;

    if (event.target.checked) {
      const targetValue = event.target.value === 'Yes' ? 'Y' : 'N';
      (this.formGroup.controls.data as any)
        .controls.extensionFlag.setValue({ 'code': targetValue, 'value': event.target.value });
      if (targetValue === 'Y') {
        this.getControl('data.followupApptDt').reset();
        this.getControl('data.followupApptDt').enable();
        this.getControl('data.followupApptDt').updateValueAndValidity();
        this.getControl('data.reasonForExtension').reset();
        this.getControl('data.reasonForExtension').enable();
        this.getControl('data.reasonForExtension').setValidators(Vs.required);
        this.getControl('data.reasonForExtension').updateValueAndValidity();
        this.getControl('data.confirmeBCC').clearValidators();
        this.getControl('data.confirmeBCC').updateValueAndValidity();
        this.enablefollowupApptDt = true;
      } else {
        this.getControl('data.followupApptDt').disable();
        this.getControl('data.followupApptDt').updateValueAndValidity();
        this.getControl('data.reasonForExtension').disable();
        this.getControl('data.reasonForExtension').clearValidators();
        this.getControl('data.reasonForExtension').updateValueAndValidity();
        this.getControl('data.confirmeBCC').setValidators([Vs.required]);
        this.getControl('data.confirmeBCC').updateValueAndValidity();
        this.enablefollowupApptDt = false;
      }
    }
    else {
      (this.formGroup.controls.data as any)
        .controls.reasonForExtension.setValue({ 'code': '', 'value': null });
    }
  }



  ngAfterContentInit() {
    const bccfristName = this.getValue('data.firstName');
    const bcclastName = this.getValue('data.lastName');
    this.name = bccfristName && bcclastName ? bccfristName + " " + bcclastName : "";
    const bccdob = this.getValue('data.dob');
    this.dob = bccdob ? this.datePipe.transform(bccdob, "MM/dd/yyyy") : "";
    this.ssn = this.getValue('data.ssn');
    const bccgender = this.getValue('data.gender');
    this.gender = bccgender ? bccgender === 'M' ? "Male" : "Female" : "";
    this.checkboxValue = (this.formGroup.controls.data as any)
      .controls.extensionFlag?.value;
    this.enablefollowupApptDt = this.checkboxValue?.code === 'Y' ? true : false;
    const lastFourSSN = this.ssn !== "" ? this.ssn.toString().slice(5) : "";
    this.formattedSSN = this.ssn !== "" ? "XXX-XX-" + lastFourSSN : "N/A";
  }
}