import { Component, AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { isExportStatusNotEmpty, isExported, isRmbRmc } from '@app/helpers/mode.helpers';
import { formatTableData } from '@app/helpers/tables.helpers';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ApplicationService } from '@app/services/application/application.service';

@Component({
  templateUrl: './utilities.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UtilitiesComponent extends SectionFormDirective implements AfterContentInit {
  expenseFrequencyCodes: any = [];
  isHousingTypeExpenseDisabled: any = null;
  isOwnHomeExpenseTypeDisabled: any = null;
  translatedLang;
  defaultLanguage;
  isRmbRmc = false;
  otherHealthCareList;
  answeredBrstCervCancerSw;
  answeredEcfSw;
  answeredHcbsSw;
  answeredHospiceSw;
  answeredIntelDisbSw;
  answeredMedFacilitySw;
  answeredeMedicalBillsIndSw;
  answeredMedicareIndSw;
  answeredNursingHomeSw;
  answeredSsiBenefitsSw;
  finalListOfIndividuals = [];
  spousalRelationMedicare;
  finalParentIndvsForLessThanEighteenMedicare;
  noMedicalBills;
  answeredKtBeckettSw;
  ktBeckettIndividuals;
  spousalRelationForOtherSwitches;
  relationshipList;
  otherHealthCareListData;
  finalListIndvs;
  brstCervCancerSwChecked;
  ecfSwChecked;
  hcbsSwChecked;
  hospiceSwChecked;
  intelDisbSwChecked;
  medFacilitySwChecked;
  medicalBillsIndSwChecked;
  medicareIndSwChecked;
  nursingHomeSwChecked;
  ssiBenefitsSwChecked;
  otherIndvsCheckedMedicare;
  spousalRelation;
  otherSwitchesSpouse = [];
  otherSwitchesCheckedIndvs;
  otherSwitchesCheckedIndviduals =  [];
  user = sessionStorage.getItem('userType');
  appType = sessionStorage.getItem('appType');
  isAFBorFC = (this.appType === 'AFB') || (this.user == 'RP' && (this.appType === 'AFB'));
  checkForDropdown: boolean = false;

  
  constructor(
    builder: FormBuilder,
    public translateService: TranslateService,
    private titleService: Title,
    private application: ApplicationService,
    private cd: ChangeDetectorRef
  ) {
    super(builder);
    this.titleService.setTitle('Utilities');
  }

  ngAfterContentInit() {
    this.checkForDropdown = (this.isAFBorFC && this.members.length > 1);
    this.isRmbRmc = isRmbRmc(this.mode)
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);

    this.translateService.onDefaultLangChange.subscribe((res) => {
    this.translatedLang = res.lang;
    this.languageCheck(res.lang);
    });
    if (this.record && isExported(this.record.exportingStatus) && isExportStatusNotEmpty(this.record.exportingStatus)) {
      this.isHousingTypeExpenseDisabled = true;
    } 


    //code for filtering the dropdowns for checked individuals on gatepost questions
    //only if AFB or FC and no. of people on the application is > 1
    if(this.isAFBorFC && this.members.length > 1){
      this.application.getSection(this.applicationNumber, 'AIBAO').subscribe(rtn => {
        if (rtn.records.length > 0) {
          const { records } = rtn;
          this.otherHealthCareListData = records[0].data;
          if (this.members.length > 1) {
            this.getGatePostQuestionsDetails(this.otherHealthCareListData);

            this.application.getSection(this.applicationNumber, 'HHRLS').subscribe(rtn => {
              if (rtn.records.length > 0) {
                const { records } = rtn;
                this.relationshipList = records[0].data.relationshipList;
                if (this.members.length > 1) {
                  this.getRelnAndCheckedIndvsMedicareAndOtherSwitches(this.relationshipList);
                  this.finalList();
                };
              }
            });

          };
        }
      });

      this.ktBeckettIndividualsList();
    }
    
  }



  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  tableCodeValue (tableName, tableControl){
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }


  getRelnAndCheckedIndvsMedicareAndOtherSwitches(relationshipList ) {
    this.filteredRelationshipsMedicare(relationshipList);
    const otherSwitchesArray = [this.ecfSwChecked, this.hcbsSwChecked, this.hospiceSwChecked, this.intelDisbSwChecked, this.medFacilitySwChecked, this.medicalBillsIndSwChecked, this.nursingHomeSwChecked, this.ssiBenefitsSwChecked];
    
    for(let i = 0; i < otherSwitchesArray.length; i++){
      this.spouseAndCheckedIndvsForOtherSwitches(relationshipList,otherSwitchesArray[i]);
    };

    this.otherSwitchesSpouse = this.otherSwitchesSpouse.reduce((acc, val) => acc.concat(val), []);
    this.otherSwitchesCheckedIndviduals = this.otherSwitchesCheckedIndviduals.reduce((acc, val) => acc.concat(val), []);
  };


  filteredRelationshipsMedicare(relationshipList){
    //spousal relation for individuals checked for Medicare
    if(this.medicareIndSwChecked.length > 0){
      this.spousalRelationMedicare = this.medicareIndSwChecked.map((e)=>{
        let tempM = relationshipList.filter(element=> ((element.sourceIndividual === e) || (element.referenceIndividual === e)) &&
                                                      ((element.relation.code === 'SPO') ))
        return tempM;
      });

      this.spousalRelationMedicare = (this.spousalRelationMedicare.map(item => 
                      ({"source" : item[0]?.sourceIndividual, "reference":item[0]?.referenceIndividual})).map(data => Object.values(data)));

      this.spousalRelationMedicare = (this.spousalRelationMedicare.reduce((acc, val) => acc.concat(val), [])).filter(data => data !== undefined);
      console.log(this.spousalRelationMedicare);


      //parent or step-parent relation for individuals checked for Medicare
      let ageOfIndividualsLessThanEighteen = this.individuals.filter(item => item.age < 18).map(data => JSON.parse(data.identifier));
      let ageOfIndividualsLessThanEighteenChecked = this.medicareIndSwChecked.map((e)=>{
        let tempC = ageOfIndividualsLessThanEighteen.filter(element=> (element === e))
        return tempC;
      });
      ageOfIndividualsLessThanEighteenChecked = (ageOfIndividualsLessThanEighteenChecked.reduce((acc, val) => acc.concat(val), [])).filter(data => data !== undefined);
      console.log(ageOfIndividualsLessThanEighteenChecked);

      let parentalRelationForLessThanEighteen = ageOfIndividualsLessThanEighteenChecked.map((e)=>{
          let temp = relationshipList.filter(element=> ((element.sourceIndividual === e) || (element.referenceIndividual === e)) &&
                                                        ((element.relation.code === 'FAO') || (element.relation.code === 'MOO') ||
                                                          (element.relation.code === 'SFA') || (element.relation.code === 'SMO') ||
                                                          (element.relation.code === 'SDA') || (element.relation.code === 'SSO') ||
                                                          (element.relation.code === 'DAU') || (element.relation.code === 'SON') ))
          return temp;
      });

      parentalRelationForLessThanEighteen = parentalRelationForLessThanEighteen.reduce((acc, val) => acc.concat(val), []);

      let listParentIndvsForLessThanEighteen = (parentalRelationForLessThanEighteen.map(item => ({"source" : item.sourceIndividual, "reference":item.referenceIndividual})).map(data => Object.values(data)));
      listParentIndvsForLessThanEighteen = listParentIndvsForLessThanEighteen.reduce((acc, val) => acc.concat(val), []);

      this.finalParentIndvsForLessThanEighteenMedicare = listParentIndvsForLessThanEighteen.filter((c, index) => {
        return listParentIndvsForLessThanEighteen.indexOf(c) === index;
      });


      //other individuals checked for Medicare
      this.otherIndvsCheckedMedicare = this.medicareIndSwChecked;
    }

    console.log(this.spousalRelationMedicare, this.finalParentIndvsForLessThanEighteenMedicare);
  }



  spouseAndCheckedIndvsForOtherSwitches(relationshipList, switchName){
  // spouse and other checked indviduals for ecfSwChecked, hcbsSwChecked, hospiceSwChecked, 
  // intelDisbSwChecked, medFacilitySwChecked, medicalBillsIndSwChecked, nursingHomeSwChecked, ssiBenefitsSwChecked

    if(switchName.length > 0){
      this.spousalRelation = switchName.map((e)=>{
        let tempM = relationshipList.filter(element=> ((element.sourceIndividual === e) || (element.referenceIndividual === e)) &&
                                                       ((element.relation.code === 'SPO') ))
        return tempM;
      });

      this.spousalRelation = (this.spousalRelation.map(item => 
        ({"source" : item[0]?.sourceIndividual, "reference":item[0]?.referenceIndividual})).map(data => Object.values(data)));

      this.spousalRelation = (this.spousalRelation.reduce((acc, val) => acc.concat(val), [])).filter(data => data !== undefined);
      console.log(this.spousalRelation);

      this.otherSwitchesCheckedIndvs = switchName.filter( x => !this.spousalRelation.includes(x) );
    };

    if(switchName.length > 0){
      if(this.spousalRelation.length > 0){
        this.otherSwitchesSpouse.push(this.spousalRelation);
      }
      if(this.otherSwitchesCheckedIndvs.length > 0){
        this.otherSwitchesCheckedIndviduals.push(this.otherSwitchesCheckedIndvs);
      }
    };

  };


  ktBeckettIndividualsList(){
    this.ktBeckettIndividuals = this.individuals.filter(item => item?.age >= 18).map(data => JSON.parse(data?.identifier));
    console.log(this.ktBeckettIndividuals);
  }


  getGatePostQuestionsDetails(otherHealthCareListData) {
    const otherHealthCareList = otherHealthCareListData?.otherBenefitsIndvProfileData;
    this.otherHealthCareList = otherHealthCareList;
    this.answeredYesToGatePostQuestions(otherHealthCareListData);
  }


  answeredYesToGatePostQuestions(otherBenefitsDataRecord){
    //list of individuals who checked for each switch in gatepost questions
    const otherBenefitsData = otherBenefitsDataRecord?.otherBenefitsIndvProfileData;
    this.brstCervCancerSwChecked = otherBenefitsData.filter(data => data.brstCervCancerSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.ecfSwChecked = otherBenefitsData.filter(data => data.ecfSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.hcbsSwChecked = otherBenefitsData.filter(data => data.hcbsSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.hospiceSwChecked = otherBenefitsData.filter(data => data.hospiceSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.intelDisbSwChecked = otherBenefitsData.filter(data => data.intelDisbSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.medFacilitySwChecked = otherBenefitsData.filter(data => data.medFacilitySw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.medicalBillsIndSwChecked = otherBenefitsData.filter(data => data.medicalBillsIndSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.medicareIndSwChecked = otherBenefitsData.filter(data => data.medicareIndSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.nursingHomeSwChecked = otherBenefitsData.filter(data => data.nursingHomeSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.ssiBenefitsSwChecked = otherBenefitsData.filter(data => data.ssiBenefitsSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    

    //what gatepost questions are answered as yes
    this.answeredKtBeckettSw = otherBenefitsDataRecord?.kbReferralSw?.code === 'Y';
    let brstCervCancerSw = otherBenefitsData.map(item => item?.brstCervCancerSw?.code === 'Y');
    let ecfSw = otherBenefitsData.map(item => item?.ecfSw?.code === 'Y');
    let hcbsSw = otherBenefitsData.map(item => item?.hcbsSw?.code === 'Y');
    let hospiceSw = otherBenefitsData.map(item => item?.hospiceSw?.code === 'Y');
    let intelDisbSw = otherBenefitsData.map(item => item?.intelDisbSw?.code === 'Y');
    let medFacilitySw = otherBenefitsData.map(item => item?.medFacilitySw?.code === 'Y');
    let medicalBillsIndSw = otherBenefitsData.map(item => item?.medicalBillsIndSw?.code === 'Y');
    let medicareIndSw = otherBenefitsData.map(item => item?.medicareIndSw?.code === 'Y');
    let nursingHomeSw = otherBenefitsData.map(item => item?.nursingHomeSw?.code === 'Y');
    let ssiBenefitsSw = otherBenefitsData.map(item => item?.ssiBenefitsSw?.code === 'Y');

 
    this.answeredBrstCervCancerSw = brstCervCancerSw.some(data => data === true);
    this.answeredEcfSw = ecfSw.some(data => data === true);
    this.answeredHcbsSw = hcbsSw.some(data => data === true);
    this.answeredHospiceSw = hospiceSw.some(data => data === true);
    this.answeredIntelDisbSw = intelDisbSw.some(data => data === true);
    this.answeredMedFacilitySw = medFacilitySw.some(data => data === true);
    this.answeredeMedicalBillsIndSw = medicalBillsIndSw.some(data => data === true);
    this.answeredMedicareIndSw = medicareIndSw.some(data => data === true);
    this.answeredNursingHomeSw = nursingHomeSw.some(data => data === true);
    this.answeredSsiBenefitsSw = ssiBenefitsSw.some(data => data === true);
  }


  finalList(){
    //Final List of Individuals to be displayed in the dropdown
    if(this.answeredMedicareIndSw){
      this.finalListOfIndividuals = this.finalListOfIndividuals.concat(this.spousalRelationMedicare).concat(this.finalParentIndvsForLessThanEighteenMedicare).concat(this.otherIndvsCheckedMedicare);
    }

    if(this.answeredKtBeckettSw){
      this.finalListOfIndividuals = this.finalListOfIndividuals.concat(this.ktBeckettIndividuals);
    }

    if(this.answeredEcfSw || this.answeredHcbsSw || this.answeredHospiceSw || this.answeredIntelDisbSw || this.answeredMedFacilitySw || this.answeredeMedicalBillsIndSw || this.answeredNursingHomeSw || this.answeredSsiBenefitsSw){
      this.finalListOfIndividuals = this.finalListOfIndividuals.concat(this.otherSwitchesSpouse).concat(this.otherSwitchesCheckedIndviduals);
    }

    const finalListIndvsData = this.finalListOfIndividuals.filter((c, index) => {
      return this.finalListOfIndividuals.indexOf(c) === index;
    });

    
    this.finalListIndvs = finalListIndvsData.map((e)=>{
      let temp = this.individuals.filter(element=> ((JSON.parse(element.identifier) === e)))
      return temp;
    });

    this.finalListIndvs = this.finalListIndvs.reduce((acc, val) => acc.concat(val), []);
    this.cd.detectChanges();

    console.log(this.finalListIndvs);

  }



}