import { Component, AfterContentInit } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { FormBuilder } from '@angular/forms';
import { isNil } from 'ramda';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './sign-submit.component.html',
  styleUrls: ["./sign-submit.component.scss"],
})

export class SignSubmitComponent extends SectionFormDirective implements AfterContentInit {
  isShowSign: boolean;
  isShowPartner: boolean;
  constructor(builder: FormBuilder, private titleService: Title) {
    super(builder)
    this.titleService.setTitle('Sign Your Application');
  }

  userType = sessionStorage.getItem('userType');

  ngAfterContentInit() { 
    const appSignName = this.getControl('data.applicationSignName');
    const partnerSignName = this.getControl('data.partnerSignName');

    this.registerToggle(appSignName, code => isNil(code) ? this.isShowSign = false : this.isShowSign = true);
    this.registerToggle(partnerSignName, code => isNil(code) ? this.isShowPartner = false : this.isShowPartner = true);
  }
}