import { AfterContentInit } from '@angular/core';
import { Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { NgControl } from '@angular/forms';

import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { defineLocale, enGbLocale, esLocale } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'nh-datepicker',
  templateUrl: './datepicker.component.html',
})
export class DatepickerComponent implements AfterContentInit {
  @Input() readOnly = false;
  @ContentChild(BsDatepickerDirective, { static: false })
  dp: BsDatepickerDirective;
  @ContentChild(NgControl, { static: false })
  field: NgControl;
  @Output() close = new EventEmitter<any>();
  defaultLanguage;

  constructor(public localeService: BsLocaleService, public translateService: TranslateService) {
    defineLocale('es', esLocale);
    defineLocale('en', enGbLocale);
  }

  ngAfterContentInit() {
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.languageCheck(this.defaultLanguage);

    this.translateService.onDefaultLangChange.subscribe((res) => this.languageCheck(res.lang));
  };

  languageCheck(language) {
    if (language === 'sp') {
      this.localeService.use('es')
    } else {
      this.localeService.use('en')
    }
  }
}
