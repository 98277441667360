import { Component, OnInit } from '@angular/core';
import { CreateAccountService } from '@app/gateway/services/createAccount.service';
import { Subscription } from 'rxjs';
import { ChangeEmailService } from './change-email.service';
import { isEmpty } from 'ramda';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import Vs from '@app/services/validators/validators.service';

@Component({
  selector: 'nh-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {
  updateEmailFrom = new FormGroup({
    currentEmail: new FormControl(''),
    newEmail: new FormControl('', [Vs.required, Vs.email]),
  });
  subscription: Subscription;

  constructor(private changeEmailService: ChangeEmailService, private createAccountService: CreateAccountService, private titleService: Title
    ) {
     this.changeEmailService.updateEmailFrom = this.updateEmailFrom;
     this.titleService.setTitle('Email Update');
  }

  ngOnInit(): void {
    this.changeEmailService.setLanguage();
    this.subscription = this.createAccountService.getUserObj().subscribe((res) => {
      if(res && !isEmpty(res)) {
        this.updateEmailFrom.controls['currentEmail'].setValue(res.email);
      }
    });
  }


  showEmailAlert() {
    if (this.changeEmailService.updateEmailFrom.invalid && this.changeEmailService.showEmailAlert) {
      return false;
    }
    return this.changeEmailService.showEmailAlert;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
