export interface ReferenceValue {
  code: string;
  value: string;
}

export type Table = ReferenceValue[];

enum TablesCodes {
  TAYN = 'TAYN',
  TSTS = 'TSTS',
  TDSA = 'TDSA',
  TACY = 'TACY',
  TSTA = 'TSTA',
  TATR = 'TATR',
  TPFT = 'TPFT',
  TBAC = 'TBAC',
  TREL = 'TREL',
  TSUF = 'TSUF',
  PRGS = 'PRGS',
  MCMA = 'MCMA',
  TLAG = 'TLAG',
  TAUI = 'TAUI',
  TSRR = 'TSRR',
  TSET = 'TSET',
  SUBS = 'SUBS',
  TPRV = 'TPRV',
  TEHI = 'TEHI',
  TAGC = 'TAGC',
  TDDT = 'TDDT',
  TSCT = 'TSCT',
  TAOC = 'TAOC',
  TSPC = 'TSPC',
  TALI = 'TALI',
  TLQA = 'TLQA',
  TATC = 'TATC',
  TMVT = 'TMVT',
  TROP = 'TROP',
  TMVE = 'TMVE',
  TSPT = 'TSPT',
  TREP = 'TREP',
  TABT = 'TABT',
  TBBO = 'TBBO',
  TCHC = 'TCHC',
  APRL = 'APRL',
  CCWY = 'CCWY',
  TDDS = 'TDDS',
  TMAR = 'TMAR',
  TLAR = 'TLAR',
  TSNR = 'TSNR',
  TSAC = 'TSAC',
  TWED = 'TWED',
  DIST = 'DIST',
  TART = 'TART',
  TCTZ = 'TCTZ',
  TCOI = 'TCOI',
  THAG = 'THAG',
  TAHC = 'TAHC',
  RACE = 'RACE',
  TAFR = 'TAFR',
  COVT = 'COVT',
  TARR = 'TARR',
  TESC = 'TESC',

  // Tenn RT Tables
  PRIMARYLANGUAGE = 'PRIMARYLANGUAGE',
  WRITTENLANGUAGE = 'WRITTENLANGUAGE',
  YESNO = 'YESNO',
  ADDRESSFORMATTYPES = 'ADDRESSFORMATTYPES',
  MILITARYPOCODES = 'MILITARYPOCODES',
  APRNSOURCE = 'APRNSOURCE',
  CONTACTTIME = 'CONTACTTIME',
  MPASSTPER = 'MPASSTPER',
  AUTHREPTIME = 'AUTHREPTIME',
  AUTHREPRELATIONSHIP = 'AUTHREPRELATIONSHIP',
  NAMESUFFIX = 'NAMESUFFIX',
  STATE = 'STATE',
  COUNTY = 'COUNTY',
  ENROLLSTATUS = 'ENROLLSTATUS',
  NUMBEROFBIRTHS = 'NUMBEROFBIRTHS',
  RACEDISPLAY = 'RACEDISPLAY',
  ETHNICITY = 'ETHNICITY',
  GENDER = 'GENDER',
  LIVINGARRANGEMENTYPE = 'LIVINGARRANGEMENTYPE',
  ALIENDOCTYPE= 'ALIENDOCTYPE',
  ALIENSTATUS = 'ALIENSTATUS',
  ALIENSTATUS_PP = 'ALIENSTATUS_PP',
  SELFEMPTYPE = 'SELFEMPTYPE',
  MPPAYFREQ = 'MPPAYFREQ',
  RELATIONTYPE = 'RELATIONTYPE',
  RELATIONTYPE_GENDER = 'RELATIONTYPE_GENDER',
  STATEMILITARY = 'STATEMILITARY',
  UNEARNEDINCOMETYPE = 'UNEARNEDINCOMETYPE',
  MPDEDUCTIONS = 'MPDEDUCTIONS',
  ISMEXPENSETYPE = 'ISMEXPENSETYPE',
  INCEXPPAYFREQUENCY = 'INCEXPPAYFREQUENCY',
  MEDICALEXPENSETYPE = 'MEDICALEXPENSETYPE',
  SHELTERUTILITYEXPTYPE = 'SHELTERUTILITYEXPTYPE',
  DCLIFEINSURANCETYPE = 'DCLIFEINSURANCETYPE',
  VAUNEARNEDINCOME = 'VAUNEARNEDINCOME',
  SSUNEARNEDINCOME = 'SSUNEARNEDINCOME',
  MPAPLKBRSN = 'MPAPLKBRSN',
  MPKBCHNG = 'MPKBCHNG',
  MPKBLIVING = 'MPKBLIVING',
  PRIMCONTREL = 'PRIMCONTREL',
  THIRDPARTYPOLICYTYP = 'THIRDPARTYPOLICYTYP',
  COUNTRY = 'COUNTRY',
  MPPROPTYPE = 'MPPROPTYPE',
  REALPROPERTYUSE = 'REALPROPERTYUSE',
  RESOURCETRANSFCATEGORY = 'RESOURCETRANSFCATEGORY',
  RESOURCETRANSFERTYPE = 'RESOURCETRANSFERTYPE',
  TRUSTTYPE = 'TRUSTTYPE',
  BURIALRESOURCETYPECD = 'BURIALRESOURCETYPECD',
  BURIALRESDESIGNATEDFOR = 'BURIALRESDESIGNATEDFOR',
  FINRESTYPE = 'FINRESTYPE',
  OTHERRESTYPE = 'OTHERRESTYPE',
  MPVEHICLETYPE = 'MPVEHICLETYPE',
  VEHICLEUSE = 'VEHICLEUSE',
  MEDNEEDYEXPENSES = 'MEDNEEDYEXPENSES',
  RELATIONSHIPTOINDIVIDUAL = 'RELATIONSHIPTOINDIVIDUAL',
  CHILDSUPMANEXPENSETYPE = 'CHILDSUPMANEXPENSETYPE',
  MPFINISH = 'MPFINISH',
  MCONAME = 'MCONAME',
  MCONAME_UBW = 'MCONAME_UBW',
  DISCHRSN = 'DISCHRSN',
  PPAMDIS = 'PPAMDIS',
  YESNOPNA = 'YESNOPNA',
  MPKBLIVINGNOW = 'MPKBLIVINGNOW',
  TAXDEDUCTIONS = 'TAXDEDUCTIONS',
  ABSENTREASON = 'ABSENTREASON',
  UNEARNEDINCOMETYPE_PP = 'UNEARNEDINCOMETYPE_PP',
  OTHERRESOURCEUSE = 'OTHERRESOURCEUSE',
  BNDN = 'BNDN',
  ELIGIBLITYAPPEALREASON = 'ELIGIBLITYAPPEALREASON',
  KATIEBECKETAPPEALREASON = 'KATIEBECKETAPPEALREASON',
  RENEWALAPPEALREASON = 'RENEWALAPPEALREASON',
  LTSSAPPEALREASON = 'LTSSAPPEALREASON',
  FILINGFOR = 'FILINGFOR',
  APLFILINGMETHOD = 'APLFILINGMETHOD',
  APFACILITY = 'APFACILITY',
  EDTOA_MV = 'EDTOA_MV',
  COVERAGETYPE = 'COVERAGETYPE',
  APRELATIONSHIP = 'APRELATIONSHIP',
  BCCEXTENSIONREASON = 'BCCEXTENSIONREASON',
  MEDICALEXPENSETYPECD = 'MEDICALEXPENSETYPECD'
}

export type TableCode = keyof typeof TablesCodes;
export type TableCodes = TableCode[];

export interface Tables {
  SHELTERUTILITYEXPTYPE?: Table;
  PRIMARYLANGUAGE? : Table;
  WRITTENLANGUAGE?: Table;
  YESNO?: Table;
  ADDRESSFORMATTYPES?: Table;
  MILITARYPOCODES?: Table;
  APRNSOURCE?: Table;
  CONTACTTIME?: Table;
  MPASSTPER?: Table;
  AUTHREPTIME?: Table;
  AUTHREPRELATIONSHIP?: Table;
  NAMESUFFIX?: Table;
  STATE?: Table;
  COUNTY?: Table;
  ENROLLSTATUS?: Table;
  NUMBEROFBIRTHS?: Table;
  RACEDISPLAY? : Table;
  ETHNICITY? : Table;
  GENDER? : Table;
  LIVINGARRANGEMENTYPE? : Table;
  ALIENSTATUS?: Table;
  ALIENSTATUS_PP?: Table;
  ALIENDOCTYPE?: Table;
  SELFEMPTYPE?: Table;
  MPPAYFREQ?: Table;
  RELATIONTYPE?: Table;
  RELATIONTYPE_GENDER?: Table;
  STATEMILITARY?: Table;
  UNEARNEDINCOMETYPE?: Table;
  MPDEDUCTIONS?: Table;
  ISMEXPENSETYPE?: Table;
  INCEXPPAYFREQUENCY?: Table;
  MEDICALEXPENSETYPE? : Table;
  DCLIFEINSURANCETYPE?: Table;
  VAUNEARNEDINCOME?: Table;
  SSUNEARNEDINCOME?: Table;
  MPAPLKBRSN?: Table;
  MPKBCHNG?: Table;
  MPKBLIVING?: Table;
  PRIMCONTREL?: Table;
  THIRDPARTYPOLICYTYP?: Table;
  COUNTRY?: Table;
  MPPROPTYPE?: Table;
  REALPROPERTYUSE?: Table;
  RESOURCETRANSFCATEGORY?: Table;
  RESOURCETRANSFERTYPE?: Table;
  TRUSTTYPE?: Table;
  BURIALRESOURCETYPECD?: Table;
  BURIALRESDESIGNATEDFOR?: Table;
  FINRESTYPE?: Table;
  OTHERRESTYPE?: Table;
  MPVEHICLETYPE?: Table;
  VEHICLEUSE?: Table;
  MEDNEEDYEXPENSES?: Table;
  RELATIONSHIPTOINDIVIDUAL?: Table;
  CHILDSUPMANEXPENSETYPE?: Table;
  MPFINISH?: Table;
  MCONAME?: Table;
  MCONAME_UBW?: Table;
  PPAMDIS?: Table;
  YESNOPNA?: Table;
  TAXDEDUCTIONS?: Table;
  ABSENTREASON?: Table;
  DISCHRSN?: Table
  UNEARNEDINCOMETYPE_PP?: Table;
  OTHERRESOURCEUSE?: Table;
  BNDN?: Table;
  ELIGIBLITYAPPEALREASON?: Table;
  LTSSAPPEALREASON?: Table,
  KATIEBECKETAPPEALREASON?: Table;
  RENEWALAPPEALREASON?: Table;
  FILINGFOR?: Table;
  APLFILINGMETHOD?: Table;
  APFACILITY?: Table;
  EDTOA_MV?: Table;
  COVERAGETYPE?: Table;
  APRELATIONSHIP?: Table;
  BCCEXTENSIONREASON? : Table;
  MEDICALEXPENSETYPECD? : Table
}

export interface ReferenceGroup {
  name: string;
  referenceData: Table[];
}

export interface ReferenceTable {
  referenceGroup: ReferenceGroup[];
}

export class GlobalVars {
  public static outSideOption = { value: "Someone outside the home", code: "EMPTY" } as ReferenceValue;
}

export const outSideOption = { value: "Someone outside the home", code: "EMPTY" } as ReferenceValue;
