<nh-alert type="danger" [isOpen]="isError">
  <div class="row">
    <div class="icon">
      <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
    </div>
    <div class="message">
      <span> 
        {{errorMessage}}
      </span>
    </div>
  </div>
</nh-alert>
<div *ngIf="invaidRelation">
  <nh-alert type="danger" [isOpen]="invaidRelation">
    <div class="row">
      <div class="icon">
        <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
      </div>
      <div class="message">
        <span>{{ invalidRel }}</span>
      </div>
    </div>
  </nh-alert>
</div>
<p *ngIf="isRmbRmc">{{ "RENEWAL_TEXT" | translate }}</p>
<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <h6 class="person-label">{{ head | fullname }}</h6>
  <section [formGroup]="getControl('data')">
    <div>
      <div formArrayName="relationshipList">
        <h5 class="section-header">{{'RELATIONSHIP' | translate }}</h5><hr>
        <div *ngFor="let relationship of relationshipList.controls; let i = index;">
          <ng-container class="pt-1">
            <fieldset [formGroupName]="i">
              <nh-field id = "rene_relation" label="{{'RELATIONSHIP_STATIC_TEXT1' | translate }} {{ name(relationship.value.sourceIndividual) }}{{'RELATIONSHIP_STATIC_TEXT2' | translate }} {{ name(relationship.value.referenceIndividual) }}" class="label-section">
                <select nh name="relation" aria-label="relation" formControlName="relation" [items]="tables[gender(getControl('data.relationshipList'), i)]" (change)="getInverseRelationships(relationship)"></select>
                <span class="form-text invalid-name d-block" *ngIf="isUnknownRelation(i)">{{unknownGenderRelationError}} {{name(relationship.value.sourceIndividual)}} {{unknownGenderRelationErrorPartB}} {{name(relationship.value.referenceIndividual)}}</span>
              </nh-field>
    
              <div *ngIf="relationship.value.inverse">
                <strong><span>{{ name(relationship.value.referenceIndividual) }}&nbsp;</span>{{ relationship.get('inverse').value.value }}
                <span>{{ name(relationship.value.sourceIndividual) }}</span></strong>
              </div>
            </fieldset>
            <br><br>
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="isPrimaryCareEnabled">
      <h5 class="section-header">{{'PRIMARY_CARE_GIVER' | translate}}</h5><hr>      
      <div class="row pl-0 pt-1 text-left primary-caregivers hover-red">
        <a class="btn btn-link text-left hover-red" (click)="showPrimaryCaregiverModal()">
          <span class="pr-2">{{'MORE_ABOUT_PRIMARY_CARE_GIVER' | translate}}</span>
          <span><i class="fa fa-external-link"></i></span>
        </a>
      </div>
      <br>

      <div formArrayName="primaryCareList">
        <div *ngFor="let primary of primaryCareList.controls; let i = index;">
          <div [hidden]="primary['controls']['primaryCaregiver' + i].isHidden">
            <ng-container>
              <fieldset [formGroupName]="i">
                <nh-field id = "{{'rene_' + i}}" label="{{'PRIMARY_CARE_GIVER_STATIC_TEXT1' | translate}} {{ name(primary.value.indvId) }}{{'PRIMARY_CARE_GIVER_STATIC_TEXT2' | translate}}" class="label-section">
                  <select nh name="primaryCaregiver" aria-label="primaryCaregiver" (change)="onSelected(primary.value, $event, i)" formControlName="primaryCaregiver{{i}}" [items]="tables.YESNO"></select>
                </nh-field>
                <div *ngIf="!primary['controls']['primaryCaregiverIndividual' + i].disabled" >
                  <label for="primaryCareGivers" class="label-section"><strong class="text-danger">*</strong>{{'WHO_IS' | translate}} {{ name(primary.value.indvId) }}{{'PRIMARY_CARE_GIVER_STATIC_TEXT2' | translate }}</label>
                  <div *ngFor="let indv of indivList[i]" class="pt-3 checkbox">
                    <label class="form-check-label mt-2">
                      <input id="{{ 'rene_' + i }}" type="checkbox" class="form-check-input" (click)="onIndvSelect($event, primary.value.indvId, indivList[i])" [value]="indv.individualId" [checked]="indv.checked">
                      <span class="cr"><i class="cr-icon fa fa-check" aria-hidden="true"></i></span>{{indv.name}}
                    </label>
                  </div>
                </div>
              </fieldset>
              <br>
            </ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="primaryCareGiverValidations.length">
        <nh-alert type="danger" [isOpen]="true">
          <div class="row">
            <div class="icon">
              <img src="../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
            </div>
            <div class="message">
              <span>{{'RELATIONSHIP_ALERT_TEXT' | translate}}</span>
            </div>
          </div>
        </nh-alert>
      </div>
    </div>
  </section>

</nh-form>
