import { compact } from 'ramda-adjunct';
import { over, compose, lensProp, dissocPath, invoker, values, flip, useWith, curry } from 'ramda';

import { removeNilKeys } from './null.helpers';
import { formSkipNo } from './top-level.helpers';
import { toObject } from './object.helpers';

export const get = invoker(1, 'get');
export const getRawValue = invoker(0, 'getRawValue');
export const updateForm = invoker(0, 'updateValueAndValidity');
export const setValue = invoker(1, 'setValue');
export const valueSet = flip(setValue);
export const withValueSet = useWith(valueSet);

export const controlWith = curry((value, disabled) => ({ value, disabled }));
export const controlWhen = flip(controlWith);

const cleanIdentifier = over(lensProp('identifier'), removeNilKeys);
const removeHelpers = dissocPath(['extras', 'helpers']);

const cleanFormValue = over(
  lensProp('value'),
  compose(
    cleanIdentifier,
    removeHelpers
  )
);

export const validateForm = compose(
  formSkipNo,
  cleanFormValue
);

export const validValues = compose(
  compact,
  values
);

const withOptions = toObject(['onlySelf', 'emitEvent']);

export const emitEvent = withOptions(false, true);
export const selfSilent = withOptions(true, false);
export const selfEmit = withOptions(true, true);
