import { Component } from '@angular/core';
import { CreateAccountService } from '../services/createAccount.service';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'create-account-new',
  templateUrl: './create-account-new.component.html',
  styleUrls: ['./create-account-new.component.scss']
})
export class CreateAccountNewComponent {
  activated: boolean = true;
  steps: any = [{
    link: 'profile-details', name: 'profile-details'
  }, {
    link: 'security-info', name: 'security-info'
  }, {
    link: 'review-submit', name: 'review-submit'
  }]

  constructor(private createAccountService: CreateAccountService, private titleService: Title
    ) {
    this.titleService.setTitle('Create Account');
   }
  loaded() {
    const completed = this.createAccountService.getFormData();
    if (completed !== null) {
      this.steps = this.steps.map(step => {
        if (completed.completedSteps.includes(step.name)) step.completed = true
        return step;
      })
    }
  }
}