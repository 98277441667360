import { NewbornSignsubmitComponent } from './newborn-signsubmit.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import Vs from '@app/services/validators/validators.service';
import { ContinueLabels } from '@app/models/section.model';

const formValidation = (fb) => {
  const data = fb.group({
    applicationSignedBySw: [null, Vs.required],
    applicationSignName: [null, [Vs.required, Vs.individualName]],
    partnerSignedBySw: [null, Vs.required],
    partnerSignName: [null, [Vs.required, Vs.individualName]],
  });
  
  return {data}
};

export default sectionConfiguration(NewbornSignsubmitComponent, formValidation, [], [], {}, {
  hasSummary: false,
  showComments: true,
  showSubmit: false,
  labelContinue: ContinueLabels.SUBMIT,
});
