import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ProcessService {
    addressValidated = false;
    processAddressValidate = new Subject();
    isFromAddressValidSaveAndExit$ = new Subject();
    isFromHeaderComponentFAQ$ = new Subject();
    onLogout$ = new Subject();
    flagData: any;
    formValue: any; 
    
    //Tax Filer Outside Duplicate
    duplicateTaxOutsideInsideValidate = new Subject();
    isNoDuplicateTaxOutsideInsideValidate = new Subject();
    isDuplicateTaxOutsideInside = false;
    noDuplicateTaxInOutAfterModal = false;
    isFromTaxOrJFOutValidSaveAndExit$ = new Subject();

    //Joint Filer Outside Duplicate
    duplicateJFOutsideInsideValidate = new Subject();
    isNoDuplicateJFOutsideInsideValidate = new Subject();
    noDuplicateJFInOutAfterModal = false;
    isDuplicateJFOutsideInside = false;

    //Tax Dep & Joint Filer Outside Duplicate Presumptive
    duplicateTaxDepJFPresmInOutValidate = new Subject();
    isNoDuplicateTaxDepJFPresmInOutValidate = new Subject();
    noDuplicateTaxDepJFInOutAfterModal = false;
    isDuplicateTaxDepJFOutsideInside = false;



  constructor() {}

  getIsfromAddressSaveExit(){
    return this.isFromAddressValidSaveAndExit$;
  }

  setIsfromAddressSaveExit(isFromSaveExit: string){
     this.isFromAddressValidSaveAndExit$.next(isFromSaveExit)
  }

  getIsfromTaxDepOrJFOutsideSaveExit(){
    return this.isFromTaxOrJFOutValidSaveAndExit$;
  }

  setIsfromTaxDepOrJFOutsideSaveExit(isFromSaveExit: string){
     this.isFromTaxOrJFOutValidSaveAndExit$.next(isFromSaveExit)
  }


  getFlagData(){
    return this.flagData;
  }

  setFlagData(flags: any){
    this.flagData = flags;
  }

  getIsFromNextFAQ() {
    return this.isFromHeaderComponentFAQ$;
  }

  setIsFromNextFAQ(isFromFAQ: string){
    this.isFromHeaderComponentFAQ$.next(isFromFAQ)
  }

  setOnLogout(logout: string) {
    this.onLogout$.next(logout)
  }

  getOnLogout() {
    return this.onLogout$;
  }
}
