<div class="mb-3">
	<nh-alert type="success" [isOpen]="appealsService.showMessage"
		*ngIf="appealsService.message == 'SUCCESS_CONTINUANCE'">
		<div *ngIf="appealsService.message == 'SUCCESS_CONTINUANCE'" class="message">
			<span>{{ 'CONTINUANCE_SUBMITTED' | translate}}</span>
		</div>
	</nh-alert>
	<nh-alert type="warning" [isOpen]="appealsService.showMessage" *ngIf="appealsService.message == 'ERROR_MESSAGE'">
		<div *ngIf="appealsService.message == 'ERROR_MESSAGE'" class="message">
			<span>{{ 'ERROR_CONTINUANCE_SUBMIT' | translate}}</span>
		</div>
	</nh-alert>
	<div class="bg-custom">
		<div class="container">
			<div *ngIf="memberInfo">

				<h5 class="section-header textcolor">{{'CONTINUANCE_DETAILS' | translate}}</h5>
				<hr>
				<div>
					<p style="padding-bottom: 10px">{{'APPROVAL_MSG_CONTINUANCE' | translate}}</p>
				</div>

				<div>
					<p class="textcolor"><strong>{{'APPELLANT_NAME_CONTINUNACE' | translate}}</strong>
						<strong>{{": "}}</strong>{{ memberInfo.appellantName }}</p>
					<p class="textcolor"><strong>{{'APPEAL_NUMBER_CONTINUNACE' | translate}}</strong>
						<strong>{{": "}}</strong>{{ memberInfo.appealNumber }}</p>
					<p class="textcolor"><strong>{{'YOUR_CURRENT_HEARING_DATE_CONTINUANCE' |
							translate}}</strong><strong>{{": "}}</strong>{{ proposedHearingDate }}</p>


					<form [formGroup]="appealContinuanceForm">

						<nh-field class="textcolor" id="app_con_submittedby" [mandatory]="true"
							label="{{'SUBMITTEDBY_CONTINUANCE' | translate}}">
							<div>
								<p class="note_padding">{{'CONT_NOTE_SUBMITTEDBY' | translate}}</p>
							</div>
							<input type="text" class="form-control" name="submittedBy"
									formControlName="submittedBy" aria-label="submittedBy"
									autofocus="true" (input)="submittedValidator($event)" maxlength="2000">
						</nh-field>
						<p></p>

						<nh-field class="textcolor" id="app_con_relationship" [mandatory]="true"
							label="{{'REQUEST_RELATIONSHIP_CONTINUANCE' | translate}}">
								<div>
									<p class="note_padding rel_pad">{{'CONT_NOTE_RELATIONSHIP' | translate}}</p>
								</div>
							<nh-count>
								<textarea maxlength="2000" class="form-control" name="relationshipWithAppellant"
									formControlName="relationshipWithAppellant" aria-label="relationshipWithAppellant"
									rows="5" autofocus="true">
								</textarea>
							</nh-count>
						</nh-field>
						<p></p>

						<nh-field class="textcolor" id="app_con_comments" [mandatory]="true"
							label="{{'REQUEST_COMMENTS_CONTINUANCE' | translate}}">
							<nh-count>
								<textarea maxlength="2000" class="form-control" name="reasonForContinuance"
									formControlName="reasonForContinuance" aria-label="reasonForContinuance" rows="5"
									autofocus="true">
							</textarea>
							</nh-count>
						</nh-field>

						<div>
							<nh-field class="textcolor" id="app_con_comments" [mandatory]="false"
								label="{{'Appellant_Signature'| translate}}"></nh-field>
							<div class="checkbox-label">
								<input type="checkbox" name="appellantSignSW" formControlName="appellantSignSW"
									checkedValue="E" (change)=deselect($event)>
								<p style="padding-left: 15px" [innerHTML]="'Continuance_Checkbox' | translate"></p>
							</div>
							<div>
								<nh-field id="appellantSign" label="{{'SIGNATURE' | translate}}" class="label-section" [mandatory]="true">
									<input type="text" class="form-control" name="appellantSign" autofocus="true"
										formControlName="appellantSign" aria-label="appellantSign" maxlength="50" (input)="signatureValidator($event)">
								</nh-field>
							</div>
						</div>
					</form>

					<div class="row my-1">
						<div class="col-md-12">
							<div class="row mt-3 text-center py-3" style="padding-top: 0.5rem !important">
								<div class="ml-3">
									<button id="continue" class="btn btn-block btn-secondary btn-width"
										(click)="onSubmit(this.memberInfo, this.appealContinuanceForm.controls, this.translatedLang)"
										[disabled]="!isFormValid() || appealsService.showMessage">
										{{'SUBMIT_CONTINUANCE' | translate}}
									</button>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
		</div>

	</div>

	<div class="row my-1">
		<div class="col-md-12" style="margin-top: 7rem !important">
			<div class="row mt-3 text-center py-4">
				<div class="ml-3">
					<button id="continue" class="btn btn-block btn-secondary btn-width"
						(click)="goToAppealsDashboard()">
						{{'EXIT' | translate}}
					</button>
				</div>
			</div>
		</div>
	</div>

</div>