import { or } from 'ramda';
// import { evolve } from 'ramda';

import { NewbornImmigrationComponent } from './newborn-immigration.component';
import {isNilOrEmpty} from 'ramda-adjunct';

import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration, isOtherStatus, isNC, isUndocumentedImmigrant } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { controlWith } from '@app/helpers/form.helpers';
import { toDate, toISO } from '@app/helpers/date.helpers';
import { familyPlanning, handleMedicalPrograms } from '@app/helpers/programs.helpers';
import { nilControl } from '@app/helpers/form-control.helpers';
import { isNo, isPn } from '@app/helpers/tayn.helpers';

const formValidation = (builder, recordData, { programs, allRecords }) => {
    programs = handleMedicalPrograms(programs);
    // Do not remove this log
    console.log(recordData);
    let citizenship;
    if (sessionStorage.getItem('recordId') && sessionStorage.getItem('recordId') != "null" && sessionStorage.getItem('recordId') != '-1') {
      const resultRecord = allRecords.filter((item => (item.identifier.recordId == sessionStorage.getItem('recordId'))));
      citizenship = lookIn(resultRecord[0].data);
    } else if (sessionStorage.getItem('recordId') && sessionStorage.getItem('recordId') == '-1') {
      const recordId = Math.max.apply(Math, allRecords.map(function(o) { return o.identifier.recordId; }))
      const previousRecord = allRecords.filter((item => (item.identifier.recordId == recordId)));
      citizenship = lookIn(previousRecord[0].data);
    } else {
        citizenship = lookIn(null);
    }


    const eligibleImmigration = citizenship(['vldImmgrtnSts']);
    const statusGainDate = toDate(citizenship('immigrationStatusDt'));
    const status = citizenship(['alienStatusCd']);
    const docType = citizenship(['alienDocCd']);

    const registrationNumber = citizenship(['alienNum']);
    const i94 = citizenship(['i94Num']);
    const visa = citizenship(['visaNum']);
    const passport = citizenship(['passportNumber']);
    const countryIssuance = citizenship(['passportIssuingCtryCd']);
    const cardNumber = citizenship(['cardNum']);
    const expDate = toDate(citizenship(['expirationDt']));
    const documentDescription = citizenship(['docDescription']);

    const naturalizationCertificateNumber = citizenship(['naturalCertNum']);
    const certificateOfCitizenshipNumber = citizenship(['certOfCitizenNum']);
    const sevisID = citizenship(['sevisId']);

    const undocumentedImmigrant = isUndocumentedImmigrant(status);
    const hasMagi = familyPlanning(programs) && programs.length === 1;
    const notEligible = isNo(eligibleImmigration) || isPn(eligibleImmigration);
    const otherStatus = isOtherStatus(status);


    const isNc = isNC(docType);

    const data = builder.group({
        indvId: sessionStorage.getItem('individualId'),
        eligibleImmigration: [eligibleImmigration, Vs.required],
        immigration: builder.group({
            statusGainDate: [controlWith(statusGainDate, notEligible), [Vs.notBefore120Years, Vs.validDate]],
            status: [controlWith(status, or(notEligible, hasMagi)), [Vs.required]],
            document: builder.group({
                docType: [
                    {
                        value: docType,
                        disabled: isNo(eligibleImmigration) || isPn(eligibleImmigration) || isNilOrEmpty(status) || !undocumentedImmigrant || !otherStatus ? true : false
                    }
                ],
                registrationNumber: [nilControl(registrationNumber), [Vs.alienNumber]],
                i94: [nilControl(i94), [Vs.i94]],
                visa: [nilControl(visa)],
                passport: [nilControl(passport), [Vs.passportNumber]],
                countryIssuance: [nilControl(countryIssuance)],
                cardNumber: [nilControl(cardNumber), [Vs.cardNumber]],
                expDate: [nilControl(expDate), [Vs.validDate]],
                documentDescription: [nilControl(documentDescription), [Vs.documentDescription]],
                naturalizationCertificateNumber: [controlWith(naturalizationCertificateNumber, !isNc), [Vs.naturalizationNumber]],
                certificateOfCitizenshipNumber: [nilControl(certificateOfCitizenshipNumber), [Vs.certificateNumber]],
                sevisID: [nilControl(sevisID), [Vs.sevisID]]
            }),
        }),
    });

    return { data };
};

/*const postObject = evolve({
    immigration: {
        statusGainDate: toISO,
        document: {
            expDate: toISO,
        },
    },
});*/

const postObject = (data) => {
    return {
        "vldImmgrtnSts": data.eligibleImmigration,
        "passportIssuingCtryCd": data.immigration && data.immigration.document ? data.immigration.document.countryIssuance : null,
        "alienDocCd": data.immigration && data.immigration.document && data.immigration.document.docType ? data.immigration.document.docType : null,
        "immigrationStatusDt": data.immigration && data.immigration.statusGainDate ? toISO(data.immigration.statusGainDate) : null,
        "indvId": sessionStorage.getItem('individualId'),
        "i94Num": data.immigration && data.immigration.document ? data.immigration.document.i94 : null,
        "sevisId": data.immigration && data.immigration.document ? data.immigration.document.sevisID : null,
        "cardNum": data.immigration && data.immigration.document ? data.immigration.document.cardNumber : null,
        "passportNumber": data.immigration && data.immigration.document ? data.immigration.document.passport : null,
        "expirationDt": data.immigration && data.immigration.document && data.immigration.document.expDate ?  toISO(data.immigration.document.expDate) : null,
        "naturalCertNum": data.immigration && data.immigration.document ? data.immigration.document.naturalizationCertificateNumber : null,
        "certOfCitizenNum": data.immigration && data.immigration.document ? data.immigration.document.certificateOfCitizenshipNumber : null,
        "alienNum": data.immigration && data.immigration.document ? data.immigration.document.registrationNumber : null,
        "visaNum": data.immigration && data.immigration.document ? data.immigration.document.visa : null,
        "alienStatusCd": data.immigration ? data.immigration.status : null,
        "docDescription":data.immigration && data.immigration.document ? data.immigration.document.documentDescription: null
    };
}

export default sectionConfiguration(
  NewbornImmigrationComponent, 
  formValidation, 
  ['YESNO', 'ALIENSTATUS', 'ALIENDOCTYPE', 'COUNTRY'], 
  [], 
  {}, 
  { 
    postObject,
    hasSummary: false
  }
);
