<div>
    <div class="bg-custom">
        <div class="container">
            <section>
                <div>
                    <nh-alert type="success" [isOpen]="showChangeReqSuccessAlert">
                        <div class="row">
                            <div class="icon">
                                <img src="../../../../../assets/images/Icons/⚛️ icons-checkmark-outline.svg" alt="danger" />
                            </div>
                            <div class="message">
                                <span>{{'MCOCHANGE_REQUESTED' | translate }}</span>
                            </div>
                        </div>
                    </nh-alert>
                    <div *ngIf="caseNumberList.length >1">
                        <h5 class="section-header">{{'HEALTH_PLAN_DETAILS_STATIC_TEXT1' | translate }}</h5>
                        <hr>
                        <div class="row">
                            <div class="col-md-3">
                                <span><strong>{{'COVERAGE_CASE_NUMBER' | translate }}</strong></span>:
                            </div>
                            <div class="col-md-4">
                                <select *ngIf="translatedLang === 'en'" class="custom-select-info" aria-label="caseNumber" [(ngModel)]="caseNumber"
                                    (change)="changecaseNumber()">
                                    <option [ngValue]="null">Pick an option</option>
                                    <option *ngFor="let option of caseNumberList" [ngValue]="option">{{option}}
                                    </option>
                                </select>
                                <select *ngIf="translatedLang !== 'en'" class="custom-select-info" aria-label="caseNumber" [(ngModel)]="caseNumber"
                                (change)="changecaseNumber()">
                                <option [ngValue]="null">Elija una opción</option>
                                <option *ngFor="let option of caseNumberList" [ngValue]="option">{{option}}
                                </option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <button class="btn btn-secondary" (click)="submitCaseNumber()"
                                    [disabled]="disableSubmit">{{'SELECT' | translate }}</button>
                            </div>
                        </div>
                        <br>
                    </div>
                    <div *ngIf="showHealthPlanInfoSectionTable && changePlanRows.length==0 && caseNumberList1.length == 1">
                        <h5 class="section-header">{{'HEALTH_PLAN_DETAILS_STATIC_TEXT2' | translate }}</h5>
                        <hr>
                        <p style="font-size:15px">{{'HEALTH_PLAN_DETAILS_STATIC_TEXT8' | translate }} <span style="color: #174A7C;">1-855-259-0701</span>
                             {{'HEALTH_PLAN_DETAILS_STATIC_TEXT9' | translate }}</p>   
                    </div>                                         
                    <div *ngIf="showHealthPlanInfoSectionTable && changePlanRows && changePlanRows.length">
                         <h5 class="section-header">{{'HEALTH_PLAN_DETAILS_STATIC_TEXT2' | translate }}</h5>
                        <hr>
                        <p>{{'HEALTH_PLAN_DETAILS_STATIC_TEXT3' | translate }}</p>                        
                        <div class="d-none d-lg-block">
                            <ngx-datatable *ngIf="!isMobileView" class='bootstrap striped' #table
                                [rows]='changePlanRows' [columnMode]='"force"' [headerHeight]='"auto"'
                                [footerHeight]='50' [rowHeight]='"auto"'>
                                <ngx-datatable-column [name]="col.name | translate" [prop]="col.prop"
                                    *ngFor="let col of changePlanColumns">
                                </ngx-datatable-column>
                            </ngx-datatable>
                        </div>
                        <div class="nh-table-mobile d-lg-none">
                            <div class="table-row mb-3 pb-3" *ngFor="let row of changePlanRows">
                                <div class="mobile-table-header">{{changePlanColumns[0].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.member}}</div>
                                <br>
                                <div class="mobile-table-header">{{changePlanColumns[1].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.caseNumber}}</div>
                                <br>
                                <div class="mobile-table-header">{{changePlanColumns[2].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.providerName}}</div>
                                <br>
                                <div class="mobile-table-header">{{changePlanColumns[3].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.startDate}}</div>
                                <br>
                                <div class="mobile-table-header">{{changePlanColumns[4].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.endDate}}</div>
                                <br>
                                <div class="mobile-table-header">{{changePlanColumns[5].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.futureProviderName}}</div>
                                <br>
                                <div class="mobile-table-header">{{changePlanColumns[6].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.futurestartDate}}</div>
                                <br>                              
            
                            </div> 
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="showHealthPlanMcoSection && changePlanRows && changePlanRows.length && hideHealthPlanOptions ">
                        <!-- commented the code for future use 
                            <div *ngIf="membersNotEligible.length > 0 && hoHSW === 'Y'" class="pt-2 pb-2 col-md-12">
                            <p [innerHTML]="'HEALTH_PLAN_DETAILS_STATIC_TEXT5_1' | translate: {notEligibleUsers: membersNotEligibleString }" style="margin:0;font-size: 15px;"></p>
                        </div> -->
                        <div class="col-md-7">
                            <label for="healthPlanMCO" class="label-section">{{'HEALTH_PLAN_DETAILS_STATIC_TEXT4' |
                                translate }}</label>
                            <select *ngIf="translatedLang === 'en'" class="custom-select-info"
                                aria-label="healthPlanMCO" [(ngModel)]="healthPlanMCO" (change)="changeMCO()">
                                <option [ngValue]="null">Pick an option</option>
                                <option *ngFor="let option of healthPlanMCOoptions" [ngValue]="option">
                                    {{option.enDescription}}
                                </option>
                            </select>
                            <select *ngIf="translatedLang !== 'en'" class="custom-select-info"
                                aria-label="healthPlanMCO" [(ngModel)]="healthPlanMCO" (change)="changeMCO()">
                                <option [ngValue]="null">Elija una opción</option>
                                <option *ngFor="let option of healthPlanMCOoptions" [ngValue]="option">
                                    {{option.spDescription}}
                                </option>
                            </select>
                        </div>
                        <div col="col-md-5" style="margin-top: 12px;">
                            <button (click)="openHealthPlansModal()" class="btn healthplan-more-info">{{'MOREINFO_BUTTON_TEXT' | translate }}</button>
                            <button *ngIf = "showSubmit" class="btn btn-secondary" style="height: 55px;" [disabled]="disableMCOSubmit" (click)="submitMCOChange()">{{'SUBMIT' | translate}}</button>
                        </div>
                    </div>
                    <div *ngIf="changePlanRows.length">
                        <div *ngIf="notEligibleText || notDisplaySubmitText"
                            class="pt-2 pb-2">
                            <p [innerHTML]="'HEALTH_PLAN_DETAILS_STATIC_TEXT6' | translate" style="margin:0;font-size: 15px;"></p>
                        </div>
                        <div *ngIf="priorCaseSubmitted && mcoRequestYear && errorStatus !== 'ERROR'">
                            <p [innerHTML]="'HEALTH_PLAN_DETAILS_STATIC_TEXT5' | translate" style="margin:0;font-size: 15px;"></p>
                        </div>
                    </div>
                    <div *ngIf="changePlanRows.length && errorStatus === 'ERROR' && !mcoRequestYear">
                        <div class="pt-2 pb-2">
                            <p [innerHTML]="'HEALTH_PLAN_DETAILS_STATIC_TEXT10' | translate" style="margin:0;font-size: 15px;"></p>
                        </div>
                    </div>
                    <div>
                    </div>
                    <!-- <div *ngIf="changePlanRows.length && isCaseApprovedStatus === false">
                        <div class="pt-2 pb-2"> 
                            <p [innerHTML]="'HEALTH_PLAN_DETAILS_STATIC_TEXT6' | translate" style="margin:0;font-size: 15px;"></p>
                        </div>
                    </div> -->
                    <div *ngIf="showMcoSummaryHistory">
                        <div *ngIf="changePlanRows && changePlanRows.length && changeReqSummayRows && changeReqSummayRows.length">
                            <h5 class="section-header">{{'HEALTH_PLAN_DETAILS_STATIC_TEXT7' | translate }}</h5>
                            <hr>
                            <div class="d-none d-lg-block">
                                <ngx-datatable *ngIf="!isMobileView" class='bootstrap striped' #table
                                    [rows]='changeReqSummayRows' [columnMode]='"force"' [headerHeight]='"auto"'
                                    [footerHeight]='50' [rowHeight]='"auto"'>
                                    <ngx-datatable-column [name]="col.name | translate" [prop]="col.prop"
                                        *ngFor="let col of changeReqSummayColumns">
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                            <div class="nh-table-mobile d-lg-none">
                                <div class="table-row mb-3 pb-3" *ngFor="let row of changeReqSummayRows">
                                    <div class="mobile-table-header">{{changeReqSummayColumns[0].name | translate}}</div>
                                    <hr>
                                    <div class="mobile-table-data">{{row.requestedBy}}</div>
                                    <br>
                                    <div class="mobile-table-header">{{changeReqSummayColumns[1].name | translate}}</div>
                                    <hr>
                                    <div class="mobile-table-data">{{row.currentHealthPlan}}</div>
                                    <br>
                                    <div class="mobile-table-header">{{changeReqSummayColumns[2].name | translate}}</div>
                                    <hr>
                                    <div class="mobile-table-data">{{row.newHealthPlan}}</div>
                                    <br>
                                    <div class="mobile-table-header">{{changeReqSummayColumns[3].name | translate}}</div>
                                    <hr>
                                    <div class="mobile-table-data">{{row.requestDate}}</div>
                                    <br>
                                    <div class="mobile-table-header">{{changeReqSummayColumns[4].name | translate}}</div>
                                    <hr>
                                    <div class="mobile-table-data">{{row.status}}</div>
                                    <br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>