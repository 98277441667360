import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'replacemedicaltype'
})
export class ReplaceMedicalTypePipe implements PipeTransform {
  defaultLanguage;
  constructor(public translateService: TranslateService) {}

  transform(text: string, txtToReplace:string): any {
    text = text.replace('medicalType', txtToReplace)    
    return text;
  }

}
