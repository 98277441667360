<h5 class="section-header">{{ staticTextHeader }}</h5>
<hr>
<p class="static-text" [innerHTML]="this.staticText_1"></p>
<h5 class="section-header">{{ staticText_2 }}</h5>
<hr>
<div *ngIf="rows && rows.length" class="d-none d-lg-block">
  <ngx-datatable *ngIf="!isMobileView" class='bootstrap striped' #table [rows]='rows' [limit]="5" [columnMode]='"force"' [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
    <ngx-datatable-column [name]="col.name" [prop]="col.prop" *ngFor="let col of columns">
    </ngx-datatable-column>
    <ngx-datatable-column name="{{this.staticText_5}}" [width]="148">
        <ng-template ngx-datatable-cell-template let-row="rows" let-rowIndex="rowIndex">
            <ng-container>
                <span *ngIf="translatedLang !== 'en'" class="mobile-table-data" [innerHTML]="rows[rowIndex].typeOfChangeEs"></span>
                <span *ngIf="translatedLang === 'en'" class="mobile-table-data" [innerHTML]="rows[rowIndex].typeOfChange"></span>
            </ng-container>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{this.staticText_6}}" [width]="275">
        <ng-template ngx-datatable-cell-template let-row="rows" let-rowIndex="rowIndex">
            <ng-container>
                <span *ngIf="translatedLang !== 'en'" class="mobile-table-data" [innerHTML]="rows[rowIndex].supportingDocumentsEs"></span>
                <span *ngIf="translatedLang === 'en'" class="mobile-table-data" [innerHTML]="rows[rowIndex].supportingDocuments"></span>
            </ng-container>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="{{this.staticText_7}}" [width]="245">
        <ng-template ngx-datatable-cell-template let-row="rows" let-rowIndex="rowIndex">
            <ng-container>
                <button class="btn btn-sm btn-secondary" (click)="uploadProof()"><span>{{staticText_8}}</span></button>
            </ng-container>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>
</div>
<div class="nh-table-mobile d-lg-none">
    <ng-container *ngIf="rows && rows.length">
        <div class="table-row mb-3 pb-3" *ngFor="let row of rows">
            <div class="mobile-table-header">{{columns[0].name}}</div>
            <hr>
            <div class="mobile-table-data">{{row.hohName}}</div>
            <br>
            <div class="mobile-table-header">{{columns[1].name}}</div>
            <hr>
            <div class="mobile-table-data">{{row.changeNumber}}</div>
            <br>
            <div class="mobile-table-header">{{staticText_5}}</div>
            <hr>
            <div *ngIf="translatedLang !== 'en'" class="mobile-table-data"  [innerHTML]="row.typeOfChangeEs"></div>
            <div *ngIf="translatedLang === 'en'" class="mobile-table-data"  [innerHTML]="row.typeOfChange"></div>
            <br>
            <div class="mobile-table-header">{{staticText_6}}</div>
            <hr>
            <div *ngIf="translatedLang !== 'en'" class="mobile-table-data"  [innerHTML]="row.supportingDocumentsEs"></div>
            <div *ngIf="translatedLang === 'en'" class="mobile-table-data"  [innerHTML]="row.supportingDocuments"></div>
            <br>
            <div class="mobile-table-header">{{ staticText_7 }}</div>
            <hr>
            <span>
                <button  class="btn btn-sm btn-secondary action-button" style="width: 100%;"
                    (click)="uploadProof"><span>{{ staticText_8 }}</span></button>
            </span>
            <br>
            <hr>
        </div>
    </ng-container>
    <p *ngIf="!rows.length" class="mb-5">{{ staticText_9 }}</p>
</div>