<div class="container">
	<div class="pt-2">
		<h1 class="page-title">{{heading1}}</h1>
	</div>
	<div class="hover-red">
		<a class="hover-red btn btn-link" (click)="onPrevious()">
			<svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
				<g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
					<path id="Combined_Shape" data-name="Combined Shape"
						d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
						transform="translate(0)" fill="#174a7c" />
				</g>
			</svg>
			<span class="pl-2">Back to TennCare Access Welcome page</span>
		</a>
	</div>
	<div>
		<div class="py-4">
			<div class="card">
				<div class="page_label p-4">
					<h5 class="section-header">{{heading2}}</h5><hr>
					<p>{{heading3}} <span><nh-popover *ngIf="!isNewborn" style="padding-left: 1px;" content="{{'ADMIT_DISCHARGE_POPUP' | translate}}"></nh-popover></span></p>
					<div *ngIf="isNewborn">
						<p>{{heading4}}
						<a class="page-info btn-link" href="https://www.tn.gov/content/dam/tn/coverkids/documents/BirthReportingFormEng.docx " target="_blank"><span>Click here</span></a>
							to download the Birth Reporting Form for a Safe Haven Baby.</p><br>
					</div>

					<form [formGroup]="searchForm">
						<div>
							<div *ngIf="showSearchByNameMessage">
								<nh-alert type="danger" [isOpen]="true">
								  <div class="row">
									<div class="icon">
									  <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
									</div>
									<div class="message">
									  <span>You must enter a member's first name, last name, and date of birth to search by name.</span>
									</div>
								  </div>
								</nh-alert>
							  </div>
							<div class="form-row">
								<div class="col-sm-8 col-lg-3 col-md-3">
									<nh-field [isRow]="true" [mandatory]="true" label="{{'FIRST_NAME' | translate}}">
										<input type="text" aria-label='firstName' name="firstName" (change)='validateName($event)' formControlName="firstName" class="form-control" maxlength="45">
									</nh-field>
								</div>
					
									<div class="col-sm-4 col-lg-2 col-md-2">
										<nh-field [isRow]="true" [mandatory]="false" label="{{'MIDDLE_NAME' | translate}}">
											<input aria-label='middleInitial' type="text" name="middleInitial" formControlName="middleInitial" class="form-control" mask="S">
										</nh-field>
									</div>
					
								<div class="col-sm-8 col-lg-3 col-md-3">
									<nh-field [isRow]="true" [mandatory]="true" label="{{'LAST_NAME' | translate}}">
										<input aria-label='lastName' type="text" name="lastName" (change)='validateName($event)' formControlName="lastName" class="form-control" maxlength="45">
									</nh-field>
								</div>
								
								<div class="col-sm-4 col-lg-2 col-md-2">
									<label for="proofType" class="label-section">Suffix</label>
									<select aria-label="suffix" class="custom-select-info" formControlName="suffix" (change)="selectedOption($event)">
										<option [ngValue]="null">Pick an option</option>
										<option *ngFor="let option of options" [ngValue]="option">{{option.enDescription}}</option>
									</select>
								</div>
							</div>
						</div>

						<div class="form-row">
							<div class="col-sm-12 col-md-6">
								<nh-field label="Date of Birth" [mandatory]="true" class="label-section">
									<nh-datepicker>
										<input type="text" class="form-control" name="dateOfBirth"
											formControlName="dateOfBirth" aria-label="dateOfBirth" (bsValueChange)='validateName($event)' (change)='validateName($event)' placeholder="mm/dd/yyyy" [minDate]="minimumDate" [maxDate]="today"
											bsDatepicker nh>
									</nh-datepicker>
								</nh-field>
							</div>
						</div>

						<div class="form-row horizontal-border">
							<p class="and-border"></p>
							<p class="text-center middle-text"><strong>OR</strong></p>
							<p class="and-border"></p>
						</div>
						<div class="form-row">
							<div class="col-sm-12 col-md-3">
								<nh-field [mandatory]="true" [isRow]="true" label="Social Security Number" class="label-section">
									<input aria-label="ssn" type="text" name="ssn" formControlName="ssn" class="form-control" (change)='validateSsn()' placeholder="XXX-XX-XXXX" mask="000-00-0000">
								</nh-field>
							</div>
						</div>

						<div class="form-row horizontal-border">
							<p class="and-border"></p>
							<p class="text-center middle-text"><strong>OR</strong></p>
							<p class="and-border"></p>
						</div>

						<div class="form-row">
							<div class="col-sm-12 col-md-5">
								<nh-field [mandatory]="true" label="Person ID" class="label-section">
									<input aria-label="personId" type="text" name="personID" (change)='validateIndvId()'
										formControlName="personID" class="form-control" mask="9999999999">
								</nh-field>
							</div>
						</div>
					</form>
					<br>
					<div class="form-row">
						<div class="col-12 col-md-3 py-2 py-md-0 order-md-1">
							<button class="btn btn-secondary" (click)="onSearch()" [disabled]="!isFormValid"> Search&nbsp; <i class="fa fa-search"></i></button>
						</div>
					</div>
					<br>

					<div [formGroup]="searchForm">
						<h5 *ngIf="showSearch" class="section-header">Search Results</h5><hr>

						<div *ngIf="isNewborn && (rows | async) && result && result.length">
							<ngx-datatable class='bootstrap striped' #table [rows]='result' columnMode="force" [headerHeight]='50' [footerHeight]='50' [rowHeight]='"auto"' [limit]="5">
								<ngx-datatable-column [name]="col.name" [prop]="col.prop" *ngFor="let col of columns"></ngx-datatable-column>
								<ngx-datatable-column name="Select" [width]="180">
									<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
										<div class="d-flex">
											<a class="select-open_in_new view-tennCare-title">
												<span (click)="startApplication(row)">{{row.appTypeText}} <i class="fa fa-external-link"></i></span>
											</a>
										</div>
									</ng-template>
								</ngx-datatable-column>
							</ngx-datatable>
						</div>

						<div *ngIf="!isNewborn && (rows | async) && result && result.length" class="page_label mt-3">
							<ngx-datatable class='bootstrap striped' #table [rows]='result' [columnMode]='"force"' [headerHeight]='50' [footerHeight]='50' [rowHeight]='"auto"' [limit]="limit" [selectionType]="'single'" (select)="onSelect($event)" [selectCheck]="singleSelectCheck">
								<ngx-datatable-column [name]="col.name" [prop]="col.prop" *ngFor="let col of columns"></ngx-datatable-column>

								<ngx-datatable-column  name="Select">
									<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
										<input aria-label = 'select'  type="checkbox" name="select" *ngIf="row.isMedicaidEligible" formControlName="select" [checked]="isSelected" (change)="onCheckboxChangeFn($event)">
										<p *ngIf="!row.isMedicaidEligible" ><strong>Stop</strong> This form can't be submitted because this person is not receiving Institutional Medicaid coverage. Fill out an application and tell us this person needs nursing home care. <span><nh-popover style="padding-left: 1px;" content="{{'ADMIT_DISCHARGE_SEARCH' | translate}}"></nh-popover></span></p>
									</ng-template>
								</ngx-datatable-column>

							</ngx-datatable>
						</div>
					</div>

					<div class="pull-right" *ngIf="result && result.length">
						<p>Results Shown {{result.length}}</p>
					</div>
					<div *ngIf="result && !result.length && showSearch && isNewborn">
						<br>
						<p>We could not find the person you searched for. If this person has not already been approved, please submit a <a class="view-tennCare-title" (click)="startApp('HPE')">hospital presumptive application</a> for the mother and newborn.</p>
					</div>
					<div *ngIf="result && !result.length && showSearch && !isNewborn">
						<br>
						<p>We could not find the person you searched for. If this person is not enrolled in TennCare, please <a class="view-tennCare-title" (click)="startApp('AFB')">Apply For Coverage.</a> After submitting the application, please wait 24-48 hours before returning to complete the 2350 Form.</p>
					</div>
				</div>
			</div>
		</div>

		<div  *ngIf="!isNewborn" class="my-3">
			<button id="continue" aria-label="continueButton" [disabled]="nextDisaled" (click)="startApplication()" class="col-md-2 btn btn-block btn-secondary">{{'NEXT' | translate}}</button>
		</div>
	</div>
</div>
