import { Injectable } from '@angular/core';
import { Store, Select } from '@ngxs/store';

import { Observable } from 'rxjs';

import { PeopleState } from '@app/state/people/people.state';
import { setPeople, loadBlock, loadPerson } from '@app/state/people/people.creators';

import { Links } from '@app/models/links.model';
import { ApplicationNumber } from '@app/models/app.model';
import { Identifier, Block, Person } from '@app/models/record.model';

@Injectable()
export class PeopleFacade {
  @Select(PeopleState.activePerson) activePerson$: Observable<Person>;
  @Select(PeopleState.activeBlock) activeBlock$: Observable<Block>;
  @Select(PeopleState.blockLinks) blockLinks$: Observable<Links>;

  constructor(protected store: Store) {}

  setPeople(peopleSet): Observable<PeopleState> {
    return this.store.dispatch(setPeople(peopleSet));
  }

  loadPerson(applicationNumber: ApplicationNumber, identifier: Identifier): Observable<PeopleState> {
    return this.store.dispatch(loadPerson({ applicationNumber, identifier }));
  }

  loadBlock(applicationNumber: ApplicationNumber, identifier: Identifier): Observable<PeopleState> {
    return this.store.dispatch(loadBlock({ applicationNumber, identifier }));
  }
}
