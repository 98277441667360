import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "@app/gateway/services/api.service";

@Injectable({
    providedIn: 'root'
})
export class LinkCaseService {
  caseLinkfrom;
  isCaseLinked;
  selectedItem;
  isError: boolean;
  errorMessage = '';
  showError = false;
  showSuccess = false;

  constructor(private apiService: ApiService, private router: Router) {}

  onValueChange(selItem): void {
    switch (selItem) {
      case 'ssn':
        this.caseLinkfrom.controls['individualId'].disable();
        this.caseLinkfrom.controls['wpAppNum'].disable();
        break;
      case 'individualId':
        this.caseLinkfrom.controls['ssn'].disable();
        this.caseLinkfrom.controls['wpAppNum'].disable();
        break;
      case 'wpAppNum':
        this.caseLinkfrom.controls['ssn'].disable();
        this.caseLinkfrom.controls['individualId'].disable();
        break;
    }
  }

  saveCaseLink() {
    this.selectedItem = this.caseLinkfrom.controls['and'].value.code;
    const dateOfBirthFormat = this.caseLinkfrom.controls['dateOfBirth'].value;
    const userdateOfBirth = dateOfBirthFormat.getFullYear()
      + '-' + String(dateOfBirthFormat.getMonth() + 1).padStart(2, '0')
      + '-' + String(dateOfBirthFormat.getDate()).padStart(2, '0');

    const url = "indi/linkmycase";
    const saveLinkCase = {}
    saveLinkCase['dateOfBirth'] = userdateOfBirth;
    if(sessionStorage.getItem("userType") === 'RP'){
      saveLinkCase['usernameOrEmail'] = this.caseLinkfrom.controls['usernameOrEmail'].value;
    }
    saveLinkCase[this.selectedItem] = this.caseLinkfrom.controls[this.selectedItem].value;

      this.apiService.post(url, saveLinkCase, {header: new HttpHeaders({'pageId': 'TCLMC'})}).subscribe((res) => {
        this.isCaseLinked = res;

        this.isError = (res['code'] !== '000');
        // INDV ALREADY LINKED
        if (res['code'] === '200' && res['message'] === 'REQUESTED INDV ALREADY LINKED') {
          if(sessionStorage.getItem("userType") === 'RP'){
            this.showError = true;
            this.showSuccess = false;
          }
          this.errorMessage = 'ERROR_EXISTING_INDIVIDUAL'
          // INDIVIDUAL NOT FOUND or Record Set Empty
        } else if ((res['code'] === '200' || res['code'] === '100') && (res['message'] === 'INVALID APP NUM DOB' || res['message'] === 'RECORD SET EMPTY')) {
          if(sessionStorage.getItem("userType") === 'RP'){
            this.showError = true;
            this.showSuccess = false;
          }
          this.errorMessage = 'INDIV_NOT_FOUND'
          // APPLICATION PENDING
        } else if ((res['code'] === '200') && res['message'] === 'APPLICATION PENDING') {
          if(sessionStorage.getItem("userType") === 'RP'){
            this.showError = true;
            this.showSuccess = false;
          }
          this.errorMessage = 'APPLICATION_PENDING';
          // All other cases including WP down
        } else if ((res['code'] === '200') && res['message'] === 'Invalid Individual') {
          if(sessionStorage.getItem("userType") === 'RP'){
            this.showError = true;
            this.showSuccess = false;
          }
          this.errorMessage = 'ERROR_PERSON_ID_18';
          //Individual age is less than 18 and not HOH
        } else if ((res['code'] === '300') && res['message'] === 'DCS FOSTER RECORD FOUND') {
          if(sessionStorage.getItem("userType") === 'RP'){
            this.showError = true;
            this.showSuccess = false;
          }
          this.errorMessage = 'NO_LINKING_DCS_FOSTER';

        } else if (sessionStorage.getItem("userType") === 'RP' && (res['code'] === '200') && res['message'] === 'Invalid userName or email') {
          this.showError = true;
          this.showSuccess = false;
          this.errorMessage = 'LINK_CASE_USERNAME_OR_EMAIL_ERROR';
          //Individual age is less than 18 and not HOH
        } else {
          if(sessionStorage.getItem("userType") === 'RP'){
            this.showError = true;
            this.showSuccess = false;
          }
          this.errorMessage = 'GENERIC_LINKCASE_ERROR_MESSAGE';
          this.showSuccess = false;
        }

        if ((res['code'] === '000') && sessionStorage.getItem("userType") === 'RP') {
          this.showSuccess = true;
          this.showError = false;
          this.errorMessage = 'LINK_CASE_SUCCESS';
        }else if (res['code'] === '000') {
          (res['individualId']) ? sessionStorage.setItem('individualId', res['individualId']) : '';
          this.router.navigateByUrl('user-profile/benefit-dashboard');
        }
      });
  }

  calculateAge(birthday) {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  onCancel() {
    this.isError = false;
    return this.router.navigateByUrl('user-profile/manage-account');
  }
}