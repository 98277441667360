import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ providedIn: 'root' })

export class NudgeStatusService {
  public statusSubject = new Subject<string>();
  status$ = this.statusSubject.asObservable();

  updateStatus(status: string) {
    this.statusSubject.next(status);
  }
}