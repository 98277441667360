import { compose, map, evolve } from 'ramda';

import { RealEstateComponent } from './real-estate.component';

import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration, resourcesCoOwnerGroupRowCreator, nonMandatoryAddress } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { controlWith } from '@app/helpers/form.helpers';
import { trudy } from '@app/helpers/function.helpers';
import { disableGet } from '@app/helpers/able.helpers';
import { notYes } from '@app/helpers/tayn.helpers';
import { GlobalVars } from '@app/models/tables.model';
import { Validators } from '@angular/forms';
import { dropdownValidator, totalValidator } from '@app/helpers/validators.helpers';
import realEstateLabels from './real-estate-labels';

const formValidation = (builder, recordData, params) => {
  const realEstate = lookIn(recordData);
  const primaryResidence = realEstate(['primaryResidence'])
  const intendToReturn = realEstate(['intendToReturn']);
  const rentHome = realEstate(['rentHome']);
  const monthlyIncome = realEstate(['monthlyIncome']);
  const jointOwnership = realEstate(['jointOwnership']);

  const resourcesCoOwnerGroup = compose(
    (control: any) => builder.group(control),
    resourcesCoOwnerGroupRowCreator,
  );

  const coOwnersData = realEstate(['jointOwnershipInformation']) || [{
    jointOwnerIndividual: '',
    outOfHouseHoldname: '',
    jointOwnerPercentage: '',
  }];
  const jointOwnershipInformation = map(resourcesCoOwnerGroup, coOwnersData.map((item: any) => {
    const individual = params.individuals.find((indi: any) => +indi.identifier === item.jointOwnerIndividual.individualId);
    let jointOwnerIndividual: any;
    if (realEstate(['jointOwnershipInformation'])) {
      jointOwnerIndividual = individual ? { value : individual.name.fullName, code : individual.identifier } : GlobalVars.outSideOption;
    }
    return { ...item, jointOwnerIndividual };
  }));

  const data = builder.group({
      assetType: [realEstate(['assetType']), [Vs.required]],
      landUseCode: [realEstate(['landUseCode']), [Vs.required]],
      primaryResidence: [controlWith(primaryResidence, !primaryResidence), [Vs.required]],
      intendToReturn: [controlWith(intendToReturn, !intendToReturn)],
      rentHome: [controlWith(rentHome, !rentHome)],
      monthlyIncome: [controlWith(monthlyIncome, !monthlyIncome), Vs.currency],
      owedAmount: [realEstate(['owedAmount']), Vs.currency],
      assetAmount: [realEstate(['assetAmount']), Vs.currency],
      assetAddress: builder.group(nonMandatoryAddress(realEstate(['assetAddress']))),
      jointOwnership: [realEstate(['jointOwnership']), Vs.required],
      jointOwnershipInformation: builder.array(jointOwnershipInformation)
  }, { validators: Validators.compose([totalValidator, dropdownValidator])});


  trudy(() => disableGet('jointOwnershipInformation', data), notYes(jointOwnership));

  return { data };
};

const transformIndividulaObj = (value) => ({
  name : value?.code === 'EMPTY' ? '' : value?.value,
  individualId: value?.code === 'EMPTY' ? 0 : value?.code
});

const postObject = evolve({
  monthlyIncome: Number,
  owedAmount: Number,
  assetAmount: Number,
  jointOwnershipInformation: map(evolve({
    jointOwnerIndividual : transformIndividulaObj
  }))
});

export default sectionConfiguration(
  RealEstateComponent,
  formValidation,
  ['YESNO', 'MPPROPTYPE', 'REALPROPERTYUSE', 'STATE', 'COUNTY'],
  [
    { name: 'Owner', pipe: 'individual' },
    { name: 'Property Type', prop: 'data.assetType.value' },
    { name: 'Amount Owed', prop: 'data.owedAmount', pipe: 'currency' },
    { name: 'Property Value', prop: 'data.assetAmount', pipe: 'currency' },
  ],
  {...{}, ...realEstateLabels},
  {
    postObject
  }
);
