import { Component, OnInit } from '@angular/core';
import { BaseModalDirective } from '../base-modal';

@Component({
  selector: 'nh-browser-incompatible-modal',
  templateUrl: './browser-incompatible-modal.component.html',
  styleUrls: ['./browser-incompatible-modal.component.scss']
})
export class BrowserIncompatibleModalComponent extends BaseModalDirective implements OnInit {

  ngOnInit() {
  }

  onStart(): void {
    this.hide('');
  }

  onCancel(): void {
    this.hide('');
  }
}
