import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { StateService } from "@app/services/state/state.service";
import { StorageService } from "@app/services/storage/storage.service";
import { ApplicationService } from "@app/services/application/application.service";
import { BeforeStartComponent } from "@app/gateway/at-a-glance/before-start-application/before-start-application.component";
import { Title } from '@angular/platform-browser';

@Component({
  selector: "nh-provider-member-search",
  templateUrl: "./financial-welcomeback.component.html",
  styleUrls: ["./finanncial-welcomeback.component.scss"]
})

export class FinancialWelcomebackComponent implements OnInit {
  caseNum;
  caseDetails;
  appType = null;
  applicationNum;
  selectedNew = false;
  selectedContinue = false;

  constructor(
    private beforeStart: BeforeStartComponent,
    protected storageService: StorageService,
    private application: ApplicationService,
    private stateService: StateService,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle('Financial Welcome Back');
  }

  ngOnInit() {
    this.caseNum = JSON.parse(sessionStorage.getItem('caseNum'));
      if (this.caseNum) {
        this.application.getHeadOfHouseholdInfo(this.caseNum, 'REDETERMINATION').subscribe((res: any) => {
        this.caseDetails = res;
        this.applicationNum = res?.applicationNumber;
        sessionStorage.setItem('appNum', this.applicationNum);
      })
    }
  }

  onNext(value) {
    this.stateService.resetStates();
    if (value === 'continue') {
      this.beforeStart.startApplication('RB');
    } else {
      const userName = sessionStorage.getItem('userName');
      const postObj = { appNum: this.applicationNum , caseNum: this.caseNum, userId: userName };
      sessionStorage.setItem('appType', 'RB');
      this.application.deleteFinancialApp(postObj).subscribe(() => {
        sessionStorage.removeItem('appNum');
        this.router.navigateByUrl('/application/' + 'REDETERMINATION');
      })
    }
  }

  onPrevious() {
    this.router.navigateByUrl("api/prov/member-search?type=LOC");
  }

  onRowSelect(val) {
    if (val === 'continue') {
      this.appType = 'continue';
      this.selectedNew = true;
      this.selectedContinue = false;
    } else {
      this.appType = 'new';
      this.selectedContinue = true;
      this.selectedNew = false;
    }
  }
}
