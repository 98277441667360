import { evolve, prop, map, compose } from 'ramda';

import { AdditionalComponent } from './additional.component';

import Vs from '@app/services/validators/validators.service';
import { disables } from '@app/helpers/able.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { toDate, toISO } from '@app/helpers/date.helpers';
import { isNo, notYes, notNo, isYes } from '@app/helpers/tayn.helpers';
import { get, controlWith } from '@app/helpers/form.helpers';
import { newBornListCreator, sectionConfiguration } from '@app/helpers/models.helpers';
import { youngAdult, fosterCare } from '@app/helpers/person.helpers';
import { handleMedicalPrograms, familyPlanning } from '@app/helpers/programs.helpers';

const formValidation = (builder, recordData, { programs, selectedIndividual }) => {
  programs = handleMedicalPrograms(programs);

  const additional = lookIn(recordData);
  const isPregnantOne = additional(['pregnancyOne', 'isPregnant']);
  const numberOfBabiesOne = additional(['pregnancyOne', 'numberOfBabies']);
  const dueDateOne = additional(['pregnancyOne', 'dueDate']);
  const stillPregSwOne = additional(['pregnancyOne', 'stillPregSw']);
  const pregEndDateOne = additional(['pregnancyOne', 'pregEndDate']);
  const nextPregSwOne = additional(['pregnancyOne', 'nextPregSw']);
  const nextPregNumberOfBabiesOne = additional(['pregnancyOne', 'nextPregNumberOfBabies']);
  const nextPregDueDateOne = additional(['pregnancyOne', 'nextPregDueDate']);

  const formerFosterCare = additional(['formerFosterCare']);
  const helpWithEmergencyServiceBills = additional(['helpWithEmergencyServiceBills']);
  const addBabiesSw = additional(['pregnancyOne', 'addBabiesSw']);
  const noOfBabiesBorn = additional(['pregnancyOne', 'noOfBabiesBorn']);
  const ageGreaterThanEighteenOnTwentyThree = getAge(selectedIndividual.birthDate) >= 18;
  console.log(ageGreaterThanEighteenOnTwentyThree);
  // const ageGrEighteenOnTwentyThree = true;
  const hasMagi = familyPlanning(programs);
  const isPregnable = selectedIndividual.age >= 10 && selectedIndividual.age <= 55 && selectedIndividual.gender.code === 'F';

  const isFosterCareAge = fosterCare(selectedIndividual);
  console.log(isFosterCareAge);

  const citizenData = prop('usCitizen', recordData);
  const isCitizen = isNo(citizenData) && citizenData !== null
  const isTaxFiling = additional(['taxFilerInfo', 'filing']);
  const isDependent = additional(['taxFilerInfo', 'hasTaxDependentOutside']);
  const taxFiling = additional(['taxFilerInfo'])

  const { applyForCoverage, applyForOnGoingCoverage } = selectedIndividual;
  const disablePastPreg = isYes(applyForCoverage) && isNo(applyForOnGoingCoverage);
  const isRmbRmc = sessionStorage.getItem('appType') === 'RB' || sessionStorage.getItem('appType') === 'RC';
  // const isChangeReport = sessionStorage.getItem('appType') === 'CHANGE_REPORT';

  const isApplyMedServices = (selectedIndividual.age < 19) || (isPregnantOne?.code === 'Y');
  const isRecievedMedSer = additional(['medicalService', 'receivedMedSer']);
  const isDateRecievedMedSer = additional(['medicalService', 'dateReceivedMedSer']);
  console.log(isApplyMedServices,isRecievedMedSer, isDateRecievedMedSer);
  const user = sessionStorage.getItem('userType');
  const appType = sessionStorage.getItem('appType');
  const isAFBorFC = (appType === 'AFB') || (user == 'RP' && (appType === 'AFB'));
  console.log(isAFBorFC);

  const newBornListData = additional(['pregnancyOne', 'newBornInfoList']) || [{
    ssn: null,
    gender: [null, Vs.required],
    newbornMiddleName: null,
    newbornDOB: [toDate(null), [Vs.required]],
    newbornLastName: [null, Vs.required],
    newbornFirstName: [null, Vs.required]
  }];

  const newBornCtrlsList = compose((control: any) => builder.group(control), newBornListCreator);

  const newBornList = map(newBornCtrlsList, newBornListData);
  const showPastPregDueDateNudge = additional(['showPastPregDueDateNudge']);
  const wpIndvNumber = additional(['wpIndvNumber']);

  const data = builder.group({
    taxFilerInfo: builder.group({
      filing: [controlWith(isTaxFiling, !taxFiling)],
      hasTaxDependentOutside: [controlWith(isDependent, !taxFiling)]
    }),

    pregnancyOne: builder.group({
      isPregnant: [controlWith(isPregnantOne, !isPregnable), Vs.required],
      numberOfBabies: [controlWith(numberOfBabiesOne, notYes(stillPregSwOne)), Vs.required],
      stillPregSw: [controlWith(stillPregSwOne, notYes(isPregnantOne) ), Vs.required],
      pregEndDate: [controlWith(toDate(pregEndDateOne), notNo(stillPregSwOne)), [Vs.required]],
      dueDate: [controlWith(toDate(dueDateOne), notYes(stillPregSwOne)), [Vs.required]],
      nextPregSw: [controlWith(nextPregSwOne, !isPregnable || disablePastPreg), Vs.required],
      nextPregNumberOfBabies: [controlWith(nextPregNumberOfBabiesOne, notYes(nextPregSwOne)), Vs.required],
      nextPregDueDate: [controlWith(toDate(nextPregDueDateOne), notYes(nextPregSwOne)), [Vs.required]],
      addBabiesSw: [controlWith(addBabiesSw, notNo(stillPregSwOne)), [Vs.required]],
      noOfBabiesBorn: [controlWith(noOfBabiesBorn, notYes(addBabiesSw)), [Vs.required]],
      newBornInfoList: builder.array(newBornList)
    }),
    formerFosterCare: [controlWith(formerFosterCare, (!isFosterCareAge || !isYes(applyForCoverage))), Vs.required],
    helpWithEmergencyServiceBills: [controlWith(helpWithEmergencyServiceBills, (!isCitizen || isRmbRmc))],
    
    medicalService: builder.group({
      receivedMedSer: [controlWith(isRecievedMedSer, (!isApplyMedServices || !isAFBorFC)) ],
      dateReceivedMedSer: [controlWith(toDate(isDateRecievedMedSer), (!isYes(isRecievedMedSer)) || !isAFBorFC ), [Vs.required, Vs.notBefore90Days]]
    }),
    showPastPregDueDateNudge: [showPastPregDueDateNudge],
    wpIndvNumber: [wpIndvNumber]

  });

  if (hasMagi && programs.length <= 1) {
    if (!youngAdult(selectedIndividual)) {
      disables([get('education', data)]);
    }
  }
  return { data };
};

const getAge = (dob) => {
  var today = new Date('2023-01-01');
  var birthDate = new Date(dob);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
      age--;
  }    
  return age;
}

const postObject = evolve({
  pregnancyOne: {
    dueDate: toISO,
    pregnancyEndDate: toISO,
    nextPregDueDate: toISO,
    pregEndDate: toISO,
    newBornInfoList: map(
      evolve({
        newbornDOB: toISO
      })
    ),
  },
  pregnancyTwo: {
    dueDate: toISO,
    pregnancyEndDate: toISO,
  },
  medicalService: {
    dateReceivedMedSer: toISO
  }
});

export default sectionConfiguration(AdditionalComponent, formValidation, [], [], {}, { postObject });