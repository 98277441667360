import { AddStartComponent } from './start.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';

const formValidation = (fb) => {
  const item = {code: "MA", value: "Medical Coverage"}
  const program = {program: item}
   const data =  fb.group({
    existingPrograms: fb.array([ program ]),
    newPrograms: fb.array([])
   })
   return {data};
}

export default sectionConfiguration(AddStartComponent, formValidation, [], [], {}, {
  hasSummary: false,
  showSubmit: false,
  showComments: false,
  showSaveAndExit: false,
});
