
import { DemographicsComponent } from './demographics.component';
import Vs from '@app/services/validators/validators.service';
import { isY, isYes, notYes } from '@app/helpers/tayn.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { controlWith } from '@app/helpers/form.helpers';
import { toDate, toISO } from '@app/helpers/date.helpers';
import { aPath } from '@app/helpers/function.helpers';
import { nilControl } from '@app/helpers/form-control.helpers';
import { checkboxHelper } from '@app/helpers/form-array.helpers';
import { sectionConfiguration, nameCreator, otherNameCreator } from '@app/helpers/models.helpers';
import { disableGet } from '@app/helpers/able.helpers';
import { evolve, when, isNil } from 'ramda';

const formValidation = (builder, recordData, { tables: { RACEDISPLAY } }) => {
  const demographics = lookIn(recordData);

  const ssn = demographics(['ssnInformation', 'ssn']);
  const ssnConfirm = demographics(['ssnInformation', 'ssnConfirm']);
  const ssnAppliedDate = demographics(['ssnInformation', 'ssnAppliedDate']);
  const races = aPath(['ethnicity', 'races'], recordData);
  const otherRace = demographics(['ethnicity', 'otherRace']);
  const racesHelper = checkboxHelper(races, RACEDISPLAY);
  const helpers = builder.group({ races: builder.group(racesHelper(RACEDISPLAY)) });
  const appliedWithOtherName = (demographics(['appliedWithOtherName']));
  const usCitizen = demographics(['citizenship', 'usCitizen']);
  const derivedCitizen = demographics(['citizenship', 'derivedCitizen']);
  const registrationNumber = demographics(['citizenship', 'document', 'registrationNumber']);
  const naturalizationCertificateNumber = demographics(['citizenship', 'document', 'naturalizationCertificateNumber']);
  const livingAreaType = demographics(['livingArrangement', 'livingAreaType']);
  const tennesseeResident = demographics(['livingArrangement', 'tennesseeResident']);
  const outOfHome = demographics(['livingArrangement', 'outOfHome']);
  const intendToReturn = demographics(['livingArrangement', 'intendToReturn']);
  const intendToReturnDate = toDate(demographics(['livingArrangement', 'intendToReturnDate']));
  const tribeName = demographics(['tribeName']);
  const healthProgram = demographics(['healthProgram']);
  const eligHlthProgram = demographics(['eligHlthProgram']);
  const isDerivedCitizen = isY(derivedCitizen);
  const federalRecogizedTribe = demographics(['federalRecogizedTribe']);

  const data = builder.group({
    name: builder.group(nameCreator(demographics(['name']))),
    appliedWithOtherName: [appliedWithOtherName, Vs.required],
    otherName: builder.group(otherNameCreator(demographics(['otherName']))),
    gender: [demographics(['gender']), Vs.required],
    birthDate: [toDate(demographics(['birthDate'])), [Vs.required]],
    ssnInformation: builder.group({
      ssn: [ssn, Vs.ssn],
      ssnConfirm: [ssnConfirm, Vs.ssn],
      ssnAppliedDate: toDate(ssnAppliedDate),
    }),
    applyForCoverage: [demographics(['applyForCoverage']), Vs.required],
    applyForOnGoingCoverage: [demographics(['applyForOnGoingCoverage']), Vs.required],
    citizenship: builder.group({
      usCitizen: [controlWith(usCitizen, !usCitizen), Vs.required],
      derivedCitizen: [controlWith(derivedCitizen, !derivedCitizen), Vs.required],
      document: builder.group({
        registrationNumber: [controlWith(registrationNumber, !isDerivedCitizen), Vs.alienNumber],
        naturalizationCertificateNumber: [controlWith(naturalizationCertificateNumber, !isDerivedCitizen), Vs.naturalizationNumber]
      })
    }),
    livingArrangement: builder.group({
      livingAreaType: [controlWith(livingAreaType, !livingAreaType)],
      tennesseeResident: [tennesseeResident, Vs.required],
      outOfHome: controlWith(outOfHome, !outOfHome || isNil(tennesseeResident)),
      intendToReturn: controlWith(intendToReturn, !intendToReturn),
      intendToReturnDate: [controlWith(intendToReturnDate, !intendToReturnDate), Vs.invalidReturnDate]
    }),
    ethnicity: builder.group({
      races: [races, [Vs.required]],
      otherRace: [nilControl(otherRace), [Vs.required, Vs.maxLength(20)]],
    }),
    federalRecogizedTribe: [controlWith(federalRecogizedTribe, !federalRecogizedTribe), Vs.required],
    tribeName: [controlWith(tribeName, !isYes(federalRecogizedTribe)), Vs.language],
    healthProgram: [controlWith(healthProgram, !isYes(federalRecogizedTribe)), Vs.required],
    eligHlthProgram: [controlWith(eligHlthProgram, !isYes(federalRecogizedTribe)), Vs.required],
    hispanicOrigin: [demographics(['hispanicOrigin']), Vs.required],
  });

  when(notYes, () => disableGet('otherName', data), appliedWithOtherName);

  return { data, helpers };
};

const postObject = evolve({
  birthDate: toISO,
  ssnInformation: {
    ssnAppliedDate: toISO,
  },
  livingArrangement: {
    intendToReturnDate: toISO
  }
});

export default sectionConfiguration(DemographicsComponent, formValidation, [], [], {}, { postObject });
