import { Component, DoCheck } from '@angular/core';
import { BaseModalDirective } from '@app/components/modals/base-modal';

@Component({
  templateUrl: './appeal-eligibility.component.html',
  styleUrls: ['./appeal-eligibility.component.scss'],
})
export class AppealEligibilityModalComponent extends BaseModalDirective implements DoCheck {
  priorHealthPlanCaseNumber = [];
  caseNumberList1 = [];
  screenWidth: number;
  isMobileView = false;
  mobileWidth = 480;

  appealEligibilityTypeColumns = [
    { name: 'APPEAL_ELIGIBILITY_TYPE', prop: 'appealType' },
    { name: 'APPEAL_ELIGIBILITY_DESCRIPTION', prop: 'description' }
  ];
  appealEligibilityTypeRows = [
    { 'appealType': 'ELIGIBILITY_APPEAL', 'description': 'ELIGIBILITY_APPEAL_DESCRIPTION' },
    { 'appealType': 'RENEWAL_APPEAL', 'description': 'RENEWAL_APPEAL_DESCRIPTION' },
    { 'appealType': 'KATIE_BECKETT_APPEAL', 'description': 'KATIE_BECKETT_APPEAL_DESCRIPTION' },
    { 'appealType': 'LTSS_APPEAL', 'description': 'LTSS_APPEAL_DESCRIPTION' }
  ]

  ngDoCheck() {
    this.isMobileView = this.screenWidth <= this.mobileWidth;
  }
}
