export const name = {
    firstName: 'FIRST_NAME',
    lastName: 'LAST_NAME',
    middleInitial: 'MIDDLE_NAME',
    suffix: 'SUFFIX'
}

export const ssnInfo  = {
    ssn: 'SOCIAL_LABEL',
    ssnAppliedDate: 'RMB_SUMMARYSSN_STATIC'
}

export const document = {
    registrationNumber: 'ALIEN_NUMBER',
    naturalizationCertificateNumber: 'NATURALIZATION_NUMBER'
}

export const citizenDetails = {
    usCitizen: 'RMB_CITIZEN_STATIC',
    derivedCitizen: 'RMB_DERIVED_CITIZEN_STATIC',
    document: document
}

export const living = {
    livingAreaType: 'LIVING_ARRANGEMENT_STATIC_TEXT',
    tennesseeResident: 'RMB_RESIDENT',
    outOfHome: 'RMB_OUTOFHOME_STATIC',
    intendToReturn: 'RMB_INTTEND_TO_RETURN',
    intendToReturnDate: 'RMB_INTTEND_TO_RETURN_DATE'
}

export const ethnicity = {
    races: 'RACE'
}

export default {
    applyForCoverage: 'Is indiv applying for coverage with us?',
    name: name,
    appliedWithOtherName: 'OTHER_NAME1',
    otherName: name,
    gender: 'GENDER',
    birthDate: 'DOB',
    ssnInformation: ssnInfo,
    citizenship: citizenDetails,
    livingArrangement: living,
    ethnicity: ethnicity,
    federalRecogizedTribe : 'FEDERAL_RECOGNIZED_TRIBE',
    tribeName: 'NAME_OF_TRIBE',
    healthProgram: 'RMB_HEALTH_PROGRAM',
    eligHlthProgram: 'RMB_ELG_HEALTH_PROGRAM',
    hispanicOrigin: 'HISPANIC_ORIGIN'
}
