import { compose, map, pick } from 'ramda';

import { recordIdentifier } from '@app/helpers/record.helpers';
import { viewBlocks, viewPerson } from '@app/helpers/lenses.helpers';

export const viewIdentifier = compose(
  recordIdentifier,
  viewPerson
);

export const blockLinks = compose(
  map(pick(['blockId', 'title', 'status'])),
  viewBlocks
);
