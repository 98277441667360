import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'nh-remove-svg',
  template: `<svg id="{{id}}_trash_bin" data-name="⚛️ icons/trash-bin-outline" xmlns="http://www.w3.org/2000/svg"
  width="24" height="24" viewBox="0 0 24 24">
  <path id="{{id}}_shape" data-name="Combined Shape"
    d="M5.834,19.5A2.25,2.25,0,0,1,3.6,17.547l0-.034V17.5L2.324,5.25H1.5A1.5,1.5,0,0,1,0,3.75V1.5A1.5,1.5,0,0,1,1.5,0H21a1.5,1.5,0,0,1,1.5,1.5V3.75A1.5,1.5,0,0,1,21,5.25h-.823L18.9,17.5v.01A2.25,2.25,0,0,1,16.7,19.5H5.834Zm-.744-2.159,0,.012A.75.75,0,0,0,5.819,18H16.666a.751.751,0,0,0,.743-.647V17.34L18.668,5.25H3.832ZM21,3.75V1.5H1.5V3.75H21ZM13.357,14.792l-.013-.012L11.25,12.686,9.156,14.781a.75.75,0,0,1-1.073-1.049l.012-.013,2.095-2.095L8.095,9.53A.75.75,0,0,1,9.143,8.457l.013.013,2.094,2.094L13.344,8.47a.75.75,0,0,1,1.073,1.049l-.012.012-2.095,2.095,2.095,2.095a.75.75,0,0,1-1.049,1.073Z"
    transform="translate(0.75 2.25)" fill="#174a7c" />
</svg>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveSvgComponent {
  @Input () id;
}
