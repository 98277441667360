import { Component, Inject, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BaseModalDirective } from '@app/components/modals/base-modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IGetCoverageDetails, IEndCoverageDetails } from '../../../models/endCoverage.model';
@Component({
  templateUrl: './end-coverage-summary-modal.component.html',
})

export class EndCoverageSummaryModalComponent extends BaseModalDirective {
  constructor(
    @Inject(BsModalRef) protected modalRef: BsModalRef,
    @Inject(FormBuilder) protected builder: FormBuilder) {
    super(modalRef, builder);
  }
  @Input() entityDetails: IGetCoverageDetails[] = [];
  @Input() appNum: number;

  buildRequestQuery(): IEndCoverageDetails[] {
    const data: IEndCoverageDetails[] = []
    if (this.entityDetails) {      
      let appNumber : any;   
      appNumber = sessionStorage.getItem('appNum'),
      this.entityDetails.forEach((entity: IGetCoverageDetails) => {
        data.push({
        'appNum' : appNumber,
        'indvId' : entity.indvNum,
        'caseNum' : entity.caseNum,
        'srcAppInd' : "C"
        })
      })
    }
    return data;
  }

  onConfirm(): void {
    const data = this.buildRequestQuery();    
    if (data) {
      this.hide({ data });
      super.onConfirm();
    }
  }

  onCancel(): void {
    this.hide('');
    this.entityDetails = null;
  }

}
