<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <p *ngIf="isRmbRmc">{{ 'OTHER_COVERAGE_STATICTEXT14' | translate }}</p>
  <h6 class="person-label">{{selectedIndividual | fullname}}</h6>
  <section [formGroup]="getControl('data')">
    <nh-field id = "tdo_hasTaxDependentOutside"
      label="{{'DOES_2' | translate }}{{selectedIndividual | fullname}} {{'TAX_DEPENDENTS_OUTSIDE_STATIC1' | translate}}"
      class="label-section">
      <select nh name="hasTaxDependentOutside" aria-label="hasTaxDependentOutside"
        formControlName="hasTaxDependentOutside" [items]="tableCodeValue('YESNO', 'data.hasTaxDependentOutside')" (change)="hasTaxDependentsOutside()"></select>
    </nh-field>
    <div (window:resize)="onResize($event)"></div>
    <div class="d-none d-lg-block" *ngIf="isY('data.hasTaxDependentOutside')">
      <h5 class="section-header">{{'TAX_DEPENDENTS_OUTSIDE_STATIC2' | translate}}</h5>
      <hr>
      <table class="table w-100 table-striped">
        <thead>
          <tr>
            <th scope="col">{{'TAX_DEPENDENTS_OUTSIDE_STATIC3' | translate}}</th>
            <th scope="col">{{'DOB' | translate}}</th>
            <th scope="col">{{'RELATIONSHIP_STATIC_TEXT3' | translate}} <span>{{selectedIndividual | fullname}}</span></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody formArrayName="taxDependentsOutside">
          <tr *ngFor="let dependent of taxDependentsOutside.controls; index as i;" [formGroupName]="i">
            <td>
              <nh-field id = "tdo_name" [isRow]="true" [mandatory]="true">
                <input type="text" class="form-control" aria-label="name" formControlName="name" name="name" nh (change)="dataChange()">
              </nh-field>
            </td>
            <td>
              <nh-field id = "tdo_birthDate" [isRow]="true" [mandatory]="false">
                <nh-datepicker>
                  <input type="text" class="form-control" (change)="isInvalidDate($event, dependent)" [minDate]="minimumDate" formControlName="birthDate"
                    [maxDate]="today" bsDatepicker nh (change)="dataChange()">
                </nh-datepicker>
              </nh-field>
            </td>
            <td>
              <nh-field id = "tdo_relation" [isRow]="true">
                <select nh name="relation" formControlName="relation" aria-label="relation"
                [items]="tableCodeValue('RELATIONTYPE', i)" empty="Pick an option"></select>
              </nh-field>
            </td>
            <td>
              <div *ngIf="enableDeleteButton()" class="pt-1 hover-red">
                <button class="btn btn-link btn-lg delete-{{i}} hover-red p-1" (click)="removeAt(i)">
                  <span class="pr-2">{{'REMOVE' | translate}}</span>
                  <nh-remove-svg></nh-remove-svg>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pt-1 hover-red text-left">
        <button class="btn btn-link btn-lg hover-red" (click)="addRow()">
          <span class="pr-2">{{'ADD_2' | translate}}</span>
          <nh-add-svg></nh-add-svg>
        </button>
      </div>
    </div>
    <div class="nh-table-mobile mt-3 pt-2 d-lg-none" *ngIf="isY('data.hasTaxDependentOutside')">
      <div formArrayName="taxDependentsOutside">
        <div class="table-row mb-3 pb-3" *ngFor="let dependent of taxDependentsOutside.controls; index as i;"
          [formGroupName]="i">
          <h5 class="section-header">{{'TAX_DEPENDENTS_OUTSIDE_STATIC2' | translate}}</h5>
          <hr>
          <div class="mobile-table-header">{{'TAX_DEPENDENTS_OUTSIDE_STATIC3' | translate}}</div>
          <hr>
          <div class="mobile-table-data">
            <nh-field id = "tdo_name" [mandatory]="true">
              <input type="text" class="form-control" aria-label="name" formControlName="name" name="name" nh>
            </nh-field>
          </div><br>
          <div class="mobile-table-header">{{'DOB' | translate}}</div>
          <hr>
          <div class="mobile-table-data">
            <nh-field id = "tdo_birthDate" [mandatory]="false">
              <nh-datepicker>
                <input type="text" class="form-control" (change)="isInvalidDate($event, dependent)" [minDate]="minimumDate" formControlName="birthDate"
                  [maxDate]="today" bsDatepicker nh>
              </nh-datepicker>
            </nh-field>
          </div><br>
          <div class="mobile-table-header">{{'RELATIONSHIP_STATIC_TEXT3' | translate}} <span>{{selectedIndividual | fullname}}</span></div>
          <hr>
          <div class="mobile-table-data">
            <nh-field id = "tdo_relation">
              <select nh name="relation" formControlName="relation" aria-label="relation" [items]="tableCodeValue('RELATIONTYPE', i)"
                empty="Pick an option"></select>
            </nh-field>
          </div><br>
          <div *ngIf="enableDeleteButton()" class="pt-1 hover-red text-center">
            <button class="btn btn-link btn-lg delete-{{i}} hover-red p-1" (click)="removeAt(i)">
              <span class="pr-2">{{'REMOVE' | translate}}</span>
              <nh-remove-svg></nh-remove-svg>
            </button>
          </div><br>
        </div>
      </div>
      <div class="pt-1 hover-red text-left">
        <button class="btn btn-link btn-lg hover-red" (click)="addRow()">
          <span class="pr-2">{{'ADD_2' | translate}}</span>
          <nh-add-svg></nh-add-svg>
        </button>
      </div>
    </div>
  </section>
</nh-form>