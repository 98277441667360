import { trigger, transition, animate, style, query, stagger, keyframes } from '@angular/animations';

const fadeIn = animate(
  `.3s ease-in`,
  keyframes([
    style({ opacity: 0 }),
    style({ opacity: 1 }), //
  ])
);

const fadeOut = animate(
  '.3s ease-out',
  keyframes([
    style({ opacity: 1 }),
    style({ opacity: 0 }), //
  ])
);

const enter = query(':enter', [stagger('.1s', [fadeIn])], { optional: true });
const leave = query(':leave', [stagger('.1s', [fadeOut])], { optional: true });

export const notificationList = trigger('notificationList', [transition('* => *', [enter, leave])]);
