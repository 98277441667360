<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <div class="mt-3 pr-5">
    <div [formGroup]="getControl('data')">
      <section class="container">

        <p>Please answer the questions below to complete the Nursing Facility Admittance/Discharge Form 2350.</p>
        <p class="searchfor-member-title"><strong>Patient Information</strong><nh-popover class="pl-1" content="We'll use information we have in our records to pre-fill some of the information below.  If this information is incorrect or needs to be changed, call us at 855-259-0701."></nh-popover></p>
        <form [formGroup]="patientInformation">
          <div class="form-row">

            <div class="col-lg-3">
              <nh-field id = "pain_firstName" label="First Name" [mandatory]="false" class="label-section">
                <input type="text" name="firstName" aria-label="firstName"  formControlName="firstName" readonly disabled>
              </nh-field>
            </div>

            <div class="col-lg-3">
              <nh-field  id = "pain_middleInitial" [isRow]="true" label="Middle Initial" [mandatory]="false" class="label-section">
                <input type="text" name="middleInitial" aria-label="middleInitial" formControlName="middleInitial" readonly disabled>
              </nh-field>
            </div>

            <div class="col-lg-3">
              <nh-field id = "pain_lastName" label="Last Name" [mandatory]="false" class="label-section">
                <input maxlength="21" type="text" name="lastName" aria-label="lastName" formControlName="lastName" readonly disabled>
              </nh-field>
            </div>

            <div class="col-lg-2">
              <nh-field id = "pain_suffix" [isRow]="true" label="Suffix" [mandatory]="false" class="label-section">
                <input type="text" name="suffix" aria-label="suffix" formControlName="suffix" readonly disabled>
              </nh-field>
            </div>
          </div>

          <nh-field id = "pain_dateOfBirth" label="Date of Birth" [mandatory]="false" class="label-section">
            <nh-datepicker [readOnly]="true">
              <input type="text" name="dateOfBirth" aria-label="dateOfBirth" formControlName="dateOfBirth" [minDate]="minimumDate"
                [maxDate]="today" bsDatepicker nh readonly disabled>
            </nh-datepicker>
          </nh-field>


          <div class="form-row">
            <div class="col-6 col-md-6">
              <nh-field id = "pain_ssn" label="Social Security Number" [mandatory]="false" class="label-section">
                <input type="text" name="ssn" aria-label="ssn" formControlName="ssn" readonly disabled>
              </nh-field>
            </div>
          </div>
          <div class="form-row">
            <div class="col-6 col-md-6">
              <nh-field id = "pain_gender" label="Gender" [mandatory]="false" class="label-section">
                <select nh name="gender" aria-label="gender" formControlName="gender" readonly disabled>
                  <option value="" selected disabled hidden>{{gender}}</option>
                </select>

              </nh-field>
            </div>
          </div>
          <p class="searchfor-member-title"><strong>Admission or Discharge</strong></p>
          <nh-field id = "pain_admitDischarge" [isRow]='true' label="Are you reporting an admission or discharge for {{getControl('data.firstName').value}} {{getControl('data.lastName').value}}?" [mandatory]="true" class="label-section">
            <select nh name="admitDischarge" [items]="tables.PPAMDIS" aria-label="admitDischarge" formControlName="admitDischarge"
              (change)="selectChangeHandler($event)"></select>
          </nh-field>
        </form>
      </section>
    </div>
  </div>
</nh-form>