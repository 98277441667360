import { Pipe, PipeTransform } from '@angular/core';

import { gender } from '@app/helpers/person.helpers';

import { Individual } from '@app/models/individual.model';

@Pipe({ name: 'gender' })
export class GenderPipe implements PipeTransform {
  transform(individual: Individual): string {
    return gender(individual);
  }
}
