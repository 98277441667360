import { compose } from 'ramda';
import { when } from 'ramda';
import { path } from 'ramda';

import { contained } from 'ramda-adjunct';

import { value } from './value.helpers';

import { SkipForm, TopLevelAnswer } from '@app/models/section.model';

export const isSkipNo = contained([TopLevelAnswer.NO, TopLevelAnswer.SKIP]);

const valueTLA = path(['value', 'extras', 'topLevelAnswer']);

const skipForm = (form: SkipForm): SkipForm => ({
  value: form.value,
  valid: true,
  pristine: false,
  dirty: true,
  controls: form.controls,
});

export const formSkipNo = when(
  compose(
    isSkipNo,
    valueTLA
  ),
  skipForm
);

const validOptions = [TopLevelAnswer.NO, TopLevelAnswer.SKIP, TopLevelAnswer.YES];

export const isValidTopLevel = compose(
  contained(validOptions),
  value
);
