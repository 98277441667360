<div class="container">
    <div class="row">
        <div class="small edit-button">
            <div class="pr-4">
                <p>Password</p>
                <p>********</p>
            </div>
        </div>
        <div class="small pl-4"><a>Edit</a></div>
    </div>
</div>