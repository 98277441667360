<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <section [formGroup]="getControl('data')">

    <h5 class="section-header">{{'SUBMIT_STATIC_TEXT1' | translate}}</h5>

    <hr>
    <p *ngIf="!bccExt && !isIME" class="static-text">{{'SUBMIT_STATIC_TEXT2' | translate }}
      <span> {{keyword | translate }} </span>
       {{'SUBMIT_STATIC_TEXT3' | translate }}
    </p>
    <p *ngIf="bccExt || isIME">I know that I must tell TennCare if anything changes (and is different than) what I answered on this {{keyword}} 
      Form within 10 days. I can report changes online at www.tenncareconnect.tn.gov. I can call 1-855-259-0701 to report any changes. 
      I can mail changes to TennCare Connect at P.O. Box 305240, Nashville, TN 37230-5240. I can fax changes to 1-855-315-0669. 
      Someone at a county DHS office can help me report a change. I understand that a change in my information could affect the eligibility for a 
      member(s) of my household.</p>

    <p class="static-text">{{'SUBMIT_STATIC_TEXT4' | translate }}</p>
    <p *ngIf="!bccExt && !isIME" class="static-text">{{'SUBMIT_STATIC_TEXT5' | translate }}</p>
    <p *ngIf="bccExt || isIME" class="static-text">I know that if I am approved, I can’t keep any health insurance or medical payments I get from insurance or other companies. Those payments belong to the State. 
      I understand I must sign them over to the State.</p>
    <p>{{'SUBMIT_STATIC_TEXT9' | translate }}</p>
    <p class="static-text">{{'SUBMIT_STATIC_TEXT7' | translate }}{{keyword3 | translate }}, {{'SUBMIT_STATIC_TEXT8' | translate }}</p>
    <p *ngIf="!bccExt && !isIME" [innerHTML]="'SUBMIT_STATIC_TEXT10' | translate"></p>
    <p *ngIf="bccExt || isIME">I know if the State pays for medical bills or for nursing home care for me, the State may get that money back.  I know that after my death, the State may be paid back with money from my estate.</p>
    <p class="static-text">{{'SUBMIT_STATIC_TEXT11' | translate }}</p>
    <p class="static-text">{{'SUBMIT_STATIC_TEXT12' | translate }}</p>
    <p class="static-text" *ngIf="translatedLang === 'en' && !bccExt && !isIME && !isPreterm">
      {{'SUBMIT_STATIC_TEXT13' | translate }}
      <span> {{keyword | translate }} </span>
        {{'SUBMIT_STATIC_TEXT14' | translate }}
    </p>
    <p *ngIf="bccExt || isIME">If anyone on the application is eligible for health care coverage with TennCare, I am giving TennCare rights to pursue and get any money from other health insurance, legal settlements, or other third parties. I am also giving TennCare rights to pursue and get medical support from a spouse or parent.</p>
    <p class="static-text" *ngIf="translatedLang !== 'en' && !bccExt && !isIME && !isPreterm">{{'SUBMIT_STATIC_TEXT13' | translate }}</p>
    <p class="static-text" *ngIf="isPreterm">{{'SUBMIT_STATIC_TEXT13_QFR' | translate }}</p>
    <p *ngIf="!bccExt && !isIME && !isPreterm" class="static-text">{{'SUBMIT_STATIC_TEXT15_1' | translate }}
      <span> {{keyword | translate }} </span>
       {{'SUBMIT_STATIC_TEXT15_2' | translate }}
    </p>
    <p *ngIf="isPreterm" class="static-text">{{'SUBMIT_STATIC_TEXT15_QFR' | translate }}</p>
    <p *ngIf="bccExt || isIME">Does any child on this application have a parent living outside of the home? If yes, I know I will be asked to cooperate with the agency that collects medical support from an absent parent. If I think that cooperating to collect medical support will harm me or my children, I can tell TennCare and I may not have to cooperate.</p>
    <p class="static-text">{{'SUBMIT_STATIC_TEXT16' | translate }}
    </p>
    <p class="static-text">{{'SUBMIT_STATIC_TEXT17' | translate }}</p>

    <p class="static-text">{{'SUBMIT_STATIC_TEXT45' | translate }}</p>

    <h5 class="section-header">{{'SUBMIT_STATIC_TEXT18' | translate }}</h5>

    <hr>
    <p *ngIf="!isIME" class="static-text">{{'SUBMIT_STATIC_TEXT19' | translate }}&nbsp;<a href="https://tn.gov/tenncare"><strong>{{'SUBMIT_STATIC_TEXT20' | translate }}</strong> </a> {{'SUBMIT_STATIC_TEXT22' | translate }}</p>
    <p *ngIf="isIME" class="static-text">{{'SUBMIT_STATIC_TEXT19_1' | translate }}&nbsp;<a href="https://tn.gov/tenncare"><strong>{{'SUBMIT_STATIC_TEXT20_1' | translate }}</strong> </a> {{'SUBMIT_STATIC_TEXT21' | translate }} {{'SUBMIT_STATIC_TEXT22' | translate }}</p>
    <h5 class="section-header">{{'SUBMIT_STATIC_TEXT23' | translate }}</h5>

    <hr>
    <p *ngIf="!bccExt && !isIME" class="static-text">{{'SUBMIT_STATIC_TEXT24' | translate }}
      {{'SUBMIT_STATIC_TEXT25' | translate }}
      <a href="https://www.tn.gov/tenncare/members-applicants/civil-rights-compliance.html">
        https://www.tn.gov/tenncare/members-applicants/civil-rights-compliance </a> {{'SUBMIT_STATIC_TEXT26' | translate}}
    </p>
    <p *ngIf="bccExt || isIME">We do not allow unfair treatment in our program. No one is treated in a different way because of 
      race, color, birthplace, religion, language, sex, age, or disability. Do you think you’ve been treated unfairly? 
      Do you have more questions? Do you need more help? You can make a free call to TennCare Connect at 855-259-0701.  Or go to <a
        class="view-tennCare-title"
        href="https://www.tn.gov/tenncare/members-applicants/civil-rights-compliance.html"><strong>http://www.tn.gov/tenncare/topic/non-discrimination-compliance</strong>
      </a> to learn more.</p>


    <div *ngIf="isPresumptive && !isFinReassessment">
      <h5 class="section-header">Presumptive Applications</h5>

      <hr>
      <p class="static-text">
        I want to apply for temporary TennCare based on TennCare's rules. I understand that TennCare is using the facts on this page to decide if I can get TennCare. I know that if I lie on purpose to get TennCare, I could be fined or go to jail. I know if I do not submit a full Application now, I must also mail or apply for TennCare at Tenncareconnect.tn.gov by the end of next month or I will lose coverage. If I am not eligible, I know I can still apply for TennCare and other programs at <a href="http://www.tenncareconnect.tn.gov">Tenncareconnect.tn.gov</a>.
      </p>

      <nh-checkbox name="presumptiveApplicationSignedBySw"  [control]="getControl('data.presumptiveApplicationSignedBySw')" checkedValue="PARTNER_PORTAL" [mandatory] ="true"
        label="By checking this box, I agree that this information is true and correct based on what I know." class="label-section">
      </nh-checkbox>

      <nh-alert type="danger" [isOpen]="this.isShowSign && !this.getValue('data.presumptiveApplicationSignedBySw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="warning" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>
    </div>

    <br>
    <h5 class="section-header">{{'SUBMIT_STATIC_TEXT27' | translate }}</h5>

    <hr>

    <p *ngIf="!bccExt && !isIME" class="static-text">{{'SUBMIT_STATIC_TEXT28' | translate }} {{keyword2 | translate }} {{'SUBMIT_STATIC_TEXT29' | translate }}</p>
    <p *ngIf="bccExt">I’m signing this page under penalty of perjury which means I’ve provided true answers to all the questions for the person(s) named here and its supplements to the best of my knowledge. I also certify that:</p>
    <p *ngIf="isIME">I’m signing this page under penalty of perjury which means I’ve provided true answers to all the questions for the person(s) named in this Incurred Medical Expense form and its supplements to the best of my knowledge. I also certify that:</p>
    <p *ngIf="!bccExt && !isIME" class="static-text">{{'SUBMIT_STATIC_TEXT30' | translate }} {{keyword2 | translate }} {{'SUBMIT_STATIC_TEXT31' | translate }} {{keyword2 | translate }} {{'SUBMIT_STATIC_TEXT32' | translate }}</p>
    <p *ngIf="bccExt">I agree to submit this form by electronic means. By signing this BCC Treatment Plan Extension Request electronically, I understand that an electronic signature has the same legal effect and can be enforced in the same ways as a written signature.</p>
    <p *ngIf="isIME">I agree to submit this Incurred Medical Expense form by electronic means. By signing this Incurred Medical Expense form electronically, I understand that an electronic signature has the same legal effect and can be enforced in the same ways as a written signature.
    </p>
    <p *ngIf="!bccExt && !isIME" class="static-text">{{'SUBMIT_STATIC_TEXT33' | translate }} {{keyword2 | translate }}.</p>
    <p *ngIf="bccExt">I understand the questions and statements on this BCC Treatment Plan Extension Request.</p>
    <p *ngIf="isIME">I understand the questions and statements on this Incurred Medical Expense form.</p>
    <p class="static-text">{{'SUBMIT_STATIC_TEXT34' | translate }}</p>
    <p class="static-text">{{'SUBMIT_STATIC_TEXT35' | translate }}</p>

   <div *ngIf="(isAFB || isRmbRmc) && authRep && !isFinReassessment">
      <h5 class="section-header">{{'SUBMIT_STATIC_TEXT36' | translate }}</h5><hr>

      <nh-checkbox  *ngIf="!isPreterm" name="applicationSignedBySw" [control]="getControl('data.applicationSignedBySw')" checkedValue="ASSISTING_PERSON"
        label="{{'SUBMIT_STATIC_TEXT37' | translate }}" class="label-section">
      </nh-checkbox>
      
      <nh-checkbox *ngIf="isPreterm" name="applicationSignedBySw" [control]="getControl('data.applicationSignedBySw')" checkedValue="ASSISTING_PERSON"
        label="{{'SUBMIT_STATIC_TEXT37_QFR' | translate }}" class="label-section">
      </nh-checkbox>

      <nh-alert type="danger" [isOpen]="isShowSign && !this.getValue('data.applicationSignedBySw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="warning" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>

      <nh-field id = "sub_applicationSignName" label="{{'SIGNATURE' | translate }}" class="label-section">
        <input type="text" class="form-control" name="applicationSignName" formControlName="applicationSignName" aria-label="applicationSignName"
          maxlength="40">
      </nh-field>
    </div>

    <div *ngIf="((isAFB || isRmbRmc) && !authRep) && !isKepro && !isPartner && !isFinReassessment">
      <h5 class="section-header">{{'SUBMIT_STATIC_TEXT38' | translate }} </h5>
      <hr>

      <nh-checkbox *ngIf="!isPreterm" name="applicationSignedBySw" [control]="getControl('data.applicationSignedBySw')" checkedValue="HOUSEHOLD_MEMBER"  [mandatory]="true"
        label="{{'SUBMIT_STATIC_TEXT39' | translate }} {{keyword4 | translate }}." class="label-section">
      </nh-checkbox>
      <nh-checkbox  *ngIf="isPreterm" name="applicationSignedBySw" [control]="getControl('data.applicationSignedBySw')" checkedValue="HOUSEHOLD_MEMBER"  [mandatory]="true"
      label="{{'SUBMIT_STATIC_TEXT39_QFR' | translate }} {{keyword4 | translate }}." class="label-section">
      </nh-checkbox>

      <nh-alert type="danger" [isOpen]="isShowSign && !getValue('data.applicationSignedBySw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="warning" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>

      <nh-field id = "sub_applicationSignName" label="{{'SIGNATURE' | translate }}" class="label-section">
        <input type="text" class="form-control" name="applicationSignName" formControlName="applicationSignName" aria-label="applicationSignName"
          maxlength="40">
      </nh-field>
    </div>

    <div *ngIf="isKepro">
      <h5 class="section-header">{{'SUBMIT_STATIC_TEXT38' | translate }} </h5>
      <hr>

      <nh-checkbox  *ngIf="!isPreterm" name="applicationSignedBySw" [control]="getControl('data.applicationSignedBySw')" checkedValue="HOUSEHOLD_MEMBER"  [mandatory]="true"
        label="{{'SUBMIT_STATIC_TEXT39' | translate }} {{keyword4 | translate }}." class="label-section">
      </nh-checkbox>
      <nh-checkbox  *ngIf="isPreterm" name="applicationSignedBySw" [control]="getControl('data.applicationSignedBySw')" checkedValue="HOUSEHOLD_MEMBER"  [mandatory]="true"
      label="{{'SUBMIT_STATIC_TEXT39_QFR' | translate }} {{keyword4 | translate }}." class="label-section">
      </nh-checkbox>

      <nh-alert type="danger" [isOpen]="isShowSign && !getValue('data.applicationSignedBySw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="warning" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>

      <nh-field id = "sub_applicationSignName" label="{{'SIGNATURE' | translate }}" class="label-section">
        <input type="text" class="form-control" name="applicationSignName" formControlName="applicationSignName" aria-label="applicationSignName"
          maxlength="40">
      </nh-field>
      <div class="form-check-label">
        <span class="label-section">{{'CASE_NOTES' | translate }}</span> {{applicationSource  | translate }} {{'CASE_NOTES_TEXT' | translate }} {{userFullName}}({{userName}}) {{'ON' | translate }} {{ convertMonth(appSubmittedDate | date: 'longDate') }}.
        <nh-field id = "sub_callCenterComments" label [mandatory]="false">
        <textarea maxlength="2000" placeholder="Additional Notes..." class="form-control" name="callCenterComments" formControlName="callCenterComments" aria-label="callCenterComments" rows="5" autofocus="true" style="margin-top:5px"></textarea>
        </nh-field>
      </div>
    </div>



    <div *ngIf="(isPresumptive || isPartner) && !isKepro  && !isFinReassessment">

      <h5 class="section-header">Patient or Responsible Party:</h5><hr>

      <nh-checkbox *ngIf="!isIME"  name="applicationSignedBySw" uniqueString="application" [control]="getControl('data.applicationSignedBySw')" checkedValue="PARTNER_PORTAL" [mandatory]=true
        label="By checking this box and typing my name below, I am electronically signing as the patient or responsible party." class="label-section">
      </nh-checkbox>
      <nh-checkbox *ngIf="isIME" name="applicationSignedBySw" uniqueString="application" [control]="getControl('data.applicationSignedBySw')" checkedValue="PARTNER_PORTAL" [mandatory]=true
      label="By checking this box and typing my name below, I am electronically signing this Incurred Medical Expense form as the patient." class="label-section">
    </nh-checkbox>

      <nh-alert type="danger" [isOpen]="this.isShowSign && !getValue('data.applicationSignedBySw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="warning" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>

      <nh-field id = "sub_applicationSignName" label="Signature:" class="label-section">
        <input type="text" class="form-control" name="applicationSignName" formControlName="applicationSignName" aria-label="applicationSignName" maxlength="40">
      </nh-field>

      <h5 class="section-header">{{'SUBMIT_STATIC_TEXT40' | translate }}</h5><hr>
      <div *ngIf="isPresumptive" class="static-text"> By signing, you attest that you have accurately recorded information provided by the applicant. By signing, you also agree that you understand you must:
        <ul class="list-inline">
          <li> Tell the applicant of the presumptive determination.</li>
          <li> Offer to mail a paper Application or to help the applicant apply online for ongoing TennCare.</li>
          <li> Tell the applicant a full Application must be filed by the end of next month or that they may lose eligibility.</li>
          <li> Tell the applicant, if denied, they can still send in a full Application or apply again at <a class="add-primary-color" href="https://tenncareconnect.tn.gov">https://tenncareconnect.tn.gov</a> for TennCare and other programs.</li>
        </ul>
      </div>
      <br>

      <nh-checkbox *ngIf="!bccExt && !isIME" name="partnerSignedBySw" [control]="getControl('data.partnerSignedBySw')" checkedValue="PARTNER_PORTAL" [mandatory] ="true"
        label="{{'SUBMIT_STATIC_TEXT41' | translate }}" class="label-section">
      </nh-checkbox>
      <nh-checkbox *ngIf="bccExt" name="partnerSignedBySw" [control]="getControl('data.partnerSignedBySw')" checkedValue="PARTNER_PORTAL" [mandatory] ="true"
      label="{{'SUBMIT_STATIC_TEXT46' | translate }}" class="label-section">
    </nh-checkbox>
    <nh-checkbox *ngIf="isIME" name="partnerSignedBySw" [control]="getControl('data.partnerSignedBySw')" checkedValue="PARTNER_PORTAL" [mandatory] ="true"
    label="By checking this box and typing my name below, I am electronically signing this Incurred Medical Expense form as the TennCare Access user." class="label-section">
    </nh-checkbox>

      <nh-alert type="danger" [isOpen]="this.isShowPartner && !getValue('data.partnerSignedBySw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="warning" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>

      <nh-field id = "sub_partnerSignName" label="{{'SIGNATURE' | translate }}" class="label-section">
        <input type="text" class="form-control" name="partnerSignName" formControlName="partnerSignName" aria-label="partnerSignName" maxlength="40">
      </nh-field>
    </div>

    <div *ngIf="isFinReassessment && !isKepro">
      <p class="section-header"><strong>{{'SUBMIT_STATIC_TEXT44' | translate }}</strong></p>
      <nh-checkbox  name="applicationSignedBySw" uniqueString="applicationBy" [control]="getControl('data.applicationSignedBySw')" checkedValue="CALL_CENTER"  [mandatory]="true"
        label="{{'SUBMIT_STATIC_TEXT42' | translate }}" class="label-section">
      </nh-checkbox>

      <nh-alert type="danger" [isOpen]="isShowSign && !this.getValue('data.applicationSignedBySw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="warning" />
          </div>
          <div class="message">
            <span>{{'SUBMIT_STATIC_TEXT43' | translate }}</span>
          </div>
        </div>
      </nh-alert>
      <br>
      <nh-field id = "sub_applicationSignName" *ngIf="this.getValue('data.applicationSignedBySw')" label="{{'SIGNATURE' | translate }}" class="label-section">
        <input type="text" class="form-control" name="applicationSignName" formControlName="applicationSignName" aria-label="applicationSignName" maxlength="40">
      </nh-field>
      <div *ngIf="this.getValue('data.applicationSignedBySw')">
        <p class="section-header"><strong>TennCare Access User:</strong></p>
        <nh-checkbox  name="applicationSigned" uniqueString="applicationSigned" [control]="getControl('data.applicationSigned')"
          checkedValue="WORKER_PORTAL" [mandatory]="true"
          label="By checking this box and typing my name below, I am electronically signing this Financial Reassessment for Medicaid as the TennCare Access User."
          class="label-section">
        </nh-checkbox>

        <nh-alert type="danger" [isOpen]="isShowPartner && !this.getValue('data.applicationSigned')">
          <div class="row">
            <div class="icon">
              <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="warning" />
            </div>
            <div class="message">
              <span>{{'SUBMIT_STATIC_TEXT43' | translate }}</span>
            </div>
          </div>
        </nh-alert>
        <br>
        <nh-field id = "sub_partnerSignName" label="{{'SIGNATURE' | translate }}" class="label-section">
          <input type="text" class="form-control" name="partnerSignName" formControlName="partnerSignName" aria-label="partnerSignName" maxlength="40">
        </nh-field>
      </div>
    </div>
  </section>
</nh-form>