<div *ngIf="isRmbRmc"><span>{{ staticText }}</span></div>
<div *ngIf="isRmbRmc && this.selectedIndividual"><br></div>
<div *ngIf="getValue('extras.topLevelAnswer') === 'YES'" form-top>
  <h6 class="person-label">{{ selectedIndividual | fullname }}</h6>
</div>
<nh-form [topLevelName] = "'bu'" [individuals]="checkForDropdown ? finalListAll : individuals" [formGroup]="getForm()" [mode]="mode" class="label-section"
  topLevelQuestion="{{'BURRIAL_TOPLEVEL_QUESTION' | translate }}"
  individualQuestion="{{'BURRIAL_INDIV_QUESTION' | translate }}" (validate)="onValidate($event)" (individualAnswerChanged)="onIndividualChange($event)">

  <section [formGroup]="getControl('data')">
    <h5 class="section-header">{{'BURRIAL_STATIC_TEXT1' | translate }}</h5>
    <hr>

    <nh-field id = "bu_assetType" 
      label="{{'BURRIAL_STATIC_TEXT3' | translate }} {{ selectedIndividual | fullname }}{{'VEHICLE_STATIC_TEXT3' | translate }} <div class=hint>{{'IF' | translate }} {{ selectedIndividual | fullname }} {{'BURRIAL_STATIC_TEXT4' | translate }}</div>"
      class="label-section">
      <select nh name="assetType" aria-label="assetType" [items]="tableCodeValue('BURIALRESOURCETYPECD', 'data.assetType')" formControlName="assetType"></select>
    </nh-field>

    <div class="row pt-1 hover-red">
      <a class="btn btn-link hover-red" (click)="showBurialPopup()">
        <span class="pr-2">{{'BURRIAL_STATIC_TEXT2' | translate }}</span>
        <span><i class="fa fa-external-link"></i></span>
      </a>
    </div>
    <br>

    <nh-field id = "bu_redesignatedFor" 
      label="{{'BURRIAL_STATIC_TEXT5' | translate }} {{ getValue('data.assetType').value }}{{'BURRIAL_STATIC_TEXT6' | translate }}"
      class="label-section">
      <select nh name="redesignatedFor" [items]="tableCodeValue('BURIALRESDESIGNATEDFOR', 'data.redesignatedFor')" 
        formControlName="redesignatedFor" aria-label ="redesignatedFor"></select>
    </nh-field>

    <nh-field id = "bu_assetAmount" [mandatory]="false" label="{{'BURRIAL_STATIC_TEXT7' | translate }}" class="label-section">
      <nh-input-group prepend="$">
        <input nh type="decimal"  maxlength="7" name="assetAmount" formControlName="assetAmount" class="form-control">
      </nh-input-group>
    </nh-field>

    <nh-field  id = "bu_owedAmount" [mandatory]="false"
      label="{{'VEHICLE_STATIC_TEXT9' | translate }} {{ selectedIndividual | fullname }} {{'BURRIAL_STATIC_TEXT8' | translate }}"
      class="label-section">
      <nh-input-group prepend="$">
        <input nh type="decimal"  maxlength="7" name="owedAmount" formControlName="owedAmount" class="form-control">
      </nh-input-group>
    </nh-field>

    <br>

    <div [formGroup]="getControl('data.bankSearch')">

      <div *ngIf="isEnabled('data.bankSearch.bankName')">
        <h5 class="section-header">{{'BURRIAL_STATIC_TEXT9' | translate }}</h5>
        <hr>
      </div>

      <nh-search [applicationNumber]="applicationNumber" keyword="bank" resource="banks" [searchOnOpen]="true"
        [columns]="resultsColumns" [searchGroup]="getControl('extras.helpers.search')" [showSearchButton]="false"
         [showSearch]="true" (row)="selectItem($event)" (reset)="deleteItem()" (search)="searchHandler()" selectColumn="true">

        <ng-container search-form [formGroup]="getControl('extras.helpers.search')">

          <div class="row">
            <div class="col-12">
              <nh-field id = "bu_bankName" [isRow]="true"  [mandatory]="true" label="{{'BURRIAL_STATIC_TEXT10' | translate }}" class="label-section">
                <input type="text" name="bankName" formControlName="bankName" aria-label="bankName" class="form-control"
                [maxlength]="bankNameLength" [required]="isEnabled('extras.helpers.search.bankName')">
              </nh-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-4">
              <nh-field id = "bu_state" [isRow]="true" [mandatory]="false" label="{{'STATE' | translate }}" class="label-section">
                <select nh name="state" aria-label="state" [items]="tables.STATE" bindValue="code"
                  formControlName="state"></select>
              </nh-field>
            </div>

            <div class="col-12 col-md-4">
              <nh-field id = "bu_zipCode" [isRow]="true" [mandatory]="false" label="{{'ZIP_CODE' | translate }}" class="label-section">
                <input type="text" name="zipCode" mask="00000-9999" pattern="" placeholder="XXXXX-XXXX" formControlName="zipCode" class="form-control"  />
              </nh-field>
            </div>
          </div>
        </ng-container>
      </nh-search>

      <nh-field id = "bu_bankName" *ngIf="showBankAddress" [isRow]="true" [mandatory]="true" 
        label="{{'FINANCIAL_RESOURCES_STATIC_TEXT18' | translate }}" class="label-section">
        <input  type="text" name="bankName" formControlName="bankName" aria-label ="bankName" class="form-control" 
        [maxlength]="bankNameLength" [attr.disabled]="isAddressDisabled ? true : null" [required]="true">
      </nh-field> 

      <nh-name-address [addressname] = "'bu_assetAddress'" *ngIf="showBankAddress" [address]="getControl('data.assetAddress')" [stateDefault]="false"
        [showPopup]="false" [isAssistingPerson]="true" [tables]="tables" [addressFieldMandatory]="true"
        [isDisabled]="isAddressDisabled"></nh-name-address>
    </div>
    <div *ngIf="selectedIndividual">
    <h5 class="section-header">
      {{'CO-OWENERS_TITLE' | translate }}
    </h5>
    <hr>

    <nh-field id = "bu_jointOwnership"
      label="{{'VEHICLE_STATIC_TEXT14' | translate }} {{ getValue('data.assetType').value }} {{'VEHICLE_STATIC_TEXT15' | translate }} {{ selectedIndividual | fullname }}? <div class=hint>{{'BURRIAL_STATIC_TEXT11' | translate }} {{ selectedIndividual | fullname }} {{'BURRIAL_STATIC_TEXT12' | translate }}</div>"
      class="label-section">
      <select nh name="jointOwnership" [items]="tableCodeValue('YESNO', 'data.jointOwnership')"  formControlName="jointOwnership" aria-label="jointOwnership"></select>
    </nh-field>

    <div *ngIf="isY('data.jointOwnership')">
      <nh-co-owner [jointOwnershipInformation]="getControl('data.jointOwnershipInformation')"
        [formData]="getControl('data')" [addRowValid]="checkValid()" [coOwnerIndividuals]="coOwnerIndividuals"
        [error]="error" (emitValidate)="coOwnerValidate($event)" (errorMessage)="getErrorMessage($event)" [coOwnerIndivErrorMessage]="coOwnerIndivErrorMessage"
        (removeRow)="removeRow($event)" (addRow)="addRow()"></nh-co-owner>
    </div>
  </div>
  </section>
</nh-form>