<p *ngIf="isRmbRmc">{{ "RENEWAL_TEXT" | translate }}</p>
<div *ngIf="isY('data.filing')" class="d-sm-block">
  <h6 class="person-label">{{ this.fullName }}</h6>
  <br>
</div>
<nh-form [individuals]="individuals" [formGroup]="getForm()" (validate)="onValidate($event)">

  <section formGroupName="data">
    <nh-field id = "tf_filing" *ngIf="this.hideTopLevel" label="{{'TAX_FILING_STATIC_TEXT1' | translate }}" class="label-section" [col9]='true'>
      <select nh aria-label="filing" name="filing" id="filing" formControlName="filing" [items]="tableCodeValue('YESNO', 'data.filing')" (change)="onChangeTaxFiling()"></select>
    </nh-field>


      <nh-field *ngIf="(isY('data.filing') || !this.hideTopLevel)" id = "tf_taxFilingIndividualId" label="{{'TAX_FILING_STATIC_TEXT2' | translate }}" class="label-section" [col9]='true'>
        <div class="hint" [innerHTML]="'TAX_FILING_STATIC_TEXT3' | translate"></div>
        <select nh aria-label="taxFilingIndividualId" name="taxFilingIndividualId" id="taxFilingIndividualId"
          formControlName="taxFilingIndividualId" bindLabel="name.fullName" bindValue="identifier" [items]="individuals"
          required (change)="resetTaxDependents()"></select>
      </nh-field>

      <nh-field id = "tf_jointFiler" *ngIf="isEnabled('data.jointFiler') && (isY('data.filing') || !this.hideTopLevel)"
        label="{{'DOES_2' | translate }}{{ this.fullName }} {{'TAX_FILING_STATIC_TEXT4' | translate }} {{ this.refFullname }}?"
        class="label-section">
        <select nh aria-label="jointFiler" name="jointFiler" id="jointFiler" [items]="tableCodeValue('YESNO', 'data.jointFiler')"
          formControlName="jointFiler" (change)="resetTaxDependents()"></select>
      </nh-field><br>

      <div *ngIf="(this.showDependents || isEnabled('data.taxDependent')) && (isY('data.filing') || !this.hideTopLevel)">
        <h5 class="section-header">{{'TAX_FILING_STATIC_TEXT5'| translate }}</h5>
        <hr>
        <nh-field id = "tf_taxDependent"
          label="{{'DOES_2' | translate }}{{ this.fullName }} {{'TAX_FILING_STATIC_TEXT6' | translate }}"
          class="label-section">
          <select nh aria-label="taxDependent" name="taxDependent" id="taxDependent" [items]="tableCodeValue('YESNO', 'data.taxDependent')"
            formControlName="taxDependent" (change)="resetTaxDependents()"></select>
        </nh-field>
      </div>

      <div *ngIf="isY('data.taxDependent')&& (isY('data.filing') || !this.hideTopLevel)" [formGroup]="getControl('data')">
        <div class="mb-2 pb-2 pt-2">
          <label class="label-section" for="taxDependents"><span class="text-danger">*</span>{{'TAX_FILING_STATIC_TEXT7'
            | translate }} {{ fullName }}{{'TAX_FILING_STATIC_TEXT8' | translate}}
          </label>

         <div *ngFor="let choice of checkboxList; let i=index" class="mt-2 pt-2">
           <div class="form-check pb-md-1 checkbox">
            <label class="form-check-label mt-2">
              <input id = "{{'tf' + choice + i}}" type="checkbox" [value]="choice.individualId" (click)="onCheckChange($event)" [checked]="choice.checked">
                <span class="cr"><i class="cr-icon fa fa-check" aria-hidden="true"></i></span>{{choice.prop}}
            </label>
          </div>
          </div>
          <div *ngIf="getControl('data.taxDependents').status === 'INVALID'">
            <nh-alert type="warning" [isOpen]="true">
              <div class="row">
                <div class="icon">
                  <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
                </div>
                <div class="message">
                  <span>Please answer the required field.</span>
                </div>
              </div>
            </nh-alert>
          </div>
        </div>
      </div>

  </section>
</nh-form>