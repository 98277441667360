import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'nh-exit-svg',
  template: `<svg id="{{id}}_log-in-outline" data-name="⚛️ icons/log-in-outline" xmlns="http://www.w3.org/2000/svg"
  width="24" height="24" viewBox="0 0 24 24">

  <path id="{{id}}_shape" data-name="Combined Shape" d="M15.375,16.5H8.25a2.965,2.965,0,0,1-3-2.581V12a.75.75,0,0,1,1.5-.014v1.89c0,.514.757,1.112,1.478,1.124h7.147A1.125,1.125,0,0,0,16.5,13.893V2.625A1.124,1.124,0,0,0,15.394,1.5H7.875A1.125,1.125,0,0,0,6.75,2.606V4.5a.75.75,0,0,1-1.5.014V2.625A2.626,2.626,0,0,1,7.832,0h7.543A2.626,2.626,0,0,1,18,2.581V13.875A2.625,2.625,0,0,1,15.419,16.5ZM9.969,12.531a.75.75,0,0,1-.012-1.049l.012-.013L12.44,9H.75A.75.75,0,0,1,.735,7.5h11.7L9.969,5.03a.75.75,0,0,1-.012-1.049l.012-.012a.75.75,0,0,1,1.049-.013l.013.013,3.75,3.749a.75.75,0,0,1,.012,1.049l-.012.013-3.75,3.75a.751.751,0,0,1-1.061,0Z" 
    transform="translate(3 3.75)" fill="#174a7c"/>
  </svg>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExitSvgComponent {
  @Input () id;
}

