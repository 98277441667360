export default {
    assetAddress: 'RMB_BURRIAL_STATIC5',
    phoneNumber: 'PHONE_NUMBER1',
    insuranceName: 'COMPANY_NAME',
    assetType: 'RMB_LIFE_INSURANCE_STATIC1',
    assetAmount: 'LIFE_INSURANCE_STATIC_TEXT5',
    faceAmount: 'LIFE_INSURANCE_STATIC_TEXT3',
    policyNumber: 'POLICY_NUMBER',
    removed: {
        lifeIncEndDate: 'RMB_LIFE_INSURANCE_STATIC2'
    }
}