import { RelationshipsComponent } from './relationships-new.component';
import { FormArray } from '@angular/forms';

import { sectionConfiguration } from '@app/helpers/models.helpers';
import { sortWith, descend, prop, isEmpty, isNil } from 'ramda';
import Vs from '@app/services/validators/validators.service';
import { aPath } from '@app/helpers/function.helpers';

const formValidation = (builder, recordData, { individuals }) => {
  const relationships = aPath(['relationshipList'], recordData);

  const createCtrls = (sourceIdentifier, refIndentifier, title, valueObj, refObj, isReverseFlow) => { 
    if( valueObj && valueObj['referenceIndividual'] === JSON.parse(sourceIdentifier) && valueObj['sourceIndividual'] === JSON.parse(refIndentifier)){
      refIndentifier = JSON.stringify(valueObj['referenceIndividual']);
      sourceIdentifier = JSON.stringify(valueObj['sourceIndividual']);
    }; 
    return builder.group({
      relation: [valueObj ? valueObj.relation : null, Vs.required],
      inverse: null,
      sourceIndividual: sourceIdentifier,
      referenceIndividual: refIndentifier,
      primaryCaregiver: [valueObj ? valueObj.primaryCaregiver : null],
      title: title,
      refObj: refObj,
      isReverseFlow: isReverseFlow,
      relationshipValidation: null,
      unknownGenderValidation: null,
      primaryCaregiverValidation: null,
      showRelationText: null
    });
  };

  const createMinorCtrls = (minorIndvs, idx, primaryTitle, valueObj) => {    
    const careTaker = valueObj.filter(value => value.primaryCaregiver?.code === 'Y' && ((value.referenceIndividual == minorIndvs) ||  (value.sourceIndividual == minorIndvs)));
    const actualCareTakers = [];
    careTaker.forEach(indv => actualCareTakers.push({ individualId: indv.sourceIndividual, name: indv.name }));

    const val = actualCareTakers[0]?.individualId ? { code: 'Y', value: 'Yes' } : null;
    return builder.group({
      ['primaryCaregiver' + idx]: [!isEmpty(careTaker) ? careTaker[0]?.primaryCaregiver : isEmpty(relationships) ? null: (valueObj.length > 0) ? { code: 'N', value: 'No' } : null, Vs.required],
      ['indvId']: minorIndvs,
      ['primaryCaregiverIndividual' + idx]: [{ value: val, disabled: careTaker[0]?.primaryCaregiver?.code !== 'Y' }],
      ['items' + idx]: '',
      primaryTitle: primaryTitle
    });
  };

  const findRelation = (src, ref) => {
    if (!isNil(relationships.filter(i => ((i.sourceIndividual == src) && (i.referenceIndividual == ref)))[0])) {
      const valueObj = relationships.filter(i => ((i.sourceIndividual == src) && (i.referenceIndividual == ref)))[0];
      return valueObj ? valueObj : null;
    } else {
    return relationships.filter(i => ((i.sourceIndividual == src) && (i.referenceIndividual == ref)))[0];
    }
  }

  const relationshipList = (): FormArray => {
    const peopleArray: any = [];

    let finalList;
     const descendSort = sortWith([descend(prop('age'))]);
      finalList = descendSort(individuals);

      for (let i = 0; i < finalList.length; i++) {
        let title = finalList[i].name.fullName + `${"'s Relationships"}`;
        for (let j = i + 1; j < finalList.length; j++ ) {
          if (relationships.length) {
            peopleArray.push(createCtrls(finalList[i].identifier, finalList[j].identifier, title, findRelation(finalList[i].identifier, finalList[j].identifier),finalList[j], false));
          } else {
            peopleArray.push(createCtrls(finalList[i].identifier, finalList[j].identifier, title, null,finalList[j], false));
          }
          if(finalList[i].gender.code === 'U' || finalList[j].gender.code === 'U' ) {
            if (relationships.length) {
             peopleArray.push(createCtrls(finalList[j].identifier, finalList[i].identifier, title, findRelation(finalList[j].identifier, finalList[i].identifier),finalList[i], true));
            } else {
              peopleArray.push(createCtrls(finalList[j].identifier, finalList[i].identifier, title, null,finalList[i], true));
            }
          }
          title = '';
        }
      }
      return peopleArray as FormArray;
    };
  const primaryCareList = (): FormArray => {
    const minorIndivs: any = [];
    let primaryIndv;
    const descendSort = sortWith([descend(prop('age'))]);
    primaryIndv = descendSort(individuals);
    primaryIndv = primaryIndv.slice(1);
    
    for (let i = 0; i < primaryIndv.length; i++ ) {
      let primaryTitle = primaryIndv[i].name.fullName + `${"'s Primary Caregiver"}`;
      if (relationships) {
        minorIndivs.push(createMinorCtrls(primaryIndv[i].identifier, i, primaryTitle, findCareGivers(primaryIndv[i].identifier)));
      } else {
        minorIndivs.push(createMinorCtrls(primaryIndv[i].identifier, i, primaryTitle, null));
      }
      primaryTitle = '';
    }
    return minorIndivs as FormArray;
  };

  const findCareGivers = (primaryIndv) => {
    return relationships.filter(rel => ((rel.referenceIndividual == primaryIndv) || (rel.sourceIndividual == primaryIndv)));
  };

  const data = builder.group({  
    relationshipList: builder.array(relationshipList()),
    primaryCareList: builder.array(primaryCareList()),
    hiddenField: null,
    relationshipValidation: null,
    unknownGenderValidation: null,
    primaryCaregiverValidation: null
  });

  return { data };
};

export default sectionConfiguration(RelationshipsComponent, formValidation, ['YESNO', 'RELATIONTYPE', 'RELATIONTYPE_GENDER'], [], {}, { hasSummary: false });
