import { identity } from 'ramda';
import { useWith } from 'ramda';
import { ifElse } from 'ramda';
import { compose } from 'ramda';
import { tap } from 'ramda';

import { emptyElse } from './null.helpers';
import { firstCode } from './code.helpers';
import { enableLook } from './able.helpers';
import { assign } from './function.helpers';
import { enableSelfControls } from './forms.helpers';
import { codeIndexed, anyCodes } from './code.helpers';
import { validValues, withValueSet } from './form.helpers';
import { enableGroupControls, disableGroupControls } from './form-group.helpers';

export const hasPrograms = anyCodes(['KB', 'MA', 'HK', 'FP']);
export const programCodes = useWith(assign, [identity, codeIndexed]);

export const childCare = anyCodes(['CC']);
export const familyPlanning = anyCodes(['HK', 'FP']);
export const medicalCoverage = anyCodes(['MA']);
export const taxFilerApplicable = anyCodes(['HK', 'FP', 'MA']);
export const grossIncome = anyCodes(['MA', 'HK', 'KB']);

export const foodStamps = anyCodes(['FS']);
export const homeCareChildren = anyCodes(['KB']);
export const cashAssistance = anyCodes(['CA']);
export const limitedServices = anyCodes(['MA', 'HK']);

// Cash Assistance, Medical Assistance, HC-CSD, Medicare Assistance.
export const multiplePrograms = anyCodes(['CA', 'MA', 'KB', 'MS']);

export const coverage = anyCodes(['HK', 'FP', 'KB']);
const enableCoverage = useWith(enableLook, [identity, firstCode]);
const toggleCoverage = (programKeys, nonCoverage) =>
  ifElse(coverage, enableCoverage(programKeys), () => enableSelfControls(nonCoverage));

const updatePrograms = withValueSet([identity, validValues]);

export const togglePrograms = (programKeys, nonCoverage, helpers, selectedPrograms) =>
  compose(
    emptyElse(() => enableGroupControls(helpers), toggleCoverage(programKeys, nonCoverage)),
    tap(() => disableGroupControls(helpers)),
    tap(updatePrograms(selectedPrograms)),
    validValues
  );

export const handleMedicalPrograms = programs => {
  const mediPrograms = programs.filter(p => p.code === 'MA' || p.code === 'HK' || p.code === 'KB');
  if (mediPrograms.length > 1 && mediPrograms.some(p => p.code === 'MA')) {
    programs = programs.filter(p => p.code !== 'HK' && p.code !== 'KB');
  }
  return programs;
};
