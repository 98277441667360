<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <div>
    <h5 class="section-header">Individual Summary</h5>
    <hr>
    <nh-table [columns]="individualSummaryColumns" [sectionId]="'PRASM'" [rows]="individualSummaryResults" [mode]="mode" (edit)="onIndividualRowEdit($event)"
      (delete)="onIndividualRowDelete($event)">
    </nh-table>

    <div class="pt-1 hover-red" *ngIf="!isPresumptiveBccApp">
      <a class="btn btn-link btn-lg hover-red" (click)="addIndividual()">
        <span class="pr-2">Add Another Person</span>
        <svg id="_icons_add-circle-outline" data-name="⚛️ icons/add-circle-outline" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path id="Combined_Shape" data-name="Combined Shape" d="M0,9.75A9.75,9.75,0,1,1,9.75,19.5,9.761,9.761,0,0,1,0,9.75Zm1.5,0A8.25,8.25,0,1,0,9.75,1.5,8.259,8.259,0,0,0,1.5,9.75ZM9,13.514V10.5H6A.75.75,0,0,1,5.985,9H9V6a.75.75,0,0,1,1.5-.015V9h3a.75.75,0,0,1,.014,1.5H10.5v3a.75.75,0,0,1-1.5.014Z" transform="translate(2.25 2.25)" fill="#174a7c"/>
        </svg>
      </a>
    </div>
  </div>
  <br>
  <br>

  <div *ngIf="showRelationships">
    <h5 class="section-header">Relationships Summary</h5>
    <hr>
    <div >
      <nh-table [columns]="relationshipSummaryColumns" [sectionId]="'PRASM'" [rows]="relationshipSummaryResults" [mode]="mode" (edit)="onRelationshipsRowEdit()">
      </nh-table>
    </div>
  </div>
  <br>
  <br>

  <div>
    <h5 class="section-header">Tax Information Summary</h5>
    <hr>
    <div>
      <p *ngIf="!showTaxSummary">You haven't told us about any Tax Information for your household.</p>
      <nh-table *ngIf="showTaxSummary" [columns]="taxInformationSummaryColumns" [sectionId]="'PRASM'" [rows]="taxInformationSummaryResults" [mode]="mode" (edit)="taxInformationRowEdit()"
      (delete)="taxInformationRowDelete($event)">
    </nh-table>
    </div>
  </div>
  <br>
  <br>

  <div>
    <h5 class="section-header">Earned Income Summary</h5>
    <hr>
    <div>
      <p *ngIf="earnedIncomeSummaryResults?.length == 0">You haven't told us about any Earned Income for your household.</p>
      <nh-table *ngIf="earnedIncomeSummaryResults?.length != 0" [columns]="earnedIncomeSummaryColumns" [sectionId]="'PRASM'" [rows]="earnedIncomeSummaryResults" [mode]="mode" (edit)="earnedIncomeRowEdit()"
        (delete)="earnedIncomeRowDelete($event)">
      </nh-table>  
    </div>

    <div *ngIf="earnedIncomeSummaryResults?.length != 0" class="pt-1 hover-red">
      <a class="btn btn-link btn-lg hover-red" (click)="addIncomeDetails()">
        <span class="pr-2">Add Another</span>
        <svg id="_icons_add-circle-outline" data-name="⚛️ icons/add-circle-outline" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path id="Combined_Shape" data-name="Combined Shape" d="M0,9.75A9.75,9.75,0,1,1,9.75,19.5,9.761,9.761,0,0,1,0,9.75Zm1.5,0A8.25,8.25,0,1,0,9.75,1.5,8.259,8.259,0,0,0,1.5,9.75ZM9,13.514V10.5H6A.75.75,0,0,1,5.985,9H9V6a.75.75,0,0,1,1.5-.015V9h3a.75.75,0,0,1,.014,1.5H10.5v3a.75.75,0,0,1-1.5.014Z" transform="translate(2.25 2.25)" fill="#174a7c"/>
        </svg>
      </a>
    </div>
  </div>

  <br>
  <br>

  <div>
    <h5 class="section-header" >Self-Employment Income Summary</h5>
    <hr>
    <div>
      <p *ngIf="selfEmploymentIncomeSummaryResults?.length == 0">You haven't told us about any Self-Employment Income for your household.</p>
      <nh-table *ngIf="selfEmploymentIncomeSummaryResults?.length != 0" [columns]="selfEmploymentIncomeSummaryColumns" [sectionId]="'PRASM'" [rows]="selfEmploymentIncomeSummaryResults" [mode]="mode" (edit)="selfEmploymentRowEdit()"
      (delete)="selfEmploymentRowDelete($event)">
    </nh-table>
    </div>

    <div *ngIf="selfEmploymentIncomeSummaryResults?.length != 0" class="pt-1 hover-red">
      <a class="btn btn-link btn-lg hover-red" (click)="addIncomeDetails()">
        <span class="pr-2">Add Another</span>
        <svg id="_icons_add-circle-outline" data-name="⚛️ icons/add-circle-outline" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path id="Combined_Shape" data-name="Combined Shape" d="M0,9.75A9.75,9.75,0,1,1,9.75,19.5,9.761,9.761,0,0,1,0,9.75Zm1.5,0A8.25,8.25,0,1,0,9.75,1.5,8.259,8.259,0,0,0,1.5,9.75ZM9,13.514V10.5H6A.75.75,0,0,1,5.985,9H9V6a.75.75,0,0,1,1.5-.015V9h3a.75.75,0,0,1,.014,1.5H10.5v3a.75.75,0,0,1-1.5.014Z" transform="translate(2.25 2.25)" fill="#174a7c"/>
        </svg>
      </a>
    </div>
  </div>

  <br>
  <br>

  <div>
    <h5 class="section-header">Other Income Summary</h5>
    <hr>
    <div >
      <p *ngIf="otherIncomeSummaryResults?.length == 0">You haven't told us about any Other Income for your household.</p>
      <nh-table *ngIf="otherIncomeSummaryResults?.length != 0" [columns]="otherIncomeSummaryColumns" [sectionId]="'PRASM'" [rows]="otherIncomeSummaryResults" [mode]="mode" (edit)="otherIncomeRowEdit()"
      (delete)="otherIncomeRowDelete($event)">
    </nh-table>
    </div>


    <div *ngIf="otherIncomeSummaryResults?.length != 0" class="pt-1 hover-red">
      <a class="btn btn-link btn-lg hover-red" (click)="addIncomeDetails()">
        <span class="pr-2">Add Another</span>
        <svg id="_icons_add-circle-outline" data-name="⚛️ icons/add-circle-outline" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path id="Combined_Shape" data-name="Combined Shape" d="M0,9.75A9.75,9.75,0,1,1,9.75,19.5,9.761,9.761,0,0,1,0,9.75Zm1.5,0A8.25,8.25,0,1,0,9.75,1.5,8.259,8.259,0,0,0,1.5,9.75ZM9,13.514V10.5H6A.75.75,0,0,1,5.985,9H9V6a.75.75,0,0,1,1.5-.015V9h3a.75.75,0,0,1,.014,1.5H10.5v3a.75.75,0,0,1-1.5.014Z" transform="translate(2.25 2.25)" fill="#174a7c"/>
        </svg>
      </a>
    </div>
  </div>

  <br>
  <br>

  <div>
    <h5 class="section-header">Tax Deductions Summary</h5>
    <hr>
    <div >
      <p *ngIf="taxDeductionsSummaryResults?.length == 0">You haven't told us about any Deductions for your household.</p>
      <nh-table *ngIf="taxDeductionsSummaryResults?.length != 0" [columns]="taxDeductionsSummaryColumns" [sectionId]="'PRASM'" [rows]="taxDeductionsSummaryResults" [mode]="mode" (edit)="taxDeductionsRowEdit()"
      (delete)="taxDeductionsRowDelete($event)">
    </nh-table>
    </div>

    <div *ngIf="taxDeductionsSummaryResults?.length != 0" class="pt-1 hover-red">
      <a class="btn btn-link btn-lg hover-red" (click)="addIncomeDetails()">
        <span class="pr-2">Add Another</span>
        <svg id="_icons_add-circle-outline" data-name="⚛️ icons/add-circle-outline" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path id="Combined_Shape" data-name="Combined Shape" d="M0,9.75A9.75,9.75,0,1,1,9.75,19.5,9.761,9.761,0,0,1,0,9.75Zm1.5,0A8.25,8.25,0,1,0,9.75,1.5,8.259,8.259,0,0,0,1.5,9.75ZM9,13.514V10.5H6A.75.75,0,0,1,5.985,9H9V6a.75.75,0,0,1,1.5-.015V9h3a.75.75,0,0,1,.014,1.5H10.5v3a.75.75,0,0,1-1.5.014Z" transform="translate(2.25 2.25)" fill="#174a7c"/>
        </svg>
      </a>
    </div>
  </div>

  <br>
  <br>

  <div *ngIf="otherQuestionsSummaryResults?.length > 0">
    <h5 class="section-header" >Other Questions Summary</h5>
    <hr>
    <div >
      <nh-table [columns]="otherQuestionsSummaryColumns" [sectionId]="'PRASM'"  [rows]="otherQuestionsSummaryResults" [mode]="mode" (edit)="otherQuestionRowEdit()"
      (delete)="otherQuestionRowDelete($event)">
    </nh-table>
    </div>

    <div *ngIf="otherQuestionsSummaryResults?.length > 1" class="pt-1 hover-red">
      <a class="btn btn-link btn-lg hover-red" (click)="addOtherQuestionDetails()">
        <span class="pr-2">Add Another</span>
        <svg id="_icons_add-circle-outline" data-name="⚛️ icons/add-circle-outline" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path id="Combined_Shape" data-name="Combined Shape" d="M0,9.75A9.75,9.75,0,1,1,9.75,19.5,9.761,9.761,0,0,1,0,9.75Zm1.5,0A8.25,8.25,0,1,0,9.75,1.5,8.259,8.259,0,0,0,1.5,9.75ZM9,13.514V10.5H6A.75.75,0,0,1,5.985,9H9V6a.75.75,0,0,1,1.5-.015V9h3a.75.75,0,0,1,.014,1.5H10.5v3a.75.75,0,0,1-1.5.014Z" transform="translate(2.25 2.25)" fill="#174a7c"/>
        </svg>
      </a>
    </div>
  </div>

  <div class="py-3">
    <nh-checkbox name="applicationSignedBySw" [control]="getControl('data.applicationSignedBySw')" checkedValue="PARTNER_PORTAL" [mandatory]="true"
      label="Yes, I have reviewed all the above information with the applicant and confirm it is correct based on what I know." class="label-section">
      <span *ngIf="getControl('data.applicationSignedBySw').touched && getControl('data.applicationSignedBySw').value === '' || getControl('data.applicationSignedBySw').value === null" class="form-text invalid-tooltip d-block">Please answer the required field.</span>
    </nh-checkbox>
  </div>
</nh-form>
  