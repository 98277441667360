<div class="container logout">
  <div class="bg-custom">
    <div class="container manage-benefits">
      <div>
        <div class="mt-3">
          <div class="container col-xs-12 .col-12 form-row">
            <div class="col-md-12 col-sm-12">
              <div class="py-4">
                <p>{{'INVALIDSESSION_STATIC1' | translate}}</p>
              </div>
            </div>
         </div>
        </div>
      </div>
    </div>
  </div>
</div>
