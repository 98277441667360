export const faqData = [{
    header: 'FREQUENTLY_ASKED_QUESTIONS',
    questions: [
        {
            key: 'FAQ_KEY_1',
            value: 'FAQ_VALUE_1',
            show: false
        },
        {
            key: 'FAQ_KEY_2',
            value: 'FAQ_VALUE_2',
            show: false
        },
        {
            key: 'FAQ_KEY_3',
            value: 'FAQ_VALUE_3',
            show: false
        },
        {
            key: 'FAQ_KEY_4',
            value: 'FAQ_VALUE_4',
            show: false
        },
        {
            key: 'FAQ_KEY_5',
            value: 'FAQ_VALUE_5',
            show: false
        },
        {
            key: 'FAQ_KEY_6',
            value: 'FAQ_VALUE_6',
            show: false
        },
        {
            key: 'FAQ_KEY_7',
            value: 'FAQ_VALUE_7',
            show: false
        },
        {
            key: 'FAQ_KEY_8',
            value: 'FAQ_VALUE_8',
            show: false
        },
        {
            key: 'FAQ_KEY_9',
            value: 'FAQ_VALUE_9',
            show: false
        },
        {
            key: 'FAQ_KEY_10',
            value: 'FAQ_VALUE_10',
            show: false
        },
        {
            key: 'FAQ_KEY_11',
            value: 'FAQ_VALUE_11',
            show: false
        },
        {
            key: 'FAQ_KEY_12',
            value: 'FAQ_VALUE_12',
            show: false
        },
        {
            key: 'FAQ_KEY_13',
            value: 'FAQ_VALUE_13',
            show: false
        },
        {
            key: 'FAQ_KEY_14',
            value: 'FAQ_VALUE_14',
            show: false
        },
        {
            key: 'FAQ_KEY_15',
            value: 'FAQ_VALUE_15',
            show: false
        },
        {
            key: 'FAQ_KEY_16',
            value: 'FAQ_VALUE_16',
            show: false
        },
        {
            key: 'FAQ_KEY_17',
            value: 'FAQ_VALUE_17',
            show: false
        },
        {
            key: 'FAQ_KEY_18',
            value: 'FAQ_VALUE_18',
            show: false
        },
        {
            key: 'FAQ_KEY_19',
            value: 'FAQ_VALUE_19',
            show: false
        },
        {
            key: 'FAQ_KEY_20',
            value: 'FAQ_VALUE_20',
            show: false
        },
        {
            key: 'FAQ_KEY_21',
            value: 'FAQ_VALUE_21',
            show: false
        },
        {
            key: 'FAQ_KEY_22',
            value: 'FAQ_VALUE_22',
            show: false
        },
        {
            key: 'FAQ_KEY_23',
            value: 'FAQ_VALUE_23',
            show: false
        },
        {
            key: 'FAQ_KEY_24',
            value: 'FAQ_VALUE_24',
            show: false
        },
        {
            key: 'FAQ_KEY_25',
            value: 'FAQ_VALUE_25',
            show: false
        },
        {
            key: 'FAQ_KEY_26',
            value: 'FAQ_VALUE_26',
            show: false
        },
        {
            key: 'FAQ_KEY_27',
            value: 'FAQ_VALUE_27',
            show: false
        },
        {
            key: 'FAQ_KEY_28',
            value: 'FAQ_VALUE_28',
            show: false
        },
        {
            key: 'FAQ_KEY_29',
            value: 'FAQ_VALUE_29',
            show: false
        },
        {
            key: 'FAQ_KEY_30',
            value: 'FAQ_VALUE_30',
            show: false
        },
        {
            key: 'FAQ_KEY_31',
            value: 'FAQ_VALUE_31',
            show: false
        },
        {
            key: 'FAQ_KEY_32',
            value: 'FAQ_VALUE_32',
            show: false
        }
    ]
}];



export const faqRPData = [{
header: 'GENERAL_QUESTIONS',
questions:[
    {
        key: 'FAQ_KEY_RP_1',
        value: 'FAQ_VALUE_RP_1',
        show: false
    },
    {
        key: 'FAQ_KEY_RP_2',
        value: 'FAQ_VALUE_RP_2',
        show: false
    },
    {
        key: 'FAQ_KEY_RP_3',
        value: 'FAQ_VALUE_RP_3',
        show: false
    },
    {
        key: 'FAQ_KEY_RP_4',
        value: 'FAQ_VALUE_RP_4',
        show: false
    },
    {
        key: 'FAQ_KEY_RP_5',
        value: 'FAQ_VALUE_RP_5',
        show: false
    },
    {
        key: 'FAQ_KEY_RP_6',
        value: 'FAQ_VALUE_RP_6',
        show: false
    },
    {
        key: 'FAQ_KEY_RP_7',
        value: 'FAQ_VALUE_RP_7',
        show: false
    },

    {
        key: 'FAQ_KEY_RP_8',
        value: 'FAQ_VALUE_RP_8',
        show: false
    },
    {
        key: 'FAQ_KEY_RP_9',
        value: 'FAQ_VALUE_RP_9',
        show: false
    },
    {
        key: 'FAQ_KEY_RP_10',
        value: 'FAQ_VALUE_RP_10',
        show: false
    },
    {
        key: 'FAQ_KEY_RP_11',
        value: 'FAQ_VALUE_RP_11',
        show: false
    },
]},
{
    header: 'ACCESS_REQUEST_QUESTIONS',
    questions:[
    {
        key: 'FAQ_KEY_RP_12',
        value: 'FAQ_VALUE_RP_12',
        show: false
    },
    {
        key: 'FAQ_KEY_RP_13',
        value: 'FAQ_VALUE_RP_13',
        show: false
    },
    {
        key: 'FAQ_KEY_RP_14',
        value: 'FAQ_VALUE_RP_14',
        show: false
    },
    {
        key: 'FAQ_KEY_RP_15',
        value: 'FAQ_VALUE_RP_15',
        show: false
    },
]},
{
    header: 'PROFILE_QUESTIONS',
    questions:[
        {
            key: 'FAQ_KEY_RP_16',
            value: 'FAQ_VALUE_RP_16',
            show: false
        },
        {
            key: 'FAQ_KEY_RP_17',
            value: 'FAQ_VALUE_RP_17',
            show: false
        },
        {
            key: 'FAQ_KEY_RP_18',
            value: 'FAQ_VALUE_RP_18',
            show: false
        },
        {
            key: 'FAQ_KEY_RP_19',
            value: 'FAQ_VALUE_RP_19',
            show: false
        },
        {
            key: 'FAQ_KEY_RP_20',
            value: 'FAQ_VALUE_RP_20',
            show: false
        },
        {
            key: 'FAQ_KEY_RP_21',
            value: 'FAQ_VALUE_RP_21',
            show: false
        },
        {
            key: 'FAQ_KEY_RP_22',
            value: 'FAQ_VALUE_RP_22',
            show: false
        },
]},
{
    header: 'ONETIME_PASSCODE_QUESTIONS',
    questions:[
        {
            key: 'FAQ_KEY_RP_23',
            value: 'FAQ_VALUE_RP_23',
            show: false
        },
        {
            key: 'FAQ_KEY_RP_24',
            value: 'FAQ_VALUE_RP_24',
            show: false
        },
        {
            key: 'FAQ_KEY_RP_25',
            value: 'FAQ_VALUE_RP_25',
            show: false
        },
        {
            key: 'FAQ_KEY_RP_26',
            value: 'FAQ_VALUE_RP_26',
            show: false
        },
        {
            key: 'FAQ_KEY_RP_27',
            value: 'FAQ_VALUE_RP_27',
            show: false
        },
        {
            key: 'FAQ_KEY_RP_28',
            value: 'FAQ_VALUE_RP_28',
            show: false
        }
]},
];