import { Component, Input, Output, ViewEncapsulation, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { equals, has, prop } from 'ramda';

import { ApplicationNumber, Statuses } from '@app/models/app.model';
import { Links, Link } from '@app/models/links.model';

@Component({
  selector: 'nh-tabset',
  templateUrl: './tabset.component.html',
  styleUrls: ['./tabset.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsetComponent {
  @Input() applicationNumber: ApplicationNumber;
  @Input() tabs$: Observable<Links>;
  @Input() type: string;
  @Input() blockId: string;
  @Output() navigate = new EventEmitter<Link>();
  appType = sessionStorage.getItem('appType');
  isTaxComponent = false;
  isRMBTaxComponent = false;
  

  constructor(private router: Router) {}

 getTabTitle(tab: any): string {
   if (!this.appType || this.appType === 'AFB' || this.appType === 'RB') {
     const taxComponentTitles = ['Tax Filing', 'Tax Deductions', 'Tax Dependents Outside Household', 'Joint Filer Outside Household', 'Tax Filer Outside Household']
     if (taxComponentTitles.includes(tab.title)) {
       this.isTaxComponent = true;
     } else {
       this.isTaxComponent = false;
     }
   }
  return tab.title = this.appType === 'RB' && tab.title === 'Demographics' ? 'People in Your Home' : tab.title;
  }

  onNavigate(tab) {
    return !this.isActive(tab) && this.isValid(tab) && this.navigate.emit(tab);
  }

  isStatus(status, STATUS) {
    return equals(status, prop(STATUS, Statuses));
  }

  isBlock(tab) {
    return has('blockId', tab);
  }

  isActive(tab) {
    return this.isBlock(tab) ? this.isBlockActive(tab) : this.isSectionActive(tab);
  }

  isValid(tab) {
    return this.isBlock(tab) ? this.isBlockValid(tab) : this.isSectionValid(tab);
  }

  isSectionActive(tab) {
    return this.router.isActive(`/application/${this.applicationNumber}/section/${tab.sectionId}`, false);
  }

  isBlockActive(tab) {
    return equals(tab.blockId, this.blockId);
  }

  isSectionValid(tab) {
    return !this.isStatus(tab.status, 'NOT_STARTED');
  }

  isBlockValid(tab) {
    return this.isStatus(tab.status, 'COMPLETED');
  }
}
