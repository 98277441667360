<div class="nameAddress">

  <div [formGroup]="personalName" *ngIf="personalName">
    <div class="form-row">
      <div class="col-sm-8 col-lg-3 col-md-3">
        <nh-field id ="{{addressname+'_firstName'}}" [isRow]="true" [mandatory]="nameMandatory" label="{{'FIRST_NAME' | translate}}">
          <nh-popover *ngIf="isDisplayPopUp()" class="pl-1" content="{{'NAME_POPUP' | translate}}"></nh-popover>
          <input type="text" aria-label='firstName' name="firstName" formControlName="firstName" class="form-control" [required]="nameMandatory" [attr.disabled]="((isRmbRmc && namePopup) || isExported)?true:null" maxlength="45">
        </nh-field>
      </div>

        <div class="col-sm-4 col-lg-4 col-md-4">
          <nh-field id ="{{addressname+'_middleInitial'}}"  [isRow]="true" [mandatory]="false" label="{{'MIDDLE_NAME' | translate}}">
            <nh-popover *ngIf="isDisplayPopUp()" class="pl-1" content="{{'NAME_POPUP' | translate}}"></nh-popover>
            <input aria-label='middleInitial' type="text" name="middleInitial" formControlName="middleInitial" class="form-control" mask="S" [attr.disabled]="((isRmbRmc && namePopup) || isExported)?true:null">
          </nh-field>
        </div>

      <div class="col-sm-8 col-lg-3 col-md-3">
        <nh-field id ="{{addressname+'_lastName'}}"  [isRow]="true" [mandatory]="nameMandatory" label="{{'LAST_NAME' | translate}}">
          <nh-popover *ngIf="isDisplayPopUp()" class="pl-1" content="{{'NAME_POPUP' | translate}}"></nh-popover>
          <input aria-label='lastName' type="text" name="lastName" formControlName="lastName" class="form-control" [required]="nameMandatory" [attr.disabled]="((isRmbRmc && namePopup) || isExported)?true:null" maxlength="45">
        </nh-field>
      </div>

      <div class="col-sm-4 col-lg-2 col-md-2">
        <nh-field id ="{{addressname+'_suffix'}}"  [isRow]="true" [mandatory]="false" label="{{'SUFFIX' | translate}}">
          <nh-popover *ngIf="isDisplayPopUp()" class="pl-1" content="{{'NAME_POPUP' | translate}}"></nh-popover>
          <select aria-label='suffix' nh name="suffix" [items]="tableSuffix" formControlName="suffix" [attr.disabled]="((isRmbRmc && namePopup) || isExported)?true:null"></select>
        </nh-field>
      </div>
    </div>
  </div>

  <div [formGroup]="address" *ngIf="address">
    <div class="form-row">
      <div class="col-md-8">
        <nh-field id ="{{addressname+'_addressLine1'}}"  [isRow]="true" [mandatory]="addressFieldMandatory" label="{{'ADDRESS_LINE_1' | translate}}">
          <nh-popover class="pl-1" content="{{'ADDRESS_LINE1_POPUP' | translate}}"></nh-popover>
          <input type="text" name="addressLine1" formControlName="addressLine1" class="form-control" maxlength={{maxLengthAddressLine1}} placeholder="105 Johnson Street" [attr.disabled]="isDisabled ? true : null">
        </nh-field>
      </div>

      <div class="col-md-8">
        <nh-field id ="{{addressname+'_addressLine2'}}"  [isRow]="true" [mandatory]="false" label="{{'ADDRESS_LINE_2' | translate}}">
          <nh-popover class="pl-1" content="{{'ADDRESS_LINE2_POPUP' | translate}}"></nh-popover>
          <input type="text" class="form-control" name="addressLine2" formControlName="addressLine2" maxlength={{maxLengthAddressLine2}} placeholder="Apt 305" [attr.disabled]="isDisabled ? true : null">
        </nh-field>
      </div>
      <div class="col-md-12">
        <nh-city-state [addressname] = "addressname" [isAssistingPerson]="isAssistingPerson" [stateDefault]="stateDefault" [format]="addressFormat" [addressFieldMandatory]="addressFieldMandatory" [address]="address" [tables]="tables" [isDisabled]="isDisabled" [hideCounty]="hideCounty"></nh-city-state>
      </div>
  </div>
</div>
