<nh-form [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <section [formGroup]="getControl('data')">
    <div class="container start-page">
      <div>
        <h5 class="section-header">Medical Expense Information</h5>
        <hr>
          <nh-field id = "medicalExpenseType" label="Incurred Medical Expense Type:" class="label-section" [mandatory]="true">
            <select nh name="medicalExpenseType" aria-label="medicalExpenseType" (change)="onMedicalExpenseChange()" [items]="tables.MEDICALEXPENSETYPE"
              formControlName="medicalExpenseType"></select>
          </nh-field>

          <nh-field id = "payFrequency" label="Frequency:" class="label-section" [mandatory]="true">
            <select nh name="payFrequency" aria-label="payFrequency" (change)="onPayFrequencyChange()" [items]="tables.INCEXPPAYFREQUENCY"
              formControlName="payFrequency"></select>
          </nh-field>

          <nh-field id="dateOfService" label="Date of Service:" [mandatory]="true" class="label-section">
            <nh-datepicker>
              <input type="text" name="dateOfService" aria-label="dateOfService" class="form-control"
                formControlName="dateOfService" bsDatepicker nh  [minDate]="minimumDate" maxLength="10">
            </nh-datepicker>
          </nh-field>
      </div>
      <div>
        <table>
          <tr>
            <th><span class="text-danger ng-star-inserted">*</span></th>
            <th><h5 class="section-header">Upload a Medical Expense Document</h5></th>
          </tr>
        </table>
          <hr>
          <div class="row uploaded-documentname" *ngIf="displayLoading && !checkDocumentUploadError()">
            <div class="col-8">
              <span class="pr-2 file-icon"><img alt="uploadedDoc"
                  src="../../../../../../assets/images/⚛️ icons-document-attach-outline.svg"></span>
              <span class="file-name" style="max-width:440px">{{docName}}</span>
            </div>
            <div class="col-4">
              <div class="option-lables">
                <img src="../../../../../../assets/images/⚛️ File_Uploader_Loader.svg">
              </div>
            </div>
          </div>
          <div *ngFor="let doc of documents; index as i">
            <div class="row">
              <div class="col-md-12">
                <div *ngIf="doc.error" class="error-messages position-relative">
                  <span class="form-text invalid-tooltip d-block">
                    <ng-container>{{'REQUIRED' | translate }}</ng-container>
                  </span>
                </div>
              </div>
              <div *ngIf="!isMobileView && !doc.file && i !== 0 && doc.show" class="col-md-2 offset-md-4">
                <div class="row pl-1 pt-1 hover-red upload-btn-close">
                  <a (click)="close(i)"><span class="pr-2">{{'CLOSE' | translate }}</span><i class="fa fa-times-circle"></i></a>
                </div>
              </div>
            </div>
            <div class="row pt-2" *ngIf="doc.show">
              <div class="col-md-12">
                <nh-file-upload (passFileData)="getFileDetails($event, i)" (fileUploadStarted)="getFileProgress($event)">
                </nh-file-upload>
              </div>
            </div>
            <div class="d-flex align-items-center p-3" *ngIf="doc.file?.name">
              <div class="row uploaded-documentname">
                <div class="pr-2 file-icon"><img alt="uploadedDoc" src="../../../../../../assets/images/⚛️ icons-document-attach-outline.svg">
                </div>
                <div class="file-name" style="max-width:440px">
                  <span>{{doc.file.name}}</span>
                </div>
                <div class="hover-red option-lables">
                  <a class="btn btn-link hover-red" (click)="viewPdf(i)">
                    <span class="pr-2">{{'View' | translate }}</span>
                    <svg id="_icons_search-outline" data-name="⚛️ icons/search-outline" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                      <defs>
                        <clipPath id="clip-path">
                          <rect id="Background" width="24" height="24" fill="#174a7c" />
                        </clipPath>
                      </defs>
                      <g id="Mask_Group_1" data-name="Mask Group 1" clip-path="url(#clip-path)">
                        <path id="Combined_Shape" data-name="Combined Shape"
                          d="M18.232,19.292l-.013-.012-4.927-4.927a8.122,8.122,0,1,1,1.061-1.061l4.927,4.927a.75.75,0,0,1-1.049,1.073ZM1.5,8.114A6.614,6.614,0,1,0,8.114,1.5,6.621,6.621,0,0,0,1.5,8.114Z"
                          transform="translate(2.25 2.25)" fill="#174a7c" />
                      </g>
                    </svg>
                  </a>
        
                  <a class="btn btn-link hover-red" (click)="removeItem(i)">
                    <span class="pr-2">{{'REMOVE' | translate }}</span>
                    <nh-remove-svg></nh-remove-svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          
  <div>
    <div class="row mt-3 col-md-12">
      <div class="ml-2">
        <button id="addAnother" (click)="addAnother()" class="btn btn-block btn-outline-secondary btn-add-another"
          [hidden]="!showAddAnother">{{'ADD_1' | translate }}
          <svg id="_icons_add-circle-outline" data-name="⚛️ icons/add-circle-outline" xmlns="http://www.w3.org/2000/svg"
            width="24" height="24" viewBox="0 0 24 24">
            <path id="Combined_Shape" data-name="Combined Shape"
              d="M0,9.75A9.75,9.75,0,1,1,9.75,19.5,9.761,9.761,0,0,1,0,9.75Zm1.5,0A8.25,8.25,0,1,0,9.75,1.5,8.259,8.259,0,0,0,1.5,9.75ZM9,13.514V10.5H6A.75.75,0,0,1,5.985,9H9V6a.75.75,0,0,1,1.5-.015V9h3a.75.75,0,0,1,.014,1.5H10.5v3a.75.75,0,0,1-1.5.014Z"
              transform="translate(2.25 2.25)" fill="#174a7c" />
          </svg></button>
      </div>
    </div>
  </div>
      </div>
    </div>
  </section>
</nh-form>