import { Component, AfterContentInit, ChangeDetectionStrategy, AfterContentChecked, LOCALE_ID, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { swapControl, swapControls } from '@app/helpers/forms.helpers';
import { isYes } from '@app/helpers/tayn.helpers';
import { afterSecondMonth, toDate, minus3Months1stDay } from '@app/helpers/date.helpers';
import { isExported } from '@app/helpers/mode.helpers';
import { Individual } from '@app/models/individual.model';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
import { formatTableData } from '@app/helpers/tables.helpers';
import { isRmbRmc } from '@app/helpers/mode.helpers';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './employment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmploymentComponent extends SectionFormDirective implements AfterContentInit, AfterContentChecked {
  today = new Date();
  maximumDate = afterSecondMonth();
  minimumloseJobEndDate = minus3Months1stDay();
  topLevelQuestionNonPregOrChild;
  topLevelQuestionNonPregOrChildEn = `Does anyone in your household have a job now or will start a new job this month? <div class=hint>If self-employed, we'll ask about that later.</div>`;
  topLevelQuestionNonPregOrChildSp = `¿Alguien de su hogar tiene un nuevo trabajo o comenzará un nuevo trabajo este mes? <div class=hint>Si trabaja por su cuenta, le preguntaremos sobre eso más tarde.</div>`
  topLevelQuestionPregOrChild;
  topLevelQuestionPregOrChildEn = `Has anyone in your household had a job in the last 3 months, or have a job now or will anyone start a new job this month? If this person is self-employed or is only paid with goods or services instead of money, answer no. We’ll ask about this later.`;
  topLevelQuestionPregOrChildSp = "¿Alguien en su hogar ha tenido un trabajo en los últimos 3 meses, o tiene un trabajo ahora o alguien comenzará un nuevo trabajo este mes? Si esta persona trabaja por cuenta propia o solo se le paga con bienes o servicios en lugar de dinero, responda que no. Preguntaremos sobre esto más tarde.";
  conditionalTopLevelQuestion: string;
  totalWagesNew = 'For the current month, what were your total wages?';
  totalHoursNew = 'For the current month, what were your total hours worked?';
  totalWages = 'For your final month, what were your total wages?';
  totalHours = 'For your final month, what were your total hours worked?';
  payCheckText;
  payCheckTextEn = 'If you tell us how much each pay check, use the amount before taxes.';
  payCheckTextSp = 'Si nos dice cuánto paga cada cheque, use el monto antes de impuestos. '
  exported = false;
  userTriedToDeleteExistingJob = false;
  startDateOption: any;
  individualDOB = new Date();
  minDate;
  individualQuestion;
  individualQuestionEn = "Who has the job? <div class=hint>If more than one person in your home has a job , you can add another person (and their job) after you complete this page and click <b>Next</b>.</div>";
  individualQuestionSp = "¿Quién tiene el trabajo? <div class=hint>Si más de una persona del hogar tiene un empleo, puede agregar a otra persona (y su empleo) después de completar esta página y hacer clic en <b>Siguiente</b>.</div> ";
  translatedLang;
  defaultLanguage;
  appKeyWord;
  isRmbRmc = false;
  isAddAnother = false;

  constructor(private titleService: Title, builder: FormBuilder, public translateService: TranslateService, public datePipe: DatePipe, 
    @Inject(LOCALE_ID) public locale : string) {
    super(builder);
    this.titleService.setTitle('Employment');
  }

  ngAfterContentInit() {
    if (sessionStorage.getItem("labelForAddAnother") == "false") {
      this.isAddAnother = true;
      sessionStorage.removeItem("labelForAddAnother");
    } else {
      this.isAddAnother = false;
    }
    
    this.appKeyWord = sessionStorage.getItem("appType");
    this.isRmbRmc = isRmbRmc(this.mode);

    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);
    if (this.defaultLanguage !== 'en') {
      this.topLevelQuestionPregOrChild = this.topLevelQuestionPregOrChildSp
      this.topLevelQuestionNonPregOrChild = this.topLevelQuestionNonPregOrChildSp
      this.individualQuestion = this.individualQuestionSp
      this.payCheckText = this.payCheckTextSp
    } else {
      this.topLevelQuestionPregOrChild = this.topLevelQuestionPregOrChildEn
      this.topLevelQuestionNonPregOrChild = this.topLevelQuestionNonPregOrChildEn
      this.individualQuestion = this.individualQuestionEn
      this.payCheckText = this.payCheckTextEn
    }

    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.languageCheck(res.lang);
      if (res.lang === 'en') {
        this.topLevelQuestionPregOrChild = this.topLevelQuestionPregOrChildEn
        this.topLevelQuestionNonPregOrChild = this.topLevelQuestionNonPregOrChildEn
        this.individualQuestion = this.individualQuestionEn
        this.payCheckText = this.payCheckTextEn
        this.setGatePostQuestion();
      } else {
        this.topLevelQuestionPregOrChild = this.topLevelQuestionPregOrChildSp
        this.topLevelQuestionNonPregOrChild = this.topLevelQuestionNonPregOrChildSp
        this.individualQuestion = this.individualQuestionSp
        this.payCheckText = this.payCheckTextSp
        this.setGatePostQuestion();
      }
    });
    const loseJobSw = this.getControl('data.loseJobSw');
    const loseJobEndDate = this.getControl('data.loseJobEndDate');
    this.setGatePostQuestion();
    let valueChanged = false;
    this.getControl('extras.individual').valueChanges.subscribe((individual: Individual) => {
      this.individualDOB = toDate(individual.birthDate);
      this.startDateOption = {
        name: individual.name.fullName,
        errorMsg: {
          INVALID_START_DATE: true
        }
      };
      const appType = sessionStorage.getItem('appType');
      let isChildOrPregnantAndMovedIn = false;
      if (individual.age < 21 || (individual.pregnant && individual.gender['code'] === 'F')) {
        if (individual.someoneMovedIn === 'Y') {
          isChildOrPregnantAndMovedIn = true;
        }
      }
      this.loseJobControl(isChildOrPregnantAndMovedIn, appType);
      valueChanged = true;
    })
    if(!valueChanged) {
      const appType = sessionStorage.getItem('appType');
      let isChildOrPregnantAndMovedIn = false;
      if (this.selectedIndividual?.age < 21 || (this.selectedIndividual?.pregnant && this.selectedIndividual?.gender['code'] === 'F')) {
        if (this.selectedIndividual.someoneMovedIn === 'Y') {
          isChildOrPregnantAndMovedIn = true;
        }
      }
      this.loseJobControl(isChildOrPregnantAndMovedIn, appType);
    }
    this.exported = isExported(this.record.exportingStatus);
    const addAddress = this.getControl('extras.helpers.addAddress');
    const employmentLost = this.getControl('data.employmentLost');
    const address = this.getControl('data.employer.address');
    if (this.mode !== 'NEW' && this.record['userTriedToDeleteExistingJob']) {
      this.userTriedToDeleteExistingJob = true;
      Object.keys(employmentLost['controls']).forEach(key => {
        employmentLost['controls'][key].enable();
      });
    }

    // tslint:disable-next-line: no-unused-expression
    this.record.data && this.getControl('extras.helpers.paymentOption')
      .setValue(this.tables.MPPAYFREQ.find((item) => {
        return item.code === (this.getValue('data.wage.paymentPeriod') ? 'P' : 'H');
      }));
    this.paymentOptionChange();

    this.registerToggle(addAddress, swapControl(address, isYes));
    this.registerToggle(
      loseJobSw,
      swapControls([loseJobEndDate], isYes)
    );
    const assistingAddressState = this.getControl('data.employer.address.state');
    if (assistingAddressState.value) {
      const tableVar = this.tables.STATE.filter((item) => item.code === assistingAddressState.value.code);
      tableVar[0].value = assistingAddressState.value.value;
      assistingAddressState.patchValue(tableVar[0]);

    }

  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  loseJobControl(isChildOrPregnantAndMovedIn, appType) {
    if (isChildOrPregnantAndMovedIn && (appType === 'RC' || appType === 'RB')) {
      this.getControl('data.loseJobSw').enable();
    } else if (isChildOrPregnantAndMovedIn === false && (appType === 'RC' || appType === 'RB')) {
      this.getControl('data.loseJobSw').disable();
      this.getControl('data.loseJobEndDate').disable();
    }
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  tableCodeValue(tableName, tableControl) {
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }

  setGatePostQuestion() {
    let isChildOrPregnant = false;
    let isChildOrPregnantAndMovedIn = false;
    const appType = sessionStorage.getItem('appType');
    this.individuals.forEach(individual => {
      if (individual.age < 21 || (individual.pregnant && individual.gender['code'] === 'F')) {
        isChildOrPregnant = true;
        if (individual.someoneMovedIn === 'Y') {
          isChildOrPregnantAndMovedIn = true;
        }
      }
    });
    if (isChildOrPregnant && appType === 'AFB') {
      this.conditionalTopLevelQuestion = this.topLevelQuestionPregOrChild;
      this.getControl('data.loseJobSw').enable();
    } else {
      if (appType === 'AFB') {
        this.conditionalTopLevelQuestion = this.topLevelQuestionNonPregOrChild;
        this.getControl('data.loseJobSw').disable();
        this.getControl('data.loseJobEndDate').disable();
      }
    }
    if (isChildOrPregnantAndMovedIn && (isRmbRmc(this.mode))) {
      this.conditionalTopLevelQuestion = this.topLevelQuestionPregOrChild;
    } else if (isChildOrPregnantAndMovedIn === false && (isRmbRmc(this.mode))) {
      this.conditionalTopLevelQuestion = this.topLevelQuestionNonPregOrChild;
    }
  }

  paymentOptionChange() {
    this.diasableAllWage();
    if (this.getValue('extras.helpers.paymentOption').code === 'H') {
      this.getControl('data.wage.hoursWorkedPeriod').enable();
      this.getControl('data.wage.paymentAmtPerHr').enable();
    }
    if (this.getValue('extras.helpers.paymentOption').code === 'P') {
      this.getControl('data.wage.paymentPeriod').enable();
      this.getControl('data.wage.paymentAmountPerPayCheck').enable();
    }
  }

  ngAfterContentChecked() {
    const birthDate = this.selectedIndividual ? this.selectedIndividual.birthDate : new Date();
    const frmtDate = formatDate(birthDate, 'MM/dd/yyyy',this.locale);
    this.minDate = this.selectedIndividual ?  new Date(frmtDate) : new Date();
  }

  diasableAllWage() {
    const wage = this.getControl('data.wage');
    Object.keys(wage['controls']).forEach(key => {
      wage['controls'][key].disable();
    });
  }

  isInvalidDate(event) {
    const enteredDate = event.target.value;
    if ( !enteredDate || enteredDate === '' || enteredDate === 'Invalid date' ) {
      event.target.value = enteredDate;
    } else if (event.target.name === 'loseJobEndDate') {
      event.target.value = formatDate(this.getControl('data.loseJobEndDate').value, 'MM/dd/yyyy', 'en');
    }
  }
}
