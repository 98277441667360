import {Component, OnInit} from '@angular/core';
import { SigninService } from '@app/gateway/signin/signin.service';
import { StateService } from '@app/services/state/state.service';
import mockNews from '../mockNews';
// import { BeforeStartComponent } from './../before-start-application/before-start-application.component';
@Component({
    selector: 'nh-case-application',
    templateUrl: './case-application.component.html',
    styleUrls: ['./case-application.component.scss']
})

export class CaseApplicationComponent implements OnInit {
	constructor(private signinService: SigninService, private stateService: StateService) {}
	news = [];
	caseData;
	isApplyNow = false;
	isResume = false;

	ngOnInit() {
		const user = JSON.parse(sessionStorage.getItem('currentUser'));
		const accountType = user.accountTypeCd;
		const caseNum = user.caseNum;
		this.signinService.getCaseData(accountType, caseNum, user).subscribe((res) => {
			if (res) {
				this.caseData = res;
				// this.isApplyNow = this.caseData.actionBoxes[0].showApplyNow;
				// this.isResume = this.caseData.actionBoxes[0].showResume;
			};
		})
		this.news = [...mockNews];
	}

	continueApp = () => {
		this.stateService.continueApplication(true);
		// this.beforeStart.startApplication()
	};
}