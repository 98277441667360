import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BsModalService } from 'ngx-bootstrap/modal';

import { construct } from 'ramda';

import { IdleService } from './idle/idle.service';
import { TokenService } from './token/token.service';
import { ModalService } from './modal/modal.service';
import { StorageService } from './storage/storage.service';
import { SpinnerService } from './spinner/spinner.service';
import { UserIdleService } from './idle/user-idle.service';
import { ApplicationService } from './application/application.service';
import { NotificationsService } from './notification/notification.service';
import { HttpInterceptorService } from './interceptor/interceptor.service';
import { ProcessService } from './process.service';

import { StateService } from './state/state.service';
import { ApplicationFacade } from '@app/state/app/app.facade';
import { SectionFacade } from '@app/state/section/section.facade';
import { PeopleFacade } from '@app/state/people/people.facade';
import { AuthService } from '@app/gateway/services/auth.service';
import { ApiService } from '@app/gateway/services/api.service';
import { SigninService } from '@app/gateway/signin/signin.service';
import { GoogleAnalyticsService } from './analytics/analytics.service';
import { MetaService } from '../services/metaservice';
import { NudgeStatusService } from './fw-nudges/nudge-status.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AuthService,
    ApiService,
    IdleService,
    TokenService,
    SpinnerService,
    UserIdleService,
    ApplicationService,
    ProcessService,
    NotificationsService,
    StorageService,
    GoogleAnalyticsService,
    NudgeStatusService,
    /// STATE
    ApplicationFacade,
    SectionFacade,
    PeopleFacade,
    StateService,
    MetaService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    {
      provide: ModalService,
      useFactory: construct(ModalService),
      deps: [BsModalService],
    },
    {
      provide: IdleService,
      useFactory: construct(IdleService),
      deps: [UserIdleService, ModalService, TokenService, AuthService, ApiService, SigninService],
    },
  ],
})
export class ServicesModule {}
