import { Component, AfterViewInit, AfterContentInit } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { FormBuilder } from '@angular/forms';
import { ModalService } from '@app/services/modal/modal.service';
import { Router } from '@angular/router';
import { equals } from 'ramda'
import { ApplicationService } from '@app/services/application/application.service';
import { otherMedicalQuestion } from '../../other/other-benefits/other-benefits-labels'
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './summary-presumptive.component.html'
})

export class SummaryPresumptiveComponent extends SectionFormDirective implements AfterContentInit, AfterViewInit {
  isPresumptive = false;
  appType: string;
  isPresumptiveBccApp = false;
  showTaxSummary = true;
  showRelationships = true;
  results = [];

  individualSummaryColumns = [{ name: 'Person Name', prop: 'personName' }, { name: 'Date of Birth', prop: 'birthDate' }, { name: 'Gender', prop: 'gender' }, { name: 'U.S. Citizen', prop: 'citizen' }, { name: 'Immigration Status', prop: 'immgStatus' }, { name: 'TN Resident', prop: 'tennesseeResident' }];
  individualSummaryResults: any;

  relationshipSummaryColumns = [{ name: 'Person Name', prop: 'personName' }, { name: 'Relationship', prop: 'relationship' }, { name: 'Caretaker', prop: 'primaryCaregiver' }];
  relationshipSummaryResults: any;

  taxInformationSummaryColumns = [{ name: 'Person Name', prop: 'personName' }, { name: 'Joint Filing with Spouse', prop: 'jointFilingWithSpouse' }, { name: 'Tax Dependents <br>Inside Household', prop: 'taxDependentsInsideHousehold' }, { name: 'Tax Dependents <br>Outside Household', prop: 'taxDependentsOutsideHousehold'}];
  taxInformationSummaryResults: any;

  earnedIncomeSummaryColumns = [{ name: 'Person Name', prop: 'personName' }, { name: 'Frequency', prop: 'frequency' }, { name: 'Amount', prop: 'amount' }]
  earnedIncomeSummaryResults: any;

  selfEmploymentIncomeSummaryColumns = [{ name: 'Person Name', prop: 'personName' }, { name: 'Type', prop: 'type' }, { name: 'Monthly Amount', prop: 'monthlyNetAmount' }]
  selfEmploymentIncomeSummaryResults: any;

  otherIncomeSummaryColumns = [{ name: 'Person Name', prop: 'personName' }, { name: 'Type', prop: 'type' }, { name: 'Frequency', prop: 'frequency' }, { name: 'Amount', prop: 'amount' }]
  otherIncomeSummaryResults: any;

  taxDeductionsSummaryColumns = [{ name: 'Person Name', prop: 'personName' }, { name: 'Type', prop: 'type' }, { name: 'Monthly Amount', prop: 'deductionAmount' }]
  taxDeductionsSummaryResults: any;

  otherQuestionsSummaryColumns = [{ name: 'Person Name', prop: 'personName' }, { name: 'Assistance Type', prop: 'assistanceType' }]
  otherQuestionsSummaryResults = [];

  constructor(builder: FormBuilder,
    private modalService: ModalService,
    private router: Router,
    private datePipe: DatePipe,
    private appService: ApplicationService,
    private titleService: Title) {
    super(builder)
    this.titleService.setTitle('Application Summary');
  }

  ngAfterContentInit() { }

  ngAfterViewInit() {
    this.appType = sessionStorage.getItem('appType');
    this.isPresumptiveBccApp = this.appType === 'BCC'; 
    this.isPresumptive = this.appType === 'HPE' || this.appType === 'PRG' || this.appType === 'BCC';
    this.individualSummaryResults = this.individuals.map(({ name, gender, age, tennesseeResident, headOfHousehold, identifier }) => {
      const individualData = this.record.data.individualSummary.filter((x => x.individualId == identifier))[0];
      const immgStatus = (individualData.immigrationStatus) ? individualData.immigrationStatus.value : 'N/A';
      const birthDate = this.datePipe.transform(individualData.dob, "MM/dd/yyyy");

      return {
        personName: `${name.fullName} ${age} ${gender.code}`,
        gender: gender.value,
        birthDate: birthDate,
        citizen: individualData?.usCitizen?.value,
        tennesseeResident: tennesseeResident?.value,
        immgStatus: immgStatus,
        data: {
          headOfHousehold: headOfHousehold
        },
        identifier
      }
      window.scroll(0,0);
    });

    this.earnedIncomeSummaryResults = this.record.data.earnedIncomeSummary.map(({ individualId, frequency, grossAmount, recordId }) => {
      const personFulldetails = this.individuals.filter((x => x.identifier == individualId))[0];
      let isInteger = false;
      if (grossAmount) {
        isInteger = Number.isInteger(grossAmount)
        grossAmount = isInteger ? grossAmount.toLocaleString() + '.00' : this.decimalFormat(grossAmount.toLocaleString());
      }
      return {
        personName: `${personFulldetails?.name.fullName} ${personFulldetails?.age} ${personFulldetails?.gender.code}`,
        frequency: frequency.value,
        amount: ((grossAmount != null) ? ('$' + grossAmount)  : grossAmount ),
        individualId,
        recordId
      }
    });

    this.otherIncomeSummaryResults = this.record.data.otherIncomeSummary.map(({ individualId, type, frequency, grossAmount, recordId }) => {
      const personFulldetails = this.individuals.filter((x => x.identifier == individualId))[0];
      let isInteger = false;
      if (grossAmount) {
        isInteger = Number.isInteger(grossAmount)
        grossAmount = isInteger ? grossAmount.toLocaleString() + '.00' : this.decimalFormat(grossAmount.toLocaleString());
      }
      return {
        personName: `${personFulldetails?.name.fullName} ${personFulldetails?.age} ${personFulldetails?.gender.code}`,
        type: type.value,
        frequency: frequency.value,
        amount: ((grossAmount != null) ? ('$' +grossAmount)  : grossAmount ),
        recordId,
        individualId
      }
    });

    this.selfEmploymentIncomeSummaryResults = this.record.data.selfEmploymentSummary.map(({ individualId, type, monthlyNetAmount, recordId }) => {
      const personFulldetails = this.individuals.filter((x => x.identifier == individualId))[0];
      let isInteger = false;
      if (monthlyNetAmount) {
        isInteger = Number.isInteger(monthlyNetAmount)
        monthlyNetAmount = isInteger ? monthlyNetAmount.toLocaleString() + '.00' : this.decimalFormat(monthlyNetAmount.toLocaleString());
      }
      return {
        personName: `${personFulldetails?.name.fullName} ${personFulldetails?.age} ${personFulldetails?.gender.code}`,
        type: type.value,
        monthlyNetAmount: ((monthlyNetAmount != null) ? ('$' +monthlyNetAmount)  : monthlyNetAmount ),
        recordId,
        individualId
      }
    });

    this.taxDeductionsSummaryResults = this.record.data.taxDeductionsSummary.map(({ indvId, deductionType, deductionAmount, taxDedSeqNum }) => {
      const personFulldetails = this.individuals.filter((x => x.identifier == indvId))[0];
      let isInteger = false;
      if (deductionAmount) {
        isInteger = Number.isInteger(deductionAmount)
        deductionAmount = isInteger ? deductionAmount.toLocaleString() + '.00' : this.decimalFormat(deductionAmount.toLocaleString());
      }
      return {
        personName: `${personFulldetails?.name.fullName} ${personFulldetails?.age} ${personFulldetails?.gender.code}`,
        type: deductionType?.value,
        deductionAmount: ((deductionAmount != null) ? ('$' + deductionAmount)  : deductionAmount ),
        indvId,
        taxDedSeqNum: taxDedSeqNum
      }
    });

    this.relationshipSummaryResults = this.record.data.relationShipSummary == null ? this.showRelationships = false : this.record.data.relationShipSummary?.relationshipList?.map(({ sourceIndividual, referenceIndividual, relation, primaryCaregiver }) => {
      const sourcePersonFulldetails = this.members.filter((x => x.identifier == sourceIndividual))[0];
      const refSourcePersonFulldetails = this.members.filter((x => x.identifier == referenceIndividual))[0];
      return {
        personName: `${sourcePersonFulldetails?.name.fullName} ${sourcePersonFulldetails?.age} ${sourcePersonFulldetails?.gender.code}`,
        relationship: `${relation.value} ${refSourcePersonFulldetails?.name?.fullName}`,
        primaryCaregiver: primaryCaregiver?.value,
        recordOwner: sourceIndividual.toString(),
        data: {
          headOfHousehold: true
        },
      }
    });

    this.otherQuestionsSummaryResults = this.record.data.otherQuestionSummary?.otherBenefitsIndvProfileData?.map((item) => {
      const personFulldetails = this.individuals.filter((x => x.identifier == item.indvId))[0];
      let benefitsEnrolled = '';      
      Object.keys(item).forEach((x) => {
        if (otherMedicalQuestion[x] && item[x] && item[x].code === 'Y'){
          benefitsEnrolled = benefitsEnrolled + otherMedicalQuestion[x] + '<br/>'
        }
      })
      if (benefitsEnrolled === '') {
        return '';
      }
      return {
        personName: `${personFulldetails?.name.fullName} ${personFulldetails?.age} ${personFulldetails?.gender.code}`,
        assistanceType: benefitsEnrolled,
        identifier: item.indvId,
        data: {
          headOfHousehold: true
        },
      }
    });

    this.otherQuestionsSummaryResults = this.otherQuestionsSummaryResults.filter(Boolean)
      
    if (this.record.data.taxFilingSummary !== null) {
      this.taxInformationSummaryResults = this.record.data.taxFilingSummary?.map(({ taxFilingIndividualId, jointFilerSw, dependentOutsideHousehold, dependentIndvNumbers, jointFilerOutsideHousehold }) => {
        const personFulldetails = this.individuals.filter((x => x.identifier == taxFilingIndividualId))[0];
        let taxdependentInside = ""
        dependentIndvNumbers?.forEach((item) => {
          taxdependentInside = taxdependentInside + this.individuals.filter((x => x.identifier == item.individualId))[0]?.name.fullName + '<br/>';
        });
        const jointFiler = jointFilerSw ? jointFilerSw.value : jointFilerOutsideHousehold ? jointFilerOutsideHousehold : 'N/A';
        const dependentOutside = dependentOutsideHousehold ? dependentOutsideHousehold : 'N/A';
        if (taxdependentInside == '') {
          taxdependentInside = 'N/A'
        }
        return {
          personName: `${personFulldetails?.name.fullName} ${personFulldetails?.age} ${personFulldetails?.gender.code}`,
          jointFilingWithSpouse: jointFiler,
          taxDependentsInsideHousehold : taxdependentInside,
          taxDependentsOutsideHousehold : dependentOutside,
          recordOwner: taxFilingIndividualId
        }
      }); 
    } else {
      this.showTaxSummary = false;
    }
  }

  decimalFormat(decimalNumber) {
    if (String(decimalNumber).split(".")[1]?.length === 1) {
      return decimalNumber + '0';
    } else {
      return decimalNumber
    }
  }

  onIndividualRowEdit(event: any) {
    if (event.headOfHousehold) {
      this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/PRAPL');
    } else {
      sessionStorage.setItem('selectedIndv', event.identifier);
      this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/HHIND');
    }
  }

  onIndividualRowDelete(event: any) {
    this.modalService.confirmDelete().subscribe((res) => {
      if (res) {
        this.individualSummaryResults.forEach((item, index) => {
          if (equals(item, event.record)) {
            const remainingRows = this.individualSummaryResults.filter(row => row.identifier != event.record.identifier);
            this.appService.removeRecord(sessionStorage.getItem('appNum'), {'individualId': event.record.identifier, 'sectionId': 'HHIND'}).subscribe(() => {
              this.individualSummaryResults.splice(index, 1);
              this.individualSummaryResults = remainingRows;
            })
          }
        });
      }
    });
  }

  addIndividual() {
    sessionStorage.setItem('addPerson', 'true');
    this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/HHIND');
  }

  onRelationshipsRowEdit() {
    this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/PRRLS');
  }

  taxInformationRowEdit() {
    //Navigate to HHIND when we have only one indiv
    if(this.taxInformationSummaryResults?.length === 1){
      this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/HHIND');
    } else {
    this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/PRRLS');
    }
  }

  taxInformationRowDelete(event: any) {
    this.modalService.confirmDelete().subscribe((res) => {
      if (res) {
        this.taxInformationSummaryResults.forEach((item, index) => {
          if (equals(item, event.record)) {
            const remainingRows = this.taxInformationSummaryResults.filter(row => row.recordOwner != event.record.recordOwner);
            this.appService.removeRecordPresumptive(sessionStorage.getItem('appNum'), {'individualId': event.record.recordOwner.toString() + '-taxfiling', 'sectionId': 'PRASM'}, this.frameRequestData('taxFilingSummary', event.record.recordOwner)).subscribe(() => {
              this.taxInformationSummaryResults.splice(index, 1);
              this.taxInformationSummaryResults = remainingRows;
            })
          }
        });
      }
    });
  }

  earnedIncomeRowEdit() {
    this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/PRIND');
  }

  earnedIncomeRowDelete(event: any) {
    this.modalService.confirmDelete().subscribe((res) => {
      if (res) {
        this.earnedIncomeSummaryResults.forEach((item, index) => {
          if (equals(item, event.record)) {
            const remainingRows = this.earnedIncomeSummaryResults.filter(row => row.recordId != event.record.recordId);
            this.appService.removeRecordPresumptive(sessionStorage.getItem('appNum'), {'individualId': event.record.individualId + "-" + event.record.recordId, 'sectionId': 'PRASM'}, this.frameRequestData('earnedIncomeSummary', event.record.recordId)).subscribe(() => {
              this.earnedIncomeSummaryResults.splice(index, 1);
              this.earnedIncomeSummaryResults = remainingRows;
            })
          }
        });
      }
    });
  }

  selfEmploymentRowEdit() {
    this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/PRIND');
  }

  selfEmploymentRowDelete(event: any) {
    this.modalService.confirmDelete().subscribe((res) => {
      if (res) {
        this.selfEmploymentIncomeSummaryResults.forEach((item, index) => {
          if (equals(item, event.record)) {
            const remainingRows = this.selfEmploymentIncomeSummaryResults.filter(row => row.recordId != event.record.recordId);
            this.appService.removeRecordPresumptive(sessionStorage.getItem('appNum'), {'individualId': event.record.individualId + "-" + event.record.recordId, 'sectionId': 'PRASM'}, this.frameRequestData('selfEmploymentSummary', event.record.recordId)).subscribe(() => {
              this.selfEmploymentIncomeSummaryResults.splice(index, 1);
              this.selfEmploymentIncomeSummaryResults = remainingRows;
            })
          }
        });
      }
    });
  }

  otherIncomeRowEdit() {
    this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/PRIND');
  }

  otherIncomeRowDelete(event: any) {
    this.modalService.confirmDelete().subscribe((res) => {
      if (res) {
        this.otherIncomeSummaryResults.forEach((item, index) => {
          if (equals(item, event.record)) {
            const remainingRows = this.otherIncomeSummaryResults.filter(row => row.recordId != event.record.recordId);
            this.appService.removeRecordPresumptive(sessionStorage.getItem('appNum'),{'individualId': event.record.individualId+"-"+event.record.recordId,'sectionId': 'PRASM'}, this.frameRequestData('otherIncomeSummary', event.record.recordId)).subscribe(() => {
              this.otherIncomeSummaryResults.splice(index, 1);
              this.otherIncomeSummaryResults = remainingRows;
            })
          }
        });
      }
    });
  }

  taxDeductionsRowEdit() {
    this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/PRIND');
  }

  taxDeductionsRowDelete(event: any) {
    this.modalService.confirmDelete().subscribe((res) => {
      if (res) {
        this.taxDeductionsSummaryResults.forEach((item, index) => {
          if (equals(item, event.record)) {
            const remainingRows = this.taxDeductionsSummaryResults.filter(row => row.taxDedSeqNum != event.record.taxDedSeqNum);
            this.appService.removeRecordPresumptive(sessionStorage.getItem('appNum'), { 'individualId': event.record.indvId.toString() + "-" + event.record.taxDedSeqNum, 'sectionId': 'PRASM'}, this.frameRequestData('taxDeductionsSummary', event.record.taxDedSeqNum)).subscribe(() => {
              this.taxDeductionsSummaryResults.splice(index, 1);
              this.taxDeductionsSummaryResults = remainingRows;
            })
          }
        });
      }
    });
  }

  addIncomeDetails() {
    this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/PRIND');
  }

  otherQuestionRowEdit() {
    this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/AIBAO');
  }

  addOtherQuestionDetails() {
    this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/AIBAO');
  }

  otherQuestionRowDelete(event: any) {
    this.modalService.confirmDelete().subscribe((res) => {
      if (res) {
        this.otherQuestionsSummaryResults.forEach((item, index) => {
          if (equals(item, event.record)) {
            this.appService.removeRecordPresumptive(sessionStorage.getItem('appNum'),{'individualId': event.record.identifier+"-6",'sectionId': 'PRASM'},  this.frameRequestData('otherQuestionSummary', event.record.identifier)).subscribe(() => {
              this.otherQuestionsSummaryResults.splice(index, 1);
            })
          }
        });
      }
    }); 
  }

  frameRequestData(keyArray, recordId) {
   const tempObj = {};
   Object.keys(this.record.data).forEach((x) => {
     tempObj[x] = [null];
   })
   delete tempObj[keyArray];
   delete tempObj['relationShipSummary'];
   delete tempObj['otherQuestionSummary'];
   tempObj['relationShipSummary'] = {'relationshipList': [null]};
   if (keyArray == 'otherQuestionSummary') {
      const otherQues = this.record.data.otherQuestionSummary.otherBenefitsIndvProfileData.filter(x => x.indvId == recordId);
      tempObj[keyArray] = {'otherBenefitsIndvProfileData': otherQues}
   } else if (keyArray === 'taxFilingSummary') {
      const taxRecord = this.record.data.taxFilingSummary.filter(x => x.taxFilingIndividualId == recordId);
      tempObj[keyArray] = taxRecord;
   } else if (keyArray === 'taxDeductionsSummary') {
      const taxRecord = this.record.data.taxDeductionsSummary.filter(x => x.taxDedSeqNum == recordId);
      tempObj[keyArray] = taxRecord;
   } else {
      tempObj['otherQuestionSummary'] = {'otherBenefitsIndvProfileData': [null]}
      tempObj[keyArray] = this.record.data[keyArray].filter(x => x.recordId == recordId);
   } 

   return tempObj;
  }

}