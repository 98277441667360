<div class="cityState">
  <div class="form-row" [formGroup]="address" *ngIf="address">
    <div *ngIf="!this.isMilitary" class="col-sm-12 col-md-12">
      <nh-field id ="{{addressname+'_city'}}" [isRow]="true" label="{{'CITY' | translate}}" [mandatory]="addressFieldMandatory">
        <input type="text" name="city" aria-label="city" formControlName="city" maxlength="25" class="form-control" [attr.disabled]="isDisabled ? true : null">
      </nh-field>
    </div>

    <div *ngIf="!this.isMilitary" class="col-sm-12 col-md-4">
      <nh-field id ="{{addressname+'_state'}}" [isRow]="true" label="{{'STATE' | translate}}" [mandatory]="addressFieldMandatory">
        <select nh name="state" aria-label="state" [items]="tables.STATE" bindLabel="value" formControlName="state" (change)="onStateChange(address.value.state)" [attr.disabled]="isDisabled ? true : null"></select>
      </nh-field>
    </div>

    <div *ngIf="this.isMilitary" class="col-sm-12 col-md-4">
      <nh-field  id ="{{addressname+'_addrApoFpo'}}" [isRow]="true" label="{{'APO_FPO' | translate}}">
        <select nh name="addrApoFpo" aria-label="addrApoFpo" [items]="tables.MILITARYPOCODES" formControlName="addrApoFpo"></select>
      </nh-field>
    </div>

    <div *ngIf="this.isMilitary" class="col-sm-12 col-md-4">
      <nh-field id ="{{addressname+'_addrAaAeAp'}}" [isRow]="true" label="{{'AA_AE_AP' | translate}}">
        <select nh name="addrAaAeAp" aria-label="addrAaAeAp" [items]="tables.STATEMILITARY" formControlName="addrAaAeAp"></select>
      </nh-field>
    </div>

    <div class="col-xs-12 col-md-3">
      <nh-field id ="{{addressname+'_zip'}}" [isRow]="true" label="{{'ZIP_CODE' | translate}}" [mandatory]="addressFieldMandatory">
        <input type="text" name="zip" formControlName="zip" class="form-control" mask="00000-9999" pattern="" placeholder="XXXXX-XXXX" [attr.disabled]="isDisabled ? true : null">
      </nh-field>
    </div>
    <div *ngIf="!this.isMilitary && showCounty && !isAssistingPerson && !hideCounty" class="col-sm-12 col-md-12">
      <nh-field id ="{{addressname+'_county'}}" [isRow]="true" label="{{'COUNTY' | translate}}" [mandatory]="addressFieldMandatory">
        <select nh name="county" aria-label="county" [items]="tables.COUNTY" formControlName="county" [required]="!this.military"></select>
      </nh-field>
    </div>
  </div>
</div>
