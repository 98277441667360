import { Component, AfterContentInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { minus120Years, after10Years } from '@app/helpers/date.helpers';
import { SectionComponent } from '@app/section/section.component';

@Component({
  templateUrl: './incurredMedicalExpInformation.component.html',
  styleUrls: ["./incurredMedicalExpInformation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class IncurredMedicalExpInfo extends SectionFormDirective implements AfterContentInit {

  name;
  clientId;
  caseNumber;
  ssn;
  formattedSSN;
  dob;
  gender;
  checkboxValue;
  enablefollowupApptDt = false;
  minimumDate = minus120Years();
  maximumDate = after10Years(new Date());
  columnsA = [];
  columnsB = [];
  rows = []
  @Output() public continue = new EventEmitter<any>();
  constructor(public builder: FormBuilder, private titleService: Title , private section: SectionComponent) {
    super(builder)
    this.titleService.setTitle('Incurred Medical Expenses Information');
  };

  addIME() {
    this.section.onSave()
    console.log("next screen")
  }
 
  ngAfterContentInit() {
    if(sessionStorage.getItem('removedIMERecord')) {
      sessionStorage.removeItem('removedIMERecord')
    }
    const firstName = this.getValue('data.firstName');
    const lastName = this.getValue('data.lastName');
    if (firstName && lastName) {
      let middleName = this.getValue('data.middleName');
      middleName = middleName ? middleName : "";
      let suffix = this.getValue('data.sufxName');
      suffix = suffix ? suffix : "";
      this.name = `${firstName}  ${middleName}  ${lastName} ${suffix}`
    }
    this.clientId = this.getValue('data.indvId');
    this.caseNumber = this.getValue('data.caseNum');
    this.rows = this.getValue('data.expenseList');
    this.rows?.forEach( item => {
      item['name'] = this.name
    })
    this.columnsA = [
      { name: "Patient Incurring Expense", prop: "name" },
      { name: "Medical Expense Type", prop: "medicalExpenseType.value" },
    ];
    this.columnsB = [
      { name: "Date Bill Submitted", prop: "dateBillSubmitted"},
      { name: "Date of Service", prop: "dateOfService" },
      { name: "Allowable Amount $", prop: "allowableAmount" },
    ];
  }

}