import { equals } from 'ramda';
import { Component, Input, AfterContentInit } from '@angular/core';
import { BaseModalDirective } from '@app/components/modals/base-modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';
import { ProcessService } from '@app/services/process.service';
import {Router} from '@angular/router';

@Component({
  templateUrl: './address-validation.component.html',
  styleUrls: ['./address-validation.component.scss'],
})

export class AddressValidationComponent extends BaseModalDirective implements AfterContentInit {
  @Input() saveExit;

  obj;
  form;
  physicalAddress;
  hasPhysicalAddress = false;
  hasNursingAddress = false;
  hasDischargeAddress = false;
  mailingAddress;
  hasMalingAddress = false;
  assistingAddress;
  hasAssistingAddress = false;
  addressList;
  isPhyValid = false;
  isMailValid = false;
  isAssisValid = false;
  physicalAddressAlert = true;
  mailingAddressAlert = true;
  AssistingAddressAlert = true;
  isAppeal = false;
  isAppealRep = false;
  isAppealDemo = false;
  
  constructor(bsModalService: BsModalService, 
              protected modalRef: BsModalRef, 
              protected builder: FormBuilder,
              public processService: ProcessService,
              private router: Router) {
    super(modalRef, builder);
    this.obj = bsModalService.config.initialState;
    this.addressList = this.obj.initialState.addressList;    
  }

  physicalSelect(val) {    
    this.physicalAddressAlert = false;
    if (this.addressList[0].addressType === 'PHYSICAL') {
      if (equals(val, 'originalPhy')) {
        this.addressList[0].selectedAddress = 'Original';
      } else if (equals(val, 'suggestedPhy')) {
      this.addressList[0].selectedAddress = 'Suggested';
      }
    }
    this.isPhyValid = true;
  }

  mailingSelect(val) {
    this.mailingAddressAlert = false;
    const mailingIdx = this.addressList.findIndex(i => i.addressType === 'MAILING');
    if (equals(mailingIdx, 0) || equals(mailingIdx, 1)) {
      if (equals(val, 'originalMail')) {
        this.addressList[mailingIdx].selectedAddress = 'Original';
      } else if (equals(val, 'suggestedMail')) {
        this.addressList[mailingIdx].selectedAddress = 'Suggested';
      }
    }
    this.isMailValid = true;
  }

  assistingSelect(val) {
    this.AssistingAddressAlert = false;
    const mailingIdx = this.addressList.findIndex(i => i.addressType === 'ASSISTING');
    if (equals(mailingIdx, 1)) {
      if (equals(val, 'originalAssis')) {
        this.addressList[mailingIdx].selectedAddress = 'Original';
      } else if (equals(val, 'suggestedMail')) {
        this.addressList[mailingIdx].selectedAddress = 'Suggested';
      }
    }
    this.isAssisValid = true;
  }

  ngAfterContentInit() {
    this.isAppeal = sessionStorage.getItem('appType') === "APL";
    if (this.isAppeal) {
      if (this.router.url.includes('ARPDT')) {
        this.isAppealRep = true;
        this.isAppealDemo = false;
      } else if (this.router.url.includes('APDEM')) {
        this.isAppealRep = false;
        this.isAppealDemo = true;
      } else {
        this.isAppealRep = false;
        this.isAppealDemo = false;
      }
    }
    const physicalIndex = this.obj.initialState.addressList.findIndex(i => i.addressType === 'PHYSICAL');
    if (equals(physicalIndex, 0)) {
      this.isPhyValid = false;
      if (sessionStorage.getItem('appType') === "ADF") {
        this.hasNursingAddress = true;
        this.hasPhysicalAddress = false;
      } else {
      this.hasNursingAddress = false;
      this.hasPhysicalAddress = true;
      }
      this.physicalAddress = this.obj.initialState.addressList[0];
    } else {
      this.isPhyValid = true;
    }

    if (this.obj.initialState.addressList.length) {
      const mailingAddress = this.obj.initialState.addressList.findIndex(i => i.addressType === 'MAILING');
      if (equals(mailingAddress, 1)) {
        this.mailingAddress = this.obj.initialState.addressList[1];
        if (sessionStorage.getItem('appType') === "ADF") {
          this.hasDischargeAddress = true;
          this.hasMalingAddress = false;
        } else {
        this.hasDischargeAddress = false;
        this.hasMalingAddress = true;
        }
        this.isMailValid = false;
      } else if (equals(mailingAddress, 0)) {
        this.mailingAddress = this.obj.initialState.addressList[0];
        this.hasMalingAddress = true;
        this.isMailValid = false;
      } else {
        this.isMailValid = true;
      }

      const assistingAddress = this.obj.initialState.addressList.findIndex(i => i.addressType === 'ASSISTING')
      if (equals(assistingAddress, 2)) {
        this.hasAssistingAddress = true;
        this.isAssisValid = false;
        this.assistingAddress = this.obj.initialState.addressList[2];
      } else if (equals(assistingAddress, 1)) {
        this.hasAssistingAddress = true;
        this.isAssisValid = false;
        this.assistingAddress = this.obj.initialState.addressList[1];
      } else if (equals(assistingAddress, 0)) {
        this.hasAssistingAddress = true;
        this.isAssisValid = false;
        this.assistingAddress = this.obj.initialState.addressList[0];
      } else {
        this.isAssisValid = true;
      }
    }

    this.form = this.builder.group({ type: null });
  }
  
  onConfirm(): void {
    this.form.markAsTouched();
    if (this.form.valid) {
      this.hide(this.addressList);
      this.saveExit ? this.processService.setIsfromAddressSaveExit('fromSaveExit') : '';
      super.onConfirm();
    }
  }
}

