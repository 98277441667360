export default {
    typeOfAsset: 'RMB_TRANSFER_STATIC_TEXT1',
    specificAsset: 'RMB_TRANSFER_STATIC_TEXT2',
    assetTransferredTo: 'RMB_TRANSFER_STATIC_TEXT3',
    assetTransferDate: 'TRANSFERED_STATIC_TEXT7',
    valueOfAsset: 'TRANSFERED_STATIC_TEXT8',
    inReturnAmount: 'TRANSFERED_STATIC_TEXT9',
    removed: {
        transferEndDate: 'TRANSFERRED_RESOURCE_RMB_SUMMARY_STATIC1'
    }
}