<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left" id="my-modal-title"> {{'ADDRESS_VALIDATION' | translate}} </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon" />
    </button>
  </div>
  <div class="modal-body">
    <p *ngIf="saveExit">{{'ADDRESS_VALIDATION_INFO' | translate}}</p>
    <div>
      <div *ngIf="hasPhysicalAddress && isAppeal" class="panel-section">
        <p>{{'APPL_ADDRESS_VALIDATION_STATIC1' | translate }}</p>
        <div aria-label="nursingAddress">
          <h5 *ngIf="isAppealDemo" class="section-header">{{'APPL_ADDRESS_VALIDATION_STATIC2' | translate}}</h5>
          <h5 *ngIf="isAppealRep" class="section-header">{{'APPL_ADDRESS_VALIDATION_STATIC4' | translate}}</h5>
          <hr>
        </div>
        <p>{{'APPL_ADDRESS_VALIDATION_STATIC3' | translate}}</p>
        <form [formGroup]="form">
          <div class="col">
            <div class="col-md-9">
              <input class="form-check-input" type="radio" id="originalPhy" name="type" aria-label="originalPhy"
                (change)="physicalSelect('originalPhy')" value="original">
              <label class="form-check-label"
                for="originalPhy"><strong>{{'ADDRESS_VALIDATION_INFO_4' | translate }}:</strong></label>
              <div>
                <span>{{ physicalAddress.originalAddress.addressLine1 }}<br></span>
                <span *ngIf="physicalAddress.originalAddress.addressLine2">{{
                  physicalAddress.originalAddress.addressLine2 }}<br></span>
                <span>{{ physicalAddress.originalAddress.city }}, {{ physicalAddress.originalAddress.state.code }} {{
                  physicalAddress.originalAddress.zip | mask: '00000-9999' }}</span>
              </div>
            </div>
            <div *ngIf="physicalAddress.suggestedAddress" class="col-md-9"> <br>
              <input class="form-check-input" type="radio" name="type" id="suggestedPhy" aria-label="suggestedPhy"
                (change)="physicalSelect('suggestedPhy')" value="suggested">
              <label class="form-check-label" for="suggestedPhy"><strong>{{'ADDRESS_VALIDATION_INFO_6' |
                  translate}}:</strong></label>
              <div>
                <span>{{ physicalAddress.suggestedAddress.addressLine1 }}<br></span>
                <span *ngIf="physicalAddress.suggestedAddress.addressLine2">{{
                  physicalAddress.suggestedAddress.addressLine2 }}<br></span>
                <span>{{ physicalAddress.suggestedAddress.city }}, {{ physicalAddress.suggestedAddress.state.code }} {{
                  physicalAddress.suggestedAddress.zip | mask: '00000-9999' }}</span>
              </div>
            </div>
            <nh-alert type="warning" [isOpen]="physicalAddressAlert">
              <div class="row">
                <div class="icon">
                  <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
                </div>
                <div class="message">
                  <span>{{'ENTER_MANDATORY' | translate }}</span>
                </div>
              </div>
            </nh-alert>
          </div>
        </form>
      </div>

      <div *ngIf="(hasPhysicalAddress || hasNursingAddress) && !isAppeal" class="panel-section">
        <div *ngIf="hasPhysicalAddress">
          <h5 class="section-header">{{'HOUSEHOLD_PHYSICAL_ADDRESS' | translate}}</h5>
          <hr>
        </div>
        <div aria-label="nursingAddress" *ngIf="hasNursingAddress">
          <h5 class="section-header">{{'NURSING_FACILITY_ADDRESS' | translate}}</h5>
          <hr>
        </div>
        <div *ngIf="physicalAddress.suggestedAddress">
          <p>{{'ADDRESS_VALIDATION_INFO_1' | translate }}</p>
        </div>
        <div *ngIf="!physicalAddress.suggestedAddress">
          <p>{{'ADDRESS_VALIDATION_INFO_2' | translate }}</p>
          <p [innerHTML]="'ADDRESS_VALIDATION_INFO_3' | translate"></p>
        </div>
        <form [formGroup]="form">
          <div class="col">
            <div class="col-md-9">
              <input class="form-check-input" type="radio" id="originalPhy" name="type" aria-label="originalPhy"
                (change)="physicalSelect('originalPhy')" value="original">
              <label *ngIf="physicalAddress.suggestedAddress" class="form-check-label"
                for="originalPhy"><strong>{{'ADDRESS_VALIDATION_INFO_4' | translate }}:</strong></label>
              <label *ngIf="!physicalAddress.suggestedAddress" class="form-check-label"
                for="originalPhy"><strong>{{'ADDRESS_VALIDATION_INFO_5' | translate }}:</strong></label>
              <div>
                <span>{{ physicalAddress.originalAddress.addressLine1 }}<br></span>
                <span *ngIf="physicalAddress.originalAddress.addressLine2">{{
                  physicalAddress.originalAddress.addressLine2 }}<br></span>
                <span>{{ physicalAddress.originalAddress.city }}, {{ physicalAddress.originalAddress.state.code }} {{
                  physicalAddress.originalAddress.zip | mask: '00000-9999' }}</span>
              </div>
            </div>
            <div *ngIf="physicalAddress.suggestedAddress" class="col-md-9"> <br>
              <input class="form-check-input" type="radio" name="type" id="suggestedPhy" aria-label="suggestedPhy"
                (change)="physicalSelect('suggestedPhy')" value="suggested">
              <label class="form-check-label" for="suggestedPhy"><strong>{{'ADDRESS_VALIDATION_INFO_6' |
                  translate}}:</strong></label>
              <div>
                <span>{{ physicalAddress.suggestedAddress.addressLine1 }}<br></span>
                <span *ngIf="physicalAddress.suggestedAddress.addressLine2">{{
                  physicalAddress.suggestedAddress.addressLine2 }}<br></span>
                <span>{{ physicalAddress.suggestedAddress.city }}, {{ physicalAddress.suggestedAddress.state.code }} {{
                  physicalAddress.suggestedAddress.zip | mask: '00000-9999' }}</span>
              </div>
            </div>
            <nh-alert type="warning" [isOpen]="physicalAddressAlert">
              <div class="row">
                <div class="icon">
                  <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
                </div>
                <div class="message">
                  <span>{{'ENTER_MANDATORY' | translate }}</span>
                </div>
              </div>
            </nh-alert>
          </div>
        </form>
      </div>

      <div *ngIf="hasMalingAddress || hasDischargeAddress" class="panel-section">
        <div *ngIf="hasMalingAddress">
          <h5 class="section-header">{{'HOUSEHOLD_MAILING_ADDRESS' | translate}}</h5>
          <hr>
        </div>
        <div aria-label="dischargeAddress" *ngIf="hasDischargeAddress">
          <h5 class="section-header">{{'NURSING_FACILITY_ADDRESS' | translate}}</h5>
          <hr>
        </div>
        <div *ngIf="!mailingAddress.suggestedAddress">
          <p>{{'ADDRESS_VALIDATION_INFO_2' | translate}}</p>
          <p [innerHTML]="'ADDRESS_VALIDATION_INFO_3' | translate"></p>
        </div>
        <form [formGroup]="form">
          <div class="col">
            <div class="col-md-9">
              <input class="form-check-input" type="radio" id="originalMail" name="type" aria-label="original"
                (change)="mailingSelect('originalMail')" value="original">
              <label *ngIf="mailingAddress.suggestedAddress" class="form-check-label"
                for="originalMail"><strong>{{'ADDRESS_VALIDATION_INFO_4' | translate}}:</strong></label>
              <label *ngIf="!mailingAddress.suggestedAddress" class="form-check-label"
                for="originalMail"><strong>{{'ADDRESS_VALIDATION_INFO_5' | translate}}:</strong></label>
              <div>
                <span>{{ mailingAddress.originalAddress.addressLine1 }}<br></span>
                <span *ngIf="mailingAddress.originalAddress.addressLine2">{{ mailingAddress.originalAddress.addressLine2
                  }}<br></span>
                <span>{{ mailingAddress.originalAddress.city }}, {{ mailingAddress.originalAddress.state.code }} {{
                  mailingAddress.originalAddress.zip | mask: '00000-9999' }}</span>
              </div>
            </div>
            <div *ngIf="mailingAddress.suggestedAddress" class="col-md-9"><br>
              <input class="form-check-input" type="radio" name="type" id="suggestedMail" aria-label="suggestedMail"
                (change)="mailingSelect('suggestedMail')" value="suggested">
              <label class="form-check-label" for="suggestedMail"><strong>{{'ADDRESS_VALIDATION_INFO_6' |
                  translate}}:</strong></label>
              <div>
                <span>{{ mailingAddress.suggestedAddress.addressLine1 }}<br></span>
                <span *ngIf="mailingAddress.suggestedAddress.addressLine2">{{
                  mailingAddress.suggestedAddress.addressLine2 }}<br></span>
                <span>{{ mailingAddress.suggestedAddress.city }}, {{ mailingAddress.suggestedAddress.state.code }} {{
                  mailingAddress.suggestedAddress.zip | mask: '00000-9999' }}</span>
              </div>
            </div>
            <nh-alert type="warning" [isOpen]="mailingAddressAlert">
              <div class="row">
                <div class="icon">
                  <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
                </div>
                <div class="message">
                  <span>{{'ENTER_MANDATORY' | translate }}</span>
                </div>
              </div>
            </nh-alert>
          </div>
        </form>
      </div>

      <div *ngIf="hasAssistingAddress" class="panel-section">
        <h5 class="section-header">{{'ASSISTING_PERSON_ADDRESS' | translate}}</h5>
        <hr>
        <div *ngIf="!assistingAddress.suggestedAddress">
          <p>{{'ADDRESS_VALIDATION_INFO_2' | translate}}</p>
          <p [innerHTML]="'ADDRESS_VALIDATION_INFO_3' | translate"></p>
        </div>
        <form [formGroup]="form">
          <div class="col">
            <div class="col-md-9">
              <input class="form-check-input" type="radio" id="originalAssis" name="type" aria-label="originalAssis"
                (change)="assistingSelect('originalAssis')" value="original">
              <label *ngIf="assistingAddress.suggestedAddress" class="form-check-label"
                for="originalAssis"><strong>{{'ADDRESS_VALIDATION_INFO_4' | translate}}:</strong></label>
              <label *ngIf="!assistingAddress.suggestedAddress" class="form-check-label"
                for="originalAssis"><strong>{{'ADDRESS_VALIDATION_INFO_5' | translate}}:</strong></label>
              <div>
                <span>{{ assistingAddress.originalAddress.addressLine1 }}<br></span>
                <span *ngIf="assistingAddress.originalAddress.addressLine2">{{
                  assistingAddress.originalAddress.addressLine2 }}<br></span>
                <span>{{ assistingAddress.originalAddress.city }}, {{ assistingAddress.originalAddress.state.code }} {{
                  assistingAddress.originalAddress.zip | mask: '00000-9999' }}</span>
              </div>
            </div>
            <div *ngIf="assistingAddress.suggestedAddress" class="col-md-9"><br>
              <input class="form-check-input" type="radio" name="type" id="suggestedAssis" aria-label="suggestedAssis"
                (change)="assistingSelect('suggestedAssis')" value="suggested">
              <label class="form-check-label" for="suggestedAssis"><strong>{{'ADDRESS_VALIDATION_INFO_6' |
                  translate}}:</strong></label>
              <div>
                <span>{{ assistingAddress.suggestedAddress.addressLine1 }}<br></span>
                <span *ngIf="assistingAddress.suggestedAddress.addressLine2">{{
                  assistingAddress.suggestedAddress.addressLine2 }}<br></span>
                <span>{{ assistingAddress.suggestedAddress.city }}, {{ assistingAddress.suggestedAddress.state.code }}
                  {{ assistingAddress.suggestedAddress.zip | mask: '00000-9999' }}</span>
              </div>
            </div>
            <nh-alert type="warning" [isOpen]="AssistingAddressAlert">
              <div class="row">
                <div class="icon">
                  <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
                </div>
                <div class="message">
                  <span>{{'ENTER_MANDATORY' | translate }}</span>
                </div>
              </div>
            </nh-alert>
          </div>
        </form>
      </div>

    </div>
  </div>
  <div class="modal-footer d-block">
    <div class="form-row justify-content-start form-actions" >
      <div class="col-md-3">
        <button id="dialog" type="button" class="btn form-actions-btn btn-block btn-secondary"
          [disabled]="!isPhyValid || !isMailValid || !isAssisValid" (click)="onConfirm()">{{'ADDRESS_SUBMIT' |
          translate}}</button>
      </div>
      <div class="col-md-3">
        <button id="dialog" type="button" class="btn form-actions-btn btn-block btn-link" (click)="onCancel()">{{'CANCEL' | translate}}</button>
      </div>
    </div>
  </div>
</div>