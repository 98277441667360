import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { keys, zip, map, zipObj } from 'ramda';

import { Table } from '@app/models/tables.model';

@Component({
  selector: 'nh-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxListComponent implements OnInit {
  @Input() checkboxGroup: FormGroup;
  @Input() referenceTable: Table;
  @Input() label = null;
  @Input() inline = false;
  @Input() disabled = false;
  @Input() uniqueString: string;
  // @Input() checkboxName: string;

  checkboxList;

  ngOnInit() {
    const createCheckboxes = map(zipObj(['control', 'reference']));
    const zipControls = zip(keys(this.checkboxGroup.controls), this.referenceTable);
    this.checkboxList = createCheckboxes(zipControls);
  }
}
