import { isNil } from 'ramda';
import { compose } from 'ramda';
import { length } from 'ramda';
import { either } from 'ramda';
import { repeat } from 'ramda';
import { propSatisfies } from 'ramda';
import { pathSatisfies } from 'ramda';
import { ifElse } from 'ramda';
import { when } from 'ramda';
import { isEmpty } from 'ramda';
import { all } from 'ramda';
import { pickBy } from 'ramda';
import { not } from 'ramda';
import { dec } from 'ramda';
import { inc } from 'ramda';
import { useWith } from 'ramda';
import { or } from 'ramda';

import { omitBy } from 'ramda-adjunct';
import { isNotNil } from 'ramda-adjunct';
import { isNotEmpty } from 'ramda-adjunct';

export const eitherNil = either(isNil);
export const nullList = compose(
  repeat(null),
  length
);
export const nilProp = propSatisfies(isNil);
export const nunProp = propSatisfies(isNotNil);
export const nilElse = ifElse(isNil);
export const nunElse = ifElse(isNotNil);

export const emptyElse = ifElse(isEmpty);
export const ifNotEmpty = when(isNotEmpty);

export const ifNil = when(isNil);
export const ifNun = when(isNotNil);

export const pathEmpty = pathSatisfies(isEmpty);
export const nilPath = pathSatisfies(isNil);
export const allNil = all(isNil);
export const removeNilKeys = omitBy(isNil);
export const pickNotNil = pickBy(isNotNil);

export const nilArgs = useWith(or, [isNil, isNil]);

export const nilZero = compose(
  not,
  dec,
  inc
);
