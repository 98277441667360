import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { routeEquals } from './helpers/route.helpers';
import { StateService } from '@app/services/state/state.service';

@Injectable()
export class AppResolve implements Resolve<any> {
  constructor(private state: StateService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return routeEquals(() => this.state.inProgress(), state, route);
  }
}
