<div *ngIf="isRmbRmc"><span>{{ staticText }}</span></div>
<div *ngIf="isRmbRmc && this.selectedIndividual"><br></div>
<div *ngIf="getValue('extras.topLevelAnswer') === 'YES'" form-top>
  <h6 class="person-label">{{ selectedIndividual | fullname }}</h6></div><br>
<nh-form [topLevelName] = "'re'"
[individuals]="checkForDropdown ? finalListAll : individuals" [formGroup]="getForm()" class="label-section" topLevelQuestion="{{'RESOURCES_PROPERTY_TOPLEVEL' | translate }}" popupText="{{'RESOURCES_PROPERTY_POPUP' | translate }}" individualQuestion="{{'RESOURCES_PROPERTY_INDIV_QUESTION' | translate }}" 
(validate)="onValidate($event)" (individualAnswerChanged)="onIndividualChange($event)">

  <section [formGroup]="getControl('data')">
    <h5 class="section-header">{{'RESOURCES_PROPERTY_STATIC1' | translate }}</h5><hr>

    <nh-field id = "re_assetType" label="{{'RESOURCES_PROPERTY_STATIC2' | translate }} {{ selectedIndividual | fullname }}{{'VEHICLE_STATIC_TEXT3' | translate }}<div class=hint>{{'IF' | translate }} {{ selectedIndividual | fullname }} {{'RESOURCES_PROPERTY_STATIC3' | translate }}</div>" class="label-section">
     <select nh name="assetType" aria-label="assetType" [items]="tableCodeValue('MPPROPTYPE', 'data.assetType')" formControlName="assetType"></select>
    </nh-field>

    <nh-alert type="warning" [isOpen]="isHome">
      <div class="row">
        <div class="icon">
          <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
        </div>
        <div class="message">
          <span>{{ 'RESOURCES_PROPERTY_ERROR1' | translate }} {{ selectedIndividual | fullname }} {{ 'RESOURCES_PROPERTY_ERROR2' | translate }} {{ selectedIndividual | fullname }}{{ 'RESOURCES_PROPERTY_ERROR3' | translate }}</span>
        </div>
      </div>
    </nh-alert>
    <nh-field id = "re_landUseCode" label="{{'RESOURCES_PROPERTY_STATIC4' | translate }}" class="label-section">
      <select nh name="landUseCode" aria-label="landUseCode" [items]="tableCodeValue('REALPROPERTYUSE', 'data.landUseCode')" (change)="validateLandUseCode()" formControlName="landUseCode"></select>
    </nh-field>

    <nh-field id = "re_primaryResidence"  *ngIf="isEnabled('data.primaryResidence')" label="{{'DOES_2' | translate }}{{ selectedIndividual | fullname }} {{'RESOURCES_PROPERTY_STATIC5' | translate }}" class="label-section">
      <select nh name="primaryResidence" aria-label="primaryResidence" [items]="tableCodeValue('YESNO', 'data.primaryResidence')" formControlName="primaryResidence"></select>
    </nh-field>

    <nh-field id = "re_intendToReturn" [mandatory]="false"  *ngIf="isEnabled('data.intendToReturn')" label="{{'DOES_2' | translate }}{{ selectedIndividual | fullname }} {{'RESOURCES_PROPERTY_STATIC6' | translate }}" class="label-section">
      <select nh name="intendToReturn" [items]="tableCodeValue('YESNO', 'data.intendToReturn')" formControlName="intendToReturn"></select>
    </nh-field>

    <nh-field id = "re_rentHome" [mandatory]="false"  *ngIf="isEnabled('data.rentHome')" label="{{'DOES_2' | translate }}{{ selectedIndividual | fullname }} {{'RESOURCES_PROPERTY_STATIC7' | translate }}" class="label-section">
      <select nh name="rentHome" [items]="tableCodeValue('YESNO', 'data.rentHome')" formControlName="rentHome"></select>
    </nh-field>

    <nh-field id = "re_monthlyIncome" [mandatory]="false"  *ngIf="isEnabled('data.monthlyIncome')" label="{{'RESOURCES_PROPERTY_STATIC8' | translate }}" class="label-section">
      <nh-input-group prepend="$">
        <input nh type="decimal"  maxlength="7" name="monthlyIncome" formControlName="monthlyIncome" class="form-control">
            </nh-input-group>
        </nh-field>

    <nh-field id = "re_owedAmount" [mandatory]="false"  *ngIf="isEnabled('data.owedAmount')" label="{{'VEHICLE_STATIC_TEXT9' | translate }} {{ selectedIndividual | fullname }} {{'RESOURCES_PROPERTY_STATIC9' | translate }} {{ getValue('data.assetType').value }}?" class="label-section">
      <nh-input-group prepend="$">
        <input nh type="decimal"  maxlength="7" name="owedAmount" formControlName="owedAmount" class="form-control">
      </nh-input-group>
        </nh-field>

    <nh-field id = "re_assetAmount" [mandatory]="false" label="{{'RESOURCES_PROPERTY_STATIC10' | translate }} {{ getValue('data.assetType').value }}?" class="label-section">
      <nh-input-group prepend="$">
        <input nh type="decimal"  maxlength="7" name="assetAmount" formControlName="assetAmount" class="form-control">
            </nh-input-group>
        </nh-field>

    <h5 class="section-header">{{'RESOURCES_PROPERTY_STATIC11' | translate }}</h5><hr>

    <nh-alert type="warning" [isOpen]="getControl('data.assetAddress').touched">
      <div class="row">
        <div class="icon">
          <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
        </div>
        <div class="message">
          <span>{{'ADDRESS_WARNING' | translate }}</span>
        </div>
      </div>
    </nh-alert>

    <nh-name-address [addressname] = "'re_assetAddress'" [addressFieldMandatory]="false" [stateDefault]="false" [showPopup]="false" [address]="getControl('data.assetAddress')" [tables]="tables"
    [hideCounty]="true"></nh-name-address>
    <br>
    <div *ngIf="selectedIndividual">
    <h5 class="section-header">{{'CO-OWENERS_TITLE' | translate}}</h5><hr>
    <nh-field id = "re_jointOwnership"
      label="{{'VEHICLE_STATIC_TEXT14' | translate }} {{ getValue('data.assetType').value }} {{'VEHICLE_STATIC_TEXT15' | translate }} {{ selectedIndividual | fullname }}? " class="label-section">
      <div class=hint>{{'VEHICLE_STATIC_TEXT16' | translate }} {{ selectedIndividual | fullname }} {{'VEHICLE_STATIC_TEXT17' | translate }}</div>
      <select nh name="jointOwnership" aria-label="jointOwnership" [items]="tableCodeValue('YESNO', 'data.jointOwnership')" formControlName="jointOwnership"></select>
    </nh-field>
    <div (window:resize)="onResize($event)"></div>

    <div *ngIf="isY('data.jointOwnership')">
      <nh-co-owner [jointOwnershipInformation]="getControl('data.jointOwnershipInformation')"
        [formData]="getControl('data')" [addRowValid]="checkValid()" [coOwnerIndividuals]="coOwnerIndividuals"
        [error]="error" (emitValidate)="coOwnerValidate($event)" (errorMessage)="getErrorMessage($event)"
        [coOwnerIndivErrorMessage]="coOwnerIndivErrorMessage"
        (removeRow)="removeRow($event)" (addRow)="addRow()"></nh-co-owner>
    </div>
  </div>
  </section>
</nh-form>
