import { Directive, AfterViewInit, ElementRef, Renderer2, ContentChild } from '@angular/core';
import { FormControlName } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'input[formControlName], textarea[formControlName], select[formControlName]',
})
export class NameIdDirective implements AfterViewInit {
  @ContentChild(FormControlName, { static: false })
  field: FormControlName;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  ngAfterViewInit() {
    if (this.field) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'id', `${this.field.name}`);
    }
  }
}
