import { Pipe, PipeTransform } from '@angular/core';

import { MaskPipe } from 'ngx-mask';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  constructor(private maskPipe: MaskPipe) {}

  transform(value: any): string {
    return this.maskPipe.transform(value, '000-000-0000');
  }
}
