import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceConfigService {

  public server;
  public httpWithoutInterceptor: HttpClient;

  constructor(handler: HttpBackend) { 
     this.httpWithoutInterceptor = new HttpClient(handler);
  }
  loadAppConfig() {
    return this.httpWithoutInterceptor.get('/assets/data/appConfig.json')
      .toPromise()
      .then(data => {
        this.server = data;
      });
  }

  getServerConfig() {
    return this.server;
  }
}
