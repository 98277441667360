import { isEmpty } from 'ramda';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { Tables } from '@app/models/tables.model';
// import { CreateAccountService } from '../services/createAccount.service';
import Vs from '@app/services/validators/validators.service';
import { ApiService } from '../services/api.service';
import { ServiceConfigService } from '@app/services/service-config.service';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal/modal.service';
import { RecaptchaLoaderService } from 'ng-recaptcha';
import { RecaptchaLanguageLoaderService } from '@app/services/recaptcha-language-loader.service';
import { HttpHeaders } from '@angular/common/http';
import { MetaService } from '@app/services/metaservice';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'nh-register-account',
  templateUrl: './register-account.component.html',
  styleUrls: ['./register-account.component.scss']
})
export class RegisterAccountComponent implements OnInit {

  public securityQuestions: Tables | any;
  showErr1: boolean = false;
  showErr2: boolean = false;
  passwordRuleLen: boolean;
  passwordRuleUpp: boolean;
  passwordRuleLow: boolean;
  passwordRuleNum: boolean;
  passwordRuleSpl: boolean;
  isPasswordMatched: boolean = true;
  isEmailIdMatched: boolean = true;
  validPassword: boolean;
  fieldTextType: boolean;
  fieldConfirmPassTextType: boolean;
  splValue = '(!$#,&@~^*_-+;=><?%:;|)';
  isMailIdTaken: boolean;
  isUsernameTaken: boolean;
  questionSet1: any;
  questionSet2: any;
  questionSet3: any;
  // headerTitle: string = 'Registration';
  // sectionTitle: string = 'Create Your Account';
  isRegistrationScreenEnabled: boolean = true;
  // showErrorMessage = '';
  siteKey: string;
  questionsArray = [];
  isRecaptchaEnabled: boolean;
  currentLang;
  isPerfEnv: boolean;
  verfLink;
  loaderReady = false;
  contentHeaders = new HttpHeaders({'pageId':'TCCAT'})

  registerAccount = new FormGroup({
    userName: new FormControl('', [Vs.spl, Vs.userLength]),
    firstName: new FormControl('', Vs.firsName),
    lastName: new FormControl('', Vs.lastName),
    email: new FormControl('', Vs.email),
    confirmEmail: new FormControl('', Vs.email),
    password: new FormControl('', Vs.required),
    confirmPassword: new FormControl(''),
    question1: new FormControl('', Vs.required),
    answer1: new FormControl('', Vs.answer),
    question2: new FormControl('', Vs.required),
    answer2: new FormControl('', Vs.answer),
    question3: new FormControl('', Vs.required),
    answer3: new FormControl('', Vs.answer),
    recaptchaReactive: new FormControl(null, Vs.required)
  })

  constructor(
    @Inject(RecaptchaLoaderService)private loader: RecaptchaLanguageLoaderService,
    private zone: NgZone,
    // private createAccountService: CreateAccountService,
    private apiService: ApiService,
    public translateService: TranslateService,
    private serverConfig: ServiceConfigService,
    private router: Router,
    private modal: ModalService, private metaService: MetaService,
    private titleService: Title
    ) {

    // const refData = this.createAccountService.getReferenceData();
    // const opts = refData.referenceGroup.find(data => data.name === "securityQuestions");
    // this.securityQuestions = opts.referenceData;
    this.titleService.setTitle('Register Account');
    this.siteKey = this.serverConfig.getServerConfig().SITE_KEY;
    this.isRecaptchaEnabled = this.serverConfig.getServerConfig().isRecaptchaEnabled;
    this.isPerfEnv = this.serverConfig.getServerConfig().isPerfEnv;

    translateService.onDefaultLangChange.subscribe((res) => {
      res.lang === 'sp' ? this.changeRecaptchaLanguage('es') : this.changeRecaptchaLanguage('en');
      this.switchLanguage(res.lang);
    });
  }

  get registerForm() {
    return this.registerAccount.controls;
  }
  get answer1() {
    return this.registerForm.answer1;
  }
  get answer2() {
    return this.registerForm.answer2;
  }
  get answer3() {
    return this.registerForm.answer3;
  }

  ngOnInit(): void {
    this.metaService.createCanonicalURL();
    this.currentLang = this.translateService.defaultLang === 'sp' ? 'ES' : 'EN';
    if (!this.isRecaptchaEnabled) {
      this.registerAccount.removeControl('recaptchaReactive');
    }
    const url = 'publ/reference-tables/retrieve?table=SECURITYQUESTIONS';
    this.apiService.get(url).subscribe((res) => {
      res.columnDetails.forEach((item) => {
        const subset = (({ code, enDescription, spDescription }) => ({ code, enDescription, spDescription }))(item);
        subset['value'] = subset['enDescription'];
        this.questionsArray.push(subset);
      });
      this.questionSet1 = this.questionsArray.slice(0, 9).sort(function(a, b) {
         return a.value.localeCompare(b.value);
      });
      this.questionSet2 = this.questionsArray.slice(10, 19).sort(function(a, b) {
         return a.value.localeCompare(b.value);
      });
      this.questionSet3 = this.questionsArray.slice(20, this.questionsArray.length - 1).sort(function(a, b) {
         return a.value.localeCompare(b.value);
      });
    });

    setTimeout(() => {
      const lang = this.translateService.getDefaultLang();
      lang === 'sp' ? this.changeRecaptchaLanguage('es') : this.changeRecaptchaLanguage('en')
    }, 1000);
    this.loader.ready.subscribe(v => {
      setTimeout(() => this.zone.run(() => this.loaderReady = !!v), 0);
    });
  }

  switchLanguage(lang) {
    this.currentLang = lang;
    this.questionSet1 = this.questionSet2 = this.questionSet3 = [];

    if (lang === 'sp' && this.questionsArray) {
      this.questionsArray.forEach(item => {
        item.value = item.spDescription;
      });
      this.questionSet1 = this.questionsArray.slice(0, 9).sort(function(a, b) {
         return a.value.localeCompare(b.value);
      });
      this.questionSet2 = this.questionsArray.slice(10, 19).sort(function(a, b) {
         return a.value.localeCompare(b.value);
      });
      this.questionSet3 = this.questionsArray.slice(20, this.questionsArray.length - 1).sort(function(a, b) {
         return a.value.localeCompare(b.value);
      });
    } else if (this.questionsArray) {
      this.questionsArray.forEach(item => {
        item.value = item.enDescription;
      });
      this.questionSet1 = this.questionsArray.slice(0, 9).sort(function(a, b) {
         return a.value.localeCompare(b.value);
      });
      this.questionSet2 = this.questionsArray.slice(10, 19).sort(function(a, b) {
         return a.value.localeCompare(b.value);
      });
      this.questionSet3 = this.questionsArray.slice(20, this.questionsArray.length - 1).sort(function(a, b) {
         return a.value.localeCompare(b.value);
      });
    }
  };

  comparePassword(event) {
    this.showErr1 = false;
    this.showErr2 = false;
    const passwordRuleLow = (event.target.value.match(/[a-z]/g));
    const passwordRuleUpp = (event.target.value.match(/[A-Z]/g));
    const passwordRuleNum = (event.target.value.match(/[0-9]/g));
    const passwordRuleLen = (event.target.value.length >= 8 && event.target.value.length <= 32);
    let passwordRuleSpl = false;
    for (let i = 0; i < event.target.value.length; i++) {
      if (this.splValue.indexOf(event.target.value.charAt(i)) > -1) {
        passwordRuleSpl = true;
      }
    }
    this.showErr2 = passwordRuleLen && passwordRuleLow && passwordRuleUpp && passwordRuleNum && passwordRuleSpl ? false: true;
    this.validPassword = (this.passwordRuleLow && this.passwordRuleUpp && this.passwordRuleNum
      && this.passwordRuleSpl && this.passwordRuleLen &&
      (event.target.value == this.registerAccount.controls['password'].value));
    this.isPasswordMatched = (event.target.value.length > 0 && (event.target.value == this.registerAccount.controls['password'].value));

    if (this.isPasswordMatched) {
      this.registerForm.confirmPassword.setErrors(null);
      // this.showErr2 = false;
    } else if (isEmpty(this.registerForm.confirmPassword.value)) {
      this.showErr1 = true;
      this.registerForm.confirmPassword.setErrors({ PASSWORD_CONFIRM: true });
      // this.showErr2 = false;
    } else {
      this.registerForm.confirmPassword.setErrors({ PASSWORDS_DIFFERENT: true });
    }
  }

  validateEmail(event) {
    this.isEmailIdMatched = (event.target.value == this.registerAccount.controls['email'].value);
    this.isEmailIdMatched ? this.registerForm.confirmEmail.setErrors(null) : this.registerForm.confirmEmail.setErrors({EMAIL_MATCH: true})
  }

  toggleFieldTextType(fieldType) {
    if (fieldType == 'password') {
      this.fieldTextType = !this.fieldTextType;
    } else {
      this.fieldConfirmPassTextType = !this.fieldConfirmPassTextType
    }
  }

  validatePassword(event): void {
    this.passwordRuleLow = (event.target.value.match(/[a-z]/g));
    this.passwordRuleUpp = (event.target.value.match(/[A-Z]/g));
    this.passwordRuleNum = (event.target.value.match(/[0-9]/g));
    this.passwordRuleLen = (event.target.value.length >= 8 && event.target.value.length <= 32);
    this.passwordRuleSpl = false;
    for (let i = 0; i < event.target.value.length; i++) {
      if (this.splValue.indexOf(event.target.value.charAt(i)) > -1) {
        this.passwordRuleSpl = true;
        break;
      }
    }
    if (this.passwordRuleLow && this.passwordRuleUpp && this.passwordRuleNum
      && this.passwordRuleSpl && this.passwordRuleLen) {
      this.showErr1 = true;
      this.registerForm.confirmPassword.setErrors({ PASSWORD_CONFIRM: true });
      this.showErr2 = false;
    } else {
      this.showErr2 = true;
      this.registerForm.confirmPassword.setErrors(null);
      // this.showErrorMessage = 'Please enter a password that matches the guidelines.'
    }
  }

  isFormValid(): boolean {
    return ((this.registerAccount.status === "VALID")
      && this.validPassword
      && this.isEmailIdMatched
      && !this.isUsernameTaken
      && !this.isMailIdTaken);
  }

  checkUserNameAvailability(): void {
    this.apiService.get('publ/users/checkUserAvailability?userName=' + this.registerAccount.controls['userName'].value, {headers: this.contentHeaders}).subscribe((result: any) => {
      this.isUsernameTaken = !result;
    });
  }

  checkEmailAvailability(): void {
    if (this.registerAccount.controls['email'].value) {
      this.apiService.get('publ/users/checkEmailAvailability?emailId=' + this.registerAccount.controls['email'].value, {headers: this.contentHeaders}).subscribe((result) => {
        this.isMailIdTaken = !result;
      })
    }
  }

  createAccount(): void {
    const reqestData = this.registerAccount.value;
    reqestData['language'] = this.currentLang;
    this.apiService.post('publ/users/createAccount', reqestData, {headers: this.contentHeaders}).subscribe((response) => {
      if (this.isPerfEnv && response['code'] == 200) {
          this.isRegistrationScreenEnabled = false;
          this.verfLink = response['userName'];
      }
      if (!this.isPerfEnv && response['code'] == 200) {

        this.isRegistrationScreenEnabled = false;
      }
    })
  }

  resolved(captchaResponse: string) {
    const recaptachRequest = {
      'recaptchaResponseToken': captchaResponse,
      'chkRecaptcha': "YES"
    }
    this.apiService.post('publ/users/validateRecaptcha', recaptachRequest, {headers: this.contentHeaders}).subscribe(() => {

    })
  }

  answerUpdate(ans2, ans3, ans1) {
    if (ans1.value && [ans2.value, ans3.value].indexOf(ans1.value) >= 0) {
      ans1.setErrors({ ANSWER_DUPLICATE: true })
    } else {
      if ([ans2.value, ans3.value].indexOf(ans1.value) < 0 && [ans3.value, ans1.value].indexOf(ans2.value) < 0 && [ans1.value, ans2.value].indexOf(ans3.value) < 0
      && ans1.value.length >= 3 && ans2.value.length >= 3 && ans3.value.length >= 3) {
        [ans1, ans2, ans3].forEach(ans => ans.setErrors(null));
      }
      if ([[ans2.value, ans3.value].indexOf(ans1.value) < 0] && ans1.value.length >= 3) {
        ans1.setErrors(null);
      }
      if ([ans3.value, ans1.value].indexOf(ans2.value) < 0 && ans2.value.length >= 3) {
        ans2.setErrors(null);
      }
      if ([ans1.value, ans2.value].indexOf(ans3.value) < 0 && ans3.value.length >= 3) {
        ans3.setErrors(null);
      }
      !ans1.value ? ans1.setErrors({required: true}) :ans1.value.length < 3 ? ans1.setErrors({INVALID_ANSWER: true}) : null;
    }
  }

  changeRecaptchaLanguage(lang) {
    this.loader.updateLanguage(lang);
  }

  onPrevious() {
    if(this.registerAccount.pristine) {
      this.router.navigateByUrl("/signin");
      return;
    }
    this.modal.confirmDeactivate().subscribe(resp => {
      if (resp) {
        this.router.navigateByUrl("/signin");
      }
    });
  }
}
