import { HttpParams } from '@angular/common/http';

import { compose } from 'ramda';
import { construct } from 'ramda';
import { propOr } from 'ramda';
import { map } from 'ramda';
import { invoker } from 'ramda';
import { objOf } from 'ramda';
import { reduce } from 'ramda';
import { toPairs } from 'ramda';
import { apply } from 'ramda';
import { identity } from 'ramda';
import { propEq } from 'ramda';
import { when } from 'ramda';
import { equals } from 'ramda';
import { startsWith } from 'ramda';

import { nilKeys } from './object.helpers';
import { pickNotNil } from './null.helpers';
import { withApply } from './function.helpers';
import { dangerMessage } from './notification.helpers';

export const clone = invoker(1, 'clone');
export const set = invoker(2, 'set');
const setKeys = withApply([identity, apply(set)]);

export const httpParams = construct(HttpParams);

export const httpErrors = compose(
  map(({ message, code, field }) => dangerMessage(message, code, field, null)),
  propOr([], 'errors')
);

const FORBIDDEN = 403;
const UNPROCESSABLE_ENTITY = 422;
const INTERNAL_SERVER_ERROR = 500;
const INTERNAL_SERVER_ERROR_FIVE_O_FOUR = 504;

const isError = propEq('status');
export const isForbidden = isError(FORBIDDEN);
export const isUnprocessableEntity = isError(UNPROCESSABLE_ENTITY);
export const isInternalServerError = isError(INTERNAL_SERVER_ERROR);
export const isInternalServerErrors = isError(INTERNAL_SERVER_ERROR) || isError(INTERNAL_SERVER_ERROR_FIVE_O_FOUR);

const setParamKeys = reduce(setKeys, httpParams({}));

export const createParams = compose(
  objOf('params'),
  setParamKeys,
  toPairs,
  pickNotNil
);

export const bodyWash = compose(
  nilKeys,
  propOr({}, 'body')
);

export const onFirst = when(equals(1));

export const hasHTTP = startsWith('http');
