<nh-form [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <section [formGroup]="getControl('data')">
    <div class="container start-page">
      <div>
        <p>Please answer the questions below to complete the BCC Treatment Plan Extension Request. This page asks for
          information about the request.</p>
        <h5 class="section-header">Member Information</h5>
        <hr>
        <div class="d-flex align-items-center">
          <h6 class="person-label">Name: </h6><span class="pl-2 pb-1">{{ name }}</span>
        </div><br>
        <div class="d-flex align-items-center">
          <h6 class="person-label">Date of Birth: </h6><span class="pl-2 pb-1">{{ dob }}</span>
        </div><br>
        <div class="d-flex align-items-center">
          <h6 class="person-label">Social Security Number: </h6><span class="pl-2 pb-1">{{ formattedSSN }}</span>
        </div><br>
        <div class="d-flex align-items-center">
          <h6 class="person-label">Gender: </h6><span class="pl-2 pb-1">{{ gender }}</span>
        </div><br>
        <p class="static-text"><b><span class="requiredfd">*</span>Can you confirm you want to submit a BCC Treatment
            Plan Extension for {{ name }}? </b> </p>

        <div class="form-row py-2">
          <div class="d-flex">
            <label class="radio-inline checkbox" for="requestExtenssionYesCheck">
              <input #checkYes [checked]="checkboxValue?.code === 'Y'" class="chk-input chk-gap"
                id="requestExtensionYesCheck" type="radio" (change)="requestExtension($event, checkNo)"
                name="requestExtensionYesCheck" value="Yes" required>
              <label class="chk-lbl">{{'Yes' | translate }}</label>
            </label>

            <label class="radio-inline checkbox" for="requestExtenssionNoCheck">
              <input #checkNo [checked]="checkboxValue?.code === 'N'" class="chk-input chk-gap"
                id="requestExtensionNoCheck" type="radio" (change)="requestExtension($event, checkYes)"
                name="requestExtensionNoCheck" value="No" required>
              <label class="chk-lbl">{{'No' | translate }}</label>
            </label>
          </div>
        </div>
        <div *ngIf="enablefollowupApptDt">
          <br>
          <h5 class="section-header">BCC Treatment Extension Information</h5>
          <hr>

          <nh-field id="followupApptDt" label="Date of Follow Up Appointment" [mandatory]="false" class="label-section">
            <nh-datepicker>
              <input type="text" name="followupApptDt" aria-label="followupApptDt" class="form-control"
                formControlName="followupApptDt" bsDatepicker nh [minDate]="minimumDate" [maxDate]="maximumDate" maxLength="10">
            </nh-datepicker>
          </nh-field>

          <nh-field id = "reasonForExtension" label="Reason for BCC Treatment Plan Extension" class="label-section">
            <select nh name="reasonForExtension" aria-label="reareasonForExtensionson" [items]="tables.BCCEXTENSIONREASON"
              formControlName="reasonForExtension"></select>
          </nh-field>
        </div>
      </div>
    </div>
  </section>
</nh-form>