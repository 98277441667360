export default [ 
"<strong><font color='#ef6b49'>NEW!</font></strong> The priority of the Department of Health and Human Services is to ensure the health and safety of all those we serve. In an effort to slow the spread of illness, beginning Wednesday, March 18, 2020 until further notice, the Bureau of Family Assistance, the Bureau of Child Support Services, and the Bureau of Employment Supports will be conducting all interviews by phone. If you have any questions about an upcoming appointment, please contact the Customer Services Center at 1-844-275-3447.",
"<strong><font color='#ef6b49'>NEW!</font></strong> Beginning February 3, 2020, all Medicaid beneficiaries, who had at least one day of Medicaid coverage in 2019 may request a copy of their Form 1095-B from the Department. <a href='/PDFs/IRSNotice-More.pdf' target='_blank'> <u>Read More</u>  </a>",
"Due to the July 29, 2019 legal decision by the US District Court for the District of Columbia, DHHS is prohibited from implementing the Work and Community Engagement requirement under Granite Advantage at this time. This means Granite Advantage members do not need to meet the community engagement requirement to maintain their Medicaid coverage. <strong>There is NO LOSS of coverage for Granite Advantage members.</strong> <a href='https://www.dhhs.nh.gov/medicaid/granite/documents/court-decision-letter-08092019.pdf' target='_blank'> <u>DHHS is notifying Granite Advantage members via mail of the Court's decision in this notice.</u>  </a>",
"Interested in getting WIC benefits? To learn more about the program and find a WIC clinic near you, please visit the <a href='#/case//atglance/outbound/https%3A%252F%252Fsignupwic.com%252F' > SIGN UP WIC WEBSITE  </a> today!",
"ONLINE ACCESS TO YOUR BUREAU OF CHILD SUPPORT SERVICES CASE - BCSS customers can now register and create an account to view case and payment information, update personal information, and e-mail BCSS workers. Customers need their BCSS Case ID to register. <a href='https://e-childsupport.dhhs.nh.gov/customer' target='_blank'> <u>NH e-ChildSupport.</u>  </a>",
"If you have an EBT card, you can access your account information by visiting the <a href='https://www.ebtedge.com/gov/portal/CardholderLogon.do' target='_blank'> <u>EDGE Customer Portal</u>  </a>",
"Apply online for a free cell phone and minutes program through <a href='http://www.safelink.com' target='_blank'> <u>SafeLink!</u>  </a> Enter Promo Code NHDHS.",
"Get information about help paying for landline phone services through the <a href='https://www.puc.nh.gov/consumer/lifeline.html' target='_blank'> <u>LIFELINE TELEPHONE ASSISTANCE PROGRAM.</u>  </a>",
"To view PDF documents, you will need <a href='http://get.adobe.com/reader/' target='_blank'> <u>Adobe Reader.</u>  </a>"
]