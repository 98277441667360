import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { CreateAccountService } from '@app/gateway/services/createAccount.service';
import { push } from '@app/helpers/form-array.helpers';
import { selfEmpIncomeGroup } from '@app/helpers/models.helpers';
import { Identifier } from '@app/models/record.model';
import { ApplicationService } from '@app/services/application/application.service';
import { ModalService } from '@app/services/modal/modal.service';
import Vs from '@app/services/validators/validators.service';
import {minus120Years} from "@app/helpers/date.helpers";
import { reset } from '@app/helpers/reset.helpers';
import { isEmpty } from 'ramda';
@Component({
  templateUrl: './income-tax.component.html',
  styleUrls: ['./income-tax.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncomeTaxComponent extends SectionFormDirective implements AfterContentInit {
  isDesktop = true;
  individualsArr: any[] = [];
  taxDeductionTypeAlp = false;
  showAlimonyDate = false;
  minimumDate = minus120Years();
  today: any = new Date();
  otherIncomeTypeSSIEn = "If you are a pregnant woman, child, or caretaker of a minor child, you do not need to tell us about SSI (Supplementary Security Income), Veteran’s benefits, or Child Support income."
  get earnedIncomeList(): FormArray {
    return this.getControl('data.earnedIncome.earnedIncomeList') as FormArray;
  }

  get selfEmpIncomeList(): FormArray {
    return this.getControl('data.selfEmpIncome.selfEmpIncomeList') as FormArray;
  }

  get otherIncomeList(): FormArray {
    return this.getControl('data.otherIncome.otherIncomeList') as FormArray;
  }

  get taxDeductionsList(): FormArray {
    return this.getControl('data.taxDeductionsData') as FormArray;
  }

  get income(): FormArray {
    return this.getControl('data.income') as FormArray;
  }

  get selfEmployment(): FormArray {
    return this.getControl('data.selfEmployment') as FormArray;
  }

  get otherIncome(): FormArray {
    return this.getControl('data.otherIncome') as FormArray;
  }

  get taxDeductionsData(): FormArray {
    return this.getControl('data.taxDeductionsData') as FormArray;
  }

  constructor(
    private modalService: ModalService, builder: FormBuilder, 
    private createaAccountService: CreateAccountService,
    private appService: ApplicationService,
    private titleService: Title
    ) {
    super(builder);
    this.titleService.setTitle('Income and Deductions');
    this.isDesktop = window.innerWidth >= 768 ? true : false;
  }

  ngAfterContentInit() {
    /*.map(res => {
      return { code: `${res.name.firstName} ${res.name.lastName}`, value: `${res.name.firstName} ${res.name.lastName}`}
    }); */
    this.createaAccountService.setIsSummary(false);
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  addEarnedIncomeRow() {
    this.income.push(this.createIncomeItem());
  }


  createIncomeItem(): FormGroup {
    return this.builder.group({
      indvId: null,
      wage: this.builder.group({
        paymentPeriod: '',
        paymentAmountPerPayCheck: ''
      }),
      employer: this.builder.group({
        name: '',
        address: this.builder.group({
          state:{
            "value": "Tennessee",
            "code": "TN"
          }
        })
      }),
      recordId: null
    });
  }

  removeIncome(row) {
    row.controls.indvId.reset();              
    row.controls["indvId"].clearValidators();
    row.controls["indvId"].updateValueAndValidity();
    row.controls["wage"].controls["paymentAmountPerPayCheck"].reset();
    row.controls["wage"].controls["paymentAmountPerPayCheck"].clearValidators();
    row.controls["wage"].controls["paymentAmountPerPayCheck"].updateValueAndValidity()
    row.controls["wage"].controls["paymentPeriod"].reset();
    row.controls["wage"].controls["paymentPeriod"].clearValidators();
    row.controls["wage"].controls["paymentPeriod"].updateValueAndValidity();
  }

  removeEarnedIncomeRow(index: number, row) {
    if (!isEmpty(row.value.indvId)) {
      this.modalService.confirmDelete().subscribe((res) => {
        if (res) {
          if (this.income.controls.length > 0) {
            if (row.controls.recordId.value) {
              const recordId = row.controls.recordId.value;
              const sectionData : Identifier = {
                'individualId': row.controls.indvId.value + "-" + recordId,
                'sectionId': "PRIND"
              }
              this.appService.removeRecordPresumptive(sessionStorage.getItem('appNum'), sectionData, this.frameRequestData('income', recordId)).subscribe(() => {
                if (index === 0) {
                  this.removeIncome(row);
                } else {
                  this.income.removeAt(index);
                }
              })
            } else if (index === 0) {              
              this.removeIncome(row);
            } else {
              this.income.removeAt(index);
            }
          } else {
            row.reset();
          }
        }
      });
    }
  }

  addSelfEmpIncomeRow() {
    push(selfEmpIncomeGroup({
      indvId: null,
      type: null,
      averageMonthlyProfit: null
    }), this.selfEmployment);
  }

  removeSelfIncome(row) {
    row.controls["indvId"].reset();
    row.controls["indvId"].clearValidators();
    row.controls["indvId"].updateValueAndValidity()
    row.controls["averageMonthlyProfit"].reset();
    row.controls["averageMonthlyProfit"].clearValidators();
    row.controls["averageMonthlyProfit"].updateValueAndValidity();
    row.controls["type"].reset();
    row.controls["type"].clearValidators();
    row.controls["type"].updateValueAndValidity()
  };

  removeSelfEmpIncomeRow(index: number, row) {
    if (!isEmpty(row.value.indvId)) {
      this.modalService.confirmDelete().subscribe((res) => {
        if(res) {
          if (this.selfEmployment.controls.length > 0) {
            if (row.controls.recordId.value) {
              const recordId = row.controls.recordId.value;
              const sectionData : Identifier = {
                'individualId': row.controls.indvId.value + "-" + recordId,
                'sectionId': "PRIND"
              }
              this.appService.removeRecordPresumptive(sessionStorage.getItem('appNum'), sectionData, this.frameRequestData('selfEmployment', recordId)).subscribe(() => {
                if (index === 0) {
                  this.removeSelfIncome(row);
                } else {
                  this.selfEmployment.removeAt(index);
                }
              })
            } else if (index === 0) {
              this.removeSelfIncome(row);
            } else {
              this.selfEmployment.removeAt(index);
            }
          } else {
            row.reset();
          }
        }
      });
    }
  }

  addOtherIncomeRow() {
    this.otherIncome.push(this.createOtherIncomeItem());
  }

  createOtherIncomeItem(): FormGroup {
    return this.builder.group({
      indvId: null,
      otherIncomeDetails: this.builder.group({
        paymentFrequency: null,
        otherIncomeTypeCode: null,
        paymentAmount: null,
        alimonyOrderDate: null
      }),
      recordId: null
    });
  }

  removeOtherIncome(row) {
    row.controls["indvId"].reset();
    row.controls["indvId"].clearValidators();
    row.controls["indvId"].updateValueAndValidity()
    row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].reset();
    row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].clearValidators();
    row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].updateValueAndValidity()
    row.controls["otherIncomeDetails"].controls["paymentAmount"].reset();
    row.controls["otherIncomeDetails"].controls["paymentAmount"].clearValidators();
    row.controls["otherIncomeDetails"].controls["paymentAmount"].updateValueAndValidity()
    row.controls["otherIncomeDetails"].controls["paymentFrequency"].reset();
    row.controls["otherIncomeDetails"].controls["paymentFrequency"].clearValidators();
    row.controls["otherIncomeDetails"].controls["paymentFrequency"].updateValueAndValidity()
  }

  removeOtherIncomeRow(index: number, row) {
    if (!isEmpty(row.value.indvId)) {
      this.modalService.confirmDelete().subscribe((res) => {
        if(res) {
          if (this.otherIncome.controls.length > 0) {
            if (row.controls.recordId.value) {
              const recordId = row.controls.recordId.value;
              const sectionData : Identifier = {
                'individualId': row.controls.indvId.value + "-" + recordId,
                'sectionId': "PRIND"
              }
              this.appService.removeRecordPresumptive(sessionStorage.getItem('appNum'), sectionData, this.frameRequestData('otherIncome', recordId)).subscribe(() => {
                if (index === 0) {
                  this.removeOtherIncome(row);
                } else {
                  this.otherIncome.removeAt(index);
                }
              })
            } else if (index === 0) {
              this.removeOtherIncome(row);
            } else {
              this.otherIncome.removeAt(index);
            }
          } else {
            row.reset();
          }
        }
      });
    }
  }

  addTaxDeductionsRow() {
    this.taxDeductionsData.push(this.createTaxDeductionsItem())
  }

  createTaxDeductionsItem(): FormGroup {
    return this.builder.group({
      indvId: null,
      deductionType: null,
      deductionAmount: null,
      alimonyOrderDate: null,
      taxDedSeqNum: null
    });
  }

  onTaxTypeChange(val, row) {
    if (val === 'ALP') {
      this.taxDeductionTypeAlp = true; 
      row.controls['alimonyOrderDate'].setValidators([Vs.validDate, Vs.notBefore120Years, Vs.required]);
      row.controls['alimonyOrderDate'].updateValueAndValidity();
    } else {
      this.taxDeductionTypeAlp = false;
      reset(row.controls['alimonyOrderDate']);
    }
  }

  onOtherIncomeChange(val, row) {
    if (val === 'AL') {
      this.showAlimonyDate = true; 
      row.controls.otherIncomeDetails.controls['alimonyOrderDate'].setValidators([Vs.validDate, Vs.notBefore120Years]);
      row.controls.otherIncomeDetails.controls['alimonyOrderDate'].updateValueAndValidity();
    } else {
      this.showAlimonyDate = false;
      reset(row.controls.otherIncomeDetails.controls['alimonyOrderDate']);
    }
  }

  taxTypeAlp(val) {
    return val && val === 'ALP' ? true : false;
  }

  incomeTypeAlp(val) {
    return val && val === 'AL' ? true : false;
  }

  removeTaxDeductionsRow(index: number, row) {
    if (!isEmpty(row.value.indvId)) {
      this.modalService.confirmDelete().subscribe((res) => {
        if (res) {
          if (this.taxDeductionsData.controls.length > 1) {
            this.taxDeductionsData.removeAt(index);
          } else if (index === 0) {
            row.controls["indvId"].reset();
            row.controls["indvId"].clearValidators();
            row.controls["indvId"].updateValueAndValidity()
            row.controls["deductionAmount"].reset();
            row.controls["deductionAmount"].clearValidators();
            row.controls["deductionAmount"].updateValueAndValidity()
            row.controls["deductionType"].reset();
            row.controls["deductionType"].clearValidators();
            row.controls["deductionType"].updateValueAndValidity()
          } else {
            row.reset();
          }
        }
      });
    }
  }

  onIndividualSelectForEarnedIncome(row){
    row.controls["indvId"].setValidators([Vs.required]);
    row.controls["indvId"].updateValueAndValidity()
    row.controls["wage"].controls["paymentAmountPerPayCheck"].setValidators([Vs.required, Vs.numeric]);
    row.controls["wage"].controls["paymentAmountPerPayCheck"].updateValueAndValidity()
    row.controls["wage"].controls["paymentAmountPerPayCheck"].touched;
    row.controls["wage"].controls["paymentPeriod"].setValidators([Vs.required]);
    row.controls["wage"].controls["paymentPeriod"].updateValueAndValidity();
    row.controls["wage"].controls["paymentPeriod"].touched;
  }

  onPaymentPeriodForEarnedIncome(row) {
    if (row.controls["wage"].controls["paymentPeriod"].value) {
      row.controls["indvId"].setValidators([Vs.required]);
      row.controls["indvId"].updateValueAndValidity()
      row.controls["wage"].controls["paymentAmountPerPayCheck"].setValidators([Vs.required, Vs.numeric]);
      row.controls["wage"].controls["paymentAmountPerPayCheck"].updateValueAndValidity()
    } else if ((row.controls["indvId"].value === null || row.controls["indvId"].value === '') && (row.controls["wage"].controls["paymentAmountPerPayCheck"].value === null || row.controls["wage"].controls["paymentAmountPerPayCheck"].value === '')) {
      row.controls["indvId"].reset();
      row.controls["indvId"].clearValidators();
      row.controls["indvId"].updateValueAndValidity();
      row.controls["wage"].controls["paymentAmountPerPayCheck"].reset();
      row.controls["wage"].controls["paymentAmountPerPayCheck"].clearValidators();
      row.controls["wage"].controls["paymentAmountPerPayCheck"].updateValueAndValidity();
      row.controls["wage"].controls["paymentPeriod"].reset();
      row.controls["wage"].controls["paymentPeriod"].clearValidators();
      row.controls["wage"].controls["paymentPeriod"].updateValueAndValidity();
    }
  }

  onPaymentAmountForEarnedIncome(row) {
    if (row.controls["wage"].controls["paymentAmountPerPayCheck"].value) {
      row.controls["indvId"].setValidators([Vs.required]);
      row.controls["indvId"].updateValueAndValidity()
      row.controls["wage"].controls["paymentPeriod"].setValidators([Vs.required]);
      row.controls["wage"].controls["paymentPeriod"].updateValueAndValidity();
    } else if ((row.controls["indvId"].value === null || row.controls["indvId"].value === '') && (row.controls["wage"].controls["paymentPeriod"].value === null || row.controls["wage"].controls["paymentPeriod"].value === '')) {
      row.controls["indvId"].reset();
      row.controls["indvId"].clearValidators();
      row.controls["indvId"].updateValueAndValidity();
      row.controls["wage"].controls["paymentPeriod"].reset();
      row.controls["wage"].controls["paymentPeriod"].clearValidators();
      row.controls["wage"].controls["paymentPeriod"].updateValueAndValidity();
      row.controls["wage"].controls["paymentAmountPerPayCheck"].reset();
      row.controls["wage"].controls["paymentAmountPerPayCheck"].clearValidators();
      row.controls["wage"].controls["paymentAmountPerPayCheck"].updateValueAndValidity();
    }
  }



  onIndividualSelectForSelfEmployment(row){
    row.controls["indvId"].setValidators([Vs.required]);
    row.controls["indvId"].updateValueAndValidity()
    row.controls["averageMonthlyProfit"].setValidators([Vs.required, Vs.numeric]);
    row.controls["averageMonthlyProfit"].updateValueAndValidity();
    row.controls["averageMonthlyProfit"].touched;
    row.controls["type"].setValidators([Vs.required]);
    row.controls["type"].updateValueAndValidity()
  }

  onTypeForSelfEmployment(row) {
    if (row.controls["type"].value) {
      row.controls["indvId"].setValidators([Vs.required]);
      row.controls["indvId"].updateValueAndValidity()
      row.controls["averageMonthlyProfit"].setValidators([Vs.required, Vs.numeric]);
      row.controls["averageMonthlyProfit"].updateValueAndValidity();
      row.controls["averageMonthlyProfit"].touched;
    } else if ((row.controls["indvId"].value === null || row.controls["indvId"].value === '') && (row.controls["averageMonthlyProfit"].value === null || row.controls["averageMonthlyProfit"].value === '')) {
      row.controls["indvId"].reset();
      row.controls["indvId"].clearValidators();
      row.controls["indvId"].updateValueAndValidity();
      row.controls["averageMonthlyProfit"].reset();
      row.controls["averageMonthlyProfit"].clearValidators();
      row.controls["averageMonthlyProfit"].updateValueAndValidity();
      row.controls["type"].reset();
      row.controls["type"].clearValidators();
      row.controls["type"].updateValueAndValidity();
    }
  }

  onMonthlyProfitForSelfEmployment(row) {
    if (row.controls["averageMonthlyProfit"].value) {
      row.controls["indvId"].setValidators([Vs.required]);
      row.controls["indvId"].updateValueAndValidity()
      row.controls["type"].setValidators([Vs.required]);
      row.controls["type"].updateValueAndValidity()
    } else if ((row.controls["indvId"].value === null || row.controls["indvId"].value === '') && (row.controls["type"].value === null || row.controls["type"].value === '')) {
      row.controls["indvId"].reset();
      row.controls["indvId"].clearValidators();
      row.controls["indvId"].updateValueAndValidity();
      row.controls["averageMonthlyProfit"].reset();
      row.controls["averageMonthlyProfit"].clearValidators();
      row.controls["averageMonthlyProfit"].updateValueAndValidity();
      row.controls["type"].reset();
      row.controls["type"].clearValidators();
      row.controls["type"].updateValueAndValidity();
    }
  }

  onIndividualSelectForOtherIncome(row){
    row.controls["indvId"].setValidators([Vs.required]);
    row.controls["indvId"].updateValueAndValidity()
    row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].setValidators([Vs.required]);
    row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].updateValueAndValidity()
    row.controls["otherIncomeDetails"].controls["paymentAmount"].setValidators([Vs.required, Vs.numeric]);
    row.controls["otherIncomeDetails"].controls["paymentAmount"].updateValueAndValidity()
    row.controls["otherIncomeDetails"].controls["paymentFrequency"].setValidators([Vs.required]);
    row.controls["otherIncomeDetails"].controls["paymentFrequency"].updateValueAndValidity()

  }

  onCodeForOtherIncome(row) {
    if (row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].value) {
      row.controls["indvId"].setValidators([Vs.required]);
      row.controls["indvId"].updateValueAndValidity()
      row.controls["otherIncomeDetails"].controls["paymentAmount"].setValidators([Vs.required, Vs.numeric]);
      row.controls["otherIncomeDetails"].controls["paymentAmount"].updateValueAndValidity()
      row.controls["otherIncomeDetails"].controls["paymentFrequency"].setValidators([Vs.required]);
      row.controls["otherIncomeDetails"].controls["paymentFrequency"].updateValueAndValidity()
    } else if ((row.controls["indvId"].value === null || row.controls["indvId"].value === '') &&
      (row.controls["otherIncomeDetails"].controls["paymentAmount"].value === null || row.controls["otherIncomeDetails"].controls["paymentAmount"].value === '')
      && (row.controls["otherIncomeDetails"].controls["paymentFrequency"].value === null || row.controls["otherIncomeDetails"].controls["paymentFrequency"].value === '')) {
      row.controls["indvId"].reset();
      row.controls["indvId"].clearValidators();
      row.controls["indvId"].updateValueAndValidity();
      row.controls["otherIncomeDetails"].controls["paymentAmount"].reset();
      row.controls["otherIncomeDetails"].controls["paymentAmount"].clearValidators();
      row.controls["otherIncomeDetails"].controls["paymentAmount"].updateValueAndValidity();
      row.controls["otherIncomeDetails"].controls["paymentFrequency"].reset();
      row.controls["otherIncomeDetails"].controls["paymentFrequency"].clearValidators();
      row.controls["otherIncomeDetails"].controls["paymentFrequency"].updateValueAndValidity();
      row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].reset();
      row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].clearValidators();
      row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].updateValueAndValidity();
    }
  }

  onFrequencyForOtherIncome(row) {
    if (row.controls["otherIncomeDetails"].controls["paymentFrequency"].value) {
      row.controls["indvId"].setValidators([Vs.required]);
      row.controls["indvId"].updateValueAndValidity()
      row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].setValidators([Vs.required]);
      row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].updateValueAndValidity()
      row.controls["otherIncomeDetails"].controls["paymentAmount"].setValidators([Vs.required, Vs.numeric]);
      row.controls["otherIncomeDetails"].controls["paymentAmount"].updateValueAndValidity()
    } else if ((row.controls["indvId"].value === null || row.controls["indvId"].value === '') &&
      (row.controls["otherIncomeDetails"].controls["paymentAmount"].value === null || row.controls["otherIncomeDetails"].controls["paymentAmount"].value === '')
      && (row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].value === null || row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].value === '')) {
      row.controls["indvId"].reset();
      row.controls["indvId"].clearValidators();
      row.controls["indvId"].updateValueAndValidity();
      row.controls["otherIncomeDetails"].controls["paymentAmount"].reset();
      row.controls["otherIncomeDetails"].controls["paymentAmount"].clearValidators();
      row.controls["otherIncomeDetails"].controls["paymentAmount"].updateValueAndValidity();
      row.controls["otherIncomeDetails"].controls["paymentFrequency"].reset();
      row.controls["otherIncomeDetails"].controls["paymentFrequency"].clearValidators();
      row.controls["otherIncomeDetails"].controls["paymentFrequency"].updateValueAndValidity();
      row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].reset();
      row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].clearValidators();
      row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].updateValueAndValidity();
    }
  }

  onPaymentAmountForOtherIncome(row) {
    if (row.controls["otherIncomeDetails"].controls["paymentAmount"].value) {
      row.controls["indvId"].setValidators([Vs.required]);
      row.controls["indvId"].updateValueAndValidity()
      row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].setValidators([Vs.required]);
      row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].updateValueAndValidity()
      row.controls["otherIncomeDetails"].controls["paymentFrequency"].setValidators([Vs.required]);
      row.controls["otherIncomeDetails"].controls["paymentFrequency"].updateValueAndValidity()
    } else if ((row.controls["indvId"].value === null || row.controls["indvId"].value === '') &&
      (row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].value === null || row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].value === '')
      && (row.controls["otherIncomeDetails"].controls["paymentFrequency"].value === null || row.controls["otherIncomeDetails"].controls["paymentFrequency"].value === '')) {
      row.controls["indvId"].reset();
      row.controls["indvId"].clearValidators();
      row.controls["indvId"].updateValueAndValidity();
      row.controls["otherIncomeDetails"].controls["paymentAmount"].reset();
      row.controls["otherIncomeDetails"].controls["paymentAmount"].clearValidators();
      row.controls["otherIncomeDetails"].controls["paymentAmount"].updateValueAndValidity();
      row.controls["otherIncomeDetails"].controls["paymentFrequency"].reset();
      row.controls["otherIncomeDetails"].controls["paymentFrequency"].clearValidators();
      row.controls["otherIncomeDetails"].controls["paymentFrequency"].updateValueAndValidity();
      row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].reset();
      row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].clearValidators();
      row.controls["otherIncomeDetails"].controls["otherIncomeTypeCode"].updateValueAndValidity();
    }
  }

  onIndividualSelectForTaxDeduction(row) {
    row.controls["indvId"].setValidators([Vs.required]);
    row.controls["indvId"].updateValueAndValidity()
    row.controls["deductionAmount"].setValidators([Vs.required, Vs.numeric]);
    row.controls["deductionAmount"].updateValueAndValidity()
    row.controls["deductionType"].setValidators([Vs.required]);
    row.controls["deductionType"].updateValueAndValidity()
  }

  onCodeForTaxDeduction(row) {
    if (row.controls["deductionType"].value) {
      row.controls["indvId"].setValidators([Vs.required]);
      row.controls["indvId"].updateValueAndValidity()
      row.controls["deductionAmount"].setValidators([Vs.required]);
      row.controls["deductionAmount"].updateValueAndValidity()
    } else if ((row.controls["indvId"].value === null || row.controls["indvId"].value === '') && (row.controls["deductionAmount"].value === null || row.controls["deductionAmount"].value === '')) {
      row.controls["indvId"].reset();
      row.controls["indvId"].clearValidators();
      row.controls["indvId"].updateValueAndValidity()
      row.controls["deductionAmount"].reset();
      row.controls["deductionAmount"].clearValidators();
      row.controls["deductionAmount"].updateValueAndValidity()
      row.controls["deductionType"].reset();
      row.controls["deductionType"].clearValidators();
      row.controls["deductionType"].updateValueAndValidity()
    }
  }

  onAmountForTaxDeduction(row) {
    if (row.controls["deductionAmount"].value) {
      row.controls["indvId"].setValidators([Vs.required]);
      row.controls["indvId"].updateValueAndValidity()
      row.controls["deductionType"].setValidators([Vs.required]);
      row.controls["deductionType"].updateValueAndValidity()
    } else if ((row.controls["indvId"].value === null || row.controls["indvId"].value === '') && (row.controls["deductionType"].value === null || row.controls["deductionType"].value === '')) {
      row.controls["indvId"].reset();
      row.controls["indvId"].clearValidators();
      row.controls["indvId"].updateValueAndValidity()
      row.controls["deductionAmount"].reset();
      row.controls["deductionAmount"].clearValidators();
      row.controls["deductionAmount"].updateValueAndValidity()
      row.controls["deductionType"].reset();
      row.controls["deductionType"].clearValidators();
      row.controls["deductionType"].updateValueAndValidity()
    }
  }

  frameRequestData(keyArray, recordId){
    const tempObj = {};
    Object.keys(this.record.data).forEach((x) => {
      tempObj[x] = [null];
    })
    delete tempObj[keyArray];
    tempObj[keyArray] = this.record.data[keyArray].filter(x => x.recordId == recordId);
    if(tempObj && tempObj['incDedTaxConsentSw'] && tempObj['incDedTaxConsentSw'].length) {
      tempObj['incDedTaxConsentSw'] = 'N';
    }
    return tempObj;
   }
}
