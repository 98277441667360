import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NudgeStatusService } from "@app/services/fw-nudges/nudge-status.service";

@Component({
  selector: "nh-nudge",
  templateUrl: "./nudge.component.html",
  styleUrls: ["./nudge.component.scss"],
})
export class NudgeComponent {
  @Input() message: string;
  @Input() action: string;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  showNudge: boolean = true;

  constructor(public nudgeStatusService: NudgeStatusService) {
    this.nudgeStatusService.updateStatus("closed");
  }

  closeSnackBar() {
    this.nudgeStatusService.updateStatus("closed");
    this.showNudge = false;
    sessionStorage.setItem('closeNudge', 'true');
    this.close.emit();
  }
}
