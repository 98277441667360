import { compose, when, isNil } from 'ramda';
import { none } from 'ramda';
import { values } from 'ramda';
import { always } from 'ramda';
import { ifElse } from 'ramda';
import { last } from 'ramda';
import { propOr } from 'ramda';

import { allNil } from './null.helpers';
import { disable } from './able.helpers';
import { setValue } from './form.helpers';
import { refValue } from './tables.helpers';
import { codeEqual, codeUnequal } from './code.helpers';

export const isYes = codeEqual('Y');
export const isNo = codeEqual('N');
export const isPn = codeEqual('PN');

const Yes = refValue('Y', 'Yes');
const No = refValue('N', 'No');

export const Y = always(Yes);
export const N = always(No);

export const notYes = codeUnequal('Y');
export const notNo = codeUnequal('N');

export const noYs = compose(
  none(isYes),
  values
);

export const setValueYes = setValue(Yes);
export const setValueNo = setValue(No);

export const disableYes = control => (setValueYes(control), disable(control));
export const disableNo = control => (setValueNo(control), disable(control));

export const isYN = ifElse(Boolean, Y, N);
export const isY = when(Boolean, Y);
export const isN = when(Boolean, N);

export const nilOrYN = ifElse(
  allNil,
  always(null),
  compose(
    isYN,
    last
  )
);

export const nilYN = ifElse(isNil, always(Yes), always(No));

export const hasYN = compose(
  isYN,
  propOr(false)
);

export const yN = p => ifElse(p, Y, N);
