<div *ngIf="isRmbRmc">
  <span>
    {{ staticText }}
  </span>
</div>
<div *ngIf="isRmbRmc && this.selectedIndividual">
  <br>
</div>
<div *ngIf="(getValue('extras.topLevelAnswer') === 'YES') && (this.selectedIndividual)" form-top>
  <h5 class="person-label">{{ selectedIndividual | fullname }}</h5>
</div><br>
<nh-form [topLevelName] = "'ve'" [individuals]="checkForDropdown ? finalListAll : individuals" [formGroup]="getForm()" [mode]="mode" class="label-section"
  topLevelQuestion="{{'VEHICLE_TOPLEVEL_QUESTION' | translate }}"
  popupText="{{'VEHICLE_TOPLEVEL_POPUP' | translate }}"
  individualQuestion="{{'VEHICLE_INDIVIDUAL_QUESTION' | translate }}"
  (validate)="onValidate($event)"
  (individualAnswerChanged)="onIndividualChange($event)">

  <section [formGroup]="getControl('data')">
    <h5 class="section-header">{{'VEHICLE_STATIC_TEXT1' | translate }}</h5>
    <hr>

    <nh-field id = "ve_assetType" label="{{'VEHICLE_STATIC_TEXT2' | translate }} {{ selectedIndividual | fullname }}{{'VEHICLE_STATIC_TEXT3' | translate }} <div class=hint>{{'IF' | translate }} {{ selectedIndividual | fullname }} {{'VEHICLE_STATIC_TEXT4' | translate }}</div>" class="label-section">
      <select nh name="assetType" aria-label="assetType" [items]="tableCodeValue('MPVEHICLETYPE', 'data.assetType')"
        formControlName="assetType"></select>
    </nh-field>

    <nh-field id = "ve_vehicleYear" [mandatory]="false" label="{{'YEAR' | translate }}" class="label-section">
      <input type="text" name="vehicleYear" formControlName="vehicleYear" class="form-control" mask="0*" maxlength="4"
        placeholder="YYYY">
    </nh-field>

    <nh-field id = "ve_vehicleMake" [mandatory]="false" label="{{'VEHICLE_STATIC_TEXT5' | translate }}" class="label-section">
      <input type="text" name="vehicleMake" aria-label="vehicleMake" formControlName="vehicleMake" maxlength="25" class="form-control">
    </nh-field>

    <nh-field id = "ve_vehicleModel" [mandatory]="false" label="{{'VEHICLE_STATIC_TEXT6' | translate }}" class="label-section">
      <input type="text" name="vehicleModel" aria-label="vehicleModel" formControlName="vehicleModel" maxlength="25" class="form-control">
    </nh-field>

        <nh-field id = "ve_vehicleUsage" label="{{'VEHICLE_STATIC_TEXT7' | translate }} {{ selectedIndividual | fullname }} {{'VEHICLE_STATIC_TEXT8' | translate }} {{ getValue('data.assetType').value }}?" class="label-section">
            <select nh name="vehicleUsage" aria-label="vehicleUsage" [items]="tableCodeValue('VEHICLEUSE', 'data.vehicleUsage')" formControlName="vehicleUsage"></select>
        </nh-field>

    <nh-field id = "ve_owedAmount" [mandatory]="false"
      label="{{'VEHICLE_STATIC_TEXT9' | translate }} {{ selectedIndividual | fullname }} {{'VEHICLE_STATIC_TEXT10' | translate }} {{ getValue('data.assetType').value }}? "
      class="label-section">
      <div class=hint>{{'VEHICLE_STATIC_TEXT11' | translate }}</div>
      <nh-input-group prepend="$">
        <input nh type="decimal"  maxlength="7" name="owedAmount" formControlName="owedAmount" class="form-control">
            </nh-input-group>
        </nh-field>

    <nh-field id = "ve_assetAmount" label="{{'VEHICLE_STATIC_TEXT12' | translate }} " class="label-section">
      <div class=hint>{{'VEHICLE_STATIC_TEXT13' | translate }}</div>
      <nh-input-group prepend="$">
        <input nh type="decimal"  maxlength="7" name="assetAmount" formControlName="assetAmount" class="form-control">
            </nh-input-group>
        </nh-field>

    <br>
    <div (window:resize)="onResize($event)"></div>
    <div *ngIf="selectedIndividual">
    <h5 class="section-header">{{'CO-OWENERS_TITLE' | translate }}</h5>
    <hr>
    <nh-field id = "ve_jointOwnership"
      label="{{'VEHICLE_STATIC_TEXT14' | translate }} {{ getValue('data.assetType').value }} {{'VEHICLE_STATIC_TEXT15' | translate }} {{ selectedIndividual | fullname }}? "
      class="label-section">
      <div class=hint>{{'VEHICLE_STATIC_TEXT16' | translate }} {{ selectedIndividual | fullname }} {{'VEHICLE_STATIC_TEXT17' | translate }}</div>
      <select nh name="jointOwnership" aria-label="jointOwnership" [items]="tableCodeValue('YESNO', 'data.jointOwnership')" formControlName="jointOwnership"></select>
    </nh-field>

    <div *ngIf="isY('data.jointOwnership')">
      <nh-co-owner [jointOwnershipInformation]="getControl('data.jointOwnershipInformation')"
        [formData]="getControl('data')" [addRowValid]="checkValid()" [coOwnerIndividuals]="coOwnerIndividuals"
        [error]="error" (emitValidate)="coOwnerValidate($event)" (errorMessage)="getErrorMessage($event)"
        [coOwnerIndivErrorMessage]="coOwnerIndivErrorMessage"
        (removeRow)="removeRow($event)" (addRow)="addRow()"></nh-co-owner>
    </div>
    </div>
  </section>
</nh-form>