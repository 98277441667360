<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title pull-left" id="my-modal-title">{{ 'IDENTITY_PROOFING_TITLE' | translate }}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="section-header">{{'IDENTITY_PROOFNG' | translate}}</h5>
    <hr>
    <p>{{'IDENTITY_PROOFNG_STATIC_TEXT1' | translate}}</p>
    <p>{{'IDENTITY_PROOFNG_STATIC_TEXT3' | translate}}&nbsp;{{this.fullName}}.</p>

    <p *ngIf="saveExit">{{'IDENTITY_PROOFNG_STATIC_TEXT2' | translate}}</p>

    <form id="questionnaire" [formGroup]="answersForm">
      <section *ngFor="let answer of answers.controls; let i = index;" [formGroupName]="i" class="d-flex flex-column flex-md-row">
        <div class="py-2 p-md-2 p-md-0 w-100">
          <nh-field [label]="questions[i].text">
            <select aria-label="questions[i].text" nh name="answer" [items]="questions[i].answers" bindLabel="text" bindValue="answerId" formControlName="answerId"></select>
          </nh-field>
        </div>
      </section>
    </form>
  </div>

  <div class="modal-footer d-block">
    <div class="form-row justify-content-start">
      <div class="col-md-3 order-md-1">
        <button type="button" id="dialog" class="btn btn-block btn-primary" (click)="onConfirm()" [disabled]="answersForm.invalid">{{'SUBMIT' | translate}}</button>
      </div>
      <div class="col-md-3 order-md-2">
        <button type="button" id="dialog" class="btn btn-block btn-link" (click)="onCancel()">{{'CANCEL' | translate}}</button>
      </div>
    </div>
  </div>
</div>
