<div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title pull-left" id="my-modal-title">{{ 'DUPLICATE_TAXFILEROUTSIDE_INSIDE_TITLE' | translate }}</h4>
    </div>
    <div class="modal-body">
      <span [innerHTML]="'DUPLICATE_TAXFILEROUTSIDE_INSIDE_TEXT_01' | translate"></span>
        <span *ngFor="let dupIndv of dupIndvs; let i = index">
  
          <ng-container *ngIf="dupIndvs.length > 1"> {{dupIndv}}         
            <ng-container *ngIf=" i !== dupIndvs.length-1">
              <ng-container *ngIf="i === dupIndvs.length-2">{{ 'DUPLICATE_TAXFILEROUTSIDE_INSIDE_AND' | translate }}
              </ng-container>
              <ng-container *ngIf="i !== dupIndvs.length-2">,
              </ng-container>
            </ng-container>
          </ng-container>
  
          <ng-container *ngIf="dupIndvs.length === 1">
            {{dupIndv}}
          </ng-container>
  
        </span>
      <span *ngIf="section === 'taxdep'" [innerHTML]="'DUPLICATE_TAXFILEROUTSIDE_INSIDE_TEXT_02' | translate"></span>
      <span *ngIf="section === 'jointfiler'" [innerHTML]="'DUPLICATE_JOINTFILER_INSIDE_TEXT_02' | translate"></span>
    </div>
    <div class="modal-footer">
      <div class="mr-2 ml-2">
        <button type="button" id="dialog" class="btn btn-block btn-secondary" (click)="onClosed()">{{
          'CLOSE' | translate }}</button>
      </div>
    </div>
</div>