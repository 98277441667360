<div *ngIf="field?.touched && field?.errors" class="error-messages position-relative">

  <span class="form-text invalid-tooltip d-block">

    <ng-container *ngIf="!hasDateErrors() && field.hasError('bsDate')">
      <ng-container *ngIf="field.getError('bsDate').invalid">
        {{ errorMessage('INVALID_DATE', field.getError('bsDate')) | translate }}
      </ng-container>

      <ng-container *ngIf="field.getError('bsDate').minDate && !this.option">
        <ng-container>
          {{ field.name ==='statusGainDate' || field.name ==='paymentStartDate' || field.name === 'alimonyOrderDate' || 
           field.name === 'medFacilityAdmitDt' || field.name == 'startDate'
          ? (errorMessage('INVALID_DOB', field.getError('bsDate').minDate) | translate)
          : field.name === 'dueDate'
          ? (errorMessage('PAST_DUE_DATE', field.getError('bsDate').minDate) | translate)
          : field.name === 'incomeEndDate' || field.name === 'loseJobEndDate'
          ? (errorMessage('DATE_PAST_THREEMONTHS', field.getError('bsDate').minDate) | translate)
          : field.name === 'nextPregDueDate' || field.name === 'haveBabyDate' || field.name === 'pregEndDate'
          ? (errorMessage('TWELVE_MONTHS_MAX', field.getError('bsDate').minDate) | translate)
          : field.name === 'expDate' || field.name === 'intendToReturnDate'
          ? (errorMessage('INVALID_MAX_DATE', field.getError('bsDate').minDate) | translate)
          : field.name === 'assetTransferDate' || field.name === 'careReceiveDate'
          ? (errorMessage('INVALID_START_DATE', field.getError('bsDate').minDate) | translate)
          : field.name === 'dateOfApplication'
          ? (errorMessage('INVALID_APPLICATION_DATE', field.getError('bsDate').minDate) | translate)
          : field.name === 'dateOfService'
          ? (errorMessage('INVALID_DATE_OF_SERVICE_MIN_DATE', field.getError('bsDate').minDate) | translate)
          : (errorMessage('INVALID_MIN_DATE', field.getError('bsDate').minDate)
          | translate)}}
        </ng-container>
      </ng-container>

      <ng-container *ngIf="field.getError('bsDate').maxDate">
        {{ field.name === 'careReceiveDate' || field.name === 'paymentStartDate' || field.name === 'alimonyOrderDate'
        || field.name === 'dateOfBirth' || field.name === 'birthDate' || field.name === 'statusGainDate'|| field.name
        === 'nextPregDueDate'
        || field.name === 'haveBabyDate' || field.name === 'pregEndDate' || field.name === 'incomeEndDate'
        || field.name === 'assetTransferDate'
        ? (errorMessage('INVALID_FUTURE_DATE', field.getError('bsDate').maxDate) | translate)
        : field.name === 'dueDate'
        ? (errorMessage('MAX_DATE_DUE', field.getError('bsDate').maxDate) | translate)
        : field.name === 'dobDate'
        ? (errorMessage('INVALID_MIN_DATE', field.getError('bsDate').maxDate) | translate)
        : (errorMessage('INVALID_FUTURE_DATE', field.getError('bsDate').maxDate) | translate)
        }}
      </ng-container>
      <ng-container
        *ngIf="field.getError('bsDate').minDate && this.option && this.option.errorMsg && this.option.errorMsg.INVALID_START_DATE">
        <ng-container>{{errorMessage('INVALID_START_DATE', field.getError('bsDate').minDate) | translate}}
        </ng-container>
      </ng-container>

    </ng-container>

    <ng-container *ngFor="let errorCode of errorCodes">
      <ng-container *ngIf="field.hasError(errorCode)">
        {{ errorMessage(errorCode, field.getError(errorCode)) | translate }}
        <ng-container *ngIf="errorCode === 'minlength'">
          {{ errorMessage('minlength_count', field.getError('minlength')) }}
          {{ errorMessage('minlength_count_character', field.getError('minlength')) | translate }}
        </ng-container>
        <ng-container *ngIf="errorCode === 'maxlength'">
          {{ errorMessage('maxlength_count', field.getError('maxlength')) }}
          {{ errorMessage('minlength_count_character', field.getError('maxlength')) | translate }}
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="field.hasError('EQUITY_VALUE')">
      {{ errorMessage('EQUITY_VALUE', field.getError('EQUITY_VALUE')) | translate }}
    </ng-container>
  </span>

</div>