import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'nh-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverComponent implements OnInit {
  @Input() isOpen = false;
  @Input() content = '';
  @Input() container = 'body';
  @Input() placement = 'top';
  @Input() customClass = '';
  @Input() iconMode = 'default';
  @Output() change = new EventEmitter();

  containerClass = 'popover-tooltip';

  onChange(isOpen: boolean) {
    this.isOpen = isOpen;
    this.change.emit(this.isOpen);
  }
  ngOnInit() {
    if (this.customClass) {
      this.containerClass += ' ' + this.customClass;
    }
  }
}
