import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@app/gateway/services/api.service';
import { minus120Years, toISO } from '@app/helpers/date.helpers';
import Vs from '@app/services/validators/validators.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'nh-submission-search-form',
  templateUrl: './submission-search-form.component.html',
  styleUrls: ['./submission-search-form.component.scss']
})

export class ProviderSubmissionSearchFormComponent implements OnInit {
  searchForm!: FormGroup;
  minDate = minus120Years();
  maxDate = new Date();
  results: any[] = [];
  typeApp = 'Number';
  showApp = false;

  submissionStatusArr = [];

  submissionTypes = [];
  submission = null;
  sup = false;
  kepro = false;

  constructor(private apiService: ApiService, private router: Router) {
    if (sessionStorage.getItem('SUP') === 'true') this.sup = true;
    if (sessionStorage.getItem('KPU') === 'true') this.kepro = true;
    const appType = 'publ/reference-tables/retrieve?table=APPTYPE';
    this.apiService.get(appType).subscribe((res) => {
      this.submissionTypes = [...res.columnDetails]
    })
    const appStatus = 'publ/reference-tables/retrieve?table=APPSTATUS';
    this.apiService.get(appStatus).subscribe((res) => {
      res.columnDetails.forEach(col => col['value'] = col.enDescription);
      this.submissionStatusArr = [...res.columnDetails]
    })
  }

  ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search)
    if (!sessionStorage.getItem('tokenId') && !urlParams.get('mpToken')) {
      this.router.navigateByUrl('/logout');
      return;
    }
    this.searchForm = new FormGroup({
      firstName: new FormControl('', [Vs.firsName]),
      middleIntial: new FormControl('', [Vs.invalidField]),
      lastName: new FormControl('', [Vs.lastName]),
      ssn: new FormControl('', [Vs.ssn]),
      subStatus: new FormControl(''),
      subType: new FormControl(''),
      appChangeRenewReass: new FormControl('', [Vs.alphaNumeric]),
      userName: new FormControl('', [Vs.orgName]),
      orgName: new FormControl('', [Vs.orgName]),
      subBeginDate: new FormControl('', [Vs.validDate]),
      subEndDate: new FormControl('', [Vs.validDate])
    })
  }

  subTypeChange() {
    if (this.searchForm.value.subType !== null) {
      this.submission =  this.searchForm.value.subType.code === 'AD' ? this.searchForm.value.subType : null
      this.showApp = true;
      const code = this.searchForm.value.subType.code;
      switch (code) {
        case 'HPE':
        case 'BCC':
        case 'AD':
        case 'PRG':
        case 'FMA':
        case 'ADF':
        case 'NBN':
        case 'AFB':
          this.typeApp = 'Application Number';
          break;
        case 'RMC':
          this.typeApp = 'Change Report Number';
          break;
        case 'RMB':
          this.typeApp = 'Renewal Number';
          break
        case 'LOC':
          this.typeApp = 'Reassessment Number';
          break 
        case 'BCE':
          this.typeApp = 'Extension Number';
          break;
        case 'IME':
          this.typeApp = 'Expense Number';
          break;
        case 'PRT':
          this.typeApp = 'Questions for Review Number';
          break;
      }
      this.searchForm.controls.appChangeRenewReass.setValue('');
      this.searchForm.controls.appChangeRenewReass.setValidators([Vs.required, Vs.alphaNumeric]);
      this.searchForm.controls.appChangeRenewReass.updateValueAndValidity();
    } else {
      this.showApp = false;
      this.submission = null;
      this.searchForm.controls.appChangeRenewReass.clearValidators();
      this.searchForm.controls.appChangeRenewReass.updateValueAndValidity();
    }
  }

  onSubmit() {
    const wpSwitch = sessionStorage.getItem('isKepro') === 'true';
    const loginUser = sessionStorage.getItem('userName');
    const mtlToken = sessionStorage.getItem('mtlToken')? sessionStorage.getItem('mtlToken') : "";
    const { firstName, middleIntial, lastName, ssn, subStatus, subType, appChangeRenewReass, userName, orgName, subBeginDate, subEndDate } = this.searchForm.value;
    const payload = {
      mtlToken, firstName, middleIntial, lastName, ssn, appChangeRenewReass: subType ? appChangeRenewReass: null, userName: userName !== "" ? userName : loginUser, orgName, subBeginDate: subBeginDate ? toISO(subBeginDate) : subBeginDate, subEndDate: subEndDate? toISO(subEndDate): subEndDate, subStatus: subStatus?.code, subType: subType ? subType.code : null, userType: wpSwitch ? 'kepro' : null
    }
    if (this.kepro) payload['kpuUser'] = 'Y';
    if (this.sup) payload['superUser'] = 'Y';

    this.apiService.post(`publ/searchsubmisson`, payload).subscribe((res: any) => {
      if (res) {
        this.results = res;
      }
    })
  }

  onPrevious() {
    this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
  }

  reload () {
    this.onSubmit();
  }

  isInvalidDate(event) {
    const enteredDate = event.target.value;
    if ( !enteredDate || enteredDate === '' || enteredDate === 'Invalid date' ) {
      event.target.value = enteredDate;
    }
    else if (event.target.name === 'subBeginDate') {
      event.target.value = formatDate(this.searchForm.value.subBeginDate , 'MM/dd/yyyy' , 'en');
    }
    else if (event.target.name === 'subEndDate') {
      event.target.value = formatDate(this.searchForm.value.subEndDate , 'MM/dd/yyyy' , 'en');
    }
  }
}
