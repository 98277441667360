import { propOr } from 'ramda';

import { CommentsComponent } from './comments.component';

import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import commentsLabels from './comments-labels.en';

const formValidation = (builder, recordData) => {
  return {
    data: builder.group({ comments: [propOr('', 'comments', recordData), [Vs.comments]] }),
  };
};

export default sectionConfiguration(CommentsComponent, formValidation, [], [], {...{},...commentsLabels}, {
  hasSummary: false,
  showComments: false,
});
