import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { identity } from 'ramda';

import { ApplicationState } from '@app/state/app/app.state';

import { updateApp, getApp, loadApp, startApp, loadCongrats } from '@app/state/app/app.creators';
import { loadComments, updateComments, setIndividuals } from '@app/state/app/app.creators';
import { sectionInProgress } from '@app/state/app/app.selectors';
import { nextSectionId } from '@app/state/section/section.selectors';

import { viewComments, viewApp } from '@app/helpers/lenses.helpers';
import { applyMap } from '@app/helpers/observable.helpers';

import { Records } from '@app/models/record.model';
import { SectionId } from '@app/models/section.model';
import { Individuals } from '@app/models/individual.model';
import { ApplicationStateModel, StateModel } from '@app/models/state.model';
import { ApplicationNumber, Comments, Programs, Mode, CaseNumber } from '@app/models/app.model';
import { getDataValue } from '@app/helpers/object.helpers';
import { StateResetAll } from 'ngxs-reset-plugin';

@Injectable()
export class ApplicationFacade {
  @Select(ApplicationState.applicationNumber) applicationNumber$: Observable<ApplicationNumber>;
  @Select(ApplicationState.application) application$: Observable<ApplicationStateModel>;
  @Select(ApplicationState.individuals) individuals$: Observable<Individuals>;
  @Select(ApplicationState.programs) programs$: Observable<Programs>;
  @Select(ApplicationState.comments) comments$: Observable<Comments>;
  @Select(ApplicationState.congrats) congrats$: Observable<Comments>;

  @Select(ApplicationState.tableCodes) tableCodes$: Observable<any>;

  constructor(protected store: Store, protected router: Router) {}

  tableCodes(sectionId: SectionId): Observable<any> {
    return this.tableCodes$.pipe(applyMap(sectionId));
  }

  loadApp(applicationNumber: ApplicationNumber) {
    return this.store.dispatch(loadApp(applicationNumber));
  }

  resetAll () {
    return this.store.dispatch(
      new StateResetAll()
    )
  }

  startApp(mode: Mode, caseNumber: CaseNumber) {
    this.store.dispatch(startApp(mode, caseNumber));
  }

  getApp(applicationNumber: ApplicationNumber) {
    return this.store.dispatch(getApp(applicationNumber));
  }

  setIndividuals(records: Records) {
    this.store.dispatch(setIndividuals({ records }));
  }

  updateApp(applicationNumber: ApplicationNumber, incompleteSection: SectionId | null) {
    return this.store.dispatch(updateApp({ applicationNumber, partialSubmission: true, incompleteSection }));
  }

  loadComments(applicationNumber: ApplicationNumber) {
    return this.store.dispatch(loadComments(applicationNumber)).pipe(map(viewComments));
  }

  updateComments(applicationNumber: ApplicationNumber, comments: Comments) {
    return this.store.dispatch(updateComments({ applicationNumber, comments }));
  }

  loadCongrats(applicationNumber: ApplicationNumber) {
    return this.store.dispatch(loadCongrats(applicationNumber));
  }

  navigate(applicationNumber: ApplicationNumber, sectionId) {
    if (sectionId === 'SUCFM') {
      return this.router.navigate(['congrats/' + applicationNumber]);
    } else if (sectionId === 'PRHAS') {
      const appType = sessionStorage.getItem('appType')
      return this.router.navigate(['/application/' + applicationNumber + '/section/' + sectionId], { queryParams: { type: appType } });
    } else {
      return this.router.navigate(['/application/' + applicationNumber + '/section/' + sectionId]);
    }
  }

  getStore(): Observable<StateModel> {
    return this.store.selectOnce(identity).pipe(map(viewApp));
  }

  toNextSection({ applicationNumber, section, modules, status }) {
    const sectionId = getDataValue('identifier', section);
    // The following is for exception in HHSUR section, because HHSUR only exist one time for people verification
    // then removed from api modules, so UI has to set current section as HHAPL to go to the next section.
    if (sectionId === 'HHSUR') {
      section['identifier'] = 'HHAPL';
    }
    if (sectionId === 'APSUM') {
      sessionStorage.removeItem('appealNum')
      status = 'SUBMITTED';
    }
    return this.navigate(applicationNumber, nextSectionId({ section, modules, status }));
  }

  toInProgress({ applicationNumber, modules }) {
    return this.navigate(applicationNumber, sectionInProgress(modules));
  }

  nextSection() {
    return this.getStore().pipe(tap(app => this.toNextSection(app)));
  }

  inProgress() {
    return this.getStore().pipe(
      tap(app => {
        return this.toInProgress(app);
      })
    );
  }
}
