import { State, Action, Selector } from '@ngxs/store';

import { map, catchError, switchMap } from 'rxjs/operators';

import { ApplicationService } from '@app/services/application/application.service';
import { PeopleState } from '@app/state/people/people.state';

import { SaveQuestionnaire, HandlePayload, UpdatePeopleSumSection } from './section.actions';
import { SetSection, LoadSection, UpdateSection } from './section.actions';
import { CreateRecord, UpdateRecord, RemoveRecord, RemoveDataRecord, SetRecord } from './section.actions';

import { toTables } from '@app/state/shared/shared.selectors';
import { updateRecords } from '@app/state/shared/shared.selectors';

import { nonePayload, setPayload, isPartialPayload, setBlock } from '@app/helpers/payload.helpers';

import { Tables } from '@app/models/tables.model';
import { Records } from '@app/models/record.model';
import { SectionStateModel } from '@app/models/state.model';
import { TopLevelAnswer, SectionId } from '@app/models/section.model';
import { Injectable } from '@angular/core';

export const sectionState: SectionStateModel = {
  identifier: null,
  topLevelAnswer: TopLevelAnswer.EMPTY,
  records: [],
  earnedIncome: true,
  showInternet: true,
  showInternetInclude: true,
  showNewActivities: true,
  tables: {},
  payload: nonePayload(),
};

@State<SectionStateModel>({
  name: 'section',
  defaults: sectionState,
  children: [PeopleState],
})

@Injectable()
export class SectionState {
  constructor(private application: ApplicationService) {}

  @Selector()
  static identifier({ identifier }: SectionStateModel): SectionId | null {
    return identifier;
  }

  @Selector()
  static topLevelAnswer({ topLevelAnswer }: SectionStateModel): TopLevelAnswer {
    return topLevelAnswer;
  }

  @Selector()
  static tables({ tables }: SectionStateModel): Tables {
    return tables;
  }

  @Selector()
  static records({ records }: SectionStateModel): Records {
    return records;
  }

  @Selector()
  static earnedIncome({ earnedIncome }: SectionStateModel): boolean {
    return earnedIncome;
  }

  @Selector()
  static showInternet({ showInternet }: SectionStateModel): boolean {
    return showInternet;
  }

  @Selector()
  static showInternetInclude({ showInternetInclude }: SectionStateModel): boolean {
    return showInternetInclude;
  }

  @Selector()
  static showNewActivities({ showNewActivities }: SectionStateModel): boolean {
    return showNewActivities;
  }

  @Action(SetSection)
  setSection({ patchState }, { payload }) {
    patchState(payload);
  }

  @Action(SetRecord)
  setRecord({ getState, patchState }, { payload }) {
    patchState({ records: updateRecords(payload, getState().records) });
  }

  @Action(LoadSection)
  loadSection({ getState, patchState }, { payload: { applicationNumber, sectionId, tableCodes } }) {
    return this.application.loadSection(applicationNumber, sectionId, tableCodes).pipe(
      switchMap(([section, tables]) => [
        patchState(section), // Update section
        patchState({ tables: toTables(getState().tables, tables) }), // Keep previously loaded tables
      ])
    );
  }

  @Action(UpdateSection)
  updateSection(_, { payload: { applicationNumber, sectionId, topLevelAnswer } }) {
    return this.application.updateSection(applicationNumber, sectionId, topLevelAnswer);
  }

  @Action(UpdatePeopleSumSection)
  updatePeopleSumSection(_, { payload: { applicationNumber, sectionId, answer } }) {
    return this.application.updatePeopleSumSection(applicationNumber, sectionId, answer);
  }

  @Action(CreateRecord)
  createRecord({ dispatch }, { payload: { applicationNumber, record, isPartial, topLevelAnswer } }) {
    return this.application.createRecord(applicationNumber, record, topLevelAnswer).pipe(
      catchError(() => isPartialPayload({ applicationNumber, record, isPartial })),
      map(({ payload, recordId }) => dispatch([setPayload(payload), setBlock(record.data, recordId)]))
    );
  }

  @Action(UpdateRecord)
  updateRecord({ dispatch }, { payload: { applicationNumber, record, isPartial } }) {
    return this.application.updateRecord(applicationNumber, record).pipe(
      catchError(() => isPartialPayload({ applicationNumber, record, isPartial })),
      map(({ payload }) => dispatch(setPayload(payload)))
    );
  }

  @Action(RemoveRecord)
  removeRecord(_, { payload: { applicationNumber, identifier } }) {
    return this.application.removeRecord(applicationNumber, identifier);
  }

  @Action(RemoveDataRecord)
  removeDataRecord(_, { payload: { applicationNumber, identifier, record, exportingStatus } }) {
    return this.application.removeDataRecord(applicationNumber, identifier, record, exportingStatus);
  }

  @Action(HandlePayload)
  handlePayload({ patchState }, { payload }) {
    return patchState({ payload });
  }

  @Action(SaveQuestionnaire)
  saveQuestionnaire({ dispatch }, { payload: { applicationNumber, verification } }) {
    return this.application.saveQuestionnaire(applicationNumber, verification).pipe(
      map(payload => dispatch(setPayload({ payload }))) //
    );
  }
}
