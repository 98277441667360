<nh-form [individuals]="individuals" [formGroup]="getForm()" (validate)="onValidate($event)">
  <div class="m-2 before-submit" [formGroup]="getControl('data')">
    <h5 class="section-header">{{'BEFORE_SUBMIT_STATIC_TEXT18' | translate }}</h5>
    <hr>
    <div>
      <p class="static-text" *ngIf="hideHealthPlanText" [innerHTML]="'BEFORE_SUBMIT_STATIC_TEXT19' | translate"></p>
      <p class="static-text" *ngIf="showHealthPlanText" [innerHTML]="'BEFORE_SUBMIT_STATIC_TEXT19_01' | translate"></p>
      <div class="row" style="margin-bottom: 10px;">
        <div class="col-md-5">
          <label for="healthPlanMCO" class="label-section">{{'BEFORE_SUBMIT_STATIC_TEXT20' | translate }}</label>
          <select *ngIf="hideHealthPlanText" id = "ch_healthPlan"  nh name="healthPlan" aria-label="healthPlan" [items]="tableCodeValue('MCONAME', 'data.healthPlan')"
            formControlName="healthPlan"></select>
            <select *ngIf="showHealthPlanText" id = "ch_healthPlan"  nh name="healthPlan" aria-label="healthPlan" [items]="tableCodeValue('MCONAME_UBW', 'data.healthPlan')"
            formControlName="healthPlan"></select>
        </div>
        <div col="col-md-6" style="margin-top: 17px;">
          <button (click)="openHealthPlansModal()" class="btn healthplan-more-info">{{'MOREINFO_BUTTON_TEXT' | translate }}</button>
        </div>
      </div>

      <p class="static-text" [innerHTML]="'BEFORE_SUBMIT_STATIC_TEXT21' | translate"></p>
      <p class="static-text" [innerHTML]="'BEFORE_SUBMIT_STATIC_TEXT23' | translate"></p>
    </div>
  </div>
</nh-form>