import { ApplicationNumber, Mode, CaseNumber } from '@app/models/app.model';
import { Records } from '@app/models/record.model';

import { AppUpdate, CommentUpdate } from '@app/models/state.model';

export class LoadApp {
  static readonly type = '[APP] Load Application';
  constructor(public payload: ApplicationNumber) {}
}

export class StartApp {
  static readonly type = '[APP] Start Application';
  constructor(public mode: Mode, public caseNumber: CaseNumber) {}
}

export class UpdateApp {
  static readonly type = '[APP] Update Application';
  constructor(public payload: AppUpdate) {}
}

export class GetApp {
  static readonly type = '[APP] Get Application';
  constructor(public payload: ApplicationNumber) {}
}

export class LoadComments {
  static readonly type = '[APP] Load Comments';
  constructor(public payload: ApplicationNumber) {}
}

export class UpdateComments {
  static readonly type = '[APP] Update Comments';
  constructor(public payload: CommentUpdate) {}
}

export class SetIndividuals {
  static readonly type = '[APP] Set Individuals';
  constructor(public payload: Records) {}
}

export class LoadCongrats {
  static readonly type = '[APP] Load Congrats';
  constructor(public applicationNumber: ApplicationNumber) {}
}
