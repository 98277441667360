import { Component, AfterContentInit, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { inkindSupportGroup } from '@app/helpers/models.helpers';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { push } from '@app/helpers/form-array.helpers';
import { ModalService } from '@app/services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { eng_labels, sp_labels } from './other-income-labels';
import { swapControl, swapControls, toggles } from '@app/helpers/forms.helpers';
import { isYes } from '@app/helpers/tayn.helpers';
import { minus3Months1stDay } from '@app/helpers/date.helpers';
import { isRmbRmc } from '@app/helpers/mode.helpers';
import { formatTableData } from '@app/helpers/tables.helpers';
import { Individual } from '@app/models/individual.model';
import { ServiceConfigService } from '@app/services/service-config.service';
import { codeEqual } from '@app/helpers/code.helpers';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './other-income.component.html',
  styleUrls: ['./other-income.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherIncomeComponent extends SectionFormDirective implements AfterContentInit {
  today = new Date();
  isPaymentFrequencyDisabled = false;
  disableAll: boolean;
  disableOtherIncome: any = null;
  filterOtherIncomeTypes: any = [];
  otherIncomeType: string;
  selectedOtherIncomeType: any = {};
  topLevelQuestionPregnantOrChild;
  topLevelQuestionNonPregnantOrChild
  topLevelQuestionNonPregnantOrChildEn = 'Is anyone in your household receiving or will receive (in the next 30 days) any other kind of income we have not already asked about?';
  topLevelQuestionNonPregnantOrChildSp = '¿Hay alguien en su hogar que recibe o recibirá (en los próximos 30 días) algún otro tipo de ingresos sobre los que todavía no hemos preguntado?';
  topLevelQuestionPregnantOrChildEn = 'Is anyone in your household receiving or will receive (in the next 30 days) any other kind of income we have not already asked about?  Or did they receive any other income in the last 3 months?';
  topLevelQuestionPregnantOrChildSp = '¿Hay alguien en su hogar que recibe o recibirá (en los próximos 30 días) algún otro tipo de ingresos sobre los que todavía no hemos preguntado?  O bien, ¿recibió algún otro ingreso en los últimos 3 meses?';
  conditionalTopLevelQuestion: string;
  conditionalTopLevelTooltip: string;
  toolTip = 'You should include all of the people who live in the same home as this person even if they are not applying for benefits';
  minDate = new Date("1990-01-01T00:00:00.000-06:00");
  minimumcomeEndDate = minus3Months1stDay();
  amountPerMonthLabel = { label: 'Amount for Food and Housing Each Month' };
  amountPaidByInHomeLabel = { label: 'Amount Paid by People in the Home' };
  amountPaidByOutHomeLabel = { label: 'Amount Paid by People Outside the Home' };
  individualQuestion;
  individualQuestionEn = "Who earns the other income? <div class=hint>If more than one person in your household has other income, you can add another person (and their other income) after you complete this page and click <b>Next</b>.</div>";
  individualQuestionSp = "¿Quién gana los otros ingresos? <div class=hint>Si más de una persona del hogar tiene otros ingresos, puede agregar a otra persona (y sus ingresos) después de completar esta página y hacer clic en <b>Siguiente</b>.</div> ";
  otherIncomeTypeCodeHint;
  otherIncomeTypeCodeHintNext;
  otherIncomeTypeCodeHintEn = "has more than one kind of other income, you can add another source of income after you complete this page and click ";
  otherIncomeTypeCodeHintNextEn = "Next.";
  otherIncomeTypeCodeHintSp = "tiene más de un tipo de otros ingresos, puede agregar otra fuente de ingresos después de completar esta página y hacer clic en ";
  otherIncomeTypeCodeHintNextSp = "Siguiente.";
  otherIncomeTypeSSI;
  otherIncomeTypeSSIEn = "If you are a pregnant woman, child, or caretaker of a minor child, you do not need to tell us about SSI (Supplementary Security Income), Veteran’s benefits, or Child Support income.";
  otherIncomeTypeSSISp = "Si es una mujer embarazada, un niño o está a cargo de un niño menor de edad, no necesita informarnos sobre el SSI (Seguridad de Ingreso Suplementario), los beneficios para veteranos ni los ingresos de manutención infantil.";
  isDesktop = true;
  language = 'en';
  labels = { ...eng_labels };
  isRmbRmc = false;
  staticText;
  staticTextEn = "We'll show you the information you have on file for your case below."
  staticTextSp = "Abajo le mostraremos la información que tenemos en nuestros expedientes para su caso."
  translatedLang;
  defaultLanguage;
  isCovidAlertMsgEnable: boolean = false;
  isFinReassessment = false;

  get inkindSupportList(): FormArray {
    return this.getControl('data.inKindData.inkindSupportList') as FormArray;
  }

  constructor(private titleService: Title, private modalService: ModalService, builder: FormBuilder, public translateService: TranslateService, public serverConfig: ServiceConfigService) {
    super(builder);
    this.isDesktop = window.innerWidth >= 768 ? true : false;
    this.titleService.setTitle('Other Income');
  }

  addRow() {
    push(inkindSupportGroup({
      amountPerMonth: null,
      expenseType: null,
      amountPaidByInHome: null,
      amountPaidByOutHome: null,
    }), this.inkindSupportList);
  }

  removeRow(index: number, row: FormGroup) {
    this.modalService.confirmDelete().subscribe((res) => {
      if (res) {
        if (this.inkindSupportList.controls.length > 1) {
          this.inkindSupportList.removeAt(index);
        } else {
          row.reset();
        }
      }
    });
  }
  onSomeOnePayFoodMonthlyChange() {
    this.validateInkindSupportList();
  }
  onSomeOnePayLivesMonthlyChange() {
    this.validateInkindSupportList();
  }

  validateInkindSupportList() {
    if (this.isY('data.inKindData.someOnePayFoodMonthly') || this.isY('data.inKindData.someOnePayLivesMonthly')) {
      this.inkindSupportList.enable();
      this.getControl('data.inKindData.liveWithOtherAdults').enable();
      this.getControl('data.inKindData.paidHouseholdExpenseAmt').enable();
      this.getControl('data.inKindData.howManyPeopleLive').enable();
    } else {
      this.inkindSupportList.disable();
      this.getControl('data.inKindData.liveWithOtherAdults').disable();
      this.getControl('data.inKindData.paidHouseholdExpenseAmt').disable();
      this.getControl('data.inKindData.howManyPeopleLive').disable();
    }
  }

  onOtherIncomeTypeCodeChange() {
    const otherIncomeDetails = this.getControl('data.otherIncomeDetails');
    this.selectedOtherIncomeType = this.getValue('data.otherIncomeDetails.otherIncomeTypeCode');
    const socialSecurityCodes = ['SSN'];
    const veteranCodes = ['VA'];
    const alimonyCodes = ['AL'];
    const ssi = ['SI']
    Object.keys(otherIncomeDetails['controls']).forEach(key => {
      if (key !== 'incomeEndSw' && key !== 'incomeEndDate') {
        otherIncomeDetails['controls'][key].disable();
      }
    });
    this.getControl('data.otherIncomeDetails.paymentStartDate').enable();
    this.getControl('data.otherIncomeDetails.otherIncomeTypeCode').enable();
    this.getControl('data.otherIncomeDetails.paymentFrequency').enable();
    this.getControl('data.otherIncomeDetails.paymentAmount').enable();
    this.isPaymentFrequencyDisabled = false;
    if (socialSecurityCodes.find(str => str === this.selectedOtherIncomeType.code)) {
      this.otherIncomeType = 'SOCIAL_SECURITY';
      this.getControl('data.otherIncomeDetails.socialSecurityIncomeTypeCode').enable();
    } else if (veteranCodes.find(str => str === this.selectedOtherIncomeType.code)) {
      this.otherIncomeType = 'VETERAN';
      const veteranBenefitTypeCode = this.getControl('data.otherIncomeDetails.veteranBenefitTypeCode');
      veteranBenefitTypeCode.enable();
    } else if (alimonyCodes.find(str => str === this.selectedOtherIncomeType.code)) {
      this.otherIncomeType = 'ALIMONY';
      this.getControl('data.otherIncomeDetails.alimonyOrderDate').enable();
    } else if (ssi.find(str => str === this.selectedOtherIncomeType.code)) {
      this.otherIncomeType = null;
      this.isPaymentFrequencyDisabled = true;
      this.getControl('data.otherIncomeDetails.paymentFrequency').patchValue({ code: "MO", spValue: "Mensual", value: "Monthly" });
    } else {
      this.otherIncomeType = null;
    }
  }

  validateOtherIncomeDetails() {

  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
    if (language === 'en') {
      this.labels = { ...eng_labels };
    } else {
      this.labels = { ...sp_labels }
    }
  }

  tableCodeValue(tableName, tableControl) {
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }

  ngAfterContentInit() {
    this.isRmbRmc = isRmbRmc(this.mode);
    this.isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);
    this.isCovidAlertMsgEnable = this.serverConfig.getServerConfig()['COVID_MSG_FLAG']
    if (this.defaultLanguage !== 'en') {
      this.topLevelQuestionPregnantOrChild = this.topLevelQuestionPregnantOrChildSp
      this.topLevelQuestionNonPregnantOrChild = this.topLevelQuestionNonPregnantOrChildSp
      this.individualQuestion = this.individualQuestionSp;
      this.otherIncomeTypeCodeHint = this.otherIncomeTypeCodeHintSp;
      this.otherIncomeTypeCodeHintNext = this.otherIncomeTypeCodeHintNextSp;
      this.otherIncomeTypeSSI = this.otherIncomeTypeSSISp;
      this.staticText = this.staticTextSp;
    } else {
      this.topLevelQuestionPregnantOrChild = this.topLevelQuestionPregnantOrChildEn
      this.topLevelQuestionNonPregnantOrChild = this.topLevelQuestionNonPregnantOrChildEn
      this.individualQuestion = this.individualQuestionEn;
      this.otherIncomeTypeCodeHint = this.otherIncomeTypeCodeHintEn;
      this.otherIncomeTypeCodeHintNext = this.otherIncomeTypeCodeHintNextEn;
      this.otherIncomeTypeSSI = this.otherIncomeTypeSSIEn;
      this.staticText = this.staticTextEn;
    }
    const otherIncomeDetails = this.getControl('data.otherIncomeDetails');
    const incomeEndSw = otherIncomeDetails.get('incomeEndSw');
    const incomeEndDate = otherIncomeDetails.get('incomeEndDate');
    this.setGatePostQuestion();

    this.getControl('extras.individual').valueChanges.subscribe((individual: Individual) => {
      let isChildOrPregnantAndMovedIn = false;
      if (individual.age < 21 || (individual.pregnant && individual.gender['code'] === 'F')) {
        if (individual.someoneMovedIn === 'Y') {
          isChildOrPregnantAndMovedIn = true;
        }
      }
      const appType = sessionStorage.getItem('appType');
      if (isChildOrPregnantAndMovedIn && (appType === 'RC' || appType === 'RB')) {
        this.getControl('data.otherIncomeDetails.incomeEndSw').enable();
      } else if (isChildOrPregnantAndMovedIn === false && (appType === 'RC' || appType === 'RB')) {
        this.getControl('data.otherIncomeDetails.incomeEndSw').disable();
        this.getControl('data.otherIncomeDetails.incomeEndDate').disable();
      }
    });

    this.onOtherIncomeTypeCodeChange();
    this.validateInkindSupportList();

    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.languageCheck(res.lang);
      if (res.lang === 'en') {
        this.topLevelQuestionPregnantOrChild = this.topLevelQuestionPregnantOrChildEn
        this.topLevelQuestionNonPregnantOrChild = this.topLevelQuestionNonPregnantOrChildEn
        this.individualQuestion = this.individualQuestionEn;
        this.otherIncomeTypeCodeHint = this.otherIncomeTypeCodeHintEn;
        this.otherIncomeTypeCodeHintNext = this.otherIncomeTypeCodeHintNextEn;
        this.otherIncomeTypeSSI = this.otherIncomeTypeSSIEn;
        this.staticText = this.staticTextEn;
        this.setGatePostQuestion();
      } else {
        this.topLevelQuestionPregnantOrChild = this.topLevelQuestionPregnantOrChildSp
        this.topLevelQuestionNonPregnantOrChild = this.topLevelQuestionNonPregnantOrChildSp
        this.individualQuestion = this.individualQuestionSp;
        this.otherIncomeTypeCodeHint = this.otherIncomeTypeCodeHintSp;
        this.otherIncomeTypeCodeHintNext = this.otherIncomeTypeCodeHintNextSp;
        this.otherIncomeTypeSSI = this.otherIncomeTypeSSISp;
        this.staticText = this.staticTextSp;
        this.setGatePostQuestion();
      }
    });
    this.registerToggle(
      incomeEndSw,
      swapControls([incomeEndDate], isYes)
    );
    const otherIncomeTypeControl = this.getControl('data.otherIncomeDetails.otherIncomeTypeCode');
    const inKindControlGroup = this.getControl('data.inKindData');

    if (otherIncomeTypeControl?.value?.code === 'OT') {
      const { UNEARNEDINCOMETYPE_PP } = this.tables;
      UNEARNEDINCOMETYPE_PP.push({ code: "OT", description: "Other Income", rank: "14", spDescription: "Otros Ingresos", value: "Other Income" });
      const { UNEARNEDINCOMETYPE } = this.tables;
      UNEARNEDINCOMETYPE.push({ code: "OT", description: "Other Income", rank: "14", spDescription: "Otros Ingresos", value: "Other Income" });
    }

    this.registerToggle(otherIncomeTypeControl, toggles([swapControl(inKindControlGroup, codeEqual('SSN')), () => {
      this.validateInkindSupportList();
    }]));

    if (otherIncomeTypeControl.value) {
      if(this.isFinReassessment){
        const tableVar = this.tables.UNEARNEDINCOMETYPE_PP.filter((item) => item.code === otherIncomeTypeControl.value.code);
        otherIncomeTypeControl.patchValue(tableVar[0]);
      }else{
        const tableVar = this.tables.UNEARNEDINCOMETYPE.filter((item) => item.code === otherIncomeTypeControl.value.code);
        otherIncomeTypeControl.patchValue(tableVar[0]);
      }
    }

  }
  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  setGatePostQuestion() {
    let isChildOrPregnantAndMovedIn = false;
    let isChildOrPregnant = false;
    this.individuals.forEach(individual => {
      if (individual.age < 19 || (individual.pregnant && individual.gender['code'] === 'F')) {
        isChildOrPregnant = true;
        if (individual.someoneMovedIn === 'Y') {
          isChildOrPregnantAndMovedIn = true;
        }
      }
    });

    if (isChildOrPregnant) {
      this.getControl('data.otherIncomeDetails.incomeEndSw').enable();
      this.conditionalTopLevelQuestion = this.topLevelQuestionPregnantOrChild;
      this.conditionalTopLevelTooltip = 'TOPLEVEL_TOOL_TIP';
    } else {
      this.conditionalTopLevelQuestion = this.topLevelQuestionNonPregnantOrChild;
      this.conditionalTopLevelTooltip = 'TOPLEVEL_TOOL_TIP';
      this.getControl('data.otherIncomeDetails.incomeEndSw').disable();
      this.getControl('data.otherIncomeDetails.incomeEndDate').disable();
    };

    if (isChildOrPregnantAndMovedIn && (isRmbRmc(this.mode))) {
      this.conditionalTopLevelQuestion = this.topLevelQuestionPregnantOrChild;
      this.conditionalTopLevelTooltip = 'TOPLEVEL_TOOL_TIP';
    } else if (isChildOrPregnantAndMovedIn === false && (isRmbRmc(this.mode))) {
      this.conditionalTopLevelQuestion = this.topLevelQuestionNonPregnantOrChild;
      this.conditionalTopLevelTooltip = 'TOPLEVEL_TOOL_TIP';
    };
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isDesktop = window.innerWidth >= 768 ? true : false;
  }

}
