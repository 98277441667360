import { Component, Input, Output, OnInit, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';

import { SubscriptionsDirective } from '@app/classes/subscriptions.class';
import { ifNil } from '@app/helpers/null.helpers';
import { disable } from '@app/helpers/able.helpers';
import { valueChanges } from '@app/helpers/form-group.helpers';
import { validateForm, updateForm, get } from '@app/helpers/form.helpers';

import { FormStatus, SkipForm } from '@app/models/section.model';
import { Individuals } from '@app/models/individual.model';

@Component({
  selector: 'nh-form',
  templateUrl: './form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent extends SubscriptionsDirective implements OnInit {
  @Input() public readonly formGroup: FormGroup;
  @Input() public readonly individualQuestion: string;
  @Input() public individuals: Individuals;
  @Input() public readonly topLevelQuestion: string;
  @Input() public readonly mode: string;
  @Input() popupText: string;
  @Input() hintText: string;
  @Input() topLevelHintText: string;
  @Input() topLevelName: string;

  @Output() public validate = new EventEmitter<FormStatus>();
  @Output() public individualAnswerChanged: EventEmitter<string> = new EventEmitter<string>();

  public selectedIndividual: FormControl;
  public sectionId: string;
  public topLevelAnswer: string;
  public initialTopLevelAnswer: string; // get the actual toplevelanswer

  ngOnInit() {
    this.selectedIndividual = get('extras.individual', this.formGroup);
    if (this.selectedIndividual) {
      const sIndi = this.selectedIndividual.value;
      if (sIndi) {
        if (this.individuals && this.individuals.length) {
          if (!this.individuals.find(i => i.identifier === sIndi.identifier)) {
            this.individuals.push(sIndi);
          }
        } else {
          this.individuals = [sIndi];
        }
      }
    }
    this.sectionId = get('identifier', this.formGroup)?.value?.sectionId;
    this.topLevelAnswer = get('extras.topLevelAnswer', this.formGroup)?.value;
    this.initialTopLevelAnswer = get('extras.topLevelAnswer', this.formGroup)?.value;
    if(this.sectionId === 'ASCLD'){
      if(this.topLevelAnswer === 'EMPTY' && (this.selectedIndividual && this.selectedIndividual?.value === undefined)){
        this.formGroup.get('extras.topLevelAnswer').setValue('YES');
        this.formGroup.get('extras.topLevelAnswer').disable();
      }
    }
  
    this.add(this.watchChanges());
    ifNil(() => disable(this.formGroup.get('extras.individual')), this.individualQuestion);
    updateForm(this.formGroup);
  }

  formValue(value, { valid, pristine, dirty, controls }): SkipForm {
    console.log(controls.data);
    return validateForm({ value, valid, pristine, dirty, controls });
  }

  watchChanges() {
    return valueChanges(this.formGroup)
      .pipe(map(value => this.formValue(value, this.formGroup)))
      .subscribe(form => this.emitValidate(form));
  }

  emitValidate({ value, valid, pristine, dirty }) {
    this.validate.emit({ value, valid, pristine, dirty });
  }

  onIndividualChange(event) {
    this.individualAnswerChanged.emit(event.target.value)
  }

}
