import { Pipe, PipeTransform } from '@angular/core';
import { MaskPipe } from 'ngx-mask';

@Pipe({ name: 'ssn' })
export class SSNPipe implements PipeTransform {
  constructor(private maskPipe: MaskPipe) {}

  transform(value: string): string {
    return value ? value : '';
  }

  ssnTransform(value: string): string {
    return this.maskPipe.transform(value, '000-00-0000');
  }
}
