import { Directive, Inject, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { Subject } from 'rxjs';

import { BsModalRef } from 'ngx-bootstrap/modal';
@Directive()
export class BaseModalDirective {
  @Input() title : any;
  @Input() SectionId : any;
  @Input() confirmButton = 'Continue';
  @Input() declineButton = 'Cancel';
  @Input() showConfirm = true;
  @Input() showDecline = true;
  @Input() body: string;
  @Input() from: string;
  public onClose: Subject<any>;

  constructor(
    @Inject(BsModalRef) protected modalRef: BsModalRef, //
    @Inject(FormBuilder) protected builder: FormBuilder,
  ) {
    this.onClose = new Subject();
  }

  protected hide(response) {
    this.onClose.next(response);
    this.modalRef.hide();
  }

  public onConfirm(): void {
    this.hide(true);
  }

  public onCancel(): void {
    this.hide(false);
  }
}
