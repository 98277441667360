import { Injectable } from "@angular/core";
import { ApiService } from "@app/gateway/services/api.service";

@Injectable()
export class ReferenceTableService {
  private referenceURL = 'publ/';
  private _tables = new Map();
  private completeRelationshipUrl = 'publ/reference-tables/'

  constructor(private apiService: ApiService) {}

  getTables(tables: Array<string>) {
    const fetchTables = [];
    tables.forEach(table => {
      if (!this._tables.has(table)) {
        fetchTables.push(table);
      }
    });

    if (fetchTables) {
      const url = this.referenceURL + fetchTables.join(',');
      return new Promise((resolve) => {
        this.apiService.get(url).subscribe(data => {
          if (data && data.referenceGroup) {
            data.referenceGroup.forEach((rt, idx) => {
              this._tables.set(fetchTables[idx], rt);
            });
          }
          const rtValues = [];
          tables.forEach(tableName => {
            rtValues.push(this._tables.get(tableName));
          });
          resolve(rtValues);
        }, error => {
          console.error(error);
          resolve(new Array(tables.length).fill([]))
        });
      });
    } else {
      return new Promise((resolve) => {
        const rtValues = [];
        tables.forEach(tableName => {
          rtValues.push(this._tables.get(tableName));
        });
        resolve(rtValues);
      });
    }
  }

  getCompleteRelationshipData () {
    const url = this.completeRelationshipUrl + 'complete?tables=' + 'RELATIONTYPE'
      return this.apiService.get(url)

  }
}