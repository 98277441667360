import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { notificationList } from '@app/helpers/animation.helpers';
import { Notifications } from '@app/models/partials.model';
import { AlertConfig } from 'ngx-bootstrap/alert';

@Component({
  selector: 'nh-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [notificationList],
  providers: [AlertConfig],
})
export class NotificationsComponent {
  @Input() messages: Observable<Notifications>;
  animationInitialized = false;
}
