<div class="container">
    <!-- <nh-alert type="danger" [isOpen]="isAlertMessage" dismissible="true">{{ alertMessage | translate }}</nh-alert> -->

    <nh-alert type="danger" [isOpen]="showErr2">
      <div class="row">
        <div class="icon">
          <img src="../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
        </div>
        <div class="message">
          <span>{{ 'PASSWORD_GUIDELINES' | translate}}</span>
        </div>
      </div>
    </nh-alert>

    <nh-alert type="danger" [isOpen]="isAlertMsg()">
      <div class="row">
          <div class="icon">
              <img src="../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
              <span>{{ alertMessage | translate }}</span>
          </div>
      </div>
    </nh-alert>
    
    <div class="bg-custom-register">
        <h5 class="section-header">{{'CHANGE_PASSWORD' | translate }}</h5>
        <hr>

          <section>
            <div class="col-md-12">
                <p [innerHtml]="'CHANGE_PASSWORD_TEXT' | translate"></p>
                <h5 class="section-header">{{'SET_NEW_PWD' | translate }}</h5>
                <hr>
                <p [innerHtml]="'PASSWORD_GUIDELINES_TITLE' | translate"></p>
                <form [formGroup]="changePassword">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <nh-field [isRow]="true" label="{{'CURRENT_PWD' | translate }}" class="label-section">
                                <div class="password-eye">
                                    <input [type]="fieldCurrentTextType ? 'text' : 'password'" class="form-control"
                                        formControlName="currentPassword" placeholder="{{'CURRENT_PWD'| translate}}" required />
                                    <span (click)="toggleFieldTextType('currentPassword')" class="eye-icon"><img alt="" src="assets/images/Icons/eye-outline-password.svg"></span>
                                </div>
                            </nh-field>
                            <nh-field [isRow]="true" label="{{'SET_NEW_PWD' | translate }}" class="label-section">
                                <div class="password-eye">
                                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control"
                                        formControlName="password" placeholder="{{'ENTER_PASSWORD'| translate}}" (keyup)="validatePassword($event)" required />
                                    <span (click)="toggleFieldTextType('password')" class="eye-icon"><img alt="" src="assets/images/Icons/eye-outline-password.svg"></span>
                                </div>
                            </nh-field>
                            <nh-field [isRow]="true" label="{{'CONFIRM_PASSWORD' | translate }}" class="label-section">
                                <div class="password-eye">
                                    <input [type]="fieldConfirmPassTextType ? 'text' : 'password'" class="form-control"
                                        formControlName="confirmPassword" placeholder="{{'CONFIRM_PASSWORD'| translate}}" (keyup)="comparePassword($event)" (change)="getErrorMessage()"/>
                                    <span (click)="toggleFieldTextType('confirmPassword')" class="eye-icon"><img alt="" src="assets/images/Icons/eye-outline-password.svg"></span>
                                </div>
                            </nh-field>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="password-guide">
                                <div class="password-header"><span>{{'PWD_GUIDE' | translate }}</span><hr></div>
                                <ul>
                                    <li *ngIf="passwordRuleLen" class="password-rules-valid">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/met icon_PWGuidelines.svg" alt="passowrd-met">
                                        <div>{{ 'PG_1' | translate }}</div>
                                      </div>
                                    </li>
                                    <li *ngIf="!passwordRuleLen" class="password-rules">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/not met icon_PWGuidelines.svg" alt="passowrd-not-met">
                                        <div>{{ 'PG_1' | translate }}</div>
                                      </div>
                                    </li>
                                    <li *ngIf="passwordRuleUpp" class="password-rules-valid">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/met icon_PWGuidelines.svg" alt="passowrd-met">
                                        <div>{{ 'PG_2' | translate }}</div>
                                      </div>
                                    </li>
                                    <li *ngIf="!passwordRuleUpp" class="password-rules">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/not met icon_PWGuidelines.svg" alt="passowrd-not-met">
                                        <div>{{ 'PG_2' | translate }}</div>
                                      </div>
                                    </li>                    
                                    <li *ngIf="passwordRuleLow" class="password-rules-valid">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/met icon_PWGuidelines.svg" alt="passowrd-met">
                                        <div>{{ 'PG_3' | translate }}</div>
                                      </div>
                                    </li>
                                    <li *ngIf="!passwordRuleLow" class="password-rules">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/not met icon_PWGuidelines.svg" alt="passowrd-not-met">
                                        <div>{{ 'PG_3' | translate }}</div>
                                      </div>
                                    </li>                   
                                     <li *ngIf="passwordRuleNum" class="password-rules-valid">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/met icon_PWGuidelines.svg" alt="passowrd-met">
                                        <div>{{ 'PG_4' | translate }}</div>
                                      </div>
                                    </li>
                                    <li *ngIf="!passwordRuleNum" class="password-rules">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/not met icon_PWGuidelines.svg" alt="passowrd-not-met">
                                        <div>{{ 'PG_4' | translate }}</div>
                                      </div>
                                    </li>                    
                                    <li *ngIf="passwordRuleSpl" class="password-rules-valid">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/met icon_PWGuidelines.svg" alt="passowrd-met">
                                        <div>{{ 'PG_5' | translate }}</div>
                                      </div>
                                    </li>
                                    <li *ngIf="!passwordRuleSpl" class="password-rules">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/not met icon_PWGuidelines.svg" alt="passowrd-not-met">
                                        <div>{{ 'PG_5' | translate }}</div>
                                      </div>
                                    </li>
                                  </ul>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <br>
            <br>
        </section>
    </div>
</div>
