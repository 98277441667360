import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'nh-add-svg',
  template: `<svg id="_icons_add-circle-outline" data-name="⚛️ icons/add-circle-outline"
  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path id="Combined_Shape" data-name="Combined Shape"
      d="M0,9.75A9.75,9.75,0,1,1,9.75,19.5,9.761,9.761,0,0,1,0,9.75Zm1.5,0A8.25,8.25,0,1,0,9.75,1.5,8.259,8.259,0,0,0,1.5,9.75ZM9,13.514V10.5H6A.75.75,0,0,1,5.985,9H9V6a.75.75,0,0,1,1.5-.015V9h3a.75.75,0,0,1,.014,1.5H10.5v3a.75.75,0,0,1-1.5.014Z"
      transform="translate(2.25 2.25)" fill="#174a7c" />
</svg>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSvgComponent {}
