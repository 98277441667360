<nh-form [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">

  <div *ngIf="isPartnerPortal === false && storeItem !== 'yes'" class="container start-page">
    <div *ngIf="!closeAFBnudge">
      <nh-nudge *ngIf="hasIndiv" [message]="'NUDGETEXT_01' | translate"></nh-nudge>
    </div>
    <h5 class="section-header">{{'BEFORE_YOU_BEGIN' | translate}}</h5>
    <hr >
    <div class="static-text">
      <p [innerHTML]="'START_STATIC_TEXT_1' | translate"></p>
      <p>{{'START_STATIC_TEXT_2' | translate}}</p>
      <p>{{'START_STATIC_TEXT_3' | translate}}: </p>
      <ul class="pl-4 mb-4">
        <li>{{'START_STATIC_TEXT_4' | translate}}</li>
        <li>{{'START_STATIC_TEXT_5' | translate}}</li>
        <li>{{'START_STATIC_TEXT_6' | translate}}</li>
        <li>{{'START_STATIC_TEXT_7' | translate}}</li>
      </ul>
      <p>{{'START_STATIC_TEXT_8' | translate}} </p>
      <p [innerHTML]="'START_STATIC_TEXT_9' | translate"></p>
      <div class="p-3 mb-4 mt-4 helpful-tips">
        <h5 class="pl-2 sub-section">{{'HELPFUL_TIPS' | translate}}</h5>
        <hr>
        <div>
          <div class="d-flex pb-2">
            <img alt="checkIcon" class="align-self-start img-responsive" src="../../../assets/images/Icons/HelpfulTips_CheckIcon.svg" />
            <div class="width-90 pl-3" [innerHTML]="'HELPFUL_TIPS_1' | translate">
            </div>
          </div>
          <div class="d-flex pb-1">
            <img alt="checkIcon" class="align-self-start img-responsive" src="../../../assets/images/Icons/HelpfulTips_CheckIcon.svg" />
            <div class="width-90 pl-3">{{'HELPFUL_TIPS_2' | translate}}<br />
              <br />
            </div>
          </div>
          <p class="pl-5">{{'HELPFUL_TIPS_3' | translate}}</p>
          <div class="d-flex">
            <img alt="importantIcon" class="align-self-start img-responsive" src="../../../assets/images/Icons/HelpfulTips_ImportantIcon.svg" />
            <div class="pl-3 width-90">
              <p [innerHTML]="'HELPFUL_TIPS_4' | translate"></p>
            </div>
          </div>
        </div>
      </div>
      <div [innerHTML]="'HELPFUL_TIPS_5' | translate"></div>
    </div>
  </div>

  <div *ngIf="isPartnerPortal === true && storeItem !== 'yes'" class="container start-page">
    <h5 class="section-header">Before You Begin</h5>
    <hr>
    <div class="static-text">
      <p>Gather as much information before you start as possible.
        Questions throughout the application are written as if we are asking the applicant. <b>And</b>, the
        answers require input from the applicant or family member. If a question is required (marked with *)
        that means it must be answered for us to make a correct eigibility decision </p>
      <p>We will ask about things like:</p>
      <ul class="pl-4 mb-4">
        <li>Social Security numbers, dates of birth, and citizenship and immigration status for everyone applying</li>
        <li>Money from self-employment, jobs, and other income like child support</li>
        <li>Resources including vehicles, life insurance, bank accounts, homes, property, etc. </li>
        <li>Other health insurance information, including policy information and premium amounts. This also includes
          Medicare.</li>
      </ul>
      <p>You will also be able to add an Assisting Person as part of the application process. An
        Assisting Person can be someone who is helping the applicant complete ths application or it can be someone that,
        with the applicant's permission, can make decisions and talk to us on behalf of their household.
        You will be able to provide more information on this on the Household Information screen. </p>
      <p>
        At the end of the application, you must click the <b>Submit</b> button. This action sends your application to us
        and tells us you're ready for a decision. If approved, the start date will be the date we receive this
        application (or the date the applicant meet our rules after it's submitted).</p>
      <div class="p-3 mb-4 mt-4 helpful-tips">
        <p class="sub-section pt-3"><strong>Helpful Tips:</strong></p>
        <hr>
        <div>
          <div class="d-flex pb-3">
            <img alt="checkIcon" class="align-self-start img-responsive" src="../../../assets/images/Icons/HelpfulTips_CheckIcon.svg" />
            <div class="pl-3">After getting started, you can stop and save your answers at any time by clicking the
              <b>Save and Exit</b> button at the bottom of the page. When you're ready, you can log back in and continue.
            </div>
          </div>
          <div>
            <div class="d-flex pb-3">
              <img alt="checkIcon" class="align-self-start img-responsive" src="../../../assets/images/Icons/HelpfulTips_CheckIcon.svg" />
              <div class="width-90 pl-3">We may need proof for some of your answers before we can make a decision. After you submit 
                your application, you can upload documents if you have them with you. We'll tell you more about that at the end.<br/>
              </div>
            </div>
            <p class="pl-5">What if you don't have them with you or we need more information from you?
              We'll send you a letter asking for the proof we need. Be sure to give us the
              address where you get your mail, your phone number, and your email address so
              we can reach you.
              </p>
          </div>
          <div class="d-flex">
            <img alt="importantIcon" class="align-self-start img-responsive" src="../../../assets/images/Icons/HelpfulTips_ImportantIcon.svg" />
            <div class="pl-3"><strong>Important:</strong> When you click the <b>Next</b> button, you'll be asked for information about the Head of Household.  By Head of Household, we mean the person in your home who is mostly responsible for taking care of this TennCare Connect account and your health insurance.</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="storeItem === 'yes'" class="container start-page">
    <h5 class="section-header pt-1">{{'START2_STATIC_TEXT_1' | translate}}</h5>
    <hr>
    <div class="static-text">
      <p class="pt-2">{{'START2_STATIC_TEXT_2' | translate}}
      </p>
      <p class="pt-2">{{'START2_STATIC_TEXT_3' | translate}}
      </p>
      <p class="pt-3"><strong>TennCare Medicaid: </strong>{{'START2_STATIC_TEXT_4' | translate}}</p>

      <p class="pt-3"><strong>TennCare Standard: </strong>{{'START2_STATIC_TEXT_5' | translate}}</p>

      <p class="pt-3"><strong>CoverKids: </strong> {{'START2_STATIC_TEXT_6' | translate}}</p>

      <p class="pt-3"><strong>{{'START2_STATIC1_TEXT_7' | translate}}: </strong>{{'START2_STATIC2_TEXT_7' | translate}}</p>

      <p class="pt-3"><strong>Katie Beckett: </strong>{{'START2_STATIC_TEXT_8' | translate}}</p>
      <div>{{'START2_STATIC_TEXT_9' | translate}} <b>{{'NEXT' | translate}} </b>{{'START2_STATIC_TEXT_10' | translate}}</div>
    </div>
  </div>
</nh-form>
