<nh-form [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">

  <div *ngIf="isPartnerPortal === false && storeItem !== 'yes'" class="container start-page">
    <h5 class="section-header">Before You Begin</h5>
    <hr>
    <div class="static-text">
      <p>Before you start your application, would you like to learn a little more about our eligibility categories and
        the income and resource limits for each group? Take a look at our <a
          href="https://www.tn.gov/content/dam/tn/tenncare/documents/eligibilityrefguide.pdf">Eligibility Reference
          Guide.</a></p>
      <p>Give yourself enough time. It takes most people about 30 minutes to complete the application depending on how
        many people are in your household.</p>
      <p>Gather as much information before you start as possible. We'll ask about: </p>
      <ul class="pl-4 mb-4">
        <li>Social Security numbers, dates of birth, and citizenship and immigration status for everyone applying</li>
        <li>Money from self-employment, jobs, and other income like child support</li>
        <li>Resources including vehicles, life insurance, bank accounts, homes, property, etc. </li>
        <li>Other health insurance information, including Medicare</li>
      </ul>
      <p>Tell us as much as you can when you apply. This can help get you a decision faster. If we need
        more information to make our decision, we'll send you a letter. </p>
      <p>
        At the end of the application, you must click the <b>Submit</b> button. This action sends your application to us
        and tells us you're ready for a decision. If approved, your start date will be the date we receive your
        application (or the date you meet our rules after you submit).</p>
      <h5 class="pt-2 section-header">Helpful Tips:</h5><hr>
      <div class="tips-container ml-3 mb-3 mt-2">
        <div class="d-flex pb-3">
          <img alt="checkIcon" class="align-self-start img-responsive" src="../../../assets/images/Icons/HelpfulTips_CheckIcon.svg" />
          <div class="pl-3">After getting started, you can stop and save your answers at any time by clicking the
            <b>Save and Exit</b> button at the bottom of the page. When you're ready, you can log back in and continue.
          </div>
        </div>
        <div class="d-flex pb-1">
          <img alt="checkIcon" class="align-self-start img-responsive" src="../../../assets/images/Icons/HelpfulTips_CheckIcon.svg" />
          <div class="width-90 pl-3">We may need proof for some of your answers before we can make a decision. After you submit
            your application, you can upload documents if you have them with you. We'll tell you more about that at the
            end.<br />
          </div>
        </div>
        <p class="pl-5">What if you don't have them with you or we need more information from you?
          We'll send you a letter asking for the proof we need. Be sure to give us the
          address where you get your mail, your phone number, and your email address so
          we can reach you.</p>
        <div class="d-flex">
          <img alt="importantIcon" lass="align-self-start img-responsive" src="../../../assets/images/Icons/HelpfulTips_ImportantIcon.svg" />
          <div class="pl-3"><strong>Important:</strong> When you click the <b>Next</b> button, you'll be asked for information 
            about the Head of Household.  By Head of Household, we mean the person in your home who is mostly responsible for taking 
            care of this TennCare Connect account and your health insurance.</div>
        </div>
      </div>
      <div>Ready to get started? Click <strong>Next!</strong> </div>
    </div>
  </div>

  <div *ngIf="isPartnerPortal === true && storeItem !== 'yes'" class="container start-page">
    <h5 class="section-header">Before You Begin</h5>
    <hr>
    <div class="static-text">
      <p>Gather as much information before you start as possible.
        Questions throughout the application are written as if we are asking the applicant. <b>And</b>, the
        answers require input from the applicant or family member. If a question is required (marked with *)
        that means it must be answered for us to make a correct eligibility decision. </p>
      <p>We'll ask about things like:</p>
      <ul class="pl-4 mb-4">
        <li>Social Security numbers, dates of birth, and citizenship and immigration status for everyone applying</li>
        <li>Money from self-employment, jobs, and other income like child support</li>
        <li>Resources and their value including vehicles, life insurance, bank accounts, homes, property, etc. </li>
        <li>Other health insurance information including policy information and premium amounts. This also includes
          Medicare.</li>
      </ul>
      <p>You will also be able to add an Assisting Person as part of the application process. An
        Assisting Person can be someone who is helping the applicant complete this application or it can be someone that,
        with the applicant's permission, can make decisions and talk to us on behalf of their household.
        You will be able to provide more information on this on the Household Information screen. </p>
      <p>
        At the end of the application, you must click the <b>Submit</b> button. This action sends this application to us
        and tells us you're ready for a decision. If approved, the start date will be the date we receive this
        application (or the date the applicant meets our rules after it's submitted).</p>
      <div class="tips px-3">

      <div class="p-3 mb-4 mt-4 helpful-tips">
        <h5 class="pt-2 sub-section">Helpful Tips:</h5>
        <hr>
        <div>
          <div class="d-flex pb-3">
            <img alt="checkIcon" class="align-self-start img-responsive" src="../../../assets/images/Icons/HelpfulTips_ImportantIcon.svg" />
            <div class="pl-3">After we getting started, you can stop and save your answers at any time by clicking the
              <b>Save and Exit</b> button at the bottom of the page. When you're ready, you can log back in and continue.
            </div>
          </div>
          <div class="d-flex pb-3">
            <img alt="checkIcon" class="align-self-start img-responsive" src="../../../assets/images/Icons/HelpfulTips_CheckIcon.svg" />
            <div class="width-90 pl-3">We may need proof for some of your answers before we can make a decision. After you submit 
              your application, you can upload documents if you have them with you. We'll tell you more about that at the end.<br/>
            </div>
          </div>
          <p class="pl-5">What if you don't have them with you or we need more information from you?
            We'll send you a letter asking for the proof we need. Be sure to give us the
            address where you get your mail, your phone number, and your email address so
            we can reach you.</p>
          <div class="d-flex">
            <img alt="importantIcon" class="align-self-start img-responsive" src="../../../assets/images/Icons/HelpfulTips_ImportantIcon.svg" />
            <div class="pl-3"><strong>Important:</strong> When you click the <b>Next</b> button, you'll be asked for information about the Head of Household.  By Head of Household, we mean the person in your home who is mostly responsible for taking care of this TennCare Connect account and your health insurance.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div *ngIf="storeItem === 'yes'" class="container start-page">
    <h5 class="pt-2 section-header">Health Care Coverage you can get with us</h5>
    <hr>
    <div class="static-text">
    <p class="pt-2">Do you think you might qualify for lower cost public health insurance? Using TennCare Connect, you
      can apply for TennCare or CoverKids (Children's Health Insurance Program, CHIP), or get help with
      paying your Medicare costs through Medicare Savings Programs (MSP). These programs are
      managed by the State of Tennessee and help cover medical expenses for people who are low income
      and meet other rules.
    </p>
    <p class="pt-2">Below, you'll find a brief description of each kind of public health insurance that's offered by
      TennCare. Use it as a guide but don't worry about deciding which one might work for you or which
      one you should apply for. When you apply. we'll review the information and our rules to decide
      which program you qualify for.
    </p>
    <p class="pt-3"><strong>TennCare Medicaid: </strong> There are several Medicaid groups that people may qualify for
      and each one
      has different income limits and rules. A few groups are children and families with children, pregnant
      women, and people who need nursing home care. Some of the groups also have limits on what you
      own (your resources). Resources are things like bank accounts, cars, and land. The number of
      people who live in your household counts too. </p>

    <p class="pt-3"><strong>TennCare Standard: </strong>This is only for children under age 19 who don't qualify anymore
      for TennCare Medicaid and who don't have access to other health insurance (like through a parent's job).</p>

    <p class="pt-3"><strong>CoverKids: </strong> This is for children under age 19 or pregnant women who are not
      enrolled in other health
      insurance. Usually, their income is over the income limits to qualify for TennCare.</p>

    <p class="pt-3"><strong>Medicare Saving Program: </strong>This TennCare program is for people who have Medicare and
      need help
      paying their Medicare costs. You might know this as QMB (Qualified Medicare Beneficiary) or SLMB
      (Specified Low-income Medicare Beneficiary). If you qualify, TennCare pays for your Medicare
      premiums and sometimes your Medicare co-pays and deductibles so you don't have to. </p>

    <p class="pt-3"><strong>Katie Beckett: </strong>This program is for children under age 18 who have disabilities or
      complex medical
      needs. These children do not qualify for Medicaid because their parents' income or resources are
      over the limits for Medicaid. Part A serves children who would qualify for care in an institution but
      want care at home instead. Part B serves children with disabilities or complex medical needs but
      who may not qualify for care in an institution. </p>
      <div>Click <b>Next</b> to continue!</div>
      </div>
  </div>
</nh-form>