import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {
  constructor() {}

  public setCurrentUser(userId: string) { 
    gtag('set', {'user_id': userId});
  };
}