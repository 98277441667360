import { Component, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { UserProfileService } from '../user-profile.service';
import Vs from '@app/services/validators/validators.service'
import {DatePipe} from "@angular/common";
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';


@Component({
    selector: 'my-letters',
    templateUrl: './my-letters.component.html',
    styleUrls: ['./my-letters.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MyLettersComponent {
    search = new FormGroup({
        type: new FormControl('AL'),
        beginDate: new FormControl('', [Vs.notBefore120Years]),
        endDate: new FormControl('', [Vs.notBefore120Years])
    })
    error: any = { isError: false, errorMessage: '' };

    itemsEn = [{ code: 'AL', value: 'All' }, { code: 'ED', value: 'Eligibility' }, { code: 'AP', value: 'Appeal' }];
    itemsSp = [{ code: 'AL', value: 'Todos' }, { code: 'ED', value: 'Elegibilidad' }, { code: 'AP', value: 'Apelación' }];
    items;

    columns = [
        /*{ name: 'Letter Name', prop: 'letterName' },*/
        { name: 'Letter For', prop: 'letterFor' },
        { name: 'Date', prop: 'date' },
        { name: 'Letter Viewed?', prop: 'viewed' },
    ];
    isRmbRmc = false;
    rows;
    limit = 5;
    start = 1;
    end;
    linkMyCase = false;
    emptyMessage;
    emptyMessageEn = "No results for your search";
    emptyMessageSp = "No hay resultados para su búsqueda";
    translatedLang;
    indivId = sessionStorage.getItem('individualId');
    isAppealLetter: boolean = false;
    constructor(private userProfileService: UserProfileService, private datePipe: DatePipe,  public translateService: TranslateService,private titleService: Title, private router: Router) { 
    this.titleService.setTitle('My letters');
    }

    get f() {
        return this.search.controls;
    }

    ngAfterContentInit() {
        console.log("Myletters component");
        const defaultLanguage = this.translateService.getDefaultLang();
        this.translatedLang = defaultLanguage;
        if (defaultLanguage !== 'en') { 
            this.emptyMessage = this.emptyMessageSp;
            this.items = this.itemsSp;
        } else {
            this.emptyMessage = this.emptyMessageEn;
            this.items = this.itemsEn;
        }
        this.translateService.onDefaultLangChange.subscribe((res) => {
            this.translatedLang = res.lang;
            if (res.lang !== 'en') {
                this.emptyMessage = this.emptyMessageSp;
                this.items = this.itemsSp;
            } else {
                this.emptyMessage = this.emptyMessageEn;
                this.items = this.itemsEn;
            }
        });
        const indivId = sessionStorage.getItem('individualId');
        if (!indivId || indivId === '0' || indivId == 'undefined' || indivId == 'null') {
        this.linkMyCase = true;
        }
        const appealLetter = sessionStorage.getItem('appealLetter');
        if (appealLetter === 'true') {
          this.search.controls.type.patchValue("AP");
          sessionStorage.removeItem('appealLetter');
        }
        this.searchData()
    }

    setValidator() {
        if (this.f.beginDate.value) {
            this.search.get('endDate').setValidators([Vs.required, Vs.notBefore120Years])
        } else {
            this.search.get('endDate').clearValidators()
        }
    }

    searchData() {
        this.userProfileService.getLetters(this.indivId, this.f.type.value, this.f.beginDate.value, this.f.endDate.value).subscribe((res: any) => {
            if (res && res.length > 0) {
                const data = [...res];
                this.rows = data.map(letter => {
                    const pickDate = letter.printDate.slice(0, 10);
                    const uploadDate = this.datePipe.transform(pickDate, "MMMM d, y");
                    return {
                        letterName: letter.letterDescription,
                        letterFor: letter.appealNum ? `Appeal ${letter.appealNum}` : letter.caseNum ? `Case ${letter.caseNum}` :'',
                        date: uploadDate,
                        viewed: letter.letterViewedSW === 'Y' ? 'Yes' : 'No',
                        fID: letter.fileNetDocId,
                        indivID: letter.indvId,
                        reqSeq: letter.coReqSeq,
                    }
                })
                if (this.rows?.length && this.rows?.length > 1) {
                    this.rows.sort((a, b) => {
                        return new Date(b?.date).getTime() - new Date(a?.date).getTime();
                    });
                }
                this.end = this.rows.length < 5 ? this.rows.length : this.limit;
            } else {
                this.start = 0; 
                this.end = 0;
                this.rows = [];
            }
        })

        if (this.search.controls.type.value === 'AL' || this.search.controls.type.value === 'AP') {
            this.isAppealLetter = true;
        } else {
            this.isAppealLetter = false;
        }
    }

    compareTwoDates() {
        if (this.search.controls.endDate.value && this.monthDiff(new Date(this.search.controls['beginDate'].value), new Date(this.search.controls['endDate'].value)) > 365) {
            this.search.controls.endDate.setErrors({INVALID_DATE_RANGE: true})
            this.error = { isError: true, errorMessage: "Begin Date and End Date must be within 12 months of each other." };
        } else {
            this.error = { isError: false, errorMessage: "" }
        }
    }

    monthDiff(d1, d2) {
        const Difference_In_Time = d2.getTime() - d1.getTime();
        const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        return Difference_In_Days <= 0 ? 0 : Difference_In_Days;
    }

    onPage(e) {
        this.start = e.offset * this.limit + 1;
        this.end = this.rows.length < 5 ? this.rows.length : this.start - 1 + this.limit;
    }

    printPdf(fileNetDocId, indivId, reqSeq) {
        fileNetDocId = fileNetDocId.slice(1, -1);
        const typeOfUser = sessionStorage.getItem('userType');
        this.userProfileService.getPrintDocPdf(fileNetDocId, indivId, reqSeq, typeOfUser).subscribe((res: any) => {
            const file = new Blob([res], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        })
    }
    getRowClass(row) {
        return row.viewed === 'No' ? 'row-highlight' : ''
    }

    goToMyAppeals() {
        this.router.navigate(['/user-profile', 'my-appeals']);
    }
}