<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
	<div [formGroup]="getControl('data')">
		<section class="container">
			<div>
			</div>
			<p>Please use the fields below to tell us about this newborn. If the mother gave birth to more than one newborn,
				complete this page first.</p>
			<p>If you need to tell us about a Safe Haven Baby, you must use the Birth Reporting Form and fax or mail the form
				to TennCare Connect.
				<a class="page-info btn-link"
					href="https://www.tn.gov/content/dam/tn/coverkids/documents/BirthReportingFormEng.docx "
					target="_blank"><span>Click here</span></a>
				to download the Birth Reporting Form for a Safe Haven Baby.</p>
			<br>
			<h5 class="section-header">Newborn</h5>
			<hr>
			<form [formGroup]="newBornInformation">
				<div class="form-row">

					<div class="col-8 col-lg-4">
						<nh-field id = "nein_childFirstName" label="Baby's First Name" isRow="true" [mandatory]="true" class="label-section">
							<input type="text" name="childFirstName" aria-label="childFirstName" formControlName="childFirstName">
						</nh-field>
					</div>

					<div class="col-4 col-lg-4">
						<nh-field id = "nein_childMidName" label="Baby's Middle Initial" isRow="true" [mandatory]="false" class="label-section">
							<input type="text" name="childMidName" aria-label="childMidName" maxlength="1" formControlName="childMidName">
						</nh-field>
					</div>

					<div class="col-8 col-lg-4">
						<nh-field id = "nein_childLastName" label="Baby's Last Name" isRow="true" [mandatory]="true" class="label-section">
							<input maxlength="21" type="text" aria-label="childLastName" name="childLastName"
								formControlName="childLastName">
						</nh-field>
					</div>
				</div>

				<nh-field id = "nein_childDob" label="Baby's Date of Birth" [mandatory]="true" class="label-section">
					<nh-datepicker>
						<input type="text" name="childDob" formControlName="childDob" [minDate]="minimumDate"
							(change)="isInvalidDate($event)" [maxDate]="today" bsDatepicker nh>
					</nh-datepicker>
				</nh-field>

				<div class="form-row">
					<div class="col-6 col-md-6">
						<nh-field id = "nein_genderCd" label="Baby's Gender" [mandatory]="true" class="label-section">
							<select nh name="genderCd" aria-label="genderCd" [items]="tables.GENDER" formControlName="genderCd">
								<option value="" selected disabled hidden>{{genderCd}}</option>
							</select>

						</nh-field>
					</div>
				</div>
				<div class="form-row">
					<div class="col-6 col-md-6">
						<nh-field id = "nein_childDeceasedSw" label="Is this baby deceased?" [mandatory]="true" class="label-section">
							<select nh name="childDeceasedSw" aria-label="childDeceasedSw" [items]="tables.YESNO"
								formControlName="childDeceasedSw">
								<option value="" selected disabled hidden></option>
							</select>

						</nh-field>
					</div>
				</div>
				<nh-field id = "nein_childDeathDt" label="Date of Death" [mandatory]="true" class="label-section" *ngIf="isEnabled('data.childDeathDt')">
					<nh-datepicker>
						<input type="text" name="childDeathDt" formControlName="childDeathDt" (change)="isInvalidDate($event)"
							[minDate]="minimumDate" [maxDate]="today" bsDatepicker nh>
					</nh-datepicker>
				</nh-field>


				<div class="form-row">
					<div class="col-6 col-md-6">
						<nh-field  id = "nein_ssn" label="Social Security Number" [mandatory]="false" class="label-section">
							<nh-popover field-label class="ml-2 pl-1" [content]="caseheadPopoverText"></nh-popover>
							<input type="text" name="ssn" aria-label="ssn" formControlName="ssn" placeholder="XXX-XX-XXXX"
								mask="000-00-0000" (blur)="compareSSN()">
						</nh-field>
					</div>
				</div>
				<div class="form-row">
					<div class="col-6 col-md-6">
						<nh-field id = "nein_ssnConfirm" [mandatory]="false" label="{{'CONFIRM_SSN' |translate}}" class="label-section">
							<input aria-label='ssnConfirm' type="text" name="ssnConfirm" formControlName="ssnConfirm"
							 placeholder="XXX-XX-XXXX" mask="000-00-0000" (blur)="compareSSN()">
						</nh-field>
					</div>
				</div>
				<nh-alert type="warning" [isOpen]="getControl('data.ssn').touched && isEmpty('data.ssn')">
					<div class="row">
						<div class="icon">
							<img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
						</div>
						<div class="message">
							<span> {{'SOCIAL_SECURITY_STATIC_TEXT4' | translate}}
							</span>
						</div>
					</div>
				</nh-alert>

				<nh-field id = "nein_facilityBirthSw" label="Was this baby born in your facility?" [mandatory]="true" class="label-section">
					<select nh name="facilityBirthSw" aria-label="facilityBirthSw" [items]="tables.YESNO"
						formControlName="facilityBirthSw">
						<option value="" selected disabled hidden></option>
					</select>
				</nh-field>

				<nh-field id = "nein_usCtznSw" label="Is {{ this.name }} a United States citizen?" [mandatory]="true" class="label-section"
					*ngIf="isN('data.facilityBirthSw')">
					<select nh name="usCtznSw" aria-label="usCtznSw" [items]="tables.YESNO" formControlName="usCtznSw">
						<option value="" selected disabled hidden></option>
					</select>
				</nh-field>

				<nh-field id = "nein_derivedCtznSw" label="Is {{ this.name }} a naturalized or derived U.S citizen?" [mandatory]="true"
					class="label-section" *ngIf="isN('data.facilityBirthSw') && isY('data.usCtznSw')">
					<P>This usually means that they were born outside of the U.S.</P>
					<select nh name="derivedCtznSw" aria-label="derivedCtznSw" [items]="tables.YESNO"
						formControlName="derivedCtznSw">
						<option value="" selected disabled hidden></option>
					</select>
				</nh-field>

				<div class="form-row" *ngIf="isalienAndNatureNumberEnable">
					<div class="col-6 col-md-6">
						<nh-field id = "nein_alienNum" label="Alien Number" [mandatory]="false" class="label-section">
							<input type="text" name="alienNum" formControlName="alienNum" nh>
						</nh-field>
					</div>
				</div>
				<div class="form-row" *ngIf="isalienAndNatureNumberEnable">
					<div class="col-7 col-md-7">
						<nh-field id = "nein_naturalCertNum" label="Naturalization Certificate Number" *ngIf="isalienAndNatureNumberEnable" [mandatory]="false"
							class="label-section">
							<input type="text" name="naturalCertNum" formControlName="naturalCertNum" nh>
						</nh-field>
					</div>
				</div>
			</form>
		</section>
	</div>
</nh-form>
