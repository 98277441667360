

export const contactDetails = {
    cell: 'CELL_PHONE'
}
export const representativeAddress = {
    addressLine1: 'ADDRESS_LINE_1'
}

export const name = {
    firstName: 'FIRST_NAME'
}

export default {
    representativeAddress : representativeAddress,
    contactDetails: contactDetails,
    appeallantRelationship: 'Eligible authorized Representative',
    name: name,
    removed: {

    }
}
