<div class="container">
<div *ngIf = "userType === 'RP'" class="pt-2">
  <h1 class="page-title">{{ "Link My Case"}}</h1>
</div>
<nh-alert type="danger" [isOpen]="linkCaseService.showError">
  <div class="row">
    <div class="icon">
      <img src="../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
    </div>
    <div class="message">
      <span>{{ this.linkCaseService.errorMessage | translate}}</span>
    </div>
  </div>
</nh-alert>
<nh-alert type="success" [isOpen]="linkCaseService.showSuccess">
  <div class="row">
    <div class="message">
      <span>{{ this.linkCaseService.errorMessage | translate}}</span>
    </div>
  </div>
</nh-alert>
<div *ngIf = "userType === 'RP'" class="hover-red">
  <a class="hover-red btn btn-link" (click)="onPrevious()">
    <svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
      <g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
        <path id="Combined_Shape" data-name="Combined Shape"
          d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
          transform="translate(0)" fill="#174a7c" />
      </g>
    </svg>
    <span class="pl-2">Back to TennCare Access Welcome Page</span>
  </a>
</div>
<br>

<div class="container mb-2">
  <div class="row">
    <div class="card" style="flex: 1 1 1px;">
      <div class="card-body">
        <section>
          <p>{{'LINK_CASE_DESC_1' | translate}}</p>
          <p>{{'LINK_CASE_DESC_2' | translate}} <a href="https://www.tn.gov/tenncare.html">
            TennCare </a>{{'LINK_CASE_DESC_2_1' | translate}} 855-259-0701</p>
          <p>{{'LINK_CASE_DESC_3' | translate}} </p>

          <h5 class="section-header">{{'LINK_CASE_DESC_4' | translate}}</h5><hr>
          <p class="static-text"
            [innerHTML]="'LINK_CASE_DESC_5' | translate"></p>
          <form [formGroup]="caseLinkfrom">
            <div>
              <nh-field label="{{'DOB' | translate }}" class="label-section">
                <nh-datepicker>
                  <input type="text" class="form-control" name="dateOfBirth" formControlName="dateOfBirth"
                    [minDate]="minimumDate" [maxDate]="today" bsDatepicker nh required>
                </nh-datepicker>
              </nh-field>
              <nh-field label="{{'AND' | translate}}" class="label-section">
                <select nh name="and" aria-label="and" [items]="caseLinkItems" formControlName="and" (change)="onValueChange()"
                  required></select>
              </nh-field>
              <nh-field *ngIf="selectedItem == 'ssn'" label="{{'SSN' | translate }}" class="label-section">
                <input type="text" name="ssn" aria-label="ssn" placeholder="XXX-XX-XXXX" mask="000-00-0000" class="form-control" formControlName="ssn" (change)="ssnValidator()">
              </nh-field>
              <nh-field *ngIf="selectedItem == 'individualId'" label="{{'PID' | translate }}" class="label-section">
                <nh-popover field-label class="ml-2 pl-1" [content]="personPop | translate"></nh-popover>
                <input type="text" name="individualId" aria-label="individualId" mask="9999999999" formControlName="individualId" (input)="checkPersonId()" class="form-control" required>
              </nh-field>
              <nh-field *ngIf="selectedItem == 'wpAppNum'" label="{{'APP_NUMBER' | translate }}" class="label-section">
                <nh-popover field-label class="ml-2 pl-1" [content]="applicationPop | translate"></nh-popover>
                <input type="text" name="wpAppNum" aria-label="wpAppNum" formControlName="wpAppNum" (input)="checkValidAppNum()" mask="S00000000" class="form-control" required>
              </nh-field>

              <nh-field *ngIf = "userType === 'RP'" label="{{'Username or Email Address'}}" class="label-section">
                <input type="text" name="usernameOrEmail" aria-label="usernameOrEmail" formControlName="usernameOrEmail" (change)="usernameOrEmail()" class="form-control" required>
              </nh-field>

            </div>
          </form>
          <br>

        </section>
      </div>
    </div>
  </div>
</div>

<div *ngIf = "userType === 'RP'" class="row my-1">
  <div class="col-md-12">
    <div class="row mt-3 text-center py-4">
      <div class="ml-3">
        <button id="continue" class="btn btn-block btn-secondary btn-width" (click)="onSubmit()" [disabled]="!isFormValid()">
          {{'SUBMIT' | translate}}
        </button>
      </div>
      <div class="ml-3">
        <button id="btn-cancel" class="btn btn-block btn-outline-secondary" (click)="onPrevious()">
          {{'CANCEL' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

</div>