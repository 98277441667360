import { OnInit, Component, DoCheck, HostListener, Input } from '@angular/core';
import { StorageService } from '@app/services/storage/storage.service';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nh-rmc-uploadDoc',
  templateUrl: './rmc-upload-documents.component.html',
})
export class RmcUploadDocument implements OnInit, DoCheck {

  constructor(public storageService: StorageService, private router: Router, public translateService: TranslateService) {
    this.getScreenSize();
  }
  @Input() rows: any[];
  @Input() wPappNum: string;
  isMobileView = false;
  screenHeight: number;
  screenWidth: number;
  mobileWidth = 480;
  defaultLanguage;
  translatedLang;
  staticTextHeader;
  staticTextHeader_en = "Recommended Documents";
  staticTextHeader_sp = "Documentos Recomendados";
  staticText_1;
  staticText_1_en = "You reported a change. We may need more information from you to make a decision. Here are some of the kinds of proof we may need from you.  We may not need them all. The table below tells you who needs to give us documents and the kind of documents we may need for that person. If you’re ready to upload the document now, click <b>Upload Proof.</b> If we need more information, we’ll send you a letter that says what we need and the due date. If you want to upload these documents later, click <b>Next</b> to go to your coverage details.";
  staticText_1_sp = "Usted reportó un cambio. Es posible que necesitemos más información de usted para tomar una decisión. Estos son los tipos de comprobantes que podríamos requerir de usted.  También es posible que no los necesitemos todos. En la siguiente tabla se indica quién debe proporcionarnos documentos y el tipo de documentos que podríamos requerir para esa persona. Si está listo para cargar el documento ahora, haga clic en <b>Cargar comprobante.</b> Si necesitamos más información, le enviaremos una carta que dice lo que necesitamos y la fecha de vencimiento. Si quiere cargar estos documentos más tarde, haga clic en <b>Siguiente</b> para ir a los detalles de su cobertura.";
  staticText_2;
  staticText_2_en = "Types of Documents";
  staticText_2_sp = "Tipos de Documentos";
  staticText_3;
  staticText_3_en = "Head of household";
  staticText_3_sp = "Jefe de Familia";
  staticText_4;
  staticText_4_en = "Change Number";
  staticText_4_sp = "Número de cambio";
  staticText_5;
  staticText_5_en = "Type of Change";
  staticText_5_sp = "Tipo de cambio";
  staticText_6;
  staticText_6_en = "Information Needed";
  staticText_6_sp = "Información necesaria";
  staticText_7;
  staticText_7_en = "Action";
  staticText_7_sp = "Acción";
  staticText_8;
  staticText_8_en = "Upload Proof";
  staticText_8_sp = "Cargar comprobante";
  staticText_9;
  staticText_9_en = "No data to display";
  staticText_9_sp = "no hay información para mostrar";
  staticText_10;
  staticText_10_en = "Change ";
  staticText_10_sp = "Cambio ";
  columns;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngOnInit() {
    sessionStorage.setItem('inRMCUploadDoc', 'true');
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
      if (this.defaultLanguage !== 'en') {
        this.getSpanishLabels()
      } else {
        this.getEnglishLabels();
      }

    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      if (res.lang === 'en') {
        this.getEnglishLabels();
      } else {
        this.getSpanishLabels();
      }
    });
  }

  getEnglishLabels() {
    this.staticTextHeader = this.staticTextHeader_en;
    this.staticText_1 = this.staticText_1_en;
    this.staticText_2 = this.staticText_2_en;
    this.staticText_3 = this.staticText_3_en;
    this.staticText_4 = this.staticText_4_en;
    this.staticText_5 = this.staticText_5_en;
    this.staticText_6 = this.staticText_6_en;
    this.staticText_7 = this.staticText_7_en;
    this.staticText_8 = this.staticText_8_en;
    this.staticText_9 = this.staticText_9_en;
    this.staticText_10 = this.staticText_10_en;
    this.columns = [
      { name: this.staticText_3, prop: 'hohName' },
      { name: this.staticText_4, prop: 'changeNumber' }
    ];
    this.rows.forEach((element) => {
      if(element.changeNumber.includes(this.staticText_10_sp)) {
        element.changeNumber = element.changeNumber.replace('Cambio ', 'Change ' )
      } else if(!element.changeNumber.includes(this.staticText_10_sp || this.staticText_10_en)) {
        element.changeNumber = this.staticText_10_en + element.changeNumber;
      } else {
        element.changeNumber = element.changeNumber;
      }
    });
  }


  getSpanishLabels() {
    this.staticTextHeader = this.staticTextHeader_sp;
    this.staticText_1 = this.staticText_1_sp;
    this.staticText_2 = this.staticText_2_sp;
    this.staticText_3 = this.staticText_3_sp;
    this.staticText_4 = this.staticText_4_sp;
    this.staticText_5 = this.staticText_5_sp;
    this.staticText_6 = this.staticText_6_sp;
    this.staticText_7 = this.staticText_7_sp;
    this.staticText_8 = this.staticText_8_sp;
    this.staticText_9 = this.staticText_9_sp;
    this.staticText_10 = this.staticText_10_sp;
    this.columns = [
      { name: this.staticText_3, prop: 'hohName' },
      { name: this.staticText_4, prop: 'changeNumber' }
    ];
    this.rows.forEach((element) => {
      if(element.changeNumber.includes(this.staticText_10_en)) {
        element.changeNumber = element.changeNumber.replace('Change ', 'Cambio ' )
      } else if(!element.changeNumber.includes(this.staticText_10_sp || this.staticText_10_en)) {
        element.changeNumber = this.staticText_10_sp + element.changeNumber;
      } else {
        element.changeNumber = element.changeNumber;
      }
    });
  }

  ngDoCheck() {
    this.isMobileView = this.screenWidth <= this.mobileWidth;
  }

  uploadProof() {
    sessionStorage.setItem('fromRmcDocUpload', 'true');
    sessionStorage.setItem('appTrackingNum', this.wPappNum)
    this.router.navigate(['user-profile/my-documents', 'documents-we-need'])
  }
}
