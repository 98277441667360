import { FormControl, Validators } from '@angular/forms';
import { F, isNil, mapObjIndexed, forEach, constructN, invoker, compose } from 'ramda';
import { isNotNil } from 'ramda-adjunct';

import { validValues, valueSet, controlWith } from './form.helpers';

export const patchValue = invoker(1, 'patchValue');
export const clearValidators = invoker(0, 'clearValidators');
export const setValidators = invoker(1, 'setValidators');

export const setControl = invoker(2, 'setControl');
export const setErrors = invoker(1, 'setErrors');
export const formControl = constructN(1, FormControl);
const withControl = constructN(2, FormControl);

export const disabledControl = (value, disabler = F) => controlWith(value, disabler(value));
export const notNilControl = value => disabledControl(value, isNotNil);
export const nilControl = value => disabledControl(value, isNil);
export const enumControls = mapObjIndexed(() => nilControl(null));

export const setRequired = setValidators([Validators.required]);
export const requiredControl = (v, c = false) => [controlWith(v, c), [Validators.required]];
export const requiredTrue = (v, c = false) => [controlWith(v, c), [Validators.requiredTrue]];

export const controlRequired = value => withControl(value, Validators.required);
export const controlsClear = forEach(clearValidators);

export const setValid = control =>
  compose(
    valueSet(control),
    validValues
  );
