<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left" id="my-modal-title">{{ 'MODAL_HEADER' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onConfirm()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
    </button>
  </div>

  <div class="modal-body">
    <p class="m-3">{{'MODAL_DESC' | translate}}</p>
    <div class="m-3">
      <p class="mb-2">
        {{'MODAL_DESC2' | translate}}<b>{{'MODAL_CONTINUE' | translate}}</b> {{'MODAL_DESC2_1' | translate}} <b>{{'MODAL_EXIT' | translate}}</b>. {{'MODAL_DESC2_2' | translate}}
      </p>
      <p class="mb-2">
        {{'MODAL_DESC3' | translate}}<b>{{'MODAL_CONTINUE' | translate}}</b>{{'MODAL_DESC3_1' | translate}}<b>{{'MODAL_EXIT' | translate}}</b> {{'MODAL_DESC3_2' | translate}}
      </p>
      <p class="mb-2">{{'MODAL_DESC4' | translate}}<b>{{'MODAL_EXIT' | translate}}</b>.</p>
      <p class="mb-2"> {{'MODAL_DESC5' | translate}}<b>{{'MODAL_CONTINUE' | translate}}</b>{{'MODAL_DESC5_1' | translate}}</p>
    </div>
  </div>

  <!-- Standard markup for a 2 buttons footer; just change the button names & events -->
  <div class="modal-footer d-block">
    <div class="form-row justify-content-center">
      <div class="col-md-3 order-md-1 m-1">
        <button type="button" id="modal-submit-action" class="btn btn-block btn-secondary" (click)="onConfirm()">{{'MODAL_CONTINUE' | translate}}</button>
      </div>
      <div class="col-md-3 order-md-2 m-1">
        <button type="button" id="modal-cancel-action" class="btn btn-block btn-primary" (click)="onCancel()">{{'MODAL_EXIT' | translate}}</button>
      </div>
    </div>
  </div>
</div>
