<form id='section' [formGroup]="formGroup">

  <nh-notifications></nh-notifications>

  <nh-top-level [topLevelName] = "topLevelName" [answerControl]="formGroup.get('extras.topLevelAnswer')" [mode]="mode" [label]="topLevelQuestion" [popupText]="popupText" [topLevelHint]="topLevelHintText" [initialTopLevelAnswer]="initialTopLevelAnswer">

    <ng-content top-level-info select="[form-top]"></ng-content>

    <nh-field [label]="individualQuestion" *ngIf="individualQuestion" class="label-section">
      <div *ngIf="hintText" class="hint" [innerHtml]="hintText"></div>
      <select nh id="individuals" name="individuals" aria-label="individuals" bindLabel="name.fullName" bindId="identifier" [items]="individuals" [formControl]="formGroup.get('extras.individual')" (change)="onIndividualChange($event)"></select>
    </nh-field>

    <ng-content></ng-content>

  </nh-top-level>

</form>