<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <section [formGroup]="getControl('data')">

    <h5 class="section-header">{{'OTHER_HEALTHCARE_STATIC_TEXT1' | translate }}</h5><hr>
    <nh-field id = "otbe_medicareIndSw" [mandatory]="false" label="{{'OTHER_HEALTHCARE_STATIC_TEXT2' | translate }}" class="label-section">
      <nh-popover class="pl-1" [content]="'OTHER_HEALTHCARE_STATIC_TEXT4' | translate"></nh-popover>
      <div class="hint">{{'OTHER_HEALTHCARE_STATIC_TEXT3' | translate }}</div>
      <select nh name="medicareIndSw" aria-label="Medicare Costs" (change)="onProfileDataChange('medicareIndSw', 'medicareIndSwOpt')" [items]="tableCodeValue('YESNO', 'extras.helpers.medicareIndSw')" [formControl]="getControl('extras.helpers.medicareIndSw')"></select>
    </nh-field>

    <div *ngIf="isY('extras.helpers.medicareIndSw')">
      <label for="Medicare Costs" class="label-section"><span class="text-danger">*&nbsp;</span>{{'OTHER_HEALTHCARE_STATIC_TEXT5' | translate }}</label>
      <nh-checkbox-list uniqueString="MEDC" [checkboxGroup]="this.getControl('extras.helpers.medicareIndSwOpt')" [referenceTable]="individualTable"></nh-checkbox-list>
      <nh-alert type="danger" [isOpen]="!getCtrl('medicareIndSw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>
    </div>
    <br>

    <h5 class="section-header">{{'OTHER_HEALTHCARE_STATIC_TEXT40' | translate }}</h5><hr>
    <nh-field id = "otbe_ssiBenefitsSw" [mandatory]="false" label="{{'OTHER_HEALTHCARE_STATIC_TEXT41' | translate }}" class="label-section">
      <nh-popover class="pl-1" [content]="'OTHER_HEALTHCARE_STATIC_TEXT42' | translate"></nh-popover>
      <select nh name="ssiBenefitsSw" aria-label="Pickle" (change)="onProfileDataChange('ssiBenefitsSw', 'ssiBenefitsSwOpt')" [items]="tableCodeValue('YESNO', 'extras.helpers.ssiBenefitsSw')" [formControl]="getControl('extras.helpers.ssiBenefitsSw')"></select>
    </nh-field>

    <div *ngIf="isY('extras.helpers.ssiBenefitsSw')">
      <label for="Pickle" class="label-section"><span class="text-danger">*&nbsp;</span>{{'OTHER_HEALTHCARE_STATIC_TEXT5' | translate }}</label>
      <nh-checkbox-list uniqueString="PKL" [checkboxGroup]="this.getControl('extras.helpers.ssiBenefitsSwOpt')" [referenceTable]="individualTable"></nh-checkbox-list>
      <nh-alert type="danger" [isOpen]="!getCtrl('ssiBenefitsSw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>
    </div>
    <br>

    <div *ngIf="!isPresumptiveBcc">
      <h5 class="section-header">{{'OTHER_HEALTHCARE_STATIC_TEXT6' | translate }}</h5>
      <hr>
      <nh-field *ngIf ="(!isPresumptive && !isFullCoverageApp && !isPPFRM) || isKepro" id = "otbe_brstCervCancerSw" [mandatory]="false" label="{{'OTHER_HEALTHCARE_STATIC_TEXT7' | translate }}" class="label-section">
        <nh-popover class="pl-1" [content]="'OTHER_HEALTHCARE_STATIC_TEXT8' | translate"></nh-popover>
        <select nh name="brstCervCancerSw" aria-label="Breast or Cervical Cancer" (change)="onProfileDataChange('brstCervCancerSw', 'brstCervCancerSwOpt')" [items]="tableCodeValue('YESNO', 'extras.helpers.brstCervCancerSw')" [formControl]="getControl('extras.helpers.brstCervCancerSw')"></select>
      </nh-field>
      <nh-field *ngIf ="(isPresumptive || isFullCoverageApp || isPPFRM) && !isKepro" id = "otbe_brstCervCancerSw" [mandatory]="false" label="{{'OTHER_HEALTHCARE_STATIC_TEXT43' | translate }}" class="label-section">
        <nh-popover class="pl-1" [content]="'OTHER_HEALTHCARE_STATIC_TEXT8' | translate"></nh-popover>
        <select nh name="brstCervCancerSw" aria-label="Breast or Cervical Cancer" (change)="onProfileDataChange('brstCervCancerSw', 'brstCervCancerSwOpt')" [items]="tableCodeValue('YESNO', 'extras.helpers.brstCervCancerSw')" [formControl]="getControl('extras.helpers.brstCervCancerSw')"></select>
      </nh-field>
      <div *ngIf="isY('extras.helpers.brstCervCancerSw')">
        <nh-alert type="warning" [isOpen]="isY('extras.helpers.brstCervCancerSw')">
          <div class="row">
            <div class="icon">
              <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
            </div>
            <div class="message">
              <span>
                {{'OTHER_HEALTHCARE_STATIC_TEXT9' | translate }}
              </span>
            </div>
          </div>
        </nh-alert>
        <label for="Breast or Cervical Cancer" class="label-section"><span class="text-danger">*&nbsp;</span>{{'OTHER_HEALTHCARE_STATIC_TEXT5' | translate }}</label>
        <nh-checkbox-list uniqueString="BCC" [checkboxGroup]="this.getControl('extras.helpers.brstCervCancerSwOpt')" [referenceTable]="individualTable"></nh-checkbox-list>
        <nh-alert type="danger" [isOpen]="!getCtrl('brstCervCancerSw')">
          <div class="row">
            <div class="icon">
              <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
            </div>
            <div class="message">
              <span>{{'REQUIRED' | translate }}</span>
            </div>
          </div>
        </nh-alert>
      </div>
      <br>
    </div>
  
    <section *ngIf="!isPresumptive && showKb && isKbReferralSw()">
      <h5 class="section-header">Katie Beckett </h5><hr>
      <nh-field id = "otbe_kbReferralSw" [mandatory]="false" label="{{'OTHER_HEALTHCARE_STATIC_TEXT10' | translate }}" class="label-section">
        <br>
        <div class="pt-1 hover-red">
          <a class="pl-0 text-left btn btn-link hover-red" (click)="showMoreKatieBeckettProgramModal()">
            <span style="padding-right: 8px;">{{'OTHER_HEALTHCARE_STATIC_TEXT11' | translate }}</span>
            <span><i class="fa fa-external-link"></i></span>
          </a>
        </div>
        <br>
        <select nh name="kbReferralSw" aria-label="Katie Backett" [items]="tableCodeValue('YESNO', 'data.kbReferralSw')" formControlName="kbReferralSw" [attr.disabled]="this.disableKb"></select>
      </nh-field>
      <br> 
    </section>

    <section *ngIf="isMedicalBillSw">
      <h5 class="section-header">{{'OTHER_HEALTHCARE_STATIC_TEXT12' | translate }}</h5><hr>
      <nh-field id = "otbe_medicalBillSw" [mandatory]="false" label="{{'OTHER_HEALTHCARE_STATIC_TEXT13' | translate }}" class="label-section">
        <nh-popover class="pl-1" [content]="'OTHER_HEALTHCARE_STATIC_TEXT16' | translate"></nh-popover>
        <select nh name="medicalBillSw" aria-label="Medical Bills" (change)="onProfileDataChange('medicalBillSw', 'medicalBillsIndOpt')" [items]="tableCodeValue('YESNO', 'data.medicalBillSw')" formControlName="medicalBillSw" [attr.disabled]="isMedicalBillsDisabled ? true : null"></select>
      </nh-field>
    </section>
    <div *ngIf="isY('data.medicalBillSw')">
      <label for="Medical Bills" class="label-section"><span class="text-danger">*&nbsp;</span>{{'OTHER_HEALTHCARE_STATIC_TEXT5' | translate }}</label>
      <nh-checkbox-list uniqueString="MEDB" [checkboxGroup]="this.getControl('extras.helpers.medicalBillsIndOpt')" [disabled] = "isCheckboxDisabled" [referenceTable]="changeMode ? medicalBillsIndvs : medicalBillsIndividualTable"></nh-checkbox-list>
      <nh-alert type="danger" [isOpen]="!getCtrl('medicalBillsIndSw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>
    </div>
    <br>

    <h5 class="section-header">{{'OTHER_HEALTHCARE_STATIC_TEXT14' | translate }}</h5><hr>
    <nh-field id = "otbe_medFacilitySw" [mandatory]="false" label="{{'OTHER_HEALTHCARE_STATIC_TEXT15' | translate }}" class="label-section">
      <select nh name="medFacilitySw" aria-label="Medical Facility" (change)="onProfileDataChange('medFacilitySw', 'medFacilitySwOpt')" [items]="tableCodeValue('YESNO', 'extras.helpers.medFacilitySw')" [formControl]="getControl('extras.helpers.medFacilitySw')"></select>
    </nh-field>
    <div formArrayName="otherBenefitsIndvProfileData" *ngIf="isY('extras.helpers.medFacilitySw')">
      <label for="Medical Facility" class="label-section"><span class="text-danger">*&nbsp;</span>{{'OTHER_HEALTHCARE_STATIC_TEXT5' | translate }}</label>
      <nh-checkbox-list uniqueString="MED" [checkboxGroup]="this.getControl('extras.helpers.medFacilitySwOpt')" [referenceTable]="individualTable"></nh-checkbox-list>
      <nh-alert type="danger" [isOpen]="!getCtrl('medFacilitySw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>
      <div class="mt-1" *ngFor="let row of otherBenefitsIndvProfileData.controls;  index as i;" [formGroupName]="i">
        <div *ngIf="isMedFacilitySw(row)">
          <br>
          <div><strong>{{'OTHER_HEALTHCARE_STATIC_TEXT17' | translate }}{{ getindividualsById(getValue('indvId',row)) }}{{'OTHER_HEALTHCARE_STATIC_TEXT18' | translate }}</strong></div>
          <br>
          <nh-field id = "otbe_medFacilityAdmitDt" label="{{'OTHER_HEALTHCARE_STATIC_TEXT19' | translate }}{{ getindividualsById(getValue('indvId',row)) }}{{'OTHER_HEALTHCARE_STATIC_TEXT20' | translate }}" class="label-section">
            <nh-datepicker>
              <input type="text" class="form-control" name="medFacilityAdmitDt" (change)="isInvalidDate($event, i)" formControlName="medFacilityAdmitDt" [minDate]="minimumDate" [maxDate]="today" bsDatepicker nh maxlength="10">
            </nh-datepicker>
          </nh-field>
          <br>
          <nh-field id = "otbe_hospitalName" label="{{'OTHER_HEALTHCARE_STATIC_TEXT21' | translate }} {{ getindividualsById(getValue('indvId',row)) }}" class="label-section">
            <input type="text" name="hospitalName" aria-label="hospitalName" class="form-control" formControlName="hospitalName">
          </nh-field>
        </div>
      </div>
    </div>
    <br>

    <h5 class="section-header">{{'OTHER_HEALTHCARE_STATIC_TEXT22' | translate }}</h5><hr>
    <nh-field id = "otbe_nursingHomeSw" [mandatory]="false" label="{{'OTHER_HEALTHCARE_STATIC_TEXT23' | translate }}" class="label-section">
      <select nh name="nursingHomeSw" aria-label="Nursing Home" (change)="onProfileDataChange('nursingHomeSw', 'nursingHomeSwOpt')" [items]="tableCodeValue('YESNO', 'extras.helpers.nursingHomeSw')" [formControl]="getControl('extras.helpers.nursingHomeSw')"></select>
    </nh-field>

    <nh-alert type="warning" [isOpen]="isY('extras.helpers.nursingHomeSw')">
      <div class="row">
        <div class="icon">
          <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
        </div>
        <div class="message">
          <span>{{'OTHER_HEALTHCARE_STATIC_TEXT25' | translate }}&nbsp;<a class="hover-red" href="https://tpaes.tenncare.tn.gov/tmtrack/ecf/index.htm" target="_blank"><u><strong>{{'Here' | translate }}</strong></u></a>
          </span>
        </div>
      </div>
    </nh-alert>

    <div formArrayName="otherBenefitsIndvProfileData" *ngIf="isY('extras.helpers.nursingHomeSw')">
      <label for="Nursing Home" class="label-section"><span class="text-danger">*&nbsp;</span>{{'OTHER_HEALTHCARE_STATIC_TEXT5' | translate }}</label>
      <nh-checkbox-list uniqueString="NRG" [checkboxGroup]="this.getControl('extras.helpers.nursingHomeSwOpt')" [referenceTable]="individualTable"></nh-checkbox-list>
      <nh-alert type="danger" [isOpen]="!getCtrl('nursingHomeSw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>
      <div class="mt-1" *ngFor="let row of otherBenefitsIndvProfileData.controls;  index as i;" [formGroupName]="i">
        <div *ngIf="isNursingHomeSw(row)">
          <br>
          <nh-field id = "otbe_nursingHomeName" label="{{'OTHER_HEALTHCARE_STATIC_TEXT24' | translate }}" class="label-section">
            <input type="text" name="nursingHomeName" aria-label="nursingHomeName" class="form-control" formControlName="nursingHomeName">
          </nh-field>
        </div>
      </div>
    </div>
    <br>

    <h5 class="section-header">{{'OTHER_HEALTHCARE_STATIC_TEXT26' | translate }}</h5><hr>
    <nh-field id = "otbe_hospiceSw" [mandatory]="false" label="{{'OTHER_HEALTHCARE_STATIC_TEXT27' | translate }}" class="label-section">
      <select nh name="hospiceSw" aria-label="Hospice Care" (change)="onProfileDataChange('hospiceSw', 'hospiceSwOpt')"  [items]="tableCodeValue('YESNO', 'extras.helpers.hospiceSw')" [formControl]="getControl('extras.helpers.hospiceSw')"></select>
    </nh-field>
    <div *ngIf="isY('extras.helpers.hospiceSw')">
      <label for="Hospice Care" class="label-section"><span class="text-danger">*&nbsp;</span>{{'OTHER_HEALTHCARE_STATIC_TEXT5' | translate }}</label>
      <nh-checkbox-list uniqueString="HPC" [checkboxGroup]="this.getControl('extras.helpers.hospiceSwOpt')" [referenceTable]="individualTable"></nh-checkbox-list>
      <nh-alert type="danger" [isOpen]="!getCtrl('hospiceSw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>
    </div>
    <br>

    <h5 class="section-header">{{'OTHER_HEALTHCARE_STATIC_TEXT28' | translate }}</h5><hr>
    <nh-field id = "otbe_hcbsSw" [mandatory]="false" label="{{'OTHER_HEALTHCARE_STATIC_TEXT29' | translate }}" class="label-section">
      <nh-popover class="pl-1" [content]="'OTHER_HEALTHCARE_STATIC_TEXT30' | translate"></nh-popover>
      <select nh name="hcbsSw" aria-label="HCBS" (change)="onProfileDataChange('hcbsSw', 'hcbsSwOpt')" [items]="tableCodeValue('YESNO', 'extras.helpers.hcbsSw')" [formControl]="getControl('extras.helpers.hcbsSw')"></select>
    </nh-field>
    <div *ngIf="isY('extras.helpers.hcbsSw')">
      <label class="label-section" for="HCBS"><span class="text-danger">*&nbsp;</span>{{'OTHER_HEALTHCARE_STATIC_TEXT5' | translate }}</label>
      <nh-checkbox-list uniqueString="HCBS" [checkboxGroup]="this.getControl('extras.helpers.hcbsSwOpt')" [referenceTable]="individualTable"></nh-checkbox-list>
      <nh-alert type="danger" [isOpen]="!getCtrl('hcbsSw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>
    </div>
    <br>

    <section>
      <h5 class="section-header">{{'OTHER_HEALTHCARE_STATIC_TEXT31' | translate }}</h5>
      <hr>
      <nh-field id = "otbe_ecfSw" [mandatory]="false" label="{{'OTHER_HEALTHCARE_STATIC_TEXT32' | translate }}" class="label-section">
        <nh-popover class="pl-1" [content]="'OTHER_HEALTHCARE_STATIC_TEXT33' | translate"></nh-popover>
        <select nh name="ecfSw" aria-label="ECF" (change)="onProfileDataChange('ecfSw', 'ecfIndSwOpt')" [items]="tableCodeValue('YESNO', 'extras.helpers.ecfSw')" [formControl]="getControl('extras.helpers.ecfSw')"></select>
      </nh-field>
    </section>
     <div *ngIf="isY('extras.helpers.ecfSw')">
      <label for="ECF" class="label-section"><span class="text-danger">*&nbsp;</span>{{'OTHER_HEALTHCARE_STATIC_TEXT5' | translate }}</label>
      <nh-checkbox-list uniqueString="ECF" [checkboxGroup]="getControl('extras.helpers.ecfIndSwOpt')" [referenceTable]="individualTable"></nh-checkbox-list>
      <nh-alert type="danger" [isOpen]="!getCtrl('ecfSw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>
    </div>
    <br>

    <h5 class="section-header">
      {{'OTHER_HEALTHCARE_STATIC_TEXT34' | translate }}
    </h5>
    <hr>
    <nh-field id = "otbe_intelDisbSw" [mandatory]="false" label="{{'OTHER_HEALTHCARE_STATIC_TEXT35' | translate }}" class="label-section">
      <select nh name="intelDisbSw" aria-label="Intermediate Care" (change)="onProfileDataChange('intelDisbSw', 'intelDisbSwOpt')" [items]="tableCodeValue('YESNO', 'extras.helpers.intelDisbSw')" [formControl]="getControl('extras.helpers.intelDisbSw')"></select>
    </nh-field>
    <div *ngIf="isY('extras.helpers.intelDisbSw')">
      <label for="IMC" class="label-section"><span class="text-danger">*&nbsp;</span>{{'OTHER_HEALTHCARE_STATIC_TEXT5' | translate }}</label>
      <nh-checkbox-list uniqueString="IMC" [checkboxGroup]="this.getControl('extras.helpers.intelDisbSwOpt')" [referenceTable]="individualTable"></nh-checkbox-list>
      <nh-alert type="danger" [isOpen]="!getCtrl('intelDisbSw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>
    </div>
    <br>

    <!-- Transfer Resources -->
    <div *ngIf="showTransferResources()">
      <h5 class="section-header">
        {{'OTHER_HEALTHCARE_STATIC_TEXT44' | translate }}
      </h5>
      <hr>
      <nh-field id = "otbe_transferInd" *ngIf="!isPresumptive" [mandatory]="true" label="{{'OTHER_HEALTHCARE_STATIC_TEXT45' | translate }}" class="label-section">
        <select nh name="transferredResInd" aria-label="Transfer" [items]="tableCodeValue('YESNO', 'data.transferredResInd')" [attr.disabled]="isTransferDisabled ? true : null" formControlName="transferredResInd" (change)="onTransfer($event)"></select>
      </nh-field>

      <nh-field id = "otbe_transferInd" *ngIf="isPresumptive" [mandatory]="true" label="{{'OTHER_HEALTHCARE_STATIC_TEXT45' | translate }}" class="label-section">
        <select nh name="transferredResIndSw" [items]="tableCodeValue('YESNO', 'extras.helpers.transferredResIndSw')"
        (change)="onProfileDataChange('transferredResIndSw','transferIndOpt')" aria-label="Transfer Switch" [formControl]="getControl('extras.helpers.transferredResIndSw')"></select>
      </nh-field>
      <br>

      <div *ngIf="isY('extras.helpers.transferredResIndSw') && isPresumptive">
        <label for="Expenses" class="label-section"><span class="text-danger">*&nbsp;</span>{{'OTHER_HEALTHCARE_STATIC_TEXT5' | translate }}</label>
        <nh-checkbox-list uniqueString="TRANS" [checkboxGroup]="this.getControl('extras.helpers.transferIndOpt')" [referenceTable]="individualTable"></nh-checkbox-list>
        <nh-alert type="danger" [isOpen]="!getCtrl('transferredResIndSw')">
          <div class="row">
            <div class="icon">
              <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
            </div>
            <div class="message">
              <span>{{'REQUIRED' | translate }}</span>
            </div>
          </div>
        </nh-alert>
       </div>
       
    </div>
    

    <div *ngIf="showExpenseResources()">
      <h5 class="section-header">{{'OTHER_HEALTHCARE_STATIC_TEXT36' | translate }}</h5>
      <hr>
      <nh-field id = "otbe_expensesInd" *ngIf="!isPresumptive" [mandatory]="false" label="{{'OTHER_HEALTHCARE_STATIC_TEXT37' | translate }}" class="label-section">
        <select nh name="expensesIndSw" aria-label="Expenses" [items]="tableCodeValue('YESNO', 'data.expensesInd')" [attr.disabled]="isExpensesDisabled ? true : null" formControlName="expensesInd"></select>
      </nh-field>
      <nh-field id = "otbe_expensesInd" *ngIf="isPresumptive" [mandatory]="false" label="{{'OTHER_HEALTHCARE_STATIC_TEXT37' | translate }}" class="label-section">
        <select nh name="expensesIndSw" [items]="tableCodeValue('YESNO', 'extras.helpers.expensesIndSw')"
        (change)="onProfileDataChange('expensesIndSw','expensesIndOpt')" aria-label="Expenses Switch" [formControl]="getControl('extras.helpers.expensesIndSw')"></select>
      </nh-field>
      <br>

     <div *ngIf="isY('extras.helpers.expensesIndSw') && isPresumptive">
      <label for="Expenses" class="label-section"><span class="text-danger">*&nbsp;</span>{{'OTHER_HEALTHCARE_STATIC_TEXT5' | translate }}</label>
      <nh-checkbox-list uniqueString="EXPI" [checkboxGroup]="this.getControl('extras.helpers.expensesIndOpt')" [referenceTable]="individualTable"></nh-checkbox-list>
      <nh-alert type="danger" [isOpen]="!getCtrl('expensesIndSw')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
            <span>{{'REQUIRED' | translate }}</span>
          </div>
        </div>
      </nh-alert>
     </div>
    </div>

    <section *ngIf="showExpenseResources()">
      <h5 class="section-header">{{'OTHER_HEALTHCARE_STATIC_TEXT38' | translate }}</h5>
      <hr>
      <nh-field id = "otbe_assetIndSw" *ngIf="!isPresumptive" [mandatory]="false" label="{{'OTHER_HEALTHCARE_STATIC_TEXT39' | translate }}" class="label-section">
        <select nh name="assetIndSw" aria-label="Resources" [attr.disabled]="isResoursesDisabled ? true : null" [items]="tableCodeValue('YESNO', 'data.assetInd')" formControlName="assetInd"></select>
      </nh-field>
      <nh-field id = "otbe_assetIndSw" *ngIf="isPresumptive" [mandatory]="false" label="Does anyone in your household have resources like financial accounts, 
      vehicles, property, burial resources, or life insurance?" class="label-section">
        <select nh name="assetIndSw" aria-label="Asset Switch" [items]="tableCodeValue('YESNO', 'extras.helpers.assetIndSw')"  (change)="onProfileDataChange('assetIndSw','assetIndSwOpt')" 
        [formControl]="getControl('extras.helpers.assetIndSw')"></select>
      </nh-field>
      <br>

      <div *ngIf="isY('extras.helpers.assetIndSw') && this.isPresumptive">
        <label for="Resources" class="label-section"><span class="text-danger">*&nbsp;</span>{{'OTHER_HEALTHCARE_STATIC_TEXT5' | translate }}</label>
        <nh-checkbox-list uniqueString="ASI" [checkboxGroup]="this.getControl('extras.helpers.assetIndSwOpt')" [referenceTable]="individualTable"></nh-checkbox-list>
        <nh-alert type="danger" [isOpen]="!getCtrl('assetIndSw')">
          <div class="row">
            <div class="icon">
              <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
            </div>
            <div class="message">
              <span>{{'REQUIRED' | translate }}</span>
            </div>
          </div>
        </nh-alert>
      </div>
    </section>
  </section>
</nh-form>
