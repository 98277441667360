import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { noop } from 'rxjs';

@Pipe({
  name: 'boldText'
})
export class BoldTextPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }

    transform(text: string, textToBold: string, txtToReplace:string): any {
        const replaceString = new RegExp(txtToReplace,"gi");
        if(text.search(replaceString) == -1){        
            return text
        } else {       
            return this.replace(textToBold, replaceString);
        }
    }

    replace(str, replaceString) {
        let matched = str.match(replaceString);
        matched ? matched.forEach(foundString => {
        foundString = foundString.substring(0, foundString.length);
        str = str.replace(replaceString, `<b>${foundString}</b>`);
        }) : noop;
        return str;
    }

    sanitize(str) {
        return this.sanitizer.sanitize(SecurityContext.HTML, str);
    }
}