import { Component, Input, OnInit } from '@angular/core';

import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseModalDirective } from '@app/components/modals/base-modal';

@Component({
  templateUrl: './timeout.component.html',
})
export class TimeoutModalComponent extends BaseModalDirective implements OnInit {
  tick: Observable<number>;
  @Input() timeout: number;

  ngOnInit() {
    this.tick = timer(0, 1000).pipe(map(n => this.timeout - n));
  }
}
