import { Component } from '@angular/core';
import { BaseModalDirective } from '../base-modal';

@Component({
  selector: 'nh-confirm-hoh',
  templateUrl: './confirm-hoh.component.html',
})
export class ConfirmHohComponent extends BaseModalDirective {

}
