export const living = {
    kbAddressFormat: 'ADDRESS_FORMAT',
    address: 'ADDRESS',
    currentLivingArrangement: 'KT_SUMMARY_RMB_STATIC1',
    kbOtherPhysicalAddressSwitch: 'KT_SUMMARY_RMB_STATIC2',
    kbSchoolSwitch: 'KT_SUMMARY_RMB_STATIC3',
    pastYearLivingArrangement: 'KT_SUMMARY_RMB_STATIC4'
}

export const name = {
    firstName: 'FIRST_NAME',
    lastName: 'LAST_NAME',
    middleInitial: 'MIDDLE_NAME',
    suffix: 'SUFFIX'
}

export const contactPhones = {
    home: 'HOME_PHONE',
    work: 'WORK_PHONE',
    cell: 'CELL_PHONE'
}


export const contactInfo = {
    kbContactIndicator: '',
    name: name,
    contactPhones: contactPhones,
    kbContactEmail: 'EMAIL_ADDRESS',
    kbContactInterpreterSwitch: 'KATIE_BECKETT_STATIC_TEXT19',
    kbContactLanguage: 'KATIE_BECKETT_STATIC_TEXT20',
    kbContactRelationship: "KT_SUMMARY_RMB_STATIC5"
}

export default {
    houseMember: 'KT_SUMMARY_RMB_STATIC6',
    reasonsForApplyingToKb: "KT_SUMMARY_RMB_STATIC7",
    kbChange: "KATIE_BECKETT_STATIC_TEXT31",
    livingArrangement: living,
    contactInformation: contactInfo,
    removed: {
        kbRemovalDt: 'I do not want to continue this Katie Beckett referral but I do want to continue my TennCare application'
    }
}