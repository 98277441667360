import { lookIn } from '@app/helpers/flipped.helpers';
import { IncurredMedicalExpDetails } from './incurredMedicalExpDetails.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { ExitLabels } from '@app/models/section.model';
import { toMiddleEndian } from '@app/helpers/date.helpers';
import { evolve } from 'ramda';
import Vs from '@app/services/validators/validators.service';
import IMEinformationLabels from './incurredMedicalExpDetails-labels';
import { FormArray } from '@angular/forms';
import { aPath } from '@app/helpers/function.helpers';

const formValidation = (builder, recordData) => {

  const imeInformation = lookIn(recordData);
  const memberName = imeInformation(['memberName'])
  const medicalExpenseType = imeInformation(['medicalExpenseType'])
  const payFrequency = imeInformation(['payFrequency'])
  const dateOfService = imeInformation(['dateOfService']) ?  new Date(imeInformation(['dateOfService'])) : imeInformation(['dateOfService']);
  const expenseSeqNum = imeInformation(['expenseSeqNum']);
  const documents = aPath(['documentList'], recordData);
  const createCtrls = (element) => { 
    return builder.group({
      documentId: [element['documentId'], Vs.required],
      documentName: [element['documentName'], Vs.required],
      documentFormatCd: [element['documentFormatCd'], Vs.required],
    });
  };

  const createDocumentCtrls = () => { 
    return builder.group({
      documentId: [null, Vs.required],
      documentName: [null, Vs.required],
      documentFormatCd: [null, Vs.required],
    });
  };

  const documentList = (): FormArray => {
    const documentsArray: any = [];
    if (documents.length) {
      for (let i = 0; i < documents.length; i++ ) {
        
          documentsArray.push(createCtrls(documents[i]));
        } 
      } else {
        documentsArray.push(createDocumentCtrls());
      }

    return documentsArray as FormArray;
  };

  const data = builder.group({
    memberName : memberName,
    medicalExpenseType : [medicalExpenseType, Vs.required],
    payFrequency : [payFrequency, Vs.required],
    dateOfService : [dateOfService, Vs.required],
    medicalExpenseTypeCd : medicalExpenseType?.value ? medicalExpenseType.value : null,
    payFrequencyCd : payFrequency?.value ? payFrequency.value : null,
    expenseSeqNum : expenseSeqNum,
    documentList : builder.array(documentList()),
  })

  return { data };
};

const postObject = evolve({
  dateOfService: toMiddleEndian
});



export default sectionConfiguration(
  IncurredMedicalExpDetails,
  formValidation,
  ['MEDICALEXPENSETYPE','INCEXPPAYFREQUENCY' ],
  [{ name: 'Patient Incurring Expense', prop: "data.memberName"},
  { name: 'Medical Expense Type', prop: "data.medicalExpenseType.value"},
  { name: 'Frequency', prop: "data.payFrequency.value"},
  { name: 'Date of Service', prop: "data.dateOfService"}],
  { ...{}, ...IMEinformationLabels},
  {
    postObject: postObject,
    validateSubmit: true,
    showComments: false,
    showSaveAndExit: false,
    labelExit: ExitLabels.EXIT
  }
);

