import { Component, ChangeDetectionStrategy, AfterContentInit } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { propEq } from 'ramda';
import {minus120Years, minus16Years} from '@app/helpers/date.helpers';
import {FormArray, FormControl} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { formatTableData } from '@app/helpers/tables.helpers';
import { isRmbRmc, isExported, isExportStatusNotEmpty } from '@app/helpers/mode.helpers';
import { formatDate } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './health-coverage.component.html',
  styleUrls: ['./health-coverage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class HealthCoverageComponent extends SectionFormDirective implements AfterContentInit {
  today = new Date();
  minimumDate = minus120Years();
  minimumDobDate = minus16Years();
  public indi: any;
  groupNumber: false;
  policyNumber: false;
  list;
  above16;
  youngerIndividual;
  otherSelectedIndividual;
  limitedPlan = false;
  show = false;
  head;
  translatedLang;
  defaultLanguage;
  isRmbRmc = false;
  isAddAnother = false;
  someoneEn = {
    name: {
      fullName: 'Someone outside of the home'
    },
    identifier: "SomeoneOutSideOfHouse"
  };
  someoneSp = {
    name: {
      fullName: 'Alguien fuera de casa'
    },
    identifier: "SomeoneOutSideOfHouse"
  };
  someone;
  exported: boolean = false;

  constructor(builder: FormBuilder, public translateService: TranslateService, private titleService: Title) {
    super(builder);
    this.titleService.setTitle('Other Health Insurance');
  }

  get coveredIndividuals () {
    return this.getControl('data.coveredIndividualDetails.converedIndiv') as FormArray
  }
  get policyHolderFirstName () {
    return this.getControl('data.policyHolder.policyHolderFirstName');
  }
  get policyHolderLastName () {
    return this.getControl('data.policyHolder.policyHolderLastName');
  }

  ngAfterContentInit() {

    if (sessionStorage.getItem("labelForAddAnother") == "false") {
      this.isAddAnother = true;
    } else {
      this.isAddAnother = false;
    }
    
    this.isRmbRmc = isRmbRmc(this.mode);
    this.exported = isExportStatusNotEmpty(this.record.exportingStatus) && isExported(this.record.exportingStatus) && this.isRmbRmc;

    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);

    this.translateService.onDefaultLangChange.subscribe((res) => {
    this.translatedLang = res.lang;
    this.languageCheck(res.lang);
    if (this.individuals) {
      const someone = this.someone;
      this.indi = [...this.individuals, ...[someone]];
    }
    });
    const headofHousehold = this.individuals.filter(ind => ind.applicantRelationship === null)
    this.head = headofHousehold[0];
    this.setHeadFromIndividuals(this.head);
    this.disablePolicyControls();
    this.coverageTypeChange();
    this.above16 = this.individuals.filter(ind => ind.age > 16)
    this.youngerIndividual = this.individuals.some(ind => ind.age < 18)
    this.list = this.individuals.map(ind => {
      return {
        coveredIndividualNumber: ind.identifier,
        name: ind.name.fullName,
        age: ind.age,
        gender: ind.gender.code
      }
    })
    this.isCheckedIndi()

    if (this.individuals) {
      const someone = this.someone;
      this.indi = [...this.individuals, ...[someone]];
    }
    const data = this.getControl('data');
    const policyHolder = data.get('policyHolder');
    const houseMember = data.get('policyHolder.houseMember');
    const policyHolderFirstNameControl = policyHolder.get('policyHolderFirstName');
    const policyHolderLastNameControl = policyHolder.get('policyHolderLastName');
    const dateOfBirth = policyHolder.get('dobDate');
    const ssnNum = policyHolder.get('ssnNum');

    if ((!this.getValue('data.policyHolder.houseMember') || this.getValue('data.policyHolder.houseMember') === '0')  && this.getValue('data.policyHolder.policyHolderFirstName')) {
      houseMember.patchValue("SomeoneOutSideOfHouse");
      policyHolderFirstNameControl.enable();
      policyHolderLastNameControl.enable();
      dateOfBirth.enable();
      ssnNum.enable();
    } else {
      const individual = this.individuals.filter(ind => ind.identifier === this.getValue('data.policyHolder.houseMember'));
      if (individual.length > 0) this.otherSelectedIndividual = individual[0].name.fullName;
    }
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
    if (language === 'en') {
      this.someone = this.someoneEn
    } else {
      this.someone = this.someoneSp
    }
  }

  tableCodeValue (tableName, tableControl) {
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }

  isSomeOutside(control) {
    const isSomeOne = propEq('identifier', 'SomeoneOutSideOfHouse');
    return isSomeOne(this.getValue(control));
  }

  update() {
    const data = this.getControl('data');
    const policyHolder = data.get('policyHolder');
    this.individuals.filter(ind => {
      if (ind.identifier === this.getValue('data.policyHolder.houseMember')) {
        sessionStorage.setItem('policyHolder', ind.name.fullName);
      }     
    })
    const policyHolderFirstNameControl = policyHolder.get('policyHolderFirstName');
    const policyHolderLastNameControl = policyHolder.get('policyHolderLastName');
    const dateOfBirth = policyHolder.get('dobDate');
    const ssnNum = policyHolder.get('ssnNum');
    if (this.getValue('data.policyHolder.houseMember') === 'SomeoneOutSideOfHouse') {
      policyHolderFirstNameControl.enable();
      policyHolderLastNameControl.enable();
      policyHolderFirstNameControl.reset();
      policyHolderLastNameControl.reset();
      dateOfBirth.enable();
      ssnNum.enable();
      this.otherSelectedIndividual = null;
    } else {
      policyHolderFirstNameControl.disable();
      policyHolderLastNameControl.disable();
      dateOfBirth.disable();
      ssnNum.disable();
      const individual = this.individuals.filter(ind => ind.identifier === this.getValue('data.policyHolder.houseMember'));
      if (individual.length > 0) this.otherSelectedIndividual = individual[0].name.fullName;
    }
  }

  onCheckChange (e, i) {
    if (e.target.checked) {
      this.coveredIndividuals.push(new FormControl({coveredIndividualNumber: e.target.value}))
    } else {
      this.coveredIndividuals.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value.coveredIndividualNumber == e.target.value) {
          this.coveredIndividuals.removeAt(i);
        }
      });
    }
  }

  isCheckedIndi() {
    this.list.forEach(val => {
      val.checked = this.coveredIndividuals.controls.some(ctrl => ctrl.value.coveredIndividualNumber === val.coveredIndividualNumber )
    })
  }
  coverageTypeChange() {
    this.disableInsuranceControls();
    if (this.getValue('data.coverageType')) {
      if (this.getValue('data.coverageType').code === 'OT') {
        this.getControl('data.limitedBenefitPlanSwitch').enable();
        this.getControl('data.insuranceCompanyName').enable();
      } else if (this.getValue('data.coverageType').code === 'EI') {
        this.getControl('data.stateEmployeeBenfitPlanSwitch').enable();
        this.getControl('data.insuranceCompanyName').enable();
      } else if (this.getValue('data.coverageType').code === 'LTC') {
        this.getControl('data.longTermCarePremiumAmount').enable();
      }
    }
  }

  disableInsuranceControls() {
    this.getControl('data.insuranceCompanyName').disable();
    this.getControl('data.stateEmployeeBenfitPlanSwitch').disable();
    this.getControl('data.limitedBenefitPlanSwitch').disable();
    this.getControl('data.longTermCarePremiumAmount').disable();

  }
  disablePolicyControls() {
    this.getControl('data.policyHolder.policyHolderFirstName').disable();
    this.getControl('data.policyHolder.policyHolderLastName').disable();
    this.getControl('data.policyHolder.dobDate').disable();
    this.getControl('data.policyHolder.ssnNum').disable();
  }

  showAlert () {
    return (
      this.getForm().valid &&
      this.getValue('data.policyHolder.houseMember') === 'SomeoneOutSideOfHouse' &&
      !this.getValue('data.policyHolder.ssnNum')
    )
  }

  isInvalidDate(event) {
    const enteredDate = event.target.value;
    if ( !enteredDate || enteredDate === '' || enteredDate === 'Invalid date' ) {
      event.target.value = enteredDate;
    } else if (event.target.name === 'policyStartDate') {
      event.target.value = formatDate(this.getControl('data.policyStartDate').value, 'MM/dd/yyyy', 'en');
    }
    else if (event.target.name === 'dobDate') {
      event.target.value = formatDate(this.getControl('data.policyHolder.dobDate').value, 'MM/dd/yyyy', 'en');
    }
  }
}
