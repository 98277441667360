import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SectionFormDirective } from '@app/classes/section-form.class';
@Component({
  templateUrl: './start.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AddStartComponent extends SectionFormDirective implements AfterContentInit {
  isFinReassessment = false;
  appType = sessionStorage.getItem('appType');
  isRenewBenefits = false;
  isPreterm = sessionStorage.getItem('isPreterm') === "PRETERM" && (sessionStorage.getItem('appType') === 'RB' || (sessionStorage.getItem('appType') === 'REDETERMINATION'  && sessionStorage.getItem('isKepro') === "true"));

  constructor(public builder: FormBuilder, private titleService: Title) {
    super(builder)
    this.titleService.setTitle('Apply For Coverage');
  };
 
  ngAfterContentInit() {
    this.isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));
    this.isRenewBenefits = (this.appType === 'RB' || this.appType === "REDETERMINATION");
    this.isPreterm = sessionStorage.getItem('isPreterm') === "PRETERM" && (sessionStorage.getItem('appType') === 'RB' || (sessionStorage.getItem('appType') === 'REDETERMINATION'  && sessionStorage.getItem('isKepro') === "true"));
  }
}