import { Component } from '@angular/core';

import { BaseModalDirective } from '@app/components/modals/base-modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';

@Component({
  templateUrl: './continuance-appeal.component.html',
  styleUrls: ['./continuance-appeal.component.scss']
})
export class DuplicateContinuanceAppealModalComponent extends BaseModalDirective {
  constructor(protected modalRef: BsModalRef, protected builder: FormBuilder) {
    super(modalRef, builder);
  }

  onAppealConfirm() {
    this.hide({confirm: true});
  }
  
  onCancel() {
    this.hide({confirm: false});
  }
}
