export const trustInfo = {
    trustType: 'FINANCIAL_RESOURCES_STATIC_TEXT6',
    trusteeName: 'FINANCIAL_RESOURCES_STATIC_TEXT7',
    beneficiaryOfTrust: 'RMB_FINANCIAL_STATIC_TEXT1',
    ownTrust: 'RMB_FINANCIAL_STATIC_TEXT2'
}
export const bankSearch = {
    bankName: 'FINANCIAL_RESOURCES_STATIC_TEXT16'
}

export const assetAddress = {
    addressLine1: 'ADDRESS_LINE_1',
    addressLine2: 'ADDRESS_LINE_2',
    city: 'CITY',
    state: 'STATE',
    zip: 'ZIP_CODE'
}

export const accountInfo = {
    accountNumber: "RMB_FINANCIAL_STATIC_TEXT3",
    bankSearch: bankSearch
}

export default {
    assetType: 'RMB_FINANCIAL_STATIC_TEXT4',
    trustInformation: trustInfo,
    retireOrJobEnd: 'RMB_FINANCIAL_STATIC_TEXT5',
    beneficiaryOrDonor: 'RMB_FINANCIAL_STATIC_TEXT6',
    assetAmount: "RMB_FINANCIAL_STATIC_TEXT7",
    accountInformation: accountInfo,
    assetAddress: 'RMB_BURRIAL_STATIC5',
    jointOwnership: 'RMB_FINANCIAL_STATIC_TEXT8',
    jointOwnershipInformation: 'RMB_C_CO_OWNERS',
    removed: {
        cashEndDate: 'RMB_FINANCIAL_STATIC_TEXT9'
    }
}

