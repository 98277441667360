import { map } from 'ramda';
import { find } from 'ramda';
import { prop } from 'ramda';
import { both } from 'ramda';
import { head } from 'ramda';
import { unless } from 'ramda';
import { always } from 'ramda';
import { equals } from 'ramda';
import { propEq } from 'ramda';
import { propOr } from 'ramda';
import { filter } from 'ramda';
import { compose } from 'ramda';
import { useWith } from 'ramda';
import { identity } from 'ramda';
import { differenceWith } from 'ramda';

import { gender } from './person.helpers';
import { transform } from './object.helpers';
import { withValueSet } from './form.helpers';
import { code, codeEqual } from './code.helpers';
import { nunProp, nilProp } from './null.helpers';
import { identifierIndividualId } from './record.helpers';
import { newLineSeparated } from './list.helper';

const identifier = propOr('', 'identifier');
export const updateIdentifier = withValueSet([identity, identifier]);

export const genderCode = compose(
  code,
  gender
);

const eqSelf = codeEqual('SLF');
export const isSelf = compose(
  eqSelf,
  prop('applicantRelationship')
);

export const isHeadOfHousehold = compose(
  data => data,
  prop('headOfHousehold')
)

export const isHHTJF = propEq('sectionId', 'HHTJF');
export const householdHead = find(isSelf);

const isRecordPerIndividual = compose(
  both(nunProp('individualId'), nilProp('recordId')),
  identifier,
  head
);

const recordPerIndividual = unless(isRecordPerIndividual, always([]));
const compareIdentifiers = useWith(equals, [identifier, identifierIndividualId]);
export const individualsFilters = f => useWith(differenceWith(compareIdentifiers), [filter(f), identity]);

export const individualRecords = useWith(differenceWith(compareIdentifiers), [identity, recordPerIndividual]);

export const individualNames = (path, individualPipe) => map(i => transform(i, path, individualPipe));

const getIndividuals = path => (individuals, { individual }) => individualNames(path, individual)(individuals);
export const getEligible = filter(prop('eligible'));

export const showIndividuals = path =>
  compose(
    newLineSeparated,
    getIndividuals(path)
  );

export const showEligible = path => (individuals, { individual }) =>
  compose(
    newLineSeparated,
    individualNames(path, individual),
    getEligible
  )(individuals);
