<div class="container">
	<div class="row">
		<div class="col-12">
			<h1 class="page-title">{{headerTitle | translate}}</h1>
		</div>
	</div>
	<div class="card mb-5" style="border: 0px;">
		<div class="card-body shadow-sm">
			<h5 class="section-header">{{sectionTitle | translate}}</h5>
			<hr>
			<section *ngIf="isRegisterAccountLinkValid">
				<p> {{'REGISTRATION_LINK_SUCC_BODY_TEXT' | translate}}</p>

				<div class="row pt-1 hover-red comments">
					<a class="btn btn-link hover-red" [routerLink]="['/signin']">
						<span class="pr-2">{{'BACK_TO_LOGIN' | translate }}</span>
						<nh-exit-svg></nh-exit-svg>
					</a>
				</div>
			</section>
			<section *ngIf="isRegisterAccountLinkValid !== undefined && !isRegisterAccountLinkValid">
				<p> {{'REGISTRATION_LINK_EXP_BODY_TEXT' | translate}} </p>

				<div class="row  pt-1 hover-red comments">
					<a class="btn btn-link hover-red" [routerLink]="['/signin']">
						<span class="pr-2">{{'BACK_TO_LOGIN' | translate }}</span>
						<nh-exit-svg></nh-exit-svg>
					</a>
				</div>
			</section>
		</div>
	</div>
</div>