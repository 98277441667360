<p *ngIf="isRmbRmc">{{ 'OTHER_COVERAGE_STATICTEXT14' | translate }}</p>
<nh-form [individuals]="individuals" [formGroup]="getForm()" (validate)="onValidate($event)">
  <div [formGroup]="getControl('data')">
    <nh-field id = "tfo_filerOutsideHoh" label="{{'TAX_FILER_STATIC_TEXT1' | translate}}" class="label-section">
      <select aria-label='filerOutsideHoh' nh name="filerOutsideHoh" formControlName="filerOutsideHoh" [items]="tableCodeValue('YESNO', 'data.filerOutsideHoh')"></select>
    </nh-field>

    <div *ngIf="isY('data.filerOutsideHoh')">
      <nh-alert type="warning" [isOpen]="hasIndividuals">
        <div class="row">
          <div class="icon">
            <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
          </div>
          <div class="message">
            <span>{{'TAX_FILER_STATIC_TEXT4' | translate }}</span>
          </div>
        </div>
      </nh-alert>

      <nh-alert type="warning" [isOpen]="!hasIndividuals">
        <div class="row">
          <div class="icon">
            <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
          </div>
          <div class="message">
            <span [innerHTML]="'TAX_FILER_STATIC_TEXT3' | translate"></span>
          </div>
        </div>
      </nh-alert>

      <div *ngIf="hasIndividuals">
        <h5 class="section-header">{{'TAX_FILER_STATIC_TEXT2' | translate}}</h5><hr>
      
        <p class="static-text">{{'TAX_FILER_STATIC_TEXT5' | translate}} <br> {{'TAX_FILER_STATIC_TEXT6' | translate}}</p>
      </div>
    </div>

    <section *ngIf="isY('data.filerOutsideHoh')">
      <div class="d-flex flex-column mt-2">
        <ng-container formArrayName="taxDependents">
          <section *ngFor="let taxDependent of taxDependents.controls; let i = index;" [formGroupName]="i" class="d-flex flex-column flex-lg-row">
            <div class="py-2 p-md-2 p-md-0 w-50 mt-4 checkbox">
              <label class="font-weight-normal ml-2 mt-2">
                <input id = "{{'tfo_taxDependent' + i}}" aria-label="toggle" type="checkbox" name="toggle" [checked]="isEnabled('taxFilerName', taxDependent)" (click)="toggle(taxDependent)">
                <span class="cr"><i class="cr-icon fa fa-check" aria-hidden="true"></i></span>
                {{ individuals[i] | fullname }}
              </label>
            </div>
  
            <div class="py-2 p-md-2 p-md-0 w-100">
              <nh-field id = "{{'tfo_taxFilerName' + i}}" [isRow]="true" [mandatory]="isEnabled('taxFilerName', taxDependent)" label="{{'TAX_FILER_STATIC_TEXT7' | translate}}" class="label-section">
                <input aria-label="taxFilerName" type="text" name="taxFilerName" maxlength="50" class="form-control" formControlName="taxFilerName">
              </nh-field>
            </div>
  
            <div class="py-2 p-md-2 p-md-0 w-100">
              <nh-field id = "{{'tfo_relationToTaxFiler' + i}}" [isRow]="true" [mandatory]="isEnabled('taxFilerName', taxDependent)" label="{{'TAX_FILER_STATIC_TEXT8' | translate}} {{ individuals[i] | fullname }}{{'TAX_FILER_STATIC_TEXT9' | translate}} {{ getValue('taxFilerName', taxDependent) }}?" class="label-section">
                <select nh aria-label="relationToTaxFiler" name="relationToTaxFiler" [items]="tableCodeValue('RELATIONTYPE', i)" formControlName="relationToTaxFiler"></select>
              </nh-field>
            </div>
          </section>
        </ng-container>
      </div>
  
    </section>
    <input id = "tfo_hiddenInput" type="hidden" name="hiddenInput" formControlName="hiddenInput">
  </div>
</nh-form>
