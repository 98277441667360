import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { StateService } from '@app/services/state/state.service';

import { viewAppNumber } from '@app/helpers/lenses.helpers';

@Injectable()
export class CongratsResolve implements Resolve<any> {
  constructor(private state: StateService) {}

  resolve(route: ActivatedRouteSnapshot): any {
    return this.state.loadCongrats(viewAppNumber(route));
  }
}
