import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "@app/gateway/services/api.service";

@Injectable({
    providedIn: 'root'
})
export class ChangeSecurityQuestionsService {
    currentLang;
    updateSecurityQuestions;
  
    constructor(private apiService: ApiService, private router: Router) {}
  
    saveSecurityQuestion(): void{
      const url = 'indi/users/updateSecurityQuestions';
      const securityQuestions = this.updateSecurityQuestions.value;
      securityQuestions['language'] = this.currentLang;
      securityQuestions['userName'] = JSON.parse(sessionStorage.getItem('currentUser'))['userId']
      this.apiService.post(url, securityQuestions, {headers: new HttpHeaders({'pageId':'TCCSQ'})}).subscribe((res) => {
        if(res['code'] == '200') {
          this.router.navigateByUrl('user-profile/manage-account?updatedSecurityQuestions=true');
        }
      });
    }
}