import { fromPairs } from 'ramda';
import { compose } from 'ramda';
import { prop } from 'ramda';
import { propEq } from 'ramda';
import { converge } from 'ramda';
import { find } from 'ramda';
import { flatten } from 'ramda';
import { map } from 'ramda';
import { mergeAll } from 'ramda';
import { of } from 'ramda';
import { values } from 'ramda';
import { eqProps } from 'ramda';
import { append } from 'ramda';
import { when } from 'ramda';
import { always } from 'ramda';
import { objOf } from 'ramda';
import { lift } from 'ramda';
import { identity } from 'ramda';
import { useWith } from 'ramda';
import { merge } from 'ramda';
import { propOr } from 'ramda';

import { flatMerge } from '@app/helpers/list.helper';
import { toObject } from '@app/helpers/object.helpers';
import { updateRecordId } from '@app/helpers/lenses.helpers';

const toTable = compose(
  fromPairs,
  of,
  values
);

const fromGroup = compose(
  mergeAll,
  map(toTable),
  prop('referenceGroup')
);

export const toTables = useWith(merge, [identity, fromGroup]);

export const getSections = compose(
  flatten,
  map(prop('sections'))
);

export const allSections = compose(getSections);

export const sectionIdentifier = compose(
  prop('identifier'),
  prop('section')
);

const isSectionId = compose(
  propEq('sectionId'),
  sectionIdentifier
);
export const findSection = converge(find, [isSectionId, allSections]);

const equalIdentifier = eqProps('identifier');

export const createRecord = (record, response, records) =>
  append(updateRecordId(propOr(null, 'recordId', response), record), records);

export const updateRecords = (record, records) => map(when(equalIdentifier(record), always(record)), records);

export const sectionId = prop('sectionId');
export const sections = prop('sections');
export const moduleId = prop('moduleId');

const sectionsIds = compose(
  map(sectionId),
  sections
);
const moduleIds = converge(toObject(['moduleId', 'sectionIds']), [moduleId, sectionsIds]);
const sectionIds = prop('sectionIds');
const modulesId = compose(
  of,
  prop('moduleId')
);

const order = converge(
  compose(
    mergeAll,
    lift(objOf)
  ),
  [sectionIds, modulesId]
);

export const modules = prop('modules');

export const sectionsModule = compose(
  flatMerge,
  map(order),
  map(moduleIds),
  modules
);
