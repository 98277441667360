<div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title pull-left" id="my-modal-title">{{'BURRIAL_POPUP_TITLE' | translate }}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
        <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
      </button>
    </div>
    <div class="modal-body">
      <h5 class="section-header">{{'BURRIAL_POPUP_TITLE' | translate }}</h5> <hr/>
      
      <p [innerHTML]="'BURRIAL_POPUP_STATIC1' | translate"></p>
      <p [innerHTML]="'BURRIAL_POPUP_STATIC2' | translate"></p>
    </div>
  </div>
