import { of, zip, EMPTY, combineLatest } from 'rxjs';

import { map, tap, first, skipWhile, switchMap, catchError, distinctUntilChanged, startWith } from 'rxjs/operators';

import { invoker } from 'ramda';
import { zipObj } from 'ramda';
import { useWith } from 'ramda';
import { converge } from 'ramda';
import { chain } from 'ramda';
import { isNil } from 'ramda';
import { always } from 'ramda';
import { mergeAll } from 'ramda';
import { compose } from 'ramda';
import { equals } from 'ramda';
import { cond } from 'ramda';
import { T } from 'ramda';
import { objOf } from 'ramda';
import { applyTo } from 'ramda';

import { ifNil, nilElse } from './null.helpers';

import { iif } from './function.helpers';
import { value } from '@app/helpers/value.helpers';
import { ActivableFunction } from '@app/models/partials.model';
import { valueChanges } from '@app/helpers/form-group.helpers';

export const empty = always(EMPTY);

const pipe = invoker(1, 'pipe');
const piper = invoker(2, 'pipe');
export const unsubscribe = invoker(0, 'unsubscribe');

export const activate: ActivableFunction = always(of(true));
export const deactivate: ActivableFunction = always(of(false));

export const activable = piper(switchMap(activate), catchError(deactivate));

const skipNil = skipWhile(isNil);

export const skipNils = action =>
  piper(
    tap(ifNil(action)), //
    skipNil //
  );

export const skipSwitch = action =>
  piper(
    switchMap(ifNil(action)), //
    skipNil //
  );

export const firstNun = action =>
  piper(
    switchMap(nilElse(action, of)), //
    first() //
  );

const mapMerge = map(mergeAll);

export const zipMerge = compose(
  pipe(mapMerge),
  zip
);

const tryPartial = (tryOne, tryTwo) =>
  cond([
    [equals(1), tryOne], //
    [equals(2), tryTwo], //
    [T, empty],
  ]);

export const partialOptions = (partial, tryOne, tryTwo) => of(partial).pipe(switchMap(tryPartial(tryOne, tryTwo)));

export const distinct = pipe(distinctUntilChanged());

export const oOf = compose(
  pipe,
  map,
  objOf
);

export const applyMap = compose(
  map,
  applyTo
);

const startValue = compose(
  startWith,
  value
);

const startChanges = converge(pipe, [startValue, valueChanges]);

const latestValues = compose(
  combineLatest,
  chain(startChanges)
);

const named = compose(
  map,
  zipObj
);

export const combineControls = useWith(pipe, [named, latestValues]);

export const orEmpty = action => iif(action, empty);
