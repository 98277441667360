import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BaseModalDirective } from '@app/components/modals/base-modal';

@Component({
  templateUrl: './feedback-survey.component.html',
  styleUrls: ['./feedback-survey.component.scss'],
})
export class FeedbackSurveyModalComponent extends BaseModalDirective implements OnInit {
  feedbackForm: FormGroup;

  ngOnInit() {
    this.feedbackForm = this.builder.group({
      name: null,
      userId: null,
      appNumber: null,
      email: null,
      phone: null,
      type: null,
      comments: null,
    });
  }
}
