import { map } from 'ramda';
import { objOf } from 'ramda';
import { indexBy } from 'ramda';
import { propOr } from 'ramda';
import { pluck } from 'ramda';
import { propEq } from 'ramda';
import { complement } from 'ramda';
import { eqProps } from 'ramda';
import { innerJoin } from 'ramda';
import { o } from 'ramda';
import { of } from 'ramda';
import { useWith } from 'ramda';
import { identity } from 'ramda';
import { compose } from 'ramda';
import { curryN } from 'ramda';
import { pipe } from 'ramda';
import { head } from 'ramda';
import { flip } from 'ramda';
import { sortWith } from 'ramda';
import { ascend } from 'ramda';
import { prop } from 'ramda';

import { contained } from 'ramda-adjunct';

import { withAny, withDefault, callWith, runWith } from './function.helpers';
import { from } from './flipped.helpers';
import { validValues } from './form.helpers';
import { value } from './value.helpers';

export const code = propOr('', 'code');
export const codes = pluck('code');

const asCode = objOf('code');
export const asCodes = map(asCode);
export const codeIndexed = indexBy(code);

export const codeIn = callWith([contained, code]);

const isCode = propEq('code');
const isSomeOne = propEq('identifier');

export const codeEqual = withDefault(isCode);
export const codeUnequal = complement(codeEqual);

export const isSomeOneOutside = withDefault(isSomeOne);

const eqCode = eqProps('code');
export const joinCodes = innerJoin(eqCode);

export const anyCodes = withAny([contained, codes]);
export const codeAny = flip(anyCodes);

const ofCode = o(of, code);
export const inCodes = withAny([contained, ofCode]);

export const fromCode = useWith(from, [identity, isCode]);

export const validCodes = compose(
  codes,
  validValues
);

export const valueCode = compose(
  code,
  value
);

export const lookups = curryN(3, pipe)(code);

export const firstCode = compose(
  code,
  head
);

export const runCode = runWith([identity, codeEqual]);
export const sortByAscendingOrder = sortWith([ascend(prop('code'))]);
