<nh-alert *ngIf="isCovidAlertMsgEnable" type="warning" [isOpen]="true">
  <div class="row">
    <div class="icon">
      <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
    </div>
    <div class="message">
      <span>
        {{'COVID19_STIMULUS_CHECK_MSG' | translate}}
      </span>
    </div>
  </div>
</nh-alert>
<div *ngIf="isRmbRmc"><span>{{ staticText }}</span></div><br>

<div *ngIf="getValue('extras.topLevelAnswer') === 'YES'" form-top>
  <h6 class="person-label">
    {{ selectedIndividual | fullname }}
  </h6>
</div>
<nh-form [topLevelName] = "'otin'" [individuals]="individuals" [formGroup]="getForm()" [mode]="mode"
  [topLevelQuestion]="conditionalTopLevelQuestion" [popupText]="conditionalTopLevelTooltip | translate"
  individualQuestion="{{ this.individualQuestion }}"
  (validate)="onValidate($event)" class="label-section">

  <section [formGroup]="getControl('data')">
    <h5 class="section-header">{{'OTHER_INCOME_SATIC_TEXT1' | translate }}</h5><hr>

    <div [formGroup]="getControl('data.otherIncomeDetails')">
      <div class=hint>{{'IF' | translate }} {{ selectedIndividual | fullname }} {{ otherIncomeTypeCodeHint }}<b>{{otherIncomeTypeCodeHintNext}}</b></div>
      <br>
      <div class="hint semibold">{{otherIncomeTypeSSI}}</div>
      <br>
      <nh-field id = "otin_otherIncomeTypeCode" [label]="'OTHER_INCOME_SATIC_TEXT2' | translate" class="label-section">
       
        <nh-popover field-label class="ml-2 pl-1" [content]="'OTHER_INCOME_TOOLTIP' | translate"></nh-popover>
        <select *ngIf="isFinReassessment" nh name="otherIncomeTypeCode" aria-label="otherIncomeTypeCode" (change)="onOtherIncomeTypeCodeChange()"
          [items]="tableCodeValue('UNEARNEDINCOMETYPE_PP', 'data.otherIncomeDetails.otherIncomeTypeCode')"
          formControlName="otherIncomeTypeCode" [attr.disabled]="getControl('data.otherIncomeDetails.otherIncomeTypeCode').value?.code === 'OT' ? true : null"></select>

          <select *ngIf="!isFinReassessment" nh name="otherIncomeTypeCode" aria-label="otherIncomeTypeCode" (change)="onOtherIncomeTypeCodeChange()"
          [items]="tableCodeValue('UNEARNEDINCOMETYPE', 'data.otherIncomeDetails.otherIncomeTypeCode')"
          formControlName="otherIncomeTypeCode" [attr.disabled]="getControl('data.otherIncomeDetails.otherIncomeTypeCode').value?.code === 'OT' ? true : null"></select>
      </nh-field>

      <nh-field id = "otin_socialSecurityIncomeTypeCode" *ngIf="otherIncomeType === 'SOCIAL_SECURITY'"
        [label]="'OTHER_INCOME_SATIC_TEXT3' | translate" class="label-section">
        <select nh name="socialSecurityIncomeTypeCode"  [items]="tableCodeValue('SSUNEARNEDINCOME', 'data.otherIncomeDetails.socialSecurityIncomeTypeCode')"
          formControlName="socialSecurityIncomeTypeCode" aria-label="socialSecurityIncomeTypeCode"></select>
      </nh-field>

      <nh-field id = "otin_veteranBenefitTypeCode" *ngIf="otherIncomeType === 'VETERAN'" [label]="'OTHER_INCOME_SATIC_TEXT4' | translate"
        class="label-section">
        <select nh name="veteranBenefitTypeCode" [items]="tableCodeValue('VAUNEARNEDINCOME', 'data.otherIncomeDetails.veteranBenefitTypeCode')"
          formControlName="veteranBenefitTypeCode"></select>
      </nh-field>

      <nh-field id = "otin_alimonyOrderDate" *ngIf="otherIncomeType === 'ALIMONY'" [label]="'OTHER_INCOME_SATIC_TEXT5' | translate"
        class="label-section">
        <nh-datepicker>
          <input type="text" class="form-control" [minDate]="minDate" [maxDate]="today" name="alimonyOrderDate"
            formControlName="alimonyOrderDate" bsDatepicker nh>
        </nh-datepicker>
      </nh-field>
      <nh-field id = "otin_paymentStartDate"
        label="{{'OTHER_INCOME_SATIC_TEXT6' | translate}} {{ selectedIndividual | fullname }} {{'OTHER_INCOME_SATIC_TEXT7' | translate}} {{selectedOtherIncomeType.value}}?"
        class="label-section">
        <nh-datepicker>
          <input type="text" class="form-control" [minDate]="minDate" [maxDate]="today" name="paymentStartDate"
            formControlName="paymentStartDate" bsDatepicker nh>
        </nh-datepicker>
      </nh-field>

      <nh-field id = "otin_incomeEndSw" label="{{'OTHER_INCOME_SATIC_TEXT8' | translate}} {{selectedIndividual | fullname}}{{'OTHER_INCOME_SATIC_TEXT9' | translate}}" [mandatory]="false" class="label-section"
        *ngIf="isEnabled('data.otherIncomeDetails.incomeEndSw')">
        <select nh name="incomeEndSw" aria-label="incomeEndSw" [items]="tableCodeValue('YESNO', 'data.otherIncomeDetails.incomeEndSw')"
          formControlName="incomeEndSw"></select>
      </nh-field>

      <nh-field id = "otin_incomeEndDate"
        label="{{'OTHER_INCOME_SATIC_TEXT6' | translate }} {{ selectedIndividual | fullname }} {{'OTHER_INCOME_SATIC_TEXT10' | translate }} {{selectedOtherIncomeType.value}} {{'OTHER_INCOME_SATIC_TEXT11' | translate }}"
        class="label-section" *ngIf="isEnabled('data.otherIncomeDetails.incomeEndDate')">
        <nh-datepicker>
          <input type="text" class="form-control" [minDate]="minimumcomeEndDate" [maxDate]="today" name="incomeEndDate"
            formControlName="incomeEndDate" bsDatepicker nh maxlength="10">
        </nh-datepicker>
      </nh-field>

      <nh-field id = "otin_paymentFrequency"
        label="{{'OTHER_INCOME_SATIC_TEXT12' | translate }} {{ selectedIndividual | fullname }} {{'OTHER_INCOME_SATIC_TEXT13' | translate }} {{selectedOtherIncomeType.value}}?"
        class="label-section">
        <select nh name="paymentFrequency" aria-label="paymentFrequency" [items]="tableCodeValue('INCEXPPAYFREQUENCY', 'data.otherIncomeDetails.paymentFrequency')"
          [attr.disabled]="isPaymentFrequencyDisabled ? 'disabled' : null" formControlName="paymentFrequency"></select>
      </nh-field>

      <nh-field id = "otin_paymentAmount" label="{{'OTHER_INCOME_SATIC_TEXT14' | translate }}  {{selectedOtherIncomeType.value}}?" class="label-section">
        <nh-input-group prepend="$">
          <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="paymentAmount" [attr.disabled]="disableAll" formControlName="paymentAmount" class="form-control">
                </nh-input-group>
            </nh-field>
        </div>
    </section>
    <section [formGroup]="getControl('data.inKindData')" *ngIf="otherIncomeType === 'SOCIAL_SECURITY'">
        <div class="d-flex">
            <h5 class="section-header pt-1">
                {{'OTHER_INCOME_SATIC_TEXT15' | translate }}
            </h5>
            <nh-popover class="pl-1 pt-1" [content]="'OTHER_INCOME_SATIC_TEXT20' | translate"></nh-popover>
        </div>
        <hr>
    <nh-field id = "otin_someOnePayFoodMonthly"
      label="{{'OTHER_INCOME_SATIC_TEXT16' | translate }} {{selectedIndividual | fullname}}{{'OTHER_INCOME_SATIC_TEXT17' | translate }}"
      class="label-section">
      <select nh name="someOnePayFoodMonthly" aria-label="someOnePayFoodMonthly"
        (change)="onSomeOnePayFoodMonthlyChange()" [items]="tableCodeValue('YESNO', 'data.inKindData.someOnePayFoodMonthly')"
        formControlName="someOnePayFoodMonthly"></select>
    </nh-field>
    <nh-field id = "otin_someOnePayLivesMonthly"
      label="{{'OTHER_INCOME_SATIC_TEXT18' | translate }} {{selectedIndividual | fullname}}{{'OTHER_INCOME_SATIC_TEXT19' | translate }}"
      class="label-section">
      <select nh name="someOnePayLivesMonthly" aria-label="someOnePayLivesMonthly"
        (change)="onSomeOnePayLivesMonthlyChange()" [items]="tableCodeValue('YESNO', 'data.inKindData.someOnePayLivesMonthly')"
        formControlName="someOnePayLivesMonthly"></select>
    </nh-field>
    <section *ngIf="isY('data.inKindData.someOnePayFoodMonthly') || isY('data.inKindData.someOnePayLivesMonthly')">
      <div class="d-none d-lg-block">
        <table class="table w-100 table-striped">
          <thead>
            <tr>
              <th scope="col">{{'OTHER_INCOME_SATIC_TEXT21' | translate }}</th>
              <th scope="col">{{'OTHER_INCOME_SATIC_TEXT22' | translate }}</th>
              <th scope="col">{{'OTHER_INCOME_SATIC_TEXT23' | translate }}</th>
              <th scope="col">{{'OTHER_INCOME_SATIC_TEXT24' | translate }}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody formArrayName="inkindSupportList">
            <tr *ngFor="let row of inkindSupportList.controls; index as i; last as isLast" [formGroupName]="i">
              <td>
                <nh-field id = "otin_amountPerMonth" [isRow]="true" [mandatory]="true" [option]="amountPerMonthLabel">
                  <nh-input-group prepend="$">
                    <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="amountPerMonth" formControlName="amountPerMonth" class="form-control">
                  </nh-input-group>
                </nh-field>
              </td>
              <td>
                <nh-field id = "otin_expenseType" [isRow]="true" [mandatory]="true">
                  <select nh name="expenseType" aria-label="expenseType" [items]="tableCodeValue('ISMEXPENSETYPE', inkindSupportList.controls[i])"
                    formControlName="expenseType"></select>
                </nh-field>

              </td>
              <td>
                <nh-field id = "otin_amountPaidByInHome" [isRow]="true" [mandatory]="true" [option]="amountPaidByInHomeLabel">
                  <nh-input-group prepend="$">
                    <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="amountPaidByInHome" formControlName="amountPaidByInHome" class="form-control">
                  </nh-input-group>
                </nh-field>
              </td>
              <td>
                <nh-field id = "otin_amountPaidByOutHome" [isRow]="true" [mandatory]="true" [option]="amountPaidByOutHomeLabel">
                  <nh-input-group prepend="$">
                    <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="amountPaidByOutHome" formControlName="amountPaidByOutHome" class="form-control">
                  </nh-input-group>
                </nh-field>
              </td>
              <td class="removeRow">
                <div *ngIf="i" class="pt-1 hover-red">
                  <button class="btn btn-link btn-lg delete-{{i}} hover-red p-1" (click)="removeRow(i, row)">
                    <span class="pr-2">{{'REMOVE' | translate }}</span>
                    <nh-remove-svg></nh-remove-svg>
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="5">
                <div class="mt-1 hover-red add-another-left">
                  <button class="btn btn-link btn-lg hover-red" (click)="addRow()"
                    [disabled]="!getControl('data.inKindData.inkindSupportList').valid">
                    <span class="pr-2">{{'ADD_2' | translate }}</span>
                    <nh-add-svg></nh-add-svg>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <div class="nh-table-mobile mt-3 pt-2 d-lg-none">
        <div formArrayName="inkindSupportList">
          <div class="table-row mb-3 pb-3" *ngFor="let row of inkindSupportList.controls; index as i; last as isLast"
            [formGroupName]="i">
            <div class="mobile-table-header">{{'OTHER_INCOME_SATIC_TEXT21' | translate }}</div>
            <hr>
            <div class="mobile-table-data">
              <nh-field id = "otin_amountPerMonth" [isRow]="true" [mandatory]="true" [option]="amountPerMonthLabel">
                <nh-input-group prepend="$">
                  <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="amountPerMonth" formControlName="amountPerMonth" class="form-control">
                </nh-input-group>
              </nh-field>
            </div><br>
            <div class="mobile-table-header">{{'OTHER_INCOME_SATIC_TEXT22' | translate }}</div>
            <hr>
            <div class="mobile-table-data">
              <nh-field id = "otin_expenseType" [isRow]="true" [mandatory]="true">
                <select nh name="expenseType" aria-label="expenseType" [items]="tableCodeValue('ISMEXPENSETYPE', inkindSupportList.controls[i])"
                  formControlName="expenseType"></select>
              </nh-field>
            </div><br>
            <div class="mobile-table-header">{{'OTHER_INCOME_SATIC_TEXT23' | translate }}</div>
            <hr>
            <div class="mobile-table-data">

              <nh-field id = "otin_amountPaidByInHome" [isRow]="true" [mandatory]="true" [option]="amountPaidByInHomeLabel">
                <nh-input-group prepend="$">
                  <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="amountPaidByInHome" formControlName="amountPaidByInHome" class="form-control">
                </nh-input-group>
              </nh-field>
            </div><br>
            <div class="mobile-table-header">{{'OTHER_INCOME_SATIC_TEXT24' | translate }}</div>
            <hr>
            <div class="mobile-table-data">
              <nh-field id = "otin_amountPaidByOutHome" [isRow]="true" [mandatory]="true" [option]="amountPaidByOutHomeLabel">
                <nh-input-group prepend="$">
                  <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="amountPaidByOutHome" formControlName="amountPaidByOutHome" class="form-control">
                </nh-input-group>
              </nh-field>
            </div>
            <div *ngIf="i" class="pt-1 hover-red text-center">
              <br>
              <button class="btn btn-link btn-lg delete-{{i}} hover-red p-1" (click)="removeRow(i, row)">
                <span class="pr-2">{{'REMOVE' | translate }}</span>
                <nh-remove-svg></nh-remove-svg>
              </button>
            </div><br>
          </div>
        </div>

        <div class="pt-1 hover-red text-left">
          <button class="btn btn-link btn-lg hover-red" (click)="addRow()"
            [disabled]="!getControl('data.inKindData.inkindSupportList').valid">
            <span class="pr-2">{{'ADD_2' | translate }}</span>
            <nh-add-svg></nh-add-svg>
          </button>
        </div>
      </div>
      <nh-field id = "otin_liveWithOtherAdults" [mandatory]="false"
        label="{{'DOES_2' | translate }}{{selectedIndividual | fullname}} {{'OTHER_INCOME_SATIC_TEXT25' | translate }}"
        class="label-section">
        <select nh name="liveWithOtherAdults" aria-label="liveWithOtherAdults" [items]="tableCodeValue('YESNO', 'data.inKindData.liveWithOtherAdults')"
          formControlName="liveWithOtherAdults"></select>
      </nh-field>
      <nh-field id = "otin_paidHouseholdExpenseAmt" *ngIf="isEnabled('data.inKindData.paidHouseholdExpenseAmt')" label="{{'OTHER_INCOME_SATIC_TEXT26' | translate }} {{selectedIndividual | fullname}} {{'OTHER_INCOME_SATIC_TEXT27' | translate }}"
        class="label-section">
        <nh-input-group prepend="$">
          <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="paidHouseholdExpenseAmt" formControlName="paidHouseholdExpenseAmt" class="form-control">
                </nh-input-group>
            </nh-field>
      <nh-field id = "otin_howManyPeopleLive" [mandatory]="false" label="{{'OTHER_INCOME_SATIC_TEXT28' | translate }} {{selectedIndividual | fullname}}{{'OTHER_INCOME_SATIC_TEXT29' | translate }}"
        class="label-section">
        <nh-popover class="pl-1" [content]="'OTHER_INCOME_SATIC_TEXT30' | translate"></nh-popover>
        <input type="text" name="howManyPeopleLive" aria-label="howManyPeopleLive" formControlName="howManyPeopleLive" mask="9999"
          class="form-control">
      </nh-field>
    </section>
  </section>
</nh-form>
