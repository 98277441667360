import { UtilitiesComponent } from './utilities.component';

import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';

import utilitiesLabels from './utilities-labels';

const formValidation = (builder, recordData) => {
  const rentalData = lookIn(recordData);

  const housingTypeExpense = rentalData(['housingTypeExpense']);
  const expenseFrequencyCode = rentalData(['expensePaymentInformation', 'expenseFrequencyCode']);
  const expenseAmount = rentalData(['expensePaymentInformation', 'expenseAmount']);

  const data = builder.group({
    housingTypeExpense: [housingTypeExpense, [Vs.required]],
    expensePaymentInformation: builder.group({
      expenseFrequencyCode: [expenseFrequencyCode, [Vs.required]],
      expenseAmount: [expenseAmount, [Vs.required, Vs.currency]],
    })
  });

  return { data };
};

export default sectionConfiguration(
  UtilitiesComponent,
  formValidation,
  ['YESNO', 'INCEXPPAYFREQUENCY', 'SHELTERUTILITYEXPTYPE'],
  [
    { name: 'Name', pipe: 'individual' },
    { name: 'Expense Type', prop: 'data.housingTypeExpense.value' },
    { name: 'How Much', prop: 'data.expensePaymentInformation.expenseAmount', pipe: 'currency' },
    { name: 'How Often', prop: 'data.expensePaymentInformation.expenseFrequencyCode.value' },
  ],
  {...{}, ...utilitiesLabels},
  {tooltipAdd: null}
);
