import { Component, ChangeDetectionStrategy, AfterContentInit } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { formatTableData } from '@app/helpers/tables.helpers';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { getDataValue } from "@app/helpers/object.helpers";
import { equals, isNil, isEmpty } from 'ramda';
import { ApiService } from "@app/gateway/services/api.service";
import { ProcessService } from "@app/services/process.service";
import { ModalService } from "@app/services/modal/modal.service";
import { isMilitary } from '@app/helpers/models.helpers';
import { disableResetControls, enableResetControls, resetDisableControls, resetEnableControls } from '@app/helpers/reset-able.helpers';
import Vs from '@app/services/validators/validators.service';


@Component({
  templateUrl: './appeal-demographics.component.html',
  styleUrls: ['./appeal-demographics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AppealDemographicsComponent extends SectionFormDirective implements AfterContentInit {
  
  translatedLang;
  defaultLanguage;
  toolTip: boolean;
  caseNumber;
  appellantName;
  exisitngAppellantAddress;
  addressFormat;
  addressSub: Subscription;
  isMilitary;
  addressFormatValue;
  validAddress = true;
  interpreterMandatory = false


  showAppellentAddresForm: boolean = false;

  constructor(public builder: FormBuilder, private titleService: Title, public translateService: TranslateService, public processService: ProcessService, public apiService: ApiService, private modalService: ModalService) {
    super(builder);
    this.addressSub = this.processService.processAddressValidate.subscribe((action) => this.addressValidate(action));
    this.titleService.setTitle('Appelant Demographics');
  };

  addressValidate(action) {
    let postObject;
    const appNum = this.applicationNumber;
    const formData = this.formGroup.value.data;
    const appealAddress = getDataValue('appealAddress.address', formData);
    const isSaveExit = !!equals(action, 'fromSaveExit');
    postObject = {
      addressList: [
        { addressType: 'PHYSICAL', originalAddress: appealAddress },
      ]
    }
    if (postObject && isEmpty(postObject.addressList)) {
      this.processService.addressValidated = true;
    } else {
      this.apiService.post('applications/' + appNum + '/sections/APDEM/address', postObject, {}, true).subscribe((res) => {
        if (!isNil(res)) {
          this.modalService.openAddressModal(res, isSaveExit).subscribe(result => {
            if (result !== true) {
              if (result) {
                const phyIndex = result.findIndex(i => i.addressType === 'PHYSICAL');
                if (phyIndex === 0) {
                  const addressLine1 = this.getControl('data.appealAddress.address.addressLine1', this.formGroup);
                  const addressLine2 = this.getControl('data.appealAddress.address.addressLine2', this.formGroup);
                  const city = this.getControl('data.appealAddress.address.city', this.formGroup);
                  const state = this.getControl('data.appealAddress.address.state', this.formGroup);
                  const zip = this.getControl('data.appealAddress.address.zip', this.formGroup);

                  if (result[0].selectedAddress === 'Suggested') {
                    addressLine1.setValue(result[0].suggestedAddress.addressLine1);
                    addressLine2.setValue(result[0].suggestedAddress.addressLine2);
                    city.setValue(result[0].suggestedAddress.city);
                    state.setValue(result[0].originalAddress.state);
                    zip.setValue(result[0].suggestedAddress.zip);
                  } else if (result[0].selectedAddress === 'Original') {
                    addressLine1.setValue(result[0].originalAddress.addressLine1);
                    addressLine2.setValue(result[0].originalAddress.addressLine2);
                    city.setValue(result[0].originalAddress.city);
                    state.setValue(result[0].originalAddress.state);
                    zip.setValue(result[0].originalAddress.zip);
                  }
                };
                this.processService.addressValidated = true;
              }
            }
          });
        } else {
          this.processService.addressValidated = true;
        }
      });
    }
  }

  ngAfterContentInit() {
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.languageCheck(res.lang);
    });
    if (this.getValue('data.appellantDemographics.futureAddressSw') === '') {
      this.processService.addressValidated = false;
    }
    const langPrefValue = this.getValue('data.appellantDemographics.languagePreference');
    if(langPrefValue){
      this.languagePref();
    }
    this.caseNumber = this.getValue('data.caseNumber');
    this.appellantName = this.getValue('data.appellantName');
    this.exisitngAppellantAddress = this.getValue('data.appealAddress.address');
    this.addressFormat = this.getControl('data.appealAddress.addressFormat');
    this.addressFormatValue = this.getValue('data.appealAddress.addressFormat')
    this.addressFormatChange('onLoad');
    const appealAddress = this.getControl('data.appealAddress.address');
   if(appealAddress?.value?.state?.code !== 'TN'){
    this.getControl('data.appealAddress.address.county').clearValidators();
      this.getControl('data.appealAddress.address.county').updateValueAndValidity();
   }
    appealAddress.valueChanges.pipe().subscribe(() => {
      this.processService.addressValidated = false;
      this.validAddress = appealAddress.status === 'VALID' ? true : false;
    });
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  tableCodeValue(tableName, tableControl) {
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang, false)
  }

  addressFormatChange(info) {
    const addressFormatCode = this.getValue('data.appealAddress.addressFormat');
    const addressControl = this.getControl('data.appealAddress.address')
    if (addressFormatCode.code !== 'MI') {
      this.getControl('data.appealAddress.address.addrAaAeAp').clearValidators();
      this.getControl('data.appealAddress.address.addrAaAeAp').updateValueAndValidity();
      this.getControl('data.appealAddress.address.addrApoFpo').clearValidators();
      this.getControl('data.appealAddress.address.addrApoFpo').updateValueAndValidity();
      this.getControl('data.appealAddress.address.city').setValidators(Vs.required);
      this.getControl('data.appealAddress.address.city').updateValueAndValidity();
      this.getControl('data.appealAddress.address.state').setValidators(Vs.required);
      this.getControl('data.appealAddress.address.state').updateValueAndValidity();
      this.getControl('data.appealAddress.address.county').setValidators(Vs.required);
      this.getControl('data.appealAddress.address.county').updateValueAndValidity();
    } else {
      //this.getControl('data.appealAddress.address').reset();
      this.getControl('data.appealAddress.address.city').clearValidators();
      this.getControl('data.appealAddress.address.city').updateValueAndValidity();
      this.getControl('data.appealAddress.address.state').clearValidators();
      this.getControl('data.appealAddress.address.state').updateValueAndValidity();
      this.getControl('data.appealAddress.address.county').clearValidators();
      this.getControl('data.appealAddress.address.county').updateValueAndValidity();
      this.getControl('data.appealAddress.address.addrAaAeAp').setValidators(Vs.required);
      this.getControl('data.appealAddress.address.addrAaAeAp').updateValueAndValidity();
      this.getControl('data.appealAddress.address.addrApoFpo').setValidators(Vs.required);
      this.getControl('data.appealAddress.address.addrApoFpo').updateValueAndValidity();
    }
    if (info === 'onChange') {
    const city = this.getControl('data.appealAddress.address.city');
    const state = this.getControl('data.appealAddress.address.state');
    const county = this.getControl('data.appealAddress.address.county');
    const zip = this.getControl('data.appealAddress.address.zip');
    const addrApoFpo = this.getControl('data.appealAddress.address.addrApoFpo');
    const addrAaAeAp = this.getControl('data.appealAddress.address.addrAaAeAp');
    const whenMilitary = [addrApoFpo, addrAaAeAp];
    const whenNotMilitary = [city, state, county];
    this.isMilitary = isMilitary(addressFormatCode);
    if (addressFormatCode) {
      if (addressControl.status === 'DISABLED') {
        return null;
      } else {
        if (this.isMilitary) {
          resetEnableControls(whenMilitary);
          resetDisableControls(whenNotMilitary);
          zip.reset()
        } else {
          enableResetControls(whenNotMilitary);
          disableResetControls(whenMilitary);
          zip.reset();
          county?.markAsPristine();
          county?.markAsUntouched();
        }
      }
    }
  }
  }

  languagePref() {
    const langPrefValue = this.getValue('data.appellantDemographics.languagePreference');
    if(langPrefValue?.code !== '1') {
      this.getControl('data.appellantDemographics.appealHearInterpreterSw').clearValidators();
      this.getControl('data.appellantDemographics.appealHearInterpreterSw').updateValueAndValidity();
      this.interpreterMandatory = false;
    } else {
      this.getControl('data.appellantDemographics.appealHearInterpreterSw').setValidators(Vs.required);
      this.getControl('data.appellantDemographics.appealHearInterpreterSw').updateValueAndValidity();
      this.interpreterMandatory = true;
    }
  }

  onEdit() {
    this.showAppellentAddresForm = true;
    this.addressFormatValue = this.getValue('data.appealAddress.addressFormat')
    const countyValue = this.getValue('data.appealAddress.address.county');
    if (this.addressFormatValue?.code === 'MI') {
      const addrApoFpoControl = this.getControl('data.appealAddress.address.addrApoFpo');
      const addrAaAeApControl = this.getControl('data.appealAddress.address.addrAaAeAp');
      const tableVaraddrApoFpo = this.tables.MILITARYPOCODES.filter((item) => item.code === this.exisitngAppellantAddress?.addrApoFpo?.code);
      tableVaraddrApoFpo[0].value = this.exisitngAppellantAddress?.addrApoFpo?.value;
      addrApoFpoControl.patchValue(tableVaraddrApoFpo[0]);
      const tableVaraddrAaAeAp = this.tables.STATEMILITARY.filter((item) => item.code === this.exisitngAppellantAddress?.addrAaAeAp?.code);
      tableVaraddrAaAeAp[0].value = this.exisitngAppellantAddress?.addrAaAeAp?.value;
      addrAaAeApControl.patchValue(tableVaraddrAaAeAp[0]);
      this.getControl('data.appealAddress.address.addressLine1').patchValue(this.exisitngAppellantAddress?.addressLine1);
      this.getControl('data.appealAddress.address.addressLine2').patchValue(this.exisitngAppellantAddress?.addressLine2);
      this.getControl('data.appealAddress.address.zip').patchValue(this.exisitngAppellantAddress?.zip);
    } else if(countyValue?.value) {
      const countyControl = this.getControl('data.appealAddress.address.county');
      const tableCounty = this.tables.COUNTY.filter((item) => item.code === this.exisitngAppellantAddress?.county?.code);
      tableCounty[0].value = this.exisitngAppellantAddress?.county?.value;
      countyControl.patchValue(tableCounty[0]);
      
    }
    this.getControl('data.addressEditValidation').setValidators(Vs.required);
    this.getControl('data.addressEditValidation').updateValueAndValidity();
  }
  
  onSave() {
    this.showAppellentAddresForm = false;
    this.addressFormatValue = this.getValue('data.appealAddress.addressFormat')
    this.exisitngAppellantAddress = this.getValue('data.appealAddress.address');
    this.getControl('data.addressEditValidation').clearValidators();
    this.getControl('data.addressEditValidation').updateValueAndValidity();
  }

  onCancel() {
    this.showAppellentAddresForm = false;
    this.getControl('data.appealAddress.addressFormat').patchValue(this.addressFormatValue);
    this.addressFormatChange('onChange');
    this.getControl('data.appealAddress.address').patchValue(this.exisitngAppellantAddress);
    this.getControl('data.addressEditValidation').clearValidators();
    this.getControl('data.addressEditValidation').updateValueAndValidity();
  }

  ngOnDestroy() {
    this.addressSub.unsubscribe();
  }
}
