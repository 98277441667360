<div class="container">
	<div class="pt-2">
		<h1 class="page-title">Review Financial Reassessment Already in Progress</h1>
	</div>
	<div class="hover-red">
		<a class="hover-red btn btn-link" (click)="onPrevious()">
			<svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
				<g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
					<path id="Combined_Shape" data-name="Combined Shape"
						d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
						transform="translate(0)" fill="#174a7c" />
				</g>
			</svg>
			<span class="pl-2">Back to previous page</span>
		</a>
	</div>
	<div class="container">
		<div class="row py-4">
			<div class="card card-body">
				<section class="container">
					<div>
						<h5 class="section-header">In Progress Financial Reassessment for Medicaid</h5><hr>
						<p>There is already a Financial Reassessment in progress. Do you want to continue this one?</p>
						<p>If so you won't see any changes that may have been reported for this case since the financial reassessment was started. Be sure to review all of you information again to make sure it's correct.</p>
						<p>If you start over, any of the information you changed before will be deleted.</p><br>

						<table class="table w-100 table-striped">
							<thead>
								<tr>
									<th scope="col">Case Number</th>
									<th scope="col">Head of household</th>
									<th scope="col">Started By</th>
									<th scope="col">Started On</th>
									<th scope="col">Last Accessed By</th>
									<th scope="col">Last Accessed On</th>
									<th scope="col" colspan="1">Select</th>
								</tr>
							</thead>
							<tbody *ngIf="caseDetails">
								<tr [ngClass]="{'applyColor': appType === 'continue'}" (click)="onRowSelect('continue')">
									<td>{{caseDetails.caseNumber}}</td>
									<td>{{caseDetails.headOfHousehold}}</td>
									<td>{{caseDetails.startedBy}}</td>
									<td>{{caseDetails.startedOn | date:'MM/dd/yyyy'}}</td>
									<td>{{caseDetails.lastAccessedBy}}</td>
									<td>{{caseDetails.lastAccessedOn | date:'MM/dd/yyyy'}}</td>
									<td>
										<span *ngIf="selectedNew" class="un-selected-circle ml-3"><img src="assets/images/Icons/bank-company-selected.svg" alt="existingApp"></span>
										<span *ngIf="!selectedNew" class="un-selected-circle ml-3"><img src="assets/images/Icons/bank-company-unselected.svg" alt="unselected"></span>
									</td>
								</tr>
								<tr [ngClass]="{'applyColor': appType === 'new'}"  (click)="onRowSelect('new')">
									<td colspan="6"><p><strong>Start a new Financial Reassessment for Medicaid for case {{caseDetails.caseNumber}}</strong></p></td>
									<td>
										<span *ngIf="selectedContinue" class="un-selected-circle ml-3"><img src="assets/images/Icons/bank-company-selected.svg" alt="existingApp"></span>
										<span *ngIf="!selectedContinue" class="un-selected-circle ml-3"><img src="assets/images/Icons/bank-company-unselected.svg" alt="unselected"></span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</section>
			</div>
		</div>
	</div>
	<div class="form-row">
		<div class="col-12 col-md-2 py-3">
			<button class="btn btn-block btn-secondary" (click)="onNext(appType)" [disabled]="appType == null || appType == ''">Next</button>
		</div>
	</div>
</div>