import { complement } from 'ramda';
import { compose } from 'ramda';
import { invoker } from 'ramda';
import { when } from 'ramda';

const { parseFloat, isNaN } = Number;

const isNotNaN = compose(
  complement(isNaN),
  parseFloat
);

const toFixed = invoker(1, 'toFixed');

const toFixedFloat = compose(
  toFixed(2),
  parseFloat
);

export const toFloat = when(isNotNaN, toFixedFloat);
