import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';

import { FullNameSummaryPipe } from '@app/pipes/fullNameSummary/fullnamesummary.pipe';

import { assignAs } from '@app/helpers/function.helpers';
import { viewIndividuals } from '@app/helpers/lenses.helpers';
import { recordIndividual, fromId } from '@app/helpers/record.helpers';

import { Individuals } from '@app/models/individual.model';

@Pipe({ name: 'individual' })
export class IndividualPipe implements PipeTransform {
  private individuals: Individuals;

  constructor(private store: Store, private fullNamePipe: FullNameSummaryPipe) {
    this.store.select(viewIndividuals).subscribe(assignAs(this, 'individuals'));
  }

  transform(individual: any, path: string): string {
    return this.fullNamePipe.transform(recordIndividual(fromId(individual, path), this.individuals));
  }
}
