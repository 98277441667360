import { Component, AfterContentInit } from '@angular/core';
import { BaseModalDirective } from '@app/components/modals/base-modal';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './other-resources.component.html',
})
export class OtherResourcesTypesModalComponent extends BaseModalDirective implements AfterContentInit {
  constructor(builder: FormBuilder, protected modalRef: BsModalRef, public translateService: TranslateService) {
    super(modalRef, builder);

  }
  mobile = window.innerWidth < 768 ? true : false;
  title;
  titleEn = "Other Resource Types";
  titleSp = "Tipo de Otro Recurso";
  title1;
  title1En = "Other Resource Type";
  title1Sp = "Tipos de Otros Recursos"
  description;
  descriptionEn = "Description";
  descriptionSp = "Descripción";
  type1;
  type1En = "Business / Self Employment Equipment";
  type1Sp = "Equipo de negocios/empleo por cuenta propia";
  type1Description;
  type1DescriptionEn = "Items that are essential to run the business, trade, or self-employment like tools, equipment, buildings or land (real property), or office equipment.";
  type1DescriptionSp = "Artículos que son esenciales para administrar el negocio, el comercio o el trabajo por cuenta propia, como herramientas, equipos, edificios o terrenos (bienes inmuebles) o equipos de oficina.";
  type2;
  type2En = "Disaster Assistance";
  type2Sp = "Asistencia en caso de desastre";
  type2Description;
  type2DescriptionEn = "Payments or benefits provided under certain federal statutes like Agent Orange Settlement Payments or Disaster Relief Assistance received under the Disaster Relief Act of 1974.";
  type2DescriptionSp = "Pagos o beneficios facilitados bajo ciertos estatutos federales como Pagos del Acuerdo del Agente Naranja o Asistencia de Ayuda para Desastres recibidos en virtud de la Ley de Ayuda para Desastres de 1974.";
  type3;
  type3En = "Household Goods / Personal Effects";
  type3Sp = "Artículos de uso doméstico/efectos personales";
  type3Description;
  type3DescriptionEn = "Household goods are items you use in or near your home on a regular basis.  Personal Effects are items usually carried or worn or that are sentimental to you.";
  type3DescriptionSp = "Los artículos para el hogar son artículos que usa en su casa o cerca de ella con regularidad. Los efectos personales son artículos que generalmente se llevan o se usan o que son sentimentales para usted.";
  type4;
  type4En = "Livestock";
  type4Sp = "Ganado";
  type4Description;
  type4DescriptionEn = "The value of livestock necessary for your self employment, as a tool of the trade, or that you raise for home or personal use.";
  type4DescriptionSp = "El valor del ganado necesario para su trabajo por cuenta propia, como herramienta del oficio, o que cría para uso doméstico o personal.";
  type5;
  type5En = "Non-Business Equipment";
  type5Sp = "Equipo no comercial";
  type5Description;
  type5DescriptionEn = "Equipment not used for business.";
  type5DescriptionSp = "Equipo no utilizado para negocios.";
  type6;
  type6En = "Oil and Mineral Rights";
  type6Sp = "Derechos sobre petróleo y minerales";
  type6Description;
  type6DescriptionEn = "Oil and mineral rights may be included with land ownership or owned separately.";
  type6DescriptionSp = "Los derechos sobre petróleo y minerales pueden incluirse con la propiedad de la tierra o poseerse por separado.";
  type7;
  type7En = "Other";
  type7Sp = "Otro";
  type7Description;
  type7DescriptionEn = "Any other resources or items that considered to be of great value or worth.";
  type7DescriptionSp = "Cualquier otro recurso o artículo que se considere de gran valor o valor.";
  
  onResize(e) {
    this.mobile = e.target.innerWidth < 768 ? true : false;
  }
  ngAfterContentInit() {
    const defaultLanguage = this.translateService.getDefaultLang();
    if (defaultLanguage !== 'en') {
      this.title = this.titleSp
      this.title1 = this.title1Sp
      this.description = this.descriptionSp
      this.type1 = this.type1Sp
      this.type1Description = this.type1DescriptionSp
      this.type2 = this.type2Sp
      this.type2Description = this.type2DescriptionSp
      this.type3 = this.type3Sp
      this.type3Description = this.type3DescriptionSp
      this.type4 = this.type4Sp
      this.type4Description = this.type4DescriptionSp
      this.type5 = this.type5Sp
      this.type5Description = this.type5DescriptionSp
      this.type6 = this.type6Sp
      this.type6Description = this.type6DescriptionSp
      this.type7 = this.type7Sp
      this.type7Description = this.type7DescriptionSp
    } else {
      this.title = this.titleEn
      this.title1 = this.title1En
      this.description = this.descriptionEn
      this.type1 = this.type1En
      this.type1Description = this.type1DescriptionEn
      this.type2 = this.type2En
      this.type2Description = this.type2DescriptionEn
      this.type3 = this.type3En
      this.type3Description = this.type3DescriptionEn
      this.type4 = this.type4En
      this.type4Description = this.type4DescriptionEn
      this.type5 = this.type5En
      this.type5Description = this.type5DescriptionEn
      this.type6 = this.type6En
      this.type6Description = this.type6DescriptionEn
      this.type7 = this.type7En
      this.type7Description = this.type7DescriptionEn
    }
  }
}
