import { construct, useWith, identity, prop, converge, map, compose } from 'ramda';
import { compact } from 'ramda-adjunct';

import { NotificationMessage, NotificationError } from '@app/models/partials.model';
import { ifNun } from './null.helpers';
import { codes } from './code.helpers';
import { lookUp } from './flipped.helpers';

const notificationMessage = construct(NotificationMessage);
export const dangerMessage = construct(NotificationError);

const infoMessage = notificationMessage(false, 'info');

const alertMessage = 'Enter the information for the disabled child only.';

const KB = {
  HCPPG: 'The casehead is the parent or the individual responsible for the disabled child.',
  ICPPG: alertMessage,
  XPPPG: alertMessage,
  ASPPG: alertMessage,
  INPPG: alertMessage,
  APPPG: alertMessage,
};

const messages = lookUp({ KB });
const newMessage = ifNun(infoMessage);
const selectMessage = useWith(prop, [identity, messages]);
const createMessage = converge(newMessage, [selectMessage]);
export const findNotifications = compose(
  compact,
  useWith(map, [createMessage, codes])
);
