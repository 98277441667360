<p *ngIf="isRmbRmc">{{ "RENEWAL_TEXT" | translate }}</p>
<div *ngIf="getValue('extras.topLevelAnswer') === 'YES'" form-top><h6 class="person-label"> {{ selectedIndividual | fullname }}</h6> </div>
<nh-form [topLevelName] = "'li'" [individuals]="checkForDropdown ? finalListAll : individuals" [formGroup]="getForm()" [mode]="mode" class="label-section" topLevelQuestion="{{'LIFE_INSURANCE_TOP_LEVEL' | translate }}"
  individualQuestion="{{'LIFE_INSURANCE_INDIV_QUES' | translate }}" (validate)="onValidate($event)">
  <br>
  <h5 *ngIf="!isRmbRmc" class="section-header">
    {{'LIFE_INSURANCE_TITLE' | translate }}
  </h5> 
  <h5 *ngIf="isRmbRmc" class="section-header">
    {{'LIFE_INSURANCE_TITLE_RMBC' | translate }}
  </h5> 
  <hr>
 
  <section [formGroup]="getControl('data')">
    <nh-field id = "li_assetType" label="{{'LIFE_INSURANCE_STATIC_TEXT1' | translate }} {{ selectedIndividual | fullname }}{{'VEHICLE_STATIC_TEXT3' | translate }} <div class=hint>{{'IF' | translate }} {{ selectedIndividual | fullname }} {{'LIFE_INSURANCE_STATIC_TEXT2' | translate }}</div>" class="label-section">
      <select nh name="assetType" [items]="tableCodeValue('DCLIFEINSURANCETYPE', 'data.assetType')" (change)="assetTypeValidate()" formControlName="assetType"></select>
    </nh-field>
    <nh-field id = "li_faceAmount" label="{{'LIFE_INSURANCE_STATIC_TEXT3' | translate }}" class="label-section">
      <nh-popover class="pl-1" [content]="'LIFE_INSURANCE_STATIC_TEXT4' | translate"></nh-popover>
      <nh-input-group prepend="$">
        <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="faceAmount" formControlName="faceAmount" class="form-control">
            </nh-input-group>
        </nh-field>
        <section *ngIf="isEnabled('data.assetAmount')">
            <nh-field id = "li_assetAmount" [mandatory]="false" label="{{'LIFE_INSURANCE_STATIC_TEXT5' | translate }}" class="label-section">
                <nh-popover class="pl-1" [content]="'LIFE_INSURANCE_STATIC_TEXT6' | translate"></nh-popover>
                <nh-input-group prepend="$">
                  <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="assetAmount" formControlName="assetAmount" class="form-control">
                </nh-input-group>
            </nh-field>
        </section>
        <nh-field id = "li_policyNumber" [mandatory]="false" label="{{'POLICY_NUMBER' | translate }}" class="label-section">
            <input type="text" name="policyNumber" formControlName="policyNumber" maxlength="16" class="form-control">
        </nh-field>
    <br>
    <h5 class="section-header">
      {{'LIFE_INSURANCE_TITLE2' | translate }}
    </h5><hr>
    <nh-alert type="warning" [isOpen]="getControl('data.assetAddress').touched">
      <div class="row">
        <div class="icon">
          <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="danger" />
        </div>
        <div class="message">
          <span>{{'ADDRESS_WARNING' | translate }}</span>
        </div>
      </div>
    </nh-alert>
    <nh-field id = "li_insuranceName" [mandatory]="false" label="{{'COMPANY_NAME' | translate }}" class="label-section">
      <input type="text" name="insuranceName" formControlName="insuranceName" maxlength="50" class="form-control">
    </nh-field>
    <nh-name-address [addressname] = "'li_assetAddress'" [addressFieldMandatory]="false" [addressFormat]="null" [stateDefault]="false" [showPopup]="false" [address]="getControl('data.assetAddress')" [tables]="tables" [hideCounty]="true"></nh-name-address><br>
    <nh-field id = "li_phoneNumber" [mandatory]="false" label="{{'PHONE_NUMBER2' | translate }}" class="label-section">
      <input type="text" name="phoneNumber" formControlName="phoneNumber" class="form-control" mask="000-000-0000" placeholder="XXX-XXX-XXXX">
    </nh-field>
  </section>
</nh-form>
