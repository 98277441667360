import { Directive, HostBinding } from '@angular/core';

// tslint:disable-next-line:directive-selector
@Directive({ selector: 'input,textarea,select' })
export class InputDirective {
  @HostBinding('attr.autocomplete') autocomplete = 'off';
  @HostBinding('attr.autocorrect') autocorrect = 'off';
  @HostBinding('attr.autocapitalize') autocapitalize = 'none';
  @HostBinding('attr.spellcheck') spellcheck = false;
}
