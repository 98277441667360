<div class="container">
	<div class="row">
		<div class="col-8">
			<h1 class="page-title">{{ headerTitle | translate }}</h1>
		</div>
	</div>
	<div class="card mb-4 bg-custom-register">
		<h5 class="section-header">{{ sectionTitle | translate }}</h5>
		<hr>
		<section *ngIf="isChangeEmailLinkValid">
			<div class="row">
				<div class="col-md-12 col-xs-12">
					<p [innerHtml]="'EMAIL_CHANGE' | translate"></p>
				</div>
			</div>
			<div class="row">
				<div class="row pt-1 col-md-12 col-xs-12 hover-red comments">
					<a class="btn btn-link hover-red" [routerLink]="['/signin']">
					  <span class="pr-2">{{'BACK_TO_LOGIN' | translate }}</span>
					  <nh-exit-svg></nh-exit-svg>
					</a>
				</div>
			</div>
		</section>
		<section *ngIf="!isChangeEmailLinkValid">
			<div class="row">
				<div class="col-md-12 col-xs-12">
					<p>{{'CHANGE_EMAIL_LINK_EXPIRED' | translate }}</p>
				</div>
			</div>
			<div class="row">
			<div class="row pt-1 col-md-12 col-xs-12 hover-red comments">
				<a class="btn btn-link hover-red" [routerLink]="['/signin']">
				  <span class="pr-2">{{'BACK_TO_LOGIN' | translate }}</span>
				  <nh-exit-svg></nh-exit-svg>
				</a>
			</div>
			</div>
		</section>
	</div>
</div>
