import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'nh-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls:['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {
  @Input() control: FormControl;
  @Input() checkedValue: any;
  @Input() uncheckedValue: any = null;
  @Input() label: string;
  @Input() name: string;
  @Input() inline = false;
  @Input() disabled = false;
  @Input() mandatory = false;
  @Input() index: string;

  get isDisabled() {
    if (this.disabled) {
      return true;
    } else {
      return null;
    }
  }
}
