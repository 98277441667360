import { RecordCreate, RecordUpdate, RecordRemove, DataRecordRemove } from '@app/models/state.model';
import { SectionStateModel, SectionLoad } from '@app/models/state.model';
import { VerificationAnswer } from '@app/models/partials.model';
import { TopLevelAnswer } from '@app/models/section.model';
import { Record } from '@app/models/record.model';
import { Payload } from '@app/models/payload.model';

export class LoadSection {
  static readonly type = '[SECTION] Load Section';
  constructor(public payload: SectionLoad) {}
}

export class SetSection {
  static readonly type = '[SECTION] Save Section';
  constructor(public payload: SectionStateModel) {}
}

export class UpdateSection {
  static readonly type = '[SECTION] Update Section';
  constructor(public payload: TopLevelAnswer) {}
}

export class UpdatePeopleSumSection {
  static readonly type = '[SECTION] Update People Summary Section';
  constructor(public payload: any) {}
}

export class CreateRecord {
  static readonly type = '[SECTION] Create Record';
  constructor(public payload: RecordCreate) {}
}

export class UpdateRecord {
  static readonly type = '[SECTION] Update Record';
  constructor(public payload: RecordUpdate) {}
}

export class RemoveRecord {
  static readonly type = '[SECTION] Remove Record';
  constructor(public payload: RecordRemove) {}
}

export class RemoveDataRecord {
  static readonly type = '[SECTION] Remove Data Record';
  constructor(public payload: DataRecordRemove) {}
}

export class SetRecord {
  static readonly type = '[SECTION] Set Record';
  constructor(public payload: Record) {}
}

export class SaveQuestionnaire {
  static readonly type = '[SECTION] Verify Id';
  constructor(public payload: VerificationAnswer) {}
}

export class HandlePayload {
  static readonly type = '[SECTION] Handle Payload';
  constructor(public payload: Payload) {}
}
