<div class="container">
    <div class="row">
        <div class="card" style="width:100%;">
            <div class="card-body">
                <nav class="d-lg-block">
                    <ul class="nav nav-tabs nav-justified nav-fill flex-lg-row tab-height">
                        <li class="nav-item item-height">
                            <a class="nav-link d-flex flex-row justify-content-center p-0 link-height" routerLinkActive="active"
                            [routerLink]="['documents-we-need']"><span class="tab-section">{{'DOCUMENTS_WE_NEED' | translate }}</span></a>
                        </li>
                        <li class="nav-item item-height">
                            <a class="nav-link d-flex flex-row justify-content-center p-0 pr-1 link-height"
                                [routerLink]="['uploaded-documents']" routerLinkActive="active"><span class="tab-section">{{'UPLOADED_DOCUMENTS' | translate }}</span></a>
                        </li>
                        <li class="nav-item item-height">
                            <a class="nav-link d-flex flex-row justify-content-center p-0 link-height" routerLinkActive="active" [routerLink]="['case-documents']">
                                <span class="tab-section">{{'DOCUMENTS_ON_MYCASE_STATI1' | translate }}</span></a>
                        </li>
                    </ul>
                </nav>
                <div>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
