import { SummaryAppealComponent } from './summary-appeal.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { ContinueLabels, CancelLabels } from '@app/models/section.model';

const formValidation = (fb, recordData) => ({ data: fb.group(recordData) });

export default sectionConfiguration(SummaryAppealComponent, formValidation, [], [], {}, {
  hasSummary: false,
  showComments: true,
  showSubmit: false,
  labelContinue: ContinueLabels.FILE_APPEAL,
  labelCancel: CancelLabels.CANCEL
});
