<div class="container logout">
  <div class="row">
    <div class="col">
      <h1 class="page-title">{{'ERROR_TEXT1' | translate}}<span *ngIf="mobileUser" class="page-title">!</span></h1>
    </div>
  </div>
  <div class="bg-custom">

    <div class="container manage-benefits">
      <div>
        <div class="mt-3">
          <div class="container col-xs-12 .col-12 form-row">
            <div class="col-md-12 col-sm-12">
              <div class="py-4">
                <b> {{'ERROR_TEXT2' | translate}} Time: {{ currentDate | date: 'EEE MMM dd hh:mm:ss yyyy' }} </b>
              </div>
            </div>

            <div class="col-md-12 col-sm-12">
              <div class="py-4">
                <p>{{'ERROR_TEXT3' | translate}}</p>
                <br *ngIf="!mobileUser">
                <p *ngIf="!mobileUser">{{'ERROR_TEXT4' | translate}}</p>
              </div>
            </div>

            <div *ngIf="mobileUser" class="col-md-12 col-sm-12">
              <div class="py-2">
                <p>{{'ERROR_TEXT6' | translate}}</p>
              </div>
            </div>

            <div *ngIf="!mobileUser" class="col-md-12 col-sm-12">
              <div class="py-4 text-align">
                <hr>
                <div style="padding-top:20px">
                  <a class="btn-link btn-go-home" (click)="gotoHome()"> {{'ERROR_TEXT5'| translate}}</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
