
import { evolve, map, compose } from 'ramda';
import { TaxDependentsOutsideComponent } from './tax-dependents-outside.component';

import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration, createOutsideHousehold } from '@app/helpers/models.helpers';
import { toISO } from '@app/helpers/date.helpers';
import { aPath } from '@app/helpers/function.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';

const formValidation = (builder, recordData) => {

  const taxDependentOutside = lookIn(recordData);
  const hasTaxDependentOutside = taxDependentOutside(['hasTaxDependentOutside']);

  const taxDependentsOutside = aPath(['taxDependentsOutside'], recordData)

  const outsideHousehold = compose(
    control => builder.group(control, { updateOn: 'change' }),
    createOutsideHousehold
  );

  const data = builder.group({
    hasTaxDependentOutside: [hasTaxDependentOutside, Vs.required],
    taxDependentsOutside: builder.array(map(outsideHousehold, taxDependentsOutside))
  });

  return { data };
};

const postObject = evolve({
  taxDependentsOutside: map(
    evolve({
      birthDate: toISO
    })
  ),
});

export default sectionConfiguration(
  TaxDependentsOutsideComponent,
  formValidation,
  [],
  [],
  {},
  {
    postObject,
    hasSummary: false,
    showComments: false
  }
);
