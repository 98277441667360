<div class="container" >
  <div class="row" *ngIf="this.appType === 'Application'">
    <div class="card card-body">
      <section class="container mb-2">
        <div>
          <h1 class="page-title">TennCare Connect Application</h1>
        </div>
        <div>
          <!-- <div *ngIf="!hasPpAccess">
            <p class="mt-2">You haven't been approved to use TennCare Access yet. Contact your Organization Administrator.</p>
            <hr>
          </div> -->
          <div>
            <div class="mt-3">
              <h5 class="section-header">Full Coverage Application</h5>
              <hr>
            </div>
            <div class="card m-3 mt-2">
              <div class="row align-items-center p-3">
                <div>
                  <div><img class="col dot" aria-label="afb" src="../../../assets/images/Icons/Welcome_FullCovgApp.svg">
                  </div>
                </div>
                <div class="col" (click)="searchCoverage()">
                  <strong class="tile_header">Apply for Full Coverage</strong>
                  <p>Start an application for TennCare coverage. This includes TennCare and CoverKids. Don’t use
                    this application if you are trying to help someone apply for presumptive eligibility (PE) coverage.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h5 class="ml-3 section-header">Other Online Forms and Features</h5>
          <hr>
          <div class="card m-3">
            <div class="row align-items-center p-3">
              <div>
                <div><img class="col dot" aria-label="searchSubmission" src="../../../assets/images/Icons/Welcome_SearchSubmissions.svg"></div>
              </div>
              <div class="col" (click)="searchSubmissions()">
                <strong class="tile_header"> Search My Submissions </strong>
                <p>Search for applications you have submitted to review the application summary or upload
                  documents if TennCare has not started working the application yet. You can also search for
                  applications you have started but not finished.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <div *ngIf="this.appType === 'REDETERMINATION' && isDataLoaded">
    <div class="row mb-2">
      <div class="col-12">
        <h1 class="page-title">Review Renewal Already In Progress</h1>
      </div>
    </div>
        <!-- <nh-alert type="danger" [isOpen]="optionNotSelected">
          <div class="row">
            <div class="icon">
              <img src="../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
            </div>
            <div class="message">
              <span>{{ 'SELECT_TILE' | translate}}</span>
            </div>
          </div>
        </nh-alert> -->
    <!-- <div class="row pl-1 pb-1 hover-red">
      <div class="btn btn-link hover-red" (click)="onPreviousClick()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
          <g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
            <path id="Combined_Shape" data-name="Combined Shape"
              d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
              transform="translate(0)" fill="#174a7c" />
          </g>
        </svg>
        <span class="pl-2">{{'BACK_TO_PREVIOUS_PAGE' | translate}}</span>
      </div>
    </div> -->
    <div class="bg-white p-4">
      <h5 class="section-header">In Progress Renewal</h5><hr>
      <p class="static-text">From here, you can pick up the renewal where the last call center worker left off or start over.</p>
      <p class="static-text">If any changes were reported since starting the renewal, you may not see them here. Be sure to review all the information to make sure it’s correct.</p>
      <p class="static-text">If you start a new renewal, all the information entered before will be deleted.</p>
      <div>
        <div>
          <div class="card card-body shadow mb-2" [ngClass]="{'applyColor': selectedValue === 'continue'}" (click)="select('continue')" role="button">
            <div class="row pl-2">
              <div class="row spl-margin  pl-3"><img class="col dot" aria-label="takeMeBack" src="../../../assets/images/Icons/Take_back.svg"></div>
              <div class="section-column">
                <strong class="tile_header">Take me back to renewal in progress</strong>
                <div *ngIf="caseDetails">
                  <div class="small tile-margin tile-text">Case Number: {{caseDetails.caseNumber}}</div>
                  <div class="small tile-text">Head of Household: {{caseDetails.headOfHousehold}}</div>
                  <div class="small tile-text">Started By: {{caseDetails.startedBy}}</div>
                  <div class="small tile-text">Started On: {{caseDetails.startedOn | date:'MM/dd/yyyy'}}</div>
                  <div class="small tile-text">Last Accessed By: {{caseDetails.lastAccessedBy}}</div>
                  <div class="small tile-text">Last Accessed On: {{caseDetails.lastAccessedOn | date:'MM/dd/yyyy'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card card-body shadow" [ngClass]="{'applyColor': selectedValue === 'new'}" (click)="select('new')">
            <div class="row pl-2">
              <div class="row spl-margin pl-3"><img class="col dot" aria-label="startNew" src="../../../assets/images/Icons/Start_new_app.svg"></div>
              <div class="section-column spl-padding"><strong class="tile_header">Start renewal from the beginning</strong>
                <div *ngIf="caseDetails">
                  <div class="small tile-margin tile-text">Case Number: {{caseDetails.caseNumber}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-3">
      <button id="continue" class="col-md-2 btn btn-secondary" (click)="next()">{{'NEXT'}}</button>
    </div>
  </div>
</div>