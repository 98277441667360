import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

export declare var grecaptcha: any;
declare const window: any;

@Injectable()
export class RecaptchaLanguageLoaderService {
  public ready: Observable<any>;
  public language = '';
  private static ready: BehaviorSubject<any>;

  constructor() {
    this.init();
    this.ready = RecaptchaLanguageLoaderService.ready.asObservable();
  }

  public updateLanguage(newLang: string): void {
    this.language = newLang;
    RecaptchaLanguageLoaderService.ready.next(null);
    this.init();
  }

  private init() {
    if (RecaptchaLanguageLoaderService.ready) {
      if (RecaptchaLanguageLoaderService.ready.getValue()) {
        return;
      }
    } else {
      RecaptchaLanguageLoaderService.ready = new BehaviorSubject<any>(null);
      window.ng2recaptchaloaded = () => {
        RecaptchaLanguageLoaderService.ready.next(grecaptcha);
      };
    }

    const langParam = this.language ? '&hl=' + this.language : '';
    const googleRecaptchaURL = `https://www.google.com/recaptcha/api.js?render=explicit&onload=ng2recaptchaloaded${langParam}`;
    this.removeScript();
    this.appendScript(googleRecaptchaURL);
  }

  private appendScript(src) {
    const script = document.createElement('script') as HTMLScriptElement;
    script.innerHTML = '';
    script.src = src;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }

  private removeScript(){
    const script = document.querySelectorAll('[src*="www.gstatic.com/recaptcha"]');
    script.forEach(element => {
      document.head.removeChild(element)
    });
  }
}
