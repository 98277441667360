<nh-form [formGroup]="getForm()" (validate)="validate($event)">
  <section *ngIf="modules && form">
    <div class="row py-3">
      <div class="col-12">
        <p *ngIf="showKtMsg" (click)="toTenncare()" class="static-text" [innerHTML]="'RMC_STATIC_TEXT0' | translate"></p>
        <p class="static-text">{{'REPORT_CHANGES__START_STATIC_TEXT1' | translate }}</p>
        <h5 class="section-header"><strong>{{'REPORT_MY_CHANGES' | translate }}</strong></h5><hr>
        <p class="static-text" [innerHTML]="'REPORT_CHANGES__START_STATIC_TEXT2' | translate"></p>
      </div>
    </div>
    <div class="row report-grid">
      <div class="col-md-12 report-section">
        <div class="sections border p-4 m-3" [ngClass]="{'selected': contactInfo, 'disabled': disable('contactInfo')}" (click)="toggleValue('contactInfo')">
          <span class="section-column">
            <img class="col dot" aria-label="contactInfo" src="assets/images/Report changes/Contact Info.svg"/>
          </span>
          <span class="section-column">
            <div class="tile_header"><strong>{{'REPORT_CHANGES__START_STATIC_TEXT3' | translate }}</strong></div>
            <span class="description">
              {{'REPORT_CHANGES__START_STATIC_TEXT4' | translate }}<br/>{{'REPORT_CHANGES__START_STATIC_TEXT5' | translate }}
            </span>
          </span>
        </div>
      </div>
      <div class="col-md-12 report-section">
        <div class="sections border p-4 m-3" [ngClass]="{'selected': houseHoldMembers, 'disabled': disable('houseHoldMembers')}" (click)="toggleValue('houseHoldMembers')">
          <span class="section-column">
            <img class="col dot" aria-label="houseHoldMembers" src="assets/images/Report changes/Household Members.svg"/>
          </span>
          <span class="section-column">
            <div class="tile_header"><strong>{{'REPORT_CHANGES__START_STATIC_TEXT6' | translate }}</strong></div>
            <span class="description">
              {{'REPORT_CHANGES__START_STATIC_TEXT7' | translate }}<br/>
              {{'REPORT_CHANGES__START_STATIC_TEXT8' | translate }}<br/>
              {{'REPORT_CHANGES__START_STATIC_TEXT10' | translate }}
            </span>
          </span>
        </div>
      </div>
      <div class="col-md-12 report-section">
        <div class="sections border p-4 m-3" [ngClass]="{'selected': houseHoldInfo, 'disabled': disable('houseHoldInfo')}" (click)="toggleValue('houseHoldInfo')">
          <span class="section-column">
            <img class="col dot" aria-label="houseHoldInfo" src="assets/images/Report changes/Household Info.png"/>
          </span>
          <span class="section-column">
            <div class="tile_header"><strong>{{'REPORT_CHANGES__START_STATIC_TEXT11' | translate }}</strong>
              <nh-popover class="pl-1 householdTooltip" content="{{'REPORT_CHANGES__START_STATIC_TEXT33' | translate}}"></nh-popover>
            </div>
            <span class="description">
              {{'REPORT_CHANGES__START_STATIC_TEXT12' | translate }}<br/>{{'REPORT_CHANGES__START_STATIC_TEXT13' | translate }}<br/>
            </span>
          </span>
        </div>
      </div>
      <div class="col-md-12 report-section">
        <div class="sections border p-4 m-3" [ngClass]="{'selected': tax, 'disabled': disable('tax')}" (click)="toggleValue('tax')">
          <span class="section-column">
            <img class="col dot" aria-label="taxInfo" src="assets/images/Report changes/TaxInformation.svg"/>
          </span>
          <span class="section-column">
            <div class="tile_header"><strong>{{'REPORT_CHANGES__START_STATIC_TEXT9' | translate }}:</strong></div>
            <span class="description">
              {{'REPORT_CHANGES__START_STATIC_TEXT14' | translate }}
            </span>
          </span>
        </div>
      </div>
      <div class="col-md-12 report-section">
        <div class="sections border p-4 m-3" [ngClass]="{'selected': income, 'disabled': disable('income')}" (click)="toggleValue('income')">
          <span class="section-column">
            <img class="col dot" aria-label="incomeEmp" src="assets/images/Report changes/Income&Employment.svg"/>
          </span>
          <span class="section-column">
            <div class="tile_header"><strong>{{'REPORT_CHANGES__START_STATIC_TEXT15' | translate }}</strong></div>
            <span class="description">{{'REPORT_CHANGES__START_STATIC_TEXT16' | translate }}<br/>{{'REPORT_CHANGES__START_STATIC_TEXT17' | translate }}<br>{{'REPORT_CHANGES__START_STATIC_TEXT18' | translate }}</span>
          </span>
        </div>
      </div>
      <div class="col-md-12 report-section">
        <div class="sections border p-4 m-3" [ngClass]="{'selected': other, 'disabled': disable('other')}" (click)="toggleValue('other')">
          <span class="section-column">
            <img class="col dot" aria-label="otherBenifits" src="assets/images/Report changes/OtherBenefits.svg"/>
          </span>
          <span class="section-column">
            <div class="tile_header"><strong>{{'REPORT_CHANGES__START_STATIC_TEXT19' | translate }}</strong></div>
            <span class="description">
              {{'REPORT_CHANGES__START_STATIC_TEXT20' | translate }}<br/>
              {{'REPORT_CHANGES__START_STATIC_TEXT21' | translate }}<br/>
              {{'REPORT_CHANGES__START_STATIC_TEXT22' | translate }}<br/>
              {{'REPORT_CHANGES__START_STATIC_TEXT23' | translate }}
            </span>
          </span>
        </div>
      </div>
      <div class="col-md-12 report-section">
        <div class="sections border p-4 m-3" [ngClass]="{'selected': transfer, 'disabled': disable('transfer')}" (click)="toggleValue('transfer')">
          <span class="section-column">
            <img class="col dot" aria-label="transfer" src="assets/images/Report changes/Resources.svg"/>
          </span>
          <span class="section-column">
            <div class="tile_header"><strong>{{'OTHER_HEALTHCARE_STATIC_TEXT44' | translate }}</strong></div>
          </span>
        </div>
      </div>
      <div class="col-md-12 report-section">
        <div class="sections border p-4 m-3" [ngClass]="{'selected': expenses, 'disabled': disable('expenses')}" (click)="toggleValue('expenses')">
          <span class="section-column">
            <img class="col dot" aria-label="expenses" src="assets/images/Report changes/Expenses.svg"/>
          </span>
          <span class="section-column">
            <div class="tile_header"><strong>{{'EXPENSES' | translate }}</strong></div>
            <span class="description">
              {{'REPORT_CHANGES__START_STATIC_TEXT24' | translate }}<br/>{{'REPORT_CHANGES__START_STATIC_TEXT25' | translate }}<br/>{{'Mandatory Payments' | translate }}<br/>{{'REPORT_CHANGES__START_STATIC_TEXT26' | translate }}<br/>{{'REPORT_CHANGES__START_STATIC_TEXT34' | translate }}
            </span>
          </span>
        </div>
      </div>
      <div class="col-md-12 report-section">
        <div class="sections border p-4 m-3" [ngClass]="{'selected': assets, 'disabled': disable('assets')}" (click)="toggleValue('assets')">
          <span class="section-column">
            <img class="col dot" aria-label="assets" src="assets/images/Report changes/Resources.svg"/>
          </span>
          <span class="section-column">
            <div class="tile_header"><strong>{{'RESOUCES' | translate }}</strong></div>
            <span class="description">
              {{'REPORT_CHANGES__START_STATIC_TEXT27' | translate }}<br/>{{'REPORT_CHANGES__START_STATIC_TEXT28' | translate }}<br/>{{'REPORT_CHANGES__START_STATIC_TEXT29' | translate }}<br/>{{'Vehicles' | translate }}
            </span>
          </span>
        </div>
      </div>
      <div class="col-md-12 report-section">
        <div class="sections border p-4 m-3" [ngClass]="{'selected': endCoverage, 'disabled': disable('endCoverage')}" (click)="toggleValue('endCoverage')">
          <span class="section-column">
            <img class="col dot" aria-label="endCoverage" src="assets/images/Report changes/End Coverage.svg"/>
          </span>
          <span class="section-column">
            <div class="tile_header"><strong>{{'REPORT_CHANGES__START_STATIC_TEXT30' | translate }}</strong></div>
          </span>
        </div>
      </div>
      <div *ngIf="showKtTile" class="col-md-12 report-section">
        <div class="sections border p-4 m-3" [ngClass]="{'selected': kb, 'disabled': disable('kb')}" (click)="toggleValue('kb')">
          <span class="section-column">
            <img class="col dot" aria-label="katieBeckett" src="assets/images/Report changes/KatieBeckett.svg"/>
          </span>
          <span class="section-column">
            <div class="tile_header"><strong>Katie Beckett:</strong></div>
            <span *ngIf="changeKt" class="description">{{'REPORT_CHANGES__START_STATIC_TEXT31' | translate }}</span><br>
            <span *ngIf="referralKt" class="description">{{'REPORT_CHANGES__START_STATIC_TEXT32' | translate }}</span>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="!closePregDueDateNudge">
      <nh-nudge *ngIf="showPregDueDateNudge === 'Y'" [message]="'NUDGETEXT_03' | translate"></nh-nudge>
    </div>
  </section>
</nh-form>
