import { Pipe, PipeTransform } from '@angular/core';

import { individualName } from '@app/helpers/people.helpers';

import { Individual } from '@app/models/individual.model';

@Pipe({ name: 'fullnamesummary' })
export class FullNameSummaryPipe implements PipeTransform {
  transform(individual: Individual, alternative = 'this person'): string {
    return individualName(alternative, individual) + ", " + individual.age + " " + individual.gender.code;
  }
}
