<div class="container">
    <div class="bg-custom">
      <div>
        <h4>
          <strong>Reference Table Data</strong>
        </h4>
      </div>
      <section>
        <div class="row">
          <div class="col-md-12">
            <form [formGroup]="rtForm">
              <div class="form-row">
                <div class="col-8 col-md-4">
                  <nh-field [isRow]="true" label="Reference Table ID" class="label-section">
                    <input type="text" name="tableId" formControlName="tableId" class="form-control" required>
                  </nh-field>
                </div>
  
                <div class="col-4 col-md-6">
                  <nh-field [isRow]="true" label="Reference Table Name" class="label-section" required>
                    <input type="text" name="tableName" formControlName="tableName" class="form-control">
                  </nh-field>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-3 offset-md-6">
                  <button id="comments" class="btn btn-block btn-outline-primary" (click)="search()">Search</button>
                </div>
                <div class="col-md-3">
                  <button id="continue" class="btn btn-block btn-primary" (click)="clearSearch()">Clear</button>
                </div>
              </div>
              <h4 class="mt-4" *ngIf="this.hasNoData">There are no search results for the above search parameters</h4>
              <div>
                <div *ngIf="this.hasRows">
                  <h4><strong>Column Details:</strong></h4>
                  <table class="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Spanish Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of rowData">
                        <th>{{ item.rowid }}</th>
                        <td>{{ item.code }}</td>
                        <td>{{ item.enDescription }}</td>
                        <td>{{ item.spDescription }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div formArrayName="columnDetails" class="mt-4">
                  <div *ngFor="let col of columnDetails.controls; let i = index">
                    <div [formGroupName]="i">
                      <div class="form-row">
                          <div class="col-8 col-md-3">
                            <nh-field [isRow]="true" label="Code:" class="label-section">
                              <input type="text" name="code" formControlName="code" class="form-control" required>
                            </nh-field>
                          </div>
                          <div class="col-4 col-md-4">
                            <nh-field [isRow]="true" label="Description" class="label-section">
                              <input type="text" name="enDescription" formControlName="enDescription" class="form-control" required>
                            </nh-field>
                          </div>
                          <div class="col-4 col-md-4">
                            <nh-field [isRow]="true" label="Spanish Description" class="label-section">
                                <input type="text" name="spDescription" formControlName="spDescription" class="form-control" required>
                            </nh-field>
                          </div>
                          <div class="col-8 col-md-1 times">
                            <span (click)="removeColumns(i)"><i class="fa fa-times-circle fa-lg"></i></span>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="this.hasNoData || this.hasRows" class="row mt-4">
                  <div class="col-md-3 offset-md-9">
                    <button id="continue" class="btn btn-block btn-outline-primary" (click)="addColumns('true')">Add Columns</button>
                  </div>
                </div>
                <div *ngIf="this.showCol" class="row mt-4">
                  <div class="col-md-3 offset-md-9">
                    <button id="save" class="btn btn-block btn-primary" (click)="onSave()">Save</button>
                  </div>
                </div>
              </div>
              <!-- {{this.rtForm.value | json}} -->
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
  