<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title pull-left" id="my-modal-title" >{{'ADD_COMMENTS' | translate }}</h5><hr>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
    </button>
  </div>
  <div class="modal-body">
      <div *ngIf="showAlert" class="alert alert-warning" role="alert">
        <div class="static-text"><span><i class="fa fa-exclamation-circle"></i></span>&nbsp;&nbsp;<span [innerHTML]="'COMMENTS_CANCEL_WARNING' | translate"></span></div>
      </div>
      <div>
        <p>{{'ADD_OR_MODIFY_COMMENT' | translate: {type: appKeyWord | translate} }}</p>
      </div>
      <nh-count>
        <textarea maxlength="2000" class="form-control" name="comments"  aria-label="comments" [formControl]="commentsControl" rows="5"></textarea>
      </nh-count>
  </div>

  <!-- Standard markup for a 2 buttons footer; just change the button names & events -->
  <div class="modal-footer d-block">
  <section class="row mt-2 pb-3 ml-2 main-content-width" style="overflow-x: hidden;">
  <div class="mr-3 pr-md-1 py-sm-1">
    <button type="button" id="dialog" class="btn btn-block btn-secondary" (click)="onConfirm()">{{'SAVE' | translate }}</button>
  </div>
  <div class="pr-md-1 py-sm-1">
    <button type="button" id="dialog" class="btn btn-block btn-link" (click)="onCancel()">{{'CANCEL' | translate }}</button>
  </div>
</section>
  </div>
</div>