<p *ngIf="isRmbRmc">{{ "RENEWAL_TEXT" | translate }}</p>
<h6 class="person-label">{{selectedIndividual | fullname}}</h6>
<nh-form [topLevelName] = "'dec'" [individuals]="checkForDropdown ? finalListIndvs : individuals" [formGroup]="getForm()" [mode]="mode" class="label-section"
  topLevelQuestion="{{'DEPENDENT_CARE_STATIC_TEXT1' | translate }}"
  [individualQuestion]="this.individualQues"
  popupText="{{'DEPENDENT_CARE_STATIC_TEXT2' | translate }}"
  (validate)="onValidate($event)">

  <section [formGroup]="getControl('data')">
    <div [formGroup]="getControl('data.dependantCareInformation')">
      <br>
      <h5 class="section-header">
        {{'DEPENDENT_CARE_STATIC_TEXT3' | translate }}
      </h5><hr>
      <nh-field id = "dec_dependantIndivNumber" label="{{'DEPENDENT_CARE_STATIC_TEXT4' | translate }}" class="label-section">
        <select nh name="dependantIndivNumber" aria-label="dependantIndivNumber" [attr.disabled]="isReadOnlyDependent" [items]="indi"
          bindValue="identifier" bindLabel="name.fullName" formControlName="dependantIndivNumber"
          (change)="setIndiv()"></select>
      </nh-field>

      <nh-field id = "dec_personOutOfHouseholdName" *ngIf="isEnabled('data.dependantCareInformation.personOutOfHouseholdName')"
        label="{{'DEPENDENT_CARE_STATIC_TEXT5' | translate }}" class="label-section">
        <input type="text" class="form-control" formControlName="personOutOfHouseholdName">
      </nh-field>
    </div>

    <div [formGroup]="getControl('data.expensePaymentInformation')">
      <nh-field id = "dec_expenseAmount"
        label="{{'DEPENDENT_CARE_STATIC_TEXT6' | translate }} {{selectedIndividual | fullname}} {{'DEPENDENT_CARE_STATIC_TEXT7' | translate }}" class="label-section">
        <nh-popover style="padding-left: 3px;" content="{{'DEPENDENT_CARE_STATIC_TEXT9' | translate }}"></nh-popover>
        <div class="hint">{{'DEPENDENT_CARE_STATIC_TEXT8' | translate }}</div>
        <nh-input-group prepend="$">
          <input nh type="decimal"  maxlength="7" name="expenseAmount" formControlName="expenseAmount" class="form-control">        </nh-input-group>
      </nh-field>
      <nh-field id = "dec_expenseFrequencyCode" label="{{'DEPENDENT_CARE_STATIC_TEXT10' | translate }} {{selectedIndividual | fullname}} {{'DEPENDENT_CARE_STATIC_TEXT11' | translate }}" class="label-section">
        <select nh name="expenseFrequencyCode" aria-label="expenseFrequencyCode" [items]="tableCodeValue('INCEXPPAYFREQUENCY', 'data.expensePaymentInformation.expenseFrequencyCode')"
          formControlName="expenseFrequencyCode"></select>
      </nh-field>
    </div>
  </section>
</nh-form>
