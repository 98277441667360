import { Injectable } from "@angular/core";

@Injectable()
export class AuthService {
  user: any;
  private _tokenId: string;

  get tokenId() {
    if (!this._tokenId) {
      const sessionTokenId = sessionStorage.getItem('tokenId');
      if (sessionTokenId) {
        this._tokenId = sessionTokenId;
      }
    }
    return this._tokenId;
  }

  set tokenId(tokenId) {
    sessionStorage.setItem('tokenId', tokenId);
    this._tokenId = tokenId;
  }

  get isAuthenticated(): boolean {
    return !!this.tokenId;
  }

  setToken(tokenId) {
    this.tokenId = tokenId;
  }

  removeToken() {
    sessionStorage.removeItem('tokenId');
    this._tokenId = null;
  }
}