<div class="d-block d-sm-none mobile-bg py-3">
  <ul>
    <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/state-services.html" target="_blank">{{'FOOTER_LINK1' | translate}}</a><hr></li>
    <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/directory.html" target="_blank">{{'FOOTER_LINK2' | translate}}</a><hr></li>
    <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/transparenttn.html" target="_blank">{{'FOOTER_LINK3' | translate}}</a><hr></li>
    <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/humanrights/file-a-discrimination-complaint/title-vi1/title-vi-disclaimer.html" target="_blank">{{'FOOTER_LINK4' | translate}}</a><hr></li>
    <li class="nav-item"><a class="nav-link" (click)="openAnalyticsPolicy()">{{'FOOTER_LINK5' | translate}}</a><hr></li>
    <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/about-tn/state-symbols.html" target="_blank">{{'FOOTER_LINK6' | translate}}</a><hr></li>
    <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/web-policies/privacy-statement.html" target="_blank">{{'FOOTER_LINK7' | translate}}</a><hr></li>
    <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/web-policies/accessibility.html" target="_blank">{{'FOOTER_LINK8' | translate}}</a> <hr></li>
    <li class="nav-item"><a class="nav-link" href="https://help.tn.gov/tn" target="_blank">{{'FOOTER_LINK9' | translate}} </a><hr></li>
    <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/survey.html" target="_blank">{{'FOOTER_LINK10' | translate}}</a></li>
  </ul>
</div>

<footer style="overflow-x: hidden;">
  <div class="d-block d-sm-none">
    <div class="row extra-space"></div>
  </div>

  <div class="d-none d-sm-block">
    <div class="bg white">
      <div class="container ">
        <div class="d-flex justify-content-between">
          <div class="my-2">
            <div>
              <img alt="book" class="img-responsive img-book" src="../../../assets/images/Icons/icons-document-text-outline.svg">
              <a class="page-info btn-link" href="https://www.tn.gov/tenncare/members-applicants/how-do-i-apply-for-tenncare.html" target="_blank"><span>{{ 'PAPER_APPLICATION' | translate}}</span></a>
            </div>
          </div>
          <div class="my-2">
            <img alt="phone" class="img-responsive img-book" src="../../../assets/images/Icons/icons-call-outline-1.svg">
            <span class="page-info">&nbsp;855-259-0701</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="footer-top" class="d-flex flex-column align-items-center tn-footer-logo"><img _ngcontent-cyx-c5=""
    alt="TN-Footer-Logo" src="../../assets/images/Illio.NashStarsFooterIcon.svg">
  </div>

  <div class="d-block d-sm-none">
    <div class="row ft-btm-mobile"></div>
  </div>

  <div class="ft-btm d-none d-sm-block">
    <div class="container pt-1">
      <div class="d-none d-sm-block">
        <nav id="footer-bottom" class="navbar navbar-light navbar-expand-md">
          <ul class="nav navbar-nav justify-content-start">
            <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/state-services.html" target="_blank">{{'FOOTER_LINK1' | translate}}</a></li>
            <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/directory.html" target="_blank">{{'FOOTER_LINK2' | translate}}</a></li>
            <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/transparenttn.html" target="_blank">{{'FOOTER_LINK3' | translate}}</a></li>
            <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/humanrights/file-a-discrimination-complaint/title-vi1/title-vi-disclaimer.html" target="_blank">{{'FOOTER_LINK4' | translate}}</a></li>
            <li class="nav-item"><a class="nav-link" (click)="openAnalyticsPolicy()">{{'FOOTER_LINK5' | translate}}</a></li>
          </ul>
          <ul class="nav navbar-nav ml-auto justify-content-end">
            <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/about-tn/state-symbols.html" target="_blank">{{'FOOTER_LINK6' | translate}}</a></li>
            <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/web-policies/privacy-statement.html" target="_blank">{{'FOOTER_LINK7' | translate}}</a></li>
            <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/web-policies/accessibility.html" target="_blank">{{'FOOTER_LINK8' | translate}}</a></li>
            <li class="nav-item"><a class="nav-link" href="https://help.tn.gov/tn" target="_blank">{{'FOOTER_LINK9' | translate}} </a></li>
            <li class="nav-item"><a class="nav-link" href="https://www.tn.gov/survey.html" target="_blank">{{'FOOTER_LINK10' | translate}}</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</footer>