import { Component, Input, ContentChild } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'nh-field',
  templateUrl: './field.component.html',
})
export class FieldComponent {
  @ContentChild(NgControl, { static: false })
  field: NgControl;
  @Input() label: string;
  @Input() isRow = false;
  @Input() mandatory = true;
  @Input() readonly = false;
  @Input() option: any;
  @Input() showLabel = true;
  @Input() labelSize = true;
  @Input() col9 = false;
}
