import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';

import { SectionFormDirective } from '@app/classes/section-form.class';
import { isYes } from '@app/helpers/tayn.helpers';
import { swapControls} from '@app/helpers/forms.helpers';
import {minus120Years} from '@app/helpers/date.helpers';
import { formatTableData } from '@app/helpers/tables.helpers';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { equals } from 'ramda';
import { ProcessService } from '@app/services/process.service';
import { ModalService } from '@app/services/modal/modal.service';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: './joint-filer-outside.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JointFilersOutsideComponent extends SectionFormDirective implements AfterContentInit {
  today = new Date();
  minimumDate = minus120Years();
  translatedLang;
  defaultLanguage;
  appType = sessionStorage.getItem('appType');
  isRenewBenefits = false;
  duplicateJointFilerSub: Subscription;
  jointFilerOutsideDobIndv;
  checkDateGMT

  constructor(private titleService: Title, builder: FormBuilder, public translateService: TranslateService, 
    public processService: ProcessService, private modalService: ModalService) {
    super(builder);
    this.titleService.setTitle('Joint Filer Outside Household');
    this.duplicateJointFilerSub = this.processService.duplicateJFOutsideInsideValidate.subscribe((action) => this.isDuplicateJointFiler(action));
    this.processService.isDuplicateJFOutsideInside = false;
  }

  get name() {
    return this.getControl('data.name')
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  tableCodeValue (tableName, tableControl){
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }

  ngAfterContentInit() {
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);

    this.translateService.onDefaultLangChange.subscribe((res) => {
    this.translatedLang = res.lang;
    this.languageCheck(res.lang);
    });
    const data = this.getControl('data');
    const jointFilerOutside = data.get('jointFilerOutside');
    const name = data.get('name');
    const ssn = data.get('ssn');
    const birthDate = data.get('birthDate');

    this.registerToggle(jointFilerOutside, swapControls([name, birthDate, ssn], isYes));
    this.isRenewBenefits = (this.appType === 'RB');
  }

  showAlert () {
    if (this.getValue('data.jointFilerOutside').code === 'Y' &&
    this.getForm().valid && 
    !this.getValue('data.ssn')) {
      window.scroll(0,0);
    }
    return (
      this.getValue('data.jointFilerOutside').code === 'Y' &&
      this.getForm().valid && 
      !this.getValue('data.ssn')
    )
  }

  getSsnLabel(name: string): string {
    return name ? 'JOIN_FILER_OUTSIDE_STATIC5_NEW' : 'JOIN_FILER_OUTSIDE_STATIC5';
  }

  getDobLabel(name: string): string {
    return name ? 'JOIN_FILER_OUTSIDE_STATIC8' : 'JOIN_FILER_OUTSIDE_STATIC7';
  }
  isInvalidDate(event) {
    const enteredDate = event.target.value;
    if ( enteredDate === 'Invalid date' ) {
      event.target.value = enteredDate;
    } else if (event.target.name === 'birthDate') {
      event.target.value = formatDate (this.getControl('data.birthDate').value , 'MM/dd/yyyy' , 'en');
    } else if (event.target.name === 'intendToReturnDate') {
      event.target.value = formatDate (this.getControl('data.livingArrangement.intendToReturnDate').value , 'MM/dd/yyyy' , 'en');
    }

    if(this.processService.isDuplicateJFOutsideInside){
      this.processService.isDuplicateJFOutsideInside = false;
    }
  }


  isDuplicateJointFiler(action){
    const isSaveExit = equals(action, 'fromSaveExit') ? true : false;
    const formData = this.formGroup.value.data;

    if(isSaveExit && formData?.jointFilerOutside?.code === 'N'){
      this.processService.isDuplicateJFOutsideInside = true;
      isSaveExit ? this.processService.setIsfromTaxDepOrJFOutsideSaveExit('fromSaveExit') : '';
    }else{
      const jointFilerOutsideNameIndv =  JSON.parse(JSON.stringify(formData?.name));
      if(this.formGroup.value?.data?.birthDate){
      this.jointFilerOutsideDobIndv =  JSON.parse(JSON.stringify(formData?.birthDate));
      }
      if (this.processService.isDuplicateJFOutsideInside && jointFilerOutsideNameIndv && this.jointFilerOutsideDobIndv) {
        this.processService.isDuplicateJFOutsideInside = true;
      }else{
        if(this.formGroup.value?.data?.birthDate){
         this.checkDateGMT = (/\bGMT\b/).test(this.jointFilerOutsideDobIndv['birthDate']);
        }
        if(this.checkDateGMT){
          this.jointFilerOutsideDobIndv = this.getDate((this.jointFilerOutsideDobIndv).toLocaleDateString());        
        }else{
          const checkDateHasT = (/T/).test(this.jointFilerOutsideDobIndv);
          if(checkDateHasT){
            this.jointFilerOutsideDobIndv = this.jointFilerOutsideDobIndv.split("T")[0];
          }else{
            this.jointFilerOutsideDobIndv = this.getDate(this.jointFilerOutsideDobIndv);
          }
        }

        const jointFilerOutsideIndvs = [{name: jointFilerOutsideNameIndv, birthDate: this.jointFilerOutsideDobIndv}];

        const indvsRows = [...this.members];
        let duplicateIndvRows = indvsRows.filter((el) => {
          return jointFilerOutsideIndvs.some((f) => {
            return (f.name?.toLowerCase() === el.name.fullName?.toLowerCase()) && (f.birthDate === el.birthDate);
          });
        });

        duplicateIndvRows = duplicateIndvRows.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.name.fullName.toLowerCase() === value.name.fullName.toLowerCase() && t.birthDate === value.birthDate
        )));

        const duplicateIndvNames = duplicateIndvRows.map(indv => indv.name.fullName);

        if(duplicateIndvNames.length>0){
          this.modalService.openDuplicateTaxDepOrJointFiler(duplicateIndvNames, isSaveExit,'jointfiler').subscribe(response => {
            console.log(response);
            this.processService.isDuplicateJFOutsideInside = true;
          });
        }else{
          if(duplicateIndvNames.length<=0 && isSaveExit){
            this.processService.isDuplicateJFOutsideInside = true;
            isSaveExit ? this.processService.setIsfromTaxDepOrJFOutsideSaveExit('fromSaveExit') : '';
          }else{
            this.processService.noDuplicateJFInOutAfterModal = true;
            this.processService.isNoDuplicateJFOutsideInsideValidate.next([true, this.formGroup.value.data]);
          }
        };
      }
    }
  }


  getDate(date){
    const checkSlash = (/\//).test(date);
    if(checkSlash){
      const dateParts = date.split("/");
      const formattedDate = dateParts[2] + "-" + dateParts[0].padStart(2,'0') + "-" + dateParts[1].padStart(2,'0');
      return formattedDate;
    }else{
      return date;
    }
  }

  ngOnDestroy() {
    this.duplicateJointFilerSub.unsubscribe();
  }

  jointFilerChange(){
    if(this.processService.isDuplicateJFOutsideInside){
      this.processService.isDuplicateJFOutsideInside = false;
    }
  }

  jointFilerNameChange(){
    if(this.processService.isDuplicateJFOutsideInside){
      this.processService.isDuplicateJFOutsideInside = false;
    }
  }

}
