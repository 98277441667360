import { StatementsComponent } from './statements.component';
import { lookIn } from '@app/helpers/flipped.helpers';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import Vs from '@app/services/validators/validators.service';

const formValidation = (builder, recordData) => {
  const statement = lookIn(recordData);
  const renewEligibility = statement(['applicationSignature', 'renewEligibility']);
  const isRenewRequired = statement(['applicationSignature', 'isRenewRequired']);
  const healthPlan = statement(['applicationSignature', 'healthPlan']);
  const voterRegistration = statement(['applicationSignature', 'voterRegistration']);
  const avsConsent = statement(['applicationSignature', 'avsConsent']);

  const data = builder.group({
    applicationSignature: builder.group({
      isRenewRequired : [isRenewRequired, Vs.required],
      renewEligibility : [renewEligibility, Vs.required],
      voterRegistration : [voterRegistration, Vs.required],      
      healthPlan : [healthPlan],
      avsConsent : [avsConsent]
    }),
  });

  return { data };
};

export default sectionConfiguration(StatementsComponent, formValidation, ['YESNO', 'MPFINISH', 'MCONAME'], [], {}, {
  hasSummary: false,
  showSubmit: false,
});
