import { complement } from 'ramda';
import { assocPath } from 'ramda';
import { compose } from 'ramda';
import { propEq } from 'ramda';
import { filter } from 'ramda';
import { both } from 'ramda';
import { find } from 'ramda';
import { path } from 'ramda';
import { map } from 'ramda';
import { gte } from 'ramda';
import { lte } from 'ramda';
import { all } from 'ramda';
import { lt } from 'ramda';
import { __ } from 'ramda';

import { valueIn } from './value.helpers';
import { isYes, isNo } from './tayn.helpers';
import { withAnd, oPath, aPath } from './function.helpers';
import { codeEqual, joinCodes, codeIn, anyCodes } from './code.helpers';
import { hasPrograms, medicalCoverage, homeCareChildren, cashAssistance } from './programs.helpers';

const maritalStatus = oPath(['maritalStatus']);
const jointFiler = oPath(['taxFilerInfo', 'jointFiler']);
const jointFilerOutOfHome = oPath(['taxFilerInfo', 'jointFilerOutOfHome']);
const taxDepOutOfHome = oPath(['taxFilerInfo', 'taxDepOutOfHome']);
const races = aPath(['ethnicity', 'races']);
const programs = aPath(['programs']);
const applicantRelation = aPath(['applicantRelationship']);

export const age = oPath(['age']);
export const livingAreaType = oPath(['livingAreaType']);
export const gender = oPath(['gender', 'code']);

export const isSpouse = codeEqual('SPO');
export const notSpouse = complement(isSpouse);
export const hasSpouse = compose(
  codeIn(['IS', 'MA']),
  maritalStatus
);

export const asSpouse = compose(
  isSpouse,
  applicantRelation
);

const isJointFiler = compose(
  isYes,
  jointFiler
);

const notJointFilerOutOfHome = compose(
  isNo,
  jointFilerOutOfHome
);

export const isJointFilerOutOfHome = compose(
  isYes,
  jointFilerOutOfHome
);

export const isTaxDepOutOfHome = compose(
  isYes,
  taxDepOutOfHome
);

const taxFiler = both(isJointFiler, notJointFilerOutOfHome);
const isTaxFiler = both(taxFiler, hasSpouse);

export const isFiler = withAnd([isTaxFiler, isSpouse]);
export const isTaxDependant = withAnd([hasPrograms, notSpouse]);

export const notFiler = complement(isFiler);
export const notTaxDependant = complement(isTaxDependant);

const seventeenOrOver = gte(__, 17);
const underEighteen = lt(__, 18);
const eighteenOrOver = gte(__, 18);
const nineteenOrOver = gte(__, 19);
const twentyOrUnder = lte(__, 20);
const twentyTwoOrUnder = lte(__, 22);
const nineteenUnder = lt(__, 18);

export const thirteenOrOver = gte(__, 10);
export const twentyOneOrUnder = lte(__, 21);

export const sixtyOrUnder = lte(__, 60);
export const twentyFiveOrUnder = lte(__, 25);
export const fourteenOrOver = gte(__, 14);
export const underSixtyFive = lt(__, 65);

export const thirteenOrUnder = lte(__, 13);

export const youngAdult = compose(
  both(nineteenOrOver, twentyOrUnder),
  age
);

export const workFullTime = compose(
  twentyTwoOrUnder,
  age
);

export const child = compose(
  thirteenOrUnder,
  age
);

export const teenager = compose(
  both(thirteenOrOver, twentyOneOrUnder),
  age
);

export const major = compose(
  eighteenOrOver,
  age
);

export const militaryAvailableAge = compose(
  seventeenOrOver,
  age
);

export const fullTimeAge = compose(
  fourteenOrOver,
  age)

export const fit = compose(
  both(nineteenOrOver, underSixtyFive),
  age
);

export const minor = compose(
  underEighteen,
  age
);

export const pregnableAge = compose(
  both(thirteenOrOver, sixtyOrUnder),
  age
);

export const underNineteen = compose(
  nineteenUnder,
  age
);

export const isStudent = compose (
  twentyOneOrUnder,
  age
)

export const fosterCare = compose(
  both(eighteenOrOver, twentyFiveOrUnder),
  age
)
export const female = compose(
  codeEqual('F'),
  gender
);

export const hasNursing = compose(
  codeEqual('52'),
  livingAreaType
);

export const pregnable = both(pregnableAge, female);
export const housing = codeEqual('08');
export const homeless = codeEqual('06');

export const inHousing = compose(
  housing,
  livingAreaType
);

export const relationTypes = (genders, genrel, relations) => joinCodes(relations, filter(valueIn(genders), genrel));

export const dataBlocks = aPath(['data', 'blocks']);
const isUncompleted = propEq('status', 'UNCOMPLETED');

export const personUncompleted = compose(
  find(isUncompleted),
  dataBlocks
);

const americanAlaskan = anyCodes(['I']);

export const isAmericanAlaskan = compose(
  americanAlaskan,
  races
);

export const hasMedicare = compose(
  medicalCoverage,
  programs
);

export const hasHomeCare = compose(
  homeCareChildren,
  programs
);

export const hasCashAssistance = compose(
  cashAssistance,
  programs
);

export const foodTogether = all(path(['data', 'prepareFoodTogether']));

export const resetNudging = map(assocPath(['data', 'prepareFoodTogether'], true));
