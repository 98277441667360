export default {
    assetType: 'RMB_REAL_ESTATE_STATIC_TEXT1',
    landUseCode: 'RESOURCES_PROPERTY_STATIC4',
    primaryResidence: 'RMB_REAL_ESTATE_STATIC_TEXT2',
    intendToReturn: 'RMB_REAL_ESTATE_STATIC_TEXT3',
    rentHome: 'RMB_REAL_ESTATE_STATIC_TEXT4',
    monthlyIncome: 'RESOURCES_PROPERTY_STATIC8',
    owedAmount: 'RMB_REAL_ESTATE_STATIC_TEXT5',
    assetAmount: 'RMB_REAL_ESTATE_STATIC_TEXT6',
    assetAddress: 'RMB_BURRIAL_STATIC5',
    jointOwnership: 'RMB_FINANCIAL_STATIC_TEXT8',
    jointOwnershipInformation: 'RMB_C_CO_OWNERS',
    removed: {
        propertyEndDate: 'RMB_REAL_ESTATE_STATIC_TEXT7'
    }
}