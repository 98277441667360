import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from '@app/services/application/application.service';
import { StateService } from '@app/services/state/state.service';
import { isEmpty } from 'ramda';
import { mergeMap } from 'rxjs/operators';
import { toISO} from '@app/helpers/date.helpers';
import { BeforeStartComponent } from '../at-a-glance/before-start-application/before-start-application.component';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nh-renewal-benefits-welcome',
  templateUrl: './renewal-benefits-welcome.component.html',
  styleUrls: ['./renewal-benefits-welcome.component.scss']
})
export class RenewalBenefitsWelcomeComponent implements OnInit {
  constructor(
    private router: Router, 
    private app: ApplicationService, 
    private beforeStart: BeforeStartComponent,
    private activatedRoute: ActivatedRoute,
    private stateService: StateService,
    private titleService: Title,
    public translateService: TranslateService
    ) {
      this.titleService.setTitle('Renew Benefits');
     }

  appType = '';
  applicationNum: any;
  caseDetails: any;
  optionNotSelected = false;
  isOverSevenDays: boolean = false;
  mobileUser = sessionStorage.getItem('isMobileUser') === 'true' ? true : false;
  isPreterm = sessionStorage.getItem('isPreterm') === "PRETERM" && (sessionStorage.getItem('appType') === 'RB' || (sessionStorage.getItem('appType') === 'REDETERMINATION'  && sessionStorage.getItem('isKepro') === "true"));
  isKepro = sessionStorage.getItem('isKepro') === "true";
  pretermCase;
  response;
  row;

  ngOnInit() {
    const appNum = typeof sessionStorage.getItem('appNum') === 'string' ? sessionStorage.getItem('appNum') : JSON.parse(sessionStorage.getItem('appNum'));

    this.applicationNum = appNum;
    if (!this.isPreterm && !this.mobileUser) {
      this.activatedRoute.queryParams.pipe(mergeMap((res) => {
        if (!isEmpty(res)) {
          sessionStorage.setItem('caseNum', res.caseNumber);
        }
        return this.app.getHeadOfHouseholdInfo(res.caseNumber, 'REDETERMINATION')
      })).subscribe((res: any) => {
        this.caseDetails = res;
        const date = new Date();
        const todaysDate = ((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
        if (this.getNumberOfDays(toISO(res?.startedOn), todaysDate) >= 7) {
          this.isOverSevenDays = true;
        }
        this.applicationNum = res?.applicationNumber;
        sessionStorage.setItem('appNum', this.applicationNum);
      })
    } else if (this.isPreterm && !this.isKepro) {
      this.caseDetails = JSON.parse(sessionStorage.getItem('caseDetails'));
      sessionStorage.setItem('caseNum', this.caseDetails?.caseNumber);
    }else if(this.mobileUser && !this.isPreterm && !this.isKepro){
      this.caseDetails = JSON.parse(sessionStorage.getItem('mobileRmbCaseDetails'));
      const date = new Date();
      const todaysDate = ((date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
      if (this.getNumberOfDays(toISO(this.caseDetails?.startedOn), todaysDate) >= 7) {
        this.isOverSevenDays = true;
      }
    }
  }
  

  select(value) {
    this.appType = value;
  }

  next() {
    if (this.isPreterm && this.applicationNum === 'null') {
      sessionStorage.setItem('appNum', this.caseDetails?.applicationNumber);
    }
    this.stateService.continueApplication(true);
    if (this.appType && this.appType === 'continue') {
      this.optionNotSelected = false;
      this.continueApp();	
    } else if (this.appType && this.appType === 'new') {
      this.optionNotSelected = false;
      this.startNewApp();
    } else { 
      this.optionNotSelected = true;
    }
  }

  startApp() {
    return this.router.navigateByUrl('/application/' + 'REDETERMINATION');
  }
  
  startNewApp() {
    if (this.caseDetails && this.caseDetails.applicationNumber != 0) {
      this.stateService.resetStates();
      this.app.deleteApp(this.applicationNum).subscribe(() => {
        sessionStorage.removeItem('appNum');
        this.startApp();
      })
    } else {
      this.startApp();
    }
  }

  continueApp() {
    if (this.applicationNum && this.applicationNum != 0) {
        const appType = 'RB';
        if (this.isPreterm && this.isKepro) {
          const payLoad = {"appType": "PRETERMINATION"}
          this.app.determineAhsContinueUserApp(this.applicationNum, payLoad).subscribe(() => {
            this.beforeStart.startApplication('RB');
          })
        } else {
          this.beforeStart.startApplication(appType);
        }
     } else {
         this.startApp();
     } 
  }

  onPreviousClick() {
    if(!this.isPreterm) {
      sessionStorage.removeItem('appType');
      this.router.navigateByUrl('/user-profile/renew-benefits');
    } else {
      sessionStorage.removeItem('isPreterm');
      sessionStorage.removeItem('appType');
      this.router.navigateByUrl('/user-profile/pre-term');
    }
    
  }

  getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }
}
