import { Directive, ComponentFactoryResolver, ViewContainerRef, Type, ComponentFactory } from '@angular/core';

import { assign } from '@app/helpers/function.helpers';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[nh-container]' })
export class ContainerDirective {
  constructor(private viewRef: ViewContainerRef, private factory: ComponentFactoryResolver) {}

  public createInstance(component, inputs) {
    assign(this.load(component).instance, inputs);
  }

  public element() {
    return this.viewRef.element.nativeElement;
  }

  private load(component: Type<any>) {
    return this.createComponent(this.componentFactory(component));
  }

  public clear(): void {
    return this.viewRef.clear();
  }

  private componentFactory(component: Type<any>) {
    return this.factory.resolveComponentFactory(component);
  }

  private createComponent(factory: ComponentFactory<any>) {
    return this.viewRef.createComponent(factory);
  }
}
