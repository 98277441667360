import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'nh-contact-phones',
  templateUrl: './contact-phones.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPhonesComponent {
  @Input() phoneNumbers: FormGroup;
  @Input() contactPhoneName: string;
}
