export const employer = {
    address: 'ADDRESS',
    name: 'EMPLOYMENT_STATIC_TEXT6',
    phoneNumber: 'EMPLOYMENT_STATIC_TEXT8'
}

export const wage = {
    hoursWorkedPeriod: 'RMB_EMPLOYMENT_STATIC_TEXT1',
    paymentAmtPerHr: 'RMB_EMPLOYMENT_STATIC_TEXT2',
    paymentPeriod: 'RMB_EMPLOYMENT_STATIC_TEXT3',
    paymentAmountPerPayCheck: 'RMB_EMPLOYMENT_STATIC_TEXT4'
}

export default {
    employer: employer,
    startDate: 'RMB_EMPLOYMENT_STATIC_TEXT5',
    wage: wage,
    removed: {
        employmentEndDate: 'RMB_EMPLOYMENT_STATIC_TEXT6',
        lastPaycheckDate: 'RMB_EMPLOYMENT_STATIC_TEXT7',
        lastPaycheckAmt: 'RMB_EMPLOYMENT_STATIC_TEXT8'
    }
}