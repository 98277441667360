import { evolve } from 'ramda';

import { JointFilersOutsideComponent } from './joint-filer-outside.component';

import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { toISO } from '@app/helpers/date.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { isNo } from '@app/helpers/tayn.helpers';
import { controlWith } from '@app/helpers/form.helpers';
import { toDate } from 'app/helpers/date.helpers';

const formValidation = (builder, recordData) => {
  const jointFilerOutsideInfo = lookIn(recordData);
  const jointFilerOutside = jointFilerOutsideInfo(['jointFilerOutside']);

  const name = jointFilerOutsideInfo(['name']);
  const ssn = jointFilerOutsideInfo(['ssn']);
  const birthDate = toDate(jointFilerOutsideInfo(['birthDate']));

  const noJointFilter = isNo(jointFilerOutside)

  const data = builder.group({
    jointFilerOutside: [jointFilerOutside, Vs.required],
    name: [controlWith(name, noJointFilter), [Vs.required, Vs.invalidName]],
    ssn: [controlWith(ssn, noJointFilter), [Vs.ssn]],
    birthDate: [controlWith(birthDate, noJointFilter), [Vs.validDate, Vs.notBefore120Years]]
  });

  return { data };
};

const postObject = evolve({
    birthDate: toISO
});

export default sectionConfiguration(JointFilersOutsideComponent, formValidation, ['YESNO'], [], {}, { postObject, hasSummary: false, validateSubmit: true });
