import { when, map, curry, find, merge, compose, forEach, isNil, isEmpty } from 'ramda';

import { TaxFilerOutsideComponent } from './tax-filer-outside.component';

import Vs from '@app/services/validators/validators.service';
import { nilPath } from '@app/helpers/null.helpers';
import { aPath } from '@app/helpers/function.helpers';

import { disable, disableGet } from '@app/helpers/able.helpers';
import { sectionConfiguration, createTaxFilerOutside } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { isNo } from '@app/helpers/tayn.helpers';

const formValidation = (builder, recordData, { individuals }) => {
  const appType = sessionStorage.getItem('appType');
  const user = sessionStorage.getItem('userType');
  const isPpRmbRmc = (appType === 'RB' || appType === 'RC') && user === 'RP';
  const isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));
  const isKepro = JSON.parse(sessionStorage.getItem('isKepro'));
  const taxDeps = lookIn(recordData);
  const converedIndividuals = aPath(['taxDependents'], recordData);
  let filerOutsideHoh = taxDeps(['filerOutsideHoh']);
  if(!isNil(filerOutsideHoh) && (!isPpRmbRmc || !isFinReassessment || !isKepro)) {
    const tempObj = filerOutsideHoh;
    // tempObj['description'] = filerOutsideHoh.value;
    // tempObj['spDescription'] = filerOutsideHoh.spValue;
    // if(filerOutsideHoh.code == 'Y') {
    //   tempObj['rank'] = "1";
    // }else {
    //   tempObj['rank'] = "2";
    // }
    // delete tempObj.spValue;
    filerOutsideHoh = tempObj;
  }
  const filerOutsideVal = isNil(filerOutsideHoh) ? '' : filerOutsideHoh;

  const matchIndividualCovered = curry((identifier, n) => n.dependent === identifier);
  const matchCovered = curry((covered, { identifier }) =>
    merge({ dependent: identifier }, find(matchIndividualCovered(identifier), covered))
  );

  const coveredIndividualsDetails = curry(
    compose(
      control => builder.group(control, { updateOn: 'change' }),
      createTaxFilerOutside,
      matchCovered
    )
  );

  const converedIndiv = map(coveredIndividualsDetails(converedIndividuals), individuals);

  forEach(when(nilPath(['value', 'taxFilerName']), disable), converedIndiv);

  const data = builder.group({
    taxDependents: builder.array(converedIndiv, [Vs.required]),
    filerOutsideHoh: [filerOutsideVal, Vs.required],
    hiddenInput: null
  });

  when(isNo, () => disableGet('taxDependents', data), filerOutsideHoh);
  when(isEmpty, () => disableGet('taxDependents', data), converedIndividuals);


  return { data };
};

export default sectionConfiguration(TaxFilerOutsideComponent, formValidation, [], [], {}, {});
