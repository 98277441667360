import { Pipe, PipeTransform } from '@angular/core';

import { Record } from '@app/models/record.model';
import { Pipes } from '@app/models/section.model';

@Pipe({ name: 'domain', pure: true })
export class DomainPipe implements PipeTransform {
  transform(value: any, args: (record: Record, pipes: Pipes) => any, pipes: Pipes): string {
    return args(value, pipes);
  }
}
