<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <section [formGroup]="getControl('data')">
    <div class="p-3">
      <h5 class="section-header">Responsibilities, Rights, and Penalties</h5><hr>
      <p>I know that I must tell TennCare if anything changes (and is different than) what I answered on the 
        Application or Renewal Packet within 10 days of that change. I can report changes online at www.tenncareconnect.tn.gov. 
        I can call 1-855-259-0701 to report any changes. I can mail changes to TennCare Connect at P.O. Box 305240, Nashville, TN 37230-5240. 
        I can fax changes to 1-855-315-0669. Someone at a county DHS office can help me report a change. I understand that a change 
        in my information could affect the eligibility for a member(s) of my household.</p>
      <p>I know that under federal law, discrimination isn’t permitted on the basis of race, color, national origin, sex, age, 
        sexual orientation, gender identity, or disability.  If you think you have been treated unfairly, call 1-855-259-0701 
        to report it.  It’s a free call.</p>
      <p>I know that if I am approved, I can't keep any health insurance payments or medical payments I get from insurance or 
        other companies. Those payments belong to the State. I understand I must sign them over to the State.</p>
      <p>I know that TennCare may use the email address (or mobile phone number) that I provided to send emails or Short Message Service (SMS) 
        messages related to my coverage, depending on my communication preference selections. TennCare and their partners may also use the 
        phone number I provided to call me about my coverage.</p>
      <p>I know that if the Tennessee Bureau of Investigation, TennCare, Office of Inspector General, or another agency asks for my help 
        catching health care fraud and abuse, I must help.</p>
      <p>I know if the State pays for medical bills or for nursing home care for me, the State may get that money back.  I know that after 
        my death, the State may be paid back with money from my estate.</p>
      <p>I know no one else can use my health care card.  I know if I let someone else use my card I may have to pay the State back for that 
        other person’s medical bills.  And I could go to jail. </p>
      <p>If I have a Social Security Number (SSN) and I’m applying for coverage, I know I am required to provide a valid SSN.  Federal and 
        State law lets us ask for an SSN.  [42 CFR 435.910; Tenn. Code Ann § 71-5-106]</p>
      <p>If anyone on the Application is eligible for health care coverage with TennCare, I am giving TennCare rights to pursue and get any 
        money from other health insurance, legal settlements, or other third parties. I am also giving TennCare rights to pursue and get medical 
        support from a spouse or parent.</p>
      <p>Does any child on this  Application have a parent living outside of the home?  If yes, I know I will be asked to cooperate with the 
        agency that collects medical support from an absent parent.  If I think that cooperating to collect medical support will harm me or 
        my children, I can tell TennCare and I may not have to cooperate.</p>
      <p>If I think TennCare or CoverKids (CHIP) has made a mistake, I can appeal its decision.  To appeal means to tell someone that I think 
        the action is wrong, and ask for a fair review of the action.  I know that I can be represented in the process by someone other than 
        myself.  My eligibility and other important information will be explained to me.  I know that I can find out how to appeal by contacting 
        TennCare Connect at 1-855-259-0701.</p>
      <p>I understand that if I  am eligible for the other kinds of benefits like disability, unemployment income, or retirement income I must 
        also apply for those programs if I want to keep coverage with TennCare.</p>
      <p>If I think TennCare is taking more than 45 days (or more than 90 days if I applied for long-term care), I can ask for a “delay hearing”. 
        I know I can ask for a delay hearing by contacting TennCare Connect at 1-855-259-0701.</p> 
      <p class="sign-submit-title"><strong>Your Right to Privacy</strong></p><hr>
      <p>We know you value the privacy of your personal information.  Federal law says we must follow privacy rules to keep your facts private.
        You can read about the rules on our website. For more information about our privacy rules, 
        go to <a class="view-tennCare-title" href="https://tn.gov/tenncare" target="_blank">our privacy page</a> 
        and choose Policy and Guidelines.  If you want us to mail you a copy, call TennCare Connect for free at 855-259-0701.</p>
      <p class="sign-submit-title"><strong>Non-discrimination</strong></p><hr>
      <p><strong>We do not allow unfair treatment in our program.</strong> No one is treated in a different way because of 
        race, color, birthplace, religion, language, sex, age, or disability. Do you think you’ve been treated unfairly? 
        Do you have more questions? Do you need more help? You can make a free call to TennCare Connect at 855-259-0701.  Or go to <a
          class="view-tennCare-title"
          href="https://www.tn.gov/tenncare/members-applicants/civil-rights-compliance.html">https://www.tn.gov/tenncare/members-applicants/civil-rights-compliance
        </a>to learn more.</p>
      <p class="sign-submit-title"><strong>Electronic Signature</strong></p><hr>
      <p>I’m signing this page under penalty of perjury which means I’ve provided true answers to all the questions for the person(s) 
        named here and its supplements to the best of my knowledge. I also certify that:</p>
      <p>I agree to submit this by electronic means. By signing this electronically, I understand that an electronic signature 
        has the same legal effect and can be enforced in the same ways as a written signature.</p>
      <p>I understand the questions and statements that were included here.</p>
      <p>I have read and understand my Rights & Responsibilities.</p>
      <p>I know that I may be subject to penalties under state and federal law if I provide false and or untrue information.</p>
      <div>
      <h6 class="label-section">Patient or Responsible Party</h6>
        <nh-checkbox name="applicationSignedBySw" [mandatory]="true" [control]="getControl('data.applicationSignedBySw')"
          checkedValue="PARTNER_PORTAL"
          label="By checking this box and typing my name below, I am electronically signing as the patient or responsible party."
          class="label-section">
        </nh-checkbox>
        <nh-alert type="danger" [isOpen]="isPatientShowSign && !this.getValue('data.applicationSignedBySw')">
          <div class="row">
            <div class="icon">
              <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="warning" />
            </div>
            <div class="message">
              <span>{{'SUBMIT_STATIC_TEXT43' | translate }}</span>
            </div>
          </div>
        </nh-alert>
        <br>
        <nh-field id = "nss_applicationSignName" label="Signature:" class="label-section">
          <input type="text" class="form-control" name="applicationSignName" formControlName="applicationSignName"
            maxlength="40">
        </nh-field>
         
      </div>
      <div>
        <h6 class="label-section">TennCare Access Portal User's Signature:</h6>
        <nh-checkbox name="partnerSignedBySw" [mandatory]="true" [control]="getControl('data.partnerSignedBySw')"
          checkedValue="WORKER_PORTAL"
          label="By checking this box and typing my name below, I am electronically signing as the TennCare Access user."
          class="label-section">
        </nh-checkbox>
        <nh-alert type="danger" [isOpen]="istennCareShowSign && !this.getValue('data.partnerSignedBySw')">
          <div class="row">
            <div class="icon">
              <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="warning" />
            </div>
            <div class="message">
              <span>{{'SUBMIT_STATIC_TEXT43' | translate }}</span>
            </div>
          </div>
        </nh-alert>
        <br>
        <nh-field id = "nss_partnerSignName" label="Signature:" class="label-section">
          <input type="text" class="form-control" name="partnerSignName" formControlName="partnerSignName"
            maxlength="40">
        </nh-field>
         
      </div>
    </div>
  </section>
</nh-form>