import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { formatTableData } from '@app/helpers/tables.helpers';
import { isApplyForBenefits, isPresumptiveApp, isRmbRmc } from '@app/helpers/mode.helpers';
import { fullTimeAge, isStudent, workFullTime } from '@app/helpers/person.helpers';
import Vs from '@app/services/validators/validators.service';
// import { isYes } from '@app/helpers/tayn.helpers';
// import { swapControl } from '@app/helpers/forms.helpers';

@Component({
  templateUrl: './school-employment.component.html',
  styleUrls: ['./school-employment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SchoolAndEmploymentComponent extends SectionFormDirective implements AfterContentInit {
  fullTimeEligible = [];
  isStudentAge = [];
  popTaxDepOutOfHome = `People who do not live in the household but can be claimed on the person's federal income tax.`;
  isAFB;
  isPresumptive;
  workingFullTime = [];
  isWorkFullTime = [];
  defaultLanguage;
  translatedLang;
  isRmbRmc = false;
  isStudentAgeHeader =  false;
  isfullTimeEligibleHeader =  false;
  isisWorkFullTimeHeader =  false;


  get schoolAndEmploymentData(): FormArray {
    console.log(this.getControl('data.indvData') as FormArray);
    return this.getControl('data.indvData') as FormArray;
  }

  get currentEnrollmentStatus(): FormGroup {
    console.log(this.schoolAndEmploymentData.controls[0]['controls']['education'] as FormGroup);
    return this.schoolAndEmploymentData.controls[0]['controls']['education'] as FormGroup;
  }

  constructor(builder: FormBuilder, public translateService: TranslateService, private titleService: Title) {
    super(builder);
    this.titleService.setTitle('School and Employment');
  }

 
  ngAfterContentInit() {

     this.isRmbRmc = isRmbRmc(this.mode);
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);

    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang
      this.languageCheck(res.lang);
    });
    this.isAFB = isApplyForBenefits(this.applicationType);
    this.isPresumptive = isPresumptiveApp(this.applicationType);

    let recordsData = this.schoolAndEmploymentData.value;
    console.log(recordsData);
    let individualsDataCopy = [...this.individuals];
    console.log(individualsDataCopy);
    const individualsBelowTwentyThree =  individualsDataCopy.filter((item) => item.age <= 22);

    let individualsDataFinal = recordsData.map((e)=>{
        let temp = individualsBelowTwentyThree.find(element=> element.identifier === e.individualNumber)
        if(temp.age >= 0) {
            e.age = temp.age;
        }
        if(temp.name) {
            e.name = temp.name;
        }
        return e;
    })

    console.log(individualsDataFinal);

    this.isStudentAge = individualsDataFinal.map(item => isStudent(item));
    this.fullTimeEligible = individualsDataFinal.map(item => fullTimeAge(item));
    this.isWorkFullTime = individualsDataFinal.map(item => workFullTime(item));

    this.isStudentAgeHeader =  this.isStudentAge.includes(true);
    this.isfullTimeEligibleHeader =  this.fullTimeEligible.includes(true);
    this.isisWorkFullTimeHeader =  this.isWorkFullTime.includes(true);

    let isWorkingFullTime = [];
    for (var k=0; k<individualsDataFinal.length; k++){
      isWorkingFullTime.push(this.schoolAndEmploymentData.controls[k]['controls']['isWorkingFullTime']);
    }


    if (this.isPresumptive) {      
      for (var i=0; i<individualsDataFinal.length; i++){
        isWorkingFullTime[i].clearValidators();
        isWorkingFullTime[i].updateValueAndValidity();
        this.workingFullTime[i] = false;
      }

    } else {
      for (var i=0; i<individualsDataFinal.length; i++){
        this.workingFullTime[i] = true;

        if (!this.isWorkFullTime[i]) {
          isWorkingFullTime[i].clearValidators();
          isWorkingFullTime[i].updateValueAndValidity();
        } else {
          isWorkingFullTime[i].setValidators([Vs.required]);
          isWorkingFullTime[i].updateValueAndValidity();
        }
      } 
    }

  }



  tableCodeValue (tableName, tableControl) {
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }


  getEnrollmentStatus(control, idx) {
    const relation = control.value[idx]['education']['currentEnrollmentStatus'];
      if (relation?.value) {
        const tableVar = this.tables.ENROLLSTATUS.filter((item) => item.code === relation.code);
        const iscurrentEnrollmentStatus = control.controls[idx].controls['education'].controls['currentEnrollmentStatus'];
        iscurrentEnrollmentStatus.patchValue(tableVar[0]);
      }
    return 'ENROLLSTATUS';
  }


  getWorkFullTime(control, idx) {
    const relation = control.value[idx]['isWorkingFullTime'];
      if (relation?.value) {
        const tableVar = this.tables.YESNO.filter((item) => item.code === relation.code);
        const isWorkingFullTimeControl = control.controls[idx].controls['isWorkingFullTime'];
        isWorkingFullTimeControl.patchValue(tableVar[0]);
      }
    return 'YESNO';
  }

}
