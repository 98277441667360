<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title pull-left" id="my-modal-title">{{ 'APPEAL_ELIGIBILITY_POPUP_HEADER' | translate }}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon" />
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="appealEligibilityTypeRows && appealEligibilityTypeRows.length">
      <div class="d-none d-lg-block">
        <ngx-datatable *ngIf="!isMobileView" class='bootstrap striped' #table [rows]='appealEligibilityTypeRows'
          [columnMode]='"force"' [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
          <ngx-datatable-column [name]="'APPEAL_ELIGIBILITY_TYPE' | translate" [width]="180">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
              {{ row.appealType | translate }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [name]="'APPEAL_ELIGIBILITY_DESCRIPTION' | translate">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
              {{ row.description | translate }}
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
      <div class="nh-table-mobile d-lg-none">
        <div class="table-row mb-3 pb-3">
          <div class="mobile-table-header">{{'ELIGIBILITY_APPEAL' | translate}}</div>
          <hr>
          <div class="mobile-table-data">{{'ELIGIBILITY_APPEAL_DESCRIPTION' | translate }}</div>
          <br>
          <div class="mobile-table-header">{{'RENEWAL_APPEAL' | translate }}</div>
          <hr>
          <div class="mobile-table-data">{{'RENEWAL_APPEAL_DESCRIPTION' | translate }}</div>
          <br>
          <div class="mobile-table-header">{{'KATIE_BECKETT_APPEAL' | translate }}</div>
          <hr>
          <div class="mobile-table-data">{{'KATIE_BECKETT_APPEAL_DESCRIPTION' | translate }}</div>
          <br>
          <div class="mobile-table-header">{{'LTSS_APPEAL' | translate }}</div>
          <hr>
          <div class="mobile-table-data">{{'LTSS_APPEAL_DESCRIPTION' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</div>