import { contains } from 'ramda';
import { environment } from '@env/environment';

import { Component, Input, ChangeDetectionStrategy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { printView } from '@app/helpers/dom.helpers';
import { Location } from '@angular/common';

@Component({
  selector: 'nh-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTitleComponent {
  gatewayURL = environment.gatewayURL;

  @Input() title: string;
  @Input() helpId: string;
  @Input() isSummary: any;
  @Input() isSubTitleEnabled: boolean;
  appType = sessionStorage.getItem('appType');
  isIMEdetailsSummary = this.location.path().includes('IMEDT') && this.appType === 'IME' ? true : false;

  constructor(@Inject(DOCUMENT) private _document: HTMLDocument, private location: Location) {}

  canPrint() {
    return contains(this.helpId, ['SUPSM', 'SUSGN']);
  }

  print() {
    printView(this._document);
  }
}
