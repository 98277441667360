<div *ngIf="isRmbRmc"><span>{{ staticText }}</span></div>
<div *ngIf="isRmbRmc && this.selectedIndividual"><br></div>
<div *ngIf="getValue('extras.topLevelAnswer') === 'YES'" form-top>
  <h6 class="person-label">{{ selectedIndividual | fullname }}</h6>
</div><br>
<nh-form [topLevelName] = "'or'" [formGroup]="getForm()" [mode]="mode" [individuals]="checkForDropdown ? finalListAll : individuals" (validate)="onValidate($event)" (individualAnswerChanged)="onIndividualChange($event)"
  class="label-section" individualQuestion="{{'OTHER_RESOURCES_INDIV_QUES' | translate }}"
  topLevelQuestion="{{'OTHER_RESOURCES_TOP_LEVEL' | translate }}">
  <div (window:resize)="onResize($event)"></div>
  <section [formGroup]="getControl('data')">
    <h5 class="section-header">{{'OTHER_RESOURCES_TITLE' | translate }}</h5>
    <hr>
    <nh-field id = "or_assetType"
      label="{{'OTHER_RESOURCES_STATIC_TEXT1' | translate}} {{ selectedIndividual | fullname }}{{'VEHICLE_STATIC_TEXT3' | translate }} <div class=hint>{{'IF' | translate }} {{ selectedIndividual | fullname }} {{'OTHER_RESOURCES_STATIC_TEXT2' | translate }}</div>"
      class="label-section">
      <select nh name="assetType" aria-label="assetType" [items]="tableCodeValue('OTHERRESTYPE', 'data.assetType')" formControlName="assetType"></select>
    </nh-field>

    <div class="pt-1 hover-red">
      <a class="btn text-left pl-0 btn-link hover-red" (click)="otherResourcesModal()">
        <span style="padding-right: 8px">{{'OTHER_RESOURCES_STATIC_TEXT3' | translate }}</span>
        <span><i class="fa fa-external-link"></i></span>
      </a>
    </div>
    <br>
    <nh-field id = "or_useDescription" *ngIf="isEnabled('data.useDescription')" label="{{'OTHER_RESOURCES_STATIC_TEXT4' | translate }}"
      class="label-section">
      <select nh name="assetType" aria-label="assetType" [items]="tableCodeValue('OTHERRESOURCEUSE', 'data.useDescription')" formControlName="useDescription"></select>
    </nh-field>
    <div *ngIf="this.translatedLang === 'en'">
      <nh-field id = "or_assetAmount" [mandatory]="false"
        label="How much is {{ selectedIndividual | fullname }}’s {{ getValue('data.assetType').value }} worth?"
        class="label-section">
        <nh-input-group prepend="$">
          <input nh type="decimal"  maxlength="7" name="assetAmount" formControlName="assetAmount" class="form-control">
        </nh-input-group>
      </nh-field>
    </div>

    <div *ngIf="this.translatedLang !== 'en'">
      <nh-field id = "or_assetAmount" [mandatory]="false"
        label="¿Cuál es el valor de {{ getValue('data.assetType').value }} de {{ selectedIndividual | fullname }}?"
        class="label-section">
        <nh-input-group prepend="$">
          <input nh type="decimal"  maxlength="7" name="assetAmount" formControlName="assetAmount" class="form-control">
        </nh-input-group>
      </nh-field>
    </div>

    <nh-field id = "or_owedAmount" [mandatory]="false"
      label="{{'OTHER_RESOURCES_STATIC_TEXT5' | translate }} {{ getValue('data.assetType').value }}?"
      class="label-section">
      <nh-input-group prepend="$">
        <input type="text" name="owedAmount" formControlName="owedAmount" class="form-control" placeholder="0.00"
        appTwoDigitDecimaNumber thousandSeparator="">
      </nh-input-group>
    </nh-field>

    <br>
    <div *ngIf="selectedIndividual">
    <h5 class="section-header">{{'CO-OWENERS_TITLE' | translate }}</h5>
    <hr>
    <nh-field id = "or_jointOwnership"
      label="{{'VEHICLE_STATIC_TEXT18' | translate }} {{ getValue('data.assetType').value }} {{'VEHICLE_STATIC_TEXT15' | translate }} {{ selectedIndividual | fullname }}? <div class=hint>{{'VEHICLE_STATIC_TEXT16' | translate }} {{ selectedIndividual | fullname }} {{'VEHICLE_STATIC_TEXT17' | translate }}</div>"
      class="label-section">
      <select nh name="jointOwnership" aria-label="jointOwnership" [items]="tableCodeValue('YESNO', 'data.jointOwnership')" formControlName="jointOwnership"></select>
    </nh-field>

    <div *ngIf="isY('data.jointOwnership')">
      <nh-co-owner [jointOwnershipInformation]="getControl('data.jointOwnershipInformation')"
        [formData]="getControl('data')" [addRowValid]="checkValid()" [coOwnerIndividuals]="coOwnerIndividuals" [coOwnerIndivErrorMessage]="coOwnerIndivErrorMessage"
        [error]="error" (emitValidate)="coOwnerValidate($event)" (errorMessage)="getErrorMessage($event)"
        (removeRow)="removeRow($event)" (addRow)="addRow()"></nh-co-owner>
    </div>
    </div>
  </section>
</nh-form>