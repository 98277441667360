export const bankSearch = {
    bankName: 'BANK_NAME'
}

export const assetAddress = {
    addressLine1: 'ADDRESS_LINE_1',
    addressLine2: 'ADDRESS_LINE_2',
    city: 'CITY',
    state: 'STATE',
    zip: 'ZIP_CODE'
}

export default {
    assetType: 'RMB_BURRIAL_STATIC1',
    assetAmount: 'BURRIAL_STATIC_TEXT7',
    owedAmount: 'RMB_BURRIAL_STATIC2',
    bankSearch: bankSearch,
    redesignatedFor: 'RMB_BURRIAL_STATIC3',
    jointOwnership: 'RMB_BURRIAL_STATIC4',
    assetAddress: 'RMB_BURRIAL_STATIC5',
    jointOwnershipInformation: 'RMB_C_CO_OWNERS',
    removed: {
        burrialEndDate: 'RMB_BURRIAL_STATIC8'
    }
}