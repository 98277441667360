import { KtReferralComponent } from './kt-referral.component';
import { filter, isNil } from 'ramda';

import Vs from '@app/services/validators/validators.service';
import { lookIn } from '@app/helpers/flipped.helpers';;
import { controlWith } from '@app/helpers/form.helpers';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { nhAddressCreator, nameCreator, phonesCreator } from '@app/helpers/models.helpers';

import ktReferralLabels from './kt-referral-labels';
import { checkboxHelper } from '@app/helpers/form-array.helpers';
import { aPath } from '@app/helpers/function.helpers';

const formValidation = (builder, recordData, { tables: { MPKBLIVING, MPAPLKBRSN }}) => {
  const ktReferral = lookIn(recordData);

  const houseMember = ktReferral(['houseMember']);
  const reasonsForApplyingToKb = aPath(['reasonsForApplyingToKb'], recordData);
  const currentLivingArrangement = ktReferral(['livingArrangement', 'currentLivingArrangement']);
  const kbOtherPhysicalAddressSwitch = ktReferral(['livingArrangement', 'kbOtherPhysicalAddressSwitch']);
  const kbAddressFormat = ktReferral(['livingArrangement', 'kbAddressFormat']);
  const kbSchoolSwitch = ktReferral(['livingArrangement', 'kbSchoolSwitch']);
  const kbContactIndicator = ktReferral(['contactInformation', 'kbContactIndicator']);
  const kbContactEmail = ktReferral(['contactInformation', 'kbContactEmail']);
  const kbContactInterpreterSwitch = ktReferral(['kbContactInterpreterSwitch']);
  const kbContactLanguage = ktReferral(['kbContactLanguage']);
  const kbContactRelationship = ktReferral(['kbContactRelationship']);
  const kbChange = ktReferral(['kbChange']);
  const kbRmcWlSw = ktReferral(['kbRmcWlSw']);
  const kbRmcDsnrSw = ktReferral(['kbRmcDsnrSw']);
  const pastYearLivingArrangement = aPath(['livingArrangement', 'pastYearLivingArrangement'], recordData);

  const livingArrangmentHelper = checkboxHelper(pastYearLivingArrangement, MPKBLIVING);
  const reasonsHelper = checkboxHelper(reasonsForApplyingToKb, MPAPLKBRSN);
  const helpers = builder.group({
    pastYearLivingArrangement: builder.group(livingArrangmentHelper(MPKBLIVING), {validator: Vs.required}),
    reasonsForApplyingToKb: builder.group(reasonsHelper(MPAPLKBRSN), {validator: Vs.required})
  });

  const data = builder.group({
    houseMember: [houseMember, Vs.required],
    reasonsForApplyingToKb: [reasonsForApplyingToKb, Vs.required],
    kbChange: [kbChange],
    kbRmcWlSw: [kbRmcWlSw],
    kbRmcDsnrSw: [kbRmcDsnrSw],
    livingArrangement: builder.group({
      kbAddressFormat: [controlWith(kbAddressFormat, !kbAddressFormat), Vs.required],
      address: builder.group(nhAddressCreator(ktReferral(['livingArrangement', 'address']))),
      currentLivingArrangement: [currentLivingArrangement, Vs.required],
      kbOtherPhysicalAddressSwitch: [kbOtherPhysicalAddressSwitch, Vs.required],
      kbSchoolSwitch: [controlWith(kbSchoolSwitch, !kbSchoolSwitch), Vs.required],
      pastYearLivingArrangement: [controlWith(pastYearLivingArrangement, !pastYearLivingArrangement), Vs.required],
    }),
    contactInformation: builder.group({
      kbContactIndicator: [kbContactIndicator],
      name: builder.group(nameCreator(ktReferral(['contactInformation', 'name']))),
      contactPhones: builder.group(phonesCreator(ktReferral(['contactInformation', 'contactPhones']))),
      kbContactEmail: [controlWith(kbContactEmail, !kbContactEmail), [Vs.required, Vs.email]],
    }),
    kbContactLanguage: [controlWith(kbContactLanguage, !kbContactLanguage), Vs.required],
    kbContactRelationship: [kbContactRelationship, Vs.required],
    kbContactInterpreterSwitch: [kbContactInterpreterSwitch, Vs.required],
    hiddenField: ['', Vs.required]
  });

  return { data, helpers };
};

const childName = ({ data }, { individual }) => {
  const selectedChild = filter(n => n.identifier == data.houseMember, individual.individuals)[0]
  return `${selectedChild.name.fullName}, ${selectedChild.age} ${selectedChild.gender.code}`
 };

const reasonsForKT = ({data}) => {
  let reasons = '';
  data.reasonsForApplyingToKb.forEach((item, index) => {
    reasons += item.value + ((index == data.reasonsForApplyingToKb.length - 1) ? '' : ',');
  });
  return reasons;
}

const primaryContact = ({ data }, { individual}) => {
   if (data?.contactInformation?.kbContactIndicator === 'HOH') {
     return filter(n => isNil(n.applicantRelationship), individual.individuals)[0]?.name?.fullName;
   } else if (data?.contactInformation?.kbContactIndicator === 'ATH') {
    return filter(n => isNil(n.applicantRelationship), individual.individuals)[0]?.assistingPerson?.name.fullName;
   } else {
     return data?.contactInformation?.name?.fullName;
   }
}

export default sectionConfiguration(
  KtReferralComponent,
  formValidation,
  ['TAYN', 'MPAPLKBRSN', 'MPKBLIVING', 'MPKBCHNG', 'PRIMCONTREL', 'YESNO', 'ADDRESSFORMATTYPES',
    'NAMESUFFIX', 'STATE', 'COUNTY', 'PRIMARYLANGUAGE', 'MPKBLIVINGNOW', 'MILITARYPOCODES', 'STATEMILITARY'],
    [
      { name: 'Child', pipe: 'domain', args: childName },
      { name: 'Reason for Applying <br> to Katie Beckett',pipe: 'domain', args: reasonsForKT},
      { name: 'Primary Contact', pipe: 'domain', args: primaryContact },
    ],
  {...{}, ...ktReferralLabels},
  {}
);
