import { Injectable } from '@angular/core';
import { ApiService } from '@app/gateway/services/api.service';
import { AuthService } from '@app/gateway/services/auth.service';
import { HttpHeaders } from '@angular/common/http';

import { UserIdleService } from '@app/services/idle/user-idle.service';
import { ModalService } from '@app/services/modal/modal.service';
import { TokenService } from '@app/services/token/token.service';

import { onFirst } from '@app/helpers/http.helpers';
import { trudy, iif } from '@app/helpers/function.helpers';
import { SigninService } from '@app/gateway/signin/signin.service';
import { of, Subject } from 'rxjs';
import { isPartner } from '@app/helpers/mode.helpers';
import { ChatbotComponent } from '@app/components/chatbot/chatbot.component';

@Injectable()
export class IdleService {
  public isSessionExpired$ = new Subject<boolean>();
  constructor(private idle: UserIdleService,
    private modal: ModalService,
    private token: TokenService,
    private authService: AuthService,
    private apiService: ApiService,
    private signinService: SigninService
  ) {
    this.idle.setConfig({ idle: 720, timeout: 120, ping: 120 });
  }

  start() {
    const { timeout } = this.idle.getConfigValue();
    this.idle.startWatching();
    this.onTimerStart(timeout);
    this.onTimeout();
    this.onRefresh();
  }

  restart() {
    this.idle.resetTimer();
  }

  onTimerStart(timeout) {
    this.idle.onTimerStart().subscribe(onFirst(() => this.showWarning(timeout)));
  }

  onTimeout() {
    this.idle.onTimeout().subscribe(trudy(() => {
      this.signinService.signOut(false);
      this.modal.closeModal();
      if (ChatbotComponent.chatbotInstance) {
        ChatbotComponent.chatbotInstance.destroy();
      }
      this.signOut(true);
    }));
  }

  onRefresh() {
    this.idle.ping$.subscribe(() => {
      return JSON.parse(sessionStorage.getItem('isLogout')) ? of(null) : this.refresh();
    });
  }

  showWarning(timeout) {
    return this.modal.openTimeout(timeout).subscribe(iif(() => this.restart(), () => this.signOut(true))); //
  }

  refresh() {
    return this.token.refresh();
  }

  signOut(flag) {
    const userType = sessionStorage.getItem('userType');
    const isPartnerPortal = isPartner(userType);
    const idealTimeFlag = isPartnerPortal && flag ? 'Y' : 'N';
    this.isSessionExpired$.next(true);
    const url = 'indi/signout';
    const cx = sessionStorage.getItem('tcam');
    document.cookie = "TCAM=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    const headers = new HttpHeaders({ Authorization: this.authService.tokenId });
    this.apiService.post(url, {'tcam': cx, 'idealTimeFlag': idealTimeFlag }, { headers }).subscribe();
    this.idle.stopWatching();
    return this.token.signOut();
  }

  getCookie(name: string) {
    let cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
      let cookiePair = cookieArr[i].split("=");
      if (name == cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }

    return null;
  }
}
