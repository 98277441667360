import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { StorageService } from '@app/services/storage/storage.service';
import { SpinnerService } from '@app/services/spinner/spinner.service';

@Component({ template: `` })
export class NotFoundComponent {
  constructor(
    private router: Router, //
    private spinner: SpinnerService,
    private storage: StorageService
  ) {
    this.spinner.increment();
    this.router //
      .navigate(['/application/', this.storage.getAppNumber()])
      .then(() => this.spinner.decrement());
  }
}
