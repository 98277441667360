import { NewbornInformationComponent } from './newborn-information.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import Vs from "@app/services/validators/validators.service";
import {toDate, toISO} from "@app/helpers/date.helpers";
import { evolve } from 'ramda';
import { lookIn } from '@app/helpers/flipped.helpers';
import { controlWith } from '@app/helpers/form.helpers';
import { isNo } from '@app/helpers/tayn.helpers';

const formValidation = (builder, recordData, { allRecords }) => {
  let newbornInfo;
  // Do not remove this log
  console.log(recordData);
  if (sessionStorage.getItem('recordId') && sessionStorage.getItem('recordId') != "null" && sessionStorage.getItem('recordId') != '-1'){
    const resultRecord = allRecords.filter((item => (item.identifier.recordId === sessionStorage.getItem('recordId'))));
    newbornInfo = lookIn(resultRecord[0].data);
  } else if (sessionStorage.getItem('recordId') && sessionStorage.getItem('recordId') == '-1') {
    const recordId = Math.max.apply(Math, allRecords.map(function(o) { return o.identifier.recordId; }))
    const previousRecord = allRecords.filter((item => (item.identifier.recordId === recordId)));
    newbornInfo = lookIn(previousRecord[0].data);
  } else {
    newbornInfo = lookIn(null);
  }

  
  const childDeathDt = toDate(newbornInfo(['childDeathDt']))
  const alienNum = newbornInfo(['alienNum']);
  const naturalCertNum = newbornInfo(['naturalCertNum'])
  const derivedCtznSw = newbornInfo(['derivedCtznSw']);
  const usCtznSw = newbornInfo(['usCtznSw'])

  const data = builder.group({
    indvId: sessionStorage.getItem('individualId'),
    childFirstName: [newbornInfo(['childFirstName']), [Vs.required, Vs.invalidName]],
    childMidName: [newbornInfo(['childMidName']), Vs.language],
    childLastName: [newbornInfo(['childLastName']), [Vs.required, Vs.minLength(2), Vs.invalidName]],
    childDob: [toDate(newbornInfo(['childDob'])), [Vs.required, Vs.notGreaterThan1YearBabyDate]],
    ssn: [controlWith(newbornInfo(['ssn']), isNo(usCtznSw)), Vs.ssn],
    ssnConfirm: [controlWith(newbornInfo(['ssnConfirm']), isNo(usCtznSw)), Vs.ssn],
    genderCd: [newbornInfo(['genderCd']), Vs.required],
    childDeceasedSw: [newbornInfo(['childDeceasedSw']), Vs.required],
    childDeathDt: [controlWith(childDeathDt, !childDeathDt), Vs.required],
    facilityBirthSw: [newbornInfo(['facilityBirthSw']), Vs.required],
      usCtznSw: [controlWith(usCtznSw, !usCtznSw), Vs.required],
      derivedCtznSw: [controlWith(derivedCtznSw, !derivedCtznSw), Vs.required],
      alienNum: [alienNum, [Vs.alienNumber]],
      naturalCertNum: [naturalCertNum, [Vs.naturalizationNumber]],
      childSsiSw : sessionStorage.getItem('ssiSw'),
      childFosterCareSw : sessionStorage.getItem('fosterCareSw ')
  })

  return { data };
};

const postObject = evolve({
    childDob: toISO,
    childDeathDt: toISO
})

export default sectionConfiguration(
  NewbornInformationComponent,
  formValidation,
  ['PPAMDIS', 'GENDER', 'YESNO'],
  [],
  {},
  {
    postObject,
    hasSummary: false,
    validateSubmit: true,
  }
);
