<div class="container">
	<div class="row">
		<!-- <nh-alert type="danger" [isOpen]="showEmailAlert" [dismissible]="true">
			{{ 'EMAIL_USED' | translate }}
		</nh-alert> -->

		<nh-alert type="danger" [isOpen]="showEmailAlert()">
			<div class="row">
				<div class="icon">
					<img src="../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
				</div>
				<div class="message">
					<span>{{ 'EMAIL_USED' | translate }}</span>
				</div>
			</div>
		</nh-alert>
		
		<div class="card" style="width:100%;">
			<div class="card-body">
				<section>
					<h5 class="section-header">{{'CHANGE_EMAIL' | translate }}</h5><hr>
					<p [innerHtml]="'SAVE_EMAIL' | translate"></p>
					<form [formGroup]="updateEmailFrom">
						<div class="col-md-6">
							<nh-field [isRow]="true" label="{{'CURRENT_EMAIL' | translate }}" [mandatory]="false" class="label-section">
								<input type="email" name="currentEmail" aria-label="currentEmail" [attr.disabled]="true" formControlName="currentEmail"
									class="form-control">
							</nh-field>
							<nh-field [isRow]="true" label="{{'NEW_EMAIL' | translate }}" class="label-section">
								<input type="email" name="newEmail" formControlName="newEmail" class="form-control"
									placeholder="name@example.com" required>
							</nh-field>
						</div>
					</form>
					<br><br><br><br>
				</section>
			</div>
		</div>
	</div>
</div>
