import { curry } from 'ramda';
import { symmetricDifference } from 'ramda';
import { complement } from 'ramda';
import { mergeAll } from 'ramda';
import { flatten } from 'ramda';
import { compose } from 'ramda';
import { ifElse } from 'ramda';
import { always } from 'ramda';
import { when } from 'ramda';
import { join } from 'ramda';

import { isNonEmptyArray } from 'ramda-adjunct';
import { isEmptyArray } from 'ramda-adjunct';
import { contained } from 'ramda-adjunct';
import { compact } from 'ramda-adjunct';

import { codes } from './code.helpers';
import { removeNil } from './value.helpers';
import { tableLookup } from './tables.helpers';

export const listChanged = curry(
  compose(
    isNonEmptyArray,
    symmetricDifference
  )
);

const isSelected = compose(
  contained,
  codes
);

export const inSelected = (selected, table) => ifElse(isSelected(selected), tableLookup(table), always(null));

export const nonSelected = compose(
  isEmptyArray,
  compact,
  removeNil
);

export const flatMerge = compose(
  mergeAll,
  flatten
);

export const ifItems = when(isNonEmptyArray);

export const newLineSeparated = join(', <br>');

export const notContained = complement(contained);
