import { SignSubmitComponent } from './sign-submit.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { lookUp } from '@app/helpers/flipped.helpers';
import Vs from '@app/services/validators/validators.service';
import { ContinueLabels } from '@app/models/section.model';

const formValidation = (fb, recordData) => { 
  const submit = lookUp(recordData);
  const applicationSignName = submit(['applicationSignName']);
  const applicationSignedBySw = submit(['applicationSignedBySw']);
  const applicationSignedByTC = submit(['applicationSignedByTC']);
  const partnerSignName = submit(['partnerSignName']);

  const data = fb.group({
    applicationSignedBySw: [applicationSignedBySw, Vs.required],
    applicationSignName: [applicationSignName, [Vs.required, Vs.language]],
    applicationSignedByTC: [applicationSignedByTC, Vs.required],
    partnerSignName: [partnerSignName, [Vs.required, Vs.language]],
  });
  
  return {data}
};

export default sectionConfiguration(SignSubmitComponent, formValidation, [], [], {}, {
  hasSummary: false,
  showComments: true,
  showSubmit: false,
  labelContinue: ContinueLabels.SUBMIT,
});
