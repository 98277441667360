<ng-container [formGroup]="checkboxGroup">
  <label class="col-form-label" *ngIf="label">{{ label }}</label>
  <div class="mb-2">
    <demographics-checkbox [checkboxValue] = "getCheckboxValue(checkbox.reference, checkbox.control) " 
    [disabled] = "isCheckboxDisabled(checkbox.reference)" 
    (valueChange)="setFromList($event)" 
    *ngFor="let checkbox of checkboxList"
     [inline]="inline" [control]="checkboxGroup.get(checkbox.control)" [checkedValue]="checkbox.reference"
      [label]="checkbox.reference.value"  [name]="checkbox.control" [index]="uniqueString">
    </demographics-checkbox>
  </div>
</ng-container>
