import { AppealDemographicsComponent } from './appeal-demographics.component';
import { nhAddressCreator, sectionConfiguration } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import Vs from '@app/services/validators/validators.service';


const formValidation = (builder, recordData) => {
  const Appealdemographics = lookIn(recordData);
  const addressFormatP = Appealdemographics(['appealAddress', 'addressFormat']);
  const address = Appealdemographics(['appealAddress', 'address']);
  const appellantName = Appealdemographics(['appellantName']);
  const caseNumber = Appealdemographics(['caseNumber']);
  const data = builder.group({
      appealAddress: builder.group({
      address: builder.group(nhAddressCreator(address)),
      addressFormat: [ addressFormatP ],
      addressType: Appealdemographics(['appealAddress', 'addressType']),
    }),
    appellantName: appellantName,
    caseNumber: caseNumber,
    appellantDemographics: builder.group({
      futureAddressSw: [Appealdemographics(['appellantDemographics', 'futureAddressSw']), Vs.required],
      languagePreference: [Appealdemographics(['appellantDemographics', 'languagePreference']), Vs.required],
      appealHearInterpreterSw: Appealdemographics(['appellantDemographics', 'appealHearInterpreterSw']),
      emailAddress: [Appealdemographics(['appellantDemographics', 'emailAddress']), [Vs.email]],
      bestTime: [Appealdemographics(['appellantDemographics', 'bestTime']), Vs.required], 
      appellantcontactInfo: builder.group({
        home: [Appealdemographics(['appellantDemographics','appellantcontactInfo', 'home']), [Vs.required, Vs.phoneNumber]],
        message: [Appealdemographics(['appellantDemographics','appellantcontactInfo', 'message']), Vs.phoneNumber],
        extension: Appealdemographics(['appellantDemographics','appellantcontactInfo', 'extension']),
      }),
    }),
    addressEditValidation: null,
  });
  return { data };
};

export default sectionConfiguration(AppealDemographicsComponent, formValidation, ['YESNO', 'CONTACTTIME', 'ADDRESSFORMATTYPES', 'WRITTENLANGUAGE', 'STATE', 'COUNTY', 'MILITARYPOCODES', 'STATEMILITARY'], [], {}, {
  hasSummary: false,
  showSubmit: false,
  showComments: false,
  showSaveAndExit: false
});
