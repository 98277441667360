<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <div [formGroup]="getControl('data')">
    <h6 class="person-label">Head of Household: {{ this.fullName }}</h6><br>
    <h5 class="section-header">Application Date</h5>
    <hr>
    <div class="form-row">
      <div class="col-6 col-md-6">
        <nh-field id = "hhp_dateOfApplication" label="Date of Application">
          <nh-datepicker>
            <input type="text" class="form-control" name="dateOfApplication" formControlName="dateOfApplication"
              placeholder="mm/dd/yyyy" [minDate]="minimumDate" [maxDate]="today" bsDatepicker nh required>
          </nh-datepicker>
        </nh-field>
      </div>
    </div>

    <h5 class="section-header">Head of Household Demographics</h5>
    <hr>
    <p>
      If you're using TennCare Access to add a newborn born to a mother who already
      has TennCare or CoverKids, be sure to add the enrolled mother first. Include as
      much of her information as possible and mark her as Not Applying. Then add the
      newborn baby to the application and mark the baby as applying.
      Doing it in the order will help us process the baby's information faster.
    </p>
    <nh-name-address [addressname] = "'hhp_casehead'" [personalName]="getControl('data.casehead')" [isAssistingPerson]="false" [tables]="tables">
    </nh-name-address>
    <br>

    <nh-field id = "hhp_homeless" name="homeless" label="Are you homeless or living in a shelter?" class="label-section">
      <select nh name="homeless" aria-label="homeless" [items]="tables.YESNO" formControlName="homeless" required></select>
    </nh-field>
    <nh-field id = "hh_safeAtHome" [mandatory]="false" name="safeAtHome" label="{{'MAIL_THROUGH_SAFEATHOME' | translate}}" [labelSize]="false">
      <div class="tooltip">
        <span><a class="toolTip"> <img [src]="'assets/images/Icons/tool-tip-icon.svg'" alt="tooltip"/></a></span>
        <span class="tooltiptext" [innerHTML]="'SAFEATHOME_POPOVER'| translate" placement="auto"></span>
      </div>      
      <select nh name="safeAtHome" [items]="tables.YESNO" aria-label="safeAtHome" formControlName="sahPrgSw" (change)="safeAtHomeOnChange($event)"></select>
    </nh-field>

    <nh-field *ngIf="isY('data.sahPrgSw')" id = "hh_mailId" label="{{'SAFEATHOME_MAILID' | translate }}" class="label-section">
      <div class="hint ml-2">{{'SAFEATHOME_MAILID_HELPTEXT' | translate}}</div>
      <input type="text" name="sahMailId" aria-label="mailId" class="form-control" formControlName="sahMailId" maxlength="50">
    </nh-field>

    <nh-field *ngIf="isY('data.sahPrgSw')" id = "hh_safeAtHomeCounty"  name="sahCountyCd" label="{{'SAFEATHOME_COUNTY' | translate}}" [labelSize]="false">
      <select nh name="sahCountyCd" [items]="tables.COUNTY" aria-label="safeAtHomeCounty" formControlName="sahCountyCd"></select>
    </nh-field>
    <div *ngIf="!isY('data.sahPrgSw')">
    <h5 *ngIf="isEnabled('data.physicalAddress.addressFormat')" class="section-header">Residential Address</h5>
    <hr *ngIf="isEnabled('data.physicalAddress.addressFormat')">
    <div [formGroup]="getControl('data.physicalAddress')" *ngIf="isEnabled('data.physicalAddress.addressFormat')">
      <div *ngIf="isEnabled('data.physicalAddress.addressFormat')">
        <nh-field id = "hhp_physical_addressFormat" name="addressFormat" label="Address Format" class="label-section">
          <nh-popover class="pl-1" content="{{'CASE_HEAD_POPOVER_TEXT' | translate}}"></nh-popover>
          <select nh name="addressFormat" aria-label="addressFormat" [items]="tables.ADDRESSFORMATTYPES" formControlName="addressFormat"></select>
        </nh-field>
      </div>
      <nh-name-address [addressname] = "'hhp_physicalAddress'" [addressFormat]="getControl('data.physicalAddress.addressFormat')"
        [address]="getControl('data.physicalAddress.address')" [isAssistingPerson]="false" [tables]="tables">
      </nh-name-address><br>
    </div>
  </div>
  <div *ngIf="!isY('data.sahPrgSw')">
    <h5 class="section-header">Mailing Address</h5>
    <hr>
    <nh-field id = "hhp_addMailingInfo" *ngIf="isEnabled('extras.helpers.addMailingInfo')"
      label="Is the Mailing Address the same as the Residential Address?" class="label-section">
      <select nh name="addMailingInfo" aria-label="addMailingInfo"  [items]="tables.YESNO"
        [formControl]="getControl('extras.helpers.addMailingInfo')" required></select>
    </nh-field>

    <div *ngIf="isY('data.homeless')">
      <p>If you are homeless or if you don't want us to send any letters about your coverage to the address you've
        given
        us above, enter the mailing address where we can send your mail.</p>
      <p>What if you don't have a mailing address? Use a family member, friend, shelter, or agency address where you
        can
        get your mail. We cannot make a decision on your application if you do not give us an address.</p>
    </div>

    <section [formGroup]="getControl('data.mailingAddress')" *ngIf="isEnabled('data.mailingAddress.addressFormat')">
      <div *ngIf="isEnabled('data.mailingAddress.addressFormat')">
        <nh-field id = "hhp_mailing_addressFormat" name="addressFormat" label="Address Format" class="label-section">
          <nh-popover class="pl-1" content="{{'CASE_HEAD_POPOVER_TEXT' | translate}}"></nh-popover>
          <select nh name="addressFormat" aria-label="addressFormat" [items]="tables.ADDRESSFORMATTYPES" formControlName="addressFormat"></select>
        </nh-field>
      </div>
      <nh-name-address [addressname] = "'hhp_mailingAddress'" [addressFormat]="getControl('data.mailingAddress.addressFormat')" [mailing]= "true"
        [address]="getControl('data.mailingAddress.address')" [tables]="tables"></nh-name-address>
    </section>
    <br>
  </div>
    <h5 class="section-header">Additional Information</h5>
    <hr>
    <section formGroupName="languagePreference">
      <nh-field id = "hhp_letterLanguage" name="letterLanguage" label="Do you prefer to receive your letters in English or Spanish?" class="label-section">
        <nh-popover class="pl-1" [content]="languagePopup"></nh-popover>
        <select nh name="letterLanguage" aria-label="letterLanguage" [items]="tables.WRITTENLANGUAGE" formControlName="letterLanguage"
          required></select>
      </nh-field>
    </section>
    <div [formGroup]="getControl('data.physicalAddress.contactPhones')">
      <div class="row">
        <div class="col">
          <nh-field id = "hhp_home" [mandatory]="false" label="Applicant's Home Phone Number" class="label-section">
            <input type="text" name="home" formControlName="home" class="form-control" mask="000-000-0000"
              placeholder="XXX-XXX-XXXX">
          </nh-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <nh-field id = "hhp_cell" [mandatory]="false" label="Applicant's Cell Phone" class="label-section">
            <input type="text" name="cell" formControlName="cell" class="form-control" mask="000-000-0000"
              placeholder="XXX-XXX-XXXX">
          </nh-field>
        </div>
      </div>
    </div>
    <nh-field id = "hhp_healthPlan" label="Health Plan (MCO) Preference" [mandatory]="false" class="label-section">
      <select *ngIf="!showWellpoint" nh name="healthPlan" aria-label="healthPlan" [items]="tables.MCONAME" formControlName="healthPlan"></select>
      <select *ngIf="showWellpoint" nh name="healthPlan" aria-label="healthPlan" [items]="tables.MCONAME_UBW" formControlName="healthPlan"></select>
       </nh-field>
    <br>
    <h5 class="section-header">Assisting Person</h5>
    <hr>

    <p>An Assisting Person can be someone who is helping you complete this application, or it can be someone that,
      with your permission, can make decisions and talk to us on behalf of your household.</p><br>
      
    <p>If you want an Assisting Person to help you, you can tell us what you want your Assisting Person to help with.</p>

    <nh-field  id = "hhp_ppAddAssistingPersonToApp"
    label="Does {{ fullName }} want to add {{partnerName}} as an Assisting Person on this application?" class="label-section">
    <nh-popover class="pl-1" field-label [content]="assistingPersonPop2"></nh-popover>
    <select nh name="ppAddAssistingPersonToApp" aria-label="ppAddAssistingPersonToApp" [items]="tables.YESNO" 
    formControlName="ppAddAssistingPersonToApp" (change)="onAssistingPersonPartnerSelected($event)"></select>
  </nh-field>
  <p>If your Assisting Person is part of an organization helping you apply, such as a hospital, a doctor, or a 
    nursing home, the employee representative <b>must complete the information and sign below.</b> They must also agree 
    that: <br><br>As an employee, staff member or volunteer with the named organization or provider below, they 
    affirm that they will adhere to 42 CFR 431 (f), 42 CFR 155.260(f) and 45 CFR 447.10, as well as other relevant 
    State ands Federal laws concerning conflicts of interest and confidentiality of information. The organization 
    or provider shall notify the Agency of any change in name or contact information for the representative within 
    ten (10) days of the change.</p>

    <nh-field id = "hhp_ppAssistingOnBehalf" *ngIf="isEnabled('data.ppAssistingOnBehalf')"
    label="Does {{ fullName }} want to list an Assisting Person?" class="label-section">
    <div class="hint ml-2">(This would be someone other than {{partnerName}})</div>
      <select nh name="ppAssistingOnBehalf" [items]="tables.YESNO" (change)="onAssistingPersonPartnerSelectedBehalf($event)"aria-label="ppAssistingOnBehalf"
      formControlName="ppAssistingOnBehalf"></select>
    </nh-field>

    <section [formGroup]="getControl('data.assistingPerson')">
      <div>
        <div *ngIf="(isY('data.ppAddAssistingPersonToApp') || getValue('data.ppAssistingOnBehalf').value === 'Assisting Person') && !(user === 'RP' && appType === 'AFB')">
        <h5 class="section-header">
          Assisting Person Information
        </h5>
        <hr>
      </div>

        <nh-field id = "hhp_helpDuration" [mandatory]="false" *ngIf="isEnabled('data.assistingPerson.helpDuration')"
          label="How long do you want this person to be your Assisting Person?" class="label-section">
          <div class="hint">If you don't answer, this person will be your Assisting Person until you tell us otherwise.</div>
          <select nh name="helpDuration" [items]="tables.AUTHREPTIME" aria-label="helpDuration" formControlName="helpDuration"></select>
        </nh-field>

        <nh-field id = "hhp_relationship" *ngIf="isEnabled('data.assistingPerson.relationship')"
          label="What is this person's relationship to the applicant?" class="label-section">
          <select nh name="relationship" [items]="tables.AUTHREPRELATIONSHIP" aria-label="relationship" (change)="Selected($event)" formControlName="relationship"></select>
        </nh-field>
        
        <div class="row pl-1 pt-1 hover-red">
          <a class="btn btn-link hover-red" (click)="showAssistPerson()">
            <span class="pr-2">To learn more about the types of
              Assisting Persons, click here</span>
            <span><i class="fa fa-external-link"></i></span>
          </a>
        </div>

        <nh-alert type="warning" [isOpen]="getValue('data.assistingPerson.relationship').code === 'CO' || getValue('data.assistingPerson.relationship').code === 'PA' || getValue('data.assistingPerson.relationship').code === 'LG'">
          <div class="row">
            <div class="icon">
              <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
            </div>
            <div class="message">
              <span>To act on behalf of {{ this.fullName }}
                as [Conservator/Legal Guardian/Power of Attorney] for our programs, you must send proof of your status, such
                as a Court Order, or other legal documentation. Responsibilities or permissions won't be granted to you
                until we receive this proof.</span>
            </div>
          </div>
        </nh-alert>
      </div>

      <nh-field id = "hhp_otherRelationship" *ngIf="isEnabled('data.assistingPerson.otherRelationship')" name="otherRelationship" [mandatory]="true"
        label="Other Relationship" class="label-section">
        <label><input type="text" maxlength="20"  name="otherRelationship" class="form-control" formControlName="otherRelationship" aria-label="Other Relationship"></label>
      </nh-field>

      <div
        *ngIf="getValue('data.assistingPerson.relationship').code === 'AR'">
        <h5 class="section-header">{{'ASSIST_PERSON_RESP' | translate }}</h5>
        <hr>
        <div class="mb-1"><span class="text-danger">*</span><strong> Read the responsibilities and permissions below. Then tell us the
        responsibilities and permission you are giving to your Assisting Person:</strong></div>
        

        <div [formGroup]="getControl('data.assistingPerson.responsibilities')" class="form-check pb-2 pb-md-1">
          <nh-checkbox name="applicantBehalf"
            [control]="getControl('data.assistingPerson.responsibilities.applicantBehalf')" checkedValue="A"
            label="Sign an application on the applicant's behalf." class="label-section" (change)="onChange($event, 'A')">
          </nh-checkbox>
          <nh-checkbox  name="submitRenewal"
            [control]="getControl('data.assistingPerson.responsibilities.submitRenewal')" checkedValue="S"
            label="Complete and submit a renewal form." class="label-section" (change)="onChange($event, 'S')">
          </nh-checkbox>
          <nh-checkbox  name="letterOtherCommunication"
            [control]="getControl('data.assistingPerson.responsibilities.letterOtherCommunication')" checkedValue="L"
            label="Receive copies of your letters and other communications from us." class="label-section" (change)="onChange($event, 'L')">
          </nh-checkbox>
          <nh-checkbox  name="yourBehalf" [control]="getControl('data.assistingPerson.responsibilities.yourBehalf')"
            checkedValue="Y" label="Act on your behalf on all other matters with us." class="label-section" (change)="onChange($event, 'Y')">
          </nh-checkbox>
        </div>

        <div *ngIf="false" class="alert alert-warning" role="alert">
          <div><span><i class="fa fa-exclamation-circle"></i></span>&nbsp;&nbsp;Are you sure you want to receive copies
            of this person's notices? This means that you will be mailed a paper copy of all future notices for this
            person.</div>
        </div>
      </div>
        <br>
      <div *ngIf="(isY('data.ppAddAssistingPersonToApp') || isY('data.ppAssistingOnBehalf'))">
        <div>
          <nh-checkbox  name="ackSignSw"
          [control]="getControl('data.assistingPerson.ackSignSw')" checkedValue="E" [mandatory]=true 
          label="By checking this box and typing my name below, I am electronically signing as the assisting person." class="label-section"></nh-checkbox>
        </div>
        <div>
          <nh-field id = "hhp_assistingPersonSignName" label="Signature:" class="label-section">
            <input type="text" class="form-control" name="assistingPersonSignName" formControlName="assistingPersonSignName"
              aria-label="assistingPersonSignName" maxlength="40">
          </nh-field>
        </div>
      </div>
   
      <section class="pt-3">
        <nh-alert type="warning" [isOpen]="validateAssistingPersonName()">
          <div class="row">
            <div class="icon">
              <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
            </div>
            <div class="message">
              <span>{{ 'ASSISTING_PERSON_ERROR' | translate }}</span>
            </div>
          </div>
        </nh-alert>
        <div *ngIf="isEnabled('data.assistingPerson.name')">
          <h5 class="section-header">Assisting Person Name</h5>
          <hr>
          <nh-name-address [addressname] = "'hhp_name'" [personalName]="getControl('data.assistingPerson.name')" [tables]="tables"></nh-name-address>
          <br>
        </div>

        <nh-field id = "hhp_organizationName" *ngIf="isEnabled('data.assistingPerson.organizationName')" name="organizationName" [mandatory]="false"
          label="Organization Name? (if applicable)" class="label-section">
          <input type="text" name="organizationName" class="form-control" aria-label="organizationName" formControlName="organizationName"
            maxlength="256">
        </nh-field>

        <nh-field id = "hhp_idNumber" *ngIf="isEnabled('data.assistingPerson.idNumber')" name="idNumber" [mandatory]="false"
          label="ID Number (if applicable)" mask="9999999999" class="label-section">
          <input type="text" name="idNumber" class="form-control" aria-label="idNumber" formControlName="idNumber" maxlength="30">
        </nh-field>

        <div *ngIf="isEnabled('data.assistingPerson.address')">
          <h5 class="section-header">Assisting Person Address</h5>
          <hr>
          <div>
            <nh-field id = "hhp_assisting_addressFormat" name="addressFormat" label="Address Format" class="label-section">
              <nh-popover class="pl-1" content="{{'CASE_HEAD_POPOVER_TEXT' | translate}}"></nh-popover>
              <select nh name="addressFormat" aria-label="addressFormat" [items]="tables.ADDRESSFORMATTYPES"
                formControlName="addressFormat"></select>
            </nh-field>
          </div>
          <nh-name-address [addressname] = "'hhp_assisting_addressFormat'" [addressFormat]="getControl('data.assistingPerson.addressFormat')"
            [address]="getControl('data.assistingPerson.address')" [isAssistingPerson]="true" [tables]="tables">
          </nh-name-address>
          <br>
        </div>

        <div *ngIf="isEnabled('data.assistingPerson.email')">
          <h5 class="section-header">Assisting Person Contact</h5>
          <hr>
          <nh-field id = "hhp_email" [mandatory]="false" label="Email Address" class="label-section">
            <input type="email" name="email" class="form-control" aria-label="email" formControlName="email" maxlength="63">
          </nh-field>
        </div>

        <nh-field id = "hhp_bestTime" *ngIf="isEnabled('data.assistingPerson.bestTime')" [mandatory]="false"
          label="What is the best time to call you during the week?" class="label-section">
          <select nh name="bestTime" aria-label="bestTime" [items]="tables.CONTACTTIME" formControlName="bestTime"></select>
        </nh-field>

        <nh-contact-phones [contactPhoneName] = "'hhp_assistingPerson_contactPhones'" *ngIf="isEnabled('data.assistingPerson.contactPhones')"
          [phoneNumbers]="getControl('data.assistingPerson.contactPhones')"></nh-contact-phones>
      </section>
      <br>
    </section>
    <div>
      <input id = "hhp_hiddenField" type="hidden" formControlName="hiddenField">
    </div>
  </div>
</nh-form>