import { identity } from 'ramda';

import { sectionConfiguration } from '@app/helpers/models.helpers';

export default sectionConfiguration(
  null,
  identity,
  [],
  [{ name: 'Name', prop: 'data.name.fullName' }, { name: 'Date of Birth', prop: 'data.birthDate', pipe: 'date' }],
  { individualsFilter: identity }
);
