import { construct } from 'ramda';
import {
  LoadSection,
  RemoveRecord,
  RemoveDataRecord,
  SetSection,
  UpdateSection,
  CreateRecord,
  UpdateRecord,
  SetRecord,
  SaveQuestionnaire,
  HandlePayload,
  UpdatePeopleSumSection,
} from './section.actions';

export const loadSection = construct(LoadSection);
export const setSection = construct(SetSection);
export const updateSection = construct(UpdateSection);
export const updatePeopleSumSection = construct(UpdatePeopleSumSection);

export const createRecord = construct(CreateRecord);
export const updateRecord = construct(UpdateRecord);
export const removeRecord = construct(RemoveRecord);
export const removeDataRecord = construct(RemoveDataRecord);
export const setRecord = construct(SetRecord);

export const saveQuestionnaire = construct(SaveQuestionnaire);
export const handlePayload = construct(HandlePayload);
