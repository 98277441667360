import { Component, OnInit, ViewChild, Output, ElementRef, EventEmitter } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ServiceConfigService } from '@app/services/service-config.service';

@Component({
  selector: 'nh-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Output() passFileData = new EventEmitter<any>();
  @Output() fileUploadStarted = new EventEmitter<any>();

  uploadUrl: string = `${this.serverConfig.getServerConfig()['gatewayURL']}` + 'rest/api/indi/neededDocuments/addDocument';
  isInvalidFileSize: boolean = false;
  isInvalidFileType: boolean = false;
  showErrorMessage: boolean = false;
  isPasswordProtected: boolean = false;
  isIMEappType = sessionStorage.getItem('appType') === 'IME' ? true : false;

  public uploader: FileUploader = new FileUploader({
    url: this.uploadUrl,
    disableMultipart: false,
    headers: [
      // { name: 'Access-Control-Allow-Headers', value: 'Content-Type' }, 
      // { name: 'Access-Control-Allow-Methods', value: 'POST' }, 
      // { name: 'Access-Control-Allow-Origin', value: '*' },
      { name: 'Authorization', value: sessionStorage.getItem('tokenId') }
    ],
    method: 'POST',
    itemAlias: 'file'
  });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;
  @ViewChild('fileInput', { static: false }) uploadElRef: ElementRef;

  constructor(private serverConfig: ServiceConfigService) { }

  ngOnInit(): void {
    this.uploader.onAfterAddingFile = (item => {
      if (item['file']['size'] <= 5000000) {
        item.withCredentials = false;
        this.fileUploadStarted.emit({fileName:item['file']['name'], displayLoading: true});
      }
    })

    this.uploader.onSuccessItem =((item: FileItem, response: string) => {
      if(!this.isIMEappType) {
      if (response === '102') {
        this.showErrorMessage = true;
        return;
      }
      if(response === '101') {
        this.isInvalidFileType = true;
        return;
      }
      if(response === '100') {
        this.isPasswordProtected = true;
        this.fileUploadStarted.emit({fileName:item['file']['name'], displayLoading: false});
      } else {
        this.isPasswordProtected = false;
      }
      if (item['file']['size'] <= 5000000 && !this.isPasswordProtected) {
        this.fileUploadStarted.emit({fileName:item['file']['name'], displayLoading: false});
        this.onSuccess(item, response)
      }
    } else {
      if (response === '102') {
        this.showErrorMessage = true;
        this.fileUploadStarted.emit({fileName:item['file']['name'], displayLoading: false});
      } else {
        this.showErrorMessage = false;
      }
      if(response === '101') {
        this.isInvalidFileType = true;
        this.fileUploadStarted.emit({fileName:item['file']['name'], displayLoading: false});
      } else {
        this.isInvalidFileType = false;
      }
      if(response === '100') {
        this.isPasswordProtected = true;
        this.fileUploadStarted.emit({fileName:item['file']['name'], displayLoading: false});
      } else {
        this.isPasswordProtected = false;
      }
      if (item['file']['size'] <= 5000000 && !this.isPasswordProtected && !this.showErrorMessage && !this.isInvalidFileType) {
        this.fileUploadStarted.emit({fileName:item['file']['name'], displayLoading: false});
        this.onSuccess(item, response)
      }
      this.checkForAnyErrorMessageEnabled()
    }
      
    })
    this.uploader.onErrorItem =((item: FileItem, response: string) => {
      console.log(item);
      const errorCode = JSON.parse(response);
      if (errorCode.status === 422) {
        this.showErrorMessage = true;
      } else {
        this.showErrorMessage = false;
      }
      this.checkForAnyErrorMessageEnabled()
    })
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }


  onSuccess (item, response) {
    this.passFileData.emit({...{fileRes: item, res: response}})
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  removeErrorFilesFromQueue(file) {
    for (const fileItem of this.uploader.queue) {
      if (file.name === fileItem.file.name) {
        this.uploader.removeFromQueue(fileItem);
        break;
      }
    }
  }

  checkForAnyErrorMessageEnabled() {
    if(this.isInvalidFileSize || this.isInvalidFileType || this.showErrorMessage || this.isPasswordProtected ) {
      sessionStorage.setItem('fileUploadError', 'true')
    } else {
      sessionStorage.removeItem('fileUploadError')
    }
  }

  public onFileSelected(event: EventEmitter<File[]>): void {
    const file: File = event[0];
    let type;

    const str = file?.name;
    const substrings = str.split('.');
    const fileTypes = ['pdf', 'jpeg', 'tiff', 'bmp', 'jpg', 'JPG'];
    for (let i = 0; substrings.length > i; i++) {
      if (fileTypes.includes(substrings[i])) {
        type = substrings[i];
      }
    }

    if (type) {
      this.isInvalidFileType = false;
    } else {
      this.isInvalidFileType = true;
      this.removeErrorFilesFromQueue(file)
    }
    if (file.size > 5000000) {
      this.isInvalidFileSize = true;
      this.removeErrorFilesFromQueue(file)
    } else {
      this.isInvalidFileSize = false;
      this.uploader.uploadAll();
    }
    this.checkForAnyErrorMessageEnabled()
  }

}
