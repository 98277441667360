import { AppealDetailsComponent } from './appeal-details.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import Vs from '@app/services/validators/validators.service';
import { lookIn } from '@app/helpers/flipped.helpers';
import { FormArray } from '@angular/forms';
import { toISO, toDate } from '@app/helpers/date.helpers';
import { evolve } from 'ramda';

const formValidation = (builder, recordData) => {
  const appealDetails = lookIn(recordData);
  const appealCategory = appealDetails(['appealCategory']);
  const benefitChgTxt = appealDetails(['benefitChgTxt']);
  const appealReasonTxt = appealDetails(['appealReasonTxt']);
  const elgNoticeIssuesSw = appealDetails(['elgNoticeIssuesSw']);
  const elgNoticeIssuesTxt = appealDetails(['elgNoticeIssuesTxt']);
  const denialTxt = appealDetails(['denialTxt']);
  const denialSW = appealDetails(['denialSW']);
  const applyDateSW = appealDetails(['applyDateSW']);
  const applyDateRangeTxt = appealDetails(['applyDateRangeTxt']);
  const applyDateForBenefit = appealDetails(['applyDateForBenefit']);
  const appealFilingLocation = appealDetails(['appealFilingLocation']);
  const elgBeginDate = toDate(appealDetails(['elgBeginDate']));
  const elgEffectiveDateTxt = appealDetails(['elgEffectiveDateTxt']);
  const nursingFacilityOrHCBS = appealDetails(['nursingFacilityOrHCBS']);
  const patientLiabilityTxt = appealDetails(['patientLiabilityTxt']);
  const premiumAmount = appealDetails(['premiumAmount']);
  const terminationIncorrectTxt = appealDetails(['terminationIncorrectTxt']);
  const renewalPktSw = appealDetails(['renewalPktSw']);
  const sendCompletedPacketSw = appealDetails(['sendCompletedPacketSw']);
  const packetReturnDate = toDate(appealDetails(['packetReturnDate']));
  const packetReturnDateRangeTxt = appealDetails(['packetReturnDateRangeTxt']);
  const otherPeopleListedSw = appealDetails(['otherPeopleListedSw']);
  const packetNamesList = appealDetails(['packetNamesList']);
  const packetSendType = appealDetails(['packetSendType']);
  const letterReceivedMoreInfoSw = appealDetails(['letterReceivedMoreInfoSw']);
  const requestedInfoSentSw = appealDetails(['requestedInfoSentSw']);
  const addInfoSentDate = toDate(appealDetails(['addInfoSentDate']));
  const addInfoDateRangeTxt = appealDetails(['addInfoDateRangeTxt']);
  const addInfoSentType = appealDetails(['addInfoSentType']);
  const cvgSuspensionBeginDate = toDate(appealDetails(['cvgSuspensionBeginDate']));
  const cvgSuspensionEndDate = toDate(appealDetails(['cvgSuspensionEndDate']));
  const qualifyHardshipAndAssistanceTxt = appealDetails(['qualifyHardshipAndAssistanceTxt']);
  const appellantPregnantOrChildSw = appealDetails(['appellantPregnantOrChildSw']);
  const priorMedServReceivedSw = appealDetails(['priorMedServReceivedSw']);
  const filingWithin90DaysPregSw = appealDetails(['filingWithin90DaysPregSw']);
  const newBornSw = appealDetails(['newBornSw']);
  const appealReasonCd = appealDetails(['appealReasonCd']);
  const appealReasonDesc = appealDetails(['appealReasonDesc']);
  const appealType = appealDetails(['appealType']);
  const aplNum = appealDetails(['aplNum']);
  const appealIssueSame = appealDetails(['appealIssueSame']);
  const previousFiledAppeal = appealDetails(['previousFiledAppeal']);
  const enterAppealNum = appealDetails(['enterAppealNum']);

  const getAppealCategoryList = (): FormArray => {
    const peopleArray: any = [];
    for (let i = 0; i < appealCategory.length; i++) {
      appealCategory[i]['availableCoverageTypes'].sort(function(a, b) {
        var codeA = a.code;
        var codeB = b.code;
        return (codeA < codeB) ? -1 : (codeA > codeB) ? 1 : 0;
    });
      peopleArray.push(createCtrls(appealCategory[i]['availableCoverageTypes'], appealCategory[i]['memberName'], appealCategory[i]['personId'], appealCategory[i]['selectedCoverageType'], appealCategory[i]['coeCodeMap']));
    }
    return peopleArray as FormArray;
  };

  const createCtrls = (availableCoverageTypes, memberName, personId, selectedCoverageType, coeCodeMap) => {  
    return builder.group({
      availableCoverageTypes: builder.array(availableCoverageTypes),
      memberName: memberName,
      personId: personId,
      selectedCoverageType: [selectedCoverageType, Vs.required],
      coeCodeMap: coeCodeMap
    });
  };

  const data = builder.group({
    appealCategory: builder.array(getAppealCategoryList()),
    benefitChgTxt: [benefitChgTxt],
    appealReasonTxt: [appealReasonTxt, Vs.required],
    elgNoticeIssuesSw: [elgNoticeIssuesSw, Vs.required],
    elgNoticeIssuesTxt: [elgNoticeIssuesTxt],
    denialSW: [denialSW],
    denialTxt: [denialTxt],
    applyDateSW: [applyDateSW],
    applyDateRangeTxt: [applyDateRangeTxt],
    applyDateForBenefit: [applyDateForBenefit],
    appealFilingLocation: [appealFilingLocation],
    elgBeginDate: [elgBeginDate],
    elgEffectiveDateTxt: [elgEffectiveDateTxt],
    nursingFacilityOrHCBS: [nursingFacilityOrHCBS],
    patientLiabilityTxt: [patientLiabilityTxt],
    premiumAmount: [premiumAmount],
    terminationIncorrectTxt: [terminationIncorrectTxt],
    renewalPktSw: [renewalPktSw],
    sendCompletedPacketSw: [sendCompletedPacketSw],
    packetReturnDate: [packetReturnDate],
    packetReturnDateRangeTxt: [packetReturnDateRangeTxt],
    otherPeopleListedSw: [otherPeopleListedSw],
    packetNamesList: [packetNamesList],
    packetSendType: [packetSendType],
    letterReceivedMoreInfoSw: [letterReceivedMoreInfoSw],
    requestedInfoSentSw: [requestedInfoSentSw],
    addInfoSentDate: [addInfoSentDate],
    addInfoDateRangeTxt: [addInfoDateRangeTxt],
    addInfoSentType: [addInfoSentType],
    cvgSuspensionBeginDate: [cvgSuspensionBeginDate],
    cvgSuspensionEndDate: [cvgSuspensionEndDate],
    qualifyHardshipAndAssistanceTxt: [qualifyHardshipAndAssistanceTxt],
    appellantPregnantOrChildSw: [appellantPregnantOrChildSw, Vs.required],
    priorMedServReceivedSw: [priorMedServReceivedSw],
    filingWithin90DaysPregSw: [filingWithin90DaysPregSw, Vs.required],
    newBornSw: [newBornSw, Vs.required],
    appealReasonCd: [appealReasonCd],
    appealReasonDesc: [appealReasonDesc],
    appealType: [appealType],
    aplNum: [aplNum],
    appealIssueSame: [appealIssueSame],
    previousFiledAppeal: [previousFiledAppeal],
    enterAppealNum: [enterAppealNum]
  });

  return { data };
};

const postObject = evolve({
  addInfoSentDate: toISO,
  packetReturnDate: toISO,
  cvgSuspensionBeginDate: toISO,
  cvgSuspensionEndDate: toISO,
  elgBeginDate: toISO
});

export default sectionConfiguration(AppealDetailsComponent, formValidation, [ 'YESNO', 'APLFILINGMETHOD', 'APFACILITY', 'EDTOA_MV', 'COVERAGETYPE'], [], {}, {
  postObject,
  hasSummary: false,
  showSubmit: false,
  showComments: false,
  showSaveAndExit: false,
});
