import { evolve, compose, map } from 'ramda';

import { OtherResourcesComponent } from './other-resources.component';

import Vs from '@app/services/validators/validators.service';
import { resourcesCoOwnerGroupRowCreator, sectionConfiguration } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { Validators } from '@angular/forms';
import {totalValidator, dropdownValidator} from '@app/helpers/validators.helpers';
import { notYes } from '@app/helpers/tayn.helpers';
import { trudy } from '@app/helpers/function.helpers';
import { disableGet } from '@app/helpers/able.helpers';
import { GlobalVars } from '@app/models/tables.model';
import { controlWith } from '@app/helpers/form.helpers';

import otherResourcesLabels from './other-resources-labels';


const formValidation = (builder, recordData, params) => {
  const transfer = lookIn(recordData);

  const resourcesCoOwnerGroup = compose(
    (control: any) => builder.group(control),
    resourcesCoOwnerGroupRowCreator,
  );

  const coOwnersData = transfer(['jointOwnershipInformation']) || [{
    jointOwnerIndividual: '',
    outOfHouseHoldname: '',
    jointOwnerPercentage: '',
  }];  

  const jointOwnershipInformation = map(resourcesCoOwnerGroup, coOwnersData.map((item: any) => {
    const individual = params.individuals.find((indi: any) => +indi.identifier === item.jointOwnerIndividual.individualId);
    let jointOwnerIndividual: any;
    if (transfer(['jointOwnershipInformation'])) {
      jointOwnerIndividual = individual ? { value : individual.name.fullName, code : individual.identifier } : GlobalVars.outSideOption;
    }
    return { ...item, jointOwnerIndividual };
  }));

  const assetType = transfer(['assetType']);
  const assetSubType = transfer(['assetSubType']);
  const useDescription = transfer(['useDescription']);
  const assetAmount = transfer(['assetAmount']);
  const owedAmount = transfer(['owedAmount']);
  const jointOwnership = transfer(['jointOwnership']);

  const data = builder.group({
    assetType: [assetType, [Vs.required]],
    assetSubType: [assetSubType],
    useDescription: [controlWith(useDescription, !useDescription), Vs.required],
    assetAmount: [assetAmount, [Vs.currency]],
    owedAmount: [owedAmount, [Vs.currency]],
    jointOwnership: [jointOwnership, Vs.required],
    jointOwnershipInformation: builder.array(jointOwnershipInformation)
  }, { validators: Validators.compose([totalValidator, dropdownValidator])});

  trudy(() => disableGet('jointOwnershipInformation', data), notYes(jointOwnership));

  return { data };
};

const transformIndividulaObj = (value) => ({
  name : value?.code === 'EMPTY' ? '' : value?.value,
  individualId: value?.code === 'EMPTY' ? 0 : value?.code
});

const postObject = evolve({
  owedAmount: Number,
  assetAmount: Number,
  jointOwnershipInformation: map(evolve({
    jointOwnerIndividual : transformIndividulaObj
  }))
});

export default sectionConfiguration(
  OtherResourcesComponent,
  formValidation,
  ['YESNO', 'OTHERRESTYPE', 'OTHERRESOURCEUSE'],
  [
    { name: 'Owner', pipe: 'individual' },
    { name: 'Type', prop: 'data.assetType.value' },
    { name: 'Value', prop: 'data.assetAmount', pipe: 'currency' },
    { name: 'Amount Owed', prop: 'data.owedAmount', pipe: 'currency' },
  ],
  {...{}, ...otherResourcesLabels},
  {
    postObject
  }
);
