<div class="container">
    <div class="row">
      <div class="col-8">
        <h1 class="page-title">APPLY FOR ASSISTANCE</h1>
      </div>
    </div>
    <div class="bg-custom">
        
      <div class="container manage-benefits">
        <div>
          <div class="mt-3">
            <div class="col-xs-12 .col-12 list-content">
              <h4><strong>Before You Start the Application</strong></h4>
              <p>You may need the following information for each person in the household to help you complete this application. You should try to gather as much of it as possible before you start. It may take 20-45 minutes to complete your application, depending on the number of people in the household.</p>
              <ul>
                <li>Personal information including social security numbers, birth dates, etc.</li>
                <li>Income from self-employment, jobs and other income, such as child support</li>
                <li>Expenses such as housing, medical care and dependent care</li>
                <li>Asset information including vehicles, life insurance, bank accounts, homes, property, etc.</li>
                <li>Current or recent health insurance and/or Medicare information</li>
              </ul>
              <h4 class="mt-3"><strong>Important Information for Applicants</strong></h4>
              <div class="important-info">
                <!-- Scrollable text Box Content-->
                <h6 class="text-uppercase text-center"><strong>Application for Assistance</strong></h6>
                <p class="text-center"><strong>Welcome to the Department of Health & Human Services (DHHS), Division of Family Assistance (DFA)</strong></p>
                <p>To apply for the programs and services we offer, you must fill out this Application for Assistance, have an interview, and give us proof of your household circumstances. Please read all of the information given to you, and answer all of the questions as best as you can.&nbsp;<strong>Do not answer anything that you do not understand.</strong> If you need help in filling out this Application, tell us.&nbsp;<strong>You have the right to immediately file your Application for SNAP as long as it contains the applicant's name and address and the signature of a responsible household member or the household's authorized representative.</strong> However, we will be able to figure out if you can get benefits much quicker if you complete the entire Application. DFA assistance is based on your income. Some&nbsp;<abbr title="Division of Family Assistance" aria-label="Division of Family Assistance">DFA</abbr> programs may also look at the cash value of things that you own, your "assets," when figuring out if you qualify for a program&nbsp;<abbr title="Division of Family Assistance" aria-label="Division of Family Assistance">DFA</abbr> offers.</p>
                <p class="text-center"><strong>SNAP Benefits</strong></p>
                <p>The SNAP Program helps low-income people buy the food they need for good health. You will need to have an interview with a&nbsp;<abbr title="Department of Health &amp; Human Services" aria-label="Department of Health &amp; Human Services">DHHS</abbr> worker to see if you are eligible for this program. Your SNAP benefits are based on the date of application, which is the date your completed application is received by the District Office. If you are a resident of an institution who is jointly applying for SSI and SNAP benefits prior to leaving the institution, the filing date of your application is your date of release from the institution.</p>
                <p>With identification, you may get emergency food assistance within 7 calendar days if:</p>
                <ul>
                  <li>you have less than $150 in monthly gross income and no more than $100 in liquid resources;</li>
                  <li>you have shelter costs that are higher than your gross income and liquid resources;&nbsp;<strong>or</strong></li>
                  <li>you are a migrant or seasonal farm worker who is destitute as defined in 7 CFR 273.10(e)(3).</li>
                </ul>
                <p class="text-center"><strong>Social Security Numbers (SSN)</strong></p>
                <p>The Federal Privacy Act of 1974 as amended, requires that we tell you the laws that allow us to ask for the Social Security Numbers (SSN) of each person requesting assistance, whether you are required to give them to us, and what we will do with them. SSNs are required for the following programs. After each program is the law or regulation that requires us to ask for these SSNs:</p>
                <ul>
                  <li>FANF: 42 USC 405(c)(2), 45 CFR 205.52, RSA 167:4-c, & RSA 167:79, iii(h).</li>
                  <li>SNAP: RSA 167:4-c, Food and Nutrition Act of 2008 (formerly Food Stamp Act), as amended, 7 USC 2011-2036, 7 CFR 273.2(b)(4), & 7 CFR 273.6.</li>
                  <li>Medical Assistance and other financial assistance: RSA 167:4-c, Section 2651 of PL 98-369 & 42 CFR 435.920, & 42 USC 1320b-7.</li>
                </ul>
                <p>Each person who wants assistance from the above programs must provide an SSN or apply for a number at the Social Security Administration (SSA). Members of your household who do not apply for benefits do not need to provide a social security number. If you are applying only for some members of your family, such as a parent applying for Medical Assistance just for a child, you only have to give us the child's number or apply for one for your child. Your child's eligibility for medical coverage will not be affected if you only give us your child's SSN.</p>
                <p>If an SSN is not provided for each person who is applying for the listed programs, your application may be denied or you may get less benefits. If someone wants help getting an SSN, call 1-800-772-1213 or visit socialsecurity.gov. TTY users should call 1-800-325-0778.</p>
                <p>Applicants for&nbsp;<abbr title="New Hampshire" aria-label="New Hampshire">NH</abbr> Child Care Scholarship only do not have to provide an SSN, but if SSNs are provided, it may help shorten the eligibility verification process.</p>
                <p>We ask for SSNs so we can verify identity, earned and unearned income and resource information you give us. It will be shared and verified with:</p>
                <ul>
                  <li>federal, state and local entities;</li>
                  <li>various offices within&nbsp;<abbr title="Department of Health &amp; Human Services" aria-label="Department of Health &amp; Human Services">DHHS</abbr> as allowed by federal law;</li>
                  <li>employment and unemployment databases;</li>
                  <li>the Internal Revenue Service and SSA;</li>
                  <li>financial entities; and</li>
                  <li>other computer matching programs.</li>
                </ul>
                <p>The information will be used:</p>
                <ul>
                  <li>to figure out if your household is eligible or continues to be eligible for the assistance you requested;</li>
                  <li>to figure out the amount of your benefits or errors in your eligibility or benefits; and</li>
                  <li>in an investigation of suspected abuse of program law or rules.</li>
                </ul>
                <p>This information may be disclosed to other Federal and State agencies for official examination, and to law enforcement officials for the purpose of apprehending persons fleeing to avoid the law. If a SNAP claim arises against your household, the information on this application, including all SSNs, may be referred to Federal and State agencies, as well as private claims collection agencies, for claims collection action.</p>
                <p>We do not give SSNs or any other information regarding non-applicants to the US Citizenship and Immigration Services (USCIS), formerly known as INS, or any other agency not directly connected with programs and/or services offered by&nbsp;<abbr title="Department of Health &amp; Human Services" aria-label="Department of Health &amp; Human Services">DHHS</abbr>.</p>
                <p class="text-center"><strong>Emergency Medicaid for Non-Citizens</strong></p>
                <p>Emergency Medicaid may be available to certain non-citizens, regardless of their immigration status, to cover some emergency services, including labor and delivery.
                  <strong> Social Security Numbers are not needed to apply for Emergency Medicaid.</strong>
                </p>
                <p class="text-center"><strong>Citizenship & Identity</strong></p>
                <p>You must declare and prove the citizenship or non-citizenship status of each household member applying for assistance. Non-citizens applying for assistance, except Emergency Medicaid, must provide USCIS documentation of qualified alien status. USCIS documentation will be verified and non-citizen status of applicant household members will be subject to verification through the submission of information from the application to USCIS, and the submitted information received from USCIS may affect eligibility and benefits.</p>
                <p class="text-center"><strong>Third Party Insurance or Medical Payments</strong></p>
                <p>If you are applying for Medical Assistance, receipt of such assistance is an assignment to&nbsp;<abbr title="Department of Health &amp; Human Services" aria-label="Department of Health &amp; Human Services">DHHS</abbr> of your rights to all third party insurance or medical payments without anyone having to sign any other form. All available parties must be billed and all resulting payments must be applied to the cost of medical care before&nbsp;<abbr title="Department of Health &amp; Human Services" aria-label="Department of Health &amp; Human Services">DHHS</abbr> will pay. Also, if you receive a settlement or an award from a liable third party, you must pay&nbsp;<abbr title="Department of Health &amp; Human Services" aria-label="Department of Health &amp; Human Services">DHHS</abbr> back for related medical services we paid. RSA 167:14-a.</p>
                <p class="text-center"><strong>Benefits Received in Error</strong></p>
                <p>You are required to pay back any benefits or services received in error, regardless of whether you made a mistake in the information you provided, or failed to provide, to us. If you get SNAP, you must also pay back any benefits you received in error if we made a mistake in processing your case.</p>
                <p class="text-center"><strong>Financial or Medical Child Support</strong></p>
                <p>If you are applying for TANF cash payments, your receipt of such assistance is an assignment to&nbsp;<abbr title="Department of Health &amp; Human Services" aria-label="Department of Health &amp; Human Services">DHHS</abbr> of your rights to financial child support. Without signing any other form, you give&nbsp;<abbr title="Department of Health &amp; Human Services" aria-label="Department of Health &amp; Human Services">DHHS</abbr> the right to collect and keep financial child support payments made on behalf of your children who receive assistance. RSA 161-C:22</p>
                <p><abbr title="Department of Health &amp; Human Services" aria-label="Department of Health &amp; Human Services">DHHS</abbr> collects and keeps the support to partially offset the amount of cash assistance paid to you. If support payments are equal to or more than the amount we give you, your cash assistance case will be closed and the support payments sent to you.</p>
                <p>Receipt of Children's Medicaid is an assignment of medical child support rights. This means that you must cooperate with&nbsp;<abbr title="Department of Health &amp; Human Services" aria-label="Department of Health &amp; Human Services">DHHS</abbr> to establish and enforce medical child support for your children. Medical child support usually means health insurance provided by the absent parent, but can also be an ongoing dollar amount paid by the other parent to allow you to buy health insurance for your children.</p>
                <p>If you receive money to purchase medical insurance, this money will be kept by the State if you receive Medicaid for your child and will be used to pay back the state and federal governments. If paternity is not established for any of your children who are getting Medicaid, you must also cooperate with&nbsp;<abbr title="Department of Health &amp; Human Services" aria-label="Department of Health &amp; Human Services">DHHS</abbr> to legally establish paternity.</p>
                <p>The assignment of support rights is a requirement. Your rights and responsibilities and the penalty for refusal without a good reason, will be explained to you when you meet with your District Office Worker.</p>
                <p class="text-center"><strong>Begin Date for Medicaid Eligibility</strong></p>
                <p>Your Medicaid eligibility generally begins on the day that you meet all the requirements for the program you applied for, including the resource limit.</p>
              </div><span class="clearfix"></span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 offset-md-6">
              <button id="cancel" [routerLink]="['/user-profile']" class="btn btn-block btn-outline-primary">CANCEL</button>
            </div>
            <div class="col-md-3">
              <button id="continue" class="btn btn-block btn-primary" (click)="startApplication('AFB')">Continue</button>
            </div>
            </div>
        </div>
      </div>

    </div>
  </div>