import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ServiceConfigService } from '@app/services/service-config.service';
import { ApiService } from '../services/api.service';
import Vs from '@app/services/validators/validators.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal/modal.service';
import { RecaptchaLoaderService } from 'ng-recaptcha';
import { RecaptchaLanguageLoaderService } from '@app/services/recaptcha-language-loader.service';
import { HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'nh-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  isRecaptchaEnabled: boolean;
  forgotPassword = new FormGroup({
    usernameOrEmail: new FormControl(''),
    recaptchaReactive: new FormControl(null, Vs.required)
  })
  isForgotPasswordlinkPage: boolean = false;
  title: string = "Forgot Password";
  siteKey: string;
  currentLang;
  loaderReady = false;
  contentHeader = new HttpHeaders({"pageId": "TCFGP"});

  constructor(
    @Inject(RecaptchaLoaderService)private loader: RecaptchaLanguageLoaderService,
    private zone: NgZone,
    private apiService: ApiService,
    public translateService: TranslateService,
    private serverConfig: ServiceConfigService,
    private router: Router,
    private modal: ModalService,
    private titleService: Title
    ) {
    this.titleService.setTitle('Forgot Password');
    this.siteKey = this.serverConfig.getServerConfig().SITE_KEY;
    this.isRecaptchaEnabled = this.serverConfig.getServerConfig().isRecaptchaEnabled;
    translateService.onDefaultLangChange.subscribe((res) => {
      this.switchLanguage(res.lang);
      res.lang === 'sp' ? this.changeRecaptchaLanguage('es') : this.changeRecaptchaLanguage('en')
    });
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.defaultLang === 'sp' ? 'ES' : 'EN';
    if (!this.isRecaptchaEnabled) {
      this.forgotPassword.removeControl('recaptchaReactive');
    }
    setTimeout(() => {
      const lang = this.translateService.getDefaultLang();
      lang === 'sp' ? this.changeRecaptchaLanguage('es') : this.changeRecaptchaLanguage('en')
    }, 1000);
    this.loader.ready.subscribe(v => {
      setTimeout(() => this.zone.run(() => this.loaderReady = !!v), 0);
    });
  }

  switchLanguage(lang) {
    if (lang === 'sp') {
      this.currentLang = 'ES'
    } else {
      this.currentLang = 'EN'
    }
  };

  sendForgotPasswordLink(): void {
    const forgotUsernameJson = {
      userName: this.forgotPassword['controls']['usernameOrEmail'].value,
      language: this.currentLang
    }
    this.apiService.post('publ/users/forgotPassword', forgotUsernameJson, {headers: this.contentHeader}).subscribe(() => {
      this.isForgotPasswordlinkPage = true;
    });

  }

  resolved(captchaResponse: string) {
    const recaptachRequest = {
      'recaptchaResponseToken': captchaResponse,
      'chkRecaptcha': "YES"
    }
    this.apiService.post('publ/users/validateRecaptcha', recaptachRequest, {headers: this.contentHeader}).subscribe(() => {

    })
  }

  changeRecaptchaLanguage(lang) {
    this.loader.updateLanguage(lang);
  }

  onPrevious() {
    if(this.forgotPassword.pristine) {
      this.router.navigateByUrl("/signin");
      return;
    }
    this.modal.confirmDeactivate().subscribe(resp => {
      if (resp) {
        this.router.navigateByUrl("/signin");
      }
    });
  }

}
