<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <section [formGroup]="getControl('data')">

    <div class="container start-page">
      <h5 class="section-header">{{'APPEAL_REQUEST_HEADER' | translate}}</h5>
      <hr>
      <div>
        <p *ngIf="!multipleCase"><span class="label-section">{{'CASE_NUMBER' | translate}}</span> <span> {{ singleCaseNum }}</span></p>
        <nh-field *ngIf="multipleCase" [mandatory]="true" label="{{'APPEAL_REQUEST_STATIC_TEST1' | translate}}"
          class="label-section">
          <div class="row">
            <div class="p-2">
              <select style="width: 340px;height: 38px" nh name="appealCaseNumber" [items]="caseNumberList"
                aria-label="appealCaseNumber" formControlName="appealCaseNumber" (change)="appealCaseFor()"></select>
            </div>
            <div class="p-2"></div>
            <div class="p-2">
              <button id="showSelectAppelants" (click)="showSelectAppelants()" class="btn btn-secondary btn-search"
                [disabled]="!enableSearch">
                {{searchSelect | translate}}
              </button>
            </div>
          </div>
        </nh-field>
      </div>
      <div *ngIf="showSelectAppelantsTable">
        <h5 class="section-header">{{'SELECT_APPELANTS_HEADER' | translate}}</h5>
        <hr />
        <nh-field *ngIf="isKepro" [mandatory]="true" label="{{'APPEAL_REQUEST_STATIC_TEST11' | translate}}"
          class="label-section">
          <nh-name-address [personalName]="getControl('data.callerName')" [isAssistingPerson]="false" [tables]="tables">
          </nh-name-address>
        </nh-field>
        <nh-field [mandatory]="true" label="{{'APPEAL_REQUEST_STATIC_TEST2' | translate}}" class="label-section">
          <select nh name="filingCaseFor" [items]="tableCodeValue('FILINGFOR', 'data.filingCaseFor')"
            aria-label="filingCaseFor" (change)="filingCaseFor()" [attr.disabled]="disableFlingCaseFor" formControlName="filingCaseFor"></select>
        </nh-field>
        <div *ngIf="enableTable" class="d-none d-lg-block">
          <p *ngIf="showKBELigibleMessage === true" [innerHTML]="'APPEAL_REQUEST_KB_MESSAGE' | translate"></p>
          <ngx-datatable class='bootstrap striped' #table [rows]='selectAppelantsRows' columnMode="force"
            [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
            <ngx-datatable-column [cellClass]="'my-auto'" [name]="col.name | translate" [prop]="col.prop"
              *ngFor="let col of selectAppelantsColumns">
            </ngx-datatable-column>
            <ngx-datatable-column [cellClass]="'my-auto'" [name]="IncludeinGroupAppeal | translate">
              <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <input type="checkbox" [checked]="row.groupAppelant" attr.aria-label="checkbox{{rowIndex}}"
                  (change)="selectCheckBox($event, row, rowIndex, 'groupAppealSelect', 'browserView')"
                  [value]="row.groupAppelant" [disabled]="!row.enableGroupAppealCheckBox">
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [cellClass]="'my-auto'" [name]="PrimaryAppellant | translate">
              <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <input type="radio" [checked]="row.primaryAppelant" attr.aria-label="radiobutton{{rowIndex}}"
                  (change)="selectCheckBox($event, row, rowIndex, 'primaryAppealSelect', 'browserView')"
                  [value]="row.primaryAppelant">
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
          <nh-alert type="danger" [isOpen]="showPrimaryAlertMessage">
            <div class="row">
              <div class="icon">
                <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
              </div>
              <div class="message">
                <span>{{'PRIMARY_APPELANT_VALIDATION_MESSAGE' | translate}}</span>
              </div>
            </div>
          </nh-alert>
        </div>
        <div *ngIf="enableTable" class="nh-table-mobile d-lg-none">
          <div class="table-row mb-3 pb-3" *ngFor="let row of selectAppelantsRows; index as rowIndex">
            <div class="mobile-table-header">{{selectAppelantsColumns[0].name | translate}}</div>
            <hr>
            <div class="mobile-table-data">{{row.memberName}}</div>
            <br>
            <div class="mobile-table-header">{{IncludeinGroupAppeal | translate}}</div>
            <hr>
            <button id="viewMyAppealLetters"
              (click)="selectCheckBox($event, row, rowIndex, 'groupAppealSelect', 'mobileView')"
              class="btn btn-secondary btn-search" [disabled]="!row.enableGroupAppeal">
              {{ row.groupAppealSelect | translate}}
            </button>
            <br><br>
            <div class="mobile-table-header">{{PrimaryAppellant | translate}}</div>
            <hr>
            <button id="viewMyAppealLetters"
              (click)="selectCheckBox($event, row, rowIndex, 'primaryAppealSelect', 'mobileView')"
              class="btn btn-secondary btn-search" [disabled]="!row.enablePrimaryAppeal">
              {{ row.primaryAppealSelect | translate}}
            </button>
            <br>
          </div>
          <p *ngIf="showKBELigibleMessage === true" [innerHTML]="'APPEAL_REQUEST_KB_MESSAGE' | translate"></p>
          <nh-alert type="danger" [isOpen]="showPrimaryAlertMessage">
            <div class="row">
              <div class="icon">
                <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
              </div>
              <div class="message">
                <span>{{'PRIMARY_APPELANT_VALIDATION_MESSAGE' | translate}}</span>
              </div>
            </div>
          </nh-alert>
        </div>
      </div>
      <div *ngIf="showSelectAppelantsTable && !showPrimaryAlertMessage">
        <h5 class="section-header">{{'APPEAL_INFORMATION_HEADER' | translate}}</h5>
        <hr />
        <div class="row pl-1 pt-1 hover-red">
          <a class="btn btn-link hover-red" (click)="openAppealEligibilityModal()">
            <span class="pr-2">{{'TYPES_OF_APPEALS' | translate}}</span>
            <span><i class="fa fa-external-link"></i></span>
          </a>
        </div>
        <br>
        <nh-field [mandatory]="true" label="{{'APPEAL_REQUEST_STATIC_TEST3' | translate}}" class="label-section">
          <select nh name="appealEligibilityDecision"
            [items]="tableCodeValue('YESNO', 'data.appealEligibilityDecision')" aria-label="appealEligibilityDecision"
            formControlName="appealEligibilityDecision" (change)="eligibilityDecision()"></select>
        </nh-field>
        <p  class= "text-font" *ngIf="(isN('data.appealEligibilityDecision'))">{{ 'APPEAL_REQUEST_STATIC_TEST4' | translate }}<span
            style="color: #174A7C;"><strong>1-800-878-3192</strong></span>{{ 'APPEAL_REQUEST_STATIC_TEST5' | translate
          }}
        </p>
        <nh-field *ngIf="(isY('data.appealEligibilityDecision'))" [mandatory]="true"
          label="{{'APPEAL_REQUEST_STATIC_TEST6' | translate}}" class="label-section">
          <select nh name="renewalLetter" [items]="tableCodeValue('YESNO', 'data.renewalLetter')"
            aria-label="renewalLetter" formControlName="renewalLetter" (change)="renewalLetter()"></select>
        </nh-field>
        <nh-field *ngIf="(isN('data.renewalLetter'))" [mandatory]="true" label="{{'APPEAL_REQUEST_STATIC_TEST10' | translate}}"
          class="label-section">
          <select nh name="ltssProgram" [items]="tableCodeValue('YESNO', 'data.ltssProgram')" aria-label="ltssProgram"
            formControlName="ltssProgram" (change)="ltssChange()"></select>
        </nh-field>
        <nh-field *ngIf="((isgroupAppeal || selectedSelf) && isN('data.ltssProgram') && kbElgFlag)" [mandatory]="true"
          label="{{'APPEAL_REQUEST_STATIC_TEST7' | translate}}" class="label-section">
          <select nh name="kBprogram" [items]="tableCodeValue('YESNO', 'data.kBprogram')" aria-label="kBprogram"
            formControlName="kBprogram" (change)="selcectKb()"></select>
        </nh-field>
        <nh-field *ngIf="isY('data.renewalLetter')" [mandatory]="true"
          label="{{'APPEAL_REQUEST_STATIC_TEST8' | translate}}" class="label-section">
          <select nh name="appealingReasonRenewal"
            [items]="tableCodeValue('RENEWALAPPEALREASON', 'data.appealingReasonRenewal')"
            aria-label="appealingReasonRenewal" formControlName="appealingReasonRenewal" (change)="appealReason('data.appealingReasonRenewal')"></select>
        </nh-field>
        <nh-field *ngIf="isY('data.kBprogram') && (isgroupAppeal || selectedSelf)" [mandatory]="true"
          label="{{'APPEAL_REQUEST_STATIC_TEST8' | translate}}" class="label-section">
          <select nh name="appealingReasonKb"
            [items]="tableCodeValue('KATIEBECKETAPPEALREASON', 'data.appealingReasonKb')" aria-label="appealingReasonKb"
            formControlName="appealingReasonKb" (change)="appealReason('data.appealingReasonKb')"></select>
        </nh-field>
        <nh-field
          *ngIf="((!isgroupAppeal || !selectedSelf) && isN('data.renewalLetter') && isN('data.ltssProgram') && !isY('data.kBprogram')) || ((isgroupAppeal || selectedSelf) && isN('data.kBprogram') && kbElgFlag) || ((isgroupAppeal || selectedSelf) && isN('data.ltssProgram') && !kbElgFlag)"
          [mandatory]="true" label="{{'APPEAL_REQUEST_STATIC_TEST8' | translate}}" class="label-section">
          <select nh name="appealingReasonElg" [items]="tableCodeValue('ELIGIBLITYAPPEALREASON', 'data.appealingReasonElg')"
            aria-label="appealingReasonElg" formControlName="appealingReasonElg" (change)="appealReason('data.appealingReasonElg')"></select>
        </nh-field>
        <nh-field *ngIf="isY('data.ltssProgram')" [mandatory]="true" label="{{'APPEAL_REQUEST_STATIC_TEST8' | translate}}"
          class="label-section">
          <select nh name="appealingReasonLtss" [items]="tableCodeValue('LTSSAPPEALREASON', 'data.appealingReasonLtss')"
            aria-label="appealingReasonLtss" formControlName="appealingReasonLtss" (change)="appealReason('data.appealingReasonLtss')"></select>
        </nh-field>
        <nh-field *ngIf="duplicateAppealQuestionFlag && isY('data.appealEligibilityDecision')" [mandatory]="true"
          label="{{'APPEAL_REQUEST_STATIC_TEST9' | translate}}" class="label-section">
          <select nh name="duplicateAppeal" [items]="tableCodeValue('YESNO', 'data.duplicateAppeal')"
            aria-label="duplicateAppeal" formControlName="duplicateAppeal" (change)="duplicateAppealDecision()"></select>
        </nh-field>
      </div>
    </div>
  </section>
</nh-form>