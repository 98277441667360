import { Component } from '@angular/core';
import { getJwtToken } from 'web-kore-sdk-master/UI/JWT/JwtTokenGen';
import { koreAnonymousFn } from 'web-kore-sdk-master/libs/anonymousassertion';
import { ServiceConfigService } from "@app/services/service-config.service";
import { TranslateService } from '@ngx-translate/core';

declare const koreBotChat: any;
declare const $: any;
declare function getJwtToken(JSONData): any;
declare function koreAnonymousFn(options): any;

@Component({
  selector: 'nh-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css']
})
export class ChatbotComponent {
  koreURL: any;
  koreId: any;
  koreUserId: any;
  koreclientId: any;
  koreClientSecret: any;
  currentLang;
  static chatbotInstance = null;

  constructor(private serverConfig: ServiceConfigService, public translateService: TranslateService
  ) {
    this.koreURL = this.serverConfig.getServerConfig().koreURL;
    this.koreId = this.serverConfig.getServerConfig().koreId;
    this.koreUserId = this.serverConfig.getServerConfig().koreUserId;
    this.koreclientId = this.serverConfig.getServerConfig().koreclientId;
    this.koreClientSecret = this.serverConfig.getServerConfig().koreClientSecret;
    this.currentLang = this.translateService.getDefaultLang() === 'sp' ? "es" : "en";
  }

  initiateChat() {
    //***CODE_START
    const botOptions: any = {};
    let koreBot: any = {};

    function assertion(options, callback) {
      //client has to fill the claims and call the callback.
      const jsonData = {
        clientId: botOptions.clientId,
        clientSecret: botOptions.clientSecret,
        identity: botOptions.userIdentity,
        aud: "",
        isAnonymous: true,
      };

      options.assertion = getJwtToken(jsonData);

      options.handleError = koreBot.showError;
      options.chatHistory = koreBot.chatHistory;
      options.botDetails = koreBot.botDetails;
      callback(null, options);
      setTimeout(function() {
        if (koreBot && koreBot.initToken) {
          koreBot.initToken(options);
        }
      }, 2000);
    }

    const userobj = JSON.parse(sessionStorage.getItem('currentUser'));
    const indivId = sessionStorage.getItem('individualId');
    botOptions.koreAPIUrl = this.koreURL;
    botOptions.assertionFn = assertion;
    botOptions.koreAnonymousFn = koreAnonymousFn;
    botOptions.botInfo = {
      name: this.translateService.getDefaultLang() === 'sp' ? "Hablar con el asistente virtual de TennCare" : "Talk to TennCare Virtual Assistant",
      _id: this.koreId,
    }; //Capture Bot Name & Bot ID from Builder Tool app. Go to respective Bot and then navigate to Settings-->Config Settings-->General settings section. Bot Name is case sensitive.
    botOptions.JWTUrl = ""; //above assertion function  picks url from here
    botOptions.userIdentity = userobj ?.emailId; // Provide users email id here
    botOptions.clientId = this.koreclientId; // issued by the kore.ai on client app registration.
    botOptions.clientSecret = this.koreClientSecret; // issued by the kore.ai on client app registration.
    botOptions.logLevel = "debug";
    botOptions.botInfo.customData = {
      emailId: userobj ?.emailId,
      indvId: userobj ?.individualId ? userobj ?.individualId : indivId,
      interactiveLanguage: this.currentLang,
      loginName: userobj ?.firstName + " " + userobj ?.lastName,
      userType: (userobj ?.accountTypeCd === 'CL' ? 'M' : 'P'),
      wid: userobj ?.userId,
    };

    const chatConfig = {
      i18n: {defaultLanguage:this.translateService.getDefaultLang()},
      botOptions: botOptions,
      allowIframe: false, // set true, opens authentication links in popup window, default value is "false"
      isSendButton: false, // set true, to show send button below the compose bar
      isTTSEnabled: true, // set false, to hide speaker icon
      isSpeechEnabled: true, // set false, to hide mic icon
      allowGoogleSpeech: true, //This feature requires valid Google speech API key. (Place it in 'web-kore-sdk/libs/speech/key.js')
      //Google speech works in Google Chrome browser without API key.
      allowLocation: true, // set false, to deny sending location to server
      loadHistory: false, // set true to load recent chat history
      messageHistoryLimit: 10, // set limit to load recent chat history
      autoEnableSpeechAndTTS: false, // set true, to use talkType voice keyboard.
      graphLib: "d3",
      minimizeMode: true, // set google, to render google charts.This feature requires loader.js file which is available in google charts documentation.
      //googleMapsAPIKey:'' // please provide google maps API key to fetch user location.
    };

    console.log("chatconfig:"+ JSON.stringify(chatConfig));
    
    koreBot = koreBotChat();
    koreBot.show(chatConfig);
    ChatbotComponent.chatbotInstance = koreBot;

    this.translateService.onDefaultLangChange.subscribe((res) => { 

      console.log(res);
      
        if (ChatbotComponent.chatbotInstance) {
          ChatbotComponent.chatbotInstance.destroy(true);
        };
        chatConfig.i18n.defaultLanguage = res.lang;
        let koreBot1 = koreBotChat();

        koreBot1.show(chatConfig);
        ChatbotComponent.chatbotInstance = koreBot1;
        // ChatbotComponent.chatbotInstance.seti18n('sp');
        // ChatbotComponent.chatbotInstance.chatWindow.prototype.seti18n('sp');
      
    });
      
  }
}