import { DependentCareComponent } from './dependent-care.component';
import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import {personReceivingCare} from '@app/helpers/models.helpers'
import dependentCareLabels from './dependent-care-labels';

const formValidation = (builder, recordData) => {
  const healthInsurance = lookIn(recordData);

  const dependantIndivNumber = healthInsurance(['dependantCareInformation', 'dependantIndivNumber']);
  const personOutOfHouseholdName = healthInsurance(['dependantCareInformation', 'personOutOfHouseholdName']);
  const expenseFrequencyCode = healthInsurance(['expensePaymentInformation', 'expenseFrequencyCode']);
  const expenseAmount = healthInsurance(['expensePaymentInformation', 'expenseAmount']);

  const data = builder.group({
    dependantCareInformation: builder.group({
      dependantIndivNumber: [dependantIndivNumber, [Vs.required]],
      personOutOfHouseholdName: [personOutOfHouseholdName, [Vs.maxLength(30), Vs.invalidName, Vs.required]],
    }),
    expensePaymentInformation: builder.group({
      expenseFrequencyCode: [expenseFrequencyCode, [Vs.required]],
      expenseAmount: [expenseAmount, [Vs.required, Vs.currency]],
    }),
  });

  return { data };
};

export default sectionConfiguration(
  DependentCareComponent,
  formValidation,
  ['YESNO', 'INCEXPPAYFREQUENCY'],
  [
    { name: 'Name', pipe: 'individual' },
    { name: 'Person Receiving Care', args: personReceivingCare, pipe: 'domain'},
    { name: 'How Much', prop: 'data.expensePaymentInformation.expenseAmount', pipe: 'currency' },
    { name: 'How Often', prop: 'data.expensePaymentInformation.expenseFrequencyCode.value' },
  ],
  {...{}, ...dependentCareLabels},
  {
    tooltipAdd: null
  }
);
