import { Component, Input } from '@angular/core';

import { BaseModalDirective } from '@app/components/modals/base-modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';
import { Messages } from '@app/models/payload.model';
import { ProcessService } from '@app/services/process.service';

@Component({
  templateUrl: './duplicate-taxdep-or-jointfiler.component.html',
  styleUrls: ['./duplicate-taxdep-or-jointfiler.component.scss']
})
export class DuplicateTaxDepOrJointFilerComponent extends BaseModalDirective {
  @Input() dupIndvs: Messages;
  @Input() saveExit;
  @Input() section;
  
  constructor(protected modalRef: BsModalRef, protected builder: FormBuilder,public processService: ProcessService,) {
    super(modalRef, builder);
  }

  onClosed() {
    this.hide({confirm: true});
    this.saveExit ? this.processService.setIsfromTaxDepOrJFOutsideSaveExit('fromSaveExit') : '';
    super.onConfirm();
  }
}