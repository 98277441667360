import { Component, Input, OnInit } from '@angular/core';
import { BaseModalDirective } from '@app/components/modals/base-modal';
import { isRenewBenefits, isReportChanges, isPresumptiveApp } from '@app/helpers/mode.helpers';

@Component({
  templateUrl: './save-exit.component.html',
  styleUrls: ['./save-exit.component.scss'],
})
export class SaveExitModalComponent extends BaseModalDirective implements OnInit {
  @Input()sectionId : any;
  @Input()appType : any;
  isPresumptive = false;
  isRenewBenefitsAppType = false;
  isReportChangesAppType = false;
  appKeyWord;
  isRenewal:boolean = false;
  mobileUser = sessionStorage.getItem('isMobileUser') === 'true' ? true : false;
  isPreterm = sessionStorage.getItem('isPreterm') === "PRETERM" && (sessionStorage.getItem('appType') === 'RB' || (sessionStorage.getItem('appType') === 'REDETERMINATION'  && sessionStorage.getItem('isKepro') === "true"));

 
  
  ngOnInit() {
    this.isRenewBenefitsAppType = isRenewBenefits(this.appType);
    this.isReportChangesAppType = isReportChanges(this.appType)
    this.appKeyWord = this.isReportChangesAppType ? 'CHANGE' : this.isRenewBenefitsAppType ? 'RENEWAL' : 'APPLICATION';
	  this.isRenewal = this.appKeyWord === 'RENEWAL' ? true : false;
	  this.isPresumptive = isPresumptiveApp(this.appType);
  }

  showSubmitTab() {
    const sectionToHide = ['HHAPL'];
    if (sectionToHide.find((item) => item === this.sectionId)) {
      return false;
    } else {
      return true;
    }
  }

  select (value) {
      this.appType = value;
  }

  next () {
      this.onConfirm();
  }

  onConfirm(): void {
    this.hide({appType: this.appType});
  }

  onCancel(): void {
    this.hide('');
  }

}
