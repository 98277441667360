<ng-container *ngIf="stepper$ | async as modules">

  <div role="navigation" class="stepper-container pb-lg-5" *ngIf="modules.length > 1" aria-label="Navigation">
    <nav class="stepper" role="navigation" aria-label="Main">
      <div *ngFor="let module of modules; let i = index" class="step {{ getClass(modules, i) }}">
        <div class="headline d-none d-lg-block" [ngClass]="{ active: isActiveModule(module) }">
          <span>{{ module.title | translate }}</span>
        </div>
        <div class="wraptext">
          <div class="step-inner" [class.active]="isActiveModule(module)" (click)="onModuleNavigate(module)">
            <span>
              <img *ngIf="isCompleted(module.status)" src="../../../assets/images/⚛️ Progress-Tracker.Icon - COMPLETE.svg" alt="Compelted">
              <img *ngIf="isNotStarted(module.status)" src="../../../assets/images/⚛️ Progress-Tracker.Icon - NOT STARTED .svg" alt="Not Started">
              <img *ngIf="isInProgress(module.status)" src="../../../assets/images/⚛️ Progress-Tracker.Icon - IN PROGRESS.svg" alt="In progress">
            </span>
          </div>
        </div>

        <div class="step-content d-lg-none">
          <h6 class="active" (click)="onModuleNavigate(module)">{{ module.title | translate }}</h6>
          <ul *ngIf="isActiveModule(module) && module.sections.length > 1">
            <li class="{{ section.status }}" *ngFor="let section of module.sections">
              <span (click)="onSectionNavigate(section)" [class.active]="isActiveSection(section.sectionId)">
                {{ section.title | translate }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

</ng-container>
