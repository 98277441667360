<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left" id="my-modal-title">{{ 'LIQUID_TYPE_TITLE' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon" />
    </button>
  </div>
  <div class="modal-body">
    <table class="table table-striped d-none d-lg-block modal-table">
      <thead>
        <tr class="header-sty">
          <th scope="col">{{'LIQUID_TYPE_STATIC_TEXT1' | translate }}</th>
          <th scope="col">{{'LIQUID_TYPE_STATIC_TEXT2' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{'LIQUID_TYPE1' | translate }}</td>
          <td>{{'LIQUID_TYPE1_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE2' | translate }}</td>
          <td>{{'LIQUID_TYPE2_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE3' | translate }}</td>
          <td>{{'LIQUID_TYPE3_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE4' | translate }}</td>
          <td>{{'LIQUID_TYPE4_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE5' | translate }}</td>
          <td>{{'LIQUID_TYPE5_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE6' | translate }}</td>
          <td>{{'LIQUID_TYPE6_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE7' | translate }}</td>
          <td>{{'LIQUID_TYPE7_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE8' | translate }}</td>
          <td>{{'LIQUID_TYPE8_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE9' | translate }}</td>
          <td>{{'LIQUID_TYPE9_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE10' | translate }}</td>
          <td>{{'LIQUID_TYPE10_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE11' | translate }}</td>
          <td>{{'LIQUID_TYPE11_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE12' | translate }}</td>
          <td>{{'LIQUID_TYPE12_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE13' | translate }}</td>
          <td>{{'LIQUID_TYPE13_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE14' | translate }}</td>
          <td>{{'LIQUID_TYPE14_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE15' | translate }}</td>
          <td>{{'LIQUID_TYPE15_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE16' | translate }}</td>
          <td>{{'LIQUID_TYPE16_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE17' | translate }}</td>
          <td>{{'LIQUID_TYPE17_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE18' | translate }}</td>
          <td>{{'LIQUID_TYPE18_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE19' | translate }}</td>
          <td>{{'LIQUID_TYPE19_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE20' | translate }}</td>
          <td>{{'LIQUID_TYPE20_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE21' | translate }}</td>
          <td>{{'LIQUID_TYPE21_DESCRIPTION' | translate }}</td>
        </tr>
        <tr>
          <td>{{'LIQUID_TYPE22' | translate }}</td>
          <td>{{'LIQUID_TYPE22_DESCRIPTION' | translate }}</td>
        </tr>
      </tbody>
    </table>
    <div class="d-lg-none nh-table-mobile">
      <div class="mobile-table-header">{{'LIQUID_TYPE1' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE1_DESCRIPTION' | translate }}
      </div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE2' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE2_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE3' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE3_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE4' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE4_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE5' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE5_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header"> {{'LIQUID_TYPE6' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE6_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE7' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE7_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE8' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE8_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE9' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE9_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE10' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE10_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE11' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE11_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE12' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE12_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE13' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE13_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE14' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE14_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE15' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE15_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE16' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE16_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE17' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE17_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE18' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE18_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE19' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE19_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE20' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE20_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE21' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE21_DESCRIPTION' | translate }}</div><br>
      <div class="mobile-table-header">{{'LIQUID_TYPE22' | translate }}</div>
      <hr>
      <div class="mobile-table-data">{{'LIQUID_TYPE22_DESCRIPTION' | translate }}</div><br>
    </div>
  </div>
