<div class="container logout">
  <div class="row" *ngIf="backOrRefresh">
    <br>
    <div class="col">
      <nh-alert type="warning" [isOpen]="backOrRefresh">
        <div class="row">
          <div class="icon">
            <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
          </div>
          <div class="message">
            <span> 
              {{'LOGOUT_MESSAGE' | translate }}
            </span>
          </div>
        </div>
      </nh-alert>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h1 *ngIf="!mobileUser" class="page-title">{{'LOGOUT_HEADER' | translate}}</h1>
      <h1 *ngIf="mobileUser" class="page-title">{{'LOGOUT_MOBILE_APP_HEADER' | translate}}</h1>
    </div>
  </div>
  <div class="bg-custom">
      
    <div *ngIf="!mobileUser" class="container manage-benefits">
      <div>
        <div class="mt-3">
          <div class="container col-xs-12 .col-12 text-align">
            <div class="row full-width">                                                                                                                                                                                                                                                                               <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="py-4">
                <b>{{'LOGOUT_PARA1_LINE1' | translate}}<br>
                   {{'LOGOUT_PARA1_LINE2' | translate}}<br>
                   {{'LOGOUT_PARA1_LINE3' | translate}}</b>
              </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="py-4">
                  <b>{{'LOGOUT_PARA3_LINE1' | translate}}<br>
                     {{'LOGOUT_PARA3_LINE2' | translate}}<br>
                     {{'LOGOUT_PARA3_LINE3' | translate}}</b>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
              <div class="card">
                <div class="card-body">
                  <button (click)="onNavigate('MP')" class="p-3 btn-bg btn-height">
                    <span>{{'LOGOUT_PARA2_LINE1' | translate}}</span><br>
                    <span>{{'LOGOUT_PARA2_LINE2' | translate}}</span><br>
                    <span>{{'LOGOUT_PARA2_LINE3' | translate}}</span>
                  </button>
                  <img src="../../../assets/images/welcome_page/pp.jpg" alt="First slide">
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="card">
                <div class="card-body">
                  <button (click)="onNavigate('pp')" class="p-3 btn-bg btn-height">
                    <span>{{'LOGOUT_PARA4_LINE1' | translate}}</span><br>
                    <span>{{'LOGOUT_PARA4_LINE2' | translate}}</span><br>
                    <span>{{'LOGOUT_PARA4_LINE3' | translate}}</span>
                  </button>
                  <img src="../../../assets/images/welcome_page/PartnerHome1.jpeg" alt="First slide">
                </div>
              </div>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="mobileUser">
      <p> {{'LOGOUT_MOBILE_APP_STATIC' | translate}}</p>
  </div>
</div>