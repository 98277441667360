import { Injectable } from "@angular/core";
// import { ApiService } from "@app/gateway/services/api.service";

@Injectable()
export class LandingService {
  // constructor(private apiService: ApiService) {}

  getNotifications() {
    // const url = 'publ/notifications';
    // return this.apiService.get(url).subscribe(x => data = x);
  }
}
