import { sectionConfiguration } from '@app/helpers/models.helpers';
import demographicsLabels from '../details/demographics/demographics-labels';
import additionalLabels from '../details/additional/additional-labels';
import immigrationLabels from '../details/immigration/immigration-labels';
import { identity } from 'ramda';

export default sectionConfiguration(
  null,
  identity,
  [ 'ENROLLSTATUS',
    'RACEDISPLAY',
    'ETHNICITY',
    'GENDER',
    'LIVINGARRANGEMENTYPE',
    'ALIENSTATUS',
    'ALIENSTATUS_PP',
    'ALIENDOCTYPE',
    'NUMBEROFBIRTHS',
    'RELATIONTYPE',
    'RELATIONTYPE_GENDER',
    'NAMESUFFIX',
    'YESNO',
    'COUNTRY',
    'YESNOPNA',
    'ABSENTREASON',
    'ADDRESSFORMATTYPES',
    'MILITARYPOCODES',
    'STATE',
    'COUNTY',
    'STATEMILITARY'
  ],
  [],
  {...demographicsLabels, ...additionalLabels, ...immigrationLabels, ...{removed: {indvDeletedDate: 'indiv left the household: '}}},
  {
    individualsFilter: identity,
    tooltipAdd:
      ' if there are any other people in your household that are applying for assistance or are a parent of a child under 18 that is applying for assistance. Note: If you are applying for medical coverage, also be sure to include any tax dependents living with you.',
  }
);
