export const policyHolder = {
    houseMember: 'POLICY_HOLDER',
    policyHolderFirstName: 'POLICY_HOLDER_FIRSTNAME',
    policyHolderLastName: 'POLICY_HOLDER_LASTNAME',
    dobDate: 'DOB',
    ssnNum: 'SSN'
}

export default {
    policyHolder: policyHolder,
    coverageType: 'OTHER_HEALTH_RMB_SUMMARY_STATC1',
    insuranceCompanyName: 'HEALTH_INSURANCE_STATIC_TEXT17',
    longTermCarePremiumAmount: 'HEALTH_INSURANCE_STATIC_TEXT18',
    monthlyPremiumAmountForKB: 'OTHER_HEALTH_RMB_SUMMARY_STATC2',
    groupNumber: 'GROUP_NUMBER',
    policyNumber: 'POLICY_NUMBER',
    policyStartDate: 'HEALTH_INSURANCE_STATIC_TEXT6',
    purchaseInsFromYourEmplyerSw: 'HEALTH_INSURANCE_STATIC_TEXT7',
    stateEmployeeBenfitPlanSwitch: 'HEALTH_INSURANCE_STATIC_TEXT9',
    limitedBenefitPlanSwitch: 'HEALTH_INSURANCE_STATIC_TEXT11',
    coverMaternityBenefitsSwitch: 'HEALTH_INSURANCE_STATIC_TEXT13',
    removed: {
        coverageEndDate: "OTHER_HEALTH_RMB_SUMMARY_STATC3"
    }
}

