import { head } from 'ramda';
import { prop } from 'ramda';
import { split } from 'ramda';
import { equals } from 'ramda';
import { concat } from 'ramda';
import { ifElse } from 'ramda';
import { compose } from 'ramda';
import { useWith } from 'ramda';
import { identity } from 'ramda';

import { contained } from 'ramda-adjunct';

import { runIf } from './flipped.helpers';
import { viewAppNumber } from './lenses.helpers';
import { modes } from './models.helpers';

const toPath = compose(
  concat('/application/'),
  viewAppNumber
);

const statePath = compose(
  head,
  split('?'),
  prop('url')
);

const routeState = useWith(equals, [statePath, toPath]);

export const routeEquals = useWith(runIf, [identity, routeState, identity]);

export const isNewApp = ifElse(contained(modes));
