import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'replaceindiv'
})
export class ReplaceindivPipe implements PipeTransform {
  defaultLanguage;
  constructor(public translateService: TranslateService) {}

  transform(text: string, txtToReplace:string, secondTxt: string = null): any {

    let name = txtToReplace.split(',');
    let resultText;
    this.defaultLanguage = this.translateService.getDefaultLang();
    if (text.search("indiv") == -1) {
      resultText = text
    } else {
      resultText = text.replace("indiv", name[0])
    }
    if (secondTxt) {
      if (text.search("#typ") == -1) {
        resultText = resultText
      } else if(this.defaultLanguage === 'en') {
        resultText = resultText.replace("#typ", secondTxt['value']+" ")
      } else {
        resultText = resultText.replace("#typ", secondTxt['spValue']+" ")
      }
    }else {
      if (text.search("#typ") != -1) {
        resultText = resultText.replace("#typ"," ")
      }
    }
    return resultText;
  }
}
