import { NgModule, LOCALE_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngxs/store';

import { CommonModule, DatePipe, CurrencyPipe } from '@angular/common';
import { NgxMaskModule, MaskPipe } from 'ngx-mask';

import { construct } from 'ramda';

import { LoaderPipe } from './loader/loader.pipe';
import { IndividualPipe } from './individual/individual.pipe';
import { SSNPipe } from './ssn/ssn.pipe';
import { PhonePipe } from './phone/phone.pipe';
import { TimePipe } from './time/time.pipe';
import { PathPipe } from './path/path.pipe';
import { FullNamePipe } from './fullname/fullname.pipe';
import { FullNameSummaryPipe } from './fullNameSummary/fullnamesummary.pipe';
import { SanitizerPipe } from './sanitize/sanitize.pipe';
import { BinaryPipe } from './binary/binary.pipe';
import { DomainPipe } from './domain/domain.pipe';
import { AgePipe } from './age/age.pipe';
import { GenderPipe } from './gender/gender.pipe';
import { NameagegenderPipe } from './nameagegender/nameagegender.pipe';
import { ReplacedatePipe } from './replacedate/replacedate.pipe';
import { ReplaceindivPipe } from './replaceIndiv/replaceIndiv.pipe';
import { ReplaceMedicalTypePipe } from './replacemedicaltype/replaceMedicalType.pipe';
import { ReplaceAssetTypePipe } from './replaceassettype/replaceAssetType.pipe';
import { BoldTextPipe } from './boldText/boldText.pipe';
import { DateFormatPipe } from './dateFormat.pipe';

@NgModule({
  imports: [CommonModule, NgxMaskModule.forRoot()],
  declarations: [
    LoaderPipe,
    IndividualPipe,
    SSNPipe,
    PhonePipe,
    TimePipe,
    PathPipe,
    FullNamePipe,
    FullNameSummaryPipe,
    SanitizerPipe,
    BinaryPipe,
    DomainPipe,
    AgePipe,
    GenderPipe,
    NameagegenderPipe,
    ReplacedatePipe,
    ReplaceindivPipe,
    ReplaceMedicalTypePipe,
    ReplaceAssetTypePipe,
    BoldTextPipe,
    DateFormatPipe
  ],
  exports: [
    NgxMaskModule,
    LoaderPipe,
    IndividualPipe,
    SSNPipe,
    PhonePipe,
    TimePipe,
    PathPipe,
    FullNamePipe,
    FullNameSummaryPipe,
    SanitizerPipe,
    BinaryPipe,
    DomainPipe,
    AgePipe,
    GenderPipe,
    NameagegenderPipe,
    ReplacedatePipe,
    ReplaceindivPipe,
    ReplaceMedicalTypePipe,
    ReplaceAssetTypePipe,
    BoldTextPipe,
    DateFormatPipe
  ],
  providers: [
    {
      provide: LoaderPipe,
      useFactory: construct(LoaderPipe),
      deps: [
        DatePipe,
        CurrencyPipe,
        PhonePipe,
        SSNPipe,
        IndividualPipe,
        FullNamePipe,
        FullNameSummaryPipe,
        BinaryPipe,
        TimePipe,
        DomainPipe,
        AgePipe,
        GenderPipe
      ],
    },
    { provide: IndividualPipe, useFactory: construct(IndividualPipe), deps: [Store, FullNameSummaryPipe] },
    { provide: SSNPipe, useFactory: construct(SSNPipe), deps: [MaskPipe] },
    { provide: PhonePipe, useClass: PhonePipe },
    { provide: TimePipe, useClass: TimePipe },
    { provide: PathPipe, useClass: PathPipe },
    { provide: FullNamePipe, useClass: FullNamePipe },
    { provide: FullNameSummaryPipe, useClass: FullNameSummaryPipe },
    { provide: BinaryPipe, useClass: BinaryPipe },
    { provide: SanitizerPipe, useFactory: construct(SanitizerPipe), deps: [DomSanitizer] },
    { provide: LOCALE_ID, useValue: 'en-US', multi: false },
    { provide: DatePipe, useFactory: construct(DatePipe), deps: [LOCALE_ID] },
    { provide: CurrencyPipe, useFactory: construct(CurrencyPipe), deps: [LOCALE_ID] },
    { provide: DomainPipe, useFactory: construct(DomainPipe) },
    { provide: MaskPipe, useClass: MaskPipe },
    { provide: AgePipe, useClass: AgePipe },
    { provide: GenderPipe, useClass: GenderPipe },
  ],
})
export class PipesModule {}
