<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <section [formGroup]="getControl('data')">
    <h5 class="section-header">Earned Income</h5>
    <hr>
    <ng-container formArrayName="income">
      <div class="details-container mb-2">
        <div *ngIf="isDesktop" class="d-flex p-3 bg-details-container">
          <div class="col-md-3">Person Name</div>
          <div class="col-md-3">Frequency</div>
          <div class="col-md-3">Gross Amount
            <span>
              <nh-popover class="pl-1" content="Gross income is your total income before taxes.">
              </nh-popover>
            </span>
          </div>
        </div>
        <ng-container *ngFor="let row of income.controls; index as i;" [formGroupName]="i">
          <div class="form-row bg-even pl-3 pr-3 pt-3 pb-2" *ngIf="this.getValue('iden', row) !== 'd'">
            <div class="col-md-3">
              <nh-field [showLabel]="!isDesktop" [isRow]="true" [mandatory]="false" label="Person Name"
                class="label-section">
                <select nh name="indvId" aria-label="Person Name" [items]="individuals"
                  [attr.disabled]="income.controls[i].value.indvId != null ? true : null" bindValue="identifier"
                  bindLabel="name.fullName" formControlName="indvId"
                  (change)="onIndividualSelectForEarnedIncome(row)"></select>
              </nh-field>
            </div>
            <div class="col-md-9" [formGroup]="row.controls.wage">
              <div class="row">
                <nh-field [showLabel]="!isDesktop" [isRow]="true" [mandatory]="false" label="Frequency"
                  class="col-md-4">
                  <select nh name="paymentPeriod" aria-label="Frequency" [items]="tables.INCEXPPAYFREQUENCY"
                    formControlName="paymentPeriod"  (change)="onPaymentPeriodForEarnedIncome(row)"></select>
                </nh-field>
                <nh-field [showLabel]="!isDesktop" [isRow]="true" [mandatory]="false" label="Gross Amount"
                  class="col-md-4">
                  <nh-input-group prepend="$">
                    <input nh name="paymentAmountPerPayCheck" type="text" mask="separator.2" thousandSeparator="" separatorLimit="999999999" placeholder="0.00" class="form-control"
                      formControlName="paymentAmountPerPayCheck" (change)="onPaymentAmountForEarnedIncome(row)">
                  </nh-input-group>
                </nh-field>
                <div class="hover-red text-right col-md-3">
                  <button class="btn btn-link btn-lg delete-{{i}} hover-red p-1"
                    [disabled]="income.value[i].indvId == '' || income.value[i].indvId == null"
                    (click)="removeEarnedIncomeRow(i, row)">
                    <span class="pr-2">Remove</span>
                    <nh-remove-svg></nh-remove-svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="pt-1 hover-red text-left">
          <button class="btn btn-link btn-lg hover-red" (click)="addEarnedIncomeRow()"
            [disabled]="!getControl('data.income').valid">
            <span class="pr-2">Add Another</span>
            <nh-add-svg></nh-add-svg>
          </button>
        </div>
      </div>
    </ng-container>

    <h5 class="section-header">Self-Employment Income</h5>
    <hr>
    <ng-container formArrayName="selfEmployment">
      <div class="details-container mb-2">
        <div *ngIf="isDesktop" class="d-flex p-3 bg-details-container">
          <div class="col-md-3">Person Name</div>
          <div class="col-md-3">Type</div>
          <div class="col-md-3">Monthly Net Amount</div>
        </div>
        <ng-container *ngFor="let row of selfEmployment.controls; index as i;" [formGroupName]="i">
          <div class="form-row bg-even pl-3 pr-3 pt-3 pb-2" *ngIf="this.getValue('iden', row) !== 'd'">
            <nh-field [showLabel]="!isDesktop" [isRow]="true" [mandatory]="false" label="Person Name" class="col-md-3">
              <select nh name="indvId" aria-label="Person Name" [items]="individuals"
                [attr.disabled]="selfEmployment.controls[i].value.indvId != null ? true : null" bindValue="identifier"
                bindLabel="name.fullName" formControlName="indvId"
                (change)="onIndividualSelectForSelfEmployment(row)"></select>
            </nh-field>
            <nh-field [showLabel]="!isDesktop" [isRow]="true" [mandatory]="false" label="Type" class="col-md-3">
              <select nh name="type" aria-label="Type" [items]="tables.SELFEMPTYPE" formControlName="type" (change)="onTypeForSelfEmployment(row)"></select>
            </nh-field>
            <nh-field [showLabel]="!isDesktop" [isRow]="true" [mandatory]="false" label="Monthly Net Amount"
              class="col-md-3">
              <nh-input-group prepend="$">
                <input nh name="averageMonthlyProfit" type="text" mask="separator.2" thousandSeparator="" separatorLimit="999999999" placeholder="0.00" class="form-control"
                  formControlName="averageMonthlyProfit" (change)="onMonthlyProfitForSelfEmployment(row)">
              </nh-input-group>
            </nh-field>
            <div class="hover-red text-center col-md-3">
              <button class="btn btn-link btn-lg delete-{{i}} hover-red p-1"
                [disabled]="selfEmployment.value[i].indvId == '' || selfEmployment.value[i].indvId == null"
                (click)="removeSelfEmpIncomeRow(i, row)">
                <span class="pr-2">Remove</span>
                <nh-remove-svg></nh-remove-svg>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="pt-1 hover-red text-left">
          <button class="btn btn-link btn-lg hover-red" (click)="addSelfEmpIncomeRow()"
            [disabled]="!getControl('data.selfEmployment').valid">
            <span class="pr-2">Add Another</span>
            <nh-add-svg></nh-add-svg>
          </button>
        </div>
      </div>
    </ng-container>

    <h5 class="section-header">Other Income</h5>
    <hr>
    <div class="hint semibold">{{otherIncomeTypeSSIEn}}</div>
    <br>
    <ng-container formArrayName="otherIncome">
      <div class="details-container mb-2">
        <div *ngIf="isDesktop" class="d-flex p-3 bg-details-container">
          <div class="col-md-3">Person Name</div>
          <div class="col-md-2">Type</div>
          <div class="col-md-2 ml-2">Frequency</div>
          <div class="col-md-3 ml-3">Gross Amount
            <span>
              <nh-popover class="pl-1" content="Gross pay is how much you get paid before taxes.">
              </nh-popover>
            </span>
          </div>
          <div class="col-md-2"></div>
        </div>
        <ng-container *ngFor="let row of otherIncome.controls; index as i;" [formGroupName]="i">
          <div class="form-row bg-even pl-3 pr-3 pt-3 pb-2" *ngIf="this.getValue('iden', row) !== 'd'">
            <div class="col-md-3">
              <nh-field [showLabel]="!isDesktop" [isRow]="true" [mandatory]="false" label="Person Name"
                class="label-section">
                <select nh name="indvId" aria-label="Person Name"
                  [attr.disabled]="otherIncome.controls[i].value.indvId != null ? true : null" [items]="individuals"
                  bindValue="identifier" bindLabel="name.fullName" formControlName="indvId"
                  (change)="onIndividualSelectForOtherIncome(row)"></select>
              </nh-field>
            </div>
            <div class="col-md-9" [formGroup]="row.controls.otherIncomeDetails">
              <div class="row">
                <nh-field [showLabel]="!isDesktop" [isRow]="true" [mandatory]="false" label="Type" class="col-md-3">
                  <select nh name="otherIncomeTypeCode" aria-label="Type" [items]="tables.UNEARNEDINCOMETYPE_PP"
                    (change)="onOtherIncomeChange(otherIncome.value[i].otherIncomeDetails.otherIncomeTypeCode.code, row)"
                    formControlName="otherIncomeTypeCode" (change)="onCodeForOtherIncome(row)"></select>
                </nh-field>
                <nh-field [showLabel]="!isDesktop" [isRow]="true" [mandatory]="false" label="Frequency"
                  class="col-md-3 pl-0">
                  <select nh name="paymentFrequency" aria-label="Frequency" [items]="tables.INCEXPPAYFREQUENCY"
                    formControlName="paymentFrequency" (change)="onFrequencyForOtherIncome(row)"></select>
                </nh-field>
                <nh-field [showLabel]="!isDesktop" [isRow]="true" [mandatory]="false" label="Gross Amount"
                  class="col-md-3 pl-0">
                  <nh-input-group prepend="$">
                    <input nh name="paymentAmount" type="text" mask="separator.2" thousandSeparator="" separatorLimit="999999999" placeholder="0.00" class="form-control"
                      formControlName="paymentAmount" (change)="onPaymentAmountForOtherIncome(row)">
                  </nh-input-group>
                </nh-field>
                <div class="text-center col-md-3 hover-red">
                  <button class="btn btn-link btn-lg delete-{{i}} hover-red p-1"
                    [disabled]="otherIncome.value[i].indvId == '' || otherIncome.value[i].indvId == null"
                    (click)="removeOtherIncomeRow(i, row)">
                    <span class="pr-2">Remove</span>
                    <nh-remove-svg></nh-remove-svg>
                  </button>
                </div>
                <div class="container"
                  *ngIf="incomeTypeAlp(otherIncome?.value[i]?.otherIncomeDetails.otherIncomeTypeCode?.code)">
                  <div class="row">
                    <div class="col-md-3">
                      <nh-field [isRow]='true' [mandatory]="false" label="Alimony Order Date">
                        <nh-datepicker>
                          <input type="text" class="form-control" name="alimonyOrderDate"
                            formControlName="alimonyOrderDate" placeholder="mm/dd/yyyy" [maxDate]="today" bsDatepicker
                            nh>
                        </nh-datepicker>
                      </nh-field>
                    </div>
                    <div class="col-md-3"></div>
                    <div class="col-md-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="pt-1 hover-red text-left">
          <button class="btn btn-link btn-lg hover-red" (click)="addOtherIncomeRow()"
            [disabled]="!getControl('data.otherIncome').valid">
            <span class="pr-2">Add Another</span>
            <nh-add-svg></nh-add-svg>
          </button>
        </div>
      </div>
    </ng-container>


    <h5 class="section-header">Tax Deductions</h5>
    <hr>
    <ng-container formArrayName="taxDeductionsData">
      <div class="details-container mb-2">
        <div *ngIf="isDesktop" class="d-flex p-3 bg-details-container">
          <div class="col-md-3">Person Name</div>
          <div class="col-md-3">Type</div>
          <div class="col-md-3">Monthly Amount</div>
        </div>
        <ng-container *ngFor="let row of taxDeductionsData.controls; index as i;" [formGroupName]="i">
          <div class="form-row bg-even pl-3 pr-3 pt-3 pb-2" *ngIf="this.getValue('iden', row) !== 'd'">
            <nh-field [showLabel]="!isDesktop" [isRow]="true" [mandatory]="false" label="Person Name" class="col-md-3">
              <select nh name="indvId" [items]="individuals" aria-label="Person Name"
                [attr.disabled]="taxDeductionsData.controls[i].value.indvId != null ? true : null"
                bindValue="identifier" bindLabel="name.fullName" formControlName="indvId"
                (change)="onIndividualSelectForTaxDeduction(row)"></select>
            </nh-field>
            <nh-field [showLabel]="!isDesktop" [isRow]="true" [mandatory]="false" label="Type" class="col-md-3">
              <select nh name="deductionType" [items]="tables.MPDEDUCTIONS" aria-label="Type"
                (change)="onTaxTypeChange(taxDeductionsData.value[i].deductionType.code, row)"
                formControlName="deductionType" (change)="onCodeForTaxDeduction(row)"></select>
            </nh-field>
            <nh-field [showLabel]="!isDesktop" [isRow]="true" [mandatory]="false" label="Monthly Amount"
              class="col-md-3">
              <nh-input-group prepend="$">
                <input nh name="deductionAmount"  type="text" mask="separator.2" thousandSeparator="" separatorLimit="999999999" placeholder="0.00"   class="form-control"
                  formControlName="deductionAmount" (change)="onAmountForTaxDeduction(row)">
              </nh-input-group>
            </nh-field>
            <div class="hover-red text-center col-md-3">
              <button class="btn btn-link btn-lg delete-{{i}} hover-red p-1"
                [disabled]="taxDeductionsData.value[i].indvId == '' || taxDeductionsData.value[i].indvId == null"
                (click)="removeTaxDeductionsRow(i, row)">
                <span class="pr-2">Remove</span>
                <nh-remove-svg></nh-remove-svg>
              </button>
            </div>
          </div>
          <!-- TEDS-168263 Start here -->
          <div class="container">
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-6" *ngIf="taxTypeAlp(taxDeductionsData?.value[i]?.deductionType?.code)">
                <nh-field [mandatory]="false" label="Alimony Order Date">
                  <nh-datepicker>
                    <input type="text" class="form-control" name="alimonyOrderDate" formControlName="alimonyOrderDate"
                      placeholder="mm/dd/yyyy" [maxDate]="today" bsDatepicker nh>
                  </nh-datepicker>
                </nh-field>
              </div>
              <div class="col-md-3"></div>
            </div>
          </div>
          <!-- TEDS-168263Ends Here -->
        </ng-container>
      </div>
      <div class="row">
        <div class="pt-1 hover-red text-left">
          <button class="btn btn-link btn-lg hover-red" (click)="addTaxDeductionsRow()"
            [disabled]="!getControl('data.taxDeductionsData').valid">
            <span class="pr-2">Add Another</span>
            <nh-add-svg></nh-add-svg>
          </button>
        </div>
      </div>
    </ng-container>
  </section>
</nh-form>