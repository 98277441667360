<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title pull-left" id="my-modal-title">{{ title }}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onConfirm()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
    </button>
  </div>
  <div class="modal-body">
    <section *ngIf="DATA_DISCREPANCY">
      <p>Please verify the information you entered for Name and Date of Birth and SSN and correct any errors.</p>
    </section>

    <section *ngIf="CITIZENSHIP_DISCREPANCY">
      <p>
        <strong>Citizenship Status:</strong>
        Our records indicate that <strong>{{ individualName }}</strong> is not a US Citizen.
      </p>
      <p>Your answer to the question, <strong>"Are you a Citizen?"</strong> has been changed to <strong>"No"</strong>.</p>
    </section>

    <section *ngIf="DEATH_CONFIRMATION">
      <p>Our records indicate that <strong>{{ individualName }}</strong> has passed away.</p>

      <form id="death-confirmation" [formGroup]="deathForm">
        <nh-field label="Has {{ individualName }} passed away?">
          <select nh name="answer" [items]="TAYN" formControlName="passedAway"></select>
        </nh-field>

        <nh-field label="When did {{ individualName }} pass away?" *ngIf="deathForm.get('deathDate').enabled">
          <nh-datepicker>
            <input type="text" name="deathDate" class="form-control" formControlName="deathDate" bsDatepicker nh>
          </nh-datepicker>
        </nh-field>
      </form>
    </section>

  </div>

  <div class="modal-footer d-block">
    <div class="form-row justify-content-end">
      <div class="col-md-3 order-md-2">
        <button type="button" id="dialog" class="btn btn-block btn-primary" (click)="onConfirm()" [disabled]="deathForm.invalid">
          OK
        </button>
      </div>
    </div>
  </div>
</div>
