import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { isApplyForBenefits, isPartner, isPresumptiveApp } from '@app/helpers/mode.helpers';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { formatTableData } from '@app/helpers/tables.helpers';
import Vs from '@app/services/validators/validators.service';
import { or } from 'ramda';
import { Title } from '@angular/platform-browser';
import { ServiceConfigService } from "@app/services/service-config.service";

@Component({
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementsComponent extends SectionFormDirective implements AfterContentInit {
  isAFB;
  voterLabel;
  isPresumptive;
  isPartner: any;
  permissionLabel;
  isFullMedicAidApp: any;  
  mcoMasterSwitch:boolean;
  isFinReassessment = false;
  defaultLanguage;
  translatedLang;
  voterLabelAfb;
  voterLabelAfbEn = "Would you like us to mail you a voter registration application?";
  voterLabelAfbSp = "¿Desea que le enviemos por correo una solicitud de registro de votante?";
  voterLabelPresumptive = "Would you like a voter registration application mailed to you?";
  permissionLabelFullMedicaidApp;
  permissionLabelRmc;
  permissionLabelFullMedicaidAppEn = "You have my permission to try to renew my eligibility automatically for the next:";
  permissionLabelRmcEn = "You have my permission to try to renew my eligibility automatically for the next:";
  permissionLabelFullMedicaidAppSp = "Tienen mi permiso para tratar de renovar automáticamente mi elegibilidad para:"
  permissionLabelRmcSp = "Tienen mi permiso para tratar de renovar automáticamente mi elegibilidad para:"
  checkboxValue;
  isRewCoverageReq;
  isavsConsentValue;
  showHealthPlan:boolean = false;
  constructor(private titleService: Title, builder: FormBuilder, 
    public translateService: TranslateService,    
    private readonly serviceConfigService: ServiceConfigService) {
    super(builder);
    this.titleService.setTitle('Before you Submit');
  }

  ngAfterContentInit() {
    this.mcoMasterSwitch = this.serviceConfigService.getServerConfig().mcoMasterSwitch;
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);
    if (this.defaultLanguage !== 'en') {
      this.voterLabelAfb = this.voterLabelAfbSp
      this.permissionLabelFullMedicaidApp = this.permissionLabelFullMedicaidAppSp
      this.permissionLabelRmc = this.permissionLabelRmcSp
    } else {
      this.voterLabelAfb = this.voterLabelAfbEn
      this.permissionLabelFullMedicaidApp = this.permissionLabelFullMedicaidAppEn
      this.permissionLabelRmc = this.permissionLabelRmcEn;
    }
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.languageCheck(res.lang);
      if (res.lang === 'en') {
        this.voterLabelAfb = this.voterLabelAfbEn;
        this.permissionLabelFullMedicaidApp = this.permissionLabelFullMedicaidAppEn;
        this.permissionLabelRmc = this.permissionLabelRmcEn;
      } else {
        this.voterLabelAfb = this.voterLabelAfbSp
        this.permissionLabelFullMedicaidApp = this.permissionLabelFullMedicaidAppSp
        this.permissionLabelRmc = this.permissionLabelRmcSp
      }
      this.voterLabel = this.isPresumptive ? this.voterLabelPresumptive : this.voterLabelAfb;
      this.permissionLabel = this.isFullMedicAidApp ? this.permissionLabelFullMedicaidApp : this.permissionLabelRmc;
    });

    const userType = sessionStorage.getItem('userType');
    this.isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));
    this.isPresumptive = isPresumptiveApp(this.applicationType);
    this.isAFB = isApplyForBenefits(this.applicationType);
    this.isPartner = isPartner(userType);
    this.isFullMedicAidApp = or(this.isPartner, this.isAFB);
    this.permissionLabel = this.isFullMedicAidApp ? this.permissionLabelFullMedicaidApp : this.permissionLabelRmc;
    this.voterLabel = this.isPresumptive ? this.voterLabelPresumptive : this.voterLabelAfb;
    this.checkboxValue = (this.formGroup.controls.data as any)
    .controls.applicationSignature.controls?.voterRegistration?.value;
    const renewEligibilityValue = (this.formGroup.controls.data as any)
    .controls.applicationSignature.controls?.renewEligibility?.value;
    let isRenewReqValue = {};
    if(this.checkboxValue === null && renewEligibilityValue === null){
      isRenewReqValue = {'code': '', 'value': null}
    } else {
      const renewEligi = (this.formGroup.controls.data as any)
      .controls.applicationSignature.controls.renewEligibility;
      if(renewEligibilityValue?.code) {
        isRenewReqValue = {'code': 'Y', 'value': 'Yes'};
        renewEligi.setValidators([Vs.required]);
        renewEligi.updateValueAndValidity();
      } else {
        isRenewReqValue = {'code': 'N', 'value': 'No'};
        renewEligi.setValue(null);
        renewEligi.clearValidators();
        renewEligi.updateValueAndValidity();
      }
    }
    this.isRewCoverageReq =  isRenewReqValue;
    (this.formGroup.controls.data as any)
    .controls.applicationSignature.controls.isRenewRequired.setValue(isRenewReqValue);
    this.showHealthPlan = this.isAFB || this.isPresumptive;

    const avsConsentValue = (this.formGroup.controls.data as any).controls.applicationSignature.controls?.avsConsent?.value;
    if(avsConsentValue === null){
      this.isavsConsentValue = {'code': '', 'value': null}
    }else{
      if(avsConsentValue?.code === 'Y'){
        this.isavsConsentValue = {'code': 'Y', 'value': 'Yes'};
      }else{
        this.isavsConsentValue = {'code': 'N', 'value': 'No'};
      }
    }
    (this.formGroup.controls.data as any).controls.applicationSignature.controls.avsConsent.setValue(this.isavsConsentValue);
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  voteRegiChecked(event, otherField){    
    console.log(event);
    otherField.checked = false;
    if(event.target.checked){
      const targetValue = event.target.value === 'Yes' ? 'Y': 'N';
      (this.formGroup.controls.data as any)
      .controls.applicationSignature.controls.voterRegistration.setValue({'code': targetValue, 'value': event.target.value});
    }
    else {
      (this.formGroup.controls.data as any)
    .controls.applicationSignature.controls.voterRegistration.setValue({'code': '', 'value': null});
    }
  }
  
  isRenewChecked(event, otherField){    
    otherField.checked = false;
    const targetValue = event.target.value === 'Yes' ? 'Y': 'N';
    if(event.target.checked){
      const renewEligi = (this.formGroup.controls.data as any)
      .controls.applicationSignature.controls.renewEligibility;
      if(targetValue === 'Y') {
        renewEligi.setValidators([Vs.required]);
        renewEligi.updateValueAndValidity();
      } else {
        renewEligi.setValue(null);
        renewEligi.clearValidators();
        renewEligi.updateValueAndValidity();
      }
      (this.formGroup.controls.data as any)
      .controls.applicationSignature.controls.isRenewRequired.setValue({'code': targetValue, 'value': event.target.value});
    }
    else {
      (this.formGroup.controls.data as any)
    .controls.applicationSignature.controls.isRenewRequired.setValue({'code': targetValue, 'value': null});
    }
  }


  isRenewCheckedAVS(event, otherField){    
    otherField.checked = false;
    const targetValue = event.target.value === 'Yes' ? 'Y': 'N';
    if(event.target.checked){
      (this.formGroup.controls.data as any).controls.applicationSignature.controls.avsConsent.setValue({'code': targetValue, 'value': event.target.value});
    }
    else {
      (this.formGroup.controls.data as any).controls.applicationSignature.controls.avsConsent.setValue({'code': targetValue, 'value': null});
    }
  }

  
  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  tableCodeValue (tableName, tableControl){
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }
}
