<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
    <section [formGroup]="getControl('data')">
        <div class="container start-page">
            <div class="nh-table-mobile d-lg-none" *ngIf="!hideTopQuestions">
                <h5 class="section-header">{{'APPEAL_REP_DETAILS_MOBILE_STATIC1' | translate}}</h5>
                <hr>
            </div>
            <div class="d-none d-lg-block" *ngIf="!hideTopQuestions">
                <h5 class="section-header">{{'APPEAL_REP_DETAILS_DESKTOP_STATIC1' | translate}}</h5>
                <hr>
            </div>
            <div *ngIf="authRepresntativeObj?.eligRepresentativeName && !hideTopQuestions">
                <p>{{'APPEAL_REP_DETAILS_STATIC2' | translate}}</p>
                <div *ngFor="let choice of individualTable; let i=index" class="mt-2 pt-2">
                    <div class="form-check pb-md-1 checkbox">
                        <label class="form-check-label mt-2">
                            <input type="checkbox" class="form-check-input" [value]="choice.eligAuthSelected"
                                aria-label="individualTable{i}" (change)="selectCheckBox($event)"
                                [checked]="choice.eligAuthSelected">
                            {{choice.name}} - {{choice.relation}}
                        </label>
                    </div>
                </div>
                <br>
            </div>
            <p *ngIf="(eligAuthResprentativeSw === 'N' || !eligAuthResprentativeSw) && !hideTopQuestions">{{'APPEAL_REP_DETAILS_STATIC4' | translate}}</p>
            <h5 class="section-header">{{'APPEAL_REP_DETAILS_STATIC5' | translate}}</h5>
            <hr>
            <nh-field *ngIf="!authEligibleExists && !hideTopQuestions" [mandatory]="true"
                label="{{'APPEAL_REP_DETAILS_STATIC6' | translate}}" class="label-section">
                <select nh name="addReprentative" [items]="tableCodeValue('YESNO', 'data.addReprentative')"
                    aria-label="addReprentative" (change)="addReprentative()"
                    formControlName="addReprentative"></select>
            </nh-field>
            <nh-field *ngIf="authEligibleExists && !hideTopQuestions" [mandatory]="true"
                label="{{'APPEAL_REP_DETAILS_STATIC7' | translate}}" class="label-section">
                <select nh name="addReprentative" [items]="tableCodeValue('YESNO', 'data.addReprentative')"
                    aria-label="addReprentative" (change)="addReprentative()"
                    formControlName="addReprentative"></select>
            </nh-field>
            <div *ngIf="(isY('data.addReprentative'))">
                <nh-field [mandatory]="true" label="{{'APPEAL_REP_DETAILS_STATIC8' | translate}}" class="label-section">
                    <select nh name="appeallantRelationship" [items]="tableCodeValue('APRELATIONSHIP', 'data.appeallantRelationship')"
                        aria-label="appeallantRelationship" (change)="onRelationChange()" formControlName="appeallantRelationship"></select>
                </nh-field>
            </div>
            <nh-alert type="danger" [isOpen]="showRelationValidation">
                <div class="row">
                  <div class="icon">
                    <img src="../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
                  </div>
                  <div class="message">
                    <span>{{ 'APPEAL_RELATION_VALIDATION_MESSAGE' | translate}}</span>
                  </div>
                </div>
            </nh-alert>
            <div *ngIf="relationshipSelected">
                <nh-name-address [nameMandatory]= "nameFieldsMandatory" [namePopup]="true" [personalName]="getControl('data.name')" [isAssistingPerson]="false"
                    [tables]="tables">
                </nh-name-address>
                <nh-field [mandatory]="false" label="{{'GENDER' | translate}}" class="label-section">
                    <select aria-label='gender' nh name="gender" formControlName="gender"
                        [items]="tableCodeValue('GENDER', 'data.gender')"></select>
                </nh-field>
                <nh-field name="organizationName" [mandatory]="organizationMadatory"
                    label="{{'APPEAL_REP_DETAILS_STATIC9' | translate}}" class="label-section">
                    <input (input)="onInput($event)" type="text" name="organizationName" class="form-control" aria-label="organizationName"
                        formControlName="organizationName" maxlength="45">
                </nh-field>
                <div [formGroup]="getControl('data.representativeAddress')"> 
                <nh-field name="addressFormat" label="{{'ADDRESS_FORMAT' | translate}}" class="label-section">
                    <nh-popover class="pl-1" content="{{'CASE_HEAD_POPOVER_TEXT' | translate}}"></nh-popover>
                    <select nh name="addressFormat" [items]="tableCodeValue('ADDRESSFORMATTYPES', 'data.representativeAddress.addressFormat')"
                        aria-label="addressFormat" formControlName="addressFormat" (change)="addressFormat()"></select>
                </nh-field>
                <nh-name-address [addressname] = "'appeal-representative'" [addressFormat]="getControl('data.representativeAddress.addressFormat')"
                [address]="getControl('data.representativeAddress.address')" [stateDefault]="false" [isAssistingPerson]="false" [tables]="tables">
                </nh-name-address>
                </div>
                <div [formGroup]="getControl('data.contactDetails')">
                    <nh-field [mandatory]="true" label="{{'CELL_PHONE' | translate}}">
                        <input type="text" name="cell" formControlName="cell" class="form-control" mask="000-000-0000"
                            placeholder="XXX-XXX-XXXX">
                    </nh-field>
                    <nh-field [mandatory]="false" label="{{'WORK_PHONE' | translate}}">
                        <input type="text" name="work" formControlName="work" class="form-control" mask="000-000-0000"
                            placeholder="XXX-XXX-XXXX">
                    </nh-field>
                    <nh-field [mandatory]="false" label="{{'FAX' | translate}}">
                        <input type="text" name="fax" formControlName="fax" class="form-control" mask="000-000-0000"
                        placeholder="XXX-XXX-XXXX">
                    </nh-field>
                    <nh-field [mandatory]="false" label="{{'APPEAL_EMAIL' | translate}}" class="label-section">
                        <input type="email" name="email" class="form-control" aria-label="email" formControlName="email"
                            maxlength="63">
                    </nh-field>
                </div>
                <br>
                <button id="reset" (click)="resetAll()" class="btn btn-secondary btn-search">{{'RESET' | translate}}
                </button>
            </div>
        </div>
    </section>
</nh-form>