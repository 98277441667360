import { useWith } from 'ramda';
import { unless } from 'ramda';
import { isEmpty } from 'ramda';
import { concat } from 'ramda';
import { compose } from 'ramda';
import { propEq } from 'ramda';
import { anyPass } from 'ramda';
import { ifElse } from 'ramda';
import { converge } from 'ramda';
import { call } from 'ramda';
import { always } from 'ramda';
import { prop } from 'ramda';

import { set } from './http.helpers';
import { get } from './form.helpers';
import { orS } from './function.helpers';

export const prefixWith = useWith(unless(isEmpty), [concat, orS]);

const isDelete = propEq('method', 'DELETE');
const isPut = propEq('method', 'PUT');
export const isPutDelete = anyPass([isDelete, isPut]);

const headers = prop('headers');

const actionType = ifElse(isDelete, always('delete'), always('update'));

const setActionType = compose(
  set('action'),
  actionType
);

export const setAction = converge(call, [setActionType, headers]);

export const readToken = compose(
  get('x-auth-token'),
  headers
);
