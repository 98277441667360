import { FacilityInformationComponent } from './facility-information.component';
import { nhAddressCreator, sectionConfiguration } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { toDate, toISO } from '@app/helpers/date.helpers';
import Vs from '@app/services/validators/validators.service';
import { evolve } from 'ramda';

const formValidation = (builder, recordData) => {
  const nursingFacility = lookIn(recordData);
  const admissionDt = nursingFacility(['admissionInfo', 'admissionDt']);
  const dischargeDt = nursingFacility(['dischargeInfo', 'dischargeDt']);
  const dateOfDeath = nursingFacility(['dischargeInfo', 'dateOfDeath']);
  const prevConfStartDt = nursingFacility(['admissionInfo', 'prevConfStartDt']);
  const prevConfEndDt = nursingFacility(['admissionInfo', 'prevConfEndDt']);
  
  const data = builder.group({
    caseNum: sessionStorage.getItem('caseNum'),
    admitDischargeSw: null,
    appNum: sessionStorage.getItem('appNum'),
    indvId: sessionStorage.getItem('individualId'),
    instId: [nursingFacility(['instId'])],
    nursingFacilityAddress: builder.group({
      address: builder.group(nhAddressCreator(nursingFacility(['nursingFacilityAddress', 'address']))),
    }),
    admissionInfo: builder.group({
      admissionDt: [toDate(admissionDt), Vs.required],
      prevConfStartDt: [toDate(prevConfStartDt)],
      prevConfEndDt: [toDate(prevConfEndDt)],
      hospiceSw: [nursingFacility(['admissionInfo', 'hospiceSw'])],
      stateVeteranSw: [nursingFacility(['admissionInfo', 'stateVeteranSw'])],
    }),
    dischargeInfo: builder.group({
      dischargeAddrSw: [nursingFacility(['dischargeInfo','dischargeAddrSw'])],
      dischargeDt: [toDate(dischargeDt)],
      dateOfDeath: [toDate(dateOfDeath)],
      dischargereason: [nursingFacility(['dischargeInfo', 'dischargereason'])],
      dischargeAddress: builder.group({
        address: builder.group(nhAddressCreator(nursingFacility(['dischargeInfo', 'dischargeAddress', 'address']))),
      }),
    }),
    nursingFacilitySearch: [nursingFacility(['nursingFacilitySearch']), Vs.individualName],
    nursingFacilityName: [nursingFacility(['nursingFacilityName']), [Vs.orgName, Vs.required, Vs.maxLength(100)]],
    select: [nursingFacility(['select'])],
    
  })

  return { data };
};

const postObject = evolve({
  dischargeInfo: {
    dateOfDeath: toISO,
    dischargeDt: toISO,
  },
  admissionInfo: {
    admissionDt: toISO,
    prevConfStartDt: toISO,
    prevConfEndDt: toISO,
  },
});

export default sectionConfiguration(
  FacilityInformationComponent,
  formValidation,
  ['STATE', 'COUNTY', 'YESNO', 'DISCHRSN'],
  [],
  {},
  {
    postObject,
    hasSummary: false,
    validateSubmit: true,
  }
);
