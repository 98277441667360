import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { tap, switchMap, map } from 'rxjs/operators';

import { prop, equals, isNil, isEmpty, contains, uniq } from 'ramda';

import { validations } from '../details';
import { SectionComponent } from '@app/section/section.component';

import { nilElse } from '@app/helpers/null.helpers';
import { editUncompleted } from '@app/helpers/people.helpers';
import { recordIndividualId, setBlockId, setRecordId } from '@app/helpers/record.helpers';
import { trudy } from '@app/helpers/function.helpers';

import { Person } from '@app/models/record.model';
import { Block, Identifier } from '@app/models/record.model';
import { isChangeReport, isRedeMode } from '@app/helpers/mode.helpers';
import { Observable } from 'rxjs';
@Component({
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxComponent extends SectionComponent implements OnInit {
  showLeave = true;
  indivlist: [];
  blocks;
  showAlert = false;
  jointFiler;
  taxDependents;
  isOnEdit = false;
  disableNext = false;
  exportStatus;
  isChangeMode = false;
  isRedeModeOnly = false;
  appKeyWord;
  checkBlock$: Observable<boolean>;
  showMovedOut = false;
  jointMovedOutOfHousehold;
  movedOutOfHousehold;
  appType = sessionStorage.getItem('appType');
  user = sessionStorage.getItem('userType');
  isKepro = sessionStorage.getItem('isKepro');
  isMovedOutId;
  isjointFilerSpouseMovedOut;
  isFilerMovedOut;
  isRmc;
  isRmbFrm;
  hohTaxId;
  hohTaxIndv;
  hohTaxRecord;
  intervalHolder: any;
  ////////////////////////////////////// LIFECYCLE //////////////////////////////////////

  ngOnInit() {
    this.cd.detach();
    this.intervalHolder = setInterval(() => {
      this.getAllUsers();
    }, 0);
    this.isChangeMode = isChangeReport(this.mode);
    this.isRedeModeOnly = isRedeMode(this.mode);
    this.isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));
    this.appKeyWord = this.isChangeMode ? 'CHANGE' : (this.isRedeModeOnly && !this.isFinReassessment) ? 'RENEWAL' : (this.isRedeModeOnly && this.isFinReassessment) ? 'reassessment' : 'APPLICATION';
    this.records.filter(i => {
      return this.exportStatus = i.exportingStatus;
    });
    this.jointFiler = this.records.filter(i => i.data.jointFilerSw !== null);
    this.taxDependents = this.records.filter(rec => rec.data.taxDependents);
    this.movedOutOfHousehold = this.individuals.some(data => data['moveOutReason']?.code === 'MO');


    //warning for jointfiler or taxfiler moved out of household
    //MP-RMB, VTC-RMB, AHS-RMB, FRM
    //MP-RMC, VTC-RMC, AHS-RMC
    this.isRmc = ((this.user !== 'RP' && this.appType === 'RC') || (this.user === 'RP' && this.appType === 'RC') || (this.isKepro && this.appType === 'CHANGE_REPORT'));
    this.isRmbFrm = ((this.appType === 'RB') || (this.isKepro && this.appType === 'CHANGE_BENEFITS'));

    if(this.isRmc || this.isRmbFrm){
      this.hohTaxIndv = this.individuals.filter(data => data['headOfHousehold'] === true).map(item => this.hohTaxId = JSON.parse(item.identifier));
      this.hohTaxRecord = this.records.filter(data =>JSON.parse(data?.identifier?.individualId) === this.hohTaxId);
      const jointFilerSw = this.hohTaxRecord[0]?.data?.jointFilerSw?.code;
      const jointTaxFilingIndvId = this.hohTaxRecord[0]?.data?.jointTaxFilingIndividualId;
      const isMovedOut = this.individuals.filter(data => data['moveOutReason']?.code === 'MO').map(item => this.isMovedOutId = JSON.parse(item.identifier));
      this.isjointFilerSpouseMovedOut = (this.isRmbFrm || this.isRmc) && (isMovedOut[0] === jointTaxFilingIndvId) && (isMovedOut[0] !== undefined) && (jointFilerSw === 'Y');
      const taxFilingInvId =  this.records.filter(data => (data?.data?.taxFilingStatus === 'Filer') && (this.isMovedOutId === JSON.parse(data?.identifier?.individualId)));
      this.isFilerMovedOut = (this.isRmbFrm || this.isRmc) && (taxFilingInvId.length > 0);
      
      if(this.isjointFilerSpouseMovedOut || this.isFilerMovedOut){
        this.showMovedOut = true;
      }
    }

    if ((this.jointFiler.length || this.taxDependents?.length) && this.jointFiler.exportingStatus !== 'REMOVED') {
      const count = this.jointFiler.length + this.records.length;
      this.showAlert = equals(count, this.individuals.length);

      if (this.jointFiler?.length && (this.jointFiler[0]?.data?.jointFilerOutsideHousehold !== 'Yes' || this.jointFiler[0]?.data?.jointFilerOutsideHousehold == null)) {
        this.jointFiler.forEach(jf => {
          if (jf.data.jointFilerSw?.code === 'Y') {
            const jointTaxFilingIndividualId = jf.data.jointTaxFilingIndividualId?.toString();
            this.records.filter(indv => indv.data.taxFilingIndividualId)
            if (this.records.length) {
              const jointFilerRecord = {
                data: { identifier: jointTaxFilingIndividualId, taxFilingStatus: 'Joint Filer', filer: this.jointFiler[0].data.taxFilingIndividualId.toString(), updateDate: this.jointFiler[0].data?.updateDate },
                exportingStatus: this.exportStatus,
                identifier: { individualId: jointTaxFilingIndividualId, sectionId: null }
              };

              const fakeList = this.records.filter(record => record.data.taxFilingStatus === 'Joint Filer');
              if (!fakeList.length) {
                this.records.push(jointFilerRecord);
              };
            }
          }
        })
      }

      if (this.taxDependents?.length) { 
        this.taxDependents.forEach(dep => {
          if (dep.exportingStatus !== 'REMOVED') {
            dep.data.taxDependents.forEach(indv => { 
              const taxDep = indv.individualId.toString();
              const isTaxIndId = this.records.find(rec => rec.identifier.individualId === taxDep);
              if(!isTaxIndId){
              const taxDependentRecords = {
                data: { identifier: taxDep, taxFilingStatus: 'In Household Dependent', filer: dep.data.taxFilingIndividualId.toString(), taxFilingEndDate: dep.data.taxFilingEndDate, updateDate: dep.data?.updateDate},
                exportingStatus: dep.exportingStatus ? dep.exportingStatus : this.exportStatus,
                identifier: { individualId: taxDep, sectionId: null }
              };

              this.records.push(taxDependentRecords);
              this.records = uniq(this.records);
            }
            });
          }
        })
      };
    }

    const bool = this.records.some(rec => rec.exportingStatus === 'EXPORTED');
    this.disableNext = isRedeMode(this.mode) ? bool : false; 


    if (this.topLevelAnswer !== "YES" || this.records[0].data.dummyIndividual === true) {
      this.hasSummary = false;
      const hohRecord = this.records[0];
      this.onEditBlock(hohRecord);
    };

    if (equals(this.individuals.length, this.records.length)) {
      this.showAlert = true;
    } else {
      this.showAlert = false;
    }

    this.state.setPerson(null);
    editUncompleted(record => this.onEditBlock(record), this.records);

    this.checkBlock$ = this.blockLinks$.pipe(map((res) => {
      return (res ? (res.length <= 0) : true);
    }))
  }

  ngOnDestroy() {
    clearInterval(this.intervalHolder);
  }

  onLeave () {
    if (this.blockId === 'taxfiling') {
      this.onClose();
    } else {
      this.canDeactivate().subscribe(trudy(() => {
        this.blockLinks$.subscribe(res => {
            if (res) this.blocks = res;
        })
        if (this.blocks) {
          const activeIndex = this.blocks.findIndex(block => this.blockId === block.blockId)
          this.onEditBlock(setRecordId(this.blocks[activeIndex-1].blockId, this.activePerson));
        }
      }));
    }
  }

  showAddAnother () {
    return this.records?.some(i => (i.data.jointFilerSw === null || i.data.jointFilerSw?.code === 'N'));
  }

  getAllUsers(){
    this.cd.detectChanges();
  }

  ////////////////////////////////////// LIFECYCLE //////////////////////////////////////

  ////////////////////////////////////// FACADE //////////////////////////////////////

  onPerson(activePerson: Person) {
    return this.state.setPerson(activePerson).pipe(
      tap(setBlockId(this)),      
      switchMap(identifier => {
        if (identifier.sectionId === 'HHTJF' && identifier.recordId === 'demographics') {
          identifier.recordId = 'taxfiling';
          this.blockId = 'taxfiling';
        }
        return this.loadBlock(identifier)
      })
    )
  }

  loadBlock(identifier: Identifier) {
    return this.state.loadBlock(this.applicationNumber, identifier);
  }

  onHalt(record) {
    this.clearForm();
    this.onEdit(record);
  }

  onSaved(block: Block | null) {
    return block ? this.onHalt(block) : super.onSaved(block);
  }

  ////////////////////////////////////// FACADE //////////////////////////////////////

  ////////////////////////////////////// STATE UPDATE //////////////////////////////////////
  updateConfig({ component, formValidation, postObject }) {
    this.update({ component, formValidation, postObject });
  }

  updateForm() {
    this.clearForm();
    this.updateConfig(prop(this.blockId, validations));
  }

  ////////////////////////////////////// STATE UPDATE //////////////////////////////////////

  ////////////////////////////////////// TEMPLATE ACTION HANDLERS //////////////////////////////////////

  onBlock(tab) {
    this.onEditBlock(setRecordId(tab.blockId, this.activePerson));
  }

  onCreateBlock() {
    this.blockId = 'taxfiling';
    this.updateForm();
    super.onCreate();
  }

  onCreate() {
    this.onEdit(
      this.emptyRecord({
        sectionId: this.sectionId,
        recordId: this.blockId,
        individualId: recordIndividualId(this.activePerson),
      })
    );
  }
  editTaxFiler () {
    const rec =  this.records.filter((rec:any) => rec.data.taxFilingStatus === 'Filer');
    this.onEditBlock(rec[0]);
  }

  onEditBlock(activePerson, showLeave = true) {
    const filingStatusus = ['Joint Filer', 'In Household Dependent'];
    const filingStatusOutside = ['Out of Household Dependent'];
    if (contains(activePerson.data.taxFilingStatus, filingStatusus)) {
      activePerson = this.records.filter(rec => rec.identifier.individualId == activePerson.data.filer)[0];
      const headIndvid = activePerson.data.taxFilingIndividualId.toString();
      activePerson.identifier.individualId = headIndvid;
    } else if (contains(activePerson.data.taxFilingStatus, filingStatusOutside)) {
      activePerson = this.records[0];
      const indiv = this.individuals[0].identifier;
      activePerson.identifier.individualId = indiv;
    }

    this.update({ showLeave });
    return this.onPerson(activePerson)
      .pipe(tap(() => this.updateForm()))
      .subscribe(nilElse(() => this.onCreate(), record => this.onEdit(record)));
  }

  onChange(event) {
    if (Array.isArray(event)) {
      this.peopleSelection = event;
    }
  }

  onTaxSave() {
    const filingVal = this.form.value.data?.filing;
    const taxDeps = this.form.value.data?.taxDependents;
    if ((filingVal && filingVal.code === 'N' && equals(this.blockId, 'taxfiling')) || (isNil(taxDeps) && equals(this.blockId, 'taxfileroutside'))) {
      if (this.activePerson?.identifier.individualId === '1234567890' || this.activePerson === null) {
        const headIndvId = this.individuals[0].identifier;
        this.form.value.identifier.individualId = headIndvId;
        this.onSave();
      } else if (this.activePerson.identifier.individualId) {
        this.form.value.identifier.individualId = this.activePerson.identifier.individualId;
        this.onSave();
      }
    } else {
      if (isEmpty(this.form.value.identifier.individualId)) {
        if (isNil(this.form.value.identifier.recordId)) {
          this.form.value.identifier.recordId = 'taxfiling';
        }
        this.form.value.identifier.individualId = this.form.value.data.taxFilingIndividualId;
        this.onSave();
      } else {
        this.onSave();
      }
    }
  };

  ////////////////////////////////////// TEMPLATE ACTION HANDLERS //////////////////////////////////////
}
