import { compose } from 'ramda';
import { propEq } from 'ramda';
import { map } from 'ramda';
import { prop } from 'ramda';
import { omit } from 'ramda';
import { pluck } from 'ramda';
import { converge } from 'ramda';
import { identity } from 'ramda';
import { find } from 'ramda';
import { findIndex } from 'ramda';

import { sectionId } from '@app/state/shared/shared.selectors';
import { getSections } from '@app/state/shared/shared.selectors';
import { householdHead } from '@app/helpers/individual.helpers';
import { viewApp, viewFirstId, viewIndividuals } from '@app/helpers/lenses.helpers';
import { setModuleIdL, viewModules, overModules } from '@app/helpers/lenses.helpers';

import { Statuses } from '@app/models/app.model';

export const viewApplication = compose(
  omit(['modules', 'sections', 'individuals']),
  viewApp
);

export const isSubmitted = propEq('status', Statuses.SUBMITTED);

const isInProgress = propEq('status', Statuses.IN_PROGRESS);

export const findIndexInProgress = findIndex(isInProgress);
export const findInProgress = find(isInProgress);

export const indexInProgress = compose(
  findIndexInProgress,
  getSections
);

export const sectionInProgress = compose(
  sectionId,
  findInProgress,
  getSections
);

const setModuleFirstId = converge(setModuleIdL);

export const stepperLinks = compose(
  viewModules,
  overModules(map(setModuleFirstId([viewFirstId, identity])))
);

export const sectionsIds = compose(
  pluck('sectionId'),
  prop('sections')
);

export const viewHouseholdHead = compose(
  householdHead,
  viewIndividuals
);
