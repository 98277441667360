import { AppealRepresentativeDetailsComponent } from './appeal-representative-details.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import Vs from '@app/services/validators/validators.service';
import { phonesCreatorAppeal, nameCreator, addressCreator } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import AppealRepDetailsLabels from './appeal-representative-details-labels';

const formValidation = (builder, recordData) => {
  const appealRep = lookIn(recordData);
  const addReprentative = appealRep(['addReprentative']);
  const addressFormatP = appealRep(['representativeAddress', 'addressFormat']);
  const address = appealRep(['representativeAddress', 'address']);
  const appeallantRelationship = appealRep(['appeallantRelationship']);
  const oldAppeallantRelationship = appealRep(['appeallantRelationship']);
  const eligAuthResprentativeSw = appealRep(['eligAuthResprentativeSw']);
  const gender = appealRep(['gender']);
  const organizationName = appealRep(['organizationName']);
  const caseNumber = appealRep(['caseNumber']);
  const authEligibleArray = appealRep(['eligibleRepresentative']);

  const data = builder.group({ 
    addReprentative: [addReprentative, Vs.required],
    appeallantRelationship: [appeallantRelationship],
    oldAppeallantRelationship: [oldAppeallantRelationship],
    eligibleRepresentative: [authEligibleArray],
    eligAuthResprentativeSw: [eligAuthResprentativeSw],
    contactDetails: builder.group(phonesCreatorAppeal(appealRep(['contactDetails']))),
    gender: gender,
    name: builder.group(nameCreator(appealRep(['name']))),
    organizationName: organizationName,
    caseNumber: caseNumber,
    representativeAddress: builder.group({
      address: builder.group(addressCreator(address)),
      addressFormat: [ addressFormatP ],
      addressType: appealRep(['representativeAddress', 'addressType']),
    }),
    duplicateRelationship : null
  })

  return { data };
 }

const personName = ({ data }) => {
  let returnName;
  if (data.name?.firstName) {
    const middleName = data.name?.middleInitial ? data.name.middleInitial : "";
    const suffix = data.name?.suffix ? " " + data.name.suffix.value + " " : "";
    returnName = `${data.name?.firstName}  ${middleName}  ${data.name?.lastName} ${suffix}`

  } else if (data.organizationName) {
    returnName = `${data.organizationName}`
  }
  return returnName;
};

const contact = ({ data }) => {
  const cell = data.contactDetails?.cell;
  const work = data.contactDetails?.work;
  let number = work ? cell +" " + work : cell ? cell : 'N/A';
  return `${number}`

}

const address = ({ data }) => {
  if(data.representativeAddress?.addressFormat?.code === 'MI') {
    const address2 = data.representativeAddress?.address?.addressLine2 ? ", " + data.representativeAddress.address.addressLine2 + "," : ",";
    return `${data.representativeAddress?.address?.addressLine1}${address2} ${data.representativeAddress?.address?.addrApoFpo?.value} ${", "} ${data.representativeAddress?.address?.addrAaAeAp?.value} ${data.representativeAddress?.address?.zip}`;
  } else {
    const address2 = data.representativeAddress?.address?.addressLine2 ? ", " + data.representativeAddress.address.addressLine2 + "," : "";
    let city;
    if(data.representativeAddress?.address?.addressLine2) {
      city = data.representativeAddress?.address?.city ? data.representativeAddress.address.city + "," : "";
    } else {
      city = data.representativeAddress?.address?.city ? ", " + data.representativeAddress.address.city + "," : "";
    }
    const county = data.representativeAddress?.address?.county ? "," + data.representativeAddress?.address?.county.value : "";
    return `${data.representativeAddress?.address?.addressLine1}${address2}${city} ${data.representativeAddress?.address?.state?.code} ${data.representativeAddress?.address?.zip} ${county}`;
  }
}


export default sectionConfiguration(AppealRepresentativeDetailsComponent, formValidation, ["YESNO", "GENDER", 'ADDRESSFORMATTYPES', 'NAMESUFFIX', 'STATE', 'COUNTY', 'MILITARYPOCODES', 'STATEMILITARY', 'APRELATIONSHIP'], 
[{ name: 'Name', pipe: 'domain', args: personName },
{ name: 'Phone Number', pipe: 'domain', args: contact },
{ name: 'Relationship', prop: "data.appeallantRelationship.value" },
{ name: 'Address', pipe: 'domain', args: address }], 
{ ...{}, ...AppealRepDetailsLabels }, {
  showSubmit: false,
  showComments: false,
  showSaveAndExit: false,
});
