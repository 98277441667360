import { SubmitComponent } from './submit.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { lookUp } from '@app/helpers/flipped.helpers';
import Vs from '@app/services/validators/validators.service';
import { ContinueLabels } from '@app/models/section.model';
import { isPartner } from '@app/helpers/mode.helpers';
import { controlWith } from '@app/helpers/form.helpers';

const formValidation = (builder, recordData) => {
  const submit = lookUp(recordData);
  const applicationSignName = submit(['applicationSignName']);
  const applicationSignedBySw = submit(['applicationSignedBySw']);
  const presumptiveApplicationSignedBySw = submit(['presumptiveApplicationSignedBySw']);
  const partnerSignName = submit(['partnerSignName']);
  const partnerSignedBySw = submit(['partnerSignedBySw']);
  const callCenterComments = submit(['callCenterComments']);

  const isPartnerUser = isPartner(sessionStorage.getItem('userType'));
  const isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));
  const isKepro = sessionStorage.getItem('isKepro') === 'true';

  const data = builder.group({
    applicationSignedBySw: [applicationSignedBySw, Vs.required],
    applicationSigned: [controlWith(applicationSignedBySw, !isFinReassessment || isKepro), Vs.required],
    applicationSignName: [applicationSignName, [Vs.required, Vs.individualName]],
    presumptiveApplicationSignedBySw: [presumptiveApplicationSignedBySw, Vs.required],
    partnerSignedBySw: [controlWith(partnerSignedBySw, !isPartnerUser || isFinReassessment || isKepro), Vs.required],
    partnerSignName: [controlWith(partnerSignName, (!isPartnerUser && !isFinReassessment) || isKepro), [Vs.required, Vs.individualName]],
    callCenterComments: [callCenterComments],
    submitApplicationSourceType : null
  });

  return { data };
};

export default sectionConfiguration(SubmitComponent, formValidation, ['TAYN'], [], {}, {
  hasSummary: false,
  showSubmit: false,
  showSaveAndExit: false,
  labelContinue: ContinueLabels.SUBMIT,
});
