<div class="modal-content">
	<div class="modal-header">
		<h5 class="modal-title pull-left" id="my-modal-title">{{ 'End_Coverage_Title' | translate }}</h5>
	</div>
	<div class="modal-body">
		<p>{{'End_Coverage_Text1' | translate}} </p>

		<div *ngFor="let entityDetail of entityDetails">
			<p><strong>{{entityDetail.firstNam}} {{entityDetail.lastNam}}</strong></p>
		</div>
		<p>{{'End_Coverage_Text2' | translate}} <strong>{{'End Coverage' | translate}}</strong></p>

		<p> {{'END_COVERAGE_STATIC_TEXT2' | translate}}
		</p>
	</div>

	<!-- <div class="modal-footer d-block">
		<div class="form-row justify-content-end">
			<div class="col-md-4 order-md-1">
				<button type="button" class="btn btn-block btn-link" (click)="onConfirm()">END COVERAGE</button>
			</div>
			<div class="col-md-3 order-md-2">
				<button type="button" class="btn btn-block btn-secondary" (click)="onCancel()">Cancel</button>
			</div>
		</div>
	</div> -->
	<div class="modal-footer d-block">
		<section class="row mt-2 pb-3 ml-2 main-content-width" style="overflow-x: hidden;">
		<div class="mr-3 pr-md-1 py-sm-1">
			<button type="button" id="End_Coverage_dilouge" class="btn btn-block btn-link" (click)="onConfirm()">{{'End Coverage' | translate}}</button>
		</div>
		<div class="pr-md-1 py-sm-1">
			<button type="button" id="dialog" class="btn btn-block btn-secondary" (click)="onCancel()">{{'CANCEL' | translate}}</button>
		</div>
	  </section>
		</div>
</div>
