<div class="container">
	<div class="pt-2">
		<h1 class="page-title">{{heading1}}</h1>
	</div>
	<div  *ngIf="!isKbUser" class="hover-red">
		<a class="hover-red btn btn-link" (click)="onPrevious()">
			<svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
				<g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
					<path id="Combined_Shape" data-name="Combined Shape"
						d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
						transform="translate(0)" fill="#174a7c" />
				</g>
			</svg>
			<span class="pl-2">Back to TennCare Access Welcome Page</span>
		</a>
	</div>
	<div class="container">
		<div class="row py-4">
			<div class="card">
				<div class="page_label p-4">
					<h5 class="section-header">{{heading2}}</h5><hr>
					<p>{{heading3}}</p>
					<nh-alert type="warning" [isOpen]="bccTreatment && duplicateWarning">
						<div class="row">
						  <div class="icon">
							<img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
						  </div>
						  <div class="message">
							<span>A BCC Treatment Plan Extension request was already submitted for this member on {{submittedDate}}. Do you want to submit another request?</span>
						  </div>
						</div>
					  </nh-alert>
					  <br>
					<form [formGroup]="memberSearchForm">
						<div class="form-row">
							<div class="col-sm-12 col-md-6">
								<nh-field label="Date of Birth" class="label-section">
									<nh-datepicker>
										<input id = "ms_dateOfBirth" type="text" class="form-control" [attr.disabled]="isKbUser ? true : null" name="dateOfBirth"
											formControlName="dateOfBirth" aria-label="dateOfBirth" placeholder="mm/dd/yyyy" [minDate]="minimumDate" [maxDate]="today"
											bsDatepicker nh required>
									</nh-datepicker>
								</nh-field>
							</div>
						</div>

						<div class="form-row">
							<div class="col-5 col-md-5 d-none d-sm-block">
								<p class="and-border"></p>
							</div>
							<div class="col-md-2 col-sm-12">
								<p class="text-center"><strong>AND</strong></p>
							</div>
							<div class="col-5 col-md-5 d-none d-sm-block">
								<p class="and-border"></p>
							</div>
						</div>
						<div class="form-row">
							<div class="col-sm-12 col-md-3">
								<nh-field id = "ms_dateOfBirth" [mandatory]="true" [isRow]="true" label="Social Security Number" class="label-section">
									<input aria-label="ssn" type="text" name="ssn" formControlName="ssn" class="form-control" placeholder="XXX-XX-XXXX" mask="000-00-0000">
								</nh-field>
							</div>
							<div class="col-sm-12 col-md-1">
								<p class="text-center pt-4"><strong>OR</strong></p>
							</div>
							<div class="col-sm-12 col-md-5">
								<nh-field id = "ms_dateOfBirth" [mandatory]="true" label="Person ID" class="label-section">
									<input aria-label="personId" type="text" [attr.disabled]="isKbUser ? true : null" name="personID"
										formControlName="personID" class="form-control" mask="9999999999">
								</nh-field>
							</div>
						</div>
						<div class="form-row py-2">
							<div class="d-flex">
								<div>
									<nh-checkbox name="applicantBehalf" [control]="memberSearchForm.controls['applicantBehalf']" checkedValue='checked' [mandatory]="false"></nh-checkbox>
								</div>
								<div>
									<span *ngIf="this.bccTreatment" class="requiredfd">*</span>{{heading4}}
								</div>
							</div>
						</div>
					</form>
					<br>
					<div class="form-row">
						<div *ngIf= "!imeSubmission"class="col-12 col-md-3 py-2 py-md-0 order-md-1">
							<button class="btn btn-secondary" (click)="onSearch()" style="text-transform: initial"
							[disabled]="memberSearchForm.invalid">
								Search&nbsp; <i class="fa fa-search"></i>
							</button>
						</div>
						<div *ngIf= "imeSubmission"class="col-12 col-md-3 py-2 py-md-0 order-md-1">
							<button class="btn btn-secondary" (click)="onSearch()" style="text-transform: initial"
							[disabled]="memberSearchForm.invalid">
								Search Member&nbsp; <i class="fa fa-search"></i>
							</button>
						</div>
					</div>
					<br>

					<div *ngIf="rows && isSearchCompleted">
						<h5 class="section-header">Search Results</h5><hr>

						<ngx-datatable *ngIf="rows && !imeSubmission" class='bootstrap striped' #table [rows]='rows' columnMode="force" [headerHeight]='50' [footerHeight]='50' [rowHeight]='"auto"' [limit]="5">
						<ngx-datatable-column [name]="col.name" [prop]="col.prop" *ngFor="let col of columns"></ngx-datatable-column>
						<ngx-datatable-column name="Select" [width]="180">
							<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
								<div class="d-flex">
									<a *ngIf="this.isFinReacess && isSearchCompleted && isMedicaidEligible" class="view-tennCare-title">
										<span (click)="startReassessment(row)">Start Financial Reassessment for Medicaid</span>
									</a>
									<div *ngIf="this.isFinReacess && isSearchCompleted && !isMedicaidEligible">
										<p><strong>Stop</strong> This form can't be submitted because this person is not receiving 
											Institutional Medicaid coverage. Fill out an application and tell us this person needs nursing home care.
										</p>
									</div>
									<a *ngIf="this.bccTreatment && isSearchCompleted && isMedicaidEligible" class="view-tennCare-title">
										<span (click)="startBccExtension(row)">Start BCC Treatment Plan Extension Request</span>
									</a>
									<div *ngIf="this.bccTreatment && isSearchCompleted && !isMedicaidEligible">
										<p>This form can’t be submitted because this person is not pending for BCC Coverage. Fill out an application and tell us this person needs BCC Coverage.
										</p>
									</div>
									<a *ngIf="!this.isFinReacess && !this.bccTreatment" class="view-tennCare-title">
										<span (click)="toTenncare(row.personId)">View TennCare Connect</span>
									</a>&nbsp;&nbsp;
									<span *ngIf="isMedicaidEligible || (!this.isFinReacess && !this.bccTreatment)"><i class="fa fa-external-link view-tennCare-title"></i></span>
								</div>
							</ng-template>
						</ngx-datatable-column>
						</ngx-datatable>
						<ngx-datatable *ngIf="rows && imeSubmission" class='bootstrap striped' #table [rows]='rows' columnMode="force" [headerHeight]='50' [footerHeight]='50' [rowHeight]='"auto"' [limit]="5">
							<ngx-datatable-column [name]="col.name" [prop]="col.prop" *ngFor="let col of imeColumns1"></ngx-datatable-column>
							<ngx-datatable-column name="Living Arrangement" [width]="170">
								<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
									<div>
										<p class="living">{{ row.livingArrangement}} </p>		
									</div>
								</ng-template>
							</ngx-datatable-column>
							<ngx-datatable-column [name]="col.name" [prop]="col.prop" *ngFor="let col of imeColumns2"></ngx-datatable-column>
							<ngx-datatable-column name="Select" [width]="170">
								<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
									<div class="d-flex">
										<a *ngIf="this.imeSubmission && isSearchCompleted && row.isMedicaidEligible" class="view-tennCare-title">
											<span (click)="startIMEApplication(row)">Incurred Medical Expenses</span>
										</a>&nbsp;&nbsp;
										<span *ngIf="this.imeSubmission && isSearchCompleted && row.isMedicaidEligible"><i class="fa fa-external-link view-tennCare-title"></i></span>
										<div *ngIf="this.imeSubmission && isSearchCompleted && !row.isMedicaidEligible">
											<p class="living">This form can’t be submitted for this case because this person is not eligible to add Incurred Medical Expenses.
											</p>
										</div>				
									</div>
								</ng-template>
							</ngx-datatable-column>
							</ngx-datatable>
						<ngx-datatable-footer>
							<ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount"
								let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
								<div class="container" ></div>
							</ng-template>
						</ngx-datatable-footer>
					</div>

					<div class="pull-right" *ngIf="rows && rows.length">
						<p>Results Shown {{rows.length}}</p>
					</div>
					<div *ngIf="rows && !rows.length">
						<p *ngIf="!this.isFinReacess && !bccTreatment && !imeSubmission">Your search did not return any results. Please try different criteria and search again.</p>
						<p *ngIf="this.isFinReacess">We could not find the person you searched for. If this person is not enrolled in TennCare, please <a class="btn-link afb-btn" (click)="startApp('AFB')">Apply For Coverage</a>.</p>
						<p *ngIf="bccTreatment">We could not find the person you searched for. If this person is not enrolled in TennCare, please complete the <a class="btn-link afb-btn" (click)="startApp('BCC')"><strong>Presumptive BCC Application.</strong></a></p>
						<p *ngIf="imeSubmission && !showTable">We could not find the person you searched for. If this person is not enrolled in TennCare, please complete the <a class="btn-link afb-btn" (click)="startApp('AFB')"><strong>Full Coverage Application.</strong></a></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>