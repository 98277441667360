import { Component, AfterContentInit, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { UserProfileService } from "@app/gateway/user-profile/user-profile.service";
import { ModalService } from "@app/services/modal/modal.service";
import { ApplicationService } from "@app/services/application/application.service";
import Vs from '@app/services/validators/validators.service';
import { removeAt } from '@app/helpers/form-array.helpers';
import { minus120Years } from '@app/helpers/date.helpers';
@Component({
  templateUrl: './incurredMedicalExpDetails.component.html',
  styleUrls: ["./incurredMedicalExpDetails.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class IncurredMedicalExpDetails extends SectionFormDirective implements AfterContentInit {

  options;
  documents = []
  code = [];
  error = true;
  showCZPRmessage = false;
  isMobileView = false;
  mobileWidth = 480;
  screenHeight: number;
  screenWidth: number;
  displayLoading = false;
  docName: string;
  imeDetails: FormGroup;
  payFreq;
  medicalExpense;
  showAddAnother = false;
  noError = false;
  minimumDate = minus120Years();


  constructor(public builder: FormBuilder, private titleService: Title, private userProfileService: UserProfileService, private modalService: ModalService, private application: ApplicationService) {
    super(builder)
    this.titleService.setTitle('Incurred Medical Expenses Details');
  };

  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
  ngDoCheck() {
    this.isMobileView = this.screenWidth <= this.mobileWidth;
  }

  get documentList(): FormArray {
    return this.getControl('data.documentList') as FormArray;
  }

  close(i: any) {
    this.documents.splice(i, 1);
    removeAt(i, this.documentList);
    this.addDocbuttonVisibility();
    this.showAddAnother = true;
  };


  checkDocumentUploadError () {
    this.noError = sessionStorage.getItem('fileUploadError') ? JSON.parse(sessionStorage.getItem('fileUploadError')) : false;
    return this.noError
  }


  viewPdf(i) {
    if(Number(this.documents[i].file.res)) {
      this.documents[i].file.res = this.documents[i].file.res.toString()
    }
    const docId = this.documents[i].file.res.replace(/[{}]/g, "");
    this.userProfileService.getPrintDoc(docId).subscribe((res: any) => {
      const file = new Blob([res], { type: 'application/pdf' });
      let newVariable: any;
      newVariable = window.navigator;
      if (newVariable && newVariable.msSaveOrOpenBlob) {
        newVariable.msSaveOrOpenBlob(file);
      } else {
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }

    });
  }

  removeItem(i) {
    this.modalService.confirmDelete().subscribe((res) => {
      if (res) {
        const applicationNum = sessionStorage.getItem('appNum');
        const postObj = { appNum: applicationNum , docId: this.documents[i]['file']['res'], sectionID: 'IMEDT' };
        this.application.deleteImeDocument(postObj).subscribe(() => {})
        this.documents[i].file = null;
        if(this.documents?.length == 1 && this.documents.find(i => i.file === null)) {
          this.documents = [{ docType: null, show: true, file: null, error: false }];
      this.showAddAnother = false;
          this.documents[i].show = true;         
        }       
        this.addDocbuttonVisibility();
        if(this.documents?.length > 1) { 
          this.showAddAnother = true;
          this.documents.splice(i, 1);
          removeAt(i, this.documentList);
        } else {
        this.showAddAnother = false;
        const documentListCtrls = this.documentList['controls'];
        const documentControls = documentListCtrls[i]['controls'];
        documentControls['documentId'].patchValue(null);
        documentControls['documentId'].updateValueAndValidity()
        documentControls['documentName'].patchValue(null);
        documentControls['documentName'].updateValueAndValidity()
        documentControls['documentFormatCd'].patchValue(null);
        documentControls['documentFormatCd'].updateValueAndValidity()
        }
      }
    });
  }

  getFileDetails(fileData, i) {
    if (fileData.res !== null) {
      this.documents[i].show = false;
      this.documents[i].file = { ...fileData.fileRes.file, ...{ res: fileData.res } };
        const documentListCtrls = this.documentList['controls'];
        const documentControls = documentListCtrls[i]['controls'];
        documentControls['documentId'].patchValue(fileData.res);
        documentControls['documentId'].updateValueAndValidity()
        documentControls['documentName'].patchValue(fileData.fileRes.file.name);
        documentControls['documentName'].updateValueAndValidity()
        documentControls['documentFormatCd'].patchValue(fileData.fileRes.file.type);
        documentControls['documentFormatCd'].updateValueAndValidity()
      this.addDocbuttonVisibility();
      this.showAddAnother = true
    }
  }

  addAnother() {
    this.documents.push({
      docType: null,
      show: true,
      file: null,
      error: false,
    });
    const createDocumentCtrls = () => { 
      return this.builder.group({
        documentId: [null, Vs.required],
        documentName: [null, Vs.required],
        documentFormatCd: [null, Vs.required],
      });
    };
    this.showAddAnother = false;
    this.documentList.push(createDocumentCtrls());
    this.addDocbuttonVisibility();
  }


  addDocbuttonVisibility() {
    this.error = this.documents.some(doc => doc.file == null);
  }


  getFileProgress(event) {
    if (event) {
      this.displayLoading = event.displayLoading;
      this.docName = event.fileName;
    } else {
      this.displayLoading = false;
      this.docName = null;
    }
  }

  onPayFrequencyChange() {
    this.payFreq = this.getValue('data.payFrequency')
    if (this.payFreq.code) {
      this.getControl('data.payFrequencyCd').patchValue(this.payFreq.code);
      this.getControl('data.payFrequencyCd').updateValueAndValidity()
    } else {
      this.getControl('data.payFrequencyCd').reset();
      this.getControl('data.payFrequencyCd').updateValueAndValidity()
    }
  }

  onMedicalExpenseChange() {
    this.medicalExpense = this.getValue('data.medicalExpenseType');
    if (this.medicalExpense.code) {
      this.getControl('data.medicalExpenseTypeCd').patchValue(this.medicalExpense.code);
      this.getControl('data.medicalExpenseTypeCd').updateValueAndValidity()
    } else {
      this.getControl('data.medicalExpenseTypeCd').patchValue(this.medicalExpense.code)
      this.getControl('data.medicalExpenseTypeCd').updateValueAndValidity()
    }
  }

  ngAfterContentInit() {
    if(sessionStorage.getItem('removedIMERecord')) {
      sessionStorage.removeItem('removedIMERecord')
    }
    let docListArray = this.getValue('data.documentList')
    if(docListArray.length && docListArray[0]['documentName'] !== null) {
      this.showAddAnother = true;
      docListArray.forEach((element) => {
        const temp = { docType: null, show: false, file: {
          'name' : element['documentName'],
          'res' : element['documentId']
        }, error: false };
        if(docListArray.length === 1) {
          this.documents = [{ docType: null, show: false, file: null, error: false }];
          this.documents[0]['show'] = false,
          this.documents[0]['file'] = {
            'name' : docListArray[0]['documentName'],
            'res' : docListArray[0]['documentId']
          }
        } else {
          this.documents.push(temp);
        }
        
      })
    } else {
      this.documents = [{ docType: null, show: true, file: null, error: false }];
      this.showAddAnother = false;
    }
  }
}