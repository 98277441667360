import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServicesModule } from '@app/services/services.module';
import { ComponentsModule } from '@app/components/components.module';

import { CongratsResolve } from './congrats.resolve';
import { CongratsComponent } from './congrats.component';
import { RmcUploadDocument } from './rmc-upload-documents/rmc-upload-documents.component';

const entryComponents = [CongratsComponent, RmcUploadDocument];

@NgModule({
  imports: [CommonModule, ServicesModule, ComponentsModule],
  exports: entryComponents,
  declarations: entryComponents,
  providers: [CongratsResolve],
  entryComponents,
})
export class CongratsModule {}
