import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, CurrencyPipe } from '@angular/common';

import { identity } from 'ramda';
import { propOr } from 'ramda';
import { contains } from 'ramda';

import { stubString } from 'ramda-adjunct';

import { IndividualPipe } from '@app/pipes/individual/individual.pipe';
import { FullNamePipe } from '@app/pipes/fullname/fullname.pipe';
import { DomainPipe } from '@app/pipes/domain/domain.pipe';
import { BinaryPipe } from '@app/pipes/binary/binary.pipe';
import { PhonePipe } from '@app/pipes/phone/phone.pipe';
import { TimePipe } from '@app/pipes/time/time.pipe';
import { SSNPipe } from '@app/pipes/ssn/ssn.pipe';

import { ifNil } from '@app/helpers/null.helpers';

import { PipeToken } from '@app/models/section.model';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'loader' })
export class LoaderPipe implements PipeTransform {
  private pipes: any;

  public constructor(
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private phonePipe: PhonePipe,
    private ssnPipe: SSNPipe,
    private individualPipe: IndividualPipe,
    private fullNamePipe: FullNamePipe,
    private binaryPipe: BinaryPipe,
    private timePipe: TimePipe,
    private domainPipe: DomainPipe,
    public translateService: TranslateService
  ) {
    this.pipes = {
      date: this.datePipe,
      currency: this.currencyPipe,
      phone: this.phonePipe,
      ssn: this.ssnPipe,
      individual: this.individualPipe,
      fullname: this.fullNamePipe,
      binary: this.binaryPipe,
      time: this.timePipe,
      domain: this.domainPipe,
    };
  }

  share(token) {
    return contains(token, ['date', 'currency']) ? undefined : this.pipes;
  }

  pipe(pipe) {
    return propOr({ transform: identity }, pipe, this.pipes);
  }

  transform(value: any, token: PipeToken, args: any): any {
    const result = this.pipe(token).transform(value, args, this.share(token));
    let finalResult;
    if (result && typeof result == 'object' && result.length > 0) {
      if (this.translateService.getDefaultLang() !== 'en') {
        finalResult = result[1];
      } else {
        finalResult = result[0];
      }
    } else {
      finalResult = result;
    }

    return ifNil(stubString, finalResult);
  }
}
