<div class="mb-3">
	<nh-alert type="success" [isOpen]="appealsService.showMessageWithdrawal"
		*ngIf="appealsService.messageWithdrawal == 'SUCCESS_CONTINUANCE'">
		<div *ngIf="appealsService.messageWithdrawal == 'SUCCESS_CONTINUANCE'" class="message">
			<span>{{ 'WITHDRAWAL_SUBMITTED' | translate}}</span>
		</div>
	</nh-alert>
	<nh-alert type="warning" [isOpen]="appealsService.showMessageWithdrawal"
		*ngIf="appealsService.messageWithdrawal == 'ERROR_MESSAGE'">
		<div *ngIf="appealsService.messageWithdrawal == 'ERROR_MESSAGE'" class="message">
			<span>{{ 'ERROR_WITHDRAWL_SUBMIT' | translate}}</span>
		</div>
	</nh-alert>
	<div class="bg-custom">
		<div class="container">
			<div *ngIf="memberInfo">
				<h5 class="section-header">{{'WITHDRAWAL_DETAILS' | translate}}</h5>
				<hr>
				<div>
					<p class="mb-4">{{'WITHDRAWAL_MSG' | translate}}</p>
				</div>
				<div>
					<p class="mb-0 text-color"><strong>{{'NAME_OF_REQUESTER_WITHDRAWAL' |
							translate}}</strong><strong>{{": "}}</strong>{{ memberInfo.requesterName }}</p>
					<br>
					<p class="mb-0 text-color"><strong>{{'APPEAL_NUMBER_WITHDRAWAL' | translate}}</strong>
						<strong>{{": "}}</strong>{{ memberInfo.appealNumber }}</p>
					<br>
					<p class="mb-0 text-color"><strong>{{'APPEAL_REASON_WITHDRAWAL' | translate}}</strong>
						<strong>{{": "}}</strong>{{ memberInfo.appealReasonDescription }}</p>
					<br>
					<p class="mb-0 text-color"><strong>{{'APPEAL_STATUS_WITHDRAWAL' | translate}}</strong>
						<strong>{{": "}}</strong>{{ memberInfo.appealStatusDesc }}</p>
					<br>
					<p class="mb-0 text-color"><strong>{{'COVERAGE_TYPE_WITHDRAWAL' | translate}}</strong>
						<strong>{{": "}}</strong>{{ memberInfo.continueBenefitsDescription }}</p>
					<br>


					<form [formGroup]="appealWithdrawalForm">
						<nh-field class="textcolor" id="app_con_submittedby" [mandatory]="true"
							label="{{'SUBMITTEDBY_CONTINUANCE' | translate}}">
							<div>
								<p class="note_padding">{{'WITHDRAW_NOTE_SUBMITTEDBY' | translate}}</p>
							</div>
							<input type="text" class="form-control" name="submittedBy"
									formControlName="submittedBy" aria-label="submittedBy"
									autofocus="true" (input)="submittedValidator($event)" maxlength="2000">
						</nh-field>
						<p></p>

						<nh-field [isRow]="true" [mandatory]="false" label="{{'PHONE_NUMBER_WITHDRAWAL' | translate}}">
							<input type="text" name="phoneNumber" formControlName="phoneNumber"
								class="form-control mt-2" mask="000-000-0000" placeholder="XXX-XXX-XXXX">
						</nh-field>

						<nh-field class="textcolor" id="app_con_relationship" [mandatory]="true"
							label="{{'REQUEST_RELATIONSHIP_CONTINUANCE' | translate}}">
								<div>
									<p class="rel_pad">{{'WITHDRAW_NOTE_RELATIONSHIP' | translate}}</p>
								</div>
							<nh-count>
								<textarea maxlength="2000" class="form-control" name="relationshipWithAppellant"
									formControlName="relationshipWithAppellant" aria-label="relationshipWithAppellant"
									rows="5" autofocus="true">
								</textarea>
							</nh-count>
						</nh-field>

						<nh-field class="textcolor" id="app_con_comments" [mandatory]="true"
							label="{{'REQUEST_COMMENTS_WITHDRAWAL' | translate}}">
							<nh-count>
								<textarea maxlength="2000" class="form-control" name="reasonForWithdrawal"
									formControlName="reasonForWithdrawal" aria-label="reasonForWithdrawal" rows="5"
									autofocus="true">
							</textarea>
							</nh-count>
						</nh-field>

						<div>
							<p style="padding-bottom: 1rem"><strong>{{'NOTE_WITHDRAWAL' |
									translate}}</strong><strong>{{': '}}</strong>{{'WITHDRAWAL_MSG_1' | translate}}</p>
						</div>

						<div>
							<nh-field class="textcolor" id="app_con_comments" label [mandatory]="false"
								label="{{'Appellant_Signature' | translate}}"></nh-field>
							<div class="checkbox-label">
								<input type="checkbox" name="appellantSignSW" formControlName="appellantSignSW"
									checkedValue="E" (change)=deselect($event)>
								<p style="padding-left: 15px" [innerHTML]="'Withdrawal_Checkbox' | translate"></p>
							</div>
							<div>
								<nh-field id="appellantSign" label="{{'SIGNATURE' | translate}}" class="label-section" [mandatory]="true">
									<input type="text" class="form-control" name="appellantSign" autofocus="true"
										formControlName="appellantSign" aria-label="appellantSign" maxlength="50" (input)="signatureValidator($event)">
								</nh-field>
							</div>
						</div>

					</form>

					<div class="row my-1">
						<div class="col-md-12">
							<div class="row mt-3 text-center py-3" style="padding-top: 0.5rem !important">
								<div class="ml-3">
									<button id="continue" class="btn btn-block btn-secondary btn-width"
										[disabled]="appealsService.showMessageWithdrawal"
										(click)="onSubmit(this.memberInfo, this.appealWithdrawalForm.controls, this.translatedLang)"
										[disabled]="!isFormValid()">
										{{'SUBMIT_WITHDRAWAL' | translate}}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row my-5">
		<div class="col-md-12">
			<div class="row mt-3 text-center py-4 withdrawl-exit">
				<div class="ml-3">
					<button id="continue" class="btn btn-block btn-secondary btn-width"
						(click)="goToAppealsDashboard()">
						{{'EXIT' | translate}}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>