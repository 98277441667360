import { SummaryComponent } from './summary.component';

import { sectionConfiguration } from '@app/helpers/models.helpers';

const formValidation = (fb, recordData) => ({ data: fb.group(recordData) });

export default sectionConfiguration(SummaryComponent, formValidation, [], [], {}, {
  hasSummary: false,
  showComments: true,
  showSubmit: false,
});
