<p *ngIf="isRmbOnly && isNotAdded && !isMedBillCarryFwSw" [innerHTML]="'START2_STATIC_TEXT_11' | translate"></p>
<p *ngIf="isMedBillCarryFwSw" [innerHTML]="'START2_STATIC_TEXT_11_NEW' | translate"></p>
<h6 class="person-label">{{selectedIndividual | fullname}}</h6>
<nh-form [topLevelName] = "'me'" [individuals]="checkForDropdown ? finalListAll : individuals" [formGroup]="getForm()" [mode]="mode" class="labe-section"
  topLevelQuestion="{{'MEDICAL_BILLS_STATIC_TEXT1' | translate }}" individualQuestion="{{this.individualQuestion}}"
  (validate)="onValidate($event)" *ngIf="!isMedBillCarryFwSw">

     <div [formGroup]="getControl('data')">
      <nh-field id = "me_kbMedicalBillsSw" *ngIf="(getValue('extras.topLevelAnswer') === 'YES') && (this.showKbQuestion)"
        label="{{ kbReferralQuestion }}" class="label-section">
        <nh-popover style="padding-left: 3px;" content="{{'MEDICAL_BILLS_TOOLTIP' | translate }}"></nh-popover>
        <select nh name="kbMedicalBillsSw" formControlName="kbMedicalBillsSw" aria-label="kbMedicalBillsSw"
          [items]="tableCodeValue('YESNO', 'data.kbMedicalBillsSw')" (change)="setValidations($event)"></select>
      </nh-field>
    </div>

    <div [formGroup]="getControl('data')" *ngIf="(isY('data.kbMedicalBillsSw')) || (!this.showKbQuestion)" >
   <h5 class="section-header">{{'MEDICAL_BILLS_STATIC_TEXT2' | translate }}</h5>
    <hr>
    <nh-field id = "me_medicalBillType"
      label="{{'MEDICAL_BILLS_STATIC_TEXT3_1' | translate }} {{this.selectedIndividual | fullname }} {{'MEDICAL_BILLS_STATIC_TEXT4' | translate }}"
      class="label-section">
      <div class="hint">{{'IF' | translate }} {{this.selectedIndividual | fullname }} <span class="hint"
          [innerHTML]="this.medicalBillsHint">{{this.medicalBillsHint}}</span></div>
      <select nh name="medicalBillType" formControlName="medicalBillType" aria-label="medicalBillType" [items]="tableCodeValue('MEDNEEDYEXPENSES', 'data.medicalBillType')"
        (change)="showHide()"></select>
    </nh-field>

     <div class="row pl-1 pt-1 hover-red">
      <a class="btn btn-link hover-red" (click)="showModal()">
        <span class="pr-2">{{'MEDICAL_BILLS_STATIC_TEXT5' | translate }}</span>
        <span><i class="fa fa-external-link"></i></span>
      </a>
    </div>

    <nh-field id = "me_careReceivingIndvNumber"
      label="{{'MEDICAL_BILLS_STATIC_TEXT6' | translate }} {{medicalBillType.value? medicalBillType.value.value: ''}}{{'MEDICAL_BILLS_STATIC_TEXT7' | translate }}"
      class="label-section">
      <select nh name="careReceivingIndvNumber" aria-label="careReceivingIndvNumber" [items]="indi" bindValue="identifier" bindLabel="name.fullName"
        formControlName="careReceivingIndvNumber" (change)="setIndiv()"></select>
    </nh-field>

     <nh-field id = "me_personOutOfHouseholdName" *ngIf="isEnabled('data.personOutOfHouseholdName')" label="{{'MEDICAL_BILLS_STATIC_TEXT8' | translate }}"
      class="label-section">
      <input type="text" class="form-control" name="personOutOfHouseholdName"
        formControlName="personOutOfHouseholdName">
    </nh-field>

    <nh-field id = "me_relationPersonOutsideHH" *ngIf="isEnabled('data.relationPersonOutsideHH')"
      label="{{'MEDICAL_BILLS_STATIC_TEXT9' | translate }} {{personOutOfHouseholdName.value}} {{'MEDICAL_BILLS_STATIC_TEXT10' | translate }} {{head | fullname}}"
      class="label-section">
      <select nh name="relationPersonOutsideHH" formControlName="relationPersonOutsideHH"
      [items]="tableCodeValue('RELATIONSHIPTOINDIVIDUAL', 'data.relationPersonOutsideHH')"></select>
    </nh-field>

    <nh-field id = "me_billPayingFrequencycd" 
      label="{{'MEDICAL_BILLS_STATIC_TEXT11' | translate }} {{medicalBillType.value? medicalBillType.value.value: ''}}{{'MEDICAL_BILLS_STATIC_TEXT12' | translate }}"
      class="label-section">
      <select nh name="billPayingFrequencycd" formControlName="billPayingFrequencycd" aria-label="billPayingFrequencycd"
      [items]="tableCodeValue('INCEXPPAYFREQUENCY', 'data.billPayingFrequencycd')"></select>
    </nh-field>

    <nh-field id = "me_careReceiveDate"
      label="{{'MEDICAL_BILLS_STATIC_TEXT13_1'| translate }} {{this.selectedIndividual | fullname }} {{'MEDICAL_BILLS_STATIC_TEXT14'| translate }}"
      class="label-section">
      <nh-datepicker>
        <input type="text" name="careReceiveDate" class="form-control" formControlName="careReceiveDate"
          [maxDate]="today" bsDatepicker (bsValueChange)="toggleAmountFields($event)" nh (change)="toggleAmountFields($event)">
      </nh-datepicker>
    </nh-field>

    <nh-field  id = "me_medicalBillUnpaidAmt" *ngIf="isEnabled('data.medicalBillUnpaidAmt')"
    label="{{'MEDICAL_BILLS_STATIC_TEXT15_1' | translate }} {{selectedIndividual | fullname}}'s {{medicalBillType.value? medicalBillType.value.value: ''}} {{'MEDICAL_BILLS_STATIC_TEXT16' | translate }}"
      class="label-section">
      <nh-input-group prepend="$">
        <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" aria-label="medicalBillUnpaidAmt" formControlName="medicalBillUnpaidAmt" class="form-control">
      </nh-input-group>
    </nh-field>

    <nh-field id = "me_medicalBillOweAmt" [mandatory]="true" *ngIf="isEnabled('data.medicalBillOweAmt') && dateMoreThan3Months"
      label="{{'MEDICAL_BILLS_STATIC_TEXT17' | translate }} {{medicalBillType.value? medicalBillType.value.value: ''}} {{ 'MEDICAL_BILLS_STATIC_TEXT18_1' | translate }}"
      class="label-section">
      <nh-input-group prepend="$">
        <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" aria-label="medicalBillOweAmt" formControlName="medicalBillOweAmt" class="form-control">
      </nh-input-group>
    </nh-field>

    <nh-field id = "me_medicalBillOweAmt" [mandatory]="true" *ngIf="isEnabled('data.medicalBillOweAmt') && !dateMoreThan3Months"
      label="{{'MEDICAL_BILLS_STATIC_TEXT17_1' | translate }} {{medicalBillType.value? medicalBillType.value.value: ''}} {{ 'MEDICAL_BILLS_STATIC_TEXT22_1' | translate }}"
      class="label-section">
      <nh-input-group prepend="$">
        <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" aria-label="medicalBillOweAmt" formControlName="medicalBillOweAmt" class="form-control">
      </nh-input-group>
    </nh-field>

    <nh-field id = "me_milesPerAppointment" [mandatory]="false" *ngIf="isEnabled('data.milesPerAppointment')"
      label="{{'MEDICAL_BILLS_STATIC_TEXT19' | translate }} {{otherSelectedIndividual!== ''? otherSelectedIndividual : personOutOfHouseholdName.value}} {{'MEDICAL_BILLS_STATIC_TEXT20' | translate }}"
      class="label-section">
      <nh-popover class="pl-1" content="{{'MEDICAL_BILLS_STATIC_TEXT21' | translate }}"></nh-popover>
      <input type="text" class="form-control" formControlName="milesPerAppointment">
    </nh-field>
  </div>
</nh-form>


<!--  when you click edit on existing bill these are set of question visible-->

<nh-form [topLevelName] = "'me'" [individuals]="checkForDropdown ? finalListAll : individuals" [formGroup]="getForm()" [mode]="mode" class="labe-section"
  topLevelQuestion="{{'MEDICAL_BILLS_STATIC_TEXT1' | translate }}" individualQuestion="{{this.individualQuestion}}"
  (validate)="onValidate($event)" *ngIf="isMedBillCarryFwSw && isRmbOnly">

    <div [formGroup]="getControl('data')" *ngIf="(isY('data.kbMedicalBillsSw')) || (!this.showKbQuestion)" >
   <h5 class="section-header">{{'MEDICAL_BILLS_STATIC_TEXT2' | translate }}</h5>
    <hr>
    <nh-field id = "me_medicalBillType"
      label="{{'MEDICAL_BILLS_STATIC_TEXT3' | translate }} {{this.selectedIndividual | fullname }} {{'MEDICAL_BILLS_STATIC_TEXT4' | translate }}"
      class="label-section">
      <div class="hint">{{'IF' | translate }} {{this.selectedIndividual | fullname }} <span class="hint"
          [innerHTML]="this.medicalBillsHint">{{this.medicalBillsHint}}</span></div>
      <select nh name="medicalBillType" formControlName="medicalBillType" aria-label="medicalBillType" [items]="tableCodeValue('MEDNEEDYEXPENSES', 'data.medicalBillType')"
        (change)="showHide()" [attr.disabled]="true"></select>
    </nh-field>

     <div class="row pl-1 pt-1 hover-red">
      <a class="btn btn-link hover-red" (click)="showModal()">
        <span class="pr-2">{{'MEDICAL_BILLS_STATIC_TEXT5' | translate }}</span>
        <span><i class="fa fa-external-link"></i></span>
      </a>
    </div>

    <nh-field id = "me_careReceivingIndvNumber"
      label="{{'MEDICAL_BILLS_STATIC_TEXT6' | translate }} {{medicalBillType.value? medicalBillType.value.value: ''}}{{'MEDICAL_BILLS_STATIC_TEXT7' | translate }}"
      class="label-section">
      <select nh name="careReceivingIndvNumber" aria-label="careReceivingIndvNumber" [items]="indi" bindValue="identifier" bindLabel="name.fullName"
        formControlName="careReceivingIndvNumber" (change)="setIndiv()" [attr.disabled]="true"></select>
    </nh-field>

     <nh-field id = "me_personOutOfHouseholdName" *ngIf="isEnabled('data.personOutOfHouseholdName')" label="{{'MEDICAL_BILLS_STATIC_TEXT8' | translate }}"
      class="label-section">
      <input type="text" class="form-control" name="personOutOfHouseholdName"
        formControlName="personOutOfHouseholdName" [attr.disabled]="true">
    </nh-field>

    <nh-field id = "me_relationPersonOutsideHH" *ngIf="isEnabled('data.relationPersonOutsideHH')"
      label="{{'MEDICAL_BILLS_STATIC_TEXT9' | translate }} {{personOutOfHouseholdName.value}} {{'MEDICAL_BILLS_STATIC_TEXT10' | translate }} {{head | fullname}}"
      class="label-section">
      <select nh name="relationPersonOutsideHH" formControlName="relationPersonOutsideHH"
      [items]="tableCodeValue('RELATIONSHIPTOINDIVIDUAL', 'data.relationPersonOutsideHH')" [attr.disabled]="true"></select>
    </nh-field>

    <!-- <nh-field id = "me_billPayingFrequencycd" 
      label="{{'MEDICAL_BILLS_STATIC_TEXT11' | translate }} {{medicalBillType.value? medicalBillType.value.value: ''}}{{'MEDICAL_BILLS_STATIC_TEXT12' | translate }}"
      class="label-section">
      <select nh name="billPayingFrequencycd" formControlName="billPayingFrequencycd" aria-label="billPayingFrequencycd"
      [items]="tableCodeValue('INCEXPPAYFREQUENCY', 'data.billPayingFrequencycd')" [attr.disabled]="true"></select>
    </nh-field> -->

    <nh-field id = "me_careReceiveDate"
      label="{{'MEDICAL_BILLS_STATIC_TEXT13' | translate }}"
      class="label-section">
      <nh-datepicker>
        <input type="text" [attr.disabled]="true" name="careReceiveDate" class="form-control" formControlName="careReceiveDate"
          [maxDate]="today" bsDatepicker (bsValueChange)="toggleAmountFields($event)" nh (change)="toggleAmountFields($event)">
      </nh-datepicker>
    </nh-field>

    <nh-field  id = "me_medicalBillUnpaidAmt" *ngIf="isEnabled('data.medicalBillUnpaidAmt')"
    label="{{'MEDICAL_BILLS_STATIC_TEXT15' | translate }} {{(translatedLang === 'en' ? languageUpdate : medicalBillType.value? medicalBillType.value.value: '')}} {{(translatedLang === 'en' ? 'MEDICAL_BILLS_STATIC_TEXT16_1' : '') | translate }} 
    {{translatedLang === 'en' && medicalBillType.value? medicalBillType.value.value: ''}} {{(translatedLang === 'en' ? 'MEDICAL_BILLS_STATIC_TEXT16' : '') | translate }} {{(translatedLang === 'sp' ? ('de ' + languageUpdate) + '?' : '')}}"
      class="label-section">
      <nh-input-group prepend="$">
        <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" aria-label="medicalBillUnpaidAmt" formControlName="medicalBillUnpaidAmt" class="form-control" [attr.disabled]="true">
      </nh-input-group>
    </nh-field>

    <!-- <nh-field id = "me_medicalBillOweAmt" [mandatory]="true" *ngIf="isEnabled('data.medicalBillOweAmt') && dateMoreThan3Months"
      label="{{'MEDICAL_BILLS_STATIC_TEXT17' | translate }} {{medicalBillType.value? medicalBillType.value.value: ''}} {{ 'MEDICAL_BILLS_STATIC_TEXT18' | translate }}"
      class="label-section">
      <nh-input-group prepend="$">
        <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" aria-label="medicalBillOweAmt" formControlName="medicalBillOweAmt" class="form-control" [attr.disabled]="true">
      </nh-input-group>
    </nh-field> -->

    <nh-field id = "me_medicalBillOweAmt" [mandatory]="true" *ngIf="isEnabled('data.medicalBillOweAmt')"
      label="{{'MEDICAL_BILLS_STATIC_TEXT17' | translate }} {{medicalBillType.value? medicalBillType.value.value: ''}} {{ 'MEDICAL_BILLS_STATIC_TEXT22' | translate }}"
      class="label-section">
      <nh-input-group prepend="$">
        <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" aria-label="medicalBillOweAmt" formControlName="medicalBillOweAmt" class="form-control" [attr.disabled]="true">
      </nh-input-group>
    </nh-field>

    <nh-field id = "me_milesPerAppointment" [mandatory]="false" *ngIf="isEnabled('data.milesPerAppointment')"
      label="{{'MEDICAL_BILLS_STATIC_TEXT19' | translate }} {{otherSelectedIndividual!== ''? otherSelectedIndividual : personOutOfHouseholdName.value}} {{'MEDICAL_BILLS_STATIC_TEXT20' | translate }}"
      class="label-section">
      <nh-popover class="pl-1" content="{{'MEDICAL_BILLS_STATIC_TEXT21' | translate }}"></nh-popover>
      <input type="text" class="form-control" formControlName="milesPerAppointment" [attr.disabled]="true">
    </nh-field>

    <nh-field id = "me_medBillStillOweSw"
             label="{{'MEDICAL_BILLS_STATIC_TEXT28' | translate }}"
             class="label-section">
             <select nh name="medBillStillOweSw" formControlName="medBillStillOweSw" aria-label="medBillStillOweSw" [items]="tableCodeValue('YESNO', 'data.medBillStillOweSw')"
               (change)="showOweBill($event)" ></select>
           </nh-field>
       
           <nh-field  id = "medBillStillOweAmt" *ngIf="(isY('data.medBillStillOweSw'))"
             label="{{'MEDICAL_BILLS_STATIC_TEXT29' | translate }}"
             class="label-section">
             <nh-input-group prepend="$">
               <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" aria-label="medBillStillOweAmt" formControlName="medBillStillOweAmt" class="form-control" (input)="validateMedBillStillOweAmt($event)">
             </nh-input-group>
             <!-- <div *ngIf="showError === true" class="error-messages position-relative">
              <span class="form-text invalid-tooltip d-block">
                <ng-container> error.errorMessage</ng-container>
              </span>
            </div> -->
           </nh-field>
  </div>

</nh-form>


