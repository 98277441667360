import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { isApplyForBenefits, isPartner, isPresumptiveApp, isRmbRmc } from '@app/helpers/mode.helpers';
import { filter, isNil } from 'ramda';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { SigninService } from "@app/gateway/signin/signin.service";
import { isChangeReport, isRedeMode } from './../../../../helpers/mode.helpers';
import { Title } from '@angular/platform-browser';
@Component({
  templateUrl: './submit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitComponent extends SectionFormDirective implements AfterContentInit {
  toggleUserSwitch = false;
  headofHousehold: any;
  todayDate: Date = new Date();
  authRep = false;
  isAFB;
  isRmbRmc;
  isPresumptive;
  isPartner;
  isFinReassessment = false;
  keyword;
  keyword2;
  keyword3;
  keyword4;
  isShowSign = false;
  isShowPartner = false;
  isKepro = false;
  defaultLanguage;
  translatedLang;
  userFullName;
  userName;
  appSubmittedDate: Date;
  applicationSource;
  bccExt = sessionStorage.getItem('appType') === 'BXT';
  isIME = sessionStorage.getItem('appType') === 'IME';
  mobileUser = sessionStorage.getItem('isMobileUser') === 'true' ? true : false;
  isPreterm = sessionStorage.getItem('isPreterm') === "PRETERM" && (sessionStorage.getItem('appType') === 'RB' || (sessionStorage.getItem('appType') === 'REDETERMINATION'  && sessionStorage.getItem('isKepro') === "true"));


  constructor(private titleService: Title, builder: FormBuilder, public translateService: TranslateService, public signinService: SigninService) {
    super(builder);
    this.titleService.setTitle('Sign your Application');
  }

  ngAfterContentInit() {
    if(sessionStorage.getItem('removedIMERecord')) {
      sessionStorage.removeItem('removedIMERecord')
    }
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
    })
    this.isKepro = sessionStorage.getItem('isKepro') === 'true';
    this.appSubmittedDate = new Date();
    this.userName =  sessionStorage.getItem('userName');
    this.isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));
    this.keyword2 = this.isFinReassessment ? 'Reassessment' : 'application';
    this.keyword3 = 'TennCare';
    this.isAFB = isApplyForBenefits(this.applicationType);
    this.isRmbRmc = isRmbRmc(this.mode);
    this.keyword4 = isChangeReport(this.mode) ? 'change' : isRedeMode(this.mode) && !this.isPreterm ? 'renewal' : isRedeMode(this.mode) && this.isPreterm ? 'questions for review' : 'application';
    this.isPresumptive = isPresumptiveApp(this.applicationType);
    this.isPartner = isPartner(sessionStorage.getItem('userType'));
    const data = this.record.data;
    if (this.isAFB || this.isPresumptive) {
      this.keyword = "Application or Renewal Packet"
    } else if(this.isPartner && this.isIME) {
      this.keyword = "Incurred Medical Expense"
    } else if(this.isPartner && this.bccExt) {
      this.keyword = "BCC Treatment Plan Extension"
    } else {
      this.keyword = this.isFinReassessment ? 'Reassessment' :  'Application or Renewal Packet';
    }

    this.authRep = data.authorizedRep === 'Y' ? true : false;
    this.headofHousehold = filter(n => isNil(n.applicantRelationship), this.individuals)[0];

    const appSignName = this.getControl('data.applicationSignName');
    const partnerSignName = this.getControl('data.partnerSignName');
    if (this.isKepro) {
      const appSource = sessionStorage.getItem('appSource') ? (sessionStorage.getItem('appSource')  + ' ') : '';
      this.userFullName = sessionStorage.getItem('fullName');
      this.applicationSource = isChangeReport(this.mode) ? appSource + 'Change' : isRedeMode(this.mode) ? appSource + 'Renewal' : appSource + 'Application';
    }
    if (!(this.isPresumptive && !this.isFinReassessment)) {
      this.getControl('data.presumptiveApplicationSignedBySw').disable();
    }
    this.registerToggle(appSignName, code => isNil(code) ? this.isShowSign = false : this.isShowSign = true);
    this.registerToggle(partnerSignName, code => isNil(code) ? this.isShowPartner = false : this.isShowPartner = true);
    if(this.mobileUser) {
      this.getControl('data.submitApplicationSourceType').patchValue("MOBILE");
      this.getControl('data.submitApplicationSourceType').updateValueAndValidity()
    } else {
      this.getControl('data.submitApplicationSourceType').patchValue(null);
      this.getControl('data.submitApplicationSourceType').updateValueAndValidity()
    }
  }
  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  convertMonth(dateString){
    if(this.translatedLang == 'sp')
    {
      let currentMonth = dateString.split(' ')[0];
      let month = this.translateService.instant(currentMonth);
      dateString = dateString.replace(currentMonth, month);
      return dateString;
    }
    return dateString;
  }
}