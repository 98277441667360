import { Component, AfterContentInit, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { isRmbRmc } from '@app/helpers/mode.helpers';
import { ModalService } from '@app/services/modal/modal.service'
import { isYes } from '@app/helpers/tayn.helpers';
import { swapControl } from '@app/helpers/forms.helpers';
import { contains } from 'ramda';
import { equals } from 'ramda';
import { disable, enable, enabled } from '@app/helpers/able.helpers';
import { relationTypes } from '@app/helpers/person.helpers';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { toIdentifier, recordIndividual } from '@app/helpers/record.helpers';
import { ReferenceTableService } from '@app/gateway/services/referenceTable.service';
import { TranslateService } from '@ngx-translate/core';
import { formatTableData } from '@app/helpers/tables.helpers';
import Vs from '@app/services/validators/validators.service';
import { Title } from '@angular/platform-browser';
import { isPartner } from '@app/helpers/mode.helpers';
@Component({
  templateUrl: './relationships-new.component.html',
  styleUrls: ['./relationships-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationshipsComponent extends SectionFormDirective implements AfterContentInit, OnInit {
  isPrimaryCareEnabled = false;
  relationTable;
  showModal = false;
  head;
  pList: any;
  errorMessage = '';
  isError = false;
  validRelationCodes = [];
  isHidden = false;
  indivList = [];
  canBePrimaryCareGiverCodes = ['FAO', 'FCO', 'GFO', 'GMO', 'MOO', 'SFA', 'SMO'];
  canBePrimaryCareGiverWithYesIfCodes = ['AUN', 'BRO', 'NEP', 'NIE', 'SBR', 'SSI', 'UNC', 'SIS'];
  defaultLanguage;
  translatedLang;
  genderCode;
  isRmbRmc = false;
  primaryCareGiverValidations = [];
  unknownRelation = [];
  referenceRelations;
  invaidRelation;
  unknownGender;
  moreThanOneSpouseEn = "You have entered more than one spouse for #name . Please update the information in the relationship dropdowns before continuing.";
  moreThanOneSpouseSp = "Ha ingresado más de un cónyuge para #name. Actualice la información en los menús desplegables de relaciones antes de continuar.";
  invalidRel;
  invalidRelEn = "A child under the age of 10 cannot have a parent relationship or step parent relationship to another member of the household.";
  invalidRelSp = "Un niño menor de 10 años no puede tener una relación de padre o padrastro con otro miembro del hogar'";
  unknownGenderRelationError;
  unknownGenderRelationErrorEn = "Please review selection for";
  unknownGenderRelationErrorSp = "Revise la selección de la relación de";
  unknownGenderRelationErrorPartB;
  unknownGenderRelationErrorPartBEn = "relationship to";
  unknownGenderRelationErrorPartBSp = "con";
  userType = sessionStorage.getItem('userType');
  isPartner = isPartner(this.userType);

  constructor(
    private modalService: ModalService,
    private referenceTableService: ReferenceTableService,
    public translateService: TranslateService,
    builder: FormBuilder,
    private titleService: Title) {
    super(builder);
    this.titleService.setTitle('Relationships');
  }

  showPrimaryCaregiverModal() {
    this.modalService.openPrimaryCaregivers()
  }

  identifier(control) {
    return recordIndividual(toIdentifier(control), this.individuals);
  }

  getGender(control) {
    this.genderCode = control.gender.code;
    return control.gender.code;
  }

  gender(control, idx) {
    const indiv = control.value[idx].sourceIndividual;
    const sourceIndiv = this.individuals.find(i => i.identifier === indiv);
    return this.getGender(sourceIndiv);
  }

  get relationshipList(): FormArray {
    return this.getControl('data.relationshipList') as FormArray;
  }

  get primaryCareList(): FormArray {
    return this.getControl('data.primaryCareList') as FormArray;
  }

  relationTypes() {
    const { RELATIONTYPE, RELATIONTYPE_GENDER } = this.tables;
    this.tables.F = relationTypes(['F', 'U'], RELATIONTYPE_GENDER, RELATIONTYPE);
    this.tables.M = relationTypes(['M', 'U'], RELATIONTYPE_GENDER, RELATIONTYPE);
    this.tables.U = relationTypes(['F', 'M', 'U'], RELATIONTYPE_GENDER, RELATIONTYPE);
  }

  get relationships(): FormArray {
    return this.getControl('data.relationships') as FormArray;
  }

  isRelationshipEnabled(control) {
    return enabled(control);
  }

  fullname(name) {
    return name.fullName
  }

  name(id) {
    return this.individuals.filter(i => i.identifier === id)[0]?.name?.fullName;
  }

  isUnknownRelation(index) {
    return this.unknownRelation[index];
  }


  getInverseRelationships(person) {
    const rels = this.relationshipList.value.filter(val => val.relation && val.relation.code === 'SPO');
    this.validRelationCodes = this.relationshipList.value.filter(val => val.relation && (this.canBePrimaryCareGiverCodes.includes(val.relation.code) || this.canBePrimaryCareGiverWithYesIfCodes.includes(val.relation.code)));
    const relationShipList = this.getControl('data.relationshipList');
    relationShipList['controls'].forEach((relationShipGroup) => {
      const primary = relationShipGroup.controls['primaryCaregiver'];
      if (relationShipGroup.value.relation && (!this.canBePrimaryCareGiverCodes.includes(relationShipGroup.value.relation.code) && !this.canBePrimaryCareGiverWithYesIfCodes.includes(relationShipGroup.value.relation.code))) {
        primary.patchValue(null);
        primary.clearValidators();
        primary.updateValueAndValidity();
      }
    })

       for (let i = 0; i < rels.length; i++) {
      for (let j = i + 1; j < rels.length; j++) {
        if (rels[i].sourceIndividual === rels[j].referenceIndividual &&
          rels[i].referenceIndividual === rels[j].sourceIndividual) {
          rels.splice(j, 1);
        }
      }
    }
    let count = 0;
    for (let i = 0; i < this.relationshipList?.length; i++) {
      for (let j = i + 1; j < this.relationshipList?.length; j++) {
        if (this.identifier(this.relationshipList.at(i).get('sourceIndividual')) === this.identifier(this.relationshipList.at(j).get('referenceIndividual'))
          && this.identifier(this.relationshipList.at(i).get('referenceIndividual')) === this.identifier(this.relationshipList.at(j).get('sourceIndividual'))) {
          const personRelation = this.relationshipList.at(i).get('relation').value?.code;
          const inverseRelation = this.relationshipList.at(j).get('relation').value?.code;
          if (inverseRelation && personRelation) {
            const validRel = this.getUnknownGenderRelation(personRelation, inverseRelation);
            console.log(validRel);
            if (!validRel) {
              count == count++;
              this.isError = true;
              this.unknownGender = true;
              this.unknownRelation[i] = true;
              this.getControl('data.unknownGenderValidation').setValidators(Vs.required);
              this.getControl('data.unknownGenderValidation').updateValueAndValidity();
              let unknownGenderRelationError1: string = '';
              if (this.translatedLang === 'en') {
                unknownGenderRelationError1 = this.unknownGenderRelationErrorEn;
                this.unknownGenderRelationErrorPartB = this.unknownGenderRelationErrorPartBEn;
              } else {
                unknownGenderRelationError1 = this.unknownGenderRelationErrorSp;
                this.unknownGenderRelationErrorPartB = this.unknownGenderRelationErrorPartBSp;
              }
              unknownGenderRelationError1 = unknownGenderRelationError1.replace('#personA', this.identifier(this.relationshipList.at(i).get('sourceIndividual')).name.fullName);
              this.unknownGenderRelationError = unknownGenderRelationError1.replace('#personB', this.identifier(this.relationshipList.at(i).get('referenceIndividual')).name.fullName);
            } 
            else if(validRel){
              this.unknownRelation[i] = false;
            }
            if (count < 1) {
              this.isError = false;
              this.unknownRelation[i] = false;
              this.getControl('data.unknownGenderValidation').clearValidators();
              this.getControl('data.unknownGenderValidation').updateValueAndValidity();
            }
          }
        }
      }
    }



    if (rels.length > 1) {
      const indivs = [...rels.map(rel => rel.sourceIndividual), ...rels.map(rel => rel.referenceIndividual)];
      const filterUnique = indivs.filter(i => indivs.indexOf(i) !== indivs.lastIndexOf(i));
      if (filterUnique.length > 0) {
        setTimeout(() => this.relationshipList.setErrors({ INVALID_INDIV: true }), 500);
        this.relationshipList.updateValueAndValidity();
        this.isError = true;
        this.getControl('data.relationshipValidation').setValidators(Vs.required);
        this.getControl('data.relationshipValidation').updateValueAndValidity();
        let errorMessage: string = '';
        if (this.translatedLang === 'en') {
          errorMessage = this.moreThanOneSpouseEn;
        } else {
          errorMessage = this.moreThanOneSpouseSp;
        }
        this.errorMessage = errorMessage.replace('#name', this.individuals.filter(ind => ind.identifier == filterUnique[0])[0].name.fullName);
      } else {
        this.isError = false;
        this.getControl('data.relationshipValidation').clearValidators();
        this.getControl('data.relationshipValidation').updateValueAndValidity();
      }
    } else {
      this.isError = false;
      this.getControl('data.relationshipValidation').clearValidators();
      this.getControl('data.relationshipValidation').updateValueAndValidity();
    }
    const relative = this.identifier(person.get('referenceIndividual'));
    const tab = this.relationTable.filter(per => per.code === person.get('relation').value.code)
    const obj = relative.gender.code === 'F' && tab ? this.getFemaleInverseObj(tab) : this.getMaleInverseObj(tab);
    person.patchValue({ 'inverse': obj })
    if(relative?.gender?.code === 'U') {
      person.patchValue({ 'inverse': null })
    }
    const primaryIndiv1 = this.primaryCareList.value.filter(val => val.indvId === person.value.sourceIndividual);
    const primaryIndiv2 = this.primaryCareList.value.filter(val => val.indvId === person.value.referenceIndividual);
    const index1 = this.primaryCareList.value.findIndex(indiv => indiv.indvId === person.value.sourceIndividual);
    const index2 = this.primaryCareList.value.findIndex(indiv => indiv.indvId === person.value.referenceIndividual);
    const sourseTenAndRefIndivUnderTen = ['DAU', 'GDO', 'SON', 'SDA', 'SSO', 'GSO', 'GMO', 'MOO', 'SMO']
    const refIndivUnderTen = ['DAU', 'GDO', 'SDA', 'SSO', 'SON', 'GSO']
    const sourceIndivUnderTen = ['SPO', 'HOS', 'FAO', 'GFO', 'GMO', 'MOO', 'SFA', 'SMO']
    const sourseAndRefIndivUnderTen = ['DAU', 'GDO', 'SON', 'SDA', 'SSO', 'GSO', 'FAO', 'GFO', 'GMO', 'MOO', 'SFA', 'SMO']
    const invalidRel = []
    for (let i = 0; i < this.relationshipList.length; i++) {
      const relative = this.identifier(this.relationshipList.at(i).get('referenceIndividual'));
      const sourseIndiv = this.identifier(this.relationshipList.at(i).get('sourceIndividual'));
      const relation = this.relationshipList.at(i).get('relation').value?.code
      const sourceUnderTen = relative.age > 10 && sourseIndiv.age < 10 && contains(relation, sourceIndivUnderTen)
      const refUnderTen = relative.age <= 10 && sourseIndiv.age > 10 && contains(relation, refIndivUnderTen)
      const sourceTenAndRefUnderTen = relative.age < 10 && sourseIndiv.age === 10 && contains(relation, sourseTenAndRefIndivUnderTen)
      const sourceAndRefUnderTen = relative.age < 10 && sourseIndiv.age < 10 && contains(relation, sourseAndRefIndivUnderTen)
      if (refUnderTen || sourceUnderTen || sourceAndRefUnderTen || sourceTenAndRefUnderTen) {
        invalidRel.push({ relative, sourseIndiv })
      }
    }
    if (invalidRel.length) {
      this.invaidRelation = true;
      this.getControl('data.hiddenField').setValidators(Vs.required);
      this.getControl('data.hiddenField').updateValueAndValidity();
    } else {
      this.invaidRelation = false;
     this.getControl('data.hiddenField').clearValidators();
     this.getControl('data.hiddenField').updateValueAndValidity();
    }
    const relationList = this.getValue('data.relationshipList');
    if (primaryIndiv1.length) {
      this.primaryCareGiversItems(primaryIndiv1[0], index1, relationList, true);
    }
    if (primaryIndiv2.length) {
      this.primaryCareGiversItems(primaryIndiv2[0], index2, relationList, true);
    }
     relationShipList['controls'].forEach((relationShipGroup) => {
 
      const primary = relationShipGroup.controls['primaryCaregiver'];
      if (relationShipGroup.value.relation && (!this.canBePrimaryCareGiverCodes.includes(relationShipGroup.value.relation.code) && !this.canBePrimaryCareGiverWithYesIfCodes.includes(relationShipGroup.value.relation.code))) {
        primary.patchValue(null);
        const index = this.primaryCareList.value.findIndex(indiv => indiv.indvId === person.value.referenceIndividual);
        if(index >=0){
        let opts = this.indivList[index].filter(item => item.checked);
        if (person.value.referenceIndividual == relationShipGroup.value.referenceIndividual || person.value.referenceIndividual == relationShipGroup.value.sourceIndividual ) {
          if (opts.length && opts.length <= 2) {
           this.primaryCareGiverValidations = this.primaryCareGiverValidations.filter(val => val !== person.value.referenceIndividual);
           primary.clearValidators();
           primary.updateValueAndValidity();
           if(this.primaryCareGiverValidations.length < 1){
           this.getControl('data.primaryCaregiverValidation').clearValidators();
           this.getControl('data.primaryCaregiverValidation').updateValueAndValidity();
           }
            this.primaryCareGiverValidations = this.primaryCareGiverValidations.filter(val => val !== relationShipGroup.value.referenceIndividual);
          }
        } 
      }
      }
    })
    relationShipList['controls'].forEach((relationShipGroup) => { 
      const index = this.primaryCareList.value.findIndex(indiv => indiv.indvId === person.value.referenceIndividual);
      if(index >=0){
        let opts = this.indivList[index].filter(item => item.checked);
      const primary = relationShipGroup.controls['primaryCaregiver'];
      if (person.value.referenceIndividual == relationShipGroup.value.referenceIndividual  || person.value.referenceIndividual == relationShipGroup.value.sourceIndividual ){
        if (opts.length && opts.length <= 2) {
          primary.clearValidators();
          primary.updateValueAndValidity();
        }
      }
    }
    });
 
  }

  getUnknownGenderRelation(relation1, relation2){
    switch (relation1) {
      case 'AUN':
        return ['NEP', 'NIE'].includes(relation2)
        break;
      case 'BRO':
        return ['SIS', 'BRO'].includes(relation2)
        break;
      case 'DAU':
        return ['MOO', 'FAO'].includes(relation2)
        break;
      case 'FAO':
        return ['DAU', 'SON'].includes(relation2)
        break;
      case 'FCO':
        return ['FCO'].includes(relation2)
        break;
      case 'GDO':
        return ['GMO', 'GFO'].includes(relation2)
        break;
      case 'GSO':
        return ['GMO', 'GFO'].includes(relation2)
        break;
      case 'GFO':
        return ['GDO', 'GSO'].includes(relation2)
        break;
      case 'GMO':
        return ['GDO', 'GSO'].includes(relation2)
        break;
      case 'MOO':
        return ['DAU', 'SON'].includes(relation2)
        break;
      case 'NEP':
        return ['AUN', 'UNC'].includes(relation2)
        break;
      case 'NIE':
        return ['AUN', 'UNC'].includes(relation2)
        break;
      case 'NRT':
        return ['NRT'].includes(relation2)
        break;
      case 'RIO':
        return ['RIO'].includes(relation2)
        break;
      case 'SON':
        return ['FAO', 'MOO'].includes(relation2)
        break;
      case 'SBR':
        return ['SBR', 'SSI'].includes(relation2)
        break;
      case 'SDA':
        return ['SFA', 'SMO'].includes(relation2)
        break;
      case 'SFA':
        return ['SSO', 'SDA'].includes(relation2)
        break;
      case 'SPO':
        return ['SPO'].includes(relation2)
        break;
      case 'SMO':
        return ['SSO', 'SDA'].includes(relation2)
        break;
      case 'SSI':
        return ['SBR', 'SSI'].includes(relation2)
        break;
      case 'SSO':
        return ['SFA', 'SMO'].includes(relation2)
        break;
      case 'UNC':
        return ['NEP', 'NIE'].includes(relation2)
        break;
      case 'SIS':
        return ['BRO', 'SIS'].includes(relation2)
        break;
      default:
        throw new Error('NoMatchFoundError')
    }
  }

  getUnique(arr, comp) {
    const unique = arr.map(e => e[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((e) => arr[e]).map(e => arr[e]);

    return unique;
  }

  getFemaleInverseObj(tab) {
    const val = this.relationTable.filter(per => per.code === tab[0].fInverse)
    let obj;
    if (this.translatedLang === 'en') {
      obj = { code: tab[0].fInverse, value: val[0].description, possibleCaregiver: val[0].possibleCaregiver };
    } else {
      obj = { code: tab[0].fInverse, value: val[0].spDescription, possibleCaregiver: val[0].possibleCaregiver };
    } return obj
  }

  getMaleInverseObj(tab) {
    const val = this.relationTable.filter(per => per.code === tab[0].mInverse)
    let obj;
    if (this.translatedLang === 'en') {
      obj = { code: tab[0].fInverse, value: val[0].description, possibleCaregiver: val[0].possibleCaregiver };
    } else {
      obj = { code: tab[0].fInverse, value: val[0].spDescription, possibleCaregiver: val[0].possibleCaregiver };
    } return obj
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  tableCodeValue(tableName, tableControl) {
    const idx = tableControl;
    if (typeof tableControl !== 'string') {
      if (tableName === 'F' || tableName === 'M') {
        const relationshipList = this.getControl('data.relationshipList');
        const relationshipCtrls = relationshipList['controls'];
        const relationshipControls = relationshipCtrls[idx];
        tableControl = relationshipControls.controls['relation'];
      } else {
        const primaryCaregiverList = this.getControl('data.primaryCareList');
        const primaryCaregiverCtrls = primaryCaregiverList['controls'];
        const primaryCareControls = primaryCaregiverCtrls[idx];
        tableControl = primaryCareControls.controls['primaryCaregiver' + idx];
      }
    }
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }

  ngAfterContentInit() {
    this.isRmbRmc = isRmbRmc(this.mode);
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);
    if (this.translatedLang === 'en') {
      this.invalidRel = this.invalidRelEn;
    } else {
      this.invalidRel = this.invalidRelSp;
    }
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.languageCheck(res.lang);
      if (this.translatedLang === 'en') {
        this.invalidRel = this.invalidRelEn;
      } else {
        this.invalidRel = this.invalidRelSp;
      }
      const relationShipListControl = this.relationshipList.controls;
      for (let i = 0; i < relationShipListControl.length; i++) {
        if (relationShipListControl[i].get('relation').value != null) {
          this.getInverseRelationships(relationShipListControl[i]);
        }
      }
    });
    this.referenceTableService.getCompleteRelationshipData().subscribe(({ referenceGroup }) => {
      this.relationTable = [...referenceGroup[0].referenceData]
      const relationShipListControl = this.relationshipList.controls;
      const rels = relationShipListControl.filter(rel => rel.value.relation == null);
      if (rels.length == relationShipListControl.length) {
        this.isPrimaryCareEnabled = false;
      }
      for (let i = 0; i < relationShipListControl.length; i++) {
        if (relationShipListControl[i].get('relation').value != null) {
          this.getInverseRelationships(relationShipListControl[i]);
        }
      }
    });

    const headofHousehold = this.individuals.filter(ind => ind.headOfHousehold === true);
    this.head = headofHousehold[0];

    this.relationTypes();

    const relationShipList = this.getControl('data.relationshipList');
    relationShipList['controls'].forEach((relationShipGroup) => {
      const relation = relationShipGroup.controls['relation'];
      if (relation.value) {
        const tableVar = this.tables.RELATIONTYPE.filter((item) => item.code === relation.value.code);
        relation.patchValue(tableVar[0]);
      }
    });

    const primaryCaregiverList = this.getControl('data.primaryCareList');
    this.pList = primaryCaregiverList;
    const ctrls = primaryCaregiverList['controls'];
    ctrls.forEach((indivFormGroup, idx) => {
      const primaryCareGiver = indivFormGroup.controls['primaryCaregiver' + idx];
      const tableVar = this.tables.YESNO.filter((item) => item.code === primaryCareGiver.value?.code);
      primaryCareGiver.patchValue(tableVar[0]);
      this.registerToggle(indivFormGroup.controls['primaryCaregiver' + idx], swapControl(indivFormGroup.controls['primaryCaregiverIndividual' + idx], isYes));
    });
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  primaryCareGiversItems(primaryIndiv, index, relationList, flag) {
    let indvsList = [];
     let referenceRelationsList = relationList?.filter(i => i.referenceIndividual == primaryIndiv?.indvId);
     this.referenceRelations = referenceRelationsList.filter(val => val.isReverseFlow === false);

    const referenCare = this.referenceRelations.filter(person => {
      const tab = this.relationTable.filter(per => per.code === person.relation?.code);
      if (tab.length) {
        if (tab[0].possibleCaregiver === "Y_15" || tab[0].possibleCaregiver === "Y") {
          return person;
        }
      }
    });

    referenCare.forEach(i => {
      const indv = i.sourceIndividual;
      const filtertedIndv = this.individuals.filter(idx => idx.identifier == indv);

      if (filtertedIndv.length > 0) {
        const obj = { individualId: filtertedIndv[0].identifier, name: `${this.fullname(filtertedIndv[0].name)} ${filtertedIndv[0].age} ${filtertedIndv[0].gender.code}` }
        indvsList = indvsList.concat(obj)
      }
    });

    this.indivList[index] = this.isChecked(indvsList, primaryIndiv);    
    const primaryCaregiverList = this.getControl('data.primaryCareList');
    const relationShipList = this.getControl('data.relationshipList');
    const primaryCaregiverCtrls = primaryCaregiverList['controls'];
    if(flag && this.indivList[index].length && !this.indivList[index].filter(i => i.checked === true).length){
      if( primaryCaregiverCtrls[index]['controls']['primaryCaregiver'+ index].value?.code === 'Y' && !this.isPartner){
    primaryCaregiverCtrls[index]['controls']['primaryCaregiver'+ index].patchValue({ code: 'N', description: 'No', rank: '2', spDescription: 'No', value: "No" });
      } 
      if( primaryCaregiverCtrls[index]['controls']['primaryCaregiver'+ index].value?.code === 'Y' && this.isPartner){
        primaryCaregiverCtrls[index]['controls']['primaryCaregiver'+ index].patchValue({ code: 'N', value: "No", spValue: "No"});
      } 
    relationShipList['controls'].forEach((relationShipGroup) => {
      const primary = relationShipGroup.controls['primaryCaregiver'];
      if ((primaryIndiv.indvId == relationShipGroup.value.referenceIndividual)) {
        primary.clearValidators();
        primary.updateValueAndValidity();
      }
    })
    }
    if (this.validRelationCodes.length) {

        primaryCaregiverCtrls.forEach((indivFormGroup, idx) => {
          if (this.indivList[idx] && this.indivList[idx].length) {
            enable(indivFormGroup.controls['primaryCaregiver' + idx]);
            indivFormGroup.controls['primaryCaregiver' + idx].isHidden = false;
          } else {
            disable(indivFormGroup.controls['primaryCaregiver' + idx]);
            indivFormGroup.controls['primaryCaregiver' + idx].isHidden = true;
          }
        });
        const indivListLength = this.indivList.filter(indiv => indiv.length > 0);
        this.isPrimaryCareEnabled = indivListLength.length ? true : false;
      } else {
      this.isPrimaryCareEnabled = false;
      primaryCaregiverCtrls.forEach((indivFormGroup, idx) => {
        disable(indivFormGroup.controls['primaryCaregiver' + idx]);
      });
    }

    if (this.indivList[index].length == 0 && this.isPrimaryCareEnabled) {
      const person1 = this.relationshipList.value.filter(per => per.relation && per.sourceIndividual === primaryIndiv?.indvId);
      const person2 = this.relationshipList.value.filter(per => per.relation && per.referenceIndividual === primaryIndiv?.indvId);
      const indivFormGroup = primaryCaregiverCtrls.filter(indiv => primaryIndiv?.indvId === indiv.value['indvId']);
      if (person1[0] && indivFormGroup[0] && (person1[0].sourceIndividual === indivFormGroup[0].value['indvId'])) {
        disable(indivFormGroup[0].controls['primaryCaregiver' + index]);
        indivFormGroup[0].controls['primaryCaregiver' + index].isHidden = true;
      }
      if (person2[0] && indivFormGroup[0] && (person2[0].referenceIndividual === indivFormGroup[0].value['indvId'])) {
        disable(indivFormGroup[0].controls['primaryCaregiver' + index]);
        indivFormGroup[0].controls['primaryCaregiver' + index].isHidden = true;
      }
    }
  }

  isChecked(list, indiv) {
    const checkedList = list.map(val => {
      val.checked = this.relationshipList.controls.some(ctrl => {
        // return ctrl.sourceIndividual == val.individualId && ctrl.primaryCaregiver?.code === 'Y' && ctrl.referenceIndividual == indiv.indvId
        return ((ctrl.value.sourceIndividual == val.individualId && ctrl.value.primaryCaregiver?.code === 'Y' && ctrl.value.referenceIndividual == indiv.indvId) ||  (ctrl.value.referenceIndividual == val.individualId && ctrl.value.primaryCaregiver?.code === 'Y' && ctrl.value.sourceIndividual == indiv.indvId))
      });
      return val;
    })
    return checkedList;
  }

  onSelected(id, event, index) {
    const relationList = this.getValue('data.relationshipList');
    if (equals(event.target.value, 'Yes')) {
      this.primaryCareGiversItems(id, index, relationList, false);
      for (let i = 0; i < this.indivList[index].length; i++) {
        this.indivList[index][i].checked = false;
      } 
      const relationShipList = this.getControl('data.relationshipList');
      relationShipList['controls'].forEach((relationShipGroup) => {
        const primary = relationShipGroup.controls['primaryCaregiver'];
        if (id.indvId == relationShipGroup.value.referenceIndividual && !relationShipGroup.value.isReverseFlow) {
          primary.patchValue(null);
          primary.setValidators(Vs.required);
          primary.updateValueAndValidity();
        }
      })
    } else if (equals(event.target.value, 'No')) {
      this.primaryCareGiverValidations = this.primaryCareGiverValidations.filter(val => val !== id.indvId);
      relationList.filter((item: any) => {
        if (item.referenceIndividual == id.indvId || (item.sourceIndividual == id.indvId && item.isReverseFlow)){
          item.primaryCaregiver = { code: 'N', value: "No" };
        }
      });
      const relationShipList = this.getControl('data.relationshipList');
      relationShipList['controls'].forEach((relationShipGroup) => {
        const primary = relationShipGroup.controls['primaryCaregiver'];
        if ((id.indvId == relationShipGroup.value.referenceIndividual)) {
          relationShipGroup['controls'].primaryCaregiver.patchValue({ code: 'N', value: "No" });
          primary.clearValidators();
          primary.updateValueAndValidity();
        }
        if(id.indvId == relationShipGroup.value.sourceIndividual){
          primary.clearValidators();
          primary.updateValueAndValidity();
        }
      })
      for (let i = 0; i < this.indivList[index].length; i++) {
        this.indivList[index][i].checked = false;
      }  
      const opts = this.indivList[index].filter(item => item.checked);
      if (opts.length === 0) {            
        if(this.primaryCareGiverValidations.length < 1){
        this.getControl('data.primaryCaregiverValidation').clearValidators();
        this.getControl('data.primaryCaregiverValidation').updateValueAndValidity();
        }
      }
    }
  }

  onIndvSelect(event: any, sourceIndv, indivList) {
    if (event.target.checked) {
      const relationShipList = this.getControl('data.relationshipList');
      const relationShipListRecord = this.record?.data?.relationshipList;
      this.formGroup.value.data.relationshipList.filter((i, index) => {
        if ((i.sourceIndividual === event.target.value && i.referenceIndividual === sourceIndv && !i.isReverseFlow)){
          relationShipList['controls'][index].controls['primaryCaregiver'].patchValue({ code: 'Y', value: "Yes" })
        }
        if ((i.referenceIndividual === event.target.value && i.sourceIndividual === sourceIndv && i.isReverseFlow)){
          relationShipList['controls'][index].controls['primaryCaregiver'].patchValue(null);
        }
      })
      this.record?.data?.relationshipList.filter((i, index) => {
        if ((i.sourceIndividual.toString() === event.target.value && i.referenceIndividual.toString() === sourceIndv)){
          if(relationShipListRecord[index].primaryCaregiver !== null){
          relationShipListRecord[index].primaryCaregiver.code = 'Y';
          relationShipListRecord[index].primaryCaregiver.spValue = 'Si';
          relationShipListRecord[index].primaryCaregiver.value = 'Yes';
          } else {
            relationShipListRecord[index].primaryCaregiver = ({ code: 'Y', spValue: 'Si', value: "Yes" });
          }
        }
      })
      for (let i = 0; indivList.length > i; i++) {
        if (event.target.value === indivList[i].individualId) {
          indivList[i].checked = true;
        };
      }      

      relationShipList['controls'].forEach((relationShipGroup) => {
        const primary = relationShipGroup.controls['primaryCaregiver'];
        if ((relationShipGroup.value.sourceIndividual === event.target.value && relationShipGroup.value.referenceIndividual === sourceIndv) && !relationShipGroup.value?.isReverseFlow) {
          primary.patchValue({ code: 'Y', value: "Yes" })
        }
        const opts = indivList.filter(item => item.checked);
        if (sourceIndv == relationShipGroup.value.referenceIndividual || sourceIndv == relationShipGroup.value.sourceIndividual) {
          if (opts.length && opts.length <= 2) {            
            primary.clearValidators();
            primary.updateValueAndValidity();
            if(this.primaryCareGiverValidations.length < 1){
            this.getControl('data.primaryCaregiverValidation').clearValidators();
            this.getControl('data.primaryCaregiverValidation').updateValueAndValidity();
            }
          }
          else {
            if (opts.length && !this.primaryCareGiverValidations.includes(sourceIndv)) {
              this.primaryCareGiverValidations.push(sourceIndv);
              this.getControl('data.primaryCaregiverValidation').setValidators(Vs.required);
              this.getControl('data.primaryCaregiverValidation').updateValueAndValidity();
              primary.setValidators(Vs.required);
              primary.updateValueAndValidity();
            }
            //primary.patchValue(null);
            primary.setValidators(Vs.required);
            primary.updateValueAndValidity();
          }
        }
      })
    } else {
      let opts;
      const relationShipListRecord = this.record?.data?.relationshipList;
      this.formGroup.value.data.relationshipList.filter(i => {
        if ((i.sourceIndividual === event.target.value && i.referenceIndividual === sourceIndv) || (i.referenceIndividual === event.target.value && i.sourceIndividual === sourceIndv)) {
          i.primaryCaregiver = { code: 'N', value: "No" }
        }
      })

      this.getControl('data.relationshipList')['controls'].filter(j => {
        if (((j['controls'].sourceIndividual.value === event.target.value && j['controls'].referenceIndividual.value === sourceIndv) || (j['controls'].referenceIndividual.value === event.target.value && j['controls'].sourceIndividual.value === sourceIndv))) {
          j['controls']['primaryCaregiver'].patchValue({ code: 'N', value: "No" });
        }
      })

      this.record?.data?.relationshipList.filter((i, index) => {
        if ((i.sourceIndividual.toString() === event.target.value && i.referenceIndividual.toString() === sourceIndv)){
          if(relationShipListRecord[index].primaryCaregiver !== null){
          relationShipListRecord[index].primaryCaregiver.code = 'N';
          relationShipListRecord[index].primaryCaregiver.spValue = 'No';
          relationShipListRecord[index].primaryCaregiver.value = 'No';
          } else {
            relationShipListRecord[index].primaryCaregiver = ({ code: 'N', spValue: 'No', value: "No" });
          }
        }
      })

      for (let i = 0; indivList.length > i; i++) {
        if (event.target.value === indivList[i].individualId) {
          indivList[i].checked = false;         
        }

        this.getControl('data.relationshipList')['controls'].filter(j => {
          if (((j['controls'].sourceIndividual.value === indivList[i].individualId && j['controls'].referenceIndividual.value === sourceIndv) || (j['controls'].referenceIndividual.value === indivList[i].individualId && j['controls'].sourceIndividual.value === sourceIndv)) && indivList[i].checked == true && !j['controls'].isReverseFlow?.value) {
            j['controls']['primaryCaregiver'].patchValue({ code: 'Y', value: "Yes" });
          }
        })
      }

      const relationShipList = this.getControl('data.relationshipList');
      relationShipList['controls'].forEach((relationShipGroup) => {
        const primary = relationShipGroup.controls['primaryCaregiver'];
        // if ((relationShipGroup.value.sourceIndividual === event.target.value && relationShipGroup.value.referenceIndividual === sourceIndv)) {
        //   primary.patchValue(null);
        // }
        opts = indivList.filter(item => item.checked);
        if (sourceIndv == relationShipGroup.value.referenceIndividual || sourceIndv == relationShipGroup.value.sourceIndividual ) {
          if (opts.length && opts.length <= 2) {
            this.primaryCareGiverValidations = this.primaryCareGiverValidations.filter(val => val !== sourceIndv);
            if(this.primaryCareGiverValidations.length < 1){
            this.getControl('data.primaryCaregiverValidation').clearValidators();
            this.getControl('data.primaryCaregiverValidation').updateValueAndValidity();
            }
            primary.clearValidators();
            primary.updateValueAndValidity();
          }
          else if((sourceIndv == relationShipGroup.value.sourceIndividual || sourceIndv == relationShipGroup.value.referenceIndividual) && (event.target.value == relationShipGroup.value.referenceIndividual || event.target.value == relationShipGroup.value.sourceIndividual)) {
            primary.patchValue(null);
            primary.setValidators(Vs.required);
            primary.updateValueAndValidity();
          }
        }
      })
    }
  }
}