<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left" id="my-modal-title">{{ title }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
    </button>
  </div>
  <div class="modal-body">
    <div class="row align-items-center">
      <div class="col-md-6 col-lg-5 offset-lg-2 order-md-2">
        <p>If you suspect abuse, neglect, self neglect, or exploitation, please notify us. Proof of abuse or neglect is
          not required to make a report. Reports are confidential and can be anonymous.</p>
      </div>
      <div class="col-md-6 col-lg-5 order-lg-1 pb-3">
        <a class="btn btn-primary btn-block" role="button" title="Child abuse" href="http://www.dhhs.nh.gov/dcyf/cps/contact.htm"
          (click)="onConfirm()">CHILD ABUSE</a>
        <a class="btn btn-primary btn-block" role="button" title="Adult abuse" href="http://www.dhhs.nh.gov/dcbcs/beas/adultprotection.htm"
          (click)="onConfirm()">ADULT ABUSE</a>
        <a class="btn btn-primary btn-block" role="button" title="Domestic violence" href="http://www.dhhs.nh.gov/dcyf/domesticviolence.htm"
          (click)="onConfirm()">DOMESTIC VIOLENCE</a>
      </div>
    </div>
  </div>
</div>
