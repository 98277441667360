import { Component, ChangeDetectionStrategy, AfterContentInit } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { Title } from '@angular/platform-browser';
import { FormBuilder } from '@angular/forms';

@Component({
  templateUrl: './start-presumptive.component.html',
  styleUrls: ['./start-presumptive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class StartPresumptiveComponent extends SectionFormDirective implements AfterContentInit {
  storeItem;

  user = sessionStorage.getItem('userType');

  isPartnerPortal = this.user === 'RP' ? true : false;

  constructor(public builder: FormBuilder, private titleService: Title) {
    super(builder)
    this.titleService.setTitle('Apply for Coverage');
  };

  ngAfterContentInit() {
    this.storeItem = sessionStorage.getItem('typesOfCoverage');
  }
}
