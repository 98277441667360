import { Injectable } from "@angular/core";
// import mockData from "../securityQuestionsMock";
import { Subject, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class CreateAccountService {
  userData: any = {};
  sessionStorage: any;
  private userObj$ = new Subject<any>();
  private isSummary$ = new Subject<any>();
  private isStartPage$ = new Subject<any>();
  private isStepperOn$ = new Subject<any>();
  private manageAccountAction$ = new Subject<any>();
  manageAccountFormUpdateState = false;

  constructor() {
    this.sessionStorage = window.sessionStorage;
  }

  updateUser(formData) {
    this.userData = { ...this.userData, ...formData };
    this.sessionStorage.setItem("userData", JSON.stringify(this.userData));
  }
  getFormData() {
    this.userData = JSON.parse(this.sessionStorage.getItem("userData"));
    return this.userData;
  }
  remove() {
    return this.sessionStorage.removeItem("userData");
  }
  // getReferenceData() {
  //   return mockData;
  // }

  getUserObj(): Observable<any> {
    return this.userObj$.asObservable();
  }

  setUserObj(profile: any) {
    this.userObj$.next(profile);
  }

  getIsSummary(): Observable<boolean> {
    return this.isSummary$.asObservable();
  }

  setIsSummary(isSummary: boolean) {
    this.isSummary$.next(isSummary);
  }

  getIsStartPage(): Observable<any> {
    return this.isStartPage$.asObservable();
  }

  setIsStartPage(isStartPage: boolean) {
    this.isStartPage$.next(isStartPage);
  }

  getIsStepperOn(): Observable<any> {
    return this.isStepperOn$.asObservable();

  }
  setIsStepperOn(isStepperEnable: boolean) {
    this.isStepperOn$.next(isStepperEnable);
  }
  getmanageAccountAction(): Observable<any> {
    return this.manageAccountAction$.asObservable();

  }
  setmanageAccountAction(actionType) {
    this.manageAccountAction$.next(actionType);
  }


}
