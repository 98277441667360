import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../services/api.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'nh-change-email-validate',
  templateUrl: './change-email-validate.component.html',
  styleUrls: ['./change-email-validate.component.scss']
})
export class ChangeEmailValidateComponent implements OnInit {
  headerTitle: string = "";
  sectionTitle: string = "";
  currentLang;

  isChangeEmailLinkValid: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService ,public translateService: TranslateService, private titleService: Title
    ) {
      this.titleService.setTitle('Update Email');
    this.activatedRoute.queryParams.subscribe(res => {
      if (res && res.langcd) {
        this.currentLang = res.langcd === 'sp' ? 'ES' : res.langcd
        if (this.currentLang == 'ES') {
          this.translateService.setDefaultLang('sp');
        }
      }
      else translateService.onDefaultLangChange.subscribe(res => this.switchLanguage(res.lang));
    }) 
  }

  switchLanguage(lang) {
    if (lang === 'sp') {
      this.currentLang = 'ES'
    } else {
      this.currentLang = 'EN'
    }
  };

  ngOnInit(): void {
    const validateLinkObj = {}
    this.activatedRoute.queryParams.subscribe((response) => {
      validateLinkObj['reasonCode'] = response.reasonCode;
      validateLinkObj['token'] = response.token;
      validateLinkObj['language'] = this.currentLang;
      validateLinkObj['userName'] = response.loginAttribute
    })

    this.apiService.post('publ/users/validateLinks', validateLinkObj).subscribe((response) => {
      response['language'] === 'ES' ? this.translateService.setDefaultLang('sp') : this.translateService.setDefaultLang('en');
      if (response['code'] == '400') {
        this.isChangeEmailLinkValid = false;
        this.sectionTitle = 'VERIFICATION_LINK_EXPIRED';
        this.headerTitle = 'LINK_EXPRIED';
      } else if(response['code'] == '200') {
        this.isChangeEmailLinkValid = true;
        this.sectionTitle = 'EMAIL_ADDRESS_CHANGED'
        this.headerTitle = 'SUCCESS';
      }
    })
  }


}
