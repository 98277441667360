<div class="container mb-3">
  <div class="row">
    <div class="col-12">
      <h1 class="page-title">{{ 'FORGOT_PASSWORD' | translate }}</h1>
    </div>
  </div>
  <div *ngIf="!isForgotPasswordlinkPage" class="row pl-1 pb-1 hover-red">
    <div class="btn btn-link hover-red" (click)="onPrevious()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
        <g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
          <path id="Combined_Shape" data-name="Combined Shape"
            d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
            transform="translate(0)" fill="#174a7c" />
        </g>
      </svg>
      <span class="pl-2">{{'BACK_TO_PREVIOUS_PAGE' | translate}}</span>
    </div>
  </div>
  <div class="card mb-4" style="border:0px;">
    <div class="card-body shadow-sm">
      <div *ngIf="!isForgotPasswordlinkPage" class="section-header">
        <h4><strong>{{ 'FORGOT_PASSWORD' | translate }}</strong></h4>
        <hr>
      </div>
      <div *ngIf="isForgotPasswordlinkPage" class="section-header">
        <h4><strong>{{ 'PASSWORD_RESET' | translate }}</strong></h4>
        <hr>
      </div>

      <section *ngIf="!isForgotPasswordlinkPage">
        <form [formGroup]="forgotPassword">
          <div class="form-row" style="margin-bottom: 20px">
            <div class="col-md-10 col-lg-10 col-xs-12">
              <nh-field [isRow]="true" label="{{'USERNAME_EMAIL' | translate }}" class="label-section">
                <input id = "fp_usernameOrEmail" type="text" name="usernameOrEmail" formControlName="usernameOrEmail" aria-label="usernameOrEmail"
                  class="form-control" minlength="6" maxlength="63" required>
              </nh-field>
            </div>
          </div>
          <re-captcha id = "fp_recaptchaReactive"  *ngIf="isRecaptchaEnabled && loaderReady" (resolved)="resolved($event)"
            formControlName="recaptchaReactive" [siteKey]="siteKey">
          </re-captcha>
          <br>
        </form>


      </section>
      <section *ngIf="isForgotPasswordlinkPage">
        <div class="row">
          <div class="col-md-12 col-xs-12">
            <p>{{'RESET_INSTRUCTIONS' | translate}}</p>
          </div>
        </div>
        <div class="mb-2">
          <div class="row pt-1 hover-red comments">
            <a class="btn btn-link hover-red" [routerLink]="['/signin']">
              <span class="pr-2">{{'BACK_TO_LOGIN' | translate }}</span>
              <nh-exit-svg></nh-exit-svg>
            </a>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div *ngIf="!isForgotPasswordlinkPage" class="row mb-4">
    <div class="col-md-2">
      <button id="continue" class="btn btn-block btn-secondary" (click)="sendForgotPasswordLink()"
        [disabled]="forgotPassword.status != 'VALID'">
        {{'NEXT_BUTTON' | translate}}
      </button>
    </div>
  </div>
</div>