import { compose, not, equals, either } from 'ramda';

export const isExportStatusNotEmpty = compose(
  not,
  equals('')
);

export const isExported = compose(
  not,
  equals('ADDED')
)

export const isOtherModes = compose(
  not,
  equals('NEW')
);

export const isNewAddprogram = either(equals('NEW'), equals('ADD_PROGRAM'));

export const isAddProgramOrRede = either(equals('ADD_PROGRAM'), equals('REDETERMINATION'));

export const isRedeMode = equals('REDETERMINATION');

export const isReapplyMode = equals('REAPPLY');

export const isNewReapplyMode = either(equals('NEW'), equals('REAPPLY'));

export const isChangeReport = equals('CHANGE_REPORT');

export const isRenewBenefits = equals('RENEW_BENEFITS');

export const isReportChanges = equals('REPORT_CHANGES');

export const isNewAddReapplyMode = either(isNewAddprogram, equals('REAPPLY'));

export const isApplyForBenefits = equals('APPLY_BENEFITS');

export const isPresumptiveApp = either(equals('PRESUMPTIVE_BCC'), either(equals('PRESUMPTIVE_HOSPITAL'), equals('PRESUMPTIVE_PREGNANCY')));

export const isPresumptiveHospital = equals('PRESUMPTIVE_HOSPITAL');

export const isPresumptivePregnancy = equals('PRESUMPTIVE_PREGNANCY');

export const isPresumptiveBcc = equals('PRESUMPTIVE_BCC');

export const isPartner = equals('RP');

export const isRmbRmc = either(isChangeReport, isRedeMode);

export const isRmbOnly = equals('REDETERMINATION');