import { propOr, reject, compose } from 'ramda';
import { contained } from 'ramda-adjunct';

import { nilProp } from './null.helpers';
import { withUse } from './flipped.helpers';
import { callWith } from './function.helpers';

const { parseFloat } = Number;

const nilValue = nilProp('value');

export const value = propOr('', 'value');
export const toFloat = compose(
  parseFloat,
  value
);
export const removeNil = reject(nilValue);
export const useValues = withUse([value, value]);
export const useNumericValues = withUse([toFloat, toFloat]);
export const valueIn = callWith([contained, value]);
