<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left" id="my-modal-title">{{ 'SAVE_EXIT_STATIC_TEXT1' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon" />
    </button>
  </div>
  <div class="modal-body">
    <h5 class="page-header">{{ 'SAVE_EXIT_STATIC_TEXT1' | translate }}</h5>
      <div class="row">
        <div class="p-4">
          <div class="card card-body shadow mb-2" [ngClass]="{'applyColor': appType === 'atglance'}"
            (click)="select('atglance')" role="button">
            <div class="row align-items-center">
              <div>
                <div><img class="col dot"  aria-label="save" src="../../../../assets/images/Icons/S&E_save.svg"></div>
              </div>
              <div class="col">
                <div class="tile_header"><strong *ngIf="!isPreterm" >{{'SAVE_EXIT_STATIC_TEXT2' | translate }} {{ appKeyWord | translate }} {{'SAVE_EXIT_STATIC_TEXT3' | translate }}</strong>
                                         <strong *ngIf="isPreterm" >{{'SAVE_EXIT_STATIC_TEXT2_QFR' | translate }}</strong>
                </div><br>
                <div *ngIf="!isPreterm">
                  <span class="tile-text">{{'SAVE_EXIT_STATIC_TEXT4' | translate }} {{ appKeyWord | translate }}. <label class="notbold" *ngIf="!isRenewal">{{'SAVE_EXIT_STATIC_TEXT5' | translate }}</label> <label class="notbold" *ngIf="isRenewal">{{'SAVE_EXIT_STATIC_TEXT19' | translate }}</label> {{ appKeyWord | translate }}{{'SAVE_EXIT_STATIC_TEXT6' | translate }}.</span><br><br>
                  <span class="tile-text">{{'SAVE_EXIT_STATIC_TEXT7' | translate }} {{ appKeyWord | translate }} </span><span class="tile-text" [innerHTML]="'SAVE_EXIT_STATIC_TEXT8' | translate">{{'SAVE_EXIT_STATIC_TEXT8' | translate}}</span><br><br>
                  <span class="tile-text">{{'SAVE_EXIT_STATIC_TEXT9' | translate }}</span>
                </div>
                <div *ngIf="isPreterm">
                  <span class="tile-text">{{'SAVE_EXIT_STATIC_TEXT4_QFR' | translate }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="card card-body shadow mb-2" [ngClass]="{'applyColor': appType === 'continue'}"
            (click)="select('continue')" role="button">
            <div class="row align-items-center">
              <div>
                <div><img class="col dot"  aria-label="keepWorking" src="../../../../assets/images/Icons/S&E_continue.svg"></div>
              </div>
              <div class="col">
                <div class="tile_header"><strong *ngIf="!isPreterm">{{'SAVE_EXIT_STATIC_TEXT10' | translate }} {{ appKeyWord | translate
                    }}</strong>
                  <strong *ngIf="isPreterm">{{'SAVE_EXIT_STATIC_TEXT10_QFR' | translate }}</strong>
                </div><br>
                <span class="tile-text" [innerHTML]="'SAVE_EXIT_STATIC_TEXT11' | translate">{{'SAVE_EXIT_STATIC_TEXT11' | translate }}</span>
              </div>
            </div>
          </div>

          <div *ngIf="showSubmitTab() && !isPresumptive" class="card card-body shadow mb-2"
            [ngClass]="{'applyColor': appType === 'submit'}" (click)="select('submit')" role="button">
          <div class="row align-items-center">
              <div>
                <div><img class="col dot"  aria-label="submit" src="../../../../assets/images/Icons/S&E_submit.svg"></div>
              </div>
              <div class="col">
                <div class="tile_header"><strong *ngIf="!isPreterm">{{'SAVE_EXIT_STATIC_TEXT12' | translate}} {{ appKeyWord | translate }}.</strong>
                                         <strong *ngIf="isPreterm">{{'SAVE_EXIT_STATIC_TEXT12_QFR' | translate}}</strong> 
                </div><br>
                <span *ngIf="!isPreterm" class="tile-text">
                  {{'SAVE_EXIT_STATIC_TEXT13' | translate }} {{ appKeyWord | translate }} {{'SAVE_EXIT_STATIC_TEXT14' | translate }}
                </span>
                <span *ngIf="isPreterm" class="tile-text">
                  {{'SAVE_EXIT_STATIC_TEXT13_QFR' | translate }}
                </span><br><br>
                <span class="tile-text">{{'SAVE_EXIT_STATIC_TEXT15' | translate }}</span>
              </div>
            </div>
          </div><br>
        <div *ngIf="!mobileUser && !isPreterm">{{'SAVE_EXIT_STATIC_TEXT16' | translate}} {{ appKeyWord | translate }}, <span [innerHTML]="'SAVE_EXIT_STATIC_TEXT17' | translate">{{'SAVE_EXIT_STATIC_TEXT17' | translate}}</span> {{ appKeyWord | translate }}{{'SAVE_EXIT_STATIC_TEXT18' | translate}}</div>
        <div *ngIf="!mobileUser && isPreterm">{{'SAVE_EXIT_STATIC_TEXT16_QFR' | translate}}</div>
      </div>
    </div>
  </div>

  <div class="modal-footer d-block">
    <div class="form-row justify-content-end">
      <div class="col-md-3">
        <button id="SaveContinue" id="dialog" class="btn btn-block btn-secondary" (click)="next()" [disabled]="appType === ''">
          {{'NEXT' | translate }}
        </button>
      </div>
    </div>
  </div>

</div>
