import { Component, Input, ContentChild, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { NgControl, MaxLengthValidator } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { length, subtract, propOr } from 'ramda';

@Component({
  selector: 'nh-count',
  template: `
  <br>
  <p class="hint" *ngIf="(remaining$ | async) as remaining">{{ 'REMAINING_CHAR' | translate }} {{ remaining }}</p>
  <ng-content class="margin-top-1"></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountComponent implements AfterContentInit {
  @ContentChild(NgControl, { static: false })
  field;
  @ContentChild(MaxLengthValidator, { static: false })
  validator;
  @Input() text;
  remaining$: Observable<number>;

  constructor(private translate: TranslateService) {
    this.translate.get('REMAINING_CHAR').subscribe((trans: string) => this.text = trans);
  }

  ngAfterContentInit() {
    const maxlength = propOr(0, 'maxlength', this.validator);
    if (maxlength) {
      this.remaining$ = this.field.valueChanges.pipe(
        startWith(''),
        map(length),
        map(subtract(maxlength))
      );
    }
  }
}
