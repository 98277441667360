import { invoker, flip, forEach } from 'ramda';

import { selfSilent } from './form.helpers';

export const reset = invoker(0, 'reset');
export const resetWith = invoker(2, 'reset');
export const resets = forEach(reset);

const withReset = flip(resetWith);

export const silentReset = withReset(selfSilent);
export const resetValue = withReset({});
