<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ title }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
    </button>
  </div>
  <div class="modal-body">
    <h6>
      <strong>TennCare Connect Analytics Policy</strong>
    </h6>
    <p>
      We use a tool called "Google Analytics" to collect information about use of this site.
      Google Analytics collects information such as how often users visit this site, what pages they visit when they do
      so, and what other sites they used prior to coming to this site. We use the information we get from Google
      Analytics only to improve this site. Google Analytics collects only the IP address assigned to you on the date
      you visit this site, rather than your name or other identifying information. We do not combine the information
      collected through the use of Google Analytics with personally identifiable information. Although Google Analytics
      plants a permanent cookie on your web browser to identify you as a unique user the next time you visit this site,
      the cookie cannot be used by anyone but Google. Google's ability to use and share information collected by Google
      Analytics about your visits to this site is restricted by the
      <a class="text-uppercase" role="button" target="_blank" href="https://www.google.com/analytics/terms/us.html"
        title="Analytics Terms of Use">Google Analytics Terms of Use</a>
      and the
      <a class="text-uppercase" role="button" target="_blank" href="https://policies.google.com/privacy" title="Google Privacy">Google
        Privacy Policy</a>.
    </p>
    <p>
      You can prevent Google Analytics from recognizing you on return visits to this site by installing the
      <a class="text-uppercase" role="button" target="_blank" href="https://tools.google.com/dlpage/gaoptout" title="Google Analytics opt-out browser add-on">
        Google Analytics opt-out browser add-on
      </a>.
    </p>
  </div>
</div>
