import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'demographics-checkbox',
  templateUrl: './demographics-checkbox.component.html',
  styleUrls:['./demographics-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DemographicsCheckboxComponent {
  private _checkedValue;
  private _checkboxValue: boolean;
  constructor(private readonly cd: ChangeDetectorRef){}
  @Input() control: FormControl;
  @Input() set checkedValue(value: any){
    this._checkedValue = value;
    console.log(value);
  };

  get checkedValue(): any{
    return this._checkedValue;
  }


  @Input() set checkboxValue(value: boolean){
    this._checkboxValue = value;
    if(value){
      this.cd.detectChanges();
    }
  };

  get checkboxValue(): boolean{
    
    return this._checkboxValue;
  }
  isChecked(): boolean{
    return this._checkboxValue;
  }


  @Input() uncheckedValue: any = null;
  @Input() label: string;
  @Input() name: string;
  @Input() inline = false;
  @Input() disabled = false;
  @Input() mandatory = false;
  @Input() index: string;
  @Input() isPNSelected: boolean;
  @Output() valueChange = new EventEmitter<any>();

  get isDisabled() {
    if (this.disabled) {
      return true;
    } else {
      return null;
    }
  }

  // isCheckboxChecked(): boolean{
  //   return !this.isDisabled && this.isPNSelected;
  // }

  checkboxValueChanged(checked: any, value: any): void{
    this.valueChange.emit({value, isChecked: checked?.currentTarget?.checked});
  }
}
