import { ChooseHealthPlanComponent } from './choose-healthplan.component';
import { lookIn } from '@app/helpers/flipped.helpers';
import { sectionConfiguration } from '@app/helpers/models.helpers';
// import Vs from '@app/services/validators/validators.service';

const formValidation = (builder, recordData) => {
  const chooseHealthPaln = lookIn(recordData);
  const data = builder.group({
    healthPlan:[chooseHealthPaln(['healthPlan'])] 
  });

  return { data };
};

export default sectionConfiguration(ChooseHealthPlanComponent, formValidation, ['MCONAME', 'MCONAME_UBW'], [], {}, {
  hasSummary: false,
  showSubmit: false,
});
