import { State, Action, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { map, tap } from 'rxjs/operators';

import { omit } from 'ramda';
import { merge } from 'ramda';

import { SectionState } from '@app/state/section/section.state';

import { ApplicationService } from '@app/services/application/application.service';
import { sections } from '@app/section/forms';

import { LoadApp, StartApp, GetApp, UpdateApp } from './app.actions';
import { LoadComments, UpdateComments } from './app.actions';
import { LoadCongrats } from './app.actions';
import { SetIndividuals } from './app.actions';
import { setIndividuals } from './app.creators';
import { setSection } from '@app/state/section/section.creators';
import { title, moduleId, previousSection, sectionStatus } from '@app/state/section/section.selectors';
import { sectionLinks, tableCodes, sectionConfig } from '@app/state/section/section.selectors';

import { toIndividuals } from '@app/helpers/people.helpers';
import { moduleCongrats } from '@app/helpers/types.helpers';
import { viewFirstRecordData } from '@app/helpers/lenses.helpers';

import { SectionId } from '@app/models/section.model';
import { Individuals } from '@app/models/individual.model';
import { ApplicationStateModel } from '@app/models/state.model';

import { ApplicationNumber, Programs, Comments, Statuses } from '@app/models/app.model';

export const appState: ApplicationStateModel = {
  applicationNumber: null,
  applyingForHcbc: false,
  applicationType: null,
  applyingForNursingFacilities: false,
  modules: [],
  sections,
  individuals: [],
  mode: null,
  status: Statuses.IN_PROGRESS,
  programs: [],
  comments: null,
};

@State<ApplicationStateModel>({
  name: 'app',
  defaults: appState,
  children: [SectionState],
})
@Injectable()
export class ApplicationState {
  constructor(private application: ApplicationService) {}

  @Selector()
  static applicationNumber({ applicationNumber }: ApplicationStateModel): ApplicationNumber {
    return applicationNumber;
  }

  @Selector()
  static application(app: ApplicationStateModel) {
    return omit(['sections', 'individuals', 'section', 'status'], app);
  }

  @Selector()
  static individuals({ individuals }: ApplicationStateModel): Individuals {
    return individuals;
  }

  @Selector()
  static programs({ programs }: ApplicationStateModel): Programs | null {
    return programs;
  }

  @Selector()
  static comments({ comments }: ApplicationStateModel): Comments | null {
    return comments;
  }

  @Selector()
  static sectionConfig(app: ApplicationStateModel) {
    return (sectionId: SectionId): any => sectionConfig(app, sectionId);
  }

  @Selector()
  static tableCodes(app: ApplicationStateModel): Function {
    return (sectionId: SectionId): any => tableCodes(app, sectionId);
  }

  @Selector()
  static title({ section, modules }): any | null {
    return title(section, modules);
  }

  @Selector()
  static moduleId({ section, modules }): any | null {
    return moduleId(section, modules);
  }

  @Selector()
  static previousSection({ section, modules }): any | null {
    return previousSection(section, modules);
  }

  @Selector()
  static sectionLinks({ section, modules }): Function {
    return sectionLinks(section, modules);
  }

  @Selector()
  static sectionStatus({ section, modules }) {
    return sectionStatus(section, modules);
  }

  @Selector()
  static congrats({ applicationNumber, programs, section }) {
    return merge({ applicationNumber, programs }, viewFirstRecordData(section));
  }

  @Action(StartApp)
  startApp({ patchState }, { mode, caseNumber }) {
    return this.application.startApp(mode, caseNumber).pipe(map(patchState));
  }

  @Action(LoadApp)
  loadApp({ dispatch, patchState }, { payload }) {
    return this.application.loadApp(payload).pipe(
      map(([app, people]) => [
        patchState(app), //
        dispatch(setIndividuals(people)),
      ])
    );
  }

  @Action(LoadCongrats)
  loadCongrats({ dispatch, patchState }, { applicationNumber }) {
    return this.application.loadCongrats(applicationNumber).pipe(
      map(congrats => [
        patchState({ applicationNumber, modules: moduleCongrats }), //
        dispatch(setSection(congrats)),
      ])
    );
  }

  @Action(GetApp)
  getApp({ patchState }, { payload }) {
    return this.application.getApp(payload).pipe(map(patchState));
  }

  @Action(UpdateApp)
  updateApp(_: any, { payload: { applicationNumber, partialSubmission, incompleteSection } }) {
    return this.application.updateApp(applicationNumber, { partialSubmission, incompleteSection });
  }

  @Action(SetIndividuals)
  setIndividuals({ patchState }, { payload }) {
    patchState({ individuals: toIndividuals(payload) });
  }

  @Action(LoadComments)
  loadComments({ patchState }, { payload }) {
    return this.application.loadComments(payload).pipe(tap(patchState));
  }

  @Action(UpdateComments)
  updateComments({ patchState }, { payload: { applicationNumber, comments } }) {
    return this.application.updateComments(applicationNumber, comments).pipe(tap(() => patchState({ comments })));
  }
}
