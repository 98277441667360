import { Component, Input } from '@angular/core';

import { BaseModalDirective } from '@app/components/modals/base-modal';

@Component({
  templateUrl: './health-plan-confirm.component.html',
  styleUrls: ['./health-plan-confirm.component.scss'],
})
export class HealthPlanConfirmModalComponent extends BaseModalDirective {
  @Input() healthPlanDetails: any;
}
