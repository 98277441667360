<div [formGroup]="phoneNumbers">
  <div class="row">
    <div class="col">
      <nh-field id ="{{contactPhoneName+'_home'}}" [mandatory]="false" label="{{'HOME_PHONE' | translate}}">
        <input type="text" name="home" formControlName="home" class="form-control" mask="000-000-0000"
          placeholder="XXX-XXX-XXXX">
      </nh-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <nh-field id ="{{contactPhoneName+'_cell'}}" [mandatory]="false" label="{{'CELL_PHONE' | translate}}">
        <input type="text" name="cell" formControlName="cell" class="form-control" mask="000-000-0000"
          placeholder="XXX-XXX-XXXX">
      </nh-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <nh-field id ="{{contactPhoneName+'_message'}}" [mandatory]="false" label="{{'WORK_PHONE' | translate}}">
        <input type="text" name="message" formControlName="message" class="form-control" mask="000-000-0000"
          placeholder="XXX-XXX-XXXX">
      </nh-field>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <nh-field id ="{{contactPhoneName+'_extension'}}" [mandatory]="false" label="{{'EXTENSION' | translate}}">
        <input type="text" name="extension" aria-label="extension" formControlName="extension" maxlength="6" mask="0*" class="form-control">
      </nh-field>
    </div>
  </div>
</div>
