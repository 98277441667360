<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <div class="page_label mt-3 p-3">
    <div [formGroup]="getControl('data')">
      <p> Tell us about the nursing facility completing the 2350 Form. You can use the 2350 to transfer nursing
        facilities or HCBS. </p>
      <p>But if you need to request a level of care transition (such as from a nursing facility to HCBS), you must call
        the patient’s health plan. You cannot use the 2350 Form to request a level of care transition.</p><br>
      <div class="admit-discharge-header"><span>Nursing Facility Address</span></div>
      <br>

        <div>
          <nh-field  id = "fain_nursingFacilitySearch" label="Search for a Nursing Facility" [mandatory]="false" class="label-section">
            <input type="text" name="nursingFacilitySearch" maxlength="100" class="form-control" aria-label="nursingFacilitySearch" formControlName="nursingFacilitySearch">
          </nh-field>
        </div>

      <div class="form-row justify-content-start">
        <div class="col-5 col-md-2 py-2 py-md-0 order-md-2">
          <button class="btn btn-block btn-secondary" (click)="onSearch()"> Search&nbsp; <i class="fa fa-search"></i></button>
        </div>
      </div>
      <br>
      <ngx-datatable *ngIf="(rows | async) || showTable" class='bootstrap striped' #table [rows]='result' [columnMode]='"force"' [headerHeight]='50' [footerHeight]='50' [rowHeight]='"auto"' [limit]="limit" [selected]="selected" [selectionType]="'single'" (select)="onSelect($event)" [messages]="{emptyMessage:'No results for your search'}">
      <ngx-datatable-column [name]="col.name" [prop]="col.prop" *ngFor="let col of columns"></ngx-datatable-column>
      <ngx-datatable-column name="Select">
        <ng-template id = "fain_select" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex" let-value="value" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
          <input type="checkbox" name="select" formControlName="select" [checked]="isSelected" (change)="onCheckboxChangeFn($event)">
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
    <br>
    <div class="col-lg-5 py-2 py-md-0 order-md-1 nursing-btn-styles">
      <button type="button" class="btn btn-block btn-secondary" (click)="onClick()">
        I can't find my nursing facility
        <img src="../../../../../assets/images/close-icon.svg" alt="close-icon">
      </button>
      <br>
    </div>

      <div *ngIf="showNursingDetails">
        <nh-field id = "fain_nursingFacilityName" label="Name of Nursing Facility" class="label-section" [mandatory]="!isNursingRowSelected">
          <input type="text" name="nursingFacilityName" class="form-control" formControlName="nursingFacilityName" [attr.disabled]="disableAddress ? true : null">
        </nh-field>

        <div [formGroup]="getControl('data.nursingFacilityAddress')">
            <nh-name-address [addressname] = "'hh_nursingFacilityAddress'"  [addressname] = "'fain'" [address]="getControl('data.nursingFacilityAddress.address')" [isAssistingPerson]="true" [tables]="tables" [showPopup]="false" [isDisabled]="disableAddress" [addressFieldMandatory]="!isNursingRowSelected"></nh-name-address>
        </div>
      </div>

      <div [formGroup]="getControl('data.admissionInfo')">
      <div *ngIf="admitDischarge === 'Admission'">
        <div class="admit-discharge-header"><span>Admission Information</span></div>
        <br>

        <nh-field id = "fain_admissionDt" label="Admission Date" [mandatory]="admissionDate" class="label-section">
          <nh-datepicker>
            <input type="text" name="admissionDt" class="form-control" formControlName="admissionDt" aria-label="admissionDt" [minDate]="minDate" [maxDate]="today" bsDatepicker nh>
          </nh-datepicker>
        </nh-field>
        <nh-field id = "fain_prevConfStartDt" label="Previous Confinement Start Date" [mandatory]="false" class="label-section">
          <nh-datepicker>
            <input type="text" name="prevConfStartDt" class="form-control" formControlName="prevConfStartDt" aria-label="prevConfStartDt" [minDate]="minDate" [maxDate]="today"
              bsDatepicker nh>
          </nh-datepicker>
        </nh-field>
        <nh-field id = "fain_prevConfEndDt" label="Previous Confinement End Date" [mandatory]="false" class="label-section">
          <nh-datepicker>
            <input type="text" name="prevConfEndDt" class="form-control" formControlName="prevConfEndDt" aria-label="prevConfEndDt" [minDate]="minDate" [maxDate]="today" bsDatepicker
              nh>
          </nh-datepicker>
        </nh-field>
        <nh-field id = "fain_hospiceSw" label="Is the patient receiving hospice care in this nursing facility?" [mandatory]="false" class="label-section">
          <select nh name="hospiceSw" [items]="tables.YESNO" formControlName="hospiceSw" aria-label="hospiceSw"></select>
        </nh-field>
        <nh-field id = "fain_stateVeteranSw" label="Is this nursing facility a state veterans home?" [mandatory]="false" class="label-section">
          <select nh name="stateVeteranSw" [items]="tables.YESNO" formControlName="stateVeteranSw" aria-label="stateVeteranSw"></select>
        </nh-field>
      </div>
    </div><br><br>
    <div [formGroup]="getControl('data.dischargeInfo')">
      <div *ngIf="admitDischarge === 'Discharge'">
        <div class="admit-discharge-header"><span>Discharge Information</span></div>
        <br>

        <nh-field id = "fain_dischargereason" label="Reason for Discharge" [mandatory]="true" class="label-section">
          <select nh name="dischargereason" [items]="tables.DISCHRSN" formControlName="dischargereason" aria-label="dischargereason"></select>
        </nh-field>
        <nh-field id = "fain_dischargeDt" *ngIf="showDischargeDate" label="Discharge Date" [mandatory]="true" class="label-section">
          <nh-datepicker>
            <input type="text" name="dischargeDt" class="form-control" formControlName="dischargeDt" [minDate]="minDate" [maxDate]="today" aria-label="dischargeDt" bsDatepicker nh>
          </nh-datepicker>
        </nh-field>
        <nh-field id = "fain_dateOfDeath" *ngIf="showDeathDate" label="Date of Death" [mandatory]="true" class="label-section">
          <nh-datepicker>
            <input type="text" name="dateOfDeath" class="form-control" formControlName="dateOfDeath" [minDate]="minDate" [maxDate]="today" aria-label="dateOfDeath" bsDatepicker nh>
          </nh-datepicker>
        </nh-field>
        <nh-field id = "fain_dischargeAddrSw" *ngIf="showDischargeDate" label="Do you have a new address to report for this person?" [mandatory]="true" class="label-section">
          <select nh name="dischargeAddrSw" [items]="tables.YESNO" formControlName="dischargeAddrSw" aria-label="dischargeAddrSw"></select>
        </nh-field>
        <div *ngIf="showDischargeDate && showNewAddress">
          <div [formGroup]="getControl('data.dischargeInfo.dischargeAddress')">
            <nh-name-address [addressname] = "'hh_dischargeAddress'" [address]="getControl('data.dischargeInfo.dischargeAddress.address')" [isAssistingPerson]="true" [tables]="tables" [showPopup]="false"></nh-name-address>
        </div>
      </div>
      </div>
    </div>
    </div>

  </div>
</nh-form>
