import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { mergeProps } from 'ramda-adjunct';

import { StateService } from '@app/services/state/state.service';
import { SectionComponent } from './section.component';

import { activable } from '@app/helpers/observable.helpers';

import { ApplicationNumber } from '@app/models/app.model';
import { SectionId } from '@app/models/section.model';

@Injectable()
export class SectionGuard implements CanActivate, CanDeactivate<SectionComponent> {
  constructor(private state: StateService) {}

  getSection(applicationNumber: ApplicationNumber, sectionId: SectionId): Observable<any> {
    return this.state.guardSection(applicationNumber, sectionId);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { appNumber, sectionId }: any = mergeProps(['params', 'data'], route);

    this.state.setSection();
    this.state.setPeople();

    return activable(this.getSection(appNumber, sectionId));
  }

  canDeactivate(section: SectionComponent): Observable<boolean> {
    return section.canDeactivate();
  }
}
