import { PatientInformationComponent } from './patient-information.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import Vs from "@app/services/validators/validators.service";

const formValidation = (builder) => {

  const getMaskedSSN = (ssn) => {
    if(ssn === null || ssn === "0"){
      return null;
    }else{
      return "XXX - XX - " + ssn?.substr(ssn.length - 4);
    }
  }

  const data = builder.group({
    firstName: sessionStorage.getItem('firstName'),
    middleInitial: sessionStorage.getItem('middleInitial'),
    lastName: sessionStorage.getItem('lastName'),
    dateOfBirth: sessionStorage.getItem('dob'),
    ssn: getMaskedSSN(sessionStorage.getItem('ssn')),
    gender: sessionStorage.getItem('gender'),
    admitDischarge: [null, Vs.required],
    suffix: sessionStorage.getItem('suffix')
  })



  return { data };
};

export default sectionConfiguration(
  PatientInformationComponent,
  formValidation,
  ['PPAMDIS', 'GENDER'],
  [],
  {
    hasSummary: false,
    validateSubmit: true,
  }
);
