import { evolve, compose, map, isNil } from 'ramda';

import { LiquidComponent } from './liquid.component';

import { Validators } from '@angular/forms';
import Vs from '@app/services/validators/validators.service';
import { notYes } from '@app/helpers/tayn.helpers';
import { trudy } from '@app/helpers/function.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { controlWith } from '@app/helpers/form.helpers';
import { disableGet } from '@app/helpers/able.helpers';
import { GlobalVars } from '@app/models/tables.model';
import { sectionConfiguration, isTrust, showRetirementCodes, isQualified, showSearchAndAccCodes, resourcesCoOwnerGroupRowCreator, addressCreator, showAccountNumberCodes } from '@app/helpers/models.helpers';
import { dropdownValidator, totalValidator } from '@app/helpers/validators.helpers';
import liquidLabels from './liquid-labels';

const formValidation = (builder, recordData, params) => {
  const liquid = lookIn(recordData);

  const assetType = liquid(['assetType']);
  const jointOwnership = liquid(['jointOwnership']);

  const isTrustType = isTrust(assetType);

  const trustType = liquid(['trustInformation', 'trustType']);
  const trusteeName = liquid(['trustInformation', 'trusteeName']);
  const beneficiaryOfTrust = liquid(['trustInformation', 'beneficiaryOfTrust']);
  const ownTrust = liquid(['trustInformation', 'ownTrust']);

  const retireOrJobEnd = liquid(['retireOrJobEnd']);
  const isRetirement = showRetirementCodes(assetType);

  const beneficiaryOrDonor = liquid(['beneficiaryOrDonor']);
  const isDonor = isQualified(trustType);

  const accountNumber = liquid(['accountInformation', 'accountNumber']);
  const isAccNum = showAccountNumberCodes(assetType);

  const bankName = liquid(['accountInformation', 'bankSearch', 'bankName']);
  const institutionId = liquid(['accountInformation', 'bankSearch', 'institutionId']);
  const isBank = showSearchAndAccCodes(assetType);
  const assetAddress = addressCreator(liquid(['assetAddress']));
  assetAddress.state = [assetAddress.state, Vs.required],
  delete assetAddress.addrAaAeAp;
  delete assetAddress.addrApoFpo;
  delete assetAddress.county;


  const helpers = builder.group({
    search: builder.group({
      state: 'TN',
      bankName: [bankName, [Vs.maxLength(100), Vs.invalidName]],
      zipCode: ['', Vs.zipCode],
      institutionId: '',
      locationId: '',
    }),
  });

  const resourcesCoOwnerGroup = compose(
    (control: any) => builder.group(control),
    resourcesCoOwnerGroupRowCreator,
  );

  const coOwnersData = liquid(['jointOwnershipInformation']) && liquid(['jointOwnershipInformation']).length > 0 ? liquid(['jointOwnershipInformation']) : [{
    jointOwnerIndividual: '',
    outOfHouseHoldname: '',
    jointOwnerPercentage: '',
  }];
  const jointOwnershipInformation = map(resourcesCoOwnerGroup, coOwnersData.map((item: any) => {
    const individual = params.individuals.find((indi: any) => +indi.identifier === item.jointOwnerIndividual.individualId);
    let jointOwnerIndividual: any;
    if (liquid(['jointOwnershipInformation'])) {
      jointOwnerIndividual = individual ? { value : individual.name.fullName, code : individual.identifier } : GlobalVars.outSideOption;
    }
    return { ...item, jointOwnerIndividual };
  }));

  const data = builder.group(
    {
      assetType: [assetType, [Vs.required]],
      trustInformation: builder.group({
        trustType: [controlWith(trustType, !isTrustType), Vs.required],
        trusteeName: [controlWith(trusteeName, !isTrustType), [Vs.invalidName, Vs.required]],
        beneficiaryOfTrust: [controlWith(beneficiaryOfTrust, !isTrustType), Vs.required],
        ownTrust: [controlWith(ownTrust, !isTrustType), Vs.required]
      }),
      retireOrJobEnd: [controlWith(retireOrJobEnd, !isRetirement), Vs.required],
      beneficiaryOrDonor: [controlWith(beneficiaryOrDonor, !isDonor), Vs.required],
      assetAmount: [liquid(['assetAmount']), Vs.currency],
      accountInformation: builder.group({
        accountNumber: [controlWith(accountNumber, !isAccNum), [Vs.invalidName, Vs.maxLength(20)]],
        bankSearch: builder.group({
          institutionId: institutionId,
          bankName: [controlWith(bankName, false), [Vs.invalidName]]
        }),
      }),
      assetAddress: builder.group(assetAddress),
      jointOwnership: [jointOwnership, Vs.required],
      jointOwnershipInformation: builder.array(jointOwnershipInformation)
    },
    { validators: Validators.compose([totalValidator, dropdownValidator])});

  trudy(() => disableGet('jointOwnershipInformation', data), notYes(jointOwnership));
  trudy(() => disableGet('assetAddress', data), !isNil(bankName) && !isBank);

  return { helpers, data };
};

const transformIndividulaObj = (value) => ({
  name : value?.code === 'EMPTY' ? '' : value?.value,
  individualId: value?.code === 'EMPTY' ? 0 : value?.code
});

const postObject = evolve({
  jointOwnershipInformation: map(evolve({
    jointOwnerIndividual : transformIndividulaObj
  }))
});

export default sectionConfiguration(
  LiquidComponent,
  formValidation,
  ['YESNO', 'TRUSTTYPE', 'FINRESTYPE', 'STATE', 'COUNTY', 'BNDN'],
  [
    { name: 'Owner', pipe: 'individual' },
    { name: 'Type', prop: 'data.assetType.value' },
    { name: 'Bank or <br/> Company Name', prop: 'data.accountInformation.bankSearch.bankName' },
    { name: 'Total Value', prop: 'data.assetAmount', pipe: 'currency' },
  ],
  {...{}, ...liquidLabels},
  {
    postObject
  }
);
