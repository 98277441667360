export const pregnancyOne = {
    isPregnant: 'RMB_IS_PREGNANT',
    numberOfBabies: 'RMB_NUMBER_BABIES',
    dueDate: "RMB_DUE_DATE",
    noOfBabiesBorn: "RMB_HOW_MANY_BABIES",
    addBabiesSw: "PEOPLE_DELETE_STATIC_TEXT11",
    //hadBabySw: 'HAD_BABY_SW',
    stillPregSw: 'STILL_PREGNANT_SW'
}
export const education = {
    currentEnrollmentStatus: 'RMB_ENROLLMENT_STATUS'
}

export default {
    pregnancyOne: pregnancyOne,
    education: education,
    isWorkingFullTime: 'RMB_WORKING_FULLTIME',
    formerFosterCare: 'RMB_FORMERFROSTERCARE',
    helpWithEmergencyServiceBills: 'Has indiv experienced an emergency health problem and needs help paying for those emergency services?'
}