<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left" id="my-modal-title">{{ title | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
    </button>
  </div>
  <div class="card-background modal-body">
    <h6 class="section-font">{{'Types of Proof' | translate }}</h6>
    <div *ngIf="staticTextDisplay">
      <p>{{'TYPES_OF_PROOF_STATIC1' | translate }} <strong> {{'TYPES_OF_PROOF_STATIC2' | translate }} </strong>
      </p>
      <p>{{'TYPES_OF_PROOF_STATIC3' | translate }} <strong>{{'TYPES_OF_PROOF_STATIC4' | translate }}</strong> {{'TYPES_OF_PROOF_STATIC5' | translate }}
      </p>
    </div>

    <hr>
    <div id="accordion">
      <div class="card card-background p-3" *ngFor="let item of data ; index as i" (click)="item.show = !item.show">
        <div id="headingOneProof">
          <h6>
            <div data-toggle="collapse" attr.data-target="#collapse{{i}}" aria-expanded="true">
              <div class="d-flex justify-content-between align-items-center">
                <div class="item-key"><strong>{{item.key | translate }}</strong></div>
                <div class="item-key-symbol" *ngIf="item.show"><i class="fa fa-angle-up"></i></div>
                <div class="item-key-symbol" *ngIf="!item.show"><i class="fa fa-angle-down"></i></div>
              </div>
            </div>
          </h6>
        </div>

        <div id="collapse{{i}}" [ngClass]="{'show': item.show}" class="collapse" aria-labelledby="headingOne"
          data-parent="#accordion">
          <div class="card-body">

            <div *ngIf="item.key == 'TYPES_OF_PROOF_STATIC6'">
              <div class="row">
                <div class="col-12 col-md-6 pb-2">
                  <ul class="item-val" *ngFor="let itemVal of incomeProofColA">
                    <li>{{itemVal | translate }}</li>
                  </ul>
                </div>
                <div class="col-12 col-md-6 pb-2">
                  <ul class="item-val" *ngFor="let itemVal of incomeProofColB">
                    <li>{{itemVal | translate }}</li>
                  </ul>
                </div>
              </div>
            </div>

            <div *ngIf="item.key == 'TYPES_OF_PROOF_STATIC7'">
              <div class="criteriaTxt">{{generalHeader | translate }}</div>
              <ul class="item-val" *ngFor="let itemVal of generalProof">
                <li>{{itemVal | translate }}</li>
              </ul>
              <br>
              <div class="criteriaTxt">{{otherProof | translate }}</div>
              <br>
              <div class="criteriaTxt">{{listAHeader | translate }}</div>
              <ul class="item-val" *ngFor="let itemVal of listAProof">
                <li>{{itemVal | translate }}</li>
              </ul>
              <br>
              <div class="criteriaTxt">{{listBHeader | translate }}</div>
              <ul class="item-val" *ngFor="let itemVal of listBProof">
                <li>{{itemVal | translate }}</li>
              </ul>
            </div>

            <div *ngIf="item.key !== 'TYPES_OF_PROOF_STATIC6' && item.key !== 'TYPES_OF_PROOF_STATIC7'">
              <ul class="item-val" *ngFor="let itemVal of item.value">
                <li>{{itemVal | translate }}</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
