import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.scss']
})

export class ErrorPageComponent {
  currentDate: Date
  constructor(
    private router: Router,
    private titleService: Title
  ) {
    this.currentDate = new Date();
    this.titleService.setTitle('Oops Error');
  }

  url = this.router.url;
  mobileUser = this.url.includes('mobile-errorpage') ? true : false; 

  gotoHome() {
    this.router.navigateByUrl('/');
  }

}
