import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { createAccountData } from './create-account-data';
import { Router } from '@angular/router';
import { MetaService } from '@app/services/metaservice';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'nh-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateAccountComponent implements OnInit {

  constructor(public router: Router, private metaService: MetaService, private titleService: Title
    ) {
    this.titleService.setTitle('Create Account');
  }

  data = [];

  ngOnInit(): void {
    this.metaService.createCanonicalURL();
    this.data = [...createAccountData];
  }

  onPrevious() {
    this.router.navigate(['/']);
  }

}
