<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left" id="my-modal-title">{{'KATIE_BECKETT_TITLE' | translate}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon" />
    </button>
  </div>
  <div class="modal-body">
    <h6>
      <strong>{{'KATIE_BECKETT_HEADER' | translate}}</strong>
    </h6>
    <hr />
    <p>{{'KATIE_BECKETT_INFO' | translate}}</p>
  </div>
</div>