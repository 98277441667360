import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationService } from "@app/services/application/application.service";
import { isPartner } from '@app/helpers/mode.helpers';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "pre-term",
  templateUrl: "./pre-term.component.html",
  styleUrls: ["./pre-term.component.scss"],
})
export class PretermComponent implements OnInit {
  rows;
  columns = [{ name: "Case Number", prop: "caseNum" }, { name: "Due Date", prop: "renewalDueDate" }];
  indivId;
  userName;
  indivEncId;
  isPartner;
  applicationNum;
  pretermDuplicateSubmission = false;
  pretermRecentFaxSubmission = false;
  submittedNames = ""
  pretermsubmitted = new Set();
  response;
  defaultLanguage;
  and;
  translatedLang;

  constructor(
    private router: Router,
    private app: ApplicationService,
    private titleService: Title,
    public translateService: TranslateService,
  ) {
    this.titleService.setTitle('Complete Questions for Review');
  }

  ngOnInit(): void {
    this.userName = sessionStorage.getItem("userName");
    this.indivEncId = sessionStorage.getItem("individualId");
    const userType = sessionStorage.getItem('userType');
    this.isPartner = isPartner(userType);
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    if (this.defaultLanguage === 'en') {
      this.and = " and "
    } else {
      this.and = " y "
    }

    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.defaultLanguage = res.lang;
      if (this.defaultLanguage === 'en') {
        this.and = " and "
      } else {
        this.and = " y "
      }
      this.buildingHohandNonHohh()
    })
    this.buildingHohandNonHohh()
  }

  buildingHohandNonHohh() {
    if (this.userName) {
      this.indivId = sessionStorage.getItem("individualId");
      this.app.preTermData(this.indivId).subscribe((res: any) => {
        if (res) {
          this.response = res
          if (this.response?.caseDetails.length > 0) {
            this.rows = this.response.caseDetails;
            this.rows.forEach((i) => {
              i.hoHID = i.headOfHousehold?.indvId;
              if (i.headOfHousehold?.firstName) {
                const middleName = i.headOfHousehold?.middleInitial ? i.headOfHousehold?.middleInitial : "";
                const suffix = i.headOfHousehold?.suffix ? " " + i.headOfHousehold?.suffix + " " : "";
                i.hohName = suffix === "" ? `${i.headOfHousehold?.firstName}  ${middleName}  ${i.headOfHousehold?.lastName}` : `${i.headOfHousehold?.firstName}  ${middleName}  ${i.headOfHousehold?.lastName} ${suffix}`
              }
              i.eligibleIndividualDetails.forEach((k) => {
                if (k?.wpTaskInProgressFlag) {
                  this.pretermRecentFaxSubmission = true;
                }
                if (k?.applicationStatus === 'SUBMITTED') {
                  this.pretermDuplicateSubmission = true;
                }
              })

              const submittedData = i?.eligibleIndividualDetails.filter(obj => obj?.applicationStatus === 'SUBMITTED');
              submittedData.forEach((indiv) => {
                const middleName = indiv?.middleInitial ? indiv?.middleInitial : "";
                const suffix = indiv?.suffix ? " " + indiv?.suffix + " " : "";
                const fullName = suffix === "" ? `${indiv?.firstName}  ${middleName}  ${indiv?.lastName}` : `${indiv?.firstName}  ${middleName}  ${indiv?.lastName} ${suffix}`
                this.pretermsubmitted.add(fullName.trim()) // Add name to the Set, ensuring uniqueness
              })

              if (i?.eligibleIndividualDetails?.length === 1) {
                const indiv = i.eligibleIndividualDetails[0]
                const middleName = indiv?.middleInitial ? indiv?.middleInitial : "";
                const suffix = indiv?.suffix ? " " + indiv?.suffix + " " : "";
                i.nonHoh = suffix === "" ? `${indiv?.firstName}  ${middleName}  ${indiv?.lastName}` : `${indiv?.firstName}  ${middleName}  ${indiv?.lastName} ${suffix}`
              } else if (i?.eligibleIndividualDetails?.length === 2) {
                const indiv1 = i.eligibleIndividualDetails[0]
                const middleName1 = indiv1?.middleInitial ? indiv1?.middleInitial : "";
                const suffix1 = indiv1?.suffix ? " " + indiv1?.suffix + " " : "";
                i.nonHoh = suffix1 === "" ? `${indiv1?.firstName}  ${middleName1}  ${indiv1?.lastName}` : `${indiv1?.firstName}  ${middleName1}  ${indiv1?.lastName} ${suffix1}`
                const indiv2 = i.eligibleIndividualDetails[1]
                const middleName2 = indiv2?.middleInitial ? indiv2?.middleInitial : "";
                const suffix2 = indiv2?.suffix ? " " + indiv2?.suffix + " " : "";
                i.nonHoh = suffix2 === "" ?  i.nonHoh + this.and + `${indiv2?.firstName}  ${middleName2}  ${indiv2?.lastName}` : i.nonHoh + this.and + `${indiv2?.firstName}  ${middleName2}  ${indiv2?.lastName} ${suffix2}`
              } else if (i?.eligibleIndividualDetails?.length > 2) {
                const length = i.eligibleIndividualDetails.length
                i.eligibleIndividualDetails.forEach((j, index) => {
                  const indiv = j
                  if (index + 1 === length) {
                    const middleName = indiv?.middleInitial ? indiv?.middleInitial : "";
                    const suffix = indiv?.suffix ? " " + indiv?.suffix + " " : "";
                    i.nonHoh = suffix === "" ?  i.nonHoh + this.and + `${indiv?.firstName}  ${middleName}  ${indiv?.lastName}` : i.nonHoh + this.and + `${indiv?.firstName}  ${middleName}  ${indiv?.lastName} ${suffix}`
                  } else {
                    const middleName = indiv?.middleInitial ? indiv?.middleInitial : "";
                    const suffix = indiv?.suffix ? " " + indiv?.suffix + " " : "";
                    if (index === 0) {
                      i.nonHoh = suffix === "" ? `${indiv?.firstName}  ${middleName}  ${indiv?.lastName}` + ", " : `${indiv?.firstName}  ${middleName}  ${indiv?.lastName} ${suffix}` + ", "
                    } else if (index === length - 2) {
                      i.nonHoh = suffix === "" ? i.nonHoh + `${indiv?.firstName}  ${middleName}  ${indiv?.lastName}` : i.nonHoh + `${indiv?.firstName}  ${middleName}  ${indiv?.lastName} ${suffix}`
                    } else {
                      i.nonHoh = suffix === "" ? i.nonHoh + `${indiv?.firstName}  ${middleName}  ${indiv?.lastName}` + ", " : i.nonHoh + `${indiv?.firstName}  ${middleName}  ${indiv?.lastName} ${suffix}` + ", "
                    }
                  }
                })
              }
            })
            // converting the set to array and combining all names in to single string
            this.submittedNames = Array.from(this.pretermsubmitted).join(", ").replace(/, ([^,]*)$/, `${this.and} $1`);
          }
        }
      })
    }
  }

  pretermWelcomePage(row) {
    sessionStorage.setItem("appType", "RB");
    sessionStorage.setItem("caseNum", row?.caseNum);
    sessionStorage.setItem('isPreterm', "PRETERM");
    sessionStorage.setItem('eligibleIndivs', JSON.stringify(row));
    this.app
      .getPendingApplications(row?.caseNum, "RENEW_BENEFITS")
      .subscribe((res: any) => {
        if (res && res?.PRT?.length) {
          res.PRT.forEach(element => {
            if((element?.caseNumber === row.caseNum) || (element?.caseNumber === Number(row.caseNum))) {
              const caseDetails = {"applicationNumber": element?.applicationNumber, "caseNumber": element?.caseNumber, "headOfHousehold" : element?.headOfHousehold?.headOfHousehold, "startedBy": element?.headOfHousehold?.startedByName, 
                "startedOn": element?.headOfHousehold?.startedOn , "lastAccessedBy": element?.headOfHousehold?.lastAccessedBy, "lastAccessedOn": element?.headOfHousehold?.lastAccessedOn}
              sessionStorage.setItem('caseDetails', JSON.stringify(caseDetails));
              sessionStorage.setItem('appNum', element['applicationNumber'])
            }
          });
          this.router.navigateByUrl("/renewMyBenefit?caseNumber=" + row.caseNum);
        } else {
          this.router.navigateByUrl("/application/" + "REDETERMINATION");
        }
      });
  }
}
