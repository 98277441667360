<div class="row pl-1 pb-1 hover-red main-content-width">
  <a *ngIf="!inSummary && showLeave" class="btn btn-link hover-red pl-0" (click)="onLeave()">
    <svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
      <g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
        <path id="Combined_Shape" data-name="Combined Shape"
          d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
          transform="translate(0)" fill="#174a7c" />
      </g>
    </svg>
    <span class="pl-1">{{'BACK_TO_PREVIOUS_PAGE' | translate}}</span>
  </a>
  <a *ngIf="inSummary && showLeave" class="btn btn-link hover-red pl-0" (click)="onPrevious()">
    <svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
      <g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
        <path id="Combined_Shape" data-name="Combined Shape"
          d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
          transform="translate(0)" fill="#174a7c" />
      </g>
    </svg>
    <span class="pl-1">{{'BACK_TO_PREVIOUS_PAGE' | translate}}</span>
  </a>
</div>
<section class="border bg-white p-3 main-content-width">
  <div>
    <nh-tabset [applicationNumber]="applicationNumber" [tabs$]="sectionLinks$" type="modules" (navigate)="onNavigate($event)"></nh-tabset>
    <nh-tabset [applicationNumber]="applicationNumber" [tabs$]="blockLinks$" type="blocks" (navigate)="onBlock($event)" [blockId]="blockId"></nh-tabset>
  </div>
  <section id="records" *ngIf="inSummary && showRecords() && mode === 'NEW' && (checkBlock$ | async)">
    <nh-alert type="warning" [isOpen]="!this.showAlert">
      <div class="row">
        <div class="icon">
          <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
        </div>
        <div class="message">
          <span [innerHTML]="'TAX_SUMMARY_STATIC_TEXT1' | translate"></span>
        </div>
      </div>
    </nh-alert>

    <nh-alert type="success" [isOpen]="this.showAlert">
      <div class="row">
        <div class="icon">
          <img src="../../../../../assets/images/Icons/⚛️ icons-checkmark-outline.svg" alt="success" />
        </div>
        <div class="message">
          <span>{{'TAX_SUMMARY_STATIC_TEXT2' | translate}}</span>
        </div>
      </div>
    </nh-alert>

    <p class="static-text" [innerHTML]="'TAX_SUMMARY_STATIC_TEXT3' | translate"></p>
    <p class="static-text" [innerHTML]="'TAX_SUMMARY_STATIC_TEXT4' | translate"></p>
    <nh-table [columns]="columns" [rows]="records" [mode]="mode" [sectionId]="sectionId" (edit)="onEditBlock($event)" (delete)="onDelete($event)" (change)="onChange($event)" [individuals]="individuals"></nh-table>
    <div *ngIf="!showAlert" class="pt-1 hover-red add-another-left">
      <a class="btn btn-link btn-lg hover-red" (click)="onCreateBlock()">
        <span class="pr-2">{{'ADD_3' | translate}}</span>
        <svg id="_icons_add-circle-outline" data-name="⚛️ icons/add-circle-outline" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path id="Combined_Shape" data-name="Combined Shape" d="M0,9.75A9.75,9.75,0,1,1,9.75,19.5,9.761,9.761,0,0,1,0,9.75Zm1.5,0A8.25,8.25,0,1,0,9.75,1.5,8.259,8.259,0,0,0,1.5,9.75ZM9,13.514V10.5H6A.75.75,0,0,1,5.985,9H9V6a.75.75,0,0,1,1.5-.015V9h3a.75.75,0,0,1,.014,1.5H10.5v3a.75.75,0,0,1-1.5.014Z" transform="translate(2.25 2.25)" fill="#174a7c"/>
        </svg>
      </a>
    </div>
  </section>

  <section id="records" *ngIf="inSummary && showRecords() && mode !== 'NEW' && (checkBlock$ | async)">
    <nh-alert type="warning" [isOpen]="!this.showAlert">
      <div class="row">
        <div class="icon">
          <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
        </div>
        <div class="message">
          <span [innerHTML]="'TAX_SUMMARY_STATIC_TEXT1' | translate"></span>
        </div>
      </div>
    </nh-alert>
    <nh-alert type="success" [isOpen]="this.showAlert">
      <div class="row">
        <div class="icon">
          <img src="../../../../../assets/images/Icons/⚛️ icons-checkmark-outline.svg" alt="success" />
        </div>
        <div class="message">
          <span>{{'TAX_SUMMARY_STATIC_TEXT2' | translate}}</span>
        </div>
      </div>
    </nh-alert>
    <nh-alert type="warning" [isOpen]="this.showMovedOut">
      <div class="row">
        <div class="icon">
          <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
        </div>
        <div class="message">
          <span>{{'TAX_SUMMARY_MOVEDOUT' | translate}}</span>
        </div>
      </div>
    </nh-alert>

    <p *ngIf="this.isRedeModeOnly" class="static-text" [innerHTML]="'SUMMARY_STATIC_TEXT1' | translate"></p>
    <p *ngIf="this.isChangeMode" class="static-text" [innerHTML]="'TAX_SUMMARY_STATIC_TEXT5' | translate"></p>
    <nh-review-summary-table [columns]="columns" [mode]="mode" [rows]="records" (edit)="onEditBlock($event)" (delete)="onDelete($event)" (undo)="onUndo($event)" [sectionId]="sectionId" [individuals]="individuals" [labels]="labelData"></nh-review-summary-table>
    <div *ngIf="!showAlert" class="pt-1 hover-red add-another-left">
      <a class="btn btn-link btn-lg hover-red" (click)="onCreateBlock()">
        <span class="pr-2">{{'ADD_3' | translate}}</span>
        <svg id="_icons_add-circle-outline" data-name="⚛️ icons/add-circle-outline" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path id="Combined_Shape" data-name="Combined Shape" d="M0,9.75A9.75,9.75,0,1,1,9.75,19.5,9.761,9.761,0,0,1,0,9.75Zm1.5,0A8.25,8.25,0,1,0,9.75,1.5,8.259,8.259,0,0,0,1.5,9.75ZM9,13.514V10.5H6A.75.75,0,0,1,5.985,9H9V6a.75.75,0,0,1,1.5-.015V9h3a.75.75,0,0,1,.014,1.5H10.5v3a.75.75,0,0,1-1.5.014Z" transform="translate(2.25 2.25)" fill="#174a7c"/>
        </svg>
      </a>
    </div>
  </section>

  <ng-container nh-container #container></ng-container>
</section>
<!-- <div class="row pt-1 hover-red comments main-content-width">
  <a *ngIf="showComments" class="pl-lg-0 btn btn-link hover-red" (click)="onComment()">
    <span class="pr-1">{{'ADD_YOUR_COMMENTS' | translate}} {{ appKeyWord | translate }}</span>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 19.49">
      <g id="_icons_create-outline" data-name=" icons create-outline">
        <path id="Combined_Shape-287" data-name="Combined Shape-287" class="cls-1"
          d="M2.63,19.49A2.62,2.62,0,0,1,0,16.91V5.62A2.63,2.63,0,0,1,2.58,3h7.9a.75.75,0,0,1,.75.75.74.74,0,0,1-.74.75H2.63A1.12,1.12,0,0,0,1.5,5.6V16.87A1.12,1.12,0,0,0,2.61,18H13.88A1.12,1.12,0,0,0,15,16.89V8.24a.76.76,0,0,1,.74-.76.75.75,0,0,1,.76.75v8.64a2.62,2.62,0,0,1-2.59,2.62Zm4.92-7.55a.17.17,0,0,1,0-.18l.39-1.17A.42.42,0,0,1,8,10.41L16.47,2a.43.43,0,0,1,.6,0h0l.47.47a.42.42,0,0,1,0,.59L9.09,11.49a.5.5,0,0,1-.19.1L7.74,12H7.68a.21.21,0,0,1-.13-.05ZM18.17,1.85l-.53-.53a.39.39,0,0,1,0-.53h0l.58-.58a.76.76,0,0,1,1.07,0,.75.75,0,0,1,0,1.06l-.56.57a.4.4,0,0,1-.54,0h0Z" />
      </g>
</svg>
  </a>
</div> -->

<div class="mb-4"></div>

<nh-button-bar *ngIf="!inSummary" [disableContinue]="disableContinue()" [labelContinue]="labelContinue" (continue)="onTaxSave()" (saveExit)="saveAndExit()" [showExit]="true">
</nh-button-bar>

<nh-button-bar *ngIf="inSummary" [disableContinue]="disableNext" (continue)="onNext()" [showExit]="true" (saveExit)="saveAndExit()">
</nh-button-bar>
