import { State, Action, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { ApplicationService } from '@app/services/application/application.service';

import { setPeople } from './people.creators';
import { SetPeople, LoadPerson, LoadBlock } from './people.actions';
import { blockLinks } from './people.selectors';

import { setRecord } from '@app/state/section/section.creators';

import { PeopleStateModel } from '@app/models/state.model';
import { Person, Block } from '@app/models/record.model';
import { Links } from '@app/models/links.model';
import { Injectable } from '@angular/core';

export const peopleState: PeopleStateModel = {
  activePerson: null,
  activeBlock: null,
};

@State<any>({
  name: 'person',
  defaults: peopleState,
})

@Injectable()
export class PeopleState {
  constructor(private application: ApplicationService) {}

  @Selector()
  static activePerson(state: PeopleStateModel): Person | null {
    return state.activePerson;
  }

  @Selector()
  static activeBlock(state: PeopleStateModel): Block | null {
    return state.activeBlock;
  }

  @Selector()
  static blockLinks(state: PeopleStateModel): Links | null {
    return blockLinks(state);
  }

  @Action(SetPeople)
  setPeople({ patchState }, { payload }) {
    patchState(payload);
  }

  loadRecord(applicationNumber, identifier) {
    return this.application.loadRecord(applicationNumber, identifier);
  }

  @Action(LoadPerson)
  loadPerson({ dispatch }, { payload: { applicationNumber, identifier } }) {
    return this.loadRecord(applicationNumber, identifier).pipe(
      tap(activePerson => [
        dispatch(setPeople({ activePerson, activeBlock: null })), //
        dispatch(setRecord(activePerson)),
      ])
    );
  }

  @Action(LoadBlock)
  loadBlock({ dispatch }, { payload: { applicationNumber, identifier } }) {
    return this.loadRecord(applicationNumber, identifier).pipe(
      tap(activeBlock => dispatch(setPeople({ activeBlock })))
    );
  }
}
