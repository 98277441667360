<p *ngIf="isRmbRmc">{{'RENEWAL_TEXT' | translate}}</p>
<h6 class="person-label">{{selectedIndividual | fullname}}</h6>
<nh-form [topLevelName] = "'ut'" [individuals]="checkForDropdown ? finalListIndvs : individuals" [formGroup]="getForm()" [mode]="mode"
  topLevelQuestion="{{'UTILITIES_EXPENSES_STATIC_TEXT1' | translate }}"
  popupText="{{'UTILITIES_EXPENSES_STATIC_TEXT2' | translate }}"
  [individualQuestion]="'UTILITIES_EXPENSES_STATIC_TEXT3' | translate"
  (validate)="onValidate($event)">

  <section [formGroup]="getControl('data')">
    <h5 class="section-header">{{'UTILITIES_EXPENSES_STATIC_TEXT4' | translate }}</h5><hr>
    <nh-field id = "ut_housingTypeExpense" label="{{'UTILITIES_EXPENSES_STATIC_TEXT5' | translate }} {{selectedIndividual | fullname}}{{'UTILITIES_EXPENSES_STATIC_TEXT6' | translate }}" class="label-section">
      <select nh name="housingTypeExpense" [items]="tableCodeValue('SHELTERUTILITYEXPTYPE', 'data.housingTypeExpense')"
        [attr.disabled]="isHousingTypeExpenseDisabled" aria-label="expenseType" formControlName="housingTypeExpense"></select>
    </nh-field>
    <div [formGroup]="getControl('data.expensePaymentInformation')">
      <nh-field id = "ut_expenseFrequencyCode" label="{{'UTILITIES_EXPENSES_STATIC_TEXT7' | translate }} {{selectedIndividual | fullname}} {{'UTILITIES_EXPENSES_STATIC_TEXT8' | translate }} " class="label-section">
        <select nh name="expenseFrequencyCode" aria-label="frequencyCode" [items]="tableCodeValue('INCEXPPAYFREQUENCY', 'data.expensePaymentInformation.expenseFrequencyCode')"  formControlName="expenseFrequencyCode"></select>
      </nh-field>

      <nh-field id = "ut_expenseAmount" label="{{'UTILITIES_EXPENSES_STATIC_TEXT9' | translate }}" class="label-section">
        <nh-input-group prepend="$">
          <input nh type="decimal"  maxlength="7" name="expenseAmount" formControlName="expenseAmount" class="form-control">
        </nh-input-group>
      </nh-field>
    </div>
  </section>
</nh-form>