import { Inject, Input, OnInit, AfterContentInit, AfterViewInit, Directive } from '@angular/core';
import { ViewChild, ElementRef, QueryList } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';

import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import { compose } from 'ramda';
import { identity } from 'ramda';
import { isEmpty } from 'ramda';
import { isNil } from 'ramda';
import { propOr } from 'ramda';
import { equals } from 'ramda';
import { isNotNil } from 'ramda-adjunct';

import Vs from '@app/services/validators/validators.service';
import { SubscriptionsDirective } from './subscriptions.class';

import { FormComponent } from '@app/components/form/form.component';

import { value } from '@app/helpers/value.helpers';
import { enabled } from '@app/helpers/able.helpers';
import { anyCodes } from '@app/helpers/code.helpers';
import { focusFirst } from '@app/helpers/dom.helpers';
import { assign } from '@app/helpers/function.helpers';
import { isComplete } from '@app/helpers/types.helpers';
import { isNo, isYes } from '@app/helpers/tayn.helpers';
import { valueChanges } from '@app/helpers/form-group.helpers';
import { householdHead, updateIdentifier } from '@app/helpers/individual.helpers';
import { updateForm, getRawValue, get, setValue } from '@app/helpers/form.helpers';
import { isCreate, identifier, otherIndividuals } from '@app/helpers/record.helpers';
import { yesNoValue, getValue, hasValue, toggles } from '@app/helpers/forms.helpers';
import { disabledControl, notNilControl, setControl } from '@app/helpers/form-control.helpers';

import { Record } from '@app/models/record.model';
import { Tables, Table } from '@app/models/tables.model';
import { Individuals, Individual } from '@app/models/individual.model';
import { TopLevelAnswer, OnValidate, FormValidation } from '@app/models/section.model';
import { ControlPath, EffectFunction, UpdateFunction } from '@app/models/partials.model';
import { ApplicationNumber, Mode, ModuleId, SectionStatuses } from '@app/models/app.model';

@Directive()
export abstract class SectionFormDirective extends SubscriptionsDirective implements OnInit, AfterContentInit, AfterViewInit {
  @ViewChild(FormComponent, { read: ElementRef, static: false })
  form: QueryList<FormComponent>;
  public formGroup: FormGroup;

  @Input() public applicationNumber: ApplicationNumber;
  @Input() public topLevelAnswer: TopLevelAnswer;
  @Input() public record: Record;
  @Input() public individuals: Individuals;
  @Input() public members: Individuals;
  @Input() public programs: Table | any;
  @Input() public tables: Tables | any;
  @Input() public moduleId: ModuleId | any;
  @Input() public selectedIndividual: Individual;
  @Input() public applyingForHcbc: boolean;
  @Input() public applicationType: string;
  @Input() public applyingForNursingFacilities: boolean;
  @Input() public status: SectionStatuses;
  @Input() public mode: Mode;

  @Input() public earnedIncome: boolean;
  @Input() public showInternet: boolean;
  @Input() public showInternetInclude: boolean;
  @Input() public showNewActivities: boolean;

  @Input() public onValidate: OnValidate;

  @Input() private formValidation: FormValidation;

  public householdHead: Individual;
  public hasIndividual: boolean;
  public otherIndividuals: Individuals;

  abstract ngAfterContentInit(): void;

  constructor(@Inject(FormBuilder) public builder: FormBuilder) {
    super();
  }

  ngAfterViewInit() {
    try {
      focusFirst(this.form);
    } catch (ex) {}
  }

  protected setData(data) {
    setControl('data', data, this.getForm());
  }

  protected setHelpers(helpers) {
    setControl('helpers', helpers, this.getControl('extras'));
  }

  protected sectionData() {
    const { data, helpers } = this.formValidation(this.builder, this.record.data, {
      tables: this.tables,
      programs: this.programs,
      individuals: this.individuals,
      applyingForHcbc: this.applyingForHcbc,
      applicationType: this.applicationType,
      selectedIndividual: this.selectedIndividual,
      applyingForNursingFacilities: this.applyingForNursingFacilities,
      earnedIncome: this.earnedIncome,
      mode: this.mode,
      showInternet: this.showInternet,
      showInternetInclude: this.showInternetInclude,
      showNewActivities: this.showNewActivities,
      allRecords: this['records']
    });
    this.setData(data);
    this.setHelpers(helpers);
  }

  protected createIdentifier() {
    const { sectionId, individualId = null, recordId = null } = identifier(this.record);
    return this.builder.group({
      sectionId,
      individualId,
      recordId: [disabledControl(recordId, isNil)],
    });
  }

  protected getTableCodeValues(tableName, tableControl, lang, isAdditional?) {
    if (this.spanishTranslationsRequired()) {
    let dataControl;
    let isString;
    if (typeof tableControl === 'string') {
      isString = true;

    } else {
      isString = false;
    }
    if (!isString && tableName === 'YESNO') {
      dataControl = tableControl.value;
    } else if (tableName === 'ISMEXPENSETYPE') {
      tableControl = tableControl.controls.expenseType;
      dataControl = tableControl.value;
    } else if (tableName === 'MPDEDUCTIONS') {
      tableControl = tableControl.controls.deductionType;
      dataControl = tableControl.value;
    } else if (tableName === 'F' || tableName === 'M') {
      dataControl = tableControl.value;
    } else if (tableName === 'RELATIONTYPE' ) {
      dataControl = tableControl.value;
    } else if (tableName === 'GENDER' && isAdditional) {
      dataControl = tableControl.value;
    } else if (tableName === 'NAMESUFFIX' ) {
      dataControl = tableControl.value;
    } else {
      dataControl = this.getControl(tableControl)?.value;
    }

    const tableObj = [];
    let obj
    this.tables[tableName]?.forEach((element) => {

      obj = {
        'code': element.code,
      };

      if (lang === 'sp') {
        obj['value'] = element.spDescription
      } else {
        obj['value'] = element.description
      }
      if (lang === 'sp' && element.spDescription === undefined && tableName === 'MCONAME') {
        obj['value'] = element.description
      }

      if (lang === 'sp' && element.spDescription === undefined && tableName === 'MCONAME_UBW') {
        obj['value'] = element.description
      }
      tableObj.push(obj);
    });
    if (dataControl) {
      const tableVar = tableObj.filter((item) => item.code === dataControl.code);
      if ((tableName === 'GENDER' && isAdditional) || tableName === 'MPDEDUCTIONS' || tableName === 'ISMEXPENSETYPE' || tableName === 'F' || tableName === 'M' || tableName === 'RELATIONTYPE' || tableName === 'NAMESUFFIX' || (tableName === 'YESNO' && !isString)) {
        tableControl.patchValue(tableVar[0]);
      } else {
        this.getControl(tableControl).patchValue(tableVar[0]);
      }

    }
    return tableObj
  } else {
    return this.tables[tableName]
  }
  }

  protected setHouseholdHead() {
    this.householdHead = householdHead(this.members);
  }

  protected setHasIndividual() {
    this.hasIndividual = isNotNil(this.selectedIndividual);
  }

  protected setOtherIndividuals() {
    this.otherIndividuals = otherIndividuals(this.selectedIndividual, this.members);
  }

  protected setSelectedIndividual(individual) {
    this.selectedIndividual = individual;
  }

  protected createExtras() {
    return this.builder.group({
      individual: [notNilControl(this.selectedIndividual), Vs.required],
      topLevelAnswer: [this.topLevelAnswer, Vs.required],
    });
  }

  protected createForm() {
    this.formGroup = this.builder.group({
      identifier: this.createIdentifier(),
      extras: this.createExtras(),
    });
    this.setHouseholdHead();
    this.setHasIndividual();
    this.setOtherIndividuals();
    this.sectionData();
    this.createEvents();
  }

  protected setHeadAsIndividual() {
    setValue(this.householdHead, this.getControl('extras.individual'));
  }

  protected setHeadFromIndividuals (mem) {
    setValue(mem, this.getControl('extras.individual'));
  }

  protected createEvents() {
    this.registerToggle(
      this.getControl('extras.individual'),
      toggles([
        updateIdentifier(this.getControl('identifier.individualId')),
        individual => this.setSelectedIndividual(individual),
        () => this.setOtherIndividuals(),
      ])
    );
  }

  ngOnInit() {
    this.createForm();
    updateForm(this.formGroup);
  }

  protected controlChanges(update: UpdateFunction, control: AbstractControl | null, effect: EffectFunction = identity) {
    return valueChanges(control).pipe(
      distinctUntilChanged(),
      map(update),
      tap(effect),
      tap(() => updateForm(this.formGroup))
    );
  }

  protected registerChange(update: UpdateFunction, control: AbstractControl | null, effect: EffectFunction = identity) {
    this.add(this.controlChanges(update, control, effect).subscribe());
  }

  protected updateWith(transformer, property) {
    return compose(
      propOr(null, property),
      assign(this),
      transformer(property)
    );
  }

  protected registerYesNo(property: string, control: AbstractControl | null, effect?: EffectFunction) {
    return this.registerChange(this.updateWith(yesNoValue, property), control, effect);
  }

  protected registerHas(property: string, control: AbstractControl | null, effect?: EffectFunction) {
    return this.registerChange(this.updateWith(hasValue, property), control, effect);
  }

  protected registerValue(property: string, control: AbstractControl | null, effect?: EffectFunction) {
    return this.registerChange(this.updateWith(getValue, property), control, effect);
  }

  protected registerToggle(control: AbstractControl | null, toggle: EffectFunction) {
    return this.registerChange(identity, control, toggle);
  }

  protected clearControl(path: ControlPath, name = '') {
    setControl(name, null, this.getControl(path));
  }

  protected updateForm() {
    return updateForm(this.formGroup);
  }

  getForm() {
    return this.formGroup;
  }

  getControl(path: ControlPath, group: FormGroup = this.formGroup): AbstractControl {
    return get(path, group);
  }

  getValue(path: ControlPath, group: FormGroup = this.formGroup): any {
    return value(this.getControl(path, group));
  }

  isEnabled(path: ControlPath, group: FormGroup = this.formGroup): boolean {
    const control = this.getControl(path, group);
    return enabled(control);
  }

  getRawValue(path: ControlPath): any {
    return getRawValue(this.getControl(path));
  }

  hasCodes(codes: string[], path: ControlPath): any {
    return anyCodes(codes, this.getValue(path));
  }

  isY(path: ControlPath): boolean {
    return isYes(this?.getValue(path));
  }

  hasValue(path: ControlPath): boolean {
    return isNotNil(this.getValue(path));
  }

  isN(path: ControlPath): boolean {
    return isNo(this?.getValue(path));
  }

  isEmpty(path: ControlPath): boolean {
    return isEmpty(this.getValue(path));
  }

  isCreate(): boolean {
    return isCreate(this.record);
  }

  isMode(mode) {
    return equals(mode, this.mode);
  }

  toggle(flag) {
    this[flag] = !this[flag];
  }

  isComplete() {
    return isComplete(this.status);
  }

  spanishTranslationsRequired(): boolean {
    const isMobileUser = JSON.parse(sessionStorage.getItem('isMobileUser'));
    if(!isMobileUser){
      return (sessionStorage.getItem('currentUser') !== null);
    }else{
      return true;
    }
  }
}
