import { Component } from '@angular/core';

import { BaseModalDirective } from '@app/components/modals/base-modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';

@Component({
  templateUrl: './income-deduction-modal.component.html',
  styleUrls: ['./income-deduction-modal.component.scss']
})
export class IncomeDeductionModalComponent extends BaseModalDirective {
  incomeValue = false;
  isConfrimDisabled = true;
  incomeModalSelectYes = false;
  incomeModalSelectNo = false;
  mailingAddressAlert = true;
  constructor(protected modalRef: BsModalRef, protected builder: FormBuilder) {
    super(modalRef, builder);
  }

  incomeDeductionValue(value) {
    this.incomeValue = value;
    this.isConfrimDisabled = false
  }

  changeOption(event) {
    const targetValue = event.target.value
    if (targetValue === 'Yes') {
      this.incomeModalSelectYes = event.target.checked
      this.incomeModalSelectNo = false;
    }
    else {
      this.incomeModalSelectYes = false
      this.incomeModalSelectNo = event.target.checked;
    }
    this.isConfrimDisabled = !(this.incomeModalSelectYes || this.incomeModalSelectNo);
    this.mailingAddressAlert = !(this.incomeModalSelectYes || this.incomeModalSelectNo);
  }


  handleConfirm() {
    if (this.incomeModalSelectYes) {
      this.onConfirm()
    }
    else {
      this.onCancel()
    }
  }
}
