import { Pipe, PipeTransform } from '@angular/core';

import { format } from 'date-fns';

@Pipe({ name: 'time' })
export class TimePipe implements PipeTransform {
  transform(value: number): string {
    return format(value, 'HH:mm:ss');
  }
}
