import { Component, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { keys, zip, map, zipObj } from 'ramda';

import { Table } from '@app/models/tables.model';

@Component({
  selector: 'demographics-checkbox-list',
  templateUrl: './demographics-checkbox-list.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DemographicsCheckboxListComponent implements OnInit {
  @Input() checkboxGroup: FormGroup;
  @Input() referenceTable: Table;
  @Input() label = null;
  @Input() inline = false;
  @Input() uniqueString: string;
  @Input() isNASelected: boolean;
  @Input() isUNSelected: boolean;
  @Input() selectedCode?: string;
  @Input() selectedCodes: string[] = [];
  @Output() valueChange = new EventEmitter<any>();

  checkboxList;

  ngOnInit() {
    const createCheckboxes = map(zipObj(['control', 'reference']));
    const zipControls = zip(keys(this.checkboxGroup.controls), this.referenceTable);
    this.checkboxList = createCheckboxes(zipControls);   
  }

  setFromList(value: any): void{
    this.valueChange.emit(value);
  }

  getCheckboxValue(reference: any, control: any): boolean{
    console.log(control);
    
    if(this.isCheckboxDisabled(reference) ){
     this.checkboxGroup.get(reference.code)?.patchValue(null);
     return false;
    }

    if(this.selectedCodes.includes(reference?.code)){
      this.checkboxGroup.get(reference.code)?.patchValue(reference);
      return true;
    }

    this.checkboxGroup.get(reference.code)?.patchValue(null);
    return false;
  }

  isCheckboxDisabled(reference: any): boolean{
    if(reference?.code !== 'UN' && this.isUNSelected === true && this.isNASelected === true){
      this.checkboxGroup.get(reference?.code)?.disable();
      return true;
    } 
     if(reference?.code !== 'UN' && this.isUNSelected === true  && this.isNASelected !== true){
      this.checkboxGroup.get(reference?.code)?.disable();
      return true;
    }
     if(reference?.code !== 'NA' && this.isNASelected === true && this.isUNSelected !== true){
      this.checkboxGroup.get(reference?.code)?.disable();
      return true;
    }
    this.checkboxGroup.get(reference?.code)?.enable();
    return false;
  }

}
