import { Component, AfterContentInit, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Vs from '@app/services/validators/validators.service';
import { ModalService } from '@app/services/modal/modal.service'
import { isYes } from '@app/helpers/tayn.helpers';
import { swapControl } from '@app/helpers/forms.helpers';
import { enabled } from '@app/helpers/able.helpers';
import { relationTypes } from '@app/helpers/person.helpers';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { toIdentifier, recordIndividual } from '@app/helpers/record.helpers';
import { ReferenceTableService } from '@app/gateway/services/referenceTable.service';
import { sortWith, descend, prop, equals, isNil } from 'ramda';
import { removeAt } from '@app/helpers/form-array.helpers';
import { minus120Years } from '@app/helpers/date.helpers';
import { isNilOrEmpty } from 'ramda-adjunct';
import { Title } from '@angular/platform-browser';
import { contains } from 'ramda';
import { ProcessService } from '@app/services/process.service';
import { Subscription } from 'rxjs';
@Component({
  templateUrl: './relationships-presumptive.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationshipsPresumptiveComponent extends SectionFormDirective implements AfterContentInit, OnInit {
  isPrimaryCareEnabled = false;
  relationTable;
  items = [];
  showModal = false;
  hasDependent = true;
  hasJointFiler = true;
  head;
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'individualId',
    textField: 'name',
    itemsShowLimit: 2,
    enableCheckAll: false
  };
  someArray: FormArray;
  pList: any;
  duplicateRelations = [];
  duplicateTaxDependents = [];
  taxDependentName = [];
  taxFilersEnabled;
  taxDependentsEnabled;
  val = {code: 'Y', value: 'Yes', spValue: "Si"};
  minimumDate = minus120Years();
  today: any = new Date();
  ifRequired = false;
  isRequired = false;
  error: any = { isError: false, errorMessage: '' };
  errorRelationSPO = { isError: false, errorMessage: '' };
  invaidRelation;
  invaidRelationOutside;
  invalidUnknownGenderRelation;
  invalidRel = "A child under the age of 10 cannot have a parent relationship or step parent relationship to another member of the household.";
  unknownGenderRelationError;
  unknownGenderRelationErrorEn = "Please review selection for #personA relationship to #personB.";
  duplicateTaxDepJFSub: Subscription;

  constructor(private modalService: ModalService, private referenceTableService: ReferenceTableService, builder: FormBuilder, 
    private titleService: Title, public processService: ProcessService,) {
    super(builder);
    this.referenceTableService.getCompleteRelationshipData().subscribe(({ referenceGroup }) => {
      this.relationTable = [...referenceGroup[0].referenceData]
    });
    this.titleService.setTitle('Presumptive Relationships');
    this.duplicateTaxDepJFSub = this.processService.duplicateTaxDepJFPresmInOutValidate.subscribe((action) => this.isDuplicateJFTaxDepPresm(action));
    this.processService.isDuplicateTaxDepJFOutsideInside = false;
  }

  showPrimaryCaregiverModal() {
    this.modalService.openPrimaryCaregivers()
  }

  identifier(control) {
    return recordIndividual(toIdentifier(control), this.individuals);
  }

  getGender(control) {
    return control?.gender?.code;
  }

  gender(control, idx) {
    const indiv = control?.value[idx]?.sourceIndividual;
    const sourceIndiv = this.individuals.find(i => i.identifier === indiv);
    return this.getGender(sourceIndiv);
  }

  get relationshipList(): FormArray {
    return this.getControl('data.householdRelationshipInformation.relationshipList') as FormArray;
  }

  get primaryCareList(): FormArray {
    return this.getControl('data.householdRelationshipInformation.primaryCareList') as FormArray;
  }

  get outsideHousehold(): FormArray {
    return this.getControl('data.outsideHousehold') as FormArray;
  }

  getOutsideHousehold(index): FormArray {
    return (this.outsideHousehold.at(index) as FormArray);
  }

  relationTypes() {
    const { RELATIONTYPE, RELATIONTYPE_GENDER } = this.tables;
    this.tables.F = relationTypes(['F', 'U'], RELATIONTYPE_GENDER, RELATIONTYPE);
    this.tables.M = relationTypes(['M', 'U'], RELATIONTYPE_GENDER, RELATIONTYPE);
    this.tables.U = relationTypes(['M', 'F', 'U'], RELATIONTYPE_GENDER, RELATIONTYPE);
  }

  get relationships(): FormArray {
    return this.getControl('data.householdRelationshipInformation.relationships') as FormArray;
  }

  isRelationshipEnabled(control) {
    return enabled(control);
  }

  fullname(name) {
    return name.fullName
  }

  name(id) {
    return this.individuals.filter(i => i.identifier === id)[0]?.name?.fullName;
  }

  getJointFiler(code) {
    return code === "SPO" ? true : false
  }

  removeAt(index, dependent) {
    const taxFilingData = this.taxFilingData.value;
    for (let i = 0; i < taxFilingData.length; i++) {
      this.duplicateTaxDependents[i] = false;
    }
    if (!isNil(dependent.value.taxDependentIndividualId) || !isNil(dependent.value.taxFilingIndividualId)) {
      this.modalService.confirmDelete().subscribe((res) => {
        if (res) {
          dependent.value.taxFilingIndividualId = null,
          dependent.value.taxDependentIndividualId = null,
          dependent.value.recordId = null,
          dependent.value.dependentOutsideHousehold = null,
          dependent.value.jointFilerSw = null,
          dependent.value.dependentIndvNumbers = null
          this.taxFilingData.removeAt(index);
          if (this.taxFilingData.length === 0) {
            this.addRow();
            this.error = {isError: false, errorMessage: ""};
          }
          const newTaxFilingData = this.taxFilingData.value;
          for (let i=0; i< newTaxFilingData.length; i++) {
            const jointFiler = this.formGroup.value.data.householdRelationshipInformation.relationshipList
            .filter(rel => (!isNil(newTaxFilingData[i]?.taxDependentIndividualId) && ((rel?.sourceIndividual === newTaxFilingData[i].taxFilingIndividualId) || (rel?.referenceIndividual === newTaxFilingData[i].taxFilingIndividualId)) && rel?.jointFilers?.code == "Y"));
            if (jointFiler.length) {
              this.error = { isError: true, errorMessage: "Joint filers should have the same tax dependents. Please list the same tax dependents for each joint filer." };
            }
          }
          this.displayError(newTaxFilingData);
        }
      })
    }
  }

  removeTaxDependentAt(index, row) {
    if (!isNil(row.value.taxFilerIndividual)) {
      this.modalService.confirmDelete().subscribe((res) => {
        if (res) {
            row.value.dependentBirthDate = null,
            row.value.dependentFirstName = null,
            row.value.dependentLastName = null,
            row.value.hasTaxDependentOutside = null,
            row.value.jointFiler = null,
            row.value.relation = null,
            row.value.sourceIndividual = null,
            row.value.taxDependent = null,
            row.value.taxFilerIndividual = null
            removeAt(index, this.outsideHousehold);
            this.isRequired = false;

            let test = 0;
            for (let i = 0; i < this.outsideHousehold.length; i++) {
              const relationSpouse = this.formGroup.value.data.householdRelationshipInformation.relationshipList.filter(rel => rel?.relation?.code == 'SPO');
              for (let j = 0; j < relationSpouse.length; j++) {
                  if (this.outsideHousehold.at(i).get('relation').value?.code === 'SPO' && relationSpouse.length && (this.outsideHousehold.at(i)?.value?.sourceIndividual === relationSpouse[j].sourceIndividual || this.outsideHousehold.at(i)?.value?.sourceIndividual === relationSpouse[j].referenceIndividual)) {
                    test == test++;
                       this.errorRelationSPO = { isError: true, errorMessage: 'You cannot have more than one legal spouse or Joint Filer.' };
                       } else if(test < 1) {
                      this.errorRelationSPO = { isError: false, errorMessage: '' };
                }
              }

            }
          if (this.outsideHousehold.length === 0) {
            this.addTaxDependentsRow();
            this.errorRelationSPO = { isError: false, errorMessage: '' };
          }
        }
      })
    }
  }

  addRow() {
    this.taxFilingData.push(this.createTaxHouse())
  }

  addTaxDependentsRow() {
    this.outsideHousehold.push(this.createTaxDep());
  }

  private createTaxDep(): FormGroup {
    return this.builder.group({
      name: null,
      birthDate: null,
      dependentBirthDate: null,
      dependentFirstName: null,
      dependentLastName: null,
      taxFilingIndividualId: null,
      jointFiler: null,
      taxDependent: null,
      taxFilerIndividual: null,
      sourceIndividual: null,
      relation: null,
      hasTaxDependentOutside: null,
    })
  }

  private createTaxHouse(): FormGroup {
    return this.builder.group({
      taxFilingIndividualId: null,
      taxDependentIndividualId: null,
      recordId: null,
      dependentOutsideHousehold: null,
      jointFilerSw: null,
      dependentIndvNumbers: null
    })
  }

  get taxFilingData() {
    return this.getControl('data.taxFilingData') as FormArray;
  }

  get householdRelationshipInformation() {
    return this.getControl('data.householdRelationshipInformation');
  }

  isfilingTax(individuals, idx) {
    const descendSort = sortWith([descend(prop('age'))]);
    const sortedIndvs = descendSort(individuals);
    const filing: any = {
      "code": "Y",
      "value": "Yes"
    };
    const filingDependent = {
      "code": "N",
      "value": "No"
    }
    for (let i = 0; i < sortedIndvs.length; i++) {
      if (i === idx) {
        if (sortedIndvs[i].taxFilerInfo.filing && sortedIndvs[i].taxFilerInfo.filing.code === 'Y') {
          return filing;
        } else {
          return filingDependent
        }
      }
    }
  }
  getTaxFilers(dependents) {
    const taxFilers = [];
    const descendSort = sortWith([descend(prop('age'))]);
    const sortedIndvs = descendSort(this.individuals);
    for (let i = 0; i < sortedIndvs.length; i++) {
      if (sortedIndvs[i].taxFilerInfo.filing && (sortedIndvs[i].taxFilerInfo.filing.code === 'Y' ||
      sortedIndvs[i].taxFilerInfo.hasTaxDependentOutside?.code === 'Y') && dependents === true) {
        taxFilers.push({
          "individualId": sortedIndvs[i].identifier,
          "prop": sortedIndvs[i].name.fullName + "," + sortedIndvs[i].age + "," + sortedIndvs[i].gender.code,
          "name": sortedIndvs[i].name.fullName
        });


      } else if ((isNil(sortedIndvs[i].taxFilerInfo.filing) ||
        sortedIndvs[i].taxFilerInfo.filing?.code === 'N') && dependents === false) {
        taxFilers.push({
          "individualId": sortedIndvs[i].identifier,
          "prop": sortedIndvs[i].name.fullName + "," + sortedIndvs[i].age + "," + sortedIndvs[i].gender.code,
          "name": sortedIndvs[i].name.fullName,


        });


      }
    }


    return taxFilers;
  }


  getPrimaryCare(code) {
    const codes = ["AUN", "BRO", "DAU", "FAO", "FCO", "GFO", "GMO", "MOO", "NEP", "NIE", "SBR", "SFA", "SMO", "SSI", "UNC", "SIS"];
    return codes.includes(code) ? true : false;
  }

  getDependent(code) {
    return code === "Y" ? true : false;
  }

  age(id) {
    return this.individuals.filter(i => i.identifier === id)[0]?.age;
  }
  fetchGender(id) {
    return this.individuals.filter(i => i.identifier === id)[0]?.gender?.code;
  }

  onRelationChange(type, row, index) {
    const existing = [];
    const existingPerson = [];
    const rels = this.relationshipList.value.filter(val => val.relation && val.relation.code === 'SPO');

    if (type === 'relationshipList') {
      for (let i = 0; i < rels.length; i++) {
        for (let j = i + 1; j < rels.length; j++ ) {
          if( rels[i].sourceIndividual === rels[j].referenceIndividual && 
            rels[i].referenceIndividual === rels[j].sourceIndividual) {
                rels.splice(j, 1);
          }
        }
      }
      
  
      let ctr = 0;
      for (let i = 0; i < this.relationshipList.length; i++) {
        console.log(this.relationshipList.at(i)?.value?.sourceIndividual);
       // const relationSpouse = this.formGroup.value.data.householdRelationshipInformation.relationshipList.filter(rel => rel?.relation?.code == 'SPO');
        for (let j = 0; j < rels.length; j++) {
          if (this.relationshipList.at(i).get('relation').value?.code === 'SPO' && rels.length > 1 
          && (this.relationshipList.at(i)?.value?.sourceIndividual === rels[j].sourceIndividual
          || this.relationshipList.at(i)?.value?.sourceIndividual === rels[j].referenceIndividual) 
          && ((rels.filter(e => e && e.sourceIndividual === rels[j].sourceIndividual).length > 1) 
          ||(rels.filter(e => e && e.referenceIndividual === rels[j].sourceIndividual).length > 0) 
          || (rels.filter(e => e && e.referenceIndividual === rels[j].referenceIndividual).length > 1))
          ) {
            ctr == ctr++;
            this.duplicateRelations[i] = true;
            this.getControl('data.relationshipValidation').setValidators(Vs.required);
            this.getControl('data.relationshipValidation').updateValueAndValidity();
            } else if(ctr < 1) {
          this.duplicateRelations[i] = false;
          this.getControl('data.relationshipValidation').clearValidators();
          this.getControl('data.relationshipValidation').updateValueAndValidity();
        }
      }
    }
    let count = 0;
      for (let i = 0; i < this.relationshipList?.length; i++) {
        for(let j = i+1; j < this.relationshipList?.length; j++ ){
        if(this.identifier(this.relationshipList.at(i).get('sourceIndividual')) === this.identifier(this.relationshipList.at(j).get('referenceIndividual')) 
          && this.identifier(this.relationshipList.at(i).get('referenceIndividual')) === this.identifier(this.relationshipList.at(j).get('sourceIndividual'))){
            const personRelation = this.relationshipList.at(i).get('relation').value?.code;
            const inverseRelation = this.relationshipList.at(j).get('relation').value?.code;
            if (inverseRelation && personRelation) {
              const validRel = this.getUnknownGenderRelation(personRelation, inverseRelation);
              console.log(validRel);
              if (!validRel) {
                count == count++;
                this.invalidUnknownGenderRelation = true;
                let unknownGenderRelationError1: string = '';
                unknownGenderRelationError1 = this.unknownGenderRelationErrorEn;
                unknownGenderRelationError1 = unknownGenderRelationError1.replace('#personA', this.identifier(this.relationshipList.at(i).get('sourceIndividual')).name.fullName);
                this.unknownGenderRelationError = unknownGenderRelationError1.replace('#personB', this.identifier(this.relationshipList.at(i).get('referenceIndividual')).name.fullName);
                this.getControl('data.UnknownGenderValidation').setValidators(Vs.required);
                this.getControl('data.UnknownGenderValidation').updateValueAndValidity();
              } else  if(count < 1) {
                this.invalidUnknownGenderRelation = false;
                this.getControl('data.UnknownGenderValidation').clearValidators();
                this.getControl('data.UnknownGenderValidation').updateValueAndValidity();      
              }
            }
          }
      }
      }
    } else if (type === 'household') {
      for (let i = 0; i < this.outsideHousehold.length; i++) {
        existing.push(this.outsideHousehold.at(i).get('relation').value);
        existingPerson.push(this.relationshipList.at(i)?.get('sourceIndividual').value);
      }
      // const current = this.outsideHousehold.at(index)?.get('relation')?.value;
      const currentPerson = this.relationshipList.at(index)?.get('sourceIndividual')?.value;
     if (currentPerson && existingPerson.filter(p => p && p === currentPerson).length > 1) {
        // if (current && current.code === 'SPO' && existing.filter(e => e && e.code === current.code).length > 1) {
        //   this.duplicateHouseHoldRelations[index] = true;
        // } else {
        //   this.duplicateHouseHoldRelations[index] = false
        // }
      } else {
        this.duplicateRelations[index] = false
      }
      if (!isNilOrEmpty(row.value.taxFilerIndividual) || !isNilOrEmpty(row.value.dependentFirstName) || !isNilOrEmpty(row.value.dependentLastName) || !isNilOrEmpty(row.value.dependentBirthDate)) {
        this.outsideHousehold.at(index).get('relation').setValidators(Vs.required);
        this.outsideHousehold.at(index).get('relation').updateValueAndValidity();
        this.outsideHousehold.at(index).get('dependentFirstName').setValidators ([Vs.required, Vs.maxLength(22)]);
        this.outsideHousehold.at(index).get('dependentFirstName').updateValueAndValidity();
        this.outsideHousehold.at(index).get('dependentLastName').setValidators ([Vs.required, Vs.maxLength(22)]);
        this.outsideHousehold.at(index).get('dependentLastName').updateValueAndValidity();
        this.outsideHousehold.at(index).get('dependentBirthDate').setValidators([Vs.required, Vs.notBefore120Years, Vs.maxFutureDate, Vs.validDate]);
        this.outsideHousehold.at(index).get('dependentBirthDate').updateValueAndValidity();
        this.hasDependent = false;
        this.hasJointFiler = false;
        let ctr = 0;
        for (let i = 0; i < this.outsideHousehold.length; i++) {
          const relationSpouse = this.formGroup.value.data.householdRelationshipInformation.relationshipList.filter(rel => rel?.relation?.code == 'SPO');
          for (let j = 0; j < relationSpouse.length; j++) {
              if (this.outsideHousehold.at(i).get('relation').value?.code === 'SPO' && relationSpouse.length && (this.outsideHousehold.at(i)?.value?.sourceIndividual === relationSpouse[j].sourceIndividual || this.outsideHousehold.at(i)?.value?.sourceIndividual === relationSpouse[j].referenceIndividual)) {
                   ctr == ctr++;
                   this.errorRelationSPO = { isError: true, errorMessage: 'You cannot have more than one legal spouse or Joint Filer.' };
                   } else if(ctr < 1) {
                  this.errorRelationSPO = { isError: false, errorMessage: '' };
            }
          }
        
            this.outsideHousehold.at(i).get('dependentBirthDate').clearValidators();
            this.outsideHousehold.at(i).get('dependentBirthDate').updateValueAndValidity();
        }
      } else if (isNilOrEmpty(row.value.taxFilerIndividual) || isNilOrEmpty(row.value.dependentFirstName) || isNilOrEmpty(row.value.dependentLastName) || isNilOrEmpty(row.value.dependentBirthDate)) {
        this.outsideHousehold.at(index).get('relation').clearValidators();
        this.outsideHousehold.at(index).get('relation').updateValueAndValidity();
        this.outsideHousehold.at(index).get('dependentFirstName').clearValidators();
        this.outsideHousehold.at(index).get('dependentFirstName').updateValueAndValidity();
        this.outsideHousehold.at(index).get('dependentLastName').clearValidators();
        this.outsideHousehold.at(index).get('dependentLastName').updateValueAndValidity();
        this.outsideHousehold.at(index).get('dependentBirthDate').clearValidators();
        this.outsideHousehold.at(index).get('dependentBirthDate').updateValueAndValidity();
      }

      if (row.status === 'INVALID') {
        this.isRequired = true;
      } else {
        this.isRequired = false;
      }
    }
    const relative = this.identifier(row.get('referenceIndividual'));
    const tab = this.relationTable.filter(per => per.code === row.get('relation')?.value?.code)
    const obj = relative?.gender?.code === 'F' && tab ? this.getFemaleInverseObj(tab) : this.getMaleInverseObj(tab);
    row.patchValue({ 'inverse': obj });
    if(relative?.gender?.code === 'U') {
      row.patchValue({ 'inverse': null })
    }
    const sourseTenAndRefIndivUnderTen = ['DAU', 'GDO', 'NIE', 'SON', 'SDA', 'SSO', 'GSO', 'GMO', 'MOO', 'SMO']
    const refIndivUnderTen = ['DAU', 'GDO', 'SDA', 'SSO', 'SON', 'GSO']
    const sourceIndivUnderTen = ['SPO', 'HOS', 'AUN', 'FAO', 'GFO', 'GMO', 'MOO', 'SFA', 'SMO', 'UNC']
    const sourseAndRefIndivUnderTen = ['DAU', 'GDO', 'NIE', 'SON', 'SDA', 'SSO', 'GSO', 'FAO', 'GFO', 'GMO', 'MOO', 'SFA', 'SMO']
    const invalidRel = []
    const invalidRelOutsideHouseHold = []
    if (type === 'relationshipList') {
      for (let i = 0; i < this.relationshipList.length; i++) {
        const relative = this.identifier(this.relationshipList.at(i).get('referenceIndividual'));
        const sourseIndiv = this.identifier(this.relationshipList.at(i).get('sourceIndividual'));
        const relation = this.relationshipList.at(i).get('relation').value?.code
        const sourceUnderTen = relative.age > 10 && sourseIndiv.age < 10 && contains(relation, sourceIndivUnderTen)
        const refUnderTen = relative.age <= 10 && sourseIndiv.age > 10 && contains(relation, refIndivUnderTen)
        const sourceTenAndRefUnderTen = relative.age < 10 && sourseIndiv.age === 10 && contains(relation, sourseTenAndRefIndivUnderTen)
        const sourceAndRefUnderTen = relative.age < 10 && sourseIndiv.age < 10 && contains(relation, sourseAndRefIndivUnderTen)
        if (refUnderTen || sourceUnderTen || sourceAndRefUnderTen || sourceTenAndRefUnderTen) {
          invalidRel.push({ relative, sourseIndiv })
        }
      }
    } else if (type === 'household') {
      for (let i = 0; i < this.outsideHousehold.length; i++) {
        const sourseIndiv = this.identifier(this.outsideHousehold.at(i).get('taxFilerIndividual'));
        const relation = this.outsideHousehold.at(i).get('relation').value?.code
        const refDob = this.outsideHousehold.at(i).get('dependentBirthDate').value;
        if (refDob) {
          const refAge = this.calculateAge(refDob)
          const sourceUnderTen = refAge > 10 && sourseIndiv.age < 10 && contains(relation, sourceIndivUnderTen)
          const refUnderTen = refAge <= 10 && sourseIndiv.age > 10 && contains(relation, refIndivUnderTen)
          const sourceTenAndRefUnderTen = refAge < 10 && sourseIndiv.age === 10 && contains(relation, sourseTenAndRefIndivUnderTen)
          const sourceAndRefUnderTen = refAge < 10 && sourseIndiv.age < 10 && contains(relation, sourseAndRefIndivUnderTen)
          if (refUnderTen || sourceUnderTen || sourceAndRefUnderTen || sourceTenAndRefUnderTen) {
            invalidRelOutsideHouseHold.push({ relative, sourseIndiv })
          }
        }
      }
    }
    if (invalidRel.length) {
      this.invaidRelation = true;
      this.getControl('data.hiddenRelationField').setValidators(Vs.required);
      this.getControl('data.hiddenRelationField').updateValueAndValidity();
    } else {
      this.invaidRelation = false;
      this.getControl('data.hiddenRelationField').clearValidators();
      this.getControl('data.hiddenRelationField').updateValueAndValidity();      
    }
    if (invalidRelOutsideHouseHold.length) {
      this.invaidRelationOutside = true;
      this.getControl('data.hiddenField').setErrors({ INVALID_INDIV: true });
    } else {
      this.invaidRelationOutside = false;
      this.getControl('data.hiddenField').setErrors(null)
    }
    this.relationStatus();
    this.outsideHouseholdRelationChange(index);
    this.primaryCaregivers(this.relationshipList);
  }
 
  getUnknownGenderRelation(relation1, relation2){
    switch (relation1) {
      case 'AUN':
        return ['NEP', 'NIE'].includes(relation2)
        break;
      case 'BRO':
        return ['SIS', 'BRO'].includes(relation2)
        break;
      case 'DAU':
        return ['MOO', 'FAO'].includes(relation2)
        break;
      case 'FAO':
        return ['DAU', 'SON'].includes(relation2)
        break;
      case 'FCO':
        return ['FCO'].includes(relation2)
        break;
      case 'GDO':
        return ['GMO', 'GFO'].includes(relation2)
        break;
      case 'GSO':
        return ['GMO', 'GFO'].includes(relation2)
        break;
      case 'GFO':
        return ['GDO', 'GSO'].includes(relation2)
        break;
      case 'GMO':
        return ['GDO', 'GSO'].includes(relation2)
        break;
      case 'MOO':
        return ['DAU', 'SON'].includes(relation2)
        break;
      case 'NEP':
        return ['AUN', 'UNC'].includes(relation2)
        break;
      case 'NIE':
        return ['AUN', 'UNC'].includes(relation2)
        break;
      case 'NRT':
        return ['NRT'].includes(relation2)
        break;
      case 'RIO':
        return ['RIO'].includes(relation2)
        break;
      case 'SON':
        return ['FAO', 'MOO'].includes(relation2)
        break;
      case 'SBR':
        return ['SBR', 'SSI'].includes(relation2)
        break;
      case 'SDA':
        return ['SFA', 'SMO'].includes(relation2)
        break;
      case 'SFA':
        return ['SSO', 'SDA'].includes(relation2)
        break;
      case 'SPO':
        return ['SPO'].includes(relation2)
        break;
      case 'SMO':
        return ['SSO', 'SDA'].includes(relation2)
        break;
      case 'SSI':
        return ['SBR', 'SSI'].includes(relation2)
        break;
      case 'SSO':
        return ['SFA', 'SMO'].includes(relation2)
        break;
      case 'UNC':
        return ['NEP', 'NIE'].includes(relation2)
        break;
      case 'SIS':
        return ['BRO', 'SIS'].includes(relation2)
        break;
      default:
        throw new Error('NoMatchFoundError')
    }
  }

  calculateAge(birthday) {
    const bdate = new Date(birthday);
    const timeDiff = Math.abs(Date.now() - bdate.getTime());
    const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
    return age;
  }

  relationChange(row, i) {   
    this.onRelationChange('household', row, i);
    if (this.getJointFiler(row?.value?.relation?.code) && !this.errorRelationSPO.isError) {
      this.outsideHousehold.at(i).get('jointFiler').setValidators(Vs.required);
      this.outsideHousehold.at(i).get('jointFiler').updateValueAndValidity();
      this.outsideHousehold.at(i).get('taxDependent').clearValidators();
      this.outsideHousehold.at(i).get('taxDependent').updateValueAndValidity();
      this.outsideHousehold.at(i).get('dependentBirthDate').clearValidators();
      this.outsideHousehold.at(i).get('dependentBirthDate').updateValueAndValidity();
    } else if (!this.getJointFiler(row?.value?.relation?.code)) {
      this.outsideHousehold.at(i).get('taxDependent').setValidators(Vs.required);
      this.outsideHousehold.at(i).get('taxDependent').updateValueAndValidity();
      this.outsideHousehold.at(i).get('jointFiler').clearValidators();
      this.outsideHousehold.at(i).get('jointFiler').updateValueAndValidity();
    }
  
    this.onNextDisable();
  }

  relationStatus() {
    this.relationshipList.controls.forEach((row, index) => {
      if (!this.getPrimaryCare(row.value?.relation?.code)) {
        this.relationshipList.at(index).get('primaryCaregiver').clearValidators();
        this.relationshipList.at(index).get('primaryCaregiver').updateValueAndValidity();
        this.relationshipList.at(index).get('primaryCaregiver').setValue(null);
      }
      if (!this.getJointFiler(row.value?.relation?.code)){
        this.relationshipList.at(index).get('jointFilers').clearValidators();
        this.relationshipList.at(index).get('jointFilers').updateValueAndValidity();
      }
    })
  }

  outsideHouseholdRelationChange(index) {
    if (this.outsideHousehold.at(index)?.get('relation')?.value) {
      const outsideHouseholdTaxDependent = this.outsideHousehold.at(index)?.value?.taxDependent;
      const outsideHouseholdJointFiler = this.outsideHousehold.at(index)?.value?.jointFiler;
      if (outsideHouseholdTaxDependent) {
        this.outsideHousehold.at(index)?.get('jointFiler')?.disable();
      } else if (!outsideHouseholdTaxDependent) {
        this.outsideHousehold.at(index)?.get('jointFiler')?.enable();
      }
      if (outsideHouseholdJointFiler) {
        this.outsideHousehold.at(index)?.get('taxDependent')?.disable();
      } else if (!outsideHouseholdJointFiler) {
        this.outsideHousehold.at(index)?.get('taxDependent')?.enable();
      }
    } else {
      this.outsideHousehold.at(index)?.get('taxDependent')?.clearValidators();
    }
  }

  isDuplicateRelation(index) {
    return this.duplicateRelations[index];
  }

  isDuplicateTaxDependent(index) {
    return this.duplicateTaxDependents[index];
  }

  getUnique(arr, comp) {
    const unique = arr.map(e => e[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((e) => arr[e]).map(e => arr[e]);
    return unique;
  }

  getFemaleInverseObj(tab) {
    const val = this.relationTable.filter(per => per.code === tab[0].fInverse)
    const obj = { code: tab[0].fInverse, value: val[0]?.description, possibleCaregiver: val[0]?.possibleCaregiver };
    return obj
  }

  getMaleInverseObj(tab) {
    const val = this.relationTable.filter(per => per.code === tab[0]?.mInverse)
    const obj = { code: tab[0]?.mInverse, value: val[0]?.description, possibleCaregiver: val[0]?.possibleCaregiver }
    return obj;
  }

  ngAfterContentInit() {
   const headofHousehold = this.individuals.filter(ind => ind.headOfHousehold === true);
    this.head = headofHousehold[0];
    this.relationTypes();

    const primaryCaregiverList = this.getControl('data.householdRelationshipInformation.primaryCareList');
    this.pList = primaryCaregiverList;
    const ctrls = primaryCaregiverList['controls'];
    ctrls.forEach((indivFormGroup, idx) => {
      this.registerToggle(indivFormGroup.controls['primaryCaregiver' + idx], swapControl(indivFormGroup.controls['primaryCaregiverIndividual' + idx], isYes));
    });
    for (let i = 0; i < this.relationshipList.length; i++) {
      this.duplicateRelations.push(false);
    }

    const descendSort = sortWith([descend(prop('age'))]);
    const sortedIndvs = descendSort(this.individuals);

    const taxFiler = [];
    const taxDependent = [];
    for (let i = 0; i < sortedIndvs.length; i++) {

      if (sortedIndvs[i].taxFilerInfo.filing && sortedIndvs[i].taxFilerInfo.filing.code === 'Y') {
        taxFiler.push(sortedIndvs[i].identifier);

      } else if (sortedIndvs[i].taxFilerInfo.filing && sortedIndvs[i].taxFilerInfo.filing.code === 'N') {
        taxDependent.push(sortedIndvs[i].identifier);
      }
    }
    if (taxFiler.length > 0) {

       this.taxFilersEnabled = true;
    } else {

      this.taxFilersEnabled = false;
    }
    if (taxDependent.length > 0) {

      this.taxDependentsEnabled = true;
    } else {

      this.taxDependentsEnabled = false;
    }

    this.getControl('data.outsideHousehold').valueChanges.subscribe(() => {
      if(this.processService.isDuplicateTaxDepJFOutsideInside){
        this.processService.isDuplicateTaxDepJFOutsideInside = false;
      }
    });
    this.getControl('data.taxFilingData').valueChanges.subscribe(() => {
      if(this.processService.isDuplicateTaxDepJFOutsideInside){
        this.processService.isDuplicateTaxDepJFOutsideInside = false;
      }
    });
    this.getControl('data.householdRelationshipInformation').valueChanges.subscribe(() => {
      if(this.processService.isDuplicateTaxDepJFOutsideInside){
        this.processService.isDuplicateTaxDepJFOutsideInside = false;
      }
    });
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  onSelected(id, event, index) {
    let indvsList = [];
    const relationList = this.getValue('data.householdRelationshipInformation.relationshipList');
    if (equals(event.target.value, 'Yes')) {
      const sourceRelations = relationList.filter(i => i.sourceIndividual === id.indvId);
      const referenceRelations = relationList.filter(i => i.referenceIndividual === id.indvId);

      const sourceCare = sourceRelations.filter(rel => rel.inverse.possibleCaregiver === 'Y_15' || rel.inverse.possibleCaregiver === 'Y');
      const referenCare = referenceRelations.filter(person => {
        const tab = this.relationTable.filter(per => per.code === person.relation.code);
        if (tab[0].possibleCaregiver === "Y_15" || tab[0].possibleCaregiver === "Y") {
          return person;
        }
      });

      referenCare.forEach(i => {
        const indv = i.sourceIndividual;
        const filtertedIndv = this.individuals.filter(idx => idx.identifier === indv);

        if (filtertedIndv.length > 0) {
          const obj = { individualId: filtertedIndv[0].identifier, name: `${this.fullname(filtertedIndv[0].name)} ${filtertedIndv[0].age} ${filtertedIndv[0].gender.code}` }
          indvsList = indvsList.concat(obj)
        }
      });

      sourceCare.forEach(i => {
        const indv = i.referenceIndividual;
        const filtertedIndv = this.individuals.filter(idx => idx.identifier === indv);

        if (filtertedIndv.length > 0) {
          const obj = { individualId: filtertedIndv[0].identifier, name: `${this.fullname(filtertedIndv[0].name)} ${filtertedIndv[0].age} ${filtertedIndv[0].gender.code}` }
          indvsList = indvsList.concat(obj);
        }
      })
      this.items[index] = indvsList;
    } else if (equals(event.target.value, 'No')) {
      relationList.filter((item: any) => {
        if (item.referenceIndividual === id.indvId) {
          item.primaryCaregiver = { code: 'N', value: "No" };
        }
      });
    }
  }

  onIndvSelect(event: any, sourceIndv) {
    this.formGroup.value.data.householdRelationshipInformation.relationshipList.filter(i => ((i.sourceIndividual === event.individualId) && (i.referenceIndividual === sourceIndv)))[0].primaryCaregiver = { code: 'Y', value: "Yes" };
  }

  onTaxChange(event: any, index: any) {
    this.duplicateTaxDependents[index] = false;
    const taxFilingData = this.taxFilingData.value;
    for (let i = 0; i < taxFilingData.length; i++) {
      this.duplicateTaxDependents[i] = false;
    }

    const jointFiler = this.formGroup.value.data.householdRelationshipInformation.relationshipList
      .filter(rel => (!isNil(taxFilingData[index]?.taxDependentIndividualId) && ((rel?.sourceIndividual === taxFilingData[index].taxFilingIndividualId) || (rel?.referenceIndividual === taxFilingData[index].taxFilingIndividualId)) && rel?.jointFilers?.code == "Y"));
    if (jointFiler.length) {
      this.error = { isError: true, errorMessage: "Joint filers should have the same tax dependents. Please list the same tax dependents for each joint filer." };
    }

    this.displayError(taxFilingData);

    if (event.target.value && this.taxDependentsEnabled && this.taxFilersEnabled) {
      this.taxFilingData.at(index).get('taxFilingIndividualId').setValidators(Vs.required);
      this.taxFilingData.at(index).get('taxFilingIndividualId').updateValueAndValidity();
      this.taxFilingData.at(index).get('taxDependentIndividualId').setValidators(Vs.required);
      this.taxFilingData.at(index).get('taxDependentIndividualId').updateValueAndValidity();
    } else {
      this.taxFilingData.at(index).get('taxFilingIndividualId').clearValidators();
      this.taxFilingData.at(index).get('taxFilingIndividualId').updateValueAndValidity();
      this.taxFilingData.at(index).get('taxDependentIndividualId').clearValidators();
      this.taxFilingData.at(index).get('taxDependentIndividualId').updateValueAndValidity();
    }
  }

  // TEDS-170578 - This function disables the Next button for Custom Error Messages On Tax dependents
  onNextDisable() {
    if (this.duplicateTaxDependents.filter(x => x).length > 0 || this.error.isError || this.errorRelationSPO.isError) {
      this.getControl('data.hiddenField').setValidators(Vs.required);
      this.getControl('data.hiddenField').updateValueAndValidity();
    } else {
      this.getControl('data.hiddenField').clearValidators();
      this.getControl('data.hiddenField').updateValueAndValidity();
    }
  }

  primaryCaregivers(relationships) {
    const primaryCareGiver = new Map<string, number>()
     relationships.value.forEach(i => {
      if( i.primaryCaregiver?.code === 'Y'){
        if(primaryCareGiver.has(i.referenceIndividual)){
          primaryCareGiver.set(i.referenceIndividual, primaryCareGiver.get(i.referenceIndividual) + 1)
        }
        else{
          primaryCareGiver.set(i.referenceIndividual, 1)
        }
      }

    })
        if (Array.from(primaryCareGiver.values()).some(count => (count > 2))) {
      this.ifRequired = true;
      this.getControl('data.hiddenField').setValidators(Vs.required);
      this.getControl('data.hiddenField').updateValueAndValidity();
    } else {
      this.ifRequired = false;
      this.getControl('data.hiddenField').clearValidators();
      this.getControl('data.hiddenField').updateValueAndValidity();
    }
  }

  onTaxFilingChange(type, person, index) {
    this.onRelationChange(type, person, index);
    const obj = person.get('taxFilerIndividual')?.value;
    const birthDate = this.individuals.filter(i => i.identifier === obj)[0]?.birthDate;
    let filing = this.individuals.filter(i => i.identifier === obj)[0]?.taxFilerInfo?.filing;
    if (filing && filing.code === 'N') {
      filing = this.individuals.filter(i => i.identifier === obj)[0]?.taxFilerInfo['hasTaxDependentOutside'];
    }
    const name = this.individuals.filter(i => i.identifier === obj)[0]?.name?.fullName;
    person.patchValue({
      'name': name,
      'birthDate': birthDate,
      'sourceIndividual': obj,
      'hasTaxDependentOutside': filing
    });
  }

  displayError(taxFilingData) {
    const taxDependents = this.getTaxFilers(false);
    const taxFilers = this.getTaxFilers(true);
    const taxFilerNonDupData = [];
    const taxFilersWithDependents = [];
    for (let i = 0; i < taxFilers.length; i++) {
      for (let j = 0; j < taxDependents.length; j++) {
        taxFilersWithDependents.push({"taxFilingIndividualId" : taxFilers[i].individualId, "taxDependentIndividualId" : taxDependents[j].individualId});
      }
    }
    for (let i = 0; i < taxFilingData.length; i++) {
      const taxFilerWithDependant = taxFilingData[i].taxFilingIndividualId?.concat(taxFilingData[i].taxDependentIndividualId)
      if (!(taxFilerNonDupData.includes(taxFilerWithDependant)) && !(taxFilerWithDependant?.includes("null")))
        taxFilerNonDupData.push(taxFilerWithDependant);
    }
    for (let i = 0; i < taxFilingData.length; i++) {
      for (let j = i + 1; j < taxFilingData.length; j++) {
        const relationship = this.formGroup.value.data.householdRelationshipInformation.relationshipList
        .filter(rel => ((rel.sourceIndividual === taxFilingData[i].taxFilingIndividualId) && (rel.referenceIndividual === taxFilingData[j].taxFilingIndividualId)) ||
        ((rel.referenceIndividual === taxFilingData[i].taxFilingIndividualId) && (rel.sourceIndividual === taxFilingData[j].taxFilingIndividualId)));
        if (taxFilingData[i].taxFilingIndividualId != taxFilingData[j].taxFilingIndividualId) {
          if (taxFilingData[i].taxDependentIndividualId == taxFilingData[j].taxDependentIndividualId) {
            if (taxFilerNonDupData.length == taxFilersWithDependents.length || relationship[0]?.jointFilers?.code == "Y") {
              this.error = {isError: false, errorMessage: ""};
            }
            if (!(relationship[0]?.jointFilers?.code == "Y")) {
              this.duplicateTaxDependents[j] = true;
              this.taxDependentName[j] = this.individuals.filter(x => (x.identifier == taxFilingData[i].taxFilingIndividualId))[0]?.name.fullName;
            }
          }
        } else if (taxFilingData[i].taxFilingIndividualId == taxFilingData[j].taxFilingIndividualId){
          if (!isNil(taxFilingData[j]?.taxDependentIndividualId) && taxFilingData[i].taxDependentIndividualId != taxFilingData[j].taxDependentIndividualId) {
            this.error = { isError: false, errorMessage: "" };
          } else if (!isNil(taxFilingData[j]?.taxDependentIndividualId) && taxFilingData[i].taxDependentIndividualId == taxFilingData[j].taxDependentIndividualId) {
            this.duplicateTaxDependents[j] = true;
            this.taxDependentName[j] = this.individuals.filter(x => (x.identifier == taxFilingData[i].taxFilingIndividualId))[0]?.name.fullName;
          }
        }
        if (taxFilerNonDupData.length == taxFilersWithDependents.length) {
          this.error = {isError: false, errorMessage: ""};
        }
      }
      this.onNextDisable();
    }
  }


  isDuplicateJFTaxDepPresm(action){
    const isSaveExit = equals(action, 'fromSaveExit') ? true : false;
    const formData = this.formGroup.value.data;

    const taxDepJFInOutIndvs =  JSON.parse(JSON.stringify(formData.outsideHousehold));
    if(isSaveExit && formData?.hasTaxDependentOutside?.code === 'N'){
      this.processService.isDuplicateTaxDepJFOutsideInside = true;
      isSaveExit ? this.processService.setIsfromTaxDepOrJFOutsideSaveExit('fromSaveExit') : '';
    }else{

      Object.keys(taxDepJFInOutIndvs).forEach((item) => {
        const checkDateGMT = (/\bGMT\b/).test(taxDepJFInOutIndvs[item]['dependentBirthDate']);
        const fullName = taxDepJFInOutIndvs[item].dependentFirstName + ' ' + taxDepJFInOutIndvs[item].dependentLastName;
        taxDepJFInOutIndvs[item]['fullName'] = fullName;

        if(checkDateGMT){
          taxDepJFInOutIndvs[item]['dependentBirthDate'] = this.getDate((taxDepJFInOutIndvs[item]['dependentBirthDate']).toLocaleDateString());
        }else{
          const checkDateHasT = (/T/).test(taxDepJFInOutIndvs[item]['dependentBirthDate']);
          if(checkDateHasT){
            taxDepJFInOutIndvs[item]['dependentBirthDate'] = taxDepJFInOutIndvs[item]['dependentBirthDate'].split("T")[0];
          }else{
            taxDepJFInOutIndvs[item]['dependentBirthDate'] = this.getDate((taxDepJFInOutIndvs[item]['dependentBirthDate']));
          }
        }
      });

      const indvsRows = [...this.members];
      let duplicateIndvRows = indvsRows.filter((el) => {
        return taxDepJFInOutIndvs.some((f) => {
          return (f.fullName?.toLowerCase() === el.name.fullName?.toLowerCase()) && (f.dependentBirthDate === el.birthDate);
        });
      });

      duplicateIndvRows = duplicateIndvRows.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.name.fullName.toLowerCase() === value.name.fullName.toLowerCase() && t.birthDate === value.birthDate
      )));

      const duplicateIndvNames = duplicateIndvRows.map(indv => indv.name.fullName);

      if(duplicateIndvNames.length>0){
        this.modalService.openDuplicateTaxDepOrJointFiler(duplicateIndvNames, isSaveExit,'taxdep').subscribe(response => {
          console.log(response);
          this.processService.isDuplicateTaxDepJFOutsideInside = true;
        });
      }else if(duplicateIndvNames.length<=0 && isSaveExit){
        this.processService.isDuplicateTaxDepJFOutsideInside = true;
        this.processService.setIsfromTaxDepOrJFOutsideSaveExit('fromSaveExit');
      }else{
        this.processService.noDuplicateTaxDepJFInOutAfterModal = true;
        this.processService.isNoDuplicateTaxDepJFPresmInOutValidate.next([true, this.formGroup.value.data]);
      };
    }

  }


  getDate(date){
    const checkSlash = (/\//).test(date);
    if(checkSlash){
      const dateParts = date.split("/");
      const formattedDate = dateParts[2] + "-" + dateParts[0].padStart(2,'0') + "-" + dateParts[1].padStart(2,'0');
      return formattedDate;
    }else{
      return date;
    }
  }

  ngOnDestroy() {
    this.duplicateTaxDepJFSub.unsubscribe();
  }

}