<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title pull-left" id="my-modal-title">{{ title }}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
    </button>
  </div>
  <div class="modal-body">
    <h5>
      <strong>Personal Information</strong>
    </h5>
    <p>
      These will be filled in for you, if you are signed into your account. You can delete this information, if you
      prefer to submit
      your feedback anonymously.
    </p>
    <form id='feedback' [formGroup]="feedbackForm">
      <nh-field id = "fs_name" label="Name">
        <input type="text" name="name" class="form-control" formControlName="name" required>
      </nh-field>

      <nh-field id = "fs_userId" label="User ID">
        <input type="text" name="userId" class="form-control" formControlName="userId" required>
      </nh-field>

      <nh-field id = "fs_appNumber" label="Case / Application Number">
        <input type="text" name="appNumber" class="form-control" formControlName="appNumber" required>
      </nh-field>
      <br>

      <h5>
        <strong>Contact Information</strong>
      </h5>
      <p>You can provide your email or phone number, if you would like to be contacted by us about your feedback.</p>
      <nh-field id = "fs_email" label="E-mail Address">
        <input type="email" name="email" class="form-control" formControlName="email" required>
      </nh-field>

      <nh-field id = "fs_phone" label="Phone Number">
        <input type="phone" name="phone" class="form-control" formControlName="phone" mask="000-000-0000" placeholder="XXX-XXX-XXXX"
          required>
      </nh-field>
      <br>

      <h5>
        <strong>Feedback</strong>
      </h5>
      <div>
        <label class="radio-inline checkbox" for="feedback-compliment" role="radio">
          <input id="fs_feedback-compliment" type="radio" name="type" value="Compliment" required>Compliment
        </label>

        <label class="radio-inline checkbox" for="feedback-problem" role="radio">
          <input id="fs_feedback-problem" type="radio" name="type" value="Problem" required>Problem
        </label>

        <label class="radio-inline checkbox" for="feedback-suggestion" role="radio">
          <input id="fs_feedback-suggestion" type="radio" name="type" value="Suggestion" required>Suggestion
        </label>
      </div>

      <p class="mt-2">Please rate your satisfaction with the site:</p>
      <span class="star-rating">
        <input id="fs_radio1" type="radio" name="rating" value="1" />
        <i></i>
        <input id="fs_radio2" type="radio" name="rating" value="2" />
        <i></i>
        <input id="fs_radio3" type="radio" name="rating" value="3" />
        <i></i>
        <input id="fs_radio4" type="radio" name="rating" value="4" />
        <i></i>
        <input id="fs_radio5" type="radio" name="rating" value="5" />
        <i></i>
      </span>

      <div class="row">
        <div class="col">
          <label for="" class="control-label">Comments</label>
          <textarea id = "fs_comments" class="form-control" rows="8" name="comments" formControlName="comments"></textarea>
        </div>
      </div>
    </form>

  </div>
  <!-- Standard markup for a 2 buttons footer; just change the button names & events -->
  <div class="modal-footer d-block">
    <div class="form-row justify-content-end">
      <div class="col-md-3 order-md-2">
        <button type="button" id="dialog" class="btn btn-block btn-primary" (click)="onConfirm()">Submit</button>
      </div>
      <div class="col-md-3 order-md-1">
        <button type="button" id="dialog" class="btn btn-block btn-link" (click)="onCancel()">Cancel</button>
      </div>
    </div>
  </div>
</div>
