<div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title pull-left" id="my-modal-title">{{'PRIMARY_CARE_GIVERS' | translate}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
        <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
      </button>
    </div>
    <div class="modal-body">
      <h5  class="section-header">
        {{'PRIMARY_CARE_GIVER_INFO' | translate}}
      </h5>
      <hr/>
      <p>
        {{'PRIMARY_CARE_GIVERS_STATIC_TEXT1' | translate}}
      </p>
    </div>
  </div>
