import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'nh-security-settings',
    templateUrl: './security-settings.component.html',
    styleUrls: ['./security-settings.component.scss']
})

export class SecuritySettingsComponent implements OnInit {
    ngOnInit() {}
}