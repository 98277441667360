import { map, either } from 'ramda';
import { compose } from 'ramda';
import { ifElse } from 'ramda';
import { equals } from 'ramda';
import { contains } from 'ramda';
import { filter } from 'ramda';
import { objOf } from 'ramda';
import { reject } from 'ramda';
import { prop } from 'ramda';
import { view } from 'ramda';

import { contained } from 'ramda-adjunct';
import { lensIso } from 'ramda-adjunct';

import { N, Y } from './tayn.helpers';
import { lookUp } from './flipped.helpers';
import { refValue } from './tables.helpers';
import { codeUnequal, codes, code } from './code.helpers';
import { identifier, identifierEqual } from './record.helpers';

import { TopLevelAnswer } from '@app/models/section.model';
import { ProviderTypes, UtilityLabels } from '@app/models/partials.model';
import { Statuses } from '@app/models/app.model';

export const tennessee = refValue('TN', 'Tennessee');
export const unitedStates = refValue('US', 'US');

export const CoverageTypes = [
  refValue('D', 'Dental Services'),
  refValue('T', 'Doctor Services'),
  refValue('H', 'Hospital Services'),
  refValue('N', 'Nursing Home'),
  refValue('P', 'Prescribed Drugs'),
  refValue('V', 'Vision Services'),
];

export const ApplicantTypes = [
  refValue('HOUSEHOLD_MEMBER', 'Household Member'),
  refValue('ASSISTING_PERSON', 'Assisting Person'),
];

export const CasePermissionTypes = [
  refValue('CC', 'Get, fill out, and sign applications, forms, and other DHHS paperwork for me'),
  refValue('NL', 'Get my notices from DHHS'),
  refValue('EI', 'Go to my eligibility interviews for me'),
  refValue('RC', 'Request and represent me at an appeal'),
  refValue('OT', 'Other'),
];

export const YesNoSkip = [
  refValue(TopLevelAnswer.YES, 'Yes'),
  refValue(TopLevelAnswer.YES, 'Si'),
  refValue(TopLevelAnswer.NO, 'No'),
  refValue(TopLevelAnswer.SKIP, 'Skip'),
];

export const YesNo = [refValue(TopLevelAnswer.YES, 'Yes'), refValue(TopLevelAnswer.NO, 'No'), refValue(TopLevelAnswer.YES, 'Si')];

export const isYES = equals(TopLevelAnswer.YES);
export const isNO = equals(TopLevelAnswer.NO);
export const isEMPTY = equals(TopLevelAnswer.EMPTY);

const notSKIP = codeUnequal(TopLevelAnswer.SKIP);

const filterSkip = ifElse(contains('FS'), () => YesNoSkip, () => filter(notSKIP, YesNoSkip));

export const topLevelAnswers = compose(
  filterSkip,
  codes
);

const toRelationship = map(
  compose(
    objOf('sourceIndividual'),
    identifier
  )
);

export const findRelatives = individualId =>
  compose(
    toRelationship,
    reject(identifierEqual(individualId))
  );

export const isTAFR = compose(
  contained(['4C', '5C']),
  code
);

export const isRegistered = equals(ProviderTypes.REGISTERED);
export const isCaptured = equals(ProviderTypes.CAPTURED);

const fromType = lookUp({
  CAPTURED: Y(),
  REGISTERED: N(),
});

const toType = compose(
  lookUp({
    Y: ProviderTypes.CAPTURED,
    N: ProviderTypes.REGISTERED,
  }),
  prop('code')
);

const providerTypeL = lensIso(toType, fromType);

export const toProviderType = view(providerTypeL);
export const fromProviderType = view(lensIso.from(providerTypeL));

export const moduleCongrats = [
  { moduleId: 'SUPPG', title: 'Finish', sections: [{ sectionId: 'SUCFM', title: null }] },
];

export const getUtility = lookUp(UtilityLabels);

export const isComplete = equals(Statuses.COMPLETE);
export const immigrationVerified = either(equals('S'), equals('V'));
