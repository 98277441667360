import { NewStartComponent } from './start.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';

// const formValidation = (fb, recordData) => ({data: fb.group(recordData)});
const formValidation = (fb) => {
  const item = {code: "MA", value: "Medical Coverage"}
   const data =  fb.group({
    selectedPrograms: fb.array([ item ])
   })
   return {data};
}

export default sectionConfiguration(NewStartComponent, formValidation, [], [], {}, {
  hasSummary: false,
  showSubmit: false,
  showComments: false,
  showSaveAndExit: false,
});
