import { AfterViewInit, Component } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ChangeDetectionStrategy, AfterContentInit } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { ModalService } from "@app/services/modal/modal.service";
import { Router } from "@angular/router";
import { ApplicationService } from "@app/services/application/application.service";
import { Identifier } from "@app/models/record.model";
import { equals } from 'ramda';
import { Title } from "@angular/platform-browser";

@Component({
  templateUrl: './newborn-summary.component.html',
  styleUrls: ['./newborn-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewbornSummaryComponent extends SectionFormDirective implements  AfterContentInit, AfterViewInit {

  newBornSummaryColumns = [{ name: 'Name/SSN', prop: 'personName' }, { name: 'Gender', prop: 'gender' }, { name: 'Date of Birth', prop: 'birthDate' }];
  newBornSummaryResults: any;
  newBornDuplicateSummaryResults: any;
  isDuplicateNewBornError: boolean = false;
  isDuplicateBabiesExist: boolean = false;

  constructor(private titleService: Title, builder: FormBuilder, private modalService: ModalService, private router: Router, private appService: ApplicationService) {
    super(builder);
    this.titleService.setTitle('Newborm Summary');
  }

  ngAfterContentInit() {}

  ngAfterViewInit() {
    let isCitenzFlag: boolean = false;
    let isImmigrationStatus: boolean = false;
    let childDeathdate: boolean = false;
    this.newBornSummaryResults = this['records'].map(({data, identifier}) => {
      if(data.usCtznSw && data.usCtznSw.code != 'Y' && !isCitenzFlag) {
        this.newBornSummaryColumns.push({ name: 'U.S. Citizen', prop: 'citizen' });
        isCitenzFlag = true;
      }
      if(data.vldImmgrtnSts && !isImmigrationStatus) {
        this.newBornSummaryColumns.push({ name: 'Immigration Status', prop: 'immgStatus' });
        isImmigrationStatus = true;
      }
      if(data.childDeathDt && !childDeathdate){
        this.newBornSummaryColumns.push({ name: 'Deceased', prop: 'deceased' });
        childDeathdate = true;
      }

      const personaNameFormator = (firstname, middlename, lastname, val) => {
        if (val) {
          val = val.replace(/\D/g, '');
          val = val.replace(/^(\d{3})/, '$1-');
          val = val.replace(/-(\d{2})/, '-$1-');
          val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
        } else {
          val = '';
        }
        if (middlename){
          return firstname +" "+ middlename +" "+ lastname + "<br>" + val;
        }  else{
          return firstname +" "+ lastname + "<br>" + val;
        }      
      }  

      const dateformater = (date) => {
        let newDate = date.split("-");
        return newDate[1]+"/"+newDate[2]+"/"+newDate[0];
      }

      return {
        personName: personaNameFormator(data.childFirstName, data.childMidName, data.childLastName, data.ssn),
        gender: data.genderCd.code,
        birthDate: dateformater(data.childDob),
        citizen: data.usCtznSw ? data.usCtznSw.value : 'N/A',
        immgStatus: data.alienStatusCd ? data.alienStatusCd.value : 'N/A',
        deceased: data.childDeathDt ? dateformater(data.childDeathDt) : 'N/A',
        recordId: identifier?.recordId,
        indivId: identifier.individualId,
        duplicateNewBorn: data.duplicateNewBorn
      }
    });

    this.newBornDuplicateSummaryResults =  this.newBornSummaryResults.filter((item) => item.duplicateNewBorn === 'Yes');

    if(this.newBornDuplicateSummaryResults?.length >= 1){
      this.isDuplicateNewBornError = true;
    }else{
      this.isDuplicateNewBornError = false;
    }
  
    this.isDuplicateBabiesExist = this['records'].some(item => item.data && (item.data.duplicateNewBorn === 'Y' || item.data.duplicateNewBornBaby === 'Y' || item.data.duplicatePerson === 'Y' || item.data.duplicateBabyAndPerson === 'Y'));
    window.scroll(0,0);
  }

  onNewBornRowEdit(event) {
    sessionStorage.setItem('recordId', event.recordId);
    this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/NBINF');
  }

  onNewBornRowDelete(event) {
    this.modalService.confirmDelete().subscribe((res) => {
     if (res) {
       let identifierObj: Identifier
       
       identifierObj = {"individualId" :  event.record.indivId, 
                         "recordId" : event.record.recordId,
                          "sectionId" :'NBSUM'}

       this.newBornSummaryResults.forEach((item, index) => {
        if (equals(item, event.record)) {
          const remainingRows = this.newBornSummaryResults.filter(row => row.recordId != event.record.recordId);
          this.appService.removeRecord(this.applicationNumber, identifierObj).subscribe(() => {
            this.newBornSummaryResults.splice(index, 1);
            this.newBornSummaryResults = remainingRows;
          })
        }
      });

      } 
    });
  }

  addNewBornBaby() {
    sessionStorage.setItem('recordId', null);
    this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/NBINF');
  }
}
