import { Injectable } from '@angular/core';
import { Store, Select } from '@ngxs/store';

import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { SectionState } from './section.state';
import { updateRecord, removeRecord, removeDataRecord, createRecord, handlePayload } from './section.creators';
import { updateSection, updatePeopleSumSection, loadSection, setSection, saveQuestionnaire } from './section.creators';

import { ApplicationState } from '@app/state/app/app.state';

import { setRecordId } from '@app/helpers/record.helpers';
import { applyMap, oOf, zipMerge } from '@app/helpers/observable.helpers';
import { viewSectionId, viewAfterRecord } from '@app/helpers/lenses.helpers';

// import { Links } from '@app/models/links.model';
import { TableCodes } from '@app/models/tables.model';
import { Record, Identifier } from '@app/models/record.model';
import { VerificationAnswer } from '@app/models/partials.model';
import { AppState, PayloadResponse } from '@app/models/http.model';
import { ApplicationNumber, ModuleId } from '@app/models/app.model';
import { SectionId, TopLevelAnswer, IsPartial } from '@app/models/section.model';

@Injectable()
export class SectionFacade {
  @Select(ApplicationState.sectionConfig) sections$: Observable<any>;

  @Select(SectionState.identifier) public identifier$: Observable<SectionId>;
  @Select(SectionState.topLevelAnswer) public topLevelAnswer$: Observable<TopLevelAnswer>;
  @Select(SectionState.tables) public tables$: Observable<any>;
  @Select(SectionState.records) public records$: Observable<any>;
  @Select(SectionState.earnedIncome) public earnedIncome$: Observable<any>;
  @Select(SectionState.showInternet) public showInternet$: Observable<any>;
  @Select(SectionState.showInternetInclude) public showInternetInclude$: Observable<any>;
  @Select(SectionState.showNewActivities) public showNewActivities$: Observable<any>;

  @Select(ApplicationState.title) title$: Observable<string>;
  @Select(ApplicationState.sectionStatus) sectionStatus$: Observable<SectionState>;
  @Select(ApplicationState.moduleId) moduleId$: Observable<ModuleId>;
  @Select(ApplicationState.sectionLinks) sectionLinks$: Observable<any>;
  @Select(ApplicationState.previousSection) previousSection$: Observable<SectionId>;

  constructor(protected store: Store) {}

  get sectionConfig$() {
    return this.identifier$.pipe(
      switchMap(sectionId => {
        return this.sections$.pipe(applyMap(sectionId));
      })
    );
  }

  get config$() {
    return zipMerge(
      this.asKey(this.title$, 'title'),
      this.asKey(this.moduleId$, 'moduleId'),
      this.asKey(this.identifier$, 'sectionId'),
      this.asKey(this.sectionStatus$, 'status'),
      this.asKey(this.previousSection$, 'previousSection')
    );
  }

  asKey(o$, key) {
    return o$.pipe(oOf(key));
  }

  loadSection(applicationNumber: ApplicationNumber, sectionId: SectionId, tableCodes: TableCodes): AppState {
    return this.store.dispatch(loadSection({ applicationNumber, sectionId, tableCodes }));
  }

  setSection(section) {
    return this.store.dispatch(setSection(section));
  }

  handlePayload(payload) {
    return this.store.dispatch(handlePayload(payload));
  }

  loadRecords(applicationNumber: ApplicationNumber, identifier: Identifier): PayloadResponse {
    return this.loadSection(applicationNumber, viewSectionId(identifier), []).pipe(map(viewAfterRecord));
  }

  createRecord(applicationNumber: ApplicationNumber, record: Record, isPartial: IsPartial, topLevelAnswer: TopLevelAnswer): PayloadResponse {
    return this.store
      .dispatch(createRecord({ applicationNumber, record, isPartial, topLevelAnswer }))
      .pipe(switchMap(() => this.loadRecords(applicationNumber, record.identifier)));
  }

  updateRecord(applicationNumber: ApplicationNumber, record: Record, isPartial: IsPartial): PayloadResponse {
    return this.store
      .dispatch(updateRecord({ applicationNumber, record, isPartial }))
      .pipe(switchMap(() => this.loadRecords(applicationNumber, record.identifier)));
  }

  removeRecord(applicationNumber: ApplicationNumber, identifier: Identifier): PayloadResponse {
    return this.store
      .dispatch(removeRecord({ applicationNumber, identifier }))
      .pipe(switchMap(() => this.loadRecords(applicationNumber, identifier)));
  }

  removeAppealDataRecord(applicationNumber: ApplicationNumber, identifier: Identifier, record: Record, exportingStatus?: String): PayloadResponse {
    exportingStatus = 'ADDED'
    return this.store
      .dispatch(removeDataRecord({ applicationNumber, identifier, record, exportingStatus}))
      .pipe(switchMap(() => this.loadRecords(applicationNumber, identifier)));
  }

  removeDataRecord(applicationNumber: ApplicationNumber, identifier: Identifier, record: Record, exportingStatus?: String): PayloadResponse {
    return this.store
      .dispatch(removeDataRecord({ applicationNumber, identifier, record, exportingStatus }))
      .pipe(switchMap(() => this.loadRecords(applicationNumber, identifier)));
  }

  updateSection(applicationNumber: ApplicationNumber, sectionId: SectionId, topLevelAnswer: TopLevelAnswer): AppState {
    return this.store.dispatch(updateSection({ applicationNumber, sectionId, topLevelAnswer }));
  }

  updatePeopleSumSection(applicationNumber: ApplicationNumber, sectionId: SectionId, answer: any): AppState {
    return this.store.dispatch(updatePeopleSumSection({ applicationNumber, sectionId, answer }));
  }

  saveQuestionnaire(applicationNumber: ApplicationNumber, verification: VerificationAnswer): PayloadResponse {
    return this.store.dispatch(saveQuestionnaire({ applicationNumber, verification })).pipe(map(viewAfterRecord));
  }

  deathConfirmation(applicationNumber: ApplicationNumber, record: Record): PayloadResponse {
    return this.updateRecord(applicationNumber, setRecordId('death-confirmation', record), 0);
  }
}
