import { evolve, map, compose } from 'ramda';

import { getIndividualTable, OtherBenefitsComponent } from './other-benefits.component';

import { sectionConfiguration } from '@app/helpers/models.helpers';
import { toDate, toISO } from '@app/helpers/date.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { checkboxHelper } from '@app/helpers/form-array.helpers';
import Vs from '@app/services/validators/validators.service';
import { isChangeReport } from '@app/helpers/mode.helpers';
import { isRedeMode } from '@app/helpers/mode.helpers';


export const otherBenefitsIndvProfileDataCreator = ({
  indvId,
  medicareIndSw,
  ssiBenefitsSw,
  brstCervCancerSw,
  medFacilitySw,
  medFacilityAdmitDt,
  hospitalName,
  nursingHomeSw,
  nursingHomeName,
  hospiceSw,
  hcbsSw,
  intelDisbSw,
  medicalBillsIndSw,
  expensesIndSw,
  transferredResIndSw,
  assetIndSw,
  ecfSw
 
   }) => ({
  indvId: [indvId],
  medicareIndSw: [medicareIndSw],
  ssiBenefitsSw: [ssiBenefitsSw],
  brstCervCancerSw: [brstCervCancerSw],
  medFacilitySw: [medFacilitySw],
  medFacilityAdmitDt: [toDate(medFacilityAdmitDt)],
  hospitalName: [hospitalName],
  nursingHomeSw: [nursingHomeSw],
  nursingHomeName: [nursingHomeName],
  hospiceSw: [hospiceSw],
  hcbsSw: [hcbsSw],
  intelDisbSw: [intelDisbSw],
  medicalBillsIndSw: [medicalBillsIndSw],
  expensesIndSw: [expensesIndSw],
  transferredResIndSw: [transferredResIndSw],
  assetIndSw: [assetIndSw],
  ecfSw: [ecfSw]

});

export const getOptedIndividuals = (individualTable, profileData = [], fieldName) => {
  const optedIndividulas = [];
  individualTable.forEach(element => {
    const data = profileData.find((item) => item.indvId === element.code);
    if (data && data[fieldName] && data[fieldName].code === 'Y'  ) {
      optedIndividulas.push({...element});
    }
  });
  return optedIndividulas;
}


const formValidation = (builder, recordData, { individuals }) => {
//   recordData = 
//   {
//     "otherBenefitsIndvProfileData": [
//         {
//             "indvId": "5000257855",
//             "medicareIndSw": null,
//             "brstCervCancerSw": null,
//             "medFacilitySw": null,
//             "nursingHomeSw": null,
//             "hospiceSw": null,
//             "hcbsSw": null,
//             "intelDisbSw": null,
//             "medicalBillsIndSw": null,
//             "ssiBenefitsSw": {
//                 "code": "N",
//                 "value": "No",
//                 "spValue": "No"
//             },
//             "updateDt": "2024-06-28",
//             "medFacilityAdmitDt": null,
//             "hospitalName": null,
//             "nursingHomeName": null,
//             "assetIndSw": {
//                 "code": "Y",
//                 "value": "Yes",
//                 "spValue": "Si"
//             },
//             "expensesIndSw": null,
//             "ecfSw": null,
//             "transferredResIndSw": null
//         },
//         {
//             "indvId": "5000257863",
//             "medicareIndSw": null,
//             "brstCervCancerSw": null,
//             "medFacilitySw": null,
//             "nursingHomeSw": null,
//             "hospiceSw": null,
//             "hcbsSw": null,
//             "intelDisbSw": null,
//             "medicalBillsIndSw": null,
//             "ssiBenefitsSw": {
//                 "code": "N",
//                 "value": "No",
//                 "spValue": "No"
//             },
//             "updateDt": "2024-06-28",
//             "medFacilityAdmitDt": null,
//             "hospitalName": null,
//             "nursingHomeName": null,
//             "assetIndSw": {
//                 "code": "Y",
//                 "value": "Yes",
//                 "spValue": "Si"
//             },
//             "expensesIndSw": {
//                 "code": "Y",
//                 "value": "Yes",
//                 "spValue": "Si"
//             },
//             "ecfSw": null,
//             "transferredResIndSw": null
//         },
//         {
//             "indvId": "5000257871",
//             "medicareIndSw": null,
//             "brstCervCancerSw": null,
//             "medFacilitySw": null,
//             "nursingHomeSw": null,
//             "hospiceSw": null,
//             "hcbsSw": null,
//             "intelDisbSw": null,
//             "medicalBillsIndSw": null,
//             "ssiBenefitsSw": {
//                 "code": "N",
//                 "value": "No",
//                 "spValue": "No"
//             },
//             "updateDt": "2024-06-28",
//             "medFacilityAdmitDt": null,
//             "hospitalName": null,
//             "nursingHomeName": null,
//             "assetIndSw": {
//                 "code": "Y",
//                 "value": "Yes",
//                 "spValue": "Si"
//             },
//             "expensesIndSw": {
//                 "code": "Y",
//                 "value": "Yes",
//                 "spValue": "Si"
//             },
//             "ecfSw": null,
//             "transferredResIndSw": null
//         },
//         {
//             "indvId": "5000257881",
//             "medicareIndSw": null,
//             "brstCervCancerSw": null,
//             "medFacilitySw": null,
//             "nursingHomeSw": null,
//             "hospiceSw": null,
//             "hcbsSw": null,
//             "intelDisbSw": null,
//             "medicalBillsIndSw": null,
//             "ssiBenefitsSw": {
//                 "code": "N",
//                 "value": "No",
//                 "spValue": "No"
//             },
//             "updateDt": "2024-06-28",
//             "medFacilityAdmitDt": null,
//             "hospitalName": null,
//             "nursingHomeName": null,
//             "assetIndSw": {
//                 "code": "Y",
//                 "value": "Yes",
//                 "spValue": "Si"
//             },
//             "expensesIndSw": {
//                 "code": "Y",
//                 "value": "Yes",
//                 "spValue": "Si"
//             },
//             "ecfSw": null,
//             "transferredResIndSw": null
//         }
//     ],
//     "medicalBillSw": null,
//     "assetInd": null,
//     "assetDisableSw": null,
//     "expenseDisableSw": {
//         "code": "Y",
//         "value": "Yes",
//         "spValue": "Si"
//     },
//     "medBillsDisableSw": {
//         "code": "N",
//         "value": "No",
//         "spValue": "No"
//     },
//     "kbReferralSw": null,
//     "expensesInd": {
//         "code": "Y",
//         "value": "Yes",
//         "spValue": "Si"
//     },
//     "othBenVisitedInd": {
//         "code": "Y",
//         "value": "Yes",
//         "spValue": "Si"
//     },
//     "appNum": "500179951",
//     "kePro": false,
//     "reassessment": false,
//     "transferredResInd": {
//       "code": "Y",
//       "value": "Yes",
//       "spValue": "Si"
//   },
//     "transferAssetDisableSw": {
//       "code": "Y",
//       "value": "Yes",
//       "spValue": "Si"
//   }
// }
  const currentMode = sessionStorage.getItem('mode');
  const changeMode = isChangeReport(currentMode);
  const otherBenefitData = lookIn(recordData);
  const individualTable = getIndividualTable(individuals);
  const renewMode = isRedeMode(currentMode);
  const medBillsIndividuals = changeMode ? individuals.filter(item => (item.age < 21 || item.pregnant) && item.someoneMovedIn === 'Y') 
                           : renewMode ? individuals 
                           : individuals.filter(item => (item.age < 21 || item.pregnant));
  const medicalBillsIndividualTable = getIndividualTable(medBillsIndividuals);
  const profileData = otherBenefitData(['otherBenefitsIndvProfileData']) || [];
  const medicareIndSwOptHelper = checkboxHelper(getOptedIndividuals(individualTable, profileData, 'medicareIndSw'), individualTable);
  const ssiBenefitsSwOptHelper = checkboxHelper(getOptedIndividuals(individualTable, profileData, 'ssiBenefitsSw'), individualTable);
  const brstCervCancerSwOptHelper = checkboxHelper(getOptedIndividuals(individualTable, profileData, 'brstCervCancerSw'), individualTable);
  const medicalBillsIndSwOptHelper = checkboxHelper(getOptedIndividuals(medicalBillsIndividualTable, profileData, 'medicalBillsIndSw'), medicalBillsIndividualTable);
  const medFacilitySwOptHelper = checkboxHelper(getOptedIndividuals(individualTable, profileData, 'medFacilitySw'), individualTable);
  const nursingHomeSwOptHelper = checkboxHelper(getOptedIndividuals(individualTable, profileData, 'nursingHomeSw'), individualTable);
  const hospiceSwOptHelper = checkboxHelper(getOptedIndividuals(individualTable, profileData, 'hospiceSw'), individualTable);
  const hcbsSwOptHelper = checkboxHelper(getOptedIndividuals(individualTable, profileData, 'hcbsSw'), individualTable);
  const intelDisbSwOptHelper = checkboxHelper(getOptedIndividuals(individualTable, profileData, 'intelDisbSw'), individualTable);
  const medicalBillSwOptHelper = checkboxHelper(getOptedIndividuals(individualTable,profileData, 'medicalBillSw'), individualTable);
  const expensesIndOptHelper = checkboxHelper(getOptedIndividuals(individualTable, profileData, 'expensesIndSw'), individualTable);
  const transferIndOptHelper = checkboxHelper(getOptedIndividuals(individualTable, profileData, 'transferredResIndSw'), individualTable);
  const assetIndSwOptHelper = checkboxHelper(getOptedIndividuals(individualTable, profileData, 'assetIndSw'), individualTable);
  const ecfIndSwHelper = checkboxHelper(getOptedIndividuals(individualTable, profileData, 'ecfSw'), individualTable);
  const expenseDisableSw = otherBenefitData(['expenseDisableSw']);
  const assetDisableSw = otherBenefitData(['assetDisableSw']);
  const medBillsDisableSw = otherBenefitData(['medBillsDisableSw']);
  const transferAssetDisableSw = otherBenefitData(['transferAssetDisableSw']);

  const otherBenefitsIndvProfileDataGroup = compose(
    (control: any) => builder.group(control),
    otherBenefitsIndvProfileDataCreator,
  );

  const individualsData = [];
    profileData.forEach(item => {
    individualsData.push(individuals.find((indi: any) => +indi.identifier === +item.indvId));
   });

  const inkindSupportListData = otherBenefitData(['otherBenefitsIndvProfileData']) || [{
    indvId: '',
    medicareIndSw: '',
    ssiBenefitsSw: '',
    brstCervCancerSw: '',
    medFacilitySw: '',
    medFacilityAdmitDt: '',
    hospitalName: '',
    nursingHomeSw: '',
    nursingHomeName: '',
    hospiceSw: '',
    hcbsSw: '',
    intelDisbSw: '',
    expensesIndSw: '',
    transferredResIndSw: '',
    assetIndSw: '',
    ecfSw: ''
  
  }];
  const otherBenefitsIndvProfileData = map(otherBenefitsIndvProfileDataGroup, inkindSupportListData);

  const helpers = builder.group({
    medicareIndSwOpt: builder.group(medicareIndSwOptHelper(individualTable)),
    ssiBenefitsSwOpt: builder.group(ssiBenefitsSwOptHelper(individualTable)),
    brstCervCancerSwOpt: builder.group(brstCervCancerSwOptHelper(individualTable)),
    medicalBillsIndOpt: builder.group(medicalBillsIndSwOptHelper(medicalBillsIndividualTable)),
    medFacilitySwOpt: builder.group(medFacilitySwOptHelper(individualTable)),
    nursingHomeSwOpt: builder.group(nursingHomeSwOptHelper(individualTable)),
    hospiceSwOpt: builder.group(hospiceSwOptHelper(individualTable)),
    hcbsSwOpt: builder.group(hcbsSwOptHelper(individualTable)),
    intelDisbSwOpt: builder.group(intelDisbSwOptHelper(individualTable)),
    medicalBillSwOpt: builder.group(medicalBillSwOptHelper(individualTable)),
    expensesIndOpt: builder.group(expensesIndOptHelper(individualTable)),
    transferIndOpt: builder.group(transferIndOptHelper(individualTable)),
    assetIndSwOpt: builder.group(assetIndSwOptHelper(individualTable)),
    ecfIndSwOpt: builder.group(ecfIndSwHelper(individualTable), {validator: Vs.required}),
    

    medicareIndSw: [otherBenefitData(['medicareIndSw'])],
    ssiBenefitsSw: [otherBenefitData(['ssiBenefitsSw'])],
    brstCervCancerSw: [otherBenefitData(['brstCervCancerSw'])],
    medFacilitySw: [otherBenefitData(['medFacilitySw'])],
    nursingHomeSw: [otherBenefitData(['nursingHomeSw'])],
    hospiceSw: [otherBenefitData(['hospiceSw'])],
    hcbsSw: [otherBenefitData(['hcbsSw'])],
    intelDisbSw: [otherBenefitData(['intelDisbSw'])],
    medicalBillsIndSw: [otherBenefitData(['medicalBillsIndSw'])],
    expensesIndSw : [otherBenefitData(['expensesIndSw'])],
    assetIndSw:  [otherBenefitData(['assetIndSw'])],
    ecfSw: [otherBenefitData(['ecfSw'])],
    transferredResIndSw : [otherBenefitData(['transferredResIndSw']),Vs.required],
 
  });

  const data = builder.group({
    otherBenefitsIndvProfileData: builder.array(otherBenefitsIndvProfileData),
    medicalBillSw: [otherBenefitData(['medicalBillSw'])],
    expensesInd: [otherBenefitData(['expensesInd'])],
    kbReferralSw: [otherBenefitData(['kbReferralSw'])],
    assetInd: [otherBenefitData(['assetInd'])],
    expenseDisableSw: expenseDisableSw,
    assetDisableSw: assetDisableSw,
    medBillsDisableSw: medBillsDisableSw,
    transferredResInd: [otherBenefitData(['transferredResInd']),Vs.required],
    transferAssetDisableSw: transferAssetDisableSw,
  });
  return { helpers, data };
};
const postObject = evolve({
  dateOfApplication: toISO,
  otherBenefitsIndvProfileData: map(evolve({
    medFacilityAdmitDt : toISO
  }))
});

export default sectionConfiguration(
  OtherBenefitsComponent,
  formValidation,
  ['TAYN', 'TABT', 'TBBO', 'MPDEDUCTIONS', 'YESNO'],
  [
    { name: 'Name', pipe: 'individual' },
    { name: 'Type', prop: 'data.benefitAppliedFor.value' },
    { name: 'Apply Date', prop: 'data.dateOfApplication', pipe: 'date' },
    { name: 'Status', prop: 'data.statusOfApplication.value' },
  ],
  {},
  {
    postObject,
    hasSummary: false,
    showComments: true,
  }
);