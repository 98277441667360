<p *ngIf="isRenewBenefits || isReportChange" >{{ "RENEWAL_TEXT" | translate }}</p><br>
<nh-form class="demographics" [individuals]="individuals" [formGroup]="getForm()" (validate)="onValidate($event)">

  <section [formGroup]="getControl('data')">
    <div *ngIf="record && record.data && record.data.householdHead" class="static-text">
      <h6 *ngIf="this.isPresumptive">
        Start with yourself, then add other people who live with you.
      </h6>
      <h6 *ngIf="!this.isPresumptive">
        {{'DEMOGRAPHICS_STATIC_TEXT_1' | translate}}
      </h6>
      <p [innerHTML]="'DEMOGRAPHICS_STATIC_TEXT_2' | translate"></p>
      <ul class="list-inline pl-1" [innerHTML]="'DEMOGRAPHICS_STATIC_TEXT_3' | translate"></ul><br>
      <p [innerHTML]="'DEMOGRAPHICS_STATIC_TEXT_4' | translate"></p>
      <ul class="list-inline pl-1" [innerHTML]="'DEMOGRAPHICS_STATIC_TEXT_5' | translate"></ul><br>
      <p [innerHTML]="'DEMOGRAPHICS_STATIC_TEXT_6' | translate"></p>
      <ul class="list-inline pl-1" [innerHTML]="'DEMOGRAPHICS_STATIC_TEXT_7' | translate"></ul><br>
    </div>

    <h6 *ngIf="!isPresumptive" class="person-label">
      <span *ngIf="record && record.data && record.data.householdHead">{{'HEAD_OF_HOUSEHOLD' | translate}}: </span>
      {{ selectedIndividual | fullname }}
    </h6>
    <p *ngIf="isPresumptive">
      <span *ngIf="record && record.data && record.data.householdHead"><strong>People You've Told Us About</strong>
      </span>
    </p>
    <h6 *ngIf="isPresumptive && !selectedIndividual?.age" class="person-label">{{ selectedIndividual?.name?.firstName}} {{ selectedIndividual?.name?.lastName}}</h6>
    <h6 *ngIf="isPresumptive && selectedIndividual?.age" class="person-label">{{ selectedIndividual?.name?.firstName}} {{ selectedIndividual?.name?.lastName}}, {{ selectedIndividual?.age}} {{ selectedIndividual?.gender?.code}}</h6>
    <br>
    <h5 class="section-header" *ngIf="isPresumptive">Individual Information</h5>
    <h5 class="section-header" *ngIf="!isPresumptive">{{'LINK_CASE_DESC_PERSONAL' | translate}}</h5>
    <hr>
    <nh-name-address [addressname] = "'de_name'" *ngIf="isEnabled('data.name')" [personalName]="getControl('data.name')" [tables]="tables"
    [namePopup]="displayPopup()" [isDemographics]='true' [rmcPopUp]="displayRmcPopup()" [selectedIndividualPopup]="isIndividualPopup()" [isExported]="exported?true:null">
    </nh-name-address>
    <span *ngIf="isEnabled('data.name')"><br></span>

    <nh-field id = "de_gender" *ngIf="isPresumptive" label="Gender" class="label-section">
      <select aria-label='gender' nh [name]="getControl('gender')" formControlName="gender" [items]="tables.GENDER"></select>
    </nh-field>

    <nh-field id = "de_birthDate" *ngIf="isPresumptive" label="Date of Birth" class="label-section">
      <nh-datepicker>
        <input aria-label='birthDate' type="text"  name="birthDate" class="form-control" (change)="isInvalidDate($event)" formControlName="birthDate" (bsValueChange)="isInvalidDate($event)" 
          bsDatepicker nh [minDate]="minimumDate" [maxDate]="today"  [attr.disabled]="exported?true:null">
      </nh-datepicker>
    </nh-field>

    <div *ngIf="!isPresumptive">
    <h5 class="section-header">{{'ALTERNATE_NAME' | translate}}</h5>
    <hr>
    <nh-field id = "de_appliedWithOtherName" label="{{'IS' |translate}} {{ selectedIndividual | fullname }} {{'OTHER_NAME' | translate}}"
      class="label-section">
      <select aria-label='appliedWithOtherName' nh name="appliedWithOtherName" formControlName="appliedWithOtherName"
      [items]="tableCodeValue('YESNO', 'data.appliedWithOtherName')"></select>
    </nh-field>
    <nh-name-address [addressname] = "'de_otherName'" *ngIf="isEnabled('data.otherName')" [personalName]="getControl('data.otherName')"
      [tables]="tables">
    </nh-name-address>
    </div>

    <span *ngIf="isSameName && !isPresumptive" class="form-text invalid-name d-block"> {{'ALTERNATE_INVALID_NAME' | translate}}</span>

    <div *ngIf="!isPresumptive">
      <br>
      <div>
        <h5 class="section-header">{{'PERSONAL_DETAILS' | translate}}</h5>
        <hr>
      </div>
      <nh-field id = "de_gender" label="{{'GENDER' | translate}}" class="label-section">
        <select aria-label='gender'  [attr.disabled]="exported?true:null"  nh name="gender" formControlName="gender" [items]="tableCodeValue('GENDER', 'data.gender')"></select>
      </nh-field>

      <nh-field id = "de_birthDate" label="{{'DOB' | translate}}" class="label-section">
        <nh-popover *ngIf="isRenewBenefits || isIndividualPopup()" class="pl-1" content="{{'RMB_DOB_TEXT' | translate}}"></nh-popover>
        <nh-datepicker [readOnly]="exported?true:false">
          <input aria-label='birthDate' type="text" name="birthDate" class="form-control"
            (change)="isInvalidDate($event)" formControlName="birthDate" bsDatepicker nh [minDate]="minimumDate"
            [maxDate]="today" [attr.disabled]="exported?true:null"> 
        </nh-datepicker>
      </nh-field>
    </div>
    <br>

    <h5 class="section-header">{{'APPLYING_FOR_COVERAGE' | translate}}</h5>
    <hr>
    <p *ngIf="isPresumptive" class="static-text">
      Presumptive Eligibility is temporary health coverage and lasts no more than 90 days.
      If approved, this helps you start getting care right away. <br><br>
      If you are approved for Presumptive
      Eligibility, you can also apply to keep TennCare past your temporary coverage. If you choose not
      to apply for temporary Presumptive Eligibility, you can still complete a TennCare application.
    </p>
    <br>
    <nh-field id = "de_applyForCoverage" *ngIf="this.isPresumptive"
      label="Is {{ selectedIndividual | fullname }} applying for Presumptive Coverage?" class="label-section">
      <select aria-label='applyForCoverage' nh name="applyForCoverage" formControlName="applyForCoverage"
      [items]="tables.YESNO" (change)="onApplyFprCovSelected($event)"></select>
    </nh-field>

    <nh-field id = "de_applyForCoverage" *ngIf="!this.isPresumptive"
      label="{{'IS_1' | translate}}{{ selectedIndividual | fullname }} {{'APPLYING_FOR_COVERAGE_WITH_US' | translate}}"
      class="label-section">
      <select aria-label='applyForCoverage' nh name="applyForCoverage" formControlName="applyForCoverage"
      [items]="tableCodeValue('YESNO', 'data.applyForCoverage')"  (change)="onApplyFprCovSelected($event)"></select>
    </nh-field>

    <nh-field id = "de_applyForOnGoingCoverage" *ngIf="this.isPresumptive" label="Is {{ selectedIndividual | fullname }} applying for ongoing coverage?"
      class="label-section">
      <select aria-label='applyForOnGoingCoverage' nh name="applyForOnGoingCoverage"
        formControlName="applyForOnGoingCoverage" [items]="tableCodeValue('YESNO', 'data.applyForOnGoingCoverage')"
        (change)="onApplyFprCovOnGoSelected($event)"></select>
    </nh-field><br *ngIf="this.isPresumptive">

    <div *ngIf="isPresumptive" formGroupName="ssnInformation">
      <div>
        <h5 class="section-header">{{'SOCIAL_SECURITY_INFO' | translate}}</h5>
        <hr>
        <div *ngIf = "ssnCoverage">
          <p class="static-text" [innerHTML]="'SOCIAL_SECURITY_STATIC_TEXT' | translate"></p><br>
          <p [innerHTML]="'SSN_TEXT_BULLETPOINT_Header' | translate"></p>
          <ul class="list-inline pl-1" [innerHTML]="'SSN_LIST' | translate"></ul><br>
          <p class="static-text"> {{'SOCIAL_SECURITY_STATIC_TEXT5' | translate}} </p><br><br> 
        </div>

        <p class="static-text"> {{'SOCIAL_SECURITY_STATIC_TEXT2' | translate}}<br><br>

        <nh-field id = "de_ssn" [mandatory]="false" label="Social Security Number" class="label-section">
          <span *ngIf="this.isPresumptive">
            <nh-popover field-label class="ml-2 pl-1" [content]="presumptiveSsnPop"></nh-popover>
          </span>
          <span *ngIf="!this.isPresumptive">
            <nh-popover field-label class="ml-2 pl-1" [content]="ssnPop"></nh-popover>
          </span>

          <input type="text" aria-label='ssn' name="ssn" formControlName="ssn" class="form-control"
            placeholder="XXX-XX-XXXX" mask="000-00-0000" (blur)="compareSSN()">
        </nh-field>
      </div>
      <div>
        <nh-field id = "de_ssnConfirm" [mandatory]="false" label="{{'CONFIRM_SSN' |translate}}" class="label-section">
          <input aria-label='ssnConfirm' type="text" name="ssnConfirm" formControlName="ssnConfirm" class="form-control"
            placeholder="XXX-XX-XXXX" mask="000-00-0000" [attr.disabled]="exported?true:null" (blur)="compareSSN()">
        </nh-field>
      </div>

      <nh-alert type="warning"
        [isOpen]="getControl('data.ssnInformation.ssn').touched && isEmpty('data.ssnInformation.ssn')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
          </div>
          <div class="message">
            <span> {{'SOCIAL_SECURITY_STATIC_TEXT4' | translate}}
            </span>
          </div>
        </div>
      </nh-alert>
      <br>
      <nh-field id = "de_ssnAppliedDate" *ngIf="!getValue('data.ssnInformation.ssn')" [mandatory]='false'
        label="If {{ selectedIndividual | fullname }} has applied for an SSN but has not received it, what date did he or she apply?"
        class="label-section">
        <nh-datepicker>
          <input type="text" aria-label='ssnAppliedDate' name="ssnAppliedDate" class="form-control"
            formControlName="ssnAppliedDate" bsDatepicker nh [minDate]="minimumDate" [maxDate]="today">
        </nh-datepicker>
      </nh-field>
    </div><br>

    <div *ngIf="!isPresumptive" formGroupName="ssnInformation">
      <h5 class="section-header">{{'SOCIAL_SECURITY_INFO' | translate}}</h5>
      <hr>
      <div *ngIf = "ssnCoverage">
        <p class="static-text"
          [innerHTML]="'SOCIAL_SECURITY_STATIC_TEXT' | translate"></p>
          <span><br></span>
        <p  [innerHTML]="'SSN_TEXT_BULLETPOINT_Header' | translate"></p>
          <ul class="list-inline pl-1" [innerHTML]="'SSN_LIST' | translate"></ul><br>
        <p class="static-text"> {{'SOCIAL_SECURITY_STATIC_TEXT5' | translate}} </p><br><br> 
      </div>

      <p class="static-text"> {{'SOCIAL_SECURITY_STATIC_TEXT2' | translate}}</p><br><br>
      <div>
        <nh-field id = "de_ssn" [mandatory]="false" label="{{'SSN' |translate}}" class="label-section">
          <span *ngIf="this.isPresumptive">
            <nh-popover field-label class="ml-2 pl-1" [content]="presumptiveSsnPop"></nh-popover>
          </span>
          <span *ngIf="!this.isPresumptive">
            <nh-popover field-label class="ml-2 pl-1" [content]="ssnPop"></nh-popover>
          </span>
          <input aria-label='ssn' type="text" name="ssn" formControlName="ssn" class="form-control"
           placeholder="XXX-XX-XXXX" mask="000-00-0000" [attr.disabled]="exported?true:null" (blur)="compareSSN()">
        </nh-field>
      </div>
      <div>
        <nh-field id = "de_ssnConfirm" [mandatory]="false" label="{{'CONFIRM_SSN' |translate}}" class="label-section">
          <input aria-label='ssnConfirm' type="text" name="ssnConfirm" formControlName="ssnConfirm" class="form-control"
            placeholder="XXX-XX-XXXX" mask="000-00-0000" [attr.disabled]="exported?true:null" (blur)="compareSSN()">
        </nh-field>
      </div>
      <nh-alert type="warning"
        [isOpen]="getControl('data.ssnInformation.ssn').touched && isEmpty('data.ssnInformation.ssn')">
        <div class="row">
          <div class="icon">
            <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
          </div>
          <div class="message">
            <span>{{'SSN_ENTER_MANDATORY' | translate}}</span>
          </div>
        </div>
      </nh-alert>
      <br>
      <nh-field id = "de_ssnAppliedDate" *ngIf="!getValue('data.ssnInformation.ssn')" [mandatory]='false'
        label="{{'IF' | translate}} {{ selectedIndividual | fullname }} {{'SOCIAL_SECURITY_STATIC_TEXT3' | translate}}"
        class="label-section">
        <nh-datepicker>
          <input aria-label='ssnAppliedDate' type="text" name="ssnAppliedDate" class="form-control"
            formControlName="ssnAppliedDate" bsDatepicker nh [minDate]="minimumDate" [maxDate]="today">
        </nh-datepicker>
      </nh-field>
      <span *ngIf="!getValue('data.ssnInformation.ssn')"><br></span>
    </div>

    <div *ngIf="isY('data.applyForCoverage') || isY('data.applyForOnGoingCoverage')" formGroupName="citizenship">
      <h5 class="section-header">{{'CITIZENSHIP' | translate}}</h5>
      <hr>
      <nh-field id = "de_usCitizen" *ngIf="isEnabled('data.citizenship.usCitizen')"
        label="{{'IS' | translate}} {{ selectedIndividual | fullname }} {{'CITIZENSHIP_ STATIC_TEXT1' | translate}}"
        class="label-section">
        <select aria-label='usCitizen' nh name="usCitizen" formControlName="usCitizen" [items]="tableCodeValue('YESNO', 'data.citizenship.usCitizen')"></select>
      </nh-field>

      <nh-field id = "de_derivedCitizen" *ngIf="isEnabled('data.citizenship.derivedCitizen') && isY('data.citizenship.usCitizen')"
        label="{{'IS_1' | translate}}{{ selectedIndividual | fullname }} {{'CITIZENSHIP_ STATIC_TEXT2' | translate}}"
        class="label-section">
        <div class="hint">{{'CITIZENSHIP_ STATIC_TEXT3' | translate}}</div>
        <select aria-label='derivedCitizen' nh name="derivedCitizen" formControlName="derivedCitizen"
        [items]="tableCodeValue('YESNO', 'data.citizenship.derivedCitizen')"></select>
      </nh-field>

      <div formGroupName="document">
        <nh-field id = "de_registrationNumber"
          *ngIf="isEnabled('data.citizenship.document.registrationNumber') && isY('data.citizenship.derivedCitizen')"
          [mandatory]="false" label="{{'ALIEN_NUMBER' | translate}}" class="label-section">
          <div class="d-flex align-items-center alien-num"><span class="pl-2 pr-3 tab-section">A</span><input type="text"
              name="registrationNumber" aria-label='registrationNumber' class="form-control"
              formControlName="registrationNumber" placeholder="XXX-XX-XXXX" mask="999999999" (keypress)="restrictSplChar($event)">
          </div>
        </nh-field>

        <nh-field id = "de_naturalizationCertificateNumber"
          *ngIf="isEnabled('data.citizenship.document.naturalizationCertificateNumber') && isY('data.citizenship.derivedCitizen')"
          [mandatory]="false" label="{{'NATURALIZATION_NUMBER' | translate}}" class="label-section">
          <input aria-label='naturalizationCertificateNumber' type="text" name="naturalizationCertificateNumber"
            class="form-control" formControlName="naturalizationCertificateNumber" maxlength="12">
        </nh-field>
        <span *ngIf="isEnabled('data.citizenship.document.naturalizationCertificateNumber') && isY('data.citizenship.derivedCitizen')"><br></span>
      </div>
    </div>

    <div formGroupName="livingArrangement">
      <div *ngIf="isY('data.applyForCoverage') || isY('data.applyForOnGoingCoverage')">
        <h5 class="section-header">{{'LIVING_ARRANGEMENT' | translate}}</h5>
        <hr>
      </div>
      <nh-field id = "de_livingAreaType" *ngIf="isY('data.applyForCoverage') || isY('data.applyForOnGoingCoverage')"
        [mandatory]="false" label="{{'LIVING_ARRANGEMENT_STATIC_TEXT' | translate}}" class="label-section">
        <nh-popover field-label class="ml-2 pl-1" [content]="livingArrangement"></nh-popover>
        <select aria-label='livingAreaType' nh name="livingAreaType" formControlName="livingAreaType"
        [items]="tableCodeValue('LIVINGARRANGEMENTYPE', 'data.livingArrangement.livingAreaType')"></select>
      </nh-field>

      <nh-field id = "de_tennesseeResident" *ngIf="isEnabled('data.livingArrangement.tennesseeResident') && (isY('data.applyForCoverage') || isY('data.applyForOnGoingCoverage'))"
        label="{{'IS_1' | translate}}{{ selectedIndividual | fullname }} {{'TENNESSEE_RESIDENT' | translate}}"
        class="label-section">
        <nh-popover field-label class="ml-2 pl-1" [content]="residencyArrangement"></nh-popover>
        <select aria-label='tennesseeResident' nh name="tennesseeResident" formControlName="tennesseeResident"
          [items]="tableCodeValue('YESNO', 'data.livingArrangement.tennesseeResident')"></select>
      </nh-field>

      <nh-field id = "de_outOfHome" *ngIf="isEnabled('data.livingArrangement.outOfHome') && (isY('data.applyForCoverage') || isY('data.applyForOnGoingCoverage'))" [mandatory]="false"
        label="{{'IS_3' | translate}} {{ selectedIndividual | fullname }} {{'OUT_OF_STATE' | translate}}"
        class="label-section">
        <select aria-label='outOfHome' nh name="outOfHome" formControlName="outOfHome" [items]="tableCodeValue('YESNO', 'data.livingArrangement.outOfHome')"></select>
      </nh-field>

      <nh-field id = "de_intendToReturn" *ngIf="isEnabled('data.livingArrangement.intendToReturn') && (isY('data.applyForCoverage') || isY('data.applyForOnGoingCoverage'))" [mandatory]="false"
        label="{{'DOES_1' | translate}} {{ selectedIndividual | fullname }} {{'PLAN_TO_RETURN' | translate}}"
        class="label-section">
        <select aria-label='intendToReturn' nh name="intendToReturn" formControlName="intendToReturn"
        [items]="tableCodeValue('YESNO', 'data.livingArrangement.intendToReturn')"></select>
      </nh-field>

      <nh-field id = "de_intendToReturnDate" *ngIf="isEnabled('data.livingArrangement.intendToReturnDate') && (isY('data.applyForCoverage') || isY('data.applyForOnGoingCoverage'))" [mandatory]="false"
        label="{{'DATE' | translate}} {{ selectedIndividual | fullname }} {{'PLANS_TO_RETURN' | translate}}"
        class="label-section">
        <nh-datepicker>
          <input aria-label='intendToReturnDate' type="text" name="intendToReturnDate"
            class="form-control" formControlName="intendToReturnDate" bsDatepicker nh>
        </nh-datepicker>
      </nh-field>
      <nh-alert type="danger" [isOpen]="isRmbRmc && intendDateBannerChange() && isEnabled('data.livingArrangement.intendToReturnDate') && (isY('data.applyForCoverage'))">
        <div class="row">
          <div class="icon">
            <img src="../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
            <span>{{ 'INVALID_MAX_DATE' | translate}}</span>
          </div>
        </div>
      </nh-alert>
    </div>

    <p *ngIf="!isPresumptive" class="static-text">{{'TENNESSEE_RESIDENT_STATIC_TEXT1' | translate}}</p>
    <span *ngIf="!isPresumptive"><br></span>
      <table>
        <tr>
          <th><span class="text-danger ng-star-inserted">*</span></th>
          <th><h5 class="section-header">{{'RACE' | translate}}</h5></th>
        </tr>
      </table>
    <hr>
    <nh-alert type="warning"
        [isOpen]="raceWarning">
        <div class="row">
          <div class="icon">
            <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
          </div>
          <div class="message">
            <span> {{'RACE_STATIC_TEXT1' | translate}}
            </span>
          </div>
        </div>
      </nh-alert>
    <demographics-checkbox-list [selectedCodes] = "selectedCodes" [selectedCode] = "selectedCode" [isNASelected]="isNACodeSelected" [isUNSelected]="isUNCodeSelected" (valueChange)="selectDisabledValues($event)"
      [checkboxGroup]="this.getControl('extras.helpers.races')" [referenceTable]="tables.RACEDISPLAY">
    </demographics-checkbox-list>
    <br>
    <div *ngIf="isY('data.applyForCoverage') && !this.isPresumptive">
      <nh-field id = "de_federalRecogizedTribe" [mandatory]="true"
        label="{{'IS' | translate}} {{ selectedIndividual | fullname }} {{'FEDRALLY_RECOGNIZED' | translate}}" class="label-section">
        <select aria-label='federalRecogizedTribe' nh name="federalRecogizedTribe" formControlName="federalRecogizedTribe"
          [items]="tableCodeValue('YESNO', 'data.federalRecogizedTribe')"></select>
      </nh-field>
    </div>

    <nh-field id = "de_tribeName" *ngIf="isEnabled('data.tribeName')" [mandatory]="false" label="{{'NAME_OF_TRIBE' | translate}}"
      class="label-section">
      <input aria-label='tribeName' type="text" name="tribeName" formControlName="tribeName" class="form-control">
    </nh-field>

    <nh-field id = "de_healthProgram" *ngIf="isEnabled('data.healthProgram')"
      label="{{'HAS' | translate}} {{ selectedIndividual | fullname }} {{'SERVICE' | translate}}" class="label-section">
      <select aria-label='healthProgram' nh name="healthProgram" formControlName="healthProgram"
      [items]="tableCodeValue('YESNO', 'data.healthProgram')"></select>
    </nh-field>

    <nh-field id = "de_eligHlthProgram" *ngIf="isEnabled('data.eligHlthProgram')"
      label="{{'IS_1' | translate}}{{ selectedIndividual | fullname }} {{'INDIAN_HEALTH_SERVICE' | translate}}"
      class="label-section">
      <select aria-label='eligHlthProgram' nh name="eligHlthProgram" formControlName="eligHlthProgram"
      [items]="tableCodeValue('YESNO', 'data.eligHlthProgram')"></select>
    </nh-field>
    <span *ngIf="isEnabled('data.eligHlthProgram')"><br></span>
    <h5 class="section-header">{{'ETHNICITY' | translate}}</h5>
    <hr>
    <nh-field id = "de_hispanicOrigin" [mandatory]="true"
      label="{{'ETHNICITY_STATIC_TEXT_1' | translate}} {{ selectedIndividual | fullname }}{{'ETHNICITY_STATIC_TEXT_2' | translate}}"
      class="label-section">
      <div class="hint">{{'ETHNICITY_STATIC_TEXT_3' | translate}}</div>
      <select aria-label='hispanicOrigin' nh name="hispanicOrigin" formControlName="hispanicOrigin"
      [items]="tableCodeValue('ETHNICITY', 'data.hispanicOrigin')"></select>
    </nh-field>
  </section>
</nh-form>