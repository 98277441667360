import { Component, AfterViewInit, AfterContentInit } from "@angular/core";
import { SectionFormDirective } from "@app/classes/section-form.class";
import { FormBuilder } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import { ApplicationService } from "@app/services/application/application.service";
import { SectionId } from "@app/models/section.model";
@Component({
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent
  extends SectionFormDirective
  implements AfterContentInit, AfterViewInit {
  constructor(builder: FormBuilder, private titleService: Title,
    private translateService: TranslateService, public activatedRoute: ActivatedRoute,
    protected application: ApplicationService) {
    super(builder);
    this.titleService.setTitle('Application Summary');
  }
  showDetails = false;
  currentHeader;
  showWarning = false;
  summaryData;

  ngAfterContentInit() {
    this.showWarning = this.getValue('data.kbValidation') === 'N';

  }

  ngAfterViewInit() {
    const elementsList: NodeListOf<Element> = document.querySelectorAll(".carat");
    elementsList.forEach((i) => {
      document.getElementById(i.id).addEventListener("click", this.logInfo.bind(this));
      i.nextElementSibling?.addEventListener("click", this.logInfo.bind(this));
    });
    this.translateService.onDefaultLangChange.subscribe(() => {
      const appNumber = this.activatedRoute.snapshot.paramMap.get('appNumber');
      const sectionId: SectionId = this.activatedRoute.snapshot.paramMap.get('sectionId') as SectionId;
      this.application.loadSection(appNumber, sectionId, []).subscribe(res => {
        console.log(res);
        if (res?.length) {
          res[0].records[0].data.summary = res[0].records[0].data.summary.replace('id="heading_"', ' (click)="logInfo($event)" id="heading_"');
          this.summaryData = res[0].records[0].data.summary;          
          this.getControl('data.summary').patchValue(this.summaryData)
          setTimeout(() => {
            const elementsList: NodeListOf<Element> = document.querySelectorAll("#accordion");
            var headings = elementsList[0]?.querySelectorAll('[id^="heading"]');            
            for (let i = 0; i < headings.length; i++) {                                    
              (headings[i] as any).onclick = this.logInfo;
            };
          },10);
        }
      })

    })
    window.scroll(0,0);
  }

  logInfo(e) {
    const id = e.currentTarget.id.split("_");
    const el = document.getElementById("collapse_" + id[1]);
    el.classList.toggle("show");

    const up = document.getElementById("up_" + id[1]);
    up.classList.toggle("cla1");
    up.classList.toggle("cla2");

    const dwn = document.getElementById("dwn_" + id[1]);
    dwn.classList.toggle("cla2");
    dwn.classList.toggle("cla1");
  }
}
