<nh-form [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">

  <div class="container start-page">
    <h5 class="section-header">{{'BEFORE_YOU_BEGIN' | translate}}</h5>
    <hr />
    <div>
      <div *ngIf="!isFinReassessment">
        <div *ngIf="!isRenewBenefits">
          <p [innerHTML]="'START_STATIC_TEXT_1' | translate"></p>
          <p>{{'START_STATIC_TEXT_2' | translate}}</p>
          <p>{{'START_STATIC_TEXT_3' | translate}}</p>
        <ul class="pl-4 mb-4">
          <li>{{'START_STATIC_TEXT_4' | translate}}</li>
          <li>{{'START_STATIC_TEXT_5' | translate}}</li>
          <li>{{'START_STATIC_TEXT_6' | translate}}</li>
          <li>{{'START_STATIC_TEXT_7' | translate}}</li>
        </ul>
        <p>{{'START_STATIC_TEXT_8' | translate}} </p>
        </div>
        <div *ngIf="isRenewBenefits && !isPreterm">
          <p [innerHTML]="'START_STATIC_TEXT_1_RN' | translate"></p>
          <p>{{'START_STATIC_TEXT_2_RN' | translate}}</p>
          <p>{{'START_STATIC_TEXT_3_RN' | translate}}</p>
          <p>{{'START_STATIC_TEXT_3_RN_NEXT' | translate}}</p>
          <ul class="pl-4 mb-4">
            <li>{{'START_STATIC_TEXT_4_RN' | translate}}</li>
            <li>{{'START_STATIC_TEXT_5_RN' | translate}}</li>
            <li>{{'START_STATIC_TEXT_6_RN' | translate}}</li>
            <li>{{'START_STATIC_TEXT_7_RN' | translate}}</li>
          </ul>
          <p>{{'START_STATIC_TEXT_8_RN' | translate}} </p>
        </div>
        <div *ngIf="isRenewBenefits && isPreterm">
          <p [innerHTML]="'START_STATIC_TEXT_1_QFR' | translate"></p>
          <p>{{'START_STATIC_TEXT_2_QFR' | translate}}</p>
          <p>{{'START_STATIC_TEXT_3_QFR' | translate}}</p>
          <p>{{'START_STATIC_TEXT_3_RN_NEXT' | translate}}</p>
          <ul class="pl-4 mb-4">
            <li>{{'START_STATIC_TEXT_4_RN' | translate}}</li>
            <li>{{'START_STATIC_TEXT_5_RN' | translate}}</li>
            <li>{{'START_STATIC_TEXT_6_RN' | translate}}</li>
            <li>{{'START_STATIC_TEXT_7_RN' | translate}}</li>
          </ul>
          <p>{{'START_STATIC_TEXT_8_RN' | translate}} </p>
        </div>
        <p *ngIf="!isPreterm" [innerHTML]="'START_STATIC_TEXT_9_RN' | translate"></p>
        <p *ngIf="isPreterm" [innerHTML]="'START_STATIC_TEXT_9_QFR' | translate"></p>
      </div>

      <div *ngIf="isFinReassessment">
        <p>The Financial Reassessment for Medicaid is for TennCare members who are enrolled in Institutional Medicaid
          which means they
          are in a long-term care facility or are receiving HCBS. The financial reassessment makes sure your patient is
          still financially
          eligible to receive TennCare so it's different from the medical level of care assessment you do. The fields in
          the financial
          reassessment will be pre-filled with information that's been provided in the past. Be sure to review each
          field and make updates
          to any information that has changed since the last application or renewal.</p>

        <p>Changes may include things like:</p>
        <ul class="pl-4 mb-4">
          <li>If anyone has moved into or out of the household</li>
          <li>If you or someone in your household has changed jobs</li>
          <li>The amount of income or source of income has changed</li>
          <li>Changes in immigration or citizenship status</li>
        </ul>

        <p>Give yourself enough time. It takes most people about 30 minutes to fill out the reassessment depending on
          how many people
          are in the household. Remember, the fields will be pre-filled and you'll be asked to confirm if the
          information is still
          correct. If it is, you can leave it as is. If you need to make a change, click <b>Edit.</b></p>

        <p>At the end of the reassessment, you must click the <b>Submit</b> button. This action sends your reassessment
          to us and tells
          us you're ready for a decision. If approved, your start date will be the date we receive your reassessment.
        </p>
      </div>

      <div class="p-3 mb-4 mt-4 helpful-tips">
        <p class="sub-section pt-3">{{'HELPFUL_TIPS' | translate}}</p>
        <hr>
        <div>
          <div *ngIf="!isFinReassessment">
            <div class="d-flex pb-3">
              <img alt="checkIcon" class="align-self-start img-responsive" src="../../../assets/images/Icons/HelpfulTips_CheckIcon.svg" />
              <div class="pl-3" [innerHTML]="'HELPFUL_TIPS_1' | translate"> 
              </div>
            </div>
            <div class="d-flex pb-3">
              <img alt="checkIcon" class="align-self-start img-responsive"
                src="../../../assets/images/Icons/HelpfulTips_CheckIcon.svg" />
                <div *ngIf="isRenewBenefits && !isPreterm" class="width-90 pl-3">{{'HELPFUL_TIPS_2_RN' | translate}}<br />
              </div>
              <div *ngIf="!isRenewBenefits && !isPreterm" class="width-90 pl-3">{{'HELPFUL_TIPS_2' | translate}}<br />
              </div>
              <div *ngIf="isPreterm" class="width-90 pl-3">{{'HELPFUL_TIPS_2' | translate}}<br />
              </div>
            </div>
            <div class="pl-5"><p [innerHTML]="'HELPFUL_TIPS_3' | translate"></p>
            </div>
            <div class="d-flex">
              <img alt="importantIcon" class="align-self-start img-responsive" src="../../../assets/images/Icons/HelpfulTips_ImportantIcon.svg" />
              <div class="pl-3 width-90">
                <p [innerHTML]="'HELPFUL_TIPS_4' | translate"></p>
              </div>
            </div>
          </div>

          <div *ngIf="isFinReassessment">
            <div class="d-flex pb-3">
              <img alt="checkIcon" class="align-self-start img-responsive"
                src="../../../assets/images/Icons/HelpfulTips_CheckIcon.svg" />
              <div class="pl-3">Once you log in and get started, you can stop and save your answers at any time by
                clicking the <b>Save and Exit</b>
                button at the bottom of the page. When you're ready, you can log back in and continue answering
                questions. Once you've answered all
                the questions, click the <b>Submit</b> button at the end.</div>
            </div>

            <div class="d-flex pb-3">
              <img alt="checkIcon" class="align-self-start img-responsive"
                src="../../../assets/images/Icons/HelpfulTips_CheckIcon.svg" />
              <div class="pl-3">We may need proof for some of these answers before we can make a decision. If we need
                more information, we'll send a letter asking for the proof we need. Be sure to give us the
                current mailing address, phone number, and email address of the person responsible for your patient so
                we can reach them.</div>
            </div>
          </div>
        </div>
      </div>
      <div [innerHTML]="'HELPFUL_TIPS_5' | translate"></div>
    </div>
  </div>
</nh-form>