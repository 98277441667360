import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from '@app/services/application/application.service';
import { StateService } from '@app/services/state/state.service';
import { StorageService } from '@app/services/storage/storage.service';
import { isNilOrEmpty } from 'ramda-adjunct';
@Component({
  selector: 'nh-provider-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})

export class ProviderWelcomeComponent implements OnInit {
  @Input() peAccessCdArr;
  constructor(
    private router: Router, 
    private stateService: StateService,
    protected storageService: StorageService,
    protected applicationService: ApplicationService
  ) {}

  appType = '';
  applicationNum;
  hasPpAccess = false;
  isKbEligible;
  
  ngOnInit () {
    sessionStorage.removeItem('recordId');
    this.hasPpAccess = isNilOrEmpty(this.peAccessCdArr);

    const userName = sessionStorage.getItem('userName');
    this.applicationService.getKbInfo(userName).subscribe((res: any) => {
      if (res) {
        this.isKbEligible = res?.activeFlag === 'Y' ? 'true' : 'false';
        sessionStorage.setItem('isKbEligible', this.isKbEligible);
      }
    });
  }

  viewAccount(appType) {
    if (appType === 'LOC' || appType === 'TCC' || appType === 'BXT'|| appType === 'IME') {
      this.router.navigate(['/api/prov/member-search/'], { queryParams: { type: appType } });
    } else {
      this.router.navigate(['/api/prov/search/'], { queryParams: { type: appType } });
    }
  }

  searchSubmissions() {
    this.router.navigateByUrl('/api/prov/submission-search');
  }

  startAppUrl = () => this.router.navigateByUrl('/application/' + 'NEW');

  startApp (appType) {
    this.stateService.resetStates();
    this.storageService.setApplicationType(appType);
    this.startAppUrl();
  };

  linkCase(){
    this.router.navigateByUrl('/api/prov/case-link');
  }
}
