<div class="row pl-1 pb-1 hover-red main-content-width">
  <div *ngIf="!inSummary && showLeave && sectionId !== 'NBSUM' && !hidecontent &&((!isKepro || (!isKepro && sectionId !== 'SACHS')) || (isKepro && sectionId !== 'SURCH')) && !hideBackToPrev" class="btn btn-link hover-red pl-0" (click)="onClose()">
    <svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
      <g id="back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
        <path id="back-shape" data-name="Combined Shape"
          d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
          transform="translate(0)" fill="#174a7c" />
      </g>
    </svg>
    <span class="pl-2">{{ backToPreviousPage | translate }}</span>
  </div>
  <a *ngIf="inSummary && showLeave && sectionId !== 'SURCH' && sectionId !== 'NBSUM'" class="btn btn-link hover-red pl-0" (click)="onPrevious()">
    <svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
      <g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
        <path id="back_shape" data-name="Combined Shape"
          d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
          transform="translate(0)" fill="#174a7c" />
      </g>
    </svg>
    <span class="pl-2">{{ backToPreviousPage | translate }}</span>
  </a>
</div>
<br />
<nh-alert type="warning" [isOpen]="this.sectionId === 'SUPSM'">
  <div class="row">
    <div class="icon">
      <img src="../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="danger" />
    </div>
    <div class="message">
      <span *ngIf="!isPreterm" [innerHTML]="'REVIEW_CONFIRMATION' | translate: {type: appKeyWord | translate}">
      </span>
      <span *ngIf="isPreterm" [innerHTML]="'REVIEW_CONFIRMATION_1' | translate">
      </span>
    </div>
  </div>
</nh-alert>
<nh-alert type="warning" [isOpen]="this.sectionId === 'APSUM'">
  <div class="row">
    <div class="icon">
      <img src="../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="danger" />
    </div>
    <div class="message">
      <span [innerHTML]="'REVIEW_APPEAL_CONFIRMATION' | translate">
      </span>
    </div>
  </div>
</nh-alert>
<nh-alert type="danger" [isOpen]="recordExists">
  <div class="row">
    <div class="icon">
      <img src="../../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
    </div>
    <div class="message">
      <span [innerHTML]="'MULTIPLE_RECORDS'| translate">
      </span>
    </div>
  </div>
</nh-alert>
<br />
<section class="sections border bg-white p-3 main-content-width">
  <nh-tabset [applicationNumber]="applicationNumber" [tabs$]="sectionLinks$" type="modules"
    (navigate)="onNavigate($event)"></nh-tabset>
  <nh-notifications [messages]="notifications"></nh-notifications>

  <section id="records" *ngIf="inSummary && showRecords() && mode === 'NEW'">
    <p *ngIf="!isSummaryTitle && !appealSummaryAddrep && !imeDetails" class="static-text" [innerHTML]="'OTHER_HEALTH_SUMMARY_STATIC_TEXT1' | translate"></p>
    <p *ngIf="!isSummaryTitle && !appealSummaryAddrep && imeDetails" class="section-header">Incurred Medical Expenses Summary</p><hr>
    <p *ngIf="isSummaryTitle" class="static-text" [innerHTML]="'TAX_SUMMARY_STATIC_TEXT3' | translate"></p>
    <p *ngIf="appealSummaryAddrep" class="static-text" [innerHTML]="'APPEAL_REP_SUMMARY_STATIC1' | translate"></p>
    <p *ngIf="imeDetailsSummaryStatic" class="static-text">This member has no past submitted incurred medical expenses. To add an incurred medical expense for this member, select the Add Another hyperlink below.</p>
    <nh-table *ngIf="!appealSummaryAddrep && !imeDetailsSummaryStatic" [columns]="columns" [sectionId]="this.sectionId" [rows]="records" [mode]="mode" (edit)="onEdit($event)" (delete)="onDelete($event)">
    </nh-table>
    <nh-section-info *ngIf="showCreate()" [title]="buttonAdd" [addKbMedicalBills]="this.addKbMedicalBill" (create)="onCreate()"></nh-section-info>
    <nh-section-info *ngIf="sectionId === 'ARPDT'"  [title]="buttonAdd" [hideAddRep]="hideAddRepresentative" [addKbMedicalBills]=false [sectionId]="'ARPDT'" (create)="onCreate()"></nh-section-info>
    <nh-section-info *ngIf="sectionId === 'IMEDT'"  [title]="buttonAdd" [addKbMedicalBills]=false [sectionId]="'IMEDT'" (create)="onCreate()"></nh-section-info>
  </section>

  <section id="records" *ngIf="
      inSummary && showRecords() && mode !== 'NEW' && this.sectionId !== 'SURCH'
    ">
    <p *ngIf="mode === 'REDETERMINATION'" class="static-text" [innerHTML]="'SUMMARY_STATIC_TEXT1' | translate"></p>
    <p *ngIf="mode === 'CHANGE_REPORT'" [innerHTML]="'RMC_SUMMARY_TEXT' | translate" ></p> 
    <nh-review-summary-table [mode]="mode" [columns]="columns" [rows]="records" [labels]="labelData"
      (edit)="onEdit($event)" (delete)="onDelete($event)" (undo)="onUndo($event)" [individuals]="individuals"
      [sectionId]="sectionId"></nh-review-summary-table>
    <nh-section-info *ngIf="showCreate() || addAssistingPerson" [addAssisting]="addAssistingPerson" [addKbMedicalBills]="this.addKbMedicalBill" [sectionId]="sectionId" [title]="buttonAdd" (create)="onCreate()" (edit)="addAssisting()"></nh-section-info>
  </section>

  <ng-container nh-container #container></ng-container>
</section>
<!-- <div class="row pt-1 hover-red comments main-content-width">
  <a *ngIf="showComments && !presumptiveCondition()" class="pl-lg-0 btn btn-link hover-red" (click)="onComment()">
    <span class="pr-2">{{ "ADD_YOUR_COMMENTS" | translate }} {{ appKeyWord | translate }}</span>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 19.49">
          <g id="_icons_create-outline" data-name=" icons create-outline">
            <path id="Combined_Shape-287" data-name="Combined Shape-287" class="cls-1"
              d="M2.63,19.49A2.62,2.62,0,0,1,0,16.91V5.62A2.63,2.63,0,0,1,2.58,3h7.9a.75.75,0,0,1,.75.75.74.74,0,0,1-.74.75H2.63A1.12,1.12,0,0,0,1.5,5.6V16.87A1.12,1.12,0,0,0,2.61,18H13.88A1.12,1.12,0,0,0,15,16.89V8.24a.76.76,0,0,1,.74-.76.75.75,0,0,1,.76.75v8.64a2.62,2.62,0,0,1-2.59,2.62Zm4.92-7.55a.17.17,0,0,1,0-.18l.39-1.17A.42.42,0,0,1,8,10.41L16.47,2a.43.43,0,0,1,.6,0h0l.47.47a.42.42,0,0,1,0,.59L9.09,11.49a.5.5,0,0,1-.19.1L7.74,12H7.68a.21.21,0,0,1-.13-.05ZM18.17,1.85l-.53-.53a.39.39,0,0,1,0-.53h0l.58-.58a.76.76,0,0,1,1.07,0,.75.75,0,0,1,0,1.06l-.56.57a.4.4,0,0,1-.54,0h0Z" />
          </g>
    </svg>
  </a>
</div>-->

<div class="mb-4"></div>

<nh-button-bar *ngIf="!inSummary" [disableContinue]="disableContinue()" [disableSubmit]="disableSubmit()"
  [labelContinue]="labelContinue" (continue)="onSave()" (leave)="onClose()" [showExit]="showSaveAndExit"
  (saveExit)="saveAndExit()" [sectionId]="sectionId"></nh-button-bar>

<nh-button-bar *ngIf="inSummary" [disableContinue]="continueDisable()" [disableSubmit]="disableSubmit()"
  (continue)="onNext()" (leave)="onPrevious()" [showExit]="showSaveAndExit" (saveExit)="saveAndExit()"></nh-button-bar>