<nh-form [individuals]="individuals" [formGroup]="getForm()" (validate)="onValidate($event)">
  <p *ngIf="isRmbRmc">{{ "RENEWAL_TEXT" | translate }}</p>
  <h6 class="person-label">{{selectedIndividual | fullname}}</h6>
  <section [formGroup]="getControl('data')">
    <nh-field id = "td_taxDeductions"
      label="{{'DOES_2' | translate}}{{selectedIndividual | fullname}} {{'TAX_DEDUCTIONS_STATIC_TEXT1' | translate}}"
      class="label-section">
      <nh-popover class="ml-2 pl-1" [content]="popTaxDeductions"></nh-popover>
      <select nh name="taxDeductions" aria-label="Tax Deductions" formControlName="taxDeductions"
      [items]="tableCodeValue('YESNO', 'data.taxDeductions')"></select>
    </nh-field>
    <div (window:resize)="onResize($event)"></div>

    <section *ngIf="isY('data.taxDeductions')">
      <h5 class="section-header"><strong>{{'TAX_DEDUCTIONS_STATIC_TEXT2' | translate}}</strong></h5>
      <hr>
      <p>{{'IF' | translate}} {{selectedIndividual | fullname}} {{'TAX_DEDUCTIONS_STATIC_TEXT3' | translate }}</p>
      <ng-container formArrayName="taxDeductionsIndvData">
        <div class="details-container mb-2">
          <div *ngIf="isDesktop" class="d-flex p-3 bg-details-container">
            <div class="col-md-3">{{'TAX_DEDUCTIONS_STATIC_TEXT4' | translate }}</div>
            <div class="col-md-3">{{'TAX_DEDUCTIONS_STATIC_TEXT5' | translate }}</div>
            <div class="col-md-3">{{'TAX_DEDUCTIONS_STATIC_TEXT6' | translate }}</div>
          </div>
          <ng-container *ngFor="let row of taxDeductionsIndvData.controls; index as i;" [formGroupName]="i">
            <div class="form-row bg-even pl-3 m-0 pt-3 pr-3" *ngIf="getValue('iden', row) !== 'd'">
              <nh-field id = "td_indvId" [showLabel]="!isDesktop" [isRow]="true" [mandatory]="false"
                label="{{'TAX_DEDUCTIONS_STATIC_TEXT4' | translate }}" class="col-md-3">
                <select nh name="indvId" [items]="members" bindValue="identifier" bindLabel="name.fullName"
                  formControlName="indvId" aria-label="indvId" [attr.disabled]="true"></select>
              </nh-field>
              <nh-field id = "td_deductionType" [showLabel]="!isDesktop" [isRow]="true" [mandatory]="true"
                label="{{'TAX_DEDUCTIONS_STATIC_TEXT5' | translate }}" class="col-md-3">
                <select nh name="deductionType" aria-label="Type" [items]="tableCodeValue('MPDEDUCTIONS', taxDeductionsIndvData.controls[i])"
                  (change)="onTaxTypeChange(taxDeductionsIndvData.value[i].deductionType.code, row)"
                  formControlName="deductionType"></select>
              </nh-field>
              <nh-field id = "td_deductionAmount" [showLabel]="!isDesktop" [isRow]="true" [mandatory]="true"
                label="{{'TAX_DEDUCTIONS_STATIC_TEXT6' | translate }}" class="col-md-3">
                <nh-input-group prepend="$">
                  <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="deductionAmount" formControlName="deductionAmount" class="form-control">
                </nh-input-group>
              </nh-field>
              <div *ngIf="taxDeductionsIndvData.controls.length >= 1" class="pt-1 hover-red">
                <button class="btn btn-link btn-lg delete-{{i}} hover-red p-1" (click)="removeTaxDeductionsRow(i)">
                  <span class="pr-2">{{'REMOVE' | translate }}</span>
                  <nh-remove-svg></nh-remove-svg>
                </button>
              </div>

              <div class="container">
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-6" *ngIf="taxTypeAlp(taxDeductionsIndvData?.value[i]?.deductionType?.code)">
                    <nh-field id = "td_alimonyOrderDate" [mandatory]="true" label="{{'TAX_DEDUCTIONS_STATIC_TEXT7' | translate}}">
                      <nh-datepicker>
                        <input type="text" class="form-control" name="alimonyOrderDate"
                          formControlName="alimonyOrderDate" placeholder="mm/dd/yyyy" [minDate]="minimumDate"
                          [maxDate]="today" bsDatepicker nh>
                      </nh-datepicker>
                    </nh-field>
                  </div>
                  <div class="col-md-6" *ngIf="taxTypeOther(taxDeductionsIndvData?.value[i]?.deductionType?.code)">
                    <nh-field id = "td_otherExpenseName" [mandatory]="true" label="{{'TAX_DEDUCTIONS_STATIC_TEXT8' | translate}}">
                      <input type="text" aria-label="otherExpenseName" class="form-control" name="otherExpenseName" maxlength="100"
                        formControlName="otherExpenseName">
                    </nh-field>
                  </div>
                  <div class="col-md-3"></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="pt-1 hover-red text-left">
          <button class="btn btn-link btn-lg hover-red" (click)="addTaxDeductionsRow()"
            [disabled]="!getControl('data.taxDeductionsIndvData').valid">
            <span class="pr-2">{{'ADD_1' | translate}}</span>
            <nh-add-svg></nh-add-svg>
          </button>
        </div>
      </ng-container>
    </section>
  </section>
</nh-form>