
import { FormBuilder } from '@angular/forms';
import { Component, AfterContentInit, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { distinctUntilChanged, map } from "rxjs/operators";
import { SectionFormDirective } from '@app/classes/section-form.class';
import { ApplicationService } from "@app/services/application/application.service";
import { ProcessService } from "@app/services/process.service";
import { ModalService } from "@app/services/modal/modal.service";
import { ApiService } from "@app/gateway/services/api.service";
import { equals, isNil, isEmpty } from 'ramda';
import { getDataValue } from "@app/helpers/object.helpers";
import Vs from "@app/services/validators/validators.service";
import { Subscription } from 'rxjs';
import { minus120Years } from '@app/helpers/date.helpers';

@Component({
  templateUrl: './facility-information.component.html',
  styleUrls: ["./facility-information.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})

export class FacilityInformationComponent extends SectionFormDirective implements AfterContentInit, OnDestroy {

  constructor(builder: FormBuilder,
    private application: ApplicationService, public processService: ProcessService,
    private modalService: ModalService, public apiService: ApiService) {
    super(builder);
    this.addressValidate$ = this.processService.processAddressValidate.subscribe((action) => this.addressValidate(action));
  }
  showSearch = false;
  isEnabled;
  columns = [
    { name: "Institution ID", prop: "InstitutionID" },
    { name: "Institution Name", prop: "InstitutionName" },
    { name: "Address", prop: "address" },
  ];
  addressValidate$: Subscription;
  rows: any;
  limit = 10;
  selected = [];
  showTable = false;
  disableAddress = true;
  today = new Date();
  admitDischarge = sessionStorage.getItem('admitDischarge');
  admissionDate;
  showDischargeDate = false;
  showDeathDate = false;
  showNewAddress = false;
  showNursingDetails
  isNursingRowSelected = false;
  result: any = [];
  minDate = minus120Years();

  get nursingFacility() {
    return this.getControl('data');
  }

  onSearch() {
    this.result = [];
    this.selected = [];
    this.showTable = true;
    const searchName = this.nursingFacility.value.nursingFacilitySearch;
    const postObj = { "nursingFacilitySearch": searchName }
    this.rows = this.application.admitDischargeNursingFacility(postObj).pipe(map((res: any) => {
      if (res && res.length > 0) {
        const responseDate = [...res];
        this.result =  responseDate.map((data) => {
          return {
            InstitutionID: data.instId,
            InstitutionName: data.instName,
            address: this.getFullAddress(data.address),
            select: true,
            addressLine1: data.address.addressLine1,
            addressLine2: data.address.addressLine2,
            city: data.address.city,
            stateCode: data.address.state?.code,
            stateValue: data.address.state?.value,
            state: data.address.state,
            zip: data.address.zip
          };
        });
        return this.result;
      } else {
        this.showTable = true;
      }
    }));
  }

  onSelect($event) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...$event.selected);
    if (this.selected && this.selected.length) {
      this.resetDataForNursingFacility();
      this.getControl('data.nursingFacilityAddress.address').enable();
      this.getControl('data.nursingFacilityName').enable();
      const addressLine1 = this.getControl('data.nursingFacilityAddress.address.addressLine1', this.formGroup);
      const addressLine2 = this.getControl('data.nursingFacilityAddress.address.addressLine2', this.formGroup);
      const city = this.getControl('data.nursingFacilityAddress.address.city', this.formGroup);
      const state = this.getControl('data.nursingFacilityAddress.address.state', this.formGroup);
      const zip = this.getControl('data.nursingFacilityAddress.address.zip', this.formGroup);
      const institutionName = this.getControl('data.nursingFacilityName', this.formGroup);
      this.getControl('data.instId').patchValue(this.selected[0].InstitutionID)
      institutionName.setValue(this.selected[0].InstitutionName);
      addressLine1.setValue(this.selected[0].addressLine1);
      addressLine2.setValue(this.selected[0].addressLine2);
      city.setValue(this.selected[0].city);
      state.setValue(this.selected[0].state);
      zip.setValue(this.selected[0].zip);
      this.disableAddress =  this.showNursingDetails = this.isNursingRowSelected = true;
      if (this.nursingFacility.get('nursingFacilityAddress.address.county')) {
        this.nursingFacility.get('nursingFacilityAddress.address.county').disable();
      }
      this.processService.addressValidated = true;
    }
  }

  onClick() {
    this.disableAddress = false;
    this.showNursingDetails = true;
    this.selected = [];
    this.isNursingRowSelected = false;
    this.processService.addressValidated = false;
    this.resetDataForNursingFacility();
    this.getControl('data.nursingFacilityName', this.formGroup)?.enable();
    this.getControl('data.nursingFacilityAddress', this.formGroup).enable();
    this.getControl('data.instId').patchValue(null)
  }

  clearValidatorForFirstSection() {
    this.nursingFacility.get('dischargeInfo.dischargereason').clearValidators();
    this.nursingFacility.get('dischargeInfo.dischargereason').updateValueAndValidity();
    this.clearAddressValidation();
   }

   setValidatorForFirstSection() {

    this.nursingFacility.get('dischargeInfo.dischargereason').setValidators([Vs.required]);
    this.nursingFacility.get('dischargeInfo.dischargereason').updateValueAndValidity();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.addressLine1').setValidators([Vs.required, Vs.streetAddress, Vs.maxLength(50)]);
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.addressLine1').updateValueAndValidity();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.addressLine2').setValidators([Vs.streetAddress, Vs.maxLength(75)]);
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.addressLine2').updateValueAndValidity();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.county').setValidators([Vs.required]);
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.county').updateValueAndValidity();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.city').setValidators([Vs.required, Vs.city,  Vs.maxLength(25)]);
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.city').updateValueAndValidity();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.state').setValidators([Vs.required]);
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.state').updateValueAndValidity();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.zip').setValidators([Vs.required]);
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.zip').updateValueAndValidity();
   }


  ngAfterContentInit() {
    const admitOrDischarge = sessionStorage.getItem('admitDischarge');
    let admitOrDischargeCode
    if (admitOrDischarge === "Admission") {
      admitOrDischargeCode = 'A'
    } else if (admitOrDischarge === "Discharge") {
      admitOrDischargeCode = 'D'
    }
    if (this.record?.data && this.record.data.admitDischargeSw !== admitOrDischargeCode) {
      this.getControl('data.nursingFacilityName').reset();
      this.getControl('data.nursingFacilityAddress').reset();
      this.getControl('data.admissionInfo').reset();
      this.getControl('data.dischargeInfo').reset();
    }
    this.getControl('data.admitDischargeSw').patchValue(admitOrDischargeCode);
    this.resetMilitryAddressFields();
    this.disableAddress = this.getControl('data.instId').value;
    this.isNursingRowSelected = this.getControl('data.instId').value;
    this.nursingFacility.get('nursingFacilitySearch').valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        if (res) {
          this.showSearch = true;
        }
      })

      if (this.admitDischarge === 'Admission') {
        this.admissionDate = true;
        this.nursingFacility.get('admissionInfo.admissionDt').setValidators([Vs.required]);
        this.nursingFacility.get('admissionInfo.admissionDt').updateValueAndValidity();
        this.processService.addressValidated = true;
        this.resetDataFordischargeReason();
        this.clearValidatorForFirstSection()
      } else {
        this.admissionDate = false;
        this.nursingFacility.get('admissionInfo.admissionDt').clearValidators();
        this.nursingFacility.get('admissionInfo.admissionDt').updateValueAndValidity();
        this.setValidatorForFirstSection()
      }

      if (this.admitDischarge === 'Discharge') {
        this.admissionDate = false;
        this.nursingFacility.get('admissionInfo.admissionDt').clearValidators();
        this.nursingFacility.get('admissionInfo.admissionDt').updateValueAndValidity();
        this.showDischargeDate = (!isEmpty(this.getControl('data.dischargeInfo.dischargeDt').value) && !isNil(this.getControl('data.dischargeInfo.dischargeDt').value));
        this.showDeathDate = (!isEmpty(this.getControl('data.dischargeInfo.dateOfDeath').value) && !isNil(this.getControl('data.dischargeInfo.dateOfDeath').value));
        if (this.showDeathDate) {
          this.clearAddressValidation();
        }
        if (!isEmpty(this.getControl('data.dischargeInfo.dischargeAddrSw').value) && !isNil(this.getControl('data.dischargeInfo.dischargeAddrSw').value)) {
          if (this.getControl('data.dischargeInfo.dischargeAddrSw').value.code === 'Y') {
            this.getControl('data.dischargeInfo.dischargeAddress.address').patchValue(this.record.data.dischargeInfo.dischargeAddress.address);
            this.showNewAddress = true;
          } else {
            this.showNewAddress = false;
          }
        } else {
          this.showNewAddress = false;
        }
      } else {
        this.admissionDate = true;
        this.nursingFacility.get('admissionInfo.admissionDt').setValidators([Vs.required]);
        this.nursingFacility.get('admissionInfo.admissionDt').updateValueAndValidity();
      }

      this.showNursingDetails = !isEmpty(this.getControl('data.nursingFacilityName').value) && !isNil(this.getControl('data.nursingFacilityName').value);
      if (this.showNursingDetails) {
        this.getControl('data.nursingFacilityAddress.address').patchValue(this.record.data.nursingFacilityAddress.address);
      }
      this.processService.addressValidated = true;

      this.nursingFacility.get('dischargeInfo.dischargereason').valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        if (res && res.value === 'Death') {
          this.showDeathDate = true
          this.showDischargeDate = false;
          if (this.isNursingRowSelected) {
            this.processService.addressValidated = true;
          }
          this.nursingFacility.get('dischargeInfo.dateOfDeath').setValidators([Vs.required]);
          this.nursingFacility.get('dischargeInfo.dateOfDeath').updateValueAndValidity();
          this.nursingFacility.get('dischargeInfo.dischargeDt').clearValidators();
          this.nursingFacility.get('dischargeInfo.dischargeDt').updateValueAndValidity();
          this.nursingFacility.get('dischargeInfo.dischargeAddrSw').clearValidators();
          this.nursingFacility.get('dischargeInfo.dischargeAddrSw').updateValueAndValidity();
          this.resetDataFordischargeReason();
          this.clearValidatorForFirstSection()
        } else if (res) {
          this.showDischargeDate = true
          this.showDeathDate = false
          this.showNewAddress = false;
          this.nursingFacility.get('dischargeInfo.dateOfDeath').clearValidators();
          this.nursingFacility.get('dischargeInfo.dateOfDeath').updateValueAndValidity();
          this.nursingFacility.get('dischargeInfo.dischargeDt').setValidators([Vs.required]);
          this.nursingFacility.get('dischargeInfo.dischargeDt').updateValueAndValidity();
          this.nursingFacility.get('dischargeInfo.dischargeAddrSw').setValidators([Vs.required]);
          this.nursingFacility.get('dischargeInfo.dischargeAddrSw').updateValueAndValidity();
          this.resetDataFordischargeReason();
          this.setValidatorForFirstSection();
        } else {
          this.nursingFacility.get('dischargeInfo.dischargereason').setValidators([Vs.required]);
          this.nursingFacility.get('dischargeInfo.dischargereason').updateValueAndValidity();
        }
      })

      this.nursingFacility.get('dischargeInfo.dischargeAddrSw').valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        if (res && res.value === 'Yes') {
          this.showNewAddress = true
          this.processService.addressValidated = false;
          this.setValidatorForFirstSection()
        } else if (res) {
          this.showNewAddress = false;
          this.processService.addressValidated = true;
          this.clearValidatorForFirstSection()
        } else {
          this.processService.addressValidated = true;
        }
      })

      if (!this.showNursingDetails) {
        this.getControl('data.nursingFacilityAddress', this.formGroup).disable();
        this.getControl('data.nursingFacilityName', this.formGroup).disable();
      }

     if (this.nursingFacility.get('dischargeInfo.dischargeAddress.address.county')) {
        this.nursingFacility.get('dischargeInfo.dischargeAddress.address.county').disable();
      }

      if (this.nursingFacility.get('nursingFacilityAddress.address.county')) {
        this.nursingFacility.get('nursingFacilityAddress.address.county').disable();
      }
  }
  ngAfterViewInit(): void {
    window.scroll(0,0);
  }
  addressValidate(action) {
    let postObject;
    const appNum = this.applicationNumber;
    const formData = this.formGroup.value.data;
    const nursingFacility = getDataValue('nursingFacilityAddress.address', formData);
    const discharge = getDataValue('dischargeInfo.dischargeAddress.address', formData);

    const isSaveExit = !!equals(action, 'fromSaveExit');
    if (this.showNursingDetails && !this.isNursingRowSelected && !isNil(nursingFacility)) {
      postObject = {
        addressList: [
          { addressType: 'PHYSICAL', originalAddress: nursingFacility },
        ]
      }
    } else if (this.showNewAddress && !isNil(discharge)) {
      postObject = {
        addressList: [
          { addressType: 'MAILING', originalAddress: discharge },
        ]
      }
    }
    if (this.showNursingDetails && !this.isNursingRowSelected &&  this.showNewAddress  && !isNil(nursingFacility) && !isNil(discharge)) {
      postObject = {
        addressList: [
          { addressType: 'PHYSICAL', originalAddress: nursingFacility },
          { addressType: 'MAILING', originalAddress: discharge },
        ]
      }
    };

    if (postObject && isEmpty(postObject.addressList)) {
      this.processService.addressValidated = true;
    } else {
      this.apiService.post('applications/' + appNum + '/sections/HHAPL/address', postObject, {}, true).subscribe((res) => {
        if (!isNil(res)) {
          this.modalService.openAddressModal(res, isSaveExit).subscribe(result => {
            if (result !== true) {
              if (result) {
              const phyIndex = result.findIndex(i => i.addressType === 'PHYSICAL');
              const mailIndex = result.findIndex(i => i.addressType === 'MAILING');

              if (phyIndex === 0) {
                const addressLine1 = this.getControl('data.nursingFacilityAddress.address.addressLine1', this.formGroup);
                const addressLine2 = this.getControl('data.nursingFacilityAddress.address.addressLine2', this.formGroup);
                const city = this.getControl('data.nursingFacilityAddress.address.city', this.formGroup);
                const state = this.getControl('data.nursingFacilityAddress.address.state', this.formGroup);
                const zip = this.getControl('data.nursingFacilityAddress.address.zip', this.formGroup);

                if (result[0].selectedAddress === 'Suggested') {
                  addressLine1.setValue(result[0].suggestedAddress.addressLine1);
                  addressLine2.setValue(result[0].suggestedAddress.addressLine2);
                  city.setValue(result[0].suggestedAddress.city);
                  state.setValue(result[0].originalAddress.state);
                  zip.setValue(result[0].suggestedAddress.zip);
                } else if (result[0].selectedAddress === 'Original') {
                  addressLine1.setValue(result[0].originalAddress.addressLine1);
                  addressLine2.setValue(result[0].originalAddress.addressLine2);
                  city.setValue(result[0].originalAddress.city);
                  state.setValue(result[0].originalAddress.state);
                  zip.setValue(result[0].originalAddress.zip);
                }
              };
              if (mailIndex === 0 || mailIndex === 1)  {
                const idx = mailIndex;
                const addressLine1 = this.getControl('data.dischargeInfo.dischargeAddress.address.addressLine1', this.formGroup);
                const addressLine2 = this.getControl('data.dischargeInfo.dischargeAddress.address.addressLine2', this.formGroup);
                const city = this.getControl('data.dischargeInfo.dischargeAddress.address.city', this.formGroup);
                const state = this.getControl('data.dischargeInfo.dischargeAddress.address.state', this.formGroup);
                const zip = this.getControl('data.dischargeInfo.dischargeAddress.address.zip', this.formGroup);

                if (result[idx].selectedAddress === 'Suggested') {
                  addressLine1.setValue(result[idx].suggestedAddress.addressLine1);
                  addressLine2.setValue(result[idx].suggestedAddress.addressLine2);
                  city.setValue(result[idx].suggestedAddress.city);
                  state.setValue(result[idx].originalAddress.state);
                  zip.setValue(result[idx].suggestedAddress.zip);
                } else if (result[idx].selectedAddress === 'Original') {
                  addressLine1.setValue(result[idx].originalAddress.addressLine1);
                  addressLine2.setValue(result[idx].originalAddress.addressLine2);
                  city.setValue(result[idx].originalAddress.city);
                  state.setValue(result[idx].originalAddress.state);
                  zip.setValue(result[idx].originalAddress.zip);
                }
              };
              this.processService.addressValidated = true;
            }
          }
          });
        } else {
          this.processService.addressValidated = true;
        }
      });
    }
  }

  resetDataFordischargeReason() {
    this.getControl('data.dischargeInfo.dischargeAddress').reset();
    this.getControl('data.dischargeInfo.dateOfDeath').reset();
    this.getControl('data.dischargeInfo.dischargeAddrSw').reset();
    this.getControl('data.dischargeInfo.dischargeDt').reset();

  }

  resetDataForNursingFacility() {
    this.getControl('data.nursingFacilityAddress').reset();
    this.getControl('data.nursingFacilityName').reset();
  }

  resetMilitryAddressFields() {
    this.nursingFacility.get('nursingFacilityAddress.address.addrAaAeAp').clearValidators();
    this.nursingFacility.get('nursingFacilityAddress.address.addrAaAeAp').updateValueAndValidity();
    this.nursingFacility.get('nursingFacilityAddress.address.addrApoFpo').clearValidators();
    this.nursingFacility.get('nursingFacilityAddress.address.addrApoFpo').updateValueAndValidity();

    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.addrAaAeAp').clearValidators();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.addrAaAeAp').updateValueAndValidity();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.addrApoFpo').clearValidators();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.addrApoFpo').updateValueAndValidity();
  }

  clearAddressValidation() {
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.addrAaAeAp').clearValidators();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.addrAaAeAp').updateValueAndValidity();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.addrApoFpo').clearValidators();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.addrApoFpo').updateValueAndValidity();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.addressLine1').clearValidators();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.addressLine1').updateValueAndValidity();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.county').clearValidators();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.county').updateValueAndValidity();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.city').clearValidators();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.city').updateValueAndValidity();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.state').clearValidators();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.state').updateValueAndValidity();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.zip').clearValidators();
    this.nursingFacility.get('dischargeInfo.dischargeAddress.address.zip').updateValueAndValidity();
  }

  getFullAddress(address: any): string{
    let fullAddress: string =' ';
    if(address){
      fullAddress = address.addressLine1;
      if(address.addressLine2){
        fullAddress = fullAddress + "," + address.addressLine2;
      }
      fullAddress = fullAddress + "<br>";
      if(address.city) {
        fullAddress = fullAddress + address.city + ", ";
      }
      if(address.state){
        fullAddress = fullAddress + address.state.code + " ";
      } if(address.zip) {
        fullAddress = fullAddress + address.zip;
      }
    }
  return fullAddress;
  }

  ngOnDestroy() {
    this.addressValidate$.unsubscribe();
  }
}
