import { compose } from 'ramda';
import { pathOr } from 'ramda';
import { merge } from 'ramda';
import { assocPath } from 'ramda';
import { converge } from 'ramda';
import { objOf } from 'ramda';
import { identity } from 'ramda';
import { propEq } from 'ramda';
import { useWith } from 'ramda';
import { reject } from 'ramda';
import { eqProps } from 'ramda';
import { call } from 'ramda';
import { curry } from 'ramda';

import { value } from './value.helpers';
import { nilProp, ifNun } from './null.helpers';
import { personUncompleted } from './person.helpers';
import { oPath, withFind, pathS, ifNilOr, assignAs, orO } from './function.helpers';

const orDemographics = pathOr('demographics');
export const setRecordId = assocPath(['identifier', 'recordId']);
export const recordSection = pathOr(null, ['identifier', 'sectionId']);

export const identifier = oPath(['identifier']);
export const identifierEqual = propEq('identifier');
const individualId = pathOr(null, ['individualId']);

export const identifierIndividualId = compose(
  individualId,
  identifier
);

const blockId = compose(
  orDemographics(['blockId']),
  personUncompleted
);

const recordId = compose(
  objOf('recordId'),
  blockId
);

export const recordIdentifier = converge(merge, [recordId, identifier]);

export const recordIndividualId = compose(
  individualId,
  recordIdentifier
);

export const setBlockId = to =>
  compose(
    assignAs(to, 'blockId'),
    orDemographics(['recordId'])
  );

export const isCreate = nilProp('data');

const asIdentifier = objOf('identifier');
const asIndividualId = objOf('individualId');

export const toIndividualId = compose(
  asIdentifier,
  asIndividualId
);

export const toIdentifier = compose(
  toIndividualId,
  value
);

export const formIdentifier = oPath(['value', 'identifier']);
export const formData = oPath(['value', 'data']);

export const recordIndividual = withFind([
  compose(
    identifierEqual,
    identifierIndividualId
  ),
  identity,
]);

const eqIdentifier = eqProps('identifier');
const safeIdentifier = compose(
  eqIdentifier,
  orO
);

export const otherIndividuals = useWith(reject, [safeIdentifier, identity]);

const toId = curry(
  compose(
    toIndividualId,
    pathS
  )
);

const hasId = compose(
  ifNun,
  toId
);

const withIndividual = individual =>
  compose(
    ifNilOr(individual),
    hasId(individual)
  );

export const fromId = useWith(call, [withIndividual, identity]);