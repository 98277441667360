import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';

import { prop } from 'ramda';
import { isSelf } from '@app/helpers/individual.helpers';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { isYes, isNo } from '@app/helpers/tayn.helpers';
import { swapControl, swapControls, toggles } from '@app/helpers/forms.helpers';
import { teenager, fosterCare, fullTimeAge, isStudent, workFullTime } from '@app/helpers/person.helpers';
import { isApplyForBenefits, isExported, isPresumptiveApp, isRmbOnly, isRmbRmc } from '@app/helpers/mode.helpers';
import { minus120Years, add10Months, minus12Months, minus3Months } from '@app/helpers/date.helpers';
import Vs from "@app/services/validators/validators.service";
import { formatDate } from '@angular/common';
import { formatTableData } from '@app/helpers/tables.helpers';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { push } from '@app/helpers/form-array.helpers';
import { formControl } from '@app/helpers/form-control.helpers';
import { removeAt } from './../../../../../helpers/form-array.helpers';
import { Title } from '@angular/platform-browser';
@Component({
  templateUrl: './additional.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalComponent extends SectionFormDirective implements AfterContentInit {
  today = new Date();
  minimumDate = minus120Years();
  maximumDueDate = add10Months();
  minimumPregDate = minus12Months();
  minimumMedSerDate = minus3Months();
  isExported = false;
  isTeen = false;
  isHomeCare = false;
  isPregnable = false;
  isFosterCare = false;
  fullTimeEligible = false;
  isHead = false;
  isPregnantReadOnly: any = null;
  usCitizen = false;
  isStudentAge;
  popTaxDepOutOfHome = `People who do not live in the household but can be claimed on the person's federal income tax.`;
  isAFB;
  isPresumptive;
  workingFullTime = true;
  isWorkFullTime;
  disablePastPreg = false;
  defaultLanguage;
  translatedLang;
  isRmbRmc = false;
  isPregnableAndMovedIn = false;
  isApplyMedServicesAndMovedIn = false;
  showNewbornList = false;
  ageGreaterThanEighteenOnTwentyThree;
  isFosterCareAge;
  appTypePregnancy = sessionStorage.getItem('appType');
  isRetroIndividual = false;
  isNewIndividual = false;
  isApplyMedServices;
  user = sessionStorage.getItem('userType');
  appType = sessionStorage.getItem('appType');
  isFinReassessment;
  isAFBorFC = (this.appType === 'AFB') || (this.user == 'RP' && (this.appType === 'AFB'));
  isFC = (this.user == 'RP' && (this.appType === 'AFB'));
  showPastPregDueDateNudge: boolean;
  isRmbOnly = false;
  closePregDueDateNudge: boolean = false;
  closeNudgeData: boolean = false;
  wpIndivId: string;

  constructor(private titleService: Title, builder: FormBuilder, public translateService: TranslateService) {
    super(builder);
    this.titleService.setTitle('Additional Details');
  }

  get newBornInfoList(): FormArray {
    return this.getControl('data.pregnancyOne.newBornInfoList') as FormArray;
  };

  getDiff(a, b) {
    return Math.abs(a - b);
  }

  addDynamicRows(val) {
    const dataList = this.getControl('data.pregnancyOne.newBornInfoList') as FormArray;
    dataList.enable();
    const formData = this.formGroup.value.data;
    const noOfKids = parseInt(val.code);
    let listLength = formData.pregnancyOne.newBornInfoList?.length;
    if (listLength === undefined) {
      listLength = 0;
    }
    const diff = this.getDiff(noOfKids, listLength);

    if (noOfKids >= listLength) {
      this.showNewbornList = true;
      for (let i = 0; i < diff; i++) {
        push(new FormGroup({ 
          ssn: new formControl(null),
          gender: new formControl(null, [Validators.required]),
          newbornMiddleName: new formControl(null),
          newbornDOB: new formControl(null, [Validators.required]),
          newbornLastName: new formControl(null, [Validators.required]),
          newbornFirstName: new formControl(null, [Validators.required])
        }),
        this.newBornInfoList);
      }
      if (noOfKids === listLength) {
        this.newBornInfoList.value[0]?.newbornDOB?.reset();
      }
    } else {
      for (let i = 0; i < diff; i++) {
        removeAt(-1, this.newBornInfoList);
      }
    }
  };

  isGreaterafterTwentyThree(){
    if(this.ageGreaterThanEighteenOnTwentyThree === false && this.isFosterCareAge && isYes(this.getControl('data.formerFosterCare').value)){
      if(this.isPregnable && this.appTypePregnancy !== 'PRG'){
        this.getControl('data.pregnancyOne.isPregnant').disable();
      }
      if((this.isPregnable || this.disablePastPreg) && this.appTypePregnancy !== 'PRG'){
        this.getControl('data.pregnancyOne.nextPregSw').disable();
      }
    }else if(this.ageGreaterThanEighteenOnTwentyThree === false && this.isFosterCareAge && isNo(this.getControl('data.formerFosterCare').value)){
      if(this.isPregnable && this.appTypePregnancy !== 'PRG'){
        this.getControl('data.pregnancyOne.isPregnant').enable();
        this.getControl('data.pregnancyOne.isPregnant').setValidators([Vs.required]);
      }
      if((this.isPregnable) && this.appTypePregnancy !== 'PRG' && this.isPregnableAndMovedIn){
        if(!this.disablePastPreg){
          this.getControl('data.pregnancyOne.nextPregSw').enable();
          this.getControl('data.pregnancyOne.nextPregSw').setValidators([Vs.required]);
        }
      }
    }
    if ((!this.isPresumptive || !this.isRmbRmc) && this.usCitizen && 
            ((this.ageGreaterThanEighteenOnTwentyThree === false) && this.isFosterCareAge && isNo(this.getControl('data.formerFosterCare').value))) {
      const helpWithEmergencyServiceBills = this.getControl('data.helpWithEmergencyServiceBills');
      helpWithEmergencyServiceBills.enable();
      helpWithEmergencyServiceBills.setValidators([Vs.required]);
    }
    if ((!this.isPresumptive || !this.isRmbRmc) && this.usCitizen && 
         (this.ageGreaterThanEighteenOnTwentyThree === false && this.isFosterCareAge && isYes(this.getControl('data.formerFosterCare').value))) {
      const helpWithEmergencyServiceBills = this.getControl('data.helpWithEmergencyServiceBills');
      helpWithEmergencyServiceBills.clearValidators();
      helpWithEmergencyServiceBills.updateValueAndValidity(); 
      helpWithEmergencyServiceBills.disable();
    }

    //Emergency Services when FFC is yes/no
    const receivedMedSer = this.getControl('data.medicalService.receivedMedSer');
    const dateReceivedMedSer = this.getControl('data.medicalService.dateReceivedMedSer');
    if ((this.isApplyMedServices && (this.isAFBorFC || this.isApplyMedServicesAndMovedIn)) && 
            ((this.ageGreaterThanEighteenOnTwentyThree === false) && this.isFosterCareAge && isNo(this.getControl('data.formerFosterCare').value))) {
      receivedMedSer.enable();
      if(isYes(this.getControl('data.medicalService.receivedMedSer').value)){
        dateReceivedMedSer.enable();
      };
    }

    if ((this.isApplyMedServices && (this.isAFBorFC || this.isApplyMedServicesAndMovedIn)) && 
            ((this.ageGreaterThanEighteenOnTwentyThree === false) && this.isFosterCareAge && isYes(this.getControl('data.formerFosterCare').value))) {
      const receivedMedSer = this.getControl('data.medicalService.receivedMedSer');      
      receivedMedSer.disable();
      dateReceivedMedSer.disable();
    }

  }
  

  isPregnantChanged(val){
    if(this.getControl('data.pregnancyOne.stillPregSw').value == null){
      this.showNewbornList = false;
    }

    //Medical Services RMB/C - newly added person
    const appType = sessionStorage.getItem('appType');
    const receivedMedSer = this.getControl('data.medicalService.receivedMedSer');
    const dateReceivedMedSer = this.getControl('data.medicalService.dateReceivedMedSer');
    const isKepro = JSON.parse(sessionStorage.getItem('isKepro'));
    
    if(this.isApplyMedServices || isYes(this.getControl('data.pregnancyOne.isPregnant').value)){
      if (this.selectedIndividual.someoneMovedIn === 'Y') {
        this.isNewIndividual = true;
      }
    }

    if (this.isNewIndividual && (appType === 'RC' || appType === 'RB' || this.isFinReassessment || (isKepro && (appType === 'CHANGE_REPORT' || appType === 'REDETERMINATION')))) {
      this.isApplyMedServicesAndMovedIn = true;
      receivedMedSer.enable();
      if(isYes(this.getControl('data.medicalService.receivedMedSer').value)){
        dateReceivedMedSer.enable();
      };
    } else if (this.isNewIndividual === false && (appType === 'RC' || appType === 'RB' || this.isFinReassessment || (isKepro && (appType === 'CHANGE_REPORT' || appType === 'REDETERMINATION')))) {
      this.isApplyMedServicesAndMovedIn = false;
      receivedMedSer.disable();
      dateReceivedMedSer.disable();
    }


    if((this.selectedIndividual.age < 19 || isYes(this.getControl('data.pregnancyOne.isPregnant').value) 
                                         || isNo(this.getControl('data.pregnancyOne.isPregnant').value))
                 && (this.isAFBorFC || this.isApplyMedServicesAndMovedIn)){
      if(val.target?.value === 'Yes' || val.target?.value === 'Si'){
        this.getControl('data.medicalService.receivedMedSer').enable();
        if(isYes(this.getControl('data.medicalService.receivedMedSer').value)){
          this.getControl('data.medicalService.dateReceivedMedSer').enable();
          this.getControl('data.medicalService.dateReceivedMedSer').setValidators([Vs.required, Vs.notBefore90Days]);
        }else{
          this.getControl('data.medicalService.dateReceivedMedSer').clearValidators();
          this.getControl('data.medicalService.dateReceivedMedSer').updateValueAndValidity();
          this.getControl('data.medicalService.dateReceivedMedSer').disable();
        }
      }else if(val.target?.value === 'No'){
        if(isNo(this.getControl('data.medicalService.receivedMedSer').value)){
          this.getControl('data.medicalService.dateReceivedMedSer').clearValidators();
          this.getControl('data.medicalService.dateReceivedMedSer').updateValueAndValidity();
        }
        if(this.selectedIndividual.age >= 19){
          this.getControl('data.medicalService.receivedMedSer').clearValidators();
          this.getControl('data.medicalService.receivedMedSer').updateValueAndValidity();
          this.getControl('data.medicalService.receivedMedSer').disable();
          this.getControl('data.medicalService.dateReceivedMedSer').clearValidators();
          this.getControl('data.medicalService.dateReceivedMedSer').updateValueAndValidity();
          this.getControl('data.medicalService.dateReceivedMedSer').disable();
        }
      }
    }
  }


  addDateReceivedMedSer(value){
    if(value.target?.value === 'Yes' || value.target?.value === 'Si' ){
      this.getControl('data.medicalService.dateReceivedMedSer').enable();
      this.getControl('data.medicalService.dateReceivedMedSer').setValidators([Vs.required, Vs.notBefore90Days]);
    }else{
      const dateReceivedMedSer = this.getControl('data.medicalService.dateReceivedMedSer');
      dateReceivedMedSer.clearValidators();
      dateReceivedMedSer.updateValueAndValidity(); 
      dateReceivedMedSer.disable();
    }
  }
  
  disableRemovePreg(type) {
    if(this.getControl('data.pregnancyOne.stillPregSw').value){
      if(this.getControl('data.pregnancyOne.stillPregSw').value.code === 'Y'){
        this.showNewbornList = false;
      }
    }
    if (type?.code === 'N') {
      this.showNewbornList = false;
      const dataList = this.getControl('data.pregnancyOne.newBornInfoList') as FormArray;
      dataList.clear();
    } else if (type?.code === 'Y') {
      const addBabiesSw = this.getControl('data.pregnancyOne.addBabiesSw');
      if (!this.isRmbRmc) {
        addBabiesSw.disable();
      }
    }else if (type?.code === undefined) {
      this.showNewbornList = false;
      const dataList = this.getControl('data.pregnancyOne.newBornInfoList') as FormArray;
      dataList.clear();
    }
  };

  ngAfterContentInit() {
    this.isRmbRmc = isRmbRmc(this.mode);
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);

    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang
      this.languageCheck(res.lang);
    });
    this.isRmbOnly = isRmbOnly(this.mode);
    this.isAFB = isApplyForBenefits(this.applicationType);
    this.isPresumptive = isPresumptiveApp(this.applicationType);
    this.ageGreaterThanEighteenOnTwentyThree = this.getAge(this.selectedIndividual.birthDate) >= 18;
    this.isFosterCareAge = fosterCare(this.selectedIndividual);

    if(this.selectedIndividual?.applyForCoverage?.code === 'N'){
      this.getControl('data.formerFosterCare').setValue(null);
    };

    const citizenData = prop('usCitizen', this.record.data);
    
    if (this.isPresumptive || this.isRmbRmc) {
      this.usCitizen = false;
    } else {
      this.usCitizen = isNo(citizenData);
    }

    this.isHead = isSelf(this.selectedIndividual);
    this.isExported = this.record && this.record.exportingStatus && isExported(this.record.exportingStatus);
    this.isTeen = teenager(this.selectedIndividual);
    this.isPregnable = this.pregnable(this.selectedIndividual);
    this.fullTimeEligible = fullTimeAge(this.selectedIndividual);
    this.isStudentAge = isStudent(this.selectedIndividual);
    this.isWorkFullTime = workFullTime(this.selectedIndividual)
    this.isApplyMedServices = (this.selectedIndividual.age < 19) || (this.record.data.pregnancyOne.isPregnant?.code === 'Y');
    this.isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));

    const pregnancyOne = this.getControl('data.pregnancyOne');
    const isPregnantOne = pregnancyOne.get('isPregnant');
    const numberOfBabiesControlOne = pregnancyOne.get('numberOfBabies');
    const stillPregSwOne = pregnancyOne.get('stillPregSw');
    const pregEndDateOne = pregnancyOne.get('pregEndDate');
    const nextPregSwOne = pregnancyOne.get('nextPregSw');
    const dueDate = pregnancyOne.get('dueDate');
    const nextPregNumberOfBabiesOne = pregnancyOne.get('nextPregNumberOfBabies');
    const nextPregDueDateOne = pregnancyOne.get('nextPregDueDate');
    const addBabiesSw = pregnancyOne.get('addBabiesSw');
    const noOfBabiesBorn = pregnancyOne.get('noOfBabiesBorn');
    const newBornInfoList = pregnancyOne.get('newBornInfoList');
    // const isKepro = JSON.parse(sessionStorage.getItem('isKepro'));
    if (newBornInfoList.value[0].gender !== null && this.isRmbRmc) {
      this.showNewbornList = true;
    } else {
      newBornInfoList.disable();
    };

    if (!this.isRmbRmc) {
      addBabiesSw.disable();
    }

    const taxFilerInfo = this.getControl('data.taxFilerInfo');
    const filing = taxFilerInfo.get('filing');
    const hasTaxDependentOutside = taxFilerInfo.get('hasTaxDependentOutside');
    const formerFosterCare = this.getControl('data.formerFosterCare');

    const { applyForCoverage, applyForOnGoingCoverage } = this.selectedIndividual;
    this.disablePastPreg = isYes(applyForCoverage) && isNo(applyForOnGoingCoverage);
    
    if (this.isPregnable) {
      if (this.selectedIndividual.someoneMovedIn === 'Y') {
        this.isRetroIndividual = true;
      }
    }
    const appType = sessionStorage.getItem('appType');
    if (this.isRetroIndividual && (appType === 'RC' || appType === 'RB')) {
      this.isPregnableAndMovedIn = true;
      nextPregSwOne.enable();
    } else if (this.isRetroIndividual === false && (appType === 'RC' || appType === 'RB')) {
      this.isPregnableAndMovedIn = false;
      nextPregSwOne.disable();
      nextPregNumberOfBabiesOne.disable();
      nextPregDueDateOne.disable();
    } else {
      this.isPregnableAndMovedIn = true;
    }

    if (isYes(applyForCoverage)) {
      this.isFosterCare = fosterCare(this.selectedIndividual);
    }


    //Medical Services RMB/C - newly added person
    const receivedMedSer = this.getControl('data.medicalService.receivedMedSer');
    const dateReceivedMedSer = this.getControl('data.medicalService.dateReceivedMedSer');
    const isKepro = JSON.parse(sessionStorage.getItem('isKepro'));

    if(this.isApplyMedServices){
      if (this.selectedIndividual.someoneMovedIn === 'Y') {
        this.isNewIndividual = true;
      }
    }

    if (this.isNewIndividual && (appType === 'RC' || appType === 'RB' || this.isFinReassessment || (isKepro && (appType === 'CHANGE_REPORT' || appType === 'REDETERMINATION')))) {
      this.isApplyMedServicesAndMovedIn = true;
      receivedMedSer.enable();
      if(isYes(this.getControl('data.medicalService.receivedMedSer').value)){
        dateReceivedMedSer.enable();
      };
    } else if (this.isNewIndividual === false && (appType === 'RC' || appType === 'RB' || this.isFinReassessment || (isKepro && (appType === 'CHANGE_REPORT' || appType === 'REDETERMINATION')))) {
      this.isApplyMedServicesAndMovedIn = false;
      receivedMedSer.disable();
      dateReceivedMedSer.disable();
    }


    // if(this.isRmbRmc && isKepro){
    //   formerFosterCare.clearValidators();
    //   formerFosterCare.updateValueAndValidity();         
    // }

    if (this.isRmbRmc) {
      this.registerToggle(isPregnantOne, swapControls([stillPregSwOne], isYes));
    } else {
      noOfBabiesBorn.disable();
      newBornInfoList.disable();
      this.registerToggle(isPregnantOne, swapControls([stillPregSwOne], isYes));
    };
    
    if (this.isRmbRmc) {
      this.registerToggle(stillPregSwOne, swapControls([numberOfBabiesControlOne], isYes));
    } else {
      noOfBabiesBorn.disable();
      newBornInfoList.disable();
      this.registerToggle(stillPregSwOne, swapControls([numberOfBabiesControlOne], isYes));
    };

    if (this.isRmbRmc) {
      this.registerToggle(stillPregSwOne, swapControls([addBabiesSw], isNo));
    } else {
      noOfBabiesBorn.disable();
      newBornInfoList.disable();      
    };

    this.registerToggle(stillPregSwOne, toggles([swapControls(addBabiesSw, isYes)]));
    this.registerToggle(stillPregSwOne, toggles([swapControl(pregEndDateOne, isNo), swapControl(dueDate, isYes)]));
    this.registerToggle(nextPregSwOne, swapControls([nextPregDueDateOne, nextPregNumberOfBabiesOne], isYes));
    this.registerToggle(addBabiesSw, toggles([
      swapControl(noOfBabiesBorn, isYes),
      type => this.disableRemovePreg(type)
    ]));


    if(this.ageGreaterThanEighteenOnTwentyThree === false && this.isFosterCareAge && isYes(this.getControl('data.formerFosterCare').value)){
      if(this.isPregnable && this.appTypePregnancy !== 'PRG'){
        this.getControl('data.pregnancyOne.isPregnant').disable();
      }
      if((this.isPregnable || this.disablePastPreg) && this.appTypePregnancy !== 'PRG'){
        this.getControl('data.pregnancyOne.nextPregSw').disable();
      }
    }
    
    const helpWithEmergencyServiceBills = this.getControl('data.helpWithEmergencyServiceBills');
    if ((!this.isPresumptive || !this.isRmbRmc) && this.usCitizen && 
            ( ((this.ageGreaterThanEighteenOnTwentyThree === false) && (formerFosterCare.value === 'No')) || this.ageGreaterThanEighteenOnTwentyThree || !this.isEnabled('data.formerFosterCare'))) {
      helpWithEmergencyServiceBills.setValidators([Vs.required]);
    }

    if (( (this.selectedIndividual.age < 19) ||  
          (this.selectedIndividual.age < 19 && this.record.data.pregnancyOne.isPregnant?.code === 'Y') ||
          (this.selectedIndividual.age > 19 && this.record.data.pregnancyOne.isPregnant?.code === 'Y')) && (this.isAFBorFC || this.isApplyMedServicesAndMovedIn) && 
            ( ((this.ageGreaterThanEighteenOnTwentyThree === false) && (formerFosterCare.value === 'No')) || this.ageGreaterThanEighteenOnTwentyThree || !this.isEnabled('data.formerFosterCare'))) {
      const receivedMedSer = this.getControl('data.medicalService.receivedMedSer');
      receivedMedSer.enable();
    }
    
    if (!this.isPresumptive) {
      filing.clearValidators();
      hasTaxDependentOutside.clearValidators();
    }

    if (this.isFinReassessment || this.isRmbOnly) {
      this.wpIndivId = this.getValue('data.wpIndvNumber');
      const getIndivData = JSON.parse(sessionStorage.getItem('selectedIndivData'));
      if (getIndivData && getIndivData?.length > 0) {
        const length: number = getIndivData.filter((item: any) => item.indivId === this.wpIndivId).length;

        if (length === 0) {
          const newObj = {
            indivId: this.wpIndivId,
            isVisited: true,
            closeSnackbar: false
          };

          getIndivData.push(newObj);
          sessionStorage.setItem('selectedIndivData', JSON.stringify(getIndivData));
          this.closeNudgeData = true;
        }
      } else {
        const newObj = [
          {
            indivId: this.wpIndivId,
            isVisited: true,
            closeSnackbar: false
          }
        ]
        sessionStorage.setItem('selectedIndivData', JSON.stringify(newObj));
        this.closeNudgeData = true;
      }

      this.showPastPregDueDateNudge = this.getValue('data.showPastPregDueDateNudge') === 'Y' ? true : false;
      if (this.showPastPregDueDateNudge) {
        const getIdentifier = getIndivData.filter((item: any) => item.indivId === this.wpIndivId);
        if (!!getIdentifier && getIdentifier.length >= 1) {
          this.closePregDueDateNudge = this.closeNudgeData ? !this.closeNudgeData : getIdentifier[0].isVisited || getIdentifier[0].closeSnackbar;
        }
      }
    }
  }

  closeSnackbar() {
    this.closePregDueDateNudge = true;
    this.wpIndivId = this.getValue('data.wpIndvNumber');
    const getIndivData = JSON.parse(sessionStorage.getItem('selectedIndivData'));
    if (!!getIndivData) {
      getIndivData.forEach(item => {
        if (item.indivId === this.wpIndivId) {
          item.closeSnackbar = true;
        }
      });
      sessionStorage.setItem('selectedIndivData', JSON.stringify(getIndivData));
    }
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  tableCodeValue(tableName, tableControl, ) {
    if (typeof tableControl !== 'string') {
      const idx = tableControl;
      const newbornList = this.getControl('data.pregnancyOne.newBornInfoList');
      const newbornCtrls = newbornList['controls'];
      const relationControl = newbornCtrls[idx];
      tableControl = relationControl.controls['gender'];
      }
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang, true)
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  pregnable(individual) {
    return individual.age >= 10 && individual.age <= 55 && individual.gender.code === 'F';
  }

  isInvalidDate(event) {
    const enteredDate = event.target.value;
    if (!enteredDate || enteredDate === '' || enteredDate === 'Invalid date') {
      event.target.value = enteredDate;
    } else if (event.target.name === 'pregEndDate') {
      event.target.value = formatDate(this.getControl('data.pregnancyOne.pregEndDate').value, 'MM/dd/yyyy', 'en');
    //   event.target.value = formatDate(this.getControl('data.pregnancyOne.haveBabyDate').value, 'MM/dd/yyyy', 'en');
    } else if (event.target.name === 'dueDate') {
      event.target.value = formatDate(this.getControl('data.pregnancyOne.dueDate').value, 'MM/dd/yyyy', 'en');
    } else if (event.target.name === 'nextPregDueDate') {
      event.target.value = formatDate(this.getControl('data.pregnancyOne.nextPregDueDate').value, 'MM/dd/yyyy', 'en');
    } else if (event.target.name === 'dateReceivedMedSer') {
      event.target.value = formatDate(this.getControl('data.medicalService.dateReceivedMedSer').value, 'MM/dd/yyyy', 'en');
    }
  }


  getAge = (dob) => {
    var today = new Date('2023-01-01');
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
        age--;
    }    
    return age;
  }
}