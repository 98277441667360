import { construct } from 'ramda';

import { LoadApp, StartApp, GetApp, UpdateApp } from './app.actions';
import { LoadCongrats, SetIndividuals } from './app.actions';
import { LoadComments, UpdateComments } from './app.actions';

export const loadApp = construct(LoadApp);
export const startApp = construct(StartApp);
export const getApp = construct(GetApp);
export const updateApp = construct(UpdateApp);

export const setIndividuals = construct(SetIndividuals);

export const loadComments = construct(LoadComments);
export const updateComments = construct(UpdateComments);

export const loadCongrats = construct(LoadCongrats);
