import { Component, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { ContainerDirective } from '@app/directives/container/container.directive';

@Component({
  selector: 'nh-section-info',
  templateUrl: './section-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionInfoComponent implements AfterViewInit {
  @Input() public title: string;
  @Input() public tooltip: string;
  @Input() addAssisting: boolean;
  @Input() addKbMedicalBills: boolean;
  @Input() hideAddRep: boolean;
  @Input() sectionId;
  @Output() public create = new EventEmitter<any>();
  @Output() public edit = new EventEmitter<any>();
  @ViewChild(ContainerDirective, { static: false })
  add: ContainerDirective;

  emitCreate() {
    this.create.emit();
  }

  emitEdit () {
    this.edit.emit();
  }

  ngAfterViewInit() {
    // this.add.element().focus();
  }
}
