import { BccExtensionInformation } from './bccExtensionInformation.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import Vs from '@app/services/validators/validators.service';
import { toDate, toISO } from '@app/helpers/date.helpers';
import { evolve } from 'ramda';
import { ExitLabels } from '@app/models/section.model';

const formValidation = (builder, recordData) => {
  const bccExtensionInfoData = lookIn(recordData);
  const followupApptDt = toDate(bccExtensionInfoData(['followupApptDt']));
  const reasonForExtension = bccExtensionInfoData(['reasonForExtension']);
  const dob = bccExtensionInfoData(['dob']);
  const firstName = bccExtensionInfoData(['firstName']);
  const lastName = bccExtensionInfoData(['lastName']);
  const ssn = bccExtensionInfoData(['ssn']);
  const gender = bccExtensionInfoData(['gender']);
  const indvId = bccExtensionInfoData(['indvId']);
  const extensionFlag = bccExtensionInfoData(['extensionFlag'])
  
  
  const data = builder.group({
    followupApptDt: [followupApptDt],  
    reasonForExtension: [reasonForExtension, Vs.required],   
    confirmeBCC: [],
    dob : [dob],
    firstName : firstName,
    lastName : lastName,
    ssn : ssn,
    gender : gender,
    indvId : indvId,
    extensionFlag : extensionFlag
    
  })

  return { data };
};

const postObject = evolve({
  followupApptDt: toISO,
});

export default sectionConfiguration(
  BccExtensionInformation,
  formValidation,
  ['BCCEXTENSIONREASON'],
  [],
  {},
  {
    postObject: postObject,
    hasSummary: false,
    validateSubmit: true,
    showSaveAndExit: false,
    labelExit: ExitLabels.EXIT
  }
);
