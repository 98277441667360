export const otherMedicalQuestion = {
    medicareIndSw: 'Getting help with Medicare Costs',
    medicalBillsIndSw: 'Medical Bills',
    brstCervCancerSw: 'Breast or Cervical Cancer',
    medFacilitySw: 'Medical Facility',
    nursingHomeSw: 'Nursing Home',
    hospiceSw: 'Hospice Care',
    hcbsSw: 'Home and Community Based Services',
    ecfSw: 'Employment and Community First (ECF) Choices',
    intelDisbSw: 'Intermediate Care for Individuals with Intellectual Disabilities',
    ssiBenefitsSw: 'Supplemental Security Income'
}
