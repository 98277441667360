import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import Vs from '@app/services/validators/validators.service';
import { BaseModalDirective } from '@app/components/modals/base-modal';
import { value } from '@app/helpers/value.helpers';
import { formControl } from '@app/helpers/form-control.helpers';
import { isChangeReport, isRedeMode } from '@app/helpers/mode.helpers';
import { Modes } from '@app/models/app.model';

@Component({
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsModalComponent extends BaseModalDirective implements OnInit {
  commentsControl: FormControl;
  showAlert: boolean = false;
  appKeyWord: string;

  ngOnInit() {
    this.commentsControl = formControl(this.body, Vs.comments);
    const currentMode = sessionStorage.getItem('mode');
    const mode = currentMode ? Modes[currentMode] : 'NEW';
    this.appKeyWord = isChangeReport(mode) ? 'CHANGE' : isRedeMode(mode) ? 'RENEWAL' : 'APPLICATION';
  }

  onConfirm(): void {
    this.hide(value(this.commentsControl));
  }

  onCancel(): void {
    if(this.commentsControl.touched && !this.showAlert){
      this.showAlert = true;
      return;
    }
    this.hide('');
  }

  hideModal(): void {
    this.hide('');
  }
}
