<div class="background-page">
  <div class="browser-incompatable-header" *ngIf="showBrowserIncompatableMsg">
    <span class="browser-incompatable-text">{{'BROWSER_INCOMPATABLE_TEXT' | translate}}</span>,
    <a class="btn-link cursor-hand" (click)="showbrowserIncompatablePopup()">
        <span class="pr-2 popup">{{'CLICK_HERE' | translate }}</span>
    </a>
  </div>
  <div class="jumbotron tenncare-background">
    <div class="container">
      <div class="row pr-5 py-5" style="padding-left: 2.3rem;">
        <div class="page-info col-md-6">
          <h2 class="welcome-title">{{'WELCOME_TITLE' | translate}}</h2>
          <div class="before-border ml-1">
            <p class="small pl-2 welcome-sub-head">{{'WELCOME_DESC' | translate}}</p>
            <p class="small pl-2 welcome-body">{{'WELCOME_DESC2' | translate}}.</p>
          </div>
          <div class="row ml-1 pb-3 pt-2">
            <button [routerLink]="['/', 'create-account']" class="btn btn-secondary col-md-6">{{'WELCOME_BTN' | translate}}</button>
          </div>
        </div>
        <div class="ml-md-4 col-md-5">
          <img class="bike-image img-responsive" src="../../../assets/images/welcome_page/WelcomePage_5.jpg" alt="welcome image" width="110%" />
        </div>
      </div>
    </div>
  </div>
  <div class="container my-5 hor">
    <div class="row justify-content-center">
      <div class="col-md-4 px-box">
        <img alt="Apply coverage" class="apply-coverage-image img-responsive" src="../../../assets/images/Icons/apply_for_coverage.svg" />
        <h5 class="section-header mt-2 mb-3 px-box">{{'WELCOME_APPLY_COVERAGE_HEADER' | translate}}</h5>
        <hr class="px-box-none">
        <p class="small mt-3 px-box">{{'WELCOME_APPLY_COVERAGE' | translate}}</p>
      </div>
      <div class="col-md-4 px-box">
        <img alt="Manange coverage" class="apply-coverage-image img-responsive" src="../../../assets/images/Icons/manage_coverage.svg" />
        <h5 class="section-header mt-2 mb-3 px-box">{{'WELCOME_MANAGE_COVERAGE_HEADER' | translate}}</h5>
        <hr class="px-box-none">
        <p class="small mt-3">{{'WELCOME_MANAGE_COVERAGE' | translate}}</p>
      </div>
      <div class="col-md-4 px-box">
        <img alt="Upload Docs" class="apply-coverage-image img-responsive" src="../../../assets/images/Icons/upload_documents.svg" />
        <h5 class="section-header mt-2 mb-3 px-box">{{'WELCOME_MANAGE_UPLOAD_HEADER' | translate}}</h5>
        <hr class="px-box-none">
        <p class="small mt-3">{{'WELCOME_MANAGE_UPLOAD' | translate}}</p>
      </div>
    </div>
  </div>
  <div class="carousel-holder">
    <div class="container">
      <div id="myCarousel" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#myCarousel" [ngClass]="{'active': slide == 1}"></li>
          <li data-target="#myCarousel" [ngClass]="{'active': slide == 2}"></li>
          <li data-target="#myCarousel" [ngClass]="{'active': slide == 3}"></li>
          <li data-target="#myCarousel" [ngClass]="{'active': slide == 4}"></li>
          <li data-target="#myCarousel" [ngClass]="{'active': slide == 5}"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item" [ngClass]="{'active': slide == 1}">
            <img class="d-block slide-images" src="../../../assets/images/welcome_page/WelcomePage_1.jpg" alt="First slide">
          </div>
          <div class="carousel-item" [ngClass]="{'active': slide == 2}">
            <img class="d-block slide-images" src="../../../assets/images/welcome_page/WelcomePage_2.jpg" alt="Second slide">
          </div>
          <div class="carousel-item" [ngClass]="{'active': slide == 3}">
            <img class="d-block slide-images" src="../../../assets/images/welcome_page/WelcomePage_3.jpg" alt="Third slide">
          </div>
          <div class="carousel-item" [ngClass]="{'active': slide == 4}">
            <img class="d-block slide-images" src="../../../assets/images/welcome_page/WelcomePage_4.jpg" alt="Fourth slide">
          </div>
          <div class="carousel-item" [ngClass]="{'active': slide == 5}">
            <img class="d-block slide-images" src="../../../assets/images/welcome_page/WelcomePage_5.jpg" alt="Fifth slide">
          </div>
        </div>
        <a class="carousel-control-prev" (click)="prevSlide()" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" (click)="nextSlide()" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
  <div class="mt-5 card background-tenncare">
    <div class="card-body">
      <div class="container px-md-5 px-3 py-3">
        <div class="row justify-content-center align-items-center text-center text-md-left">
          <h2 class="bg-user col-lg-12 mb-4">{{'FOOTER-MOBILE-APP-TITLE' | translate }}</h2>
          <div class="col-lg-3 col-md-4">
            <div class="affirmation-cirlce">
              <div class="circle"></div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="pt-3 pt-md-0">
              <div class="before-border ml-1">
                <p class="small text-white pl-2">
                  {{'FOOTER-MOBILE-APP-SUB-HEAD1' | translate}}
                  <br><br>
                  {{'FOOTER-MOBILE-APP-SUB-HEAD2' | translate}}
                </p>
              </div>
              <div class="row ml-1 pb-3 pt-2">
                <div class="col-md-6">
                  <a class="small page-info btn-link" href="https://apps.apple.com/us/app/tenncare/id1435791637" target="_blank">
                    <img alt="apple store" class="img-responsive pl-1" src="../../../assets/images/Icons/icons-logos-apple-appstore.svg">
                    <span class="footer-app-store-text">{{'FOOTER-APP-STORE-TEXT' | translate}}</span></a>
                </div>
                <div class="col-md-6">
                  <a class="small page-info btn-link" href="https://play.google.com/store/apps/details?id=gov.tn.teds" target="_blank">
                    <img alt="google playstore" class="img-responsive pl-1" src="../../../assets/images/Icons/icons-logos-google-playstore.svg">
                    <span class="footer-app-store-text">{{'FOOTER-PLAY-STORE-TEXT' | translate}}</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row pr-5 py-5" style="padding-left: 2.3rem;">
      <div class="page-info col-md-6">
        <h2 class="footer-mobile-app-title">{{'FOOTER-DID-YOU-KNOW-TITLE' | translate}}</h2>
        <div class="before-border ml-1 mt-4 mb-2">
          <p class="small pl-2 footer-mobile-app-sub-head">
            {{'FOOTER-DID-YOU-KNOW-TEXT' | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
