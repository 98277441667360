<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <section [formGroup]="getControl('data')">
    
    <nh-alert type="warning" [isOpen]="commentBannerMessage">
      <div class="row">
        <div class="icon">
          <img src="../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="danger" />
        </div>
        <div class="message">
          <span [innerHTML]="'COMMENTS_BANNER_MESSAGE' | translate">
          </span>
        </div>
      </div>
    </nh-alert>
    
    <!--<h5 class="section-header">{{ 'SUMMARY' | translate }}</h5><hr>-->
    <p *ngIf="!isPreterm">{{ 'SUMMARY_TITLE1' | translate }}{{this.appKeyWord.toLowerCase()}}{{'SUMMARY_TITLE2' | translate}}{{this.appKeyWord.toLowerCase()}}.</p>
    <p *ngIf="isPreterm">{{ 'SUMMARY_TITLE1_QFR' | translate }}{{this.appKeyWord.toLowerCase()}}{{'SUMMARY_TITLE2_QFR' | translate}}{{this.appKeyWord.toLowerCase()}}.</p>
    <nh-field id = "com_comments" label [mandatory]="false">

      <nh-count>
        <textarea maxlength="2000" class="form-control" name="comments" formControlName="comments" aria-label="comments" rows="5" autofocus="true"></textarea>
      </nh-count>

    </nh-field>
  </section>
</nh-form>
