<div class="application-benefits">
    <div class="row mb-4">
        <div class="mx-3 greyish-panels">
            <div class="row align-items-center">
                <div class="col-8 align-self-center">
                    <span class="mr-2 benefits-plus-sign"><i class="fa fa-plus fa-lg"></i></span>
                    <span>Apply for Benefits</span>
                </div>
                <div class="col-4">
                    <button class="btn btn-block btn-sm btn-primary" [routerLink]="['/start/welcomeback']">Apply for Benefits</button>
                    <!-- <button class="btn btn-block btn-sm btn-primary" (click)="continueApp()">Continue Your Application</button> -->
                </div>
            </div>
        </div>
    </div> 
    <hr>
    <div class="mt-4 news">
        <h5 class="section-header">News & Updates</h5>
        <hr>
        <ul>
            <li class="p-3 news-item" *ngFor="let newsItem of news">
                <div [innerHtml]="newsItem"></div>
            </li>
        </ul>

    </div>
</div>