import { Component, Input, OnInit } from '@angular/core';
import { BaseModalDirective } from '@app/components/modals/base-modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { toDate, toISO } from '@app/helpers/date.helpers';
import { Select } from '@ngxs/store';
import { SectionState } from '@app/state/section/section.state';
import { Observable } from 'rxjs';
import { minus120Years } from '@app/helpers/date.helpers';
import { formatDate } from '@angular/common';
import Vs from '@app/services/validators/validators.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './record-delete.component.html',
})
export class RecordDeleteModalComponent extends BaseModalDirective implements OnInit {
  @Input() address: FormGroup;

  @Select(SectionState.tables) public tables$: Observable<any>;
  obj;
  form;
  title;
  labelExt;
  fullName;
  sectionId;
  headingExt;
  headingExt1;
  headingExt1En = ' stopped being your Assisting Person.';
  headingExt1Sp = ' dejó de ser su Persona que Ayuda.';
  labelExt1;
  labelExt1En = " stop being your Assisting Person?";
  labelExt1Sp = " dejar de ser su Persona de Asistencia?";
  labelExt2;
  labelExt3;
  labelExt4;
  labelExt5;
  labelExt6;
  labelExt7;
  labelExt8;
  labelExt9;
  labelExt10;
  labelExt11;
  labelExt12;
  labelExt13;
  labelExt14;
  labelExt15;
  labelExt16;
  labelExt17;
  labelExt18;
  headingExt2;
  headingExt2En = "'s health insurance coverage ended.";
  headingExt2Sp = ' dejó de ser su Persona que Ayuda.';
  toRemove;
  toRemove2;
  toRemove3;
  toRemove4;
  toRemove5;
  toRemove6;
  toRemove7;
  toRemove8;
  toRemove9;
  toRemove10;
  toRemove11;
  toRemove12;
  toRemove13;
  toRemove14;
  toRemove15;
  toRemove16;
  toRemove17;
  toRemove20;
  toRemove18 = "To remove this record, tell us when ";
  toRemove19 = "Para eliminar este registro, díganos cuándo ";
  formCtrlName;
  today = new Date();
  minimumDate = minus120Years();
  agreeTermsForm;
  assistingPersonName;
  translatedLang;
  defaultLanguage;
  removeSpanish;

  constructor(bsModalService: BsModalService, protected modalRef: BsModalRef, protected builder: FormBuilder, public translateService: TranslateService) {
    super(modalRef, builder);
    this.obj = bsModalService.config.initialState;
    this.form = this.builder.group({
      assistingEndDate: toDate(this.obj.coverageEndDate) || null,
      coverageEndDate: toDate(this.obj.coverageEndDate) || null,
      taxFilingEndDate: toDate(this.obj.taxFilingEndDate) || null,
      employmentEndDate: toDate(this.obj.employmentEndDate) || null,
      lastPaycheckDate: toDate(this.obj.lastPaycheckDate) || null,
      lastPaycheckAmt: [this.obj.lastPaycheckAmt, Vs.currency] || null,
      selfempEndDate: toDate(this.obj.selfempEndDate) || null,
      otherIncomeEndDate: toDate(this.obj.otherIncomeEndDate) || null,
      kbRemovalDt: toDate(this.obj.kbRemovalDt) || null,
      shelterEndDate: toDate(this.obj.shelterEndDate) || null,
      careEndDate: toDate(this.obj.careEndDate) || null,
      mandatoryEndDate: toDate(this.obj.mandatoryEndDate) || null,
      medicalPaymentDate: toDate(this.obj.medicalPaymentDate) || null,
      otherEndDate: toDate(this.obj.otherEndDate) || null,
      cashEndDate: toDate(this.obj.cashEndDate) || null,
      vehicleEndDate: toDate(this.obj.vehicleEndDate) || null,
      propertyEndDate: toDate(this.obj.propertyEndDate) || null,
      burrialEndDate: toDate(this.obj.burrialEndDate) || null,
      lifeIncEndDate: toDate(this.obj.lifeIncEndDate) || null,
      othResourcesEndDate: toDate(this.obj.othResourcesEndDate) || null
    });
    this.agreeTermsForm = this.builder.group({
      transferEndDate: toDate(this.obj.transferEndDate) || null,
      agreeTerms: [ this.obj.agreeTerms || null,Vs.required]
    })
  };

  getEngText () {
    this.headingExt1 = this.headingExt1En;
    this.labelExt1 = this.labelExt1En;
    this.toRemove = this.toRemove18 + this.fullName +"'s health insurance coverage ended";
    this.toRemove2 = this.toRemove18 + this.fullName +"'s employment ended.";
    this.toRemove3 = this.toRemove18 + this.fullName + "'s self-employment ended.";
    this.toRemove4 = this.toRemove18 + this.fullName + "'s other income ended.";
    this.toRemove5 = this.toRemove18 + this.fullName + " KT ended.";
    this.toRemove6 = this.toRemove18 + this.fullName + "'s shelter or utility expense ended.";
    this.toRemove7 = this.toRemove18 + this.fullName + "'s dependent care expense ended.";
    this.toRemove8 = this.toRemove18 + this.fullName + "'s mandatory payment expense ended.";
    this.toRemove9 = this.toRemove18 + this.fullName + "'s medical bill expense ended.";
    this.toRemove10 = this.toRemove18 + this.fullName + "'s other health coverage ended.";
    this.toRemove11 = this.toRemove18 + this.fullName + " stopped owning this financial resource.";
    this.toRemove12 = this.toRemove18 + this.fullName + " stopped owning this vehicle.";
    this.toRemove13 = this.toRemove18 + this.fullName + " stopped owning this property.";
    this.toRemove14 = this.toRemove18 + this.fullName + " stopped owning this burial resource.";
    this.toRemove15 = this.toRemove18 + this.fullName + " stopped owning this life insurance.";
    this.toRemove16 = "To remove this record, please confirm the information below.";
    this.toRemove17 = this.toRemove18 + this.fullName + " stopped owning this other resource.";
    this.toRemove20 = this.toRemove18 + this.fullName + " tax filing ended.";
    this.labelExt2 = "When did " + this.fullName + "'s health insurance coverage end?";
    this.labelExt3 = "This self-employment ended:";
    this.labelExt4 = "This other income ended:";
    this.labelExt5 = "When did " + this.fullName + "'s KT coverage end?";
    this.labelExt6 = "When did " + this.fullName + " make their final payment for this shelter or utility expense?";
    this.labelExt7 = "When did " + this.fullName + " make their final payment for this dependent care expense?";
    this.labelExt8 = "When did " + this.fullName + " make their final payment for this mandatory payment expense?";
    this.labelExt9 = "When did " + this.fullName + " make their final payment for this medical bill expense?";
    this.labelExt10 = "When did " + this.fullName + "'s health coverage end?";
    this.labelExt11 = "When was the last date that " + this.fullName + " owned this financial resource?";
    this.labelExt12 = "When was the last date that " + this.fullName + " owned this vehicle?";
    this.labelExt13 = "When was the last date that " + this.fullName + " owned this property?";
    this.labelExt14 = "When was the last date that " + this.fullName + " owned this burial resource?";
    this.labelExt15 = "When was the last date that " + this.fullName + " owned this life insurance?";
    this.labelExt16 = "When did this information change?";
    this.labelExt17 = "When was the last date that " + this.fullName + " owned this other resource?";
    this.labelExt18 =  this.fullName + "'s tax filing end date?";
  }

  getSpanishText () {
    const  sectionIdArray1 = ['ICEMI', 'ICSEM', 'XPSUP', 'ASLIF'];
    const  sectionIdArray2 = ['ICOTH', 'XPUTL', 'XPDEP'];
    const  sectionIdArray3 = ['ASLIQ', 'ASVEH', 'ASPRP', 'ASBUR', 'OTHRS', 'ASCLD'];
    if (sectionIdArray1.includes(this.sectionId)) {
      this.removeSpanish = "Eliminar el"
    } else if (sectionIdArray2.includes(this.sectionId)) {
      this.removeSpanish = "Eliminar los"
    } else if (sectionIdArray3.includes(this.sectionId)) {
      this.removeSpanish = "Eliminar"
    } else {
      this.removeSpanish = 'Eliminar la'
    }
    this.headingExt1 = this.headingExt1Sp;
    this.labelExt1 = this.labelExt1Sp;
    this.toRemove = this.toRemove19 + "terminó la cobertura de seguro médico de" + " " + this.fullName + ".";
    this.toRemove2 = this.toRemove19 + "terminó el empleo de " + this.fullName + ".";
    this.toRemove3 = this.toRemove19 + "terminó el empleo por cuenta propia de "  + this.fullName + ".";
    this.toRemove4 = this.toRemove19 + "terminaron los otros ingresos de " + this.fullName + ".";
    this.toRemove5 = this.toRemove19 + "terminó " + this.fullName + " KT.";
    this.toRemove6 = this.toRemove19 + "terminaron los gastos de vivienda o servicios públicos de " + this.fullName + ".";
    this.toRemove7 = this.toRemove19 + "terminaron los gastos de cuidado de dependientes de " + this.fullName + ".";
    this.toRemove8 = this.toRemove19 + "terminó el pago de gasto obligatorio de " + this.fullName + ".";
    this.toRemove9 = this.toRemove19 + "terminó el gasto de factura médica de " + this.fullName + ".";
    this.toRemove10 = this.toRemove19 + "terminó la otra cobertura de seguro médico de " + this.fullName + ".";
    this.toRemove11 = this.toRemove19  + this.fullName + " dejó de poseer este recurso económico.";
    this.toRemove12 = this.toRemove19  + this.fullName + " dejó de ser dueño de este vehículo.";
    this.toRemove13 = this.toRemove19  + this.fullName + " dejó de ser dueño de esta propiedad.";
    this.toRemove14 = this.toRemove19  + this.fullName + " dejó de ser dueño de este recurso de entierro.";
    this.toRemove15 = this.toRemove19  + this.fullName + " dejó de tener este seguro de vida."
    this.toRemove16 = "Para eliminar este registro, confirme la información de abajo.";
    this.toRemove17 = this.toRemove19  + this.fullName + " dejó de ser dueño de este otro recurso.";
    this.toRemove20 = this.toRemove19 + "finalizaron las deducciones fiscales de" + this.fullName + ".";
    this.labelExt2 = "¿Cuándo terminó la cobertura de seguro médico de " + this.fullName + "?";
    this.labelExt3 = "Este trabajo por cuenta propia terminó:";
    this.labelExt4 = "Estos otros ingresos terminaron:";
    this.labelExt5 = "¿Cuándo terminó la cobertura de KT de " + this.fullName + "?";
    this.labelExt6 = "¿Cuándo hizo " +  this.fullName + " su pago final por este gasto de vivienda o servicios públicos?";
    this.labelExt7 = "¿Cuándo hizo " +  this.fullName + " su pago final por este gasto de cuidado de dependientes?";
    this.labelExt8 = "¿Cuando hizo " +  this.fullName + " el pago final de este gasto de pago obligatorio?";
    this.labelExt9 = "¿Cuándo hizo " +  this.fullName + " el pago final de este gasto de factura médica?";
    this.labelExt10 = "¿Cuándo terminó la cobertura de seguro médico de " + +  this.fullName + "?";
    this.labelExt11 = "¿Cuándo fue la última fecha en que " +  this.fullName + " era dueño de este recurso económico?";
    this.labelExt12 = "¿Cuándo fue la última fecha en que " +  this.fullName + " era dueño de este vehículo?";
    this.labelExt13 = "¿Cuándo fue la última fecha en que " +  this.fullName + " era dueño de esta propiedad?";
    this.labelExt14 = "¿Cuándo fue la última fecha en que " +  this.fullName + " era dueño de este recurso de entierro?";
    this.labelExt15 = "¿Cuándo fue la última fecha en que " +  this.fullName + " era dueño de este seguro de vida?";
    this.labelExt16 = "¿Cuándo cambió esta información?"
    this.labelExt17 = "¿Cuándo fue la última fecha en que " +  this.fullName + " era dueño de este otro recurso?";
    this.labelExt18 = this.toRemove19  + this.fullName + "dejará de recibir deducciones de impuestos.";
  }



  ngOnInit() {
    const { indvName, assistingPersonName, sectionId } = this.obj.commonItems;
    this.fullName = indvName;
    this.assistingPersonName = assistingPersonName;
    this.sectionId = sectionId;
    this.defaultLanguage = this.translateService.getDefaultLang();
    if (this.defaultLanguage === 'en') {
      this.getEngText ();
    } else {
      this.getSpanishText();
    }
    this.translatedLang = this.defaultLanguage;
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      if (this.translatedLang === 'en') {
        this.getEngText();
      } else {
        this.getSpanishText();
      }
    });

    switch (this.sectionId) {
      case 'HHAPL':
        this.title = 'Assisting Person'
        this.headingExt = this.headingExt1;
        this.labelExt = this.labelExt1;
        this.formCtrlName = 'assistingEndDate'
        break;
      case 'HHHLC':
        this.title = 'Other Health Coverage'
        this.headingExt = this.toRemove;
        this.labelExt = this.labelExt2;
        this.formCtrlName = 'coverageEndDate'
        break;
      case 'HHTJF':
        this.title = 'Tax Filer Information'
        this.headingExt = this.toRemove20;
        this.labelExt = this.labelExt18;
        this.formCtrlName = 'taxFilingEndDate'
        break;
      case 'ICEMI':
        this.title = 'Employment'
        this.headingExt = this.toRemove2;
        break;
      case 'ICSEM':
        this.title = 'Self-Employment'
        this.headingExt = this.toRemove3;
        this.labelExt = this.labelExt3;
        this.formCtrlName = 'selfempEndDate'
        break;
      case 'ICOTH':
        this.title = 'Other Income'
        this.headingExt = this.toRemove4;
        this.labelExt = this.labelExt4;
        this.formCtrlName = 'otherIncomeEndDate'
        break;
      case 'OTKBR':
        this.title = 'Katie Beckett Referral'
        this.headingExt = this.toRemove5;
        this.labelExt = this.labelExt5;
        this.formCtrlName = 'kbRemovalDt'
        break;
      case 'XPUTL':
        this.title = 'Shelter or Utility Expense'
        this.headingExt = this.toRemove6;
        this.labelExt = this.labelExt6;
        this.formCtrlName = 'shelterEndDate'
        break;
      case 'XPDEP':
        this.title = 'Dependent Care Expense'
        this.headingExt = this.toRemove7;
        this.labelExt = this.labelExt7;
        this.formCtrlName = 'careEndDate'
        break;
      case 'XPSUP':
        this.title = 'Mandatory Payment'
        this.headingExt = this.toRemove8;
        this.labelExt = this.labelExt8;
        this.formCtrlName = 'mandatoryEndDate'
        break;
      case 'INMED':
        this.title = 'Medical Bill'
        this.headingExt = this.toRemove9;
        this.labelExt = this.labelExt9;
        this.formCtrlName = 'medicalPaymentDate'
        break;
      case 'XPOTH':
        this.title = 'Access to Other Coverage Information'
        this.headingExt = this.toRemove10;
        this.labelExt = this.labelExt10;
        this.formCtrlName = 'otherEndDate'
        break;
      case 'ASLIQ':
        this.title = 'Financial Resource'
        this.headingExt = this.toRemove11;
        this.labelExt = this.labelExt11;
        this.formCtrlName = 'cashEndDate'
        break;
      case 'ASVEH':
        this.title = 'Vehicle'
        this.headingExt = this.toRemove12;
        this.labelExt = this.labelExt12;
        this.formCtrlName = 'vehicleEndDate'
        break;
      case 'ASPRP':
        this.title = 'Property'
        this.headingExt = this.toRemove13;
        this.labelExt = this.labelExt13;
        this.formCtrlName = 'propertyEndDate'
        break;
      case 'ASBUR':
        this.title = 'Burial Resource'
        this.headingExt = this.toRemove14;
        this.labelExt = this.labelExt14;
        this.formCtrlName = 'burrialEndDate'
        break;
      case 'ASLIF':
        this.title = 'Life Insurance'
        this.headingExt = this.toRemove15;
        this.labelExt = this.labelExt15;
        this.formCtrlName = 'lifeIncEndDate'
        break;
      case 'ASCLD':
        this.title = 'Transferred Resources'
        this.headingExt = this.toRemove16;
        this.labelExt = this.labelExt16;
        this.formCtrlName = 'transferEndDate'
        break;
      case 'OTHRS':
        this.title = 'Other Resource'
        this.headingExt = this.toRemove17;
        this.labelExt = this.labelExt17;
        this.formCtrlName = 'othResourcesEndDate'
        break;
    }
  };

  get isDisabled() {
    if (this.sectionId != 'ASCLD' && this.form.valid) {
      return null;
    } else if (this.agreeTermsForm.valid) {
      return null;
    } else {
      return true;
    }
  }

  isInvalidDate(event) {
    const enteredDate = event.target.value;
    var formControlValue;
    switch (this.sectionId) {
      case 'HHAPL':
        formControlValue = this.form.value.assistingEndDate;
        break;
      case 'HHHLC':
        formControlValue = this.form.value.coverageEndDate;
        break;
      case 'HHTJF':
        formControlValue = this.form.value.taxFilingEndDate;
        break;
      case 'ICEMI':
        if (event.target.value === 'employmentEndDate') {
          formControlValue = this.form.value.employmentEndDate;
        } else if (event.target.value === 'lastPaycheckDate') {
          formControlValue = this.form.value.lastPaycheckDate;
        }
        break;
      case 'ICSEM':
        formControlValue = this.form.value.selfempEndDate;
        break;
      case 'ICOTH':
        formControlValue = this.form.value.otherIncomeEndDate;
        break;
      case 'OTKBR':
        formControlValue = this.form.value.kbRemovalDt;
        break;
      case 'XPUTL':
        formControlValue = this.form.value.shelterEndDate;
        break;
      case 'XPDEP':
        formControlValue = this.form.value.careEndDate;
        break;
      case 'XPSUP':
        formControlValue = this.form.value.mandatoryEndDate;
        break;
      case 'INMED':
        formControlValue = this.form.value.medicalPaymentDate;
        break;
      case 'XPOTH':
        formControlValue = this.form.value.otherEndDate;
        break;
      case 'ASLIQ':
        formControlValue = this.form.value.cashEndDate;
        break;
      case 'ASVEH':
        formControlValue = this.form.value.vehicleEndDate;
        break;
      case 'ASPRP':
        formControlValue = this.form.value.propertyEndDate;
        break;
      case 'ASBUR':
        formControlValue = this.form.value.burrialEndDate;
        break;
      case 'ASLIF':
        formControlValue = this.form.value.lifeIncEndDate;
        break;
      case 'ASCLD':
        formControlValue = this.agreeTermsForm.value.transferEndDate;
        break;
      case 'OTHRS':
        formControlValue = this.form.value.othResourcesEndDate;
        break;
    }
    if (!enteredDate || enteredDate === '' || enteredDate === 'Invalid date') {
      event.target.value = enteredDate;
    } else if (event.target.name === this.formCtrlName) {
      event.target.value = formatDate (formControlValue , 'MM/dd/yyyy' , 'en');
    }
  }

  onConfirm(): void {
    this.form.markAsTouched();
    const toIso = v => toISO(v);
    if (this.form.valid) {
      this.obj.commonItems = null;
      this.obj.assistingEndDate = toIso(this.form.value.assistingEndDate);
      this.obj.coverageEndDate = toIso(this.form.value.coverageEndDate);
      this.obj.taxFilingEndDate = toIso(this.form.value.taxFilingEndDate);
      this.obj.employmentEndDate = toIso(this.form.value.employmentEndDate);
      this.obj.lastPaycheckDate = toIso(this.form.value.lastPaycheckDate);
      this.obj.lastPaycheckAmt = this.form.value.lastPaycheckAmt;
      this.obj.selfempEndDate = toIso(this.form.value.selfempEndDate);
      this.obj.otherIncomeEndDate = toIso(this.form.value.otherIncomeEndDate);
      this.obj.kbRemovalDt = toIso(this.form.value.kbRemovalDt);
      this.obj.shelterEndDate = toIso(this.form.value.shelterEndDate);
      this.obj.careEndDate = toIso(this.form.value.careEndDate);
      this.obj.mandatoryEndDate = toIso(this.form.value.mandatoryEndDate);
      this.obj.medicalPaymentDate = toIso(this.form.value.medicalPaymentDate);
      this.obj.otherEndDate = toIso(this.form.value.otherEndDate);
      this.obj.cashEndDate = toIso(this.form.value.cashEndDate);
      this.obj.vehicleEndDate = toIso(this.form.value.vehicleEndDate);
      this.obj.propertyEndDate = toIso(this.form.value.propertyEndDate);
      this.obj.burrialEndDate = toIso(this.form.value.burrialEndDate);
      this.obj.lifeIncEndDate = toIso(this.form.value.lifeIncEndDate);
      this.obj.othResourcesEndDate = toIso(this.form.value.othResourcesEndDate);
      this.obj.transferEndDate = toIso(this.agreeTermsForm.value.transferEndDate);
      this.obj.agreeTerms = this.agreeTermsForm.value.agreeTerms;
      super.onConfirm();
    }
  }
}
