import { Component, OnInit } from '@angular/core';
import { formGroup } from '@app/helpers/form-group.helpers';
import { formControl } from '@app/helpers/form-control.helpers';
import { ChangePasswordService } from './change-password.service';
import { isEmpty } from 'ramda';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'nh-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  showErr2: boolean = false;
  passwordRuleLen: boolean;
  passwordRuleUpp: boolean;
  passwordRuleLow: boolean;
  passwordRuleNum: boolean;
  passwordRuleSpl: boolean;
  isPasswordMatched: boolean = true;
  validPassword: boolean = false;
  fieldTextType: boolean;
  fieldConfirmPassTextType: boolean;
  fieldCurrentTextType: boolean;
  splValue = '(!$#,&@~^*_-+=><?%:;|)';
  alertMessage: any;
  currentLang;

  changePassword = new formGroup({
    password: new formControl(''),
    confirmPassword: new formControl(''),
    currentPassword: new formControl('')
  })

  get changeForm() {
    return this.changePassword.controls;
  }

  constructor(private changePasswordService: ChangePasswordService,   
              private titleService: Title
    ) {
    this.changePasswordService.changePassword = this.changePassword;
    this.titleService.setTitle('Change Password');
  }

  ngOnInit(): void {
    this.changePasswordService.setLanguage();
  }

  toggleFieldTextType(fieldType) {
    if (fieldType == 'password') {
      this.fieldTextType = !this.fieldTextType;
    } else if (fieldType == 'currentPassword') {
      this.fieldCurrentTextType = !this.fieldCurrentTextType;
    } else {
      this.fieldConfirmPassTextType = !this.fieldConfirmPassTextType;
    }
  }

  comparePassword(event) {
    this.showErr2 = false;
    const passwordRuleLow = (event.target.value.match(/[a-z]/g));
    const passwordRuleUpp = (event.target.value.match(/[A-Z]/g));
    const passwordRuleNum = (event.target.value.match(/[0-9]/g));
    const passwordRuleLen = (event.target.value.length >= 8 && event.target.value.length <= 32);
    let passwordRuleSpl = false;
    for (let i = 0; i < event.target.value.length; i++) {
      if (this.splValue.indexOf(event.target.value.charAt(i)) > -1) {
        passwordRuleSpl = true;
      }
    }
    this.showErr2 = passwordRuleLen && passwordRuleLow && passwordRuleUpp && passwordRuleNum && passwordRuleSpl ? false: true;
    this.validPassword = (this.passwordRuleLow && this.passwordRuleUpp && this.passwordRuleNum
      && this.passwordRuleSpl && this.passwordRuleLen &&
      (event.target.value == this.changePassword.controls['password'].value));
    this.isPasswordMatched = (event.target.value.length > 0 && (event.target.value == this.changePassword.controls['password'].value));
    this.changePasswordService.validPassword = this.validPassword;
    if (this.isPasswordMatched) {
      this.changeForm.confirmPassword.setErrors(null);
    } else if (isEmpty(this.changeForm.confirmPassword.value)) {
      this.changeForm.confirmPassword.setErrors({ PASSWORD_CONFIRM: true });
    } else {
      this.changeForm.confirmPassword.setErrors({ PASSWORDS_DIFFERENT: true });
    }
  }

  getErrorMessage() {
    this.changeForm.confirmPassword.value === '' ? this.changeForm.confirmPassword.setErrors({ PASSWORD_CONFIRM: true }) : this.changeForm.confirmPassword.value === this.changeForm.password.value ?
      this.changeForm.confirmPassword.setErrors(null) :
      this.changeForm.confirmPassword.setErrors({ PASSWORDS_DIFFERENT: true });
  }

  validatePassword(event): void {
    this.passwordRuleLow = (event.target.value.match(/[a-z]/g));
    this.passwordRuleUpp = (event.target.value.match(/[A-Z]/g));
    this.passwordRuleNum = (event.target.value.match(/[0-9]/g));
    this.passwordRuleLen = (event.target.value.length >= 8 && event.target.value.length <= 32);
    this.passwordRuleSpl = false;
    for (let i = 0; i < event.target.value.length; i++) {
      if (this.splValue.indexOf(event.target.value.charAt(i)) > -1) {
        this.passwordRuleSpl = true;
        break;
      }
    }

    if (this.passwordRuleLow && this.passwordRuleUpp && this.passwordRuleNum
      && this.passwordRuleSpl && this.passwordRuleLen) {
      this.changeForm.confirmPassword.setErrors({ PASSWORD_CONFIRM: true });
      this.showErr2 = false;
    } else {
      this.showErr2 = true;
      this.changeForm.confirmPassword.setErrors(null);
    }
  }

  switchLanguage(lang) {
    if (lang === 'sp') {
      this.currentLang = 'ES'
    } else {
      this.currentLang = 'EN'
    }
  };

  isAlertMsg () {
    this.alertMessage = this.changePasswordService.alertMessage;
    if (!this.changePasswordService.isFormValid() && this.changePasswordService.isAlertMessage) {
      return false;
    }
    return this.changePasswordService.isAlertMessage;
  }
}
