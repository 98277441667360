<div class="container">
  <div class="row">
    <div class="col-12">
      <h1 class="page-title">{{'REPORT_CHANGE' | translate }}</h1>
    </div>
  </div>

  <div class="row">
    <div class="card col-md-12">
      <div class="card-body">
        <form>
          <div class="row">
            <table class="table">
              <thead>
                <tr>
                  <th>{{'Case' | translate }}</th>
                  <th>{{'Action' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of this.caseSummary">
                  <td>{{ item.caseNum }}</td>
                  <td>
                    <button *ngIf="(item.openRenewalSW === 'N' || item.openRenewalSW === null) && !item.isReportSsiEligble && !item.isDcsEligible && item.hoHID === this.indivId" class="btn btn-block btn-rcsecondary" (click)="onReportChange(item.caseNum, 'RC')">{{'REPORT_CHANGE' | translate }}</button>
                    <p *ngIf="item.openRenewalSW === 'Y'">{{'REPORT_CHANGE_STATIC1' | translate }}</p>
                    <p *ngIf="(item.openRenewalSW === 'N' || item.openRenewalSW === null) && item.isReportSsiEligble">{{'REPORT_SSI_ELIGBLE' | translate }}</p>
                    <p *ngIf="(item.openRenewalSW === 'N' || item.openRenewalSW === null) && item.isDcsEligible">{{'REPORT_DCS_ELIGIBLE' | translate }}<br><br> {{'REPORT_DCS_ELIGIBLE_1' | translate }}</p>
                    <p *ngIf="item.hoHID !== this.indivId">{{'REPORT_CHANGE_STATIC2' | translate }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
