import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { equals, any, prop } from 'ramda';

import { assignAs } from '@app/helpers/function.helpers';
import { viewApplicationNumber } from '@app/helpers/lenses.helpers';
import { stepperLinks, sectionsIds } from '@app/state/app/app.selectors';

import { Stepper, Statuses, ApplicationNumber } from '@app/models/app.model';


@Component({
  selector: 'nh-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent {
  @Select(stepperLinks) stepper$: Observable<Stepper>;
  @Select(viewApplicationNumber) applicationNumber$: Observable<ApplicationNumber>;
  applicationNumber: ApplicationNumber;
  appType = sessionStorage.getItem('appType');
  isFinReassessment = false;
  isKepro = false;
  isBCCEXT = sessionStorage.getItem('appType') === 'BXT';
  isPreterm = false

  constructor(private router: Router) {
    this.applicationNumber$.subscribe(assignAs(this, 'applicationNumber'));
    this.isKepro = JSON.parse(sessionStorage.getItem('isKepro'));
  }

  getClass(modules:any, index: any) {
    let prevStep, nextStep;
    const currentStep = modules[index];
    this.isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));   
    this.isPreterm = sessionStorage.getItem('isPreterm') === "PRETERM" && (sessionStorage.getItem('appType') === 'RB' || (sessionStorage.getItem('appType') === 'REDETERMINATION'  && sessionStorage.getItem('isKepro') === "true"));
    if (currentStep.title === "Finish") {
      if (this.isBCCEXT && currentStep.moduleId === 'SUSIG'){
        currentStep.title = "Sign & Submit"
      } else {
      for (let i = 0; currentStep.sections.length > i; i++) {
        if (currentStep.sections[i].sectionId === 'SUPSM' && currentStep.sections[i].title === 'Application Summary') {
          if (this.appType === 'RC') {
            currentStep.sections[i].title = 'Change Summary'
          } else if (this.appType === 'RB' && !this.isFinReassessment && !this.isPreterm) {
            currentStep.sections[i].title = 'Renewal Summary';
          } else if (this.appType === 'RB' && this.isFinReassessment) {
            currentStep.sections[i].title = 'Reassessment Summary';
          } else if (this.appType === 'RB' && this.isPreterm) {
            currentStep.sections[i].title = 'Questions for Review Summary';
          }
        } else if (currentStep.sections[i].sectionId === 'SUSIG' && currentStep.sections[i].title === 'Sign Your Application') {
          if (this.appType === 'RC' || (this.appType === 'CHANGE_REPORT' && this.isKepro)) {
            currentStep.sections[i].title = 'Sign Your Change'
          }
          else if ((this.appType === 'RB'|| (this.appType === 'RENEW_BENEFITS' && this.isKepro)) && !this.isPreterm) {
            currentStep.sections[i].title = 'Sign Your Renewal';
          } 
          else if ((this.appType === 'RB'|| (this.appType === 'RENEW_BENEFITS' && this.isKepro)) && this.isPreterm) {
            currentStep.sections[i].title = 'Sign Your Questions for Review';
          }         
        }
      }
    }
    }
    if (index > 0) {
      prevStep = modules[index - 1];
      nextStep = index < modules.length ? modules[index + 1] : null;
      if((currentStep.status !== "COMPLETE") && (prevStep.status === "IN_PROGRESS" && (nextStep && nextStep.status === "NOT_STARTED"))){
        return 'NOT_STARTED_BEFORE_BLUE_BAR';
      } else if(prevStep.status === "IN_PROGRESS" && (nextStep  && nextStep.status === "COMPLETE")){
        return 'NOT_STARTED_AFTER_GREEN_BAR';
      } else if(currentStep.status === "NOT_STARTED" && prevStep.status === "COMPLETE"){
        return 'STARTED_BEFORE_GREEN_BAR';
      } else if(modules.indexOf(currentStep) + 1 === modules.length && prevStep.status === "IN_PROGRESS"){
        return 'LAST_BAR_BEFORE_BLUE';
      } else if((currentStep.status === "COMPLETE") && (prevStep.status === "IN_PROGRESS" && (nextStep && nextStep.status === "NOT_STARTED"))){
        return 'NOT_STARTED_AFTER_GREEN_BAR';
      }
    }
    return currentStep.status;
  }

  isCompleted(status) {
    return equals(status, Statuses.COMPLETE);
  }

  isInProgress(status) {
    return equals(status, Statuses.IN_PROGRESS);
  }

  isNotStarted(status) {
    return equals(status, Statuses.NOT_STARTED);
  }

  setIcon(status) {
    return prop(status, { COMPLETE: 'fa-check', NOT_STARTED: 'fa-ellipsis-h', IN_PROGRESS: 'fa-exclamation' });
  }

  navigate(sectionId) {
    return this.router.navigateByUrl('/application/' + this.applicationNumber + '/section/' + sectionId);
  }

  onModuleNavigate(mod) {
    const isActiveModule = this.isActiveModule(mod);
    const hasStarted = this.isNotStarted(mod.status);
    if (!isActiveModule && !hasStarted) {
      this.navigate(mod.moduleId);
    }
  }

  onSectionNavigate(section) {
    return (
      !this.isActiveSection(section.sectionId) && //
      !this.isNotStarted(section.status) &&
      this.navigate(section.sectionId)
    );
  }

  isActiveSection(sectionId) {
    return this.router.isActive(`/application/${this.applicationNumber}/section/${sectionId}`, false);
  }

  isActiveModule(mod) {
    return any(sectionId => this.isActiveSection(sectionId), sectionsIds(mod));
  }
}
