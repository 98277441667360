<nh-form [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
<section class="summary-left">
    <div>
        <h5 class="section-header">{{APPEAL_SUMMARY_STATIC1}}</h5>
        <hr>
    </div>
    <p [innerHTML]="APPEAL_SUMMARY_STATIC2"></p>
    <div id="accordion">
        <!-- Appeal Request Information Table -->
        <div id="heading_1">
            <div class="row">
                <div class="col-12 d-flex align-items-center justify-content">
                    <div  id="caratId_1" class="carat d-none d-lg-block"><span id="dwn_1" class="cla2"><i
                                class="fa fa-2x fa-angle-down"></i></span><span id="up_1" class="cla1"><i
                                class="fa fa-2x fa-angle-up"></i></span></div>
                    <h5 id="headerButton_1" class="section-header pl-3">{{APPEAL_SUMMARY_STATIC3}}</h5>
                    <div id="caratId_1" class="carat d-lg-none"><span id="dwn_1" class="cla2"><i
                        class="fa fa-2x fa-angle-down"></i></span><span id="up_1" class="cla1"><i
                        class="fa fa-2x fa-angle-up"></i></span></div>
                </div>
            </div>
            <hr>
        </div>
        <div id="collapse_1" class="collapse" aria-labelledby="heading_1" data-parent="#accordion">
            <div class="card-body d-none d-lg-block">
                <p> {{APPEAL_SUMMARY_STATIC4}}</p>
                <table class="table table-bordered" *ngIf="appealRequestInformationArray?.length">
                    <tbody class="thead-light text-left">
                        <tr *ngFor="let item of appealRequestInformationArray; index as i">
                            <th scope="row">{{item.key | translate}}</th>
                            <td>{{item.value | translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-body nh-table-mobile d-lg-none">
                <p> {{APPEAL_SUMMARY_STATIC4}}</p>
                <div class="table-row mb-3 pb-3" *ngFor="let item of appealRequestInformationArray; index as i">
                    <div class="mobile-table-header">{{item.key | translate}}</div>
                    <hr>
                    <div class="mobile-table-data">{{item.value | translate}}
                    </div>
                </div>
            </div>
        </div>
        <!-- Appeal Demographics Table -->
        <div id="heading_2">
            <div class="row">
                <div class="col-12 d-flex align-items-center justify-content">
                    <div id="caratId_2" class="carat d-none d-lg-block"><span id="dwn_2" class="cla2"><i
                                class="fa fa-2x fa-angle-down"></i></span><span id="up_2" class="cla1"><i
                                class="fa fa-2x fa-angle-up"></i></span></div>
                    <h5 id="headerButton_2" class="section-header pl-3">{{APPEAL_SUMMARY_STATIC5}}</h5>
                    <div id="caratId_2" class="carat d-lg-none"><span id="dwn_2" class="cla2"><i
                        class="fa fa-2x fa-angle-down"></i></span><span id="up_2" class="cla1"><i
                        class="fa fa-2x fa-angle-up"></i></span></div>
                </div>
            </div>
            <hr>
        </div>
        <div id="collapse_2" class="collapse" aria-labelledby="heading_2" data-parent="#accordion">
            <div class="card-body d-none d-lg-block">
                <p> {{APPEAL_SUMMARY_STATIC6}}</p>
                <table class="table table-bordered" *ngIf="appealDemographicsArray?.length">
                    <tbody class="thead-light text-left">
                        <tr *ngFor="let item of appealDemographicsArray; index as i">
                            <th scope="row">{{item.key | translate}}</th>
                            <td>{{item.value | translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="nh-table-mobile d-lg-none">
                <p> {{APPEAL_SUMMARY_STATIC6}}</p>
                <div class="table-row mb-3 pb-3" *ngFor="let item of appealDemographicsArray; index as i">
                    <div class="mobile-table-header">{{item.key | translate}}</div>
                    <hr>
                    <div class="mobile-table-data">{{item.value | translate}}
                    </div>
                </div>
            </div>
        </div>
        <!-- Appeal Details Table -->
        <div id="heading_3">
            <div class="row">
                <div class="col-12 d-flex align-items-center justify-content">
                    <div id="caratId_3" class="carat d-none d-lg-block"><span id="dwn_3" class="cla2"><i
                                class="fa fa-2x fa-angle-down"></i></span><span id="up_3" class="cla1"><i
                                class="fa fa-2x fa-angle-up"></i></span></div>
                    <h5 id="headerButton_3" class="section-header pl-3">{{APPEAL_SUMMARY_STATIC7}}</h5>
                        <div id="caratId_3" class="carat  d-lg-none"><span id="dwn_3" class="cla2"><i
                            class="fa fa-2x fa-angle-down"></i></span><span id="up_3" class="cla1"><i
                            class="fa fa-2x fa-angle-up"></i></span></div>
                </div>
            </div>
            <hr>
        </div>
        <div id="collapse_3" class="collapse" aria-labelledby="heading_3" data-parent="#accordion">
            <div class="card-body d-none d-lg-block">
                <p> {{APPEAL_SUMMARY_STATIC8}}</p>
                <table class="table table-bordered" *ngIf="appealDetailsArray?.length">
                    <tbody class="thead-light text-left">
                        <tr *ngFor="let item of appealDetailsArray; index as i">
                            <th *ngIf="i !== this.editKey && !item.coverageKey" scope="col">{{item.key | translate}}</th>
                            <th *ngIf="i === this.editKey && !item.coverageKey" scope="col">{{ array[0] | translate}}{{array2[0]}}{{array2[1] | translate}}</th>
                            <th *ngIf="item.coverageKey" scope="col">{{ item.coverageKey | translate}}{{item.name}}</th>
                            <td>{{item.value | translate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="nh-table-mobile d-lg-none">
                <p> {{APPEAL_SUMMARY_STATIC8}}</p>
                <div class="table-row mb-3 pb-3" *ngFor="let item of appealDetailsArray; index as i">
                    <div class="mobile-table-header">{{item.key | translate}}</div>
                    <hr>
                    <div class="mobile-table-data text-wrap">{{item.value | translate}}
                    </div>
                </div>
            </div>
        </div>
        <!-- Appeal Representative Details Table -->
        <div id="heading_4">
            <div class="row">
                <div class="col-12 d-flex align-items-center justify-content">
                    <div id="caratId_4" class="carat d-none d-lg-block"><span id="dwn_4" class="cla2"><i
                                class="fa fa-2x fa-angle-down"></i></span><span id="up_4" class="cla1"><i
                                class="fa fa-2x fa-angle-up"></i></span></div>
                    <h5 id="headerButton_4" class="section-header pl-3">{{APPEAL_SUMMARY_STATIC9}}</h5>
                        <div id="caratId_4" class="carat  d-lg-none"><span id="dwn_4" class="cla2"><i
                            class="fa fa-2x fa-angle-down"></i></span><span id="up_4" class="cla1"><i
                            class="fa fa-2x fa-angle-up"></i></span></div>
                </div>
            </div>
            <hr>
        </div>
        <div id="collapse_4" class="collapse" aria-labelledby="heading_4" data-parent="#accordion">
            <div class="card-body d-none d-lg-block">
                <p *ngIf="appealRepresentativeArray?.length"> {{APPEAL_SUMMARY_STATIC10}}</p>
                <div *ngIf="appealRepresentativeArray?.length">
                    <div *ngFor="let item of appealRepresentativeArray">
                        <table class="table table-bordered">
                            <tbody class="thead-light text-left">
                                <tr *ngFor="let obj of item.request; index as i">
                                    <th scope="col">{{obj.key | translate}}</th>
                                    <td>{{obj.value | translate}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <p *ngIf="!appealRepresentativeArray?.length">{{'APPEAL_SUMMARY_REP_EMPTYDATA' | translate }}</p>
            </div>
            <div class="nh-table-mobile d-lg-none" *ngIf="appealRepresentativeArray?.length">
                <p> {{APPEAL_SUMMARY_STATIC10}}</p>
                <div *ngFor="let item of appealRepresentativeArray">
                    <div class="table-row mb-3 pb-3" *ngFor="let obj of item.request; index as i">
                        <div class="mobile-table-header">{{obj.key | translate}}</div>
                        <hr>
                        <div class="mobile-table-data">{{obj.value | translate}}
                        </div>
                    </div>
                </div>
            </div>
            <p class="nh-table-mobile d-lg-none" *ngIf="!appealRepresentativeArray?.length">{{'APPEAL_SUMMARY_REP_EMPTYDATA' | translate }}</p>
        </div>
    </div>
</section>
</nh-form>