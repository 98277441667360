<ng-container>
  <nh-field [id] = "topLevelName" [label]="label" *ngIf="answerControl.enabled && !this.isASCLD" class="label-section">
    <nh-popover class="pl-1 field-label" *ngIf="popupText" [content]="popupText"></nh-popover>
    <div class="hint">{{topLevelHint}}</div>
    <ng-content select="[top-level-info]"></ng-content>
    <select nh name="topLevelAnswer" aria-label="topLevelAnswer" bindValue="code" [empty]="{ code: 'EMPTY', value: '' }" [items]="options" [formControl]="answerControl"></select>
    <span *ngIf="answerControl.touched && answerControl.value === 'EMPTY'" class="form-text invalid-tooltip d-block">{{'REQUIRED' | translate}}</span>
  </nh-field>

  <nh-field [id] = "topLevelName" [label]="label" *ngIf="this.isASCLD && initialTopLevelAnswer === 'EMPTY'" class="label-section">
    <nh-popover class="pl-1 field-label" *ngIf="popupText" [content]="popupText"></nh-popover>
    <div class="hint">{{topLevelHint}}</div>
    <ng-content select="[top-level-info]"></ng-content>
    <select nh name="topLevelAnswer" aria-label="topLevelAnswer" bindValue="code" [empty]="{ code: 'EMPTY', value: '' }" [items]="options" [formControl]="answerControl"></select>
    <span *ngIf="answerControl.touched && answerControl.value === 'EMPTY'" class="form-text invalid-tooltip d-block">{{'REQUIRED' | translate}}</span>
  </nh-field>
  <ng-content *ngIf="showForm$ | async"></ng-content>
</ng-container>
