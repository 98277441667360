import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { tap, switchMap, map } from 'rxjs/operators';

import { prop } from 'ramda';

import { validations } from '../details';
import { SectionComponent } from '@app/section/section.component';

import { nilElse } from '@app/helpers/null.helpers';
import { editUncompleted } from '@app/helpers/people.helpers';
import { recordIndividualId, setRecordId, setBlockId } from '@app/helpers/record.helpers';

import { Person } from '@app/models/record.model';
import { Block, Identifier } from '@app/models/record.model';

import { isChangeReport, isPresumptiveApp, isPresumptiveBcc, isPresumptiveHospital, isPresumptivePregnancy, isRedeMode, isApplyForBenefits, isRenewBenefits, isReportChanges } from '@app/helpers/mode.helpers';


import { propOr } from 'ramda';
import { maskSSN } from '@app/helpers/function.helpers';
import { Observable } from 'rxjs';
import { trudy } from '@app/helpers/function.helpers';
@Component({
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class PeopleComponent extends SectionComponent implements OnInit {
  showLeave = true;
  blocks;
  disableNext = false;
  presumptiveErrorMessage: any = [];
  isPresumptiveError: boolean = false;
  isPresumptiveApp: boolean = isPresumptiveApp(this.applicationType);
  isPresumptiveHospitalApp: boolean = isPresumptiveHospital(this.applicationType);
  isPresumptivePregnancyApp: boolean = isPresumptivePregnancy(this.applicationType);
  isPresumptiveBccApp: boolean = isPresumptiveBcc(this.applicationType);
  isApplyForBenefits: boolean = isApplyForBenefits(this.applicationType);
  isRenewBenefits: boolean = isRenewBenefits(this.applicationType);
  isReportChanges: boolean = isReportChanges(this.applicationType);
  isChangeMode = false;
  isRedeModeOnly = false;
  appKeyWord;
  isFinReassessment = false;
  isMinorApplyForBenefitsError: boolean = false;
  isApplyForBenefitsError: boolean = false;
  isCOBwarningMessage: boolean = false;
  presumptiveWarning : any = [];
  checkBlock$: Observable<boolean>;
  appType: string;
  isPolicyHolderDeleted = false;
  policyHolderName = sessionStorage.getItem('policyHolder');
  duplicateSummaryResults: any;
  peopleDuplicateSummaryResults: any;
  isDuplicatePeopleError: boolean = false;
  isRmbRmc: boolean = false;
  isDuplicateBabiesExist: boolean = false;
  intervalHolder: any;
  
 // appType = sessionStorage.getItem('appType');

  ////////////////////////////////////// LIFECYCLE //////////////////////////////////////
  ngOnDestroy() {
    clearInterval(this.intervalHolder);
  }


  ngOnInit() {
    this.cd.detach();
    this.intervalHolder = setInterval(() => {
      this.getAllUsers();
    }, 0);
    if (this.policyHolderName) {
      if (this.records.filter(x => (x.data.name.fullName === this.policyHolderName)).length === 0) {
        this.isPolicyHolderDeleted = true;
        sessionStorage.removeItem('policyHolder');
      } else {
        this.isPolicyHolderDeleted = false;
      }
    }
    this.isChangeMode = isChangeReport(this.mode);
    this.isRedeModeOnly = isRedeMode(this.mode);
    this.isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));
    this.appType = sessionStorage.getItem('appType');
    this.isRmbRmc = this.appType === 'RB' || this.appType == 'RC';
    this.appKeyWord = this.isChangeMode ? 'CHANGE' : (this.isRedeModeOnly && !this.isFinReassessment) ? 'RENEWAL' : (this.isRedeModeOnly && this.isFinReassessment) ? 'reassessment' : 'APPLICATION';
    if (this.sectionId !== 'HHSUR') {
      this.updateIndividuals();
      this.state.setPerson(null);
      editUncompleted(record => this.onEditBlock(record), this.records);
    }
    const bool = this.records.some(rec => rec.exportingStatus === 'EXPORTED');
    this.disableNext = isRedeMode(this.mode) ? bool : false;

    if (this.isPresumptiveApp && this.sectionId === 'HHIND') {
      this.isPresumptiveError = false;
      this.checkPresumptiveAppStatus();

      if (this.records && this.records[0].data && this.records[0].data.cobIndicator) {
        if (this.records.some(rec => rec.data.cobIndicator === 'Y')) {
          const name = this.records.filter(rec => rec.data.cobIndicator === 'Y')[0].data.name.fullName;
          this.isCOBwarningMessage = true
          this.presumptiveWarning.push({'message': `${name} has temporary coverage due to an appeal but you can still complete the application.`})
        }
      }

      if (!this.isPresumptiveError && this.records && this.records[0].data && this.records[0].data.applyForCoverage && this.isPresumptivePregnancyApp) {
        this.isPresumptiveError = false;
        if (this.records.filter(x => (x.data.applyForCoverage.code == 'Y' && x.data.pregnant)).length === 0) {
          this.presumptiveErrorMessage.push({'message': 'Please make sure that at least one individual in your application is pregnant on the Additional Details screen to apply for Presumptive Pregnant coverage.'});
          this.isPresumptiveError = true;
        }
      }

      if (!this.isPresumptiveError && this.records && this.records[0].data && this.records[0].data.applyForCoverage && this.isPresumptivePregnancyApp) {
        this.isPresumptiveError = false;
        const femalesApplyForCoverage = this.records.filter(x => (x.data.gender.code == 'F' && x.data.applyForCoverage.code == 'Y')).length;
        const ispregnent = this.records.filter(x => (x.data.gender.code == 'F' && x.data.applyForCoverage.code == 'Y' && x.data.pregnant)).length;
        if (femalesApplyForCoverage !== ispregnent) {
          this.presumptiveErrorMessage.push({'message': 'Please make sure that all female individuals in your application are pregnant on the Additional Details screen to apply for Presumptive Pregnant coverage.'});
          this.isPresumptiveError = true;
        }
      }

      if (!this.isPresumptiveError && this.records && this.records[0].data && this.records[0].data.applyForCoverage && this.isPresumptiveBccApp) {
        this.isPresumptiveError = false;
        if (this.records.filter(x => (x.data.applyForCoverage.code == 'Y' && x.data.age < 65)).length === 0) {
          this.isPresumptiveError = true;
          this.presumptiveErrorMessage.push({message: 'Applicant\'s age cannot be 65 or older for a Presumptive BCC application. Please confirm the applicant\'s date of birth. If the applicant is 65 or older they should submit a full Medicaid application instead.'});
        }
      }

      if (this.records && this.records[0].data && !this.isPresumptiveBccApp && this.records[0].data.cobIndicator !== 'Y') {
        if (this.records.some(rec => rec.data.presumptivePastEligSw === 'Y')) {
          const name = this.records.filter(rec => rec.data.presumptivePastEligSw === 'Y')[0].data.name.fullName;
          this.presumptiveErrorMessage.push({'message': `${name} is either receiving benefits now or had a Hospital Presumptive Eligibility period in the last two years. ${name} may call TennCare Connect at 855-259-0701 or log on to member portal (TennCare Connect) to apply or check benefits.`})
        }
      }
    }

    if (this.isPresumptiveApp && this.sectionId === 'HHIND' && this.mode === 'NEW') {
      this.disableNext = this.isPresumptiveError;
    }

    if (this.isApplyForBenefits && this.sectionId === 'HHIND') {
      if (this.records && this.records[0].data?.age < 18 ) {
        this.isMinorApplyForBenefitsError = true;
      }
      if (this.records.length === this.records.filter(rec => rec.data?.applyForCoverage?.code === 'N').length) {
        this.isApplyForBenefitsError = true;
      }
    }

    if (this.isApplyForBenefits && this.sectionId === 'HHIND' && this.mode === 'NEW') {
      this.disableNext = this.isApplyForBenefitsError;
    }

    if ((this.isRenewBenefits || this.isReportChanges || this.isFinReassessment) && this.sectionId === 'HHIND') {
      this.duplicateSummaryResults = this['records'].map(({data, exportingStatus}) => {

        const dateformater = (date) => {
          let newDate = date.split("-");
          return newDate[1]+"/"+newDate[2]+"/"+newDate[0];
        }
        return {
          birthDate: dateformater(data.birthDate),
          personFirstname: data.name.firstName?.toLowerCase(),
          personLastname: data.name.lastName?.toLowerCase(),
          personMiddlename: data.name.middleInitial?.toLowerCase(),
          personGender: data.gender?.code,
          exportingStatus: exportingStatus,
          ssn: data.ssn
        }

      });

      const originalArray =  this.duplicateSummaryResults.filter((item) => (item.exportingStatus === 'CHANGED' || item.exportingStatus === 'EXPORTED' || (item.exportingStatus === 'REMOVED')));
      const addedArray =  this.duplicateSummaryResults.filter((item) => (item.exportingStatus === 'ADDED'));

      this.peopleDuplicateSummaryResults = this.checkDuplicates(originalArray,addedArray);
      const allEqualToFalse = this.peopleDuplicateSummaryResults.every( v => v === false );

      if(allEqualToFalse){
        this.isDuplicatePeopleError = false;
      }else{
        this.isDuplicatePeopleError = true;
      }
    }
    if (this.sectionId === 'HHIND' && this.isRmbRmc) {
      this.isDuplicateBabiesExist = this.records.some(item => item.data && (item.data.duplicateNewBorn === 'Y' || item.data.duplicateNewBornBaby === 'Y' || item.data.duplicatePerson === 'Y' || item.data.duplicateBabyAndPerson === 'Y'));
    }
    const user = sessionStorage.getItem('userType');
    const isPartnerPortal = (user != 'RP');
    let postObj: any;

    const getSSN = propOr('', 'ssn');

    const nameSSN = ({ data, identifier }, { individual, ssn }) => {
      let ssnValue = getSSN(data);

      if (ssnValue && ssnValue[0] !== 'x' && ssnValue[0] !== 'X') {
        ssnValue = maskSSN(ssnValue);
      }
      return `${individual.transform({ identifier })}<br>
    ${ssn.transform(ssnValue)}`;
    };

    const maleCheck = ({data}) => {
      if (data.gender?.code === 'M' || (data.gender?.code === 'F' && data.age <= 9 || data.age >= 56)) return 'N/A';
      else return data.pregnant ? 'Yes' : 'No'
    }

    // const notApplyCoverage = this.records.filter(record => record.data?.applyForCoverage?.code === 'N');
    // const applyForCoverageNonTNResidents = this.records.filter(record => record.data?.applyForCoverage?.code === 'Y' && record.data?.tennesseeResident?.code === 'N');
    // const applyForCoverage = this.records.filter(record => record.data?.applyForCoverage?.code === 'Y');
    // if (notApplyCoverage.length) {
    //   notApplyCoverage.forEach((record) => {
    //     record.data.tennesseeResident = { code: "Y", value: "Yes", spValue: "Si" };
    //   });
    // }
    // if (applyForCoverageNonTNResidents.length !== 0 && applyForCoverage.length !== 0 && applyForCoverageNonTNResidents.length === applyForCoverage.length) {
    //   this.records.forEach((record) => {
    //     record.data.tennesseeResident = { code: "N", value: "No", spValue: "No" };
    //   });
    // }

    if (isPartnerPortal || (!isPartnerPortal && this.isApplyForBenefits)) {
      postObj = [{ name: 'Name / SSN', pipe: 'domain', args: nameSSN },
      { name: 'Date of Birth', prop: 'data.birthDate', pipe: 'date' },
      { name: 'Applying for<br> Coverage', prop: 'data.applyForCoverage.value' },
      { name: 'Pregnant', pipe: 'domain', args: maleCheck}]
    } else {
      postObj = [
        { name: 'Person', pipe: 'domain', args: nameSSN },
        { name: 'Date of Birth', prop: 'data.birthDate', pipe: 'date' },
        { name: 'Presumptive<br> Coverage', prop: 'data.applyForCoverage.value' },
        { name: 'Full<br> Coverage', prop: 'data.applyForOnGoingCoverage.value' },
        { name: 'Pregnant', pipe: 'domain', args: maleCheck }
      ]
    }

    this.columns = postObj;

    if (sessionStorage.getItem('addPerson') == 'true') {
      sessionStorage.removeItem('addPerson');
      return this.onCreateBlock();
    };

    const selectedIndv = sessionStorage.getItem('selectedIndv');
    if (selectedIndv) {
      sessionStorage.removeItem('selectedIndv');
      const filteredRecord = this.records.filter(record => record.identifier.individualId == selectedIndv);
      return this.onEditBlock(filteredRecord[0]);
    };

    this.checkBlock$ = this.blockLinks$.pipe(map((res) => {
      return (res ? (res.length <= 0) : true);
    }))

  }

  getAllUsers(){
    this.cd.detectChanges();
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  checkDuplicates(originalArray, addedArray) {
    const finalArray = [];
    for (var j = 0; j < addedArray.length; j++) {
      for (var i = 0; i < originalArray.length; i++) {
        if ((originalArray[i].personFirstname === addedArray[j].personFirstname) && (originalArray[i].personLastname === addedArray[j].personLastname) && (originalArray[i].personMiddlename === addedArray[j].personMiddlename) && (originalArray[i].birthDate === addedArray[j].birthDate) && (originalArray[i].personGender === addedArray[j].personGender) && (originalArray[i].ssn === addedArray[j].ssn)) {
          finalArray.push(true);
        }else{
          finalArray.push(false);
        }
      }
    }
    return finalArray;
  }
  onLeave() {
    if (this.blockId === 'demographics') {
      if (this.activePerson) {
        if (this.activePerson.data.blocks[0].status === 'UNCOMPLETED') {
          this.onPrevious();
        } else {
          this.onClose();
        }
      } else {
        this.onClose();
      }
        }  else {
      this.canDeactivate().subscribe(trudy(() => {
      this.blockLinks$.subscribe(res => {
        if (res) this.blocks = res;
      })
      if (this.blocks) {
        const activeIndex = this.blocks.findIndex(block => this.blockId === block.blockId)
        this.onEditBlock(setRecordId(this.blocks[activeIndex - 1].blockId, this.activePerson));
      }
      }));
    }
  }
     
  ////////////////////////////////////// LIFECYCLE //////////////////////////////////////

  ////////////////////////////////////// FACADE //////////////////////////////////////

  onPerson(activePerson: Person) {
    return this.state.setPerson(activePerson).pipe(
      tap(setBlockId(this)),
      switchMap(identifier => this.loadBlock(identifier))
    );
  }

  loadBlock(identifier: Identifier) {
    return this.state.loadBlock(this.applicationNumber, identifier);
  }

  onHalt(record) {
    this.clearForm();
    this.updateIndividuals();
    this.onEdit(record);
  }

  onSaved(block: Block | null) {
    return block ? this.onHalt(block) : super.onSaved(block);
  }

  ////////////////////////////////////// FACADE //////////////////////////////////////

  ////////////////////////////////////// STATE UPDATE //////////////////////////////////////

  updateIndividuals() {
    this.state.setIndividuals(this.records);
  }

  updateConfig({ component, formValidation, postObject }) {
    this.update({ component, formValidation, postObject });
  }

  updateForm() {
    this.clearForm();
    this.updateConfig(prop(this.blockId, validations));
  }

  ////////////////////////////////////// STATE UPDATE //////////////////////////////////////

  ////////////////////////////////////// TEMPLATE ACTION HANDLERS //////////////////////////////////////

  onBlock(tab) {
    this.onEditBlock(setRecordId(tab.blockId, this.activePerson));
  }

  onCreateBlock(isDemographics?: boolean) {
    if (isDemographics) {
      this.storageService.setAddPersonClicked(true);
    }
    this.blockId = 'demographics';
    sessionStorage.setItem('isAddingPerson', 'true');
    this.state.setBlock(null).subscribe();
    this.updateForm();
    super.onCreate();
  }

  onCreate() {
    this.onEdit(
      this.emptyRecord({
        sectionId: this.sectionId,
        recordId: this.blockId,
        individualId: recordIndividualId(this.activePerson),
      })
    );
  }

  onEditBlock(activePerson, showLeave = true) {
    this.update({ showLeave });
    return this.onPerson(activePerson)
      .pipe(tap(() => this.updateForm()))
      .subscribe(nilElse(() => this.onCreate(), record => this.onEdit(record)));
  }

  onUndo (record) {
    this.state.onUndo(this.applicationNumber, record.identifier, record).subscribe((resp) => {if(resp) { this.continue()}});
  }

  onChange(event) {
    if (Array.isArray(event)) {
      this.peopleSelection = event;
    }
  }

  checkPresumptiveAppStatus() {
    if (this.records && this.records[0].data && this.records[0].data.applyForCoverage) {
      this.isPresumptiveError = false;
      if (this.records.filter(x => x.data.applyForCoverage.code == 'Y').length === 0) {
        this.isPresumptiveError = true;
        this.presumptiveErrorMessage.push({message: 'Please make sure that at least one individual in your application is applying for presumptive Coverage'});
      }
    }
  }

  ////////////////////////////////////// TEMPLATE ACTION HANDLERS //////////////////////////////////////
}