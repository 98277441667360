import { both, compose, find, map, assoc, converge, join, props, evolve } from 'ramda';

import { minor, fit } from './person.helpers'; // age
import { inHousing } from './person.helpers'; // livingAreaType
import { hasSpouse, personUncompleted } from './person.helpers';
import { grossIncome, taxFilerApplicable, childCare, medicalCoverage } from './programs.helpers';

import { N } from './tayn.helpers';
import { ifNun } from './null.helpers';
import { orPath } from './flipped.helpers';
import { withAnd, callWith, oPath, aPath } from './function.helpers';

export const coverable = withAnd([medicalCoverage, minor]);
export const filer = withAnd([taxFilerApplicable, hasSpouse]);
export const frail = withAnd([grossIncome, fit]);

const hasChildCare = compose(
  childCare,
  aPath(['programs'])
);

export const isMcKinney = both(hasChildCare, inHousing);

const peopleUncompleted = find(personUncompleted);
export const editUncompleted = callWith([ifNun, peopleUncompleted]);
export const deniedCitizenship = evolve({ data: { usCitizen: N } });

const individualId = oPath(['identifier', 'individualId']);
const data = oPath(['data']);

const collapseRecord = converge(assoc('identifier'), [individualId, data]);

export const toIndividuals = compose(
  map(collapseRecord),
  aPath(['records'])
);

export const individualFullname = compose(
  join(' '),
  props(['firstName', 'middleInitial', 'lastName'])
);

export const individualName = compose(
  individualFullname,
  orPath(['name'])
);

export const individualNameSuffixName = compose(
  join(' '),
  props(['value'])
);

export const individualNameSuffixValue = compose(
  individualNameSuffixName,
  orPath(['suffix'])
);
