import { Component, Input } from '@angular/core';

@Component({
  selector: 'nh-input-group',
  templateUrl: './input-group.component.html',
})
export class InputGroupComponent {
  @Input() prepend: string | null = null;
  @Input() append: string | null = null;
}
