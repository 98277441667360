<div class="container">
  <div class="row">
    <div class="card" style="width:100%;">
      <div class="card-body">
        <section>
          <h5 class="section-header">{{'CHANGE_SECURITY_QUES' | translate }}</h5><hr>
          <p [innerHtml]="'CHANGE_SECURITY_TEXT' | translate"></p><br>

          <form [formGroup]="updateSecurityQuestions">
            <div class="row">
              <div class="col">
                <nh-field [isRow]="true" label="{{'Q1' | translate }}" class="label-section">
                  <select   style="max-width: 65.66666667% !important;" nh name="question1" formControlName="question1" [items]="questionSet1" aria-label="question1"></select>
                </nh-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <nh-field [isRow]="true" label="{{'ANSWER' | translate }}" class="label-section">
                  <input type="text" name="answer1" formControlName="answer1" aria-label="answer1"
                    (input)="answerUpdate(answer2, answer3, answer1)" class="form-control" required>
                </nh-field>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <nh-field [isRow]="true" label="{{'Q2' | translate }}" class="label-section">
                  <select   style="max-width: 65.66666667% !important;" nh name="question2" formControlName="question2" [items]="questionSet2" aria-label="question2"></select>
                </nh-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <nh-field [isRow]="true" label="{{'ANSWER' | translate }}" class="label-section">
                  <input type="text" name="answer2" formControlName="answer2" aria-label="answer2"
                    (input)="answerUpdate(answer1, answer3, answer2)" class="form-control" required>
                </nh-field>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <nh-field [isRow]="true" label="{{'Q3' | translate }}" class="label-section">
                  <select   style="max-width: 65.66666667% !important;" nh name="question3" formControlName="question3" [items]="questionSet3" aria-label="question3"></select>
                </nh-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <nh-field [isRow]="true" label="{{'ANSWER' | translate }}" class="label-section">
                  <input type="text" name="answer3" formControlName="answer3" aria-label="answer3"
                    (input)="answerUpdate(answer1, answer2, answer3)" class="form-control" required>
                </nh-field>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
</div>
