import { SupportPaymentsComponent } from './support-payments.component';

import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';

import supportPaymentLabels from './support-payments-labels';

const formValidation = (builder, recordData) => {
  const supportPayments = lookIn(recordData);

  const data = builder.group({
    supportKindCode: [supportPayments(['supportKindCode']), Vs.required],
    expenseAmount: [supportPayments(['expenseAmount']), [Vs.required, Vs.currency]],
  });

  return { data };
};

export default sectionConfiguration(
  SupportPaymentsComponent,
  formValidation,
  ['TAYN', 'CHILDSUPMANEXPENSETYPE', 'TPFT', 'TACY', 'TSTA'],
  [
    { name: 'Name', pipe: 'individual' },
    { name: 'Expense Type', prop: 'data.supportKindCode.value' },
    { name: 'How Much', prop: 'data.expenseAmount', pipe: 'currency' }
  ],
  {...{}, ...supportPaymentLabels},
  {tooltipAdd: null}
);
