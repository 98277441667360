export const doc = {
    docType: "What is indiv's immigration document type?",
    registrationNumber: 'Alien Number',
    i94: 'I-94 Number',
    visa: 'Visa Number',
    passport: 'Passport Number',
    countryIssuance: 'Country of Issuance',
    cardNumber: 'Receipt/Card Number',
    expDate: 'Document/Passport Expiration Date',
    documentDescription: 'Document Description',
    naturalizationCertificateNumber: 'Naturalization Certificate Number',
    certificateOfCitizenshipNumber: 'Certificate of Citizenship',
    sevisID: 'SEVIS ID'
}
export const immigration = {
    statusGainDate: 'What date did indiv gain this status?',
    status: "What is indiv's immigration status?",
    document: doc
}

export const military = {
    isVeteranOrActiveDuty: 'Is indiv veteran or active duty member?',
    isSpouseVeteranOrActiveDuty: "Is indiv's spouse a veteran or active duty member?",
    isParentVeteranOrActiveDuty: 'Is the parent of the indiv in the household a veteran or active duty member?'
}

export default {
    eligibleImmigration: "If indiv isn't a U.S. citizen or national, do they have eligible immigration status?",
    immigration: immigration,
    militaryInfo: military
}