<div class="container">
	<div class="pt-2">
		<h1 class="page-title">{{heading1}}</h1>
	</div>
	<div class="hover-red">
		<a class="hover-red btn btn-link" (click)="onPrevious()">
			<svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
				<g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
					<path id="Combined_Shape" data-name="Combined Shape"
						d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
						transform="translate(0)" fill="#174a7c" />
				</g>
			</svg>
			<span class="pl-2">Back to previous page</span>
		</a>
	</div>
	<div class="container">
		<div class="row py-4">
			<div class="card">
				<div class="page_label p-4">
					<h5 class="section-header">{{heading2}}</h5>
					<hr>
					<p>{{heading3}}</p>
					<br>
					<form [formGroup]="coverageSearchForm">
						<div class="form-row">
							<div class="col-sm-12 col-md-6">
								<div class="coverageSearch">
									<div class="col-sm-12">
										<nh-field [isRow]="true" [mandatory]="true"
											label="{{'FIRST_NAME' | translate}}">
											<input type="text" aria-label='firstName' name="firstName"
												 formControlName="firstName"
												class="form-control mt-3" maxlength="45">
										</nh-field>
									</div>
									<div class="col-sm-12">
										<nh-field [isRow]="true" [mandatory]="true" label="{{'LAST_NAME' | translate}}">
											<input aria-label='lastName' type="text" name="lastName"
												 formControlName="lastName"
												class="form-control mt-3" maxlength="45">
										</nh-field>
									</div>
								</div>
								<div class="coverageSearch">
									<div class="col-sm-12">
										<nh-field id="ms_dateOfBirth" [mandatory]="true" [isRow]="true"
											label="Social Security Number" class="label-section">
											<input aria-label="ssn" type="text" name="ssn" formControlName="ssn"
												class="form-control mt-3" placeholder="XXX-XX-XXXX" mask="000-00-0000">
										</nh-field>
									</div>
									<div class="col-sm-12 col-md-12">
										<nh-field label="Date of Birth" class="label-section" [isRow]="true">
											<nh-datepicker>
												<input id="ms_dateOfBirth" type="text" class="form-control mt-3"
													name="dateOfBirth" formControlName="dateOfBirth"
													aria-label="dateOfBirth" placeholder="mm/dd/yyyy"
													[minDate]="minimumDate" [maxDate]="today" bsDatepicker nh required>
											</nh-datepicker>
										</nh-field>
									</div>
								</div>
							</div>
						</div>
						<div class="form-row py-2">
							<div class="d-flex">
								<div>
									<nh-checkbox name="applicantBehalf"
										[control]="coverageSearchForm.controls['applicantBehalf']"
										checkedValue='checked' label="{{heading4}}"
										[mandatory]="true"></nh-checkbox>
								</div>
							</div>
						</div>
					</form>
					<br>
					<div class="form-row">
						<div class="col-12 col-md-3 py-2 py-md-0 order-md-1">
							<button class="btn btn-secondary" (click)="onSearch()" style="text-transform: initial"
								[disabled]="coverageSearchForm.invalid">
								Search&nbsp; <i class="fa fa-search"></i>
							</button>
						</div>
					</div>
					<br>
					<div *ngIf="rows && rows?.length && isSearchCompleted">
						<h5 class="section-header">Search Results</h5>
						<hr>
						<ngx-datatable *ngIf="rows && rows?.length" class='bootstrap striped' #table [rows]='rows'
							columnMode="force" [headerHeight]='50' [footerHeight]='50' [rowHeight]='"auto"' [limit]="5">
							<ngx-datatable-column [name]="col.name" [prop]="col.prop"
								*ngFor="let col of columns"></ngx-datatable-column>
							<ngx-datatable-column name="Select" [width]="180">
								<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
									<div class="d-flex">
										<a *ngIf="isSearchCompleted"
											class="view-tennCare-title">
											<span (click)="resumeAFB()">Continue in progress application
												</span>
										</a>
									</div>
								</ng-template>
							</ngx-datatable-column>
						</ngx-datatable>
					</div>
					<div *ngIf="rows && !rows.length">
						<h5 class="section-header">Search Results</h5>
						<hr>
						<p>We could not find the person you searched for. Click the button below to start a new application.</p>
					</div>
					<div class="pt-2">
						<div class="form-row justify-content-center">
							<div class="col-5 col-md-5 d-none d-sm-block">
								<p class="and-border"></p>
							</div>
							<div class="px-1">
								<p class="text-center"><strong>OR</strong></p>
							</div>
							<div class="col-5 col-md-5 d-none d-sm-block">
								<p class="and-border"></p>
							</div>
						</div>
					</div>
					<div class="text-center">
						<button (click)="startApp('AFB')" class="btn btn-secondary btn-search" style="text-transform: initial">
							Start a New Application
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>