export const en_labels = {
    topLevelQuestion: "OTHER_COVERAGE_STATICTEXT2",
    individualQuestion: "OTHER_COVERAGE_RMB_SUMMARY_STATIC1",
    title: "OTHER_COVERAGE_STATICTEXT1",
    subTitle: "OTHER_COVERAGE_RMB_SUMMARY_STATIC2",
    insuranceCompanyName: 'NAME',
    phoneNumber: 'PHONE_NUMBER1',
    emailId: 'EMAIL_ADDRESS',
    minRequirementMetSw: 'OTHER_COVERAGE_STATICTEXT5',
    minRequirementMetSwTooltip: "OTHER_COVERAGE_RMB_SUMMARY_STATIC3",
    policyPremiumAmount: 'OTHER_COVERAGE_STATICTEXT7',
    policyPremiumAmountTooltip: 'OTHER_COVERAGE_STATICTEXT10',
    premiumFreqCd: 'OTHER_COVERAGE_STATICTEXT9',
    cobraSw: 'OTHER_COVERAGE_STATICTEXT8',
    cobraSwTooltip: 'OTHER_COVERAGE_STATICTEXT11',
    retireeSw: 'OTHER_COVERAGE_STATICTEXT12',
    retireeSwTooltip: 'OTHER_COVERAGE_STATICTEXT13',
    removed: {
        otherEndDate: 'OTHER_COVERAGE_RMB_SUMMARY_STATIC4'
    }
}

export const sp_labels = {
  topLevelQuestion: "¿Alguien del hogar tiene acceso a seguro médico través de un trabajo o el trabajo de un miembro de la familia pero no se ha inscrito?",
  individualQuestion: "¿Quién podría inscribirse en otro seguro a través del empleador de un miembro de la familia? <div class=hint>Si necesita agregar a otra persona, complete esta página y haga clic en <b>Siguiente.</b></div>",
  title: "Acceso a otra cobertura",
  subTitle: "Quizás necesitemos más información sobre este seguro médico. ¿Quién puede darnos más información?",
  insuranceCompanyName: 'Nombre',
  phoneNumber: 'Número de teléfono',
  emailId: 'Dirección de correo electrónico',
  minRequirementMetSw: '¿Esta cobertura de salud cumple con las reglas de cobertura mínima esencial?',
  minRequirementMetSwTooltip: 'A esto a veces se le llama "cobertura médica calificada".  Es un seguro que cubre atención médica, salud conductual, medicamentos con receta, atención en el hospital, servicios preventivos y de emergencia.  También le impide pagar una multa fiscal.  El seguro que no cumple con las reglas para la cobertura esencial mínima incluye planes de beneficios limitados o fijos, seguro médico complementario o seguro médico a corto plazo.',
  policyPremiumAmount: '¿Cuál es la cantidad de la prima para este plan?',
  policyPremiumAmountTooltip: 'Una prima es la cantidad que paga, generalmente cada mes, para inscribirse en este seguro.',
  premiumFreqCd: '¿Con qué frecuencia se pagará la prima?',
  cobraSw: '¿Es un plan COBRA?',
  cobraSwTooltip: 'Seguro médico a través de un empleador que puede conservar durante un tiempo limitado después de que finalice el empleo.',
  retireeSw: '¿Es un plan de seguro médico para jubilados?',
  retireeSwTooltip: 'Cobertura de seguro médico a través de un empleador anterior para un individuo jubilado.',
  removed: {
      otherEndDate: 'indiv removed other coverage information on '
  }
}

export default en_labels;
