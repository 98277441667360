import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { minus120Years } from '@app/helpers/date.helpers';
import Vs from '@app/services/validators/validators.service';
import { UntypedFormControl } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { LinkCaseService } from './link-case.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ModalService } from '@app/services/modal/modal.service';
import { ApiService } from '@app/gateway/services/api.service';
import { HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'nh-link-case',
  templateUrl: './link-case.component.html'
})
export class LinkCaseComponent implements OnInit {

  contentHeaders = new HttpHeaders({'pageId':'TCCAT'})
  caseLinkfrom = new UntypedFormGroup({
    dateOfBirth: new UntypedFormControl('', [Vs.validDate]),
    ssn: new UntypedFormControl('', [Vs.required, Vs.ssn]),
    individualId: new UntypedFormControl(''),
    wpAppNum: new UntypedFormControl(''),
    and: new UntypedFormControl(''),
    usernameOrEmail: new UntypedFormControl('', [Vs.required]),
  });

  get form() { return this.caseLinkfrom['controls']; }

  selectedItem: any;
  today: any = new Date();
  isCaseLinked: any;
  minimumDate = minus120Years();
  personPop = 'PERSON_POPUP'
  applicationPop = 'APPLICATION_POPUP'
  defaultLanguage;
  translatedLang;
  wpAppNum = { "code": "wpAppNum", "value": "Application Number"};
  indivId = { "code": "individualId", "value": "Person ID" };
  ssn = { "code": "ssn", "value": "Social Security Number" };

  wpAppNumSp = { "code": "wpAppNum", "value": "Numero de solicitud"};
  indivIdSp = { "code": "individualId", "value": "Identificación de persona" };
  ssnSp = { "code": "ssn", "value": "Número de Seguro Social" };

  caseLinkItemsEn: any = [ this.wpAppNum, this.indivId, this.ssn];
  caseLinkItemsSp: any = [ this.wpAppNumSp, this.indivIdSp, this.ssnSp];

  caseLinkItems;
  userType;

  constructor(public linkCaseService: LinkCaseService, public cdr: ChangeDetectorRef, public translateService: TranslateService, private titleService: Title,
    private router: Router,
    private modal: ModalService,
    public apiService: ApiService) {
    this.linkCaseService.caseLinkfrom = this.caseLinkfrom;
    this.titleService.setTitle('Link a Case');
  }

  ngOnInit(): void {
    this.userType = sessionStorage.getItem("userType");
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.translatedLang);
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang
      this.languageCheck(this.translatedLang);
    })

    if(this.userType !== 'RP'){
      this.caseLinkfrom.controls['usernameOrEmail'].disable();
    }
  }

  languageCheck(lang) {
    if (lang === 'en') {
      if (this.selectedItem === 'wpAppNum') {
        this.caseLinkfrom.controls['and'].setValue(this.wpAppNum);
      } else if (this.selectedItem === 'individualId') {
        this.caseLinkfrom.controls['and'].setValue(this.indivId);
      } else if (this.selectedItem === 'ssn') {
        this.caseLinkfrom.controls['and'].setValue(this.ssn);
      } else {
        this.caseLinkfrom.controls['and'].setValue({'code': '', 'value': ''});
      }
      this.caseLinkItems = this.caseLinkItemsEn;
    } else {
      if (this.selectedItem === 'wpAppNum') {
        this.caseLinkfrom.controls['and'].setValue(this.wpAppNumSp);
      } else if (this.selectedItem === 'individualId') {
        this.caseLinkfrom.controls['and'].setValue(this.indivIdSp);
      } else if (this.selectedItem === 'ssn') {
        this.caseLinkfrom.controls['and'].setValue(this.ssnSp);
      } else {
        this.caseLinkfrom.controls['and'].setValue({'code': '', 'value': ''});
      }
      this.caseLinkItems = this.caseLinkItemsSp;
    }
  }


  onValueChange(): void {
    this.selectedItem = this.caseLinkfrom.controls['and'].value.code;
    this.cdr.detectChanges();
    this.enableFormControls();
    this.linkCaseService.onValueChange(this.selectedItem);
  }

  ssnValidator() {
    if (this.form.ssn.value.length === 9) {
      const exp = /^(?!000|999|666)\d{3}(?!00)\d{2}\d{4}$/;
      !exp.test(this.form.ssn.value) ? this.form.ssn.setErrors({INVALID_SSN_NUM: true}): this.form.ssn.setErrors(null)
    }
  }

  checkValidAppNum() {
    if (this.form.wpAppNum.value !== '')
      this.form.wpAppNum.value.length === 9 && (this.form.wpAppNum.value.charAt(0) === 'T' || this.form.wpAppNum.value.charAt(0) === 't') ? this.form.wpAppNum.setErrors(null) : this.form.wpAppNum.setErrors({ ENTER_APP_NUM: true });
  }

  checkPersonId() {
    if (this.form.individualId.value !== '')
      this.form.individualId.value.length === 9 || this.form.individualId.value.length === 10 ? this.form.individualId.setErrors(null) : this.form.individualId.setErrors({ INVALID_PERSON_ID: true });
  }

  usernameOrEmail() {
    if (this.form.usernameOrEmail.value.length !== '') {
      if(this.form.usernameOrEmail.value.includes('@')){
        const exp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        !exp.test(this.form.usernameOrEmail.value) ? this.form.usernameOrEmail.setErrors({INVALID_EMAIL: true}): this.form.usernameOrEmail.setErrors(null)
      }else{
        const exp = /^.{6,20}$/;
        !exp.test(this.form.usernameOrEmail.value) ? this.form.usernameOrEmail.setErrors({INVALID_LENGTH: true}): this.form.usernameOrEmail.setErrors(null);
      }
    }
  }


  enableFormControls(): void {
    this.caseLinkfrom.controls['individualId'].enable();
    this.caseLinkfrom.controls['ssn'].enable();
    this.caseLinkfrom.controls['wpAppNum'].enable();
  }

  onPrevious() {
    if (this.caseLinkfrom.pristine) {
      this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
      return;
    }
    this.modal.confirmDeactivate().subscribe(resp => {
      if (resp) {
        this.router.navigateByUrl('api/prov/atglance?entityId=' + this.apiService.getEntityId());
      }
    });
  }

  onSubmit(): void {
    this.linkCaseService.saveCaseLink();
    // const reqestData = this.caseLinkfrom.value;
    // // reqestData['language'] = this.currentLang;
    // this.apiService.post('publ/users/createAccount', reqestData, {headers: this.contentHeaders}).subscribe((response) => {
    //  console.log(response);
    // })
  }

  isFormValid(): boolean {
    return ((this.caseLinkfrom.status === "VALID"))
  }
  

}