import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './invalidSession.component.html',
  styleUrls: ['./invalidSession.component.scss']
})

export class InvalidSessionComponent {
  currentDate: Date
  constructor(
    private titleService: Title
  ) {
    this.currentDate = new Date();
    this.titleService.setTitle('Unable to Signin');
  }

}
