import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { push } from '@app/helpers/form-array.helpers';
import Vs from '@app/services/validators/validators.service';
import { ReferenceValue, Table } from '@app/models/tables.model';
import { isNil } from 'ramda';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { formatTableData } from '@app/helpers/tables.helpers';
import { isExported, isRmbRmc } from '@app/helpers/mode.helpers';
import { Title } from '@angular/platform-browser';
export class GlobalVars {
  public static outSideOption = { value: "Someone outside the home", code: "EMPTY" } as ReferenceValue;
}
@Component({
  templateUrl: './self-employment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfEmploymentComponent extends SectionFormDirective implements AfterContentInit {
  coOwnerIndividuals: Table;
  showBusinessDesc: boolean = false;
  error = { isError: false, errorMessage: "" };
  errorMessageEn = "Please make sure that the percentages of ownership add up to 100%.";
  errorMessageSp = "Asegúrese de que los porcentajes de propiedad sumen el 100%.";
  defaultLanguage;
  translatedLang;
  coOwnerIndivErrorMessage: boolean;
  isRmbRmc = false;
  exported = false;
  constructor(private titleService: Title, builder: FormBuilder, public translateService: TranslateService) {
    super(builder);
    this.titleService.setTitle('Self Employment');
  }

  get coOwners(): FormArray {
    return this.getControl('data.coOwners') as FormArray;
  }

  get data() {
    return this.getControl('data');
  }

  ngAfterContentInit() {
    this.isRmbRmc = isRmbRmc(this.mode);
    this.exported = isExported(this.record.exportingStatus) && this.record.exportingStatus!== "" ? true : false ;
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);
    if (this.defaultLanguage !== 'en') {
      GlobalVars.outSideOption = { value: "Alguien fuera de casa", code: "EMPTY" }
    } else {
      GlobalVars.outSideOption = { value: "Someone outside the home", code: "EMPTY" }
    }
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.languageCheck(res.lang);
        if (res.lang === 'en' && this.error.errorMessage !== null) {
          this.error.errorMessage = this.errorMessageEn
          GlobalVars.outSideOption = { value: "Someone outside the home", code: "EMPTY" }
        } else {
          this.error.errorMessage = this.errorMessageSp
          GlobalVars.outSideOption = { value: "Alguien fuera de casa", code: "EMPTY" }
        }
        this.coOwnerIndividuals = [GlobalVars.outSideOption, ...this.individuals.map((item) => ({ value: item.name.fullName, code: item.identifier }))];
    });
   // this.onAnyOneOutSideChange();
    this.onSelfEmpTypeChange();
    this.coOwnerIndividuals = [GlobalVars.outSideOption, ...this.individuals.map((item) => ({ value: item.name.fullName, code: item.identifier }))];

    this.isEnabled('data.coOwners') && this.coOwners.controls.forEach((row: FormGroup) => {
      this.coOwnerValidate(row);
    })
  }
  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  tableCodeValue (tableName, tableControl){
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }

  addRow() {
    const ownershipPercentage = new FormControl('', [Vs.required, Vs.decimal]);
   push(new FormGroup({
     coOwnerIndividual: new FormControl(null, [Vs.required]),
     outsideTheHomeFullName:  new FormControl( '', [Vs.required, Vs.invalidName]),
     ownershipPercentage: ownershipPercentage,
   }), this.coOwners);
  }
  removeRow(i: any) {
    (this.getControl('data.coOwners') as FormArray).removeAt(i);
  }

  coOwnerValidate(row: any) {
    const coOwnerIndividual = this.getValue('coOwnerIndividual', row);
    if (coOwnerIndividual && coOwnerIndividual.code === "EMPTY") {
      if (this.getValue('outsideTheHomeFullName', row) === 'N/A') {
        this.getControl('outsideTheHomeFullName', row).setValue('');
      }
      this.getControl('outsideTheHomeFullName', row).enable();
    } else if (coOwnerIndividual) {
      this.getControl('outsideTheHomeFullName', row).setValue('N/A');
      this.getControl('outsideTheHomeFullName', row).disable();
    }

    let valueArr = this.getValue('data.coOwners').map(item => item.coOwnerIndividual);
    let values = valueArr.map(val => val?.code);
    let resultedValues = values.filter(item => item !== 'EMPTY')
    const isDuplicate = resultedValues.some((item, idx) => resultedValues.indexOf(item) != idx );
    if (isDuplicate) {
      this.getControl('coOwnerIndividual', row).setErrors({INVALID_INDIV : true})
      this.coOwnerIndivErrorMessage = true
    } else {
      this.getControl('coOwnerIndividual', row).setErrors(null);
      this.coOwnerIndivErrorMessage = false
    }
  }

  onAnyOneOutSideChange() {
    if (this.getValue('data.anyOneOutside').code === 'N') {
      this.getControl('data.coOwners').disable();
      this.getControl('data.coOwners')['controls'] = [];
    } else {
      this.getControl('data.coOwners').enable();
      if (this.getControl('data.coOwners')['controls'].length == 0) {
        this.addRow();
        this.addRow();
      } else if ( this.getControl('data.coOwners')['controls'].length == 1) {
        this.addRow();
      }

      const { coOwnerIndividual, outsideTheHomeFullName } = (this.coOwners.controls[0] as FormGroup).controls;
      if (isNil(coOwnerIndividual.value) && this.selectedIndividual) {
        const { identifier, name } = this.selectedIndividual;
        coOwnerIndividual.setValue({ code: identifier, value: name.fullName });
        outsideTheHomeFullName.setValue('N/A');
        outsideTheHomeFullName.disable();
      }

      this.coOwners.controls.forEach((row: FormGroup) => {
        this.coOwnerValidate(row);
      })
    }
  }

    getErrorMessage(row) {
      const percentageControl = this.getControl('ownershipPercentage', row)
      const total = this.coOwners.controls.reduce((total, current: FormGroup) => {
        const value = (+current.controls['ownershipPercentage'].value);
        return value + total;
      }, 0);
      if (this.defaultLanguage === 'en') {
        total !== 100 && percentageControl.status === 'VALID' ?
          this.error = { isError: true, errorMessage: this.errorMessageEn } :
          this.error = { isError: false, errorMessage: null }
      } else {
        total !== 100 && percentageControl.status === 'VALID' ?
          this.error = { isError: true, errorMessage: this.errorMessageSp } :
          this.error = { isError: false, errorMessage: null }
      }
    }

  onSelfEmpTypeChange() {
    if (this.getValue('data.type').code === 'OT') {
      this.showBusinessDesc = true;
      this.getControl('data.businessDescription').enable();
    } else {
      this.showBusinessDesc = false;
      this.getControl('data.businessDescription').disable();
    }
  }

  isAddButtonEnabled(): boolean {
      let percentageTotal = 0;
      this.getValue('data.coOwners').forEach(coOwner => {
        percentageTotal = percentageTotal + parseFloat(coOwner.ownershipPercentage);
      })
      return percentageTotal === 100 || this.coOwnerIndivErrorMessage;
  }
}
