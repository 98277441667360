<div class="container mb-2">
	<div class="row">
		<div class="col-12">
			<h1 class="page-title">{{'RENEW_MY_COVERAGE' | translate }}</h1>
		</div>
	</div>
	<div class="row">
		<div class="card pb-2" style="width:100%">
			<div class="card-body">
				<div>
					<a class="btn pl-0 btn-link" [routerLink]="['/user-profile', 'benefit-dashboard']">
						<i class="fa fa-arrow-left"></i>
						<span class="pl-2">{{'BACK_TO_DASHBOARD' | translate }}</span>
					</a>
				</div>
				<div *ngIf="response" class="d-none d-lg-block">
					<div class="row">
						<nh-alert type="warning" [isOpen]="renewalNudgeAlert">
							<div class="row" >
							  <div class="icon">
								<img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
							  </div>
							  <div class="message">
								<span>{{'RENEWAL_NUDGE_WARNING' | translate}}</span>
							  </div>
							</div>
						  </nh-alert>
						<table class="table">
							<thead>
								<tr>
									<th>{{'Case' | translate }}</th>
									<th>{{'Due Date' | translate }}</th>
									<th>{{'Action' | translate }}</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let row of this.rows">
									<td *ngIf="row.openRenewalSW  || row.openRenewalSW === null">{{ row.caseNum }}</td>
									<td *ngIf="row.openRenewalSW">{{ row.renewalDueDate | date: 'MM/dd/yyyy' }}</td>
									<td>
										<button *ngIf="row.openRenewalSW === 'Y' && !row.isRenewSsiEligble && row.hoHID === this.indivId" class="btn btn-block btn-rbsecondary" (click)="renewalWelcomePage(row)">{{'RENEW_MY_COVERAGE' | translate }}</button>
										     <p *ngIf="(row.openRenewalSW === 'N'|| row.openRenewalSW === null) && row.renewalProgress !== 'RI'">{{'RENEW_MY_COVERAGE_STATIC1' | translate }}</p>
										     <p *ngIf="(row.openRenewalSW === 'N'|| row.openRenewalSW === null) && row.renewalProgress === 'RI'">{{'RENEW_MY_COVERAGE_STATIC3' | translate }}</p>
										<p *ngIf="row.openRenewalSW === 'Y' && row.isRenewSsiEligble">{{'RENEW_SSI_ELIGIBLE' | translate }}</p>
										<p *ngIf="row.openRenewalSW === 'Y' && row.hoHID !== this.indivId">{{'RENEW_MY_COVERAGE_STATIC2' | translate }}</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div *ngIf="response">
					<div class="nh-table-mobile d-lg-none">
						<ng-container *ngIf="rows.length">
							<div *ngFor="let row of rows">
								<div *ngIf="row.openRenewalSW" class="table-row mb-3 pb-3">
									<div *ngIf="row.openRenewalSW || row.openRenewalSW === null" class="mobile-table-header">{{'Case' | translate}}</div>
									<hr>
									<div *ngIf="row.openRenewalSW  || row.openRenewalSW === null" class="mobile-table-data">{{row.caseNum}}</div>
									<br>
									<div *ngIf="row.openRenewalSW" class="mobile-table-header">{{'Due Date' | translate }}</div>
									<hr>
									<div *ngIf="row.openRenewalSW" class="mobile-table-data">{{row.renewalDueDate | date: 'MM/dd/yyyy'}}</div>
									<br>
									<div *ngIf="row.openRenewalSW" class="mobile-table-header">{{'Action' | translate }}</div>
								</div>
								<div class="mobile-table-data">
									<button *ngIf="row.openRenewalSW === 'Y' && !row.isRenewSsiEligble && row.hoHID === this.indivId" class="w-100 btn btn-block btn-rbsecondary" (click)="renewalWelcomePage(row)">{{'RENEW_MY_COVERAGE' | translate }}</button>
									<p *ngIf="(row.openRenewalSW === 'N'|| row.openRenewalSW === null) && row.renewalProgress !== 'RI'">{{'RENEW_MY_COVERAGE_STATIC1' | translate }}</p>
									<p *ngIf="(row.openRenewalSW === 'N'|| row.openRenewalSW === null) && row.renewalProgress === 'RI'">{{'RENEW_MY_COVERAGE_STATIC3' | translate }}</p>
									<p *ngIf="row.openRenewalSW === 'Y' && row.isRenewSsiEligble">{{'RENEW_SSI_ELIGIBLE' | translate }}</p>
									<p *ngIf="row.openRenewalSW === 'Y' && row.hoHID !== this.indivId">{{'RENEW_MY_COVERAGE_STATIC2' | translate }}</p>
								</div>
								<br>
								<hr>
							</div>
						</ng-container>
						<p *ngIf="!rows.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
