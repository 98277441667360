import { Pipe, PipeTransform } from '@angular/core';

import { DatePipe } from '@angular/common';
@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: any): string {
    if (typeof value === 'string' && value.includes('-') && !isNaN(Date.parse(value))) 
        return this.datePipe.transform(value, 'MM/dd/yyyy')
    else return value;
  }
}
