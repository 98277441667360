import { Component, Input, OnInit } from '@angular/core';
import { FormArray, Validators, FormGroup } from '@angular/forms';
import { map } from 'ramda';

import { BaseModalDirective } from '@app/components/modals/base-modal';
import { value } from '@app/helpers/value.helpers';
import { VerificationAnswer } from '@app/models/partials.model';

@Component({
  templateUrl: './questionnaire.component.html',
})
export class QuestionnaireModalComponent extends BaseModalDirective implements OnInit {
  @Input() questions;
  @Input() record;
  @Input() saveExit;
  answersForm: FormArray;
  fullName;  

  get answers(): FormArray {
    return this.answersForm as FormArray;
  }

  createAnswer(question): FormGroup {
    return this.builder.group({
      questionId: question.questionId,
      answerId: [null, [Validators.required]],
    });
  }

  createForm(questions) {
    this.answersForm = this.builder.array(map(question => this.createAnswer(question), questions));
  }

  ngOnInit() {
    if (this.record === null) {
      this.saveExit = true;
      this.fullName = sessionStorage.getItem('hohName');
      sessionStorage.removeItem('hohName');
    } else {
      this.fullName = this.record?.data?.name?.fullName;
    }

    this.createForm(this.questions);
  }

  public onConfirm(): void {
    this.hide({ answers: value(this.answersForm), rejectedToAnswer: false } as VerificationAnswer);
  }

  public onCancel(): void {
    this.hide({ answers: [], rejectedToAnswer: true } as VerificationAnswer);
  }
}
