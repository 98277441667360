import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import { isNil, equals } from 'ramda';
import { or } from 'ramda';
import { when } from 'ramda';

import { ApplicationFacade } from '@app/state/app/app.facade';
import { SectionFacade } from '@app/state/section/section.facade';

import { value } from '@app/helpers/value.helpers';
import { resetValue } from '@app/helpers/reset.helpers';
import { controlWith } from '@app/helpers/form.helpers';
import { valueChanges } from '@app/helpers/form-group.helpers';
import { isEMPTY, isYES, topLevelAnswers } from '@app/helpers/types.helpers';

import { Programs } from '@app/models/app.model';
import { TopLevelAnswer } from '@app/models/section.model';
import { isOtherModes } from '@app/helpers/mode.helpers';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nh-top-level',
  templateUrl: './top-level.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopLevelComponent implements OnInit {
  private programs$: Observable<Programs> = this.app.programs$;

  @Input() public answerControl: FormControl;
  @Input() public label: string;
  @Input() public info: string;
  @Input() popupText: string;
  @Input() public readonly mode: string;
  @Input() topLevelHint: string;
  showForm$: Observable<boolean>;
  options$: Observable<TopLevelAnswer[]>;
  options: TopLevelAnswer[];
  isHHTXD: boolean;
  isASCLD: boolean;
  defaultLanguage;
  @Input() topLevelName: string;
  @Input() initialTopLevelAnswer: string;


  constructor(private app: ApplicationFacade, private section: SectionFacade, public translateService: TranslateService) {}

  ngOnInit() {
    this.section.identifier$.subscribe(res => {this.isHHTXD = equals(res, 'HHTXD'); this.isASCLD = equals(res, 'ASCLD')});
    if(!this.isASCLD){   
      when(topLevelAnswer => this.isHHTXD ? false : this.shouldHide(topLevelAnswer), () => this.hide(), value(this.answerControl));
    }
    else{
      //for transfer resources
      when(topLevelAnswer => this.isHHTXD ? false : this.shouldHideTransfer(topLevelAnswer,this.initialTopLevelAnswer), () => this.hide(), value(this.answerControl));
    }

    this.getOptions();
    this.defaultLanguage = this.translateService.getDefaultLang();
    if (this.defaultLanguage !== 'en') {
      this.getSpanishLabels()
    } else {
      this.getEnglishLabels();
    }

    this.translateService.onDefaultLangChange.subscribe((res) => {
      if (res) {
        this.getOptions();
        if (res.lang === 'en') {
          this.getEnglishLabels();
        } else {
          this.getSpanishLabels();
        }
      }
    });

    this.showForm$ = valueChanges(this.answerControl).pipe(
      startWith(value(this.answerControl)),
      tap(topLevelAnswer => this.section.setSection({ topLevelAnswer })),
      map(isYES)
    );
  }

  getOptions() {
    this.options$ = this.programs$.pipe(map(topLevelAnswers));
    this.options$.subscribe(val => {
      if (isOtherModes(this.mode)) {
        this.options = val.filter(v => v['code'] !== 'SKIP');
      } else {
        this.options = val;
      }
    });
  }

  getSpanishLabels() {
    if (this.options) {
      for (let i = 0; this.options.length > i; i++) {
        if (this.options[i]['value'] === 'Yes') {
          this.options.splice(i, 1);
        }
      }
    }
    return this.options;
  }

  getEnglishLabels() {
    if (this.options) {
      for (let i = 0; this.options.length > i; i++) {
        if (this.options[i]['value'] === 'Si') {
          this.options.splice(i, 1);
        }
      }
    }
    return this.options;
  }

  shouldHide(topLevelAnswer) {
    return or(isYES(topLevelAnswer), isNil(this.label));
  }

  hide() {
    resetValue(controlWith(TopLevelAnswer.YES, true), this.answerControl);
  }

  shouldHideTransfer(topLevelAnswer,initialTopLevelAnswer) {
    console.log(topLevelAnswer);
    return or(!isEMPTY(initialTopLevelAnswer), isNil(this.label));
  }

  isYes(topLevelAnswer) {
    return isYES(topLevelAnswer);
  }
}
