import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@app/gateway/services/api.service'

export interface Wonder{
  mcoProviderDetailsResponse: MCOProviderDetailsResponse[];
}

export interface MCOProviderDetailsResponse{
  mcoId: number;
  mcoName: string;
  mcoLogo: string;
  mcoContactNumber: string;
  mcoTty: string;
  mcoUrl: string;
}
@Injectable({
  providedIn: 'root'
})
export class HealthPlanService {
  constructor(public apiService: ApiService) { 
  }

  getHealthPlanProviderDetails(): Observable<Wonder>{
    const url = 'publ/mcoservices/mcoproviderdetails';
    return this.apiService.get(url, true);
  }
}