import { evolve, map, compose } from 'ramda';
import { TaxDeductionsComponent } from './tax-deductions.component';

import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration } from '@app/helpers/models.helpers';

import { lookIn } from '@app/helpers/flipped.helpers';
import { toDate, toISO } from '@app/helpers/date.helpers';


const formValidation = (builder, recordData, {selectedIndividual}) => {
    const taxDeduct = lookIn(recordData);

    const taxDeductionsListCreatorLatest = ({ deductionAmount, deductionType, alimonyOrderDate, otherExpenseName, taxDedSeqNum }) => ({
        indvId: [selectedIndividual ? selectedIndividual.identifier: ''],
        deductionType: [deductionType ? deductionType : null],
        deductionAmount: [deductionAmount, [Vs.numeric]],
        alimonyOrderDate: [alimonyOrderDate ? toDate(alimonyOrderDate) : null],
        otherExpenseName: [otherExpenseName ? otherExpenseName : null],
        taxDedSeqNum: taxDedSeqNum
    });

    let taxDeductionsListDataLatest;
    if (recordData.taxDeductionsIndvData && recordData.taxDeductionsIndvData.length > 0) {
        taxDeductionsListDataLatest = taxDeduct(['taxDeductionsIndvData'])
    } else {
        taxDeductionsListDataLatest = [{
            indvId: selectedIndividual.identifier,
            deductionType: null,
            deductionAmount: null,
            alimonyOrderDate: null,
            otherExpenseName: null,
            taxDedSeqNum: null
        }]
    }


    const taxDeductionsListGroupLatest = compose(
        (control: any) => builder.group(control),
        taxDeductionsListCreatorLatest,
    );

    const taxDeductionsListLatest = map(taxDeductionsListGroupLatest, taxDeductionsListDataLatest);

    const data = builder.group({
        taxDeductions: [taxDeduct(['taxDeductions']), Vs.required],
        taxDeductionsIndvData: builder.array(taxDeductionsListLatest),
    });
    return { data };


}
const postObject = evolve({
    taxDeductionsIndvData: map(
        evolve({
            alimonyOrderDate: toISO,
            deductionAmount: Number
        })
    ),
});

export default sectionConfiguration(TaxDeductionsComponent, formValidation, [], [], {}, { postObject });
