<div class="mb-3">
    <nh-alert type="success" [isOpen]="updateSuccess">
        <div class="message">
            <span>{{ 'AUTO_RENEWAL_SUCCESS_MESSAGE' | translate}}</span>
        </div>
    </nh-alert>
    <div class="bg-custom">
        <div class="container">
            <h5 class="section-header">{{ 'AUTO_RENEWAL_STATIC_HEADER1' | translate}}</h5>
            <hr>
            <h5 class="section-header">{{ 'AUTO_RENEWAL_STATIC_HEADER2' | translate}}</h5>
            <hr>
            <p>{{ 'AUTO_RENEWAL_STATIC_HEADER3' | translate}}</p>
            <form [formGroup]="autoRenewalForm">
                <div *ngIf='caseList.length > 1' class="row mt-3 text-center py-4">
                    <div class="ml-3"><label  class="label-section">{{'CASE_NUMBER' |
                            translate}}</label>
                    </div>
                    <div class="ml-3">
                        <select nh name="caseNumber" style="width: 340px;height: 38px" aria-label="type" [items]="caseNumbers" (change)="onCaseSelect()"
                            formControlName="caseNumber"></select>
                    </div>
                    <div class="ml-3">
                        <button id="select" class="btn btn-secondary btn-search" [disabled]="!enableSelect"
                            (click)="onSelectedCase()">
                            {{'SELECT_APPEAL' | translate }}
                        </button>
                    </div>
                    
                </div>
                <div *ngIf='caseList.length === 1'>
                    <p class="static-text"><b>{{'CASE_NUMBER' | translate}}</b></p>
                    <p>{{this.caseList[0].caseNum}}</p>
                </div>

                <div *ngIf="caseSelected">
                    <p class="static-text"><b><span class="requiredfd">*</span>{{'BEFORE_SUBMIT_STATIC_TEXT24' |
                            translate }}</b> </p>
                    <label class="radio-inline checkbox" for="renewCoverageYesCheck">
                        <input #isrenewcheckYes class="chk-input chk-gap" id="renewCoverageYesCheck" type="checkbox"
                            (change)="isRenewChecked($event, isrenewcheckNo)" name="renewCoverageYesCheck"
                            formControlName="renewCoverageYesCheck" value="Yes">
                        <label class="chk-lbl">{{'Yes' | translate }}</label>
                    </label>

                    <label class="radio-inline checkbox ml-5" for="renewCoverageNoCheck">
                        <input #isrenewcheckNo class="chk-input chk-gap" id="renewCoverageNoCheck" type="checkbox"
                            (change)="isRenewChecked($event, isrenewcheckYes)" name="renewCoverageNoCheck"
                            formControlName="renewCoverageNoCheck" value="No">
                        <label class="chk-lbl">{{'No' | translate }}</label>
                    </label>
                </div>
                <br>
                <nh-field *ngIf='enableRenewEligibility' [isRow]="true" [mandatory]="false"
                    label="{{'AUTO_RENEWAL_STATIC_TEXT1' | translate}}">
                    <select nh name="yearsRenewedCD" aria-label="yearsRenewedCD" [items]="items"
                        (change)="renewEligbilitySelect()" formControlName="yearsRenewedCD"></select>
                </nh-field>
            </form>

            <div class="row mt-3 text-center py-4">
                <div class="ml-3">
                    <button id="continue" class="btn btn-block btn-secondary btn-width" [disabled]="!enableUpdate"
                        (click)="updateAutoRenewal()">
                        {{'COMMUNICATION_UPDATE' | translate }}
                    </button>
                </div>
                <div class="ml-3">
                    <button id="btn-cancel" class="btn btn-block btn-outline-secondary" [disabled]="false"
                        (click)="exitAutoRenewal()">
                        {{'EXIT' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>