import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'nh-co-owner',
  templateUrl: './co-owner-table.component.html'
})
export class CoOwnerTableComponent implements OnInit {

  @Input() jointOwnershipInformation: FormArray;
  @Input() coOwnerIndividuals;
  @Input() formData: FormGroup;
  @Input() addRowValid: boolean;
  @Input() error;
  @Input() coOwnerIndivErrorMessage: string;
  @Output() emitValidate = new EventEmitter<any>();
  @Output() errorMessage = new EventEmitter<any>();
  @Output() removeRow = new EventEmitter<any>();
  @Output() addRow = new EventEmitter<any>();

  ngOnInit () {}

  emitCoOwnerValidate (row, i) {
    this.emitValidate.emit({row: row, index: i});
  }

  emitErrorMessage (row) {
    this.errorMessage.emit(row);
  }

  removeIndex (i) {
    this.removeRow.emit(i);
  }

  emitAdd () {
    this.addRow.emit();
  }
  
}
