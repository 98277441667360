import { Directive, ContentChild, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

import { forEach } from 'ramda';

import { setAttributes } from '@app/helpers/dom.helpers';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'input[formControl], input[formControlName], textarea[formControl], textarea[formControlName]',
})
export class ControlAttributesDirective implements AfterViewInit {
  @ContentChild(NgControl, { static: false })
  field;

  constructor(private renderer: Renderer2, private element: ElementRef) {}

  setAttribute(att, value) {
    this.renderer.setAttribute(this.element.nativeElement, att, value);
  }

  ngAfterViewInit() {
    const setAttribute = name => value => {
      return this.setAttribute(name, value);
    }
    if (this.field) {
      forEach(setAttributes(setAttribute), this.field._rawValidators);
    }
  }
}
