<!-- <nh-alert type="warning" [isOpen]="showAlert()" [dismissible]="true">
  <p> You are choosing to continue without providing a Social Security Number. If you do this your application may take longer to process. 
  </p>
</nh-alert> -->

<nh-alert type="warning" [isOpen]="showAlert()">
  <div class="row">
    <div class="icon">
      <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="danger" />
    </div>
    <div class="message">
      <span>
        {{'SSN_ENTER_MANDATORY' | translate}}
      </span>
    </div>
  </div>
</nh-alert>

<div class="d-none d-sm-block">
  <p *ngIf="isRenewBenefits">{{ "RENEWAL_TEXT" | translate }}</p>
  <h6 class="person-label">{{selectedIndividual | fullname}}</h6>
</div>
<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <section [formGroup]="getControl('data')">
    <nh-field id = "jf_jointFilerOutside" label="{{'IS_1' | translate }}{{selectedIndividual | fullname}} {{'JOIN_FILER_OUTSIDE_STATIC1' | translate }}" class="label-section">
      <select nh name="jointFilerOutside" formControlName="jointFilerOutside" [items]="tableCodeValue('YESNO', 'data.jointFilerOutside')" aria-label="Joint Filer Outside" (change)="jointFilerChange()"></select>
    </nh-field>
    <div *ngIf="isY('data.jointFilerOutside')">
      <h5 class="section-header">{{'JOIN_FILER_OUTSIDE_STATIC2' | translate}}</h5>
      <hr>
      <nh-field id = "jf_name" [mandatory]="true" label="{{'JOIN_FILER_OUTSIDE_STATIC3' | translate}}" class="label-section">
        <input type="text" class="form-control" formControlName="name" name="name" aria-label="Name"  (change)="jointFilerNameChange()">
      </nh-field>

      <nh-field id = "jf_ssn" [mandatory]="false" label="{{'JOIN_FILER_OUTSIDE_STATIC4' | translate}}{{name.value}}{{getSsnLabel(name.value) | translate}}" class="label-section">
        <input type="text" class="form-control" formControlName="ssn" name="ssn"
          placeholder="XXX-XX-XXXX" mask="000-00-0000">
      </nh-field>

      <nh-field id = "jf_birthDate" label="{{'JOIN_FILER_OUTSIDE_STATIC6' | translate}}{{name.value}}{{getDobLabel(name.value) | translate}}" class="label-section" [mandatory]="false">
        <nh-datepicker>
          <input type="text" class="form-control" formControlName="birthDate" [maxDate]="today"
            bsDatepicker nh (change)="isInvalidDate($event)">
        </nh-datepicker>
      </nh-field>

    </div>
  </section>
</nh-form>