import { TaxFilingComponent } from './tax-filing.component';

import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { controlWith } from '@app/helpers/form.helpers';
import { when, isNil } from 'ramda';
import { disableGet } from '@app/helpers/able.helpers';
import { isNo, notYes } from '@app/helpers/tayn.helpers';
import { aPath } from '@app/helpers/function.helpers';


const formValidation = (builder, recordData) => {
  const taxfiling = lookIn(recordData);
  const taxFilingIndividualId = taxfiling(['taxFilingIndividualId']);
  const taxDependents = aPath(['taxDependents'], recordData);
  const jointFiler = taxfiling(['jointFiler']);
  const taxDependent = taxfiling(['taxDependent']);
  const filing = taxfiling(['filing']);
  const filingVal = isNil(filing) ? '' : filing;
  const hadIndvId = isNil(taxFilingIndividualId) ? '' : taxFilingIndividualId.toString();

  const data = builder.group({
    taxFilingIndividualId: [hadIndvId, Vs.required],
    jointFiler: [controlWith(jointFiler, !jointFiler), Vs.required],
    taxDependent: [controlWith(taxDependent, (isNo(filing) || !taxDependent)), Vs.required],
    taxDependents: builder.array(taxDependents, [Vs.required]),
    filing: [filingVal, Vs.required]
  });

  when(notYes, () => disableGet('taxDependents', data), taxDependent);

  return { data };
};



export default sectionConfiguration(TaxFilingComponent, formValidation, [], [], {}, {});
