import { RouterModule, Routes, Route, ExtraOptions } from '@angular/router';

import { AppGuard } from './app.guard';
import { AppResolve } from './app.resolve';
import { SectionGuard } from './section/section.guard';
import { CongratsResolve } from './congrats/congrats.resolve';

import { NotFoundComponent } from './components/not-found/not-found.component';

import { SectionComponent } from './section/section.component';
import { BeforeStartComponent } from './gateway/at-a-glance/before-start-application/before-start-application.component';
import { LogoutComponent } from './providers/logout/logout.component';
import { ErrorPageComponent } from './providers/errorpage/errorpage.component';
import { InvalidSessionComponent } from './providers/invalidSession/invalidSession.component';
import { PeopleComponent } from './section/forms/people/people-details/people.component';
import { TaxComponent } from './section/forms/tax-information/tax-details/tax.component';
import { CongratsComponent } from './congrats/congrats.component';
import { LandingComponent } from './gateway/landing/landing.component';
import { SigninComponent } from "@app/gateway/signin/signin.component";
import { CreateAccountNewComponent } from 'app/gateway/create-account-new/create-account-new.component';
import { RegisterAccountComponent } from 'app/gateway/register-account/register-account.component';
import { AtAGlanceComponent } from 'app/gateway/at-a-glance/at-a-glance.component';
import { CaseApplicationComponent } from 'app/gateway/at-a-glance/case-application/case-application.component';
import { SecuritySettingsComponent } from '@app/gateway/at-a-glance/security-settings/security-settings.component';
import { WelcomeBackComponent } from 'app/gateway/welcome-back/welcome-back.component';
import { ReferenceTableComponent } from './gateway/at-a-glance/Reference-tables/reference-table.component';
import { ForgotPasswordComponent } from 'app/gateway/forgot-password/forgot-password.component'
import { ForgotuserNameComponent } from 'app/gateway/forgotuser-name/forgotuser-name.component'
import { UserProfileComponent } from 'app/gateway/user-profile/user-profile.component'
import { ManageAccountComponent } from 'app/gateway/user-profile/manage-account/manage-account.component'
import { ResetPasswordComponent } from 'app/gateway/reset-password/reset-password.component'
import { ChangeSecurityQuestionsComponent } from 'app/gateway/user-profile/change-security-questions/change-security-questions.component'
import { MyDocumentsComponent } from './gateway/user-profile/my-documents/my-documents.component';
import { DocumentsNeededComponent } from './gateway/user-profile/my-documents/documentsNeeded/documents-needed.component';
import { MySubmissionsComponent } from 'app/gateway/user-profile/my-submissions/my-submissions.component';
import { ChangeEmailComponent } from 'app/gateway/user-profile/change-email/change-email.component';
import { LinkCaseComponent } from 'app/gateway/user-profile/link-case/link-case.component'
import { RegisterAccountValidateComponent } from 'app/gateway/register-account-validate/register-account-validate.component'
import { ChangeEmailValidateComponent } from 'app/gateway/change-email-validate/change-email-validate.component'
import { CoverageDetailsComponent } from './gateway/user-profile/coverage-details/coverage-details.component';
import { CommunicationPreferencesComponent } from './gateway/user-profile/communication-preferences/communication-preferences.component';
import { MyLettersComponent } from './gateway/user-profile/my-letters/my-letters.component';
import { BenefitDashboardComponent } from './gateway/user-profile/benefit-dashboard/benefit-dashboard.component';
import { CaseDocumentsComponent } from './gateway/user-profile/my-documents/case-documents/case-documents.component';
import { UploadedDocumentsComponent } from './gateway/user-profile/my-documents/uploaded-documents/uploaded-documents.component';
import { ChangePasswordComponent } from './gateway/user-profile/change-password/change-password.component'
import { ProviderAtAGlanceComponent } from './providers/at-a-glance/at-a-glance.component';
import { ProviderMemberSearchComponent } from './providers/member-search/member-search.component';
import { SearchComponent } from './providers/search/search.component';
import { FaqComponent } from './gateway/faq/faq.component';
import { ProviderSubmissionSearchComponent } from './providers/submission-search/submission-search.component';
import { CreateAccountComponent } from './gateway/create-account/create-account.component';
import { RenewalBenefitsWelcomeComponent } from './gateway/renewal-benefits-welcome/renewal-benefits-welcome.component';
import { RenewBenefitsComponent } from './gateway/user-profile/renew-benefits/renew-benefits.component';
import { ReportChangeComponent } from './gateway/user-profile/report-change/report-change.component';
import { FinancialWelcomebackComponent } from './providers/financial-welcomeback/financial-welcomeback.component';
import { KeproLandingComponent } from './kepro/kepro-landing/kepro-landing.component';
import { HealthPlanDetailsComponent } from './gateway/user-profile/healthplan-details/healthplan-details.component';
import { MyAppealsComponent } from './gateway/user-profile/my-appeals/my-appeals.component';
import { AppealContinuanceComponent } from './gateway/user-profile/my-appeals/appealContinuance/appeal-continuance.component';
import { AppealWithdrawalComponent } from './gateway/user-profile/my-appeals/appealWithdrawal/appeal-withdrawal.component';
import { AutoRenewalComponent } from './gateway/user-profile/auto-renewal/auto-renewal.component'
import { CoverageSearchComponent } from './providers/coverage-search/coverage-search.component';
import { PretermComponent } from './gateway/user-profile/pre-term/pre-term.component';

const sectionRoute: Route = {
  component: SectionComponent,
  path: `section/:sectionId`,
  pathMatch: 'full',
  canActivate: [SectionGuard],
  canDeactivate: [SectionGuard],
};

const peopleRoute: Route = {
  component: PeopleComponent,
  path: `section/HHIND`,
  data: { sectionId: 'HHIND', title: 'People Summary' },
  canActivate: [SectionGuard],
  canDeactivate: [SectionGuard],
};

const peopleSumRoute: Route = {
  component: PeopleComponent,
  path: `section/HHSUR`,
  data: { sectionId: 'HHSUR', title: 'People Summary' },
  canActivate: [SectionGuard],
  canDeactivate: [SectionGuard],
};

const taxRoute: Route = {
  component: TaxComponent,
  path: `section/HHTJF`,
  data: { sectionId: 'HHTJF', title: 'Tax Summary' },
  canActivate: [SectionGuard],
  canDeactivate: [SectionGuard],
};

const congratsRoute: Route = {
  component: CongratsComponent,
  path: `congrats/:appNumber`,
  data: { title: 'Confirmation' },
  pathMatch: 'full',
  resolve: { congrats: CongratsResolve },
};

const landingRoute: Route = {
  component: LandingComponent,
  path: '',
  children: [],
  data: { title: 'Welcome to TennCare' }
};

const atGlanceRoute: Route = {
  path: 'case',
  component: AtAGlanceComponent,
  children: [{
    path: '',
    pathMatch: 'full',
    redirectTo: 'atglance'
  }, {
    path: 'atglance',
    data: { title: 'At a Glance' },
    component: CaseApplicationComponent
  }, {
    path: 'security-settings',
    data: { title: 'Security Settings' },
    component: SecuritySettingsComponent
  }, {
    path: 'reference-table',
    data: { title: 'Reference Table' },
    component: ReferenceTableComponent
  }]
}

const userProfile: Route = {
  path: 'user-profile',
  component: UserProfileComponent,
  children: [{
    path: '',
    pathMatch: 'full',
    redirectTo: 'benefit-dashboard',
    data : {title: 'Benefit Dashboard'},
  }, {
    path: 'benefit-dashboard',
    data : {title: 'Benefit Dashboard'},
    component: BenefitDashboardComponent
  }, {
    path: 'healthplan-details',
    data : {title: 'Health Plan Benefits'},
    component: HealthPlanDetailsComponent
  },{
    path: 'coverage-details',
    data : {title: 'Coverage Details'},
    component: CoverageDetailsComponent
  }, {
    path: 'case-link',
    data : {title: 'Link a Case'},
    component: LinkCaseComponent
  }, {
    path: 'my-submissions',
    data : {title: 'My Submissions'},
    component: MySubmissionsComponent
  }, {
    path: 'manage-account',
    data : {title: 'Manage my Account'},
    component: ManageAccountComponent
  }, {
    path: 'update-security-questions',
    data : {title: 'Change security Questions'},
    component: ChangeSecurityQuestionsComponent
  }, {
    path: 'report-change',
    data : {title: 'Report a Change'},
    component: ReportChangeComponent
  }, {
    path: 'my-documents',
    data: { title: 'My Documents' },
    component: MyDocumentsComponent,
    children: [{
      path: '',
      pathMatch: 'full',
      redirectTo: 'documents-we-need',
      data : {title: 'Documents we need'},
    }, {
      path: 'documents-we-need',
      data : {title: 'Documents we need'},
      component: DocumentsNeededComponent
    }, {
      path: 'uploaded-documents',
      data : {title: 'Uploaded Documents'},
      component: UploadedDocumentsComponent,
    }, {
      path: 'uploaded-documents/:wpAppNum',
      data : {title: 'Uploaded Documents'},
      component: UploadedDocumentsComponent
    }, {
      path: 'case-documents',
      data : {title: 'Case Documents'},
      component: CaseDocumentsComponent
    }]}, {
    path: 'update-email',
    data : {title: 'Email Update'},
    component: ChangeEmailComponent
  }, {
    path: 'communication-preferences',
    data : {title: 'Communication Preferences'},
    component: CommunicationPreferencesComponent
  }, {
    path: 'my-letters',
    data : {title: 'My letters'},
    component: MyLettersComponent
  }, {
    path: 'change-password',
    data : {title: 'Change Password'},
    component: ChangePasswordComponent
  }, 
  {
    path: 'auto-renewal',
    data : {title: 'Auto Renewal'},
    component: AutoRenewalComponent
  },{
    path: 'renew-benefits',
    data : {title: 'Renew Benefits'},
    component: RenewBenefitsComponent
    },
    {
      path: 'my-appeals',
      data: { title: 'My Appeals' },
      component: MyAppealsComponent
    },
    {
      path: 'appeal-continuance',
      data: { title: 'Appeal Continuance' },
      component: AppealContinuanceComponent
    },
    {
      path: 'appeal-withdrawal',
      data: { title: 'Appeal Withdrawal' },
      component: AppealWithdrawalComponent
    },
    {
      path: 'pre-term',
      data : {title: 'Renew Benefits'},
      component: PretermComponent
      },
  ]
}

const beforeStartRoute: Route = {
  component: BeforeStartComponent,
  data : {title: 'Apply for Assistance'},
  path: `application-start/client`
};

const ppLogoutRoute: Route = {
  component: LogoutComponent,
  data : {title: 'Signout'},
  path: `logout`
};


const mobileLogout: Route = {
  component: LogoutComponent,
  data : {title: 'Signout'},
  path: `mobile-logout`
};

const errorPageRoute: Route = {
  component: ErrorPageComponent,
  data : {title: 'Oops Error'},
  path: `errorpage`
};

const mobileErrorPageRoute: Route = {
  component: ErrorPageComponent,
  data : {title: 'Oops Error'},
  path: `mobile-errorpage`
};

const mobileInvalidSession: Route = {
  component: InvalidSessionComponent,
  data : {title: 'Session Expired'},
  path: `invalidSession`
};

const renewByBenefitWelcome: Route = {
  component: RenewalBenefitsWelcomeComponent,
  data : {title: 'Renew Benefits'},
  path: `renewMyBenefit`
};

const signinRoute: Route = {
  component: SigninComponent,
  data : {title: 'Signin'},
  path: `signin`
};

const resetPassword: Route = {
  component: ResetPasswordComponent,
  data : {title: 'Reset Password'},
  path: `link/passwordLink`
};

const validateRegisterLink: Route = {
  component: RegisterAccountValidateComponent,
  data : {title: 'Register Account'},
  path: `link/registerLink`
};

const validateEmailLink: Route = {
  component: ChangeEmailValidateComponent,
  data : {title: 'Update Email'},
  path: `link/updateEmail`
};

const forgotPassordRoute: Route = {
  component: ForgotPasswordComponent,
  data : {title: 'Forgot Password'},
  path: `forgot-password`
};

const forgotUserNameRoute: Route = {
  component: ForgotuserNameComponent,
  data : {title: 'Forgot Username'},
  path: `forgot-user-name`
};

const createAccount: Route = {
  component: CreateAccountComponent,
  data : {title: 'Create Account'},
  path: `create-account`
};

const registerAccount: Route = {
  component: RegisterAccountComponent,
  data : {title: 'Register Account'},
  path: `register-account`
};

const detailsRoute: Route = {
  path: 'create-account-new/details',
  data : {title: 'Create Account'},
  component: CreateAccountNewComponent,
  children: [ {
    path: '',
    pathMatch: 'full',
    redirectTo: 'profile-details'
  }]
}

const welcomeBackRoute: Route = {
  component: WelcomeBackComponent,
  data: { title: 'Welcome Back' },
  path: `start/welcomeback`
}

const applicationRoute: Route = {
  path: 'application/:appNumber',
  children: [peopleRoute, peopleSumRoute, taxRoute, sectionRoute],
  runGuardsAndResolvers: 'always',
  canActivate: [AppGuard],
  resolve: { redirect: AppResolve }
};

const config: ExtraOptions = {
  onSameUrlNavigation: 'reload',
  useHash: false,
  enableTracing: false,
};

const providerAtAGlanceRoute: Route = {
  component: ProviderAtAGlanceComponent,
  data: { title: 'Providers' },
  path: `atglance`,
};

const partnersRoute: Route = {
  path: 'api/prov',
 children: [
   {
     path: '',
     pathMatch: 'full',
     redirectTo: 'atglance'
   },
   providerAtAGlanceRoute,
   {
    component: ProviderMemberSearchComponent,
    data: { title: 'Provider Search' },
    path: `member-search`,
   },
   {
    component: SearchComponent,
    data: { title: 'Search' },
    path: `search`,
   },
   {
    component: ProviderSubmissionSearchComponent,
    data: { title: 'Submission Search' },
    path: `submission-search`,
   },
   {
    component: FinancialWelcomebackComponent,
    data: { title: 'Financial Welcome Back' },
    path: `welcome-back`,
   },
   {
    component: LinkCaseComponent,
    data: { title: 'Link My Case' },
    path: `case-link`,
   },
   {
     component: CoverageSearchComponent,
     data: { title: 'Apply for Full Coverage' },
     path: `coverage-search`,
   }
 ]
};

const keproRoute: Route = {
  component: KeproLandingComponent,
  data: { title: 'TennCare Call Centre Landing' },
  path: 'api/prov/kepro',
}

const faqRoute: Route = {
  path: 'faq',
  data: { title: 'FAQ' },
  component: FaqComponent
}

const routes: Routes = [
  landingRoute,
  signinRoute,
  detailsRoute,
  applicationRoute,
  partnersRoute,
  keproRoute,
  atGlanceRoute,
  userProfile,
  welcomeBackRoute,
  beforeStartRoute,
  registerAccount,
  forgotPassordRoute,
  forgotUserNameRoute,
  congratsRoute,
  resetPassword,
  validateEmailLink,
  validateRegisterLink,
  faqRoute,
  createAccount,
  renewByBenefitWelcome,
  ppLogoutRoute,
  errorPageRoute,
  mobileErrorPageRoute,
  mobileInvalidSession,
  mobileLogout,
  { path: '**', component: NotFoundComponent },
];

export const AppRouting = RouterModule.forRoot(routes, config);