<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <section [formGroup]="getControl('data')">

    <div *ngIf="isStudentAgeHeader && !isfullTimeEligibleHeader">
      <h5 class="section-header">
        {{'SCHOOL' | translate}}
      </h5>
      <hr>
    </div>
    <div *ngIf="isfullTimeEligibleHeader && isisWorkFullTimeHeader && isStudentAgeHeader">
      <h5 class="section-header">
        {{'SCHOOL_EMPLOYMENT' | translate}}
      </h5>
      <hr>
    </div>
    <div *ngIf="isisWorkFullTimeHeader && !isStudentAgeHeader">
      <h5 class="section-header">
        {{'EMPLOYMENT' | translate}}
      </h5>
      <hr>
    </div>

  <ng-container formArrayName="indvData">
    <div class="details-container mb-2">

      <ng-container *ngFor="let row of schoolAndEmploymentData.controls; index as i;" [formGroupName]="i">

        <section *ngIf="isStudentAge[i]" [formGroup]="row['controls']['education']">
          <nh-field  label="{{'IS_1' | translate}}{{ row.value.name }} {{'ENROLLED_SCHOOL' | translate}}"  class="label-section">
            <nh-popover class="pl-1" content="{{'ENROLLED_SCHOOL_STATIC_TEXT' | translate}}">
            </nh-popover>
            <select nh name="currentEnrollmentStatus" formControlName="currentEnrollmentStatus"
              aria-label="currentEnrollmentStatus"
              [items]="tables[getEnrollmentStatus(getControl('data.indvData'), i)]"
              ></select>
          </nh-field>
        </section>

        <section>
          <nh-field *ngIf="fullTimeEligible[i] && isWorkFullTime[i]"  [mandatory]="workingFullTime[i]"
            label="{{'DOES_2' | translate}}{{ row.value.name }} {{'FULL_TIME' | translate}}"
            class="label-section">
            <select nh name="isWorkingFullTime" aria-label="isWorkingFullTime" formControlName="isWorkingFullTime"
              [items]="tables[getWorkFullTime(getControl('data.indvData'), i)]"></select>
          </nh-field>
        </section> 
          
      </ng-container>
    </div>

  </ng-container>

   </section>
</nh-form>