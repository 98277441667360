import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { formControl } from '@app/helpers/form-control.helpers';
import { BaseModalDirective } from '../base-modal';
import Vs from '@app/services/validators/validators.service';

@Component({
  selector: 'nh-coverage-tutorial-modal',
  templateUrl: './coverage-tutorial-modal.component.html',
  styleUrls: ['./coverage-tutorial-modal.component.scss']
})
export class CoverageTutorialModalComponent extends BaseModalDirective implements OnInit {
  commentsControl: FormControl;  
  @Output() showTutorial: EventEmitter<string> = new EventEmitter();
  takeTour: string = "takeTour";
  
  ngOnInit() {
    this.commentsControl = formControl(this.body, Vs.comments);
  }

  onStart(): void {
    this.hide('');
    this.showTutorial.emit(this.takeTour);  
  }

  onCancel(): void{
    sessionStorage.setItem('closeTutorial', 'true');
    this.hide('');
  }
}