<nh-form [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <section [formGroup]="getControl('data')">
    <div class="container start-page">
      <div>
        <h5 class="section-header">Member Information</h5>
        <hr>
        <div class="d-flex align-items-center">
          <h6 class="person-label">Person Responsible for the Bill: </h6><span class="pl-2 pb-1">{{ name }}</span>
        </div><br>
        <div class="d-flex align-items-center">
          <h6 class="person-label">Client ID: </h6><span class="pl-2 pb-1">{{clientId}}</span>
        </div><br>
        <div class="d-flex align-items-center">
          <h6 class="person-label">Case Number: </h6><span class="pl-2 pb-1">{{caseNumber}}</span>
        </div><br>
        <p>Below are the incurred medical expenses that you have submitted in the past 3 months for this member.</p>
        <h5 class="section-header">Existing Medical Expenses</h5>
        <hr>
        <ngx-datatable *ngIf="rows.length" class='bootstrap striped' #table [rows]='rows' [limit]="5"
        [columnMode]='"force"' [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
        <ngx-datatable-column  [name]="col.name | translate" [prop]="col.prop"
          *ngFor="let col of columnsA">
        </ngx-datatable-column>
        <ngx-datatable-column  [width]="460">
          <ng-template let-column="column" ngx-datatable-header-template>Frequency</ng-template>
          <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
              <div *ngIf="row && row.payFrequency.value !== null">
                  <span>{{row.payFrequency.value}}</span>
              </div>
          </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column  [name]="col.name | translate" [prop]="col.prop"
      *ngFor="let col of columnsB">
    </ngx-datatable-column>
      </ngx-datatable>
      <div class="pull-right" *ngIf="rows && rows.length">
        <p>Results Shown {{rows.length}}</p>
      </div>
      <p *ngIf="!rows.length">This member does not have active incurred medical expenses. Click the <strong>Add Incurred Medical Expense</strong> hyperlink below to add an incurred medical expense.</p>
      <div class="pt-1 hover-red text-left">
        <button class="btn btn-link btn-lg hover-red" style="padding-left: 0px" (click)="addIME()">
          <span class="pr-2">Add Incurred Medical Expense</span>
          <nh-add-svg></nh-add-svg>
        </button>
      </div>
      </div>
    </div>
  </section>
</nh-form>
