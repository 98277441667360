import { Questions } from './partials.model';
import { RecordUpdate } from './state.model';

export enum PayloadTypes {
  NONE = 'NONE',
  PARTIAL_APPLICATION = 'PARTIAL_APPLICATION',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  INFO_MESSAGES = 'INFO_MESSAGES',
  VERIFY_INPUT = 'VERIFY_INPUT',
}

type PayloadType = keyof typeof PayloadTypes;

export enum ContentIds {
  NONE = 'NONE',
  DATA_DISCREPANCY = 'DATA_DISCREPANCY',
  CITIZENSHIP_DISCREPANCY = 'CITIZENSHIP_DISCREPANCY',
  DEATH_CONFIRMATION = ' DEATH_CONFIRMATION',
}

type ContentId = keyof typeof ContentIds;

interface Message {
  id: ContentId;
  text: string;
}

export type Messages = Message[];

type PayloadContent = Messages | Questions | RecordUpdate | null;

interface PayloadInterface {
  type: PayloadType;
  content: PayloadContent;
}

export abstract class Payload implements PayloadInterface {
  type: PayloadType;
  protected constructor(public content: PayloadContent) {}
}

export class PartialApplication extends Payload {
  readonly type = PayloadTypes.PARTIAL_APPLICATION;
  constructor(public content: RecordUpdate) {
    super(content);
  }
}

export class None extends Payload {
  readonly type = PayloadTypes.NONE;
  constructor() {
    super(null);
  }
}
