import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Select } from '@ngxs/store';

import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { map } from 'ramda';
import { when } from 'ramda';

import { isTrue } from 'ramda-adjunct';

import { BaseModalDirective } from '@app/components/modals/base-modal';
import { get } from '@app/helpers/form.helpers';
import { isYes } from '@app/helpers/tayn.helpers';
import { assignAs } from '@app/helpers/function.helpers';
import { deathDate } from '@app/helpers/payload.helpers';
import { swapControl } from '@app/helpers/forms.helpers';
import { valueChanges } from '@app/helpers/form-group.helpers';
import { SectionState } from '@app/state/section/section.state';
import { requiredControl } from '@app/helpers/form-control.helpers';

import { Messages } from '@app/models/payload.model';

@Component({
  templateUrl: './info-messages.component.html',
})
export class InfoMessagesModalComponent extends BaseModalDirective implements OnInit, AfterContentInit {
  @Select(SectionState.tables) public tables$: Observable<any>;
  @Input() individualName: string;
  @Input() messages: Messages;
  deathForm: FormGroup = this.builder.group({});
  TAYN;

  DEATH_CONFIRMATION = false;
  DATA_DISCREPANCY = false;
  CITIZENSHIP_DISCREPANCY = false;

  ngOnInit() {
    this.tables$.pipe(first()).subscribe(({ TAYN }) => assignAs(this, 'TAYN', TAYN));
  }

  ngAfterContentInit() {
    map(({ id }) => assignAs(this, id, true), this.messages);
    when(isTrue, () => this.createForm(), this.DEATH_CONFIRMATION);
  }

  createForm() {
    this.deathForm = this.builder.group({
      passedAway: requiredControl(null, false),
      deathDate: requiredControl(null, true),
    });

    const passedAwayField = get('passedAway', this.deathForm);
    const deathDateField = get('deathDate', this.deathForm);

    valueChanges(passedAwayField).subscribe(swapControl(deathDateField, isYes));
  }

  public onConfirm(): void {
    this.hide(deathDate(this.deathForm));
  }
}
