import { addDays, addMonths, addYears, endOfMonth, format, isAfter, isBefore, isWithinInterval, parseISO, subDays, subMonths, subYears } from 'date-fns';
import { compose, converge, curryN, evolve, flip, invoker } from 'ramda';
import { dashSplit } from './function.helpers';
import { ifNun } from './null.helpers';



const dateValue = v => new Date(v);
const dateInts = (y, l, d, h, m, s) => new Date(y, l, d, h, m, s);

export const before1Year = s => isAfter(s, subYears(new Date(), 1));
export const beforeSecondMonth = s => isBefore(s, endOfMonth(addMonths(new Date(), 1)));
export const withInNineMonths = s => isBefore(s, addMonths(new Date(), 9));
export const before30Days = s => isAfter(s, subDays(new Date(), 30));
export const before60Days = s => isAfter(s, subDays(new Date(), 60));
export const before90Days = s => isAfter(new Date(s.getFullYear(), s.getMonth(), 1), subMonths(new Date(), 4));
export const before5Months = s => isAfter(s, subMonths(new Date(), 5));
export const before120Years = s => isAfter(s, subYears(new Date(), 120));
export const after10Months = s => isBefore(s, addMonths(new Date(), 10));
export const vehicleYearBetween = s => isWithinInterval(new Date(s), { start: new Date(1899, 0, 1), end: addYears(new Date(), 1) });
export const invalidFutureDays = s => isBefore(s, subDays(new Date(), 0));
export const invalidTodayDate = s => isAfter(new Date(s), addDays(new Date(), 0));

export const minus1Year = (date = new Date()) => addDays(subYears(date, 1), 1);
export const minus16Years = (date = new Date()) => addDays(subYears(date, 16), 0);
export const after10Years = (date = new Date()) => addDays(addYears(date, 10), 1);
//date validation must be from 1900 year
export const minus120Years =()=> new Date(1900, 0, 1);
export const minus30Days = (date = new Date()) => subDays(date, 29);
export const minus9Days = (date = new Date()) => subDays(date, 8);
export const minus60Days = (date = new Date()) => subDays(date, 60);
export const minus3Months1stDay = (date = new Date()) => new Date(date.getFullYear(), date.getMonth() - 3, 1);
export const add10Months = (date = new Date()) => addMonths(date, 10);
export const minus5Months = (date = new Date()) => subMonths(date, 5);
export const minus12Months = (date = new Date()) => subMonths(date, 12);
export const minus3Months = (date = new Date()) => subMonths(date, 3);
export const add10Days = (date = new Date()) => addDays(date, 10);
export const afterBirthDay = (date = new Date()) => addDays(date, 0);
export const afterSecondMonth = (date = new Date()) => endOfMonth(addMonths(date, 1));

const dateFormat = flip(curryN(2, format));
export const toISO = ifNun(dateFormat('yyyy-MM-dd'));
export const toMiddleEndian = ifNun(dateFormat('MM/dd/yyyy'));
export const toDate = ifNun(parseISO);
export const toShortDate = ifNun(dateFormat('yyyy-MM-dd'));

export const fromDates = evolve({
  beginDate: toDate,
  endDate: toDate,
});

export const toDates = evolve({
  beginDate: toISO,
  endDate: toISO,
});

const timeFormat = dateFormat('hh:mm A');

const toFulldate = (utc, hours, minutes, seconds) => {
  const [year, month, day] = dashSplit(utc);
  return dateInts(year, month, day, hours, minutes, seconds);
};

const getUTCFullYear = invoker(0, 'getUTCFullYear');
const getUTCMonth = invoker(0, 'getUTCMonth');
const getUTCDate = invoker(0, 'getUTCDate');
const getUTCHours = invoker(0, 'getUTCHours');
const getUTCMinutes = invoker(0, 'getUTCMinutes');
const getUTCSeconds = invoker(0, 'getUTCSeconds');

const dateUTC = converge(Date.UTC, [
  getUTCFullYear,
  getUTCMonth,
  getUTCDate,
  getUTCHours,
  getUTCMinutes,
  getUTCSeconds,
]);

const toUTC = compose(
  dateUTC,
  dateValue
);

export const timeUTC = compose(
  timeFormat,
  toUTC,
  toFulldate
);
