import { FormGroup } from '@angular/forms';

import { o } from 'ramda';
import { lt } from 'ramda';
import { map } from 'ramda';
import { any } from 'ramda';
import { both } from 'ramda';
import { when } from 'ramda';
import { curry } from 'ramda';
import { isNil } from 'ramda';
import { objOf } from 'ramda';
import { propEq } from 'ramda';
import { invoker } from 'ramda';
import { ifElse } from 'ramda';
import { anyPass } from 'ramda';
import { isEmpty } from 'ramda';
import { identity } from 'ramda';
import { propSatisfies } from 'ramda';

import { stubNull } from 'ramda-adjunct';

import { get } from './form.helpers';
import { value } from './value.helpers';
import { valueAs } from './flipped.helpers';
import { nunArgs } from './function.helpers';
import { setErrors } from './form-control.helpers';

const validationError = valueAs(true);
const compareNunValues = (comparator, extractor) => ifElse(nunArgs, stubNull, extractor(comparator));

const emptyOrValid = validator => anyPass([isNil, isEmpty, validator]);
const controlValid = validator => o(emptyOrValid(validator), value);
export const validatorError = (validator: Function, errorKey: string) =>
  ifElse(controlValid(validator), stubNull, () => validationError(errorKey));

export const setValidationError = curry((control, errorKey) => setErrors(validationError(errorKey), control));

const compareValues = (firstControl, secondControl, comparator, errorKey, extractor) =>
  when(
    () => compareNunValues(comparator, extractor)(firstControl, secondControl),
    () => objOf(errorKey, { firstControl: value(firstControl), secondControl: value(secondControl) }),
    null
  );

export const compareControls = curry(
  (
    comparator: (a: any, b: any) => boolean,
    errorKey: string,
    extractor: (a: any) => any,
    firstControl: string,
    secondControl: string,
    group: FormGroup
  ) => compareValues(get(firstControl, group), get(secondControl, group), comparator, errorKey, extractor)
);

export const hoursbyPeriod = (period, max) => both(propEq('period', period), propSatisfies(lt(max), 'hours'));

const hasError = invoker(1, 'hasError');

export const hasDateErrors = field =>
  any(
    identity,
    map(e => hasError(e, field), [
      'INVALID_DATE',
      'DATE_NOT_BEFORE_TODAY',
      'DATE_DUE',
      'DATE_NOT_AFTER_MONTH',
      'DATE_START_AFTER_END',
      'DATE_NOT_BEFORE_30_DAYS',
      'DATE_NOT_BEFORE_90_DAYS',
      'DATE_NOT_BEFORE_120_YEARS',
      'INVALID_FUTURE_DATE',
      'BABY_DATE_NOT_GREATER_THAN_1_YEAR'
    ])
  );
