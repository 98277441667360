import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, DoCheck, HostListener } from '@angular/core';
import { ApiService } from '@app/gateway/services/api.service';
import { UserProfileService } from '@app/gateway/user-profile/user-profile.service';
import { ModalService } from '@app/services/modal/modal.service';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nh-proof-upload',
  templateUrl: './proof-upload.component.html',
  styleUrls: ['./proof-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProofUploadComponent implements OnInit, DoCheck {
  @Input() appTrackingNum;
  @Input() kbDataList;
  @Input() bccCase;
  @Input() caseNumber;
  @Output() docUploadSuccess = new EventEmitter<any>();
  rows;
  data = [];
  documents = [{ docType: null, show: true, file: null, error: false }];
  docType = null;
  proofType = null;
  referenceData;
  options;
  code;
  error = true;
  showAnother = false;
  showCZPRmessage = false;
  docOptions = [];
  appTrackNum;
  userId;
  kbDocument;
  refId = null;
  proofTypeError = false;

  finalTableDispalyed: any;
  columns = [{
    name: 'Name', prop: 'fullName'
  },
  { name: 'Case', prop: 'caseNum' },
  { name: 'Information Needed', prop: 'verfDesc' },
  { name: 'Due Date', prop: 'verfDue' }]

  houseHoldColumns = [
    { name: 'Head of Household', prop: 'hohName' },
    { name: 'Submission Type', prop: 'type' },
    { name: 'Date of Submission', prop: 'submissionDate' }
  ];

  kbColumns = [
    { name: 'Name', prop: 'fullName' },
    { name: 'Ref Id', prop: 'refId' }
  ]
  householdRows;
  appealRows;
  response;
  kbRows;
  screenHeight: number;
  screenWidth: number;
  isMobileView = false;
  mobileWidth = 480;
  defaultLanguage;
  translatedLang;
  appealsUpload;
  appealOption;
  
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander() {
    if (sessionStorage.getItem('fromMyAppeals') === 'true') {
      sessionStorage.setItem('myAppealsUploadProof', 'true');

    }
  }

  constructor(private apiService: ApiService, private userProfileService: UserProfileService, public translateService: TranslateService,
    private modalService: ModalService) {
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  ngDoCheck() {
    this.isMobileView = this.screenWidth <= this.mobileWidth;
  }



  addAnother() {
    this.documents.push({
      docType: null,
      show: true,
      file: null,
      error: false,
    });
    this.addDocbuttonVisibility();
  }

  ngOnInit() {
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
    });
    this.appTrackNum = this.appTrackingNum.includes(':')? this.appTrackingNum?.split(':')[1] : this.appTrackingNum;
    if (!this.bccCase) {
      const url = `publ/reference-tables/retrieve?table=TTOP`;
    this.apiService.get(url).subscribe((res) => {
      this.options = this.kbDataList ? [...res.columnDetails] : res.columnDetails.filter(col => col.code !== 'KBPR');
      this.options = this.options?.filter(col => col.code !== 'VFPR' && col.code !== 'APPR');
      if (sessionStorage.getItem('myAppealsUploadProof') === 'true') {
        this.appealsUpload = true;
        this.appealOption = {'code': "VFPR", 'enDescription': "Appeal Proof Documents", 'rowid': "1", 'spDescription': "Documentos de comprobación para apelación"}
        this.proofType = this.appealOption;
        this.changeProofType();
        sessionStorage.removeItem('myAppealsUploadProof');
        sessionStorage.setItem('fromMyAppeals', 'true');
      }
    });
  } else {
    const url = `publ/reference-tables/retrieve?table=TTOV`;
    this.apiService.get(url).subscribe((res) => {
      this.options = res.columnDetails.filter(col => col.code === 'BCPR');
    });
  }
    this.getCmbData();
  }
  getCmbData() {
    const indivId = sessionStorage.getItem('individualId');
    this.userProfileService.getDocumentsWeNeed(indivId).pipe(mergeMap((res: any): Observable<any> => {
      this.response = { ...res }
      const url = 'publ/reference-tables?tables=TVDC';
      return this.apiService.get(url);
    })).subscribe((res) => {
      let tableDisplayed = [];
      this.referenceData = [...res.referenceGroup[0].referenceData];
      this.response['cmbCaseIndvData'].forEach((item) => {
        if (item.verfCd)
          tableDisplayed.push(res.referenceGroup[0].referenceData.filter(tableData => tableData.code == item.verfCd)[0].value);
      })
      this.finalTableDispalyed = tableDisplayed.filter((c, index) => {
        return tableDisplayed.indexOf(c) === index;
      });
    });

  }
  showProofTypes() {
    this.modalService.openTypesOfProofs(this.finalTableDispalyed);
  }

  changeProofType() {
    if (this.proofType) {
      this.kbDocument = this.proofType.code === 'KBPR' ? true : false;
      const url = `publ/reference-tables/retrieve?table=${this.proofType.code}`;
      this.apiService.get(url).subscribe((res) => {
        this.docOptions = [...res.columnDetails];
      });
    } else {
      this.proofTypeError = true;
      this.addDocbuttonVisibility();
    }
  }
  selectedOption(i) {
    this.documents[i].error = !this.documents[i].docType;
    if (this.documents[i].docType !== null) {
      this.setCZPRmessage(i);
    }
    this.addDocbuttonVisibility();
  }

  setCZPRmessage(i) {
    const vals = [...this.documents];
    const proofType = vals[i].docType ?.code;
    if (vals.length === 1 && this.proofType.code === 'CZPR') {
      this.showCZPRmessage = ["BC", "UPP", "US", "CC"].includes(proofType);
    } else {
      this.showCZPRmessage = false;
    }
  }

  removeItem(i) {
    this.modalService.confirmDelete().subscribe((res) => {
      if (res) {
        this.documents[i].file = null;
        this.documents[i].show = true;
        this.addDocbuttonVisibility();
      }
    })
  };

  close(i: any) {
    this.documents.splice(i, 1);
    this.addDocbuttonVisibility();
  };
  trackByIdx(index: number): any {
    return index;
  }

  getFileDetails(fileData, i) {
    if (fileData.res !== null) {
      this.documents[i].show = false;
      this.documents[i].file = { ...fileData.fileRes.file, ...{ res: fileData.res } };
      this.addDocbuttonVisibility();
      this.setCZPRmessage(i);
      //this.error = false;
    }
  }
  addDocbuttonVisibility() {
    this.error = this.documents.some(doc => doc.file == null || doc.docType == null);
  }

  viewPdf(i) {
    const docId = this.documents[i].file.res.replace(/[{}]/g, "");
    this.userProfileService.getPrintDoc(docId).subscribe((res: any) => {
      let newVariable: any;
      newVariable = window.navigator;
      const file = new Blob([res], { type: 'application/pdf' });
      if (newVariable && newVariable.msSaveOrOpenBlob) {
        newVariable.msSaveOrOpenBlob(file);
      } else {
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    })
  }

  submitDocuments() {
    const submitUrl = `indi/neededDocuments/submitDocuments`;
    const userType = sessionStorage.getItem('userType');
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.userId = userType && userType === 'RP' ? sessionStorage.getItem('userName') : user.userId;
    let indiv;
    if (this.proofType.code === 'KBPR') {
      indiv = this.refId?.kbIndvNum;
    } else {
      indiv = sessionStorage.getItem('individualId');
    }
    const types = this.documents.map(doc => {
      return {
        "documentType": { code: this.proofType.code, value: this.proofType.enDescription },
        "documentSubType": { code: doc.docType.code, value: doc.docType.enDescription },
        "documentId": doc.file.res,
        "indvId": indiv
      }
    })
    const uploadData = {
      refId: this.refId ? this.refId.kbReferralId : null,
      tNumber: this.appTrackingNum.includes(':')? this.appTrackingNum?.split(':')[1].trimStart() :  this.appTrackingNum,
      userId: this.userId,
      typeOfDocument: [...types],
      caseNumber: this.caseNumber
    }

    this.apiService.post(submitUrl, uploadData).subscribe(() => {
      this.docUploadSuccess.emit({ success: true })
    })
  }
}
