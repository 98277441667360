<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title pull-left" id="my-modal-title">{{ 'HEALTH_PLAN_POPUP_INFO_LINE5' | translate }}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon" />
    </button>
  </div>
  <div class="modal-body">
    <p class="pb-2 pt-2">{{'HEALTH_PLAN_POPUP_INFO_LINE1' | translate: {date: healthPlanDetails.requestSubmittedDate |
      translate} }}</p>
    <p class="plan_info">{{'HEALTH_PLAN_POPUP_INFO_LINE2' | translate: {healthPlan: healthPlanDetails.currentHealthPlan,
      newHealthPlan: healthPlanDetails.futureHealthPlan | translate} }}
      <br>{{'HEALTH_PLAN_POPUP_INFO_LINE3' | translate }}
    </p>
    <p [innerHTML]="'HEALTH_PLAN_POPUP_INFO_LINE4' | translate" class="pb-3 pt-3"></p>
  </div>
</div>