<p *ngIf="isRmbRmc">{{'OTHER_COVERAGE_STATICTEXT14' | translate }}</p>
<h6 class="person-label">{{ selectedIndividual | fullname }}</h6>

<nh-form [topLevelName] = "'otex'" [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" class="label-section"
  topLevelQuestion="{{'OTHER_COVERAGE_STATICTEXT2' | translate }}"
  [individualQuestion]="'OTHER_COVERAGE_RMB_SUMMARY_STATIC1' | translate"
  (validate)="onValidate($event)">

  <section [formGroup]="getControl('data')">
    <h5 class="section-header">{{'OTHER_COVERAGE_STATICTEXT1' | translate }}</h5><hr>
    <p>{{'OTHER_COVERAGE_STATICTEXT4' | translate }}</p>

    <nh-field id = "otex_insuranceCompanyName" [mandatory]="false" label="{{'NAME' | translate }}" class="label-section">
      <input type="text" name="insuranceCompanyName" aria-label="insuranceCompanyName" formControlName="insuranceCompanyName" class="form-control">
    </nh-field>

    <nh-field id = "otex_phoneNumber" [mandatory]="false" label="{{'PHONE_NUMBER1' | translate }}" class="label-section">
      <input type="text" name="phoneNumber" formControlName="phoneNumber" class="form-control" mask="000-000-0000"
        placeholder="XXX-XXX-XXXX">
    </nh-field>

    <nh-field id = "otex_emailId" [mandatory]="false" label="{{'EMAIL_ADDRESS' | translate }}" class="label-section">
      <input type="email" name="emailId" aria-label="email" class="form-control" formControlName="emailId">
    </nh-field>

    <nh-field id = "otex_minRequirementMetSw" [mandatory]="false" label="{{'OTHER_COVERAGE_STATICTEXT5' | translate }}" class="label-section">
      <nh-popover class="pl-1" [content]="'OTHER_COVERAGE_STATICTEXT6' | translate"></nh-popover>
      <select nh name="minRequirementMetSw" aria-label="minRequirementMetSw" [items]="tableCodeValue('YESNO', 'data.minRequirementMetSw')" formControlName="minRequirementMetSw"></select>
    </nh-field>

    <nh-field id = "otex_policyPremiumAmount" label="{{'OTHER_COVERAGE_STATICTEXT7' | translate }}" class="label-section">
      <nh-popover class="pl-1" [content]="'OTHER_COVERAGE_STATICTEXT10' | translate"></nh-popover>
      <nh-input-group prepend="$">
        <input nh type="decimal"  maxlength="7" name="policyPremiumAmount" formControlName="policyPremiumAmount" class="form-control">
            </nh-input-group>
        </nh-field>

    <nh-field id = "otex_premiumFreqCd" label="{{'OTHER_COVERAGE_STATICTEXT9' | translate }}" class="label-section">
      <select nh name="premiumFreqCd" aria-label="premiumFreqCd" [items]="tableCodeValue('INCEXPPAYFREQUENCY', 'data.premiumFreqCd')" formControlName="premiumFreqCd"></select>
    </nh-field>

    <nh-field id = "otex_cobraSw" [mandatory]="false" label="{{'OTHER_COVERAGE_STATICTEXT8' | translate }}" class="label-section">
      <nh-popover class="pl-1" [content]="'OTHER_COVERAGE_STATICTEXT11' | translate"></nh-popover>
      <select nh name="cobraSw" aria-label="cobraSw" [items]="tableCodeValue('YESNO', 'data.cobraSw')" formControlName="cobraSw"></select>
    </nh-field>

    <nh-field id = "otex_retireeSw" [mandatory]="false" label="{{'OTHER_COVERAGE_STATICTEXT12' | translate }}" class="label-section">
      <nh-popover class="pl-1" [content]="'OTHER_COVERAGE_STATICTEXT13' | translate"></nh-popover>
      <select nh name="retireeSw" aria-label="retireeSw" [items]="tableCodeValue('YESNO', 'data.retireeSw')" formControlName="retireeSw"></select>
    </nh-field>

  </section>

</nh-form>
