<div class="container">
    <div class="row">
        <div class="col-11">
            <h1 class="page-title">{{'CREATE_ACCOUNT_TITLE' | translate}}</h1>
        </div>
    </div>
    <div class="row pl-1 pb-1 hover-red">
        <div class="btn btn-link hover-red" (click)="onPrevious()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
            <g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
              <path id="Combined_Shape" data-name="Combined Shape"
                d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
                transform="translate(0)" fill="#174a7c" />
            </g>
          </svg>
          <span class="pl-2">{{'BACK_TO_PREVIOUS_PAGE' | translate}}</span>
        </div>
      </div>
    <div class="card p-2">
        <div class="card-body">
            <div class="row">
                <div class="col-11">
                    <h5 class="section-header">{{'CREATE_ACCOUNT_SECTION_HEADER' | translate}}</h5>
                    <hr>
                    <p>{{'CREATE_ACCOUNT_LABEL' | translate}}<strong>{{'CREATE_ACCOUNT_SIGNIN' | translate}}</strong>.
                    </p>
                </div>
            </div>
            <br>
            <div id="accordion">
                <div class="row" *ngFor="let item of data ; index as i" (click)="item.show = !item.show">
                    <div class="col-11">
                        <div class="section-header">
                            <div aria-hidden="true" data-toggle="collapse" attr.data-target="#collapse{{i}}" aria-expanded="true"
                                aria-controls="collapseOne">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>{{item.key | translate}}</div>
                                    <div class="ml-3 item-key-symbol" *ngIf="item.show"><i class="fa fa-angle-up"></i>
                                    </div>
                                    <div class="ml-3 item-key-symbol" *ngIf="!item.show"><i
                                            class="fa fa-angle-down"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div id="collapse{{i}}" [ngClass]="{'show': item.show}" class="collapse"
                            aria-labelledby="headingOne" data-parent="#accordion" onClick="event.stopPropagation();">
                            <div [innerHtml]="item.value | translate">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br>
            <div class="row">
                <div class="col-11">
                    <h6><strong>{{'CREATE_ACCOUNT_TITLE' | translate}}</strong></h6>
                    <p>{{'CREATE_ACCOUNT_LABEL_MSG' | translate}}<strong>{{'CREATE_ACC_LABEL' |
                            translate}}</strong>.{{'CREATE_ACCOUNT_LABEL_MSG_1' | translate}}</p>
                    <p>{{'CREATE_ACCOUNT_LABEL_MSG_2' | translate}}</p>
                    <p>{{'CREATE_ACCOUNT_LABEL_MSG_3' | translate}}<strong>{{'CREATE_ACC_LABEL' |
                            translate}}</strong>{{'CREATE_ACCOUNT_LABEL_MSG_3_1' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row mb-2 ml-1 mr-1 pb-3">
        <button [routerLink]="['/register-account']" class="btn col-md-3 btn-secondary btn-block">{{'CREATE_ACC' |
            translate}}</button>
    </div>
</div>