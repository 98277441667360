import { Component } from '@angular/core';
import { BaseModalDirective } from '@app/components/modals/base-modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { HealthPlanService, Wonder } from './health-plan.service';
import { formatDate } from '@angular/common';
import { ServiceConfigService } from '@app/services/service-config.service';

@Component({
  templateUrl: './health-plans.component.html',
  styleUrls: ['./health-plans.component.scss']
})
export class HealthPlansComponent extends BaseModalDirective {
  currentDate = formatDate(new Date(), 'MM/dd/yyyy', 'en');
 
  constructor(protected modalRef: BsModalRef, 
    protected builder: FormBuilder,
   private healthPlanService: HealthPlanService, 
   private readonly serviceConfigService: ServiceConfigService
   ) {
    super(modalRef, builder);
    this.healthPlanService.getHealthPlanProviderDetails().subscribe((w) => {
      const fDate = new Date(this.serviceConfigService.getServerConfig().wellpointEffDate);
      const cDate = new Date(this.currentDate);
      if (cDate >= fDate) {
        w.mcoProviderDetailsResponse = w.mcoProviderDetailsResponse.filter(item => item.mcoName !== 'Amerigroup');
      } else {
        w.mcoProviderDetailsResponse = w.mcoProviderDetailsResponse.filter(item => item.mcoName !== 'Wellpoint');
      }
      this.wonder$.next(w);
    });
}

wonder$ = new BehaviorSubject<Wonder>({mcoProviderDetailsResponse: []});
}
