import { Component, EventEmitter, Input, Output, OnChanges} from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from '@app/gateway/user-profile/user-profile.service';
import { ApplicationService } from '@app/services/application/application.service';
import { ModalService } from '@app/services/modal/modal.service';
import { StateService } from '@app/services/state/state.service';

@Component({
    selector: 'nh-submission-search-results',
    templateUrl: './submission-search-results.component.html',
    styleUrls: ['./submission-search-results.component.scss']
})

export class ProviderSubmissionSearchResultsComponent implements OnChanges {
	@Input() results!: any[];
	@Input() submissionType: any;
	@Output() reload =  new EventEmitter<any>();
	flagStatus: boolean = false;

	constructor (private router: Router, private userService: UserProfileService,
		 private stateService: StateService, private app: ApplicationService, private modalService: ModalService) {}

	ngOnChanges() {
			const resultsAdf = this.results.filter(s => s.submissionType.code.includes('ADF'));
			const resultsAdfWp = resultsAdf.filter(s => !s.wpAppNum);
			const resultsNbn = this.results.filter(s => s.submissionType.code.includes('NBN'));
			const resultsNbnWp = resultsNbn.filter(s => !s.wpAppNum);
			for (let i= 0; resultsAdfWp.length > i; i++) {
				if (this.results.includes(resultsAdfWp[i])) {
			this.results = this.results.filter(s => s !== resultsAdfWp[i]);
				}
			}
			for (let i= 0; resultsNbnWp.length > i; i++) {
				if (this.results.includes(resultsNbnWp[i])) {
			this.results = this.results.filter(s => s !== resultsNbnWp[i]);
				}
			}

		this.results.forEach(res => {
			res.flagStatus = false;
			if (res.fullName) {
				const nameArr = res.fullName.split(',');
				const result = nameArr.map(n => {
					const dob = n !== 'Unavailable' ? n.split('( ')[1].replace(' )', '') : '';
					return {
						name: n === 'Unavailable' ? 'Unavailable' : n.split('( ')[0],
						dob: dob
					}
				})
				res.details = [...result];
				if (res.memberSearchResponse !== null &&  res.memberSearchResponse.length > 0 && res.memberSearchResponse[0] !== null) {
					res.details.forEach(detail => {
						const getRes =  res.memberSearchResponse.filter(mem => mem !== null && mem.firstName === detail.name.split(' ')[0] && mem.dateOfBirth === detail.dob && mem.flagStatus !== null && mem.flagStatus !== 'N');
						if (getRes.length > 0) {
							res.flagStatus = true;
							detail.form2350 = getRes[0].flagStatus;
						}
					})
				}
			}
			
		})
	}

	uploadDocs(indivId) {
		sessionStorage.setItem('hidePanel', 'true');
		if (!indivId) sessionStorage.setItem("individualId", null);
		else sessionStorage.setItem("individualId", indivId )
		this.router.navigateByUrl("user-profile/my-documents/documents-we-need");
	}

	admitDischarge(details) {
		if (details) {
			sessionStorage.setItem('appType', 'ADF');
			const first =  details.name.split(' ')[0]
			const last =  details.name.split(' ')[1]
			this.userService.getSearchDetails(details);
			this.router.navigate(['/api/prov/search/'], { queryParams: {firstName: first, lastName: last, dob: details.dob } });
		}
	}

	onViewApp(pdfId) {
		const docId = pdfId?.replace(/[{}]/g, "");
        this.userService.getPrintDoc(docId).subscribe((res: any) => {
            let file = new Blob([res], { type: 'application/pdf' });
            let fileURL = URL.createObjectURL(file);
            let w = window.open(fileURL);
			w.onload = () => {
				setTimeout(() => {	w.document.title = 'Application Summary'}, 300);
			};
        }) 
    }

	continueApp(row) {
		let applicationNum = row.appNum;
		this.stateService.resetStates();
		if (row?.submissionType?.code !== 'LOC' && row?.submissionType?.code !== 'PRT') {
			sessionStorage.setItem('appType', row.submissionType.code)
		} else if (row?.submissionType?.code === 'PRT') {
			sessionStorage.setItem('isPreterm', "PRETERM");
			sessionStorage.setItem('appType', 'RB')
		} else {
			sessionStorage.setItem('appType', 'RB');
			sessionStorage.setItem('isReassessment', 'true');
		}
        this.stateService.continueApplication(true);
		sessionStorage.setItem('appNum', applicationNum);
        this.router.navigateByUrl('/application/' + applicationNum);
    }

	onDeleteApp(appNum) {
		this.modalService.confirmDelete().subscribe((res) => {
		  if(res) {
			this.app.deleteApp(appNum).subscribe(() => this.onLoad());        
		  }
		}); 
	}

	onLoad () {
		this.reload.emit()
	}

	onSubmitDocs (wpApp) {
		sessionStorage.setItem('hidePanel', 'true');
		this.router.navigate(['/user-profile/my-documents', 'uploaded-documents', {wpAppNum: wpApp}])
	}
}
