import { Directive, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { forEach, append } from 'ramda';

import { unsubscribe, distinct } from '@app/helpers/observable.helpers';

@Directive()
export class SubscriptionsDirective implements OnDestroy {
  private subscriptions: Subscription[] = [];

  public merge(subscriptions): void {
    this.subscriptions = subscriptions;
  }

  public create(selector: Observable<any>, update: (value: any) => void): Subscription {
    return distinct(selector).subscribe(update);
  }

  public clear(): void {
    forEach(unsubscribe, this.subscriptions);
  }

  public add(subscription): void {
    this.subscriptions = append(subscription, this.subscriptions);
  }

  ngOnDestroy(): void {
    this.clear();
  }
}
