import { Component, ChangeDetectionStrategy, AfterContentInit } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { FormBuilder } from '@angular/forms';
import { formatTableData } from '@app/helpers/tables.helpers';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'ramda';
import { ModalService } from "@app/services/modal/modal.service";
import Vs from '@app/services/validators/validators.service';



@Component({
  templateUrl: './appeal-request.component.html',
  styleUrls: ['./appeal-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AppealRequestComponent extends SectionFormDirective implements AfterContentInit {
  storeItem;

  defaultLanguage;
  translatedLang;
  showSelectAppelantsTable = false;
  enableSearch = true;
  selectAppelantsColumns = [
    { name: 'Name', prop: 'memberName' }
  ];
  selectAppelantsRows = [];
  IncludeinGroupAppeal = 'IncludeinGroupAppeal';
  PrimaryAppellant = 'PrimaryAppellant';
  GroupAppealSelectLabel = 'SELECT';
  PrimaryAppealSelect = 'SELECT';
  searchSelect = "SELECT_APPEAL"
  selected = 'SELECTED'
  groupAppealSelect = [];
  indvId;
  caseSummary;
  caseNumberList;
  multipleCase = false;
  caseNumber;
  caseArray = [];
  indivSummary = [];
  duplicateAppealQuestionFlag = false;
  enableTable = false;
  selectedSelf;
  isgroupAppeal;
  singleCaseNum;
  showPrimaryAlertMessage = true;
  isHoh = false;
  array;
  disableFlingCaseFor = null;
  showKBELigibleMessage = false;
  kbElgFlag;
  cmbAppealReasonArray = [];
  isLoggedInUserMinor;
  isKepro = null;

  constructor(public builder: FormBuilder, public translateService: TranslateService, private modalService: ModalService) {
    super(builder);
  };

  getCaseScenarios() {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.caseSummary = this.getValue('data.caseSummaryResponse');
    this.indvId = this.caseSummary.length ? JSON.parse(this.caseSummary[0]['loginIndvId']) : currentUser?.individualId;
    let caseSummaryData = this.getControl('data.caseSummary')
    caseSummaryData.value[0]['loginIndvId'] = this.indvId;
    const currentObj = caseSummaryData.value[0]['indvSummary'].filter(item => item.primaryAppelant === true);
    if (currentObj.length) {
      const tempObj = this.caseSummary[0]['indvSummaryResponse'].filter(item => (item.indvId === currentObj[0].indvid) || (item.indvId === String(currentObj[0].indvid)));
      this.kbElgFlag = tempObj.length ? tempObj[0]['kbElgFlag'] : false;
    }
      if (this.caseSummary.length === 1) {
        this.multipleCase = false;
        this.showSelectAppelantsTable = true;
        caseSummaryData.value[0]['caseNumber'] = this.caseSummary[0].caseNum;
        this.singleCaseNum = this.caseSummary[0].caseNum;
        if (this.caseSummary[0].hohId === String(this.indvId)) {
          this.isHoh = true;
        } else {
          this.isHoh = false;
        }
        this.isLoggedInUserMinor = this.caseSummary[0]['loginMinorFlag'];
        this.array = this.caseSummary[0];
        if (this.isLoggedInUserMinor) {
          this.getControl('data.filingCaseFor').setValue({ code: 'SLF', value: 'Self' });
          this.disableFlingCaseFor = true;
        } else {
          this.checkForMultipleRecords();
        }
        if (this.status === 'IN_PROGRESS') {
          this.filingCaseFor();
        }
        this.getControl('data.filingCaseFor').setValidators([Vs.required]);
        this.getControl('data.filingCaseFor').updateValueAndValidity();
        this.getControl('data.appealCaseNumber').clearValidators();
        this.getControl('data.appealCaseNumber').updateValueAndValidity();
      } else {
        let caseSummaryDataValue = this.getValue('data.caseSummary')
        const tempObj = caseSummaryDataValue[0]['indvSummary'].filter(item => item.indvid === String(this.indvId));
        if (!tempObj.length && caseSummaryDataValue[0]['caseNumber']) {
          this.getControl('data.filingCaseFor').setValue({ code: 'SOT', value: 'Self and/or Other Household Members' });
          this.disableFlingCaseFor = true;
        }
        this.isLoggedInUserMinor = this.caseSummary[0]['loginMinorFlag'];
        if (this.isLoggedInUserMinor) {
          let val = this.getValue('data.filingCaseFor');
          if (val?.code === 'SLF') {
            this.disableFlingCaseFor = true;
          }
        }
        this.multipleCase = true;
        this.getControl('data.appealCaseNumber').setValidators([Vs.required]);
        this.getControl('data.appealCaseNumber').updateValueAndValidity();
        let caseSummaryData = this.getControl('data.caseSummary')
        let appealCaseNum = caseSummaryData.value[0]['caseNumber']
        if (appealCaseNum) {
          for (let i = 0; this.caseSummary.length > i; i++) {
            if (this.caseSummary[i].caseNum === appealCaseNum) {
              if (this.caseSummary[i].hohId === String(this.indvId)) {
                this.isHoh = true;
              } else {
                this.isHoh = false;
              }
              this.array = this.caseSummary[i];
          }
        }
      }
    }

    this.caseNumberList = this.caseSummary.map(row => row.caseNum);
    const duplicateAppealValue = this.getValue('data.duplicateAppeal');
    this.duplicateAppealQuestionFlag = duplicateAppealValue?.code ? true : false;
    const caseSummaryControl = this.getControl('data.caseSummary');
    if (caseSummaryControl.value[0]['caseNumber']) {
      this.getControl('data.appealCaseNumber').patchValue(caseSummaryControl.value[0]['caseNumber']);
    }
    this.storeItem = sessionStorage.getItem('typesOfCoverage');
    this.enableSearch = false;
    let filingCaseValue = this.getValue('data.filingCaseFor');
    let kBprogramEnabled = this.getValue('data.kBprogram');
    if (filingCaseValue && this.status !== "IN_PROGRESS") {
      const caseSummaryValue = this.getValue('data.caseSummary');
      this.selectAppelantsRows = caseSummaryValue[0]['indvSummary'];
      for (let i = 0; this.selectAppelantsRows.length > i; i++) {
        if (this.selectAppelantsRows[i]['primaryAppelant'] === true && this.selectAppelantsRows[i]['groupAppelant'] === true) {
          this.selectAppelantsRows[i]['enableGroupAppealCheckBox'] = false;
        } else {
          this.selectAppelantsRows[i]['enableGroupAppealCheckBox'] = true;
        }
      }
      this.showPrimaryAlertMessage = false;
      this.showSelectAppelantsTable = true;
      if (filingCaseValue.code === 'SOT') {
        this.enableTable = true;
      }
      if (filingCaseValue.code === 'SLF') {
        this.selectAppelantsRows = this.selectAppelantsRows.filter((item) => (item.indvid === String(this.indvId)) || (item.indvid === this.indvId));
        this.selectAppelantsRows[0]['enableGroupAppealCheckBox'] = false;
        this.selectAppelantsRows[0]['groupAppelant'] = true;
        this.enableTable = true;
        this.selectedSelf = true;
        if (this.isLoggedInUserMinor) {
          this.disableFlingCaseFor = true;
        } else {
          this.disableFlingCaseFor = null;
          this.checkForMultipleRecords()
        }
      }
      this.showKbQuestion();
      if (kBprogramEnabled.code) {
        this.isgroupAppeal = true;
      }
    }
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.languageCheck(this.defaultLanguage);
    this.translatedLang = this.defaultLanguage
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang
      this.languageCheck(res.lang);
    })
  }


  ngAfterContentInit() {
    this.isKepro = sessionStorage.getItem('isKepro');
    if(this.isKepro){
      sessionStorage.setItem('isAHSlanding', 'true');
    }
    this.getCaseScenarios()
  }

  tableCodeValue(tableName, tableControl) {
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang, true)
  }

  appealCaseFor() {
    this.enableSearch = true;
    this.searchSelect = "SELECT_APPEAL";
    this.showSelectAppelantsTable = false;
    this.enableTable = false;
    this.selectAppelantsRows = [];
    let caseSummaryData = this.getControl('data.caseSummary')
    let appealCaseNumber = this.getValue('data.appealCaseNumber')
    for (let i = 0; this.caseSummary.length > i; i++) {
      if (this.caseSummary[i].caseNum === appealCaseNumber) {
        this.array = this.caseSummary[i];
        caseSummaryData.value[0]['caseNumber'] = this.caseSummary[i].caseNum;
        if (this.caseSummary[i].hohId === String(this.indvId)) {
          this.isHoh = true;
        } else {
          this.isHoh = false;
        }
      }
    }
    const tempObj = this.array['indvSummaryResponse'].filter(item => (item.indvId === this.indvId) || (item.indvId === String(this.indvId)));
    this.kbElgFlag = tempObj.length? tempObj[0]['kbElgFlag'] : false;
    caseSummaryData.value[0]['loginIndvId'] = this.indvId;
    this.indivSummary = caseSummaryData.value[0]['indvSummary'];
    this.getControl('data.filingCaseFor').reset();
    if (this.showSelectAppelantsTable) {
      this.enableSearch = false;
      this.searchSelect = this.selected;
      this.getControl('data.filingCaseFor').setValidators([Vs.required]);
      this.getControl('data.filingCaseFor').updateValueAndValidity();
    }
  }

  checkForMultipleRecords() {
    this.caseNumber = this.getValue('data.caseSummary');
      if (this.array['caseNum'] === this.caseNumber[0].caseNumber) {
        this.caseArray = this.array['indvSummaryResponse'];
      }
      if (this.caseArray.length === 1) {
        const tempObj = this.caseArray[0]['indvId'] === String(this.indvId);
        if(!tempObj) {
        this.getControl('data.filingCaseFor').setValue({ code: 'SOT', value: 'Self and/or Other Household Members' });
        } else {
        this.getControl('data.filingCaseFor').setValue({ code: 'SLF', value: 'Self' });
        }
        this.disableFlingCaseFor = true;
        this.caseNumber[0].indvSummary[0]['groupAppelant'] = true;
        this.caseNumber[0].indvSummary[0]['primaryAppelant'] = true;
        this.caseNumber[0].indvSummary[0]['indvid'] = this.caseArray[0].indvId;
        this.caseNumber[0].indvSummary[0]['memberName'] = this.caseArray[0].memberName;
        this.kbElgFlag = this.caseArray[0]['kbElgFlag'];
      } else {
        const tempObj = this.caseArray.filter((item) => item.indvId === String(this.indvId));
        if(!tempObj.length) {
        this.getControl('data.filingCaseFor').setValue({ code: 'SOT', value: 'Self and/or Other Household Members' });
        this.disableFlingCaseFor = true;
        }
      }
  }

  ltssChange() {
    const ltssValue = this.getValue('data.ltssProgram')
    if (ltssValue?.code === 'N' && !this.kbElgFlag) {
      this.getControl('data.appealingReasonLtss').reset();
      this.getControl('data.appealingReasonLtss').clearValidators();
      this.getControl('data.appealingReasonLtss').updateValueAndValidity();
      this.getControl('data.appealingReasonElg').setValidators([Vs.required]);
      this.getControl('data.appealingReasonElg').updateValueAndValidity();
    } else if (ltssValue?.code === 'N' && this.kbElgFlag) {
      this.getControl('data.appealingReasonLtss').reset();
      this.getControl('data.appealingReasonLtss').clearValidators();
      this.getControl('data.appealingReasonLtss').updateValueAndValidity();
      this.getControl('data.appealingReasonElg').reset();
      this.getControl('data.appealingReasonElg').clearValidators();
      this.getControl('data.appealingReasonElg').updateValueAndValidity();
      this.getControl('data.kBprogram').setValidators([Vs.required]);
      this.getControl('data.kBprogram').updateValueAndValidity();
    }else if (ltssValue?.code === 'Y')  {
      this.getControl('data.appealingReasonElg').reset();
      this.getControl('data.appealingReasonElg').clearValidators();
      this.getControl('data.appealingReasonElg').updateValueAndValidity();
      this.getControl('data.appealingReasonLtss').setValidators([Vs.required]);
      this.getControl('data.appealingReasonLtss').updateValueAndValidity();
      this.getControl('data.kBprogram').reset();
      this.getControl('data.kBprogram').clearValidators();
      this.getControl('data.kBprogram').updateValueAndValidity();
      this.getControl('data.appealingReasonKb').reset();
      this.getControl('data.appealingReasonKb').clearValidators();
      this.getControl('data.appealingReasonKb').updateValueAndValidity();
    }
  }

  eligibilityDecision() {
    const eligibilityDecision = this.getValue('data.appealEligibilityDecision')
    if (eligibilityDecision?.code === 'N') {
      this.getControl('data.renewalLetter').reset();
      this.getControl('data.renewalLetter').clearValidators();
      this.getControl('data.renewalLetter').updateValueAndValidity();
      this.getControl('data.kBprogram').reset();
      this.getControl('data.kBprogram').clearValidators();
      this.getControl('data.kBprogram').updateValueAndValidity();
      this.getControl('data.ltssProgram').reset();
      this.getControl('data.ltssProgram').clearValidators();
      this.getControl('data.ltssProgram').updateValueAndValidity();
      this.getControl('data.appealingReasonRenewal').reset();
      this.getControl('data.appealingReasonRenewal').clearValidators();
      this.getControl('data.appealingReasonRenewal').updateValueAndValidity();
      this.getControl('data.appealingReasonKb').reset();
      this.getControl('data.appealingReasonKb').clearValidators();
      this.getControl('data.appealingReasonKb').updateValueAndValidity();
      this.getControl('data.appealingReasonElg').reset();
      this.getControl('data.appealingReasonElg').clearValidators();
      this.getControl('data.appealingReasonElg').updateValueAndValidity();
      this.getControl('data.appealingReasonLtss').reset();
      this.getControl('data.appealingReasonLtss').clearValidators();
      this.getControl('data.appealingReasonLtss').updateValueAndValidity();
      this.getControl('data.appealDecisionValidation').setValidators([Vs.required]);
      this.getControl('data.appealDecisionValidation').updateValueAndValidity();
      this.getControl('data.duplicateAppeal').reset();
      this.getControl('data.duplicateAppeal').clearValidators();
      this.getControl('data.duplicateAppeal').updateValueAndValidity();
    } else if (eligibilityDecision?.code === 'Y') {
      this.getControl('data.renewalLetter').setValidators([Vs.required]);
      this.getControl('data.renewalLetter').updateValueAndValidity();
      this.getControl('data.appealDecisionValidation').clearValidators();
      this.getControl('data.appealDecisionValidation').updateValueAndValidity();
    }
  }

  duplicateAppealDecision() {
    const duplicateDecision = this.getValue('data.duplicateAppeal');
    if (duplicateDecision?.code === 'N') {
      this.getControl('data.appealDecisionValidation').setValidators([Vs.required]);
      this.getControl('data.appealDecisionValidation').updateValueAndValidity();
    } else {
      this.getControl('data.appealDecisionValidation').clearValidators();
      this.getControl('data.appealDecisionValidation').updateValueAndValidity();
    }
  }

  showKbQuestion() {
    let groupArray = [];
    let primaryArray = []
    for (let i = 0; this.selectAppelantsRows.length > i; i++) {
      if (this.selectAppelantsRows[i]['groupAppelant'] !== null && this.selectAppelantsRows[i]['groupAppelant'] === true) {
        groupArray.push(this.selectAppelantsRows[i]);
      }
      if (groupArray.length === 1 || this.selectedSelf) {
        this.isgroupAppeal = true;
        this.showKBELigibleMessage = false;
      } else if(groupArray.length > 1) {
        this.showKBELigibleMessage = true;
        this.isgroupAppeal = false;
        this.kbElgFlag = false;
      } else {
        this.isgroupAppeal = false;
        this.showKBELigibleMessage = false;
        let caseSummaryData = this.getControl('data.caseSummary')
      const currentObj = caseSummaryData.value[0]['indvSummary'].filter(item => item.primaryAppelant === true);
      if(currentObj.length) {
        const tempObj = this.caseSummary[0]['indvSummaryResponse'].filter(item => (item.indvId === currentObj[0].indvid) || (item.indvId === String(currentObj[0].indvid)));
        this.kbElgFlag = tempObj.length? tempObj[0]['kbElgFlag'] : false;
      }
      }
      if (this.selectAppelantsRows[i]['primaryAppelant'] !== null && this.selectAppelantsRows[i]['primaryAppelant'] === true) {
        primaryArray.push(this.selectAppelantsRows[i]);
      }
      if (primaryArray.length === 1) {
        this.getControl('data.primaryAppealantValidation').clearValidators();
        this.getControl('data.primaryAppealantValidation').updateValueAndValidity();
        this.showPrimaryAlertMessage = false;
      }
    }
  }

  renewalLetter() {
    const renewalSelect = this.getValue('data.renewalLetter')
    if (renewalSelect?.code === 'N' && this.kbElgFlag === true && (this.isgroupAppeal || this.selectedSelf)) {
      this.getControl('data.kBprogram').setValidators([Vs.required]);
      this.getControl('data.kBprogram').updateValueAndValidity();
      this.getControl('data.appealingReasonRenewal').reset();
      this.getControl('data.appealingReasonRenewal').clearValidators();
      this.getControl('data.appealingReasonRenewal').updateValueAndValidity();
      this.getControl('data.ltssProgram').setValidators([Vs.required]);
      this.getControl('data.ltssProgram').updateValueAndValidity();
    } else if (renewalSelect?.code === 'N' && this.kbElgFlag !== true && (this.isgroupAppeal || this.selectedSelf)) {
      this.getControl('data.appealingReasonRenewal').reset();
      this.getControl('data.appealingReasonRenewal').clearValidators();
      this.getControl('data.appealingReasonRenewal').updateValueAndValidity();
      this.getControl('data.ltssProgram').setValidators([Vs.required]);
      this.getControl('data.ltssProgram').updateValueAndValidity();
      this.getControl('data.kBprogram').reset();
      this.getControl('data.kBprogram').clearValidators();
    } else if (renewalSelect?.code === 'N' && this.kbElgFlag !== true && (!this.isgroupAppeal || !this.selectedSelf)) {
      this.getControl('data.appealingReasonElg').reset();
      this.getControl('data.appealingReasonKb').reset;
      this.getControl('data.appealingReasonElg').setValidators([Vs.required]);
      this.getControl('data.appealingReasonElg').updateValueAndValidity();
      this.getControl('data.kBprogram').reset();
      this.getControl('data.kBprogram').clearValidators();
      this.getControl('data.kBprogram').updateValueAndValidity();
      this.getControl('data.appealingReasonRenewal').reset();
      this.getControl('data.appealingReasonRenewal').clearValidators();
      this.getControl('data.appealingReasonRenewal').updateValueAndValidity();
      this.getControl('data.ltssProgram').setValidators([Vs.required]);
      this.getControl('data.ltssProgram').updateValueAndValidity();
    } else if (renewalSelect?.code === 'Y') {
      this.getControl('data.appealingReasonRenewal').setValidators([Vs.required]);
      this.getControl('data.appealingReasonRenewal').updateValueAndValidity();
      this.getControl('data.kBprogram').reset();
      this.getControl('data.kBprogram').clearValidators();
      this.getControl('data.kBprogram').updateValueAndValidity();
      this.getControl('data.appealingReasonElg').reset();
      this.getControl('data.appealingReasonElg').clearValidators();
      this.getControl('data.appealingReasonElg').updateValueAndValidity();
      this.getControl('data.appealingReasonKb').reset;
      this.getControl('data.appealingReasonKb').clearValidators();
      this.getControl('data.appealingReasonKb').updateValueAndValidity();
      this.getControl('data.ltssProgram').reset();
      this.getControl('data.ltssProgram').clearValidators();
      this.getControl('data.ltssProgram').updateValueAndValidity();
      this.getControl('data.appealingReasonLtss').reset();
      this.getControl('data.appealingReasonLtss').clearValidators();
      this.getControl('data.appealingReasonLtss').updateValueAndValidity();
    }
  }

  selcectKb() {
    const kbSelect = this.getValue('data.kBprogram')
    this.getControl('data.appealingReasonKb').reset();
    if (kbSelect?.code === 'N') {
      this.getControl('data.appealingReasonElg').setValidators([Vs.required]);
      this.getControl('data.appealingReasonElg').updateValueAndValidity();
      this.getControl('data.appealingReasonKb').clearValidators();
      this.getControl('data.appealingReasonKb').updateValueAndValidity();
    } else if (kbSelect?.code === 'Y') {
      this.getControl('data.appealingReasonKb').setValidators([Vs.required]);
      this.getControl('data.appealingReasonKb').updateValueAndValidity();
      this.getControl('data.appealingReasonElg').reset();
      this.getControl('data.appealingReasonElg').clearValidators();
      this.getControl('data.appealingReasonElg').updateValueAndValidity();
    }
  }


  filingCaseFor() {
    const filingCaseFor = this.getValue('data.filingCaseFor');
    this.getControl('data.kBprogram').reset();
    this.getControl('data.appealingReasonKb').reset();
    this.getControl('data.appealEligibilityDecision').reset();
    this.getControl('data.renewalLetter').reset();
    this.getControl('data.appealingReasonRenewal').reset();
    this.getControl('data.ltssProgram').reset();
    this.getControl('data.appealingReasonLtss').reset();
    this.getControl('data.appealingReasonElg').reset();
    this.caseNumber = this.getValue('data.caseSummary');
    this.selectAppelantsRows = [];
    this.enableTable = false;
    this.showPrimaryAlertMessage = true;
    if (this.array['caseNum'] === this.caseNumber[0].caseNumber) {
      this.caseArray = this.array['indvSummaryResponse'];
    }
    for (let i = 0; this.caseNumber[0].indvSummary.length > i; i++) {
      this.caseNumber[0].indvSummary[i]['groupAppelant'] = null;
      this.caseNumber[0].indvSummary[i]['primaryAppelant'] = null;
      this.caseNumber[0].indvSummary[i]['indvid'] = null;
    }
    for (let j = 0; this.caseArray.length > j; j++) {
      const tempObj = {
        'memberName': this.caseArray[j].memberName,
        'groupAppelant': false,
        'primaryAppelant': false,
        'enableGroupAppeal': true,
        'enablePrimaryAppeal': true,
        'indvid': Number(this.caseArray[j].indvId),
        'enableGroupAppealCheckBox': true,
        'primaryAppealSelect': this.PrimaryAppealSelect,
        'groupAppealSelect': this.GroupAppealSelectLabel
      }
      this.selectAppelantsRows.push(tempObj);
    }
    this.selectAppelantsRows = this.selectAppelantsRows.filter(
      (obj, index) =>
      this.selectAppelantsRows.findIndex((item) => item.indvid === obj.indvid) === index
    );
    if (filingCaseFor.code === 'SOT') {
      this.enableTable = true;
      this.selectedSelf = false;
      this.isgroupAppeal = false;      
      this.getControl('data.primaryAppealantValidation').setValidators(Vs.required)
      this.getControl('data.primaryAppealantValidation').updateValueAndValidity();
      this.showPrimaryAlertMessage = true;
    } else if (filingCaseFor.code === 'SLF') {
      this.enableTable = true;
      this.selectAppelantsRows = this.selectAppelantsRows.filter((item) => item.indvid === this.indvId);
      this.selectedSelf = true;
      this.isgroupAppeal = true;
      this.caseNumber[0].indvSummary[0]['groupAppelant'] = true;
      this.caseNumber[0].indvSummary[0]['primaryAppelant'] = true;
      this.caseNumber[0].indvSummary[0]['enableGroupAppealCheckBox'] = false;
      this.caseNumber[0].indvSummary[0]['indvid'] = this.indvId;
      this.selectAppelantsRows[0]['enableGroupAppealCheckBox'] = false;
      for (let i =0; this.array['indvSummaryResponse'].length > i; i++) {
        if (this.array['indvSummaryResponse'][i]['indvId'] === String(this.indvId)) {
          this.caseNumber[0].indvSummary[0]['memberName'] = this.array['indvSummaryResponse'][i].memberName;
          this.kbElgFlag = this.array['indvSummaryResponse'][i].kbElgFlag
        }
      }
      let array = this.caseNumber[0].indvSummary;
      if (array.length > 0) {
        this.caseNumber[0].indvSummary.length = 1;
      }
      this.getControl('data.primaryAppealantValidation').reset();
      this.getControl('data.primaryAppealantValidation').clearValidators();
      this.getControl('data.primaryAppealantValidation').updateValueAndValidity();
      this.showPrimaryAlertMessage = false;
    }
    const caseSummaryControl = this.getControl('data.caseSummary');
    for (let i = 0; caseSummaryControl.value[0]['indvSummary'].length > i; i++) {
      const tempObj = caseSummaryControl.value[0]['indvSummary'][i];
      if (tempObj['groupAppelant'] && this.selectAppelantsRows.length) {
        this.selectAppelantsRows[i]['groupAppelant'] = tempObj['groupAppelant'];
        this.selectAppelantsRows[i]['enableGroupAppeal'] = this.selectAppelantsRows[i]['groupAppelant'] ? false : true;
      }
      if (tempObj['primaryAppelant'] && this.selectAppelantsRows.length) {
        this.selectAppelantsRows[i]['primaryAppelant'] = tempObj['primaryAppelant'];
        this.selectAppelantsRows[i]['enablePrimaryAppeal'] = this.selectAppelantsRows[i]['primaryAppelant'] ? false : true;
      }
    }
    this.renewalLetter();
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  showSelectAppelants() {
    if (!isEmpty(this.getValue('data.appealCaseNumber')) && this.enableSearch) {
      this.showSelectAppelantsTable = true;
      this.enableSearch = false;
      this.searchSelect = this.selected;
      this.enableSearch = false;
      this.searchSelect = this.selected;
      this.showPrimaryAlertMessage = true;
      this.caseSummary = this.getValue('data.caseSummaryResponse');
      this.isLoggedInUserMinor = this.caseSummary[0]['loginMinorFlag'];
      if (this.isLoggedInUserMinor) {
        this.getControl('data.filingCaseFor').setValue({ code: 'SLF', value: 'Self' });
        this.disableFlingCaseFor = true;
        this.filingCaseFor();
      } else {
        this.getControl('data.filingCaseFor').reset();
        this.disableFlingCaseFor = null;
        this.checkForMultipleRecords();
        this.filingCaseFor();

      }
      this.getControl('data.filingCaseFor').setValidators([Vs.required]);
      this.getControl('data.filingCaseFor').updateValueAndValidity();
    }
  }

  openAppealEligibilityModal(): void {
    this.modalService.openAppealEligibilityModal();
  }

  selectCheckBox(event, row, rowIndex, box, view) {
    const caseSummaryControl = this.getControl('data.caseSummary');
    caseSummaryControl.value[0]['indvSummary'] = this.selectAppelantsRows;
    const tempObj = caseSummaryControl.value[0]['indvSummary'][rowIndex];
    if (box === 'groupAppealSelect') {
      row.enableGroupAppeal = false;
      this.GroupAppealSelectLabel = this.selected;
      if (event.target.checked === true || view === 'mobileView') {
        tempObj['groupAppelant'] = true;
        row.groupAppelant = true;
        row.groupAppealSelect = this.selected;
      } else {
        tempObj['groupAppelant'] = false;
        row.groupAppelant = false;
      }
      tempObj['enableGroupAppealCheckBox'] = true;
      row.enableGroupAppealCheckBox = true;
    }
    if (box === 'primaryAppealSelect') {
      row.enablePrimaryAppeal = false;
      row.enableGroupAppeal = false;
      if (event.target.checked === true || view === 'mobileView') {
        tempObj['primaryAppelant'] = true;
        row.primaryAppelant = true;
        tempObj['groupAppelant'] = true;
        row.groupAppelant = true;
        row.primaryAppealSelect = this.selected;
        row.groupAppealSelect = this.selected;
      } else {
        tempObj['groupAppelant'] = false;
        row.groupAppelant = false;
        tempObj['groupAppelant'] = false;
        row.groupAppelant = false;
        row.primaryAppealSelect = this.PrimaryAppealSelect
      }
      const kbObj = this.array['indvSummaryResponse'].filter(item => (item.indvId === row.indvid) || (item.indvId === String(row.indvid)));
          this.kbElgFlag = kbObj.length? kbObj[0]['kbElgFlag'] : false;
      tempObj['enableGroupAppealCheckBox'] = false;
      row.enableGroupAppealCheckBox = false;
      for (let i = 0; caseSummaryControl.value[0]['indvSummary'].length > i; i++) {
        if (i !== rowIndex) {
          caseSummaryControl.value[0]['indvSummary'][i]['primaryAppelant'] = false;
          this.selectAppelantsRows[i]['primaryAppelant'] = false;
          this.selectAppelantsRows[i]['enableGroupAppealCheckBox'] = true;
          this.selectAppelantsRows[i]['enablePrimaryAppeal'] = true;
          this.selectAppelantsRows[i]['primaryAppealSelect'] = this.PrimaryAppealSelect;
        }
      }
    }
    this.showKbQuestion();
    this.renewalLetter();
    tempObj['memberName'] = row.memberName;
    tempObj['indvid'] = row.indvid;
  }

  appealReason(control) {
    const reason = this.getValue(control);
    const appealCaseNumber = this.getValue('data.appealCaseNumber');
      let tempArray = [];
      this.cmbAppealReasonArray = this.caseSummary?.filter(value => value.caseNum === appealCaseNumber);
      if(control === 'data.appealingReasonElg') {
        tempArray = this.cmbAppealReasonArray[0]['eligReasonList']?.includes(reason?.code);
      } else if(control === 'data.appealingReasonRenewal') {
        tempArray = this.cmbAppealReasonArray[0]['renewalReasonList']?.includes(reason?.code);
      } else if(control === 'data.appealingReasonKb') {
        tempArray = this.cmbAppealReasonArray[0]['kbReasonList']?.includes(reason?.code);
      } else if(control === 'data.appealingReasonLtss') {
        tempArray = this.cmbAppealReasonArray[0]['ltssReasonList']?.includes(reason?.code);
      }
      
      if (tempArray) {
        this.duplicateAppealQuestionFlag = true;
        this.getControl('data.duplicateAppeal').setValidators([Vs.required]);
        this.getControl('data.duplicateAppeal').updateValueAndValidity();
      } else {
        this.duplicateAppealQuestionFlag = false;
        this.getControl('data.duplicateAppeal').reset();
        this.getControl('data.duplicateAppeal').clearValidators();
        this.getControl('data.duplicateAppeal').updateValueAndValidity();
      }
  }
}
