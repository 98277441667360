import { GlobalVars, SelfEmploymentComponent } from './self-employment.component';
import { evolve, compose, map } from 'ramda';
import Vs from '@app/services/validators/validators.service';
import { coOwnerRowCreator, sectionConfiguration } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { FormArray, FormGroup } from '@angular/forms';
import selfEmploymentLabels from './self-employment-labels';
import { trudy } from '@app/helpers/function.helpers';
import { disableGet } from '@app/helpers/able.helpers';
import { notYes } from '@app/helpers/tayn.helpers';

const formValidation = (builder, recordData, params) => {
  const selfEmployment = lookIn(recordData);
  const type = selfEmployment(['type']);
  const anyOneOutside = selfEmployment(['anyOneOutside']);
  const businessDescription = type?.code == 'OT' ? selfEmployment(['businessDescription']) : '';
  const businessName = selfEmployment(['businessName']);
  const averageMonthlyProfit = selfEmployment(['averageMonthlyProfit']);
  if(recordData?.coOwners?.length > 0){
    recordData.coOwners.sort(function(a, b) {
      return a.coOwnerSequence - b.coOwnerSequence;
    });
  }
  const coOwnerGroup = compose(
    (control: any) => builder.group(control),
    coOwnerRowCreator,
  );

  const coOwnersData = ( selfEmployment(['coOwners']) &&  selfEmployment(['coOwners']).length >0 )? selfEmployment(['coOwners']) : [{
    coOwnerIndividual: '',
    outsideTheHomeFullName: '',
    ownershipPercentage: '',
    coOwnerSequence: ''
  }];
  const coOwners = map(coOwnerGroup, coOwnersData.map((item: any) => {
    const individual = params.individuals.find((indi: any) => +indi.identifier === item.coOwnerIndividual.individualId);
    let coOwnerIndividual: any;
    if(selfEmployment(['coOwners']) && selfEmployment(['coOwners']).length > 0){
      coOwnerIndividual = individual? {code : individual.identifier, value : individual.name.fullName} : GlobalVars.outSideOption;
    }
    return { ...item, coOwnerIndividual };
  }));

  const totalValidator = (control : FormGroup) => {
    const coOwners = control.controls['coOwners'] as FormArray;
    const total = coOwners.controls.reduce((total, current: FormGroup) => {
      const value = (+current.controls['ownershipPercentage'].value);
      return value + total;
    }, 0);
    return total === 100 || coOwners.disabled ? null : {error : 'Please make sure that the percentages of ownership add up to 100%'};
  }
  const data = builder.group({
    type: [type, [Vs.required]],
    businessDescription: [businessDescription, [Vs.required, Vs.invalidDescription]],
    businessName: [businessName],
    averageMonthlyProfit: [averageMonthlyProfit, [Vs.required, Vs.numeric]],
    anyOneOutside: [anyOneOutside, [Vs.required]],
    coOwners: builder.array(coOwners),
  },{
    validator : totalValidator
  });
  trudy(() => disableGet('coOwners', data), notYes(anyOneOutside));
  return { data };
};


const transformIndividulaObj = (value) => ({
  name : value?.code === 'EMPTY' ? '' : value?.value,
  individualId: value?.code === 'EMPTY' ? 0 : value?.code
});

const postObject = evolve({
  averageMonthlyProfit: Number,
  coOwners: map(evolve({
    coOwnerIndividual : transformIndividulaObj
  }))
});

export default sectionConfiguration(
  SelfEmploymentComponent,
  formValidation,
  ['TAYN', 'TSET', 'SELFEMPTYPE', 'YESNO'],
  [
    { name: 'Name', pipe: 'individual' },
    { name: 'Business Description', prop: 'data.businessDescription' },
    { name: 'Type of Business', prop: 'data.type.value' },
    { name: 'Net Income', prop: 'data.averageMonthlyProfit', pipe: 'currency' },
  ],
  {...{}, ...selfEmploymentLabels},
  {
    postObject
  }
);
