<div class="container">
  <nh-alert type="danger" [isOpen]="showErr2">
    <div class="row">
      <div class="icon">
        <img src="../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
      </div>
      <div class="message">
        <span>{{ 'ERROR_MSG_2' | translate}}</span>
      </div>
    </div>
  </nh-alert>
  <div class="row" *ngIf='isRegistrationScreenEnabled'>
    <div class="col-8">
      <h1 class="page-title">{{ 'REGISTRATION' | translate}} </h1>
    </div>
  </div>
  <div class="row pl-1 pb-1 hover-red">
    <div *ngIf='isRegistrationScreenEnabled' class="btn btn-link hover-red" (click)="onPrevious()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
        <g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
          <path id="Combined_Shape" data-name="Combined Shape"
            d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
            transform="translate(0)" fill="#174a7c" />
        </g>
       </svg>
      <span class="pl-2">{{'BACK_TO_PREVIOUS_PAGE' | translate}}</span>
    </div>
  </div>
  <div class="row" *ngIf='!isRegistrationScreenEnabled'>
    <div class="col-12">
      <h1 class="page-title">{{'SUCCESS' | translate}}</h1>
    </div>
  </div>
  <div *ngIf='isRegistrationScreenEnabled' class="bg-custom-register">
    <h5 class="section-header">{{ 'CREATE_YOUR_ACCOUNT' | translate}}</h5>
    <hr>
    <section>
      <div class="row mr-1">
        <div class="col-md-12 ml-1">
          <form [formGroup]="registerAccount">
            <div class="row">
              <div class="col-md-6">
                <nh-field [isRow]="true" label="{{ 'USERNAME'  | translate }}" class="label-section">
                  <input id = "ra_userName" type="text" name="userName" aria-label="userName" formControlName="userName" class="form-control"
                    (focusout)="checkUserNameAvailability()" required>
                </nh-field>
                <div *ngIf="isUsernameTaken" class="validation-feedback">
                  <div>{{ 'USERNAME_TAKEN' | translate }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <nh-field [isRow]="true" label="{{'FIRST_NAME' | translate}}" class="label-section">
                  <input id = "ra_firstName" type="text" name="firstName" aria-label="firstName" formControlName="firstName" class="form-control" required>
                </nh-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <nh-field [isRow]="true" label="{{'LAST_NAME' | translate}}" class="label-section">
                  <input id = "ra_lastName" type="text" name="lastName" aria-label="lastName" formControlName="lastName" minlength="3" class="form-control"
                    required>
                </nh-field>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <nh-field [isRow]="true" label="{{'EMAIL_ADDRESS' | translate}}" class="label-section">
                 <nh-popover class="pl-1" [content]="'USER_EMAIL_TOOLTIP' | translate"></nh-popover>
                  <input id = "ra_email" type="email" name="email" aria-label="email" formControlName="email" class="form-control"
                    placeholder="{{'EMAIL_PLACEHOLDER' | translate }}" (focusout)="checkEmailAvailability()" required>
                </nh-field>
                <div *ngIf="isMailIdTaken" class="validation-feedback">
                  <span class="ml-3 form-text invalid-tooltip d-block">{{ 'EMAIL_USED' | translate }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <nh-field [isRow]="true" label="{{'CONFIRM_EMAIL' | translate}}" class="label-section">
                  <input id = "ra_confirmEmail" type="email" name="confirmEmail" aria-label="confirmEmail" formControlName="confirmEmail" class="form-control"
                    placeholder="{{'EMAIL_PLACEHOLDER' | translate }}" (input)="validateEmail($event)">
                </nh-field>
              </div>
            </div>
            <p>{{'VERIFICATION_LINK' | translate }} </p>

            <h5 class="section-header">{{'CREATE_PASSWORD' | translate }} </h5>
            <hr>
            <p>{{'PWD_GUIDELINES' | translate }} </p>
            <br />
            <div class="row">
              <div class="col-md-5">
                <nh-field [isRow]="true" label="{{'PASSWORD'| translate}}" class="label-section">
                  <div class="password-eye">
                    <input id = "ra_password" [type]="fieldTextType ? 'text' : 'password'" class="form-control" placeholder="{{'ENTER_PASSWORD'| translate}}" formControlName="password"
                      (input)="validatePassword($event)" />
                      <span (click)="toggleFieldTextType('password')" class="eye-icon"><img alt="" src="assets/images/Icons/eye-outline-password.svg"></span>
                  </div>
                </nh-field>
                <nh-field man [isRow]="true" label="{{'CONFIRM_PASSWORD' | translate }}" class="label-section">
                  <div class="password-eye">
                    <input id = "ra_confirmPassword" [type]="fieldConfirmPassTextType ? 'text' : 'password'" class="form-control"
                    placeholder="{{'CONFIRM_PASSWORD' | translate }}" formControlName="confirmPassword" (input)="comparePassword($event)"/>
                    <span (click)="toggleFieldTextType('confirmPassword')" class="eye-icon"><img alt="" src="assets/images/Icons/eye-outline-password.svg"></span>
                  </div>
                </nh-field>
              </div>
              <div class="col-md-7">
                <div class="password-guide">
                  <div class="password-header"><span>{{'PWD_GUIDE' | translate }}</span><hr></div>
                  <ul>
                    <li *ngIf="passwordRuleLen" class="password-rules-valid">
                      <div class="row password-guide-style">
                        <img src="../../../assets/images/met icon_PWGuidelines.svg" alt="passowrd-met">
                        <div>{{ 'PG_1' | translate }}</div>
                      </div>
                    </li>
                    <li *ngIf="!passwordRuleLen" class="password-rules">
                      <div class="row password-guide-style">
                        <img src="../../../assets/images/not met icon_PWGuidelines.svg" alt="passowrd-not-met">
                        <div>{{ 'PG_1' | translate }}</div>
                      </div>
                    </li>
                    <li *ngIf="passwordRuleUpp" class="password-rules-valid">
                      <div class="row password-guide-style">
                        <img src="../../../assets/images/met icon_PWGuidelines.svg" alt="passowrd-met">
                        <div>{{ 'PG_2' | translate }}</div>
                      </div>
                    </li>
                    <li *ngIf="!passwordRuleUpp" class="password-rules">
                      <div class="row password-guide-style">
                        <img src="../../../assets/images/not met icon_PWGuidelines.svg" alt="passowrd-not-met">
                        <div>{{ 'PG_2' | translate }}</div>
                      </div>
                    </li>                    
                    <li *ngIf="passwordRuleLow" class="password-rules-valid">
                      <div class="row password-guide-style">
                        <img src="../../../assets/images/met icon_PWGuidelines.svg" alt="passowrd-met">
                        <div>{{ 'PG_3' | translate }}</div>
                      </div>
                    </li>
                    <li *ngIf="!passwordRuleLow" class="password-rules">
                      <div class="row password-guide-style">
                        <img src="../../../assets/images/not met icon_PWGuidelines.svg" alt="passowrd-not-met">
                        <div>{{ 'PG_3' | translate }}</div>
                      </div>
                    </li>                   
                     <li *ngIf="passwordRuleNum" class="password-rules-valid">
                      <div class="row password-guide-style">
                        <img src="../../../assets/images/met icon_PWGuidelines.svg" alt="passowrd-met">
                        <div>{{ 'PG_4' | translate }}</div>
                      </div>
                    </li>
                    <li *ngIf="!passwordRuleNum" class="password-rules">
                      <div class="row password-guide-style">
                        <img src="../../../assets/images/not met icon_PWGuidelines.svg" alt="passowrd-not-met">
                        <div>{{ 'PG_4' | translate }}</div>
                      </div>
                    </li>                    
                    <li *ngIf="passwordRuleSpl" class="password-rules-valid">
                      <div class="row password-guide-style">
                        <img src="../../../assets/images/met icon_PWGuidelines.svg" alt="passowrd-met">
                        <div>{{ 'PG_5' | translate }}</div>
                      </div>
                    </li>
                    <li *ngIf="!passwordRuleSpl" class="password-rules">
                      <div class="row password-guide-style">
                        <img src="../../../assets/images/not met icon_PWGuidelines.svg" alt="passowrd-not-met">
                        <div>{{ 'PG_5' | translate }}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <br />

            <h5 class="section-header">{{'SELECT_SECURITY_QUESTIONS' | translate }}</h5>
            <hr>
            <p [innerHtml]="'SECURITY_QUES_TEXT' | translate"></p>
            <br />
            <div class="row">
              <div class="col">
                <nh-field id = "ra_question1" [isRow]="true" label="{{'Q1' | translate}}" class="label-section">
                  <select  style="max-width: 55.66666667% !important;" nh name="question1" aria-label="question1" formControlName="question1" [items]="questionSet1"></select>
                </nh-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <nh-field id = "ra_answer1" [isRow]="true" label="{{'ANSWER' | translate }}" class="label-section">
                  <input type="text" name="answer1" aria-label="answer1" formControlName="answer1"
                    (input)="answerUpdate(answer2, answer3, answer1)" class="form-control" required>
                </nh-field>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <nh-field id = "ra_question2" [isRow]="true" label="{{'Q2' | translate }}" class="label-section">
                  <select style="max-width: 55.66666667% !important;" nh name="question2" aria-label="question2" formControlName="question2" [items]="questionSet2"></select>
                </nh-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <nh-field id = "ra_answer2" [isRow]="true" label="{{'ANSWER' | translate}}" class="label-section">
                  <input type="text" name="answer2" aria-label="answer2" formControlName="answer2"
                    (input)="answerUpdate(answer1, answer3, answer2)" class="form-control" required>
                </nh-field>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <nh-field id = "ra_question3" [isRow]="true" label="{{'Q3' | translate}}" class="label-section">
                  <select style="max-width: 55.66666667% !important;" nh name="question3" aria-label="question3" formControlName="question3" [items]="questionSet3"></select>
                </nh-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <nh-field id = "ra_answer3" [isRow]="true" label="{{'ANSWER' | translate}}" class="label-section">
                  <input type="text" name="answer3" aria-label="answer3" formControlName="answer3"
                    (input)="answerUpdate(answer1, answer2, answer3)" class="form-control" required>
                </nh-field>
              </div>
            </div>
            <re-captcha id = "ra_recaptchaReactive" *ngIf="isRecaptchaEnabled && loaderReady" (resolved)="resolved($event)" formControlName="recaptchaReactive"
              [siteKey]="siteKey">
            </re-captcha>
            <br>
          </form>
        </div>
      </div>
      <div class="row mb-2 ml-1 mr-1">
        <button id="continue" (click)="createAccount()" [disabled]="!isFormValid()"
          class="btn btn-secondary col-md-2 offset-md-8">{{'NEXT_BUTTON' | translate}}</button>
      </div>
    </section>
  </div>
  <div *ngIf='!isRegistrationScreenEnabled' class="card mb-3" style="border: 0px;">
    <div class="card-body shadow-sm">
      <h5 class="section-header">{{ 'REGISTRATION_LINK_SUCC_SUB_TITLE'| translate}}</h5>
      <hr>
    <section>
      <div class="row">
        <div class="col-md-12"><p>{{'ACCOUNT_CREATION' | translate }}</p>
          <div class="pt-1" *ngIf="isPerfEnv"><a class="btn-link" [href]="verfLink" target="_blank">{{verfLink}}</a></div>

          <div class="row pt-1 hover-red comments">
            <a class="btn btn-link hover-red" [routerLink]="['/signin']">
              <span class="pr-2">{{'BACK_TO_LOGIN' | translate }}</span>
              <nh-exit-svg></nh-exit-svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
  </div>
</div>
