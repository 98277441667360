<div class="container">
    <div class="row">
        <div class="card" style="width:100%;">
            <div class="card-body">
                <p *ngIf ="!enableText">{{'COMMUNICATION_DESC' | translate}}</p>
                <p *ngIf ="enableText">{{'COMMUNICATION_DESC_PP' | translate}}</p>
                <p *ngIf ="!enableText">{{'COMMUNICATION_DESC_2' | translate}}</p>
                <p *ngIf ="enableText">{{'COMMUNICATION_DESC_2_PP' | translate}}</p>
              
                <form *ngIf="res" #communicationForm="ngForm">
                    <h5 class="section-header">{{'COMMUNICATION_HEADER' | translate}}</h5>
                    <hr>
                    <div class="d-flex align-items-center switch-control">

                        <div class="custom-control custom-switch">
                            <input type="checkbox" [disabled] = "isDisabled" class="custom-control-input" name="mail"  (change)="communicationFormCheck(communicationForm)" [(ngModel)]="res.paperSw"
                                id="customSwitch1">
                            <label class="custom-control-label" for="customSwitch1">{{'COMMUNICATION_MAIL' | translate}}.</label>
                        </div>
                    </div>


                    <h5 class="section-header mt-3">{{'COMMUNICATION_HEADER2' | translate}}</h5>
                    <hr>
                    <p class="mt-1">{{'COMMUNICATION_EMAIL_DESC' | translate}}</p>
                    <div class="d-flex align-items-center switch-control">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" [disabled] = "isDisabled" class="custom-control-input" name="email" #emailPref (change)="communicationFormCheck(communicationForm)" [(ngModel)]="res.emailSw"
                                id="customSwitch2">
                            <label class="custom-control-label" for="customSwitch2">{{'COMMUNICATION_EMAIL' | translate}}.</label>
                        </div>
                        <span>
                            <nh-popover class="pl-1"
                                content="{{'COMMUNICATION_EMAIL_TOOLTIP' | translate}}">
                            </nh-popover>
                        </span>
                    </div>
                    <div *ngIf="res.emailSw && !enableText" class="m-3 pl-4">{{emailId}}</div>
                    <div *ngIf="res.emailSw && enableText" class="m-3 pl-4">{{emailAddress}}</div>
                    <p class="mt-2">{{'COMMUNICATION_EMAIL_DESC_1' | translate}}</p>

                    <h5 class="section-header">{{'COMMUNICATION_HEADER3' | translate}}</h5>
                    <hr>
                    <p class="mt-1">{{'COMMUNICATION_TEXT_DESC' | translate}}</p>
                    <div class="d-flex align-items-center switch-control">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" [disabled] = "isDisabled" class="custom-control-input" name="text"  #textPref (change)="communicationFormCheck(communicationForm)" [(ngModel)]="res.textSw"
                                id="customSwitch3">
                            <label class="custom-control-label" for="customSwitch3">{{'COMMUNICATION_TEXT' | translate}}.</label>
                        </div>
                    </div>

                    <div class="m-3 pl-4 d-flex" *ngIf="res.textSw">
                       <div class="mr-5 mt-2" *ngIf="!onEdit && enterPhone">
                         {{res.phoneNumber | phone}}
                       </div>
                        <div  *ngIf="!enterPhone || onEdit" layout-align="start center" class=" mr-4 mt-1">
                                <input type="phone" [disabled] = "isDisabled" name="phone"  class="form-control" [(ngModel)]="res.phoneNumber" minlength="10"
                                    mask="000-000-0000" placeholder="XXX-XXX-XXXX" (change)="onChange(res.phoneNumber)" (focusout)="communicationFormCheck(communicationForm)" required>
                                    <span *ngIf="ifRequired" class="invalid-name d-block">{{ 'PHONE_NUMBER' | translate }}</span>
                        </div>
                        <button class="btn-phone-edit" [disabled] = "isDisabled" (click)="onEdit = true">
                          <span   >{{'EDIT_BUTTON' | translate}}</span>
                        </button>
                    </div>

                    <div class="small mt-4 mb-4"><strong>{{'COMMUNICATION_NOTIFICATION_HEADER' | translate}}</strong>{{'COMMUNICATION_NOTIFICATION' | translate}}</div>
                    <nh-alert type="warning" [isOpen]="isCancelClicked">
                        <div class="row">
                          <div class="icon">
                            <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
                          </div>
                          <div class="message">
                            <span>{{ 'WARNING_CANCEL' | translate }}</span>
                          </div>
                        </div>
                    </nh-alert>
                </form>
            </div>
        </div>
    </div>
</div>
