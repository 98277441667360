import { Component, ChangeDetectionStrategy, AfterContentInit } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NewStartComponent extends SectionFormDirective implements AfterContentInit {
  storeItem;
  hasIndiv: boolean;

  user = sessionStorage.getItem('userType');

  isPartnerPortal = this.user === 'RP' ? true : false
  closeAFBnudge: boolean = false;

  constructor(public builder: FormBuilder, private titleService: Title) {
    super(builder)
    this.titleService.setTitle('Apply For Coverage');
  };

  ngAfterContentInit() {
    this.storeItem = sessionStorage.getItem('typesOfCoverage');
    const indiv = sessionStorage.getItem('individualId');
    this.hasIndiv = indiv && indiv != 'null' && indiv != '0' ? true : false;
    const getNudgeStatus = sessionStorage.getItem('closeNudge') ? sessionStorage.getItem('closeNudge') : sessionStorage.getItem('closeAFBNudge');
    if (getNudgeStatus === 'true') {
      this.closeAFBnudge = true;
    }
  }
}
