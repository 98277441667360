import { NewbornSummaryComponent } from './newborn-summary.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';

const formValidation = (builder) => {

  const data = builder.group({
  })

  return { data };
};

export default sectionConfiguration(
  NewbornSummaryComponent,
    formValidation,
    [],
    [],
    {
    },{
      hasSummary: false
    }
);
