<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">

  <p *ngIf="isRmbRmc">{{ "RENEWAL_TEXT" | translate }}</p>
  <div class="d-flex">
    <div *ngIf="isHead" class="pr-2">
      <h6 class="person-label">{{'HEAD_OF_HOUSEHOLD' | translate}}</h6>
    </div>
    <div *ngIf="!isPresumptive">
      <h6 class="person-label">{{ selectedIndividual | fullname }}</h6>
    </div>
  </div>

  <div [formGroup]="getControl('data')">
   
    <div *ngIf="isFosterCare">
      <h5 class="section-header">{{'FORMER_FROSTER' | translate}}</h5>
      <hr>
      <section>
        <nh-field *ngIf="isEnabled('data.formerFosterCare')"
          label="{{'WAS' | translate}}{{ selectedIndividual | fullname}} {{'FORMER_FROSTER_STATIC_TEXT' | translate}}"
          class="label-section">
          <select nh name="formerFosterCare" aria-label="formerFosterCare" formControlName="formerFosterCare"
            [items]="tableCodeValue('YESNO', 'data.formerFosterCare')" (change)="isGreaterafterTwentyThree()"></select>
        </nh-field>
      </section>
    </div>


    <!-- Show all the sections PREGNANCY, PAST-PREGNANCY and EMS only when age is >18  before 01/01/2023 , 
                                                      irrespective of answer to FORMER FOSTERCARE question-->
    <!-- OR -->
    <!-- when age is >18  after 01/01/2023 and FORMER FOSTERCARE question is 'NO', 
                                                       then Show all the sections PREGNANCY, PAST-PREGNANCY and EMS -->

    <!-- when age is >18  after 01/01/2023 and FORMER FOSTERCARE question is 'YES', 
                                                                 go to to FINISH screen-->


    <div  *ngIf="( ((ageGreaterThanEighteenOnTwentyThree === false) && isN('data.formerFosterCare')) || ageGreaterThanEighteenOnTwentyThree || !isEnabled('data.formerFosterCare'))">
      <div *ngIf="isPresumptive">
        <h5 class="section-header">
          Tax Information
        </h5>
        <hr>

        <section [formGroup]="getControl('data.taxFilerInfo')">
          <nh-field id = "ad_filing" *ngIf="isEnabled('data.taxFilerInfo.filing')" [mandatory]="false"
            label="Is {{ selectedIndividual | fullname }} planning to file a federal income tax return the next time taxes are due?"
            class="label-section">
            <select nh name="filing" formControlName="filing"
              [items]="tableCodeValue('YESNO', 'data.taxFilerInfo.filing')"></select>
          </nh-field>
          <nh-field id = "ad_hasTaxDependentOutside" *ngIf="isN('data.taxFilerInfo.filing')" [mandatory]="false"
            label="Is {{ selectedIndividual | fullname }} being claimed as a dependent by someone outside of the home?"
            class="label-section">
            <select nh name="hasTaxDependentOutside"
              [items]="tableCodeValue('YESNO', 'data.taxFilerInfo.hasTaxDependentOutside')"
              formControlName="hasTaxDependentOutside"></select>
          </nh-field>
        </section>
      </div>
    </div> 
    
    
    <!-- {{isPregnable}} {{ (ageGreaterThanEighteenOnTwentyThree === false)}} {{ isN('data.formerFosterCare') }} {{ ageGreaterThanEighteenOnTwentyThree }} -->
    <div *ngIf="isPregnable && ( ((ageGreaterThanEighteenOnTwentyThree === false) && isN('data.formerFosterCare') && appTypePregnancy !== 'PRG') || ((ageGreaterThanEighteenOnTwentyThree === false) && appTypePregnancy === 'PRG') || ageGreaterThanEighteenOnTwentyThree || !isEnabled('data.formerFosterCare'))">
      <h5 class="section-header">
        {{'PREGNANCY' | translate}}
      </h5>
      <hr>
      <section [formGroup]="getControl('data.pregnancyOne')">
        <div>
          <nh-field id = "ad_isPregnant" *ngIf="isEnabled('data.pregnancyOne.isPregnant')"
            label="{{'IS_1' | translate}}{{ selectedIndividual | fullname }} {{'PREGNANCY_STATIC_TEXT1' | translate}}"
            class="label-section">
            <select nh name="isPregnant" aria-label="isPregnant"
              [items]="tableCodeValue('YESNO', 'data.pregnancyOne.isPregnant')" (change)="isPregnantChanged($event)" formControlName="isPregnant"></select>
          </nh-field>
	      </div>
         <!-- <nh-field *ngIf="isEnabled('data.pregnancyOne.hadBabySw')"
            label="{{'HAS_1' | translate}} {{ selectedIndividual | fullname }} {{'HAD_BABY' | translate}}"
            class="label-section">
            <select nh name="hadBabySw" aria-label="hadBabySw"
              [items]="tableCodeValue('YESNO', 'data.pregnancyOne.hadBabySw')" formControlName="hadBabySw"></select>
          </nh-field> -->
	      <div>

          <nh-field id = "ad_stillPregSw" *ngIf="isEnabled('data.pregnancyOne.stillPregSw')"
            label="{{'IS_1' | translate}}{{ selectedIndividual | fullname }} {{'STILL_PREGNANT' | translate}}"
            class="label-section"> 
            <select nh name="stillPregSw" aria-label="stillPregSw"
              [items]="tableCodeValue('YESNO', 'data.pregnancyOne.stillPregSw')" formControlName="stillPregSw"></select>
	        </nh-field>

          <nh-field id = "ad_numberOfBabies" *ngIf="isEnabled('data.pregnancyOne.numberOfBabies')"
          label="{{'HOW_MANY_BABIES' | translate}} {{ selectedIndividual | fullname }} {{'EXPECTING' | translate}}"
          class="label-section">
          <select nh name="numberOfBabies" aria-label="numberOfBabies"
            [items]="tableCodeValue('NUMBEROFBIRTHS', 'data.pregnancyOne.numberOfBabies')"
            formControlName="numberOfBabies"></select>
          </nh-field>

          <nh-field id = "ad_pregEndDate" *ngIf="isEnabled('data.pregnancyOne.pregEndDate')"
            label="{{'WHAT_WAS' | translate}} {{ selectedIndividual | fullname }}{{'PREGNANCY_END_DATE' | translate}}"
            class="label-section">
            <div class="hint" *ngIf="isEnabled('data.pregnancyOne.pregEndDate')">{{'PERSON_DUE_DATE_STATIC_TEXT' |
              translate}}</div>
            <nh-datepicker>
              <input type="text" name="pregEndDate" class="form-control" (change)="isInvalidDate($event)"
                formControlName="pregEndDate" bsDatepicker nh [maxDate]="today" [minDate]="minimumPregDate"
                maxlength="10">
            </nh-datepicker>
          </nh-field>

          <nh-field id = "ad_dueDate"
            label="{{'WHAT_IS' | translate}} {{ selectedIndividual | fullname }}{{'PERSON_DUE_DATE' | translate}}"
            *ngIf="isEnabled('data.pregnancyOne.dueDate')" class="label-section">
            <div class="hint" *ngIf="isEnabled('data.pregnancyOne.dueDate')">{{'PERSON_DUE_DATE_STATIC_TEXT' |
              translate}}</div>
            <nh-datepicker>
              <input type="text" name="dueDate" class="form-control" (change)="isInvalidDate($event)" [minDate]="today"
                [maxDate]="maximumDueDate" formControlName="dueDate" bsDatepicker nh maxlength="10">
            </nh-datepicker>
          </nh-field>

          <!-- <nh-field *ngIf="isEnabled('data.pregnancyOne.haveBabyDate')" label="{{'WHEN_DID' | translate}} {{ selectedIndividual | fullname }} {{'HAVE_BABY' | translate}}"
            class="label-section">
            <div class="hint" *ngIf="isEnabled('data.pregnancyOne.haveBabyDate')">{{'PERSON_DUE_DATE_STATIC_TEXT' |
              translate}}</div>
            <nh-datepicker>
              <input type="text" name="haveBabyDate" class="form-control" (change)="isInvalidDate($event)"
                formControlName="haveBabyDate" bsDatepicker nh [maxDate]="today" [minDate]="minimumPregDate"
                maxlength="10">
            </nh-datepicker>
          </nh-field> -->

          <nh-field id = "ad_addBabiesSw" *ngIf="isEnabled('data.pregnancyOne.addBabiesSw') && (isRmbRmc)" label="{{'PEOPLE_DELETE_STATIC_TEXT11' | translate }}">
            <select nh name="addBabiesSw" formControlName="addBabiesSw" aria-label="addBabiesSw" [required]="true" [items]="tableCodeValue('YESNO', 'data.pregnancyOne.addBabiesSw')"></select>
          </nh-field>
          
          <nh-field id = "ad_noOfBabiesBorn" *ngIf="isEnabled('data.pregnancyOne.noOfBabiesBorn')" [isRow]="true" label="{{'PEOPLE_DELETE_STATIC_TEXT12' | translate }} ">
            <select nh name="noOfBabiesBorn" (change)="addDynamicRows(this.getValue('data.pregnancyOne.noOfBabiesBorn'))" [items]="tableCodeValue('NUMBEROFBIRTHS', 'data.pregnancyOne.noOfBabiesBorn')" [required]="true" formControlName="noOfBabiesBorn" aria-label="noOfBabiesBorn"></select>
          </nh-field>

          <div *ngIf="showNewbornList" formArrayName="newBornInfoList">
            <div>
              <div *ngFor="let row of newBornInfoList.controls; let i = index;" [formGroupName]="i" class="col-md-8 col-sm-12 my-4 pl-0">
                <h5 class="section-header pb-2"> {{'PEOPLE_DELETE_STATIC_TEXT13' | translate }} </h5><hr>  
                <div class="form-row col-lg-12 pl-0">
                  <div class="col-md-4 col-sm-12">
                    <nh-field id = "ad_newbornFirstName" [isRow]="true" [mandatory]="true" label="{{'FIRST_NAME' | translate }}">
                      <input type="text" name="newbornFirstName" formControlName="newbornFirstName" aria-label= "firstName" class="form-control" maxlength="45" [required]="true">
                    </nh-field>
                  </div>
  
                  <div class="col-md-4 col-sm-12">
                    <nh-field id = "ad_newbornMiddleName" [isRow]="true" [mandatory]="false" label="{{'MIDDLE_NAME' | translate }}">
                      <input type="text" name="newbornMiddleName" formControlName="newbornMiddleName" aria-label="middleName" class="form-control" mask="S">
                    </nh-field>
                  </div>
  
                  <div class="col-md-4 col-sm-12">
                    <nh-field id = "ad_newbornLastName" [isRow]="true" [mandatory]="true" label="{{'LAST_NAME' | translate }}">
                      <input type="text" name="newbornLastName" formControlName="newbornLastName" aria-label="LastName" class="form-control" maxlength="45" [required]="true">
                    </nh-field>
                  </div>
                </div>

                <nh-field id = "ad_newbornDOB" label="{{'DOB' | translate }}" [isRow]="true" [mandatory]="true">
                  <nh-datepicker>
                    <input type="text" name="newbornDOB" placeholder="mm/dd/yyyy" class="form-control" formControlName="newbornDOB" bsDatepicker nh [required]="true" [minDate]="minimumDate" [maxDate]="today">
                  </nh-datepicker>
                </nh-field>
  
                <nh-field id = "ad_gender" [isRow]="true" [mandatory]="true" label="{{'GENDER' | translate }}">
                  <select nh name="gender" formControlName="gender" aria-label="gender" [required]="true" [items]="tableCodeValue('GENDER', i)"></select>
                </nh-field>
  
                <nh-field id = "ad_ssn" [isRow]="true" [mandatory]="false" label="{{'SSN' | translate }}">
                  <input type="text" name="ssn" formControlName="ssn" class="form-control" placeholder="XXX-XX-XXXX" mask="000-00-0000">
                </nh-field>
              </div>
            </div>
          </div>


        </div>
      </section>
      <div *ngIf="!closePregDueDateNudge">
        <nh-nudge *ngIf="showPastPregDueDateNudge" [message]="'NUDGETEXT_03' | translate" (close)="closeSnackbar()"></nh-nudge>
      </div>
    </div>

    <div *ngIf="isPregnable && !disablePastPreg && isPregnableAndMovedIn 
                                && ( ((ageGreaterThanEighteenOnTwentyThree === false) && isN('data.formerFosterCare') && appTypePregnancy !== 'PRG') || ((ageGreaterThanEighteenOnTwentyThree === false) && appTypePregnancy === 'PRG') || ageGreaterThanEighteenOnTwentyThree || !isEnabled('data.formerFosterCare'))">
      <h5 class="section-header">
        <strong>{{'PAST_PREGNANCY' | translate}}</strong>
      </h5>
      <hr>
      <section [formGroup]="getControl('data.pregnancyOne')">
        <nh-field id = "ad_nextPregSw" *ngIf="isEnabled('data.pregnancyOne.nextPregSw')"
          label="{{'DOES_PASTPREGNANCY' | translate}}{{ selectedIndividual | fullname }} {{'PAST_PREGNANCY_STATIC_TEXT' | translate}}"
          class="label-section">
          <select nh name="nextPregSw" aria-label="nextPregSw"
            [items]="tableCodeValue('YESNO', 'data.pregnancyOne.nextPregSw')" formControlName="nextPregSw"></select>
        </nh-field>
        <nh-field id = "ad_nextPregNumberOfBabies" *ngIf="isEnabled('data.pregnancyOne.nextPregNumberOfBabies')"
          label="{{'HOW_MANY_BABIES_WAS' | translate}} {{ selectedIndividual | fullname }} {{'EXPECTING' | translate}}"
          class="label-section">
          <select nh name="nextPregNumberOfBabies" aria-label="nextPregNumberOfBabies"
            [items]="tableCodeValue('NUMBEROFBIRTHS', 'data.pregnancyOne.nextPregNumberOfBabies')"
            formControlName="nextPregNumberOfBabies"></select>
        </nh-field>

        <nh-field id = "ad_nextPregDueDate"
          label="{{'WHAT_WAS' | translate}} {{ selectedIndividual | fullname }}{{'PREGNANCY_END_DATE' | translate}}"
          *ngIf="isEnabled('data.pregnancyOne.nextPregDueDate')" class="label-section">
          <div class="hint" *ngIf="isEnabled('data.pregnancyOne.nextPregDueDate')">{{ 'PERSON_DUE_DATE_STATIC_TEXT' |
            translate }}</div>
          <nh-datepicker>
            <input type="text" name="nextPregDueDate" class="form-control" (change)="isInvalidDate($event)"
              formControlName="nextPregDueDate" [maxDate]="today" [minDate]="minimumPregDate" bsDatepicker nh
              maxlength="10">
          </nh-datepicker>
        </nh-field>
      </section>
    </div>

    <!-- Medical Services for AFB, FC, and new individuals for RMB/C and FinReassessment -->
    <div  *ngIf="(((selectedIndividual.age < 19) || isY('data.pregnancyOne.isPregnant')) && (isAFBorFC || isApplyMedServicesAndMovedIn))
                      && ( ((ageGreaterThanEighteenOnTwentyThree === false) && isN('data.formerFosterCare')) 
                       || ageGreaterThanEighteenOnTwentyThree || !isEnabled('data.formerFosterCare'))">
      <div>
        <h5 class="section-header">
          {{ 'MEDICAL_SERVICES' | translate }}
        </h5>
        <hr>

        <section [formGroup]="getControl('data.medicalService')">
          <!-- not full coverage -->
          <div *ngIf = "!isFC">
            <nh-field id = "ad_receivedMedSer" *ngIf="isEnabled('data.medicalService.receivedMedSer')" [mandatory]="false"
              label="{{'MED_SER_RECEIVED' | translate }}"
              class="label-section">
              <select nh name="receivedMedSer" formControlName="receivedMedSer"
                [items]="tableCodeValue('YESNO', 'data.medicalService.receivedMedSer')"
                (change)="addDateReceivedMedSer($event)"></select>
            </nh-field>
            <nh-field id = "ad_dateReceivedMedSer" *ngIf="isY('data.medicalService.receivedMedSer')" [mandatory]="true"
              label="{{'MED_SER_DATE_RECEIVED' | translate }}"
              class="label-section">
              <div class="hint" *ngIf="isY('data.medicalService.receivedMedSer')">{{'MED_SER_DATE_RECEIVED_HINT' |
                translate}}
              </div>
              <nh-datepicker>
                <input type="text" name="dateReceivedMedSer" class="form-control" (change)="isInvalidDate($event)"
                  formControlName="dateReceivedMedSer" [maxDate]="today" bsDatepicker nh
                  maxlength="10">
              </nh-datepicker>
            </nh-field>
          </div>

          <!-- full coverage -->
          <div *ngIf = "isFC">
            <nh-field id = "ad_receivedMedSer" *ngIf="isEnabled('data.medicalService.receivedMedSer')" [mandatory]="false"
              label="{{'HAS_FC' | translate}} {{ selectedIndividual | fullname }} {{ 'MED_SER_RECEIVED_FC' | translate}}"
              class="label-section">
              <select nh name="receivedMedSer" formControlName="receivedMedSer"
                [items]="tableCodeValue('YESNO', 'data.medicalService.receivedMedSer')"
                (change)="addDateReceivedMedSer($event)"></select>
            </nh-field>
            <nh-field id = "ad_dateReceivedMedSer" *ngIf="isY('data.medicalService.receivedMedSer')" [mandatory]="true"
              label="{{'MED_SER_DATE_RECEIVED' | translate }}"
              class="label-section">
              <div class="hint" *ngIf="isY('data.medicalService.receivedMedSer')">{{'MED_SER_DATE_RECEIVED_HINT' |
                translate}}
              </div>
              <nh-datepicker>
                <input type="text" name="dateReceivedMedSer" class="form-control" (change)="isInvalidDate($event)"
                  formControlName="dateReceivedMedSer" [maxDate]="today" bsDatepicker nh
                  maxlength="10">
              </nh-datepicker>
            </nh-field>
          </div>
        </section>
      </div>
    </div> 


    <div *ngIf="usCitizen && !isRmbRmc && isEnabled('data.helpWithEmergencyServiceBills') 
                                && ( ((ageGreaterThanEighteenOnTwentyThree === false) && isN('data.formerFosterCare')) || ageGreaterThanEighteenOnTwentyThree || !isEnabled('data.formerFosterCare'))">
      <h5 class="section-header">{{'EMERGENCY_MEDICAL' | translate}}</h5>
      <hr>
      <section>
        <nh-field id = "ad_helpWithEmergencyServiceBills" *ngIf="isEnabled('data.helpWithEmergencyServiceBills')"
          label="{{'HAS_1' | translate}}{{selectedIndividual | fullname}} {{'EMERGENCY_STATIC_TEXT' | translate}}"
          class="label-section">
          <select nh name="helpWithEmergencyServiceBills" aria-label="helpWithEmergencyServiceBills"
            formControlName="helpWithEmergencyServiceBills"
            [items]="tableCodeValue('YESNO', 'data.helpWithEmergencyServiceBills')"></select>
        </nh-field>
      </section>
    </div>

  </div>
</nh-form>