import { DatePipe } from "@angular/common";
import {
  Component,
  DoCheck,
  HostListener,
  OnInit,
  ViewEncapsulation,
  ViewChild, ElementRef,
  OnDestroy
} from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "@app/gateway/services/api.service";
import { SigninService } from "@app/gateway/signin/signin.service";
import { ApplicationService } from "@app/services/application/application.service";
import { ModalService } from "@app/services/modal/modal.service";
import { ProcessService } from "@app/services/process.service";
import { StateService } from "@app/services/state/state.service";
import { JoyrideService } from "ngx-joyride";
import { UserProfileService } from "../user-profile.service";
import { TranslateService } from '@ngx-translate/core';
import { ServiceConfigService } from "@app/services/service-config.service";
import { Title } from '@angular/platform-browser';
import { toISO } from '@app/helpers/date.helpers';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { Subscription } from "rxjs";


@Component({
  selector: "nh-benefit-dashboard",
  templateUrl: "./benefit-dashboard.component.html",
  styleUrls: ["./benefit-dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})


export class BenefitDashboardComponent implements OnInit, DoCheck, OnDestroy {
  isOpenEnrollmentSW = true;
  applicationInProgress = false;
  applicationNum;
  isAppNum = false;
  rows = [];
  primaryPhone;
  householdData = [];
  assistingDetails;
  assisting = true;
  reportChangePopupSwitch: boolean;
  mcoMasterSwitch: boolean;
  vals = [];
  isRenew: Boolean = false;
  preterminprogress: Boolean = false;
  pretermInprogressCaseNum;
  renewResponse;
  renewIndivId;
  renewCaseNumber;
  linkMyCase = false;
  flags;
  response;
  isPartnerApp: Boolean = false;
  indivId;
  assistingPersonAddress = [];
  myApplications: any;
  appStatus: boolean;
  isConversionMode: boolean = false;
  mySubscription;
  renewalDueDate: any;
  replaceText = "Renewalduedate";
  screenHeight: number;
  screenWidth: number;
  isMobileView = false;
  mobileWidth = 480;
  renewalMode = false;
  showDetails = true;
  renewAppNum;
  hohId: any;
  personIdValue: Boolean = false;
  addressList;
  defaultLanguage;
  translatedLang;
  email;
  showConfirmModal = false;
  startTutorialModal = false;
  isIndivIdPresent = false;

  statusesEn = {
    DN: "Denied",
    TN: "Terminated",
    AP: "Approved",
    SB: "Submitted",
    ST: "In Progress",
    PE: "Pending",
    PP: "Premium Pending",
  };
  statusesSp = {
    DN: "Denegado",
    TN: "Terminado",
    AP: "Aprobado",
    SB: "Presentado",
    ST: "En curso",
    PE: "Pendiente",
    PP: "Prima pendiente",
  };
  detailsLiteral = "TABLE_DETAIL_DETAILS";

  columns = [
    {
      name: "TABLE_DETAIL_NUMBER",
      prop: "caseNum",
    },
    {
      name: "TABLE_DETAIL_TYPE",
      prop: "coverageType",
    },
    {
      name: "TABLE_DETAIL_STATUS",
      prop: "status",
    },
    {
      name: "TABLE_DETAIL_HEALTH_PLAN",
      prop: "health_plan",
    }
  ];

  public displayedColumns: string[] = this.columns.map(item => item.name);

  tiles = [
    {
      name: "readLetterOnline",
      header: "COVERAGE_OVERVIEW_HEADER_1",
      text: "COVERAGE_OVERVIEW_INFO_1",
      button: "WELCOME_BTN",
      click: () => this.toCommunications(),
      priority: 3,
    },
    {
      name: "changeToElectronicLetters",
      header: "COVERAGE_OVERVIEW_HEADER_2",
      text: "COVERAGE_OVERVIEW_INFO_2",
      button: "WELCOME_BTN",
      click: () => this.toCommunications(),
      priority: 3,
    },
    {
      name: "applyForCoverage",
      header: "COVERAGE_OVERVIEW_HEADER_3",
      text: "COVERAGE_OVERVIEW_INFO_3",
      button: "WELCOME_DESC3",
      click: () => this.applyNow(),
      priority: 1,
    },
    {
      name: "autoRenewMode",
      header: "COVERAGE_AUTORENEWAL_STATUS",
      text: "START_AUTORENEWAL_STATIC",
      button: "UPDATE_1",
      click: () => this.toAutoRenewal(),
      priority: 1,
    },
    {
      name: "caseError",
      header: "COVERAGE_OVERVIEW_HEADER_4",
      text: "COVERAGE_OVERVIEW_INFO_4",
      priority: 1,
    },
    {
      name: "siteError",
      header: "COVERAGE_OVERVIEW_HEADER_5",
      text: "COVERAGE_OVERVIEW_INFO_5",
      priority: 1,
    },
    {
      name: "noCoverageInformation",
      header: "COVERAGE_OVERVIEW_HEADER_6",
      text: "COVERAGE_OVERVIEW_INFO_6",
      priority: 1,
    },
    {
      name: "bccTreatment",
      header: "COVERAGE_OVERVIEW_HEADER_7",
      text: "COVERAGE_OVERVIEW_INFO_7",
      button: "UPLOAD_TEXT",
      click: () => this.upload(),
      priority: 1,
    },
    {
      name: "legalGuardian",
      header: "COVERAGE_OVERVIEW_HEADER_8",
      text: "COVERAGE_OVERVIEW_INFO_8",
      button: "UPLOAD_TEXT",
      click: () => this.upload(),
      priority: 2,
    },
    {
      name: "renewalMode",
      header: "COVERAGE_OVERVIEW_HEADER_9",
      text: "COVERAGE_OVERVIEW_INFO_9",
      priority: 1,
      showNudge: () => {
        if (this.renewalDueDate) {
          return true;
        }
        return false;
      },
    },
    {
      name: "renewalDueSoon",
      header: "COVERAGE_OVERVIEW_HEADER_13",
      text: "COVERAGE_OVERVIEW_INFO_13",
      priority: 1,
      showNudge: () => {
        return this.serviceConfigService.getServerConfig().upcomingRenewalNudge;
      },
    },
    {
      name: "pretermEnabled",
      header: "COVERAGE_OVERVIEW_HEADER_14",
      text: "COVERAGE_OVERVIEW_INFO_14",
      priority: 2,
    },
    {
      name: "uploadDoc",
      header: "COVERAGE_OVERVIEW_HEADER_10",
      text: "COVERAGE_OVERVIEW_INFO_10",
      button: "UPLOAD_TEXT",
      click: () => this.upload(),
      priority: 2,
    },
    {
      name: "linkMyCase",
      header: "COVERAGE_OVERVIEW_HEADER_11",
      text: "COVERAGE_OVERVIEW_INFO_11",
      button: "COVERAGE_DTL_11",
      click: () => this.linkCase(),
    },
    {
      name: "uploadDocAppeal",
      header: "COVERAGE_DTL_12",
      text: "DOCUMENTS_WE_NEED_STATIC_TEXT23",
      button: "UPLOAD_TEXT_NUDGE",
      click: () => this.upload(),
      priority: 1,
    }
  ];
  kbRows = [];
  kbColumns = [
    { name: "Name", prop: "name" },
    { name: "Date", prop: "aptDate" },
    { name: "Time", prop: "aptTime", width: 50 },
    { name: "Appointment Type", prop: "type" },
    { name: "Location", prop: "address" },
  ];
  kbPremiumRows = [];
  kbPremiumColumns = [
    { name: "Name", prop: "fullName" },
    { name: "Individual Premium Amount", prop: "premiumAmount" },
    { name: "Premium Due Date", prop: "dueDate" },
  ];

  isHeadOfHouseHold;
  isCaseAssigned;
  caseAssignedStatus;
  isHealthPlanSubmitted;
  isHealthPlanChanged;
  changeReqSummayRows = [];
  healthPlanRequestMemberDetails;
  phoneNumber;
  step1;
  step2;
  step3;
  programStatusNACheck = ["N/A", "n/a", "NA", "na"];
  groupedNames;
  groupedNamesData;
  preTermDueDate;
  isPretermEnabled = false;
  replacePreTermDateText = "PreTermDueDate";
  prtApplicationNum;
  public dataSource;
  preterm_Enable: boolean
  translateService$ : Subscription;

  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    public process: ProcessService,
    private stateService: StateService,
    public apiService: ApiService,
    private modalService: ModalService,
    private app: ApplicationService,
    private datePipe: DatePipe,
    private readonly joyrideService: JoyrideService,
    public signinService: SigninService,
    public translateService: TranslateService,
    private readonly serviceConfigService: ServiceConfigService,
    private titleService: Title
  ) {
    const accountType = sessionStorage.getItem("userType");
    this.isPartnerApp = accountType === "RP" ? true : false;
    this.signinService
      .getAccountData(sessionStorage.getItem("userName"))
      .subscribe((data) => {
        if (!this.isPartnerApp) {
          this.indivId = this.indivId ? this.indivId : data && data.individualId;
        } else {
          this.indivId = sessionStorage.getItem("individualId");
        }
        this.isIndivIdPresent = true;
        this.getBenefitDashboard();
      });
    this.getScreenSize();
    this.titleService.setTitle('Benefit Dashboard');
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }
  @ViewChild('step4') private step4: ElementRef;

  ngDoCheck() {
    this.isMobileView = this.screenWidth <= this.mobileWidth;
  }

  ngAfterViewInit(): void {
    this.userProfileService.sendStep4Info(this.step4);
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('currentUser') || sessionStorage.getItem('userName')) {
    this.isRenew = false;
    this.mcoMasterSwitch = this.serviceConfigService.getServerConfig().mcoMasterSwitch;
    this.reportChangePopupSwitch = this.serviceConfigService.getServerConfig().reportChangePopupSwitch;
    sessionStorage.removeItem("isRenew");
    sessionStorage.removeItem('isPreterm');
    this.stateService.resetStates().subscribe(() => this.onLoad());
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.translateService$ = this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.getBenefitDashboard();
      this.appStatus = JSON.parse(sessionStorage.getItem("appStatus"));
    });
    } else {
      this.signinService.signOut(false);
      this.router.navigateByUrl('/logout');
    }
    if (sessionStorage.getItem('appType') === 'APL') {
      sessionStorage.removeItem('appType')
    }
  }

  getKbData(ind) {
    let address, telephone, inPerson;
    if (this.translatedLang === 'en') {
      telephone = "Telephone",
        inPerson = "In-person"
    } else {
      telephone = "Teléfono",
        inPerson = "En persona"
    }
    const kbData = ind.kbInfo.map((kb) => kb.kbAppointment);
    return kbData?.filter(val => val !== null).flat().map((data) => {
      if (data.address === null && data.contactMethod === "Telephone") {
        address = telephone
      } else if (data.address === null && data.contactMethod === "In-person") {
        address = inPerson
      } else if (data.address !== null && data.contactMethod === "In-person") {
        address = inPerson + "<br/>" + data.address
      } else {
        address = data.address
      }
      return {
        address: address,
        aptTime: data.aptTime,
        aptDate: data.aptDate ? data.aptDate.split(/\s+/)[1] : "",
        name: data.name,
        type: data.aptType,
      };
    });
  }

  onLoad() {
    if (!this.isPartnerApp) {
      const firstTimeUser = sessionStorage.getItem('firstTimeAccessSw');
      if (firstTimeUser && firstTimeUser === "Y") {
        this.validateUser();
        this.startTutorialModal = true;
        this.tutorialModalPopup();
      }
    }
  }

  onNext() {
    this.process.setIsFromNextFAQ('true');
  }

  tutorialModalPopup(): void {
    if (this.startTutorialModal && this.showConfirmModal) {
      this.startTutorialModal = false
      this.openTutorial(); 
    }
    else if(!this.startTutorialModal && this.showConfirmModal){
      this.openReportChangeModal();
    } 
  }

  getBenefitDashboard(): void {
    sessionStorage.setItem('currentLang', this.translatedLang);
    if (!this.indivId) {
      this.appStatus = false;
      this.userProfileService
      .getBenefitDashboard(null)
      .subscribe((res: any) => {
         if (res) {
          this.response = { ...res };
          if (res?.myBenefitDashboardTiles) {
            let {applicationsInProgress} = this.response?.myBenefitDashboardTiles;
            let afbApplicationType = applicationsInProgress?.AFB && applicationsInProgress?.AFB?.length && applicationsInProgress?.AFB[0].applicationType;
            if (afbApplicationType === 'APPLY_BENEFITS') {
              this.checkAfbinProgress(applicationsInProgress);
            }
          }
          this.getActions();
        }
      });
      if (this.isIndivIdPresent && this.startTutorialModal) {
        this.openTutorial();
      }
    } else {
      this.userProfileService
        .getBenefitDashboard(this.indivId)
        .subscribe((res: any) => {
           if (res) {
            this.response = { ...res };
            this.isOpenEnrollmentSW =  res.cmbSummaryResponse?.caseSummaryResponse[0]?.openEnrollmentSW === 'Y' ? true: false;
            const convensionModeSW = res.cmbSummaryResponse?.caseSummaryResponse[0]?.convensionModeSW;
            this.hohId = this.response.cmbSummaryResponse ?.caseSummaryResponse[0] ?.hoHID;
            this.isHeadOfHouseHold = this.response.cmbSummaryResponse ?.caseSummaryResponse[0] ?.hoHSW === 'Y' ? true: false;
            this.isHealthPlanChanged = this.response.myBenefitDashboardTiles?.changeHealthPlan;
            this.caseAssignedStatus = this.response.cmbSummaryResponse?.caseSummaryResponse[0]?.indvSummaryResponse[0]?.mcoInfo;
            if(this.caseAssignedStatus?.length > 0){
              this.isCaseAssigned = true;
            }
            const { pretermInfo } = this.response?.myBenefitDashboardTiles;
            if (pretermInfo?.pretermEnabled) {
              this.isPretermEnabled = true;
              this.userProfileService.showHideQFR.next(this.isPretermEnabled);
              this.response.myBenefitDashboardTiles['pretermEnabled'] = true;
              this.preTermDueDate = pretermInfo?.pretermDueDate;
            }
            // const caseNumber = this.response.caseDetailsResponse.caseNum;
            // this.getRequestSummaryDetails(caseNumber);
            if (this.response) {
              const caseSummary = this.response.cmbSummaryResponse?.caseSummaryResponse;
              let apData = caseSummary?.filter(f => f.applicationStatusDesc === 'AP');
              const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
              if(currentUser?.accountTypeCd === 'CL'){
                this.apiService.post('indi/users/manageMyAccount', { 'userName': JSON.parse(sessionStorage.currentUser).userId}).subscribe((res) => {
                  let userDetails: any = res;
                  if (this.reportChangePopupSwitch) {
                    if (apData?.findIndex(f => f.openRenewalSW !== 'Y') > -1 &&
                      !this.userProfileService.isReportChangedModalOpenedForFirstTime && this.compareRMCPopUpDateWithCurrentDate(userDetails.popUpDate)) {
                      this.userProfileService.isReportChangedModalOpenedForFirstTime = true;
                      this.showConfirmModal = true
                      sessionStorage.setItem('confirmModal', 'true');
                      this.tutorialModalPopup();
                    }
                  }
                });
              }
             
              if(caseSummary?.findIndex(f => f.applicationStatusDesc === 'DN') > -1){               
                if(apData?.findIndex(f => f.openRenewalSW === 'N') > -1){
                  this.userProfileService.enableChangeReport.next(true);
                }
              }
              let caseIndiv;
              const currentIndivId = this.indivId ? this.indivId.toString() : sessionStorage.getItem('individualId');
              caseSummary?.forEach(element => {
                const searchedIndv = element.indvSummaryResponse.filter(indv => indv.indvId === currentIndivId);
                element['isKbA'] = searchedIndv?.filter(data => (( ((data.programStatus?.length && data.programStatus[0]?.coeCode === 'K01') && (this.getAge(data.dob)<=18)) || ((data.programStatus?.length && data.programStatus[0]?.coeCode === 'K03') && (this.getAge(data.dob)<=18)) )
                                                                    && data.programStatus?.length && data.programStatus[0]?.edgStatus !== 'DN')).length > 0;
                if(element['isKbA']){
                  element.hoHID = currentIndivId;
                }
                if (element.openRenewalSW === 'Y' && element.hoHID === currentIndivId) {
                caseIndiv = element;
                }
              });
              if (caseIndiv) {
                const { renewalMode } = this.response?.myBenefitDashboardTiles;
                const { hoHID, openRenewalSW } = caseIndiv;
                const showHideRenew = (currentIndivId === hoHID && openRenewalSW === 'Y') || renewalMode === true;
                this.userProfileService.hideRenewal(showHideRenew);
              }
            };
            const caseOpenRenewalSW = this.response ?.cmbSummaryResponse?.caseSummaryResponse[0] ?.openRenewalSW;
            if (caseOpenRenewalSW) sessionStorage.setItem('openRenewalSW', caseOpenRenewalSW)

            this.userProfileService.disableRmc(caseOpenRenewalSW);
            if (convensionModeSW === 'Y') {
              this.isConversionMode = true;
              // this.userProfileService.hideRenewal(true);
              this.userProfileService.disableRmc(convensionModeSW);
            } else {
              this.isConversionMode = false;
            }
            if (
              res.cmbSummaryResponse &&
              res.cmbSummaryResponse.caseSummaryResponse.length > 0
            ) {
              const summary = [...res.cmbSummaryResponse.caseSummaryResponse];
              const { renewalMode } = this.response?.myBenefitDashboardTiles;
              let {applicationsInProgress} = this.response?.myBenefitDashboardTiles;
              let afbApplicationType = applicationsInProgress?.AFB && applicationsInProgress?.AFB?.length && applicationsInProgress?.AFB[0].applicationType;
              let rmbApplicationType = applicationsInProgress?.RMB && applicationsInProgress?.RMB?.length && applicationsInProgress?.RMB[0].applicationType;
              let pretermApplicationType = applicationsInProgress?.PRT && applicationsInProgress?.PRT?.length && applicationsInProgress?.PRT[0].applicationType;
              if (this.isPretermEnabled && pretermApplicationType === 'PRETERMINATION') {
                this.checkQFRProgress(applicationsInProgress)
              } else if (!this.preterm_Enable && !this.isRenew && afbApplicationType === 'APPLY_BENEFITS') {
                this.checkAfbinProgress(applicationsInProgress);
              } else if (!this.preterm_Enable && afbApplicationType !== 'APPLY_BENEFITS' && rmbApplicationType === 'RENEW_BENEFITS') {
                this.checkRMBProgress(summary, applicationsInProgress, renewalMode);
              }

              let inds = [];
              let indvData = [];
              summary.forEach((sum, index) => {
                if (sum.renewalDueDate && new Date(sum.renewalDueDate) >= new Date() && sum.applicationStatusDesc === 'AP') {
                  sessionStorage.setItem('renewalDueDate', sum.renewalDueDate);
                  sum.renewalDueDate = sum.renewalDueDate.replace(/\s/, "T");
                  this.renewalDueDate = this.datePipe.transform(
                    sum.renewalDueDate,
                    "MM/dd/yyyy"
                  );
                }

                if(index === 0){
                  let caseHohY = sum.indvSummaryResponse.filter((item)=>item.hoHSW === 'Y');
                  let caseHohN = sum.indvSummaryResponse.filter((item)=>item.hoHSW === 'N');
                  let sortByDobHohN = caseHohN.sort((ind1, ind2) => ind1.dob - ind2.dob);
                  sum.indvSummaryResponse = caseHohY.concat(sortByDobHohN);
                }else{
                  sum.indvSummaryResponse.sort((ind1, ind2) => ind1.dob - ind2.dob);
                }
                let appRecievedDate = sum.applicationRecievedDate ? this.datePipe.transform(sum.applicationRecievedDate .split(/\s+/)[0], "MM/dd/yyyy") : null;

                inds = [
                  ...sum.indvSummaryResponse.map((ind) => {
                    return {
                      fullName: `${ind.firstName} ${ind?.middleInitial?ind.middleInitial:'' } ${ind?.lastName} ${ind?.suffixName ? ind.suffixName : ''}`,
                      status: this.getStatus(ind.programStatus?.length > 0 ? ind.programStatus[0]?.edgStatus : ""),
                      coverageType: ind.programStatus?.length > 0 ? ind.programStatus[0]?.highLevelProgram : "",
                      caseNum: sum.caseNum,
                      personId: ind.indvId,
                      dob: new Date(ind.dob),
                      coe: ind.programStatus?.length > 0 ? ind.programStatus[0]?.coeCode : "",
                      kbInfo: ind.kbInfo !== null ? ind.kbInfo : [],
                      premiumAmount: ind.initialPremiumAmt ? "$" + ind.initialPremiumAmt : null,
                      dueDate: ind.premiumDueDate ? this.datePipe.transform(ind.premiumDueDate.split(/\s+/)[0], "MM/dd/yyyy") : null,
                      appStatus: ind.programStatus?.length > 0 ? ind.programStatus[0]?.edgStatus : null,
                      encryptCaseNum: sum.encryptCaseNum,
                      encryptIndvNum: ind.encryptIndvNum,
                      health_plan: this.getMcoInfoValue(ind)
                    };
                  }),
                  ...inds,
                ];

                //for coverage-details table
                sum.indvSummaryResponse.forEach((ind) => {
                  indvData.push({
                      fullName: `${ind.firstName} ${ind?.middleInitial ? ind.middleInitial : ''} ${ind?.lastName} ${ind?.suffixName ? ind.suffixName : ''}`,
                      status: this.getStatusSw(ind),
                      coverageType: ind.programStatus?.length > 0 ? ind.programStatus[0].highLevelProgram : "",
                      caseNum: sum.caseNum,
                      personId: ind.indvId,
                      dob: new Date(ind.dob),
                      coe: ind.programStatus?.length > 0 ? ind.programStatus[0].coeCode : "",
                      cobSw: ind.programStatus?.length > 0 ? ind.programStatus[0].cobSw : "",
                      kbInfo: ind.kbInfo !== null ? ind.kbInfo : [],
                      premiumAmount: ind.initialPremiumAmt ? "$" + ind.initialPremiumAmt : null,
                      dueDate: ind.premiumDueDate ? this.datePipe.transform(ind.premiumDueDate.split(/\s+/)[0], "MM/dd/yyyy") : null,
                      appStatus: this.getAppStatusSw(ind),
                      appStatusOriginal: ind.programStatus?.length > 0 ? ind.programStatus[0]?.edgStatus : null,
                      encryptCaseNum: sum.encryptCaseNum,
                      encryptIndvNum: ind.encryptIndvNum,
                      health_plan: this.getMcoInfoValue(ind),
                      displayMsg: this.getDisplayMsg(ind),
                      gender: ind.genderDescription,
                      coverageBeginDate: ind.programStatus?.length > 0 ? ind.programStatus[0].coverageBeginDate ? this.datePipe.transform(ind.programStatus[0].coverageBeginDate.split(/\s+/)[0], "MM/dd/yyyy") : null :null,
                      coverageEndDate: ind.programStatus?.length > 0 ? ind.programStatus[0].coverageEndDate ? this.datePipe.transform(ind.programStatus[0].coverageEndDate.split(/\s+/)[0], "MM/dd/yyyy") : null : null,
                      appRecievedDate: appRecievedDate,
                      firstName: ind.firstName,
                      lastName: ind.lastName
                  });
                });
              });
              //*****//

              this.kbPremiumRows = inds.filter((ind) => ind.premiumAmount !== null);
              this.kbRows = [].concat(
                ...inds.map((ind) => this.getKbData(ind))
              );


              const sortByCov = [
                ...inds.sort((ind1, ind2) =>
                  ind1.coverageType > ind2.coverageType ? 1 : -1
                ),
              ];
              const sortByDob = [...sortByCov.sort((ind1, ind2) => ind1.dob - ind2.dob)];
              const tempArray = [];
              const tempArrayData = [];

              for (let i = 0; sortByDob.length > i; i++) {
                if (sortByDob[i].appStatus && !this.checkProgramStatus(sortByDob[i])) {
                  tempArray.push(sortByDob[i])
                }
              }
              this.rows = [...tempArray];

            // coverage-details table code
              for (let i = 0; indvData.length > i; i++) {
                if (indvData[i].appStatus) {
                  tempArrayData.push(indvData[i]);
                }
              };


              this.dataSource = JSON.parse(JSON.stringify(tempArrayData));

              Object.keys(this.dataSource).forEach((item) => {
                this.dataSource[item]['dob'] = this.getAge(this.dataSource[item]['dob'])
              });

              //grouping individual records with same indvid
              this.groupedNames = Object.values(this.dataSource.reduce((acc, obj) => {
                if(!acc[obj.personId]){
                  acc[obj.personId] = { id: acc.id, name: obj.fullName, firstName: obj.firstName, lastName: obj.lastName, 
                                        indvId: obj.personId, caserows: [], casenumbersArray: [] };
                  acc.id++;
                }
                if(!this.checkProgramStatus(obj)){
                  acc[obj.personId].caserows.push({fullName: obj.fullName,
                  status: obj.status,
                  coverageType: obj.coverageType,
                  caseNum: obj.caseNum,
                  personId: obj.personId,
                  dob: obj.dob,
                  coe: obj.coe,
                  cobSw: obj.cobSw,
                  kbInfo: obj.kbInfo,
                  premiumAmount: obj.premiumAmount,
                  dueDate: obj.dueDate,
                  appStatus: obj.appStatus,
                  appStatusOriginal: obj.appStatusOriginal,
                  encryptCaseNum: obj.encryptCaseNum,
                  encryptIndvNum: obj.encryptIndvNum,
                  health_plan: obj.health_plan,
                  displayMsg:obj.displayMsg,
                  gender: obj.gender,
                  coverageBeginDate: obj.coverageBeginDate,
                  coverageEndDate: obj.coverageEndDate,
                  appRecievedDate: obj.appRecievedDate
                  });

                  acc[obj.personId].casenumbersArray.push(obj.caseNum);
              }
                return acc;
              },{ id: 0 })).filter(obj => Object.keys(obj).length !== 0);

              this.groupedNames.sort((a, b) => {
                return parseInt(a.id) - parseInt(b.id);
              });

              this.groupedNames.forEach(obj => {
                this.sortTableCases(obj.caserows);
              });
              
              this.groupedNames = this.groupedNames.filter(data => data.caserows.length !== 0);
              this.groupedNamesData = this.groupedNames.map(item => {
                let { name, firstName, lastName, caserows, casenumbersArray } = item;
                casenumbersArray = casenumbersArray.filter((value, index, array) =>
                  array.indexOf(value) === index
                );
                return { name, firstName, lastName, caserows, casenumbersArray }
              });

              
              //grouping records with same case numbers for respective individual
              let groupedNameCaseStat = this.groupedNamesData.map(item => {
                let indvCaseStatus = {};
                item.caserows.forEach(data => {
                  if (!indvCaseStatus[data.caseNum]) {
                    indvCaseStatus[data.caseNum] = [];
                  }
                  indvCaseStatus[data.caseNum].push(
                    {
                      displayMsg: data.displayMsg,
                      appRecievedData: data.appRecievedDate,
                      coverageBeginDate: data.coverageBeginDate,
                      coverageEndDate: data.coverageEndDate,
                      caseNumber: data.caseNum,
                      appStatus: data.appStatus,
                      appStatusOriginal: data.appStatusOriginal,
                      coverageType: data.coverageType,
                      coe: data.coe,
                      rowData: data
                    });
                });

                let casestatus;
                casestatus = Object.entries(indvCaseStatus).map(([casenum, msgs]) => ({
                  [casenum]: msgs
                }));
                return { ...item, casestatus };
              });


              groupedNameCaseStat.forEach(obj => {
                obj.casestatus.forEach(casestatus => {
                  for (const key in casestatus) {
                    casestatus[key] = this.sortCaseStatus(casestatus[key]);
                  }
                })
              });

              let finalCDTData = groupedNameCaseStat.map((item) => ({
                name: item.name,
                firstName: item.firstName,
                lastName: item.lastName,
                casenumbersArray: item.casenumbersArray,
                caserows: item.caserows,
                casestatus: item.casestatus,
                sortedCaserows: item.caserows,
                statusMsgs: item.casestatus.flatMap(statusObj => statusObj[Object.keys(statusObj)[0]].map(
                  status => ({
                    caseNumber: status.caseNumber,
                    statusMsg: status.displayMsg,
                    coe: status.coe,
                    appRecievedDate: status.appRecievedData,
                    coverageBeginDate: status.coverageBeginDate,
                    coverageEndDate: status.coverageEndDate,
                    appStatus: status.appStatus,
                    appStatusOriginal: status.appStatusOriginal,
                    coverageType: status.coverageType,
                    rowData: status.rowData
                  })
                ))
              }));

              //logic for grey-tile to display status msgs
              finalCDTData.forEach((item)=> {
                item.tileCaserows = [];
                item.tileCaserows.push(this.sortCaseStatus(item.statusMsgs));
                let hasAPtile = item.tileCaserows[0].filter(row => row.rowData.appStatusOriginal === 'AP');
                let hasPEtile = item.tileCaserows[0].filter(row => row.rowData.appStatusOriginal === 'PE');
                let hasTNtile = item.tileCaserows[0].filter(row => row.rowData.appStatusOriginal === 'TN');
                let hasDNtile = item.tileCaserows[0].filter(row => row.rowData.appStatusOriginal === 'DN');
                let hasCNtile = item.tileCaserows[0].filter(row => row.rowData.appStatus === 'CN');
                let hasTNFutTile = [];
                let hasTNFutTileSameCoe = [];
                let hasAPandTNFutTileSameCoe = [];

                if (hasAPtile && hasAPtile?.length > 0) {
                  item.tileCaserows = hasAPtile;
                  item.tileCaserows = this.sortCaseStatusDate(item.tileCaserows, 'AP');
                  if (item.tileCaserows && item.tileCaserows.length > 1) {
                    item.tileCaserows = this.sortCaseStatusApplicationDate(item.tileCaserows);
                  }

                  if (hasTNtile && hasTNtile?.length > 0) {
                    hasTNFutTileSameCoe = hasTNtile.filter(row => ((item.tileCaserows[0].caseNumber === row.caseNumber) && row.coverageBeginDate && (item.tileCaserows[0].coe === row.coe)));
                    if (hasTNFutTileSameCoe && hasTNFutTileSameCoe.length > 1) {
                      hasTNFutTileSameCoe = this.sortCaseStatusApplicationDate(hasTNFutTileSameCoe);
                    }
                    if (hasTNFutTileSameCoe && hasTNFutTileSameCoe.length > 0) {
                      hasAPandTNFutTileSameCoe = item.tileCaserows.concat(hasTNFutTileSameCoe);
                    }
                  }

                  if (hasAPandTNFutTileSameCoe && hasAPandTNFutTileSameCoe?.length > 0) {
                    if ((this.addOneDay(hasTNFutTileSameCoe[0].coverageEndDate)) === new Date(item.tileCaserows[0].coverageBeginDate).getTime()) {
                      if (this.translatedLang === 'en') {
                        item.tileCaserows[0]['statusMsg'] = `<strong>Approved</strong> for ${item?.tileCaserows[0]?.coverageType} starting ${this.datePipe.transform(hasTNFutTileSameCoe[0].coverageBeginDate.split(/\s+/)[0], "MM/dd/yyyy")}`;
                      } else {
                        item.tileCaserows[0]['statusMsg'] = `<strong>Aprobado</strong> para ${item?.tileCaserows[0]?.coverageType} a partir del ${this.datePipe.transform(hasTNFutTileSameCoe[0].coverageBeginDate.split(/\s+/)[0], "MM/dd/yyyy")}`;
                      }
                    }
                  } else {
                    if (item.tileCaserows && item.tileCaserows[0]?.coverageBeginDate && (item.tileCaserows[0]?.coverageBeginDate !== null)) {
                      if (hasTNtile && hasTNtile.length > 0) {
                        hasTNFutTile = hasTNtile.filter(row => ((item.tileCaserows[0].caseNumber === row.caseNumber) && row.coverageBeginDate && (new Date(row.coverageEndDate) >= new Date())));
                        if (hasTNFutTile && hasTNFutTile.length > 1) {
                          hasTNFutTile = this.sortCaseStatusApplicationDate(hasTNFutTile);
                        }
                      }
                      item.tileCaserows = item.tileCaserows.concat(hasTNFutTile);
                    }
                  }


                  if (hasCNtile && hasCNtile?.length > 0) {
                    hasCNtile = this.sortCaseStatusDate(hasCNtile, 'CN');
                    let apNoCNTile = hasAPtile.filter(item => item.appStatus !== 'CN' && item.appStatusOriginal === 'AP');
                    apNoCNTile = this.sortCaseStatusDate(apNoCNTile, 'AP');
                    if (apNoCNTile && apNoCNTile.length > 1) {
                      apNoCNTile = this.sortCaseStatusApplicationDate(apNoCNTile);
                    }
                    let apNoCNTileDate = new Date(apNoCNTile[0]?.coverageBeginDate).getTime();
                    const cnTileDate = new Date(hasCNtile[0].coverageBeginDate).getTime();

                    if (apNoCNTile?.length === 0 && hasCNtile.length) {
                      if (this.translatedLang === 'en') {
                        item.tileCaserows[0]['statusMsg'] = `You have coverage now because you have an open appeal </br> `
                      } else {
                        item.tileCaserows[0]['statusMsg'] = `Usted tiene cobertura ahora porque tiene una apelación abierta </br>`
                      }
                    }
                    else if (apNoCNTileDate === cnTileDate) {
                      if (this.translatedLang === 'en') {
                        item.tileCaserows[0]['statusMsg'] = `You have coverage now because you have an open appeal </br> ` + item.tileCaserows[0]['statusMsg']
                      } else {
                        item.tileCaserows[0]['statusMsg'] = `Usted tiene cobertura ahora porque tiene una apelación abierta </br>` + item.tileCaserows[0]['statusMsg']
                      }
                    }
                    else if (cnTileDate > apNoCNTileDate) {
                      if (this.translatedLang === 'en') {
                        item.tileCaserows[0]['statusMsg'] = `You have coverage now because you have an open appeal </br> `
                      } else {
                        item.tileCaserows[0]['statusMsg'] = `Usted tiene cobertura ahora porque tiene una apelación abierta </br>`
                      }
                    }
                  }
                }
                else {
                  if (hasCNtile && hasCNtile?.length > 0) {
                    item.tileCaserows = hasCNtile;
                    if (item.tileCaserows && item.tileCaserows.length > 1) {
                      item.tileCaserows = this.sortCaseStatusApplicationDate(item.tileCaserows);
                    } else {
                      item.tileCaserows = item.tileCaserows.slice(0, 1);
                    }
                    if (this.translatedLang === 'en') {
                      item.tileCaserows[0]['statusMsg'] = `You have coverage now because you have an open appeal`
                    } else {
                      item.tileCaserows[0]['statusMsg'] = `Usted tiene cobertura ahora porque tiene una apelación abierta </br>`
                    }

                    if (hasTNtile && hasTNtile.length > 0) {
                      if (item.tileCaserows && item.tileCaserows.length > 1) {
                        item.tileCaserows = this.sortCaseStatusApplicationDate(item.tileCaserows);
                      } else {
                        item.tileCaserows = item.tileCaserows.slice(0, 1);
                      }
                      const todayDate = new Date();
                      todayDate.setHours(0, 0, 0, 0);
                      hasTNFutTile = hasTNtile.filter(row => ((item.tileCaserows[0].caseNumber === row.caseNumber)
                        && row.coverageBeginDate && (new Date(row.coverageEndDate) >= todayDate)));
                      if (hasTNFutTile && hasTNFutTile.length > 1) {
                        hasTNFutTile = this.sortCaseStatusApplicationDate(hasTNFutTile);
                      }
                      item.tileCaserows = item.tileCaserows.concat(hasTNFutTile);
                    }
                  }
                  else if (hasPEtile && hasPEtile?.length > 0) {
                    item.tileCaserows = hasPEtile;
                    item.tileCaserows = item.tileCaserows.slice(0, 1);

                  } else if (hasTNtile && hasTNtile?.length > 0) {
                    item.tileCaserows = hasTNtile;
                    item.tileCaserows = this.sortCaseStatusDate(item.tileCaserows, 'TN');
                    if (item.tileCaserows && item.tileCaserows.length > 1) {
                      item.tileCaserows = this.sortCaseStatusApplicationDate(item.tileCaserows);
                    } else {
                      item.tileCaserows = item.tileCaserows.slice(0, 1);
                    }

                  } else {
                    item.tileCaserows = hasDNtile;
                    item.tileCaserows = this.sortCaseStatusDate(item.tileCaserows, 'DN');
                    let tileDNCaserowsNoEnd = item.tileCaserows.filter(row => row.coverageBeginDate === null);
                    if (item.tileCaserows && item.tileCaserows.length === 0 && tileDNCaserowsNoEnd?.length === 0) {
                      item.tileCaserows = item.tileCaserows;
                    } else if (item.tileCaserows && item.tileCaserows.length > 1 && tileDNCaserowsNoEnd?.length === 0) {
                      item.tileCaserows = this.sortCaseStatusApplicationDate(item.tileCaserows);
                    } else {
                      item.tileCaserows = item.tileCaserows.slice(0, 1);
                    }
                  }
                }                 

              });

              finalCDTData.forEach(obj => {
                obj.caserows = []; 
                let greyTileCasenum = obj.tileCaserows[0].caseNumber;
                obj.casenumbersArray = obj.casenumbersArray.filter(item => item !== greyTileCasenum);
                obj.casenumbersArray.unshift(greyTileCasenum); 

                let finalCasestatusArray = obj.casestatus;
                let finalCasenumbersArray = obj.casenumbersArray;
                obj.casestatus = this.reorderCases(finalCasestatusArray, finalCasenumbersArray);
                obj.casestatus.forEach(casestatus => {
                  for (const key in casestatus) {
                    obj.caserows.push(this.sortCaseStatus(casestatus[key]));
                  }
                })
                obj.caserows = Array.prototype.concat.apply([], obj.caserows);
              });

              finalCDTData.forEach(obj => {
                obj['sortedCaserows'] = [];
                obj.caserows.forEach(caserow => {
                  obj['sortedCaserows'].push(caserow.rowData);
                })
              });

              //rearrange members by the casenumber displayed on first grey-tile
              let firstCDTCaseNum = finalCDTData[0]['casenumbersArray'][0];
              let sameCDTCaseNum = finalCDTData.filter(item => item.casenumbersArray.includes(firstCDTCaseNum));
              let otherCDTCaseNum = finalCDTData.filter(item => !item.casenumbersArray.includes(firstCDTCaseNum));
              let arrangedFinalCDTData = sameCDTCaseNum.concat(otherCDTCaseNum);

              //final data for coverage details table
              this.dataSource = arrangedFinalCDTData;              


              if (this.response?.caseDetailsResponse?.cellPhone) {
                this.phoneNumber = this.response.caseDetailsResponse.cellPhone;
              }
              else if (this.response?.caseDetailsResponse?.homePhone) {
                this.phoneNumber = this.response.caseDetailsResponse.homePhone;
              }
              else if (this.response?.caseDetailsResponse?.workPhone) {
                this.phoneNumber = this.response.caseDetailsResponse.workPhone;
              }

              if (this.response.cmbSummaryResponse?.caseSummaryResponse) {
                const summary = this.response.cmbSummaryResponse?.caseSummaryResponse
                const contactInfoArray = [];
                summary.forEach((sum) => {
                  sum.indvSummaryResponse.forEach(element => {
                    if (element?.hoHSW === 'Y') {
                      sum.casePhoneSummaryResponse.forEach(contact => {
                        if (contact.phoneNum !== null && contact.phoneType === 'Cell') {
                          element['primaryPhone'] = contact.phoneNum;
                        } else if (contact.phoneNum !== null && contact.phoneType === 'Home') {
                          element['primaryPhone'] = contact.phoneNum;
                        } else if (contact.phoneNum !== null && contact.phoneType === 'Work') {
                          element['primaryPhone'] = contact.phoneNum;
                        }
                      });
                      element['caseAddressSummaryResponse'] = sum.caseAddressSummaryResponse;
                      element['caseNum'] = sum.caseNum;
                      const userobj = JSON.parse(sessionStorage.getItem('currentUser'));
                      this.email = userobj ? userobj.emailId : null;
                      if (!contactInfoArray.includes(element.indvId)) {
                        this.householdData.push(element);
                        contactInfoArray.push(element.indvId);
                      }
                    }
                  });
                })
                const headOfHH: any = this.response?.caseDetailsResponse?.hoHSW === 'Y' ? true : false;
                const AppealSwitch = this.response?.myBenefitDashboardTiles?.pendingAplSw;
                 this.userProfileService.showHideMyAppeals.next(AppealSwitch);
                const showHideAppealsOrHealthPlan = this.response?.caseDetailsResponse ? true : false;
                this.userProfileService.enableAppealOrHealthPlan.next(showHideAppealsOrHealthPlan);
                sessionStorage.setItem('hoh', headOfHH);
                const householdDetails = { ...this.response?.caseDetailsResponse };
                this.assistingDetails = { ...householdDetails.assistingPersonResponse };
                this.assistingPersonAddress = this.response?.caseDetailsResponse?.addressResponse.filter(
                  (type) => type.addressType === "AA"
                );
              }
            }
            this.getActions();
          } else {
            const tile = this.tiles.filter((t) => t.name === "siteError");
            this.vals.push(tile[0]);
            this.showConfirmModal = true
            this.tutorialModalPopup();
          }
        });
    }
  }

  checkProgramStatus(individual) {
    let returnValue = false
    if(this.programStatusNACheck.includes(individual['coe']) || this.programStatusNACheck.includes(individual['appStatus']) || this.programStatusNACheck.includes(individual['coverageType'])){
       returnValue = true
    }
    return returnValue
  }

  getMcoInfoValue(ind){
    if(ind.mcoInfo){
      ind.mcoInfo.sort((a, b) => a.endDate - b.endDate);
      const mcoInfoFilter = ind.mcoInfo.filter((item) => toISO(item.endDate) >= toISO(new Date()));
      if(mcoInfoFilter.length> 0){
        let names = mcoInfoFilter[0].providerName;
        // for(let i =1; i< ind.mcoInfo.length; i++){
        //   names += "   " + ind.mcoInfo[i].providerName;
        // }
        return names;
      }
      return 'N/A';
    }
    return 'N/A';
  }

  checkAfbinProgress(applicationsInProgress) {
    let afbAppinProgress = applicationsInProgress?.AFB;
    if (afbAppinProgress?.length && afbAppinProgress[0].applicationNumber) {
    this.applicationNum = afbAppinProgress[0]?.applicationNumber;
    this.appStatus = true;
    sessionStorage.setItem("appNum", this.applicationNum);
    sessionStorage.setItem("appStatus", JSON.stringify(this.appStatus));
  } else {
    this.appStatus = false;
    sessionStorage.setItem("appNum", null);
  }
}

  checkQFRProgress(appInProgress) {
    if (!this.isPartnerApp) {
      let prtAppinProgress = appInProgress?.PRT;
      if (prtAppinProgress?.length && prtAppinProgress[0].applicationNumber) {
        this.preterminprogress = true;
        this.pretermInprogressCaseNum = prtAppinProgress[0].caseNumber
        this.prtApplicationNum = prtAppinProgress[0]?.applicationNumber;
        const { pretermInfo } = this.response?.myBenefitDashboardTiles;
        if (pretermInfo?.pretermEnabled) {
          this.preterm_Enable = pretermInfo?.pretermEnabled; 
          this.response.myBenefitDashboardTiles['pretermEnabled'] = false;
        }
      } else {
        this.preterminprogress = false;
      }
    }
  }

  checkRMBProgress(summ, appInProgress, renewalMode) {
    if (!this.isPartnerApp) {
      const caseDetails = summ.filter((res) => res.openRenewalSW === "Y");
      if (caseDetails.length > 0) {
        let rmbAppinProgress = appInProgress?.RMB;
            if (rmbAppinProgress?.length && rmbAppinProgress[0].applicationNumber) {
              const indivId = this.indivId ? JSON.stringify(this.indivId) : sessionStorage.getItem('individualId');
              if (indivId === this.hohId) {
                if (renewalMode) {
                  this.isRenew = true;
                  this.renewAppNum = rmbAppinProgress.applicationNumber;
                  this.preterminprogress = false;
                }
              }
            }
      }
    }
  }

  validateUser() {
    const data = {
      showTutorial: "N",
    };
    this.userProfileService.postTutorial(data).subscribe();
  }
  
  openReportChangeModal() {
    this.modalService
      .openReportChangeModal();
  }

  openTutorial() {
    this.modalService
      .openCoverageTutorial()
      .subscribe(this.startTutorial.bind(this));
  }

  startTutorial() {
    sessionStorage.removeItem('firstTimeAccessSw');
    const closeTutorial = sessionStorage.getItem('closeTutorial');
    const confirmModal = sessionStorage.getItem('confirmModal');
    if (closeTutorial !== "true") {
      this.userProfileService.step1Info.subscribe(data => {
        this.step1 = data;
      });
      this.userProfileService.step2Info.subscribe(data => {
        this.step2 = data;
      });
      this.userProfileService.step3Info.subscribe(data => {
      this.step3 = data;
    });
    const customTexts = this.translatedLang === 'en' ? { done: "Finish" } : {
      done: "Terminar",
      prev: 'Anterior',
      next: 'Siguiente'
    }
    if (!this.isMobileView) {
      this.joyrideService.startTour({
        steps: ["Step1", "Step2", "Step3", "Step4", "Step5"],
        showPrevButton: false,
        showCounter: false,
        customTexts,
      }).subscribe(data => {
        if (data.name === 'Step1') {
          this.step1.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
        if (data.name === 'Step2') {
          this.step2.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
        if (data.name === 'Step3') {
          this.step3.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
        if (data.name === 'Step4') {
          this.step4.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
      }, (_error) => { }, () => {
        if (this.showConfirmModal) {
          this.openReportChangeModal();
        }
      })
    } else {
      this.joyrideService.startTour({
        steps: ["Step6", "Step7", "Step8", "Step4", "Step9"],
        showPrevButton: false,
        showCounter: false,
        customTexts,
      });
    }
  } else if(confirmModal === 'true'){
    this.openReportChangeModal();
    sessionStorage.removeItem('closeTutorial');
    sessionStorage.removeItem('confirmModal');
  }
  sessionStorage.removeItem('closeTutorial');
  }

  private compareRMCPopUpDateWithCurrentDate(date?: string): boolean{
    if(!date){
      return true;
    }
    const savedDate = Date.parse(date);
    const currentDate = new Date();
    const dateDiff = (+currentDate - savedDate)/60/60/24/1000;
    if(Math.floor(dateDiff) > 90){
      return true;
    }
    return false;
  }

  getActions() {
    const indivId = sessionStorage.getItem("individualId");
    let actions = [];
    if (this.response && this.response.myBenefitDashboardTiles.errorCode == 500) {
      const tile = this.tiles.filter((t) => t.name === "siteError");
      this.vals.push(tile[0]);
    } else if (this.response && this.response.myBenefitDashboardTiles.errorCode == 0 && this.response.myBenefitDashboardTiles.noCoverageInformation === 'true') {
      const third = this.tiles.filter((t) => t.name === "noCoverageInformation");
      actions.push(third[0]);
      this.vals = [...actions];
    } else if (!indivId || indivId === "0" || indivId == "undefined" || indivId == "null") {
      this.linkMyCase = true;
      const tile = this.tiles.filter((t) => t.name === "linkMyCase" || t.name === "applyForCoverage");
      actions = [...tile];
      this.vals = [...actions];
    } else {
      this.flags = { ...this.response?.myBenefitDashboardTiles };
      const trueFlags = this.tiles.filter((tile) => {
        if(this.flags[tile.name]) {
          if(!tile.showNudge) {
            return true;
          }
          return tile.showNudge();
        }
        return false;
      });
      actions = [...trueFlags];
      if (actions.length > 2) {
        actions = actions
          .sort((val1, val2) => val1.priority - val2.priority)
          .slice(0, 2);
      }
      this.vals = [...actions];
    }
    if(this.response?.cmbSummaryResponse?.appealResponse?.indvAppealSummaryResponse && Array.isArray(this.response?.cmbSummaryResponse?.appealResponse?.indvAppealSummaryResponse)){
      let isValid = false;
      this.response.cmbSummaryResponse.appealResponse.indvAppealSummaryResponse.forEach(item => {
        if(item.appealTypeResponse && Array.isArray(item.appealTypeResponse)){
          item.appealTypeResponse.forEach(data =>{
            if(!isValid && data.vfdAiSubmitSW === 'Y'){
              isValid = true
            }
          })
        }
      })
      if(isValid){
        const third = this.tiles.filter((t) => t.name === "uploadDocAppeal");
        actions.push(third[0]);
        actions = actions
        .sort((val1, val2) => val1.priority - val2.priority)
      this.vals = [...actions];
      }

    }
    for (let item of this.vals) {
      (item.name == "renewalMode")
      this.renewalMode = true;
      sessionStorage.setItem("isRenew", "YES");
    }
  }

  getStatus(status) {
    if (this.translatedLang === 'sp') {
      return this.statusesSp[status];
    } else {
      return this.statusesEn[status];
    }
  }

  getDetails(row) {
    this.process.setFlagData(this.flags);
    this.router.navigateByUrl("/user-profile/coverage-details?individualId=" + row.encryptIndvNum + "&caseNumber=" + row.encryptCaseNum + "&coe=" + row.coe + "&coeStatus=" + row.appStatusOriginal);
  }

  continueApp() {
    if (this.isRenew) {
      this.getCaseDetails();
    } else {
      sessionStorage.setItem("appType", "AFB");
      this.stateService.continueApplication(true);
      this.router.navigateByUrl("/application/" + this.applicationNum);
    }
  }

  continuePretermApp() {
    sessionStorage.setItem("appType", "RB");
    sessionStorage.setItem("caseNum", this.pretermInprogressCaseNum);
    sessionStorage.setItem('isPreterm', "PRETERM");
    this.updateSessionStoreage(this.pretermInprogressCaseNum, this.prtApplicationNum);
    this.router.navigateByUrl("/application/" + this.prtApplicationNum);
  }

  getCaseDetails() {
    const indivId = sessionStorage.getItem("individualId");
    let caseNumber = sessionStorage.getItem("caseNum");
    this.userProfileService
      .getBenefitDashboard(indivId)
      .subscribe((res: any) => {
        if (res) {
          if (res?.caseDetailsResponse) {
            caseNumber = res?.caseDetailsResponse?.caseNum;
            this.checkAndNavigate(caseNumber);
          }
        }
      });
  }

  checkAndNavigate(caseNumber) {
    this.app
      .getHeadOfHouseholdInfo(caseNumber, "REDETERMINATION")
      .subscribe((res: any) => {
        this.updateSessionStoreage(caseNumber, res ?.applicationNumber);
        this.router.navigateByUrl("/application/" + res ?.applicationNumber);
      });
  }

  updateSessionStoreage(caseNumber, applicationNumber) {
    sessionStorage.setItem("appType", "RB");
    sessionStorage.setItem("caseNum", caseNumber);
    sessionStorage.setItem("appNum", applicationNumber);
  }

  applyNow() {
    sessionStorage.setItem("appType", "AFB");
    if (this.appStatus) {
      this.router.navigate(["/start", "welcomeback"]);
    } else {
      this.stateService.continueApplication(true);
      // this.ngOnInit(); // need to replace this code not good practice
      this.router.navigateByUrl("/application/" + "NEW");
    }
  }

  upload() {
    this.router.navigate(["user-profile/my-documents", "documents-we-need"]);
  }

  linkCase() {
    this.router.navigate(["user-profile", "case-link"]);
  }

  toCommunications() {
    this.router.navigateByUrl("/user-profile/communication-preferences");
  }
  
  toChangeHealthPlan () {
    this.router.navigateByUrl("/user-profile/healthplan-details");
}

  toAutoRenewal() {
    this.router.navigateByUrl("/user-profile/auto-renewal");
  }

  // getRequestSummaryDetails(caseNumber) {
  //   const url = `publ/mcoservices/mcochangerequestsummary/${caseNumber}`;
  //   this.apiService.get(url,true).subscribe((res) => {
  //     this.changeReqSummayRows = res.mcoChangeRequestSummaryResponse;
  //     this.isHealthPlanSubmitted = res && res.mcoChangeRequestSummaryResponse && res.mcoChangeRequestSummaryResponse[0]?.status === 'SUBMITTED' ? true : false;
  //   });
  // }

  getAge = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
        age--;
    }    
    return age;
  }


  getStatusSw(ind){
    if(ind.programStatus?.length > 0){
      if (ind.programStatus[0]?.cobSw === 'Y') {
       return this.translatedLang === 'en' ? 'Open on Appeal' :  " Abierto en apelación";
      
      } else {
        return this.getStatus(ind.programStatus?.length > 0 ? ind.programStatus[0]?.edgStatus : "")
      }
    }
  }

  getAppStatusSw(ind){
    if(ind.programStatus?.length > 0){
      if (ind.programStatus[0]?.cobSw === 'Y') {
        return "CN"
      } else {
        return ind.programStatus?.length > 0 ? ind.programStatus[0]?.edgStatus : "";
      }
    }
    return null;
  }


  getDisplayMsg(ind){
    if(ind.programStatus?.length > 0){
      if (ind.programStatus[0].edgStatus === 'AP') {
        if (ind.programStatus[0]?.coverageBeginDate) {
          if (this.translatedLang === 'en') {
            return `<strong>Approved</strong> for ${ind.programStatus[0].highLevelProgram} starting ${this.datePipe.transform(ind.programStatus[0]?.coverageBeginDate.split(/\s+/)[0], "MM/dd/yyyy")}`;
          } else {
            return `<strong>Aprobado</strong> para ${ind.programStatus[0].highLevelProgram} a partir del ${this.datePipe.transform(ind.programStatus[0]?.coverageBeginDate.split(/\s+/)[0], "MM/dd/yyyy")}`;
          }
        } else {
          if (this.translatedLang === 'en') {
            return `<strong>Approved</strong> for ${ind.programStatus[0].highLevelProgram}`;
          } else {
            return `<strong>Aprobado</strong> para ${ind.programStatus[0].highLevelProgram}`;
          }
        }

      } else if (ind.programStatus[0].edgStatus === 'PE') {
        if (this.translatedLang === 'en') {
          return `<strong>Pending</strong> for ${ind.programStatus[0].highLevelProgram}`;
        } else {
          return `<strong>Pendiente</strong> para ${ind.programStatus[0].highLevelProgram}`;
        }

      } else if (ind.programStatus[0].edgStatus === 'DN') {
        if (ind.programStatus[0]?.coverageBeginDate) {
          if (this.translatedLang === 'en') {
            return `<strong>Denied</strong> for ${ind.programStatus[0].highLevelProgram} starting ${this.datePipe.transform(ind.programStatus[0]?.coverageBeginDate.split(/\s+/)[0], "MM/dd/yyyy")}`;
          } else {
            return `<strong>Denegado</strong> para ${ind.programStatus[0].highLevelProgram} a partir del ${this.datePipe.transform(ind.programStatus[0]?.coverageBeginDate.split(/\s+/)[0], "MM/dd/yyyy")}`;
          }
        } else {
          if (this.translatedLang === 'en') {
            return `<strong>Denied</strong> for ${ind.programStatus[0].highLevelProgram}`;
          } else {
            return `<strong>Denegado</strong> para ${ind.programStatus[0].highLevelProgram}`;
          }
        }

      } else if(ind.programStatus[0].edgStatus === 'TN'){
        if (ind.programStatus[0]?.coverageEndDate && new Date(ind.programStatus[0]?.coverageEndDate) >= new Date() && ind.programStatus[0]?.coverageEndDate !== "2299-12-31") {
          if (this.translatedLang === 'en') {
            return `<strong>Terminated</strong> for ${ind.programStatus[0].highLevelProgram} ending ${this.datePipe.transform(ind.programStatus[0]?.coverageEndDate.split(/\s+/)[0], "MM/dd/yyyy")}`;
          } else {
            return `<strong>Cesado</strong> para ${ind.programStatus[0].highLevelProgram} terminando el ${this.datePipe.transform(ind.programStatus[0]?.coverageEndDate.split(/\s+/)[0], "MM/dd/yyyy")}`;
          }
        } else {
          if (this.translatedLang === 'en') {
            return `<strong>Terminated</strong> for ${ind.programStatus[0].highLevelProgram} ended ${this.datePipe.transform(ind.programStatus[0]?.coverageEndDate.split(/\s+/)[0], "MM/dd/yyyy")}`;
          } else {
            return `<strong>Cesado</strong> para ${ind.programStatus[0].highLevelProgram} terminó el ${this.datePipe.transform(ind.programStatus[0]?.coverageEndDate.split(/\s+/)[0], "MM/dd/yyyy")}`;
          }
        }
      }else{
        return null;
      }

    }else{
      return null;
    }
  }

  //sort records (AP, PE, TN, DN)
  sortCaseStatus(casestatus) {
    return casestatus.sort((a, b) => {
      const order = { "CN": 0, "AP": 0, "PE": 1, "TN": 2, "DN": 3 };
      return order[a.rowData.appStatus] - order[b.rowData.appStatus];
    })
  }

  //sort records by coverage begin date or end date
  sortCaseStatusDate(casestatus, appStatus){
    if (appStatus === 'AP') {
      casestatus.sort((a, b) => {
        return new Date(b.coverageBeginDate).getTime() - new Date(a.coverageBeginDate).getTime();
      });
      const latestDate = casestatus[0]?.coverageBeginDate;
      return casestatus = casestatus.filter(obj => obj?.coverageBeginDate === latestDate);
    }

    if (appStatus === 'CN') {
      casestatus.sort((a, b) => {
        return new Date(b.coverageBeginDate).getTime() - new Date(a.coverageBeginDate).getTime();
      });
      const latestDate = casestatus[0]?.coverageBeginDate;
      return casestatus = casestatus.filter(obj => obj?.coverageBeginDate === latestDate);
    }

    if (appStatus === 'TN') {
        casestatus.sort((a, b) => {
          return new Date(b.coverageEndDate).getTime() - new Date(a.coverageEndDate).getTime();
        });
        const latestDate = casestatus[0]?.coverageEndDate;
        return casestatus = casestatus.filter(obj => obj?.coverageEndDate === latestDate);
    }

    if (appStatus === 'DN') {
      let casestatusWithEnd = casestatus.filter(row => row.coverageBeginDate !== null);
      let casestatusWithNoEnd = casestatus.filter(row => row.coverageBeginDate === null);

      if (casestatusWithEnd && casestatusWithEnd?.length > 0 && (casestatusWithNoEnd && casestatusWithNoEnd?.length === 0)) {
        casestatus.sort((a, b) => {
          return new Date(b.coverageBeginDate).getTime() - new Date(a.coverageBeginDate).getTime();
        });
        const latestDate = casestatus[0]?.coverageBeginDate;
        return casestatus = casestatus.filter(obj => obj?.coverageBeginDate === latestDate);
      } else if (casestatusWithEnd && casestatusWithEnd?.length > 0 && (casestatusWithNoEnd && casestatusWithNoEnd?.length > 0)) {
        casestatus = casestatus.filter(row => row.coverageBeginDate !== null);
        casestatus.sort((a, b) => {
          return new Date(b.coverageBeginDate).getTime() - new Date(a.coverageBeginDate).getTime();
        });
        const latestDate = casestatus[0]?.coverageBeginDate;
        return casestatus = casestatus.filter(obj => obj?.coverageBeginDate === latestDate);
      } else {
        casestatus = casestatus.filter(row => row.coverageBeginDate === null);
        return casestatus = casestatus.slice(0,1);
      }
    } 

  }

  //sort records by application recieved date
  sortCaseStatusApplicationDate(casestatus){
    casestatus.sort((a,b) => {
      return new Date(b.appRecievedDate).getTime() - new Date(a.appRecievedDate).getTime();
    });
    const latestAppDate = casestatus[0]?.appRecievedDate; 
    casestatus = casestatus.filter(obj => obj?.appRecievedDate === latestAppDate);  
    if(casestatus && casestatus.length > 1){
      return casestatus = casestatus.slice(0,1);
    }else{
      return casestatus;
    }      
  }

  reorderCases(casestatusArray, casenumbersArray){
    return casenumbersArray.map(key => casestatusArray.find(obj => obj[key] !== undefined));
  }

  addOneDay(date){
    return new Date(date).setDate(new Date(date).getDate() + 1);
  }


  sortTableCases(caserows){
    let hasAP = caserows.filter(row => row.appStatus === 'AP');
    let hasPN = caserows.filter(row => row.appStatus === 'PN');
    let hasTN = caserows.filter(row => row.appStatus === 'TN');
    let hasDN = caserows.filter(row => row.appStatus === 'DN');
    let hasCN = caserows.filter(row => row.appStatus === 'CN');
    let dateA;
    let dateB;

    if(hasAP || (!hasPN && (hasTN || hasDN) || hasCN)){
      caserows.sort((a, b) => {
        if(hasAP && hasAP?.length>0){
          dateA = (a.coverageBeginDate) ? new Date(a.coverageBeginDate).getTime() : new Date(0);
          dateB = (a.coverageBeginDate) ? new Date(b.coverageBeginDate).getTime(): new Date(0);
        }else if(hasCN && hasCN?.length>0){
          dateA = (a.coverageBeginDate) ? new Date(a.coverageBeginDate).getTime() : new Date(0);
          dateB = (a.coverageBeginDate) ? new Date(b.coverageBeginDate).getTime(): new Date(0);
        }else if(hasTN && hasTN?.length>0){
          dateA = (a.coverageEndDate && a.coverageEndDate !== "2299-12-31") ? new Date(a.coverageEndDate).getTime(): new Date(0);
          dateB = (a.coverageEndDate && a.coverageEndDate !== "2299-12-31") ? new Date(b.coverageEndDate).getTime(): new Date(0);
        }else if(hasDN && hasDN?.length>0){
          dateA = (a.coverageBeginDate && a.coverageBeginDate !== "2299-12-31") ? new Date(a.coverageBeginDate).getTime(): new Date(0);
          dateB = (a.coverageBeginDate && a.coverageBeginDate !== "2299-12-31") ?  new Date(b.coverageBeginDate).getTime(): new Date(0);
        }
        
        let appdateA = new Date(a.appRecievedDate).getTime();
        let appdateB = new Date(b.appRecievedDate).getTime();

        if(dateA < dateB) return 1;
        if(dateA > dateB) return -1;

        if(appdateA < appdateB) return 1;
        if(appdateA > appdateB) return -1;

        return 0;
      });
    }else{
      return caserows;
    }
  }

  ngOnDestroy() {
    this.translateService$.unsubscribe();
  }
}