<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">

	<h5 class="section-header">Newborn Immigration Information</h5>
	<hr>
	<div>
		<section [formGroup]="getControl('data')">

			<nh-field id = "neim_eligibleImmigration" [mandatory]="true" label="Does {{babyName}} have a valid immigration status?" class="label-section">
				<select nh name="eligibleImmigration" aria-label="eligibleImmigration" [attr.disabled]="isEligible"
					[items]="tables.YESNO" formControlName="eligibleImmigration"></select>
			</nh-field>

			<div [formGroup]="getControl('data.immigration')" *ngIf="isY('data.eligibleImmigration')">

				<nh-field id = "neim_status" label="What is {{babyName }}'s immigration status?" class="label-section">
					<nh-popover class="pl-1" content="{{'IMMIGRATION_STATIC_TEXT20' | translate}}"></nh-popover>
					<select nh name="status" aria-label="status" *ngIf="!isPresumptive" [items]="tables.ALIENSTATUS"
						[attr.disabled]="isImmigrationDisabled" formControlName="status" (change)="otherStatus()"></select>
					<select nh name="status" *ngIf="isPresumptive" [items]="tables.ALIENSTATUS_PP"
						[attr.disabled]="isImmigrationDisabled" formControlName="status" (change)="otherStatus()"></select>
				</nh-field>
				<div *ngIf="isEnabled('data.immigration.statusGainDate')">
					<nh-field id = "neim_statusGainDate" [mandatory]='false' label="What date did {{babyName}} gain this status?" class="label-section">
						<nh-datepicker [readOnly]="isImmigrationDisabled">
							<input type="text" name="statusGainDate" class="form-control" [attr.disabled]="isImmigrationDisabled"
								(change)="isInvalidDate($event)" formControlName="statusGainDate" bsDatepicker nh [maxDate]="today"
								[minDate]="minDate">
						</nh-datepicker>
					</nh-field>
				</div>
			</div>

			<div [formGroup]="getControl('data.immigration.document')">
				<nh-field id = "neim_documentDescription" [mandatory]="documentDescription" *ngIf="isEnabled('data.immigration.document.documentDescription')"
				label="Document Description" class="label-section">
				<input aria-label="documentDescription" type="text" name="documentDescription" class="form-control"
					[attr.disabled]="isImmigrationDisabled" formControlName="documentDescription" maxlength="35">
			   </nh-field>
				<div *ngIf="getValue('data.immigration.status') && isY('data.eligibleImmigration') && (getValue('data.immigration.status').code != 'NA')  && (getValue('data.immigration.status').code != 'UA')">
				  <p class="hint semibold">{{'IMMIGRATION_STATIC_TEXT19' | translate}}</p>
				</div>

				<nh-field id = "neim_docType" [mandatory]="false" *ngIf="isEnabled('data.immigration.document.docType')"
					label="What is {{babyName}}'s immigration document type?" class="label-section">
					<select nh name="proxyDocType" aria-label="proxyDocType" [items]="tables.ALIENDOCTYPE"
						[attr.disabled]="isImmigrationDisabled" formControlName="docType"></select>
				</nh-field>

				<nh-field id = "neim_registrationNumber" [mandatory]="false" *ngIf="isEnabled('data.immigration.document.registrationNumber')"
					label="Alien Number" class="label-section">
					<div class="d-flex align-items-center"><span class="pl-2">A</span><span class="pl-2 pr-2"> -
						</span><input type="text" name="registrationNumber" aria-label="registrationNumber" class="form-control"
							[attr.disabled]="isImmigrationDisabled" formControlName="registrationNumber" mask="999999999">
					</div>
				</nh-field>

				<nh-field id = "neim_i94" [mandatory]="false" *ngIf="isEnabled('data.immigration.document.i94')" label="I-94 Number"
					class="label-section">
					<input type="text" aria-label="i94" name="i94" class="form-control" [attr.disabled]="isImmigrationDisabled"
						formControlName="i94" [required]="i94">
				</nh-field>

				<nh-field id = "neim_naturalizationCertificateNumber" *ngIf="isEnabled('data.immigration.document.naturalizationCertificateNumber')"
				[mandatory]="false" label="Naturalization Certificate Number" class="label-section">
					<input type="text" aria-label="naturalizationCertificateNumber" name="naturalizationCertificateNumber"
						class="form-control" [attr.disabled]="isImmigrationDisabled"
						formControlName="naturalizationCertificateNumber" maxlength="12">
				</nh-field>

				<nh-field id = "neim_certificateOfCitizenshipNumber" [mandatory]="false"
					*ngIf="isEnabled('data.immigration.document.certificateOfCitizenshipNumber')"
					label="Certificate of Citizenship" class="label-section">
					<input type="text" aria-label="certificateOfCitizenshipNumber" name="certificateOfCitizenshipNumber"
						class="form-control" [attr.disabled]="isImmigrationDisabled"
						formControlName="certificateOfCitizenshipNumber" maxlength="12">
				</nh-field>

				<nh-field id = "neim_sevisID" [mandatory]="false" *ngIf="isEnabled('data.immigration.document.sevisID')" label="SEVIS ID"
					class="label-section">
					<div class="d-flex align-items-center"><span class="pl-1">N</span><span class="pl-2 pr-2"> - </span>
						<input type="text" aria-label="sevisID" class="form-control" formControlName="sevisID" name="sevisID"
							maxlength="10">
					</div>
				</nh-field>

				<nh-field id = "neim_cardNumber" [mandatory]="false" *ngIf="isEnabled('data.immigration.document.cardNumber')"
					label="Receipt/Card Number" class="label-section">
					<input type="text" aria-label="cardNumber" name="cardNumber" class="form-control"
						[attr.disabled]="isImmigrationDisabled" formControlName="cardNumber" mask="SSS0000000000">
				</nh-field>

				<nh-field id = "neim_visa" [mandatory]="false" *ngIf="isEnabled('data.immigration.document.visa')" label="Visa Number"
					class="label-section">
					<input type="text" aria-label="visa" name="visa" class="form-control" [attr.disabled]="isImmigrationDisabled"
						formControlName="visa" minlength="8" mask="AAAAAAAA">
				</nh-field>

				<nh-field id = "neim_passport" [mandatory]="false" *ngIf="isEnabled('data.immigration.document.passport')" label="Passport Number"
					class="label-section">
					<input type="text" aria-label="passport" name="passport" class="form-control"
						[attr.disabled]="isImmigrationDisabled" formControlName="passport">
				</nh-field>

				<nh-field id = "neim_countryIssuance" [mandatory]="false" *ngIf="isEnabled('data.immigration.document.countryIssuance')"
					label="Country of Issuance" class="label-section">
					<select nh aria-label="countryIssuance" name="countryIssuance" [items]="tables.COUNTRY"
						[attr.disabled]="isImmigrationDisabled" formControlName="countryIssuance"
						[required]="countryIssuance"></select>
				</nh-field>

				<nh-field id = "neim_expDate" [mandatory]="false" label="Document/Passport Expiration Date"
					*ngIf="isEnabled('data.immigration.document.expDate')" class="label-section">
					<nh-datepicker [readOnly]="isImmigrationDisabled">
						<input type="text" aria-label="expDate" name="expDate" class="form-control"
							[attr.disabled]="isImmigrationDisabled" (change)="isInvalidDate($event)" formControlName="expDate"
							bsDatepicker nh [required]="expDate" [minDate]="today">
					</nh-datepicker>
				</nh-field>
			</div>

		</section>
	</div>
</nh-form>
