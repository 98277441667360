import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { isApplyForBenefits, isPartner, isPresumptiveApp } from '@app/helpers/mode.helpers';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { formatTableData } from '@app/helpers/tables.helpers';
import { or } from 'ramda';
import { Title } from '@angular/platform-browser';
import { ModalService } from '@app/services/modal/modal.service';
import { formatDate } from '@angular/common';
import { ServiceConfigService } from '@app/services/service-config.service';

@Component({
  templateUrl: './choose-healthplan.component.html',
  styleUrls: ['./choose-healthplan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseHealthPlanComponent extends SectionFormDirective implements AfterContentInit {
  isAFB;
  isPresumptive;
  isPartner: any;
  permissionLabel;
  isFullMedicAidApp: any;
  isFinReassessment = false;
  defaultLanguage;
  translatedLang;
  currentDate = formatDate(new Date(), 'MM/dd/yyyy', 'en');
  showHealthPlanText: boolean = false;
  hideHealthPlanText: boolean = true;
  constructor(private titleService: Title, builder: FormBuilder,
     public translateService: TranslateService, private readonly modalService: ModalService, private readonly serviceConfigService: ServiceConfigService) {
    super(builder);
    this.titleService.setTitle('Choose Health Plan');
    this.checkHealthPlanFutureDate();
  }

  ngAfterContentInit() {
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);
    if (this.defaultLanguage !== 'en') {
     
    } else {
     
    }
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.languageCheck(res.lang);
      if (res.lang === 'en') {
       
      } else {
       
      }
    });

    const userType = sessionStorage.getItem('userType');
    this.isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));
    this.isPresumptive = isPresumptiveApp(this.applicationType);
    this.isAFB = isApplyForBenefits(this.applicationType);
    this.isPartner = isPartner(userType);
    this.isFullMedicAidApp = or(this.isPartner, this.isAFB);
  
  }


  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  tableCodeValue (tableName, tableControl){
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }

  openHealthPlansModal():void{
    this.modalService.openHealthPlanTypes();
  }

  checkHealthPlanFutureDate(): void {
    const fDate = new Date(this.serviceConfigService.getServerConfig().wellpointEffDate);
    const cDate = new Date(this.currentDate);
    if (cDate >= fDate) {
      this.showHealthPlanText = true
      this.hideHealthPlanText = false
    }
  }
}
