<p *ngIf="isRmbRmc && !isAddAnother">{{ "RENEWAL_TEXT" | translate }}</p>
<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">

  <h6 *ngIf="!isPresumptive" class="person-label">{{'HOH' | translate }}: <span class="selectedIndName">{{fullName}}</span></h6>

  <h5 class="section-header">{{'IMMIGRATION_INFO' | translate}}</h5>
  <hr>
  <p *ngIf="!isPresumptive" class="pb-3">{{'IMMIGRATION_STATIC_TEXT1' | translate}}</p>
  <p *ngIf="!isPresumptive" class="pb-3">{{'IMMIGRATION_STATIC_TEXT2' | translate}}</p>
  <p *ngIf="!isPresumptive" class="pb-3">{{'IMMIGRATION_STATIC_TEXT3' | translate}} <span>{{selectedIndividual | fullname}}</span> {{'IMMIGRATION_STATIC_TEXT4' | translate}}
    <span>{{selectedIndividual | fullname}}</span> {{'IMMIGRATION_STATIC_TEXT5' | translate}} </p>

  <div>
    <section [formGroup]="getControl('data')">

      <div class="col-lg-10">
      <nh-field id = "imm_eligibleImmigration" [mandatory]="true" [isRow]="true"
        label="{{'IF' | translate}} {{selectedIndividual | fullname}} {{'IMMIGRATION_STATIC_TEXT6' | translate}}" class="label-section">
        <nh-popover class="pl-1" content="{{'IMMIGRATION_STATIC_TEXT7' | translate}}"></nh-popover>
        <select nh name="eligibleImmigration" aria-label="eligibleImmigration" [attr.disabled]="isEligible" [items]="tables.YESNOPNA"
          formControlName="eligibleImmigration"></select>
        <small class="form-text text-muted">{{'IMMIGRATION_STATIC_TEXT8' | translate}}</small>
      </nh-field>
      </div>

      <div [formGroup]="getControl('data.immigration')" *ngIf="isY('data.eligibleImmigration')">

        <nh-field id = "imm_status" label="{{'WHAT_IS_2' | translate}} {{selectedIndividual | fullname}}{{'IMMIGRATION_STATIC_TEXT9' | translate}}?" class="label-section">
          <nh-popover class="pl-1" content="{{'IMMIGRATION_STATIC_TEXT20' | translate}}"></nh-popover>
          <select nh name="status" *ngIf="!isPresumptive" aria-label="status"[items]="tables.ALIENSTATUS" [attr.disabled]="isImmigrationDisabled"
            formControlName="status" (change)="otherStatus()"></select>
          <select nh name="status" *ngIf="isPresumptive" aria-label="status" [items]="tableCodeValue('ALIENSTATUS_PP', 'data.immigration.status')" [attr.disabled]="isImmigrationDisabled"
            formControlName="status" (change)="otherStatus()"></select>
        </nh-field>
        <div *ngIf="isEnabled('data.immigration.statusGainDate')">
          <nh-field id = "imm_statusGainDate" [mandatory]= 'false' label="{{'IMMIGRATION_STATIC_TEXT10' | translate}} {{selectedIndividual | fullname}} {{'IMMIGRATION_STATIC_TEXT11' | translate}}" class="label-section">
            <nh-datepicker [readOnly]="isImmigrationDisabled">
              <input type="text" name="statusGainDate" class="form-control" [attr.disabled]="isImmigrationDisabled"
              (change)="isInvalidDate($event)" formControlName="statusGainDate" bsDatepicker nh [maxDate]="today" [minDate]="minDate">
            </nh-datepicker>
          </nh-field>
        </div>
      </div>

      <div [formGroup]="getControl('data.immigration.document')">
        <nh-field id = "imm_documentDescription" [mandatory]='true' *ngIf="isEnabled('data.immigration.document.documentDescription')"
        label="{{'IMMIGRATION_STATIC_TEXT12' | translate}}" class="label-section">
          <input type="text" name="documentDescription" class="form-control" aria-label="documentDescription" [attr.disabled]="isImmigrationDisabled"
            formControlName="documentDescription" maxlength="35">
        </nh-field>
        <div *ngIf="getValue('data.immigration.status') && isY('data.eligibleImmigration') && (getValue('data.immigration.status').code != 'NA')  && (getValue('data.immigration.status').code != 'UA')">
          <p class="hint semibold">{{'IMMIGRATION_STATIC_TEXT19' | translate}}</p>
        </div>

        <nh-field id = "imm_docType" *ngIf="isEnabled('data.immigration.document.docType')"
          label="{{'IMMIGRATION_STATIC_TEXT13' | translate}} {{selectedIndividual | fullname}}{{'IMMIGRATION_STATIC_TEXT14' | translate}}?" class="label-section" [mandatory]="false">
          <select nh name="proxyDocType" aria-label="proxyDocType" [items]="tables.ALIENDOCTYPE" [attr.disabled]="isImmigrationDisabled"
            formControlName="docType"></select>
        </nh-field>

       

        <nh-field id = "imm_registrationNumber" [mandatory]="false" *ngIf="isEnabled('data.immigration.document.registrationNumber')"
          label="{{'ALIEN_NUMBER' | translate}}" class="label-section">
          <div class="d-flex align-items-center alienNumber"><span class="pl-2 pr-3 tab-section">A </span>
            <input type="text" name="registrationNumber" class="form-control" [attr.disabled]="isImmigrationDisabled"
                formControlName="registrationNumber" mask="999999999">
            </div>
        </nh-field>

        <nh-field id = "imm_i94" [mandatory]="false" *ngIf="isEnabled('data.immigration.document.i94')" label="{{'I-94' | translate}}" class="label-section">
          <input type="text" name="i94" class="form-control" aria-label="i94" [attr.disabled]="isImmigrationDisabled"
            formControlName="i94" maxlength="11">
        </nh-field>
        <nh-field id = "naturalizationCertificateNumber" *ngIf="isEnabled('data.immigration.document.naturalizationCertificateNumber')" [mandatory]="false" label="{{'NATURALIZATION_NUMBER' | translate}}" class="label-section">
          <input type="text" name="naturalizationCertificateNumber" class="form-control" aria-label="naturalizationCertificateNumber"
            [attr.disabled]="isImmigrationDisabled" formControlName="naturalizationCertificateNumber"
            maxlength="12">
        </nh-field>

        <nh-field id = "imm_certificateOfCitizenshipNumber" [mandatory]="false"
          *ngIf="isEnabled('data.immigration.document.certificateOfCitizenshipNumber')"
          label="{{'CITIZENSHIP_CERTIFICATE' | translate}}" class="label-section">
          <input type="text" name="certificateOfCitizenshipNumber" class="form-control" aria-label="certificateOfCitizenshipNumber"
            [attr.disabled]="isImmigrationDisabled" formControlName="certificateOfCitizenshipNumber"
            maxlength="12">
        </nh-field>

        <nh-field id = "imm_sevisID" [mandatory]="false" *ngIf="isEnabled('data.immigration.document.sevisID')" label="{{'SEVIS_ID' | translate}}" class="label-section">
          <div class="d-flex align-items-center"><span class="pl-1">N</span><span class="pl-2 pr-2"> - </span> <input
              type="text" class="form-control" formControlName="sevisID" name="sevisID" placeholder="XXX-XXX-XXXX"
              mask="000-000-0000">
          </div>
        </nh-field>

        <nh-field id = "imm_cardNumber" [mandatory]="false" *ngIf="isEnabled('data.immigration.document.cardNumber')"
        label="{{'RECEIPT' | translate}}" class="label-section">
          <input type="text" name="cardNumber" class="form-control" aria-label="cardNumber" [attr.disabled]="isImmigrationDisabled"
            formControlName="cardNumber" placeholder="SSS0000000000" mask="SSS0000000000">
        </nh-field>

        <nh-field id = "imm_visa" [mandatory]="false" *ngIf="isEnabled('data.immigration.document.visa')" label="{{'VISA_NUMBER' | translate}}" class="label-section">
          <input type="text" name="visa" class="form-control" [attr.disabled]="isImmigrationDisabled"
            formControlName="visa" minlength="8" mask="AAAAAAAA">
        </nh-field>

        <nh-field id = "imm_passport" [mandatory]="false" *ngIf="isEnabled('data.immigration.document.passport')"
        label="{{'PASSPORT_NUMBER' | translate}}" class="label-section">
          <input type="text" name="passport" maxlength="20" class="form-control" aria-label="passport" [attr.disabled]="isImmigrationDisabled"
            formControlName="passport">
        </nh-field>

        <nh-field id = "imm_countryIssuance" [mandatory]="false" *ngIf="isEnabled('data.immigration.document.countryIssuance')"
          label="{{'COUNTRY_ISSUANCE' | translate}}" class="label-section">
          <select nh name="countryIssuance" aria-label="countryIssuance" [items]="tableCodeValue('COUNTRY', 'data.immigration.document.countryIssuance')"  [attr.disabled]="isImmigrationDisabled"
            formControlName="countryIssuance" [required]="countryIssuance"></select>
        </nh-field>

        <nh-field id = "imm_expDate" [mandatory]="false" label="{{'DOCUMENT_PASSPORT_EXPIRATION' | translate}}"
          *ngIf="isEnabled('data.immigration.document.expDate')" class="label-section">
          <nh-datepicker [readOnly]="isImmigrationDisabled">
            <input type="text" name="expDate" class="form-control" [attr.disabled]="isImmigrationDisabled"
              formControlName="expDate" bsDatepicker nh [required]="expDate" [minDate]="today">
          </nh-datepicker>
        </nh-field>
      </div>

    </section>
  </div>
  <div *ngIf="!isPresumptive">
    <h5 class="section-header">
     {{'MILITARY_INFORMATION' | translate}}
    </h5>
    <hr>
    <section [formGroup]="getControl('data.militaryInfo')">
      <nh-field  id = "imm_isVeteranOrActiveDuty" *ngIf="isMilitaryEligible"
        label="{{'IS_1' | translate}}{{ selectedIndividual | fullname }} {{'IS_VETERAN' | translate}}" [mandatory]="false" class="label-section">
        <select nh name="isVeteranOrActiveDuty" aria-label="isVeteranOrActiveDuty" formControlName="isVeteranOrActiveDuty" [items]="tableCodeValue('YESNO', 'data.militaryInfo.isVeteranOrActiveDuty')"></select>
      </nh-field>

      <nh-field  id = "imm_isSpouseVeteranOrActiveDuty" *ngIf="isMilitaryEligible"
        label="{{'IMMIGRATION_STATIC_TEXT15' | translate}} {{ selectedIndividual | fullname }}{{'IMMIGRATION_STATIC_TEXT16' | translate}}" [mandatory]="false" class="label-section">
        <select nh name="isSpouseVeteranOrActiveDuty" aria-label="isSpouseVeteranOrActiveDuty" formControlName="isSpouseVeteranOrActiveDuty"
        [items]="tableCodeValue('YESNO', 'data.militaryInfo.isSpouseVeteranOrActiveDuty')"></select>
      </nh-field>
      <nh-field id = "imm_isParentVeteranOrActiveDuty" *ngIf="parentalEligibility && isEnabled('data.militaryInfo.isParentVeteranOrActiveDuty')"
        label="{{'IMMIGRATION_STATIC_TEXT17' | translate}} {{ selectedIndividual | fullname }} {{'IMMIGRATION_STATIC_TEXT18' | translate}}"
        [mandatory]="false" class="label-section">
        <select nh name="isParentVeteranOrActiveDuty" aria-label="isParentVeteranOrActiveDuty" formControlName="isParentVeteranOrActiveDuty"
        [items]="tableCodeValue('YESNO', 'data.militaryInfo.isParentVeteranOrActiveDuty')"></select>
      </nh-field>
    </section>
  </div>
</nh-form>
