import { AfterContentInit, Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { Tables } from '@app/models/tables.model';
import { StorageService } from '@app/services/storage/storage.service';
@Component({
  selector: 'nh-name-address',
  templateUrl: './name-address.component.html'
})
export class NameAddressComponent implements AfterContentInit {
  @Input() personalName: FormGroup;
  @Input() address: FormGroup;
  @Input() tables: Tables | any;
  @Input() nameMandatory = true;
  @Input() addressFieldMandatory = true;
  @Input() addressFormat: FormControl;
  @Input() isDisabled = false;
  @Input() isAssistingPerson = false;
  @Input() showPopup = true;
  @Input() mailing = false;
  @Input() stateDefault = true;
  @Input() namePopup = false;
  @Input() rmcPopUp = false;
  @Input() isRMB;
  @Input() isDemographics = false;
  @Input() selectedIndividualPopup = false;
  @Input() hideCounty;
  @Input() isExported = false;
  @Input() addressname: string;
  tableSuffix;
  isRmbRmc = false;
  addCLick = false;
  isDisplayIndvPop = false;

  isPresumptive = false;
  appType = sessionStorage.getItem('appType');
  isKepro: any;
  maxLengthAddressLine1 = "50";
  maxLengthAddressLine2 = "75";

  constructor(public storageService: StorageService) { }

  ngAfterContentInit() {
    this.personalName?.controls["lastName"]?.patchValue(this.personalName?.controls["lastName"]?.value?.replace(/[^\x00-\x7F]/g, " "));
    this.isKepro = JSON.parse(sessionStorage.getItem('isKepro'));
    const userType = sessionStorage.getItem('userType');
    this.isRmbRmc = this.appType === 'RB' || this.appType === 'RC' || this.appType === 'REDETERMINATION' || this.appType === 'CHANGE_REPORT';
    this.storageService.getAddPersonClicked().subscribe((isAddPersonClicked: boolean) => {
      this.isDisplayIndvPop = isAddPersonClicked && this.isRmbRmc && this.isDemographics;
    })
    if (this.isKepro || userType === 'RP') {
      const tableData = this.tables.NAMESUFFIX;
      this.tableSuffix = tableData.sort((a, b) => (Number(a.code) > Number(b.code) ? 1 : -1));
    } else {
      const tableData = this.tables.NAMESUFFIX ? this.tables.NAMESUFFIX.map(name => {
        return {
          code: name.code,
          value: name.description,
          spValue: name.spDescription
        }
      }) : [];
      this.tableSuffix = tableData.sort((a, b) => (Number(a.code) > Number(b.code) ? 1 : -1))
    }

    this.isPresumptive = this.appType === 'HPE' || this.appType === 'PRG' || this.appType === 'BCC';
    if (userType !== 'RP') {
    this.maxLengthAddressLine1 = this.isAssistingPerson ? "50" : "50";
    this.maxLengthAddressLine2 = this.isAssistingPerson ? "50" : "75"
    }
    
  }

  isDisplayPopUp(): boolean {
    return (this.isRmbRmc && this.namePopup) || this.rmcPopUp || this.isDisplayIndvPop || this.selectedIndividualPopup;
  }
}
