<nh-form [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
  <h5 class="section-header">{{'APPEAL_CATEGORY' | translate}}</h5>
  <hr>
  <section [formGroup]="getControl('data')">
    <div class="d-none d-lg-block">
      <table class="table w-100 table-striped">
        <thead>
          <tr>
            <th scope="col">{{'Name' | translate}}</th>
            <th scope="col">{{'PERSON_ID' | translate}}</th>
            <th scope="col">{{'coverageType' | translate}}</th>
          </tr>
        </thead>
        <tbody formArrayName="appealCategory">
          <tr *ngFor="let coverage of appealCategory.controls; index as i;" [formGroupName]="i">
            <td>{{appealCategoryValues[i].memberName}}</td>
            <td>{{appealCategoryValues[i].personId}}</td>
            <td> <nh-field id="coverageType" [isRow]="true">
                <select nh name="selectedCoverageType" formControlName="selectedCoverageType" aria-label="selectedCoverageType"
                  [items]="appealCategoryValues[i].availableCoverageTypes" empty="Pick an option"></select>
              </nh-field></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="nh-table-mobile d-lg-none">
      <div formArrayName="appealCategory">
      <div class="table-row mb-3 pb-3" *ngFor="let coverage of appealCategory.controls; index as i;" [formGroupName]="i">
        <div class="mobile-table-header">{{selectAppelantsColumns[0].name | translate}}</div>
        <hr>
        <div class="mobile-table-data">{{appealCategoryValues[i].memberName}}</div>
        <br>
        <div class="mobile-table-header">{{selectAppelantsColumns[1].name | translate}}</div>
        <hr>
        <div class="mobile-table-data">{{appealCategoryValues[i].personId}}</div>
        <br>
        <div class="mobile-table-header">{{coverageType | translate}}</div>
        <hr>
        <div class="mobile-table-data">
          <select nh name="selectedCoverageType" formControlName="selectedCoverageType" aria-label="selectedCoverageType"
                  [items]="appealCategoryValues[i].availableCoverageTypes" empty="Pick an option"></select>
        </div>
      </div>
      </div>
    </div>
    <!--Eligibility Appeal-->
    <div class="row" *ngIf="isEnabled('data.nursingFacilityOrHCBS')">
      <div class="col">
        <nh-field class="label-section" label="{{'APPEAL_DETAILS_FORM_TEXT14' | translate }}" [mandatory]="true">
          <select nh name="nursingFacilityOrHCBS" [items]="tableCodeValue('APFACILITY', 'data.nursingFacilityOrHCBS')" aria-label="nursingFacilityOrHCBS" formControlName="nursingFacilityOrHCBS"></select>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.benefitChgTxt')">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT1' | translate }}" [mandatory]="true">
          <nh-count>
            <textarea (input)="onInput($event)" maxlength="2000" placeholder="{{startTypingPlaceHolder}}" class="form-control" name="benefitChgTxt"
              formControlName="benefitChgTxt" aria-label="benefitChgTxt" rows="5" autofocus="true"
              style="margin-top:5px"></textarea>
          </nh-count>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.applyDateSW')">
      <div class="col">
        <nh-field class="label-section"
          label="{{'APPEAL_DETAILS_FORM_TEXT10' | translate: {date: applyDateForBenefit |
          translate} }}" [mandatory]="true">
          <select nh name="applyDateSW" [items]="tableCodeValue('YESNO', 'data.applyDateSW')" aria-label="applyDateSW" formControlName="applyDateSW" (change)="onChange('applyDateSW')"></select>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="(isN('data.applyDateSW')) && isEnabled('data.applyDateRangeTxt')">
      <div class="col">
        <p class="static-text" [innerHTML]="'APPEAL_DETAILS_FORM_TEXT6' | translate"></p>
        <nh-field label class="label-section" [mandatory]="true">
          <input  (input)="onInput($event)" maxlength="25" type="text" placeholder="{{startTypingPlaceHolder}}" name="applyDateRangeTxt"
            aria-label="applyDateRangeTxt" formControlName="applyDateRangeTxt" class="form-control">
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.denialSW')">
      <div class="col">
        <nh-field class="label-section" label="{{'APPEAL_DETAILS_FORM_TEXT7' | translate }}" [mandatory]="true">
          <select nh name="denialSW" [items]="tableCodeValue('YESNO', 'data.denialSW')" aria-label="denialSW" formControlName="denialSW"></select>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.denialTxt') && this.appealReasonCode !== 'FD'">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT8' | translate }}" [mandatory]="true">
          <nh-count>
            <textarea (input)="onInput($event)" maxlength="2000" placeholder="{{startTypingPlaceHolder}}" class="form-control" name="denialTxt"
              formControlName="denialTxt" aria-label="denialTxt" rows="5" autofocus="true"
              style="margin-top:5px"></textarea>
          </nh-count>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.appealFilingLocation')">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT11' | translate }}" class="label-section" [mandatory]="true">
          <input (input)="onInput($event)" maxlength="25" type="text" placeholder="{{startTypingPlaceHolder}}" name="appealFilingLocation"
            aria-label="appealFilingLocation" formControlName="appealFilingLocation" class="form-control">
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.elgBeginDate')">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT12' | translate }}" [mandatory]="true">
          <nh-datepicker>
            <input type="text" name="elgBeginDate" aria-label="elgBeginDate" formControlName="elgBeginDate"
              class="form-control" bsDatepicker nh [minDate]="minimumDate" [maxDate]="today">
          </nh-datepicker>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.elgEffectiveDateTxt')">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT13' | translate }}" [mandatory]="true">
          <nh-count>
            <textarea (input)="onInput($event)" maxlength="2000" placeholder="{{startTypingPlaceHolder}}" class="form-control"
              name="elgEffectiveDateTxt" formControlName="elgEffectiveDateTxt" aria-label="elgEffectiveDateTxt" rows="5"
              autofocus="true" style="margin-top:5px"></textarea>
          </nh-count>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.patientLiabilityTxt')">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT15' | translate }}" [mandatory]="true">
          <nh-count>
            <textarea  (input)="onInput($event)" maxlength="2000" placeholder="{{startTypingPlaceHolder}}" class="form-control"
              name="patientLiabilityTxt" formControlName="patientLiabilityTxt" aria-label="patientLiabilityTxt" rows="5"
              autofocus="true" style="margin-top:5px"></textarea>
          </nh-count>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.premiumAmount')">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT17' | translate }}" class="label-section" [mandatory]="true">
          <nh-input-group prepend="$">
            <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999"
              aria-label="premiumAmount" name="premiumAmount" formControlName="premiumAmount" class="form-control">
          </nh-input-group>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.renewalPktSw')">
      <div class="col">
        <nh-field class="label-section" label="{{'APPEAL_DETAILS_FORM_TEXT19' | translate }}" [mandatory]="true">
          <select nh name="renewalPktSw" [items]="tableCodeValue('YESNO', 'data.renewalPktSw')" aria-label="renewalPktSw" formControlName="renewalPktSw" (change)="onChange('renewalPktSw')"></select>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="(isY('data.renewalPktSw')) && isEnabled('data.sendCompletedPacketSw')">
      <div class="col">
        <nh-field class="label-section" label="{{'APPEAL_DETAILS_FORM_TEXT20' | translate }}" [mandatory]="true">
          <select nh name="sendCompletedPacketSw" [items]="tableCodeValue('YESNO', 'data.sendCompletedPacketSw')" aria-label="sendCompletedPacketSw" formControlName="sendCompletedPacketSw" (change)="onChange('sendCompletedPacketSw')"></select>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="(isY('data.sendCompletedPacketSw'))">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT21' | translate }}" [mandatory]="packetReturnDateValidation">
          <nh-datepicker>
            <input type="text" name="packetReturnDate" aria-label="packetReturnDate" formControlName="packetReturnDate"
              class="form-control" bsDatepicker nh [minDate]="minimumDate" [maxDate]="today">
          </nh-datepicker>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="(isY('data.sendCompletedPacketSw'))">
      <div class="col">
        <p class="static-text" *ngIf="!isEnabled('data.packetReturnDateRangeTxt')" [innerHTML]="'APPEAL_DETAILS_FORM_TEXT38' | translate"></p>
        <p class="static-text" *ngIf="isEnabled('data.packetReturnDateRangeTxt')" [innerHTML]="'APPEAL_DETAILS_FORM_TEXT42' | translate"></p>
        <nh-field label class="label-section">
          <input (input)="onInput($event)" maxlength="25" type="text" placeholder="{{startTypingPlaceHolder}}" name="packetReturnDateRangeTxt"
            aria-label="packetReturnDateRangeTxt" formControlName="packetReturnDateRangeTxt" class="form-control" (change)="onChange('packetReturnDateRangeTxt')">
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.otherPeopleListedSw')">
      <div class="col">
        <nh-field class="label-section" label="{{'APPEAL_DETAILS_FORM_TEXT22' | translate }}" [mandatory]="true">
          <select nh name="otherPeopleListedSw" [items]="tableCodeValue('YESNO', 'data.otherPeopleListedSw')" aria-label="otherPeopleListedSw" formControlName="otherPeopleListedSw" (change)="onChange('otherPeopleListedSw')"></select>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="(((isY('data.sendCompletedPacketSw') && isY('data.otherPeopleListedSw')) && isEnabled('data.packetNamesList')) || (appealReasonCode === 'FV' && isEnabled('data.packetNamesList')))">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT23' | translate }}" [mandatory]="false">
          <nh-count>
            <textarea (input)="onInput($event)" maxlength="500" placeholder="Name 1, Name 2, Name 3, etc..." class="form-control"
              name="packetNamesList" formControlName="packetNamesList" aria-label="packetNamesList" rows="5"
              autofocus="true" style="margin-top:5px"></textarea>
          </nh-count>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="(isY('data.sendCompletedPacketSw')) && isEnabled('data.packetSendType')">
      <div class="col">
        <nh-field class="label-section" label="{{'APPEAL_DETAILS_FORM_TEXT24' | translate }}" [mandatory]="true">
          <select nh name="packetSendType" [items]="tableCodeValue('APLFILINGMETHOD', 'data.packetSendType')" aria-label="packetSendType" formControlName="packetSendType"></select>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.letterReceivedMoreInfoSw')">
      <div class="col">
        <nh-field class="label-section" label="{{'APPEAL_DETAILS_FORM_TEXT25' | translate }}" [mandatory]="true">
          <select nh name="letterReceivedMoreInfoSw" [items]="tableCodeValue('YESNO', 'data.letterReceivedMoreInfoSw')" aria-label="letterReceivedMoreInfoSw" formControlName="letterReceivedMoreInfoSw" (change)="onChange('letterReceivedMoreInfoSw')"></select>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="(isY('data.letterReceivedMoreInfoSw')) && isEnabled('data.requestedInfoSentSw')">
      <div class="col">
        <nh-field class="label-section" label="{{'APPEAL_DETAILS_FORM_TEXT26' | translate }}" [mandatory]="true">
          <select nh name="requestedInfoSentSw" [items]="tableCodeValue('YESNO', 'data.requestedInfoSentSw')" aria-label="requestedInfoSentSw" formControlName="requestedInfoSentSw" (change)="onChange('requestedInfoSentSw')"></select>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="(isY('data.requestedInfoSentSw'))">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT27' | translate }}" [mandatory]="addInfoSentDateValidation">
          <nh-datepicker>
            <input type="text" name="addInfoSentDate" aria-label="addInfoSentDate" formControlName="addInfoSentDate"
              class="form-control" bsDatepicker nh [minDate]="minimumDate" [maxDate]="today">
          </nh-datepicker>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="(isY('data.requestedInfoSentSw'))">
      <div class="col">
        <p class="static-text" *ngIf="!isEnabled('data.addInfoDateRangeTxt')" [innerHTML]="'APPEAL_DETAILS_FORM_TEXT38' | translate"></p>
        <p class="static-text" *ngIf="isEnabled('data.addInfoDateRangeTxt')" [innerHTML]="'APPEAL_DETAILS_FORM_TEXT43' | translate"></p>
        <nh-field label class="label-section">
          <input (input)="onInput($event)" maxlength="25" type="text" placeholder="{{startTypingPlaceHolder}}" name="addInfoDateRangeTxt"
            aria-label="addInfoDateRangeTxt" formControlName="addInfoDateRangeTxt" class="form-control" (change)="onChange('addInfoDateRangeTxt')">
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="(isY('data.requestedInfoSentSw')) && isEnabled('data.addInfoSentType')">
      <div class="col">
        <nh-field class="label-section" label="{{'APPEAL_DETAILS_FORM_TEXT28' | translate }}" [mandatory]="true">
          <select nh name="addInfoSentType" [items]="tableCodeValue('APLFILINGMETHOD', 'data.addInfoSentType')" aria-label="addInfoSentType" formControlName="addInfoSentType"></select>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.denialTxt') && this.appealReasonCode === 'FD'">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT44' | translate }}" [mandatory]="true">
          <nh-count>
            <textarea (input)="onInput($event)" maxlength="2000" placeholder="{{startTypingPlaceHolder}}" class="form-control" name="denialTxt"
              formControlName="denialTxt" aria-label="denialTxt" rows="5" autofocus="true"
              style="margin-top:5px"></textarea>
          </nh-count>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.terminationIncorrectTxt')">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT18' | translate }}" [mandatory]="true">
          <nh-count>
            <textarea (input)="onInput($event)" maxlength="2000" placeholder="{{startTypingPlaceHolder}}" class="form-control"
              name="terminationIncorrectTxt" formControlName="terminationIncorrectTxt"
              aria-label="terminationIncorrectTxt" rows="5" autofocus="true" style="margin-top:5px"></textarea>
          </nh-count>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.cvgSuspensionBeginDate')">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT30' | translate }}" [mandatory]="false">
          <nh-datepicker>
            <input type="text" name="cvgSuspensionBeginDate" aria-label="cvgSuspensionBeginDate"
              formControlName="cvgSuspensionBeginDate" class="form-control" bsDatepicker nh [minDate]="minimumDate"
              [maxDate]="today">
          </nh-datepicker>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.cvgSuspensionEndDate')">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT31' | translate }}" [mandatory]="false">
          <nh-datepicker>
            <input type="text" name="cvgSuspensionEndDate" aria-label="cvgSuspensionEndDate"
              formControlName="cvgSuspensionEndDate" class="form-control" bsDatepicker nh [minDate]="minimumDate"
              [maxDate]="today">
          </nh-datepicker>
        </nh-field>
      </div>
    </div>
    <div class="row" *ngIf="isEnabled('data.qualifyHardshipAndAssistanceTxt')">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT32' | translate }}" [mandatory]="true">
          <nh-count>
            <textarea (input)="onInput($event)" maxlength="2000" placeholder="{{startTypingPlaceHolder}}" class="form-control"
              name="qualifyHardshipAndAssistanceTxt" formControlName="qualifyHardshipAndAssistanceTxt"
              aria-label="qualifyHardshipAndAssistanceTxt" rows="5" autofocus="true" style="margin-top:5px"></textarea>
          </nh-count>
        </nh-field>
      </div>
    </div>
        <!--common field-->
        <div class="row">
          <div class="col">
            <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT2' | translate }}" [mandatory]="true">
              <nh-count>
                <textarea (input)="onInput($event)" maxlength="2000" placeholder="{{startTypingPlaceHolder}}" class="form-control" name="appealReasonTxt"
                  formControlName="appealReasonTxt" aria-label="appealReasonTxt" rows="5" autofocus="true"
                  style="margin-top:5px"></textarea>
              </nh-count>
            </nh-field>
          </div>
        </div>
        <!--common field-->
        <div class="row">
          <div class="col">
            <nh-field [mandatory]="true" label="{{'APPEAL_DETAILS_FORM_TEXT3' | translate }}" class="label-section">
              <select nh name="elgNoticeIssuesSw" [items]="tableCodeValue('YESNO', 'data.elgNoticeIssuesSw')" aria-label="elgNoticeIssuesSw" formControlName="elgNoticeIssuesSw" (change)="onChange('elgNoticeIssuesSw')"></select>
            </nh-field>
          </div>
        </div>
        <!--common field-->
        <div class="row" *ngIf="(isY('data.elgNoticeIssuesSw'))">
          <div class="col">
            <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT4' | translate }}" [mandatory]="true">
              <nh-count>
                <textarea (input)="onInput($event)" maxlength="1000" minlength="20" placeholder="{{startTypingPlaceHolder}}" class="form-control"
                  name="elgNoticeIssuesTxt" formControlName="elgNoticeIssuesTxt" aria-label="elgNoticeIssuesTxt" rows="5"
                  autofocus="true" style="margin-top:5px"></textarea>
              </nh-count>
            </nh-field>
          </div>
        </div>
    <!--common field-->
    <div class="row">
      <div class="col">
        <nh-field class="label-section" label="{{'APPEAL_DETAILS_FORM_TEXT33' | translate }}" [mandatory]="true">
          <select nh name="appellantPregnantOrChildSw" [items]="tableCodeValue('YESNO', 'data.appellantPregnantOrChildSw')" aria-label="appellantPregnantOrChildSw" formControlName="appellantPregnantOrChildSw" (change)="onChange('appellantPregnantOrChildSw')"></select>
        </nh-field>
      </div>
    </div>
    <!--common field-->
    <div class="row" *ngIf="(isY('data.appellantPregnantOrChildSw'))">
      <div class="col">
        <nh-field class="label-section" label="{{'APPEAL_DETAILS_FORM_TEXT34' | translate }}" [mandatory]="true">
          <select nh name="priorMedServReceivedSw" [items]="tableCodeValue('YESNO', 'data.priorMedServReceivedSw')" aria-label="priorMedServReceivedSw" formControlName="priorMedServReceivedSw"></select>
        </nh-field>
      </div>
    </div>
    <!--common field-->
    <div class="row">
      <div class="col">
        <nh-field class="label-section" label="{{'APPEAL_DETAILS_FORM_TEXT35' | translate }}" [mandatory]="true">
          <select nh name="filingWithin90DaysPregSw" [items]="tableCodeValue('YESNO', 'data.filingWithin90DaysPregSw')" aria-label="filingWithin90DaysPregSw" formControlName="filingWithin90DaysPregSw"></select>
        </nh-field>
      </div>
    </div>
    <!--common field-->
    <div class="row">
      <div class="col">
        <nh-field class="label-section" label="{{'APPEAL_DETAILS_FORM_TEXT36' | translate }}" [mandatory]="true">
          <select nh name="newBornSw" [items]="tableCodeValue('YESNO', 'data.newBornSw')" aria-label="newBornSw" formControlName="newBornSw"></select>
        </nh-field>
      </div>
    </div>
     <!--common field-->
     <div class="row">
      <div class="col">
        <nh-field class="label-section" label="{{'APPEAL_DETAILS_FORM_TEXT39' | translate }}" [mandatory]="true">
          <select nh name="appealIssueSame" [items]="tableCodeValue('YESNO', 'data.appealIssueSame')" aria-label="appealIssueSame" formControlName="appealIssueSame"></select>
        </nh-field>
      </div>
    </div>
     <!--common field-->
     <div class="row">
      <div class="col">
        <nh-field class="label-section" label="{{'APPEAL_DETAILS_FORM_TEXT40' | translate }}" [mandatory]="true">
          <select nh name="previousFiledAppeal" [items]="tableCodeValue('YESNO', 'data.previousFiledAppeal')" aria-label="previousFiledAppeal" formControlName="previousFiledAppeal"></select>
        </nh-field>
      </div>
    </div>
    <!--common field-->
    <div class="row" *ngIf="(isY('data.previousFiledAppeal'))">
      <div class="col">
        <nh-field label="{{'APPEAL_DETAILS_FORM_TEXT41' | translate }}" [mandatory]="false">
          <nh-count>
            <textarea (input)="onAppealNumberInput($event)" maxlength="250" placeholder="{{appealNumberPlaceHolder}}" class="form-control" name="enterAppealNum"
                  formControlName="enterAppealNum" aria-label="enterAppealNum" rows="5" autofocus="true"
                  style="margin-top:5px"></textarea>
          </nh-count>
        </nh-field>
      </div>
    </div>
  </section>
</nh-form>