<div class="p-2">
    <p class="static-text" *ngIf="rows.length">{{'DOCUMENTS_ON_MYCASE_STATI4' | translate }}</p>
    <h5 class="section-header" *ngIf="rows.length">{{'DOCUMENTS_ON_MYCASE_STATI3' | translate }}</h5>
    <h5 class="section-header" *ngIf="!documentsLoading && !rows.length">{{'DOCUMENTS_ON_MYCASE_STATI2' | translate }}</h5>
    <hr>
    <p class="static-text" *ngIf="!documentsLoading && !rows.length">{{'DOCUMENTS_ON_MYCASE_STATI5' | translate }}</p>
    <div class="d-none d-lg-block" *ngIf="rows.length">
        <ngx-datatable class='bootstrap striped' #table [rows]='rows' [limit]="5" [columnMode]='"force"'
            [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
            <ngx-datatable-column [name]="col.name | translate" [prop]="col.prop" *ngFor="let col of columns">
            </ngx-datatable-column>
        </ngx-datatable>
    </div>

    <div class="d-lg-none nh-table-mobile">
        <ng-container *ngIf="rows.length">
            <div class="table-row mb-3 pb-3" *ngFor="let row of rows">
                <div class="mobile-table-header">{{columns[0].name}}</div>
                <hr>
                <div class="mobile-table-data">{{row.caseNum}}</div>
                <br>
                <div class="mobile-table-header">{{columns[1].name}}</div>
                <hr>
                <div class="mobile-table-data">{{row.docType}}</div>
                <br>
                <div class="mobile-table-header">{{columns[2].name}}</div>
                <hr>
                <div class="mobile-table-data">{{row.fullName}}</div>
                <br>
                <div class="mobile-table-header">{{columns[3].name}}</div>
                <hr>
                <div class="mobile-table-data">{{row.uploadDt}}</div>
                <br>
                <div class="mobile-table-header">{{columns[4].name}}</div>
                <hr>
                <div class="mobile-table-data">{{row.source}}</div>
                <br>
            </div>
        </ng-container>
        <!-- <p *ngIf="!rows.length" class="mb-5">No data to display</p> -->
    </div>
</div>