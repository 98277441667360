import { Component, ViewEncapsulation } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { minus1Year } from "@app/helpers/date.helpers";
import { ChangeDetectionStrategy, AfterContentInit } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { swapControl, swapControls, toggles } from "@app/helpers/forms.helpers";
import {isNo, isYes} from "@app/helpers/tayn.helpers";
import {formatDate} from "@angular/common";
import Vs from "@app/services/validators/validators.service";
import { Title } from "@angular/platform-browser";
import { isNil, isEmpty } from 'ramda';
import { distinctUntilChanged } from "rxjs/operators";

@Component({
  templateUrl: './newborn-information.component.html',
  styleUrls: ['./newborn-information.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NewbornInformationComponent extends SectionFormDirective implements  AfterContentInit {

  records: any;
  minimumDate = minus1Year();
  today: any = new Date();
  selectedValue;
  genderCd;
  isalienAndNatureNumberEnable: boolean = false;
  isSSNMatched = false;

  constructor(private titleService: Title, builder: FormBuilder) {
    super(builder);
    this.titleService.setTitle('Newborn Information');
  }
  caseheadPopoverText = `Giving us your SSN might help us review your application faster. If you need help getting an SSN, visit socialsecurity.gov, or call Social Security at 1-800-772-1213, or call TennCare Connect for free at 855-259-0710.`;


  get newBornInformation() {
    return this.getControl('data');
  }

  get name() {
    const childFirstName = this.getControl('data.childFirstName');
    const childLastName = this.getControl('data.childLastName');

    return `${childFirstName.value} ${childLastName.value}`;
  }



  ngAfterContentInit() {

    const childDeceasedSw = this.getControl('data.childDeceasedSw');
    const childDeathDt = this.getControl('data.childDeathDt');
    const facilityBirthSw = this.getControl('data.facilityBirthSw');
    const usCtznSw = this.getControl('data.usCtznSw');
    const derivedCtznSw = this.getControl('data.derivedCtznSw');
    const alienNum = this.getControl('data.alienNum');
    const naturalCertNum = this.getControl('data.naturalCertNum');
    const ssn = this.getControl('data.ssn');
    const ssnConfirm = this.getControl('data.ssnConfirm');
    const ssnInitialValue = ssn.value;
    const ssnConfirmInitialValue = ssnConfirm.value;
    this.isalienAndNatureNumberEnable = (this.getControl('data.derivedCtznSw').value?.code == 'Y')

    const genderCdCode = this.newBornInformation?.value?.genderCd;
    if (genderCdCode) {
      if (genderCdCode === 'F') {
        this.genderCd = "Female"
      }
      if (genderCdCode === 'M') {
        this.genderCd = "Male"
      }
      if (genderCdCode === 'U') {
        this.genderCd = "Unknown"
      }
    }

    this.registerToggle(
        childDeceasedSw,
        toggles([
          swapControl(childDeathDt, isYes)
        ])
    );

    this.registerToggle(
        facilityBirthSw,
        toggles([
          swapControl(usCtznSw, isNo),
          () => {
            if (facilityBirthSw.value.code === 'Y') {
              usCtznSw.patchValue({code: "Y", value: "Yes", spValue: "Si"})
              usCtznSw.enable();
              derivedCtznSw.patchValue({code: "N", value: "No", spValue: "No"})
            } else {
              usCtznSw.reset();
            }
          }
        ])
    );

    ssn.setValidators([Vs.ssn, () => {
      const ssnObj = this.records.find((item) => {
        return ssn.value && item.data.ssn === ssn.value && ssnInitialValue !== ssn.value;
      });
      return ssnObj ? { DUPLICATE_SSN: true } : null;
    }])
    ssn.updateValueAndValidity();

    this.registerToggle(
        usCtznSw,
        toggles([
          swapControl(derivedCtznSw, isYes),
        ])
    );

    this.registerToggle(
        derivedCtznSw,
        toggles([
          swapControls([alienNum, naturalCertNum], isYes), 
          () => {
            this.isalienAndNatureNumberEnable = (derivedCtznSw.value?.code == 'Y')
            if (derivedCtznSw.value?.code == 'N') {
              alienNum.reset();
              naturalCertNum.reset();
            } else if (derivedCtznSw.value?.code == 'Y') {
              alienNum.enable(); 
              naturalCertNum.enable();
            }
          }
        ])
    );
    this.formControlValueChanged()
    ssnConfirm.setValidators([Vs.ssn, () => {
      const ssnConfirmObj = this.records.find((item) => {
        return ssnConfirm.value && item.data.ssnConfirm === ssnConfirm.value && ssnConfirmInitialValue !== ssnConfirm.value;
      });
      return ssnConfirmObj ? { DUPLICATE_SSN: true } : null;
    }])
    ssnConfirm.updateValueAndValidity();
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  formControlValueChanged() {
    const ssn = this.getControl('data.ssn')
    const ssnConfirm = this.getControl('data.ssnConfirm')
    if (ssn.value && !ssnConfirm.value) {
      ssnConfirm.patchValue(ssn.value)
     }

    ssn.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        if (res && res.length === 9) {
          if (res.charAt(0) === '9') {
            ssn.setErrors(null);
          }
        } else if (res.length === 0) {
          ssn.setErrors(null)
        } else {
          ssn.setErrors({INVALID_SSN: true})
        }
        ssnConfirm.markAsTouched()
        this.compareSSN()
      })
    ssnConfirm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.compareSSN()
      })
  }

  compareSSN() {
    const ssn = this.getControl('data.ssn')
    const ssnConfirm = this.getControl('data.ssnConfirm')
    this.isSSNMatched = ssn.value === ssnConfirm.value;
    if (this.isSSNMatched) {
      ssnConfirm.setErrors(null);
    } else if (isEmpty(ssnConfirm.value) || isNil(ssnConfirm.value)) {
      ssn.value ? ssnConfirm.setErrors({ SSN_CONFIRM: true }) : ssnConfirm.setErrors(null);
    } else if (ssn.value.length === 0 ) {
      ssnConfirm.reset()
      ssnConfirm.setErrors(null) 
    } else {
      ssnConfirm.setErrors({ SSN_DIFFERENT: true });
    }
  }
  selectChangeHandler(event: any) {

    this.selectedValue = event.target.value;
    sessionStorage.setItem("admitDischarge",  this.selectedValue);
  }

  isInvalidDate(event) {
    const enteredDate = event.target.value;
    if ( !enteredDate || enteredDate === '' || enteredDate === 'Invalid date' ) {
      event.target.value = enteredDate;
    } else if (event.target.name === 'childDob') {
      event.target.value = formatDate (this.getControl('data.childDob').value , 'MM/dd/yyyy' , 'en');
    } else if (event.target.name === 'childDeathDt') {
      event.target.value = formatDate (this.getControl('data.childDeathDt').value , 'MM/dd/yyyy' , 'en');
    }
  }


}
