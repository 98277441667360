import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

import { AlertType } from '@app/models/partials.model';

import { AlertConfig } from 'ngx-bootstrap/alert';

@Component({
  selector: 'nh-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AlertConfig],
})
export class AlertComponent {
  @Input() isOpen = false;
  @Input() type: AlertType = 'info';
  @Input() dismissible = false;
  @Input() heading: string | null = null;
  @Input() confirm: string | null = null;
  @Input() icon: string | null = null;
  @Output() close = new EventEmitter<boolean>();

  onClose() {
    this.isOpen = false;
    this.close.emit(this.isOpen);
  }
}
