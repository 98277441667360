import { evolve } from 'ramda';

import { TransferComponent } from './transfer.component';

import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { toDate, toISO } from '@app/helpers/date.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import transferLabels from './transfer-labels';

const formValidation = (builder, recordData) => {
  const transfer = lookIn(recordData);

  const typeOfAsset = transfer(['typeOfAsset']);
  const specificAsset = transfer(['specificAsset']);
  const assetTransferredTo = transfer(['assetTransferredTo']);
  const assetTransferDate = toDate(transfer(['assetTransferDate']));
  const valueOfAsset = transfer(['valueOfAsset']);
  const inReturnAmount = transfer(['inReturnAmount']);

  const data = builder.group({
    typeOfAsset: [typeOfAsset, [Vs.required]],
    specificAsset: [specificAsset, [Vs.required]],
    assetTransferredTo: [assetTransferredTo, [Vs.required, Vs.maxLength(50), Vs.invalidName]],
    assetTransferDate: [assetTransferDate, [Vs.required]],
    valueOfAsset: [valueOfAsset, [Vs.required, Vs.currency]],
    inReturnAmount: [inReturnAmount, [Vs.currency]]
  });
  return { data };
};

const postObject = evolve({
  assetTransferDate: toISO,
  valueOfAsset: Number,
  inReturnAmount: Number
});

export default sectionConfiguration(
  TransferComponent,
  formValidation,
  ['RESOURCETRANSFCATEGORY', 'BURIALRESOURCETYPECD', 'DCLIFEINSURANCETYPE', 'FINRESTYPE', 'MPPROPTYPE', 'MPVEHICLETYPE', 'OTHERRESTYPE', 'TRUSTTYPE'],
  [
    { name: 'Original Owner', pipe: 'individual' },
    { name: 'Type', prop: 'data.typeOfAsset.value' },
    { name: 'Value', prop: 'data.valueOfAsset', pipe: 'currency' },
    { name: 'Amount Received', prop: 'data.inReturnAmount', pipe: 'currency' },
    { name: 'Transfer Date', prop: 'data.assetTransferDate', pipe: 'date' },
  ],
  { ...{}, ...transferLabels },
  {
    postObject
  }
);
