import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replacedate'
})
export class ReplacedatePipe implements PipeTransform {
  constructor() {}

  transform(text: string, renewalDueDate: string, txtToReplace:string): any {
    const replaceString = new RegExp(txtToReplace,"gi");

    if(text.search(replaceString) == -1){        
        return text
    } else {        
        return text.replace(replaceString, renewalDueDate)
    }

  }
}
