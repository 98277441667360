import { NgModule } from '@angular/core';

import { FormDirective } from './form/form.directive';
import { InputDirective } from './input/input.directive';
import { SelectDirective } from './select/select.directive';
import { AnchorDirective } from './anchor/anchor.directive';
import { NameIdDirective } from './name-id/name-id.directive';
import { NumericDirective } from './numeric/numeric.directive';
import { DecimalDirective } from './decimal/decimal.directive';
import { ReadOnlyDirective } from './readonly/readonly.directive';
import { ContainerDirective } from './container/container.directive';
import { DatePickerDirective } from './datepicker/datepicker.directive';
import { CheckboxValueDirective } from './checkbox-value/checkbox-value.directive';
import { ControlAttributesDirective } from './control-attributes/control-attributes.directive';
import { NumberFormatDirective } from './number-format.directive';
import { DecimalPipe } from '@angular/common';

const declarations = [
  InputDirective,
  FormDirective,
  SelectDirective,
  AnchorDirective,
  NameIdDirective,
  DecimalDirective,
  ReadOnlyDirective,
  ContainerDirective,
  NumericDirective,
  DatePickerDirective,
  CheckboxValueDirective,
  ControlAttributesDirective,
  NumberFormatDirective
];

@NgModule({
  declarations,
  providers: [DecimalPipe],
  exports: declarations,
})
export class DirectivesModule {}
