import { Directive, Inject, Input, forwardRef, ElementRef, Renderer2, HostBinding, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

import { equals, map, nth, cond, compose, not, isNil, identity } from 'ramda';

import { splitPath, orS } from '@app/helpers/function.helpers';
import { indexOrZero } from '@app/helpers/dom.helpers';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";

@Directive({
  selector: 'select[nh]',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectDirective), multi: true }],
})
export class SelectDirective implements ControlValueAccessor {
  private _value: any;
  private _items: any[] = [];
  private _fragment: DocumentFragment;

  @HostBinding('class') class = 'custom-select-info';
  @Input() name: string;
  @Input() readonly bindLabel: string = 'value';
  @Input() readonly bindValue: string = '';
  @Input() readonly empty: any | null = null;

  public onChange = (_: any) => {};
  public onTouched = () => {};
  public language: any;
  public pickAnyOptionText: any;
  public dash = '- -';
  appType: string;
  isRmbRmc: boolean;

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private _renderer: Renderer2,
    private _element: ElementRef,
    public translateService: TranslateService ) {
    this.appType = sessionStorage.getItem('appType')
    this.isRmbRmc = this.appType === 'RB' || this.appType === 'RC';

    this.language = this.translateService.getDefaultLang();
    this.setTranslatedText();

    this.translateService.onDefaultLangChange.subscribe((resLang) => {
      if (resLang?.lang) {
        this.language = resLang.lang;
      }
      this.setTranslatedText();
    });
  }

  get value() {
    return this._value;
  }

  set value(val) {
    if (val === this.pickAnyOptionText || val === this.dash) {
      val = null;
    }
    if (!equals(this._value, val)) {
      this._value = val;
      this.onChange(val);
      this.onTouched();
    }
  }

  get items() {
    if (this.isRmbRmc && this.name === 'suffix') {
      return [this.dash].concat(this._items);
    }
    return [this.pickAnyOptionText].concat(this._items);
  }

  @Input()
  set items(items: any[]) {
    this._items = items;
    this.createOptions();
    this.writeValue(this.value);
  }

  @Input()
  set reset(reset: BehaviorSubject<boolean>) {
    const notNil = compose(
      not,
      isNil
    );
    cond([
      [
        notNil,
        () => {
          reset.subscribe(r => {
            cond([[identity, () => this.setSelected('')]])(r);
          });
        },
      ],
    ])(reset);
  }

  get options() {
    return this._element.nativeElement.options;
  }

  @HostListener('change', ['$event.target'])
  onHostChange({ selectedIndex }: HTMLSelectElement) {
    this.value = this.selectedValue(selectedIndex);
  }

  @HostListener('blur')
  onHostBlur() {
    this.onTouched();
  }

  private createOptions() {
    this._renderer.setProperty(this._element.nativeElement, 'textContent', null);
    this._fragment = this._document.createDocumentFragment();
    map(item => this.createOption(item), this.items);
    this._renderer.appendChild(this._element.nativeElement, this._fragment);
  }

  selectedValue(index: number) {
    return this.itemValue(nth(index, this.items));
  }

  private itemLabel(item: any) {
    return splitPath(orS(item), this.bindLabel, item);
  }

  private itemValue(item: any) {
    return splitPath(item, this.bindValue, item);
  }

  private createOption(item: any) {
    const option = this._renderer.createElement('option');
    this._renderer.setProperty(option, 'textContent', this.itemLabel(item));
    this._renderer.appendChild(this._fragment, option);
  }

  private removeOptionAttribute(option: any, attribute: string) {
    try {
      this._renderer.removeAttribute(option, attribute);
    } catch (ex) {}
  }

  findIndex(value: any) {
    return indexOrZero(item => equals(value, this.itemValue(item)), this.items);
  }

  activeOption(value: any) {
    return this.options.item(this.findIndex(value));
  }

  private setSelected(value: any) {
    Object.keys(this.options).forEach(key => {
      this.removeOptionAttribute(this.options[key], 'selected');
    });
    return this._renderer.setAttribute(this.activeOption(value), 'selected', 'true');
  }

  writeValue(value: any): void {
    this.value = value;
    this.setSelected(value);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._element.nativeElement, 'disabled', isDisabled);
  }

  setTranslatedText() {
    if (this.language && this.language === "sp") {
      this.pickAnyOptionText = 'Elija una opción';
    } else {
      this.pickAnyOptionText = 'Pick an option';
    }
  }
}
