import { SchoolAndEmploymentComponent } from './school-employment.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import Vs from '@app/services/validators/validators.service';
import { controlWith } from '@app/helpers/form.helpers';
import { fullTimeAge, isStudent } from '@app/helpers/person.helpers';
import { handleMedicalPrograms } from '@app/helpers/programs.helpers';
import { FormArray } from '@angular/forms';


const formValidation = (builder, recordData, { programs, individuals }) => {

    let indvData = recordData.indvData;
    
    let individualsDataWithAge = indvData.map((e)=>{
        let temp = individuals.find(element=> element.identifier === e.individualNumber)
        if(temp.age >=0) {
            e.age = temp.age;
        }
        if(temp.name) {
            e.name = temp.name;
        }
        return e;
    });

    let individualsData =  individualsDataWithAge.filter((item) => item.age <= 22);
    console.log(individualsData);

    programs = handleMedicalPrograms(programs);

    const currentEnrollmentStatus = [];
    const isWorkingFullTime = [];
    const isFullTime = [];
    const isStudentAge = [];
    const individualNumber  = [];
    const name = [];

    for (let j = 0; j < individualsData.length; j++) {
        currentEnrollmentStatus.push(individualsData[j]['education']['currentEnrollmentStatus']);
        isWorkingFullTime.push(individualsData[j]['isWorkingFullTime']);
        individualNumber.push(individualsData[j]['individualNumber']);
        name.push(individualsData[j]['name']['fullName']);
        isFullTime.push(fullTimeAge(individualsData[j]));
        isStudentAge.push(isStudent(individualsData[j]))
    }


    const createCtrls = (i) => {  
        return builder.group({
            name: name[i] ? name[i] : '',
            individualNumber: individualNumber[i] ? individualNumber[i] : '',
            education: builder.group({
            currentEnrollmentStatus: [controlWith(currentEnrollmentStatus[i] ? currentEnrollmentStatus[i] : '', !isStudentAge[i]), Vs.required]
            }),
            isWorkingFullTime: [controlWith(isWorkingFullTime[i] ? isWorkingFullTime[i] : undefined , !isFullTime[i]), Vs.required]
        });
    };

    const schoolAndEmploymentArray = (): FormArray => {
        const schoolAndEmploymentListLatest: any = [];
        for(let i = 0; i < individualsData.length; i++){
            schoolAndEmploymentListLatest.push(createCtrls(i));
        }
        return schoolAndEmploymentListLatest as FormArray;
    }

    const data = builder.group({
        indvData:  builder.array(schoolAndEmploymentArray())
    });

  console.log(data, 'testData');

  return { data };
};



export default sectionConfiguration(SchoolAndEmploymentComponent, formValidation, ['ENROLLSTATUS', 'YESNO'], [], {}, { hasSummary: false });
