<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left" id="my-modal-title">{{ this.title }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
    </button>
  </div>
  <div class="modal-body">
    <div class="nh-table-mobile d-lg-none">

      <div class="mobile-table-header">{{ this.type1 }}</div><hr>
      <div class="mobile-table-data"> {{ this.type1Description }}</div><br>

      <div class="mobile-table-header">{{ this.type2 }}</div><hr>
      <div class="mobile-table-data"> {{ this.type2Description }} </div><br>

      <div class="mobile-table-header">{{ this.type3 }}</div><hr>
      <div class="mobile-table-data">{{ this.type3Description }} </div><br>

      <div class="mobile-table-header">{{ this.type4 }}</div><hr>
      <div class="mobile-table-data">{{ this.type4Description }}</div><br>

      <div class="mobile-table-header">{{ this.type5 }}</div><hr>
      <div class="mobile-table-data">{{ this.type5Description }}</div><br>

      <div class="mobile-table-header">{{ this.type6 }}</div><hr>
      <div class="mobile-table-data">{{ this.type6Description }}</div><br>

      <div class="mobile-table-header">{{ this.type7 }}</div><hr>
      <div class="mobile-table-data">{{ this.type7Description }}</div><br>
    </div>

    <table class="d-none d-lg-block table table-striped modal-table">
      <thead>
        <tr class="header-sty">
          <th scope="col">{{ this.title1 }}</th>
          <th scope="col">{{ this.description }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ this.type1 }}</td>
          <td>{{ this.type1Description }}</td>
        </tr>
        <tr>
          <td>{{ this.type2 }}</td>
          <td>{{ this.type2Description }}</td>
        </tr>
        <tr>
          <td>{{ this.type3 }}</td>
          <td>{{ this.type3Description }}</td>
        </tr>
        <tr>
          <td>{{ this.type4 }}</td>
          <td>{{ this.type4Description }}</td>
        </tr>
        <tr>
          <td>{{ this.type5 }}</td>
          <td>{{ this.type5Description }}</td>
        </tr>
        <tr>
          <td>{{ this.type6 }}</td>
          <td>{{ this.type6Description }}</td>
        </tr>
        <tr>
          <td>{{ this.type7 }}</td>
          <td>{{ this.type7Description }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</div>
