import { sectionConfiguration } from '@app/helpers/models.helpers';
import { ReportChangeComponent } from './report-change.component';
import { lookIn } from '@app/helpers/flipped.helpers';
import { assign } from '@app/helpers/function.helpers';

const formValidation = (builder, recordData) => {
  const rmcData = lookIn(recordData);
  const changeKBReferralSW = rmcData(['changeKBReferralSW']);
  const initiateKBReferralSW = rmcData(['initiateKBReferralSW']);
  const showPastPregDueDateNudge = rmcData(['showPastPregDueDateNudge']);
  const modules = rmcData(['modules']);
  if (modules) {
    const m = {};
    for (const module of modules) {
      m[module.moduleId] = builder.control({
        moduleId: module.moduleId,
        sections: module.sections,
      })
    }
    const data = builder.group(m);
    const flags = { initiateKBReferralSW, changeKBReferralSW, showPastPregDueDateNudge };
    assign(data, flags);
    return { data };
  }

  return { data: null };
};

export default sectionConfiguration(ReportChangeComponent, formValidation, [], [], {}, { hasSummary: false, showComments: false });
