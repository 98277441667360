<div class="review-summary-table d-none d-lg-block">
    <table class="table w-100 table-striped">
        <thead>
            <tr>
                <th class="col-md-3">{{'NAME' | translate}}</th>
                <th class="col-md-4">{{'What Changed?' | translate}}</th>
                <th class="col-md-3"></th>
                <th *ngIf="!this.isRMC" class="col-md-2">{{'Review Complete?' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of rowData; let rowIndex = index">
                <td>
                    {{row.nameAge}}
                </td>
                <td>
                    <span *ngIf="!isObject(row)" [innerHtml]="row.changeValue | boldText : row.changeValue : replaceText | sanitize"></span>
                    <span *ngIf="isObject(row) && row.exportingStatus !== 'REMOVED'">
                        <div *ngIf="row.changeValue.length > 0">
                            <div class="pt-1" *ngFor="let item of row.changeValue">
                                <strong>{{getLabelKey(item.key) | translate | replaceindiv : row.nameAge  | replacemedicaltype : row?.data?.medicalBillType?.value |  replaceassettype : row?.data?.assetType?.value  }} </strong><br><span [innerHtml]="item.value | dateFormat | sanitize"></span>
                            </div>
                            <div class="pt-1" *ngIf="row.greaterLength" [innerHTML]="'SUMMARY_STATIC_TEXT2' | translate"></div>
                        </div>
                        <div *ngIf="row.changeValue.length === 0 && !checkIndvs(row)">
                            <div>{{text2}} {{row.nameAge? row.nameAge.split(',')[0] : 'Person'}}'s {{text1}}</div>
                            <div *ngIf="!closeIncomenudge">
                                <nh-nudge *ngIf="(sectionId === 'ICEMI' || sectionId === 'ICSEM' || sectionId === 'ICOTH') && incomeReviewStatus" [message]="'NUDGETEXT_02' | translate"></nh-nudge>
                            </div>
                        </div>
                        <div *ngIf="checkIndvs(row)">
                            <div>{{text3En}} {{this.updateDate}}</div>
                        </div>
                    </span>
                    <span *ngIf="isObject(row) && row.exportingStatus === 'REMOVED'">
                        <div class="pt-1" *ngFor="let item of row.changeValue">
                            <span>{{item.key | translate | replaceindiv : row.nameAge: row.data?.recordRemovedType}}</span><span> {{item.value | translate }}</span>
                        </div>
                    </span>
                </td>
                <td class="text-center">
                    <span class="summary-ctas">
                        <button class="btn btn-sm btn-secondary mb-1 edit-{{ rowIndex }}" [disabled]="sectionId === 'HHTJF' && (row?.data?.taxFilingStatus !== 'Filer' && row?.data?.taxFilingStatus !== null) && enableEditReview" (click)="onEdit(row)">{{this.isRMC ?
                            this.editText : (!this.isRMC && row.exportingStatus == 'EXPORTED') ? this.review :
                            this.editText}}</button>
                        <div *ngIf="row.exportingStatus === 'REMOVED' && row.identifier.sectionId !== 'OTKBR'" class="pl-2 pt-1 hover-red">
                            <a class="btn btn-lg btn-link delete-1 hover-red p-1 delete-{{ rowIndex }}"
                                (click)="onUndo(row)"> <span class="pr-2">{{'Undo' | translate}}</span>
                                <svg id="_icons_arrow_undo-outline{{rowIndex}}"
                                    data-name="⚛️ icons/icons-arrow-undo-outline" xmlns="http://www.w3.org/2000/svg"
                                    width="24" height="24" viewBox="0 0 24 24">
                                    <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
                                        fill="#174a7c" stroke="none">
                                        <path d="M65 160 l-39 -40 39 -40 c44 -45 55 -49 55 -20 0 27 30 26 64 -1 34
                                  -27 38 -22 25 24 -10 36 -50 77 -75 77 -8 0 -14 9 -14 20 0 29 -11 25 -55 -20z
                                  m81 -19 c20 -7 40 -24 46 -37 14 -30 6 -39 -20 -25 -29 15 -60 14 -64 -3 -2
                                  -11 -12 -7 -38 15 l-35 29 35 29 c22 19 36 25 38 17 1 -7 19 -18 38 -25z" />
                                    </g>
                                </svg>
                            </a>
                        </div>
                        <div *ngIf="row.exportingStatus !== 'REMOVED' && row.identifier.sectionId !== 'OTKBR'" class="pt-1 hover-red">
                            <button class="btn btn-link btn-lg delete-{{rowIndex}} hover-red p-1"
                                [disabled]="enableEditReview && (!canDelete(row) || (row?.data?.taxFilingStatus?.includes('Dependent')) || (row?.data?.taxFilingStatus?.includes('Joint')))"
                                (click)="onDelete(row)">
                                <span class="pr-2">{{'REMOVE' | translate }}</span>
                                <nh-remove-svg id="web_count" *ngIf="canDelete(row)"></nh-remove-svg>
                            </button>
                            <nh-popover *ngIf="!canDelete(row)" [content]="popContent"></nh-popover>
                        </div>
                        <nh-popover *ngIf="sectionId === 'HHTJF' && row.data.taxFilingStatus !== 'Filer' && enableEditReview" [content]="taxPopUpOne + person[rowIndex].name + (person[rowIndex].status === 'EDIT' ? taxPopUpTwo : taxPopUpThree)"></nh-popover>
                    </span>
                </td>
                <td *ngIf="!this.isRMC" class="text-center">
                    <span *ngIf="row.exportingStatus == 'EXPORTED'" class="text-danger"><i class="fa fa-times fa-2x"></i></span>
                    <span *ngIf="row.exportingStatus !== 'EXPORTED'" class="text-success"><i class="fa fa-check fa-2x"></i></span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="nh-table-mobile mt-3 pt-2 d-lg-none">
    <div class="table-row mb-3 pb-3" *ngFor="let row of rowData; index as i">
        <div class="mobile-table-header">{{'NAME' | translate}}</div>
        <hr>
        <div class="mobile-table-data">{{row.nameAge}}</div><br>
        <div class="mobile-table-header">{{'What Changed?' | translate}}</div>
        <hr>
        <div class="mobile-table-data">
            <span *ngIf="!isObject(row)"
                [innerHtml]="row.changeValue | boldText : row.changeValue : replaceText | sanitize"></span>
            <span *ngIf="isObject(row) && row.exportingStatus !== 'REMOVED'">
                <div *ngIf="row.changeValue.length > 0">
                    <div class="pt-1" *ngFor="let item of row.changeValue">
                        <strong>{{item.key | translate | replaceindiv : row.nameAge }}</strong><br><span
                            [innerHtml]="item.value | sanitize"></span>
                    </div>
                    <div *ngIf="row.greaterLength" [innerHTML]="'SUMMARY_STATIC_TEXT2' | translate"></div>
                </div>
                <div *ngIf="row.changeValue.length === 0">
                    <div>{{text2}} {{row.nameAge? row.nameAge.split(',')[0] : 'Person'}}'s {{text1}}</div>
                    <div *ngIf="!closeIncomenudge">
                        <nh-nudge *ngIf="(sectionId === 'ICEMI' || sectionId === 'ICSEM' || sectionId === 'ICOTH') && incomeReviewStatus" [message]="'NUDGETEXT_02' | translate"></nh-nudge>
                    </div>
                </div>
            </span>
            <span *ngIf="isObject(row) && row.exportingStatus === 'REMOVED'">
                <div class="pt-1" *ngFor="let item of row.changeValue">
                    <span>{{item.key | translate | replaceindiv : row.nameAge }}</span><span>{{item.value | translate }}</span>
                </div>
            </span>
        </div><br>
        <div>
            <button class="btn btn-sm btn-secondary mb-1 w-100 edit-{{ i }}" [disabled]="sectionId === 'HHTJF' && (row?.data?.taxFilingStatus !== 'Filer' && row?.data?.taxFilingStatus !== null)" (click)="onEdit(row)">{{this.isRMC ?
                this.editText :
                (!this.isRMC && row.exportingStatus == 'EXPORTED') ? this.review : this.editText}}</button>
            <div *ngIf="row.exportingStatus !== 'REMOVED' && row.identifier.sectionId !== 'OTKBR'" class="pt-1 hover-red text-center">
                <button class="btn btn-link btn-lg delete-{{i}} hover-red"
                    [disabled]="!canDelete(row) || row.data.taxFilingStatus == 'Filer'" (click)="onDelete(row)">
                    <span class="pr-2">{{'REMOVE' | translate }}</span>
                    <nh-remove-svg id="mobile_count" *ngIf="canDelete(row)"></nh-remove-svg>
                </button>
                <nh-popover *ngIf="!canDelete(row)" [content]="popContent"></nh-popover>
                <nh-popover *ngIf="sectionId === 'HHTJF' && row.data.taxFilingStatus !== 'Filer'"  [content]="taxPopUpOne + person[i].name +(person[i].status === 'EDIT' ? taxPopUpTwo : taxPopUpThree)"></nh-popover>
            </div>
            <div *ngIf="row.exportingStatus === 'REMOVED' && row.identifier.sectionId !== 'OTKBR'" class="pl-2 pt-1 hover-red text-center">
                <a class="btn btn-lg btn-link delete-1 hover-red p-1 delete-{{ i }}" (click)="onUndo(row)"> <span
                        class="pr-2">{{'Undo' | translate}}</span>
                    <svg id="_icons_arrow_undo-outline{{i}}" data-name="⚛️ icons/icons-arrow-undo-outline"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)" fill="#174a7c"
                            stroke="none">
                            <path d="M65 160 l-39 -40 39 -40 c44 -45 55 -49 55 -20 0 27 30 26 64 -1 34
                                  -27 38 -22 25 24 -10 36 -50 77 -75 77 -8 0 -14 9 -14 20 0 29 -11 25 -55 -20z
                                  m81 -19 c20 -7 40 -24 46 -37 14 -30 6 -39 -20 -25 -29 15 -60 14 -64 -3 -2
                                  -11 -12 -7 -38 15 l-35 29 35 29 c22 19 36 25 38 17 1 -7 19 -18 38 -25z" />
                        </g>
                    </svg>
                </a>
            </div>
        </div><br>
        <div *ngIf="!isRMC">
            <div class="mobile-table-header">{{'Review Complete?' | translate}}</div>
            <hr>
            <div>
                <span *ngIf="row.exportingStatus == 'EXPORTED'" class="text-danger"><i
                        class="fa fa-times fa-2x"></i></span>
                <span *ngIf="row.exportingStatus !== 'EXPORTED'" class="text-success"><i
                        class="fa fa-check fa-2x"></i></span>
            </div>
        </div>
    </div>
</div>
