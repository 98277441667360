import { Directive, Input, HostListener, ElementRef, Renderer2, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { equals, identity } from 'ramda';

@Directive({
  selector: 'input[type=checkbox][nh-value]',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxValueDirective), multi: true }],
})
export class CheckboxValueDirective implements ControlValueAccessor {
  private nativeElement;
  @Input() checkedValue = {};
  @Input() uncheckedValue = null;
  onChange: Function = identity;
  onTouched: Function = identity;

  constructor(private renderer: Renderer2, private element: ElementRef) {
    this.nativeElement = this.element.nativeElement;
  }

  @HostListener('change', ['$event.target.checked'])
  onHostChange(checked) {
    this.onChange(checked ? this.checkedValue : this.uncheckedValue);
  }

  setChecked(checked): void {
    this.renderer.setProperty(this.nativeElement, 'checked', checked);
  }

  public writeValue(currentValue: any): void {
    this.setChecked(equals(currentValue, this.checkedValue));
  }

  registerOnChange(onChange): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.nativeElement, 'disabled', isDisabled);
  }
}
