import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';

import { Observable } from 'rxjs';

import { StateService } from './services/state/state.service';

import { isNewApp } from './helpers/route.helpers';
import { activable } from './helpers/observable.helpers';

import { ApplicationNumber, Mode } from './models/app.model';
import { viewAppNumber } from '@app/helpers/lenses.helpers';

@Injectable()
export class AppGuard implements CanActivate {
  constructor(private state: StateService) {}

  loadApp(applicationNumber: ApplicationNumber): Observable<any> {
    return this.state.guardApp(() => this.state.loadApp(applicationNumber));
  }

  startApp(mode: Mode): Observable<any> {
    return this.state.guardApp(() => this.state.startApp(mode));
  }

  isNewApp(numMode): Observable<boolean> {
    return isNewApp(mode => this.startApp(mode), num => this.loadApp(num))(numMode);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return activable(this.isNewApp(viewAppNumber(route)));
  }
}
