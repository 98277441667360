import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BaseModalDirective } from '@app/components/modals/base-modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { minus120Years, toISO } from '@app/helpers/date.helpers';
import { Select } from '@ngxs/store';
import { SectionState } from '@app/state/section/section.state';
import { Observable } from 'rxjs';
import { nonMandatoryAddress } from '@app/helpers/models.helpers';
import { contains } from 'ramda';
import { clearValidators } from '@app/helpers/form-control.helpers';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './people-delete.component.html',
  styleUrls: ['./people-delete.component.scss'],
})
export class PeopleDeleteModalComponent extends BaseModalDirective implements OnInit {
  @Input() moveOutAddress: FormGroup;

  @Select(SectionState.tables) public tables$: Observable<any>;
  obj;
  form;
  YESNO;
  STATE;
  title;
  COUNTY;
  GENDER;
  fullName;
  ABSENTREASON;
  STATEMILITARY;
  NUMBEROFBIRTHS;
  modifiedReasons;
  MILITARYPOCODES;
  ADDRESSFORMATTYPES;
  today = new Date();
  minimumDate = minus120Years();
  minDate = minus120Years();
  translatedLang;
  dropDownOptionsTable;
  movedOut;
  movedOutEn = "Moved Out of the Home";
  movedOutSp = "Se salió del hogar";
  showErrorMessage: boolean = false;

  constructor(bsModalService: BsModalService, protected modalRef: BsModalRef, protected builder: FormBuilder, public translateService: TranslateService) {
    super(modalRef, builder);
    this.obj = bsModalService.config.initialState;
  };

  ngOnInit() {
    this.translatedLang = this.translateService.getDefaultLang();
    if (this.translatedLang === 'en') {
      this.movedOut = this.movedOutEn;
      } else {
        this.movedOut = this.movedOutSp;
      }
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      if (this.translatedLang === 'en') {
      this.movedOut = this.movedOutEn;
      } else {
        this.movedOut = this.movedOutSp;
      }
      this.getTableData();
    });

    this.tables$.subscribe(res => {
      this.dropDownOptionsTable = res;
      this.getTableData();
    });

    this.fullName = this.obj.indvName;
    this.title = this.movedOut;

    this.form = this.builder.group({
      deleteReason: this.obj.deleteReason || '',
      indvDeletedDate: this.obj.indvDeletedDate || '',
      deathDate: this.obj.deathDate || '',
      moveOutAddressFormat: this.obj.moveOutAddressFormat || '',
      moveOutAddress: this.builder.group(nonMandatoryAddress(this.obj.moveOutAddress) || nonMandatoryAddress('')),
    });

    this.modifiedReasons = this.ABSENTREASON?.filter(option => {
      const realResons = ['DE', 'MO']
      return contains(option.code, realResons);
    });
  };

  getTableData() {
    this.STATE = this.languageCheck(this.dropDownOptionsTable.STATE);
    this.YESNO = this.languageCheck(this.dropDownOptionsTable.YESNO);
    this.COUNTY = this.languageCheck(this.dropDownOptionsTable.COUNTY);
    this.GENDER = this.languageCheck(this.dropDownOptionsTable.GENDER);
    this.ABSENTREASON = this.languageCheck(this.dropDownOptionsTable.ABSENTREASON);
    this.STATEMILITARY = this.languageCheck(this.dropDownOptionsTable.STATEMILITARY);
    this.NUMBEROFBIRTHS = this.languageCheck(this.dropDownOptionsTable.NUMBEROFBIRTHS);
    this.MILITARYPOCODES = this.languageCheck(this.dropDownOptionsTable.MILITARYPOCODES);
    this.ADDRESSFORMATTYPES = this.languageCheck(this.dropDownOptionsTable.ADDRESSFORMATTYPES);
  }

  languageCheck(tableData) {
    let tempList = [];
    tableData.forEach(opt => {
      let tempOpt = {};
      if (this.translatedLang === 'sp') {
        tempOpt = { 'code': opt.code, 'value': opt.spDescription ? opt.spDescription : opt.spValue };
      } else {
        tempOpt = { 'code': opt.code, 'value': opt.description ? opt.description : opt.value };
      }
      tempList.push(tempOpt);
    });
    return tempList;
  }

  onChange(val?) {
     if(val && this.form.value.moveOutAddressFormat){
      if(this.form.value.moveOutAddressFormat.code === 'US'){
       this.form.controls.moveOutAddress.controls.addrApoFpo.reset();
       this.form.controls.moveOutAddress.controls.addrAaAeAp.reset();
      }
      if(this.form.value.moveOutAddressFormat.code === 'US' && this.form.value.moveOutAddress.addressLine1 && this.form.value.moveOutAddress.city && this.form.value.moveOutAddress.state && this.form.value.moveOutAddress.state.code != 'TN' && this.form.value.moveOutAddress.zip){
      this.showErrorMessage = false;
      }  else if(this.form.value.moveOutAddressFormat.code === 'US' && this.form.value.moveOutAddress.addressLine1 && this.form.value.moveOutAddress.city && this.form.value.moveOutAddress.state && this.form.value.moveOutAddress.state.code === 'TN' && this.form.value.moveOutAddress.zip && this.form.value.moveOutAddress.county) {
        this.showErrorMessage = false;
      }
      else  if(this.form.value.moveOutAddressFormat.code === 'MI' && this.form.value.moveOutAddress.addressLine1 && this.form.value.moveOutAddress.addrApoFpo && this.form.value.moveOutAddress.addrAaAeAp && this.form.value.moveOutAddress.zip){
        this.showErrorMessage = false;
        }
     else {
      this.showErrorMessage = true;
     }
    }
    else if(this.form.value.moveOutAddressFormat || (this.form.value.moveOutAddress.addressLine1 !== "" && this.form.value.moveOutAddress.addressLine1 !== null) || (this.form.value.moveOutAddress.addressLine2 !== "" && this.form.value.moveOutAddress.addressLine2 !== null) || this.form.value.moveOutAddress.city || this.form.value.moveOutAddress.state ||  this.form.value.moveOutAddress.zip || this.form.value.moveOutAddress.addrApoFpo || this.form.value.moveOutAddress.addrAaAeAp ) {
      this.showErrorMessage = true;
     }
     else {
      this.showErrorMessage = false;
     }
  }

  onDateChange(code) {
    if (code !== 'DE') {
      const { deathDate } = this.form.controls;
      deathDate.reset();
      clearValidators(deathDate);
      deathDate.updateValueAndValidity();
    }
  }

  get isDisabled() {
    if (this.form.valid && !this.showErrorMessage) {
      return null;
    } else {
      return true;
    }
  }

  onConfirm(): void {
    this.form.markAsTouched();

    if (this.form.valid) {
      if (this.form.value.indvDeletedDate) {
        this.obj.indvDeletedDate = toISO(this.form.value.indvDeletedDate);
        this.obj.deathDate = toISO(this.form.value.deathDate);
      }
      if (this.form.value.deathDate) {
        this.obj.deathDate = toISO(this.form.value.deathDate);
      }
      this.obj.deleteReason = this.form.value.deleteReason;
      this.obj.moveOutAddress = this.form.value.moveOutAddress;
      this.obj.moveOutAddressFormat = this.form.value.moveOutAddressFormat;

      super.onConfirm();
    }
  }
}
