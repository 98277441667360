import { compose } from 'ramda';
import { zipObj } from 'ramda';
import { converge } from 'ramda';
import { objOf } from 'ramda';
import { reduceWhile } from 'ramda';
import { identity } from 'ramda';
import { toPairs } from 'ramda';
import { assoc } from 'ramda';
import { invoker } from 'ramda';
import { keys } from 'ramda';
import { filter, pathOr } from 'ramda';

import { list } from 'ramda-adjunct';
import { isPlainObj } from 'ramda-adjunct';
import { isNilOrEmpty } from 'ramda-adjunct';

import { withMerge, reduceO } from './function.helpers';

export const transform = invoker(2, 'transform');

export const toObject = props =>
  compose(
    zipObj(props),
    list
  );

const objWith = convertion => converge(objOf, [identity, convertion]);
const accumulateWith = reducer => withMerge([identity, objWith(reducer)]);
export const accumulateWhile = (predicate, accumulator) => reduceWhile(predicate, accumulateWith(accumulator), {});

export const nilKeys = compose(
  reduceO(([k, v], a) => {
    if (isPlainObj(v)) {
      return assoc(k, nilKeys(v), a);
    }
    return isNilOrEmpty(v) ? a : assoc(k, v, a);
  }),
  toPairs
);

export const trueKeys = compose(
  keys,
  filter(identity)
);

export const deepCopy = obj => JSON.parse(JSON.stringify(obj));

export const getDataValue = (path: string, obj: any) => pathOr(null, path.split('.'), obj);
