import { evolve, compose, map } from 'ramda';

import { OtherIncomeComponent } from './other-income.component';
import otherIncomeLabels from './other-income-labels';

import Vs from '@app/services/validators/validators.service';
import { isOtherStatus, sectionConfiguration } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { toDate, toISO } from '@app/helpers/date.helpers';
import { controlWith } from '@app/helpers/form.helpers';
import { notYes } from '@app/helpers/tayn.helpers';

export const inkindSupportListCreator = ({ amountPerMonth, expenseType, amountPaidByInHome, amountPaidByOutHome, inkindSequence }) => ({
  amountPerMonth: [amountPerMonth, [Vs.required, Vs.currency]],
  expenseType: [expenseType, [Vs.required]],
  amountPaidByInHome: [amountPaidByInHome, [Vs.required, Vs.currency]],
  amountPaidByOutHome: [amountPaidByOutHome, [Vs.required, Vs.currency]],
  inkindSequence: [inkindSequence],
});

const formValidation = (builder, recordData, { earnedIncome }) => {
  const otherIncome = lookIn(recordData);

  const otherIncomeTypeCode = otherIncome(['otherIncomeDetails', 'otherIncomeTypeCode']);
  const paymentFrequency = otherIncome(['otherIncomeDetails', 'paymentFrequency']);
  const paymentAmount = otherIncome(['otherIncomeDetails', 'paymentAmount']);
  const veteranBenefitTypeCode = otherIncome(['otherIncomeDetails', 'veteranBenefitTypeCode']);
  const alimonyOrderDate = otherIncome(['otherIncomeDetails', 'alimonyOrderDate']);
  const paymentStartDate = otherIncome(['otherIncomeDetails', 'paymentStartDate']);
  const incomeEndSw = otherIncome(['otherIncomeDetails', 'incomeEndSw']);
  const incomeEndDate = otherIncome(['otherIncomeDetails', 'incomeEndDate']);

  const someOnePayFoodMonthly = otherIncome(['inKindData', 'someOnePayFoodMonthly']);
  const someOnePayLivesMonthly = otherIncome(['inKindData', 'someOnePayLivesMonthly']);
  const liveWithOtherAdults = otherIncome(['inKindData', 'liveWithOtherAdults']);
  const paidHouseholdExpenseAmt = otherIncome(['inKindData', 'paidHouseholdExpenseAmt']);
  const howManyPeopleLive = otherIncome(['inKindData', 'howManyPeopleLive']);
  const socialSecurityIncomeTypeCode = otherIncome(['otherIncomeDetails', 'socialSecurityIncomeTypeCode']);

  const inkindSupportListGroup = compose(
    (control: any) => builder.group(control),
    inkindSupportListCreator,
  );

  const inkindSupportListData = otherIncome(['inKindData', 'inkindSupportList']) || [{
    amountPerMonth: '',
    expenseType: null,
    amountPaidByInHome: '',
    amountPaidByOutHome: '',
    inkindSequence: '',
  }];
  const inkindSupportList = map(inkindSupportListGroup, inkindSupportListData);

  const helpers = builder.group({
    earnedIncome: [controlWith(earnedIncome, true)],
  });

  const data = builder.group({
    otherIncomeDetails: builder.group({
      otherIncomeTypeCode: [isOtherStatus(otherIncomeTypeCode) ? { code: 'OT', spValue: 'Otros Ingresos', value: 'Other Income'} : otherIncomeTypeCode, Vs.required],
      socialSecurityIncomeTypeCode: [socialSecurityIncomeTypeCode, Vs.required],
      veteranBenefitTypeCode: [veteranBenefitTypeCode, Vs.required],
      alimonyOrderDate: [toDate(alimonyOrderDate), Vs.required],
      paymentStartDate: [toDate(paymentStartDate), Vs.required],
      paymentFrequency: [paymentFrequency, Vs.required],
      paymentAmount: [paymentAmount, [Vs.required, Vs.currency]],
      incomeEndSw: [incomeEndSw],
      incomeEndDate: [controlWith(toDate(incomeEndDate), notYes(incomeEndSw)), Vs.required]
    }),
    inKindData: builder.group({
      someOnePayFoodMonthly: [someOnePayFoodMonthly, Vs.required],
      someOnePayLivesMonthly: [someOnePayLivesMonthly, Vs.required],
      inkindSupportList: builder.array(inkindSupportList),
      liveWithOtherAdults: [liveWithOtherAdults],
      paidHouseholdExpenseAmt: [paidHouseholdExpenseAmt, [Vs.required, Vs.currency]],
      howManyPeopleLive: [howManyPeopleLive, Vs.currency],
    })
  });
  return { data, helpers };
};

const postObject = evolve({
  otherIncomeDetails: {
    alimonyOrderDate: toISO,
    paymentStartDate: toISO,
    incomeEndDate: toISO
  },
});

export default sectionConfiguration(
  OtherIncomeComponent,
  formValidation,
  ['TAUI', 'TPFT', 'TAYN', 'TSRR', 'UNEARNEDINCOMETYPE_PP', 'UNEARNEDINCOMETYPE', 'VAUNEARNEDINCOME', 'SSUNEARNEDINCOME', 'YESNO', 'INCEXPPAYFREQUENCY', 'ISMEXPENSETYPE'],
  [
    { name: 'Name', pipe: 'individual' },
    { name: 'Other Income Type', prop: 'data.otherIncomeDetails.otherIncomeTypeCode.value' },
    { name: 'How Much', prop: 'data.otherIncomeDetails.paymentAmount', pipe: 'currency' },
    { name: 'How Often', prop: 'data.otherIncomeDetails.paymentFrequency.value' },
  ],
  { ...{}, ...otherIncomeLabels },
  {
    postObject
  }
);
