<div class="container">
    <div class="row">
        <div class="col-8">
            <h1 class="page-title">CREATE ACCOUNT</h1>
        </div>
        <div class="bg-custom">
            <div class="row justify-content-center mb-3">
                <div class="col-md-8">
                    <div class="wayfinder">
                        <ul class="steps  stepper stepper-horizontal">
                            <li class="step" [ngClass]="{'completed': item.completed}" role="button"
                                *ngFor="let item of steps" [routerLink]="[item.link]" routerLinkActive="active reached">
                                <span></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <router-outlet (activate)="loaded()"></router-outlet>
        </div>
    </div>