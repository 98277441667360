import { SectionStateModel } from '@app/models/state.model';
import { SectionId } from './section.model';
import { ReferenceValue } from './tables.model';
import { Nullable } from './partials.model';

export type ApplicationNumber = Nullable<string>;
export type IndividualId = Nullable<string>;
export type CaseNumber = Nullable<string>;
export type Token = Nullable<string>;
export type ApplicationType = Nullable<string>;

export enum Modes {
  NEW = 'NEW',
  REDETERMINATION = 'REDETERMINATION',
  REAPPLY = 'REAPPLY',
  ADD_PROGRAM = 'ADD_PROGRAM',
  CHANGE_REPORT = 'CHANGE_REPORT',
}

export type Mode = keyof typeof Modes;

export enum Statuses {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  UNCOMPLETED = 'UNCOMPLETED',
  COMPLETED = 'COMPLETED',
  SUBMITTED = 'SUBMITTED',
}

export type SectionStatuses = Statuses.NOT_STARTED | Statuses.IN_PROGRESS | Statuses.COMPLETE;

export interface ModuleSection {
  sectionId: SectionId;
  title: string;
  status: SectionStatuses;
}

enum ModulesIds {
  SAPPG = 'SAPPG',
  HCPPG = 'HCPPG',
  HHPPG = 'HHPPG',
  HIPPG = 'HIPPG',
  TXPPG = 'TXPPG',
  ICPPG = 'ICPPG',
  XPPPG = 'XPPPG',
  ASPPG = 'ASPPG',
  INPPG = 'INPPG',
  APPPG = 'APPPG',
  SUPPG = 'SUPPG',
  SSPPG = 'SSPPG',
  // For Partner portal newly introduced modules
  PHPPG = 'PHPPG',
  PRPPG = 'PRPPG',
  PIPPG = 'PIPPG',
  POPPG = 'POPPG',
  // For Admit/discharge newly introduced modules
  ADSPG = 'ADSPG',
  ADPIG = 'ADPIG',
  ADFIG = 'ADFIG',
  ADSSG = 'ADSSG',
  // For Newbborn newly introduced modules
  NBIPG = 'NBIPG',
  NBIMG = 'NBIMG',
  NBSMG = 'NBSMG',
}

export type ModuleId = keyof typeof ModulesIds;

export interface Module {
  moduleId: ModuleId;
  title: string;
  status: SectionStatuses;
  sections: ModuleSection[];
}

export type Modules = Module[];

export enum ProgramCodes {
  MA = 'MA',
  HK = 'HK',
  FP = 'FP',
  KB = 'KB',
  CA = 'CA',
  FS = 'FS',
  CC = 'CC',
  MS = 'MS',
}

export type ProgramCode = keyof typeof ProgramCodes;

interface Program extends ReferenceValue {
  code: ProgramCode;
}

export type Programs = Program[];

export type Comments = string | null;

export type Status = Statuses.IN_PROGRESS | Statuses.SUBMITTED;

export interface App {
  applicationNumber: ApplicationNumber;
  applicationType: string;
  applyingForHcbc: boolean;
  applyingForNursingFacilities: boolean;
  mode: Mode | null;
  modules: Module[];
  status: Status;
  programs: Programs;
}

export interface Step extends Module {
  sectionId: SectionId;
}

export type Stepper = Step[];

export interface PartialOptions {
  partialSubmission: true;
  incompleteSection?: SectionId;
}

export interface NextSection {
  modules: Modules;
  section: SectionStateModel;
}
