import { Component, AfterViewInit, AfterContentInit } from "@angular/core";
import { SectionFormDirective } from "@app/classes/section-form.class";
import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import { IGetCoverageDetails } from "@app/models/endCoverage.model";
import { Title } from "@angular/platform-browser";

@Component({
  templateUrl: "./end-coverage.component.html"
})

export class EndCoverageComponent extends SectionFormDirective implements AfterContentInit, AfterViewInit {
  errorMessage: boolean;
  infoMessage: any = false;
  subscription: Subscription;
  hohId: any;
  ssiEligibleMessage = false;
  ssiName: string;
  endCoverageCheckControls = false;
  coverageCount: IGetCoverageDetails[] = [];
  selectedIndivList = [];
  selectedAll = false;
  valuesToModal = [];
  householdMembers;
  members;

  constructor(builder: FormBuilder, private titleService: Title) {
    super(builder);
    this.titleService.setTitle('End Coverage');
  }

  ngAfterContentInit() {
    this.infoMessage = true;
    this.householdMembers = this.getValue('data.householdList');
    this.members = this.householdMembers.filter(item => item.headOfHousehold === 'N');
    this.hohId = this.householdMembers.filter(item => item.headOfHousehold === 'Y');
    this.coverageCount = this.members.length + this.hohId.length;
    this.selectedIndivList = this.householdMembers.filter(indv => indv.deleteFlag === 'true');
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  selectedAction(value) {
    const ctrl = this.getControl('data.selectedList');

    this.errorMessage = false;
    this.ssiEligibleMessage = false;
    if (this.endCoverageCheckControls) {
      this.selectedAll = false;
      this.selectedIndivList = [];
      return;
    }

    const hohVal = value.headOfHousehold ? value.headOfHousehold : value[0].headOfHousehold;
    if (this.hohId.length && this.coverageCount && hohVal === this.hohId[0].headOfHousehold) {
      this.selectedAll = !this.selectedAll;
      this.selectedIndivList = [];
      ctrl.reset();
      if (this.selectedAll) {
        this.householdMembers.forEach((individual) => {
          this.selectedIndivList.push(individual);
          ctrl.value.push(individual);
        });
      } else {
        this.selectedIndivList = [];
        ctrl.reset();
      }
    } else {
      if (this.selectedAll) {
        this.selectedAll = false;
        this.selectedIndivList = [];
        ctrl.reset();
        this.selectedIndivList.push(value);
        ctrl.value.push(value);
      } else {
        if (!this.selectedIndivList.includes(value)) {
          this.selectedIndivList.push(value);
          ctrl.value.push(value);
        } else {
          const indexToDel = this.selectedIndivList.indexOf(value);
          this.selectedIndivList.splice(indexToDel, 1);
          ctrl.value.splice(indexToDel, 1);
        }
      }
    }
  }

  selectedTile(indiv) {
    const selectedIndvi = indiv.indvNum ? indiv.indvNum : indiv[0]?.indvNum;
    const selectedList = this.selectedIndivList.map(i => i.indvNum);

    return selectedList.includes(selectedIndvi);
  }

  deselectMembers() {
    const ctrl = this.getControl('data.selectedList');
    this.selectedAll = false;
    this.selectedIndivList = [];
    ctrl.reset();
  }
}
