import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { isRmbRmc } from '@app/helpers/mode.helpers';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {en_labels, sp_labels} from './other-expenses-labels';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './other-expenses.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherExpensesComponent extends SectionFormDirective implements AfterContentInit {
  isExpenseKindCodeDisabled: any = null;
  healthCoverage;
  premiumAmount;
  cobraPlan;
  retireePlan;
  isRmbRmc = false;
  labels = {...en_labels};
  translatedLang;
  defaultLanguage;

  constructor(private titleService: Title, builder: FormBuilder, public translateService: TranslateService) {
    super(builder);
    this.titleService.setTitle('Other Expenses');
  }

  languageCheck(language) {
    if (language === 'en') {
      this.labels = {...en_labels};
    } else {
      this.labels = {...sp_labels}
    }
  }

  tableCodeValue (tableName, tableControl){
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }

  ngAfterContentInit() {
    this.isRmbRmc = isRmbRmc(this.mode);
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang =  this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);

    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang =  res.lang;
      this.languageCheck(res.lang);
    });
    this.healthCoverage = this.labels.minRequirementMetSwTooltip;
    this.premiumAmount = this.labels.policyPremiumAmountTooltip;
    this.cobraPlan = this.labels.cobraSwTooltip;
    this.retireePlan = this.labels.retireeSwTooltip;
  }
  ngAfterViewInit(): void {
    window.scroll(0,0);
  }
}
