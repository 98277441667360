import { curry, objOf, compose, mergeAll, map, invoker } from 'ramda';

import { inSelected } from './list.helper';
import { codes } from './code.helpers';

export const at = invoker(1, 'at');
export const push = invoker(1, 'push');
export const removeAt = invoker(1, 'removeAt');

const selectedCodes = curry((check, element) => objOf(element, check(element)));
const checkboxList = checker =>
  compose(
    mergeAll,
    map(selectedCodes(checker)),
    codes
  );

export const checkboxHelper = compose(
  checkboxList,
  inSelected
);
