<p *ngIf="isRmbRmc">{{ 'OTHER_COVERAGE_STATICTEXT14' | translate }}</p>
<h6 class="person-label">{{selectedIndividual | fullname}}</h6>
<nh-form [topLevelName] = "'sp'" [individuals]="checkForDropdown ? finalListIndvs : individuals" [formGroup]="getForm()" [mode]="mode" class="label-section"
  topLevelQuestion="{{'MANDATORY_PAYMENTS_STATIC_TEXT1' | translate }}"
  topLevelHintText="{{'MANDATORY_PAYMENTS_STATIC_TEXT2' | translate }}"
  [individualQuestion]="individualQues"
  [hintText]="hintText"
  popupText="{{'MANDATORY_PAYMENTS_STATIC_TEXT3' | translate }}"  (validate)="onValidate($event)">

  <section formGroupName="data">
    <h5 class="section-header">
      {{'MANDATORY_PAYMENTS_STATIC_TEXT4' | translate }}
    </h5>
    <hr>
    <div>
      <nh-field id = "sp_supportKindCode" label="{{'MANDATORY_PAYMENTS_STATIC_TEXT5' | translate}} {{selectedIndividual | fullname}}{{'MANDATORY_PAYMENTS_STATIC_TEXT6' | translate }}" class="label-section">
        <select nh name="supportKindCode" [items]="tableCodeValue('CHILDSUPMANEXPENSETYPE', 'data.supportKindCode')" formControlName="supportKindCode"></select>
      </nh-field>
      <nh-field id = "sp_expenseAmount" label="{{'OTHER_INCOME_SATIC_TEXT26' | translate}} {{selectedIndividual | fullname}}{{'UTILITIES_EXPENSES_STATIC_TEXT6' | translate }}" class="label-section">
        <div class=hint>{{'MANDATORY_PAYMENTS_STATIC_TEXT7' | translate }}</div>
        <nh-input-group prepend="$">
          <input nh type="decimal"  maxlength="7" name="expenseAmount" formControlName="expenseAmount" class="form-control">
        </nh-input-group>
      </nh-field>
    </div>
  </section>
</nh-form>
