import { Pipe, PipeTransform } from '@angular/core';

import { splitPath } from '@app/helpers/function.helpers';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'path' })
export class PathPipe implements PipeTransform {
  constructor(public translateService: TranslateService) {}
  transform(value: any, path: string): string {
    if (this.translateService.getDefaultLang() !== 'en') {
      if (path && path !== 'data.valueOfAsset') {
        path = path.replace(".value", ".spValue");
      }
    } else {
      path = path;
    }
   return path ? splitPath('', path, value) : value;
  }
}
