import { Injectable } from "@angular/core";
import { ApiService } from "@app/gateway/services/api.service";
import { AuthService } from "@app/gateway/services/auth.service";
import { BehaviorSubject, of, Subject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { HttpHeaders  } from '@angular/common/http';
import { ServiceConfigService } from '@app/services/service-config.service';

@Injectable()
export class SigninService {
  getAccountDataSubject = new Subject<any>();
  accountData = this.getAccountDataSubject.asObservable();
  getIndivId = new BehaviorSubject<string>("")
  indivId = this.getIndivId.asObservable();
  userLoggedIn = new BehaviorSubject<boolean>(false);
  tcamKey:string;
  // @ts-ignore
  constructor(private apiService: ApiService, private authService: AuthService, private serverConfig: ServiceConfigService) {
    this.tcamKey = this.serverConfig.getServerConfig().TCAM_KEY;
  }

  signIn(userId: string) {
    if (userId) {
      const url = 'publ/signin/validate?userId=' + userId;
      return this.apiService.get(url);
    }
    return of(null);
  }

  validate(user) {
    const contentHeader = new HttpHeaders({ "Content-Type": "application/json", "pageId": "TCLGN"});
    const url = 'publ/users/loginUser';
    return this.apiService.post(url, user, { headers: contentHeader, observe: 'response' }).pipe(switchMap((res: any) => {
      sessionStorage.setItem('userName', user.userName);
      if (!res.body['message']) {
      const authToken = res.headers.get('x-auth-token');
      this.authService.setToken(authToken);
      document.cookie = this.tcamKey+"=" + res.headers.get('tcam') + "; path=/;";
      const tcam = res.headers.get('tcam');
      sessionStorage.setItem('tcam', tcam);
      return this.getAccountData (user.userName).pipe(switchMap(data => {
        if (data) {
          sessionStorage.setItem('currentUser', JSON.stringify(data));
          sessionStorage.setItem('appNum', data.appNum);
          sessionStorage.setItem('individualId', data.indvId);
          sessionStorage.setItem('hoh', data.headOfHousehold);
          sessionStorage.setItem('firstTimeAccessSw', data.firstTimeAccessSw);
          this.userLoggedIn.next(true);
          this.getAccountDataSubject.next(data);
        }
        return of(null)
      }))
  } else {
    const errorMessage = res.body['message'];
      this.userLoggedIn.next(false);
        return of(errorMessage)
      }
    }))
  }

  getAccountData (userId: String) {
    if (userId) {
      const url = 'publ/signin/validate/info?userId=' + userId;
      return this.apiService.get(url)
    }
    return of(null);
  }

  getCaseData (accountType, caseNum, user) {
    const url = 'indi/atglance?accountType=' + accountType + '&caseNumber=' + caseNum + '&userId=' + user.userId;
    return this.apiService.get(url);
  }

  signOut (bool) {
    this.userLoggedIn.next(bool);
  }
}