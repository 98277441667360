import { compose } from 'ramda';
import { lensProp } from 'ramda';
import { lensIndex } from 'ramda';
import { lensPath } from 'ramda';
import { zipObj } from 'ramda';
import { over } from 'ramda';
import { view } from 'ramda';
import { set } from 'ramda';

import { viewOr, paths } from 'ramda-adjunct';
import { orView } from './flipped.helpers';

const appL = lensProp('app');
const applicationNumberL = lensProp('applicationNumber');

const individualsL = lensProp('individuals');
const programsL = lensProp('programs');
const commentsL = lensProp('comments');
const modulesL = lensProp('modules');

const sectionL = lensProp('section');
const personL = lensProp('person');
const dataL = lensProp('data');
const blocksL = lensProp('blocks');
export const payloadL = lensProp('payload');

const paramsL = lensProp('params');
const appNumberL = lensProp('appNumber');

const appApplicationNumberL = compose(
  appL,
  applicationNumberL
);

const appIndividualsL = compose(
  appL,
  individualsL
);

const appProgramsL = compose(
  appL,
  programsL
);

const appCommentsL = compose(
  appL,
  commentsL
);

const appModulesL = compose(
  appL,
  modulesL
);

const appSectionL = compose(
  appL,
  sectionL
);

const appSectionPersonL = compose(
  appSectionL,
  personL
);

const dataBlocksL = compose(
  dataL,
  blocksL
);

const appSectionPayloadL = compose(
  appSectionL,
  payloadL
);

const paramsAppNumberL = compose(
  paramsL,
  appNumberL
);

const moduleIdL = lensProp('moduleId');
const sectionsL = lensProp('sections');
const sectionIdL = lensProp('sectionId');

const zeroL = lensIndex(0);

const identifierL = lensProp('identifier');
const recordsL = lensProp('records');
const titleL = lensProp('title');
const statusL = lensProp('status');
const activePersonL = lensProp('activePerson');
const activeBlockL = lensProp('activeBlock');

const buttonAddL = lensProp('buttonAdd');

const firstIdL = compose(
  sectionsL,
  zeroL,
  sectionIdL
);

const personBlocksL = compose(
  activePersonL,
  dataBlocksL
);

const viewPeople = view(appSectionPersonL);
const viewActivePerson = view(activePersonL);
const viewActiveBlock = view(activeBlockL);

export const viewApp = view(appL);
export const viewApplicationNumber = view(appApplicationNumberL);
export const viewModules = view(appModulesL);
export const viewFirstId = view(firstIdL);
export const viewBlocks = view(personBlocksL);
export const viewIdentifier = view(identifierL);
export const viewSections = view(sectionsL);
export const viewTitle = view(titleL);
export const viewSectionId = view(sectionIdL);
export const viewRecords = viewOr([], recordsL);
export const viewIndividuals = view(appIndividualsL);
export const viewComments = view(appCommentsL);
export const viewPrograms = view(appProgramsL);
export const viewModuleId = view(moduleIdL);
export const viewPayload = view(appSectionPayloadL);
export const viewStatus = view(statusL);

export const viewBlock = compose(
  viewActiveBlock,
  viewPeople
);

export const viewPerson = compose(
  viewActivePerson,
  viewPeople
);

export const overModules = over(appModulesL);

export const updateRecordId = set(identifierL);
export const setModuleIdL = set(moduleIdL);

export const viewAppNumber = view(paramsAppNumberL);

export const viewFirstRecordData = view(lensPath(['records', 0, 'data']));

export const viewButtonadd = orView(buttonAddL);

export const viewAfterRecord = compose(
  zipObj(['payload', 'activeBlock']),
  paths([['app', 'section', 'payload'], ['app', 'section', 'person', 'activeBlock']])
); //
