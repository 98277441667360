import { identity } from 'ramda';
import { ifElse } from 'ramda';
import { always } from 'ramda';
import { equals } from 'ramda';
import { useWith } from 'ramda';
import { forEach } from 'ramda';
import { apply } from 'ramda';
import { objOf } from 'ramda';
import { when } from 'ramda';

import { isNotNil } from 'ramda-adjunct';

import { setValue } from './form.helpers';
import { lookUp, queer } from './flipped.helpers';
import { lookups } from './code.helpers';
import { enableSelf, enable } from './able.helpers';
import { isYes, setValueYes, setValueNo } from './tayn.helpers';
import { enableControls, disableReset, disableResetControls, enableResetControls } from './reset-able.helpers';

const controlValue = useWith(queer, [apply(ifElse), objOf, identity]);
export const yesNoValue = controlValue([isYes, always(true), always(false)]);
export const getValue = controlValue([isNotNil, identity, always(null)]);
export const hasValue = controlValue([isNotNil, always(true), always(false)]);

export const swapControl = (control, predicate) =>
  ifElse(predicate, () => enable(control), () => disableReset(control));

export const swapControls = (controls, predicate) =>
  ifElse(predicate, () => enableControls(controls), () => disableResetControls(controls));

export const swapResetControl = (controls, predicate) =>
  ifElse(predicate, () => enableResetControls(controls), () => disableResetControls(controls));

export const toggleControls = (controls, predicate: Function = equals, to: any = true) =>
  ifElse(predicate(to), () => enableControls(controls), () => disableResetControls(controls));

export const toggleValue = (control, i = identity, e = always(null), predicate = isYes) =>
  ifElse(predicate, v => setValue(i(v), control), v => setValue(e(v), control));

export const toggleYesNo = (control, predicate, nV = setValueNo, yV = setValueYes) =>
  ifElse(predicate, () => yV(control), () => nV(control));

export const switchControls = (controlIf, controlElse, predicate: Function) =>
  ifElse(
    predicate,
    () => [enable(controlIf), disableReset(controlElse)],
    () => [enable(controlElse), disableReset(controlIf)]
  );

export const toggles = (changes: any[]) => ref => forEach(change => change(ref), changes);

const setWhen = (control, predicate, set) => when(predicate, () => set(control));

export const swapYes = control => setWhen(control, isYes, setValueNo);

export const enableSelfControls = forEach(enableSelf);

export const tableByCode = useWith(lookups, [lookUp, lookUp]);
