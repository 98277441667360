<nh-form [individuals]="individuals" [formGroup]="getForm()" [mode]="mode" (validate)="onValidate($event)">
	<nh-alert type="info" [isOpen]="infoMessage && !errorMessage && !ssiEligibleMessage">{{'END_COVERAGE_VALIDATION' | translate }}
	</nh-alert>

    <nh-alert type="danger" [isOpen]="errorMessage">
		<div class="row">
		  <div class="icon">
			<img src="../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
		  </div>
		  <div class="message">
			<span>{{'END_COVERAGE_VALIDATION' | translate }}</span>
		  </div>
		</div>
	  </nh-alert>

    <nh-alert type="danger" [isOpen]="ssiEligibleMessage">
		<div class="row">
		  <div class="icon">
			<img src="../../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
		  </div>
		  <div class="message">
			<span>{{ssiName}} {{'END_COVERAGE_VALIDATION_2' | translate }}</span>
		  </div>
		</div>
	  </nh-alert>

	<div class="static-text">
		<p>{{'END_COVERAGE_STATIC_TEXT1' | translate }}</p>
		<h5 class="section-header">{{ 'End Coverage' | translate }}</h5>
		<hr>
		<p>{{'END_COVERAGE_STATIC_TEXT2' | translate }}</p>
		<p>{{'END_COVERAGE_STATIC_TEXT3' | translate }}</p>

		<div class="row pl-3">
			<div *ngFor="let indiv of members; let i = index" [ngClass]="{'applyColor': selectedTile(indiv)}"
				class="card card-body shadow mr-3 mt-3 col-md-5">
				<div (click)="selectedAction(indiv)">
					<div class="d-flex align-items-center">
						<ul>
							<li>
								<strong>{{indiv.firstNam}} {{indiv.lastNam}} </strong>
								<strong>{{indiv.age}}</strong>
								<strong>{{indiv.genderCd}}</strong>
							</li>
							<li>
								<div>{{ 'Case:' | translate }} <strong>{{indiv.caseNum}}</strong></div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div *ngIf="hohId.length" id="bckgrnd" class="card card-body shadow mr-3 mt-3 col-md-5"
				[ngClass]="{'applyColor': selectedTile(hohId) }" (click)="selectedAction(hohId)">
				<div class="d-flex align-items-center pl-2">
					<div>{{'END_COVERAGE_STATIC_TEXT4' | translate }}</div>
				</div>
			</div>
			<br>
		</div>
		<br>
		<div>

			<nh-checkbox name="doNotEndCoverage" [control]="getControl('extras.helpers.doNotEndCoverage')"
				(change)=deselectMembers() checkedValue="true" label="{{'END_COVERAGE_STATIC_TEXT5' | translate }}"
				class="label-section">
			</nh-checkbox>
		</div>
	</div>
</nh-form>