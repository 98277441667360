import { map, compose, mergeAll, pluck, zipObj, converge, mapObjIndexed, filter, allPass } from 'ramda';
import { paths, isNotNil, isNonEmptyString } from 'ramda-adjunct';

import { nullList } from './null.helpers';
import { fromCode, codeIndexed } from './code.helpers';
import { callWith, zipPair } from './function.helpers';
import { toObject } from './object.helpers';
import { validValues } from './form.helpers';
import { lookUp } from './flipped.helpers';

export const selectedValues = ps =>
  compose(
    mergeAll,
    map(
      compose(
        zipPair,
        paths(ps)
      )
    )
  );

export const tableGroup = converge(zipObj, [pluck('code'), nullList]);

export const validFromTable = (formatter, table) =>
  compose(
    validValues,
    mapObjIndexed(callWith([formatter, fromCode(table)])),
    filter(allPass([isNotNil, isNonEmptyString]))
  );

export const refValue = toObject(['code', 'value']);

export const tableLookup = compose(
  lookUp,
  codeIndexed
);

export const formatTableData = (tableData, field) => {
  let tabelCode = {};
  for (const key in tableData) {
    if (tableData.hasOwnProperty(key)) {
      const element = tableData[key];
      tabelCode[key] = element.map(item =>  {
        if (!item.value) {
        if (field === 'spDescription' && !item[field]) {
          if(item['description'] === 'Si'){
            item['description'] = 'Yes';
          }
         item.spDescription= item['description']
        }
        return {...item, value: item[field]}
      } else {
        if(field === 'spDescription' &&  (item['spDescription'] !== undefined)){
          item.value = item['spDescription']
        }
        else if(field === 'description' &&  (item['description'] !== undefined)){
          if(item['description'] === 'Si'){
            item['description'] = 'Yes';
          }
          item.value = item['description']
        }
        return item;
      }
      })
    }
  }
  return tabelCode;
};