import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../services/api.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'nh-register-account-validate',
  templateUrl: './register-account-validate.component.html',
  styleUrls: ['./register-account-validate.component.scss']
})
export class RegisterAccountValidateComponent implements OnInit {
  headerTitle = "";
  sectionTitle = "";
  currentLang;
  contentHeader = new HttpHeaders({'pageId': 'TCCAT'})

  isRegisterAccountLinkValid: boolean;

  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService,  public translateService: TranslateService, private titleService: Title
    ) {
      this.titleService.setTitle('Register Account');  
      this.activatedRoute.queryParams.subscribe(res => {
      if (res && res.langcd) {
        this.currentLang = res.langcd === 'sp' ? 'ES' : res.langcd
        if (this.currentLang === 'ES') {
          this.translateService.setDefaultLang('sp');
        }
      } else translateService.onDefaultLangChange.subscribe(response => this.switchLanguage(response.lang));
    }) 
  }

  switchLanguage(lang) {
    if (lang === 'sp') {
      this.currentLang = 'ES'
    } else {
      this.currentLang = 'EN'
    }
  };

  ngOnInit(): void {
    const validateLinkObj = {}
    this.activatedRoute.queryParams.subscribe((response) => {
      validateLinkObj['reasonCode'] = response.reasonCode;
      validateLinkObj['token'] = response.token;
      validateLinkObj['language'] = this.currentLang;
      validateLinkObj['userName'] = response.loginAttribute
    })

    this.apiService.post('publ/users/validateLinks', validateLinkObj, {headers : this.contentHeader}).subscribe((response) => {
      response['language'] === 'ES' ? this.translateService.setDefaultLang('sp') : this.translateService.setDefaultLang('en');
      if (response['code'] === '400') {
        this.isRegisterAccountLinkValid = false;
        this.sectionTitle = 'REGISTRATION_LINK_EXP_SUB_TIILE';
        this.headerTitle = 'LINK_EXPRIED';
      } else if (response['code'] === '200') {
        this.isRegisterAccountLinkValid = true;
        this.sectionTitle = 'REGISTRATION_LINK_SUCC_SUB_TITLE';
        this.headerTitle = 'SUCCESS';
      }
    })
  }
}
