import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';

import { SpinnerService } from '@app/services/spinner/spinner.service';

@Component({
  selector: 'nh-spinner',
  template: `
  <div *ngIf="show" class="spinner">
    <img alt="spinningWheel" src="assets/images/loading.svg" />
  </div>
`,
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements OnInit {
  show = false;
  constructor(public spinner: SpinnerService, private changeDetectorRef : ChangeDetectorRef) {}

  ngOnInit() {
    this.spinner.status.subscribe(r => {
      this.show = r;
      this.changeDetectorRef.detectChanges();
    });
  }
}
