import { EndCoverageComponent } from './end-coverage.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { aPath } from '@app/helpers/function.helpers';
// import Vs from '@app/services/validators/validators.service';


const formValidation = (fb, recordData) => {  
  const hhMembers = aPath(['endMyCoverage'], recordData);

  const helpers = fb.group({
    doNotEndCoverage: [''],
  });

  const data = fb.group({
    householdList: fb.array(hhMembers),
    selectedList: fb.array([])
  });
  return { data, helpers };
}

export default sectionConfiguration(EndCoverageComponent, formValidation, [], [], {}, {
  hasSummary: false,
  showComments: true,
  showSubmit: false,
});
