import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UserProfileService } from '../user-profile.service';
import { CommunicationPreferencesService } from "./communication-preferences.service";
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'communication-preferences',
  templateUrl: './communication-preferences.component.html',
  styleUrls: ['./communication-preferences.component.scss']
})
export class CommunicationPreferencesComponent implements OnInit, OnDestroy {
  res;
  onEdit = false;
  truthyValues = { "1": true, "0": false };
  objRes;
  isCancelClicked = false;
  ifRequired = false;
  enterPhone = false;
  isDisabled = false;
  enableText = false;
  subscription: Subscription;
  emailId: string;
  emailAddress: string;
  @ViewChild('emailPref') emailPref: ElementRef;
  @ViewChild('textPref') textPref: ElementRef;

  constructor(private userProfileService: UserProfileService, public communicationPreferencesSvc: CommunicationPreferencesService, private titleService: Title
    ) {
    this.communicationPreferencesSvc.communicationPreferFormState = false;
    this.titleService.setTitle('Communication Preferences');
  }
  ngOnInit(): void {
    this.getData();
    this.subscription = this.communicationPreferencesSvc.getUserAction().subscribe((res) => {
      if (res === 'save') {
        this.updateData();
        this.showCommPreferAlert();
      }
      else if (res === 'cancel') {
        this.reset();
      }
    });

    this.userProfileService.getEmailId().subscribe((res: string) => {
      this.emailId = res;
    })
    const userType = sessionStorage.getItem('userType');
    if(userType === 'RP'){
    this.isDisabled = true;
    this.enableText = true;
    }else{
      this.isDisabled = false;
      this.enableText = false;
    }
  }
  communicationFormCheck(communicationForm) {
    if(!this.emailPref.nativeElement.checked){
      this.textPref.nativeElement.checked = false;
      this.res.textSw = 0;
      this.communicationPreferencesSvc.communicationPreferFormState = communicationForm.dirty;
    }
    if (!this.textPref.nativeElement.checked) {
     this.res.textSw = 0;
     this.objRes.phoneNumber = "";
      this.res.phoneNumber = '';
      this.ifRequired = false;
    }
    else if (this.textPref.nativeElement.checked && this.res.phoneNumber?.length < 10) {
      this.ifRequired = true;
     this.onEdit = true;
      this.communicationPreferencesSvc.communicationPreferFormState = false;
    }

      if(!this.ifRequired && !this.enterPhone && this.textPref.nativeElement.checked){
      this.communicationPreferencesSvc.communicationPreferFormState = false;
      }
      if(this.textPref.nativeElement.checked && (this.res.phoneNumber?.length === 10)){
        this.communicationPreferencesSvc.communicationPreferFormState = communicationForm.dirty;
      }
      if(this.emailPref.nativeElement.checked && !this.textPref.nativeElement.checked && !this.ifRequired){
        this.communicationPreferencesSvc.communicationPreferFormState = communicationForm.dirty;
      }
     const iscommunicationPreferencesDisabled = !this.res.textSw && !this.res.emailSw && !this.res.paperSw;
     if(iscommunicationPreferencesDisabled) {
      this.communicationPreferencesSvc.communicationPreferFormState = false;
     }
    this.showCommPreferAlert();
  }

  showCommPreferAlert(){
    const iscommunicationPreferencesDisabled = !this.res.textSw && !this.res.emailSw && !this.res.paperSw;
    if(iscommunicationPreferencesDisabled) {
      this.communicationPreferencesSvc.communicationPreferFormState = false;
      this.communicationPreferencesSvc.communicationAlertShow = true;
     }else{
      this.communicationPreferencesSvc.communicationAlertShow = false;
     }
  }
  reset() {
    this.getData();
    this.isCancelClicked = true;
  }

  onChange(response) {
    if (response ?.length < 10) {
      this.ifRequired = true;
      this.communicationPreferencesSvc.communicationPreferFormState = false;
    } else {
      this.ifRequired = false;
    }
  }

  updateData() {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const indiv = sessionStorage.getItem('individualId');
    const currentLang = sessionStorage.getItem('currentLang');
    const data = {
      "userName": currentUser?.userId,
      "languagePreference": currentLang === 'en'? 'EN' : 'SP',
      "indvId": indiv,
      "paperSw": this.res.paperSw === true ? "1" : "0",
      "emailSw": (this.res.emailSw === true) || (this.res.textSw === true) ? "1" : "0",
      "textSw": this.res.textSw === true ? "1" : "0",
      "phoneNumber": this.res.textSw === true ? this.res.phoneNumber : this.objRes.phoneNumber,
      "email":  ((this.res.emailSw === true) || (this.res.textSw === true)) ? this.emailId : ""
    }
    this.userProfileService.postCommunicationPreferenceData(data).subscribe(() => {
      this.getData();
    })
    this.isCancelClicked = false;
  }

  getData() {
    this.res = {};
    const indiv = sessionStorage.getItem('individualId');
    this.userProfileService.getCommunicationPreferenceData(indiv).subscribe((response) => {
      this.objRes = { ...response };
      Object.keys(this.objRes).forEach((key: any) => {
        if (this.objRes[key] && (this.objRes[key] === "1" || this.objRes[key] === "0")) this.res[key] = this.truthyValues[this.objRes[key]];
        else this.res[key] = this.objRes[key];
      })
      if (this.objRes ?.phoneNumber) {
        this.enterPhone = true;
      } else {
        this.enterPhone = false;
      }
      if(this.objRes ?.emailAddress && this.objRes ?.emailAddress!=null ){
        this.emailAddress = this.objRes ?.emailAddress;
      }else{
        this.emailAddress = '';
        this.emailPref.nativeElement.isDisabled;
      }
    })
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.communicationPreferencesSvc.communicationAlertShow = false
  }
}
