<div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title pull-left" id="my-modal-title">{{'FAQS' | translate}}</h5>
      <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
        <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
      </button>
    </div>
    <div class="modal-body">
        <div id="accordion">
            <ng-container *ngFor="let itemPar of data">
                <div class="card card-background p-3" *ngFor="let item of itemPar.questions; index as i" (click)="cardClickHandler($event,item)">
                    <div *ngIf="i === 0" class="pb-3">
                        <h5 class="section-header">{{itemPar.header | translate}}</h5>
                        <hr>
                    </div>
                    <h6 attr.aria-label="collapse{{i}}">
                        <div data-toggle="collapse" attr.data-target="#collapse{{i}}">
                            <div class="d-flex align-items-center">
                                <div class="item-key"><strong>{{item.key | translate}}</strong></div>
                                <div class="ml-3 item-key-symbol" *ngIf="item.show"><i class="fa fa-angle-up"></i></div>
                                <div class="ml-3 item-key-symbol" *ngIf="!item.show"><i class="fa fa-angle-down"></i></div>
                            </div>
                        </div>
                    </h6>
                    <div id="collapse{{i}}" [ngClass]="{'show': item.show}" class="collapse" aria-labelledby="headingOne"
                        data-parent="#accordion">
                        <div class="card-body">
                            <div [innerHtml]="item.value | translate">
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div> 
    </div>
</div>