import { Component, ChangeDetectionStrategy, AfterContentInit } from "@angular/core";
import { FormArray, FormBuilder } from "@angular/forms";
import { SectionFormDirective } from "@app/classes/section-form.class";
import { minus120Years } from "@app/helpers/date.helpers";
import { groupControls } from "@app/helpers/form-group.helpers";
import { ModalService } from "@app/services/modal/modal.service";
import Vs from "@app/services/validators/validators.service";
import { TranslateService } from "@ngx-translate/core";
import { formatTableData } from "@app/helpers/tables.helpers";
import { isRmbRmc } from "@app/helpers/mode.helpers";
import { Title } from "@angular/platform-browser";

@Component({
  templateUrl: "./tax-deductions.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TaxDeductionsComponent
  extends SectionFormDirective
  implements AfterContentInit {
  today = new Date();
  minimumDate = minus120Years();
  isDesktop = window.innerWidth >= 768 ? true : false;
  taxDeductionTypeAlp;
  popTaxDeductions;
  popTaxDeductionsEn = `Tax deductions are expenses you have during the year that can be subtracted from your gross income to lower the amount you may owe in federal income taxes. Sometimes these are called "write offs"`;
  popTaxDeductionsSp = `Las deducciones de impuestos son gastos que tiene durante el año que se pueden restar de sus ingresos brutos para reducir la cantidad que puede adeudar en impuestos federales sobre la renta.  A veces, estos se denominan "deducciones"`;
  defaultLanguage;
  translatedLang;
  isRmbRmc = false;

  get taxDeductionsIndvData(): FormArray {
    return this.getControl("data.taxDeductionsIndvData") as FormArray;
  }

  constructor(
    private modalService: ModalService,
    builder: FormBuilder,
    public translateService: TranslateService,
    private titleService: Title
  ) {
    super(builder);
    this.isDesktop = window.innerWidth >= 768 ? true : false;
    this.titleService.setTitle('Tax Deductions');
  }

  ngAfterContentInit() {
    this.isRmbRmc = isRmbRmc(this.mode);
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);
    if (this.defaultLanguage === "en") {
      this.popTaxDeductions = this.popTaxDeductionsEn;
    } else {
      this.popTaxDeductions = this.popTaxDeductionsSp;
    }

    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.languageCheck(res.lang);
      if (res.lang === "en") {
        this.popTaxDeductions = this.popTaxDeductionsEn;
      } else {
        this.popTaxDeductions = this.popTaxDeductionsSp;
      }
    });
    const data = this.getControl("data");
    const taxDeductions = data.get("taxDeductions");
    if (taxDeductions.value?.code === "Y") {
      this.taxDeductionsIndvData.controls.forEach((val) => {
        if (val && val.value.deductionType)
          this.onTaxTypeChange(val.value.deductionType.code, val);
      });
    }

    this.registerToggle(taxDeductions, (val) => this.clearVal(val));
  }

  languageCheck(language) {
    this.tables =
      language === "sp"
        ? formatTableData(this.tables, "spDescription")
        : formatTableData(this.tables, "description");
  }

  tableCodeValue(tableName, tableControl) {
    return this.getTableCodeValues(
      tableName,
      tableControl,
      this.translatedLang
    );
  }

  clearVal(val) {
    const taxDeductionsIndv = this.getControl(
      "data.taxDeductionsIndvData"
    ) as FormArray;
    if (val.code === "N") {
      taxDeductionsIndv.clear();
    } else if (val.code === "Y") {
      taxDeductionsIndv.value.length > 0
        ? this.taxDeductionsIndvData.controls.forEach((value) => {
            this.onIndividualSelectForTaxDeduction(value);
          })
        : this.addTaxDeductionsRow();
    }
  }
  onResize(e) {
    this.isDesktop = e.target.innerWidth >= 768 ? true : false;
  }
  addTaxDeductionsRow() {
    this.taxDeductionsIndvData.push(this.createTaxDeductionsItem());
    const taxDeductionsIndiv = groupControls(
      this.getControl("data.taxDeductionsIndvData")
    );
    taxDeductionsIndiv.forEach((val) =>
      this.onIndividualSelectForTaxDeduction(val)
    );
  }

  createTaxDeductionsItem() {
    return this.builder.group({
      indvId: this.selectedIndividual.identifier,
      deductionType: [null, [Vs.required]],
      deductionAmount: [null, [Vs.required]],
      alimonyOrderDate: null,
      otherExpenseName: null,
      taxDedSeqNum: null,
    });
  }

  onTaxTypeChange(val, row) {
    row.controls["alimonyOrderDate"].clearValidators();
    row.controls["alimonyOrderDate"].updateValueAndValidity();
    row.controls["otherExpenseName"].clearValidators();
    row.controls["otherExpenseName"].updateValueAndValidity();
    if (val === "ALP") {
      row.controls["alimonyOrderDate"].setValidators([
        Vs.required,
        Vs.validDate,
        Vs.maxFutureDate,
        Vs.notBefore120Years,
      ]);
      row.controls["alimonyOrderDate"].updateValueAndValidity();
    } else if (val === "OTR") {
      row.controls["otherExpenseName"].setValidators([
        Vs.required,
        Vs.otherExpense,
      ]);
      row.controls["otherExpenseName"].updateValueAndValidity();
    }
  }
  taxTypeAlp(val) {
    return val && val == "ALP" ? true : false;
  }

  taxTypeOther(val) {
    return val && val == "OTR" ? true : false;
  }
  removeTaxDeductionsRow(index: number) {
    this.modalService.confirmDelete().subscribe((res) => {
      if (res) this.taxDeductionsIndvData.removeAt(index);
      if (this.taxDeductionsIndvData.controls.length === 0)
        this.addTaxDeductionsRow();
    });
  }

  onIndividualSelectForTaxDeduction(row) {
    row.controls["indvId"].setValidators([Vs.required]);
    row.controls["indvId"].updateValueAndValidity();
    row.controls["deductionAmount"].setValidators([Vs.required, Vs.currency]);
    row.controls["deductionAmount"].updateValueAndValidity();
    row.controls["deductionType"].setValidators([Vs.required]);
    row.controls["deductionType"].updateValueAndValidity();
  }
}
