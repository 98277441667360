import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'nh-at-a-glance',
    templateUrl: './at-a-glance.component.html',
    styleUrls: ['./at-a-glance.component.scss']
})

export class AtAGlanceComponent implements OnInit {
    collapsible = false;
    activatedUrl;
    constructor (private router: Router) {
        if (this.router.url.includes('security-settings')) {
            this.activatedUrl = 'security'
        }
    }
    ngOnInit() {}
}