import { SummaryPresumptiveComponent } from './summary-presumptive.component';
import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration } from '@app/helpers/models.helpers';

const formValidation = (fb) => { 
  const data = fb.group({
    applicationSignedBySw: ['', Vs.required]
  });
  
  return {data}
};

export default sectionConfiguration(SummaryPresumptiveComponent, formValidation, [], [], {}, {
  hasSummary: false,
  showComments: true,
  showSubmit: false,
});
