import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "@app/gateway/services/api.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class ChangePasswordService {
  validPassword: boolean = false;
  isAlertMessage: boolean;
  alertMessage: any;
  currentLang;
  changePassword;

  constructor(private apiService: ApiService, private route: Router, public translateService: TranslateService) { 
    translateService.onDefaultLangChange.subscribe(res => this.switchLanguage(res.lang));
  }

  setLanguage() {
    this.currentLang = this.translateService.defaultLang === 'sp' ? 'ES' : 'EN';
  }

  isFormValid(): boolean {
    return ((this.changePassword.status == "VALID")
      && this.validPassword);
  }

  switchLanguage(lang) {
    if (lang === 'sp') {
      this.currentLang = 'ES'
    } else {
      this.currentLang = 'EN'
    }
  };

  updatePassword(): void {
    this.isAlertMessage = false;
    const updatePassword = {
      currentPwd: this.changePassword.controls['currentPassword'].value,
      newPwd: this.changePassword.controls['confirmPassword'].value,
      userName: JSON.parse(sessionStorage.getItem('currentUser')).userId,
      language: this.currentLang
    }
    this.apiService.post('indi/users/changePassword', updatePassword, {header: new HttpHeaders({'pageId': 'TCUPW'})}).subscribe((response) => {
      if (response['code'] == '200') {
        this.route.navigateByUrl('user-profile/manage-account?updatedPassword=true')
      } else if(response['code'] == '400') {
        this.isAlertMessage = true;
        if (response['message'] === 'New password has been used previously') {
          this.alertMessage = 'PREVIOUS_PWD';
        } else if (response['message'] === 'Password has been changed recently' || response['message'] === 'Failed to update') {
          this.alertMessage = 'PWD_ALREADY_CHANGED';
        } else if (response['message'] === 'Current password is incorrect.'){
          this.alertMessage = 'PWD_INCORRECT';
        }
      }
    })
  }
}