<div class="modal-content">

    <div class="modal-header">
      <h4 *ngIf="translatedLang === 'en'" class="modal-title pull-left" id="my-modal-title">Remove {{ this.fullName }}'s {{ this.title | translate }}</h4>
      <h4 *ngIf="translatedLang !== 'en'" class="modal-title pull-left" id="my-modal-title">{{this.removeSpanish}} {{ this.title | translate }} de {{ this.fullName }} </h4>
      <button type="button" class="close d-flex ml-auto p-2" aria-label="Close" (click)="onCancel()">
        <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
      </button>
    </div>
    <form *ngIf="this.sectionId !== 'ASCLD'" [formGroup]="form">
    <div class="modal-body">
      <div>
        <p *ngIf="this.sectionId !== 'HHAPL'">{{ headingExt }}</p>
        <div class="col-lg-8 col" *ngIf="this.sectionId !== 'HHAPL' && this.sectionId !== 'ICEMI' && this.sectionId !== 'ICSEM' && this.sectionId !== 'ASLIQ' && this.sectionId !== 'ASVEH' && this.sectionId !== 'ASPRP' && this.sectionId !== 'ASBUR' && this.sectionId !=='ASLIF' && this.sectionId !=='OTHRS' && this.sectionId !== 'ASCLD'">
          <nh-field label="{{ labelExt | translate }}" [isRow]="true" [mandatory]="true">
            <nh-datepicker>
              <input type="text" name="{{ formCtrlName }}" class="form-control" (change)="isInvalidDate($event)" formControlName="{{ formCtrlName }}" bsDatepicker nh [minDate]="minimumDate" [maxDate]="today" [required]="true">
            </nh-datepicker>
          </nh-field>
        </div>
        <p *ngIf="this.sectionId === 'HHAPL'">{{'RECORD_DELETE_STATIC_TEXT1' | translate}} {{ this.assistingPersonName }}{{ headingExt }}</p>
        <div class="col-lg-8 col" *ngIf="this.sectionId === 'HHAPL'">
          <nh-field label="{{'WHEN_DID' | translate }} {{ this.assistingPersonName }}{{ labelExt }}" [isRow]="true" [mandatory]="true">
            <nh-datepicker>
              <input type="text" name="{{ formCtrlName }}" class="form-control" (change)="isInvalidDate($event)" formControlName="{{ formCtrlName }}" bsDatepicker nh [minDate]="minimumDate" [maxDate]="today" [required]="true">
            </nh-datepicker>
          </nh-field>
        </div>
        <div class="col-lg-8 col" *ngIf="this.sectionId === 'ICSEM'">
          <nh-field label="{{ labelExt }}" [isRow]="true" [mandatory]="true">
            <nh-datepicker>
              <input type="text" name="{{ formCtrlName }}" class="form-control" (change)="isInvalidDate($event)" formControlName="{{ formCtrlName }}" bsDatepicker nh [minDate]="minimumDate" [maxDate]="today" [required]="true">
            </nh-datepicker>
          </nh-field>
        </div>
        <div class="col-lg-8 col" *ngIf="this.sectionId === 'ASLIQ' || this.sectionId === 'ASVEH' || this.sectionId === 'ASPRP' || this.sectionId === 'ASBUR' || this.sectionId ==='ASLIF' || this.sectionId ==='OTHRS'">
          <nh-field label="{{ labelExt }}" [isRow]="true" [mandatory]="true">
            <nh-datepicker>
              <input type="text" name="{{ formCtrlName }}" class="form-control" (change)="isInvalidDate($event)" formControlName="{{ formCtrlName }}" bsDatepicker nh [minDate]="minimumDate" [maxDate]="today" [required]="true">
            </nh-datepicker>
          </nh-field>
        </div>

        <div *ngIf="this.sectionId === 'ICEMI'">
          <div class="col-lg-8 col">
            <nh-field label="{{'RECORD_DELETE_STATIC_TEXT2' | translate }}" [isRow]="true" [mandatory]="true">
              <nh-datepicker>
                <input type="text" name="employmentEndDate" class="form-control" (change)="isInvalidDate($event)" formControlName="employmentEndDate" bsDatepicker nh [minDate]="minimumDate" [maxDate]="today" [required]="true">
              </nh-datepicker>
            </nh-field>
          </div>

          <div class="col-lg-8 col">
            <nh-field label="{{'RECORD_DELETE_STATIC_TEXT3' | translate }} {{ this.fullName }}{{'RECORD_DELETE_STATIC_TEXT4' | translate }}" [isRow]="true" [mandatory]="true">
              <nh-datepicker>
                <input type="text" name="lastPaycheckDate" class="form-control" (change)="isInvalidDate($event)" formControlName="lastPaycheckDate" bsDatepicker nh [minDate]="minimumDate" [maxDate]="today" [required]="this.sectionId === 'ICEMI'">
              </nh-datepicker>
            </nh-field>
          </div>
          <div class="col-lg-8 col">
            <nh-field label="{{'RECORD_DELETE_STATIC_TEXT5' | translate }} {{ this.fullName }}{{'RECORD_DELETE_STATIC_TEXT4' | translate }}" [isRow]="true" [mandatory]="true">
              <nh-input-group prepend="$">
                <input type="decimal" maxlength="7" class="form-control" name="lastPaycheckAmt" formControlName="lastPaycheckAmt" [required]="this.sectionId === 'ICEMI'">
              </nh-input-group>
            </nh-field>
          </div>
        </div>

      </div>
    </div>

    <div class="modal-footer d-block">
      <div class="form-row justify-content-start">
        <div class="mr-2 ml-2">
          <button type="button" id="dialog" [attr.disabled]="isDisabled" class="btn btn-block btn-secondary" *ngIf="showConfirm" (click)="onConfirm()">{{ 'SUBMIT' | translate }}</button>
        </div>
        <div class="mr-2 ml-2">
          <button type="button" id="dialog" class="btn btn-block btn-link" *ngIf="showDecline" (click)="onCancel()">{{ declineButton | translate }}</button>
        </div>
      </div>
    </div>
  </form>
  <form *ngIf="this.sectionId === 'ASCLD'" [formGroup]="agreeTermsForm">
    <div class="modal-body">
      <div>
        <p>{{ headingExt }}</p>
        <div class="col-lg-8 col">
          <nh-field label="{{ labelExt }}" [isRow]="true" [mandatory]="true">
            <nh-datepicker>
              <input type="text" name="{{ formCtrlName }}" class="form-control" (change)="isInvalidDate($event)" formControlName="{{ formCtrlName }}" bsDatepicker nh [minDate]="minimumDate" [maxDate]="today" [required]="true">
            </nh-datepicker>
          </nh-field>
          <nh-checkbox name="agreeTerms" [control]="agreeTermsForm.controls['agreeTerms']" checkedValue='checked'
          [mandatory]="true">{{'RECORD_DELETE_STATIC_TEXT6' | translate }}{{ this.fullName }}{{'RECORD_DELETE_STATIC_TEXT7' | translate }}
          </nh-checkbox>
        </div>
      </div>
    </div>
    <div class="modal-footer d-block">
      <div class="form-row justify-content-start">
        <div class="mr-2 ml-2">
          <button type="button" id="dialog" [attr.disabled]="isDisabled" class="btn btn-block btn-secondary" *ngIf="showConfirm" (click)="onConfirm()">{{'SUBMIT' | translate}}</button>
        </div>
        <div class="mr-2 ml-2">
          <button type="button" id="dialog" class="btn btn-block btn-link" *ngIf="showDecline" (click)="onCancel()">{{ declineButton | translate }}</button>
        </div>
      </div>
    </div>
  </form>
</div>
