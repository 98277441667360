import {
  Component,
  DoCheck,
  HostListener,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UserProfileService } from "../../user-profile.service";
import { TranslateService } from "@ngx-translate/core";
import { Title } from '@angular/platform-browser';

@Component({
  selector: "nh-uploaded-documents",
  templateUrl: "./uploaded-documents.component.html",
  styleUrls: ["./uploaded-documents.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class UploadedDocumentsComponent implements OnInit, DoCheck {
  screenHeight: number;
  screenWidth: number;
  isMobileView = false;
  mobileWidth = 480;
  rows = [];
  imeRows = [];
  wpAppNum;
  columns = [
    { name: "Name", prop: "name" },
    { name: "Type of Proof", prop: "proofType" },
    { name: "Document Type", prop: "docType" },
    { name: "Upload Date", prop: "uploadDt" },
  ];
  imeColumns = [
    { name: "Name", prop: "name" },
    { name: "Incurred Medical Expense Type", prop: "expenseType" },
    { name: "Document Type", prop: "docType" },
    { name: "Upload Date", prop: "uploadDt" },
  ];
  translatedLang;
  defaultLanguage;
  individualId;
  user = sessionStorage.getItem('userType');
  viewTCC = JSON.parse(sessionStorage.getItem('ViewTCC'));
  ppViewTcc = (this.user === 'RP' && this.viewTCC);
  constructor(private userProfileService: UserProfileService, private route: ActivatedRoute, public translateService: TranslateService, private titleService: Title
    ) {
    this.getScreenSize();
    this.getReturnValueWP();
    this.titleService.setTitle('My Documents | Uploaded Documents');
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  getReturnValueWP() {
    this.route.params.subscribe((params) => {
      if (params["wpAppNum"]) {
        this.wpAppNum = params["wpAppNum"];
        this.userProfileService.getUploadedDocsByTNum(params["wpAppNum"]).subscribe((res: any) => {
          const rowData = { ...res };
          this.rows = rowData?.uploadedDocuments?.map((val) => {
            const arr = val.uploadDt.split("-");
            return this.getTableValues(val, arr);
          });
        });
      }
    });
  }

  getReturnValueNoWP() {
    if (!this.wpAppNum) {
      const userType = sessionStorage.getItem("userType");
      if (userType === "RP") {
        this.individualId = sessionStorage.getItem("individualId");
        this.individualId = this.individualId === "null" ? "" : this.individualId;
      }
      this.userProfileService.getUploadedDocuments(this.individualId).subscribe((res: any) => {
        const rowData = { ...res };
        this.rows = rowData?.uploadedDocuments?.map((val) => {
          const arr = val.uploadDt.split("-");
          return this.getTableValues(val, arr);
        });
        this.imeRows = rowData?.uploadedExpenseProofs?.map((val) => {
          return this.getIMETableValues(val);
        });
      });
    }
  }

  getIMETableValues(val) {
    let retValue;
      retValue = {
        name: val?.name,
        expenseType: val?.expenseType?.value,
        docType: val?.documentType?.value,
        uploadDt: val?.uploadDt,
        docId: val?.fnDocumentId ? val?.fnDocumentId : val?.uploadDocId,
      };
    return retValue;
  }

  getTableValues(val, arr) {
    let retValue;
    if (this.translatedLang === "en") {
      retValue = {
        name: val?.name,
        proofType: val?.typeOfProof?.value,
        docType: val?.documentType?.value,
        uploadDt: `${arr[1]}/${arr[2]}/${arr[0]}`,
        docId: val?.fnDocumentId ? val?.fnDocumentId : val?.uploadDocId,
      };
    } else {
      retValue = {
        name: val?.name,
        proofType: val?.typeOfProof?.spValue,
        docType: val?.documentType?.spValue,
        uploadDt: `${arr[1]}/${arr[2]}/${arr[0]}`,
        docId: val?.fnDocumentId ? val?.fnDocumentId : val?.uploadDocId,
      };
    }
    return retValue;
  }

  ngDoCheck() {
    this.isMobileView = this.screenWidth <= this.mobileWidth;
  }

  ngOnInit() {
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.getReturnValueWP();
      this.getReturnValueNoWP();
    });
    this.getReturnValueNoWP();
  }

  showPdf(row) {
    console.log('---row---', row);
    const docId = row.docId?.replace(/[{}]/g, "");
    this.userProfileService.getPrintDoc(docId).subscribe((res: any) => {
      const file = new Blob([res], { type: "application/pdf" });
      let newVariable: any;
      newVariable = window.navigator;
      if (newVariable && newVariable.msSaveOrOpenBlob) {
        newVariable.msSaveOrOpenBlob(file);
      } else {
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    });
  }
}
