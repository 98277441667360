import { Directive, forwardRef, OnInit, HostBinding, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { MaskDirective, MaskService, initialConfig } from 'ngx-mask';

import { merge } from 'ramda';

import { toFloat } from '@app/helpers/number.helpers';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'input[type=decimal]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DecimalDirective),
      multi: true,
    },
    MaskService,
  ],
})
export class DecimalDirective extends MaskDirective implements OnInit {
  @HostBinding('type') public type = 'text';
  @HostBinding('attr.placeholder') placeholder = '0.00';

  @Input() signed = false;
  @Input() percentage = false;

  ngOnInit() {
    this.dropSpecialCharacters = false;
    this.patterns = merge(
      initialConfig.patterns, //
      { M: { pattern: /[-|\d]/, optional: true } }
    );
    this.maskExpression = `${this.signed ? 'M' : '0'}999999.00`;
  }

  writeValue(inputValue: string): Promise<void> {
    if (!this.percentage)
    return super.writeValue(toFloat(inputValue));
    else 
    return super.writeValue(inputValue);
  }
}
