import { Pipe, PipeTransform } from '@angular/core';
import { gender } from '@app/helpers/person.helpers';
import { Individual } from '@app/models/individual.model';
import { individualName } from '@app/helpers/people.helpers';
import { age } from '@app/helpers/person.helpers';

@Pipe({
  name: 'nameagegender'
})
export class NameagegenderPipe implements PipeTransform {

  transform(individual: Individual, alternative = 'this person'): string {
    return individualName(alternative, individual)+', '+age(individual)+' '+ gender(individual);
  }

}
