<div class="container mb-2">
	<div class="row">
		<div class="col-12">
			<h1 class="page-title">{{'QFR' | translate }}</h1>
		</div>
	</div>
	<div class="row">
		<div class="card pb-2" style="width:100%">
			<div class="card-body">
				<div>
					<a class="btn pl-0 btn-link" [routerLink]="['/user-profile', 'benefit-dashboard']">
						<i class="fa fa-arrow-left"></i>
						<span class="pl-2">{{'BACK_TO_DASHBOARD' | translate }}</span>
					</a>
				</div>
				<div *ngIf="response" class="d-none d-lg-block">
					<div class="row">
						<nh-alert type="warning" [isOpen]="pretermDuplicateSubmission">
							<div class="row" >
							  <div class="icon">
								<img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
							  </div>
							  <div class="message">
								<span>{{'QFR_WARNING_MESSAGE_1' | translate}} {{submittedNames}}{{'QFR_WARNING_MESSAGE_2'| translate}}</span>
							  </div>
							</div>
						  </nh-alert>
						  <nh-alert type="warning" [isOpen]="pretermRecentFaxSubmission">
							<div class="row" >
							  <div class="icon">
								<img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
							  </div>
							  <div class="message">
								<span>{{'QFR_WARNING_2' | translate}}</span>
							  </div>
							</div>
						  </nh-alert>
						<table class="table">
							<thead>
								<tr>
									<th class="col-md-6">{{'Case' | translate }}</th>
									<th>{{'Due Date' | translate }}</th>
									<th>{{'Action' | translate }}</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let row of this.rows">
									<td *ngIf="row.leastPretermDueDate">{{ row.caseNum }} <br><br> {{ row.hohName }} {{'QFR_STATIC' | translate }} <strong>{{row.nonHoh}}.</strong></td>
									<td *ngIf="row.leastPretermDueDate">{{ row.leastPretermDueDate | date: 'MM/dd/yyyy' }}</td>
									<td *ngIf="row.leastPretermDueDate">
										<button class="btn btn-block btn-rbsecondary" (click)="pretermWelcomePage(row)">{{'QFR_COMPLETE' | translate }}</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div *ngIf="response">
					<div class="nh-table-mobile d-lg-none">
						<ng-container *ngIf="rows.length">
							<div *ngFor="let row of rows">
								<div *ngIf="row.leastPretermDueDate" class="table-row mb-3 pb-3">
									<div class="mobile-table-header">{{'Case' | translate}}</div>
									<hr>
									<div class="mobile-table-data">{{row.caseNum}} <br><br> {{ row.hohName }} {{'QFR_STATIC' | translate }} <strong>{{row.nonHoh}}.</strong></div>
									<br>
									<div class="mobile-table-header">{{'Due Date' | translate }}</div>
									<hr>
									<div class="mobile-table-data">{{row.leastPretermDueDate | date: 'MM/dd/yyyy'}}</div>
									<br>
									<div class="mobile-table-header">{{'Action' | translate }}</div>
								</div>
								<div class="mobile-table-data">
									<button class="w-100 btn btn-block btn-rbsecondary" (click)="pretermWelcomePage(row)">{{'QFR_COMPLETE' | translate }}</button>
								</div>
								<br>
								<hr>
							</div>
						</ng-container>
						<p *ngIf="!rows.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
