<p *ngIf="isRmbRmc">{{ 'OTHER_COVERAGE_STATICTEXT14' | translate }}</p>

<nh-alert type="warning" [isOpen]="showAlert()">
  <div class="row">
    <div class="icon">
      <img src="../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
    </div>
    <div class="message">
      <span>{{'SSN_ENTER_MANDATORY' | translate}}
      </span>
    </div>
  </div>
</nh-alert>

<div class="d-flex align-items-center">
  <h6 class="person-label">{{'HOH' | translate }}: </h6>
  <span class="pl-2 pb-1">{{ head | fullname }} </span>
</div>
<nh-form [topLevelName] = "'heco'" [topLevelName] = "'heco'" [individuals]="individuals" [formGroup]="getForm()" [mode]="mode"
  topLevelQuestion="{{'TOP_LEVEL_QUESTION' | translate}}"
  (validate)="onValidate($event)" class="label-section">

  <section>
    <fieldset [formGroup]="getControl('data')">
      <h5 class="section-header">{{'HEALTH_INSURANCE' | translate}}</h5><hr>
      <section [formGroup]="getControl('data.policyHolder')">
        <nh-field id = "heco_houseMember" label="{{'POLICY_HOLDER' | translate}}" class="label-section">
          <select nh name="individual" aria-label="individual" [items]="indi" bindLabel="name.fullName" bindValue="identifier"
            [formControl]="getControl('data.policyHolder.houseMember')" (change)="update()" [attr.disabled]="exported?true:null"></select>
        </nh-field>

        <div *ngIf="isEnabled('data.policyHolder.policyHolderFirstName')">
          <nh-field id = "heco_policyHolderFirstName" label="{{'POLICY_HOLDER_FIRSTNAME' | translate }}" class="label-section">
            <input type="text" name="policyHolderFirstName" maxlength="46" class="form-control"
              formControlName="policyHolderFirstName" aria-label="policyHolderFirstName">
          </nh-field>
          <nh-field id = "heco_policyHolderLastName" *ngIf="isEnabled('data.policyHolder.policyHolderLastName')" label="{{'POLICY_HOLDER_LASTNAME' | translate }}" class="label-section">
            <input type="text" name="policyHolderLastName" maxlength="46" class="form-control"
              formControlName="policyHolderLastName" aria-label="policyHolderLastName">
          </nh-field>
          <nh-field id = "heco_dobDate" *ngIf="isEnabled('data.policyHolder.dobDate')" [mandatory]="false"  label="{{'DOB' | translate }}" class="label-section">
            <nh-datepicker>
              <input type="text" class="form-control" name="dobDate" formControlName="dobDate" [minDate]="minimumDate"
                bsDatepicker [maxDate]="today" nh aria-label="minimumDobDate" (change)="isInvalidDate($event)">
            </nh-datepicker>
          </nh-field>
          <nh-field id = "heco_ssnNum" *ngIf="isEnabled('data.policyHolder.ssnNum')" [mandatory]="false" label="{{'SSN' | translate }}" class="label-section">
            <nh-popover field-label class="ml-2 pl-1" content="{{'SSN_STATIC_TEXT' | translate}}"></nh-popover>
            <input type="text" name="ssnNum" formControlName="ssnNum" class="form-control" placeholder="XXX-XX-XXXX"
              mask="000-00-0000" aria-label="ssnNum">
          </nh-field>
        </div>
      </section>

      <nh-field id = "heco_coverageType" label="{{'HEALTH_INSURANCE_STATIC_TEXT1' | translate}} {{otherSelectedIndividual && otherSelectedIndividual!== ''? otherSelectedIndividual : policyHolderFirstName.value !== null && policyHolderLastName.value !== null ? policyHolderFirstName.value + '   ' + policyHolderLastName.value : ''}} {{'HEALTH_INSURANCE_STATIC_TEXT2' | translate}}" class="label-section">
       <select nh name="coverageType" aria-label="coverageType" [items]="tableCodeValue('THIRDPARTYPOLICYTYP', 'data.coverageType')" formControlName="coverageType"
          (change)="coverageTypeChange()"></select>
      </nh-field>

      <nh-field id = "heco_insuranceCompanyName" [mandatory]="false" *ngIf="isEnabled('data.insuranceCompanyName')" label="{{'HEALTH_INSURANCE_STATIC_TEXT3' | translate}}" class="label-section">
        <input type="text" name="insuranceCompanyName" maxlength="40" class="form-control"
          formControlName="insuranceCompanyName" aria-label="insuranceCompanyName">
      </nh-field>

      <nh-field id = "heco_longTermCarePremiumAmount" [mandatory]="true" *ngIf="isEnabled('data.longTermCarePremiumAmount')" label="{{'HEALTH_INSURANCE_STATIC_TEXT4' | translate}}" class="label-section">
        <br>
        <span class="unbold"> {{'HEALTH_INSURANCE_STATIC_TEXT16' | translate}} </span>
        <nh-popover field-label class="ml-2 pl-1" content="{{'HEALTH_INSURANCE_STATIC_TEXT5' | translate}}"></nh-popover>
        <nh-input-group prepend="$">
          <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="longTermCarePremiumAmount" formControlName="longTermCarePremiumAmount" aria-label="longTermCarePremiumAmount" class="form-control">
        </nh-input-group>
      </nh-field>

      <nh-field id = "heco_policyNumber" label="{{'POLICY_NUMBER' | translate}}" [mandatory]="policyNumber" class="label-section">
        <input type="text" name="policyNumber" class="form-control" formControlName="policyNumber" maxlength="16" aria-label="policyNumber">
      </nh-field>

      <nh-field id = "heco_groupNumber" label="{{'GROUP_NUMBER' | translate}}" [mandatory]="groupNumber" class="label-section">
          <input type="text" name="groupNumber" class="form-control" formControlName="groupNumber" maxlength="16" aria-label="groupNumber">
      </nh-field>

      <nh-field id = "heco_monthlyPremiumAmountForKB" *ngIf="youngerIndividual && isEnabled('data.monthlyPremiumAmountForKB')" [mandatory]="groupNumber" label="{{'PREMIUM_ACCOUNT' | translate}}">
        <nh-popover field-label class="ml-2 pl-1" content="{{'PREMIUM_ACCOUNT_STATIC_TEXT' | translate}}"></nh-popover>
        <nh-input-group prepend="$">
          <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="monthlyPremiumAmountForKB" formControlName="monthlyPremiumAmountForKB" aria-label="monthlyPremiumAmountForKB" class="form-control">
        </nh-input-group>
      </nh-field>

      <nh-field id = "heco_policyStartDate" label="{{'HEALTH_INSURANCE_STATIC_TEXT6' | translate}}" [mandatory]="groupNumber" class="label-section">
        <nh-datepicker>
          <input type="text" name="policyStartDate" class="form-control" formControlName="policyStartDate" (change)="isInvalidDate($event)"
          [minDate]="minimumDate" bsDatepicker  nh aria-label="policyStartDate">
        </nh-datepicker>
      </nh-field>

      <nh-field id = "heco_purchaseInsFromYourEmplyerSw" *ngIf="youngerIndividual && isEnabled('data.purchaseInsFromYourEmplyerSw')" [labelSize]="false" label="{{'HEALTH_INSURANCE_STATIC_TEXT7' | translate}}" class="label-section">
        <nh-popover field-label class="ml-2 pl-1" content="{{'HEALTH_INSURANCE_STATIC_TEXT8' | translate}}"></nh-popover>
        <select nh name="purchaseInsFromYourEmplyerSw" [items]="tableCodeValue('YESNO', 'data.purchaseInsFromYourEmplyerSw')"
          formControlName="purchaseInsFromYourEmplyerSw" aria-label="purchaseInsFromYourEmplyerSw"></select>
      </nh-field>

      <nh-field id = "heco_stateEmployeeBenfitPlanSwitch" *ngIf="isEnabled('data.stateEmployeeBenfitPlanSwitch')" [mandatory]="false"  label="{{'HEALTH_INSURANCE_STATIC_TEXT9' | translate}}" class="label-section">
        <nh-popover field-label class="ml-2 pl-1" content="{{'HEALTH_INSURANCE_STATIC_TEXT10' | translate}}"></nh-popover>
        <select nh name="stateEmployeeBenfitPlanSwitch" [items]="tableCodeValue('YESNO', 'data.stateEmployeeBenfitPlanSwitch')"
          formControlName="stateEmployeeBenfitPlanSwitch" aria-label="stateEmployeeBenfitPlanSwitch"></select>
      </nh-field>

      <nh-field id = "heco_limitedBenefitPlanSwitch" *ngIf="isEnabled('data.limitedBenefitPlanSwitch')" [mandatory]="limitedPlan" [labelSize]="false"
        label="{{'HEALTH_INSURANCE_STATIC_TEXT11' | translate}}" class="label-section">
        <nh-popover field-label class="ml-2 pl-1" content="{{'HEALTH_INSURANCE_STATIC_TEXT12' | translate}}"></nh-popover>
        <select nh name="limitedBenefitPlanSwitch" [items]="tableCodeValue('YESNO', 'data.limitedBenefitPlanSwitch')" class="form-control"
          formControlName="limitedBenefitPlanSwitch" aria-label="limitedBenefitPlanSwitch"></select>
      </nh-field>

      <nh-field id = "heco_coverMaternityBenefitsSwitch" label="{{'HEALTH_INSURANCE_STATIC_TEXT13' | translate}}" [mandatory]="groupNumber" class="label-section">
        <nh-popover field-label class="ml-2 pl-1" content="{{'HEALTH_INSURANCE_STATIC_TEXT14' | translate}}"></nh-popover>
        <select name="coverMaternityBenefitsSwitch" aria-label="coverMaternityBenefitsSwitch" class="form-control" [items]="tableCodeValue('YESNO', 'data.coverMaternityBenefitsSwitch')"
          formControlName="coverMaternityBenefitsSwitch" nh aria-label="coverMaternityBenefitsSwitch"></select>
      </nh-field>

      <div>
        <label for="Health Coverage" class="label-section"><span class="text-danger">*</span>{{'HEALTH_INSURANCE_STATIC_TEXT15' | translate}}</label>
          <div *ngFor="let choice of list; let i=index" class="mt-2 pt-2">
          <div class="form-check pb-md-1 checkbox">
          <label class="form-check-label mt-2">
            <input id = "{{'coveredIndividualNumber' + i}}"  type="checkbox" class="form-check-input" [value]="choice.coveredIndividualNumber" aria-label="coveredIndividualNumber" (click)="onCheckChange($event, i)" [checked]="choice.checked">
            <span class="cr"><i class="cr-icon fa fa-check" aria-hidden="true"></i></span>
            {{choice.name}}, {{choice.age + choice.gender}}
          </label>
        </div>
        </div>
      </div>
    </fieldset>
  </section>

</nh-form>
