export default {
    assetType: 'RMB_VEHICLE_STATIC_TEXT1',
    vehicleYear: 'YEAR',
    vehicleMake: 'VEHICLE_STATIC_TEXT5',
    vehicleModel: 'VEHICLE_STATIC_TEXT6',
    vehicleUsage: 'RMB_VEHICLE_STATIC_TEXT2',
    assetAmount: "RMB_VEHICLE_STATIC_TEXT3",
    owedAmount: "RMB_VEHICLE_STATIC_TEXT4",
    jointOwnership: 'RMB_BURRIAL_STATIC4',
    jointOwnershipInformation: 'RMB_C_CO_OWNERS',
    removed: {
        vehicleEndDate: 'RMB_VEHICLE_STATIC_TEXT6'
    }
}