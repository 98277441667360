import { compose } from 'ramda';
import { propEq } from 'ramda';
import { find } from 'ramda';
import { useWith } from 'ramda';
import { findIndex } from 'ramda';
import { inc } from 'ramda';
import { dec } from 'ramda';
import { prop } from 'ramda';
import { flatten } from 'ramda';
import { map } from 'ramda';
import { pick } from 'ramda';
import { identity } from 'ramda';
import { keys } from 'ramda';
import { path } from 'ramda';
import { without } from 'ramda';
import { omit } from 'ramda';
import { ifElse } from 'ramda';
import { always } from 'ramda';
import { converge } from 'ramda';
import { lt } from 'ramda';

import { sectionInProgress, indexInProgress, isSubmitted } from '@app/state/app/app.selectors';
import {
  viewIdentifier,
  viewSections,
  viewTitle,
  viewModuleId,
  viewSectionId,
  viewStatus,
} from '@app/helpers/lenses.helpers';
import { applyProps } from '@app/helpers/function.helpers';

const eqSectionId = compose(
  propEq('sectionId'),
  viewIdentifier
);

export const getSections = compose(
  flatten,
  map(viewSections)
);

export const sectionConfig = (app, sectionId) => omit(['tables'], path(['sections', sectionId], app));

export const tableCodes = (app, sectionId) => {
  const tables = keys(path(['section', 'tables'], app));
  const codes = path(['sections', sectionId, 'tables'], app);
  return without(tables, codes);
};

const findSection = useWith(find, [eqSectionId, getSections]);
const findSectionIndex = useWith(findIndex, [eqSectionId, getSections]);

export const title = compose(
  viewTitle,
  findSection
);

const nextSectionIndex = compose(
  inc,
  findSectionIndex
);

const previousSectionIndex = compose(
  dec,
  findSectionIndex
);

const findSectionIdByIndex = finder => (section, modules) => {
  const sections = getSections(modules);
  const idx = finder(section, modules);
  const sectionInfo = prop(idx, sections);
  return viewSectionId(sectionInfo);
};

export const previousSection = findSectionIdByIndex(previousSectionIndex);
const nextSection = findSectionIdByIndex(nextSectionIndex);

const getModules = prop('modules');
const getSectionModules = applyProps(['section', 'modules']);

const isInProgress = converge(lt, [
  compose(
    indexInProgress,
    getModules
  ),
  getSectionModules(nextSectionIndex),
]);

const nextOrInProgress = ifElse(
  isInProgress,
  compose(
    sectionInProgress,
    getModules
  ),
  getSectionModules(nextSection)
);

export const nextSectionId = ifElse(isSubmitted, always('SUCFM'), nextOrInProgress);

const findModuleSections = useWith(find, [eqSectionId, viewSections]);

const findSectionModule = useWith(find, [findModuleSections, identity]);

export const sectionLinks = compose(
  map(pick(['sectionId', 'title', 'status'])),
  viewSections,
  findSectionModule
);

export const moduleId = compose(
  viewModuleId,
  findSectionModule
);

export const sectionStatus = compose(
  viewStatus,
  findSection
);
