import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';

import { equals, isEmpty } from 'ramda';
import { isYes, isNo } from '@app/helpers/tayn.helpers';
import { disableReset } from '@app/helpers/reset-able.helpers';
import { ApplicationService } from '@app/services/application/application.service';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { swapControl } from '@app/helpers/forms.helpers';
import { formatTableData } from '@app/helpers/tables.helpers';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import Vs from '@app/services/validators/validators.service';

@Component({
  templateUrl: './tax-filing.component.html',
  styleUrls: ['./tax-filing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TaxFilingComponent extends SectionFormDirective implements AfterContentInit {
  constructor(private titleService: Title, private application: ApplicationService, builder: FormBuilder, public translateService: TranslateService) {
    super(builder);
    this.titleService.setTitle('Tax Filing');
  }
  finalList = [];
  relationshipList;
  refFullname: any;
  refIndvId: any;
  showDependents = false;
  selectedItems: any;
  fullName;
  records: any;
  hideTopLevel: boolean;
  savedList = [];
  indivs;
  checkboxList;
  translatedLang;
  defaultLanguage;
  isRmbRmc;
  appType = sessionStorage.getItem('appType');
  selectedIndivName;
  
  fullname(name) {
    return name.fullName
  }

  get taxDependents() {
    return this.getControl('data.taxDependents') as FormArray
  }
  get taxFilingIndividualId() {
    return this.getControl('data.taxFilingIndividualId');
  }

  changeTaxFiler () {
    this.selectedIndivName = this.individuals.filter(ind => ind.identifier === this.taxFilingIndividualId.value)[0].name.fullName;
  }

  name(id, jointFilerCtrl) {
    if (jointFilerCtrl) {
      jointFilerCtrl.reset();
    };
    return this.fullName = this.members.filter(i => i.identifier === id)[0]?.name?.fullName;
  }

  spouseSelection = (relationshipList, selectedIndv) => {
    if (relationshipList) {
      const data = this.getControl('data');
      const jointFiler = data.get('jointFiler');
      const spouseIndv = this.relationshipList.find(i => i.relation.code === 'SPO');
      if (spouseIndv && this.formGroup.value.data?.filing?.code === 'Y') {
        const { sourceIndividual, referenceIndividual } = spouseIndv;

        const spIndv1 = this.members.filter(i => i.identifier == sourceIndividual);
        const spIndv2 = this.members.filter(i => i.identifier == referenceIndividual);

        const selectedIdentifier = selectedIndv;
        if (equals(selectedIdentifier, spIndv1[0].identifier)) {
          this.refFullname = spIndv2[0].name.fullName;
          this.refIndvId = spIndv2[0].identifier;
          jointFiler.enable();
        } else if (equals(selectedIdentifier, spIndv2[0].identifier)) {
          this.refFullname = spIndv1[0].name.fullName;
          this.refIndvId = spIndv1[0].identifier;
          jointFiler.enable();
        } else {
          disableReset(jointFiler);
        }
      } else {
        disableReset(jointFiler);
      }
    };
  };

  filterSelectedIndiv = (selectedIndivCtrl, relationshipList, removeJf = false) => {
    const selectedPerson = this.individuals.filter(indv => {
      const individual = selectedIndivCtrl?.value ? selectedIndivCtrl?.value : selectedIndivCtrl?.identifier;
      return indv.identifier == individual;
    });

    if (this.records?.length > 0) {
      let taxDeps = this.records.filter(rec => rec.data?.taxFilingStatus !== 'In Household Dependent');
      if (removeJf && taxDeps.length > 0) taxDeps = taxDeps.filter(rec => rec.data?.taxFilingStatus !== 'Joint Filer')
      this.indivs = taxDeps.length > 0 ? taxDeps.map(tax => tax.identifier?.individualId) : [];
    }

    const selectedIndv = selectedPerson[0]?.identifier;
    if (selectedPerson[0]?.identifier) {
      this.finalList = [];
      const filteredIndivs = this.members.filter(indv => indv.identifier !== selectedPerson[0].identifier && !this.indivs.includes(indv.identifier) && indv['moveOutReason']?.code !== 'MO');
      filteredIndivs?.forEach(indv => {
        const indivDetails = `${indv.name.fullName}, ${indv.age}  ${indv.gender.code}`;
        const indivObj = { individualId: indv.identifier, prop: indivDetails };
        this.finalList = this.finalList.concat(indivObj);
        this.checkboxList = this.isChecked(this.finalList);
      });
      this.spouseSelection(relationshipList, selectedIndv);
    } else {
      this.spouseSelection(relationshipList, selectedIndv);
    }
  };

  isChecked(list) {
    const checkedList = list.map(val => {
      val.checked = this.taxDependents.controls.some(ctrl => ctrl.value?.individualId == val.individualId);
      return val;
    })
    return checkedList;

  }

  resetChecklist() {
    const checkedList = this.checkboxList?.map(val => {
      val.checked = false;
      return val;
    })
    return checkedList;
  }

  showTaxDeps(val, taxDependent) {
    const filing = this.getControl('data.filing');
    const jointFiler = this.getControl('data.jointFiler');

    if (val.code === 'Y') {
      if( this.getControl('data.taxFilingIndividualId').value === null){
        this.getControl('data.taxFilingIndividualId').enable();
      }
      if (this.individuals?.length && this.individuals?.length > 1) {
        taxDependent.enable();
        this.showDependents = true;
        if(jointFiler?.value?.code === 'N' || jointFiler?.value?.code === 'Y'){
          jointFiler.enable();
        }
      } else {
        taxDependent.disable();
        this.showDependents = false;
        if(jointFiler?.value?.code === 'N' || jointFiler?.value?.code === 'Y'){
          jointFiler.enable();
        }
      }
    } else {
      taxDependent.disable();
      filing.enable();
      jointFiler.disable();
    }
  };

  removeJointfiler(val, taxDependent, taxDependentsCtrl) {
    const selectedIndivCtrl = this.getControl('data.taxFilingIndividualId');
    if (isYes(val) && this.individuals.length > 2) {
      const listWithoutJf = this.finalList.filter(indv => indv.individualId !== this.refIndvId);
      this.finalList = listWithoutJf;
      this.checkboxList = this.isChecked(this.finalList);
    } else if (isYes(val) && this.members.length === 2) {
      disableReset(taxDependent);
      taxDependentsCtrl.disable();
      this.showDependents = false;
    } else if ((isNo(val) && this.members.length >= 2)) {
        if(this.individuals.length > 1){
          taxDependent.enable();
          this.showDependents = true;
          this.filterSelectedIndiv(selectedIndivCtrl, this.relationshipList, true);
        }
    }
  };

  onCheckChange(e) {
    if (e.target.checked) {
      this.taxDependents.push(new FormControl({ individualId: e.target.value }))
    } else {
      this.taxDependents.removeAt(this.taxDependents.value.findIndex(tax => tax.individualId.toString() === e.target.value));
    }
  }
  resetTaxDependents() {
    this.taxDependents.removeAt(this.taxDependents.value.findIndex(tax => tax === null));
    this.resetChecklist();
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }
  
  tableCodeValue (tableName, tableControl) {
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }
  
  ngAfterContentInit() {
    this.selectedIndivName =  this.selectedIndividual ? this.selectedIndividual.name.fullName : '';
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);

    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
    this.languageCheck(res.lang);
    });
    const data = this.getControl('data');
    const taxDependent = data.get('taxDependent');
    const taxDependentsCtrl = data.get('taxDependents');
    const selectedIndivCtrl = data.get('taxFilingIndividualId');
    if(selectedIndivCtrl?.value && selectedIndivCtrl.value !== '1234567890'){
      this.getControl('data.taxFilingIndividualId').disable();   
     }
    const topLevel = data.get('filing');
    const jointFiler = data.get('jointFiler');
    this.isRmbRmc = this.appType === 'RB' || this.appType === 'RC';
    this.showTaxDeps(topLevel.value,taxDependent);


    this.registerToggle(taxDependent, swapControl(taxDependentsCtrl, isYes));
    this.registerToggle(selectedIndivCtrl, () => this.individuals.length > 1 ? this.filterSelectedIndiv(selectedIndivCtrl, this.relationshipList) : taxDependent.disable());
    this.registerToggle(topLevel, val => this.showTaxDeps(val, taxDependent));
    this.registerToggle(selectedIndivCtrl, val => this.name(val, jointFiler));
    this.registerToggle(jointFiler, val => this.removeJointfiler(val, taxDependent, taxDependentsCtrl));

    if (this.members.length > 1) {
      this.getRelationships();
    };

    if (isEmpty(this.formGroup.value.data.filing) && this.formGroup.value.data.taxFilingIndividualId !== '1234567890') {
      this.hideTopLevel = false;
      topLevel.disable();
      topLevel.clearValidators();
      topLevel.setValue({ code: 'Y', value: 'Yes' });
    } else if (this.selectedIndividual?.headOfHousehold === false) {
      this.hideTopLevel = false;
    } else if (this.record.data.filing === null) {
      this.hideTopLevel = true;
    } else {
      this.hideTopLevel = true;
    };
    if(this.formGroup.value.data.taxFilingIndividualId === '1234567890' && this.record.data.taxDependents !== null) {
      if(this.taxDependents.controls.length){
        for(let i = 0; i< this.taxDependents.controls.length; i++){
        this.taxDependents.controls[i].patchValue(null);
        this.taxDependents.controls[i].updateValueAndValidity();
      }
    }
    }
    this.name(selectedIndivCtrl.value, null);
  };

  onChangeTaxFiling(){
    if(this.formGroup.value.data.filing.code === 'Y' && (this.formGroup.value.data.taxFilingIndividualId === '1234567890' || this.formGroup.value.data.taxFilingIndividualId === null)){
      this.taxFilingIndividualId.patchValue(null);
        this.taxFilingIndividualId.updateValueAndValidity();
        if( this.getControl('data.taxFilingIndividualId').value === null){
          this.getControl('data.taxFilingIndividualId').enable();
          this.getControl('data.taxFilingIndividualId').setValidators(Vs.required);
          this.getControl('data.taxFilingIndividualId').updateValueAndValidity();
        }
      } else  if(this.formGroup.value.data.filing.code === 'N'){
        this.taxFilingIndividualId.patchValue(null);
          this.getControl('data.taxFilingIndividualId').clearValidators(); 
          this.getControl('data.taxFilingIndividualId').updateValueAndValidity();
          this.taxFilingIndividualId.updateValueAndValidity();
          const taxDependentsCtrl = this.getControl('data.taxDependents');
          const taxDependent =this.getControl('data.taxDependent');
          taxDependentsCtrl.disable();
          disableReset(taxDependent);
        }
  }

  getRelationships() {
    return this.application.getSection(this.applicationNumber, 'HHRLS').subscribe(rtn => {
      if (rtn.records.length > 0) {
        const { records } = rtn;
        const relationshipList = records[0].data.relationshipList;
        this.relationshipList = relationshipList;
        this.filterSelectedIndiv(this.selectedIndividual, relationshipList);
      }
    })
  }
}