import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceConfigService } from '@app/services/service-config.service';
import { ProcessService } from './../../services/process.service';
import { HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})

export class LogoutComponent {
  constructor(
    private router: Router,
    private serverConfig: ServiceConfigService,
    public processService: ProcessService,
    private titleService: Title
  ) {
    this.processService.setOnLogout('false');
    this.titleService.setTitle('Signout');
  }
  backOrRefresh = sessionStorage.getItem('backOrRefresh') ? true : false;
  url = this.router.url;
  mobileUser = this.url.includes('mobile-logout') ? true : false; 

  ppUrl;
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    sessionStorage.setItem('backOrRefresh', 'true')
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander() {
    sessionStorage.setItem('backOrRefresh', 'true')
  }

  onNavigate(type) {
    this.processService.setOnLogout('true');
    if (type === 'MP') {
      this.router.navigateByUrl('/');
    } else {
      this.ppUrl = this.serverConfig.getServerConfig().partnerPortalUrl;
      window.location.href = this.ppUrl;
    }
  }
}