import { Directive, Input, HostBinding, AfterContentInit } from '@angular/core';

import { BsDatepickerDirective, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[bsDatepicker][nh]',
})
export class DatePickerDirective implements AfterContentInit {
  @Input() format = 'MM/DD/YYYY';
  @HostBinding('attr.placeholder') placeholder = this.format;
  defaultLanguage;

  constructor(private datepicker: BsDatepickerDirective, private config: BsDatepickerConfig, public translateService: TranslateService) {
    this.config.containerClass = 'theme-dark-blue';
    this.config.showWeekNumbers = false;
    this.config.dateInputFormat = this.format;
    this.datepicker.triggers = 'pointerlockchange:click';
    this.datepicker.outsideClick = true;
    this.datepicker.bsConfig = config;
  }

  ngAfterContentInit() { 
    this.defaultLanguage = this.translateService.getDefaultLang();
    if (this.defaultLanguage === "en") {
      this.placeholder = 'MM/DD/YYYY';
    } else {
      this.placeholder = 'MM/DD/AAAA';
    }
    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.defaultLanguage = res.lang;
      if (res.lang === "en") {
        this.placeholder = 'MM/DD/YYYY';
      } else {
        this.placeholder = 'MM/DD/AAAA';
      }
    });
  }
}
