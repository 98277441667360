import { any } from 'ramda';
import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { isApplyForBenefits, isChangeReport, isExported, isOtherModes, isPresumptiveApp, isPresumptiveBcc} from '@app/helpers/mode.helpers';
import { Individual } from '@app/models/individual.model';
import { ModalService } from '@app/services/modal/modal.service';
import Vs from "@app/services/validators/validators.service";
import { distinctUntilChanged } from "rxjs/operators";
import { formatDate } from '@angular/common';
import { formatTableData } from '@app/helpers/tables.helpers';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '@app/services/application/application.service';
import { Title } from '@angular/platform-browser';

export const getIndividualTable = (individuals = []) => {
  return individuals.map((individual: Individual) => ({
    code: individual.identifier,
    value: `${individual.name.fullName}, ${individual.age} ${individual.gender.code}`
  }));
}
@Component({
  templateUrl: './other-benefits.component.html',
  styleUrls: ['./other-benefits.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OtherBenefitsComponent extends SectionFormDirective implements AfterContentInit {
  today = new Date();
  isExported: any = null;
  individualTable = [];
  appType = sessionStorage.getItem('appType');
  isKepro = false;
  isRenewBenefits = false;
  codeYes = { code: 'Y', spValue : 'Si', value: 'Yes' };
  codeNo = { code: 'N', spValue : 'No', value: 'No' };
  medicalBillsIndividualTable = [];
  medicareIndSwToolTip = 'Medicare is the federal health insurance for people age 65 or older and certain people with disabilities.  If anyone in your household has Medicare, TennCare may help pay for the Medicare premiums (monthly payment) and/or co-pays and deductibles (your part of the doctor bills).  Some Medicare cost sharing programs are QMB (Qualified Medicare Beneficiary), SLMB (Specified Low Income Medicare Beneficiary), and QI1.  Each program pays some of your Medicare costs and has different limits for income and resources.';
  brstCervCancerSwToolTip = 'You must apply at the health department first if you need treatment for breast or cervical cancer.';
  medicalBillSwToolTip = 'You can use medical bills for anyone in your household.  These bills can be for care you or people in your home received this month (your application month) or for the last 3 months.  You can also use medical bills that you\'ve paid this month no matter how old the bills are.';
  hcbsSwToolTip = 'Home and Community Based Services are for individuals who would qualify for the level of care provided in a nursing home but want services in their home or the community instead.';
  ecfToolTip = `Employment and Community First CHOICES is for people of all ages who have an intellectual or developmental disability (I/DD). This includes people who have significant disabilities. Services help people with I/DD gain as much independence as possible. It can even help you explore the possibility of working by helping you learn skills for work, finding and keeping a job. People are supported to live with their family or in the community, not in an institution. Residential services are available for adults with I/DD who do not live with family but need supports where they live. To see if you can be served through this program, you must complete a self-referral form. We’ll tell you how to do that later on in this application.`
  isAFB;
  isPPFRM;
  isPresumptive;
  isPresumptiveBcc;
  isExpensesDisabled = false;
  isResoursesDisabled = false;
  isMedicalBillsDisabled = false;
  isCheckboxDisabled = false;
  minimumDate = new Date("1990-01-01T00:00:00.000-06:00");
  userType = sessionStorage.getItem('userType');
  translatedLang;
  defaultLanguage;
  isFullCoverageApp;
  kbValue;
  isFinReassessment = false;
  showKb = true;
  disableKb = false;
  changeMode;
  isMedicalBillSw = false;
  medicalBillsIndvs = [];
  scheduledModules = [];
  enableExpensesSection;
  enableResourcesSection;
  isTransferDisabled = false;
  enableTransferSection;

  constructor(
    private modalService: ModalService,
    builder: FormBuilder,
    public translateService: TranslateService,
    private application: ApplicationService,
    private titleService: Title
  ) {
    super(builder);
    this.titleService.setTitle('Other Health Care Questions');
  }

  get otherBenefitsIndvProfileData(): FormArray {
    return this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
  }

  getindividualsById(id: string): string {
    const individulal = this.individuals.find((item) => item.identifier === id);
    return individulal ? individulal.name.fullName : '';
  }


  isInvalidDate(event, i) {
    const enteredDate = event.target.value;
    if ( !enteredDate || enteredDate === '' || enteredDate === 'Invalid date' ) {
      event.target.value = enteredDate;
    } else if (event.target.name === 'medFacilityAdmitDt') {
      event.target.value = formatDate (this.otherBenefitsIndvProfileData.controls[i].value.medFacilityAdmitDt , 'MM/dd/yyyy' , 'en');
    }
  }

  getCtrl(param) {
    let targetCtrl;
    this.otherBenefitsIndvProfileData.controls.forEach((profileFormGroup: FormGroup) => {
      targetCtrl = this.getControl(param, profileFormGroup)?.valid;
    })
    return targetCtrl;
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  tableCodeValue (tableName, tableControl) {
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }

  getExpensesOrResoursesSwitch(scheduledArray) {
    const expensesArray = scheduledArray.find(item => item.moduleId === 'XPPPG');
    const resoursesArray = scheduledArray.find(item => item.moduleId === 'ASPPG');
    const transferArray = scheduledArray.find(item => item.moduleId === 'TRPPG');
    const expensesSection = expensesArray?.sections[0];
    const resoursesSection = resoursesArray?.sections[0];
    const transferSection = transferArray?.sections[0];

    if (expensesSection?.changeRequested === true) {
      this.enableExpensesSection = true;
    }
    if (resoursesSection?.changeRequested === true) {
      this.enableResourcesSection = true;
    }
    if (transferSection?.changeRequested === true) {
      this.enableTransferSection = true;
    }

    if (this.changeMode) {
      if (this.enableExpensesSection) {
        const expensesInd = this.getControl('data.expensesInd');
        expensesInd.enable();
        expensesInd.patchValue({ code: "Y", value: "Yes" }) 
      }
      if (this.enableResourcesSection) {
        const assetInd = this.getControl('data.assetInd');
        assetInd.enable();
        assetInd.patchValue({ code: "Y", value: "Yes" })
      }
      if (this.enableTransferSection) {
        const transferredResInd = this.getControl('data.transferredResInd');
        transferredResInd.enable();
        transferredResInd.patchValue({ code: "Y", value: "Yes" })
      }
    }
  }

  ngAfterContentInit() {
    this.application.getSection(this.applicationNumber, 'SURCH').subscribe(rtn => {
      if (rtn) {
        this.scheduledModules = rtn.records[0].data.modules;
        this.getExpensesOrResoursesSwitch(this.scheduledModules)
      }
    })
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);
    this.disableKb = JSON.parse(sessionStorage.getItem('disableKb'));

    this.translateService.onDefaultLangChange.subscribe((res) => {
    this.translatedLang = res.lang;
    this.languageCheck(res.lang);
    });
    this.isAFB = isApplyForBenefits(this.applicationType);
    this.isPresumptive = isPresumptiveApp(this.applicationType);
    this.isPresumptiveBcc = isPresumptiveBcc(this.applicationType);
    this.isRenewBenefits = (this.appType === 'RB'|| this.appType === "REDETERMINATION");
    this.isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));
    this.isFullCoverageApp = (this.isAFB && this.userType === 'RP') ? true : false;
    this.changeMode = isChangeReport(this.mode);
    this.isKepro = sessionStorage.getItem('isKepro') === 'true';
    this.isPPFRM = (this.isFinReassessment && this.userType === 'RP');

    if (this.isFinReassessment || this.isFullCoverageApp) {
      const kbFlag = sessionStorage.getItem('isKbEligible');
      if (kbFlag === 'true') {
        this.showKb = true;
      } else {
        this.showKb = false;
      }
    };

    this.individualTable = getIndividualTable(this.individuals);
    const helpers = this.getControl('extras.helpers');

    const medicalBillsIndOpt = helpers.get('medicalBillsIndOpt');
    const brstCervCancerSwOpt = helpers.get('brstCervCancerSwOpt');
    const medFacilitySwOpt = helpers.get('medFacilitySwOpt');
    const nursingHomeSwOpt = helpers.get('nursingHomeSwOpt');
    const hospiceSwOpt = helpers.get('hospiceSwOpt');
    const hcbsSwOpt = helpers.get('hcbsSwOpt');
    const intelDisbSwOpt = helpers.get('intelDisbSwOpt');
    const medicareIndSwOpt = helpers.get('medicareIndSwOpt');
    const expensesIndOpt = helpers.get('expensesIndOpt');
    const assetIndSwOpt = helpers.get('assetIndSwOpt');
    const expenseDisableSw = this.getControl('data.expenseDisableSw')
    const assetDisableSw = this.getControl('data.assetDisableSw')
    const medBillsDisableSw = this.getControl('data.medBillsDisableSw')
    const ecfIndOpt = helpers.get('ecfIndSwOpt')
    const medBillSwOpt = this.getControl('data.medicalBillSw')
    const kbReferralSw = this.getControl('data.kbReferralSw')
    const ssiBenefitsSwOpt = helpers.get('ssiBenefitsSwOpt');
    const expensesInd = this.getControl('data.expensesInd')
    const assetInd = this.getControl('data.assetInd');
    const transferredResInd = this.getControl('data.transferredResInd');
    const transferAssetDisableSw = this.getControl('data.transferAssetDisableSw');
    const transferIndOpt = helpers.get('transferIndOpt');

    if (this.disableKb) {
      kbReferralSw.setValue({ code: 'Y', value: 'Yes', spValue: 'Si' });
    }

    // if (!this.isAFB || (this.userType === 'RP' && !this.isAFB)) {
    //   this.getControl('data.transferredResInd')?.setValue(null);
    //   this.getControl('data.transferredResInd')?.clearValidators();
    //   this.getControl('data.transferredResInd')?.updateValueAndValidity();
    // }

    
    medicalBillsIndOpt.valueChanges.subscribe((changes) => this.updateProfileData(changes, 'medicalBillsIndSw'));
    brstCervCancerSwOpt.valueChanges.subscribe((changes) => this.updateProfileData(changes, 'brstCervCancerSw'));
    medFacilitySwOpt.valueChanges.subscribe((changes) => this.updateProfileData(changes, 'medFacilitySw'));
    nursingHomeSwOpt.valueChanges.subscribe((changes) => this.updateProfileData(changes, 'nursingHomeSw'));
    hospiceSwOpt.valueChanges.subscribe((changes) => this.updateProfileData(changes, 'hospiceSw'));
    hcbsSwOpt.valueChanges.subscribe((changes) => this.updateProfileData(changes, 'hcbsSw'));
    intelDisbSwOpt.valueChanges.subscribe((changes) => this.updateProfileData(changes, 'intelDisbSw'));
    medicareIndSwOpt.valueChanges.subscribe((changes) => this.updateProfileData(changes, 'medicareIndSw'));
    expensesIndOpt.valueChanges.subscribe((changes) => this.updateProfileData(changes, 'expensesIndSw'));
    assetIndSwOpt.valueChanges.subscribe((changes) => this.updateProfileData(changes, 'assetIndSw'));
    ecfIndOpt.valueChanges.subscribe((changes) => this.updateProfileData(changes, 'ecfSw'));
    medBillSwOpt.valueChanges.subscribe((changes) => this.updateProfileData(changes, 'medicalBillSw'));
    ssiBenefitsSwOpt.valueChanges.subscribe((changes) => this.updateProfileData(changes, 'ssiBenefitsSw'));
    transferIndOpt.valueChanges.subscribe((changes) => this.updateProfileData(changes, 'transferredResIndSw'));

    this.isProfileOpted('medicareIndSw')
    this.isProfileOpted('brstCervCancerSw')
    this.isProfileOpted('medFacilitySw')
    this.isProfileOpted('nursingHomeSw')
    this.isProfileOpted('hospiceSw')
    this.isProfileOpted('hcbsSw')
    this.isProfileOpted('intelDisbSw')
    this.isProfileOpted('medicalBillsIndSw')
    this.isProfileOpted('expensesIndSw')
    this.isProfileOpted('assetIndSw')
    this.isProfileOpted('ecfSw')
    this.isProfileOpted('ssiBenefitsSw')
    this.isProfileOpted('transferredResIndSw')

    medFacilitySwOpt.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        const otherBenefitsIndvProfileCtrls = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
        otherBenefitsIndvProfileCtrls.controls.forEach((profileFormGroup: FormGroup) => {
          const indivId = profileFormGroup.value.indvId
          const show = [];
          Object.values(res).map(value => {
            if (value !== null) {
              show.push(value);
            }
            if (value) {
              Object.values(value).map(indiv => {
                if (indiv === indivId) {
                  this.getControl('hospitalName', profileFormGroup).setValidators([Vs.required, Vs.unit, Vs.maxLength(20)])
                  this.getControl('hospitalName', profileFormGroup).updateValueAndValidity();
                  this.getControl('medFacilityAdmitDt', profileFormGroup).setValidators([Vs.required])
                  this.getControl('medFacilityAdmitDt', profileFormGroup).updateValueAndValidity();
                } else {
                  this.getControl('medFacilitySw', profileFormGroup).clearValidators();
                  this.getControl('medFacilitySw', profileFormGroup).updateValueAndValidity();
                }
              })
            } else if (res && value === null && show.length === 0) {
              this.getControl('medFacilitySw', profileFormGroup).setValidators([Vs.requiredTrue])
              this.getControl('medFacilitySw', profileFormGroup).updateValueAndValidity();
            }
          })
        });
      })

      nursingHomeSwOpt.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        const otherBenefitsIndvProfileCtrls = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
        otherBenefitsIndvProfileCtrls.controls.forEach((profileFormGroup: FormGroup) => {
          const indivId = profileFormGroup.value.indvId
          const show = [];
          Object.values(res).map(value => {
            if (value !== null) {
              show.push(value);
            }
            if (value) {
              Object.values(value).map(indiv => {
                if (indiv === indivId) {
                  this.getControl('nursingHomeName', profileFormGroup).setValidators([Vs.required, Vs.invalidNursingName])
                  this.getControl('nursingHomeName', profileFormGroup).updateValueAndValidity();
                } else {
                  this.getControl('nursingHomeSw', profileFormGroup).clearValidators();
                  this.getControl('nursingHomeSw', profileFormGroup).updateValueAndValidity();
                }
              })
            } else if (res && value === null && show.length === 0) {
              this.getControl('nursingHomeSw', profileFormGroup).setValidators([Vs.requiredTrue])
              this.getControl('nursingHomeSw', profileFormGroup).updateValueAndValidity();
            }
          })
        });
      })

      hospiceSwOpt.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        const otherBenefitsIndvProfileCtrls = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
        otherBenefitsIndvProfileCtrls.controls.forEach((profileFormGroup: FormGroup) => {
          const show = [];
          Object.values(res).map(value => {
            if (value !== null) {
              show.push(value);
            }
            if (value) {
                  this.getControl('hospiceSw', profileFormGroup).clearValidators();
                  this.getControl('hospiceSw', profileFormGroup).updateValueAndValidity();
            } else if (res && value === null && show.length === 0) {
              this.getControl('hospiceSw', profileFormGroup).setValidators([Vs.requiredTrue])
              this.getControl('hospiceSw', profileFormGroup).updateValueAndValidity();
            }
          })
        });
      })

      medicalBillsIndOpt.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        const otherBenefitsIndvProfileCtrls = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
        otherBenefitsIndvProfileCtrls.controls.forEach((profileFormGroup: FormGroup) => {
          const show = [];
          Object.values(res).map(value => {
            if (value !== null) {
              show.push(value);
            }
            if (value) {
              this.getControl('medicalBillsIndSw', profileFormGroup).clearValidators();
              this.getControl('medicalBillsIndSw', profileFormGroup).updateValueAndValidity();
            } else if (res && value === null && show.length === 0) {
              this.getControl('medicalBillsIndSw', profileFormGroup).setValidators([Vs.requiredTrue])
              this.getControl('medicalBillsIndSw', profileFormGroup).updateValueAndValidity();
            }
          })
        });
      })

      hcbsSwOpt.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        const otherBenefitsIndvProfileCtrls = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
        otherBenefitsIndvProfileCtrls.controls.forEach((profileFormGroup: FormGroup) => {
          const show = [];
          Object.values(res).map(value => {
            if (value !== null) {
              show.push(value);
            }
            if (value) {
                  this.getControl('hcbsSw', profileFormGroup).clearValidators();
                  this.getControl('hcbsSw', profileFormGroup).updateValueAndValidity();
            } else if (res && value === null && show.length === 0) {
              this.getControl('hcbsSw', profileFormGroup).setValidators([Vs.requiredTrue])
              this.getControl('hcbsSw', profileFormGroup).updateValueAndValidity();
            }
          })
        });
      })

      ecfIndOpt.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        const otherBenefitsIndvProfileCtrls = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
        otherBenefitsIndvProfileCtrls.controls.forEach((profileFormGroup: FormGroup) => {
          const show = [];
          Object.values(res).map(value => {
            if (value !== null) {
              show.push(value);
            }
            if (value) {
                  this.getControl('ecfSw', profileFormGroup).clearValidators();
                  this.getControl('ecfSw', profileFormGroup).updateValueAndValidity();
            } else if (res && value === null && show.length === 0) {
              this.getControl('ecfSw', profileFormGroup).setValidators([Vs.requiredTrue])
              this.getControl('ecfSw', profileFormGroup).updateValueAndValidity();
            }
          })
        });
      })

      intelDisbSwOpt.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        const otherBenefitsIndvProfileCtrls = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
        otherBenefitsIndvProfileCtrls.controls.forEach((profileFormGroup: FormGroup) => {
          const show = [];
          Object.values(res).map(value => {
            if (value !== null) {
              show.push(value);
            }
            if (value) {
                  this.getControl('intelDisbSw', profileFormGroup).clearValidators();
                  this.getControl('intelDisbSw', profileFormGroup).updateValueAndValidity();
            } else if (res && value === null && show.length === 0) {
              this.getControl('intelDisbSw', profileFormGroup).setValidators([Vs.requiredTrue])
              this.getControl('intelDisbSw', profileFormGroup).updateValueAndValidity();
            }
          })
        });
      })

      medicareIndSwOpt.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        const otherBenefitsIndvProfileCtrls = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
        otherBenefitsIndvProfileCtrls.controls.forEach((profileFormGroup: FormGroup) => {
          const show = [];
          Object.values(res).map(value => {
            if (value !== null) {
              show.push(value);
            }
            if (value) {
              this.getControl('medicareIndSw', profileFormGroup).clearValidators();
              this.getControl('medicareIndSw', profileFormGroup).updateValueAndValidity();
            } else if (res && value === null && show.length === 0) {
              this.getControl('medicareIndSw', profileFormGroup).setValidators([Vs.requiredTrue])
              this.getControl('medicareIndSw', profileFormGroup).updateValueAndValidity();
            }
          })
        });
      })


      ssiBenefitsSwOpt.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        const otherBenefitsIndvProfileCtrls = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
        otherBenefitsIndvProfileCtrls.controls.forEach((profileFormGroup: FormGroup) => {
          const show = [];
          Object.values(res).map(value => {
            if (value !== null) {
              show.push(value);
            }
            if (value) {
              this.getControl('ssiBenefitsSw', profileFormGroup).clearValidators();
              this.getControl('ssiBenefitsSw', profileFormGroup).updateValueAndValidity();
            } else if (res && value === null && show.length === 0) {
              this.getControl('ssiBenefitsSw', profileFormGroup).setValidators([Vs.requiredTrue])
              this.getControl('ssiBenefitsSw', profileFormGroup).updateValueAndValidity();
            }
          })
        });
      })

      brstCervCancerSwOpt.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        const otherBenefitsIndvProfileCtrls = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
        otherBenefitsIndvProfileCtrls.controls.forEach((profileFormGroup: FormGroup) => {
          const show = [];
          Object.values(res).map(value => {
            if (value !== null) {
              show.push(value);
            }
            if (value) {
                  this.getControl('brstCervCancerSw', profileFormGroup).clearValidators();
                  this.getControl('brstCervCancerSw', profileFormGroup).updateValueAndValidity();
            } else if (res && value === null && show.length === 0) {
              this.getControl('brstCervCancerSw', profileFormGroup).setValidators([Vs.requiredTrue])
              this.getControl('brstCervCancerSw', profileFormGroup).updateValueAndValidity();
            }
          })
        });
      })

      medBillSwOpt.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        const otherBenefitsIndvProfileCtrls = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
        otherBenefitsIndvProfileCtrls.controls.forEach((profileFormGroup: FormGroup) => {
          const show = [];
          if (res) {
            Object.values(res).map(value => {
              if (value !== null) {
                show.push(value);
              }
              if (value) {
                this.getControl('medicalBillSw', profileFormGroup)?.clearValidators();
                this.getControl('medicalBillSw', profileFormGroup)?.updateValueAndValidity();
              } else if (res && value === null && show.length === 0) {
                this.getControl('medicalBillSw', profileFormGroup)?.setValidators([Vs.requiredTrue])
                this.getControl('medicalBillSw', profileFormGroup)?.updateValueAndValidity();
              }
            })
          }
        });
      })

      expensesIndOpt.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        const otherBenefitsIndvProfileCtrls = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
        otherBenefitsIndvProfileCtrls.controls.forEach((profileFormGroup: FormGroup) => {
          const show = [];
          if (res) {
            Object.values(res).map(value => {
              if (value !== null) {
                show.push(value);
              }
              if (value) {
                this.getControl('expensesIndSw', profileFormGroup).clearValidators();
                this.getControl('expensesIndSw', profileFormGroup).updateValueAndValidity();
              } else if (res && value === null && show.length === 0) {
                this.getControl('expensesIndSw', profileFormGroup).setValidators([Vs.requiredTrue])
                this.getControl('expensesIndSw', profileFormGroup).updateValueAndValidity();
              }
            })
          }
        });
      })

      assetIndSwOpt.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        const otherBenefitsIndvProfileCtrls = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
        otherBenefitsIndvProfileCtrls.controls.forEach((profileFormGroup: FormGroup) => {
          const show = [];
          Object.values(res).map(value => {
            if (value !== null) {
              show.push(value);
            }
            if (value) {
                  this.getControl('assetIndSw', profileFormGroup).clearValidators();
                  this.getControl('assetIndSw', profileFormGroup).updateValueAndValidity();
            } else if (res && value === null && show.length === 0) {
              this.getControl('assetIndSw', profileFormGroup).setValidators([Vs.requiredTrue])
              this.getControl('assetIndSw', profileFormGroup).updateValueAndValidity();
            }
          })
        });
      })

      transferIndOpt.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((res) => {
        const otherBenefitsIndvProfileCtrls = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
        otherBenefitsIndvProfileCtrls.controls.forEach((profileFormGroup: FormGroup) => {
          const show = [];
          Object.values(res).map(value => {
            if (value !== null) {
              show.push(value);
            }
            if (value) {
              this.getControl('transferredResIndSw', profileFormGroup).clearValidators();
              this.getControl('transferredResIndSw', profileFormGroup).updateValueAndValidity();
            } else if (res && value === null && show.length === 0) {
              this.getControl('transferredResIndSw', profileFormGroup).setValidators([Vs.requiredTrue])
              this.getControl('transferredResIndSw', profileFormGroup).updateValueAndValidity();
            }
          })
        });
      })

    const otherBenefitsIndvProfileData = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
    otherBenefitsIndvProfileData.controls.forEach((profileFormGroup: FormGroup) => {
      const medFacilityAdmitDt = this.getControl('medFacilityAdmitDt', profileFormGroup);
      medFacilityAdmitDt.setValidators([Vs.maxFutureDate]);
      medFacilityAdmitDt.updateValueAndValidity();
    });

    if (this.isPresumptive) {
    this.today = null;
    }
    if (expenseDisableSw?.value?.code === 'Y' && this.isRenewBenefits) {
      this.isExpensesDisabled = true;
      this.getControl('extras.helpers.expensesIndSw').patchValue({code: "Y", value: "Yes",spValue: "Si"});
    }

    if (assetDisableSw?.value?.code === 'Y' && this.isRenewBenefits) {
      this.isResoursesDisabled = true;
      this.getControl('extras.helpers.assetIndSw').patchValue({code: "Y", value: "Yes",spValue: "Si"});
    }

    if (transferAssetDisableSw?.value?.code === 'Y' && this.isRenewBenefits) {
      this.isTransferDisabled = true;
      this.getControl('extras.helpers.transferredResIndSw').patchValue({code: "Y", value: "Yes",spValue: "Si"});
    }

    if (medBillsDisableSw?.value?.code === 'Y' && (this.isRenewBenefits || this.changeMode)) {
      this.isMedicalBillsDisabled = true;
    }

    if (medBillsDisableSw?.value?.code === 'Y' && this.isRenewBenefits) {
      this.isCheckboxDisabled = true;
    }



    if (
      isOtherModes(this.mode) &&
      this.record &&
      isExported(this.record.exportingStatus) &&
      this.record.data &&
      this.record.data.benefitAppliedFor
    ) {
      this.isExported = true;
    }

    if (this.changeMode) {
        const newlyAddedIndivs = this.individuals.filter(indv => indv.someoneMovedIn === 'Y');
        if (newlyAddedIndivs.length === 0) {
          medBillSwOpt.disable();
          expensesInd.disable();
          assetInd.disable();
          assetDisableSw.disable();
          expenseDisableSw.disable();
          medBillsDisableSw.disable();
          transferAssetDisableSw.disable();
          transferredResInd.disable();
        }
        this.isMedicalBillSw = newlyAddedIndivs.length && medBillsDisableSw.value?.code === 'Y' || newlyAddedIndivs.some(indiv => indiv.age < 21 || indiv.pregnant);
        if (this.isMedicalBillSw) {
          this.medicalBillsIndvs = getIndividualTable(this.individuals.filter(indv => indv.someoneMovedIn === 'Y' && (indv.age < 21 || indv.pregnant)));
        }
    } else {
      this.isMedicalBillSw = medBillsDisableSw.value?.code === 'Y' || this.individuals.some(indiv => indiv.age < 21 || indiv.pregnant);
      if(this.isRenewBenefits && medBillsDisableSw.value?.code === 'Y'){ 
        this.medicalBillsIndividualTable = getIndividualTable(this.individuals);
      } else if(this.isMedicalBillSw){
        this.medicalBillsIndividualTable = getIndividualTable(this.individuals.filter(item => (item.age < 21 || item.pregnant) ));
      }
    }

    var anyOptionSelectedOnLoad = this.anyDrivingQuestionAnsweredForTransfer();
    if (!anyOptionSelectedOnLoad && !this.isRenewBenefits) {
      this.getControl('data.transferredResInd')?.clearValidators();
      this.getControl('data.transferredResInd')?.updateValueAndValidity();
      this.getControl('extras.helpers.transferredResIndSw')?.clearValidators();
      this.getControl('extras.helpers.transferredResIndSw')?.updateValueAndValidity();
    }

    if (!this.isPresumptive) {
      this.getControl('data.transferredResIndSw')?.clearValidators();
      this.getControl('data.transferredResIndSw')?.updateValueAndValidity();
      this.getControl('extras.helpers.transferredResIndSw')?.clearValidators();
      this.getControl('extras.helpers.transferredResIndSw')?.updateValueAndValidity();
    }

    if (this.isPresumptive) {
      this.getControl('data.transferredResInd')?.clearValidators();
      this.getControl('data.transferredResInd')?.updateValueAndValidity();
    }

    var anyOptionSelectedRmbOnLoad = this.showTransferResources();
    if (!anyOptionSelectedRmbOnLoad && this.isRenewBenefits) {
      this.getControl('data.transferredResInd')?.clearValidators();
      this.getControl('data.transferredResInd')?.updateValueAndValidity();
      this.getControl('data.transferredResIndSw')?.clearValidators();
      this.getControl('data.transferredResIndSw')?.updateValueAndValidity();
      this.getControl('extras.helpers.transferredResIndSw')?.clearValidators();
      this.getControl('extras.helpers.transferredResIndSw')?.updateValueAndValidity();
    }

    if (anyOptionSelectedRmbOnLoad && this.isRenewBenefits) {
      this.getControl('data.transferredResInd')?.setValidators(Vs.required);
      this.getControl('data.transferredResInd')?.updateValueAndValidity();
    }
  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  updateProfileData(changes, fieldName) {
    const otherBenefitsIndvProfileData = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
    if (changes !== null) {
      const result = Object.values(changes).every(o => o === null);
      if (result === true) setTimeout(() => otherBenefitsIndvProfileData.setErrors({required: true}, {emitEvent: false}))
    };

    otherBenefitsIndvProfileData.controls.forEach((profileFormGroup: FormGroup) => {
      const individual = this.getValue('indvId', profileFormGroup);
      if (changes !== null && changes[individual]) {
        this.getControl(fieldName, profileFormGroup)?.setValue({ code: 'Y', value: 'Yes' });
      } else {
        this.getControl(fieldName, profileFormGroup)?.setValue({ code: 'N', value: 'No' });
      }
      this.getControl(fieldName, profileFormGroup)?.updateValueAndValidity();
      this.getControl(fieldName, profileFormGroup)?.clearValidators();

    });
  }

  showMoreKatieBeckettProgramModal() {
    this.modalService.openKatieBeckettProgram();
  }

  showExpenseResources() {
    if (this.isRenewBenefits) {
      return any((item) => {
        return item.code === 'Y' && !this.changeMode;
      })([
        this.getValue('extras.helpers.medicareIndSw'),
        this.getValue('extras.helpers.ssiBenefitsSw'),
        this.getValue('extras.helpers.medFacilitySw'),
        this.getValue('extras.helpers.nursingHomeSw'),
        this.getValue('extras.helpers.hospiceSw'),
        this.getValue('extras.helpers.hcbsSw'),
        this.getValue('extras.helpers.intelDisbSw'),
        this.getValue('extras.helpers.ecfSw'),
        this.getValue('data.medicalBillSw'),
        this.getValue('data.kbReferralSw'),
        this.getValue('extras.helpers.expensesIndSw'),
        this.getValue('extras.helpers.assetIndSw')
      ]);
    }
    else {
      return any((item) => {
        return item.code === 'Y' && !this.changeMode;
      })([
        this.getValue('extras.helpers.medicareIndSw'),
        this.getValue('extras.helpers.ssiBenefitsSw'),
        this.getValue('extras.helpers.medFacilitySw'),
        this.getValue('extras.helpers.nursingHomeSw'),
        this.getValue('extras.helpers.hospiceSw'),
        this.getValue('extras.helpers.hcbsSw'),
        this.getValue('extras.helpers.intelDisbSw'),
        this.getValue('extras.helpers.ecfSw'),
        this.getValue('data.medicalBillSw'),
        this.getValue('data.kbReferralSw')
      ]);
    }
  }

  showTransferResources() {
    if (this.isRenewBenefits) {
      return any((item) => {
        return item.code === 'Y' && !this.changeMode;
      })([
        this.getValue('extras.helpers.medFacilitySw'),
        this.getValue('extras.helpers.nursingHomeSw'),
        this.getValue('extras.helpers.hcbsSw'),
        this.getValue('extras.helpers.ecfSw'),
        this.getValue('extras.helpers.transferredResIndSw')
      ]);
    }
    else {
      return any((item) => {
        return item.code === 'Y' && !this.changeMode;
      })([
        this.getValue('extras.helpers.medFacilitySw'),
        this.getValue('extras.helpers.nursingHomeSw'),
        this.getValue('extras.helpers.hcbsSw'),
        this.getValue('extras.helpers.ecfSw')
      ]);
    }
  }

  anyDrivingQuestionAnswered() {
    if (this.isRenewBenefits) {
      return any((item) => {
        return item.code === 'Y' && !this.changeMode;
      })([
        this.getValue('extras.helpers.medicareIndSw'),
        this.getValue('extras.helpers.ssiBenefitsSw'),
        this.getValue('extras.helpers.medFacilitySw'),
        this.getValue('extras.helpers.nursingHomeSw'),
        this.getValue('extras.helpers.hospiceSw'),
        this.getValue('extras.helpers.hcbsSw'),
        this.getValue('extras.helpers.intelDisbSw'),
        this.getValue('extras.helpers.medicalBillsIndSw'),
        this.getValue('extras.helpers.ecfSw'),
        this.getValue('data.medicalBillSw'),
        this.getValue('data.kbReferralSw'),
        this.getValue('extras.helpers.expensesIndSw'),
        this.getValue('extras.helpers.assetIndSw'),
      ]);
    }
    else {
      return any((item) => {
        return item.code === 'Y' && !this.changeMode;
      })([
        this.getValue('extras.helpers.medicareIndSw'),
        this.getValue('extras.helpers.ssiBenefitsSw'),
        this.getValue('extras.helpers.medFacilitySw'),
        this.getValue('extras.helpers.nursingHomeSw'),
        this.getValue('extras.helpers.hospiceSw'),
        this.getValue('extras.helpers.hcbsSw'),
        this.getValue('extras.helpers.intelDisbSw'),
        this.getValue('extras.helpers.medicalBillsIndSw'),
        this.getValue('extras.helpers.ecfSw'),
        this.getValue('data.medicalBillSw'),
        this.getValue('data.kbReferralSw')
      ])
    }
  }

  anyDrivingQuestionAnsweredForTransfer() {
    if (this.isRenewBenefits) {
      return any((item) => {
        return item.code === 'Y' && !this.changeMode;
      })([
        this.getValue('extras.helpers.medFacilitySw'),
        this.getValue('extras.helpers.nursingHomeSw'),
        this.getValue('extras.helpers.hcbsSw'),
        this.getValue('extras.helpers.ecfSw'),
        this.getValue('extras.helpers.transferredResIndSw')
      ]);
    }
    else {
      return any((item) => {
        return item.code === 'Y' && !this.changeMode;
      })([
        this.getValue('extras.helpers.medFacilitySw'),
        this.getValue('extras.helpers.nursingHomeSw'),
        this.getValue('extras.helpers.hcbsSw'),
        this.getValue('extras.helpers.ecfSw')
      ])
    }
  }

  isProfileOpted(fieldName: string) {
    const helpers = this.getControl('extras.helpers');
    const optField = helpers.get(fieldName);
    let isAllNull = true;
    if (this.record && this.record.data && this.record.data.otherBenefitsIndvProfileData) {
      isAllNull = this.record.data.otherBenefitsIndvProfileData.every((item) => !item[fieldName]);
      if (isAllNull) {
        optField.setValue(null);
      } else {
        this.record && this.record.data && this.record.data.otherBenefitsIndvProfileData.find((item) => {
          return item[fieldName] && item[fieldName].code === 'Y';
        }) ? optField.setValue(this.codeYes) : optField.setValue(this.codeNo);
      }
    }
  }

  onProfileDataChange(fieldName: string, name: string) {

    const selectionValue = (fieldName === 'medicalBillSw') ? this.getValue('data.medicalBillSw') : this.getValue(`extras.helpers.${fieldName}`);
    const otherBenefitsIndvProfileData = this.getControl('data.otherBenefitsIndvProfileData') as FormArray;
    otherBenefitsIndvProfileData.controls.forEach((profileFormGroup: FormGroup) => {
      if (selectionValue && selectionValue.code === 'N') {
        this.getControl(fieldName, profileFormGroup)?.setValue({ code: 'N', value: 'No' });
        if (fieldName === 'medFacilitySw') {
          this.getControl('hospitalName', profileFormGroup).clearValidators();
          this.getControl('hospitalName', profileFormGroup).updateValueAndValidity();
          this.getControl('medFacilityAdmitDt', profileFormGroup).clearValidators();
          this.getControl('medFacilityAdmitDt', profileFormGroup).updateValueAndValidity();
          this.getControl('medFacilityAdmitDt', profileFormGroup).setValue(null);
          this.getControl('hospitalName', profileFormGroup).setValue(null);
        }
        if (fieldName === 'nursingHomeSw') {
          this.getControl('nursingHomeName', profileFormGroup).clearValidators();
          this.getControl('nursingHomeName', profileFormGroup).updateValueAndValidity();
          this.getControl('nursingHomeName', profileFormGroup).setValue(null);
        }

        if(fieldName === 'medicalBillSw'){
          this.getControl('medicalBillsIndSw', profileFormGroup).clearValidators();
          this.getControl('medicalBillsIndSw', profileFormGroup).updateValueAndValidity();
          this.getControl('medicalBillsIndSw', profileFormGroup).setValue(null);
        }else{
          this.getControl(fieldName, profileFormGroup).clearValidators();
          this.getControl(fieldName, profileFormGroup).updateValueAndValidity();
        }
        
      } else if (selectionValue && selectionValue.code === 'Y') {
       
          var indivData = (this.getControl(`extras.helpers.${name}`) as FormGroup).controls;
          this.individualTable.forEach(element => {
            if(indivData[element.code]){
              (this.getControl(`extras.helpers.${name}`) as FormGroup).controls[element.code].setValue(null);
              (this.getControl(`extras.helpers.${name}`) as FormGroup).controls[element.code].clearValidators();
              (this.getControl(`extras.helpers.${name}`) as FormGroup).controls[element.code].updateValueAndValidity();
            }
          });

          if(fieldName === 'medicalBillSw'){
            this.getControl('medicalBillsIndSw', profileFormGroup).setValidators([Vs.requiredTrue]);
            this.getControl('medicalBillsIndSw', profileFormGroup).updateValueAndValidity();
          }else{
            this.getControl(fieldName, profileFormGroup).setValidators([Vs.requiredTrue]);
            this.getControl(fieldName, profileFormGroup).updateValueAndValidity();
          }
                 
             // ((this.getControl('extras.helpers.intelDisbSwOpt') as FormGroup).controls[this.individualTable[0].code] as FormControl).setValue(null);
      } else if (selectionValue == '') {
        if (fieldName === 'medicalBillSw'){
          this.getControl('medicalBillsIndSw', profileFormGroup)?.clearValidators();
          this.getControl('medicalBillsIndSw', profileFormGroup)?.updateValueAndValidity();
          this.getControl('data.medBillsDisableSw').setValue(null);
          this.getControl('medicalBillsIndSw', profileFormGroup)?.setValue(null);
          this.getControl(fieldName, profileFormGroup)?.clearValidators();
          this.getControl(fieldName, profileFormGroup)?.updateValueAndValidity();
          this.getControl(fieldName, profileFormGroup)?.setValue(null);
      }
        else if (fieldName === 'kbReferralSw'){
          this.getControl('data.kbReferralSw').setValue(null);
        }
        if(fieldName === 'medicalBillSw'){
          this.getControl('medicalBillsIndSw', profileFormGroup).clearValidators();
          this.getControl('medicalBillsIndSw', profileFormGroup).updateValueAndValidity();
          this.getControl('medicalBillsIndSw', profileFormGroup)?.setValue(null);
        }else{
          this.getControl(fieldName, profileFormGroup).clearValidators();
          this.getControl(fieldName, profileFormGroup).updateValueAndValidity();
          this.getControl(fieldName, profileFormGroup)?.setValue(null);
        }
      }
      var anyOptionSelected = this.anyDrivingQuestionAnswered();
      if (!anyOptionSelected && !this.changeMode && !this.isRenewBenefits) {
        this.getControl('data.assetInd')?.setValue(null);
        this.getControl('data.expensesInd')?.setValue(null);
        this.getControl('data.expenseDisableSw')?.setValue(null);
        this.getControl('data.assetDisableSw')?.setValue(null);
        this.getControl('data.assetIndSw')?.patchValue(null);
        this.getControl('data.expensesIndSw')?.patchValue(null);
        this.getControl('data.assetIndSw')?.setValue(null);
        this.getControl('data.expensesIndSw')?.setValue(null);
        this.getControl('expensesIndSw', profileFormGroup)?.setValue(null);
        this.getControl('assetIndSw', profileFormGroup)?.setValue(null);
        this.getControl('expensesIndSw', profileFormGroup)?.patchValue(null);
        this.getControl('assetIndSw', profileFormGroup)?.patchValue(null);
        this.getControl('data.otherBenefitsIndvProfileData.expensesIndSw')?.setValue(null);
        this.getControl('data.otherBenefitsIndvProfileData.assetIndSw')?.setValue(null);
        this.getControl('extras.helpers.expensesIndSw').setValue(null);
        this.getControl('extras.helpers.assetIndSw').setValue(null);
        this.getControl('assetIndSw', profileFormGroup).clearValidators();
        this.getControl('assetIndSw', profileFormGroup).updateValueAndValidity();
        this.getControl('expensesIndSw', profileFormGroup).clearValidators();
        this.getControl('expensesIndSw', profileFormGroup).updateValueAndValidity();
        this.getControl('data.transferredResInd')?.setValue(null);
        this.getControl('data.transferredResInd')?.clearValidators();
        this.getControl('data.transferredResInd')?.updateValueAndValidity();
        this.getControl('data.transferAssetDisableSw')?.setValue(null);
        this.getControl('data.transferredResIndSw')?.patchValue(null);
        this.getControl('data.transferredResIndSw')?.setValue(null);
        this.getControl('transferredResIndSw', profileFormGroup)?.setValue(null);
        this.getControl('transferredResIndSw', profileFormGroup)?.patchValue(null);
        this.getControl('data.otherBenefitsIndvProfileData.transferredResIndSw')?.setValue(null);
        this.getControl('extras.helpers.transferredResIndSw').setValue(null);
        this.getControl('extras.helpers.transferredResIndSw').clearValidators();
        this.getControl('extras.helpers.transferredResIndSw').updateValueAndValidity();
        this.getControl('transferredResIndSw', profileFormGroup).clearValidators();
        this.getControl('transferredResIndSw', profileFormGroup).updateValueAndValidity();
      }

      var anyOptionSelectedForTransfer = this.anyDrivingQuestionAnsweredForTransfer();

      if (!anyOptionSelectedForTransfer && !this.changeMode && !this.isRenewBenefits) {
        this.getControl('data.transferredResInd')?.setValue(null);
        this.getControl('data.transferredResInd')?.clearValidators();
        this.getControl('data.transferredResInd')?.updateValueAndValidity();
        this.getControl('data.transferAssetDisableSw')?.setValue(null);
        this.getControl('data.transferredResIndSw')?.patchValue(null);
        this.getControl('data.transferredResIndSw')?.setValue(null);
        this.getControl('transferredResIndSw', profileFormGroup)?.setValue(null);
        this.getControl('transferredResIndSw', profileFormGroup)?.patchValue(null);
        this.getControl('data.otherBenefitsIndvProfileData.transferredResIndSw')?.setValue(null);
        this.getControl('extras.helpers.transferredResIndSw').setValue(null);
        this.getControl('extras.helpers.transferredResIndSw').clearValidators();
        this.getControl('extras.helpers.transferredResIndSw').updateValueAndValidity();
        this.getControl('transferredResIndSw', profileFormGroup).clearValidators();
        this.getControl('transferredResIndSw', profileFormGroup).updateValueAndValidity();
      }

      if (!anyOptionSelectedForTransfer && this.isRenewBenefits) {
        this.getControl('data.transferredResInd')?.setValue(null);
        this.getControl('data.transferredResInd')?.clearValidators();
        this.getControl('data.transferredResInd')?.updateValueAndValidity();
      }

      if (anyOptionSelectedForTransfer && !this.changeMode && !this.isPresumptive) {
        this.getControl('data.transferredResInd')?.setValidators(Vs.required);
      }
      if (anyOptionSelectedForTransfer && this.isPresumptive) {
        this.getControl('data.transferredResInd')?.setValue(null);
        this.getControl('data.transferredResInd')?.clearValidators();
        this.getControl('data.transferredResInd')?.updateValueAndValidity();
        this.getControl('data.transferredResIndSw')?.setValidators(Vs.required);
        this.getControl('data.transferredResIndSw')?.updateValueAndValidity();
        this.getControl('extras.helpers.transferredResIndSw')?.setValidators(Vs.required);
        this.getControl('extras.helpers.transferredResIndSw')?.updateValueAndValidity();
        this.getControl('transferredResIndSw', profileFormGroup)?.setValidators(Vs.required);
        // this.getControl('transferredResIndSw', profileFormGroup)?.updateValueAndValidity();
      }
    });
  }

  isMedFacilitySw(profileGroup) {
    return this.getValue('medFacilitySw', profileGroup) && this.getValue('medFacilitySw', profileGroup).code === 'Y';
  }
    isNursingHomeSw(profileGroup) {
    return this.getValue('nursingHomeSw', profileGroup) && this.getValue('nursingHomeSw', profileGroup).code === 'Y';
  }

  isKbReferralSw() {
    let minor = false;
    this.individuals.forEach(indiv => {
      if (indiv.age < 18) {
        minor = true;
      }
    });
    return minor;
  }

  
 onTransfer(val){
  if(val.target.value  !== 'Yes' || val.target.value  !== 'Si' || val.target.value  !== 'No'){
    this.getControl('data.transferredResInd')?.setValidators(Vs.required);
    this.getControl('data.transferredResInd')?.updateValueAndValidity();
  }
 }
}