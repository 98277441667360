import { all } from 'ramda';
import { always } from 'ramda';
import { and } from 'ramda';
import { any } from 'ramda';
import { apply } from 'ramda';
import { applyTo } from 'ramda';
import { call } from 'ramda';
import { complement } from 'ramda';
import { compose } from 'ramda';
import { curry } from 'ramda';
import { defaultTo } from 'ramda';
import { equals } from 'ramda';
import { find } from 'ramda';
import { flip } from 'ramda';
import { identity } from 'ramda';
import { isNil } from 'ramda';
import { merge } from 'ramda';
import { objOf } from 'ramda';
import { pathOr } from 'ramda';
import { props } from 'ramda';
import { split } from 'ramda';
import { useWith } from 'ramda';
import { when } from 'ramda';
import { ifElse } from 'ramda';

import { argsPass } from 'ramda-adjunct';

import { ifNil } from './null.helpers';
import { withUse, amplify, runIf, queer } from './flipped.helpers';

export const withAny = useWith(any);
export const withAnd = useWith(and);
export const callWith = useWith(call);
export const withMerge = useWith(merge);
export const withApply = useWith(applyTo);
export const withFind = useWith(find);
export const runWith = useWith(runIf);

export const orO = defaultTo({});
export const orS = defaultTo('');
export const orA = defaultTo([]);

export const withDefault = withUse([identity, orO]);

const sPath = pathOr('');
export const oPath = pathOr({});
export const aPath = pathOr([]);

export const zipPair = apply(objOf);
export const unequal = complement(equals);
export const reduceO = amplify({});
export const assign = curry(Object.assign);
export const assignAs = curry((to, key, val) => assign(to, objOf(key, val)));

const dotSplit = split('.');
export const dashSplit = split('-');

const orSplit = compose(
  dotSplit,
  orS
);

export const pathS = useWith(flip(sPath), [identity, orSplit]);
export const ifNilOr = useWith(ifNil, [always, identity]);
export const nunArgs = argsPass(all, [isNil, isNil]);
export const trudy = when(Boolean);
export const iif = ifElse(Boolean);

// :: (b -> c) -> (a -> b) -> (c -> d)
export const applyProps = useWith(queer, [props, apply]);

export const splitPath = useWith(pathOr, [identity, split('.'), identity]);

export const maskSSN = ssn => ssn.substring(0, 3) + '-' + ssn.substring(3, 5) + '-' + ssn.substring(5);
export const maskZip = zip => zip.substring(0, 5) + '-' + zip.substring(5,  9);

export const findModule = (moduleName, modules) => modules.find(m => m.moduleId === moduleName);