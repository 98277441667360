import { Pipe, PipeTransform } from '@angular/core';
import { age } from '@app/helpers/person.helpers';

import { Individual } from '@app/models/individual.model';

@Pipe({ name: 'age' })
export class AgePipe implements PipeTransform {
  transform(individual: Individual): string {
    return age(individual);
  }
}
