<section class="container">
	<h1 class="page-title">Search My Submissions</h1>
	<div class="row hover-red pb-1 mt-3">
		<a class="btn btn-link hover-red" (click)="onPrevious()">
			<i class="fa fa-arrow-left"></i>
			<span class="pl-2">Back to TennCare Access Welcome Page</span>
		</a>
	</div>
	<div class="row mt-3">
		<div class="card card-body ie-specific">
			<h5 class="section-header">Search Criteria</h5>
			<hr>
			<p>Use the Search criteria below to look for completed or in-progress applications. For best results, use
				the full name of the applicant or organization. But, a partial entry will also
				return results. </p>
			<form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
				<div class="px-1 py-4">
					<div class="form-row">
						<div class="col-md-3">
							<nh-field [isRow]="true" [mandatory]="false" label="First Name" class="label-section">
								<input type="text" aria-label="firstName" name="firstName" formControlName="firstName" class="form-control"
									maxlength="45">
							</nh-field>
						</div>

						<div class="col-lg-2 col-md-3 pl-1">
							<nh-field [isRow]="true" [mandatory]="false" label="Middle Initial" class="label-section">
								<input type="text" aria-label="middleIntial" name="middleIntial" formControlName="middleIntial"
								mask="S" class="form-control">
							</nh-field>
						</div>

						<div class="col-md-3 pl-1">
							<nh-field [isRow]="true" [mandatory]="false" label="Last Name" class="label-section">
								<input type="text" aria-label="lastName" name="lastName" formControlName="lastName" class="form-control"
									maxlength="45">
							</nh-field>
						</div>
					</div>
				</div>

				<div class="mt-3">
					<nh-field [mandatory]="false" label="Social Security Number" class="label-section">
						<input type="text" name="ssn" aria-label="ssn" formControlName="ssn" class="form-control"
							placeholder="XXX-XX-XXXX" mask="000-00-0000">
					</nh-field>
				</div>


				<div class="mt-3">
					<nh-field [mandatory]="false" label="Submission Status" class="label-section">
						<select nh name="subStatus" aria-label="submission-status" [items]="submissionStatusArr" bindLabel="enDescription" bindValue="subStatus" formControlName="subStatus"></select>
					</nh-field>
				</div>

				<div class="mt-3">
					<nh-field [mandatory]="false" label="Submission Type" class="label-section">
						<select nh name="subType" [items]="submissionTypes" aria-label="submission-type" bindLabel="enDescription" bindValue="subType" formControlName="subType"
							(change)="subTypeChange()"></select>
					</nh-field>
				</div>

				<div *ngIf="showApp" class="mt-3">
					<nh-field [mandatory]="showApp" [label]="typeApp" class="label-section">
						<input type="text" aria-label="appNum" name="appChangeRenewReass" formControlName="appChangeRenewReass" mask="S00000000"
							class="form-control">
					</nh-field>
				</div>

				<div *ngIf="sup" class="mt-3">
					<nh-field [mandatory]="false" label="Username" class="label-section">
						<input type="text" aria-label="userName" name="userName" formControlName="userName" class="form-control"
							maxlength="21">
					</nh-field>
				</div>

				<div *ngIf="sup" class="mt-3 mb-3">
					<nh-field [mandatory]="false" label="Organization Name" class="label-section">
						<input type="text" aria-label="orgName" name="orgName" formControlName="orgName" class="form-control" maxlength="21">
					</nh-field>
				</div>

				<label class="label-section">Submission Date Range</label>

				<div class="py-4 submission-search">
					<div class="form-group">
						<nh-field [mandatory]="false" label="Begin Date" class="label-section">
							<nh-datepicker>
								<input type="text" class="form-control" [minDate]="minDate" [maxDate]="maxDate"
								(change)="isInvalidDate($event)" aria-label="subBeginDate" name="subBeginDate" formControlName="subBeginDate" bsDatepicker nh>
							</nh-datepicker>
						</nh-field>

						<nh-field [mandatory]="false" label="End Date" class="label-section">
							<nh-datepicker>
								<input type="text" class="form-control" [minDate]="minDate" [maxDate]="maxDate" (change)="isInvalidDate($event)"
									aria-label="subEndDate" name="subEndDate" formControlName="subEndDate" bsDatepicker nh>
							</nh-datepicker>
						</nh-field>
						<br>
						<div class="pr-md-1 py-sm-1">
							<button class="btn btn-secondary" [disabled]="!searchForm.valid">Search&nbsp; <i
									class="fa fa-search"></i></button>
						</div>

						<br>
					</div>
				</div>
			</form>

			<nh-submission-search-results [submissionType]="submission" [results]="results" (reload)="reload()"></nh-submission-search-results>
			<p>*An <strong>Upload</strong> button will display for any submitted applications that we have not started reviewing.  For these applications, the applicant can submit proof now or wait until we review the application so we can tell them what proof we need.  If you don't see the <strong>Upload</strong> button, we've started reviewing the application so you can't upload them now.  Once we finish the application review, we will send them a letter telling them what we need.  They can use their TennCare Connect account to upload them.<br>**For Incurred Medical Expense submissions, you can view the documents you uploaded in My Documents using View TennCare Connect.</p>

		</div>
	</div>
</section>