import { AppealRequestComponent } from './appeal-request.component';
import { nameCreator, sectionConfiguration } from '@app/helpers/models.helpers';
import Vs from '@app/services/validators/validators.service';
import { ExitLabels } from '@app/models/section.model';


const formValidation = (builder, recordData) => {
  const appealCaseNumber = null;
  const filingCaseFor = recordData.filingCaseFor;
  const appealEligibilityDecision = recordData.appealEligibilityDecision;
  const renewalLetter = recordData.renewalLetter;
  const ltssProgram = recordData.ltssProgram;
  const kBprogram = recordData.kBprogram;
  const appealingReasonLtss = recordData.appealingReasonLtss;
  const appealingReasonRenewal = recordData.appealingReasonRenewal;
  const appealingReasonKb = recordData.appealingReasonKb;
  const appealingReasonElg = recordData.appealingReasonElg;
  const duplicateAppeal = recordData.duplicateAppeal;
  const caseSummary = recordData.caseSummary;
  const caseSummaryResponse = recordData.caseSummaryResponse;
  const callerName = recordData?.callerName;

  const data = builder.group({
    appealCaseNumber: [appealCaseNumber],
    filingCaseFor: [filingCaseFor],
    appealEligibilityDecision: [appealEligibilityDecision, Vs.required],
    renewalLetter: [renewalLetter],
    kBprogram: [kBprogram],
    ltssProgram: [ltssProgram],
    appealingReasonRenewal: [appealingReasonRenewal],
    appealingReasonLtss: [appealingReasonLtss],
    appealingReasonKb: [appealingReasonKb],
    appealingReasonElg: [appealingReasonElg],
    duplicateAppeal: [duplicateAppeal],
    caseSummary: [caseSummary],
    primaryAppealantValidation: [],
    appealDecisionValidation: [],
    caseSummaryResponse: [caseSummaryResponse],
    callerName: callerName ? builder.group(nameCreator(callerName)) : null
  });


  return { data };
};

export default sectionConfiguration(AppealRequestComponent, formValidation, ['YESNO', 'FILINGFOR', 'ELIGIBLITYAPPEALREASON', 'KATIEBECKETAPPEALREASON', 'RENEWALAPPEALREASON', 'LTSSAPPEALREASON', 'NAMESUFFIX'], [], {}, {
  hasSummary: false,
  showSubmit: false,
  showComments: false,
  showSaveAndExit: false,
  labelExit: ExitLabels.EXIT
});
