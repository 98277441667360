import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'replaceassettype'
})
export class ReplaceAssetTypePipe implements PipeTransform {
  defaultLanguage;
  constructor(public translateService: TranslateService) {}

  transform(text: string, txtToReplace:string): any {
    txtToReplace = txtToReplace === 'CC' || txtToReplace === 'CIA'? "": txtToReplace;
    text = text.replace('assetType', txtToReplace)
    return text
  }

}
