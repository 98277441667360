import { forEach, juxt } from 'ramda';

import { selfSilent, emitEvent } from './form.helpers';
import { enable, disable, enableWith, disableWith } from './able.helpers';
import { reset, resetWith } from './reset.helpers';

export const disableReset = juxt([disable, reset]);
export const enableReset = juxt([enable, reset]);

export const disableResetControls = forEach(disableReset);
export const enableResetControls = forEach(enableReset);

export const enableControls = forEach(enableWith(selfSilent));
export const disableControls = forEach(disableWith(selfSilent));

const resetEnableEmit = resetWith({ value: null, disabled: false }, emitEvent);
const resetDisableEmit = resetWith({ value: null, disabled: true }, emitEvent);

export const resetEnableControls = forEach(resetEnableEmit);
export const resetDisableControls = forEach(resetDisableEmit);
