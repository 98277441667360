import { invoker } from 'ramda';
import { forEach } from 'ramda';
import { prop } from 'ramda';
import { compose } from 'ramda';
import { both } from 'ramda';

import { isNotNil } from 'ramda-adjunct';

import { get } from './form.helpers';
import { lookUp } from './flipped.helpers';

export const enable = invoker(0, 'enable');
export const disable = invoker(0, 'disable');

export const enableWith = invoker(1, 'enable');
export const disableWith = invoker(1, 'disable');

export const enabled = prop('enabled');
export const disabled = prop('disabled');

export const enableSelf = enableWith({ onlySelf: true });
export const disableSelf = disableWith({ onlySelf: true });

export const disables = forEach(disable);

export const disableGet = compose(
  disable,
  get
);

export const enableLook = compose(
  enableSelf,
  lookUp
);

export const existsEnabled = both(isNotNil, enabled);
