<div *ngIf="showErrorMessage" class = "errorMessage">
<nh-alert type="danger"  [isOpen]="showErrorMessage">
  <div class="row">
    <div class="icon">
      <img src="../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
    </div>
    <div class="message">
      <span>{{ 'ERROR_ADDRESS' | translate}}</span>
    </div>
  </div>
</nh-alert>
</div>

<div class="modal-content people-delete">
  <form [formGroup]="form">
    <div class="modal-header">
      <h4 class="modal-title pull-left" id="my-modal-title">{{ this.title }}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
        <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <h5 class="section-header">{{ this.fullName }} {{'PEOPLE_DELETE_STATIC_TEXT1' | translate }}</h5>
        <hr>
        <p class="hint-label">{{'PEOPLE_DELETE_STATIC_TEXT2' | translate }} {{ this.fullName }} {{'PEOPLE_DELETE_STATIC_TEXT3' | translate }} {{ this.fullName }} {{'PEOPLE_DELETE_STATIC_TEXT4' | translate }}</p>

        <div>
          <nh-field label="{{'PEOPLE_DELETE_STATIC_TEXT5' | translate }} {{ this.fullName }} {{'PEOPLE_DELETE_STATIC_TEXT6' | translate }}" [isRow]="true" [mandatory]="true">
            <select nh [name]="'remove_reason'" (change)="onDateChange(this.form.value.deleteReason.code)" [items]="this.modifiedReasons" [required]="true" aria-label="deleteReason" formControlName="deleteReason"></select>
          </nh-field>
        </div>
		
        <div *ngIf="this.form.value.deleteReason?.code === 'MO'" >
          <nh-field label="{{'WHEN_DID_2' | translate }} {{ this.fullName }} {{'PEOPLE_DELETE_STATIC_TEXT7' | translate }}" [isRow]="true" [mandatory]="false">
            <nh-datepicker>
              <input type="text" name="indvDeletedDate" class="form-control" formControlName="indvDeletedDate" bsDatepicker nh [minDate]="minDate" [maxDate]="today">
            </nh-datepicker>
          </nh-field>
        </div>		

        <div *ngIf="this.form.value.deleteReason?.code === 'DE'" >
          <nh-field label="{{'PEOPLE_DELETE_STATIC_TEXT8' | translate }}" [isRow]="true" [mandatory]="this.form.value.deleteReason?.code === 'DE'">
            <nh-datepicker>
              <input type="text" name="deathDate" class="form-control" formControlName="deathDate" bsDatepicker [required]="this.form.value.deleteReason?.code === 'DE'" nh [minDate]="minDate" [maxDate]="today">
            </nh-datepicker> 
          </nh-field>
        </div>

        <div *ngIf="this.form.value.deleteReason?.code === 'MO'">
          <p class="hint-label" *ngIf="translatedLang === 'en'">Do you know {{ this.fullName }}'s new address? If you do, please tell us the new address below.</p>
          <p class="hint-label" *ngIf="translatedLang !== 'en'">¿Conoce la nueva dirección de {{ this.fullName }}? Si es así, díganos la nueva dirección a continuación.</p>

          <div >
            <nh-field name="moveOutAddressFormat" [isRow]="true" [mandatory]="false" label="{{'ADDRESS_FORMAT' | translate }}">
              <select nh name="moveOutAddressFormat" [items]="ADDRESSFORMATTYPES" aria-label="moveOutAddressFormat"
              formControlName="moveOutAddressFormat" (change)="onChange(this.form.value.moveOutAddressFormat)" [required]="'false'"></select>
            </nh-field>
          </div>

          <div class="rounded">
            <div formGroupName="moveOutAddress">
              <div class="form-row">
                <div class="col-md-8">
                  <nh-field [isRow]="true" [mandatory]="false" label="{{'ADDRESS_LINE_1' | translate }}">
                    <input type="text" name="addressLine1" formControlName="addressLine1" (change)="onChange(this.form.value.moveOutAddress.addressLine1)" class="form-control" maxlength="50" placeholder="105 Johnson Street">
                  </nh-field>
                </div>

                <div class="col-md-8">
                  <nh-field [isRow]="true" [mandatory]="false" label="{{'ADDRESS_LINE_2' | translate }}">
                    <input type="text" class="form-control" name="addressLine2" formControlName="addressLine2" (change)="onChange(this.form.value.moveOutAddress.addressLine2)" maxlength="75" placeholder="Apt 305">
                  </nh-field>
                </div>
				
				        <div class="col-md-8" *ngIf="this.form.value.moveOutAddressFormat === null || this.form.value.moveOutAddressFormat?.code === 'US'" >
                    <nh-field [isRow]="true" [mandatory]="false" label="{{'CITY' | translate }}">
                      <input type="text" name="city" aria-label="city" formControlName="city" maxlength="25" (change)="onChange(this.form.value.moveOutAddress.city)" class="form-control">
                    </nh-field>
                 </div>
                <div class="form-row">                  

                  <div *ngIf="this.form.value.moveOutAddressFormat === null || this.form.value.moveOutAddressFormat?.code === 'US'" class="col-6">
                    <nh-field [isRow]="true" [mandatory]="false" label="{{'STATE' | translate }}">
                      <select nh name="state" [items]="STATE" bindLabel="code" aria-label="state" formControlName="state" (change)="onChange(this.form.value.moveOutAddress.state)" [required]="'false'"></select>
                    </nh-field>
                  </div>

                  <div *ngIf="this.form.value.moveOutAddressFormat?.code === 'MI'" class="col-6">
                    <nh-field [isRow]="true" [mandatory]="false" label="{{'APO_FPO' | translate }}">
                      <select nh name="addrApoFpo" [items]="MILITARYPOCODES" aria-label="addrApoFpo" formControlName="addrApoFpo" [required]="'false'"></select>
                    </nh-field>
                  </div>

                  <div *ngIf="this.form.value.moveOutAddressFormat?.code === 'MI'" class="col-6">
                    <nh-field [isRow]="true" [mandatory]="false" label="{{'AA_AE_AP' | translate }}">
                      <select nh name="addrAaAeAp" [items]="STATEMILITARY" aria-label="addrAaAeAp" formControlName="addrAaAeAp" [required]="'false'"></select>
                    </nh-field>
                  </div>

                  <div class="col-6">
                    <nh-field [isRow]="true" [mandatory]="false" label="{{'ZIP_CODE' | translate }}">
                      <input type="text" name="zip" formControlName="zip" (change)="onChange(this.form.value.moveOutAddress.zip)" class="form-control" mask="00000-9999" pattern="" placeholder="XXXXX-XXXX">
                    </nh-field>
                  </div>
                </div>

                <div *ngIf="this.form.value.moveOutAddress.state?.code === 'TN'" class="col-6">
                  <nh-field [isRow]="true" [mandatory]="false" label="{{'COUNTY' | translate }}">
                    <select nh name="county" [items]="COUNTY" aria-label="county" formControlName="county" (change)="onChange(this.form.value.moveOutAddress.county)" [required]="'false'"></select>
                  </nh-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer d-block button-conatiner">
      <div class="form-row justify-content-start">
        <div class="mr-2 ml-2">
          <button type="button" id="dialog" [attr.disabled]="isDisabled" class="btn btn-block btn-primary onSubmit" *ngIf="showConfirm" (click)="onConfirm()">{{ 'SUBMIT' | translate }}</button>
        </div>
        <div class="mr-2 ml-2">
          <button type="button" id="dialog" class="btn btn-block btn btn-link onCancel" *ngIf="showDecline" (click)="onCancel()">{{ declineButton | translate}}</button>
        </div>
      </div>
    </div>
  </form>
</div>
