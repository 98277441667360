import { Directive, Input, HostBinding, AfterContentInit } from '@angular/core';

import { and } from 'ramda';

import { ifNil } from '@app/helpers/null.helpers';
import { hasHTTP } from '@app/helpers/http.helpers';

// tslint:disable-next-line:directive-selector
@Directive({ selector: 'a[href]' })
export class AnchorDirective implements AfterContentInit {
  @HostBinding('attr.href')
  @Input()
  href;
  @HostBinding('attr.title')
  @Input()
  title;
  @HostBinding('attr.target')
  @Input()
  target;
  @HostBinding('attr.rel') rel;
  @HostBinding('attr.aria-label') label;

  // constructor(private element: ElementRef) {}

  ngAfterContentInit() {
    if (and(!this.target, hasHTTP(this.href))) {
      this.target = '_blank';
      this.rel = 'noopener noreferrer';
    }

    // if (!this.title) {
    //   console.warn(`Add a title to this link:`, this.element);
    // }

    this.title = ifNil(() => this.href, this.title);
    this.label = this.title;
  }
}
