<div class="modal-content modal-container" *ngIf="wonder$ | async as wonder1">
  <div class="modal-header">
    <h4 class="modal-title pull-left" id="my-modal-title"> {{'Health_Plans_Popup_title' | translate}} </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon" />
    </button>
  </div>
  <div class="modal-body">
    <p>{{'Health_Plans_Popup_text_1' | translate}}</p>
      <div class="health-plans">
        <div class="health-plan"  *ngFor="let w of wonder1.mcoProviderDetailsResponse">
          <img src='{{ "assets/images/" + w.mcoLogo }}' alt="'Health Plan' + {{ w.mcoLogo }}"><br>
            <div>
              <span>{{'Health_Plans_Popup_text_2' | translate}}</span><br/>
              <span>{{'Health_Plans_Popup_text_3' | translate: {mcoName: w.mcoName | translate } }}</span><br/>
              <b><a href="{{w.mcoUrl}}">{{w.mcoUrl}}</a></b><br/><br/>
              <span>{{'Health_Plans_Popup_text_4' | translate}}</span><br/><br/>
              <b>{{w.mcoContactNumber}}</b><br/>
              <b>(TTY {{w.mcoTty}})</b><br/>
            </div>
        </div>
      </div>
      <p [innerHTML]="'Health_Plans_Popup_text_5' | translate" class="pb-3 pt-4 text-center"></p>
  </div>
</div>