import { RouteReuseStrategy } from '@angular/router';

export class SectionRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(): boolean {
    return false;
  }

  store(): void {}

  shouldAttach(): boolean {
    return false;
  }

  retrieve(): null {
    return null;
  }

  shouldReuseRoute(): boolean {
    return false;
  }
}
