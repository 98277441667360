import { Component, OnInit } from '@angular/core';
import { BaseModalDirective } from '@app/components/modals/base-modal';

@Component({
  templateUrl: './katie-beckett.component.html',
})
export class KatieBeckettModalComponent extends BaseModalDirective implements OnInit {
  ngOnInit() {

  }
}
