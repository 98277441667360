<div class="container mb-2">
	<nh-alert type="warning" [isOpen]="isConversionMode">
		<div class="row">
			<div class="icon">
				<img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
			</div>
			<div class="message">
				<span>{{'CONVERSION_STATUS' | translate}}</span>
			</div>
		</div>
	</nh-alert>
	<div>
		<div id="step4" #step4 joyrideStep="Step4" (next)="onNext()" stepPosition="left"
			text="{{'TUTORIAL_4' | translate}}">
			<div class="mb-3 panel-height row pl-2 parentdiv">
				<div *ngIf="appStatus === true && !isMobileView && !isRenew"
					class="col-md-4 d-none d-lg-block d-md-block white-panels flex_align">
					<div class="app-status btn_align" *ngIf="!isRenew">
						<h5 class="section-header">{{'COVERAGE_APPLICATION_STATUS' | translate}}</h5>
						<p class="pb-1">{{'APP_IN_PROGRESS_TILE' | translate}}</p>
						<div class="actionbtn" (click)="continueApp()">
							<button class="w-100 btn btn-secondary capitalize-btn"
								style="height:59px;padding:0">{{'COVERAGE_APPLICATION_CONTINUE' | translate}}</button>
						</div>
					</div>
				</div>

				<div *ngIf=" !isMobileView && isRenew" class="col-md-4 d-none d-lg-block d-md-block white-panels flex_align">

					<div class="app-status btn_align">
						<h5 class="section-header">{{'COVERAGE_RENEWAL_STATUS' | translate}}</h5>
						<p class="pb-1">{{'APP_IN_PROGRESS_TILE' | translate}}</p>
						<div class="actionbtn" (click)="continueApp()">
							<button class="w-100 btn btn-secondary capitalize-btn"
								style="height:59px;padding:0">{{'COVERAGE_RENEWAL_CONTINUE' | translate}}</button>
						</div>
					</div>
				</div>
				<div *ngIf="appStatus !== true && !isMobileView && preterminprogress && !isRenew" class="col-md-4 d-none d-lg-block d-md-block white-panels flex_align">
					<div class="app-status btn_align">
						<h5 class="section-header">{{'COVERAGE_PRT_STATUS' | translate}}</h5>
						<p class="pb-1"  [innerHtml]="'COVERAGE_PRT_STATUS_2' | translate | replacedate : renewalDueDate : replaceText | replacedate : preTermDueDate : replacePreTermDateText | sanitize "></p>
						<div class="actionbtn" (click)="continuePretermApp()">
							<button class="w-100 btn btn-secondary"
								style="min-height:85px;padding:0;text-transform:none">{{'COVERAGE_PRETERM_CONTINUE' | translate}}</button>
						</div>
					</div>
				</div>
				<div *ngIf="appStatus === true && isMobileView && !isRenew" class="col-md-4 d-lg-none white-panels">
					<div>
						<h5 class="section-header">{{'COVERAGE_APPLICATION_STATUS' | translate}}</h5>
						<p class="pb-1">{{'APP_IN_PROGRESS_TILE' | translate}}</p>
						<div class="actionbtn" (click)="continueApp()">
							<button class="w-100 btn btn-secondary capitalize-btn"
								style="height:59px;padding:0">{{'COVERAGE_APPLICATION_CONTINUE' | translate}}</button>
						</div>
					</div>
				</div>
				<ng-container *ngIf="mcoMasterSwitch">
					<div *ngIf="isHealthPlanChanged && !isPartnerApp" class="col-md-4 white-panels flex_align">
						<h5 [innerHtml]="'COVERAGE_OVERVIEW_HEADER_12' | translate | sanitize" class="section-header">
						</h5>
						<p [innerHtml]="'COVERAGE_OVERVIEW_INFO_12' | translate | sanitize" class="pb-1"></p>
						<div class="actionbtn" (click)="toChangeHealthPlan()">
							<button class="w-100 btn btn-secondary capitalize-btn"
								style="height:59px;padding:0">{{'WELCOME_BTN' | translate}}</button>
						</div>
					</div>
				</ng-container>
				<div *ngIf="isMobileView && isRenew" class="col-md-4 d-lg-none white-panels">
					<div>
						<h5 class="section-header">{{'COVERAGE_RENEWAL_STATUS' | translate}}</h5>
						<p class="pb-1">{{'APP_IN_PROGRESS_TILE' | translate}}</p>
						<div class="actionbtn" (click)="continueApp()">
							<button class="w-100 btn btn-secondary capitalize-btn"
								style="height:59px;padding:0">{{'COVERAGE_RENEWAL_CONTINUE' | translate}}</button>
						</div>
					</div>
				</div>
				<div *ngIf="appStatus !== true && isMobileView && preterminprogress && !isRenew" class="col-md-4 d-lg-none white-panels">
					<div>
						<h5 class="section-header">{{'COVERAGE_PRT_STATUS' | translate}}</h5>
						<p class="pb-1">{{'APP_IN_PROGRESS_TILE' | translate}}</p>
						<div class="actionbtn" (click)="continuePretermApp()">
							<button class="w-100 btn btn-secondary"
								style="height:59px;padding:0;text-transform:none">{{'COVERAGE_PRETERM_CONTINUE' | translate}}</button>
						</div>
					</div>
				</div>
				<div class="col-md-4 white-panels childdiv flex_align" *ngFor="let item of vals">
					<h5 class="section-header">{{item.header | translate}}</h5>
					<p class="pb-1"
						[innerHtml]="item.text | translate | replacedate : renewalDueDate : replaceText | replacedate : preTermDueDate : replacePreTermDateText | sanitize ">
					</p>
					<div *ngIf="item.button" class="actionbtn"><button class="w-100 btn btn-secondary capitalize-btn"
							style="height:59px" (click)="item.click()">{{item.button | translate }}</button>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="response && response.caseDetailsResponse" class="card pb-2" style="width:100%">
			<div class="card-body">

				<div class="household-data pt-3">
					<h5 class="section-header"><strong>{{'COVERAGE_DETAILS_HEADER' | translate}}</strong></h5>
					<hr>
					<div class="table-padding">
						<mat-accordion *ngIf="!isMobileView" displayMode="" multi class="mat-table" is-open="true">
							<mat-expansion-panel *ngFor="let item of dataSource" class="expansion-panel">
								<mat-expansion-panel-header class="mat-row panel-header">
									<img class="login-icon" alt="" src="../../../../assets/images/⚛️ icons-login-person.svg">
									<div class="pl-2 mat-cell-panel-header">
										<div class="mat-cell gap-name mb-1">{{item.name}}</div>
										<span class="mat-cell">{{item.sortedCaserows[0]?.dob}}</span>,&nbsp;
										<span class="mat-cell">{{item.sortedCaserows[0]?.gender}}</span>
									</div>
									<div>
										<div class="mat-cell pb-2" *ngFor="let i of item.tileCaserows" [innerHtml]="i.statusMsg"></div>
									</div>
								</mat-expansion-panel-header>
								<ngx-datatable #table [rows]='item.sortedCaserows' columnMode="force" [headerHeight]='"auto"' [rowHeight]='"auto"'>
									<ngx-datatable-column [name]="col.name | translate" [prop]="col.prop" *ngFor="let col of columns">
										<ng-template let-column="column" ngx-datatable-header-template>
											<span
												[ngClass]="{'heath-plan-tool-tip': col.prop ==='health_plan' && translatedLang === 'en'}">{{column.name}}
												<nh-popover *ngIf="col.prop === 'health_plan'" [customClass] = "'cdt-popover'"
													[content]="'HEALTH_PLAN_POPOVER_INFO' | translate" class="pl-1"></nh-popover>
											</span>
										</ng-template>
									</ngx-datatable-column>
									<ngx-datatable-column [width]="160">
										<ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
											<div *ngIf="row && row.appStatus !== null && (row.appStatus !== 'PE' && row.appStatus !== 'PP')"
												class="p-0 hover-red">
												<a class="p-0 hover-red btn btn-link text-left" (click)="getDetails(row)"><span
														class="view_details">{{'CMB_VIEW_DETAILS' | translate}}</span></a>
											</div>
										</ng-template>
									</ngx-datatable-column>
								</ngx-datatable>
						
							</mat-expansion-panel>
							<p *ngIf="!dataSource?.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate}}</p>
						</mat-accordion>
						<!-- mobile coverage details table -->
						<mat-accordion *ngIf="isMobileView" displayMode="" multi class="mat-table" is-open="true">
							<mat-expansion-panel *ngFor="let item of dataSource" class="expansion-panel">
								<mat-expansion-panel-header class="mat-row panel-header-mobile">
									<img class="login-icon" alt="" src="../../../../assets/images/⚛️ icons-login-person.svg">
									<span class="mat-cell pl-2 gap-name mb-2">{{item.name}}</span>
									<span class="mat-cell">{{item.sortedCaserows[0]?.dob}}</span>,&nbsp;
									<span class="mat-cell">{{item.sortedCaserows[0]?.gender}}</span>
									<div class="statusMsg_mobile">
										<div class="mat-cell pb-2" *ngFor="let i of item.tileCaserows" [innerHtml]="i.statusMsg"></div>
									</div>
								</mat-expansion-panel-header>
								<div class="nh-table-mobile pt-5 d-lg-none">
									<div class="table-row mb-3 pb-3" *ngFor="let row of item.sortedCaserows">
										<div class="mobile-table-header">{{displayedColumns[0] | translate}}</div>
										<hr>
										<div class="mobile-table-data">{{row.caseNum}}</div>
										<br>
										<div class="mobile-table-header">{{displayedColumns[1] | translate}}</div>
										<hr>
										<div class="mobile-table-data">{{row.coverageType}}</div>
										<br>
										<div class="mobile-table-header">{{displayedColumns[2] | translate}}</div>
										<hr>
										<div class="mobile-table-data">{{row.status}}</div>
										<br>
										<div class="mobile-table-header">{{displayedColumns[3] | translate}}
											<nh-popover [content]="'HEALTH_PLAN_POPOVER_INFO' | translate" [customClass] = "'cdt-popover'" class="pl-1"></nh-popover>
										</div>
										<hr>
										<div class="mobile-table-data">{{row.health_plan}}</div>
										<br>
										<button id="coverageDetailsMob" *ngIf="row && (row.appStatus !=='PE'&& row.appStatus !== 'PP')"
											class="btn btn-block btn-outline-secondary" (click)="getDetails(row)">
											{{'CMB_VIEW_DETAILS' | translate}}
										</button>
									</div>
								</div>
							</mat-expansion-panel>
							<p *ngIf="!dataSource?.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate}}</p>
						</mat-accordion>
					</div>

					<div *ngIf="kbRows.length > 0">
						<h5 class="section-header">Katie Beckett Appointments</h5>
						<hr>
						<ngx-datatable *ngIf="!isMobileView" class='bootstrap striped' #table [rows]='kbRows'
							[columnMode]='"force"' [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
							<ngx-datatable-column [name]="col.name | translate" [prop]="col.prop"
								*ngFor="let col of kbColumns">
							</ngx-datatable-column>
						</ngx-datatable>
						<div *ngIf="isMobileView">
							<div *ngFor="let row of kbRows">
								<p><strong>{{kbColumns[0].name | translate }}</strong></p>
								<hr>
								<p>{{row.name}}</p>
								<br>
								<hr>
								<p><strong>{{kbColumns[1].name | translate }}</strong></p>
								<hr>
								<p>{{row.aptDate}}</p>
								<br>
								<p><strong>{{kbColumns[2].name | translate}}</strong></p>
								<hr>
								<p>{{row.aptTime}}</p>
								<br>
								<p><strong>{{kbColumns[3].name | translate }}</strong></p>
								<hr>
								<p>{{row.type}}</p>
								<br>
								<p><strong>{{kbColumns[4].name | translate }}</strong></p>
								<hr>
								<p>{{row.address | translate }}</p>
								<br>
								<p *ngIf="!kbRows.length" class="mb-5">{{'NO_DATA_TO_DISPLAY' | translate }}</p>
							</div>
						</div>
					</div>

					<div *ngIf="kbPremiumRows.length > 0">
						<h5 class="section-header">Katie Beckett Part A Premium Amount</h5>
						<ngx-datatable *ngIf="!isMobileView" class='bootstrap striped' #table [rows]='kbPremiumRows'
							[columnMode]='"force"' [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
							<ngx-datatable-column [name]="col.name | translate" [prop]="col.prop"
								*ngFor="let col of kbPremiumColumns">
							</ngx-datatable-column>
						</ngx-datatable>
						<div *ngIf="isMobileView">
							<div *ngFor="let row of kbPremiumRows">
								<p><strong>{{kbPremiumColumns[0].name}}</strong></p>
								<hr>
								<p>{{row.fullName}}</p>
								<br>
								<hr>
								<p><strong>{{kbPremiumColumns[1].name}}</strong></p>
								<hr>
								<p>{{row.premiumAmount}}</p>
								<br>
								<p><strong>{{kbPremiumColumns[2].name}}</strong></p>
								<hr>
								<p>{{row.dueDate}}</p>
								<br>
								<p *ngIf="!kbPremiumRows.length" class="mb-5">No data to display</p>
							</div>
						</div>
					</div>

					<div class="my-3">
						<div (click)="showDetails = !showDetails"
							class="section-header d-flex justify-content-between align-items-center">
							<span>{{'CONTACT_INFO' | translate}}</span>
							<span class="pr-3" *ngIf="showDetails"><i class="fa fa-angle-up"></i></span>
							<span class="pr-3" *ngIf="!showDetails"><i class="fa fa-angle-down"></i></span>
						</div>
						<hr>
						<div *ngIf="showDetails && response">
							<p><strong>{{'HOH_INFO' | translate}}</strong></p>
							<div class="card pb-2 ng-star-inserted" style="margin-bottom: 16px">
								<div class="card-body">
									<div class="mt-3"><strong>{{'COVERAGE_CASE_NUMBER' | translate }}</strong></div>
									<span>{{response.caseDetailsResponse.caseNum}}</span>
									<div class="mt-3"><strong>{{'HOH_NAME' | translate}}</strong></div>
									<div>
										<span>{{response.caseDetailsResponse?.hoHFirstName}}</span>
										<span
											*ngIf="response.caseDetailsResponse?.hoHMiddleInitial">&nbsp;{{response.caseDetailsResponse.hoHMiddleInitial}}</span>
										<span>&nbsp;{{response.caseDetailsResponse?.hoHLastName}}</span>
									</div>
									<div *ngIf="phoneNumber">
										<div class="mt-3"><strong>{{'PRIMARY_PHN' | translate}}</strong></div>
										<div>{{phoneNumber | phone}}</div>
									</div>
									<div class="mt-3" *ngIf="email"><strong>{{'EMAIL' | translate}}</strong></div>
									<div>{{email}}</div>

									<div *ngFor="let item of response.caseDetailsResponse.addressResponse">
										<div *ngIf="item.addressType != 'AA'">
											<div class="mt-3"><strong>{{ item?.addressType == 'PA' ? ('HOME_ADDRESS' |
													translate) :
													('MAILING_ADDRESS' | translate)}}</strong></div>
											<div>{{item?.addressLine1}} <span
													*ngIf="item?.addressLine2">{{item?.addressLine2}}</span></div>
											<div *ngIf="!item?.zip4" class="d-flex">
												<span *ngIf="item?.city">
													{{item?.city+", " + item?.state + " "+ item?.zip5}}
												</span>
												<span *ngIf="!item?.city">
													{{item?.zip5}}
												</span>
											</div>
											<div *ngIf="item?.zip4" class="d-flex">
												<span *ngIf="item?.city">
													{{item?.city+", " + item?.state + " "+ item?.zip5+ "-" +item?.zip4}}
												</span>
												<span *ngIf="!item?.city">
													{{item?.zip5+ "-" +item?.zip4}}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="mt-2 assisting"
							*ngIf="response && assistingDetails?.assistingPersonFirstName!== null">
							<small class="mb-2" *ngIf="!assisting" (click)="assisting = true"><u>View Assisting Person
									Information</u>
							</small>
							<div [hidden]="!assisting">
								<p class="small titleSpace"><strong>{{'ASSISTING_PERSON_INFO' | translate}}</strong></p>
								<div class="mt-3 small"><strong>{{'NAME' | translate}}</strong></div>
								<div><span>{{assistingDetails?.assistingPersonFirstName + " "}}</span>
									<span>{{assistingDetails?.assistingPersonLastName}}</span>
								</div>
								<div *ngIf="assistingDetails">
									<div *ngIf="assistingDetails.cellPhone">
										<div class="mt-3 small">
											<strong>{{'PRIMARY_PHN' | translate}}</strong>
										</div>
										<div>
											<span>{{assistingDetails?.cellPhone}}</span>
										</div>
									</div>
									<div *ngIf="!assistingDetails.cellPhone && assistingDetails.homePhone">
										<div class="mt-3 small">
											<strong>{{'PRIMARY_PHN' | translate}}</strong>
										</div>
										<div>
											<span>{{assistingDetails?.homePhone}}</span>
										</div>
									</div>
									<div
										*ngIf="!assistingDetails.cellPhone && !assistingDetails.homePhone && assistingDetails.workPhone">
										<div class="mt-3 small">
											<strong>{{'PRIMARY_PHN' | translate}}</strong>
										</div>
										<div>
											<span>{{assistingDetails?.workPhone}}</span>
										</div>
									</div>
								</div>
								<div *ngIf="assistingDetails?.emailAdd" class="mt-3 small"><strong>{{'EMAIL' |
										translate}}</strong></div>
								<div>
									<span>{{assistingDetails?.emailAdd}}</span>
								</div>
								<div *ngIf="assistingPersonAddress[0]">
									<div class="mt-3 small"><strong>{{'HOME_ADDRESS' | translate}}</strong></div>
									<div>{{assistingPersonAddress[0]?.addressLine1}} <span
											*ngIf="assistingPersonAddress[0]?.addressLine2">{{assistingPersonAddress[0]?.addressLine2}}</span>
									</div>
									<div class="d-flex">
										<div class="d-flex">
											<span *ngIf="assistingPersonAddress[0]?.city">
												{{assistingPersonAddress[0]?.city+", " +
												assistingPersonAddress[0]?.state}} &nbsp;
											</span>
											<span *ngIf="assistingPersonAddress[0]?.zip5">
												{{assistingPersonAddress[0]?.zip5}}
											</span>
											<span *ngIf="assistingPersonAddress[0]?.zip4">
												- {{+assistingPersonAddress[0]?.zip4}}
											</span>
										</div>
									</div>
								</div>
								<div *ngIf="assistingDetails">
									<div class="mt-3 small">
										<strong>{{'ROLES_RESPONSIBILITIES' | translate}}</strong>
									</div>
									<div
										*ngIf="assistingDetails.assistingPersonApplicationSW && assistingDetails.assistingPersonApplicationSW == 'Y'">
										<span>{{'SIGN_BEHALF_SUBMIT_1' | translate}}</span>
									</div>
									<div
										*ngIf="assistingDetails.assistingPersonRenewalSW && assistingDetails.assistingPersonRenewalSW == 'Y'">
										<span>{{'SIGN_BEHALF_SUBMIT_2' | translate}}</span>
									</div>
									<div
										*ngIf="assistingDetails.assistingPersonNoticesSW && assistingDetails.assistingPersonNoticesSW == 'Y'">
										<span>{{'SIGN_BEHALF_SUBMIT_3' | translate}}</span>
									</div>
									<div
										*ngIf="assistingDetails.assistingPersonActOnBehalfSW && assistingDetails.assistingPersonActOnBehalfSW == 'Y'">
										<span>{{'SIGN_BEHALF_SUBMIT_4' | translate}}</span>
									</div>
								</div>
							</div>
							<small class="mt-3 mb-2" *ngIf="assisting" (click)="assisting = false"><u>View
									less</u></small>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>