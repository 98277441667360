import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { ContinueLabels, ExitLabels, CancelLabels } from '@app/models/section.model';
import { ProcessService } from '@app/services/process.service';
import {StateService} from '@app/services/state/state.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'nh-button-bar',
  templateUrl: './button-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonBarComponent implements OnInit {
  @Input() disableContinue = true;
  @Input() disableLeave = false;
  @Input() disableSubmit = false;

  @Input() showContinue = true;
  @Input() showLeave = true;
  @Input() showComments = true;
  @Input() showSubmit = false;
  @Input() showExit = false;

  @Input() sectionId: any;

  @Input() labelContinue = ContinueLabels.NEXT;
  @Input() labelExit = ExitLabels.EXIT;
  @Input() labelCancel = CancelLabels.CANCEL;
  isRMC = false;
  isWP = false;
  showSaveExit = true;
  isSaveAndExitClicked : boolean;
  isAppeal = false;
  isKepro = false;
  isBCCEXT = false;
  isIME = false;
  isAppealReadOnly = false;

  @Output() public continue = new EventEmitter<any>();
  @Output() public leave = new EventEmitter<any>();
  @Output() public comments = new EventEmitter<any>();
  @Output() public submit = new EventEmitter<any>();
  @Output() public saveExit = new EventEmitter<any>();

 ngOnInit(): void {
   this.isRMC = sessionStorage.getItem('appType') === 'RC';
   this.isWP = sessionStorage.getItem('isKepro') === 'true';
   this.isAppeal = sessionStorage.getItem('appType') === 'APL';
   this.isKepro = sessionStorage.getItem('isKepro') === 'true';
   this.isBCCEXT = sessionStorage.getItem('appType') === 'BXT';
   this.isIME = sessionStorage.getItem('appType') === 'IME';
   this.isAppealReadOnly = sessionStorage.getItem('appealReadOnly') === 'true';
   if (this.isRMC || this.isWP || this.isAppeal) {
     this.showSaveExit = false;
   } else {
     this.showSaveExit = true;
   }
   if (this.sectionId === 'APSUM') {
    this.disableContinue = false;
  }
   if (this.isIME && this.sectionId === 'IMEST') {
     this.showContinue = false
   }
 }
  constructor (private state: StateService, private processService: ProcessService, public router: Router, private activatedRoute: ActivatedRoute) {
    this.state.getCloseRef().subscribe((res) => {
      if (res === 'questionnaire') {
        if (!this.isSaveAndExitClicked) {
          this.emitContinue();
        } else {
          this.saveExit.emit();
        }
      }
    })

    this.processService.getIsfromAddressSaveExit().subscribe((result) => {
      if (result == 'fromSaveExit') {
        this.saveExit.emit();
      }
    });
    
    this.processService.getIsfromTaxDepOrJFOutsideSaveExit().subscribe((result) => {
      if (result == 'fromSaveExit') {
        this.saveExit.emit();
      }
    });
  }

  emitContinue() {
    let appealsSectionId = [];
    if (this.isAppeal) {
      if (this.processService.addressValidated) {
        appealsSectionId = ['APREQ', 'APDET', 'APDEM', 'ARPDT', 'APSUM']
      } else {
        appealsSectionId = ['APREQ', 'APDET', 'APSUM']
      }
    }
    if (this.sectionId === 'SUSIG' || appealsSectionId.includes(this.sectionId)) {
      this.disableContinue = true;
    }

    const currentSectionId = this.activatedRoute?.snapshot?.params['sectionId'];
    if (currentSectionId === 'SACHS' && sessionStorage.getItem('appType') === 'AFB') {
      sessionStorage.setItem('closeAFBNudge', 'true');
      if (sessionStorage.getItem('closeNudge')) {
        sessionStorage.removeItem('closeNudge');
      }
    }

    if (!this.isRMC) {
      if (currentSectionId === 'ICEMI') {
        const getIncomereviewStatus = sessionStorage.getItem('employIncomeReviewStatus');
        if (getIncomereviewStatus === 'true') {
          sessionStorage.setItem('closeEmployIncomeNudge', 'true');
        }
        if (sessionStorage.getItem('closeNudge')) {
          sessionStorage.removeItem('closeNudge');
        }
      } else if (currentSectionId === 'ICSEM') {
        const getIncomereviewStatus = sessionStorage.getItem('selfIncomeReviewStatus');
        if (getIncomereviewStatus === 'true') {
          sessionStorage.setItem('closeSelfIncomeNudge', 'true');
        }
        if (sessionStorage.getItem('closeNudge')) {
          sessionStorage.removeItem('closeNudge');
        }

      } else if (currentSectionId === 'ICOTH') {
        const getIncomereviewStatus = sessionStorage.getItem('otherIncomeReviewStatus');
        if (getIncomereviewStatus === 'true') {
          sessionStorage.setItem('closeOtherIncomeNudge', 'true');
        }
        if (sessionStorage.getItem('closeNudge')) {
          sessionStorage.removeItem('closeNudge');
        }
      }
    }
    
    const rmcpregDueDateStatus = sessionStorage.getItem('RMCPastPregDueDateNudgeStatus');
    if (rmcpregDueDateStatus === 'true') {
      sessionStorage.setItem('rmcClosePregDueDateNudge', 'true');
      if (sessionStorage.getItem('closeNudge')) {
        sessionStorage.removeItem('closeNudge');
      }
    }
    this.continue.emit();
  }

  emitLeave() {
    this.leave.emit();
  }

  emitExit () {
    this.isSaveAndExitClicked = true;
    this.saveExit.emit();
  }

  emitComments() {
    this.comments.emit();
  }

  emitSubmit() {
    this.submit.emit();
  }

  appealCancel(from) {
    if(this.isBCCEXT === true) {
      this.state.canDeactivateBCCExt(from)
    } else {
      this.state.canDeactivateAppeal(from)
    }    
  }

}
