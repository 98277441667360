<div class="container">
    <div class="row">
      <div class="col-8">
          <h1 class="page-title">{{headerTitle | translate}}</h1>
      </div>
    </div>
      <nh-alert type="danger" [isOpen]="showErr2">
        <div class="row">
          <div class="icon">
            <img src="../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
            <span>{{ 'ERROR_MSG_2' | translate}}</span>
          </div>
        </div>
      </nh-alert>

      <nh-alert type="danger" [isOpen]="isAlertMessage">
        <div class="row">
          <div class="icon">
            <img src="../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
            <span>{{alertMessage | translate}}</span>
          </div>
        </div>
      </nh-alert>
    <div class="card mb-4 bg-custom-register">
        <h5 class="section-header">{{sectionTitle | translate}}</h5>
        <hr>

        <section *ngIf="passwordLinkStatus == 'update'">
            <div class="col-md-12">
                <p>{{'PASSWORD_GUIDELINES_TITLE' | translate}} </p>
                <form [formGroup]="resetPassword">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <nh-field [isRow]="true" label="{{'PASSWORD' | translate}}" class="label-section">
                                <div class="password-eye">
                                    <input id = "rp_password" [type]="fieldTextType ? 'text' : 'password'" class="form-control"
                                        formControlName="password" placeholder="{{'PASSWORD'| translate}}" (input)="validatePassword($event)" (blur)="checkPassword()" required />
                                    <span (click)="toggleFieldTextType('password')" class="eye-icon"><img alt="" src="assets/images/Icons/eye-outline-password.svg"></span>
                                </div>
                            </nh-field>
                            <nh-field man [isRow]="true" label="{{'CONFIRM_PASSWORD' | translate}}" class="label-section">
                                <div class="password-eye">
                                    <input id = "rp_confirmPassword"  [type]="fieldConfirmPassTextType ? 'text' : 'password'" class="form-control"
                                        formControlName="confirmPassword" placeholder="{{'CONFIRM_PASSWORD'| translate}}" (keyup)="comparePassword($event)" (change)="getErrorMessage()" />
                                    <span (click)="toggleFieldTextType('confirmPassword')" class="eye-icon"><img alt="" src="assets/images/Icons/eye-outline-password.svg"></span>
                                </div>
                            </nh-field>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="password-guide">
                                <div class="password-header"><span>{{'PWD_GUIDE' | translate }}</span><hr></div>
                                <ul>
                                    <li *ngIf="passwordRuleLen" class="password-rules-valid">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/met icon_PWGuidelines.svg" alt="passowrd-met">
                                        <div>{{ 'PG_1' | translate }}</div>
                                      </div>
                                    </li>
                                    <li *ngIf="!passwordRuleLen" class="password-rules">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/not met icon_PWGuidelines.svg" alt="passowrd-not-met">
                                        <div>{{ 'PG_1' | translate }}</div>
                                      </div>
                                    </li>
                                    <li *ngIf="passwordRuleUpp" class="password-rules-valid">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/met icon_PWGuidelines.svg" alt="passowrd-met">
                                        <div>{{ 'PG_2' | translate }}</div>
                                      </div>
                                    </li>
                                    <li *ngIf="!passwordRuleUpp" class="password-rules">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/not met icon_PWGuidelines.svg" alt="passowrd-not-met">
                                        <div>{{ 'PG_2' | translate }}</div>
                                      </div>
                                    </li>                    
                                    <li *ngIf="passwordRuleLow" class="password-rules-valid">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/met icon_PWGuidelines.svg" alt="passowrd-met">
                                        <div>{{ 'PG_3' | translate }}</div>
                                      </div>
                                    </li>
                                    <li *ngIf="!passwordRuleLow" class="password-rules">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/not met icon_PWGuidelines.svg" alt="passowrd-not-met">
                                        <div>{{ 'PG_3' | translate }}</div>
                                      </div>
                                    </li>                   
                                     <li *ngIf="passwordRuleNum" class="password-rules-valid">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/met icon_PWGuidelines.svg" alt="passowrd-met">
                                        <div>{{ 'PG_4' | translate }}</div>
                                      </div>
                                    </li>
                                    <li *ngIf="!passwordRuleNum" class="password-rules">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/not met icon_PWGuidelines.svg" alt="passowrd-not-met">
                                        <div>{{ 'PG_4' | translate }}</div>
                                      </div>
                                    </li>                    
                                    <li *ngIf="passwordRuleSpl" class="password-rules-valid">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/met icon_PWGuidelines.svg" alt="passowrd-met">
                                        <div>{{ 'PG_5' | translate }}</div>
                                      </div>
                                    </li>
                                    <li *ngIf="!passwordRuleSpl" class="password-rules">
                                      <div class="row password-guide-style">
                                        <img src="../../../assets/images/not met icon_PWGuidelines.svg" alt="passowrd-not-met">
                                        <div>{{ 'PG_5' | translate }}</div>
                                      </div>
                                    </li>
                                  </ul>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <br>
            <br>
        </section>
        <section *ngIf="passwordLinkStatus == 'expired'">
            <p>{{'PASSWORD_LINK_EXP_BODY_TEXT' | translate}}</p>
                <div class="row hover-red">
                    <a class="btn btn-link hover-red" [routerLink]="['/signin']">
                        <span class="pr-2">{{'BACK_TO_LOGIN' | translate }}</span>
                        <nh-exit-svg></nh-exit-svg>
                    </a>
                </div>
        </section>
        <section *ngIf="passwordLinkStatus == 'success'">
            <p>{{'PWD_UPDATED' | translate }}</p>
                <div class="row hover-red">
                    <a class="btn btn-link hover-red" [routerLink]="['/signin']">
                        <span class="pr-2">{{'BACK_TO_LOGIN' | translate }}</span>
                        <nh-exit-svg></nh-exit-svg>
                    </a>
                </div>
        </section>
    </div>
    <div *ngIf="passwordLinkStatus == 'update'" class="row py-4">
      <button (click)="updatePassword()" [disabled]="!isFormValid()"
          class="btn btn-primary col-md-2">{{ 'NEXT_BUTTON' | translate}}</button>
  </div>
</div>
