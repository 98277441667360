import { Component, AfterViewInit, AfterContentInit } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { FormBuilder } from '@angular/forms';
import { isNil } from 'ramda';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'nh-newborn-signsubmit',
  templateUrl: './newborn-signsubmit.component.html',
  styleUrls: ['./newborn-signsubmit.component.scss']
})

export class NewbornSignsubmitComponent extends SectionFormDirective implements AfterContentInit, AfterViewInit {

  isPatientShowSign = false;
  istennCareShowSign = false;

  constructor(builder: FormBuilder, private titleService: Title) {
    super(builder);
    this.titleService.setTitle('Newborn Sign Submit');
  }

  userType = sessionStorage.getItem('userType');

  ngAfterContentInit() { }

  ngAfterViewInit() {
    sessionStorage.removeItem('recordId');

    const patientApplicationSignName = this.getControl('data.applicationSignName');
    this.registerToggle(patientApplicationSignName, code => isNil(code) ? this.isPatientShowSign = false : this.isPatientShowSign = true);

    const tennCareApplicationSignName = this.getControl('data.partnerSignName');
    this.registerToggle(tennCareApplicationSignName, code => isNil(code) ? this.istennCareShowSign = false : this.istennCareShowSign = true);
  }
}
