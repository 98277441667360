import { of } from 'rxjs';

import { construct } from 'ramda';
import { compose } from 'ramda';
import { propEq } from 'ramda';
import { equals } from 'ramda';
import { evolve } from 'ramda';
import { ifElse } from 'ramda';
import { dissoc } from 'ramda';
import { objOf } from 'ramda';
import { any } from 'ramda';

import { propNotEq } from 'ramda-adjunct';

import { setPeople } from '@app/state/people/people.creators';
import { handlePayload } from '@app/state/section/section.creators';

import { N } from './tayn.helpers';
import { toISO } from './date.helpers';
import { ifNil } from './null.helpers';
import { value } from './value.helpers';
import { empty } from './observable.helpers';
import { dangerMessage } from './notification.helpers';

import { ContentIds, None, PartialApplication, PayloadTypes } from '@app/models/payload.model';

export const nonePayload = construct(None);
export const partialPayload = construct(PartialApplication);

export const isPartialApp = equals(PayloadTypes.PARTIAL_APPLICATION);
export const isQuestionnaire = equals(PayloadTypes.QUESTIONNAIRE);
export const isInfoMessages = equals(PayloadTypes.INFO_MESSAGES);
export const isVerifyInput = equals(PayloadTypes.VERIFY_INPUT);

export const isDeathConfirmed = equals(ContentIds.DEATH_CONFIRMATION);
export const isCitizenshipDiscrepancy = equals(ContentIds.CITIZENSHIP_DISCREPANCY);
export const isDataDiscrepancy = equals(ContentIds.DATA_DISCREPANCY);

export const hasCitizenshipDiscrepancy = any(propEq('id', ContentIds.CITIZENSHIP_DISCREPANCY));

export const citizenDiscrepancyMessage = dangerMessage(
  'Our records indicate that this individual is not a US Citizen',
  '',
  'usCitizen',
  N()
);

export const deathDate = compose(
  evolve({ deathDate: toISO }),
  dissoc('passedAway'),
  value
);

const orNone = ifNil(nonePayload);

export const setPayload = compose(
  handlePayload,
  orNone
);

const isPartial = propNotEq('isPartial', 0);

const createPartialPayload = compose(
  of,
  objOf('payload'),
  partialPayload
);

export const isPartialPayload = ifElse(isPartial, createPartialPayload, empty);

export const setBlock = compose(
  setPeople,
  objOf('activeBlock'),
  (data, identifier) => ({ identifier, data })
);
