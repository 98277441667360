export default {
    medicalBillType: 'RMB_MEDICAL_BILLS',
    careReceivingIndvNumber: 'RMB_MEDICAL_BILLS_STICT_TEXT1',
    medicalBillOweAmt: 'RMB_MEDICAL_BILLS_STICT_TEXT11',
    medicalBillUnpaidAmt: 'RMB_MEDICAL_BILLS_STICT_TEXT10',
    milesPerAppointment: 'RMB_MEDICAL_BILLS_STICT_TEXT4',
    billPayingFrequencycd: 'RMB_MEDICAL_BILLS_STICT_TEXT5',
    careReceiveDate: 'RMB_MEDICAL_BILLS_STICT_TEXT6',
    personOutOfHouseholdName: 'RMB_MEDICAL_BILLS_STICT_TEXT7',
    relationPersonOutsideHH: 'RMB_MEDICAL_BILLS_STICT_TEXT8',
    medBillStillOweSw: 'RMB_MEDICAL_BILLS_STICT_TEXT13',
    medBillStillOweAmt: 'RMB_MEDICAL_BILLS_STICT_TEXT14',
    removed: {
        medicalPaymentDate: 'RMB_MEDICAL_BILLS_STICT_TEXT9'
    }
}