<div class="container">
	<div class="border-blue row">
		<div class="card" style="width:100%;">
			<div class="card-body">
				<section>
					<h5 class="section-header">{{ 'MANAGE_ACCOUNT' | translate }}</h5>
					<hr>
					<p class="pt-1"><strong>{{'PERSON_LOGGED' | translate }}:</strong> {{userDetails?.firstName}}
						{{userDetails?.lastName}}
					</p>
					<p><strong>{{ 'USERNAME' | translate }}:</strong> {{userName}}</p>
					<p><strong>{{'EMAIL_ADDRESS' | translate }}:</strong> {{userDetails?.email}}</p>
					<h5 class="section-header">{{ 'ACCOUNT_SETTING' | translate }}</h5>
					<hr>
					<p>{{ 'ACCOUNT_SETTINGS_TEXT' | translate }}</p>

					<div class="row">
						<div class="card card-body shadow m-3" [ngClass]="{'applyColor': appType === 'updatePassword'}" (click)="selectedAction('updatePassword')">
							<div class="d-flex align-items-center row pl-2">
								<div class="px-center">
									<div class="dot-password mx-3 ">
										<img src="../../../../assets/rt.svg" alt="update password" />
									</div>
								</div>
								<div class="mx-3 px-center"><strong>{{'UPDATE_PASSWORD' | translate }}</strong></div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="card card-body shadow m-3" [ngClass]="{'applyColor': appType === 'updateEmail'}" (click)="selectedAction('updateEmail')">
							<div class="d-flex align-items-center row pl-2">
								<div class="px-center">
									<div class="dot-email mx-3">
										<img src="../../../../assets/cem.svg" alt="update email" />
									</div>
								</div>
								<div class="mx-3 px-center"><strong>{{'UPDATE_COMMUNICATION' | translate }}</strong></div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="card card-body shadow m-3" [ngClass]="{'applyColor': appType === 'updateSecurityQuestion'}" (click)="selectedAction('updateSecurityQuestion')">
							<div class="d-flex align-items-center row pl-2">
								<div class="px-center">
									<div class="dot-questions mx-3">
										<img src="../../../../assets/security-Q.svg" alt="update question" />
									</div>
								</div>
								<div class="mx-3 px-center"><strong>{{'UPDATE_SECURITY_QUES' | translate }}</strong></div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</div>
