import { evolve } from 'ramda';

import { LifeInsuranceComponent } from './life-insurance.component';

import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration, nonMandatoryAddress } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { toISO } from '@app/helpers/date.helpers';
import lifeInsuranceLabels from './life-insurance-labels';

const formValidation = (builder, recordData) => {
  const lifeInsurance = lookIn(recordData);
  const helpers = builder.group({
    addressFormat: [lifeInsurance(['addressFormat'])],
  });
  const data = builder.group(
    {
      assetAddress: builder.group(nonMandatoryAddress(lifeInsurance(['assetAddress']))),
      phoneNumber: [lifeInsurance(['phoneNumber']), [Vs.phoneNumber]],
      insuranceName: [lifeInsurance(['insuranceName']), [Vs.invalidCompanyName]],
      assetType: [lifeInsurance(['assetType']), [Vs.required]],
      assetAmount: [lifeInsurance(['assetAmount']), Vs.currency],
      faceAmount: [lifeInsurance(['faceAmount']),[Vs.required, Vs.currency]],
      policyNumber: [lifeInsurance(['policyNumber']), [Vs.policyNumber]]
    },
  );

  return { helpers, data };
};

const postObject = evolve({
  jointOwnershipInformation: {
    jointOwnerSetupDate: toISO,
  },
});
const policyNaumberWithCompany = ({ insuranceName, policyNumber }) =>
  `${insuranceName?insuranceName:''} </br> ${policyNumber? policyNumber:''}`;

export default sectionConfiguration(
  LifeInsuranceComponent,
  formValidation,
  ['TALI', 'TAYN', 'TDSA', 'TSTS', 'TACY', 'TDSA', 'TSTS', 'TSTA', 'TREL', 'TSUF', 'STATE', 'COUNTY', 'DCLIFEINSURANCETYPE'],
  [
    { name: 'Owner', pipe: 'individual' },
    { name: 'Policy Type', prop: 'data.assetType.value' },
    { name: 'Face Value', prop: 'data.faceAmount', pipe: 'currency' },
    { name: 'Insurance </br> Company/ Policy Number', prop: 'data', pipe: 'domain', args: policyNaumberWithCompany },
  ],
  {...{}, ...lifeInsuranceLabels},
  {
    postObject
  }
);
