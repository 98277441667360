import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AppRouting } from '../app.routing';

import { PopoverConfig } from 'ngx-bootstrap/popover';
import { TranslateModule } from "@ngx-translate/core";
import { ApiService } from "@app/gateway/services/api.service";
import { AuthService } from "@app/gateway/services/auth.service";
import { ReferenceTableService } from "@app/gateway/services/referenceTable.service";

import { SigninService } from "@app/gateway/signin/signin.service";
import { ComponentsModule } from '@app/components/components.module';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ProviderWelcomeComponent } from './welcome/welcome.component';
import { ProviderAtAGlanceComponent } from './at-a-glance/at-a-glance.component';
import { ProviderMemberSearchComponent } from './member-search/member-search.component';
import { SearchComponent } from './search/search.component';
import { FinancialWelcomebackComponent } from './financial-welcomeback/financial-welcomeback.component';
import { ProviderSubmissionSearchComponent } from './submission-search/submission-search.component';
import { ProviderSubmissionSearchFormComponent } from './submission-search/sumbmission-search-form/submission-search-form.component';
import { ProviderSubmissionSearchResultsComponent } from './submission-search/submission-search-results/submission-search-results.component';
import { LogoutComponent } from './logout/logout.component';
import { ErrorPageComponent } from './errorpage/errorpage.component';
import { InvalidSessionComponent } from './invalidSession/invalidSession.component';
import { CoverageSearchComponent } from './coverage-search/coverage-search.component';

@NgModule({
  declarations: [
    ProviderWelcomeComponent,
    ProviderAtAGlanceComponent,
    FinancialWelcomebackComponent,
    ProviderMemberSearchComponent,
    SearchComponent,
    ProviderSubmissionSearchComponent,
    ProviderSubmissionSearchFormComponent,
    ProviderSubmissionSearchResultsComponent,
    LogoutComponent,
    ErrorPageComponent,
    InvalidSessionComponent,
    CoverageSearchComponent
  ],
  imports: [
    CommonModule,
    AppRouting,
    RouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [
    ApiService,
    AuthService,
    PopoverConfig,
    ReferenceTableService,
    SigninService,
    ProviderAtAGlanceComponent
  ],
})
export class ProvidersModule {}
