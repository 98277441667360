import { Component, AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { SectionFormDirective } from '@app/classes/section-form.class';
import { push, removeAt } from '@app/helpers/form-array.helpers';
import { swapControl, toggles } from '@app/helpers/forms.helpers';
import { otherResourceUseCodes, resourcesCoOwnerGroup } from '@app/helpers/models.helpers';
import { GlobalVars, Table } from '@app/models/tables.model';
import { ModalService } from '@app/services/modal/modal.service';
import Vs from '@app/services/validators/validators.service';
import { TranslateService } from '@ngx-translate/core';
import { formatTableData } from '@app/helpers/tables.helpers';
import { isRmbRmc } from '@app/helpers/mode.helpers';
import { isNil } from 'ramda';
import { Title } from '@angular/platform-browser';
import { ApplicationService } from '@app/services/application/application.service';
@Component({
  templateUrl: './other-resources.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherResourcesComponent extends SectionFormDirective implements AfterContentInit {
  mobile = window.innerWidth < 768 ? true : false;
  error = { isError: false, errorMessage: "" };
  defaultLanguage;
  translatedLang;
  errorMessageEn = "Please make sure that the percentages of ownership add up to 100%.";
  errorMessageSp = "Asegúrese de que los porcentajes de propiedad sumen el 100%.";
  isRmbRmc = false;
  staticText;
  staticTextEn = "We'll show you the information you have on file for your case below."
  staticTextSp = "Abajo le mostraremos la información que tenemos en nuestros expedientes para su caso."
  coOwnerIndivErrorMessage: string;
  KtFlag: boolean = false;
  otherHealthCareList;
  answeredBrstCervCancerSw;
  answeredEcfSw;
  answeredHcbsSw;
  answeredHospiceSw;
  answeredIntelDisbSw;
  answeredMedFacilitySw;
  answeredeMedicalBillsIndSw;
  answeredMedicareIndSw;
  answeredNursingHomeSw;
  answeredSsiBenefitsSw;
  finalListOfIndividuals = [];
  answeredKtBeckettSw;
  ktBeckettIndividuals;
  relationshipList;
  otherHealthCareListData;
  finalListIndvs;
  brstCervCancerSwChecked;
  ecfSwChecked;
  hcbsSwChecked;
  hospiceSwChecked;
  intelDisbSwChecked;
  medFacilitySwChecked;
  medicalBillsIndSwChecked;
  medicareIndSwChecked;
  nursingHomeSwChecked;
  ktBeckettSwChecked;
  ssiBenefitsSwChecked;
  spousalRelationOther;
  finalParentIndvsForLessThanEighteenOther;
  otherIndvsCheckedOther;
  spouseOtherSwitches = [];
  checkedIndividualsOtherSwitches = [];
  finalParentIndvsForLessThanEighteenOtherSwitches = [];
  ageOfIndividualsLessThanEighteen;
  finalListAll:any = [];
  finalListOfIndividualsKT = [];
  finalListIndvsKT;
  user = sessionStorage.getItem('userType');
  appType = sessionStorage.getItem('appType');
  isAFBorFC = (this.appType === 'AFB') || (this.user == 'RP' && (this.appType === 'AFB'));
  checkForDropdown: boolean = false;
  

  constructor(private titleService: Title, private modalService: ModalService, builder: FormBuilder, public translateService: TranslateService, private application: ApplicationService, public cd: ChangeDetectorRef) {
    super(builder);
    this.titleService.setTitle('Other Resources');
  }

  get data() {
    return this.getControl('data');
  }

  coOwnerIndividuals: Table;
  get jointOwnershipInformation(): FormArray {
    return this.getControl('data.jointOwnershipInformation') as FormArray;
  }

  coOwnerValidate(rowData: any) {

    const row = rowData.row;
    const index = rowData.index;
    const { jointOwnerIndividual, outOfHouseHoldname} = row.controls;

    if (jointOwnerIndividual && jointOwnerIndividual.value?.code === "EMPTY") {
      if (this.getValue('outOfHouseHoldname', row) === 'N/A') {        
        outOfHouseHoldname.setValue('');
      } else {
        this.getControl('outOfHouseHoldname', row).setValue(outOfHouseHoldname.value);
      }
      outOfHouseHoldname.enable();
    } else if (jointOwnerIndividual) {
        outOfHouseHoldname.setValue('N/A');
        outOfHouseHoldname.disable();
    }
    let valueArr = this.getValue('data.jointOwnershipInformation').filter(item => item.jointOwnerIndividual?.code !== 'EMPTY').map(item => item.jointOwnerIndividual);
      let values = valueArr.map(val => val?.code);
      let resultedValues = values.filter(item => item !== 'EMPTY')
      const isDuplicate = resultedValues.some((item, idx) => resultedValues.indexOf(item) != idx );
      if(isDuplicate) {
        this.getControl('jointOwnerIndividual', row).setErrors({INVALID_INDIV : true})
        this.coOwnerIndivErrorMessage = 'INVALID_INDIV'
      } else {
        this.getControl('data.jointOwnershipInformation')['controls'][index].setErrors(null);
        this.coOwnerIndivErrorMessage =''
      }
  }

  onResize(e) {
    this.mobile = e.target.innerWidth < 768 ? true : false;
  }

  checkValid() {
    let percentageTotal = 0;
    this.getValue('data.jointOwnershipInformation').forEach(coOwner => {
      percentageTotal = percentageTotal + parseFloat(coOwner.jointOwnerPercentage);
    })

    return percentageTotal === 100 || !this.getControl('data.jointOwnershipInformation').valid || this.coOwnerIndivErrorMessage;

  }

  onAnyOneOutSideChange() {
    if (this.getValue('data.anyOneOutside').code === 'N') {
      this.getControl('data.jointOwnershipInformation').disable();
    } else {
      this.getControl('data.jointOwnershipInformation').enable();
    }
  }

  addRow() {
    push(resourcesCoOwnerGroup({ jointOwnerIndividual: null, outOfHouseHoldname: null, jointOwnerPercentage: '' }), this.jointOwnershipInformation);
    this.error = { isError: false, errorMessage: "" };
  }

  removeRow(index) {
    this.modalService.confirmDelete().subscribe((res) => {
      if (res) {
        removeAt(index, this.jointOwnershipInformation);
        this.jointOwnershipInformation.controls.forEach((row: FormGroup, i) => {
          this.coOwnerValidate({ row, i });
        })
      }
    });
    if (this.jointOwnershipInformation.value.length === 0) {
      this.addRow();
    }
  }

  otherResourcesModal() {
    this.modalService.openOtherResourcesTypes();
  };

  getErrorMessage(row) {
    const percentageControl = this.getControl('jointOwnerPercentage', row);
    percentageControl.setValidators([Vs.required, Vs.decimal,Vs.maxLength(3)]);
    percentageControl.updateValueAndValidity();
    const jointOwnerIndividual = this.getControl('outOfHouseHoldname', row);
    jointOwnerIndividual.setValidators([Vs.required, Vs.invalidName]);
    jointOwnerIndividual.updateValueAndValidity();
    const total = this.jointOwnershipInformation.controls.reduce((val, current: FormGroup) => {
      const value = (+current.controls['jointOwnerPercentage'].value);
      return value + val;
    }, 0);
    if (this.defaultLanguage === 'en') {
      total !== 100 && percentageControl.status === 'VALID' ?
        this.error = { isError: true, errorMessage: this.errorMessageEn } :
        this.error = { isError: false, errorMessage: null }
    } else {
      total !== 100 && percentageControl.status === 'VALID' ?
        this.error = { isError: true, errorMessage: this.errorMessageSp } :
        this.error = { isError: false, errorMessage: null }
    }
  }

  addValidator(row) {
    const jointOwnerIndividual = this.getControl('outOfHouseHoldname', row);
    jointOwnerIndividual.setValidators([Vs.required, Vs.invalidName]);
    jointOwnerIndividual.updateValueAndValidity();
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  tableCodeValue (tableName, tableControl) {
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang)
  }
  clear () {
    this.jointOwnershipInformation.disable();
    this.jointOwnershipInformation.clear();
  }

  onIndividualChange(val) {
    const formControl = this.formGroup.get('data.jointOwnership');
    if (val !== 'Pick an option' && formControl && formControl.value?.code === 'Y') {
      formControl.setValue(null);
    }
  }

  ngAfterContentInit() {

    this.checkForDropdown = (this.isAFBorFC && this.members.length > 1);

    //code for filtering the dropdowns for checked individuals on gatepost questions
    //only if AFB or FC and no. of people on the application is > 1
    if(this.isAFBorFC && this.members.length > 1){
      //request to get the gate-post questions data
      this.application.getSection(this.applicationNumber, 'AIBAO').subscribe(rtn => {
        if (rtn.records.length > 0) {
          const { records } = rtn;
          this.otherHealthCareListData = records[0].data;
          if (this.members.length > 1) {
            this.getGatePostQuestionsDetails(this.otherHealthCareListData);
            
            //request to get the relationship data
            this.application.getSection(this.applicationNumber, 'HHRLS').subscribe(rtn => {
              if (rtn.records.length > 0) {
                const { records } = rtn;
                this.relationshipList = records[0].data.relationshipList;

                if (this.members.length > 1) {
                  this.KtFlag = false;
                  this.getRelationshipsParStepParSpouseForOtherSwitches(this.relationshipList);
                  this.finalListAll = this.finalList();
                  this.cd.detectChanges();

                  //request to get applied indvs for KT
                  if(this.answeredKtBeckettSw){
                    this.application.getSection(this.applicationNumber, 'OTKBR').subscribe(rtn => {
                      if (rtn.records.length > 0) {
                        const { records } = rtn;
                        this.KtFlag = true;
                        this.ktBeckettSwChecked = records.map(data => JSON.parse(data.identifier?.individualId));
                        this.getRelationshipsParStepParSpouseForOtherSwitches(this.relationshipList);
                        
                        if(this.finalListAll && this.finalListAll.length > 0 ){
                          this.finalListAll = this.finalListAll.concat(this.finalListKT());

                          //remove duplicate individuals from the final array
                          this.finalListAll = this.finalListAll.filter((value, index, self) =>
                            index === self.findIndex((t) => (
                              t.identifier === value.identifier
                            ))
                          );

                        }else{
                          this.finalListAll = this.finalListKT();
                        }
                      }
                    });
                  }; 

                };

              }
            });

          };
        }
      });
    }



    this.defaultLanguage = this.translateService.getDefaultLang();
    this.isRmbRmc = isRmbRmc(this.mode);
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);
    if (this.defaultLanguage !== 'en') {
      this.staticText = this.staticTextSp;
      GlobalVars.outSideOption = { value: "Alguien fuera de casa", code: "EMPTY" }
    } else {
      this.staticText = this.staticTextEn;
      GlobalVars.outSideOption = { value: "Someone outside the home", code: "EMPTY" }
    }
    this.translateService.onDefaultLangChange.subscribe((res) => {
    this.translatedLang = res.lang;
    this.languageCheck(res.lang);
      if (res.lang === 'en') {
        this.staticText = this.staticTextEn;
        if (this.error.errorMessage !== null) {
          this.error.errorMessage = this.errorMessageEn
        }
        GlobalVars.outSideOption = { value: "Someone outside the home", code: "EMPTY" }
      } else {
        this.staticText = this.staticTextSp;
        if (this.error.errorMessage !== null) {
          this.error.errorMessage = this.errorMessageSp
        }
        GlobalVars.outSideOption = { value: "Alguien fuera de casa", code: "EMPTY" }
      }
      this.coOwnerIndividuals = [GlobalVars.outSideOption, ...this.individuals.map((item) => ({ value: item.name.fullName, code: item.identifier }))];
    });
    this.coOwnerIndividuals = [GlobalVars.outSideOption, ...this.individuals.map((item) => ({ value: item.name.fullName, code: item.identifier }))];
    this.jointOwnershipInformation.controls.forEach((row: FormGroup, index) => {
      this.coOwnerValidate({row, index});
    })
    const jointOwnership = this.getControl('data.jointOwnership');
    const assetType = this.getControl('data.assetType');
    const useDescription = this.getControl('data.useDescription');

    this.registerToggle(jointOwnership,
      toggles([
        (code) => this.jointOwnershipChange(code)
      ]));
    this.registerToggle(assetType, swapControl(useDescription, otherResourceUseCodes));

  }
  ngAfterViewInit(): void {
    window.scroll(0,0);
  }
  jointOwnershipChange(val) {
    if (val.code === 'N') {
      this.clear();
    }
    if (val.code === 'Y') {
      this.jointOwnershipInformation.enable();
      if (this.jointOwnershipInformation.controls.length === 0 || this.jointOwnershipInformation.controls.length === 1)
      this.addRow();
    }
    this.jointOwnershipInformation.controls.forEach((row) => this.setSelectedIndiv(row))
  }

  setSelectedIndiv = (row) => {
    const { jointOwnerIndividual, outOfHouseHoldname } = row.controls;

    if ((this.record && this.record.data &&
      (isNil(this.record.data.jointOwnershipInformation) || (!this.record.data.jointOwnershipInformation.length))) || isNil(this.record.data) || isNil(jointOwnerIndividual.value)) {
      if (jointOwnerIndividual.value?.code === 'EMPTY') {
        jointOwnerIndividual.setValue({ code: 'EMPTY', value: 'Someone outside the home' });
        outOfHouseHoldname.enable();
      } else {
        const { identifier, name } = this.selectedIndividual;
        if (this.getControl('data.jointOwnershipInformation').value[0].jointOwnerIndividual === null)
          jointOwnerIndividual.setValue({ code: identifier, value: name.fullName });
        outOfHouseHoldname.setValue('N/A');
        outOfHouseHoldname.disable();
      }
    } else if (jointOwnerIndividual.value?.code !== 'EMPTY') {
      outOfHouseHoldname.disable();
    }
  }



  getRelationshipsParStepParSpouseForOtherSwitches(relationshipList) {

    //Individuals selected for all gate post except KT
    if(!this.KtFlag){
      const spoParStparArray = [this.medicalBillsIndSwChecked, this.hcbsSwChecked, this.ecfSwChecked, 
                      this.medicareIndSwChecked, this.hospiceSwChecked, this.intelDisbSwChecked, this.medFacilitySwChecked, this.nursingHomeSwChecked, this.ssiBenefitsSwChecked];
      const spoParStparNamesArray = ["medbills", "hcbs", "ecfsw", "medcare", "hospice", "intdis", "medfac", "nurhome", "ssi"];

      for(let i = 0; i < spoParStparArray.length; i++){
        this.filteredRelationshipsParentStepPar(relationshipList,spoParStparArray[i], spoParStparNamesArray[i]);
      };

      this.spouseOtherSwitches = this.spouseOtherSwitches.reduce((acc, val) => acc.concat(val), []);
      this.finalParentIndvsForLessThanEighteenOtherSwitches = this.finalParentIndvsForLessThanEighteenOtherSwitches.reduce((acc, val) => acc.concat(val), []);
      this.checkedIndividualsOtherSwitches = this.checkedIndividualsOtherSwitches.reduce((acc, val) => acc.concat(val), []);
    };


    //Individuals selected for KT Beckett
    if(this.KtFlag){
      const ktBeckettArray = [this.ktBeckettSwChecked];
      const ktBeckettNamesArray = ["kt"];

      for(let i = 0; i < ktBeckettArray.length; i++){
      this.filteredRelationshipsParentStepPar(relationshipList,ktBeckettArray[i], ktBeckettNamesArray[i]);
      };

      this.spouseOtherSwitches = this.spouseOtherSwitches.reduce((acc, val) => acc.concat(val), []);
      this.finalParentIndvsForLessThanEighteenOtherSwitches = this.finalParentIndvsForLessThanEighteenOtherSwitches.reduce((acc, val) => acc.concat(val), []);
      this.checkedIndividualsOtherSwitches = this.checkedIndividualsOtherSwitches.reduce((acc, val) => acc.concat(val), []);
    };

  };


  filteredRelationshipsParentStepPar(relationshipList, switchName, codedName){
    //spouse, checked Indvs and ( parent, step-parent for individual under 18 ) for Medical Bills, HCBS, ECF
    //spouse and checked Indvs for Medicare, Med Facility, Nursing Home, Hospice, Disabilities, SSI
    //BCC is n/a
    //KT par and step-par under 18 for only the checked Indvs and not all the <18 indvs

    if(switchName.length > 0){
      //spouse relation
      if(codedName !== "kt"){
        this.spousalRelationOther = switchName.map((e)=>{
          let tempM = relationshipList.filter(element=> ((element.sourceIndividual === e) || (element.referenceIndividual === e)) &&
                                                        ((element.relation.code === 'SPO') ))
          return tempM;
        });

        this.spousalRelationOther = (this.spousalRelationOther.map(item => 
                        ({"source" : item[0]?.sourceIndividual, "reference":item[0]?.referenceIndividual})).map(data => Object.values(data)));

        this.spousalRelationOther = (this.spousalRelationOther.reduce((acc, val) => acc.concat(val), [])).filter(data => data !== undefined);
      };


      //parent or step-parent relation for individuals checked under 18
      if(codedName === "medbills" || codedName === "hcbs" || codedName === "ecfsw" || (codedName === "kt" && this.ktBeckettSwChecked)){
        this.ageOfIndividualsLessThanEighteen = this.individuals.filter(item => item.age < 18).map(data => JSON.parse(data.identifier));

        let ageOfIndividualsLessThanEighteenChecked = switchName.map((e)=>{
          let tempC = this.ageOfIndividualsLessThanEighteen.filter(element=> (element === e))
          return tempC;
        });

        ageOfIndividualsLessThanEighteenChecked = (ageOfIndividualsLessThanEighteenChecked.reduce((acc, val) => acc.concat(val), [])).filter(data => data !== undefined);
        console.log(ageOfIndividualsLessThanEighteenChecked);

        let parentalRelationForLessThanEighteen = ageOfIndividualsLessThanEighteenChecked.map((e)=>{
            let temp = relationshipList.filter(element=> ((element.sourceIndividual === e) || (element.referenceIndividual === e)) &&
                                                          ((element.relation.code === 'FAO') || (element.relation.code === 'MOO') ||
                                                            (element.relation.code === 'SFA') || (element.relation.code === 'SMO') ||
                                                            (element.relation.code === 'SDA') || (element.relation.code === 'SSO') ||
                                                            (element.relation.code === 'DAU') || (element.relation.code === 'SON') ))
            return temp;
        });

        parentalRelationForLessThanEighteen = parentalRelationForLessThanEighteen.reduce((acc, val) => acc.concat(val), []);

        let listParentIndvsForLessThanEighteen = (parentalRelationForLessThanEighteen.map(item => ({"source" : item.sourceIndividual, "reference":item.referenceIndividual})).map(data => Object.values(data)));
        listParentIndvsForLessThanEighteen = listParentIndvsForLessThanEighteen.reduce((acc, val) => acc.concat(val), []);

        if(codedName === "kt"){
          listParentIndvsForLessThanEighteen = listParentIndvsForLessThanEighteen.filter( x => !ageOfIndividualsLessThanEighteenChecked.includes(x) );
        }

        this.finalParentIndvsForLessThanEighteenOther = listParentIndvsForLessThanEighteen.filter((c, index) => {
          return listParentIndvsForLessThanEighteen.indexOf(c) === index;
        });
      };

      //other individuals checked
      if(codedName !== "kt"){
        this.otherIndvsCheckedOther = switchName;
      }; 

    };


    if(switchName && switchName.length > 0){
      if(this.spousalRelationOther && this.spousalRelationOther.length > 0){
        this.spouseOtherSwitches.push(this.spousalRelationOther);
      };
      if(this.finalParentIndvsForLessThanEighteenOther && this.finalParentIndvsForLessThanEighteenOther.length > 0){
        this.finalParentIndvsForLessThanEighteenOtherSwitches.push(this.finalParentIndvsForLessThanEighteenOther);
      };
      if(this.otherIndvsCheckedOther && this.otherIndvsCheckedOther.length > 0){
        this.checkedIndividualsOtherSwitches.push(this.otherIndvsCheckedOther);
      }
    };


  }



  getGatePostQuestionsDetails(otherHealthCareListData) {
    const otherHealthCareList = otherHealthCareListData?.otherBenefitsIndvProfileData;
    this.otherHealthCareList = otherHealthCareList;
    this.answeredYesToGatePostQuestions(otherHealthCareListData);
  }


  answeredYesToGatePostQuestions(otherBenefitsDataRecord){
    //list of individuals who checked for each switch in gatepost questions
    const otherBenefitsData = otherBenefitsDataRecord?.otherBenefitsIndvProfileData;
    this.ktBeckettSwChecked = otherBenefitsData.filter(data => data.ktBeckettSwChecked?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.brstCervCancerSwChecked = otherBenefitsData.filter(data => data.brstCervCancerSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.ecfSwChecked = otherBenefitsData.filter(data => data.ecfSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.hcbsSwChecked = otherBenefitsData.filter(data => data.hcbsSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.hospiceSwChecked = otherBenefitsData.filter(data => data.hospiceSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.intelDisbSwChecked = otherBenefitsData.filter(data => data.intelDisbSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.medFacilitySwChecked = otherBenefitsData.filter(data => data.medFacilitySw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.medicalBillsIndSwChecked = otherBenefitsData.filter(data => data.medicalBillsIndSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.medicareIndSwChecked = otherBenefitsData.filter(data => data.medicareIndSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.nursingHomeSwChecked = otherBenefitsData.filter(data => data.nursingHomeSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    this.ssiBenefitsSwChecked = otherBenefitsData.filter(data => data.ssiBenefitsSw?.code === 'Y').map(element => JSON.parse(element?.indvId));
    

    //what gatepost questions are answered as yes
    this.answeredKtBeckettSw = otherBenefitsDataRecord?.kbReferralSw?.code === 'Y';
    let brstCervCancerSw = otherBenefitsData.map(item => item?.brstCervCancerSw?.code === 'Y');
    let ecfSw = otherBenefitsData.map(item => item?.ecfSw?.code === 'Y');
    let hcbsSw = otherBenefitsData.map(item => item?.hcbsSw?.code === 'Y');
    let hospiceSw = otherBenefitsData.map(item => item?.hospiceSw?.code === 'Y');
    let intelDisbSw = otherBenefitsData.map(item => item?.intelDisbSw?.code === 'Y');
    let medFacilitySw = otherBenefitsData.map(item => item?.medFacilitySw?.code === 'Y');
    let medicalBillsIndSw = otherBenefitsData.map(item => item?.medicalBillsIndSw?.code === 'Y');
    let medicareIndSw = otherBenefitsData.map(item => item?.medicareIndSw?.code === 'Y');
    let nursingHomeSw = otherBenefitsData.map(item => item?.nursingHomeSw?.code === 'Y');
    let ssiBenefitsSw = otherBenefitsData.map(item => item?.ssiBenefitsSw?.code === 'Y');

 
    this.answeredBrstCervCancerSw = brstCervCancerSw.some(data => data === true);
    this.answeredEcfSw = ecfSw.some(data => data === true);
    this.answeredHcbsSw = hcbsSw.some(data => data === true);
    this.answeredHospiceSw = hospiceSw.some(data => data === true);
    this.answeredIntelDisbSw = intelDisbSw.some(data => data === true);
    this.answeredMedFacilitySw = medFacilitySw.some(data => data === true);
    this.answeredeMedicalBillsIndSw = medicalBillsIndSw.some(data => data === true);
    this.answeredMedicareIndSw = medicareIndSw.some(data => data === true);
    this.answeredNursingHomeSw = nursingHomeSw.some(data => data === true);
    this.answeredSsiBenefitsSw = ssiBenefitsSw.some(data => data === true);
  }


  finalList(){

    this.finalListOfIndividuals = this.finalListOfIndividuals.concat(this.spouseOtherSwitches).concat(this.finalParentIndvsForLessThanEighteenOtherSwitches).concat(this.checkedIndividualsOtherSwitches);

    const finalListIndvsData = this.finalListOfIndividuals.filter((c, index) => {
      return this.finalListOfIndividuals.indexOf(c) === index;
    });

    
    this.finalListIndvs = finalListIndvsData.map((e)=>{
      let temp = this.individuals.filter(element=> ((JSON.parse(element.identifier) === e)))
      return temp;
    });

    this.finalListIndvs = this.finalListIndvs.reduce((acc, val) => acc.concat(val), []);
    return this.finalListIndvs;

  }


  finalListKT(){

    this.finalListOfIndividualsKT = this.finalListOfIndividualsKT.concat(this.finalParentIndvsForLessThanEighteenOtherSwitches);

    const finalListIndvsDataKT = this.finalListOfIndividualsKT.filter((c, index) => {
      return this.finalListOfIndividualsKT.indexOf(c) === index;
    });

    
    this.finalListIndvsKT = finalListIndvsDataKT.map((e)=>{
      let temp = this.individuals.filter(element=> ((JSON.parse(element.identifier) === e)))
      return temp;
    });

    this.finalListIndvsKT = this.finalListIndvsKT.reduce((acc, val) => acc.concat(val), []);
    return this.finalListIndvsKT;

  }

}