<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left" id="my-modal-title"> {{'INCOME_AND_DEDUCTIONS' | translate}} </h4>
  </div>
  <div class="modal-body">
    <div>
      <div class="panel-section">
        <div aria-label="nursingAddress">
          <h5 class="section-header">
            <p>{{'INCOME_MODAL_TEXT1' | translate}}</p>
            <p class="static-text"><span class="text-danger">*</span>
              <span [innerHTML]="'INCOME_MODAL_TEXT2' | translate"></span></p>
          </h5>
        </div>
        <div class="col">
          <label class="radio-inline checkbox">
            <input class="chk-input chk-gap" id="incomeModalYesCheck" aria-label="incomeModalYesCheck" type="checkbox"
              name="incomeModalYesCheck" (change)="changeOption($event)" value="Yes" [checked]="incomeModalSelectYes">
            <label class="chk-lbl">{{'Yes' | translate }}</label>
          </label>

          <label class="radio-inline checkbox ml-5">
            <input class="chk-input chk-gap" id="incomeModalNoCheck" aria-label="incomeModalNoCheck" type="checkbox"
              name="incomeModalNoCheck" (change)="changeOption($event)" value="No" [checked]="incomeModalSelectNo">
            <label class="chk-lbl">{{'No' | translate }}</label>
          </label>
        </div>
      </div>
    </div>
    <nh-alert type="warning" [isOpen]="mailingAddressAlert">
      <div class="row">
        <div class="icon">
          <img src="../../../../../../assets/images/Icons/⚛️ icons-warning-outline.svg" alt="warning" />
        </div>
        <div class="message">
          <span>{{'ENTER_MANDATORY' | translate }}</span>
        </div>
      </div>
    </nh-alert>
  </div>
  <div class="modal-footer d-block">
    <div class="form-row justify-content-start form-actions">
      <div class="col-md-3">
        <button id="dialog" type="button" class="btn form-actions-btn btn-block btn-secondary" (click)="handleConfirm()"
          [disabled]="this.isConfrimDisabled">{{'CONFIRM' |
          translate}}</button>
      </div>
    </div>
  </div>
</div>