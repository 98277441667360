import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { faqData, faqRPData } from './mobileFAQ-data';
import { BaseModalDirective } from '@app/components/modals/base-modal';

@Component({
  selector: 'mobileFAQ',
  templateUrl: './mobileFAQ.component.html',
  styleUrls: ['./mobileFAQ.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class mobileFAQComponent extends BaseModalDirective implements OnInit {
  data = [];
  userType = '';
  mobileUser = sessionStorage.getItem('isMobileUser') === 'true' ? true : false;

  ngOnInit(): void {
    //this.metaService.createCanonicalURL();
    if(sessionStorage.getItem('userType')) {
      this.userType = sessionStorage.getItem('userType');
    }
    this.setData();    
  }

  onLoad(response) {
    if(response.usertype) {
      this.userType = response.usertype;
      this.setData();
    }
  }

  setData() {
    if (this.userType && this.userType === 'RP') {
      this.data = [...faqRPData];
    } else {
      this.data = [...faqData];
    }
  }

  showHide(num) {
    num.show = !num.show
  }

  cardClickHandler(event: any,item: any) {
    const target = event.target as HTMLElement;
    if(target.tagName.toLowerCase() !== 'a') {
      item.show = !item.show;
    }
   
  }

}
