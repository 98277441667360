import { Type, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { SectionFormDirective } from '@app/classes/section-form.class';

import { Records, Record } from './record.model';
import { Individuals, Individual } from './individual.model';
import { TableCodes, Tables, Table } from './tables.model';
import { Mode } from '@app/models/app.model';

export enum SectionIds {
  SACHS = 'SACHS',
  HHAPL = 'HHAPL',
  PRHAS = 'PRHAS',
  PRAPL = 'PRAPL',
  HHIND = 'HHIND',
  HHSUR = 'HHSUR',
  HHTJF = 'HHTJF',
  HHRLS = 'HHRLS',
  PRRLS = 'PRRLS',
  HHHLC = 'HHHLC',
  ICEMI = 'ICEMI',
  ICSEM = 'ICSEM',
  ICOTH = 'ICOTH',
  PRIND = 'PRIND',
  XPUTL = 'XPUTL',
  INMED = 'INMED',
  XPDEP = 'XPDEP',
  XPSUP = 'XPSUP',
  XPOTH = 'XPOTH',
  ASLIQ = 'ASLIQ',
  ASVEH = 'ASVEH',
  ASLIF = 'ASLIF',
  ASBUR = 'ASBUR',
  ASPRP = 'ASPRP',
  ASCLD = 'ASCLD',
  OTHRS = 'OTHRS',
  AIBAO = 'AIBAO',
  SUCOM = 'SUCOM',
  SUPSM = 'SUPSM',
  SUSGN = 'SUSGN',
  PRASM = 'PRASM',
  SUSIG = 'SUSIG',
  SUCFM = 'SUCFM',
  SURCH = 'SURCH',
  OTKBR = 'OTKBR',
  ADPIN = 'ADPIN',
  ADNFI = 'ADNFI',
  ADSUB = 'ADSUB',
  SUCOV = 'SUCOV',
  HACHS = 'HACHS',
  NBINF = 'NBINF',
  NBIMM = 'NBIMM',
  NBSUM = 'NBSUM',
  NBSUB = 'NBSUB',
  SUHLP = 'SUHLP',
  APREQ = 'APREQ',
  SCAEM = 'SCAEM',
  PRSEM = 'PRSEM',
  APDET = 'APDET',
  ARPDT = 'ARPDT',
  APDEM = 'APDEM',
  APSUM = 'APSUM',
  BCEXS = 'BCEXS',
  IMEST = 'IMEST',
  IMEDT = 'IMEDT',
}

export type SectionId = keyof typeof SectionIds;

export enum TopLevelAnswer {
  EMPTY = 'EMPTY',
  NO = 'NO',
  YES = 'YES',
  SKIP = 'SKIP',
}

export interface Section {
  identifier: SectionId | null;
  topLevelAnswer: TopLevelAnswer;
  records: Record[];
  earnedIncome: boolean;
  showInternet: boolean;
  showInternetInclude: boolean;
  showNewActivities: boolean;
}

export interface FormStatus {
  value: any;
  valid: boolean;
  pristine: boolean;
  dirty: boolean;
}

export interface SkipForm extends FormStatus {
  controls: any;
}

enum PipeTokens {
  date = 'date',
  currency = 'currency',
  individual = 'individual',
  phone = 'phone',
  ssn = 'ssn',
  fullname = 'fullname',
  binary = 'binary',
  time = 'time',
  domain = 'domain',
}

export type PipeToken = keyof typeof PipeTokens;

export interface Pipes {
  [s: string]: PipeTransform;
}

interface BaseColumn {
  name: string;
  args?: any;
  width?: number;
}

interface ColumnProp extends BaseColumn {
  prop: string;
  pipe?: PipeToken;
}

interface ColumnPipe extends BaseColumn {
  prop?: string;
  pipe: PipeToken;
}

interface DomainPipe extends BaseColumn {
  pipe: PipeTokens.domain;
  args: (data: Record, pipes: Pipes) => string;
}

type Column = ColumnProp | ColumnPipe | DomainPipe | any;

export type Columns = Column[];

export interface FormValidations {
  data: FormGroup;
  helpers?: FormGroup;
}

export type OnValidate = (form: FormStatus) => void;

interface Metadata {
  tables?: Tables;
  programs?: Table;
  individuals?: Individuals;
  selectedIndividual?: Individual;
  applyingForHcbc?: boolean;
  applicationType?: string;
  applyingForNursingFacilities?: boolean;
  earnedIncome?: boolean;
  mode?: Mode;
  showInternet?: boolean;
  showInternetInclude?: boolean;
  showNewActivities?: boolean;
  allRecords?: any;
}

export type FormValidation = (builder: FormBuilder, recordData: any, metadata?: Metadata) => FormValidations;

export type PostObject = (object: any) => any;

export type IndividualsFilter = (individuals: Individuals, records: Records) => Individuals;

export type BaseFormComponent = Type<SectionFormDirective>;

export enum ContinueLabels {
  NEXT = 'NEXT',
  SUBMIT = 'SUBMIT',
  FILE_APPEAL = 'FILE_APPEAL',
}
export enum ExitLabels {
  EXIT = "EXIT"
}

export enum CancelLabels {
  CANCEL = "CANCEL"
}


export type LabelContinue = keyof typeof ContinueLabels;

export type ExitLabel = keyof typeof ExitLabels;

export interface BaseSectionConfiguration {
  component: BaseFormComponent;
  formValidation: FormValidation;
  postObject: PostObject;
  columns: Columns;
  labelData?: any;
  individualsFilter: IndividualsFilter;
  hasSummary: boolean;
  showComments: boolean;
  showSaveAndExit: boolean;
  showSubmit: boolean;
  labelContinue: LabelContinue | null;
  labelExit: ExitLabel | null;
  labelCancel: CancelLabels | null;
  tooltipAdd: string;
  buttonAdd: string | null;
  validateSubmit: boolean;
}

export interface SectionConfiguration extends BaseSectionConfiguration {
  tables: TableCodes;
  mode: Mode;
}

export type Config = Partial<SectionConfiguration>;

export interface FormsConfiguration {
  [s: string]: SectionConfiguration;
}

export type IsPartial = 0 | 1 | 2;
