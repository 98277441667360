import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "@app/gateway/services/api.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class ChangeEmailService {
    showEmailAlert = false;
    message = '';
    currentLang;
    updateEmailFrom;
    updatedEmail: boolean;
    userDetails: any;

    constructor(private apiService: ApiService, private route: Router, public translateService: TranslateService) {
        translateService.onDefaultLangChange.subscribe(res => this.switchLanguage(res.lang));
    }

    setLanguage(): void {
        this.currentLang = this.translateService.defaultLang === 'sp' ? 'ES' : 'EN';
    }

    changeEmail() {
        this.showEmailAlert = false;
    }

    switchLanguage(lang) {
        if (lang === 'sp') {
            this.currentLang = 'ES'
        } else {
            this.currentLang = 'EN'
        }
    };

    updateEmail(): void {
        const contentHeader = new HttpHeaders({'pageId':'TCUPE'})
        const updateEmailreq = {
            email: this.updateEmailFrom.controls['currentEmail'].value,
            confirmEmail: this.updateEmailFrom.controls['newEmail'].value,
            language: this.currentLang
        }
        const url = "indi/users/changeEmail";
        this.apiService.post(url, updateEmailreq, {headers: contentHeader}).subscribe((res) => {
            if (res['code'] == '200') {
                window.scroll(0, 0);
                this.route.navigateByUrl('user-profile/manage-account?updatedEmail=true')
                this.updatedEmail = true;
            } else if (res['code'] === '400') {
                this.showEmailAlert = true;
                this.message = res['message'];
                window.scroll(0, 0);
            }
        });
    }
}