import { PeopleSet, PersonLoad, BlockLoad } from '@app/models/state.model';

export class SetPeople {
  static readonly type = '[PERSON] Set Person';
  constructor(public payload: PeopleSet) {}
}

export class LoadPerson {
  static readonly type = '[PERSON] Load Person';
  constructor(public payload: PersonLoad) {}
}

export class LoadBlock {
  static readonly type = '[PERSON] Load Block';
  constructor(public payload: BlockLoad) {}
}
