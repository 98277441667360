import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CommunicationPreferencesService {
  communicationPreferFormState = false;
  communicationAlertShow = false;
  private userAction$ = new Subject<any>();

  constructor() { }

  getUserAction(): Observable<any> {
    return this.userAction$.asObservable();
  }

  setUserAction(actionType: any) {
    this.userAction$.next(actionType);
  }


}
