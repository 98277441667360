<div [class.form-row]="!isRow" [class.readonly]="readonly">
  <section class="form-group" [class.col-lg-6]="!isRow" [class.col-lg-7]="!labelSize" [class.col-lg-9]='col9'>
    <label class="label-section" *ngIf="!!label">
      <span *ngIf="mandatory && showLabel" class="text-danger">*</span>
      <span *ngIf="showLabel" [innerHtml]="label"></span>
      <ng-content select=[field-label]></ng-content>
    </label>
    <ng-content></ng-content>
    <nh-messages [field]="field" [label]="label" [option]="option"></nh-messages>
  </section>
</div>
