<div class="container my-3 p-4">
	<div class="row mb-2">
		<div class="col-12">
			<h1 *ngIf="!isPreterm" class="page-title">{{'RENEWAL_INPROGRESS_STATIC_TEXT1' | translate }}</h1>
			<h1 *ngIf="isPreterm" class="page-title">{{'QFR_INPROGRESS_STATIC_TEXT1' | translate }}</h1>
		</div>
	</div>
     <nh-alert type="danger" [isOpen]="optionNotSelected">
        <div class="row">
          <div class="icon">
            <img src="../../../assets/images/Icons/⚛️ icons-alert-circle-outline.svg" alt="danger" />
          </div>
          <div class="message">
            <span>{{ 'SELECT_TILE' | translate}}</span>
          </div>
        </div>
      </nh-alert>
	<div *ngIf="!mobileUser" class="row pl-1 pb-1 hover-red">
		<div class="btn btn-link hover-red" (click)="onPreviousClick()">
			<svg xmlns="http://www.w3.org/2000/svg" width="16.875" height="15.75" viewBox="0 0 16.875 15.75">
				<g id="_icons_arrow-back-outline" data-name="⚛️ icons/arrow-back-outline" transform="translate(0)">
					<path id="Combined_Shape" data-name="Combined Shape"
						d="M7.093,15.434l-.014-.014L.329,8.671A1.126,1.126,0,0,1,.316,7.093l.014-.014L7.079.329a1.125,1.125,0,0,1,1.6,1.578l-.014.013L3.841,6.75H15.75A1.125,1.125,0,0,1,15.769,9H3.841l4.829,4.829a1.126,1.126,0,0,1,.014,1.578l-.014.013a1.126,1.126,0,0,1-1.578.014Z"
						transform="translate(0)" fill="#174a7c" />
				</g>
			</svg>
			<span class="pl-2">{{'BACK_TO_PREVIOUS_PAGE' | translate}}</span>
		</div>
	</div>
	<br />
	<div class="bg-white p-4">
		<h5 *ngIf="!isPreterm" class="section-header">{{'RENEWAL_INPROGRESS_STATIC_TEXT2' | translate }}</h5><hr *ngIf="!isPreterm">
		<h5 *ngIf="isPreterm" class="section-header">{{'QFR_INPROGRESS_STATIC_TEXT1' | translate }}</h5><hr *ngIf="isPreterm">
		<p *ngIf="!isPreterm" class="static-text">{{'RENEWAL_INPROGRESS_STATIC_TEXT3' | translate }}
		<p *ngIf="isPreterm" class="static-text">{{'QFR_INPROGRESS_STATIC_TEXT3' | translate }}
		</p>
		<p *ngIf="!isPreterm" class="static-text">
			{{'RENEWAL_INPROGRESS_STATIC_TEXT4' | translate }}
		</p>
		<p *ngIf="isPreterm" class="static-text">
			{{'QFR_INPROGRESS_STATIC_TEXT4' | translate }}
		</p>
		<p class="static-text">{{'RENEWAL_INPROGRESS_STATIC_TEXT5' | translate }}
		</p>
		<div>
			<div>
			<div *ngIf="!isOverSevenDays">
				<div class="card card-body shadow mb-2" [ngClass]="{'applyColor': appType === 'continue'}" (click)="select('continue')" role="button">
					<div class="row pl-2">
						<div class="row spl-margin  pl-3"><img class="col dot" aria-label="takeMeBack" src="../../../assets/images/Icons/Take_back.svg"></div>
						<div class="section-column">
							<strong *ngIf="!isPreterm" class="tile_header">{{'RENEWAL_INPROGRESS_STATIC_TEXT6' | translate }}</strong>
							<strong *ngIf="isPreterm" class="tile_header">{{'QFR_INPROGRESS_STATIC_TEXT6' | translate }}</strong>
							<div *ngIf="caseDetails">
								<div class="small tile-margin tile-text">{{'TABLE_DETAIL_NUMBER' | translate }}: {{caseDetails.caseNumber}}</div>
								<div class="small tile-text">{{'RENEWAL_INPROGRESS_STATIC_TEXT7' | translate }}: {{caseDetails.headOfHousehold}}</div>
								<div class="small tile-text">{{'RENEWAL_INPROGRESS_STATIC_TEXT8' | translate }} {{caseDetails.startedBy}}</div>
								<div class="small tile-text">{{'RENEWAL_INPROGRESS_STATIC_TEXT9' | translate }} {{caseDetails.startedOn | date:'MM/dd/yyyy'}}</div>
								<div class="small tile-text">{{'RENEWAL_INPROGRESS_STATIC_TEXT10' | translate }} {{caseDetails.lastAccessedBy}}</div>
								<div class="small tile-text">{{'RENEWAL_INPROGRESS_STATIC_TEXT11' | translate }} {{caseDetails.lastAccessedOn | date:'MM/dd/yyyy'}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
				<div class="card card-body shadow" [ngClass]="{'applyColor': appType === 'new'}" (click)="select('new')">
					<div class="row pl-2">
						<div class="row spl-margin pl-3"><img class="col dot" aria-label="startNew" src="../../../assets/images/Icons/Start_new_app.svg"></div>
						<div  class="section-column spl-padding"><strong *ngIf="!isPreterm" class="tile_header">{{'RENEWAL_INPROGRESS_STATIC_TEXT12' | translate }}</strong>
						<strong *ngIf="isPreterm" class="tile_header">{{'QFR_INPROGRESS_STATIC_TEXT12' | translate }}</strong>
							<div *ngIf="caseDetails">
								<div class="small tile-margin tile-text">{{'TABLE_DETAIL_NUMBER' | translate}}: {{caseDetails.caseNumber}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row mt-3 m-1">
		<button id="continue" class="col-md-2 btn btn-secondary" (click)="next()">
			{{'NEXT' | translate }}
		</button>
	</div>

</div>