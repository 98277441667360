import { Component, AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { isChangeReport, isRedeMode } from '@app/helpers/mode.helpers';
import { Modes } from '@app/models/app.model';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './comments.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsComponent extends SectionFormDirective implements AfterContentInit {
  constructor(
    public translateService: TranslateService, builder: FormBuilder,  private cdr: ChangeDetectorRef, private titleService: Title) {
    super(builder);
    this.titleService.setTitle('Comments');
  }

  appKeyWord: string;
  isFinReassessment = false;
  commentBannerMessage = true;
  isPreterm = sessionStorage.getItem('isPreterm') === "PRETERM" && (sessionStorage.getItem('appType') === 'RB' || (sessionStorage.getItem('appType') === 'REDETERMINATION'  && sessionStorage.getItem('isKepro') === "true"));
  
  languageCheck(language) {
    if (language !== 'en') {
      if (this.appKeyWord === 'APPLICATION') {
        this.appKeyWord = 'solicitud'
      } else if (this.appKeyWord === 'CHANGE') {
        this.appKeyWord = 'cambio'
      } else if (this.appKeyWord === 'RENEWAL') {
        this.appKeyWord = 'renovación'
      } else if (this.appKeyWord === 'QUESTIONS FOR REVIEW') {
        this.appKeyWord = 'preguntas para la revisión'
      }

    } else {
      if (this.appKeyWord === 'solicitud') {
        this.appKeyWord = 'APPLICATION'
      } else if (this.appKeyWord === 'cambio') {
        this.appKeyWord = 'CHANGE'
      } else if (this.appKeyWord === 'renovación') {
        this.appKeyWord = 'RENEWAL'
      } else if (this.appKeyWord === 'preguntas para la revisión') {
        this.appKeyWord = 'QUESTIONS FOR REVIEW'
      }
    }
    this.cdr.detectChanges();

  }

  ngAfterContentInit() {
    let data:any = this.formGroup.controls['data'];
    let comments = data?.controls['comments']?.value;
    if(comments.length > 0){
      comments = comments.replace('Comment added through members TennCare Connect Account: ','');
      data?.controls['comments'].setValue(comments);
    }
        
    const currentMode = sessionStorage.getItem('mode');
    const mode = currentMode ? Modes[currentMode] : 'NEW';
    this.isFinReassessment = JSON.parse(sessionStorage.getItem('isReassessment'));
    this.appKeyWord = isChangeReport(mode) ? 'CHANGE' : (isRedeMode(mode) && !this.isFinReassessment && !this.isPreterm) ? 'RENEWAL' : (isRedeMode(mode) && this.isFinReassessment && !this.isPreterm) ? 'reassessment' : (isRedeMode(mode) && !this.isFinReassessment && this.isPreterm) ? 'QUESTIONS FOR REVIEW' : 'APPLICATION';
    const defaultLanguage = this.translateService.getDefaultLang();
    this.languageCheck(defaultLanguage);

    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.languageCheck(res.lang);
    });
  }
  
}
