<header class="header">
  <div class="bg-white">
    <div class="container">
      <div class="d-flex header__navbar justify-content-between align-items-center">

        <div class="cursor-pointer text-center navbar-brand-wrapper d-flex align-items-top">
          <a (click)="setLogoRedirectionUrl()" class="d-none d-lg-block navbar-brand brand-logo">
            <img alt="logo" *ngIf="!fromPartnerPortal()" src="assets/images/TCC-Logo_DESKTOP.png"
            style="width: 184px; margin-left: 20px;">
          <img alt="logo" *ngIf="fromPartnerPortal()" src="assets/images/TCC-Logo_DESKTOP.png"
            style="width: 184px; margin-left: 20px;">
        </a> 
        <a class="d-block d-lg-none navbar-brand brand-logo">
          <img alt="logo" *ngIf="!fromPartnerPortal()" src="assets/images/TCC-Logo_MOBILE.png"
            style="width: 150px; height: 48px; margin-left: 20px;">
          <img alt="logo" *ngIf="fromPartnerPortal()" src="assets/images/TCC-Logo_DESKTOP.png"
            style="width: 150px; height: 48px; margin-left: 20px;">
        </a>
        </div>
        <div class="d-lg-none signin-clustor">
          <div *ngIf="this.show && !isLoggedIn() && !mobileUser()" class="signin-btn">
            <button [routerLink]="['/signin']" class="button btn btn-primary"> {{ 'SIGNIN' | translate }} </button>
          </div>
          <!-- Hamburger Icon -->
          <div *ngIf="isLoggedIn()" class="profile-icon">
            <img class="" alt="logo" src="assets/images/profile.svg">
          </div>
          <div (click)="onOpen($event)" class="float-right d-lg-none text-primary hamurber">
            <span class="menu-icon" *ngIf="showTutorialInMobileView ? !dropdownOpened && !joyrideService.isTourInProgress() : !dropdownOpened"><img alt="closeMenu" src="assets/images/close-menu.svg"/></span>
            <span class="close-icon" *ngIf="showTutorialInMobileView ? dropdownOpened || joyrideService.isTourInProgress() : dropdownOpened"><img alt="navigationMenu" src="assets/images/navigation-menu.svg"/></span>
          </div>
        </div>

        <div class="d-none d-lg-block header__navbar--links text-right">
          <span joyrideStep="Step5" text="{{'TUTORIAL_5' | translate}}" class="mr-4 hover">
            <a *ngIf="!mobileUser()" [routerLink]="['/', 'faq']" [queryParams]="{lang:this.defaultLanguage, usertype:this.userType}" title="FAQs" target="_blank">{{'FAQS' | translate}}</a>
            <a *ngIf="mobileUser()" (click)="openFAQPopUp()">{{'FAQS' | translate}}</a>
          </span>
          <span *ngIf="!mobileUser()" class="divider"></span>
          <span *ngIf="!isLoggedIn() || !fromPartnerPortal()" class="ml-4 mr-4 hover">
            <img class="mr-2" alt="logo" src="assets/images/translate.png">
            <a (click)="setLanguage()" >{{'TRANSLATE' | translate}}</a>
          </span>
          <span *ngIf="this.show && !isLoggedIn() && !mobileUser()">
            <button [routerLink]="['/signin']" class="btn btn-primary"> {{ 'SIGNIN' | translate }} </button>
          </span>
          <span *ngIf="isLoggedIn() && !fromPartnerPortal()" class="divider"></span>
          <span *ngIf="isLoggedIn() && !fromPartnerPortal() && !this.isKepro" (click)="toggleProfile()" class="dropdown cursor-pointer ml-4">
            <svg *ngIf="dropdownOpened" id="_icons_person-circle-outline" data-name="⚛️ icons/person-circle-outline" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 48 48">
              <path id="Combined_Shape" data-name="Combined Shape" d="M19.229,39A19.491,19.491,0,0,1,5.627,5.814,19.36,19.36,0,0,1,19.507,0c.088,0,.176,0,.266,0a19.494,19.494,0,0,1,13.606,33.19A19.362,19.362,0,0,1,19.5,39C19.411,39,19.319,39,19.229,39Zm8.788-11.126a11.636,11.636,0,0,1,3.033,2.776.373.373,0,0,0,.281.149h.018a.373.373,0,0,0,.277-.121A16.491,16.491,0,0,0,19.5,3h-.043A16.489,16.489,0,0,0,7.378,30.681a.372.372,0,0,0,.272.119h.023a.37.37,0,0,0,.281-.149,11.6,11.6,0,0,1,3.033-2.774A15.908,15.908,0,0,1,19.5,25.5,15.91,15.91,0,0,1,28.017,27.877ZM13.147,15.822a6.378,6.378,0,1,1,12.71,0C25.58,19.5,22.734,22.5,19.5,22.5S13.419,19.5,13.147,15.822Z" transform="translate(4.498 4.498)" fill="#132742"/>
            </svg>     
            <svg *ngIf="!dropdownOpened" id="_icons_person-circle-outline" data-name="⚛️ icons/person-circle-outline" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 48 48">
              <path id="Combined_Shapes" data-name="Combined Shape" d="M19.229,39A19.491,19.491,0,0,1,5.627,5.814,19.36,19.36,0,0,1,19.507,0c.088,0,.176,0,.266,0a19.494,19.494,0,0,1,13.606,33.19A19.362,19.362,0,0,1,19.5,39C19.411,39,19.319,39,19.229,39Zm8.788-11.126a11.636,11.636,0,0,1,3.033,2.776.373.373,0,0,0,.281.149h.018a.373.373,0,0,0,.277-.121A16.491,16.491,0,0,0,19.5,3h-.043A16.489,16.489,0,0,0,7.378,30.681a.372.372,0,0,0,.272.119h.023a.37.37,0,0,0,.281-.149,11.6,11.6,0,0,1,3.033-2.774A15.908,15.908,0,0,1,19.5,25.5,15.91,15.91,0,0,1,28.017,27.877ZM13.147,15.822a6.378,6.378,0,1,1,12.71,0C25.58,19.5,22.734,22.5,19.5,22.5S13.419,19.5,13.147,15.822Z" transform="translate(4.498 4.498)" fill="#174A7C"/>
            </svg>        
            <span *ngIf="dropdownOpened" class="person-style-opened"> {{'WELCOME_1' | translate }}, {{ userDetails?.firstName }} {{ userDetails?.lastName }}</span>
            <span *ngIf="!dropdownOpened" class="person-style-closed"> {{'WELCOME_1' | translate }}, {{ userDetails?.firstName }} {{ userDetails?.lastName }}</span>
            <img *ngIf="!dropdownOpened && !mobileUser()" class="ml-2 mr-2" alt="down" src="../../../assets/images/⚛️ icons-menu-collapsed-arrow.svg">
            <img *ngIf="dropdownOpened" class="ml-2 mr-2" alt="up" src="../../../assets/images/⚛️ icons-menu-expanded-arrow.svg">
          </span>
          <span *ngIf="isLoggedIn() && fromPartnerPortal()" class="ml-4">
            <svg id="_icons_person-circle-outline" data-name="⚛️ icons/person-circle-outline" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 48 48">
              <path id="Combined_Shape" data-name="Combined Shape" d="M19.229,39A19.491,19.491,0,0,1,5.627,5.814,19.36,19.36,0,0,1,19.507,0c.088,0,.176,0,.266,0a19.494,19.494,0,0,1,13.606,33.19A19.362,19.362,0,0,1,19.5,39C19.411,39,19.319,39,19.229,39Zm8.788-11.126a11.636,11.636,0,0,1,3.033,2.776.373.373,0,0,0,.281.149h.018a.373.373,0,0,0,.277-.121A16.491,16.491,0,0,0,19.5,3h-.043A16.489,16.489,0,0,0,7.378,30.681a.372.372,0,0,0,.272.119h.023a.37.37,0,0,0,.281-.149,11.6,11.6,0,0,1,3.033-2.774A15.908,15.908,0,0,1,19.5,25.5,15.91,15.91,0,0,1,28.017,27.877ZM13.147,15.822a6.378,6.378,0,1,1,12.71,0C25.58,19.5,22.734,22.5,19.5,22.5S13.419,19.5,13.147,15.822Z" transform="translate(4.498 4.498)" fill="#174A7C"/>
            </svg>
            <span class="person-style-closed cursor-type"> {{'WELCOME_1' | translate }}, {{ pPUserDetails?.firstName }} {{ pPUserDetails?.lastName }}</span>
          </span>
          <div *ngIf="isLoggedIn() && !this.isKepro && !mobileUser()" class="logout-desktop">
            <button (click)="onSignout($event)" id="log-out-web" type="button"  class="btn btn-block btn-secondary">{{ 'LOGOUT' | translate }} </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <nh-id-bar></nh-id-bar> -->
  <div class="bg-white desktop-menu-list" *ngIf="showTutorialInMobileView ? dropdownOpened || joyrideService.isTourInProgress() : dropdownOpened">
    <div *ngIf="isLoggedIn()" class="container mainnav padding-none">
      <div class="mobile-translate mobile-profile-name"> 
        <img class="mr-2" alt="logo" src="assets/images/profile.svg">
        <span *ngIf="!fromPartnerPortal() && !mobileUser()" class="hover fw-700">{{'WELCOME_1' | translate }}, {{ userDetails?.firstName }} {{ userDetails?.lastName }}</span>
        <span *ngIf="!fromPartnerPortal() && mobileUser()" class="mobApp fw-700">{{'WELCOME_1' | translate }}, {{ userDetails?.firstName }} {{ userDetails?.lastName }}</span>
        <span *ngIf="fromPartnerPortal()" class="hover fw-700">{{'WELCOME_1' | translate }}, {{ pPUserDetails?.firstName }} {{ pPUserDetails?.lastName }}</span>
      </div>
      <ul *ngIf="!fromPartnerPortal() && !mobileUser()" class="hover-options">
        <li class="hover">
          <span (click)="navigateTo('/user-profile/manage-account')"> {{'MANAGE_MY' | translate }} </span>
        </li>
      </ul>

      <ul class="mobile-translate">
        <li>
          <span joyrideStep="Step9" text="{{'TUTORIAL_5' | translate}}" class="mr-5 hover">
            <a  *ngIf="!mobileUser()" [routerLink]="['/', 'faq']" [queryParams]="{lang:this.defaultLanguage, usertype:this.userType}" title="FAQs" target="_blank">{{'FAQS' | translate}}</a>
            <a *ngIf="mobileUser()" (click)="openFAQPopUp()">{{'FAQS' | translate}}</a>
          </span>
        </li>
      </ul>
      <ul *ngIf="!isLoggedIn() || !fromPartnerPortal()" class="mobile-translate">
        <li>
          <span class="hover">
            <img class="mr-2" alt="logo" src="assets/images/translate.png">
            <a (click)="setLanguage()">{{'TRANSLATE' | translate}}</a>
          </span>
        </li>
        <hr>
      </ul>
     
    
      <div *ngIf="isLoggedIn() && !this.isKepro && !mobileUser()" class="pr-4 d-flex justify-content-end logout-mobile">
        <button (click)="onSignout($event)" id="log-out"  type="button"  class="btn btn-block btn-secondary">{{ 'LOGOUT' | translate }}
        </button>
      </div>
    </div>

    <div *ngIf="!isLoggedIn()" class="container">
      <ul class="mobile-translate">
        <li class="hover cursor-pointer">
          <a *ngIf="!mobileUser()" href="{{ gatewayURL }}#/faqs/" title="FAQs" target="_blank">{{'FAQS' | translate}}</a>
          <a *ngIf="mobileUser()" (click)="openFAQPopUp()">{{'FAQS' | translate}}</a>
        </li>
        <hr>
        <li *ngIf="!isLoggedIn() || !fromPartnerPortal()" class="hover cursor-pointer">
          <img  alt="logo" src="assets/images/translate.png">
          <a (click)="setLanguage()" >{{'TRANSLATE' | translate}}</a>
        </li>
      </ul>
      <hr>
      <div *ngIf="isLoggedIn() && !mobileUser()" class="m-3 d-flex justify-content-end">
        <button (click)="onSignout($event)" type="button" class="btn btn-outline-danger mb-10">{{ 'SIGNOUT' | translate }} </button>
      </div>
    </div>
  </div>

</header>