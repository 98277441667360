import { Injectable, InjectionToken } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { append, reject, equals, reverse } from 'ramda';

import { Notifications, Notification } from '@app/models/partials.model';

@Injectable()
export class NotificationsService {
  private notifications: BehaviorSubject<Notifications> = new BehaviorSubject([]);

  public get list(): Observable<Notifications> {
    return this.notifications.asObservable().pipe(share());
  }

  private emit(messages) {
    this.notifications.next(reverse(messages));
  }

  public add(notification: Notification) {
    this.emit(append(notification, this.notifications.getValue()));
    window.scroll(0, 0);
  }

  public remove(notification: Notification) {
    this.emit(reject(equals(notification), this.notifications.getValue()));
  }

  public clear() {
    this.emit([]);
  }

  public addMultiple(notifications) {
    this.emit(notifications);
  }
}

export const ErrorsToken = new InjectionToken<NotificationsService>('ErrorsToken');
export const MessagesToken = new InjectionToken<NotificationsService>('MessagesToken');
