export default {
    assetType: 'RMB_OTHER_RESOURCES_TEXT1',
    useDescription: 'OTHER_RESOURCES_STATIC_TEXT4',
    assetAmount: 'RMB_OTHER_RESOURCES_TEXT2',
    owedAmount: 'RMB_OTHER_RESOURCES_TEXT3',
    jointOwnership: 'RMB_FINANCIAL_STATIC_TEXT8',
    jointOwnershipInformation: 'RMB_C_CO_OWNERS',
    removed: {
        othResourcesEndDate: 'RMB_OTHER_RESOURCES_TEXT4'
    }
}