<div class="container">
    <div class="row mb-3">
        <div class="col-8">
            <h1 *ngIf="activatedUrl !== 'security'" class="page-title">At A Glance</h1>
            <h1 *ngIf="activatedUrl === 'security'" class="page-title">Security Settings</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <div class="card">
                <div class="card-body">
                    <section class="side-section">
                        <div class="title" [routerLink]="['atglance']"><strong>My Case</strong></div>
                    </section>
                    <section class="side-section">
                        <div class="title row" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                            aria-controls="collapseOne" (click)="collapsible = !collapsible">
                            <div class="col-md-8"><Strong>My Profile</Strong></div>
                            <div class="col-md-2 offset-md-2 align-self-end">
                                <i *ngIf="!collapsible" class="fa fa-plus fa-lg"></i>
                                <i *ngIf="collapsible" class="fa fa-minus fa-lg"></i>
                            </div>
                        </div>
                        <div class="collapse" [ngClass]="{'show': collapsible === true}" id="collapseOne">
                            <a class="pl-2 pt-2 small" [routerLink]="['security-settings']">Security Settings</a>
                        </div>
                    </section>
                    <section class="side-section">
                        <div class="title" [routerLink]="['reference-table']"><strong>Reference Table</strong></div>
                    </section>
                    <section class="side-section">
                        <div class="title" [routerLink]="['/user-profile']"><strong>profile</strong></div>
                    </section>
                    <section class="need-help px-2 py-4">
                        <div class="title"><Strong>Need Help?</Strong></div>
                        <p class="small">If you have a question about your case, contact the Customer Service Center.
                        </p>
                        <div class="row small">
                            <div class="col-3">
                                <i class="nh-light-blue fa fa-phone fa-2x"></i>
                            </div>
                            <div class="col-9">
                                <div>1-844-ASK-DHHS</div>
                                <div>(1-844-275-3447)</div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div class="col-9">
            <div class="card card-body">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>