import { IncomeTaxComponent } from './income-tax.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { compose, map, evolve } from 'ramda';
import { controlWith } from '@app/helpers/form.helpers';
import Vs from '@app/services/validators/validators.service';
import { toDate, toISO } from '@app/helpers/date.helpers';

export const earnedIncomeListCreator = ({ personName, frequency, grossIncome }) => ({
  personName: [personName, [Vs.required]],
  frequency: [frequency, [Vs.required]],
  grossIncome: [grossIncome, [Vs.required, Vs.numeric]]
});

export const selfEmpIncomeListCreator = ({ personName, type, monthlyNetAmount }) => ({
  personName: [personName, [Vs.required]],
  type: [type, [Vs.required]],
  monthlyNetAmount: [monthlyNetAmount, [Vs.required, Vs.numeric]]
});

export const otherIncomeListCreator = ({ personName, type, frequency, grossAmount }) => ({
  personName: [personName, [Vs.required]],
  type: [type, [Vs.required]],
  frequency: [frequency, [Vs.required]],
  grossAmount: [grossAmount, [Vs.required, Vs.numeric]]
});

export const taxDeductionsListCreator = ({ personName, type, monthlyAmount }) => ({
  personName: [personName, [Vs.required]],
  type: [type, [Vs.required]],
  monthlyAmount: [monthlyAmount, [Vs.required, Vs.numeric]]
});

const formValidation = (builder, recordData, { earnedIncome }) => {
  const otherIncome = lookIn(recordData);
  
  const earnedIncomeListGroup = compose(
    (control: any) => builder.group(control),
    earnedIncomeListCreator,
  );

  const earnedIncomeListCreatorlatest = ({ wage, indvId, recordId}) => ({
    indvId : indvId ? [indvId.toString()] : null,
    wage : builder.group({
      paymentAmountPerPayCheck: [wage.paymentAmountPerPayCheck, [Vs.numeric]],
      paymentPeriod: [wage.paymentPeriod]
    }),
    employer: builder.group({
      name: "",
      address: builder.group({
        state: {
          "value": "Tennessee",
          "code": "TN"
        }
      })
    }),
    recordId: recordId
  })

  const earnedIncomeListGrouplatest = compose(
    (control: any) => builder.group(control),
    earnedIncomeListCreatorlatest,
  );


  const earnedIncomeListData = otherIncome(['earnedIncome', 'earnedIncomeList']) || [{
    personName: '',
    frequency: '',
    grossIncome: ''
  }];

  let earnedIncomeListDatalastest;
  if (recordData.income.length > 0) {
     earnedIncomeListDatalastest = otherIncome(['income'])
  } else {
    earnedIncomeListDatalastest =  [{
      indvId: null,
      wage: {
        paymentAmountPerPayCheck : '',
        paymentPeriod: null
      },
      employer: {
        name: '',
        address: {
          state: {
            "value": "Tennessee",
            "code": "TN"
          }
        }
      }
    }];
  }

  const earnedIncomeList = map(earnedIncomeListGroup, earnedIncomeListData);

  const earnedIncomeListLatest = map(earnedIncomeListGrouplatest, earnedIncomeListDatalastest);

  const selfEmpIncomeListGroup = compose(
    (control: any) => builder.group(control),
    selfEmpIncomeListCreator,
  );

  const selfEmpIncomeListCreatorLatest = ({ indvId, type, averageMonthlyProfit, recordId }) => ({
    indvId: indvId ? [indvId.toString()] : null,
    type: [type],
    averageMonthlyProfit: [averageMonthlyProfit, [Vs.numeric]],
    recordId: recordId
  });
  const selfEmpIncomeListGroupLatest = compose(
    (control: any) => builder.group(control),
    selfEmpIncomeListCreatorLatest,
  );

  const selfEmpIncomeListData = otherIncome(['selfEmpIncome', 'selfEmpIncomeList']) || [{
    personName: '',
    type: '',
    monthlyNetAmount: ''
  }];

  let selfEmpIncomeListDataLatest;
  if(recordData.selfEmployment.length > 0) {
    selfEmpIncomeListDataLatest = otherIncome(['selfEmployment'])
  } else {
    selfEmpIncomeListDataLatest =  [{
      indvId: '',
      type: null,
      averageMonthlyProfit: ''
    }];
  } 

  const selfEmpIncomeList = map(selfEmpIncomeListGroup, selfEmpIncomeListData);

  const selfEmpIncomeListLatest = map(selfEmpIncomeListGroupLatest, selfEmpIncomeListDataLatest);

  const otherIncomeListGroup = compose(
    (control: any) => builder.group(control),
    otherIncomeListCreator,
  );

  const otherIncomeListCreatorLatest = ({ indvId, otherIncomeDetails, recordId }) => ({
    indvId: indvId ? [indvId.toString()] : null,
    otherIncomeDetails: builder.group({
      paymentAmount: [otherIncomeDetails?.paymentAmount, [Vs.numeric]],
      paymentFrequency: [otherIncomeDetails.paymentFrequency],
      otherIncomeTypeCode: [otherIncomeDetails.otherIncomeTypeCode],
      alimonyOrderDate: [otherIncomeDetails.alimonyOrderDate ? toDate(otherIncomeDetails.alimonyOrderDate) : null, [Vs.validDate, Vs.notBefore120Years]],
    }),
    recordId: recordId
  });

  const otherIncomeListGroupLatest = compose(
    (control: any) => builder.group(control),
    otherIncomeListCreatorLatest,
  );

  const otherIncomeListData = otherIncome(['otherIncome', 'otherIncomeList']) || [{
    personName: '',
    type: '',
    frequency: '',
    grossAmount: ''
  }];

  let otherIncomeListDataLatest;
  if(recordData.otherIncome.length > 0) {
    otherIncomeListDataLatest = otherIncome(['otherIncome'])
  } else {
    otherIncomeListDataLatest =[{
      indvId: '',
      otherIncomeDetails: {
        paymentAmount: '',
        paymentFrequency: null,
        otherIncomeTypeCode: null
      },
      recordId: null
    }];
  } 

  const otherIncomeList = map(otherIncomeListGroup, otherIncomeListData);

  const otherIncomeListLatest = map(otherIncomeListGroupLatest, otherIncomeListDataLatest);

  const taxDeductionsListGroup = compose(
    (control: any) => builder.group(control),
    taxDeductionsListCreator,
  );

  const taxDeductionsListCreatorLatest = ({ indvId, deductionAmount, deductionType, alimonyOrderDate,  recordId, taxDedSeqNum }) => ({
    indvId: indvId ? [indvId.toString()] : null,
    deductionType : [deductionType ? deductionType : null],
    deductionAmount: [deductionAmount, [Vs.numeric]],
    alimonyOrderDate: [alimonyOrderDate ? toDate(alimonyOrderDate) : null, [Vs.validDate, Vs.notBefore120Years]],
    recordId: recordId,
    taxDedSeqNum: taxDedSeqNum
  });

  const taxDeductionsListGroupLatest = compose(
    (control: any) => builder.group(control),
    taxDeductionsListCreatorLatest,
  );

  const taxDeductionsListData = otherIncome(['taxDeductions', 'taxDeductionsList']) || [{
    personName: '',
    type: '',
    monthlyAmount: '',
    alimonyOrderDate: ''
  }];


  let taxDeductionsListDataLatest;
  if(recordData.taxDeductionsData.length > 0) {
    taxDeductionsListDataLatest = otherIncome(['taxDeductionsData'])
  } else {
    taxDeductionsListDataLatest =[{
      indvId: '',
      deductionType : null,
      deductionAmount: '',
      alimonyOrderDate:'',
      taxDedSeqNum: null
    }]
  } 

  const taxDeductionsList = map(taxDeductionsListGroup, taxDeductionsListData);

  const taxDeductionsListLatest = map(taxDeductionsListGroupLatest, taxDeductionsListDataLatest);

  const helpers = builder.group({
    earnedIncome: [controlWith(earnedIncome, true)],
  });

  const datalatest = builder.group({
    earnedIncome: builder.group({
      earnedIncomeList: builder.array(earnedIncomeList),
    }),
    selfEmpIncome: builder.group({
      selfEmpIncomeList: builder.array(selfEmpIncomeList),
    }),
    otherIncome: builder.group({
      otherIncomeList: builder.array(otherIncomeList),
    }),
    taxDeductionsData: builder.group({
      taxDeductionsList: builder.array(taxDeductionsList),
    })
  });


  const data = builder.group({
    income: builder.array(earnedIncomeListLatest),
    selfEmployment:  builder.array(selfEmpIncomeListLatest),
    otherIncome: builder.array(otherIncomeListLatest),
    taxDeductionsData:  builder.array(taxDeductionsListLatest),
});

  console.log(data, 'testData');
  console.log(datalatest, 'testData');


  return { data, helpers };
};

const postObject = evolve({
  taxDeductionsData: map(
    evolve({
      alimonyOrderDate: toISO,
      paymentAmount: Number
    })
  ),
  otherIncome: map(
    evolve({
      otherIncomeDetails: {
        alimonyOrderDate: toISO,
        paymentAmount: Number
      }
    })
  )
});

export default sectionConfiguration(
  IncomeTaxComponent,
  formValidation,
  ['TAUI', 'TPFT', 'TAYN', 'TSRR', 'UNEARNEDINCOMETYPE_PP', 'VAUNEARNEDINCOME', 'SSUNEARNEDINCOME', 'YESNO', 'INCEXPPAYFREQUENCY', 'ISMEXPENSETYPE','SELFEMPTYPE','MPDEDUCTIONS'],
  [{}],
  {},
  {
    postObject: postObject,
    tooltipAdd: ' if anyone in the household has income that changes month to month.',
    hasSummary: false,
    validateSubmit: true,
  }
);
