<section class="row mt-2 pb-3 ml-2 main-content-width" style="overflow-x: hidden;">
  <div class="mr-3 pr-md-1 py-sm-1" *ngIf="!isAppealReadOnly">
    <button id="continue" class="btn btn-block btn-secondary" (click)="emitContinue()" *ngIf="showContinue" [disabled]="disableContinue">
      {{ labelContinue | translate }}
    </button>
  </div>
  <div class="mr-3 pr-md-1 py-sm-1" *ngIf="isAppealReadOnly && labelContinue !== 'FILE_APPEAL'">
    <button id="continue" class="btn btn-block btn-secondary" (click)="emitContinue()" *ngIf="showContinue" [disabled]="disableContinue">
      {{ labelContinue | translate }}
    </button>
  </div>
  <div class="pr-md-1 py-sm-1" *ngIf="((isAppeal && !isKepro) && sectionId !== 'APSUM') || (isBCCEXT) || (isIME)">
    <button id="saveExit" class="btn btn-block btn-outline-secondary" (click)="appealCancel('exit')">
      {{ labelExit | translate }}
    </button>
  </div>
  <div class="pr-md-1 py-sm-1" *ngIf="isAppeal && sectionId === 'APSUM' && !isAppealReadOnly">
    <button id="saveExit" class="btn btn-block btn-outline-secondary" (click)="appealCancel('cancel')">
      {{ labelCancel | translate }}
    </button>
  </div>
  <div class="pr-md-1 py-sm-1" *ngIf="isAppeal && sectionId === 'APSUM' && isKepro && isAppealReadOnly">
    <button id="saveExit" class="btn btn-block btn-outline-secondary" (click)="appealCancel('readOnlyAplCancel')">
      {{ labelCancel | translate }}
    </button>
  </div>
  <div class="pr-md-1 py-sm-1" *ngIf="showSaveExit">
    <button id="saveExit" class="btn btn-block btn-outline-secondary" (click)="emitExit()" *ngIf="showExit" [disabled]="disableContinue">
      {{'SAVE_AND_EXIT' | translate}}
    </button>
  </div>

  <div class="py-md-2 p-md-2 pb-1 w-100">
    <button id="submit" class="btn btn-block btn-primary" (click)="emitSubmit()" *ngIf="showSubmit" [disabled]="disableSubmit">
      {{'SUBMIT' | translate}}
    </button>
  </div>

</section>
