import { Component, ViewEncapsulation } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { minus120Years } from "@app/helpers/date.helpers";
import { ChangeDetectionStrategy, AfterContentInit } from '@angular/core';
import { SectionFormDirective } from '@app/classes/section-form.class';
import { Title } from "@angular/platform-browser";

@Component({
  templateUrl: './patient-information.component.html',
  styleUrls: ["./patient-information.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PatientInformationComponent extends SectionFormDirective implements AfterContentInit {

  minimumDate = minus120Years();
  today: any = new Date();
  selectedValue;
  gender;

  constructor(private titleService: Title, builder: FormBuilder) {
    super(builder)
    this.titleService.setTitle('Patient Information');
  }

  get patientInformation() {
    return this.getControl('data');
  }

  

  ngAfterContentInit() {
    const genderCode = this.patientInformation?.value.gender;
    if (genderCode) {
      if (genderCode === 'F') {
       this.gender = "Female"
      }
      if (genderCode === 'M') {
        this.gender = "Male"
      }
      if (genderCode === 'U') {
        this.gender = "Unknown"
      }
    }

    if(sessionStorage.getItem("admitDischarge")){
      const selectedAdmit = this.tables.PPAMDIS.filter(x => x.value === sessionStorage.getItem("admitDischarge"))
      this.getControl('data.admitDischarge').patchValue(selectedAdmit[0]);
    }

  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }
  selectChangeHandler(event: any) {
   
    this.selectedValue = event.target.value;
    sessionStorage.setItem("admitDischarge",  this.selectedValue);
  }



}
