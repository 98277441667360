import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'nh-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  passwordRuleLen: boolean;
  passwordRuleUpp: boolean;
  passwordRuleLow: boolean;
  passwordRuleNum: boolean;
  passwordRuleSpl: boolean;
  isPasswordMatched: boolean = true;
  validPassword: boolean = false;
  fieldTextType: boolean;
  fieldConfirmPassTextType: boolean;
  splValue = '(!$#,&@~^*_-+=><|?%:;)';
  headerTitle = '';
  sectionTitle = '';
  passwordLinkStatus: string = '';
  queryParams: any;
  isAlertMessage: boolean;
  alertMessage: any;
  showErr2: boolean = false;
  currentLang;
  contentHeaders = new HttpHeaders({'pageId': 'TCFGP'})

  resetPassword = new FormGroup({
    password: new FormControl(''),
    confirmPassword: new FormControl('')
  })

  get resetForm() {
    return this.resetPassword.controls;
  }
  constructor(private apiService: ApiService, public translateService: TranslateService, private activatedRoute: ActivatedRoute, private titleService: Title
    ) {
      this.titleService.setTitle('Reset Password');
      this.activatedRoute.queryParams.subscribe(res => {
      if (res && res.langcd) {
        this.currentLang = res.langcd === 'sp' ? 'ES' : res.langcd
        if (this.currentLang == 'ES') {
          this.translateService.setDefaultLang('sp');
        }
      }
      else translateService.onDefaultLangChange.subscribe(res => this.switchLanguage(res.lang));
    })
  }

  switchLanguage(lang) {
    if (lang === 'sp') {
      this.currentLang = 'ES'
    } else {
      this.currentLang = 'EN'
    }
  };

  ngOnInit(): void {
    const validateLinkObj = {}
    this.activatedRoute.queryParams.subscribe((response) => {
      this.queryParams = response;
      validateLinkObj['reasonCode'] = response.reasonCode;
      validateLinkObj['token'] = response.token;
      validateLinkObj['language'] = this.currentLang;
      validateLinkObj['userName'] = response.loginAttribute;
    })

    this.apiService.post('publ/users/validateLinks', validateLinkObj, {headers : this.contentHeaders}).subscribe((response) => {
      response['language'] === 'ES' ? this.translateService.setDefaultLang('sp') : this.translateService.setDefaultLang('en');
      if (response['code'] == 400) {
        this.passwordLinkStatus = 'expired';
        this.sectionTitle = 'VERIFICATION_LINK_EXPIRED';
        this.headerTitle = 'LINK_EXPRIED';
      } else if (response['code'] ==  200) {
        this.passwordLinkStatus = 'update';
        this.sectionTitle = 'SET_NEW_PASSWORD';
        this.headerTitle = 'FORGOT_PWD';
      }
    })
  }

  toggleFieldTextType(fieldType) {
    if (fieldType == 'password') {
      this.fieldTextType = !this.fieldTextType;
    } else {
      this.fieldConfirmPassTextType = !this.fieldConfirmPassTextType
    }
  }

  comparePassword(event) {
    this.validPassword = (this.passwordRuleLow && this.passwordRuleUpp && this.passwordRuleNum
      && this.passwordRuleSpl && this.passwordRuleLen &&
      (event.target.value == this.resetPassword.controls['password'].value));
    this.isPasswordMatched = (event.target.value.length > 0 && (event.target.value == this.resetPassword.controls['password'].value));
  }

  getErrorMessage() {
    this.resetForm.confirmPassword.value === '' ? this.resetForm.confirmPassword.setErrors({ PASSWORD_CONFIRM: true }) : this.resetForm.confirmPassword.value === this.resetForm.password.value ?
      this.resetForm.confirmPassword.setErrors(null) :
      this.resetForm.confirmPassword.setErrors({ PASSWORDS_DIFFERENT: true });
  }

  isFormValid(): boolean {
    return ((this.resetPassword.status == "VALID")
      && this.validPassword);
  }

  validatePassword(event): void {
    this.passwordRuleLow = (event.target.value.match(/[a-z]/g));
    this.passwordRuleUpp = (event.target.value.match(/[A-Z]/g));
    this.passwordRuleNum = (event.target.value.match(/[0-9]/g));
    this.passwordRuleLen = (event.target.value.length >= 8 && event.target.value.length <= 32);
    this.passwordRuleSpl = false;
    for (let i = 0; i < event.target.value.length; i++) {
      if (this.splValue.indexOf(event.target.value.charAt(i)) > -1) {
        this.passwordRuleSpl = true;
        break;
      }
    }
  }
  checkPassword() {
    if (this.resetForm.password.value !== '') {
      if (this.passwordRuleLow && this.passwordRuleUpp && this.passwordRuleNum
        && this.passwordRuleSpl && this.passwordRuleLen) {
        this.showErr2 = false;
      }
      else this.showErr2 = true;
    }
  }

  updatePassword(): void {
    this.isAlertMessage = false;
    const updatePassword = {
      confirmPassword: this.resetPassword.controls['confirmPassword'].value,
      userName: this.queryParams.loginAttribute,
      language: this.currentLang
    };

    this.apiService.post('publ/users/resetPassword', updatePassword, {headers : this.contentHeaders}).subscribe((response) => {
      if (response['code'] == '200') {
        this.passwordLinkStatus = 'success';
        this.sectionTitle = 'PASSWORD_UPDATE';
        this.headerTitle = 'SUCCESS';
      } else if (response['code'] == '400') {
        this.isAlertMessage = true;
        if (response['message'] ==  'password_previous') {
          this.alertMessage = 'PREVIOUS_PWD';
        }
      }
    });
  }
}
