import { FormGroup } from '@angular/forms';
import { of } from 'rxjs';

import { propOr } from 'ramda';
import { compose } from 'ramda';
import { forEachObjIndexed } from 'ramda';
import { prop } from 'ramda';

import { constructN } from 'ramda';

import { disableSelf, enableSelf } from './able.helpers';

export const formGroup = constructN(1, FormGroup);
export const valid = prop('valid');
export const valueChanges = propOr(of(), 'valueChanges');
export const statusChanges = prop('statusChanges');

export const groupControls = propOr({}, 'controls');

export const disableGroupControls = compose(
  forEachObjIndexed(disableSelf),
  groupControls
);

export const enableGroupControls = compose(
  forEachObjIndexed(enableSelf),
  groupControls
);
