import { OtherExpensesComponent } from './other-expenses.component';

import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';

import otherExpenseLabels from './other-expenses-labels';

const formValidation = (builder, recordData) => {
  const expenseData = lookIn(recordData);

  const insuranceCompanyName = expenseData(['insuranceCompanyName']);
  const phoneNumber = expenseData(['phoneNumber']);
  const emailId = expenseData(['emailId']);
  const minRequirementMetSw = expenseData(['minRequirementMetSw']);
  const policyPremiumAmount = expenseData(['policyPremiumAmount']);
  const premiumFreqCd = expenseData(['premiumFreqCd']);
  const cobraSw = expenseData(['cobraSw']);
  const retireeSw = expenseData(['retireeSw']);

  const data = builder.group({
    insuranceCompanyName: [insuranceCompanyName, Vs.invalidName],
    phoneNumber: [phoneNumber, Vs.phoneNumber],
    emailId: [emailId, Vs.email],
    minRequirementMetSw: [minRequirementMetSw],
    policyPremiumAmount: [policyPremiumAmount, [Vs.required, Vs.currency]],
    premiumFreqCd: [premiumFreqCd, [Vs.required]],
    cobraSw: [cobraSw],
    retireeSw: [retireeSw]
  });

  return { data };
};

export default sectionConfiguration(
  OtherExpensesComponent,
  formValidation,
  ['TAYN', 'TDDT', 'TPFT', 'YESNO', 'INCEXPPAYFREQUENCY'],
  [
    { name: 'Name', pipe: 'individual' },
    { name: 'Contact Name', prop: 'data.insuranceCompanyName' },
    { name: 'Minimum Requirement Met?', prop: 'data.minRequirementMetSw.value'}
  ],
  {...{}, ...otherExpenseLabels},
  {}
);
