import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AppRouting } from '../../app/app.routing';

import { PopoverConfig } from 'ngx-bootstrap/popover';
import { TranslateModule } from "@ngx-translate/core";
import { ApiService } from "@app/gateway/services/api.service";
import { AuthService } from "@app/gateway/services/auth.service";
import { ReferenceTableService } from "@app/gateway/services/referenceTable.service";

import { LandingComponent } from './landing/landing.component'
import { SigninComponent } from "@app/gateway/signin/signin.component";
import { SigninService } from "@app/gateway/signin/signin.service";
import { ComponentsModule } from '@app/components/components.module';
import { LandingService } from './landing/landing.service';
import { CreateAccountNewComponent } from 'app/gateway/create-account-new/create-account-new.component';
import { AtAGlanceComponent } from 'app/gateway/at-a-glance/at-a-glance.component';
import { CaseApplicationComponent } from 'app/gateway/at-a-glance/case-application/case-application.component';
import { SecuritySettingsComponent } from '@app/gateway/at-a-glance/security-settings/security-settings.component';
import { BeforeStartComponent } from './at-a-glance/before-start-application/before-start-application.component';
import { WelcomeBackComponent } from 'app/gateway/welcome-back/welcome-back.component';
import { ReferenceTableComponent } from './at-a-glance/Reference-tables/reference-table.component';
import { RegisterAccountComponent } from './register-account/register-account.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotuserNameComponent } from './forgotuser-name/forgotuser-name.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ManageAccountComponent } from './user-profile/manage-account/manage-account.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegisterAccountValidateComponent } from './register-account-validate/register-account-validate.component';
import { ChangeSecurityQuestionsComponent } from './user-profile/change-security-questions/change-security-questions.component';
import { MyDocumentsComponent } from './user-profile/my-documents/my-documents.component';
import { DocumentsNeededComponent } from './user-profile/my-documents/documentsNeeded/documents-needed.component'
import { MySubmissionsComponent } from './user-profile/my-submissions/my-submissions.component';
import { ChangeEmailComponent } from './user-profile/change-email/change-email.component';
import { ChangeEmailValidateComponent } from './change-email-validate/change-email-validate.component';
import { LinkCaseComponent } from './user-profile/link-case/link-case.component'
import { CoverageDetailsComponent } from './user-profile/coverage-details/coverage-details.component';
import { CommunicationPreferencesComponent } from './user-profile/communication-preferences/communication-preferences.component';
import { MyLettersComponent } from './user-profile/my-letters/my-letters.component';
import { BenefitDashboardComponent } from './user-profile/benefit-dashboard/benefit-dashboard.component';
import { CaseDocumentsComponent } from './user-profile/my-documents/case-documents/case-documents.component';
import { UploadedDocumentsComponent } from './user-profile/my-documents/uploaded-documents/uploaded-documents.component';
import { ChangePasswordComponent } from './user-profile/change-password/change-password.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { DocumentUploadComponent } from './user-profile/my-documents/documentsNeeded/document-upload/document-upload.component';
import { ProofUploadComponent } from './user-profile/my-documents/documentsNeeded/proof-upload/proof-upload.component';
import { FaqComponent } from './faq/faq.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { RenewalBenefitsWelcomeComponent } from './renewal-benefits-welcome/renewal-benefits-welcome.component';
import { JoyrideModule } from 'ngx-joyride';
import {RenewBenefitsComponent} from './user-profile/renew-benefits/renew-benefits.component';
import { ReportChangeComponent } from './user-profile/report-change/report-change.component';
import { HealthPlanDetailsComponent } from './user-profile/healthplan-details/healthplan-details.component';
import { MyAppealsComponent } from './user-profile/my-appeals/my-appeals.component';
import { AppealContinuanceComponent } from './user-profile/my-appeals/appealContinuance/appeal-continuance.component';
import { AppealWithdrawalComponent } from './user-profile/my-appeals/appealWithdrawal/appeal-withdrawal.component';
import { AutoRenewalComponent } from './user-profile/auto-renewal/auto-renewal.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { PretermComponent } from './user-profile/pre-term/pre-term.component';

@NgModule({
  declarations: [
    LandingComponent,
    SigninComponent,
    CreateAccountNewComponent,
    AtAGlanceComponent,
    CaseApplicationComponent,
    SecuritySettingsComponent,
    BeforeStartComponent,
    WelcomeBackComponent,
    ReferenceTableComponent,
    RegisterAccountComponent,
    ForgotPasswordComponent,
    ForgotuserNameComponent,
    UserProfileComponent,
    ManageAccountComponent,
    ResetPasswordComponent,
    RegisterAccountValidateComponent,
    ChangeSecurityQuestionsComponent,
    MyDocumentsComponent,
    DocumentsNeededComponent,
    MySubmissionsComponent,
    ChangeEmailComponent,
    ChangeEmailValidateComponent,
    LinkCaseComponent,
    CoverageDetailsComponent,
    CommunicationPreferencesComponent,
    MyLettersComponent,
    CaseDocumentsComponent,
    UploadedDocumentsComponent,
    BenefitDashboardComponent,
    ChangePasswordComponent,
    DocumentUploadComponent,
    ProofUploadComponent,
    FaqComponent,
    CreateAccountComponent,
    RenewalBenefitsWelcomeComponent,
    RenewBenefitsComponent,
    ReportChangeComponent,
    HealthPlanDetailsComponent,
    MyAppealsComponent,
    AppealContinuanceComponent,
    AppealWithdrawalComponent,
    AutoRenewalComponent,
    PretermComponent
  ],
  imports: [
    CommonModule,
    AppRouting,
    RouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatTableModule,
    CdkTableModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatSortModule,
    MatPaginatorModule,
    MatExpansionModule,
    JoyrideModule.forChild()
  ],
  providers: [
    ApiService,
    AuthService,
    PopoverConfig,
    ReferenceTableService,
    SigninService,
    LandingService,
    BeforeStartComponent
  ],
})
export class GatewayModule {}
