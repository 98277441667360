import { lookIn } from '@app/helpers/flipped.helpers';
import { IncurredMedicalExpInfo } from './incurredMedicalExpInformation.component';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { ExitLabels } from '@app/models/section.model';
import { FormArray } from '@angular/forms';

const formValidation = (builder, recordData) => {

  console.log(recordData)
  const imeInformation = lookIn(recordData);
  const firstName = imeInformation(['firstName']);
  const middleName = imeInformation(['middleName']);
  const lastName = imeInformation(['lastName']);
  const indvId = imeInformation(['indvId']);
  const caseNum = imeInformation(['caseNum']);
  const expenseList = imeInformation(['expenseList']);

  const expenseArrayList = (): FormArray => {
    let expenseArray: any = [];

    if(expenseList && Array(expenseList) && expenseList.length) {
      expenseArray = expenseList;
    } else {
      expenseArray = []
    }
    return expenseArray as FormArray;
  }

  const data = builder.group({
    firstName : firstName,
    middleName : middleName,
    lastName : lastName,
    indvId : indvId,
    caseNum : caseNum,
    expenseList : builder.array(expenseArrayList()),
  })
  return { data };
};


export default sectionConfiguration(
  IncurredMedicalExpInfo,
  formValidation,
  [],
  [],
  {},
  {
    hasSummary: false,
    validateSubmit: true,
    showComments: false,
    showSaveAndExit: false,
    labelExit: ExitLabels.EXIT
  }
);
