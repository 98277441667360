import { evolve } from 'ramda';

import { MedicareComponent } from './medicare.component';
import { personReceivingMedical } from '@app/helpers/models.helpers'

import Vs from '@app/services/validators/validators.service';
import { sectionConfiguration } from '@app/helpers/models.helpers';
import { toISO, toDate } from '@app/helpers/date.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';

import medicareLabels from './medicare-labels';

const formValidation = (builder, recordData) => {
  const medicalBills = lookIn(recordData)
  const kbMedicalBillsSw = medicalBills(['kbMedicalBillsSw'])
  const medicalBillType = medicalBills(['medicalBillType']);
  const careReceivingIndvNumber = medicalBills(['careReceivingIndvNumber']);
  const milesPerAppointment = medicalBills(['milesPerAppointment']);
  const medicalBillUnpaidAmt = medicalBills(['medicalBillUnpaidAmt']);
  const medicalBillOweAmt = medicalBills(['medicalBillOweAmt']);
  const careReceiveDate = toDate(medicalBills(['careReceiveDate']));
  const billPayingFrequencycd = medicalBills(['billPayingFrequencycd']);
  const relationship = medicalBills(['relationPersonOutsideHH']);
  const personOutOfHouseholdName = medicalBills(['personOutOfHouseholdName']);
  const medicalBillSubTyp = medicalBills(['medicalBillSubTyp']);
  const medBillStillOweSw = medicalBills(['medBillStillOweSw']);
  const medBillStillOweAmt = medicalBills(['medBillStillOweAmt']);
  
  let medicare: any = {
    medicalBillType: [medicalBillType, Vs.required],
    kbMedicalBillsSw : [kbMedicalBillsSw],
    careReceivingIndvNumber: [careReceivingIndvNumber, Vs.required],
    medicalBillOweAmt: [medicalBillOweAmt, [Vs.required, Vs.currency]],
    medicalBillSubTyp: [medicalBillSubTyp],
    medicalBillUnpaidAmt: [medicalBillUnpaidAmt, [Vs.required, Vs.currency]],
    milesPerAppointment: [milesPerAppointment, Vs.number],
    billPayingFrequencycd: [billPayingFrequencycd, Vs.required],
    careReceiveDate: [careReceiveDate, [Vs.notBefore120Years, Vs.required]],
    personOutOfHouseholdName: [personOutOfHouseholdName, [Vs.required, Vs.invalidName]],
    relationPersonOutsideHH: [relationship, Vs.required],
    medBillStillOweSw: medBillStillOweSw,
    medBillStillOweAmt: medBillStillOweAmt
  };

  const data = builder.group(medicare);

  return { data };
};


const postObject = evolve({
  careReceiveDate: toISO
});

export default sectionConfiguration(
  MedicareComponent,
  formValidation,
  ['MEDNEEDYEXPENSES', 'RELATIONTYPE', 'INCEXPPAYFREQUENCY', 'RELATIONSHIPTOINDIVIDUAL', 'YESNO'],
  [
    { name: 'Name', pipe: 'individual', grow: 3 },
    { name: 'Person Who <br>Received Care', args: personReceivingMedical, pipe: 'domain', grow: 7},
    { name: 'Medical Bill Type', prop: 'data.medicalBillType.value', grow: 5 },
    { name: 'Total amount', prop: 'data.medicalBillUnpaidAmt', pipe: 'currency', grow: 8 },
    { name: 'Amount you have already paid', prop: 'data.medicalBillOweAmt', pipe: 'currency', grow: 5 }
  ],
  {...{}, ...medicareLabels},
  {
    tooltipAdd: null,
    postObject
  }
);
