<div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title pull-left" id="my-modal-title">{{'ASSISTING_PERSON_TYPES_HEADER' | translate}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
        <img src="../../assets/images/⚛️ icons-close-circle-outline.svg" alt="close-icon"/>
      </button>
    </div>
    <div class="modal-body">
      <table class="table table-striped d-none d-lg-block modal-table">
        <thead>
          <tr class="header-sty">
            <th scope="col">{{'ASSISTING_PERSON_TYPES' | translate}}</th>
            <th scope="col">{{'ASSISTING_PERSON_DESCRIPTION' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{'AUTHORIZED_REPRESENTATIVE' | translate}}</td>
            <td>{{'AUTHORIZED_REPRESENTATIVE_DESCRIPTION' | translate}}</td>
          </tr>
          <tr>
            <td>{{'CONSERVATOR' | translate}}</td>
            <td>{{'CONSERVATOR_DESCRIPTION' | translate}}</td>
          </tr>
          <tr>
            <td>{{'LEGAL_GUARDIAN' | translate}}</td>
            <td>{{'LEGAL_GUARDIAN_DESCRIPTION' | translate}}</td>
          </tr>
          <tr>
            <td>{{'POWER_OF_ATTORNEY' | translate}}</td>
            <td>{{'POWER_OF_ATTORNEY_DESCRIPTION' | translate}}</td>
          </tr>
        </tbody>
      </table>
      <div class="nh-table-mobile d-lg-none">
        <div class="mobile-table-header">{{'AUTHORIZED_REPRESENTATIVE' | translate}}</div><hr>
        <div class="mobile-table-data">{{'AUTHORIZED_REPRESENTATIVE_DESCRIPTION' | translate}}</div><br>
        <div class="mobile-table-header">{{'CONSERVATOR' | translate}}</div><hr>
        <div class="mobile-table-data">{{'CONSERVATOR_DESCRIPTION' | translate}}</div><br>
        <div class="mobile-table-header">{{'LEGAL_GUARDIAN' | translate}}</div><hr>
        <div class="mobile-table-data">{{'LEGAL_GUARDIAN_DESCRIPTION' | translate}}/div><br>
        <div class="mobile-table-header">{{'POWER_OF_ATTORNEY' | translate}}</div><hr>
        <div class="mobile-table-data">{{'POWER_OF_ATTORNEY_DESCRIPTION' | translate}}</div><br>
      </div>
    </div>
  </div>
