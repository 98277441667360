import { Pipe, PipeTransform } from '@angular/core';

import { individualName, individualNameSuffixValue } from '@app/helpers/people.helpers';

import { Individual } from '@app/models/individual.model';

@Pipe({ name: 'fullname' })
export class FullNamePipe implements PipeTransform {
  transform(individual: Individual, alternative = 'this person'): string {
    const name = individualName(alternative, individual);
    if(individual && individual.name && individual.name.suffix){
        return name+" "+individualNameSuffixValue(alternative,individual.name);
    }
    return individualName(alternative, individual);
  }
}
