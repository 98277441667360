import { OnInit, Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { SigninService } from '../signin/signin.service';
import { ModalService } from "@app/services/modal/modal.service";
import { MetaService } from '@app/services/metaservice';
@Component({
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingComponent implements OnInit, AfterContentInit {
  showBrowserIncompatableMsg: boolean = false
  constructor(
    private signInService: SigninService,
    private modalService: ModalService,
    private metaService: MetaService
  ) {
  }
  slide = 1;
  ngAfterContentInit() {
    this.signInService.userLoggedIn.next(false);
  }
  ngOnInit() {
    console.log("Landing component");
    this.metaService.createCanonicalURL();
    let incompatablemessage = false;
      const browser = this.getBrowserDetails();
      const browserName = browser.name
      const browserVersion = browser.version
      if (browserName == "Safari") {
        incompatablemessage = browserVersion < 11;
      } else if (["CriOS","Chrome"].includes(browserName)) {
        incompatablemessage = browserVersion < 65.0;
      } else if (browserName == "Edge") {
        if (browserVersion < 15) {
          incompatablemessage = true;
        }
      } else {
        incompatablemessage = true;
      }
    this.showBrowserIncompatableMsg = incompatablemessage;
    sessionStorage.setItem('homeScreen', 'true')
  }

  ngOnDestroy() {
    sessionStorage.removeItem('homeScreen')
  }
  
  getBrowserDetails() {
    let ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|crios|fxios|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return {
        name: 'IE', version: (tem[1] || '')
      };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR\/(\d+)/)
      if (tem != null) {
        return {
          name: 'Opera', version: tem[1]
        };
      }
      tem = ua.match(/\bEdge\/(\d+)/)
      if (tem != null) {
        return {
          name: 'Edge', version: tem[1]
        };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1]
    };
  }
  showbrowserIncompatablePopup(){
    this.modalService
      .openBrowserIncomatiblePopup();
  }
  prevSlide() {
    if (this.slide > 1)
      this.slide = this.slide - 1
  }
  nextSlide() {
    if (this.slide < 5)
      this.slide = this.slide + 1
  }
}
