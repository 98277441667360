import { flip } from 'ramda';
import { prop } from 'ramda';
import { pathOr } from 'ramda';
import { o } from 'ramda';
import { find } from 'ramda';
import { reduceRight } from 'ramda';
import { objOf } from 'ramda';
import { forEach } from 'ramda';
import { when } from 'ramda';
import { useWith } from 'ramda';
import { propSatisfies } from 'ramda';

import { viewOr } from 'ramda-adjunct';

export const lookUp = flip(prop);
export const orPath = flip(pathOr);
export const lookIn = flip(pathOr(undefined));
export const queer = flip(o);
export const from = flip(find);
export const amplify = flip(reduceRight);
export const valueAs = flip(objOf);
export const toEach = flip(forEach);
export const runIf = flip(when);
export const withUse = flip(useWith);
export const propIs = flip(propSatisfies);
export const orView = flip(viewOr);
