import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
@Component({
    selector: 'nh-before-start',
    templateUrl: './before-start-application.component.html',
    styleUrls: ['./before-start-application.component.scss']
})

export class BeforeStartComponent implements OnInit {
    constructor(private router: Router, private titleService: Title
        ) {
            this.titleService.setTitle('Apply for Assistance');
        }
    applicationNum;
    startSectionId: string;

    ngOnInit() {}
    startApplication(appType) {
        this.applicationNum = JSON.parse(sessionStorage.getItem('appNum'));
        sessionStorage.setItem('appType', appType);
        this.router.navigateByUrl('/application/' + this.applicationNum);
    }
}