import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { of, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { head } from 'ramda';

import { ApplicationService } from '@app/services/application/application.service';
import { value } from '@app/helpers/value.helpers';
import { assign } from '@app/helpers/function.helpers';
import { valueChanges } from '@app/helpers/form-group.helpers';

import { ApplicationNumber } from '@app/models/app.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nh-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() applicationNumber: ApplicationNumber;
  @Input() searchGroup: FormGroup;
  @Input() keyword: string;
  @Input() resource: string;
  @Input() columns: any[];
  @Input() showSearch = false;
  @Input() showSearchButton: boolean;
  @Input() searchOnOpen = false;
  @Input() selectColumn = false;

  @Output() public row: EventEmitter<any> = new EventEmitter();
  @Output() public search: EventEmitter<any> = new EventEmitter();
  @Output() public reset: EventEmitter<any> = new EventEmitter();

  public rows$: Observable<any> = of(null);
  public showLebelInput = true;
  protected criteria: any;
  private defaultValue: any;

  constructor(private application: ApplicationService, public translateService: TranslateService) {}
  bankOrCompanyNameEn = "Bank or Company Name";
  bankOrCompanyNameSp = "Nombre del banco o compañía";
  bankIdEn = "Bank ID";
  bankIdSp = "ID del banco";
  addressEn = "Address";
  addressSp = "Dirección";
  emptyMessage;
  emptyMessageEn = "No data to display";
  emptyMessageSp = "no hay información para mostrar"

  canFindClick(selected, values?){
    sessionStorage.bankName = '';
    this.onRow(selected, values);
  }

  onRow({selected}, values?) {
    if(selected) {
      if (this.selectColumn && values && values.length > 0) {
        values.forEach(val => val.select = false)
        selected[0].select = true
        sessionStorage.removeItem("isnext");
      }
      this.row.emit(head(selected));
    }else {
      this.row.emit(null);
      values.forEach(val => val.select = false)
      sessionStorage.setItem("isnext","false");
    }
    
  }

  onReset() {
    this.onClose();
    this.reset.emit({});
  }

  toggleShow(show) {
    this.showSearch = show;
  }

  onOpen() {
    this.showLebelInput = false;
    this.showSearchButton = false;
    this.toggleShow(true);
    if (this.searchOnOpen) {
      this.onSearch();
    }
  }

  onClose() {
    this.showLebelInput = true;
    this.showSearchButton = true;
    this.rows$ = of(null);
    this.toggleShow(false);
  }

  onSearch() {
    //this.rows$ = this.application.resourceSearch(this.applicationNumber, this.criteria, this.resource).pipe(first());
    sessionStorage.setItem("isnext","false");
    this.rows$ = this.application.getBankDetails(this.criteria);
    this.search.emit(null);
  }

  ngOnInit() {
    const defaultLanguage = this.translateService.getDefaultLang();
      if (defaultLanguage !== 'en') { 
        for (let i = 0; this.columns.length > i; i++) { 
          if(this.columns[i].name === this.bankOrCompanyNameEn) {
            this.columns[i].name = this.bankOrCompanyNameSp
          } else if( this.columns[i].name === this.bankIdEn) {
            this.columns[i].name = this.bankIdSp
          } else if (this.columns[i].name === this.addressEn) {
            this.columns[i].name = this.addressSp
          }
        }
        this.emptyMessage = this.emptyMessageSp;
      } else {
        for (let i = 0; this.columns.length > i; i++) {
          if( this.columns[i].name === this.bankOrCompanyNameSp) {
            this.columns[i].name = this.bankOrCompanyNameEn
          } else if( this.columns[i].name === this.bankIdSp) {
            this.columns[i].name = this.bankIdEn
          } else if (this.columns[i].name === this.addressSp) {
            this.columns[i].name = this.addressEn
          }
        }
        this.emptyMessage = this.emptyMessageEn;
      }

      this.translateService.onDefaultLangChange.subscribe((res) => {
        if (res.lang !== 'en') { 
          for (let i = 0; this.columns.length > i; i++) { 
            if(this.columns[i].name === this.bankOrCompanyNameEn) {
              this.columns[i].name = this.bankOrCompanyNameSp
            } else if( this.columns[i].name === this.bankIdEn) {
              this.columns[i].name = this.bankIdSp
            } else if (this.columns[i].name === this.addressEn) {
              this.columns[i].name = this.addressSp
            }
          }
          this.emptyMessage = this.emptyMessageSp;
        } else {
          for (let i = 0; this.columns.length > i; i++) {
            if( this.columns[i].name === this.bankOrCompanyNameSp) {
              this.columns[i].name = this.bankOrCompanyNameEn
            } else if( this.columns[i].name === this.bankIdSp) {
              this.columns[i].name = this.bankIdEn
            } else if (this.columns[i].name === this.addressSp) {
              this.columns[i].name = this.addressEn
            }
          }
          this.emptyMessage = this.emptyMessageEn;
        }
      });
     
  
    this.defaultValue = value(this.searchGroup);

    valueChanges(this.searchGroup)
      .pipe(startWith(this.defaultValue))
      .subscribe(criteria => assign(this, { criteria }));
  }
}
