import { compose, map, evolve } from 'ramda';
import { BurialComponent } from './burial.component';

import Vs from '@app/services/validators/validators.service';
import { resourcesCoOwnerGroupRowCreator, sectionConfiguration, addressCreator } from '@app/helpers/models.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { Validators } from '@angular/forms';
import { notYes } from '@app/helpers/tayn.helpers';
import { trudy } from '@app/helpers/function.helpers';
import { disableGet } from '@app/helpers/able.helpers';
import { GlobalVars } from '@app/models/tables.model';
import { dropdownValidator, totalValidator } from '@app/helpers/validators.helpers';
import burialLabels from './burial-labels';

const formValidation = (builder, recordData, params) => {
  const burial = lookIn(recordData);
  const jointOwnership = burial(['jointOwnership']);
  const bankName = burial(['bankSearch', 'bankName']);
  const institutionId = burial(['bankSearch', 'institutionId']);
  const locationId = burial(['bankSearch', 'locationId']);
  const assetAddress = addressCreator(burial(['assetAddress']));
  const assetAddressFlag = burial(['assetAddressFlag']);
  assetAddress.state = [assetAddress.state, Vs.required],

  delete assetAddress.addrAaAeAp;
  delete assetAddress.addrApoFpo;
  delete assetAddress.county;

  const helpers = builder.group({
    assetAddressFlag: [assetAddressFlag],
    search: builder.group({
      state: 'TN',
      bankName: [bankName, [Vs.maxLength(140), Vs.invalidName]],
      zipCode: ['', Vs.zipCode],
      institutionId: '',
      locationId: '',
    }),
  });

  const resourcesCoOwnerGroup = compose(
    (control: any) => builder.group(control),
    resourcesCoOwnerGroupRowCreator,
  );
  
  const coOwnersData = burial(['jointOwnershipInformation']) && burial(['jointOwnershipInformation']).length > 0 ? burial(['jointOwnershipInformation']) : [{
    jointOwnerIndividual: '',
    outOfHouseHoldname: '',
    jointOwnerPercentage: '',
  }];
  
  const jointOwnershipInformation = map(resourcesCoOwnerGroup, coOwnersData.map((item: any) => {
    const individual = params.individuals.find((indi: any) => +indi.identifier === item.jointOwnerIndividual.individualId);
    let jointOwnerIndividual: any;
    if (burial(['jointOwnershipInformation'])) {
      jointOwnerIndividual = individual ? { value : individual.name.fullName, code : individual.identifier } : GlobalVars.outSideOption;
    }
    return { ...item, jointOwnerIndividual };
  }));

  const data = builder.group(
    {
      assetType: [burial(['assetType']), Vs.required],
      assetAmount: [burial(['assetAmount']), Vs.currency],
      owedAmount: [burial(['owedAmount']), Vs.currency],
      bankSearch: builder.group({
        institutionId: institutionId,
        locationId: locationId,
        bankName: [bankName, [Vs.invalidName]]
      }),
      redesignatedFor: [burial(['redesignatedFor']), [Vs.required]],
      jointOwnership: [jointOwnership, Vs.required],
      assetAddress: builder.group(assetAddress),
      jointOwnershipInformation: builder.array(jointOwnershipInformation)
    }, { validators: Validators.compose([totalValidator, dropdownValidator])});

  trudy(() => disableGet('jointOwnershipInformation', data), notYes(jointOwnership));
  // trudy(() => disableGet('assetAddress', data), !isNil(bankName));

  return { helpers, data };
};

const transformIndividulaObj = (value) => ({
  name : value?.code === 'EMPTY' ? '' : value?.value,
  individualId: value?.code === 'EMPTY' ? 0 : value?.code
});

const postObject = evolve({
  assetAmount: Number,
  owedAmount: Number,
  jointOwnershipInformation: map(evolve({
    jointOwnerIndividual : transformIndividulaObj
  }))
});


export default sectionConfiguration(
  BurialComponent,
  formValidation,
  ['BURIALRESOURCETYPECD', 'BURIALRESDESIGNATEDFOR', 'YESNO', 'STATE'],
  [
    { name: 'Owner', pipe: 'individual' },
    { name: 'Burial Type', prop: 'data.assetType.value' },
    { name: 'Total Value', prop: 'data.assetAmount', pipe: 'currency' },
  ],
  {...{}, ...burialLabels},
  {
    postObject
  }
);
