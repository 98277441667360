import { ProcessService } from './../../../services/process.service';
import { Component, OnInit } from '@angular/core'
import { StateService } from '@app/services/state/state.service'
import { Router, ActivatedRoute } from '@angular/router'
import { ApiService } from '@app/gateway/services/api.service';
import { DatePipe } from '@angular/common';
import { UserProfileService } from '../user-profile.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'nh-coverage-details',
  templateUrl: './coverage-details.component.html',
})
export class CoverageDetailsComponent implements OnInit {
  appNum;
  vals = [];
  fullName;
  response;
  memberInfo;
  coverageInfo;
  tplResponse;
  appealSummary = [];
  hearingInfo = false;
  linkMyCase = false;
  dateOfBirth: String;
  covBeginDate: String;
  covEndDate: String;
  isAppNum = false;
  indivId;
  renewalDueDate: any;
  replaceText = 'Renewalduedate';
  caseNumPop = 'COVERAGE_TXT_TOOL_TIP';
  coverageStatustooltip = 'COVERAGE_STATUS_TOOL_TIP';
  today = new Date();
  displayDetails = true;
  coverageEnd: Date;
  tiles = [
    { name: 'readLetterOnline',
    header: 'COVERAGE_DTL_1',
    text: "COVERAGE_txt_1",
    button: 'WELCOME_BTN',
    click: () => this.toCommunications(),
    priority: 3 },
    { name: 'changeToElectronicLetters',
    header: 'COVERAGE_DTL_2',
    text: "COVERAGE_txt_2",
    priority: 3 },
    { name: 'applyForCoverage',
    header: 'COVERAGE_DTL_3',
    text: "COVERAGE_txt_3",
    button: 'WELCOME_DESC3',
    click: () => this.applyNow(), priority: 1 },
    { name: 'caseError', header: 'COVERAGE_DTL_4',
    text: "COVERAGE_txt_4",
    priority: 1 },
    { name: 'siteError', header: 'COVERAGE_DTL_5',
    text: "COVERAGE_txt_5",
    priority: 1 },
    { name: 'noCoverageInformation', header: 'COVERAGE_DTL_6',
    text: "COVERAGE_txt_6",
    priority: 1 },
    { name: 'bccTreatment', header: 'COVERAGE_DTL_7',
    text: "COVERAGE_txt_7",
    button: 'Upload', click: () => this.upload(), priority: 3 },
    { name: 'legalGuardian', header: 'COVERAGE_DTL_8',
    text: "COVERAGE_txt_8",
    button: 'Upload', click: () => this.upload(), priority: 2 },
    { name: 'renewalMode', header: 'COVERAGE_DTL_9',
    text: "COVERAGE_txt_9",
    priority: 1 },
    { name: 'uploadDoc', header: 'COVERAGE_DTL_10',
    text: "COVERAGE_txt_10",
    button: 'Upload Proof', click: () => this.upload(), priority: 2 },
    { name: 'linkMyCase', header: 'COVERAGE_DTL_11',
    text: "COVERAGE_txt_11",
    button: 'Link My Case', click: () => this.linkCase() }
];
  endDate;
  startDate;
  healthPlanResponse=null;
  isHeadOfHouseHold;

  constructor(
    private userProfileService: UserProfileService,
    public stateService: StateService,
    public activatedRoute: ActivatedRoute,
    public app: ApiService,
    public router: Router,
    public process: ProcessService,
    private datePipe: DatePipe,
    public translateService: TranslateService,
    private titleService: Title
  ) {
    this.indivId = sessionStorage.getItem('individualId');
    this.titleService.setTitle('Coverage Details');
    this.userProfileService.getBenefitDashboard(this.indivId).subscribe((res: any) => {
      if (res) {
          this.response = { ...res };
          if (res.cmbSummaryResponse && res.cmbSummaryResponse.caseSummaryResponse.length > 0) {
              const summary = [...res.cmbSummaryResponse.caseSummaryResponse];
              summary.forEach(sum => {
                this.renewalDueDate = this.datePipe.transform(sum.renewalDueDate, "MM/dd/yyyy");
              })
            }
          }
        })
    }

  onLoad(response) {
    const { individualId, caseNumber, coe, coeStatus } = response;
    const url = `checkmybenefits/cmbDetails/indv/${individualId}/case/${caseNumber}/coe/${coe}/coeStatus/${coeStatus}`;
    const url2 = `checkmybenefits/caseNum/${caseNumber}/coe/${coe}`;
    this.app.get(url, true).subscribe((resp) => {

      if (resp) {
        this.response = resp;
        const { householdMemberInfo, tenncareCoverageInfo, tplResponselist, indvAppealSummary, mcoInfoList } = this.response;
        this.memberInfo = householdMemberInfo;
        this.isHeadOfHouseHold = this.memberInfo.householdMemberInfo ?.hoHSW === 'Y' ? true: false;
        this.healthPlanResponse = mcoInfoList;
        if(this.healthPlanResponse){
          this.healthPlanResponse.sort((a, b) => a.endDate - b.endDate);
          this.healthPlanResponse.forEach(row=>{
            row.startDate = row.startDate ? this.datePipe.transform(row.startDate, "MM/dd/yyyy") :'N/A';
            row.endDate = row.endDate ? this.datePipe.transform(row.endDate, "MM/dd/yyyy") :'N/A';

            if(row.endDate && row.endDate !== 'N/A'){
              const year = new Date(this.datePipe.transform(row.endDate, "MM/dd/yyyy")).getFullYear();
              if(year === 2299 ){
                row.endDate = 'N/A';
              }
            }
            
          })
        }
        this.dateOfBirth = this.datePipe.transform(this.memberInfo.dob, "MM/dd/yyyy");
        this.coverageInfo = tenncareCoverageInfo;
        this.covBeginDate = this.datePipe.transform(this.coverageInfo.coverageBeginDate, "MM/dd/yyyy");
        this.covEndDate = this.datePipe.transform(this.coverageInfo.coverageEndDate, "MM/dd/yyyy");
        resp.tenncareCoverageInfo.kbPremiumResponse.map((kbDate)=>{
          if(kbDate.premiumBeginDate) {
            kbDate.premiumBeginDate = this.datePipe.transform(kbDate.premiumBeginDate, "MM/dd/yyyy");
          }
          if(kbDate.premiumEndDate) {
            kbDate.premiumEndDate = this.datePipe.transform(kbDate.premiumEndDate, "MM/dd/yyyy");
          }
      })
        this.coverageEnd = new Date(this.coverageInfo.coverageEndDate);

        this.tplResponse = [...tplResponselist];
        this.tplResponse.forEach(row=>{
          row.tplCoverageBeginDate = row.tplCoverageBeginDate ? this.datePipe.transform(row.tplCoverageBeginDate, "MM/dd/yyyy"): '';
          row.tplCoverageEndDate = row.tplCoverageEndDate ? this.datePipe.transform(row.tplCoverageEndDate, "MM/dd/yyyy") :'';
        })

        if (indvAppealSummary) {
          this.appealSummary = [...indvAppealSummary];
          this.hearingInfo = this.appealSummary[0].appealTypeResponse.some(val => val.formatedHearingDate)
        }

        const { firstName, lastName } = this.memberInfo;
        this.fullName = firstName + ' ' + lastName;
      } else {
        this.app.get(url2, true).subscribe((data) => {
          if (data) {
            this.response = data;
            const { birthDate, indvAppealSummary, firstName, lastName, clientId, caseNum, cvrgBeginDt, cvrgEndDt, eligibleStatus, coeDesc } = this.response;
            this.memberInfo = data;
            this.memberInfo.firstName = firstName;
            this.memberInfo.lastName = lastName;
            this.memberInfo.indvId = clientId;
            this.memberInfo.caseNum = caseNum;
            this.coverageInfo = {};
            this.dateOfBirth = birthDate.monthValue + "/" + birthDate.dayOfMonth + "/" + birthDate.year;
            if (cvrgBeginDt) {
              this.coverageInfo.covBeginDate = cvrgBeginDt;
            }
            if (cvrgEndDt) {
              this.coverageInfo.covBeginDate = cvrgEndDt;
              this.coverageInfo.coverageEndDate =  cvrgEndDt;
              this.coverageInfo.coverageEndDate =  cvrgEndDt;
            }
            if (eligibleStatus) {
              this.coverageInfo.edgStatus = eligibleStatus;
            }
            if (coeDesc) {
              this.coverageInfo.highLevelProgram = coeDesc;
            }
            if (indvAppealSummary) {
              this.appealSummary = [...indvAppealSummary];
              this.hearingInfo = this.appealSummary[0].appealTypeResponse.some(val => val.hearingDate)
            }
            this.fullName = this.memberInfo.firstName + ' ' + this.memberInfo.lastName;
          }
        })
      }
    })
    this.getActions();
  }

  getActions () {
    const flags = this.process.getFlagData();
    const indivId = sessionStorage.getItem('individualId');
    let actions = []
    if (!indivId || indivId === '0' || indivId == 'undefined' || indivId == 'null') {
        this.linkMyCase = true;
        const tile = this.tiles.filter(t => t.name === 'linkMyCase' || t.name === 'applyForCoverage')
        actions = [...tile]
        this.vals = [...actions];
    } else if (flags && flags.errorCode == 1) {
        const third = this.tiles.filter(t => t.name === 'caseError');
        actions.push(third[0])
        this.vals = [...actions];
    } else if (flags && flags.errorCode == 500) {
      const tile = this.tiles.filter(t => t.name === 'siteError')
      this.vals.push(tile[0]);
    } else {
        const trueFlags = this.tiles.filter(tile => flags[tile.name] === true);
        actions = [...trueFlags];
        if (actions.length > 2) {
            actions = actions.sort((val1, val2) => val1.priority - val2.priority).slice(0, 2)
        }
        this.vals = [...actions];
    }
  }

  ngOnInit(): void {
    this.appNum = sessionStorage.getItem('appNum');
    this.isAppNum = this.appNum === 'null' || this.appNum === '0' || this.appNum === 0 ? false : true;
    this.activatedRoute.queryParams.subscribe(res => this.onLoad(res));
  }

  renewCoverage() {
    this.router.navigateByUrl('/application/' + this.appNum);
  }

  continueApp() {
    this.router.navigateByUrl('/application/' + this.appNum);
  }

  applyNow() {
    this.router.navigate(['case', 'atglance']);
  }

  upload() {
    this.router.navigate(['user-profile/my-documents', 'documents-we-need'])
  }

  linkCase() {
    this.router.navigate(['user-profile', 'case-link'])
  }

  toCommunications() {
    this.router.navigateByUrl('/user-profile/communication-preferences');
  }

}
