
<div class="container">

    <div class="container">
        <div class="row">
            <div class="card" style="width:100%;">
                <div class="card-body">
                    <p>{{'APPEALS_DASHBOARD_STATIC_TEXT' | translate }}</p>
                    <div *ngIf="appealDetailsRows?.length">
                        <h5 class="section-header">{{'APPEAL_DETAILS' | translate }}</h5>
                        <hr>
                        <nh-alert type="warning" [isOpen]="uploadProofTrue">
                            <div class="row">
                                <div class="icon">
                                    <img src="../../../../assets/images/Icons/⚛️ icons-warning-outline.svg"
                                        alt="danger" />
                                </div>
                                <div class="message">
                                    <span [innerHtml]="'UPLOAD_APPEAL_PROOF' | translate"></span>
                                </div>
                            </div>
                        </nh-alert>
                        <div class="d-none d-lg-block">
                            <ngx-datatable class='bootstrap striped' #table [rows]='appealDetailsRows' [limit]="5" columnMode="force"
                      [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
                                <ngx-datatable-column [cellClass]="'my-auto'" [name]="col.name | translate" [prop]="col.prop"
                          *ngFor="let col of appealDetailsColumns">
                                </ngx-datatable-column>
                                <ngx-datatable-column [cellClass]="'my-auto'" [width]="160">
                                    <ng-template let-column="column" ngx-datatable-header-template>{{AppealsAction | translate}}
                                        <nh-popover [iconMode]="'transparent'" [content]="'Appeal_Action_Info' | translate"></nh-popover>
                                    </ng-template>
                                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                                        <div *ngIf="row && ((row.uploadAppealAiProof !== null && row.uploadAppealAiProof === true) || (row.uploadAppealProofVfd !== null && row.uploadAppealProofVfd === true))">
                                            <button class="btn btn-block btn-outline-secondary-proof" (click)="uploadProof(row)"><span>{{'UPLOAD_INFO' | translate}}</span></button>
                                        </div>
                                        <div *ngIf="row && row.fileContinuance !== null && row.fileContinuance === true"
                                            class="p-0 hover-red">
                                            <a class="p-0 hover-red btn btn-link text-center" style="overflow-wrap: anywhere;" (click)="fileContinuance(row)" >
                                                <span style="font-size: 14px">{{'FILE_A_CONTINUANCE' |  translate}}</span></a>
                                        </div>
                                        <div *ngIf="row && row.fileWithrawal !== null && row.fileWithrawal === true"
                                            class="p-0 hover-red">
                                            <a class="p-0 hover-red btn btn-link text-center" style="overflow-wrap: anywhere;" (click)="fileWithdrawal(row)">
                                                <span style="font-size: 14px">{{'FILE_A_WITHDRAWAL' | translate}}</span></a>
                                        </div><br>
                                    </ng-template>
                                </ngx-datatable-column>
                            </ngx-datatable>
                        </div>
                        <div class="nh-table-mobile d-lg-none">
                            <div class="table-row mb-3 pb-3" *ngFor="let row of appealDetailsRows">
                                <div class="mobile-table-header">{{appealDetailsColumns[0].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.memberName}}</div>
                                <br>
                                <div class="mobile-table-header">{{appealDetailsColumns[1].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.appealNumber}}</div>
                                <br>
                                <!-- <div class="mobile-table-header">{{appealDetailsColumns[2].name | translate}}</div>
                          <hr>
                          <div class="mobile-table-data">{{row.caseNumber}}</div>
                          <br> -->
                                <div class="mobile-table-header">{{appealDetailsColumns[2].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.appealFilingDate}}</div>
                                <br>
                                <div class="mobile-table-header">{{appealDetailsColumns[3].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.appealReasonValue}}</div>
                                <br>
                                <div class="mobile-table-header">{{appealDetailsColumns[4].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.appealStatusValue}}</div>
                                <br>
                                <!-- <div class="mobile-table-header">{{appealDetailsColumns[5].name | translate}}</div>
                          <hr>
                          <div class="mobile-table-data">{{row.hearingDate}}</div>
                          <br> -->
                                <div class="mobile-table-header">{{appealDetailsColumns[5].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.coverageType}}</div>
                                <br>
                                <div class="mobile-table-header d-inline">{{'AppealsAction' | translate}}</div>
                                <nh-popover [content]="'Appeal_Action_Info' | translate"></nh-popover>
                                <hr>
                                <div *ngIf="row && ((row.uploadAppealAiProof !== null && row.uploadAppealAiProof === true) || (row.uploadAppealProofVfd !== null && row.uploadAppealProofVfd === true))">
                                    <button class="btn btn-block btn-outline-secondary-proof"
                                        (click)="uploadProof(row)"><span>{{'UPLOAD_INFO' | translate}}</span></button>
                                </div><br>
                                <div *ngIf="row && row.fileContinuance !== null && row.fileContinuance === true"
                                    class="p-0 hover-red" style="overflow: auto">
                                    <a class="p-0 hover-red btn btn-link text-center" (click)="fileContinuance(row)">
                                        <span>{{'FILE_A_CONTINUANCE' |
                                            translate}}</span></a>
                                </div><br>
                                <div *ngIf="row && row.fileWithrawal !== null && row.fileWithrawal === true"
                                    class="p-0 hover-red" style="overflow: auto">
                                    <a class="p-0 hover-red btn btn-link text-center" (click)="fileWithdrawal(row)">
                                        <span>{{'FILE_A_WITHDRAWAL' |
                                            translate}}</span></a>
                                </div><br>
                            </div>
                        </div>
                    </div>
                    <div class="d-none d-lg-block">
                        <div class="row" style="padding-left: 15px;">
                            <div class="p-1">
                                <button (click)="startApp('APL')" class="btn btn-secondary btn-search" [disabled]="!enableFileAppealButton">
                                    {{'FILE_A_NEW_APPEAL' | translate}}
                                </button>
                            </div>
                            <div *ngIf="showViewMyLetters" class="p-1">
                                <button (click)="appealLetters()" class="btn btn-secondary btn-search" [disabled]="false">
                                    {{'VIEW_MY_APPEAL_LETTERS' | translate}}
                                </button>
                            </div>
                            <div *ngIf="!showViewMyLetters" class="p-5 d-none d-lg-block"></div>
                            <div *ngIf="!showViewMyLetters" class="p-5 d-none d-lg-block"></div>
                            <div *ngIf="!showViewMyLetters && translatedLang !== 'en'" class="p-4 d-none d-lg-block"></div>
                            <div *ngIf="translatedLang === 'en'" class="p-5 d-none d-lg-block"></div>
                            <div *ngIf="translatedLang === 'en'" class="p-5 d-none d-lg-block"></div>
                            <div class="p-1" *ngIf="clickViewAppeals && pastAppealDetailsRows?.length">
                                <button (click)="clickedViewAppeals()" class="btn btn-block btn-outline-secondary"
                                    [disabled]="!clickViewAppeals">
                                    {{'VIEW_PAST_APPEALS' | translate}}
                                </button>
                            </div>
                            <div style="margin-top: 10px" *ngIf="clickViewAppeals && pastAppealDetailsRows?.length">
                                <nh-popover content="{{'VIEW_PAST_APPEALS_POPUP' | translate}}"></nh-popover>
                            </div>
                            <div class="p-1" *ngIf="clickHideAppeals && pastAppealDetailsRows?.length">
                                <button (click)="clickedHideAppeals()" class="btn btn-block btn-outline-secondary"
                                    [disabled]="!clickHideAppeals">
                                    {{'HIDE_PAST_APPEALS' | translate}}
                                </button>
                            </div>
                            <div style="margin-top: 10px" *ngIf="clickHideAppeals && pastAppealDetailsRows?.length">
                                <nh-popover content="{{'HIDE_PAST_APPEALS_POPUP' | translate}}"></nh-popover>
                            </div>
                        </div>
                    </div>
                    <div class="nh-table-mobile d-lg-none">
                        <div class="p-1">
                            <button (click)="startApp('APL')" class="btn btn-secondary btn-search" [disabled]="!enableFileAppealButton">
                                {{'FILE_A_NEW_APPEAL' | translate}}
                            </button>
                        </div>
                        <div *ngIf="showViewMyLetters" class="p-1">
                            <button (click)="appealLetters()" class="btn btn-secondary btn-search" [disabled]="false">
                                {{'VIEW_MY_APPEAL_LETTERS' | translate}}
                            </button>
                        </div>
                        <div *ngIf="translatedLang === 'en'" class="p-5 d-none d-lg-block"></div>
                        <div *ngIf="translatedLang === 'en'" class="p-5 d-none d-lg-block"></div>
                        <div class="row p-3" *ngIf="clickViewAppeals && pastAppealDetailsRows?.length">
                            <button (click)="clickedViewAppeals()" class="btn btn-block btn-outline-secondary"
                                [disabled]="!clickViewAppeals">
                                {{'VIEW_PAST_APPEALS' | translate}}
                            </button>
                            <div style="margin-top: 10px" *ngIf="clickViewAppeals && pastAppealDetailsRows?.length">
                                <nh-popover style="margin-top: 10px; margin-left: 10px" content="{{'VIEW_PAST_APPEALS_POPUP' | translate}}"></nh-popover>
                            </div>
                        </div>
                        <div class="row p-3" *ngIf="clickHideAppeals && pastAppealDetailsRows?.length">
                            <button (click)="clickedHideAppeals()" class="btn btn-block btn-outline-secondary"
                                [disabled]="!clickHideAppeals">
                                {{'HIDE_PAST_APPEALS' | translate}}
                            </button>
                            <div style="margin-top: 10px; margin-left: 10px" *ngIf="clickHideAppeals && pastAppealDetailsRows?.length">
                                <nh-popover content="{{'HIDE_PAST_APPEALS_POPUP' | translate}}"></nh-popover>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!clickViewAppeals">
                        <h5 class="section-header">{{'PAST_APPEAL_DETAILS' | translate }}</h5>
                        <hr>
                        <div class="d-none d-lg-block">
                            <ngx-datatable class='bootstrap striped' #table [rows]='pastAppealDetailsRows' [limit]="5" columnMode="force"
                      [headerHeight]='"auto"' [footerHeight]='50' [rowHeight]='"auto"'>
                                <ngx-datatable-column [cellClass]="'my-auto'" [name]="col.name | translate" [prop]="col.prop"
                          *ngFor="let col of pastAppealDetailsColumns">
                                </ngx-datatable-column>
                            </ngx-datatable>
                        </div>
                        <div class="nh-table-mobile d-lg-none">
                            <div class="table-row mb-3 pb-3" *ngFor="let row of pastAppealDetailsRows">
                                <div class="mobile-table-header">{{pastAppealDetailsColumns[0].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.memberName}}</div>
                                <br>
                                <div class="mobile-table-header">{{pastAppealDetailsColumns[1].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.appealNumber}}</div>
                                <br>
                                <div class="mobile-table-header">{{pastAppealDetailsColumns[2].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.caseNumber}}</div>
                                <br>
                                <div class="mobile-table-header">{{pastAppealDetailsColumns[3].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.appealFilingDate}}</div>
                                <br>
                                <div class="mobile-table-header">{{pastAppealDetailsColumns[4].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.appealReasonValue}}</div>
                                <br>
                                <div class="mobile-table-header">{{pastAppealDetailsColumns[5].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.pastAppealStatus}}</div>
                                <br>
                                <div class="mobile-table-header">{{pastAppealDetailsColumns[6].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.hearingDate}}</div>
                                <br>
                                <div class="mobile-table-header">{{pastAppealDetailsColumns[7].name | translate}}</div>
                                <hr>
                                <div class="mobile-table-data">{{row.coverageType}}</div>
                                <br>
                            </div>
                            <p *ngIf="!pastAppealDetailsRows?.length" class="mb-5">No data to display</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>