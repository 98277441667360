import { Injectable, OnInit } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { equals } from 'ramda';

@Injectable()
export class SpinnerService implements OnInit {
  private requestsPending = 0;
  private spinnerStatus: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  ngOnInit() {
    this.show();
  }

  get status(): Observable<boolean> {
    return this.spinnerStatus.asObservable().pipe(share());
  }

  is(n: number): boolean {
    return equals(this.requestsPending, n);
  }

  public increment() {
    this.requestsPending += 1;
    return this.show();
  }

  public decrement() {
    if (this.requestsPending !== 0) {
      this.requestsPending -= 1;
    }
    return this.is(0) && this.hide();
  }

  private show(): void {
    this.spinnerStatus.next(true);
  }

  private hide(): void {
    this.spinnerStatus.next(false);
  }
}
