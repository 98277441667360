<ng-container>
  <div class="form-row justify-content-md-start justify-content-lg-start" *ngIf="showSearchButton">
    <div class="col-12 col-md-3 pb-2">
      <button type="button" class="btn btn-secondary form-control" (click)="onOpen()">
        {{'SEARCH' | translate }} <i class="fa fa-pencil">&nbsp;&nbsp;</i>
      </button>
    </div>
    <div class="col-12 col-md-2 pb-2">
      <button type="button" class="btn btn-primary form-control" (click)="onReset()">
        Remove {{ keyword }}
        <i class="fa fa-times">&nbsp;&nbsp;</i>
      </button>
    </div>
  </div>

  <ng-content select="[search-field]" *ngIf="showLebelInput"></ng-content>

  <div class="mb-3" *ngIf="showSearch">
    <!-- First paragraph -->
    <ng-content select="[search-intro]"></ng-content>

    <section [formGroup]="searchGroup">
      <!-- Form contents -->
      <div>
        <ng-content select="[search-form]"></ng-content>
      </div>

      <!-- 2 CTA buttons -->
      <div class="form-row justify-content-start">
        <div class="col-sm-12 col-md-2 py-2">
          <button class="btn btn-block btn-secondary" (click)="onSearch()" [disabled]="!searchGroup.valid">
            {{'SEARCH' | translate }}&nbsp; <i class="fa fa-search"></i>
          </button>
        </div>
        <div class="col-12 col-md-3 py-2 py-md-0 order-md-2" *ngIf="keyword != 'bank'">
          <button class="btn btn-block btn-primary" (click)="onClose()">
            Close&nbsp; <i class="fa fa-times"></i>
          </button>
        </div>
      </div>
    </section>

    <br />

    <section *ngIf="(rows$ | async) as rows">
      <!-- Second paragraph only changes a keyword, the rest is the same for all components -->

      <!-- Table Desktop -->
      <div class="d-none d-lg-block">
        <ngx-datatable class="bootstrap table-hover" columnMode="force" rowHeight="auto" selectionType="single"
          [headerHeight]="50" [footerHeight]="50" limit="5" [columns]="columns" [rows]="rows"
          (select)="onRow($event, rows)" [messages]="{emptyMessage: this.emptyMessage}">
          <ngx-datatable-column [name]="col.name" [prop]="col.prop" *ngFor="let col of columns">
          </ngx-datatable-column>
          <ngx-datatable-column name="{{'SELECT' | translate }}" *ngIf="selectColumn">
            <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
              <span>
                <span *ngIf="!row.select" class="un-selected-circle"><img
                    src="assets/images/Icons/bank-company-unselected.svg" alt="unselected"></span>
                <span *ngIf="row.select" class="select-cirlce"><img src="assets/images/Icons/bank-company-selected.svg"
                    alt="selected"></span>
              </span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>

        <br>

        <div class="row pl-3">
          <button (click)="canFindClick($event, rows)" class="btn btn-primary-search col-md-3">{{'CANT_FIND_BANK' | translate }}
            <img src="../../assets/images/close-icon.svg" alt="close-icon" />
          </button>
        </div>
      </div>

      <!-- Table Mobile -->
      <div class="nh-table-mobile d-lg-none">
        <div class="table-row mb-3 pb-3" *ngFor="let content of rows; index as i">
          <div *ngFor="let head of columns">
            <div class="mobile-table-header" [innerHtml]="head.name | sanitize"></div>
            <hr>
            <div class="mobile-table-data"
              [innerHTML]="content | path: head.prop | loader: head.pipe : head.args | sanitize"></div>
            <br>
          </div>
          <button *ngIf="!content.select" class="btn btn-secondary btn-sm select-{{ i }} w-100"
            (click)="onRow({ selected: [content] }, rows)">{{'SELECT' | translate }}</button>
          <button *ngIf="content.select" class="btn btn-secondary select-{{ i }} w-100">SELECTED</button>
        </div>
        <div class="row px-3">
          <button (click)="canFindClick($event, rows)" class="btn btn-primary-search w-100">{{'CANT_FIND_BANK' | translate }}
            <img src="../../assets/images/close-icon.svg" alt="close-icon" />
          </button>
        </div>
      </div>
    </section>
  </div>
</ng-container>