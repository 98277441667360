<div class="warning-message">
<!-- comment test-->
<alert
  type="{{ type }} {{ type }}-box"
  [dismissible]="dismissible"
  [isOpen]="isOpen"
  (onClosed)="onClose()"
>
  <header *ngIf="heading" class="pb-2">
    <h5 class="alert-heading" [innerHTML]="heading"></h5>
  </header>
  <section>
      <ng-content></ng-content>
  </section>
  <footer *ngIf="confirm" class="pt-2 text-right">
    <button
      class="col-12 col-md-3 col-lg-2 btn btn-inherit btn-sm"
      (click)="onClose()"
    >
      <i class="fa fa-{{ icon }}" *ngIf="icon"></i> {{ confirm }}
    </button>
  </footer>
</alert>
</div>
