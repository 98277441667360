import { or, evolve, compose, map } from 'ramda';
import { isNil } from 'ramda';

import { VehicleComponent } from './vehicle.component';

import Vs from '@app/services/validators/validators.service';
import { resourcesCoOwnerGroupRowCreator, sectionConfiguration } from '@app/helpers/models.helpers';
import { notYes } from '@app/helpers/tayn.helpers';
import { lookIn } from '@app/helpers/flipped.helpers';
import { disableGet } from '@app/helpers/able.helpers';
import { trudy } from '@app/helpers/function.helpers';
import { GlobalVars } from '@app/models/tables.model';
import { Validators } from '@angular/forms';
import {totalValidator, dropdownValidator} from '@app/helpers/validators.helpers';
import vehicleLabels from './vehicle-labels';

const formValidation = (builder, recordData, params ) => {
  const vehicle = lookIn(recordData);
  const jointOwnership = vehicle(['jointOwnership']);

  const resourcesCoOwnerGroup = compose(
    (control: any) => builder.group(control),
    resourcesCoOwnerGroupRowCreator,
  );

  const coOwnersData = (vehicle(['jointOwnershipInformation']) && vehicle(['jointOwnershipInformation']).length >0) ? vehicle(['jointOwnershipInformation']) : [{
    jointOwnerIndividual: '',
    outOfHouseHoldname: '',
    jointOwnerPercentage: '',
    jointOwnerSequnceNumber: ''
  }];

  const jointOwnershipInformation = map(resourcesCoOwnerGroup, coOwnersData.map((item: any) => {
    const individual = params.individuals.find((indi: any) => +indi.identifier === item.jointOwnerIndividual.individualId);
    let jointOwnerIndividual: any;
    if (vehicle(['jointOwnershipInformation'])) {
      jointOwnerIndividual = individual ? { value : individual.name.fullName, code : individual.identifier } : GlobalVars.outSideOption;
    }
    return { ...item, jointOwnerIndividual };
  }));

  const data = builder.group(
    {
      assetType: [vehicle(['assetType']), [Vs.required]],
      vehicleYear: [vehicle(['vehicleYear']), [Vs.maxLength(4), Vs.vehicleYear]],
      vehicleMake: [vehicle(['vehicleMake']), [Vs.maxLength(25), Vs.unit]],
      vehicleModel: [vehicle(['vehicleModel']), [Vs.maxLength(30), Vs.unit]],
      vehicleUsage: [vehicle(['vehicleUsage']), [Vs.required]],
      assetAmount: [vehicle(['assetAmount']), [Vs.currency, Vs.required]],
      owedAmount: [vehicle(['owedAmount']), Vs.currency],
      jointOwnership: [jointOwnership, Vs.required],
      jointOwnershipInformation: builder.array(jointOwnershipInformation)
    }, { validators: Validators.compose([totalValidator, dropdownValidator])});

  trudy(() => disableGet('jointOwnershipInformation', data), notYes(jointOwnership));

  return { data };
};

const transformIndividulaObj = (value) => ({
  name : value?.code === 'EMPTY' ? '' : value?.value,
  individualId: value?.code === 'EMPTY' ? 0 : value?.code
});

const amount = (amount) => {
  let s;
  if (!isNil(amount)) {
    s = amount.toString();
  }
  if (amount==0 || isNil(amount)) {
    return Number.parseFloat('0').toFixed(2);
  } else if (s.indexOf('.') < 0) {
    return Number.parseFloat(amount).toFixed(2);
  }
  return Number.parseFloat(amount);
}

const postObject = evolve({
  vehicleYear: Number,
  owedAmount: amount,
  assetAmount: amount,
  jointOwnershipInformation: map(evolve({
    jointOwnerIndividual : transformIndividulaObj
  }))
});

const vehicleInfo = ({ assetType, vehicleYear, vehicleMake, vehicleModel }) =>{
  const result = []
  if(or(vehicleYear, vehicleMake)){
    result[0] = `${assetType.value} - ${vehicleYear || ''} ${vehicleMake || ''} ${vehicleModel || ''}`;
    result[1] = `${assetType.spValue} - ${vehicleYear || ''} ${vehicleMake || ''} ${vehicleModel || ''}`;
  }else {
    result[0] = `${assetType.value}`
    result[1] = `${assetType.spValue}`;
  }
  return result;
}

export default sectionConfiguration(
  VehicleComponent,
  formValidation,
  ['YESNO', 'MPVEHICLETYPE', 'VEHICLEUSE'],
  [
    { name: 'Owner', pipe: 'individual' },
    {
      name: 'Vehicle',
      prop: 'data',
      pipe: 'domain',
      args: vehicleInfo,
    },
    { name: 'Total Value', prop: 'data.assetAmount', pipe: 'currency' },
    { name: 'Amount Owed', prop: 'data.owedAmount', pipe: 'currency' },
  ],
  {...{}, ...vehicleLabels},
  {
    postObject
  }
);
