export const en_labels = {
    title: "DEPENDENT_CARE_STATIC_TEXT3",
    topLevelQuestion: "DEPENDENT_CARE_STATIC_TEXT1",
    topLevelQuestionPopOver: "DEPENDENT_CARE_STATIC_TEXT2",
    dependantIndivNumber: 'DEPENDENT_CARE_STATIC_TEXT4',
    personOutOfHouseholdName: 'DEPENDENT_CARE_STATIC_TEXT5',
    individualQues: 'DEPENDENTCARE_RMB_SUMMARY_STATIC1',
	expenseCode: 'OTHER_INCOME_SATIC_TEXT26',
    expenseFrequencyCode: 'UTILITIES_EXPENSES_STATIC_TEXT7',
    expenseFrequencyCodePart2: 'DEPENDENT_CARE_STATIC_TEXT11',
    expenseFrequesncyCodeHint: "DEPENDENT_CARE_STATIC_TEXT8",
    expenseFrequencyCodePopOver: "DEPENDENT_CARE_STATIC_TEXT2",
    expenseAmountPart2: "DEPENDENT_CARE_STATIC_TEXT7",
    removed: {
        careEndDate: 'DEPENDENTCARE_RMB_SUMMARY'
    },
    expensePaymentInformation_expenseAmount: "DEPENDENTCARE_RMB_SUMMARY_STATIC2",
    expensePaymentInformation_expenseFrequencyCode: "DEPENDENTCARE_RMB_SUMMARY_STATIC3"
}

export const sp_labels = {
    title: "Gastos de cuidado de dependientes",
    topLevelQuestion: "¿Alguien de su hogar tiene gastos de cuidado de dependientes? ",
    topLevelQuestionPopOver: "Estos son gastos para su(s) hijo(s) menor(es) de edad que no sea física o mentalmente capaz de cuidarse y que vive con usted más de la mitad del año.",
    dependantIndivNumber: '¿Para quién se paga este gasto?',
    personOutOfHouseholdName: 'Nombre de la persona que vive fuera del hogar',
    individualQues: 'DEPENDENTCARE_RMB_SUMMARY_STATIC1',
	expenseCode: '¿Cuánto paga',
    expenseFrequencyCode: '¿Con qué frecuencia',
    expenseAmountPart2: 'por este gasto de cuidado de dependientes?',
    expenseFrequesncyCodeHint: "Si no sabe la cantidad exacta, nos puede decir lo que calcula.",
    expenseFrequencyCodePopOver: "Estos son gastos para su(s) hijo(s) menor(es) de edad o un adulto que no sea física o mentalmente capaz de cuidarse y que vive con usted más de la mitad del año.",
    expenseFrequencyCodePart2: "paga este gasto de cuidado de dependientes?",
    removed: {
        careEndDate: 'DEPENDENTCARE_RMB_SUMMARY'
    },
    expensePaymentInformation_expenseAmount: '¿Cuánto paga indiv por este gasto de cuidado de dependientes? Si no conoce la cantidad exacta, puede indicarnos un presupuesto',
    expensePaymentInformation_expenseFrequencyCode: '¿Con qué frecuencia paga indiv por este gasto de cuidado de dependientes?'
}

export default en_labels
