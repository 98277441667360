import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { ApiService } from "@app/gateway/services/api.service";
import { AuthService } from "@app/gateway/services/auth.service";
import { ApplicationService } from "@app/services/application/application.service";
// import { IdleService } from "@app/services/idle/idle.service";
import { ServiceConfigService } from "@app/services/service-config.service";
import { StateService } from "@app/services/state/state.service";
import { StorageService } from "@app/services/storage/storage.service";
import { ActivatedRoute } from '@angular/router';
import { BeforeStartComponent } from "@app/gateway/at-a-glance/before-start-application/before-start-application.component";
import { toMiddleEndian } from "@app/helpers/date.helpers";
import { differenceInDays } from "date-fns";
import { Title } from '@angular/platform-browser';
import { IdleService } from "@app/services/idle/idle.service";
import { TranslateService } from "@ngx-translate/core";
import { UserProfileService } from "../../../app/gateway/user-profile/user-profile.service";

@Component({
  selector: "nh-kepro-landing",
  templateUrl: "./kepro-landing.component.html",
  styleUrls: ["./kepro-landing.component.scss"],
})

export class KeproLandingComponent implements OnInit {
  peAccessCdArr: any[] = [];
  caseDetails: any;
  isRmb = false;
  caseNumber;
  applicationNum: any;
  appType;
  selectedValue = '';

  constructor(
    private apiService: ApiService, 
    // private idle: IdleService,
    private authService: AuthService, 
    private serverConfig: ServiceConfigService,
    private router: Router,
    private stateService: StateService,
    protected storageService: StorageService,
    private app: ApplicationService,
    private route: ActivatedRoute,
    private beforeStart: BeforeStartComponent,
    private titleService: Title,
    private idle: IdleService,
    public translateService: TranslateService,
    private userProfileService: UserProfileService,
  ) {   
     this.titleService.setTitle('TennCare Call Centre Landing');
};
  validSession = false;
  mpToken;
  tcamId;
  keproUrl;
  serviceNowToken;
  payLoad = {};
  url;
  fromMobile = false;
  aplReadOnly = false;
  isDataLoaded : boolean = false;

  getCookie(name) {
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split("=");
      if (name === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  startApp(appType?) {
    return this.router.navigateByUrl('/application/' + appType);
  }

  startNewApp(appType?) {
    if (this.applicationNum && this.applicationNum !== 0) {
      this.stateService.resetStates();
      this.app.deleteApp(this.applicationNum).subscribe(() => {
        sessionStorage.removeItem('appNum');
        this.startApp(appType);
      })
    } else {
      this.startApp(appType);
    }
  }

  select(value) {
    this.selectedValue = value;
  }

  continueApp() {
    if (this.applicationNum && this.applicationNum != 0) {
      const iskepro = sessionStorage.getItem('isKepro') === 'true';
      sessionStorage.setItem('appNum', this.applicationNum);
      if (iskepro) {
        const payLoad = { "appType": "RENEW_BENEFITS" }
        this.app.determineAhsContinueUserApp(this.applicationNum, payLoad).subscribe(() => {
          this.beforeStart.startApplication('RB');
        })
      } else {
        this.beforeStart.startApplication('RB');
      }
    } else {
      this.startApp();
    }
  }

  next() {
    this.stateService.continueApplication(true);
    if (this.selectedValue && this.selectedValue === 'continue') {
      this.continueApp();	
    } else if (this.selectedValue && this.selectedValue === 'new') {
      this.startNewApp('REDETERMINATION');
    }
  }

  rmbApp(hohResponse) {
    this.isDataLoaded = true
    this.caseDetails = hohResponse;
  };

  appSelection(response) {
    this.peAccessCdArr = response.body.peAccessCd;
    if (this.peAccessCdArr.includes('KPU')) {
      this.idle.start();
    }
    this.appType = response.body.appType === 'AFB' ? 'Application' : response.body.appType === 'RMB' ? 'REDETERMINATION' : 'CHANGE_REPORT';
    if (response.body.appType === 'APL') {
      this.appType = 'APL'
    }
    this.caseNumber = response.body.caseNum;
    sessionStorage.setItem('caseNum', this.caseNumber);

    if (response.body?.userFullName) {
      sessionStorage.setItem('fullName', response.body?.userFullName);
    };
    this.app.getKbInfo(response.body.userName).subscribe((kbRes: any) => {
      if (kbRes) {
        sessionStorage.setItem('isKbEligible', kbRes.activeFlag === 'Y' ? 'true' : 'false');
      }
    });

    if (this.appType === 'REDETERMINATION' || this.appType === 'CHANGE_REPORT' || this.appType === 'APL') {
      sessionStorage.setItem('appType', this.appType);
      const isPreterm = response?.body?.redeterminationType === "PRT" ? true : false
      if (this.appType === 'APL') {
        sessionStorage.setItem('isAHSlanding', 'true');
        this.app.getHeadOfHouseholdInfo(0, 'APL').subscribe((res: any) => {
          this.applicationNum = res?.applicationNumber;
          if (sessionStorage.getItem('removedAppealRecord')) {
            sessionStorage.removeItem('removedAppealRecord')
          }
          if (this.applicationNum && this.storageService.getAppNumber() !== this.applicationNum) {
            sessionStorage.setItem('appNum', this.applicationNum);
            this.storageService.setAppNumber(this.applicationNum);
          }
          if (this.applicationNum && this.applicationNum !== '0' && this.applicationNum !== 0) {
            this.stateService.resetStates();
            this.app.deleteApp(this.applicationNum).subscribe(() => {
              sessionStorage.setItem('appNum', null);
              this.storageService.setApplicationType(this.appType);
              this.router.navigateByUrl('/application/' + 'NEW');
            })
          }
          else {
            this.stateService.resetStates();
            sessionStorage.setItem('appNum', null);
            this.storageService.setApplicationType(this.appType);
            this.router.navigateByUrl('/application/' + 'NEW');
          }
        });
      } else if (this.appType === 'REDETERMINATION' && response?.body?.redeterminationType !== "PRT") {
        this.app.getPendingApplications(this.caseNumber, "RENEW_BENEFITS").subscribe((response: any) => {
          if (response?.RMB?.length > 0) {
            const hohDetails = response?.RMB[0]?.headOfHousehold
            this.applicationNum = response?.RMB[0]?.applicationNumber;
            const startedOn = toMiddleEndian(hohDetails?.startedOn);
            const today = toMiddleEndian(new Date());
            const diffInDays = differenceInDays(new Date(today), new Date(startedOn));
            hohDetails.caseNumber = response?.RMB[0]?.caseNumber;
            if (this.appType === 'REDETERMINATION' && this.applicationNum && this.applicationNum !== 0 && diffInDays < 7) {
              this.rmbApp(hohDetails);
            } else {
              this.startNewApp(this.appType);
            }
          } else {
            this.startNewApp(this.appType);
          }
        })
      } else if (isPreterm && this.appType === "REDETERMINATION") {
        sessionStorage.setItem('isPreterm', "PRETERM");
        this.startNewApp(this.appType);
      } else {
        sessionStorage.removeItem('isPreterm');
        this.app.getHeadOfHouseholdInfo(this.caseNumber, this.appType).subscribe((hohResponse: any) => {
          if (hohResponse) {
            this.applicationNum = hohResponse?.applicationNumber;
            this.startNewApp(this.appType);
          } else {
            this.startNewApp(this.appType);
          }
        })
      }
    }
  };

  ngOnInit() {
    // tslint:disable-next-line: no-debugger
    debugger;
    this.tcamId = this.serverConfig.getServerConfig().TCAM_KEY;
    this.keproUrl = this.serverConfig.getServerConfig().keprolUrl;
    sessionStorage.setItem('isKepro', 'true');
    this.route.queryParams.subscribe((response: any) => {
      if (response?.mpToken) {
        this.apiService.setAhsMpToken(response?.mpToken);
        this.fromMobile = response?.fromMobile && response?.fromMobile === 'true' ? true : false;
        this.aplReadOnly = response?.isAppealReadOnly && response?.isAppealReadOnly === 'true' ? true : false;
        this.url = this.fromMobile ? `publ/users/mobileUserLogin` : `publ/users/KeyProLogin`;
        const source = this.fromMobile === true ? 'mobile' : null;
        const defaultLang = response.lang ? response.lang : "en";
        if(defaultLang && this.fromMobile){
          this.translateService.setDefaultLang(defaultLang);
        }
        this.payLoad = { tcam: this.getCookie(this.tcamId), mpToken: response?.mpToken, source: source };
        this.apiService.removeQueryParam('mpToken');
      } else if (response?.serviceNowToken) {
        this.url = `publ/users/snowAhsLogin`;
        this.payLoad = { tcam: this.getCookie(this.tcamId), mpToken: response?.serviceNowToken };
        this.apiService.removeQueryParam('serviceNowToken');
      }
    });

    const backToPartnerSite = () => window.location.href = this.keproUrl;
    const contentHeader = new HttpHeaders({ "Content-Type": "application/json" });

    this.apiService.post(this.url, this.payLoad, { headers: contentHeader, observe: 'response' }).subscribe((response: any) => {
      if (response && response.body?.isValidSession === 'true' && !this.fromMobile) {
        sessionStorage.setItem('isMobileUser', 'false')
        const authToken = response.headers.get('x-auth-token');
        sessionStorage.setItem('userType', response.body?.userType);
        sessionStorage.setItem('userName', response.body?.userName);
        sessionStorage.setItem('individualId', response?.body?.indvId);
        sessionStorage.setItem('encryptedIndvId', response?.body?.encryptedIndvId);
        if (this.aplReadOnly) {
          sessionStorage.setItem('appealReadOnly', 'true');
        }
        this.authService.setToken(authToken);
        this.validSession = true;
        this.appSelection(response);
      } else if (response && response.body?.isValidSession === 'true' && this.fromMobile) {
        sessionStorage.removeItem('isKepro');
        sessionStorage.setItem('isMobileUser', 'true')
        this.userProfileService.fromMobileApptoTcc(this.fromMobile);
        const authToken = response.headers.get('x-auth-token');
        sessionStorage.setItem('userType', response.body?.userType);
        sessionStorage.setItem('userName', response.body?.userName);
        sessionStorage.setItem('individualId', response.body?.indvId);
        sessionStorage.setItem('encryptedIndvId', response.body?.encryptedIndvId);
        this.authService.setToken(authToken);
        this.validSession = true;
        if(response?.body?.appType === 'RMB' ) {
          this.appType = 'REDETERMINATION'
         }
        this.caseNumber = response?.body?.caseNum;
        sessionStorage.setItem('caseNum', this.caseNumber);
        const indiv = sessionStorage.getItem('individualId');
        const hasIndiv = indiv && indiv != 'null' && indiv != '0' ? true : false;
        if (this.appType && this.appType === 'REDETERMINATION' && hasIndiv) {
          sessionStorage.setItem("appType", "RB");
          this.app.getPendingApplications(this.caseNumber, "RENEW_BENEFITS").subscribe((response: any) => {
            this.idle.start();
            if (response?.RMB?.length > 0) {
              const hohDetails = response?.RMB[0]?.headOfHousehold;
              this.applicationNum = response?.RMB[0]?.applicationNumber;
              const startedOn = toMiddleEndian(hohDetails?.startedOn);
              const today = toMiddleEndian(new Date());
              const diffInDays = differenceInDays(new Date(today), new Date(startedOn));
              hohDetails.caseNumber = response?.RMB[0]?.caseNumber;
              const element = response?.RMB[0];
              if (this.appType === 'REDETERMINATION' && this.applicationNum && this.applicationNum !== 0 && diffInDays < 7) {
                const mobileRmbCaseDetails = {"applicationNumber": element?.applicationNumber, "caseNumber": element?.caseNumber, "headOfHousehold" : element?.headOfHousehold?.headOfHousehold, "startedBy": element?.headOfHousehold?.startedByName, 
                  "startedOn": element?.headOfHousehold?.startedOn , "lastAccessedBy": element?.headOfHousehold?.lastAccessedBy, "lastAccessedOn": element?.headOfHousehold?.lastAccessedOn}
                sessionStorage.setItem('mobileRmbCaseDetails', JSON.stringify(mobileRmbCaseDetails));
                sessionStorage.setItem('appNum', this.applicationNum)
                this.router.navigateByUrl("/renewMyBenefit?caseNumber=" + response?.RMB[0]?.caseNumber);
              }
              else {
                this.router.navigateByUrl("/application/" + "REDETERMINATION");
              }
            } else {
              this.router.navigateByUrl("/application/" + "REDETERMINATION");
            }
          })
        }
      } else {
        this.validSession = false;
        if(this.fromMobile) {
          sessionStorage.setItem('isMobileUser', 'true')
          this.userProfileService.fromMobileApptoTcc(this.fromMobile);
          this.router.navigateByUrl('/invalidSession');
        } else {
          backToPartnerSite();
        }
       
      }
    }, () => {
      this.validSession = false;
      if(this.fromMobile) {
        sessionStorage.setItem('isMobileUser', 'true')
        this.userProfileService.fromMobileApptoTcc(this.fromMobile);
        this.router.navigateByUrl('/invalidSession');
      } else {
        backToPartnerSite();
      }
    })
  };

  startAppUrl = () => this.router.navigateByUrl('/application/' + 'NEW');

  startAfb(appType) {
    this.stateService.resetStates();
    this.storageService.setApplicationType(appType);
    this.startAppUrl();
  };

  searchSubmissions() {
    this.router.navigateByUrl('api/prov/submission-search');
  }

  searchCoverage(): void {
    this.router.navigateByUrl('api/prov/coverage-search');
  }
};
