import { environment } from '@env/environment';

import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { HttpRequest, HttpHandler } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { when } from 'ramda';

import { TokenService } from '@app/services/token/token.service';
import { SpinnerService } from '@app/services/spinner/spinner.service';
import { NotificationsService, ErrorsToken } from '@app/services/notification/notification.service';

import { nunProp } from '@app/helpers/null.helpers';
import { bodyWash, httpErrors, clone } from '@app/helpers/http.helpers';
import { isPutDelete, setAction } from '@app/helpers/services.helpers';
import { isForbidden, isUnprocessableEntity, isInternalServerErrors  } from '@app/helpers/http.helpers';
import { AuthService } from '@app/gateway/services/auth.service';
import { SigninService } from '@app/gateway/signin/signin.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private token: TokenService,
    private spinner: SpinnerService,
    private authService: AuthService,
    private signinService: SigninService,
    private router: Router,
    @Inject(ErrorsToken) private notify: NotificationsService
  ) { }

  defaultHeaders(request: HttpRequest<any>) {
    const url = `${environment.baseURL}${request.url}`;
    const token = this.authService.tokenId ? this.authService.tokenId : this.token.get()
    const headers = request.headers
      .set('Content-Type', 'application/json') //
      .set('Authorization', token);
    return clone({ url, headers }, request);
  }

  handleVerbs(request: HttpRequest<any>) {
    return when(isPutDelete, clone({ method: 'POST', headers: setAction(request) }), request);
  }

  handleBody(request: HttpRequest<any>) {
    if (request.url.indexOf('HHSUR') > 0) {
      return request;
    } else {
      return when(nunProp('body'), clone({ body: bodyWash(request) }), request);
    }
  }

  handleError({ error }: HttpErrorResponse) {
    if (isUnprocessableEntity(error)) {
      this.notify.addMultiple(httpErrors(error));
    }

    if (isForbidden(error)) {
      this.signinService.signOut(false);
      this.token.signOut();
      sessionStorage.clear();
    }

    if (isInternalServerErrors(error)) {
      if(sessionStorage && sessionStorage.length){
        let mobileUser = sessionStorage.getItem('isMobileUser') === 'true' ? true : false;
        if(!mobileUser){
          this.signinService.signOut(false);
          this.token.signOut();
          sessionStorage.clear();
          this.router.navigateByUrl('/errorpage');
        }else{
          this.signinService.signOut(false);
          this.token.signOut();
          sessionStorage.clear();
          this.router.navigateByUrl('/mobile-errorpage');
        }
      }
    }

    return throwError(error);
  }

  public intercept(original: HttpRequest<any>, handler: HttpHandler): Observable<any> {
    if (!original.url.endsWith('refresh')) {
      this.spinner.increment();
    };

    const defaults = this.defaultHeaders(original);
    const verbs = this.handleVerbs(defaults);
    const request = this.handleBody(verbs);

    return handler.handle(request).pipe(
      catchError(error => this.handleError(error)),
      finalize(() => this.spinner.decrement())
    );
  }
}
