<nh-form [individuals]="individuals" [formGroup]="getForm()" (validate)="onValidate($event)">
  <div class="m-2 before-submit"
    [formGroup]="getControl('data.applicationSignature')">
    <h5 class="section-header">{{'BEFORE_SUBMIT_STATIC_TEXT1' | translate }}</h5><hr>
    
    <p *ngIf="!isPresumptive" class="static-text">{{'BEFORE_SUBMIT_STATIC_TEXT3' | translate }}</p>

    <div class="static-text"> {{'BEFORE_SUBMIT_STATIC_TEXT4' | translate }}
      <ul class="list-inline">
        <li> {{'BEFORE_SUBMIT_STATIC_TEXT5' | translate }}</li>
        <li> {{'BEFORE_SUBMIT_STATIC_TEXT6' | translate }}</li>
        <li> {{'BEFORE_SUBMIT_STATIC_TEXT7' | translate }}</li>
        <li> {{'BEFORE_SUBMIT_STATIC_TEXT8' | translate }}</li>
      </ul>
    </div>
    <br>
    <p class="static-text"><b><span class="requiredfd">*</span>{{'BEFORE_SUBMIT_STATIC_TEXT9' | translate }}</b> </p>

      <div class="form-row py-2">
      <div class="d-flex">
        <label class="radio-inline checkbox" for="voteRegiYesCheck">
          <input  #checkYes [checked]="checkboxValue?.code === 'Y'" class="chk-input chk-gap" id="voteRegiYesCheck" type="radio" (change)="voteRegiChecked($event, checkNo)" name="voteRegiYesCheck" value="Yes" required>
          <label class="chk-lbl">{{'Yes' | translate }}</label>
        </label>

        <label class="radio-inline checkbox" for="voteRegiNoCheck">
          <input  #checkNo [checked]="checkboxValue?.code === 'N'" class="chk-input chk-gap" id="voteRegiNoCheck" type="radio" (change)="voteRegiChecked($event, checkYes)" name="voteRegiNoCheck" value="No" required>
          <label class="chk-lbl">{{'No' | translate }}</label>
        </label>
      </div>
    </div>

    <p class="static-text">{{'IF_YOU_DONT_MAKE_CHOICE1' | translate }} <br><br> {{'IF_YOU_DONT_MAKE_CHOICE2' | translate }}</p> <br>

    <div class="row mb-5">
      <div class="col-md-4">
        <a class="btn btn-secondary" href="https://sos.tn.gov/products/elections/register-vote"
          target="_blank">{{'BEFORE_SUBMIT_STATIC_TEXT10' | translate }} <i class="fa fa-external-link" aria-hidden="true"></i></a>
      </div>
    </div>

    <p class="static-text"><strong>{{'BEFORE_SUBMIT_STATIC_TEXT11' | translate}}</strong></p>

    <p class="static-text">{{'BEFORE_SUBMIT_STATIC_TEXT12' | translate }}</p>
    <p class="static-text">{{'BEFORE_SUBMIT_STATIC_TEXT13' | translate }}</p>
    <p class="static-text">{{'BEFORE_SUBMIT_STATIC_TEXT13_1' | translate }}</p>
    <p class="static-text adjustMargin">{{'BEFORE_SUBMIT_STATIC_TEXT14' | translate }}</p>
    <address>
      <p>Division of Elections<br>
      312 Rosa L. Parks Avenue<br>
      7th Floor, Snodgrass Tower<br>
      Nashville, TN 37243-1102<br>
      {{'OR_CALL' | translate }}</p>
    </address>
    <p class="static-text">{{'BEFORE_SUBMIT_STATIC_TEXT15' | translate }}</p>

    <h5 class="section-header">{{'BEFORE_SUBMIT_STATIC_TEXT16' | translate }}</h5>
    <hr>
    <p class="static-text">{{'BEFORE_SUBMIT_STATIC_TEXT17' | translate }}</p>

    <p class="static-text"><b><span class="requiredfd">*</span>{{'BEFORE_SUBMIT_STATIC_TEXT24' | translate }}</b> </p>

    <div class="form-row py-2 pb-5 left checkbottom">
    <div class="d-flex">
      <label class="radio-inline checkbox" for="renewCoverageYesCheck">
        <input  #isrenewcheckYes [checked]="isRewCoverageReq?.code === 'Y'" class="chk-input chk-gap" id="renewCoverageYesCheck" type="checkbox" (change)="isRenewChecked($event, isrenewcheckNo)" name="renewCoverageYesCheck" value="Yes" required>
        <label class="chk-lbl">{{'Yes' | translate }}</label>
      </label>

      <label class="radio-inline checkbox ml-5" for="renewCoverageNoCheck">
        <input  #isrenewcheckNo [checked]="isRewCoverageReq?.code === 'N'" class="chk-input chk-gap" id="renewCoverageNoCheck" type="checkbox" (change)="isRenewChecked($event, isrenewcheckYes)" name="renewCoverageNoCheck" value="No" required>
        <label class="chk-lbl">{{'No' | translate }}</label>
      </label>
    </div>
  </div>

  <nh-field id = "st_renewEligibility" [label]="permissionLabel" [isRow]="true" class="label-section" *ngIf="isrenewcheckYes.checked">
    <select nh name="renewEligibility" class="mt-2" aria-label="renewEligibility" [items]="tableCodeValue('MPFINISH', 'data.applicationSignature.renewEligibility')" formControlName="renewEligibility"></select>
  </nh-field>

  <h5 class="section-header">{{'BEFORE_SUBMIT_STATIC_TEXT27' | translate }}</h5>
  <hr>
  <p class="static-text">{{'BEFORE_SUBMIT_STATIC_TEXT25' | translate }}</p><br>
  <p class="static-text"><b>{{'BEFORE_SUBMIT_STATIC_TEXT28' | translate }}</b></p>
  <div class="form-row py-2 pb-2 left">
    <div class="d-flex">
      <label class="radio-inline checkbox" for="renewCoverageAVSYesCheck">
        <input  #isrenewAVScheckYes [checked]="isavsConsentValue?.code === 'Y'" class="chk-input chk-gap" id="renewCoverageAVSYesCheck" type="checkbox" (change)="isRenewCheckedAVS($event, isrenewAVScheckNo)" name="renewCoverageAVSYesCheck" value="Yes" required>
        <label class="chk-lbl">{{'Yes' | translate }}</label>
      </label>

      <label class="radio-inline checkbox ml-5" for="renewCoverageAVSNoCheck">
        <input  #isrenewAVScheckNo [checked]="isavsConsentValue?.code === 'N'" class="chk-input chk-gap" id="renewCoverageAVSNoCheck" type="checkbox" (change)="isRenewCheckedAVS($event, isrenewAVScheckYes)" name="renewCoverageAVSNoCheck" value="No" required>
        <label class="chk-lbl">{{'No' | translate }}</label>
      </label>
    </div>
  </div>
  <br>
  <p class="static-text" [innerHTML]="'BEFORE_SUBMIT_STATIC_TEXT26' | translate"></p>

    <div *ngIf="!this.mcoMasterSwitch">
      <div *ngIf="showHealthPlan">
        <h5 class="section-header">{{'BEFORE_SUBMIT_STATIC_TEXT18' | translate }}</h5>
        <hr>
        <p class="static-text" [innerHTML]="'BEFORE_SUBMIT_STATIC_TEXT19' | translate"></p>
    
        <nh-field id = "st_healthPlan" label="{{'BEFORE_SUBMIT_STATIC_TEXT20' | translate }}" [mandatory]="false" class="label-section">
          <select nh name="healthPlan" aria-label="healthPlan"
            [items]="tableCodeValue('MCONAME', 'data.applicationSignature.healthPlan')"
            formControlName="healthPlan"></select>
        </nh-field>
        <p class="static-text" [innerHTML]="'BEFORE_SUBMIT_STATIC_TEXT21' | translate"></p>
        <p class="static-text" [innerHTML]="'BEFORE_SUBMIT_STATIC_TEXT23' | translate"></p>
      </div>
    </div>
    </div>
</nh-form>