export default {
    taxDeductions: 'TAX_RMB_SUMARY_STATIC7',
    deductionType: 'TAX_RMB_SUMARY_STATIC8',
    medicalInsureAmount: 'Medical Insurance',
    dentalInsureAmount: 'Dental Insurance',
    visionInsureAmount: 'Vision Care Insurance',
    flexiSpendAmount: 'Flexible Spending Accounts (Health and Dependent Plans)',
    defferedCompenseAmount: 'Deferred Compensation',
    pretaxLifeInsureAmount: 'TAX_RMB_SUMARY_STATIC9',
    otherAmount: 'TAX_RMB_SUMARY_STATIC10',
    otherExpenseName: 'TAX_DEDUCTIONS_STATIC_TEXT8',
    alimonyPaidAmount: 'Alimony Paid',
    alimonyOrderDate: 'TAX_DEDUCTIONS_STATIC_TEXT7',
    studentLoanAmount: 'Student Loan interest paid',
    tuitionFeeAmount: 'Tuition and Fees',
    educationExpenseAmount: 'Educator expenses',
    businessExpenseAmount: 'Business expenses of reservists, performing artists, and free-basis government officials',
    healthSavingAmount: 'Health Savings Account deduction',
    militaryExpenseAmount: 'Military moving expense',
    deductibleSelfEmployAmount: 'Deductible part of self-employment tax',
    selfEmployedQualifiedAmount: 'Self-employed SEP, SIMPLE, and qualified plans',
    selfEmployedHealthInsureAmount: 'Self-employed health insurance deductible',
    penaltyWithdrawlAmount: 'Penalty on early withdrawal of savings',
    iraDeductionAmount: 'IRA Deduction',
    domesticProductionAmount: 'Domestic production activities deductions'
}