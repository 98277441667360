<p *ngIf="isRmbRmc && exported">{{ 'OTHER_COVERAGE_STATICTEXT14' | translate }}</p>
<div *ngIf="getValue('extras.topLevelAnswer') === 'YES'" form-top>
  <h6 class="person-label"> {{ selectedIndividual | fullname }}</h6>
</div>
<nh-form [topLevelName] = "'seem'" [individuals]="individuals" [formGroup]="getForm()" [mode]="mode"
  topLevelQuestion="{{'SELF_EMPLOYMENT_STATIC_TEXT1' | translate }}"
  individualQuestion="{{'SELF_EMPLOYMENT_STATIC_TEXT2' | translate}}"
  (validate)="onValidate($event)" class="label-section">

  <div [formGroup]="getControl('data')">
    <br>
    <h5 class="section-header">
      {{'SELF_EMPLOYMENT_STATIC_TEXT3' | translate }}
    </h5>
    <hr>

    <nh-field id = "seem_businessName" [mandatory]="false" label="{{'SELF_EMPLOYMENT_STATIC_TEXT4' | translate }}" class="label-section">
      <input type="text" name="businessName" aria-label="businessName" maxlength="50" class="form-control"
        formControlName="businessName">
    </nh-field>

    <nh-field id = "seem_type" label="{{'SELF_EMPLOYMENT_STATIC_TEXT5' | translate }} {{selectedIndividual | fullname}}{{'SELF_EMPLOYMENT_STATIC_TEXT6' | translate }}" class="label-section">
      <select nh name="type" aria-label="type" (change)="onSelfEmpTypeChange()" [items]="tableCodeValue('SELFEMPTYPE', 'data.type')"
        formControlName="type"></select>
    </nh-field>

    <nh-field id = "seem_businessDescription" *ngIf="showBusinessDesc" label="{{'SELF_EMPLOYMENT_STATIC_TEXT7' | translate }}" class="label-section">
      <input type="text" name="businessDescription" maxlength="50" class="form-control"
        formControlName="businessDescription" aria-label="businessDescription">
    </nh-field>

    <nh-field id = "seem_averageMonthlyProfit"
      label="{{'SELF_EMPLOYMENT_STATIC_TEXT8' | translate }} {{selectedIndividual | fullname}} {{'SELF_EMPLOYMENT_STATIC_TEXT9' | translate }}"
      class="label-section">
      <nh-input-group prepend="$">
        <input nh type="text" placeholder="0.00" mask="separator.2" thousandSeparator="" separatorLimit="999999999" name="averageMonthlyProfit" formControlName="averageMonthlyProfit" class="form-control">
      </nh-input-group>
    </nh-field>

    <h5 class="section-header">{{'CO-OWENERS_TITLE' | translate }}</h5><hr>

    <nh-field id = "seem_anyOneOutside" label="{{'CO-OWENERS_STATIC_TEXT1' | translate }} {{selectedIndividual | fullname}}?  " class="label-section">
      <div class=hint>{{'CO-OWENERS_STATIC_TEXT2' | translate }} {{selectedIndividual | fullname}} {{'CO-OWENERS_STATIC_TEXT3' | translate }}</div>
      <select nh name="anyOneOutside" aria-label="anyOneOutside" (change)="onAnyOneOutSideChange()"
      [items]="tableCodeValue('YESNO', 'data.anyOneOutside')" formControlName="anyOneOutside"></select>
    </nh-field>

    <div class="d-none d-lg-block" *ngIf="isY('data.anyOneOutside')">
      <table class="table w-100 table-striped">
        <thead>
          <tr>
            <th scope="col">{{'CO-OWENERS_HEADER1' | translate }}</th>
            <th scope="col">{{'CO-OWENERS_HEADER2' | translate }}</th>
            <th scope="col">{{'CO-OWENERS_HEADER3' | translate }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody formArrayName="coOwners">
          <tr *ngFor="let row of coOwners.controls; index as i; last as isLast" [formGroupName]="i">
            <td>
              <nh-field id = "{{'coOwnerIndividual' + i}}" *ngIf="i == 0" [isRow]="true" [mandatory]="true">
                <select  nh name="coOwnerIndividual" aria-label="coOwnerIndividual" (change)="coOwnerValidate(row)"
                  [items]="coOwnerIndividuals" disabled formControlName="coOwnerIndividual"></select>
                  <div *ngIf="getControl('coOwnerIndividual', row).errors && getControl('coOwnerIndividual', row).errors.INVALID_INDIV" class="error-messages position-relative">
                    <span class="form-text invalid-tooltip d-block">
                      <ng-container> {{error.errorMessage }}</ng-container>
                    </span>
                  </div>
              </nh-field>
              <nh-field id = "{{'seem_coOwnerIndividual' + i}}" *ngIf="i != 0" [isRow]="true" [mandatory]="true">
                <select  nh name="coOwnerIndividual" aria-label="coOwnerIndividual" (change)="coOwnerValidate(row)"
                  [items]="coOwnerIndividuals" formControlName="coOwnerIndividual"></select>
                  <div *ngIf="getControl('coOwnerIndividual', row).errors && getControl('coOwnerIndividual', row).errors.INVALID_INDIV" class="error-messages position-relative">
                    <span class="form-text invalid-tooltip d-block">
                      <ng-container> {{error.errorMessage }}</ng-container>
                    </span>
                  </div>
              </nh-field>
            </td>
            <td>
              <nh-field id = "{{'seem_outsideTheHomeFullName' + i}}" [isRow]="true" [mandatory]="true" [option]="{label:'Co-Owner Outside the Home'}">
                <input type="text" name="outsideTheHomeFullName" aria-label="outsideTheHomeFullName" maxlength="35"
                  class="form-control" formControlName="outsideTheHomeFullName">
              </nh-field>

            </td>
            <td>
              <nh-field id = "{{'seem_ownershipPercentage' + i}}" [isRow]="true" [mandatory]="true">
                <nh-input-group prepend="%">
                  <input name="ownershipPercentage"  percentage="true" type="text" maxlength="3" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    class="form-control" aria-label="ownershipPercentage" formControlName="ownershipPercentage" (change)="getErrorMessage(row)">
                </nh-input-group>
                <div *ngIf="data.errors?.error && isLast && error.isError" class="error-messages position-relative">
                  <span class="form-text invalid-tooltip d-block">
                    <ng-container> {{error.errorMessage }}</ng-container>
                  </span>
                </div>
              </nh-field>
            </td>
            <td>
              <div *ngIf="i" class="pt-1 hover-red">
                <button class="btn btn-link btn-lg delete-{{i}} hover-red p-1" (click)="removeRow(i)">
                  <span class="pr-2">{{'REMOVE' | translate }}</span>
                  <nh-remove-svg></nh-remove-svg>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pt-1 hover-red add-another-left">
        <button [disabled]="isAddButtonEnabled()" class="btn btn-link btn-lg hover-red" (click)="addRow()">
          <span class="pr-2">{{'ADD_2' | translate }}</span>
          <nh-add-svg></nh-add-svg>
        </button>
      </div>
    </div>

    <div class="nh-table-mobile mt-3 pt-2 d-lg-none" *ngIf="isY('data.anyOneOutside')">
      <div formArrayName="coOwners">
        <div class="table-row mb-3 pb-3" *ngFor="let row of coOwners.controls; index as i; last as isLast"
          [formGroupName]="i">
          <!-- <div *ngIf="translatedLang === 'en'">
          <div class="tab-section" *ngIf="row.value.coOwnerIndividual !== null">{{ row.value.coOwnerIndividual.value}}'s Co-Ownership</div>
          </div>
          <div *ngIf="translatedLang !== 'en'">
            <div class="tab-section" *ngIf="row.value.coOwnerIndividual !== null">Copropietario de {{ row.value.coOwnerIndividual.value}}</div>
          </div> -->
          <div class="mobile-table-header">{{'CO-OWENERS_HEADER1' | translate }}</div>
          <hr>
          <div class="mobile-table-data">
            <nh-field id = "{{'coOwnerIndividual' + i}}" [mandatory]="true">
              <select nh name="coOwnerIndividual" aria-label="coOwnerIndividual" (change)="coOwnerValidate(row)"
                [items]="coOwnerIndividuals" formControlName="coOwnerIndividual"></select>
            </nh-field>
          </div><br>
          <div class="mobile-table-header">{{'CO-OWENERS_HEADER2' | translate }}</div>
          <hr>
          <div class="mobile-table-data">
            <nh-field id = "{{'outsideTheHomeFullName' + i}}" [mandatory]="true">
              <input type="text" name="outsideTheHomeFullName" aria-label="outsideTheHomeFullName" maxlength="35" class="form-control"
                formControlName="outsideTheHomeFullName">
            </nh-field>
          </div><br>
          <div class="mobile-table-header">{{'CO-OWENERS_HEADER3' | translate }}</div>
          <hr>
          <div class="mobile-table-data">

            <nh-field id = "{{'ownershipPercentage' + i}}" [isRow]="true" [mandatory]="true">
              <nh-input-group prepend="%">
                <input name="ownershipPercentage"  percentage="true" type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="form-control"
                  (change)="getErrorMessage(row)" aria-label="ownershipPercentage" formControlName="ownershipPercentage" maxlength="3">
              </nh-input-group>
              <div *ngIf="data.errors?.error && isLast && error.isError" class="error-messages position-relative">
                <span class="form-text invalid-tooltip d-block">
                  <ng-container> {{error.errorMessage }}</ng-container>
                </span>
              </div>
            </nh-field>
          </div><br>
          <div *ngIf="i" class="pt-1 hover-red text-center">
            <button class="btn btn-link btn-lg delete-{{i}} hover-red p-1" (click)="removeRow(i)">
              <span class="pr-2">{{'REMOVE' | translate }}</span>
              <nh-remove-svg></nh-remove-svg>
            </button>
          </div><br>
        </div>
      </div>

      <div class="pt-1 hover-red text-left">
        <button [disabled]="isAddButtonEnabled()" class="btn btn-link btn-lg hover-red" (click)="addRow()">
          <span class="pr-2">{{'ADD_2' | translate }}</span>
          <nh-add-svg></nh-add-svg>
        </button>
      </div>
    </div>
  </div>
</nh-form>