import { Component, AfterContentInit, ChangeDetectionStrategy } from '@angular/core';
import { compose, objOf, isNil, isEmpty } from 'ramda';
import { SectionFormDirective } from '@app/classes/section-form.class';
import Vs from '@app/services/validators/validators.service';
import { isYes } from '@app/helpers/tayn.helpers';
import { isOther } from '@app/helpers/models.helpers';
import { assignAs } from '@app/helpers/function.helpers';
import { minus120Years } from '@app/helpers/date.helpers';
import { setValid } from '@app/helpers/form-control.helpers';
import { swapControl, swapControls } from '@app/helpers/forms.helpers';
import { enableControls, disableControls, enableReset } from '@app/helpers/reset-able.helpers';
import { isExported, isExportStatusNotEmpty, isPresumptiveApp, isRmbRmc } from '@app/helpers/mode.helpers';
import { addDays, subDays } from 'date-fns';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { formatTableData } from '@app/helpers/tables.helpers';
import { StorageService } from '@app/services/storage/storage.service';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemographicsComponent extends SectionFormDirective implements AfterContentInit {
  today = new Date();
  maxDate = subDays(new Date(), 1);
  minDate = addDays(new Date(), 1);
  ssnCoverage:boolean;
  isSSNMatched = false;
  isSameName = false;
  isSameSsn = false;
  primaryIndiv;
  minimumDate = minus120Years();
  isFirstNameRequired = true;
  isLastNameRequired: boolean;
  ssnPop;
  afbSsnEnPop = `We only need this information if you want coverage and have an SSN.
  We use SSN’s to check income and other information to see who is eligible for help with health coverage costs.
  If you have a work only SSN leave the SSN field blank. For more information on getting an SSN visit socialsecurity.gov
  or call Social Security at 800-772-1213. TTY users can call 800-325-0778.`
  afbSsnSpPop = 'Solamente necesitamos esta información si desea cobertura y tiene un SSN. Usamos el SSN para comprobar ingresos y otra información para ver quién califica para ayuda con los costos de la cobertura de seguro médico. Si solo tiene SSN para trabajo, deje el campo SSN en blanco. Para obtener más información sobre cómo obtener un SSN, visite socialsecurity.gov o llame al Seguro Social al 800-772-1213. Los usuarios de TTY pueden llamar al 800-325-0778.'
  presumptiveSsnPop = `Giving us your SSN might help us review your application faster.
   If you need help getting an SSN, visit socialsecurity.gov, or call Social Security at 1-800-772-1213, or call TennCare Connect for free
    at 855-259-0710.`;
  livingArrangement;
  livingArrangementEnPop = `Tell us where you are living at the time you are completing this application or reporting a change.`;
  livingArrangementSPPop = `Díganos dónde vive en el momento de completar esta solicitud o informar un cambio.`;

  residencyArrangement;
  residencyArrangementEnPop = `Answer “yes” if the person does not have a specific address (e.g., because he or she is homeless) but the applicant is physically present in TN and intends to remain here.`;
  residencyArrangementSpPop = `Responda "sí" si la persona no tiene una dirección específica (por ejemplo, porque no tiene hogar), pero el solicitante está físicamente presente en TN y tiene la intención de permanecer aquí.`;

  records: any;
  isPresumptive;
  controlsApplyCov: any;
  applyForOnGoCovCntrl: any;
  applyForCovCntrl: any;
  setLivingAreaData;
  ssnValid = false;
  defaultLanguage;
  translatedLang;
  isRenewBenefits;
  isReportChange;
  renew = null;
  popUp = false;
  rmcPopUp = false;
  isRmbRmc = false;
  selectedIndividualPopup = false;
  appType: string;
  intendDateBanner = false;
  user = sessionStorage.getItem('userType');
  exported: boolean = false;
  isPresumptivePreg: boolean = false;
  federalRecogizedTribe : any;
  presumptiveDateOfApp: any = null;
  isNACodeSelected = false;
  isUNCodeSelected = false;
  selectedCode = '';
  selectedCodes = [];
  raceWarning = false;
  isRMB() {
    return this.applicationType === 'RENEW_BENEFITS' ? true : null;
  }

  constructor(private titleService: Title, builder: FormBuilder, public translateService: TranslateService,
    public storageService: StorageService) {
    super(builder);
    this.titleService.setTitle('Demographics');
  }

  selectDisabledValues(obj: any): void{
    console.log('inside demographics' + ' '+ obj?.value?.code);
    this.selectedCode = obj?.value?.code;
   if(this.selectedCodes.includes(obj?.value?.code) && !obj.isChecked){
      this.selectedCodes =  this.selectedCodes.filter((code) => code !== obj?.value.code);
    }
    else{
    this.selectedCodes = [...this.selectedCodes, obj?.value.code];
    }
    if(this.selectedCodes !== null && this.selectedCodes.length > 4){
      this.raceWarning = true;
    }else {
      this.raceWarning = false;
    }
    this.isNACodeSelected = obj?.value?.code === "NA" && obj?.isChecked;
    this.isUNCodeSelected = obj?.value?.code === "UN" && obj?.isChecked;

    if(this.isNACodeSelected){
      this.selectedCodes = ['NA'];
      this.raceWarning = false;
    }
    if(this.isUNCodeSelected){
      this.selectedCodes = ['UN'];
      this.raceWarning = false;
    }
  }

  ngAfterContentInit() {
    this.exported = isExportStatusNotEmpty(this.record.exportingStatus) && isExported(this.record.exportingStatus) && isRmbRmc(this.mode);
    const dateOfApplication = this.records[0].data.dateOfApplication;
    this.presumptiveDateOfApp = new Date(dateOfApplication?.replace(/-/g, '\/')); 
    this.defaultLanguage = this.translateService.getDefaultLang();
    this.translatedLang = this.defaultLanguage;
    this.languageCheck(this.defaultLanguage);
    if (this.defaultLanguage === "en") {
      this.ssnPop = this.afbSsnEnPop;
      this.livingArrangement = this.livingArrangementEnPop;
      this.residencyArrangement = this.residencyArrangementEnPop;
    } else {
      this.ssnPop = this.afbSsnSpPop;
      this.livingArrangement = this.livingArrangementSPPop;
      this.residencyArrangement = this.residencyArrangementSpPop;
    }

    this.translateService.onDefaultLangChange.subscribe((res) => {
      this.translatedLang = res.lang;
      this.languageCheck(res.lang);
      if (res.lang === "en") {
        this.ssnPop = this.afbSsnEnPop;
        this.livingArrangement = this.livingArrangementEnPop;
        this.residencyArrangement = this.residencyArrangementEnPop;
      } else {
        this.ssnPop = this.afbSsnSpPop;
        this.livingArrangement = this.livingArrangementSPPop;
        this.residencyArrangement = this.residencyArrangementSpPop;
      }
      if (this.user !== 'RP') {
      this.getRaceValue();
      }
    });
    this.isPresumptive = isPresumptiveApp(this.applicationType);
    this.isRenewBenefits = this.applicationType === 'RENEW_BENEFITS';
    this.isReportChange = this.applicationType === 'REPORT_CHANGES';
    const data = this.getControl('data');
    const otherNameCtrl = data.get('appliedWithOtherName');
    const primaryName = data.get('name');
    const otherName = data.get('otherName');
    const applyForCoverage = data.get('applyForCoverage');
    this.applyForCovCntrl = applyForCoverage;
    const applyForOnGoingCoverage = data.get('applyForOnGoingCoverage');
    this.applyForOnGoCovCntrl = applyForOnGoingCoverage;
    const tennesseeResident = this.getControl('data.livingArrangement.tennesseeResident');
    const citizen = this.getControl('data.citizenship.usCitizen');
    const derivedCitizen = this.getControl('data.citizenship.derivedCitizen');
    const livingAreaType = this.getControl('data.livingArrangement.livingAreaType');
    const registrationNumber = this.getControl('data.citizenship.document.registrationNumber');
    const naturalizationCertificateNumber = this.getControl('data.citizenship.document.naturalizationCertificateNumber');
    const outOfHome = this.getControl('data.livingArrangement.outOfHome');
    const intendToReturn = this.getControl('data.livingArrangement.intendToReturn');
    const intendToReturnDate = this.getControl('data.livingArrangement.intendToReturnDate');
    this.federalRecogizedTribe = this.getControl('data.federalRecogizedTribe');
    const tribeName = this.getControl('data.tribeName');
    const healthProgram = this.getControl('data.healthProgram');
    const eligHlthProgram = this.getControl('data.eligHlthProgram');
    const ssn = this.getControl('data.ssnInformation.ssn');
    const ssnInitialValue = ssn.value;
    const helpers = this.getControl('extras.helpers');
    const races = helpers.get('races');
    const otherRaceSelected = helpers.get('races.O');
    this.appType = sessionStorage.getItem('appType');
    this.isRmbRmc = this.appType === 'RB' || this.appType === 'RC';
    this.isPresumptivePreg = this.appType === 'PRG'

    if (this.user !== 'RP') {
    this.getRaceValue();
    }

    if (!isNil(this.record.data) && this.records[0].data.homeless?.code === 'Y') {
      livingAreaType.setValue({ code: 'HML', value: 'Homeless/Homeless Shelter' });
    };

    
    if (!isNil(this.record.data)) {
      const racesFromDb = this.record?.data?.ethnicity?.races;
      if(racesFromDb !== null && racesFromDb.length > 0){
        this.selectedCodes = racesFromDb.map((ra) => ra.code);
        this.isNACodeSelected = this.selectedCodes.includes('NA');
      } 
      if(racesFromDb !== null && racesFromDb.length > 0){
        this.selectedCodes = racesFromDb.map((ra) => ra.code);
        this.isUNCodeSelected = this.selectedCodes.includes('UN');
      }     
    };

    const selectedIndividualName = compose(
      assignAs(this, 'selectedIndividual'),
      objOf('name')
    );
    const selectedIndividualGender = compose(
      assignAs(this, 'selectedIndividual'),
      objOf('gender.code')
    );
    const selectedIndividualAge = compose(
      assignAs(this, 'selectedIndividual'),
      objOf('age')
    );

    const name = data.get('name');
    const gender = data.get('gender.code');
    const age = data.get('age');
    const selectedRaces = data.get('ethnicity.races');
    const otherRace = data.get('ethnicity.otherRace');

    const applyCoverageCtrls = [citizen, derivedCitizen, livingAreaType, this.federalRecogizedTribe];
    this.controlsApplyCov = applyCoverageCtrls;
    const docCtrls = [registrationNumber, naturalizationCertificateNumber];
    const tennCtrls = [outOfHome];

    const compare = (a, b) => JSON.stringify(a) === JSON.stringify(b);
    const otherNameValue = val => compare(val, primaryName.value) ? this.isSameName = true : this.isSameName = false;
    const primaryNameValue = val => compare(val, otherName.value) ? this.isSameName = true : this.isSameName = false;

    const isApplyingCoverage = isYes(applyForCoverage.value);
    if (isApplyingCoverage || isYes(this.applyForOnGoCovCntrl.value)) {
      enableControls(this.controlsApplyCov);
    } else {
      disableControls(this.controlsApplyCov);
    }

    ssn.setValidators([Vs.ssn, () => {
      const ssnObj = this.records.find((item) => {
        return ssn.value && item.data.ssn === ssn.value && ssnInitialValue !== ssn.value;
      });
      return ssnObj ? { DUPLICATE_SSN: true } : null;
    }])
    ssn.updateValueAndValidity();
    this.registerToggle(otherNameCtrl, swapControl(otherName, isYes));
    this.registerToggle(otherRaceSelected, swapControl(otherRace, isOther));
    this.registerToggle(races, setValid(selectedRaces));
    this.registerToggle(name, selectedIndividualName);
    this.registerToggle(age, selectedIndividualAge);
    this.registerToggle(gender, selectedIndividualGender);
    this.registerToggle(citizen, swapControl(derivedCitizen, isYes));
    this.registerToggle(derivedCitizen, swapControls(docCtrls, isYes));
    this.registerToggle(intendToReturn, swapControl(intendToReturnDate, isYes));
    this.registerToggle(outOfHome, swapControl(intendToReturn, isYes));
    this.registerToggle(this.federalRecogizedTribe, swapControls([healthProgram, eligHlthProgram, tribeName], isYes));
    this.registerToggle(otherName, (val) => otherNameValue(val));
    this.registerToggle(primaryName, (val) => primaryNameValue(val));
    this.registerToggle(tennesseeResident, swapControls(tennCtrls, isYes))

    if (!this.isPresumptive) {
      applyForOnGoingCoverage.clearValidators();
      applyForOnGoingCoverage.disable();
    } else {
      otherNameCtrl.clearValidators();
      otherNameCtrl.updateValueAndValidity();
      this.federalRecogizedTribe.disable();
    }
    if (citizen.value && citizen.value.code === 'Y') {
      derivedCitizen.setValidators(Vs.required);
      derivedCitizen.updateValueAndValidity();
    } else {
      derivedCitizen.clearValidators();
      derivedCitizen.updateValueAndValidity();
    }

    if(applyForCoverage.value?.code === 'Y'){
      this.ssnCoverage = true;
    }else{
      this.ssnCoverage = false;
      intendToReturnDate.clearValidators();
      intendToReturnDate.updateValueAndValidity();
    }

  }

  ngAfterViewInit(): void {
    window.scroll(0,0);
  }

  getRaceValue() {
    const helpers = this.getControl('extras.helpers');
    const races = helpers.get('races');
    for (const key in races['controls']) {
      if (races['controls'].hasOwnProperty(key)) {
        const element = races['controls'][key].value;
        if (this.translatedLang === 'en' && element !== null) {
          races['controls'][key].patchValue({code : element.code, value : element['description']})
        } if (this.translatedLang !== 'en' && element !== null) {
          races['controls'][key].patchValue({code : element.code, value : element['spDescription']})
        }
      }
    }
    for (const key in this.tables) {
      let tabelCode = {};
      if (this.tables.hasOwnProperty(key)) {
        const element = this.tables[key];
        tabelCode[key] = element.map(item => {
          if (key === 'RACEDISPLAY') {
            if(this.translatedLang === 'en'){
              item.value = item.description || item.value;
            } else {
              item.value = item.spDescription || item.spValue;
            }
            return item;
          } else {
            return item;
          }
        })
      }
    }
  }


  tableCodeValue (tableName, tableControl) {
    return this.getTableCodeValues(tableName, tableControl, this.translatedLang, false)
  }

  languageCheck(language) {
    this.tables = language === 'sp' ? formatTableData(this.tables, 'spDescription') : formatTableData(this.tables, 'description');
  }

  checkForRequiredFields(event, fieldName) {
    if (fieldName === 'firstName') {
      this.isLastNameRequired = !(event.target.value.length > 0);
    } else if (fieldName === 'lastName') {
      this.isFirstNameRequired = !(event.target.value.length > 0);
    }
  }

  setLivingArea() {
    const data = this.getControl('data');
    const applyForCoverage = data.get('applyForCoverage');
    const applyForOnGoingCoverage = data.get('applyForOnGoingCoverage');
    const livingAreaType = this.getControl('data.livingArrangement.livingAreaType');

    this.primaryIndiv = this.members.find(i => i.applicantRelationship === null);
    const { homeless, applicantRelationship } = this.primaryIndiv;
    const coverage = isYes(applyForCoverage.value);
    const isApplyForOnGoingCoverage = isYes(applyForOnGoingCoverage.value);
    const isHomeless = isYes(homeless);
    if (isHomeless && isNil(applicantRelationship) && (coverage || isApplyForOnGoingCoverage)) {
      livingAreaType.setValue({ code: 'HML', value: 'Homeless/Homeless Shelter' });
    } else if (!coverage && (this.isRmbRmc || this.isPresumptive)) {
      livingAreaType.setValue({ code: "HO", description: "At Home", spDescription: "En casa" });
    }
  };

  onApplyFprCovSelected(event: any) {
    const tennesseeResident = this.getControl('data.livingArrangement.tennesseeResident');
    const derivedCitizen = this.getControl('data.citizenship.derivedCitizen');
    if ((event.target.value === 'Yes' || event.target.value === 'Si') || (this.applyForOnGoCovCntrl.value && this.applyForOnGoCovCntrl.value.value === 'Yes')) {
      enableControls(this.controlsApplyCov);
      derivedCitizen.disable();
      enableReset(tennesseeResident);
      if (this.federalRecogizedTribe?.value?.code === 'N') {
        this.federalRecogizedTribe.reset();
      }
      if (this.isPresumptive) {
        this.federalRecogizedTribe.disable();
      };
    } else if (event.target.value === 'No' || (this.applyForOnGoCovCntrl.value && this.applyForOnGoCovCntrl.value.value === 'No')) {
      disableControls(this.controlsApplyCov);
      if (this.isPresumptive) {
        this.federalRecogizedTribe.disable();
      } else {
        this.federalRecogizedTribe.patchValue({ code: 'N', value: 'No', spValue: "No" });
      }
      tennesseeResident.patchValue({ code: "Y", value: "Yes", spValue: "Si" });
    }
    
    if (event.target.value === 'Yes' || event.target.value === 'Si'){
      this.ssnCoverage = true;
    }else{
      this.ssnCoverage = false;
    }
    
    this.getControl('data.citizenship.usCitizen').updateValueAndValidity();
    this.setLivingArea();

    if (this.getControl('data.applyForCoverage').value.code == 'N') {
      const intendToReturnDate = this.getControl('data.livingArrangement.intendToReturnDate');
      intendToReturnDate.clearValidators();
      intendToReturnDate.updateValueAndValidity();
    }
  };

  onApplyFprCovOnGoSelected(event: any) {
    const derivedCitizen = this.getControl('data.citizenship.derivedCitizen');
    derivedCitizen.disable();
    const tennesseeResident = this.getControl('data.livingArrangement.tennesseeResident');
    if (event.target.value === 'Yes') {
      enableControls(this.controlsApplyCov);
      enableReset(tennesseeResident);
      this.federalRecogizedTribe.disable();
      derivedCitizen.disable();
    } else if (event.target.value === 'No') {
      if (this.applyForCovCntrl.value && this.applyForCovCntrl.value.value === 'Yes') {
        enableControls(this.controlsApplyCov);
        derivedCitizen.disable();
      } else {
        disableControls(this.controlsApplyCov);
        tennesseeResident.patchValue({ code: "Y", value: "Yes", spValue: "Si" });
        tennesseeResident.updateValueAndValidity();
      }
      if (this.isPresumptive) {
        this.federalRecogizedTribe.disable();
      };
    }
    this.getControl('data.citizenship.usCitizen').updateValueAndValidity();
    this.setLivingArea();
  }

  isInvalidDate(event) {
    const enteredDate = event?.target?.value;
    if(this.isPresumptive) {
      if(new Date(this.getControl('data.birthDate').value).setHours(0,0,0,0) > new Date().getTime()){
        this.getControl('data.birthDate').setErrors({INVALID_FUTURE_DATE: true});
      }
      else if(new Date(this.getControl('data.birthDate').value).setHours(0,0,0,0) > (this.presumptiveDateOfApp)){
          const birthDateControl = this.getControl('data.birthDate');
          birthDateControl.setErrors({INVALID_DATE_OF_BIRTH_FOR_APPLICATION: true});
          this.formGroup.updateValueAndValidity();
      }
    } else {
      if ( !!enteredDate || enteredDate === '' || enteredDate === 'Invalid date' ) {
        event.target.value = enteredDate;
      } else if (event?.target?.name === 'birthDate') {
        event.target.value = formatDate (this.getControl('data.birthDate').value , 'MM/dd/yyyy' , 'en');
      }

    }
  }
  displayPopup() {
    this.popUp = this.isRenewBenefits && this.selectedIndividual ? this.selectedIndividual.headOfHousehold : false;
    return this.popUp;
  }

  displayRmcPopup() {
    this.rmcPopUp = this.isReportChange && this.selectedIndividual ? this.selectedIndividual.headOfHousehold : false;
    return this.rmcPopUp;
  }

  isIndividualPopup() {
    return this.selectedIndividual && this.isRmbRmc ? true : false;
  }

  restrictSplChar(event): boolean {
    const k = event.keyCode;
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  calculateAge(birthday) {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  intendDateBannerChange() {
    const intendToReturnDate = this.getControl('data.livingArrangement.intendToReturnDate');
    return intendToReturnDate.value && intendToReturnDate?.value < this.minDate ? true : false;
  }

  formControlValueChanged() {
    let ssn = this.getControl('data.ssnInformation.ssn')
    let ssnConfirm = this.getControl('data.ssnInformation.ssnConfirm')
    if (ssn.value && !ssnConfirm.value) {
    ssnConfirm.patchValue(ssn.value)
    }

  }

  compareSSN() {
    const ssn = this.getControl('data.ssnInformation.ssn')
    const ssnConfirm = this.getControl('data.ssnInformation.ssnConfirm')
    this.isSSNMatched = ssn.value === ssnConfirm.value;
    if (this.isSSNMatched) {
      ssnConfirm.setErrors(null);
    } else if (isEmpty(ssnConfirm.value) || isNil(ssnConfirm.value)) {
      ssn.value ? ssnConfirm.setErrors({ SSN_CONFIRM: true }) : ssnConfirm.setErrors(null);
      ssn.updateValueAndValidity({emitEvent: true});
    } else if (ssn.value.length === 0 ) {
      ssnConfirm.reset()
      ssnConfirm.setErrors(null)
    } else if (ssnConfirm.value.length < 9) {
      ssnConfirm.setErrors({INVALID_SSN: true});
    } else {
      ssnConfirm.setErrors({ SSN_DIFFERENT: true });
    }
  }

}