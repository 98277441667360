import { Component, AfterContentInit } from '@angular/core';
import { BaseModalDirective } from '@app/components/modals/base-modal';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './medical-bills.component.html'
})
export class MedicalBillsModalComponent extends BaseModalDirective implements AfterContentInit {
  constructor(builder: FormBuilder, protected modalRef: BsModalRef, public translateService: TranslateService) {
    super(modalRef, builder);

  }

  title;
  titleEn = "Medical Bill Types";
  titleSp = "Tipos de Facturas Médicas";
  medicalBillTypes;
  medicalBillTypesEn = "Medical Bill Type";
  medicalBillTypesSp = "Tipos de Facturas Médicas"
  description;
  descriptionEn = "Description";
  descriptionSp = "Descripción";
  type1;
  type1En = "Blind Work Expense";
  type1Sp = "Gasto de trabajo de ciego";
  type1Description;
  type1DescriptionEn = "Examples are: Service Animals, Physical Therapy, Prosthetic Devices, Work-related Equipment Services, Transportation to and from work, Vehicle Modifications";
  type1DescriptionSp = "Algunos ejemplos son: animales de servicio, fisioterapia, dispositivos protésicos, servicios de equipos relacionados con el trabajo, transporte hacia y desde el trabajo, modificaciones de vehículos";
  type2;
  type2En = "Health Insurance Premium";
  type2Sp = "Prima de seguro médico";
  type2Description;
  type2DescriptionEn = "Monthly Premium Payment made for other health insurance";
  type2DescriptionSp = "Pago de prima mensual para otro seguro médico";
  type3;
  type3En = "Impairment Related Work Expense";
  type3Sp = "Gasto de trabajo relacionado con un impedimento";
  type3Description;
  type3DescriptionEn = "Examples are: Service Animals, Physical Therapy, Prosthetic Devices, Work-related Equipment Services, Transportation to and from work, Vehicle Modifications";
  type3DescriptionSp = "Algunos ejemplos son: animales de servicio, fisioterapia, dispositivos protésicos, servicios de equipos relacionados con el trabajo, transporte hacia y desde el trabajo, modificaciones de vehículos";
  type4;
  type4En = "Medicare Part A";
  type4Sp = "Medicare Parte A";
  type4Description;
  type4DescriptionEn = "Monthly Premium amount for Medicare Part A";
  type4DescriptionSp = "Cantidad de la prima mensual para la Parte A de Medicare";
  type5;
  type5En = "Medicare Part B";
  type5Sp = "Medicare Parte B";
  type5Description;
  type5DescriptionEn = "Monthly Premium amount for Medicare Part B";
  type5DescriptionSp = "Cantidad de la prima mensual para la Parte B de Medicare";
  type6;
  type6En = "Medicare Part C";
  type6Sp = "Medicare Parte C";
  type6Description;
  type6DescriptionEn = "Monthly Premium amount for Medicare Part C";
  type6DescriptionSp = "Cantidad de la prima mensual para la Parte C de Medicare";
  type7;
  type7En = "Medicare Part D";
  type7Sp = "Medicare Parte D";
  type7Description;
  type7DescriptionEn = "Monthly Premium amount for Medicare Part D";
  type7DescriptionSp = "Cantidad de la prima mensual para la Parte D de Medicare";
  type8;
  type8En = "Nursing Facilitiy Changes";
  type8Sp = "Cambios en Centro de Enfermería";
  type8Description;
  type8DescriptionEn = "If you live in a nursing home and pay out of pocket, how much do you pay each month?";
  type8DescriptionSp = "Si vive en una casa de convalecencia y paga de su bolsillo, ¿cuánto paga cada mes?";
  type9;
  type9En = "Prescription";
  type9Sp = "Receta";
  type9Description;
  type9DescriptionEn = "Prescriptions medicine is what your doctor prescribes; Over-the-Counter Medicine is medicine you can buy without a prescription (like allergy medicine, antacids, pain medicine like Advil or Tylenol)";
  type9DescriptionSp = "Los medicamentos con receta son los que receta su médico; Los medicamentos de venta libre son medicamentos que puede comprar sin receta (como medicamentos para la alergia, antiácidos, analgésicos como Advil o Tylenol).";
  type10;
  type10En = "Remedial";
  type10Sp = "Gastos";
  type10Description;
  type10DescriptionEn = "Expenses that help relieve, remedy, or reduce a medical or health condition like case management, respite care, day care, supportive home care, housing modifications for accessibility)";
  type10DescriptionSp = "Que ayuden a aliviar, remediar o reducir una afección médica o de salud como administración de casos, cuidado de relevo, guardería, atención de apoyo en el hogar, modificaciones de vivienda para la accesibilidad)";

  ngAfterContentInit() {
    const defaultLanguage = this.translateService.getDefaultLang();
    if (defaultLanguage !== 'en') {
      this.title = this.titleSp
      this.medicalBillTypes = this.medicalBillTypesSp
      this.description = this.descriptionSp
      this.type1 = this.type1Sp
      this.type1Description = this.type1DescriptionSp
      this.type2 = this.type2Sp
      this.type2Description = this.type2DescriptionSp
      this.type3 = this.type3Sp
      this.type3Description = this.type3DescriptionSp
      this.type4 = this.type4Sp
      this.type4Description = this.type4DescriptionSp
      this.type5 = this.type5Sp
      this.type5Description = this.type5DescriptionSp
      this.type6 = this.type6Sp
      this.type6Description = this.type6DescriptionSp
      this.type7 = this.type7Sp
      this.type7Description = this.type7DescriptionSp
      this.type8 = this.type8Sp
      this.type8Description = this.type8DescriptionSp
      this.type9 = this.type9Sp
      this.type9Description = this.type9DescriptionSp
      this.type10 = this.type10Sp
      this.type10Description = this.type10DescriptionSp
    } else {
      this.title = this.titleEn
      this.medicalBillTypes = this.medicalBillTypesEn
      this.description = this.descriptionEn
      this.type1 = this.type1En
      this.type1Description = this.type1DescriptionEn
      this.type2 = this.type2En
      this.type2Description = this.type2DescriptionEn
      this.type3 = this.type3En
      this.type3Description = this.type3DescriptionEn
      this.type4 = this.type4En
      this.type4Description = this.type4DescriptionEn
      this.type5 = this.type5En
      this.type5Description = this.type5DescriptionEn
      this.type6 = this.type6En
      this.type6Description = this.type6DescriptionEn
      this.type7 = this.type7En
      this.type7Description = this.type7DescriptionEn
      this.type8 = this.type8En
      this.type8Description = this.type8DescriptionEn
      this.type9 = this.type9En
      this.type9Description = this.type9DescriptionEn
      this.type10 = this.type10En
      this.type10Description = this.type10DescriptionEn
    }
  }
}
