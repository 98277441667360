import { Observable } from 'rxjs';

import { ReferenceValue } from './tables.model';
import { ProgramCode } from './app.model';

export type Nullable<T> = T | null;
export type ControlPath = Array<string | number> | string;
export type Activable = Observable<boolean>;

export type ActivableFunction = () => Activable;
export type UpdateFunction = (value: any, index: number) => any;
export type EffectFunction = (x: any) => any;

export interface Address {
  houseNumber?: any;
  unit?: any;
  direction?: any;
  streetName: string;
  streetType?: any;
  nhCity?: City;
  city?: string;
  state: State;
  additionalInfo?: any;
  zip: string;
}

export interface MedicareFields {
  entitled: boolean;
  beginDate: string;
  premiumAmount: number;
}

export interface ContactPhones {
  home: string;
  cell: string;
  message: string;
}

export interface PersonalName {
  firstName: string;
  lastName: string;
  middleInitial: string;
  suffix?: any;
  fullName: string;
}

export interface LtcContactInfo {
  personFullName: string;
  hsbcServices: boolean;
  nursingFacilityServices: boolean;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  nhCity: City;
  city: City;
  state: State;
  additionalInfo?: any;
  zipCode: string;
}

export interface NameAddress {
  address: Address;
  name: PersonalName;
}

export interface GeneralHealthCoverage {
  individualNumber: string;
  individualName: any;
  eligibleSwitch: boolean;
  enrolledSwitch: boolean;
  employedSwitch: boolean;
  beginDate: Date;
}

export enum UtilityCodes {
  TWO_OR_MORE_UTILITIES = 'TWO_OR_MORE_UTILITIES',
  HEATING_COOLING = 'HEATING_COOLING',
  ELECTRICITY = 'ELECTRICITY',
  INTERNET = 'INTERNET',
  PHONE = 'PHONE',
  NONE = 'NONE',
}

export enum UtilityLabels {
  TWO_OR_MORE_UTILITIES = 'Two or more utilities',
  HEATING_COOLING = 'Heating / Cooling',
  ELECTRICITY = 'Electric Only',
  INTERNET = 'Internet Only',
  PHONE = 'Phone Only',
  NONE = '',
}

export const utilityCodesInOrder = [
  UtilityCodes.HEATING_COOLING,
  UtilityCodes.TWO_OR_MORE_UTILITIES,
  UtilityCodes.ELECTRICITY,
  UtilityCodes.INTERNET,
  UtilityCodes.PHONE,
  UtilityCodes.NONE,
];

type NotificationType = 'info' | 'danger' | 'success' | 'warning';

interface Message {
  message: string;
  type: NotificationType;
  timeout?: number | string;
  dismissible: boolean;
}

interface Error extends Message {
  code: string;
  field: string;
  value: string;
}

export type Notification = Message | Error;

export class NotificationMessage implements Message {
  constructor(public dismissible: boolean, public type: NotificationType, public message: string, public timeout?: number) {}
}

export class NotificationError extends NotificationMessage implements Error {
  constructor(public message: string, public code: string, public field: string, public value: any) {
    super(true, 'danger', message, 10000);
  }
}

export type Notifications = Notification[];

export type PrimaryLanguage = ReferenceValue;
export type City = ReferenceValue;
export type State = ReferenceValue;

export enum ProviderTypes {
  REGISTERED = 'REGISTERED',
  CAPTURED = 'CAPTURED',
}

export interface ModalState {
  title?: string;
  body?: string;
  declineButton?: string;
  confirmButton?: string;
  showConfirm?: boolean;
  showDecline?: boolean;
  timeout?: number;
  [s: string]: any;
}

export interface IdleConfig {
  idle: number;
  timeout: number;
  ping: number;
}

interface QuestionAnswer {
  answerId: number;
  text: string;
}

interface Question {
  questionId: number;
  text: string;
  answers: QuestionAnswer[];
}

export type Questions = Question[];

interface Answer {
  questionId: number;
  answerId: number;
}

export type Answers = Answer[];

export interface VerificationAnswer {
  rejectedToAnswer: boolean;
  answers: Answers;
}

export enum Understandments {
  CERTIFY = 'I certify',
  UNDERSTAND = 'I understand',
}

type Understandment = Understandments.CERTIFY | Understandments.UNDERSTAND;

interface StatementUnderstanding {
  I: Understandment;
  understand: string;
  programs: ProgramCode[];
}

export type StatementsUnderstanding = StatementUnderstanding[];

interface Yes extends ReferenceValue {
  code: 'Y';
  value: 'Yes';
}

interface No extends ReferenceValue {
  code: 'N';
  value: 'No';
}

export type TAYN = Yes | No;

export interface LTCAdmission {
  label: string;
  minDate: Date | null;
  maxDate: Date | null;
}

export type AlertType = 'info' | 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'light' | 'dark';
