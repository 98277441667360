<nav *ngIf="(tabs$ | async) as tabs" class="d-none d-lg-block">
  <ul class="nav nav-tabs nav-justified nav-fill flex-column flex-lg-row {{ type }}" *ngIf="tabs.length > 1">
    <ng-container *ngIf="!isTaxComponent">
      <li class="nav-item" *ngFor="let tab of tabs">
        <a class="nav-link {{ tab.status }} d-flex flex-row justify-content-center h-100" [class.active]="isActive(tab)"
          (click)="onNavigate(tab)">
          <span class="tab-section">{{ getTabTitle(tab) | translate }} </span>
        </a>
      </li>
    </ng-container>
    <ng-container *ngIf="isTaxComponent">
      <li class="nav-item" *ngFor="let tab of tabs">
        <a class="nav-link2 {{ tab.status }} d-flex flex-row justify-content-center h-100" [class.active]="isActive(tab)"
          (click)="onNavigate(tab)">
          <span class="tab-section">{{ getTabTitle(tab) | translate }} </span>
        </a>
      </li>
    </ng-container>
  </ul>
</nav>
